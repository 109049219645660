import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";

//API Used
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";

//Images Used
import dashHome from "../../../../src/img/dashHome.svg";
import searchIcn from "../../../../src/img/search.svg";
import altImg from "../../../../src/img/altImg.png";
import msgIcon from "../../../../src/img/message-text.svg";

//Css Pages
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import "../../css/Student/Student_Enrich.css";
import "../../css/Student/Student_Study.css";
import { register, unregister } from "../../../../src/Serviceworker"
import CommonSort from "../../js/CommonSort"
// Mui
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Student_Enrich_Quiz from "./Student_Enrich_Quiz";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles, Tooltip } from "@material-ui/core";
import { isNOTNullOrUndefined } from "../../../utils/helper";
import mixpanel from "mixpanel-browser";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: '190px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
}));

function Student_Enrich() {
  // Navigation
  const navigate = useNavigate();
  const [modifiedDate, setModifiedDate] = useState({});
  const token = sessionStorage.getItem("token");
  const [subjectList, setSubjectList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  // sessionStorage.setItem("selectedSubject", selectedSubject);
  const [selectedsubTopic, setSelectedSubTopic] = useState("");
  const [subTopic, setSubTopic] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [query, setQuery] = useState("");
  const [branchId, setBranchId] = useState("");
  const [planId, setPlanId] = useState("");
  const inboxChapter = sessionStorage.getItem('InboxChapter');
  const [selectedChapterId, setSelectedChapterId] = useState(inboxChapter ? inboxChapter : "");
  const [showContentModal, setShowContentModal] = useState(false);
  const [secletedCardName, setSecletedCardName] = useState("");
  const [loadQuizData, setLoadQuizData] = useState(false);
  // console.log("subjectList", subjectList);
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const user_role = localStorage.getItem('role');
  const user_id = localStorage.getItem('id');
  const [studentDetail, setStudentDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const [studentId, setStudentId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [viewQuizData, setViewQuizData] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [subDd, setSubDd] = useState(
    subjectList.length > 0 ? subjectList[0].subject : ""
  );
  const [subTopicDd, setSubTopicDd] = useState("");
  const [loading, setLoading] = useState(true);
  const [showClear, setShowClear] = useState(false);
  const [resumeQuizData, setResumeQuizData] = useState({});
  const [showSubTopic, setShowSubTopic] = useState(false);
  sessionStorage.setItem("subTopicId", selectedsubTopic)
  const classes = useStyles();

  const boardId = sessionStorage.getItem('boardId');
  const branchIdd = sessionStorage.getItem('branchId');
  const gradeId = sessionStorage.getItem('gradeId');
  const gradeName = sessionStorage.getItem('gradeName');
  const schoolIdd = sessionStorage.getItem('schoolId');
  const sectionId = sessionStorage.getItem('sectionId');
  const sectionName = sessionStorage.getItem('sectionName');


  const [preSelectedData, setPreSelectedData] = useState({});
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (location?.state) {
        setPreSelectedData(location?.state);
        setSelectedSubject(location.state.subjectId);
        setSelectedSubTopic(location.state.subTopicId);
        setSelectedChapterId(location.state.chapterId);
        setSecletedCardName(location.state.selectedCardName);
        setShowContentModal(true);
        // console.log("setPreSelectedData >>> ", location?.state, preSelectedData)

      }
    }, 1500)
  }, [location?.state]);

useEffect(()=>{
  if(user_role === "STUDENT"){
  mixpanel.track('Student_Enrich_Clicked',{
    "user_id":user_id,
    "username":userName,
    "boardId" :boardId,
    "branchId" :branchIdd,
    "gradeId" :gradeId,
    "gradeName" :gradeName,
    "schoolId" :schoolIdd,
    "sectionId" :sectionId,
    "sectionName" :sectionName,
  })
}
},[])
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };

  const { quiz_listing } = useParams();
  useEffect(() => {
    if (quiz_listing) {
      const chapDetails = JSON.parse(quiz_listing);
      setShowContentModal(quiz_listing);
      setSelectedChapterId(chapDetails.chapId);
      setSecletedCardName(chapDetails.chapName);
    }
  }, [quiz_listing]);

  useEffect(async () => {
    await fetchStudentData(userName, role);
    const studentData = await fetchStudentData(userName, role);
    setStudentDetail(studentData);
    // GetLastModifiedAt(setModifiedDate);
  }, []);

  const fetchStudentData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBranchId(res.data.data.student.branchId);
    setStudentId(res.data.data.student.id);
    setSchoolId(res.data.data.student.schoolId);
    sessionStorage.setItem("gradeId", res.data.data.student.gradeId);
    sessionStorage.setItem("boardId", res.data.data.student.boardId);
    sessionStorage.setItem("sectionId", res.data.data.student.sectionId);
    sessionStorage.setItem("branchId", res.data.data.student.branchId);
    sessionStorage.setItem("schoolId", res.data.data.student.schoolId);
    sessionStorage.setItem("studentId", res.data.data.student.id);
  };

  useEffect(async () => {
    if (branchId) {
      const planDetails = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlanId(planDetails.data?.data?.plans[0]?.planId);
    }
  }, [branchId]);

  const fetchSubjectData = async () => {
    const gradeId = sessionStorage.getItem("gradeId");
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan?planId=${planId}&gradeId=${gradeId}&menuName=ENRICH`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };

  const fetchSubTopicData = async (subjectId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${subjectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data.data || [];
  };

  const fetchChapterData = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");

    const hideSubtopicsValue = subjectList.find(item => item.subjectId === selectedSubject)?.hideSubtopics;
    //let subTopicUrl = !hideSubtopicsValue ? isNOTNullOrUndefined(selectedsubTopic) && `&subTopicId=${selectedsubTopic}`: ""
    // console.log("selectedsubTopic >>>", hideSubtopicsValue, subjectList, selectedSubject)
    let subTopicUrl = '';
    if (!hideSubtopicsValue) {
      // console.log("selectedsubTopichideSubtopicsValue", selectedsubTopic)
      subTopicUrl = `&subTopicId=${selectedsubTopic}`;
    }
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters?subjectId=${selectedSubject}&gradeId=${gradeId}&boardId=${boardId}&studentLogin=true&schoolId=${schoolId}&branchId=${branchId}&sectionId=${sectionId}${subTopicUrl}`;

    // if (selectedsubTopic) {
    //   url += `&subTopicId=${selectedsubTopic}`;
    // }

    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("res.data.data", res.data.data)
    res.data.data = await CommonSort(res?.data?.data || [], "chapter", false);
    // console.log("res.data.data", res.data.data)
    return res.data.data || [];
  };

  // VIEW QUIZ API Integration //

  const fetchViewQuiz = async () => {
    // console.log("fetchViewQuiz called in enrich.js")
    const studentId = sessionStorage.getItem("studentId");
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");

    

    const sessionchaptervalue = JSON.parse(sessionStorage.getItem("selectedChapterId"))
    // console.log("sessionchaptervalue", selectedChapterId, location?.state?.subjectId, sessionStorage.getItem("subTopicId"))
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/listing?studentId=${studentId}&schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&chapterId=${selectedChapterId || sessionchaptervalue}&academicYearId=${academicYearList}&reviewStatus=APPROVED`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    var valll = res.data.data || [];
    // console.log('quizzzzzzzlistt',valll);
    let unitQuiz = valll.filter((quiz)=>quiz.quizTypeName ==="Unit Quiz")
    // console.log('unitunit',unitQuiz)
    let unitYetToStart = unitQuiz.filter((unit)=>unit.examinationStatus ==="NOT_YET_STARTED") ;
    // console.log('startUnit',unitYetToStart);
    const quizzesYetToStart = unitYetToStart.map((item) => ({
      "quizId": item?.quizId,
      "quizName": item?.quizName,
      "examinationStatus": item?.examinationStatus,
      "startDate": item?.startDate,
      "startTime": item?.startTime,
      "endDate": item?.endDate,
      "endTime": item?.endTime
  }));
  if(unitYetToStart.length>0){
  mixpanel.track('Quiz_Not_Yet_Started', {
      "user_id": user_id,
      "username":userName,
      "quizzes": quizzesYetToStart
  });
}
    unregister();
    sessionStorage.setItem("quiz_listings", JSON.stringify(valll));
    return valll

  };


  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data.data[0].id || [];
  };

  useEffect(() => {
    const fetchViewQuizData = async () => {
      try {
        const data = await fetchViewQuiz(
          studentId,
          schoolId,
          branchId,
          selectedSubject,
          selectedChapterId
        );
        setViewQuizData(data);
      } catch (error) {
        // console.error(error);
      }
    };

    const fetchAcademicYearList = async () => {
      try {
        const data = await fetchAcademicYearData();
        setAcademicYearList(data);
      } catch (error) {
        // console.error(error);
      }
    };

    let isMounted = true;

    const fetchData = async () => {
      await Promise.all([fetchAcademicYearList(), fetchViewQuizData()]);
    };

    fetchData();

    if (selectedChapterId && isMounted) {
      setShowContentModal(true);
    }
    return () => {
      isMounted = false;
    };
  }, [
    studentId,
    schoolId,
    branchId,
    selectedChapterId,
    selectedSubject,
    loadQuizData,
  ]);
  const handleSubject = (event) => {
    const test = event.target.value;
    setSelectedSubTopic("");
    location.state = null; // bcz same location exists even after selcting the subject
    setSelectedSubject(event.target.value);
    const selectedValue = subjectList.find(
      (item) => item.subjectId === test
    )?.subject;
    setSubDd(selectedValue);
    const selectedSubject = subjectList.find(val => val.subjectId === String(event.target.value));
    // console.log(subjectList, selectedSubject, showSubTopic, selectedsubTopic, "handleSubject")
    // console.log('aaaa',selectedSubject?.subject,selectedSubject?.subjectId);

    // mix panel logic goes here ---------------------------------------
    
    if(user_role === "STUDENT"){
    mixpanel.track('Student_Subject_Select',{
      "user_id":user_id,
      "subject":selectedSubject?.subject,
      "subjectId":selectedSubject?.subjectId,
      "username":userName
    })
  }
    if (selectedSubject && selectedSubject?.hideSubtopics) {
      setShowSubTopic(false);
      // console.log("setShowSubTopic false")
    } else {
      setShowSubTopic(true);
      setSelectedSubTopic(selectedSubject?.subTopics?.[0].id)
      // console.log("setShowSubTopic true", selectedSubject?.subTopics?.[0].id, subTopic)
    }
  };

  const handleCaptureChapter = (item) =>{
// console.log('chap',item);
if(user_role === "STUDENT"){
  mixpanel.track('Student_Chapter_Select',{
    "user_id":user_id,
    "chapter":item?.chapter,
    "chapterId":item?.id,
    "username":userName
  })

  }
}


  const handleSubTopic = (event) => {
    // console.log("handleSubTopic", event.target.value)
    const test = event.target.value;
    // console.log("setSelectedSubTopic", event.target.value)
    setSelectedSubTopic(event.target.value);
    const selectedValue = subTopic.find((item) => item.id === test)?.subTopic;
    setSubTopicDd(selectedValue);
    // console.log("subTopicValuecheck",selectedValue)
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const chapList = await fetchChapterData();
      setChapterData(chapList);
      setLoading(false);
    };
    if (location?.state?.from !== "enrich") {
      fetchData();
    }
  }, [selectedSubject, selectedsubTopic, planId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var subList = await fetchSubjectData();
      // console.log("4-7-24 ", subList)
      subList = subList?.filter(v => v.skilledSubject === true) || [];
      setSubjectList(subList);
      // console.log('sublist', subList);
      const InboxSub = sessionStorage.getItem('InboxSubject1')
      const matchedSubject = subList.find(subject => subject.subjectId === InboxSub);
      // console.group("matchedSubject", matchedSubject, subList)
      if (matchedSubject) {
        setSelectedSubject(matchedSubject.subjectId);
      } else {
        setSelectedSubject(subList.length > 0 ? subList[0].subjectId : "");
        setShowSubTopic(subList?.length > 0 ? !subList[0].hideSubtopics : true)
      }
      setLoading(false);
    };

    fetchData();
  }, [planId]);

  useEffect(() => {
    const fetchData = async () => {
      setSelectedSubTopic("");
      if (selectedSubject) {
        setLoading(true);
        const subTopicList = await fetchSubTopicData(selectedSubject);
        setSubTopic(subTopicList);

        if (subTopicList.length > 0) {
          var sess = location?.state?.subTopicId ? location?.state?.subTopicId : subTopicList[0]?.id;
          // console.log("setSelectedSubTopic", location?.state?.subTopicId, subTopicList[0].id, sess)
          setSelectedSubTopic(sess);
        }
        setLoading(false);
      }
    };
    fetchData();

  }, [selectedSubject]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const datas = await fetchChapterData(selectedSubject);
  //     // setSubTopic(datas);
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, []);

  // To Showing the DD Subject Name in Quiz-listing Page
  useEffect(() => {
    const selectedValue = subjectList.find(
      (item) => item.subjectId === selectedSubject
    )?.subject;
    setSubDd(selectedValue || "");
  }, [selectedSubject, subjectList]);

  // To Showing the DD Subtopic Name in Quiz-listing Page
  useEffect(() => {

    const subTopicValue = subTopic.find(
      (item) => item.id === selectedsubTopic
    )?.subTopic;
    // console.log("subTopicValuecheck",subTopicValue,location?.state,subTopic,selectedsubTopic)
    setSubTopicDd(subTopicValue || "");
  }, [selectedsubTopic, subTopic]);

  //-------| Truncate the Chapter Name |-------//
  const maxLength = 18;

  useEffect(async () => {
    var sessData = JSON.parse(sessionStorage.getItem("itemload"));
    // console.log(sessData);

    var listofQuiz = await fetchViewQuiz()
    // console.log("listofQuiz", listofQuiz)
    if (sessData) {
      setShowContentModal(true)
    }
  }, [])

  useEffect(() => {
    // console.log("subTopicValue location", selectedSubject, selectedsubTopic, subTopic, location?.state)
    if (location?.state && location?.state?.subtopicName && location?.state?.subtopicName !== null) {
      // console.log("subTopicValuecheck",location?.state?.subtopicName)
      setSubTopicDd(location?.state?.subtopicName);
    } else {
      // console.log("nothing in location")
    }

  }, [location?.state])

  return (
    <>
     
      {!showContentModal ? (
        <div>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                width: "80vw",
              }}
            >
              {/* <CircularProgress loaderdisable/> */}
            </Box>
          ) : (
            location?.state?.from !== "enrich" &&
            <div>
              {/*-------| Common Items Across The Project Starts Here |-------*/}
              <article>
                {/* <div className={dashboardContentStyles.dashboard_link}>
                  <span className={dashboardContentStyles.link_icon}>
                    <img
                      src={dashHome}
                      alt="no img"
                      width="15px"
                      height="20px"
                      style={{ verticalAlign: "middle" }}
                    />
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      marginLeft: "4px",
                      color: "#7A7A7A",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard/template");
                    }}
                  >
                    Home ⨠
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#001AF3",
                      marginLeft: "4px",
                    }}
                  >
                    Subjects
                  </span>
                </div>
                <div className={dashboardContentStyles.dashboard_last_updated}>
                  <p>Last Update: {modifiedDate.data}</p>
                </div> */}
                <div style={{ position: 'relative', margin: '14px' }}>
                  <span className={dashboardContentStyles.link_icon}>
                    <img
                      src={dashHome}
                      alt="no img"
                      className={dashboardContentStyles.az_menu_icon}
                    />
                  </span>
                  <span
                    className={dashboardContentStyles.az_menu_text}
                    style={{ cursor: 'pointer' }}
                    id={`az-breadscrum-home`}
                    onClick={() => {
                      sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                      sessionStorage.removeItem("sideNavSubMenuNames");

                      navigate("/dashboard/template");
                    }}
                  >
                    Home
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"
                      style={{ color: "#828282" }} />
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"
                      style={{ color: "#828282" }} />
                  </span>
                  <span
                    className={dashboardContentStyles.az_selected_menu_text}
                  >
                    Subjects
                  </span>
                </div>

                {/* <div className={dashboardContentStyles.dashboard_last_updated}>
                  <p>Last Update: {modifiedDate.data}</p>
                </div> */}
              </article>
              {/*-------| Common Items Across The Project Ends Here |-------*/}

              <div>
                {/*-------| DropDown & Search Division Starts Here |-------*/}
                <div className="dropdown_And_search_div menuDiv">
                  {/*-------| DropDown Division Starts Here |-------*/}
                  <div className="dropdown_div">
                    {/*-------| DD-1 |-------*/}
                    <div>
                      <Box sx={{ minWidth: 120 }}>
                        {/* <FormControl
                          fullWidth
                          size="small"
                          sx={{
                            "& .MuiInputLabel-root": {
                              color: "#FD8C00 !important",
                              fontSize: "14px !important",
                              opacity: 0.9,
                              lineHeight: 1.1,
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "#FFFFFF",
                              borderRadius: "50px",
                              width: "146px",
                              height: "32px",
                            },
                          }}
                        >
                          {!selectedSubject && (
                            <InputLabel
                              id="az-sudent-subject-select-label"
                              shrink={false}
                            >
                              Subjects
                            </InputLabel>
                          )}
                          <Select
                            labelId="demo-simple-select-label"
                            id="az-sudent-subject-select"
                            value={selectedSubject}
                            label=""
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={(e) => handleSubject(e)}
                            setSubjectList={selectedSubject}
                          >
                            {subjectList?.map((item) => {
                              return (
                                <MenuItem
                                  value={item.subjectId}
                                  // value={item.id}
                                  id={`az-student-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: 'orange',
                                      color: "#FFFFFF"
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: '#fcedc2',
                                      color: "#000000"
                                    },
                                    "&:not(.Mui-selected):hover": {
                                      backgroundColor: '#fcedc2',
                                      color: '#000000',
                                    },
                                  }}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "12px",
                                          color:
                                            selectedSubject === item.subjectId
                                              ? "#FD8C00"
                                              : "black",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "100%",
                                        }}
                                      >
                                        {item.subject}
                                      </Typography>
                                    }
                                  />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl> */}

                        <FormControl fullWidth >
                          <InputLabel
                            sx={{
                              fontSize: '13px',
                              color: '#e7ab3c',
                              top: '-10px',
                              '&.MuiInputLabel-shrink': {
                                top: '0',
                              },
                              '&.Mui-focused': {
                                color: '#e7ab3c'
                              }
                            }} className='dropDownLabel'
                            id="az-sudent-subject-select-label">Subjects</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="az-sudent-subject-select"
                            value={selectedSubject}
                            input={<OutlinedInput label='SubTopics' />}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '150px',
                                  marginTop: '2px',
                                },
                              },
                              MenuListProps: {
                                sx: {
                                  padding: '0px'
                                },
                              },
                            }}
                            IconComponent={props => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                            )}
                            sx={{
                              width: '150px',
                              height: '30px',
                              padding: '10px',
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                              }
                            }}
                            className='dropdownSelect'
                            onChange={(e) => handleSubject(e)}
                            setSubjectList={selectedSubject}
                          >
                            {/* {
                              //console.log("4-7-24 skilledsubject", subjectList)
                            } */}
                            {subjectList?.map((item) => {
                              return (
                                <MenuItem
                                  value={item.subjectId}
                                  // value={item.id}
                                  id={`az-student-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                                  className={
                                    dashboardContentStyles.menu_item
                                  }
                                  sx={{
                                    fontSize: '10px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                      backgroundColor: '#f4d788',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#e7ab3c',
                                      '&:hover': {
                                        backgroundColor: '#f4d788',
                                      },
                                    },
                                  }}
                                >
                                  {item.subject}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    {/*-------| DD-2 |-------*/}
                    {
                      //console.log(showSubTopic, subTopic, selectedsubTopic, "showSubTopic")
                    }
                    {showSubTopic && <div>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              fontSize: '13px',
                              color: '#e7ab3c',
                              top: '-10px',
                              '&.MuiInputLabel-shrink': {
                                top: '0',
                              },
                              '&.Mui-focused': {
                                color: '#e7ab3c'
                              }
                            }} className='dropDownLabel'
                            id="az-sudent-subtopic-select-label">SubTopics</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="az-sudent-subtopic-select"
                            value={selectedsubTopic}
                            onChange={handleSubTopic}
                            input={<OutlinedInput label='SubTopics' />}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '150px',
                                  marginTop: '2px',
                                },
                              },
                              MenuListProps: {
                                sx: {
                                  padding: '0px'
                                },
                              },
                            }}
                            IconComponent={props => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                            )}
                            sx={{
                              width: '150px',
                              height: '30px',
                              padding: '10px',
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                              }
                            }}
                            className='dropdownSelect'
                          >
                            {subTopic?.map((item) => {
                              return (
                                <MenuItem value={item.id}
                                  id={`az-student-subtopic-dropdown-${item?.subTopic?.toLowerCase()?.replace(/ /g, "_")}`}
                                  className={
                                    dashboardContentStyles.menu_item
                                  }
                                  sx={{
                                    fontSize: '10px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                      backgroundColor: '#f4d788',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#e7ab3c',
                                      '&:hover': {
                                        backgroundColor: '#f4d788',
                                      },
                                    },
                                  }}
                                >
                                  {item.subTopic}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>}
                  </div>
                  {/*-------| DropDown Division Ends Here |-------*/}

                  {/*-------| Search Division Starts Here |-------*/}
                  <div className="search_div">
                    <TextField
                      onChange={handleInputChange}
                      type={"text"}
                      value={query}
                      id="az-sudent-search"
                      placeholder={"Search anything..."}
                      sx={{
                        "& .MuiInputBase-root": {
                          width: "200px !important",
                          height: "30px !important",
                          borderRadius: "50px !important",
                          fontFamily: "Poppins !important",
                          fontSize: "13px",
                          marginLeft: "50px !important",
                          background: "#FFFFFF",
                          border: "1px #FFFFFF",
                          opacity: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {showClear && (
                              <IconButton onClick={handleClear}>
                                <ClearIcon
                                  style={{ color: "#F05262", fontSize: "1vw" }}
                                />
                              </IconButton>
                            )}
                            {!showClear && (
                              <IconButton>
                                <img
                                  src={searchIcn}
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: "large",
                                  }}
                                  aria-hidden="true"
                                />
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />
                  </div>
                  {/*-------| Search Division Ends Here |-------*/}
                </div>
                {/*-------| DropDown & Search Division Ends Here |-------*/}

                <div className="u_line"></div>

                {/*-------| LMS Card Division Starts Here |-------*/}
                <div>
                  {
                    location?.state?.from !== "enrich" &&

                    <div
                      id={`az-card-parent`}
                      className={dashboardContentStyles.cardContainer2}>
                      {(chapterData.length > 0) ? (
                        chapterData
                          ?.filter((itm) =>
                            itm.chapter.toLowerCase().includes(query)
                          )
                          .map((item, i) => (
                            <div
                              id={`az-card-child-${item.chapter.toLowerCase().replace(/ /g, "_")}`}
                              key={item.id}
                              onClick={() => {
                                // setSecletedCardName(item?.chapter);
                                // setSelectedChapterId(item?.chapterId);
                                handleCaptureChapter(item)
                                setSecletedCardName(item?.chapter);
                                setSelectedChapterId(item?.id);
                                sessionStorage.setItem("selectedChapterId", JSON.stringify(item?.id))
                              }}
                              className={dashboardContentStyles.card}
                            >
                              <img
                                src={item?.thumbnailPath}
                                width={110}
                                alt={""}
                                // style={{ maxHeight: "138px" }}
                                className={dashboardContentStyles.az_quiz_card_img} />
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  color: "#423d5c",
                                }}
                              >
                                {item?.chapter}
                              </div>
                            </div>
                          ))
                      ) : (
                        <div>No data for the selected filters</div>
                      )}
                    </div>
                  }
                </div>
                {/*-------| LMS Card Division Ends Here |-------*/}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Student_Enrich_Quiz
          closeModal={() => {
            if (location?.state?.from !== undefined) {
              location.state.from = "";
              var sub = subjectList?.find((v) => v.subjectId == selectedSubject) || {}
              if (sub?.hideSubtopics) {
                setShowSubTopic(false);
              } else {
                setShowSubTopic(true);
              }
            }
            // console.log("close modal called", location?.state, selectedSubject, subjectList, showSubTopic, subTopic, showContentModal)
            setShowContentModal(false);
            setSelectedChapterId("");
          }}
          selectedChapterId={selectedChapterId}
          selectedCardName={secletedCardName}
          selectedSubject={selectedSubject}
          subjectList={subjectList}
          viewQuizData={viewQuizData}
          setLoadQuizData={setLoadQuizData}
          academicYearList={academicYearList}
          schoolId={schoolId}
          branchId={branchId}
          subTopicId={selectedsubTopic}
          studentId={studentId}
          subDD={subDd}
          subTopicDd={subTopicDd}
          fetchViewQuiz={fetchViewQuiz}
          loadQuizData={loadQuizData}
          setViewQuizData={setViewQuizData}
        />
      )}
    </>
  );
}

export default Student_Enrich;
