import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import axios from 'axios'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import queSelectionStyles from '../../../css/queSelectionStyles.module.css'
import quebankStyle from '../../../css/questionbank.module.css'
import { isNOTNullOrUndefined } from '../../../../utils/helper'
import RootConceptsList from './RootConceptsList'
import SubConceptQuestionsFetched from './SubConceptQuestionsFetched'

const SubConcept = ({
  conceptId,
  chapterId,
  isConceptExpanded,
  taxonomySelected,
  questiontypeId,
  search
}) => {
  const [subConceptsList, setsubConceptsList] = useState([])

  const local_data = JSON.parse(localStorage.getItem('quiz_data'))

  const boardId = local_data?.selected_board?.id
  const gradeId = local_data?.selected_grade?.id
  const subjectId = local_data?.selected_subject?.id

  const subTopicId = local_data?.selected_sub_topic?.id

  const TOKEN = sessionStorage.getItem('token')

  const showtaxonomy = () => {
    if (questiontypeId && questiontypeId.code === 'CASE') {
      return false
    } else if (isNOTNullOrUndefined(taxonomySelected)) {
      return true
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL
          }v1/api/content/question/sub-root/concept-list?pageSize=1000&conceptId=${conceptId}&chapterId=${chapterId}${isNOTNullOrUndefined(boardId) ? `&boardId=${boardId}` : ''
          }${isNOTNullOrUndefined(gradeId) ? `&gradeId=${gradeId}` : ''}${isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}` : ''
          }${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''
          }${showtaxonomy() ? `&taxonomyId=${taxonomySelected?.id}` : ''}${isNOTNullOrUndefined(questiontypeId.id)
            ? `&questionTypeId=${questiontypeId.id}`
            : ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`
            }
          }
        )
        setsubConceptsList(response.data.data.data)
      } catch (error) {
        // console.log(error)
      }
    }
    if (isConceptExpanded) {
      fetchData()
    }
  }, [conceptId, isConceptExpanded])

  const [IsSubconceptExpanded, setIsSubconceptExpanded] = useState(true)

  return (
    <AccordionDetails>
      {subConceptsList.length > 0 &&
        subConceptsList.map((sub, i) => {
          return (
            <Accordion
              expanded={setIsSubconceptExpanded}
              key={sub.id}
              sx={{ background: '#EEEFFF' }}
              onChange={(e, expanded) => setIsSubconceptExpanded(expanded)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <p className={quebankStyle.plan_container_link}>
                  <span
                    className={queSelectionStyles.breadcrumbs}
                    style={{ color: '#FD8C00' }}
                  >
                    {sub.conceptName}
                  </span>
                </p>
              </AccordionSummary>
              <SubConceptQuestionsFetched
                IsSubconceptExpanded={IsSubconceptExpanded}
                taxonomySelected={taxonomySelected}
                questiontypeId={questiontypeId}
                chapterId={chapterId}
                subConceptId={sub.id}
                conceptId={conceptId}
                search={search}
              />
              <RootConceptsList
                IsSubconceptExpanded={IsSubconceptExpanded}
                taxonomySelected={taxonomySelected}
                questiontypeId={questiontypeId}
                chapterId={chapterId}
                subConceptId={sub.id}
                conceptId={conceptId}
                search={search}
              />
            </Accordion>
          )
        })}
    </AccordionDetails>
  )
}

export default SubConcept
