
import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from 'react';
import ReactFlow, { ReactFlowProvider, useNodes, useEdges, addEdge, removeElements, Controls } from 'react-flow-renderer';
import {
  useNodesState,
  useEdgesState,
  useReactFlow,
  Panel,
  EdgeText,
  updateEdge
} from 'reactflow';
import "../../css/Student/Student_Enrich_Quiz_MTF_DrawLines.css";
import $ from 'jquery';
import { lowerCase } from 'lodash';
import _ from "lodash";
import { Preview } from '@mui/icons-material';
import { rightClickEnable } from "../../../../src/Serviceworker"
const flowKey = 'example-flow';
const getNodeId = () => `randomnode_${+new Date()}`;




const SaveRestore = memo(({ questions, currentQuestion, setAttendMtfDl, attendMtfDl, setAttendedMtfDl, attendedMtfDl, resumeBtnClicked, attendMtfDlTemp, setAttendMtfDlTemp, ispreview, list, showExplanation }) => {
//console.log("dlllllllllllllllllll", questions, currentQuestion, "resumeBtnClicked", resumeBtnClicked, "attendedMtfDl ", attendedMtfDl, "attendMtfDl ", attendMtfDl, "attendMtfDlTemp ", attendMtfDlTemp, "ispreview", ispreview);

  function checkAnswer(array, order, valueToCheck) {
    // console.log(array, order, valueToCheck, "iniyaaa")
    const foundObject = array.find(obj => obj.order == order);
    // console.log("foundObject", foundObject)
    if (foundObject) {
      return foundObject.answers.includes(valueToCheck);
    }
    return false;
  }
  const additionalStyles = {
    '.react-flow__controls-button.react-flow__controls-fitview': {
      display: 'none',
    },
  };
  var Vquiztype = sessionStorage.getItem("quiztype");
  // console.log("Vquiztype", Vquiztype)
  // Memoize the result calculation
//console.log("setEdges 0", attendedMtfDl)
  const [edges, setEdges,] = useEdgesState([]);
  const [isDisplay, setIsDisplay] = useState(false);


  var subedges = []
  const result = useMemo(() => {
    setIsDisplay(true)
    // console.log('Calculating result...', questions[currentQuestion], !resumeBtnClicked);
    if (!resumeBtnClicked) {
      // console.log("ififififififififififififififi11111111111111111111111111111111111111111111111")
      // setAttendedMtfDl(questions[currentQuestion].mtfEdges || [])
      if (questions[currentQuestion]?.options[0] && questions[currentQuestion]?.options[0]?.length > 0) {
        return questions[currentQuestion].options[0].flatMap((item, index) => {
          const idQuestion = (index * 2 + 1).toString();
          const idJumbled = (index * 2 + 2).toString();

          return [
            {
              id: idQuestion,
              questionid: questions[currentQuestion].id,
              sourcePosition: 'right',
              // targetPosition: "right",
              type: 'input',
              data: { label: <div dangerouslySetInnerHTML={{ __html: item.htmlTagQuestion }} /> },
              position: { x: 100, y: (index * 100) + index * 100 },
            },
            {
              id: idJumbled,
              questionid: questions[currentQuestion].id,
              // sourcePosition: "left",
              targetPosition: 'left',
              type: 'output',
              data: { label: <div dangerouslySetInnerHTML={{ __html: item.htmlTagJumbled }} /> },
              position: { x: 500, y: (index * 100) + index * 100 },
              answersText: item.htmlTagJumbled
            },
          ];
        });




      } else {
        // alert("error else")
        return []
      }
    } else {
    //console.log("chapterquizkeyswe", ispreview && resumeBtnClicked)
      if (ispreview && resumeBtnClicked) {
        const edgesArray = questions[currentQuestion]?.mtfAnswerByStudent
          ?.flatMap(({ order, answers }, index) => {
          //console.log(order, answers)
            return answers.map((target, innerIndex) => {
              const result = checkAnswer(questions[currentQuestion]?.matchTheFollowing, order, target);
            //console.log(result);

            //console.log("targettargettarget", order, target, innerIndex)
              const edgeObject = {
                id: "reactflow__edge-" + lowerCase(order) + "-" + lowerCase(target),
                source: String((order.charCodeAt(0) - 'A'.charCodeAt(0) + 1) * 2 - 1),
                target: String((((target.charCodeAt(0) - 'A'.charCodeAt(0))) * 2) + 2),
                targetTemp: target,
                sourceHandle: null,
                targetHandle: null,
                style: {
                  stroke: result ? "rgb(0, 206, 149)" : "rgb(235, 62, 104)", strokeWidth: 2
                },
                questionid: questions[currentQuestion].questionId,
                connectable: true,
                answersText: answers[innerIndex].htmlTagJumbled,
                // currentQuestion
              };
            //console.log(edgeObject, "edgeObject");
              edgeObject.connectable = true
              return edgeObject;
            });
          });
        // console.log(edgesArray, "848484848484");
        const resultArray = [];
        // console.log(resultArray, "resultArray", attendedMtfDl);
        setAttendedMtfDl(resultArray)
        subedges = resultArray
        setEdges(edgesArray)
      //console.log("setEdges 1", edgesArray)


        // console.log("setEdges-----111111111111", resultArray)
        return questions[currentQuestion]?.matchTheFollowing?.flatMap((item, index) => {
          const idQuestion = (index * 2 + 1).toString();
          const idJumbled = (index * 2 + 2).toString();
          // var dynamicBorderColor = questions[currentQuestion]?.matchTheFollowing[index]?.correctAnswer ? "rgb(0, 206, 149)" : "rgb(235, 62, 104)"
          var dynamicBorderColor = questions[currentQuestion]?.ansFromStudentCurrect ? "rgb(0, 206, 149)" : "rgb(235, 62, 104)"

          // var dynamicBorderColor = "black"
          var DATA = [
            {
              id: idQuestion,
              questionid: questions[currentQuestion]?.id || questions[currentQuestion]?.questionid,
              sourcePosition: "right",
              type: "input",
              data: { label: item.question },
              position: { x: 100, y: 100 + index * 100 },
              style: {
                background: '#fff', border: '2px solid ' + dynamicBorderColor,
                //  borderRadius: 15, fontSize: 12
              }

            },
            {
              id: idJumbled,
              questionid: questions[currentQuestion].id || questions[currentQuestion].questionid,
              targetPosition: "left",
              type: "output",
              data: { label: item.jumbledAnswer },
              position: { x: 500, y: 100 + index * 100 },
              style: {
                background: '#fff', border: '2px solid ' + dynamicBorderColor,
                //  borderRadius: 15, fontSize: 12
              }

            },
          ]
          // console.log(DATA, "setedges------...................................................................", resultArray)
          setAttendedMtfDl(resultArray)
          // setEdges(DATA)
          return DATA;
        })
      } else {


        //definitely here setAttendedMtfDl need to set so answer is must
        // console.log(questions[currentQuestion]);
        const edgesArray = questions[currentQuestion].matchTheFollowing.flatMap(({ order, answers }, index) => {
          return answers.map((target, innerIndex) => {
            const edgeObject = {
              id: "reactflow__edge-" + lowerCase(order) + "-" + lowerCase(target),
              source: String((order.charCodeAt(0) - 'A'.charCodeAt(0) + 1) * 2 - 1),
              target: String((((target.charCodeAt(0) - 'A'.charCodeAt(0))) * 2) + 2),
              targetTemp: target,
              sourceHandle: null,
              targetHandle: null,
              style: { stroke: questions[currentQuestion].matchTheFollowing[index].correctAnswer ? "rgb(0, 206, 149)" : "rgb(235, 62, 104)", strokeWidth: 2 },
              questionid: questions[currentQuestion]?.questionId || questions[currentQuestion]?.id,
              connectable: true,
              answersText: answers[innerIndex].htmlTagJumbled,
              // currentQuestion
            };
            // console.log(edgeObject);
            edgeObject.connectable = true
            return edgeObject;
          });
        });
        // console.log(edgesArray, "848484848484");
        const resultArray = [];
        // console.log(resultArray, "resultArray", attendedMtfDl);
        setAttendedMtfDl(resultArray)
        subedges = resultArray
        setEdges(edgesArray)
      //console.log("setEdges 3", edgesArray)
        return questions[currentQuestion].matchTheFollowing.flatMap((item, index) => {
          const idQuestion = (index * 2 + 1).toString();
          const idJumbled = (index * 2 + 2).toString();
          var dynamicBorderColor = questions[currentQuestion].matchTheFollowing[index].correctAnswer ? "rgb(0, 206, 149)" : "rgb(235, 62, 104)"
          // var dynamicBorderColor = "black"
          var DATA = [
            {
              id: idQuestion,
              questionid: questions[currentQuestion].id || questions[currentQuestion].questionid,
              sourcePosition: "right",
              type: "input",
              data: { label: item.question },
              position: { x: 100, y: 100 + index * 100 },
              style: {
                background: '#fff', border: '2px solid ' + dynamicBorderColor,
                //  borderRadius: 15, fontSize: 12
              }

            },
            {
              id: idJumbled,
              questionid: questions[currentQuestion].id || questions[currentQuestion].questionid,
              targetPosition: "left",
              type: "output",
              data: { label: item.jumbledAnswer },
              position: { x: 500, y: 100 + index * 100 },
              style: {
                background: '#fff', border: '2px solid ' + dynamicBorderColor,
                //  borderRadius: 15, fontSize: 12
              }

            },
          ]
          // console.log(DATA, "setedges------...................................................................", resultArray)
          setAttendedMtfDl(resultArray)
          // setEdges(DATA)
          return DATA;
        })
      }
    }

  }, [questions, currentQuestion]);
  // console.log("result ", result)
  const edgeUpdateSuccessful = useRef(true);
  const [droppedAnswer, setDroppedAnswer] = useState("");
  const [answerKey, setAnswerKey] = useState("");
  const [answerArray, setAnswerArray] = useState([]);

  const [isViewModal, setViewModal] = useState(false);
  const [qstn, setQuestion] = useState(result);
  const currentQuestionData = ((ispreview && !resumeBtnClicked) || (ispreview && resumeBtnClicked) || (!ispreview && !resumeBtnClicked)) ? questions[currentQuestion] : (list?.questionAnswerKeys[currentQuestion] || questions[currentQuestion]);
  const [rfInstance, setRfInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(result);

  const [accumulatedData, setAccumulatedData] = useState([]);
  const [isInteractivityEnabled, setInteractivityEnabled] = useState(true);


  useEffect(() => {
  //console.log("useeffectt valled.")
    const handleContextMenu = (e) => {
      // if (!authContext.isAuthenticated) { this is to restrict right click based on loggedin info

      e.preventDefault();
    //console.log("handleContextMenu called")
    };
    document.addEventListener('contextmenu', handleContextMenu);

    document.addEventListener('keydown', function (event) {
    //console.log(event);
      // alert(event.getModifierState)
      const isModifierKey = event.getModifierState('Alt') ||
        event.getModifierState('Tab') ||
        event.getModifierState('Shift');

      if (event.key === 'F12' || isModifierKey) {
      //console.log("Modifier key pressed");
        event.preventDefault();
      } else if (event.key === "Enter" || event.key === " ") {
      //console.log(`${event.key} key pressed`);

        // Check if no modifier keys are pressed
        const noModifiers = !event.getModifierState('Alt') &&
          !event.getModifierState('Control') &&
          !event.getModifierState('Meta') &&
          !event.getModifierState('Shift');

        if (noModifiers) {
        //console.log("No modifier keys pressed");
          // Prevent the default behavior only for <button> elements
          if (event.target.tagName === 'BUTTON') {
            event.preventDefault();
          //console.log("Key event prevented for <button>");
          }
        }
      }
    });

    const handleBeforeUnload = (event) => {
    //console.log("close tab", event.type); // For reload and close tab
      const isRefresh = !event.currentTarget.performance.navigation.type;
    //console.log("isRefresh", isRefresh);

      if (event.type === 'beforeunload') {
        const message = "Are you sure you want to leave? Your progress may be lost.";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      } else {
      //console.log("else close tab");
        const message = "Are you sure you want to close the tab?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    }
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [])

  const onSave = () => {
    try {
    //console.log('rfInstance', rfInstance);
      if (rfInstance) {
        const flow = rfInstance.toObject();
      //console.log(flow);
        const filteredEdges = flow.edges;
      //console.log('filteredEdges', filteredEdges);
        setAnswerArray(filteredEdges);
        // setAttendedMtfDl(filteredEdges);
        localStorage.setItem(flowKey, JSON.stringify(flow));
      }
    } catch (error) {
    //console.log(error)
    }
  };



  useEffect(() => {
    setNodes(result);
    if (ispreview && !resumeBtnClicked) {
      const edgesArray = questions[currentQuestion].options[0].flatMap(({ order, answers }, index) => {
        return answers.map((target, innerIndex) => {
          const edgeObject = {
            id: "reactflow__edge-" + lowerCase(order) + "-" + lowerCase(target),
            source: String((order.charCodeAt(0) - 'A'.charCodeAt(0) + 1) * 2 - 1),
            target: String((((target.charCodeAt(0) - 'A'.charCodeAt(0))) * 2) + 2),
            targetTemp: target,
            sourceHandle: null,
            targetHandle: null,
            style: { stroke: questions[currentQuestion].options[index]?.correctAnswer ? "rgb(0, 206, 149)" : "rgb(235, 62, 104)", strokeWidth: 2 },
            questionid: questions[currentQuestion].questionId,
            connectable: true,
            answersText: answers[innerIndex].htmlTagJumbled,
            // currentQuestion
          };
          // console.log(edgeObject);
          edgeObject.connectable = true
          return edgeObject;
        });
      });
      // console.log(edgesArray, "94949449949494949");
      setEdges(edgesArray)
    //console.log("setEdges 4", edgesArray)
      return
    } else if ((ispreview && resumeBtnClicked) || (!ispreview && !resumeBtnClicked)) {
      return
    }
    // console.log("attendedMtfDl", attendedMtfDl, currentQuestion)
    var vattendedMtfDl = JSON.parse(sessionStorage.getItem("draw_line_array")) || attendedMtfDl;
    // console.log("vattendedMtfDl", vattendedMtfDl)
    if (vattendedMtfDl?.length > 0) {
      attendedMtfDl = vattendedMtfDl
    }
    const filteredArray = attendedMtfDl.filter(item => item.currentQuestion === (currentQuestion));
    const answerArray = filteredArray.map(item => item.answers);
    // console.log("=====================", answerArray, currentQuestion, filteredArray, attendedMtfDl)
    setEdges(answerArray)
  //console.log("setEdges 5", answerArray)

  }, [result]);

  const onConnect = (params) => {
  //console.log("params", params, isInteractivityEnabled, ispreview)
    if (!ispreview) {
    //console.log(edges);

      const handleConnection = () => {
        const { source, target } = params;
        const isLeftToRight = source && target;

        if (isLeftToRight) {
          const edgeToAdd = {
            ...params,
            style: { type: 'straight' },
          };

          if (isInteractivityEnabled) {
            setEdges((edges) => addEdge(edgeToAdd, edges));
          //console.log("setEdges 6", edgeToAdd)
          } else {
            // console.log("setEdges-------4444444444")
          }
        }

        var tempNodes = nodes.filter((item) => item.sourcePosition === 'left');
        var temp = answerArray;
        var TARGET = String.fromCharCode(65 + Math.round(Number(params.target) / 2) - 1);

        setAccumulatedData((prevData) => {
          const newData = { ...prevData };

          if (!newData[params.order]) {
            accumulatedData[accumulatedData.length == undefined ? Object.keys(accumulatedData).length : accumulatedData.length] = {
              ...params,
              answers: [String.fromCharCode(65 + Math.round(Number(params.target) / 2) - 1)],
              order: String.fromCharCode(65 + Math.round(Number(params.source) / 2) - 1),
              answerKey: params?.htmlTagJumbled || params?.answerKey,
              // currentQuestion
            };
          } else {
            accumulatedData[accumulatedData.length == undefined ? Object.keys(accumulatedData).length : accumulatedData.length]?.answers.push(
              String.fromCharCode(65 + Math.round(Number(params.source) / 2) - 1),

            );
            accumulatedData[accumulatedData.length == undefined ? Object.keys(accumulatedData).length : accumulatedData.length]?.answersText.push({
              answerKey: params?.htmlTagJumbled || params?.answerKey
            });
          }

          return accumulatedData;
        });
      //console.log("accumulatedData", accumulatedData)
        const accumulatedDataFinal = accumulatedData.reduce((accumulator, currentValue) => {
          const { source, target, answers } = currentValue;

          if (!accumulator[source]) {
            accumulator[source] = { ...currentValue, answers: [...answers], targetTemp: [...target], currentQuestion };
          } else {
            accumulator[source].target = [...new Set([...accumulator[source].target, String.fromCharCode(65 + Math.round(Number(target) / 2) - 1)])];
            accumulator[source].answers = [...new Set([...accumulator[source].answers, ...answers])];
            accumulator[source].targetTemp = [...new Set([...accumulator[source].targetTemp, target])];
            // accumulator[source].currentQuestion = currentQuestion
          }

          return accumulator;
        }, {});

        setEdges((edges) => {
        //console.log("edges forming here")
          const modifiedEdges = edges.map((edge) => ({
            ...edge,
            style: { ...edge.style, smoothStep: 0 },
          }));
        //console.log("setEdges 6", edges)
          return modifiedEdges;
        });

        const resultArray = Object.values(accumulatedDataFinal);

      //console.log(resultArray, '============');
        setAnswerArray(temp);
        setAttendMtfDlTemp(resultArray);

        if (!params.id) {
          params.id = `reactflow__edge-${params.source}-${params.target}`;
        }

        var obj = {
          answers: params,
          id: questions[currentQuestion].id,
          question: questions[currentQuestion],
          // currentQuestion,
          questionId: questions[currentQuestion].id,
        };

        // console.log(attendMtfDl);
        // console.log(attendedMtfDl);
        setAttendedMtfDl([...attendedMtfDl, obj]);
        sessionStorage.setItem('draw_line_array', JSON.stringify([...attendedMtfDl, obj]));
        // console.log([...attendedMtfDl, obj]);

        onSave();
      };

      if (questions[currentQuestion].clueType.code === 'EXACT_MATCH' || questions[currentQuestion].clueType === 'EXACT_MATCH') {
        var isTargetAllowed = edges.some((edge) => edge.target == params.target || edge.source == params.source);
      //console.log('isTargetAllowed', isTargetAllowed, params.target);

        if (!isTargetAllowed) {
          handleConnection();
        }
      } else {
        handleConnection();
      }
    }
  };

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
  //console.log(oldEdge, newConnection)
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  //console.log("setEdges 8", oldEdge, newConnection)
    onConnect(newConnection)
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    // console.log("onEdgeUpdateEnd", _, edge, edges)
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => {
        var val = eds.filter((e) => e.id !== edge.id);
        // console.log("val", val, attendedMtfDl)
        // const filteredData = attendedMtfDl.filter((item) => {
        //   // Check if the "answer" property exists and meets the condition
        //   if (
        //     item.answers.source === edge.source &&
        //     item.answers.target === edge.target &&
        //     item.currentQuestion === currentQuestion
        //   ) {
        //     return false; // Exclude the item from the filtered array
        //   }
        //   return true; // Include the item in the filtered array
        // });
        var vattendedMtfDl = JSON.parse(sessionStorage.getItem("draw_line_array")) || [];
        if (vattendedMtfDl?.length > 0) {
          attendedMtfDl = vattendedMtfDl
        }
        // console.log("attendedMtfDl", vattendedMtfDl, attendedMtfDl, edge)
        var filteredData = []
        attendedMtfDl.map((e) => {
          // console.log(e)
          // console.log(e.answers.source, edge.source, e.answers.target, edge.target, e.currentQuestion, currentQuestion)
          if (e.answers.source == edge.source && e.answers.target == edge.target && e.currentQuestion == currentQuestion) {
            // console.log("no push=---------------------------")
          } else {
            filteredData.push(e);
          }
        })

        // console.log(filteredData, currentQuestion, typeof currentQuestion);
        // setAttendMtfDl(filteredData)
        setAttendedMtfDl(filteredData)
        sessionStorage.setItem("draw_line_array", JSON.stringify(filteredData));
      //console.log("setEdges 9", val)
        return val
      });
    //console.log("edges", edges)
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const nodesWithProperties = nodes.map((node) => {
    // Add isConnectable flag to each node based on interactivity status
    return {
      ...node,
      draggable: false, // Set draggable based on interactivity status
      selectable: false, // Set selectable based on interactivity status
    };
  });
  useEffect(() => {
  //console.log("swesweswe", isDisplay)

    // const intervalId = setInterval(() => {
    // //console.log("called every 5 seconds")
    //   $('.react-flow__controls-button').each(function () {
    //     const buttonClasses = $(this).attr('class');
    //   //console.log(buttonClasses, buttonClasses === "react-flow__controls-button react-flow__controls-interactive", "-=-==-==-=");
    //     if (buttonClasses === "react-flow__controls-button react-flow__controls-interactive") {
    //       $(this).css('display', 'none');
    //     }
    //   });
    // }, 5000);

    // return () => clearInterval(intervalId);
    const timeoutId = setTimeout(() => {
    //console.log("called every 5 seconds")
      $('.react-flow__controls-button').each(function () {
        const buttonClasses = $(this).attr('class');
      //console.log(buttonClasses, buttonClasses === "react-flow__controls-button react-flow__controls-interactive", "-=-==-==-=");
        if (buttonClasses === "react-flow__controls-button react-flow__controls-interactive") {
          $(this).css('display', 'none');
        }
      });
    }, 100);
    return () => clearTimeout(timeoutId);

  }, [isDisplay]);

  return (
    <div id="DD-DRAWLINE">
      {
      //console.log(nodes, edges, "render part", questions[currentQuestion])
      }

      {((!ispreview && !resumeBtnClicked) || (!ispreview && resumeBtnClicked)) ? (
        questions[currentQuestion] && (
          <div>
            {<h3 className='Paragraph_title' id="az-dl-qstn-text">Question:-</h3>}
            <p className='Paragraph'
              id="az-dl-qstn"
              dangerouslySetInnerHTML={{
                __html: questions[currentQuestion].question,
              }}
            ></p>
            {/* <button onClick={toggleInteractivity}>
              {isInteractivityEnabled ? 'true' : 'false'}
            </button> */}
            <div >
            </div>
            <ReactFlow
              id="MTF_DRAW_LINE_ID"
              style={{ width: '100%', height: '60vh', border: '1px solid grey', position: 'relative' }}
              // nodes={nodes}
              nodes={nodesWithProperties}
              edges={edges}
              onNodesChange={onNodesChange}
              elementsSelectable={isInteractivityEnabled}
              panOnScroll={false}
              snapToGrid
              onEdgeUpdate={onEdgeUpdate}
              onEdgeUpdateStart={onEdgeUpdateStart}
              onEdgeUpdateEnd={onEdgeUpdateEnd}
              onConnect={onConnect}
              fitView
              attributionPosition="top-right"
              panning={{
                enabled: false,
              }}
            >

              <Controls />
            </ReactFlow>

          </div>
        )
      ) : (
        // (edges?.length > 0) &&
        <>

          {
            (ispreview && resumeBtnClicked) &&
            <div>
              {
              //console.log("currentQuestionData",currentQuestionData,questions[currentQuestion])
              }
              {currentQuestionData.question && <h3 id="az-dl-qstn-text" className='Paragraph_title'>Question:-</h3>}
              <p
                className='Paragraph'
                id="az-dl-qstn"
                dangerouslySetInnerHTML={{
                  __html: currentQuestionData.question,
                }}
              ></p>
              <div style={{ marginTop: '20px' }}>
                {questions[currentQuestion]?.explanation && <h3 id="az-dl-expl-text">Explanation</h3>}
                <p
                  id="az-dl-expl"
                  className="cqk__Qstn_content__1"
                  style={{ marginTop: '5px' }}
                  dangerouslySetInnerHTML={{
                    __html: currentQuestionData?.explanation,
                  }}
                ></p>
              </div>
            </div>
          }
          <ReactFlow
            // nodes={nodes}
            nodes={nodesWithProperties}
            edges={edges}
            onConnect={onConnect}
            onInit={setRfInstance}
            elementsSelectable={false}
            panOnScroll={false}
            fitView
            style={{ width: '100%', height: '60vh', border: '1px solid grey', cursor: 'default', ...additionalStyles }}
            // className="custom-react-flow cf1 MTF_DL_Div react-flow__controls-button react-flow__controls-interactive"
            id="az-custom-react-flow cf1 MTF_DL_Div"
            attributionPosition="top-right"
          >

            {/* {isInteractivityEnabled && <Controls />}
             */}
            <Controls />
          </ReactFlow>
        </>
      )}
    </div>
    // <div>
    //   {
    //   //console.log(nodes, edges, "render part", questions[currentQuestion])
    //   }
    //   {
    //   //console.log("render part res", resumeBtnClicked, questions[currentQuestion].matchTheFollowing, currentQuestion, attendedMtfDl)
    //   }
    //   <button onClick={toggleInteractivity}>
    //     {isInteractivityEnabled ? "true" : "false"}123
    //   </button>
    //   <ReactFlow
    //     // nodes={nodes}
    //     edges={edges}
    //     elementsSelectable={false}
    //     panOnScroll={isInteractivityEnabled}
    //     onConnect={onConnect}
    //     onInit={setRfInstance}
    //     nodes={nodesWithConnectableFlag}
    //     fitView
    //     style={{ width: '100%', height: '60vh', border: '1px solid grey' }}
    //     className="custom-react-flow cf1 MTF_DL_Div"
    //   >
    //     <Controls />
    //     {/* <DeleteEdgeDrop /> */}
    //     {
    //     //console.log("resume functionality")
    //     }
    //   </ReactFlow>

    // </div>
  );
});

export default SaveRestore;