import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Addbranchtcss from "./../css/addbranch.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
import BranchControllerGet1 from "../API/branch-controller/User_Branches_School_Admin";
import Pagination1 from "./pagination1";
import City_Controller_getall from "../API_CONTROLLER/City_Controller/City_Controller_getall_school";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import plans_controller_getall from "../API_CONTROLLER/plans-controller/plans_controller_getall";
import BranchController_post from "../API/branch-controller/User_Branches_post";
import BranchControllerIdGet from "../API/branch-controller/User_Branches_id";
import GetLastModifiedAt from "../API/branch-controller/GetLastModifiedAt";
import Branch_update_Id_put from "../API/branch-controller/Branch_update_Id_put";
import { Button } from "@mui/material";
import sortIcon from "../../../src/pages/img/sort_1.png";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import UpdatedModal from "./UpdatedModal";
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
// import { clear } from "@testing-library/user-event/dist/clear";
// import BranchDetails from "../js/data/branchDetails";
import dashHome from "../../../src/img/dashHome.svg";
import Switch from "../../components/common/Switch";

//Action Icon Image
import action_SA_Edit from "../../../src/img/Edit.svg";
import branch_Icn from "../../../src/img/branch-list.svg";

const AddbranchSchoolAdmin = () => {
  const navigate = useNavigate();
  // const [OpenPopup, setOpenPopup] = useState(false);
  // const [selectedRow, setSelectedRow] = useState();
  const [branchInputs, setbranchInputs] = useState({
    boardId: "",
    cityId: "",

    locality: "",
    // logoUrl: "",
    name: "",
    phoneNumber: "",
    schoolCode: "",
    plans: [
      {
        planId: "",
        planName: "",
      },
    ],
    pocEmail: "",
    // schoolId: "",
  });
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  // update part
  const [isEditBranch, setIsEditBranch] = useState(false);
  const [checkMapping, setCheckMapping] = useState(false);

  const prevLocation = useLocation();
  if (prevLocation.state === "edit") {
    setIsEditBranch(true);
    prevLocation.state = null;
  }
  const [updatedData, setUpdatedData] = useState();
  // const [toggleActive, setToggleActive] = useState("");
  // const token = sessionStorage.getItem("token");
  // const [filterValue, setFilterValue] = useState();
  // const [toggleMessage, settoggleMessage] = useState();
  // const [toggleConfirmation, setToggleConfirmation] = useState(false);
  // const [toggleDetails, setToggleDetails] = useState({});

  // const updateToggle = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         data: toggleDetails?.value,
  //       }
  //     )
  //     .then(function (response) {
  //       if (response?.data) {
  //         const find = branchData?.find(
  //           (item) => item.id === toggleDetails?.id
  //         );
  //         find.active = toggleDetails?.value;
  //         const clone = [...branchData];
  //         clone.forEach((data) => {
  //           data = find;
  //         });
  //         setFilterValue(clone);
  //         handleConfirmationClose();
  //         BranchControllerGet1(setBranchData, setPageDetail, rowsInput, page);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const switchHandler = async (e, id) => {
  //   setToggleDetails({});
  //   const value = e.target.checked;
  //   if (!value) {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/confirmation-api?id=${id}&operationType=TOGGLE`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     setToggleConfirmation(true);
  //     settoggleMessage(response?.data?.data?.message);
  //     setToggleDetails({ id, value });
  //   } else {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${id}/active?active=${value}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           data: value,
  //         }
  //       )
  //       .then(function (response) {
  //         if (response?.data) {
  //           const find = branchData?.find((item) => item.id === id);
  //           find.active = value;
  //           const clone = [...branchData];
  //           clone.forEach((data) => {
  //             data = find;
  //           });
  //           setFilterValue(clone);
  //           handleConfirmationClose();
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };
  // const handleConfirmationClose = () => {
  //   setToggleConfirmation(false);
  //   setToggleDetails({});
  // };

  const token = sessionStorage.getItem("token");
  const [filterValue, setFilterValue] = useState();
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [toggleDetails, setToggleDetails] = useState({});

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${toggleDetails?.id}/active?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = branchData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...branchData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          handleConfirmationClose();
          // BranchControllerGet(setBranchData, setPageDetail, rowsInput, page);
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id) => {
    setToggleDetails({});
    const value = e.target.checked;
    setToggleDetails({ id, value });
    if (value === false) {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Deactivate this Branch");
    } else {
      setToggleConfirmation(true);
      settoggleMessage("Are you sure you want to Activate this Branch");
    }
  }
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  let userRole = localStorage.getItem("role");
  useEffect(() => {
    if (isEditBranch) {
      BranchControllerIdGet(setbranchInputs);
    }
  }, [isEditBranch]);

  const [showError, setShowError] = useState("");
  const [isError, setIsError] = useState(false);
  const handlerbranchInputs = async (e) => {
    if (e.target.name === "planId") {
      const currentPlan = e.target.value.split(",");
      setbranchInputs({
        ...branchInputs,
        plans: [
          {
            planId: currentPlan[0],
            planName: currentPlan[1],
          },
        ],
      });
    } else {
      setbranchInputs({
        ...branchInputs,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name == "boardId") {
      const token = sessionStorage.getItem("token");
      const { data } = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/by-board-id?boardId=${e.target.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlan(data?.data);
      setbranchInputs(prev => ({ ...prev, boardId: data?.data }))
    }
  };
  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }

  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }

  const handleCreateBranch = (e) => {
    // e.preventDefault();
    setExpanded(false);
    for (const input in branchInputs) {
      if (branchInputs[input] === "") {
        setIsError(true);
        setShowError("Please enter all the required values");
        return;
      }
    }
    // setIsError(false);
    // other validation condtions
    if (!validateMobile(branchInputs.phoneNumber)) {
      setIsError(true);
      setShowError("Phone number invalid");
      // return;
    }
    // setIsError(false);
    if (!validateEmail(branchInputs.pocEmail)) {
      setIsError("true");
      setShowError("Please enter a valid email address");
      // return;
    }
    setIsError(false);

    BranchController_post(branchInputs, setSuccess, setShowError).then(() => {
      setSuccessCreateModal(true);
      setSuccessDisplayName(branchInputs.name);
      setExpanded(false);
      setbranchInputs("");
    });
  };
  const [success, setSuccess] = useState({});
  useEffect(() => {
    if (success.success === true) {
      // navigate("/dashboard/addbranchlist");
      BranchControllerGet1(setBranchData, setPageDetail, rowsInput, page);
    }
    setIsError(true);
  }, [success]);

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10, //should not increase
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  // pagination ends

  const [pageDetail, setPageDetail] = useState({});

  // const handleCreateBranch = (e) => {
  //   e.preventDefault();
  //   updatebranchList(e, branchInputs);

  //   navigate("/dashboard/addbranchlist");
  // };

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends
  const [schoolIdData, setSchoolIdData] = useState({});
  const [branchData, setBranchData] = useState({});
  const [cityName, setCityName] = useState({});
  const [board, setBoard] = useState([]);
  const [plan, setPlan] = useState({});
  useEffect(() => {
    SchoolControllerIdGet(setSchoolIdData);
    City_Controller_getall(setCityName);
    boards_controller_getall(setBoard);
    plans_controller_getall(setPlan);
    return () => { };
  }, []);

  // const closeModal = () => {
  //   setOpenPopup(false);
  // };
  useEffect(() => {
    BranchControllerGet1(setBranchData, setPageDetail, rowsInput, page);
    return () => { };
  }, [page, rowsInput]);

  const [Expanded, setExpanded] = useState(false);

  async function confirmBranchApi(branchid) {
    let token = sessionStorage.getItem("token");
    let result = axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/confirmation-api?id=${branchid}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCheckMapping(res.data.data.mappingExists);
        // console.log(res.data.data.mappingExists, "conAPI");
      })
      .catch((err) => console.log(err));
  }

  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false);
  const [updatedName, setUpdatedName] = useState("");
  const [SuccessDisplayName, setSuccessDisplayName] = useState("");
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
  };

  const closeModal = () => {
    setSuccesUpdatesModal(false);
  };

  const onEditBrach = (e, editedObj) => {
    e.preventDefault();
    setExpanded(true);
    setIsEditBranch(true);
    var branchid = editedObj.id;
    sessionStorage.setItem("branchid", branchid);
    setbranchInputs(prevState => ({
      ...prevState,
      boardId: editedObj?.boardId,
      cityId: editedObj?.cityId,
      locality: editedObj?.locality,
      name: editedObj?.name,
      phoneNumber: editedObj?.phoneNumber,
      schoolCode: editedObj?.schoolId,
      plans: [
        {
          planId: editedObj?.plans[0]?.planId,
          planName: editedObj?.plans[0]?.planName,
        },
      ],
      pocEmail: editedObj?.pocEmail,
    }));
    BranchControllerIdGet(setbranchInputs,);
  }
  return (
    <>
      <article>
        {/* <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>
            Resigtered Schools
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <a>Branches</a>
        </div> */}
        <div className={dashboardContentStyles.dashboard_link}>
          <div style={{ display: 'flex', position: 'relative', }}  >
            <div >
              <span
                className={dashboardContentStyles.link_icon}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={dashHome}
                  alt="no img"
                  className={dashboardContentStyles.az_menu_icon}
                // width="15px"
                // height="20px"
                // style={{ verticalAlign: "middle", marginRight: '5px' }}
                />
              </span>
            </div>
            <div>
              <span
                id="az-small"
                className={dashboardContentStyles.az_menu_text}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/dashboard/template`)

                }}
              >
                Home
              </span>

              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span
                className={`${dashboardContentStyles.az_menu_text} cursorDefault`}
              >
                Registered Schools
              </span>
              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span
                className={dashboardContentStyles.az_selected_menu_text}
              >
                Branches
              </span>

            </div>
          </div>

        </div>



        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <article className={Addbranchtcss.add_schools}>
        <div className={Addbranchtcss.taken_width}>
          <div className={Addbranchtcss.edit_schools}>
            <div className={Addbranchtcss.school_logo_jpg}>
              <img
                src={schoolIdData.logoUrl}
                style={{ width: "10vw" }}
                alt="nav logo"
                width="200"
                height="150"
              />
            </div>
            <div className={Addbranchtcss.schoolName}>
              <label for="School Name" style={{ color: "#ff9100" }}>
                School Name
              </label>
              <p>{schoolIdData.name}</p>
            </div>
            <div className={Addbranchtcss.cityName}>
              <label for="City Name" style={{ color: "#ff9100" }}>
                City Name
              </label>
              <p> {schoolIdData.city}</p>
            </div>
            <div className={Addbranchtcss.pocEmail}>
              <label for="PocEmail" style={{ color: "#ff9100" }}>
                PocEmail
              </label>
              <p> {schoolIdData.pocEmail}</p>
            </div>
            <div className={Addbranchtcss.phoneNumber}>
              <label for="PhoneNumber" style={{ color: "#ff9100" }}>
                Phone Number
              </label>
              <p>{schoolIdData.phoneNumber}</p>
            </div>
            <div className={Addbranchtcss.website}>
              <label for="Website" style={{ color: "#ff9100" }}>
                Website
              </label>
              <p>{schoolIdData.website}</p>
            </div>
            <div className={Addbranchtcss.SignatoryName}>
              <label for="Signatory Name" style={{ color: "#ff9100" }}>
                Signatory Name
              </label>
              <p>{schoolIdData.signatoryName}</p>
            </div>
            <div className={Addbranchtcss.SignatoryRole}>
              <label for="Signatory Role" style={{ color: "#ff9100" }}>
                Signatory Role
              </label>
              <p> {schoolIdData.signatoryRole}</p>
            </div>
            <div className={Addbranchtcss.schoolType}>
              {/* <label for="School type">School Type</label>
              <p>production</p> */}
            </div>

            <div>
              {/* <button
                className={Addbranchtcss.edit_button}
                id={Addbranchtcss.button_id_edit}
                type="button"
                name="edit-button"
                onClick={(e) => {
                  navigate("/dashboard/createschool");
                }}
              >
                {" "}
                <i className="fa fa-pencil"></i> <span>Edit</span>
              </button> */}
            </div>
          </div>
        </div>

        {!Expanded ? (
          <div
            className={Addbranchtcss.school_list_container}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className={Addbranchtcss.school_list_title}>
              <span className={Addbranchtcss.school_list}>
                <img
                  src={branch_Icn}
                  alt="no img"
                  style={{ verticalAlign: "middle", fontSize: "large" }}
                />
              </span>
              <span style={{ fontSize: "16px" }}> Branch List</span>
            </div>
            <div></div>
          </div>
        ) : (
          <>
            {" "}
            <div className={Addbranchtcss.add_school_title}>
              <span className={Addbranchtcss.link_icon}>
                <i className="fa-solid fa-location-pin"></i>
              </span>
              <span> Update Branch </span>
            </div>
            <form
              className={Addbranchtcss.add_schools_form}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className={Addbranchtcss.input_grid}>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="text"
                    name="name"
                    id="name"
                    value={branchInputs.name}
                    placeholder=" "
                    onChange={handlerbranchInputs}
                  />

                  <label for="name">*Branch Name</label>
                </div>
                <div className={Addbranchtcss?.input_element}>
                  <select
                    className={Addbranchtcss.form_select_element}
                    name="cityId"
                    id="cityId"
                    value={branchInputs.cityId}
                    onChange={handlerbranchInputs}
                    placeholder=" "
                  >
                    <option
                      className={Addbranchtcss.option}
                      // disabled
                      selected
                      value=""
                    >
                      Select
                    </option>

                    {cityName.length > 0 ? (
                      cityName.map((cityName, index) => {
                        return (
                          <option
                            className={Addbranchtcss.option}
                            // value={cityName.city}
                            Value={cityName.id}
                            id={cityName.id}
                          >
                            {cityName.city}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={Addbranchtcss.option}
                        // value=""
                        disabled
                        selected
                        value="default"
                      >
                        Select
                      </option>
                    )}
                  </select>
                  <label
                    for="cityId"
                    className={Addbranchtcss.label_name}
                    id={Addbranchtcss.city_name_id}
                  >
                    *Branch city
                  </label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="text"
                    name="locality"
                    id="locality"
                    value={branchInputs.locality}
                    placeholder=" "
                    onChange={handlerbranchInputs}
                  />

                  <label for="locality">*Branch Locality</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="text"
                    name="schoolCode"
                    id="schoolCode"
                    value={schoolIdData.code}
                    disabled
                    placeholder=""
                    onChange={handlerbranchInputs}
                  />
                  <label for="SchoolCode">*School Code</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="email"
                    name="pocEmail"
                    id="pocEmail"
                    Value={branchInputs.pocEmail}
                    placeholder=" "
                    onChange={handlerbranchInputs}
                  />
                  <label for="pocEmail">*POC Email</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <input
                    className={Addbranchtcss.form_input_element}
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    Value={branchInputs.phoneNumber}
                    placeholder=" "
                    onChange={handlerbranchInputs}
                  />
                  <label for="phoneNumber">*Phone Number</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <select
                    className={Addbranchtcss.form_select_element}
                    name="boardId"
                    id="boardId"
                    value={branchInputs.boardId}
                    onChange={handlerbranchInputs}
                    disabled={userRole === "SCHOOL_ADMIN" || checkMapping}
                  >
                    <option
                      className={Addbranchtcss.option}
                      // selected
                      // disabled
                      value="default"
                    >
                      Select
                    </option>
                    {board.length > 0 ? (
                      board.map((board, index) => {
                        return (
                          <option
                            className={Addbranchtcss.option}
                            // value={cityName.city}
                            Value={board.id}
                            id={board.id}
                          >
                            {board.board}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={Addbranchtcss.option}
                        // value=""
                        disabled
                        selected
                        value="default"
                      >
                        Select
                      </option>
                    )}
                  </select>
                  <label for="cityName" style={branchInputs?.boardId ? {
                    top: "-7px", color: "#fd8c00",
                    paddingRight: "0"
                  } : ""}>*Boards</label>
                </div>
                <div className={Addbranchtcss.input_element}>
                  <select
                    className={Addbranchtcss.form_select_element}
                    name="planId"
                    disabled={userRole === "SCHOOL_ADMIN" || checkMapping}
                    value={[branchInputs?.plans[0]?.planId]}
                    onChange={handlerbranchInputs}
                  >
                    <option
                      className={Addbranchtcss.option}
                      selected
                      // disabled
                      value="default"
                    >
                      Select
                    </option>
                    {plan.length > 0 ? (
                      plan.map((plan, index) => {
                        return (
                          <option
                            className={Addbranchtcss.option}
                            Value={[plan.id]}
                            id={plan.id}
                            name={plan.id}
                          >
                            {plan.plan}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={Addbranchtcss.option}
                        // value=""
                        disabled
                        selected
                        value="default"
                      >
                        Select
                      </option>
                    )}
                  </select>
                  <label for="Plan">*Plan</label>
                </div>
                <div>
                  <div className={Addbranchtcss.input_element}>
                    <input
                      className={Addbranchtcss.form_input_element}
                      type="text"
                      name="branchCode"
                      id="branchCode"
                      value={schoolIdData.branchCode}
                      // disabled
                      placeholder=""
                      onChange={handlerbranchInputs}
                    />
                    <label for="branchCode">*Branch Code</label>
                  </div>
                </div>
                <div>
                  <div
                    className={Addbranchtcss.error_box}
                    style={
                      isError
                        ? {
                          display: "block",
                          color: "red",
                        }
                        : { display: "none" }
                    }
                  >
                    {showError}
                  </div>
                </div>
                <div></div>
                <div className={Addbranchtcss.artical_button_container}>
                  <button
                    className={Addbranchtcss.artical_button_cancel}
                    id={Addbranchtcss.button_id_cancel}
                    onClick={(e) => {
                      e.preventDefault();
                      {
                        setExpanded(false);
                        isEditBranch
                          ? BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          )
                          : navigate("/dashboard/Registered%20Schools");
                      }
                    }}
                  >
                    Cancel
                  </button>
                  {isEditBranch ? (
                    <button
                      className={Addbranchtcss.artical_button}
                      id={Addbranchtcss.button_id}
                      onClick={() => {
                        Branch_update_Id_put(branchInputs, branchInputs.phoneNumber, setSuccess).then(
                          () => {
                            setSuccesUpdatesModal(true);
                            setUpdatedName(branchInputs.name);
                            BranchControllerGet1(
                              setBranchData,
                              setPageDetail,
                              rowsInput,
                              page
                            );
                            setExpanded(false);
                            setbranchInputs("");
                          }
                        );
                        setIsError(false);
                        setSuccess(true);
                        // setbranchInputs({...branchInputs});
                        // window.location.reload(true);
                        BranchControllerGet1(
                          setBranchData,
                          setPageDetail,
                          rowsInput,
                          page
                        );
                      }}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className={Addbranchtcss.artical_button}
                      id={Addbranchtcss.button_id}
                      onClick={handleCreateBranch}
                    >
                      Create Branch
                    </button>
                  )}
                </div>
              </div>

              <div className={Addbranchtcss.add_schools_btn}>
                <div className={Addbranchtcss.upload_logo_container}></div>
              </div>
            </form>
            <div className={Addbranchtcss.school_list_container}>
              <div className={Addbranchtcss.school_list_title}>
                <span className={Addbranchtcss.school_list}>
                  <img
                    src={branch_Icn}
                    alt="no img"
                    style={{ verticalAlign: "middle", fontSize: "large" }}
                  />
                </span>
                <span style={{ fontSize: "16px" }}> Branch List</span>
              </div>
            </div>
          </>
        )}

        <div className={Addbranchtcss.create_branch_list}>
          <table className={Addbranchtcss.table}>
            <thead>
              <tr>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  #
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  <div className={dashboardContentStyles.table_head}>

                    Branch name{" "}
                    <img
                      style={{ verticalAlign: "middle" }}
                      className="cursor-pointer"
                      src={sortIcon}
                      alt=""
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("name");
                        await BranchControllerGet1(
                          setBranchData,
                          setPageDetail,
                          rowsInput,
                          page,
                          "name",
                          !sortOrder
                        );
                      }}
                    />
                  </div>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branch city{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    alt=""
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("cityName");
                      await BranchControllerGet1(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "cityName",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branch locality{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    alt=""
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("locality");
                      await BranchControllerGet1(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "locality",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Boards{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    alt=""
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("board");
                      await BranchControllerGet1(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "board",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Coordinators{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    alt=""
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfCoordinators");
                      await BranchControllerGet1(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfCoordinators",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Teachers{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    alt=""
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfTeachers");
                      await BranchControllerGet1(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfTeachers",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Principals{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    alt=""
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfPrincipals");
                      await BranchControllerGet1(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Students{" "}
                  <img
                    style={{ verticalAlign: "middle" }}
                    className="cursor-pointer"
                    src={sortIcon}
                    alt=""
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("numberOfPrincipals");
                      await BranchControllerGet1(
                        setBranchData,
                        setPageDetail,
                        rowsInput,
                        page,
                        "numberOfPrincipals",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Active
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className={Addbranchtcss.data_body}>
              {branchData.length > 0 ? (
                branchData.map((branchData, index) => {
                  return (
                    <tr className={Addbranchtcss.data_row} key={index}>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        <div className={dashboardContentStyles.table_cell}>
                          {branchData.name}
                        </div>
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.cityName}
                      </td>

                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.locality}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.board}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfCoordinators}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfTeachers}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfPrincipals}
                      </td>
                      <td
                        className={`${dashboardContentStyles.table_cell} cursorPointer`}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          navigate("/dashboard/branchplan");
                          sessionStorage.setItem("branchid", branchid);
                          BranchControllerGet1(
                            setBranchData,
                            setPageDetail,
                            rowsInput,
                            page
                          );
                        }}
                      >
                        {branchData.numberOfStudents}
                      </td>
                      <td>
                        {/* {tableRow.active} */}
                        <div>
                          <Switch
                            checked={branchData?.active}
                            onChange={(e) => switchHandler(e, branchData.id)}
                          />
                        </div>
                      </td>
                      <td className={Addbranchtcss.circle}>
                        {/* {tableRow.action} */}
                        {/* <i
                          className="fa fa-pencil"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setExpanded(true)
                            e.preventDefault();
                            setIsEditBranch(true);
                            var branchid = branchData.id;
                            sessionStorage.setItem("branchid", branchid);

                            BranchControllerIdGet(setbranchInputs);
                             
                          }}
                        ></i> */}
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            onEditBrach(e, branchData)

                          }}
                          src={action_SA_Edit}
                          alt="Edit-Btn"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{
                      height: "23rem",
                    }}
                  >
                    NO DATA AVAILABLE
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* pagination container - Start */}
        <div className={Addbranchtcss.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={Addbranchtcss.rows}>
              <label for="rows">Rows per page</label>
              <select
                className="cursorPointer"
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
          {/* <branchDetails
            open={true}
            close={closeModal}
            {...branchData[selectedRow]}
          /> */}
        </div>

        {/* pagination container - End */}
        {/* <BranchDetails
          open={OpenPopup}
          close={() => {
            setOpenPopup(false)
          }}
          selectedBranch={selectedRow}
        /> */}
      </article>
      <ConfirmationModal
        open={toggleConfirmation}
        confirmationmessage={toggleMessage}
        close={handleConfirmationClose}
        updateToggle={updateToggle}
      />
      <Success_OK_Modal
        userType={"Branch"}
        userName_Modal={SuccessDisplayName}
        open={successCreateModal}
        handleOk={closeSuccessModal}
      />
      <UpdatedModal
        userType={"Branch"}
        userName_Modal={updatedName}
        open={succesUpdatesModal}
        handleOk={closeModal}
        close={closeModal}
      />
    </>
  );
};
export default AddbranchSchoolAdmin;
