import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuProps } from "./Data";
import { styled } from "@mui/material/styles";
import ViewPlanModal from "./ViewPlanModal";
import dashboardContentStyles from "../css/dashboardContent.module.css";
import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
import axios from "axios";
import TopBar from "../../components/common/TopBar";
const CancelButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  minWidth: 190,
  backgroundColor: "#f0ab35",
  borderRadius: 5,
  border: "1px solid #757575",
  borderColor: "#f0ab35",
  textTransform: "none",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#f0ab35",
  },
}));
const LearningPlanData = [
  {
    id: 1,
    student: "Pradeep",
    totalScore: "53",
    learningPlan: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
    subTopicScore: [
      {
        id: 1,
        score: "63",
      },
      {
        id: 2,
        score: "63",
      },
      {
        id: 3,
        score: "63",
      },
    ],
  },
  {
    id: 2,
    student: "Pradeep",
    totalScore: "83",
    learningPlan: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
    subTopicScore: [
      {
        id: 1,
        score: "63",
      },
      {
        id: 2,
        score: "63",
      },
      {
        id: 3,
        score: "63",
      },
    ],
  },
  {
    id: 3,
    student: "Pradeep",
    totalScore: "63",
    learningPlan: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
    subTopicScore: [
      {
        id: 1,
        score: "63",
      },
      {
        id: 2,
        score: "63",
      },
      {
        id: 3,
        score: "63",
      },
    ],
  },
  {
    id: 4,
    student: "Pradeep",
    totalScore: "30",
    learningPlan: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
    subTopicScore: [
      {
        id: 1,
        score: "63",
      },
      {
        id: 2,
        score: "63",
      },
      {
        id: 3,
        score: "63",
      },
    ],
  },
];
const LearningPlan = () => {
  const token = sessionStorage.getItem("token");
  const [viewModal, setViewModal] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const fetchSubjectData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };
  const fetchGradeData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };
  const fetchStudentData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageSize=1000`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("res student", res.data.data);
  };
  const fetchSectionData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sections/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };
  const fetchStudentByIds = async (sectionId, gradeId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageSize=1000&sortOrder=true&sectionId=${sectionId}&gradeId=${gradeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("student Data", res.data.data);
  };
  useEffect(async () => {
    if (selectedSection && selectedGrade) {
      await fetchStudentByIds(selectedSection, selectedGrade);
    }
  }, [selectedGrade, selectedSection]);
  useEffect(async () => {
    const subject = await fetchSubjectData();
    const grade = await fetchGradeData();
    const section = await fetchSectionData();
    await fetchStudentData();
    // console.log("subject", subject);
    // console.log("grade", grade);
    // console.log("section", section);
    setGradeList(grade);
    setSubjectList(subject);
    setSectionList(section);
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <TopBar
        title={"Home"}
        nav1={"TeacherDashboard"}
        modifiedDate={modifiedDate}
        subSubTitle={"Learning Plan"}
      />
      <div
        style={{
          marginTop: "10px",
          paddingRight: "33px",
          position: "absolute",
          right: "15px",
          left: "15px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
        className={dashboardContentStyles.az_dd_styling}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "1rem" }}
        >
          <FormControl
            sx={{
              minWidth: "9.3vw",
              width: "auto",
              borderRadius: 150,
            }}
          >
            <InputLabel id="demo-simple-select-label">Grade</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={<OutlinedInput label="Tag" />}
              style={{
                borderRadius: "150px",
                padding: "4px",
                background: "white",
                color: selectedGrade ? "#ffa500" : "black"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedGrade}
              onChange={(e) => setSelectedGrade(e.target.value)}
            >
              {gradeList?.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item.discription} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              minWidth: "9.3vw",
              width: "auto",
              borderRadius: 150,
            }}
          >
            <InputLabel id="demo-simple-select-label">Section</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={<OutlinedInput label="Tag" />}
              style={{
                borderRadius: "150px",
                padding: "4px",
                background: "white",
                color: selectedSection ? "#ffa500" : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              {sectionList?.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item.discription} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              minWidth: "9.3vw",
              width: "auto",
              borderRadius: 150,
            }}
          >
            <InputLabel id="demo-simple-select-label">Subject</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={<OutlinedInput label="Tag" />}
              style={{
                borderRadius: "150px",
                padding: "4px",
                background: "white",
                color: selectedSubject ? "#ffa500" : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              {subjectList?.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item.subject} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            padding: "10px",
            overflow: "hidden",
          }}
        >
          <TableContainer sx={{ maxHeight: 710, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "rgb(64,59,151)",
                      color: "#fff",
                    }}
                  >
                    S. No
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      background: "rgb(64,59,151)",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Student
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      background: "rgb(64,59,151)",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Total Score
                  </TableCell>
                  <TableCell
                    align="inherit"
                    style={{
                      fontWeight: 600,
                      background: "rgb(64,59,151)",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Learning Plan
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      background: "rgb(64,59,151)",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Subtopic Score
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      background: "rgb(64,59,151)",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {LearningPlanData?.map((data) => {
                  return (
                    <TableRow
                      key={data.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "black",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          background: "rgba(235,234,234,0.58)",
                          fontWeight: "600",
                        }}
                      >
                        {data.id}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        style={{
                          background: "rgba(235,234,234,0.58)",
                          fontWeight: "600",
                        }}
                      >
                        {data.student}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: "rgba(235,234,234,0.58)",
                          fontWeight: "600",
                        }}
                      >
                        <div
                          style={{
                            background:
                              data.totalScore < 39
                                ? "rgba(255,133,133,0.81)"
                                : data.totalScore < 59
                                  ? "rgba(255,218,207,0.81)"
                                  : data.totalScore < 79
                                    ? "rgba(193,255,159,0.81)"
                                    : "rgba(179,218,254,0.81)",
                            padding: "10px",
                            borderRadius: "5px",
                            width: "60px",
                            textAlign: "center",
                          }}
                        >{`${data.totalScore}%`}</div>
                      </TableCell>
                      <TableCell
                        align={"left"}
                        style={{
                          background: "rgba(235,234,234,0.58)",
                          fontWeight: "600",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          {data.learningPlan.map((item) => {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "1rem",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                                key={item.id}
                              >
                                <div
                                  style={{
                                    fontWeight: "600",
                                    minWidth: "100px",
                                  }}
                                >
                                  {item.subject}
                                </div>
                                <TextField
                                  type={"text"}
                                  focused
                                  value={item.value}
                                  sx={{
                                    background: "#fff",
                                    width: "100%",
                                    color: "blue",
                                    // border: "2px solid blue",
                                    borderRadius: "5px",
                                  }}
                                  color={"info"}
                                />
                                <CancelButton
                                  onClick={() => {
                                    setPlanData(data?.learningPlan);
                                    setViewModal(true);
                                  }}
                                >
                                  View Plans
                                </CancelButton>
                              </div>
                            );
                          })}
                        </div>
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: "rgba(235,234,234,0.58)",
                          fontWeight: "600",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          {data.subTopicScore.map((score) => {
                            return (
                              <div
                                style={{
                                  background: "rgba(181,237,255,0.71)",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                key={score.id}
                              >
                                {`${score.score}%`}
                              </div>
                            );
                          })}
                        </div>
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: "rgba(235,234,234,0.58)",
                          fontWeight: "600",
                        }}
                      >
                        <CancelButton>Update</CancelButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1rem",
              padding: "10px",
            }}
          >
            <div style={{ background: "red", padding: "8px" }} />
            <div>Ember (0 - 39%)</div>
            <div style={{ background: "rgb(253 147 95)", padding: "8px" }} />
            <div>Kindle (40 - 59%)</div>
            <div style={{ background: "green", padding: "8px" }} />
            <div>Glow (60 - 79%)</div>{" "}
            <div style={{ background: "#0492e2", padding: "8px" }} />
            <div>Sparkle (80 - 100%)</div>
          </div>
        </Paper>
        <ViewPlanModal
          open={viewModal}
          close={() => setViewModal(false)}
          data={planData}
        />
      </div>
    </div>
  );
};
export default LearningPlan;
