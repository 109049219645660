import React , {useState} from "react";
import { useNavigate } from "react-router-dom";
import choosestyles from "./../css/choosePlan.module.css"
import sureImage from "./../img/error-svgrepo-com.svg";

// import ImageUploading from "./uploadImage";





const ScienceTable = (props) => {
  
  const PlanList = props.PlanList;
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};
const SocialScienceTable = (props) => {
  
  const PlanList = props.PlanList;
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const EVSTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const EnglishTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const MathematicsTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const ComputerTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const CharacterDevelopmentTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const EnrichmentTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const Term1Table = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const  CCATable= (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const GrammarTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};

const TeluguTable = (props) => {
  
  const PlanList = props.PlanList;
  
  return (
    <>
      <div className={choosestyles.table_plan_div}>
        <table className={choosestyles.table_plan}>
          <thead>
            <tr className={choosestyles.Table_head1}>
              <td id={choosestyles.Table_head_items1} colspan="2"></td>
              <td id={choosestyles.Table_head_items2} colspan="8">
                Teacher<i className="fa fa-angle-down"></i>
              </td>
              <td id={choosestyles.Table_head_items3} colspan="2">
                Student
              </td>
            </tr>
          </thead>

          <thead>
            <tr className={choosestyles.Table_head2}>
              <td className={choosestyles.Table_head_items} >Grade</td>
              <td className={choosestyles.Table_head_items} >Boards</td>
              <td className={choosestyles.Table_head_items} >
                Lesson Plan
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision Module
              </td>
              <td className={choosestyles.Table_head_items}>
                RM lock/unlock visible
              </td>
              <td className={choosestyles.Table_head_items}>
                Assessment Module
              </td>
              <td className={choosestyles.Table_head_items}>
                Activities
              </td>
              <td className={choosestyles.Table_head_items}>
                WS download
              </td>
              <td className={choosestyles.Table_head_items}>
                WB Download
              </td>
              <td className={choosestyles.Table_head_items}>
                News & stories
              </td>
              <td className={choosestyles.Table_head_items}>
                Student booklet
              </td>
              <td className={choosestyles.Table_head_items}>
                Revision module{" "}
              </td>
            </tr>
          </thead>
          <tbody className={choosestyles.plan_body}>
                {PlanList.map((tableRow, index) => {
                    return (
                      <tr key={index}>
                        <td className={choosestyles.body_data}>{tableRow.Grade}</td>
                        <td className={choosestyles.body_data1}>{tableRow.Board}</td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.LessonPlan} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleTeacher} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RmLock} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.AssessmentModule} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.Activities} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WSdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.WBdownload} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.News} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.StudentBooklet} />
                        </td>
                        <td className={choosestyles.checkbox_data}>
                          <input type="checkbox"  checked= {tableRow.RevisionModuleStudent} />
                        </td>


                      </tr>
                    );})}
                                    
                                    
                                
          </tbody>
        </table>
      </div>
    </>
  );
};






const ChoosePlan =(props)=>{
    const navigate = useNavigate();
    const [PlanName,setPlanName] = useState('');
    const [showModalView, setShowModalView] = useState(false);
    const [showSelectModal, setShowSelectModal] = useState(false);
    const [showScienceTable, setShowScienceTable] = useState(false);
    const [showSocialScienceTable, setShowSocialScienceTable] = useState(false);
    const [showEVSTable, setShowEVSTable] = useState(false);
    const [showEnglishTable, setShowEnglishTable] = useState(false);
    const [showMathematicsTable, setShowMathematicsTable] = useState(false);
    const [showComputerTable, setShowComputerTable] = useState(false);
    const [showCharacterDevelopmentTable, setShowCharacterDevelopmentTable] = useState(false);
    const [showEnrichmentTable, setShowEnrichmentTable] = useState(false);
    const [showTerm1Table, setShowTerm1Table] = useState(false);
    const [showCCATable, setShowCCATable] = useState(false);
    const [showGrammarTable, setShowGrammarTable] = useState(false);
    const [showTeluguTable, setShowTeluguTable] = useState(false);
    const PlanList = props.PlanList;


    function showHide(e) {
      if (e.target.id === "1stTable"){
      setShowScienceTable(showScienceTable ? false : true);
      }
      if (e.target.id === "2ndTable"){
      setShowSocialScienceTable(showSocialScienceTable ? false : true);
      }
      if (e.target.id === "3rdTable"){
        setShowEVSTable(showEVSTable ? false : true);
        }
      if (e.target.id === "4thTable"){
        setShowEnglishTable(showEnglishTable ? false : true);
       }
       if (e.target.id === "5thTable"){
        setShowMathematicsTable(showMathematicsTable ? false : true);
     }
     if (e.target.id === "6thTable"){
      setShowComputerTable(showComputerTable ? false : true);
   }
   if (e.target.id === "7thTable"){
    setShowCharacterDevelopmentTable(showCharacterDevelopmentTable ? false : true);
      }
      if (e.target.id === "8thTable"){
        setShowEnrichmentTable(showEnrichmentTable ? false : true);
      }
      if (e.target.id === "9thTable"){
        setShowTerm1Table(showTerm1Table ? false : true);
      }
      if (e.target.id === "10thTable"){
        setShowCCATable(showCCATable ? false : true);
      }
      if (e.target.id === "11thTable"){
        setShowGrammarTable(showGrammarTable ? false : true);
      }
      if (e.target.id === "12thTable"){
        setShowTeluguTable(showTeluguTable ? false : true);
      }


     }

    const handleViewModal = (e) => {
        setPlanName(e.target.parentNode.children[1].textContent);        
        setShowModalView(true);
        
      }

      const handleViewCrossModal = (e) => {
        setShowModalView(false);
      }
    const handleSelectModal = (e) => {
      setPlanName(e.target.parentNode.parentNode.parentNode.children[0].textContent); 
      setShowSelectModal(true);
      setShowModalView(false);
    }

    

    const handlePlanSelectNo = (e) => {
      setShowSelectModal(false);
      // navigate("/dashboard/addBranch14");
    }

    const handlePlanSelectYes = (e) => {
      navigate("/dashboard/branchplan");
      setShowSelectModal(false);
    }
    

      
    return (
        <>
        <article className={choosestyles.article_add_school_plan}>
      <div className={choosestyles.Grid_school_name_branch}>
        <div className={choosestyles.school_name}>
          <i
            id={choosestyles.down_arrow_icon}
            className="fa-solid fa-house-user"
            aria-hidden="true"
          ></i>
          AZVASA Education
        </div>
        <button className={choosestyles.btn_branch_name}>
          AZVASA Mumbai Branch<i className="fa-solid fa-chevron-down"></i>
        </button>
      </div>

    

      <div className={choosestyles.grid_container_header_plan}>
        <div className={choosestyles.item_plan1}
        onClick={(e) => {
          e.preventDefault();
          navigate("/dashboard/branchplan");
        }}>Plan</div>
        <div
          className={choosestyles.item_plan2}
          onClick={(e) => {
            e.preventDefault();
            navigate("/dashboard/branchsection");
          }}
        >
          Sections
        </div>
        <div
          className={choosestyles.item_plan3}
          onClick={(e) => {
            e.preventDefault();
            navigate("/dashboard/teacherId");
          }}
        >
          Teacher ID's
        </div>
        <div className={choosestyles.item_plan4}onClick={(e) => {
            e.preventDefault();
            navigate("/dashboard/csp");
          }}>Students</div>
        <div className={choosestyles.item_plan5}onClick={(e) => {
            e.preventDefault();
            navigate("/dashboard/student");
          }}>Change Student Profile</div>
        <div className={choosestyles.item_plan6}onClick={(e) => {
            e.preventDefault();
            navigate("/dashboard/token");
          }}>Tokens</div>
        <div className={choosestyles.item_plan7}
        onClick={(e) => {
          e.preventDefault();
          navigate("/dashboard/logo");
        }}
        >Logo</div>
      </div>
      <div className={choosestyles.Your_plan}>
       <span className={choosestyles.Your_plan_head}>Choose Your Plan</span>   
        <ul  className={choosestyles.Your_plan_list}>
        <li  className={choosestyles.plan_items}><input type="radio"
                            name="Plan"
                            id="Plan1"
                             
                            defaultChecked ="checked"
                            className={choosestyles.radio_active}
                            placeholder=" " 
                            value=" "
                            // value={TeacherInputs.role}
                            // onClick={ChangeButtonHandler}
                            // onChange={handlerTeacherInputs}
                            />
                          <label for="test_teacherid">Plan 1</label>
                          <span className={choosestyles.View} onClick={handleViewModal} >View Plan</span>
                          </li>
        <li  className={choosestyles.plan_items}><input type="radio"
                            name="Plan"
                            id="Plan2"
                             
                            // defaultChecked ="checked"
                            className={choosestyles.radio_active}
                            placeholder=" " 
                            
                            />
                          <label for="Plan2">Plan 2</label>
                          <span className={choosestyles.View} onClick={handleViewModal} >View Plan</span>
                          </li>
        <li  className={choosestyles.plan_items}><input type="radio"
                            name="Plan"
                            id="Plan3"
                             
                            // defaultChecked ="checked"
                            className={choosestyles.radio_active}
                            placeholder=" " 
                            
                            />
                          <label for="Plan3">Plan 3</label>
                          <span className={choosestyles.View}  onClick={handleViewModal}>View Plan</span>
                          </li>
        <li  className={choosestyles.plan_items}><input type="radio"
                            name="Plan"
                            id="Plan4"
                             
                            // defaultChecked ="checked"
                            className={choosestyles.radio_active}
                            placeholder=" " 
                            
                            />
                          <label for="Plan4">Plan 4</label>
                          <span className={choosestyles.View}  onClick={handleViewModal}>View Plan</span>
                          </li>
        <li  className={choosestyles.plan_items}><input type="radio"
                            name="Plan"
                            id="Teacher"
                             
                            // defaultChecked ="checked"
                            className={choosestyles.radio_active}
                            placeholder=" " 
                            
                            />
                          <label for="Plan5">Plan 5</label>
                          <span className={choosestyles.View}  onClick={handleViewModal}>View Plan</span>
                          </li>
        </ul>
        <div className={choosestyles.select}>
        <span  className={choosestyles.change_notic}><i className="fa-solid fa-triangle-exclamation"></i> You cannot <span className={choosestyles.change_Yellow}>Change</span> after plan selection</span>
        <div className={choosestyles.ctn}>
                    <button className={choosestyles.select_button}>SELECT</button>
                  </div>
        </div>    
          
      </div>
      {/* ============= ChoosePlan modal starts ==================== */}
      <div className={choosestyles.flex_container_teacherid} id={choosestyles.modal_teacherid} 
        style={showModalView ? { display: "flex" } : { display: "none" }}>
            <div className={choosestyles.main_content_teacherid } id={choosestyles.modal_content_teacherid}>
              <div className={choosestyles.plan_select_list}>
                  <span  className={choosestyles.plan_select_head}>{PlanName}</span>
                  <div className={choosestyles.plan_select_btn_box}>
                    <div className={choosestyles.plan_select_btn} onClick={handleSelectModal}>
                        <button className={choosestyles.select_plan_button} 
                        
                        >SELECT</button>
                    </div>
                    <i onClick={handleViewCrossModal} className="fa-solid fa-xmark"></i>

                  </div>
              </div>
                        <ul className={choosestyles.plan_links}>
                  <div className={choosestyles.plan_blank}>h</div>
                  <li className={choosestyles.plan_links_items} 
                  id="1stTable"
                  onClick={showHide}
                  >

                    <a className={choosestyles.plan_container_link} id="1stTable" >
                      
                      <span className={choosestyles.plan_link_text}> Science </span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>

                  {showScienceTable && <ScienceTable PlanList = {PlanList} />}


                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="2ndTable"
                  >
                    <div className={choosestyles.plan_container_link} id="2ndTable" >
                      
                      <span className={choosestyles.plan_link_text}>Social Science</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </div>
                  </li>
                  {showSocialScienceTable && <SocialScienceTable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="3rdTable"
                  >
                    <a className={choosestyles.plan_container_link} id="3rdTable">
                      
                      <span className={choosestyles.plan_link_text}>EVS</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showEVSTable && <EVSTable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="4thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="4thTable">
                      
                      <span className={choosestyles.plan_link_text}>English</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showEnglishTable && <EnglishTable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="5thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="5thTable">
                      
                      <span className={choosestyles.plan_link_text}>Mathematics</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showMathematicsTable && <MathematicsTable PlanList = {PlanList} />}

                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="6thTable"
                  >
                    <a className={choosestyles.plan_container_link}  id="6thTable">
                      
                      
                      <span className={choosestyles.plan_link_text}>Computer</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showComputerTable && <ComputerTable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="7thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="7thTable">
                      
                      <span className={choosestyles.plan_link_text}>
                        Character Development
                      </span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showCharacterDevelopmentTable && <CharacterDevelopmentTable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="8thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="8thTable">
                      
                      <span className={choosestyles.plan_link_text}>Enrichment</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showEnrichmentTable && <EnrichmentTable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="9thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="9thTable">
                      
                      <span className={choosestyles.plan_link_text}>
                        Team 1-Preboards
                      </span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showTerm1Table && <Term1Table PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="10thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="10thTable" >
                      
                      <span className={choosestyles.plan_link_text}>CCA</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showCCATable && <CCATable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="11thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="11thTable">
                      
                      <span className={choosestyles.plan_link_text}>Grammar</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showGrammarTable && <GrammarTable PlanList = {PlanList} />}
                  <li className={choosestyles.plan_links_items}
                  onClick={showHide}
                  id="12thTable"
                  >
                    <a className={choosestyles.plan_container_link} id="12thTable">
                      
                      <span className={choosestyles.plan_link_text}>Telugu</span>
                      <span className={choosestyles.link_dropdown}>
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                  </li>
                  {showTeluguTable && <TeluguTable PlanList = {PlanList} />}
                  <li></li>
                </ul>
            </div>
          </div>

          {/* modal Begin */}
      <div
        className={choosestyles.flex_container}
        id={choosestyles.modal}
        style={showSelectModal ? { display: "flex" } : { display: "none" }}
      >
        <div
          className={choosestyles.main_content}
          id={choosestyles.modal_content}
        >
          <span>
            <img src={sureImage} alt="red checkmark" />
          </span>
          
          <h5>Are you sure you want select plan</h5>
          <p>"{PlanName}"</p>
          <div className={choosestyles.modal_buttons}>
            <div className={choosestyles.buttons_box_1}>
            <button
              className={choosestyles.choose_button_1}
              id={choosestyles.choose_button_id_1}
              onClick={handlePlanSelectNo}
            >
              No
            </button>
            </div>
            <div className={choosestyles.buttons_box_1}>
            <button
              className={choosestyles.choose_button_2}
              id={choosestyles.choose_button_id_2}
              onClick={handlePlanSelectYes}
            >
              YES
            </button>
            </div>
          </div>
        </div>
      </div>
      {/* modal End */}


    </article>
        </>
    );
}
 export default ChoosePlan;