import React, { useState,useEffect } from 'react'
import dashboardContentStyles from '../../../../pages/css/dashboardContent.module.css'
import dashHome from '../../../../../src/img/dashHome.svg'
import { filterStyle, labelStyle,formStyle, selectStyle, topBarContainer, topContainerLeft, topContainerRight } from './styles'
import GetLastModifiedAt from '../../../API/school-controller/GetLastModifiedAt'

const Topbar = ({
  title,
  selectedGrade,
  setSelectedGrade,
 
}) => {

const [modifiedDate, setModifiedDate] = useState({});
useEffect(() => {
  GetLastModifiedAt(setModifiedDate);
  return () => {};
}, []);

useEffect(()=>{
  // console.log(modifiedDate,'modifiedDate')

},[modifiedDate])
  return (
    <article style={topBarContainer}>
      <div style={topContainerLeft}>
        <span>
          <img
            src={dashHome}
            alt='no img'
            width='15px'
            height='20px'
            style={{ verticalAlign: 'middle',marginRight:"8px" }}
          />
        </span>
        <span  className='breadCrumbs'> Home >> <p style={{color:'#001AF3'}}> Dashboard</p> </span>
      </div>
      <div style={topContainerRight}>
     
      <div >
        <p className='last-update'>
          Last Update:{' '} 
          {modifiedDate?.data?.length ? modifiedDate.data :null}
        </p>
      </div>
      </div>
    </article>
  )
}
export default Topbar
