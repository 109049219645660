import React, { useState, useEffect } from "react";
import axios from "axios";

// Chart
import { Bar } from "react-chartjs-3";
import { Doughnut } from "react-chartjs-3";

//Css Pages
import "../../css/Student/Student_Enrich_Quiz_Analysis_Modal.css";

// Images Used
import thumb_star from "../../../../src/img/rate-removebg.png";
import clock from "../../../../src/img/clock.png";

import StarIcon from '@mui/icons-material/Star';

// Mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChapterQuizKeyModal from "./ChapterQuizKey/ChapterQuizKeyModal";
import Tooltip from '@mui/material/Tooltip';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/system';
import { Link } from "react-router-dom";
import Student_Enrich from "../Student/Student_Enrich"
import mixpanel from "mixpanel-browser";

const StyledRating = styled(Rating)({
  '.css-34he1w-MuiRating-decimal:first-of-type': {
    transform: 'translate(3px, 15px)',
  },
  '.css-34he1w-MuiRating-decimal:nth-child(2)': {
    transform: 'translate(0px, 5px)',
  },
  '.css-34he1w-MuiRating-decimal:nth-child(3)': {
    transform: 'translate(0px, 0px)',
  },
  '.css-34he1w-MuiRating-decimal:nth-child(4)': {
    transform: 'translate(0px, 5px)',
  },
  '.css-34he1w-MuiRating-decimal:nth-child(5)': {
    transform: 'translate(3px, 15px)',
  },
});

const modal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',

};

function Student_Formal_Assesment_Analysis_Modal(props) {
  
  // console.log("Student_Enrich_Quiz_Analysis_Modal props", props)
  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "auto",
    // bgcolor: "background.paper",
    backgroundColor: "white",
    // border: "2px solid #000",
    // borderRadius: "10px",
    // boxShadow: 24,
    p: 2,
  };
  const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: "white",

  };
  const modalContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',  // 100% of the viewport height
    width: '100vw',   // 100% of the viewport width
    backgroundColor: 'white',
    marginBottom: "-50px",  // Set margin to zero

  };

  const handleClose = () => {
    //console.log("close modal called")
    // alert("handleAnalysisModalClose ")
    // props.closeModal();
    // props.closeModall();
    sessionStorage.removeItem("exIdTwo");
    sessionStorage.removeItem("exIdOne");
  };
  const chapterQuizKey = sessionStorage.getItem("chapterQuizKeyBtn");
  var Vquiztype = sessionStorage.getItem("quiztype")
  const [list, setList] = useState({});

  const token = sessionStorage.getItem("token");
  const user_id = localStorage.getItem('id');
  const userName = localStorage.getItem('userName')
  const quizCardData = JSON.parse(sessionStorage.getItem("quizData"))
  const gradeId = sessionStorage.getItem("gradeId");
  const sectionId = sessionStorage.getItem("sectionId");
  const branchId = sessionStorage.getItem("branchId");
  const schoolId = sessionStorage.getItem("schoolId");
  const selectedSubject = sessionStorage.getItem("selectedSub");
  const studentId = sessionStorage.getItem("studentId");

  const [rating, setRating] = useState(0);
  const [realTimeQuizData, setRealTimeQuizData] = useState();
  const [doughtnutData, setDataForDoughnut] = useState();
  const [barLabels, setBarLabels] = useState();
  const [barPercentage, setBarPercentage] = useState();
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [chapterQuizKeyString, setchapterQuizKeyString] = useState(Vquiztype == 'practice' ? false : JSON.parse(chapterQuizKey));


  //-------------Doughnut Chart Dummy--------------------------
  const data = {
    labels: ["Red", "Green", "Yellow"],
    datasets: [
      {
        // label: "My First Dataset",
        data: doughtnutData,
        backgroundColor: ["#00CE95", "#EB3E68", "#636A86AD"],
        borderWidth: 0,
      },
    ],
    text: "",
  };
  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "My Doughnut Chart",
      },
      position: "start",
      datalabels: false,
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    cutoutPercentage: 60,
    tooltips: false,
  };
  //-------------Doughnut Chart Dummy Datas And Style--------------------------

  //--------------------Bar Chart Dummy Datas and Style--------------------------
  const dataBar = {
    labels: barLabels,
    datasets: [
      {
        label: "Difficulty",
        data: barPercentage,
        backgroundColor: ["#58cced", "#3895d3", "#1261a0"],
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
    ],
  };

  const optionsBar = {
    responsive: true,
    scales: {
      yAxes: [
        {
          stacked: false,
          ticks: {
            min: 0,
            max: 100,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: true,
            min: 0,
            max: 100,
            fontSize: 13,
            fontFamily: "Helvetica, Regular",
            fontColor: "#4988A6",
          },
          gridLines: {
            display: true,
            drawBorder: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            return Math.round(value) + "%";
          }
        },
        display: true,
        color: "#FFFFFF",
        align: "center",
        anchor: "center",
        font: { size: "13", weight: 600 },
      },
    },
    legend: {
      display: false,
    },
  };

  //--------------------Bar Chart Dummy Datas and Style--------------------------

  const exIdOne = sessionStorage.getItem("exIdOne");
  const exIdTwo = sessionStorage.getItem("exIdTwo");
  const examiD = sessionStorage.getItem("examiD");
  const examId1 = JSON.parse(sessionStorage.getItem("examId")) || props.examId || examiD;
  // console.log('exxxxxxx',examId1)

  const fetchRealTimeQuizGraph = async () => {
    const exIdTwo = sessionStorage.getItem("exIdTwo");
    // const examId = exIdTwo ? exIdTwo : exIdOne;
    var slctdSUB = sessionStorage.getItem("selectedSubject");
    // console.log("exIdTwo", exIdTwo, slctdSUB)
    const examId = examId1 
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/real-time-quiz-graph?examId=${examId}&schoolId=${schoolId}&studentId=${studentId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject || slctdSUB}&reportPage=false`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log(res?.data)
    return res?.data || [];
  };

  const [showModal, setShowModal] = useState(false);
  const [closeeeModal, setCloseeeModal] = useState(false);

  useEffect(() => {
    const realTimeQuizGraphFetch = async () => {
      const realTimeQuizGraphFetchData = await fetchRealTimeQuizGraph();
      // console.log('fgfgf',realTimeQuizGraphFetchData);
      setRealTimeQuizData(realTimeQuizGraphFetchData.data);
      setRating(realTimeQuizGraphFetchData.data.rating)
      setDataForDoughnut(
        realTimeQuizGraphFetchData &&
        realTimeQuizGraphFetchData.data.accuracyAnalysis.map((item) => {
          return item.accuracyPercentage;
        })
      );
      setBarLabels(
        realTimeQuizGraphFetchData &&
        realTimeQuizGraphFetchData.data.difficultyAnalysis.map((item) => {
          return item.taxonomyCategory;
        })
      );
      setBarPercentage(
        realTimeQuizGraphFetchData &&
        realTimeQuizGraphFetchData.data.difficultyAnalysis.map((item) => {
          return item.percentage;
        })
      );
    };
    setTimeout(() => {
      realTimeQuizGraphFetch();
    }, 3000);
    // realTimeQuizGraphFetch();
  }, []);
  useEffect(()=>{
    const handlePopstate = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopstate);
    window.history.pushState(null, document.title, window.location.href);
    return ()=>{
      window.removeEventListener('popstate', handlePopstate);

    }
  },[])

  const handleChapterQuizKey = () => {
    //console.log("handleChapterQuizKey called")
    setShowModal(true);
  };

  const closeModal = () => {
    setCloseeeModal(true)
    setShowModal(false);
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';
  //     event.returnValue = message;
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
const chapterQuizKeyClicked = () =>{
    mixpanel.track('FAQ_ChapterKey',{
      "user_id":user_id,
      "quizId":quizCardData[0]?.quizId,
      "quizName":quizCardData[0]?.quizName,
      "releaseId":quizCardData[0]?.releasedId,
      "startDate":quizCardData[0]?.startDate,
      "startTime":quizCardData[0]?.startTime,
      "examId":examId1
    })
  }


  return (
    <>
      {
        //console.log("propsssssssssssss",props,showModal)
      }
      <Modal id="QuestionModalPopup" style={{ backgroundColor: "white" }}
        // open={props.showAnalysisModal}
        open={true}
        onClose={closeeeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ backgroundColor: "white" }} sx={modalContentStyle}>
          <div
            style={{
              color: "red",
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "white"
            }}
          >
            {/* <CloseIcon
                onClick={() => handleClose()}
                style={{ fontSize: "15px" }}
              /> */}
          </div>
          {/*-----------| Header div |---------*/}
          <div className="Header_div">
            <div>
              <h5>{realTimeQuizData && realTimeQuizData.chapter}</h5>
            </div>

            <div className="Header_Img_Time_Score">
              <div className="Img_Score_div">
                <div>
                  <h6>
                    <StyledRating name="half-rating-read" value={rating} precision={0.25} readOnly size="small" />
                  </h6>
                  <img src={thumb_star} />
                  <h6>
                    Score:{" "}
                    {/* {realTimeQuizData && realTimeQuizData.totalMarkPercentage}% */}
                    {realTimeQuizData && realTimeQuizData?.accuracyAnalysis[0]?.accuracyPercentage}%
                  </h6>
                </div>
              </div>

              <div className="Time_and_details">
                <div className="clock_img">
                  <img src={clock} width="28px" height="28px" />
                </div>

                <div className="Time_div">
                  <h6>
                    Time Taken:{" "}
                    {realTimeQuizData && realTimeQuizData.timeTaken}
                  </h6>
                  <h6>
                    Total Time:{" "}
                    {realTimeQuizData && realTimeQuizData.duration}Mins
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <Divider sx={{ width: "100%" }} />

          <div className="Charts_Wrapper">
            <div className="Charts_Headings">
              <div>Accuracy Analysis</div>
              <div>Difficulty Analysis</div>
            </div>
            {/*-----------| Charts div |---------*/}
            <div className="Chart_div">
              <div>
                <div className="DNut_details">
                  <div className="DnutChart">
                    <Doughnut
                      data={data}
                      legend={false}
                      options={options}
                      width="180px"
                      height="200px"
                    />
                  </div>
                  <div className="details">
                    {realTimeQuizData &&
                      realTimeQuizData.accuracyAnalysis &&
                      realTimeQuizData.accuracyAnalysis.map((item, index) => {
                        return (
                          <div>
                            <h5>{item.accuracyPercentage}%</h5>
                            <h6>
                              <span className={`dot${index + 1}`}></span>
                              {item.accuracyStatus} | {item.accuracyCount} out
                              of {realTimeQuizData?.totalMarks}
                            </h6>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div>
                <Bar
                  data={dataBar}
                  options={optionsBar}
                  width={320}
                  height={195}
                />
              </div>
            </div>

            {/*-----------| Analysis div |---------*/}
            {realTimeQuizData && realTimeQuizData.caseStudyAnalysis && (
              <div className="Analysis_div_parent">
                {realTimeQuizData &&
                  realTimeQuizData.caseStudyAnalysis &&
                  realTimeQuizData.caseStudyAnalysis.map((item, index) => {
                    return (
                      <div className="Analysis_div">
                        <div className="Analysis_head">
                          <h6>
                            {item.caseStudyTitle}- Analysis{" "}
                            <span className="info">&#9432;</span>
                          </h6>
                        </div>

                        <div className="Analysis_Details">
                          {item.subQueByTaxonomy &&
                            item.subQueByTaxonomy.map((itm, ind) => {
                              return (
                                <div className="detail_1">
                                  <h6>
                                    {itm.taxonomy} - {itm.attendanceCount} out
                                    of {itm.actualSubQuesCount}
                                  </h6>
                                  <h6>{itm.attendancePercentage}%</h6>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            
            {/*-----------| Button div |---------*/}
            <div className="Analysis_Btn">
              {chapterQuizKeyString ?
                // <Tooltip title="Chapter Quiz Key enabled only after the quiz time is over" placement="top">

                <Tooltip title="This button get enabled only after the quiz is over" placement="top">
                  <div style={{ display: 'flex', gap: '10px', cursor: "pointer" }} >

                    <button
                      id={`az-quizkey-btn`}
                      onClick={() => {

                      chapterQuizKeyClicked()
                        // alert("btn clicked")
                        // handleChapterQuizKey(); //actual code
                        var quizzData = JSON.parse(sessionStorage.getItem("itemload"));
                        //console.log("quizzDataxxxx", quizzData, btnDisabled)
                        if (quizzData) {
                          const inputTime = quizzData.endTime;
                          // Parse the input time string
                          const [hourMinute, ampm] = inputTime.split(" ");
                          const [hour, minute] = hourMinute.split(":");
                          let targetHour = parseInt(hour, 10);
                          if (ampm === "PM" && targetHour !== 12) {
                            targetHour += 12; // Convert to 24-hour format
                          } else if (ampm === "AM" && targetHour === 12) {
                            targetHour = 0; // Midnight
                          }
                          const targetMinute = parseInt(minute, 10);
                          const targetTime = new Date();
                          targetTime.setHours(targetHour);
                          targetTime.setMinutes(targetMinute);
                          targetTime.setSeconds(0);
                          const currentTime = new Date();
                          // Compare the current time with the target time
                          if (Vquiztype == 'practice') {
                            handleChapterQuizKey();
                          } else {
                            if (currentTime < targetTime) {
                              //console.log("Current time is earlier than1 " + inputTime);
                              setchapterQuizKeyString(true);
                            } else {
                              //console.log("Current time is equal to or later than1 " + inputTime);
                              setchapterQuizKeyString(false);
                              setBtnDisabled(false)
                              handleChapterQuizKey();
                            }
                          }
                        } else {
                          // alert("else called")
                          handleChapterQuizKey();
                        }
                      }}
                      disabled={false}
                    >
                      Chapter Quiz Key
                    </button>
                    <Link  className="linkExit" to={"../formal%20assessment%20quiz"} element={Student_Enrich}>
                    <button
                    className="exitBtnn"
                      id={`az-close-btn`}
                      style={{ backgroundColor: "#959aad", }}
                      onClick={() => {
                        // window.location.reload()
                        sessionStorage.removeItem("Vquiztype");
                        // handleClose();
                        closeModal()
                        sessionStorage.removeItem("itemload")
                        //added on 7-3-24
                        sessionStorage.removeItem("viewQuizData");
                        sessionStorage.removeItem("practiceQuizAnswer");
                        sessionStorage.removeItem("drag_drop_array");
                        sessionStorage.removeItem("drag_drop_array_final");
                        sessionStorage.removeItem("shuffledQuiz");
                        sessionStorage.removeItem("alltheQuizAnswer");
                        sessionStorage.removeItem("exid");
                        sessionStorage.removeItem("case_help");
                      }}
                    >
                      Exit
                    </button>
                    </Link>
                  </div>
                </Tooltip>

                :
                <div style={{ display: 'flex', gap: '10px', cursor: "pointer" }} >
                  <button
                    id={`az-quizkey-btn`}
                    onClick={() => {
                      chapterQuizKeyClicked()
                      var quizzData = JSON.parse(sessionStorage.getItem("itemload"));
                      //console.log("quizzData", quizzData)
                      if (quizzData) {
                        const inputTime = quizzData.endTime;
                        // Parse the input time string
                        const [hourMinute, ampm] = inputTime.split(" ");
                        const [hour, minute] = hourMinute.split(":");
                        let targetHour = parseInt(hour, 10);
                        if (ampm === "PM" && targetHour !== 12) {
                          targetHour += 12; // Convert to 24-hour format
                        } else if (ampm === "AM" && targetHour === 12) {
                          targetHour = 0; // Midnight
                        }
                        const targetMinute = parseInt(minute, 10);
                        const targetTime = new Date();
                        targetTime.setHours(targetHour);
                        targetTime.setMinutes(targetMinute);
                        targetTime.setSeconds(0);
                        const currentTime = new Date();
                        // Compare the current time with the target time
                        if (Vquiztype == 'practice') {
                          handleChapterQuizKey();
                        } else {
                          if (currentTime < targetTime) {
                            //console.log("Current time is earlier than " + inputTime);
                            setchapterQuizKeyString(true);
                          } else {
                            //console.log("Current time is equal to or later than " + inputTime);
                            setchapterQuizKeyString(false);
                            handleChapterQuizKey();
                          }
                        }
                      } else {
                        handleChapterQuizKey();
                      }
                      //console.log(realTimeQuizData, props, JSON.parse(sessionStorage.getItem("quiz_listings")), JSON.parse(sessionStorage.getItem("itemload")));
                    }}
                  >
                    Chapter Quiz Key
                  </button>
                  <Link className="linkExit"  to={"../formal%20assessment%20quiz"} element={Student_Enrich}>
                  <button
                  className="exitBtnn"
                    id={`az-close-btn`}
                    style={{ backgroundColor: "#959aad", }}
                    onClick={() => {
                      //console.log(props)
                      // window.location.reload()
                      sessionStorage.removeItem("itemload");
                      sessionStorage.removeItem("itemloadInfo");
                      sessionStorage.removeItem("Vquiztype");
                      sessionStorage.removeItem("exid");
                      handleClose();
                      closeModal();
                      
                      // props?.callbackforafterquizexit();

                    }}
                  >
                    Exit

                  </button>
                  </Link>
                </div>

              }
            </div>
          </div>
        </Box>
      </Modal>
      {showModal && (
        <ChapterQuizKeyModal examId1={examId1} showModal={showModal} closeModal={closeModal} />
      )}
    </>
  );
}

export default Student_Formal_Assesment_Analysis_Modal;
