import React, { useState, useEffect } from "react";
import {
	Avatar,
	Alert,
	Box,
	Button,
	Checkbox,
	IconButton,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { SearchOutlined } from "@mui/icons-material";
import searchIcn from "../../../../../img/search.svg";
import axios from "axios";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { isNOTNullOrUndefined } from "../../../../../utils/helper";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1200,
	bgcolor: "background.paper",
	// border: "2px solid #000",
	boxShadow: 24,
	p: 2,
	borderRadius: "10px",
	display: "flex",
	flexDirection: "column",
	rowGap: "1rem",
};
const ReReleaseButton = styled(Button)(() => ({
	color: "#fff",
	width: "7.8vw",
	backgroundColor: "#ed9b00",
	borderRadius: 150,
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#ed9b00",
		border: 0,
		top: -2,
		boxShadow: "0px 3px 5px #FFA700",
	},
}));
const YesButton = styled(Button)(() => ({
	color: "#fff",
	width: "5.2vw",
	backgroundColor: "rgba(5,175,8,0.89)",
	borderRadius: 5,
	textTransform: "none",
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "rgba(5,175,8,0.89)",
		border: 0,
	},
}));
const NoButton = styled(Button)(() => ({
	color: "#fff",
	width: "5.2vw",
	backgroundColor: "rgb(255,55,0)",
	borderRadius: 5,
	textTransform: "none",
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "rgb(255,55,0)",
		border: 0,
	},
}));
const colors = [
	"#ff3700",
	"#ff8c00",
	"#0000ff",
	"#63e31f",
	"#e73232",
	"rgb(244, 57, 107)",
];
const setBg = () => {
	const randomColor = Math.floor(Math.random() * 16777215).toString(16);
	return `#${randomColor}`;
};
const getRandomInt = () => {
	let min = Math.ceil(1);
	let max = Math.floor(1000);
	return Math.floor(Math.random() * (max - min) + min);
};

const AssignQuizReReleaseModal = ({
	open,
	close,
	selectedQuizId,
	teacherId,
	selectedSectionReleaseData,
	setSucessModal,
	setSucessMessage,
	chapterId
}) => {
	const [studentData, setStudentData] = useState([]);
	const [filterValue, setFilterValue] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(0);
	const token = sessionStorage.getItem("token");
	const [selectedData, setSelectedData] = useState([]);
	const [selectedId, setSelectedId] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedTime, setSelectedTime] = useState(null);
	const [studentSectionData, setStudentSectionData] = useState([]);
	const [releaseParams, setReleaseParams] = useState();
	const [wrongTime, SetWrongTime] = useState(false)
	const [errorMessage, seterrorMessage] = useState("")
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setReleaseParams([
			{
				sectionId: selectedSectionReleaseData[0].sectionId,
				startDate: selectedSectionReleaseData[0].startDate,
				startTime: selectedSectionReleaseData[0].startTime,
				studentListToSection: selectedId,
			},
		]);
	}, [selectedId, selectedSectionReleaseData]);

	useEffect(async () => {
		if (studentSectionData?.length) {
			await setFilterValue(studentSectionData);
			await setStudentData(studentSectionData);
		}
	}, [studentSectionData]);
	const searchHandler = (event) => {
		let value = event?.target?.value?.toLowerCase();
		let result = studentData;
		if (value) {
			result = studentData?.filter((data) => {
				return (
					data?.firstName?.toLowerCase().includes(value) ||
					data?.lastName?.toLowerCase().includes(value) ||
					data?.email?.toLowerCase().includes(value) ||
					data?.admissionDate?.toLowerCase().includes(value) ||
					data?.lastLoginTime?.toLowerCase().includes(value)
				);
			});
		}
		setFilterValue(result);
	};
	const handleChangePage = (event, newPage) => {
		setPageNumber(newPage);
	};
	const handleRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPageNumber(0);
	};
	const selectHandler = (item) => {
		const id = item;
		const clone = [...selectedData];
		const findIndex = clone.findIndex((data) => data.id === id);
		const find = studentData.find((data) => data.id === id);
		if (findIndex !== -1) {
			const remove = clone.filter((data) => data.id !== id);
			setSelectedData(remove);
		} else {
			setSelectedData((pre) => [...pre, find]);
		}
	};
	const allSelectHandler = (value) => {
		if (value) {
			const clone = [...filterValue];
			setSelectedData(
				clone.filter((data) => data.examAttended === false)
			);
		} else {
			setSelectedData([]);
		}
	};

	function fetchId() {
		let arry = [];
		selectedData.forEach((data) => {
			arry.push(data.id);
		});
		return arry;
	}
	useEffect(() => {
		setSelectedId(fetchId());
		setLoading(false);
	}, [selectedData]);

	const fetchStudents = async () => {
		const boardId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const gradeId = sessionStorage.getItem("gradeId");
		const subjectId = sessionStorage.getItem("subjectId");
		const subTopicId = sessionStorage.getItem("subTopicId");
		const quizId = selectedQuizId;
		const sectionId = sessionStorage.getItem("selectedSection") || sessionStorage.getItem("sectionId");
		const academicYearId = sessionStorage.getItem("academicYearId");

		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/rerelase-list?pageNumber=0&pageSize=1000&sortOrder=true&sortBy=createdAt&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}&quizId=${quizId}&teacherId=${teacherId}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.data.success) {
					var activestudents = response?.data?.data?.data?.filter((v) => v.active === true)
					// console.log("response?.data?.data",response?.data?.data?.data,activestudents)
					setStudentSectionData(activestudents);
				}
			});
	};

	useEffect(() => {
		fetchStudents();
	}, []);

	const fetchRelease = async () => {
		// Retrieve session data
		const boardId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const gradeId = sessionStorage.getItem("gradeId");
		const subjectId = sessionStorage.getItem("subjectId");
		const subTopicId = sessionStorage.getItem("subTopicId");
		const quizId = selectedQuizId;
		const sectionId = sessionStorage.getItem("sectionId");
		const academicYearId = sessionStorage.getItem("academicYearId");

		const data = {
			quizId: quizId,
			boardId: boardId,
			schoolId: schoolId,
			branchId: branchId,
			gradeId: gradeId,
			subjectId: subjectId,
			academicYearId: academicYearId,
			sectionList: releaseParams,
			teacherId: teacherId,
			chapterId: chapterId?.id
		};
		if (isNOTNullOrUndefined(subTopicId)) {
			data.subTopicId = subTopicId;
		}
		const givenDateString = data?.sectionList[0]?.startDate; // Assuming the date is in the format "DD/MM/YYYY"
		const givenTimeString = data?.sectionList[0]?.startTime;
		const currentTime = new Date();

		// Parsing the given date string
		const [givenDay, givenMonth, givenYear] = givenDateString.split('/').map(Number);
		const givenDate = new Date(givenYear, givenMonth - 1, givenDay);

		// Parsing the given time string
		const [time, period] = givenTimeString.split(' ');
		let [givenHours, givenMinutes] = time.split(':').map(Number);

		if (period === "PM" && givenHours !== 12) {
			givenHours += 12;
		} else if (period === "AM" && givenHours === 12) {
			givenHours = 0;
		}

		// Set the given time on the given date
		givenDate.setHours(givenHours);
		givenDate.setMinutes(givenMinutes);
		givenDate.setSeconds(0);
		givenDate.setMilliseconds(0);

		// Normalize current time to ignore seconds and milliseconds
		const normalizedCurrentTime = new Date(currentTime);
		normalizedCurrentTime.setSeconds(0);
		normalizedCurrentTime.setMilliseconds(0);

		// Compare the given date and time with the current date and time based on hour and minute
		if (normalizedCurrentTime > givenDate) {
			// console.log("The current date and time is greater than the given date and time", givenTimeString, givenTimeString, normalizedCurrentTime, givenDate);
			SetWrongTime(true);
			seterrorMessage("Wrong time selected");
		} else {
			// console.log("The current date and time is not greater than the given date and time", givenTimeString);
			SetWrongTime(false);
			seterrorMessage("");
			try {
				await axios
					.post(
						`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-release`,
						data,
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						if (res.data.success) {
							setLoading(false);
							setSucessModal(true);
							setSucessMessage("Quiz Rereleased Successfully ");
							close();
						}
					}).catch((error) => {
						// console.log(error.response.data)
						const { errorCode, message } = error?.response?.data;
						SetWrongTime(true);
						seterrorMessage(message);
						setTimeout(() => {
							SetWrongTime(false);
							seterrorMessage("");
						}, 10000);
					});
			} catch (error) {
				setLoading(false);
				// console.error(error, "=================");
			}
		}
	};

	const handleRelease = () => {
		// console.log(">>>>>>>>>>>>>>>>>>>>>")
		setLoading(true);
		fetchRelease();
		// close();
	};
	const [notAttented, setNotAttented] = useState(0);
	useEffect(() => {
		setNotAttented(
			filterValue.filter((data) => data.examAttended === false).length
		);
	}, [filterValue]);

	return (
		<Modal
			open={open}
			onClose={close}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						rowGap: "10px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div
							style={{
								fontSize: 20,
								fontWeight: 600,
								color: "#29313f",
							}}
						>
							Section Students
						</div>
						<CloseOutlinedIcon
							onClick={close}
							style={{
								cursor: "pointer",
								fontSize: 20,
								color: "#757575",
							}}
						/>
					</div>
					<div style={{ textAlign: "end" }}>
						<TextField
							sx={{
								width: { sm: 200, md: 300 },
								"& .MuiInputBase-root": {
									width: "247px !important",
									height: "32px !important",
									borderRadius: "50px !important",
									background: "#fff !important",
									lineHeight: "21px !important",
									opacity: 1,
									font: "normal normal normal 13px/20px Poppins !important",
								},
							}}
							type={"text"}
							placeholder={"Search anything...."}
							onChange={searchHandler}
							InputProps={{
								endAdornment: (
									<IconButton>
										<img
											src={searchIcn}
											style={{
												verticalAlign: "top",
												fontSize: "large",
											}}
											aria-hidden="true"
										/>
									</IconButton>
								),
							}}
							size={"small"}
						/>
					</div>
				</div>
				<Paper
					sx={{
						width: "100%",
						overflow: "hidden",
						boxShadow: "0px 0px 0px 0px",
						display: "flex",
						flexDirection: "column",
						rowGap: "10px",
					}}
				>
					<TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
						<Table
							sx={{ minWidth: 650, marginBottom: "90px" }}
							stickyHeader={true}
							aria-label="customized table"
						>
							<TableHead>
								<TableRow>
									<TableCell
										style={{
											fontWeight: 600,
											background: "#f4f4f4",
											color: "#3a3642",
										}}
									>
										S.NO
									</TableCell>
									<TableCell
										style={{
											fontWeight: 600,
											background: "#f4f4f4",
											color: "#3a3642",
										}}
										align={"left"}
									>
										Name
									</TableCell>
									<TableCell
										align="center"
										style={{
											fontWeight: 600,
											background: "#f4f4f4",
											color: "#3a3642",
											textAlign: "center",
										}}
									>
										Completion Status
									</TableCell>
									<TableCell
										align="center"
										style={{
											fontWeight: 600,
											background: "#f4f4f4",
											color: "#3a3642",
											textAlign: "center",
										}}
									>
										Completion Status
										<Checkbox
											sx={{ color: "blue" }}
											color="default"
											checked={
												notAttented ===
												selectedData?.length
											}
											indeterminate={
												selectedData?.length !== 0 &&
												notAttented !==
												selectedData?.length
											}
											onChange={(e) =>
												allSelectHandler(
													e.target.checked
												)
											}
										/>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filterValue?.length > 0 &&
									filterValue
										?.slice(
											pageNumber * rowsPerPage,
											pageNumber * rowsPerPage +
											rowsPerPage
										)
										?.map((item, index) => {
											const firstLetter = item?.firstName;
											const checkValue =
												selectedData?.find(
													(data) =>
														data?.id === item?.id
												);
											return (
												<TableRow
													key={item?.id}
													sx={
														{
															// "&:last-child td, &:last-child th":
															// 	{ border: 0 },
														}
													}
												>
													<TableCell
														component="th"
														scope="row"
													>
														{pageNumber *
															rowsPerPage +
															(index + 1)}
													</TableCell>
													<TableCell
														align="left"
														style={{
															// border: "none",
															paddingBottom: 22,
															color: "#575269",
															display: "flex",
															flexDirection:
																"row",
															columnGap: "10px",
															alignItems:
																"center",
														}}
													>
														<Avatar
															style={{
																marginRight: 10,
																display: "flex",
																justifyContent:
																	"center",
																alignItems:
																	"center",
															}}
															sx={{
																bgcolor:
																	setBg(),
																// colors[Math.floor(Math.random() * 6)],
															}}
														>
															{/* {firstLetter[0]} */}
														</Avatar>
														{item.firstName}
													</TableCell>
													<TableCell
														align="center"
														style={{
															color: "#575269",
															textAlign: "center",
														}}
													>
														{item.examAttended ? (
															<YesButton>
																Yes
															</YesButton>
														) : (
															<NoButton>
																No
															</NoButton>
														)}
													</TableCell>
													<TableCell
														align="center"
														style={{
															color: "#575269",
															textAlign: "center",
														}}
													>
														{!item.examAttended && (
															<Checkbox
																sx={{
																	color: "blue",
																}}
																color="default"
																value={item?.id}
																checked={
																	checkValue?.id ===
																	item?.id
																}
																onChange={(e) =>
																	selectHandler(
																		e.target
																			.value
																	)
																}
															/>
														)}
													</TableCell>
												</TableRow>
											);
										})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						style={{
							bottom: "0",
							width: "80.5%",
							// position: "fixed",
							backgroundColor: "white",
						}}
						rowsPerPageOptions={[5, 10, 25, 50]}
						component="div"
						count={filterValue?.length || 0}
						page={pageNumber}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleRowsPerPage}
						showFirstButton={true}
						showLastButton={true}
					/>
				</Paper>
				<div style={{ textAlign: "end" }}>

					{wrongTime && (
						<Alert
							action={
								<IconButton
									color="inherit"
									size="small"
									onClick={() => {
										SetWrongTime(false);
										seterrorMessage("")
									}}
								>
									<CancelOutlinedIcon />
								</IconButton>
							}
							icon={false}
							sx={{
								color: "red",
								fontWeight: 500,
								fontSize: "0.93vw",
								width: "80%",
								float: "right",
								// background: "#bddabca1",
								background: "rgba(200,255,200,0.63)",
							}}
						>
							{errorMessage}
						</Alert>
					)}
				</div>
				<div style={{ textAlign: "end" }}>

					{selectedId.length > 0 && (
						<ReReleaseButton
							id="az-quiz-rerelease-btn"
							disabled={loading}
							onClick={handleRelease}>
							Re-Release
						</ReReleaseButton>
					)}
				</div>
			</Box>
		</Modal>
	);
};
export default AssignQuizReReleaseModal;
