import axios from 'axios'

const sectionFromgrade_get = (studentInputs,setSectionName) => {
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");
    let token = sessionStorage.getItem("token");
    // let gradeId = sessionStorage.getItem("gradeId")

    axios .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${studentInputs.gradeId}&branchId=${branchid}&schoolId=${schoolid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSectionName(response?.data.data);
        // console.log(response.data,"aaaaa")
      })
      .catch((err) => console.log(err));
    
  }

  export default sectionFromgrade_get