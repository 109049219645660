import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import searchIcn from "../../../src/img/search.svg";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Yellow100Button from "../../components/common/Yellow100Button";
import Green100Button from "../../components/common/Green100Button";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import GetOTPModal from "./GetOTPModal";
import StudentSectionModal from "./StudentSectionModal";
import RoleDetailsModal from "./RoleDetailsModal";
import ChapterTrackingModal from "./ChapterTrackingModal";
import axios from "axios";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import { useLocation } from "react-router-dom";
import TopBar from "../../components/common/TopBar";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import contentApprovalStyles from "../css/contetnApproval.module.css";
import TeacherProfileUpdateModal from "./TeacherProfileUpdateModal";
import PrincipalAccessModal from "./PrincipalAccessModal";
import TeacherSuccessModal from "./TeacherSuccessModal";
import commonsort from "../js/CommonSort"
import sortIcon from "../img/sort_1.png"
const PrincipalProfile = () => {
  const [value, setValue] = useState("profile");
  const [showGetOTPModal, setShowGetOTPModal] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({});
  const [currentAccessData, setCurrentAccessData] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(100);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location?.state) {
      setValue(location?.state?.value?.toString());
    } else {
      setValue("profile");
    }
  }, [location?.state]);
  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
  });
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const token = sessionStorage.getItem("token");
  const [teacherId, setTeacherId] = useState([]);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [principalProfileModal, setPrincipalModal] = useState(false);
  const [successmodal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedProfile, setSelectedProfile] = useState({});
  const [grades, setGrades] = useState([]);
  const [planId, setPlanId] = useState("");

  useEffect(() => {
    // GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const handleChange = (event, newValue) => {
    // console.log(newValue);
    if (newValue == 'Teacher Access') {
      fetchTeacherAccessData();
    } else if (newValue == 'School Strength') {
      fetchCountData();
      schoolStrengthData();
    } else if (newValue == 'Current Access') {
      schoolStrengthData();
    }
    setFilterValue([...updatedData]);
    setValue(newValue);
  };

  const principalProfileModalOpen = () => {
    setPrincipalModal(true);
  };

  const principalProfileModalClose = () => {
    setPrincipalModal(false);
  };

  const principalSuccess = (message) => {
    setSuccessMessage(message)
    setPrincipalModal(false);
    setSuccessModal(true);
  };
  const TeacherSuccessModalClose = () => {
    setSuccessModal(false);
  };
  useEffect(async () => {
    if (userName && role) {
      const teacherData = await fetchTeacherData(userName, role);
      setTeacherDetail(teacherData);
    }
    fetchTeacherAccessData();
    schoolStrengthData();
    fetchCountData();
  }, []);
  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("branchId", res.data.data.teacher.branch);
    sessionStorage.setItem("id", res.data.data.teacher.id);
    sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
    setTeacherId(res?.data?.data?.teacher);
  };
  const fetchTeacherId = async () => {
    const id = teacherId.id;
    // console.log("abc", id);
    const res = await axios.put(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data?.teacher || [];
  };

  const updateTeacherData = async () => {
    // const id = teacherId.id;
    const data = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      dob: "",
      gender: "",
      documentUrl: null,
      firstLanguageId: "",
      secondLanguageId: "",
      admissionDate: "",
      studentCategoryId: "",
      gradeId: "",
      sectionId: "",
      branch: "",
      school: "",
      isPromoted: true,
      yearEndProcess: true,
      address: "",
      active: true,
      lmsEnv: null,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${""}`,
      // updatedData,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data?.teacher || [];
  };
  const handleChangePage = (event, newPage) => {
    // console.log("handleChangePage", newPage, rowsPerPage, filterValue)
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    // console.log("handleRowsPerPage", event.target.value)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filterValue, setFilterValue] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [currentAcess, setCurrentAccess] = useState([]);
  const [count, setCount] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [sortOrder, setSortOrder] = useState(true)
  const fetchTeacherAccessData = async () => {
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageNumber=0&pageSize=1000&sortOrder=true&sortBy=createdAt&schoolId=${schoolId}&branchId=${branchId}&active=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setFilterValue(res?.data?.data?.data);
    setUpdatedData(res?.data?.data?.data);
  };
  const onSort = async (field) => {
    // console.log(field, filterValue)
    setSortOrder(!sortOrder)
    var sorted = await commonsort(filterValue || [], field, !sortOrder);
    // console.log(sorted)
    setFilterValue(sorted)
  }
  const [showTrackingModal, setTrackingModal] = useState(false);
  const [name, setName] = useState("")
  const ChapterTracking = async (item) => {
    const { id, name } = item;
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const boardId = sessionStorage.getItem("boardId");

    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/tracking?pageNumber=0&pageSize=1000&sortOrder=true&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&teacherId=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log(res.data.data.data, "27-6-24 required")
    // res.data.data.data = res?.data?.data?.data?.filter(v => v.skilledSubject === "Yes") || []
    setChapterData(res.data.data.data);
    setName(name);
    setTrackingModal(true);
  };

  const fetchCountData = async () => {
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/count-by/persona?schoolId=${schoolId}&branchId=${branchId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { coordinatorCount, principalCount, studentCount, teacherCount } =
      res.data.data;
    setCount([
      { label: "Principal", count: principalCount },
      { label: "Coordinator", count: coordinatorCount },
      { label: "Teacher", count: teacherCount },
      { label: "Student", count: studentCount }
    ]);
  };
  const schoolStrengthData = async () => {
    const Id = sessionStorage.getItem("id");
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/school-strength/${Id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(res.data.data, "27-6-24 required")

    const tempCurrent = [...res.data.data];
    const temp = res.data.data;
    setCurrentAccess(tempCurrent[0]?.grades);
    if (res.data.data.length > 0) {

      const grades = res.data.data[0]?.grades;
      // let filteredV = [];
      // if (res && Array.isArray(res.data?.data)) {
      //   // filteredV = res.data.data.filter(v => v.skilledSubject === true);
      //   console.log("filteredV", filteredV);
      //   res.data.data = filteredV;
      // } else {
      //   console.error("res.data.data is not an array or res is undefined");
      // }
      res.data.data.shift();
      const remaining = res.data.data;
      remaining.forEach((value, index) => {
        value?.grades?.length > 0 &&
          value?.grades?.forEach((v, i) => {
            v["subSections"] = [];
            grades.forEach((val, ind) => {
              var temp = v["subSections"];
              if (val.gradeId == v.gradeId) {
                v["subSections"] = [...val?.sections, ...temp];
              }
            });
          });
      });

      setSchoolData(remaining);
    }
  };
  const UpdateTeacherModal = (item) => {
    // console.log("UpdateTeacherModal", item)
    const { email, school, name, id, branch, userId, branchId, schoolId } = item;
    setSelectedProfile({ email, school, name, id, branch, userId, branchId, schoolId });
    getGrades();
    setOpenProfileModal(true);
    getTeacherData(id);
  };
  const [teachergrade, setTeacherGrade] = useState([]);
  const getTeacherData = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/list/assigned-access?teacherId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setTeacherGrade(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const [studentlist, setStudentList] = useState([]);
  const showStudentDetails = async (sectionId, gradeId) => {
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageNumber=0&pageSize=1000&sortOrder=true&sortBy=createdAt&sectionId=${sectionId}&gradeId=${gradeId}&branchId=${branchId}&schoolId=${schoolId}&active=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    function parseDateString(dateString) {
      if (!dateString) return null;

      const [datePart, timePart, period] = dateString.split(' ');
      const [day, month, year] = datePart.split('-').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);

      let adjustedHours = hours;
      if (period === 'PM' && hours < 12) {
        adjustedHours += 12;
      }
      if (period === 'AM' && hours === 12) {
        adjustedHours = 0;
      }

      return new Date(year, month - 1, day, adjustedHours, minutes, seconds);
    }

    res.data.data.data = res?.data?.data?.data.map(entry => {
      return {
        ...entry,
        lastUnitQuizSubmissionNew: entry.lastUnitQuizSubmission ? parseDateString(entry?.lastUnitQuizSubmission) : null,
        lastLoginTimeNew: entry.lastLoginTime ? parseDateString(entry?.lastLoginTime) : null
      };
    });
    setStudentList(res?.data?.data?.data || []);
    setShowStudentModal(true);
  };
  const [showroleModal, setroleModal] = useState(false);
  const [roleData, setroleData] = useState([]);
  const [toplabel, setTopLabel] = useState("")
  const showDetails = async (label) => {
    label = label == 'Coordinator' ? 'COORDINATOR' :
      label == 'Principal' ? 'PRINCIPAL' :
        label == 'Teacher' ? 'TEACHER' :
          ''
    setTopLabel(label);
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageNumber=0&pageSize=1000&sortOrder=true&sortBy=createdAt&branchId=${branchId}&schoolId=${schoolId}&profile=${label}&active=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setroleData(res.data.data.data || []);
    setroleModal(true);
  };

  const closeProfileModal = () => {
    setOpenProfileModal(false);
  };

  const handleSearch = async (event) => {
    let value = event.target.value?.toLowerCase();

    if (value) {
      const result = [...updatedData]?.filter((data) => {
        var { branch, name, role } = data;
        return (
          name?.toLowerCase().includes(value) ||
          branch?.toLowerCase().includes(value) ||
          role?.toLowerCase().includes(value)
        );
      }); //branch
      setFilterValue(result);
      setPage(0);
    } else {
      setFilterValue([...updatedData]);
      setPage(0);
    }
  };

  const getGrades = () => {
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/grades?branchId=${branchId}&schoolId=${schoolId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setGrades(response?.data?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setPlanId(response?.data?.data?.plans[0]?.planId);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <TopBar
        title={"Home"}
        // added by shamil
        subSubTitle={
          role === "COORDINATOR" ? " Coordinator Profile" : "Principal Profile"
        }
        modifiedDate={modifiedDate?.data}
        nav1={'principal/dashboard'}
        role={role}
      />
      <div
        style={{
          marginTop: "10px",
          paddingRight: "33px",
          position: "absolute",
          right: "15px",
          left: "15px",
          width: "100%",
        }}
      >
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={(e, v) => handleChange(e, v)}
            textColor="inherit"
            // indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                background: "white",
                height: "10px",
                top: "50px",
              },
            }}
          >
            <Tab
              value="profile"
              label="My Detail"
              sx={{
                fontWeight: 600,
                background: value === "profile" ? "white" : "",
                border: value === "profile" ? "1px solid #757575" : "",
              }}
            />
            <Tab
              value="Teacher Access"
              label="Teacher Access"
              sx={{
                fontWeight: 600,
                background: value === "Teacher Access" ? "white" : "",
                border: value === "Teacher Access" ? "1px solid #757575" : "",
              }}
            />
            <Tab
              value="Current Access"
              label="Current Access"
              sx={{
                fontWeight: 600,
                background: value === "Current Access" ? "white" : "",
                border: value === "Current Access" ? "1px solid #757575" : "",
              }}
            />
            {role === "PRINCIPAL" && (
              <Tab
                value="School Strength"
                label="School Strength"
                sx={{
                  fontWeight: 600,
                  background: value === "School Strength" ? "white" : "",
                  border: value === "School Strength" ? "1px solid #757575" : "",
                }}
              />
            )}
          </Tabs>
          {value === "Teacher Access" && (
            <div style={{ position: "absolute", right: "0px", top: "0px" }}>
              <TextField
                type={"text"}
                placeholder={"Search...."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    opacity: 1,
                    font: "normal normal normal 13px/20px Poppins !important",
                  },
                }}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
            </div>
          )}
          <TabPanel value="profile" sx={{ padding: "24px 5px" }}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                height: "670px",
              }}
            >
              <div
                style={{
                  background: "rgba(96, 91, 161, 0.81)",
                  color: "white",
                  padding: "15px",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                }}
              >
                Personal Information
              </div>
              <div
                style={{
                  padding: "20px 40px",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} lg={6} md={6} sm={12}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>First Name</div>
                          <TextField
                            name={"firstName"}
                            sx={{ width: "100%" }}
                            value={teacherId?.firstName || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Primary Email</div>
                          <TextField
                            name={"email"}
                            sx={{ width: "100%" }}
                            value={teacherId?.email || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Date of Joining</div>
                          <TextField
                            type={teacherId?.joinDate != null && teacherId?.joinDate ? "date" : "text"}
                            sx={{ width: "100%" }}
                            value={teacherId?.joinDate != null && teacherId?.joinDate ? teacherId?.joinDate : ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Designation</div>
                          <TextField
                            type={"text"}
                            name={"Designation"}
                            sx={{ width: "100%" }}
                            value={teacherId?.designation || ""}
                            disabled
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={8} lg={6} md={6} sm={12}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Last Name</div>
                          <TextField
                            name={"lastName"}
                            sx={{ width: "100%" }}
                            value={teacherId?.lastName || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Primary Phone No</div>
                          <TextField
                            name={"phoneNo"}
                            sx={{ width: "100%", borderTopLeftRadius: 0 }}
                            value={teacherId?.mobile || ""}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  style={{ marginRight: "20px" }}
                                  onClick={() => setShowGetOTPModal(true)}
                                >
                                  <ModeEditOutlineOutlinedIcon
                                    style={{ color: "#FFA700" }}
                                  />
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>School Name</div>
                          <TextField
                            name={"hrs"}
                            sx={{ width: "100%" }}
                            value={teacherId?.branchName || ""}
                            disabled
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    columnGap: "1rem",
                  }}
                >
                  <Yellow100Button
                    onClick={(e) => {
                      //console.log(role, "role");
                      if (role === "COORDINATOR") {
                        navigate("/cordinator/dashboard");
                      } else {
                        navigate("/dashboard/TeacherDashboard");
                      }
                    }}
                  >
                    Cancel
                  </Yellow100Button>
                  <Green100Button
                    onClick={async () => {
                      const updatedData = {
                        firstName: "",
                        lastName: "",
                        email: "",
                        mobile: "",
                        dob: "",
                        gender: "",
                        documentUrl: null,
                        firstLanguageId: "",
                        secondLanguageId: "",
                        admissionDate: "",
                        studentCategoryId: "",
                        gradeId: "",
                        sectionId: "",
                        branch: "",
                        school: "",
                        isPromoted: true,
                        yearEndProcess: true,
                        address: "",
                        active: true,
                        lmsEnv: null,
                      };
                      await updateTeacherData(updatedData);
                      await fetchTeacherData(userName, role);
                    }}
                  >
                    Save
                  </Green100Button>
                </div>
              </div>
            </Paper>
          </TabPanel>
          <TabPanel value="Teacher Access" sx={{ padding: "24px 5px" }}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
                style={{ marginBottom: "90px" }}
                sx={{ maxHeight: 640, overflow: "auto", scrollbarWidth: "thin" }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader={true}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          background: "rgba(96, 91, 161, 0.81)",
                          color: "white",
                        }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          background: "rgba(96, 91, 161, 0.81)",
                          color: "white",
                        }}
                      >
                        User
                        <img
                          style={{
                            verticalAlign: "middle",
                            marginLeft: "5px",
                            marginBottom: "1px",
                          }}
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          onClick={async () => {
                            onSort("name")
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          background: "rgba(96, 91, 161, 0.81)",
                          color: "white",
                        }}
                      >
                        Profile
                        <img
                          style={{
                            verticalAlign: "middle",
                            marginLeft: "5px",
                            marginBottom: "1px",
                          }}
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          onClick={async () => {
                            onSort("role")
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          background: "rgba(96, 91, 161, 0.81)",
                          color: "white",
                        }}
                      >
                        Email
                        <img
                          style={{
                            verticalAlign: "middle",
                            marginLeft: "5px",
                            marginBottom: "1px",
                          }}
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          onClick={async () => {
                            onSort("email")
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          background: "rgba(96, 91, 161, 0.81)",
                          color: "white",
                        }}
                      >
                        Branch
                        <img
                          style={{
                            verticalAlign: "middle",
                            marginLeft: "5px",
                            marginBottom: "1px",
                          }}
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          onClick={async () => {
                            onSort("branch")
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          background: "rgba(96, 91, 161, 0.81)",
                          color: "white",
                        }}
                      >
                        Tracking

                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          background: "rgba(96, 91, 161, 0.81)",
                          color: "white",
                        }}
                      >
                        Profile
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filterValue.length > 0 &&
                      filterValue
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((item, index) => {
                          return (
                            <TableRow
                              key={item?.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{
                                background: "#f9f8f8",
                              }}
                            >
                              <TableCell
                                align="left"
                                className={dashboardContentStyles.table_cell}
                              >
                                {page * rowsPerPage + (index + 1)}
                              </TableCell>

                              <TableCell
                                align="left"
                                className={dashboardContentStyles.table_cell}
                              >
                                {item?.name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={dashboardContentStyles.table_cell}
                              >
                                {item?.role}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={dashboardContentStyles.table_cell}
                              >
                                {item?.email}
                              </TableCell>

                              <TableCell
                                align="left"
                                className={dashboardContentStyles.table_cell}
                              >
                                {item?.branch}
                              </TableCell>
                              <TableCell align="left" className="cursor-pointer">
                                <a style={{ padding: "3px 4px", border: "none", borderRadius: "0px", borderBottom: "1px solid #fd8c00" }}
                                  className={contentApprovalStyles.orange_btn}
                                  onClick={() => {
                                    ChapterTracking(item);

                                  }}
                                >
                                  Chapter tracking
                                </a>
                              </TableCell>
                              <TableCell align="left">
                                <button
                                  className={contentApprovalStyles.orange_btn}
                                  style={{ width: "150px" }}
                                  onClick={() => {
                                    UpdateTeacherModal(item);
                                  }}
                                >
                                  Update Profile
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={{
                  bottom: "0",
                  width: "80.5%",
                  position: "fixed",
                  backgroundColor: "white",
                }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filterValue?.length || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </Paper>
          </TabPanel>
          <TabPanel value="Current Access" sx={{ padding: "24px 5px" }}>
            <Paper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                  height: "670px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    background: "rgba(96, 91, 161, 0.81)",
                    color: "white",
                    padding: "15px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  Current Access Information
                </div>
                <div
                  style={{
                    padding: "20px",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    rowGap: "1rem",
                    minHeight: "595px",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {schoolData.map((data) => {
                    return (
                      <div
                        key={data?.subjectId}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "rgb(227 225 225 / 61%)",
                            padding: "10px",
                            fontWeight: 600,
                          }}
                          key={data?.subject}
                        >
                          {data?.subject}
                        </div>
                        <Box
                          sx={{
                            flexGrow: 1,
                            height: "200px",
                            overflow: "auto",
                            minHeight: "200px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {data?.grades?.length > 0 &&
                              data?.grades?.map((item) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    sm={6}
                                    key={item?.gradeId}
                                  >
                                    <div
                                      style={{
                                        border:
                                          "1px solid rgb(168 168 168 / 55%)",
                                        padding: "10px",
                                        borderRadius: "7px",
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <div>{item?.grade}</div>
                                      <Grid container spacing={2}>
                                        {item.subSections.length > 0 &&
                                          item.subSections.map(
                                            (sec, secIndex) => {
                                              return (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  sm={6}
                                                  key={sec.sectionId}
                                                  onClick={() => {
                                                    setSelectedSectionId(
                                                      sec.sectionId
                                                    );
                                                    showStudentDetails(
                                                      sec.sectionId,
                                                      item?.gradeId
                                                    );
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      background:
                                                        secIndex === 0
                                                          ? "rgb(244, 57, 107)"
                                                          : secIndex === 1
                                                            ? "rgb(237, 155, 0)"
                                                            : secIndex === 2
                                                              ? "#0492e2"
                                                              : "#675dc6",
                                                      borderRadius: "3px",
                                                      padding: "10px",
                                                      color: "white",
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      columnGap: "5px",
                                                      alignItems: "center",
                                                      cursor: "pointer",
                                                      fontSize: "1.04vw",
                                                    }}
                                                  >
                                                    <div>{sec?.section}</div>
                                                    <MenuBookOutlinedIcon
                                                      style={{
                                                        fontSize: "1.04vw",
                                                      }}
                                                    />
                                                    <div>
                                                      {sec?.studentCount}
                                                    </div>
                                                  </div>
                                                </Grid>
                                              );
                                            }
                                          )}
                                      </Grid>
                                    </div>
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Box>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </TabPanel>
          {role === "PRINCIPAL" && (
            <TabPanel value="School Strength" sx={{ padding: "24px 5px" }}>
              <Paper>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                    height: "670px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      background: "rgba(96, 91, 161, 0.81)",
                      color: "white",
                      padding: "15px",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                  >
                    School Strength
                  </div>
                  <div
                    style={{
                      width: "30%",
                      padding: "7px",
                      position: "sticky",
                      marginLeft: "inherit",
                    }}
                  >
                    {count?.map((v, i) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            alignItems: "space-between",
                            margin: "5px",
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "#9e9e9e",
                              width: "60%",
                              color: "white",
                              padding: "10px",
                            }}
                          >
                            {v.label}
                          </p>
                          <p
                            className="cursor-pointer"
                            style={{
                              backgroundColor: "#9e9e9e",
                              width: "20%",
                              color: "white",
                              padding: "10px",
                              textAlign: "center",
                            }}
                            onClick={() => showDetails(v.label)}
                          >
                            {v.count}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      padding: "7px",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      rowGap: "1rem",
                      overflow: "auto",
                      flexWrap: "wrap",
                      scrollbarWidth: "thin"
                    }}
                  >
                    {currentAcess?.map((item) => {
                      return (
                        <div
                          key={item?.gradeId}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            rowGap: "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                              height: "200px",
                              overflow: "auto",
                              minHeight: "200px",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sm={6}
                                key={item?.gradeId}
                              >
                                <div
                                  style={{
                                    border: "1px solid rgb(168 168 168 / 55%)",
                                    padding: "3px",
                                    borderRadius: "7px",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    // rowGap: "10px",
                                    width: "400px",
                                  }}
                                >
                                  <div>{item?.grade}</div>
                                  <Grid container spacing={2}>
                                    {item?.sections?.length > 0 &&
                                      item.sections.map((sec, secIndex) => {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sm={6}
                                            key={sec.sectionId}
                                            onClick={() => {
                                              setSelectedSectionId(
                                                sec.sectionId
                                              );
                                              showStudentDetails(
                                                sec.sectionId,
                                                item?.gradeId
                                              );
                                            }}
                                          >
                                            <div
                                              style={{
                                                background:
                                                  secIndex === 0
                                                    ? "rgb(244, 57, 107)"
                                                    : secIndex === 1
                                                      ? "rgb(237, 155, 0)"
                                                      : secIndex === 2
                                                        ? "#0492e2"
                                                        : "#675dc6",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                color: "white",
                                                display: "flex",
                                                flexDirection: "row",
                                                columnGap: "5px",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                fontSize: "1.04vw",
                                              }}
                                            >
                                              <div>{sec?.section}</div>
                                              <MenuBookOutlinedIcon
                                                style={{ fontSize: "1.04vw" }}
                                              />
                                              <div>{sec?.studentCount}</div>
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Paper>
            </TabPanel>
          )}
        </TabContext>
      </div>
      {showGetOTPModal && (
        <GetOTPModal
          open={showGetOTPModal}
          close={() => setShowGetOTPModal(false)}
          phoneNumber={teacherId.mobile}
        />
      )}
      {showStudentModal && studentData && (
        <StudentSectionModal
          open={showStudentModal}
          close={() => {
            setShowStudentModal(false);
            setSelectedSectionId("");
            setStudentData([]);
          }}
          data={studentlist?.length && studentlist}
        />
      )}
      <RoleDetailsModal
        open={showroleModal}
        close={() => {
          setroleModal(false);
        }}
        toplabel={toplabel}
        data={roleData}
      />
      <ChapterTrackingModal
        open={showTrackingModal}
        close={() => {
          setTrackingModal(false);
        }}
        toplabel={toplabel}
        data={chapterData}
        name={name}
      />
      <TeacherProfileUpdateModal
        open={openProfileModal}
        close={closeProfileModal}
        selectedProfile={selectedProfile}
        teachergrade={teachergrade}
        addAcess={() => {
          setPrincipalModal(true);
        }}
        show={true}
      />
      <PrincipalAccessModal
        open={principalProfileModal}
        close={principalProfileModalClose}
        success={principalSuccess}
        grades={grades}
        planId={planId}
        selectedProfile={selectedProfile}
      />
      <TeacherSuccessModal
        open={successmodal}
        onClose={TeacherSuccessModalClose}
        successMessage={successMessage}
      />
    </div>
  );
};
export default PrincipalProfile;
