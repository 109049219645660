import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../../App.css";
import TopRightPopup from "../../components/common/TopRightPopup";
import { Link, useNavigate } from "react-router-dom";
import teacherFilesStyles from "./../css/teacherFiles.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import "./../css/switch.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
// take this css copy in original file
import Pagination1 from "./pagination1";
import ApprovalModal from "./ApprovalModal";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import document_category_get from "../API_CONTROLLER/document-category-controller/document_category_get";
import teacher_upload_chapter_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_upload_chapter_get";
import teacher_file_post from "../API_CONTROLLER/teacher-upload-content-controller/teacher_file_post";
import teacher_paginated_content_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_paginated_content_get";
import action__Delete from "../../../src/img/Delete.svg";
import dashHome from "../../../src/img/dashHome.svg";
import student_upload_chapter_get from "../API_CONTROLLER/student-upload-content-controller/student_upload_chapter_get";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import teacher_content_post from "../API_CONTROLLER/teacher-upload-content-controller/teacher_content_post";
import fileApprovalStyles from "../css/fileApproval.module.css";
import RejectedModal from "./RejectedModal";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import ContentViewer from '../../components/common/ContentViewer';

const ContentApprovalTeacherViewFile = () => {
  const [chapters, setChapters] = useState({});
  const [docCategory, setDocCategory] = useState({});
  const [filterval, setFilterval] = useState({});
  const [updateddata, setUpdatedata] = useState({});
  const [chapterContent, setChapterContent] = useState({});
  const [selectedItem, setSelectedItem] = useState("");
  const [success, setSuccess] = useState({});
  const [showError, setShowError] = useState("");
  const [show, setShow] = useState(false);
  const token = sessionStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [modifiedDate, setModifiedDate] = useState({});
  const [chaptersList, setChaptersList] = useState({});
  const [approveModel, setapproveModal] = useState(false);
  const [rejecteModel, setrejecteModal] = useState(false);
  const [rejectComment, setrejecetComment] = useState("");
  const [teacherInputs, setTeacherInputs] = useState({
    active: "true",
    // chapterId: "",
    contentUrl: "",
    documentCategoryId: "",
    reviewStatus: "PENDING",
  });
  const [selectedImage, setSelectedImage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const navigate = useNavigate();

  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  const handleTeacherInputs = (e) => {
    e.preventDefault();
    setTeacherInputs({
      ...teacherInputs,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const handleOpen = async (item) => {
    setOpenDeleteModal(true);
    setSelectedItem(item);
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/${selectedItem?.id}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        teacher_paginated_content_get(setUpdatedata);
        hideDeleteModal();
      })
      .catch((err) => console.log(err));
    hideDeleteModal(false);

    // teacher_upload_chapter_get(setChapterContent);
  };

  const [chapterName, setChapterName] = useState("");
  useEffect(() => {
    setFilterval(updateddata);
    setChapterName(updateddata[0]?.chapterName);
  }, [updateddata]);

  useEffect(() => {
    setTeacherInputs({
      ...teacherInputs,
      contentUrl: sessionStorage.getItem("contentUrl"),
    });
    return () => { };
  }, [sessionStorage.getItem("contentUrl")]);

  useEffect(() => {
    teacher_paginated_content_get(setUpdatedata);
    return () => { };
  }, [sessionStorage.getItem("chapterId")]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }, [show]);

  const [url, setUrl] = useState();
  const openIframe = (contentUrl) => {
    if (
      contentUrl.includes(".xlsx") ||
      contentUrl.includes(".pptx") ||
      contentUrl.includes(".doc") ||
      contentUrl.includes(".ppt") ||
      contentUrl.includes(".docx")
    ) {
      setUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${contentUrl}`
      );
    } else {
      setUrl(contentUrl);
    }
  };
  const [uptdata, setUptDta] = useState([]);
  const [isDisabled, setDisabled] = useState();
  useEffect(() => {
    student_upload_chapter_get(setUptDta);
    return () => { };
  }, [sessionStorage.getItem("chapterId")]);

  useEffect(() => {
    if (uptdata.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [uptdata]);

  useEffect(() => {
    // chapters_controller_getall(setChapters);
    document_category_get(setDocCategory);
    teacher_upload_chapter_get(setChapterContent);
    chapters_list_getall(setChaptersList);
  }, []);

  return (
    <>
      <div style={{ width: "100%", marginRight: "15px" }}>
        <article>
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <span className={dashboardContentStyles.link_text}>
              Content Approval
            </span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Teacher Files</a>
            {"  "}
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate("/dashboard/template")}
            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span className={dashboardContentStyles.az_menu_text}>
              Content Approval
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Teacher Files
            </span>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div>
        </article>
        <div
          style={{ marginTop: "65px", position: "relative" }}
          className={fileApprovalStyles.flex}
        >
          <div className={fileApprovalStyles.left_row}>
            <i
              onClick={(e) => {
                if (
                  filterval[0]?.documentTypes.length > 0 &&
                  sessionStorage.getItem("isStatus") == "true"
                ) {
                  navigate(
                    `/dashboard/ContentApprovalApproved?chapterId=${updateddata[0].chapterId}`
                  );
                } else {
                  navigate("/dashboard/ContentApprovalApproved");
                }
              }}
              className="fa-solid fa-arrow-left cursorPointer"
            ></i>
            <i
              style={{ color: "grey", fontSize: "24px", margin: "10px" }}
              className="fa-solid fa-user-gear"
            ></i>
            <p>Approved Files</p>
          </div>
          <div style={{ position: "absolute", right: "15px" }}>
            <button
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/contentapprovalviewfile");
                sessionStorage.setItem("status", "APPROVED");
              }}
              className={
                !isDisabled
                  ? fileApprovalStyles.orange_btn
                  : fileApprovalStyles.orange_btn_disabled_grey
              }
              disabled={isDisabled}
            >
              Student
            </button>
            <button
              style={{
                color: "white",
                backgroundColor: "#FD8C00",
                cursor: "pointer",
              }}
              className={fileApprovalStyles.orange_btn}
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/ContentApprovalTeacherViewFile");
                sessionStorage.setItem("status", "APPROVED");
              }}
            >
              Teacher
            </button>
          </div>
        </div>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 15px",
            marginLeft: "15px",
          }}
          style={{ display: "flex" }}
        >
          <div
            style={{ width: "40%", height: "667px", backgroundColor: "white" }}
          >
            <Table
              style={{
                width: "75%",
              }}
            // style={{ minWidth: '1385px' }}
            >
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    className={teacherFilesStyles.table_head}
                    align="left"
                    style={{ margin: "8px 8px" }}
                  >
                    {/* <Checkbox
                      style={{
                        color: "blue",
                        left: "10px",
                        margin: "5px",
                        backgroundColor: "#D9DCFE",

                        // outline: "2px solid #fb9d2a",
                        // outlineOffset: "-2px",
                        transform: "scale(0.8)",
                      }}
                      onChange={(event) => handleallChapterlist(event)}
                    />{" "} */}
                    {chapterName}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <div style={{ display: "flex", width: "107%" }}>
                  <div
                    style={{
                      width: "94%",
                      overflowY: "scroll",
                      height: "600px",
                      borderRight: "1px solid lightgrey",
                      position: "relative",
                    }}
                  >
                    {filterval[0]?.documentTypes &&
                      filterval[0]?.documentTypes.length > 0 ? (
                      filterval?.[0].documentTypes.map((item, i) => {
                        return (
                          <>
                            <Accordion>
                              <AccordionSummary
                                style={{
                                  backgroundColor: "#92A2E8",
                                  width: "100%",
                                }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography
                                  style={{ color: "white", marginLeft: "10px" }}
                                >
                                  {item.documentType} -{" "}
                                  {item.uploadContents?.length}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {item?.uploadContents ? (
                                  item.uploadContents.map((elem) => {
                                    return (
                                      <Accordion>
                                        <AccordionSummary
                                          style={{
                                            width: "100%",
                                            position: "relative",
                                          }}
                                        // expandIcon={<ExpandMoreIcon />}
                                        >
                                          <div
                                            style={{
                                              marginTop: "-13px",
                                              width: "332px",
                                              whiteSpace: "nowrap",
                                              direction: "rtl",
                                              overflow: "auto",
                                            }}
                                          >
                                            {/* {elem.reviewStatus !=
                                              "APPROVED" && (
                                              <Checkbox
                                                style={{
                                                  color: "#fb9d2a",
                                                  width: "10px",
                                                  position: "absolute",
                                                  left: "8px",
                                                  top: "8px",
                                                  // outline:
                                                  //   "2px solid #fb9d2a",
                                                  // outlineOffset: "-2px",
                                                  // transform: "scale(0.8)",
                                                }}
                                                onChange={(event) =>
                                                  handleChapterlist(event, elem)
                                                }
                                                checked={
                                                  ids.includes(elem?.id)
                                                    ? true
                                                    : false
                                                }
                                              />
                                            )} */}
                                            <a
                                              // href={elem.contentUrl}
                                              // download={elem.contentWithIndux}
                                              onClick={() =>
                                                openIframe(elem?.contentUrl)
                                              }
                                              style={{
                                                textDecoration: "none",
                                                fontSize: "1vw",
                                                color: "grey",
                                                left: "20px",
                                                position: "absolute",
                                                width: "260px",
                                                whiteSpace: "nowrap",
                                                direction: "rtl",
                                                overflow: "auto",
                                                textOverflow: "ellipsis",
                                                // word wrap style (...)
                                                inlineSize: "200px",
                                                overflowWrap: "break-word",
                                                hyphens: "manual",
                                              }}
                                              data-toggle="tooltip"
                                              title={elem.contentWithIndux}
                                            >
                                              {elem.contentWithIndux.length > 20
                                                ? elem.contentWithIndux.slice(
                                                  0,
                                                  15
                                                ) + "...."
                                                : elem.contentWithIndux}
                                            </a>
                                            <span
                                              style={{
                                                position: "absolute",
                                                right: "-21px",
                                                marginTop: "-7px",
                                              }}
                                            >
                                              <DeleteIconButton
                                                onClick={() => {
                                                  // showDeleteModal(true);
                                                  // setSelectedItem(elem);
                                                  handleOpen(elem);
                                                }}
                                              >
                                                <Tooltip
                                                  title="Remove"
                                                  placement={"top"}
                                                >
                                                  <img
                                                    style={{
                                                      width: "38px",
                                                    }}
                                                    className={
                                                      dashboardContentStyles.aRoleActionBtn
                                                    }
                                                    src={action__Delete}
                                                    alt="Dlt-Btn"
                                                  />
                                                </Tooltip>
                                              </DeleteIconButton>
                                            </span>
                                            {/* <i style={{position:"absolute",right:"10px"}} className="bi bi-download"></i> */}
                                          </div>
                                        </AccordionSummary>
                                      </Accordion>
                                    );
                                  })
                                ) : (
                                  <p>-</p>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </>
                        );
                      })
                    ) : (
                      <p>-</p>
                    )}

                    {/* <div
                      style={{
                        position: "absolute",
                        left: "100px",
                        bottom: "-225px",
                      }}
                    >
                      <button
                        className={fileApprovalStyles.orange_btn}
                        onClick={(e) => {
                          rejecteModal();
                        }}
                      >
                        REJECT
                      </button>
                      <button
                        style={{ color: "white", backgroundColor: "#FD8C00" }}
                        className={fileApprovalStyles.orange_btn}
                        onClick={(e) => {
                          approveModal();
                        }}
                      >
                        APPROVE
                      </button>
                    </div> */}
                  </div>
                </div>
              </TableBody>
            </Table>
          </div>
          <div onContextMenu={handleRightClick}>
          {url && (<ContentViewer contentstyle={{  height: "700px", width: "1160px" }}
              url={url} />)}
           
          </div>
        </Paper>

        {/* content end */}
        {/* <ApprovalModal open={approveModel} approve={approve} />
        <RejectedModal
          open={rejecteModel}
          close={closerejectmodel}
          rejecte={rejecte}
        >
          <TextField
            label="Reason"
            rows={5}
            multiline
            style={{ width: 450 }}
            value={rejectComment}
            onChange={(event) => setrejecetComment(event.target.value)}
          />
        </RejectedModal> */}
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Content"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem.contentWithIndux}
        />
        <div
          className="box animate fadeInUp one"
          style={
            show
              ? {
                color: "red",
                position: "absolute",
                right: "10px",
                top: "10px",
              }
              : { display: "none" }
          }
        >
          <TopRightPopup
            style={{ color: "red", position: "absolute", right: "10px" }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentApprovalTeacherViewFile;
