import React, { useState, useEffect } from "react";
import quizStyles from "../../../css/quiz.module.css";
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
// import dashHome from "../../../../../../src/img/dashHome.svg";
import dashHome from "../../../../../src/img/dashHome.svg";
import { useDispatch, useSelector } from "react-redux";
import { set_quiz_type } from "../../../../actions/quizAction";
import axios from "axios";
import UpdatedAt from "../UpdatedAt";
import BreadCrumbs from "../../../js/RevisionAdmin/General/BreadCrumbs";

const QuizLandingPage = ({ onNext }) => {
  const [quizType, setQuizType] = useState([]);
  const local_data = JSON.parse(localStorage.getItem("quiz_data"));
  const { quiz_type } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();

 
  useEffect(() => {
    localStorage.setItem("quiz_data", JSON.stringify({}));

  }, []);


  useEffect(() => {
    const getQuiz = () => {
      const TOKEN = sessionStorage.getItem("token");
      axios
        .get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-types`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        })
        .then((res) => {
          setQuizType(res.data.data);
        });
    };
    getQuiz();
  }, []);

  const handleClick = (data) => {
    // console.log("quiz tyupe", data);
    try {
      localStorage.setItem(
        "quiz_data",
        JSON.stringify({
          ...local_data,
          quiz_type: data.quizType,
          quiz_type_id: data.id,
        })
      );
      dispatch(set_quiz_type(data.quizType));
      onNext();
    } catch (error) {
      alert(error);
    }
  };
  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
        <BreadCrumbs currentPage={"Master Concept"} />
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Master Concept</a>
          </div>
       <UpdatedAt /> */}
        </article>

				<div>
					<center style={{ marginTop: "95px" }}>
						<p style={{ color: "#354052" }}>Select Quiz </p>
						<p
							style={{
								marginBottom: "33px",
								borderBottom: "3px solid #354052",
								width: "56px",
							}}
						></p>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								maxWidth: "60%",
								flexWrap: "wrap",
							}}
						>
							{quizType?.length > 0 ? quizType.map((data, index) => (
								<div className={quizStyles.quizBox} key={data.id}>
									<p
										style={{
											color: "#354052",
											fontSize: "1.7vw",
										}}
									>
										{data.quizType}
									</p>
									<button onClick={() => handleClick(data)}>
										Select
									</button>
								</div>
							)):<div style={{color:'red'}}>No data</div>}
						</div>
					</center>
				</div>


        {/* search row start  */}
      </div>
    </>
  );
};

export default QuizLandingPage;
