import React from 'react';
import { TreeSelect } from 'antd';
import { useState } from 'react';
import Studentprofile from '../../pages/css/Studentpg311.module.css'
const { SHOW_ALL } = TreeSelect;
// const treeData = [
//   {
//     title: 'Node1',
//     value: '0-0',
//     key: '0-0',
//     children: [
//       {
//         title: 'Child Node1',
//         value: '0-0-0',
//         key: '0-0-0',
//       },
//     ],
//   },
//   {
//     title: 'Node2',
//     value: '0-1',
//     key: '0-1',
//     children: [
//       {
//         title: 'Child Node3',
//         value: '0-1-0',
//         key: '0-1-0',
//       },
//       {
//         title: 'Child Node4',
//         value: '0-1-1',
//         key: '0-1-1',
//       },
//       {
//         title: 'Child Node5',
//         value: '0-1-2',
//         key: '0-1-2',
//       },
//     ],
//   },
// ];
const TreeSelectComponent = ({treeData,onChange,value}) => {
//   const [value, setValue] = useState([]);
  const tProps = {
    treeData,
    onChange,
    value,
    treeCheckable: true,
    className:Studentprofile.treeSelect,
    showCheckedStrategy: SHOW_ALL,   
    placeholder: 'School & Branch Name',
    // style: {
    //   width: '94%',
    //   overflowY: 'auto !important',
    //   height: '48px !important',
    // },
  };
  // console.log(value,"value")
  return <TreeSelect {...tProps} />;
};
export default TreeSelectComponent;