import React, { useEffect, useState } from "react";
import ClassRoundedIcon from "@mui/icons-material/ClassRounded";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { SearchOutlined } from "@mui/icons-material";
import GreenAddButton from "../../components/common/GreenAddButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { categoryData, MenuProps, taxonomyData } from "./Data";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import CommonDeleteModal from "./CommonDeleteModal";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import ConfirmationModal from "./ConfirmationModal";
import axios from "axios";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
//Action Icon Images
import UpdatedModal from "./UpdatedModal";
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort_1.png";
import commonsort from "../js/CommonSort"

const Taxonomy = () => {
  const [updatedData, setUpdatedData] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const [visibleAddTaxonomy, setVisibleAddTaxonomy] = useState(false);
  const [categoryList, setCategoryList] = useState();
  const [taxonomy, setTaxonomy] = useState("");
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const token = sessionStorage.getItem("token");
  const [selectedItem, setSelectedItem] = useState("");
  const [modifiedDate, setModifiedDate] = useState({});
  const [validation, setValidation] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [message, setMessage] = useState();
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const navigate = useNavigate();

  //sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");

  const [search, setSearch] = useState({
    search: " ",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    initData(search);
  };

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    initData(search);
    return () => { };
  }, [sortOrder]);
  const initData = async (search) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL
      }v1/api/content/taxonomy/page?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${(search?.search, " ")
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.data.data.length > 0) {
      data.data.data = await commonsort(data.data.data || [], sortBy, sortOrder)
      setUpdatedData(data.data.data);
      setPage(0);
    } else {
      setUpdatedData([]);
    }
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy-categories/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.data.length > 0) {
      setCategoryList(response.data.data);
    } else {
      setCategoryList([]);
    }
  };
  const openAddTaxonomy = () => {
    setVisibleAddTaxonomy(true);
  };
  const closeAddTaxonomy = () => {
    setVisibleAddTaxonomy(false);
    setCategory("");
    setTaxonomy("");
    setIsEdit(false);
    setSelectedItem("");
  };
  const showDeleteModal = async (item) => {
    setSelectedItem(item);
    setTimeout(() => {
      setOpenDeleteModal(true);
    }, 500);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/confirmation-api?id=${item.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMessage(response?.data?.data?.message);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/${selectedItem?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        initData(search);
        hideDeleteModal();
      })
      .catch((error) => {
        // console.log(error);
        hideDeleteModal();
      });
  };
  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.taxonomy?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
  };
  const [taxonomyNameCreate, setTaxonomyNameCreate] = useState("")
  const sortData = (property) => { };
  const addTaxonomyHandler = (taxonomy, category) => {
    if (!taxonomy || !category) {
      setTimeout(() => {
        setValidation(true);
      }, 500);
      return;
    }
    const data = {
      taxonomyName: taxonomy,
      description: taxonomy,
      taxonomyCategoriesId: category,
    };
    setTaxonomyNameCreate(data.taxonomyName)
    axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {

        if (response.status == 200) {
          initData(search);
          closeAddTaxonomy();
          setValidationMessage(null);
        }
        setTimeout(() => {
          setSuccessCreateModal(true)
        }, 500);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          setValidationMessage(error?.response?.data?.message);
          setTimeout(() => {
            setValidation(true);
          }, 500);
        } else {
          setValidationMessage("Failed to Add Category");
          setTimeout(() => {
            setValidation(true);
          }, 500);
        }
      });
  };
  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/toggle-active?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          handleConfirmationClose();
        }
      })
      .catch((err) => console.log(err));
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e.target.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/toggle-active?id=${id}&active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setFilterValue(clone);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const [taxonomyNameUpdate, setTaxonomyNameUpdate] = useState("")
  const editHandler = (taxonomy, category) => {
    if (!taxonomy || !category) {
      setTimeout(() => {
        setValidation(true);
      }, 500);
      return;
    }
    const data = {
      taxonomyName: taxonomy,
      description: taxonomy,
      taxonomyCategoriesId: category,
    };
    setTaxonomyNameUpdate(data.taxonomyName)
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/${selectedItem?.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {

        initData(search);
        closeAddTaxonomy();
        setTimeout(() => {
          setSuccesUpdatesModal(true)
        }, 500);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const editClickHandler = (item) => {
    openAddTaxonomy();
    setIsEdit(true);
    setTaxonomy(item.taxonomyName);
    setCategory(item.taxonomyCategoryId);
    setSelectedItem(item);
  };
  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)
  const [successCreateModal, setSuccessCreateModal] = useState(false)
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }

  const closeModal = () => {
    setSuccesUpdatesModal(false)
  }

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );


  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "}
          <a>Taxonomy</a> */}
          <span
            className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          >
            Taxonomy
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {visibleAddTaxonomy && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ClassRoundedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Taxonomy " : "Add Taxonomy"}
                </div>
              </div>
              <Paper>
                <div
                  style={{
                    display: "flex",
                    padding: "20px 20px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ width: 300 }}>
                      <TextField
                        id="demo-helper-text-aligned-no-helper"
                        label="*Taxonomy"
                        autoComplete="off"
                        style={{ fontSize: "15px", width: "300px" }}
                        sx={{
                          fontSize: "14px !important",
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              border: "1px solid #272727 !important"
                            }
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "orange !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#B5B6B6 !important",
                            fontSize: "14px"
                          },
                          "& .MuiInputBase-root": {
                            height: "48px !important",
                            width: "270px !important",
                            borderRadius: "150px !important",
                          },

                          "& .MuiInputBase-input": {
                            color: "#ffa500",

                          }
                        }}
                        value={taxonomy}
                        onChange={(e) => {
                          setTaxonomy(e.target.value);
                          setValidationMessage(null);
                        }}
                      />
                      {validation && !taxonomy && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Taxonomy is Required
                        </span>
                      )}
                      {validationMessage && (
                        <span style={{ color: "red" }}>
                          {validationMessage}
                        </span>
                      )}
                    </FormControl>
                    <FormControl sx={{ width: 300 }}>
                      <InputLabel id="demo-simple-select-label">
                        *Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        input={<OutlinedInput label="Tag" />}
                        MenuProps={MenuProps}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                          borderRadius: "150px"
                        }}

                      >
                        {categoryList?.map((item) => (
                          <MenuItem key={item.id} value={item.id}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={item?.taxonomyCategory} />
                          </MenuItem>
                        ))}
                      </Select>
                      {validation && category.length == 0 && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Category is Required
                        </span>
                      )}
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => closeAddTaxonomy()}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton
                        onClick={() =>
                          editHandler(taxonomy, category, selectedItem.id)
                        }
                      >
                        Update
                      </BlueButton>
                    ) : (
                      <BlueButton
                        onClick={() => addTaxonomyHandler(taxonomy, category)}
                      >
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </Paper>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <ClassRoundedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                Taxonomy List
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Search...."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    opacity: 1,
                    font: "normal normal normal 13px/20px Poppins !important",
                  },
                }}
                Value={search.search}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!visibleAddTaxonomy && (
                <GreenAddButton onClick={() => openAddTaxonomy()}>
                  <AddCircleOutlineOutlinedIcon
                    style={{ marginRight: "10px" }}
                  />
                  Add Taxonomy
                </GreenAddButton>
              )}
            </div>
          </div>
          <Paper>
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{
                maxHeight: 640,
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #{" "}
                      <i
                        // className="fa-solid fa-arrows-up-down"
                        onClick={() => sortData("id")}
                      ></i>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Taxonomy
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "2px 0 5px 4px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("taxonomyName");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                      <i
                        // className="fa-solid fa-arrows-up-down"
                        onClick={() => sortData("taxonomy")}
                      ></i>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Category
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "1px 0 4px 8px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("taxonomyCategory");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                      <i
                        // className="fa-solid fa-arrows-up-down"
                        onClick={() => sortData("category")}
                      ></i>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                        paddingRight: 60,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => {
                      return (
                        <TableRow
                          key={item?.id}
                          sx={{
                            // "&:last-child td, &:last-child th": { border: 0 },

                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                            "&:last-child td, &:last-child th": {
                              borderBottom: "none !important",
                            },

                            "&:hover": {

                              "& td, & th": {
                                borderTop: "1px solid #ff9702",
                                borderBottom: "1px solid #ff9702",
                              },
                              " & th:first-of-type": {
                                borderLeft: "1px solid #ff9702",
                              },

                              "& td:first-of-type": {
                                borderLeft: "0",

                              },

                              "&:last-child td, &:last-child th": {
                                borderBottom: "1px solid #ff9702 !important",
                              },

                              "& td:last-of-type": {
                                borderRight: "1px solid #ff9702",

                              },

                              "& th:last-of-type": {
                                borderRight: "0",
                              },
                            },

                            "& td, & th": {
                              border: "1px solid transparent",
                              borderTop: "1px solid transparent",
                              borderBottom: "1px solid transparent",
                              "&:not(:last-child)": {
                                borderRight: "1px solid transparent",
                              },
                            },
                          }}

                          // hover={true}
                          // style={{
                          //   background: "#f9f8f8",
                          // }}
                          className={dashboardContentStyles.table_rw}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={dashboardContentStyles.table_cell}
                          >
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cellll}
                          >
                            {item?.taxonomyName}
                          </TableCell>
                          <TableCell align="center">
                            {item?.taxonomyCategory}
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              <Switch
                                checked={item?.active}
                                onChange={(e) => switchHandler(item?.id, e)}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <EditIconButton
                              onClick={() => {
                                editClickHandler(item);
                              }}
                            >
                              <CustomTooltip title="Edit" placement={"top"}>
                                {/* <EditOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Edit}
                                  alt="Edit-Btn"
                                />
                              </CustomTooltip>
                            </EditIconButton>
                            <DeleteIconButton
                              onClick={() => {
                                showDeleteModal(item);
                              }}
                            >
                              <CustomTooltip title="Remove" placement={"top"}>
                                {/* <DeleteOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Delete}
                                  alt="Dlt-Btn"
                                />
                              </CustomTooltip>
                            </DeleteIconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterValue?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Taxonomy"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal
          userType={"Taxonomy"}
          userName_Modal={
            taxonomyNameCreate
          }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <UpdatedModal
          userType={"Taxonomy"}
          userName_Modal={
            taxonomyNameUpdate
          }
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />
      </div>
    </>
  );
};
export default Taxonomy;
