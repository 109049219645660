import axios from 'axios';

async function create_question_post(
    payload,
    setShowSuccessAlert,
    setResponseValue,
    setShowErrorAlert,
    setShowErroMessage,
    callback
) {

    if (payload.question !== "") {
        try {
            let token = sessionStorage.getItem("token");
            // console.log("payload", payload)
            let response = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`, payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
            // console.log("response data", response.data.data);
            sessionStorage.setItem("newQuestion", JSON.stringify(response.data.data));
            setShowSuccessAlert(true);
            setResponseValue(response.data.data);
            response.data.data.payload = payload;
            if (callback) {
                callback(response.data.data);
            }
        } catch (error) {
            // console.log(error, "errrpppppp");
            if (setShowErrorAlert) {
                setShowErrorAlert(true);
            }
            if (setShowErroMessage) {
                setShowErroMessage(error?.response?.data?.message || "error");
            }
            setResponseValue({ error });
            if (callback) {
                callback(error?.response)
            }
        }
    } else {
        callback({ error: "504", errorMessage: "Please fill the Required fields" });
    }



}

export default create_question_post;
