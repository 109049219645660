import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Divider, Paper } from "@mui/material";
import { useSelector } from "react-redux";

function EditableQuizModal({
  openEditableQuizModal,
  handleCloseEditableQuizModal,
  theQuizData,
}) {
  const { selected_questions } = useSelector((state) => state.quiz);
  let token = sessionStorage.getItem("token");
  const [editedData, setEditedData] = useState({
    name: "",
    totalMarks: "",
    duration: "",
  });
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const navigate = useNavigate();
  localStorage.setItem("editedData", JSON.stringify(editedData));
  const local_data = JSON.parse(localStorage.getItem("quiz_data"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

  const Gridstyle = {
    width: "100%",
    marginTop: "40px",
  };

  const warningModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    borderRadius: 2,
    p: 4,
  };

  useEffect(() => {
    setEditedData(theQuizData);
  }, [theQuizData]);

  // console.log("--->editedData", editedData);
  // console.log("--->local_data", local_data);

  const handleEditChange = (field, value) => {
    if (field === "totalMarks" || field === "duration") {
      value = parseInt(value, 10);
    }
    setEditedData((prevData) => ({ ...prevData, [field]: value }));
  };

  const generatQuestionList = () => {
    const questionList = [];

    editedData?.quizQuestions?.forEach((item, index) => {
      questionList.push({
        chapterId: local_data?.selected_chapter?.id || item.chapterId,
        questionId: item.questionId,
        insertionOrder: index + 1,
        // active: true,
        // removed: true,
      });
      // const presentid = editedData.quizQuestions.find(
      //   (question) => question.questionId === question.questionId
      // );
      // if (presentid) theQuestion.id = presentid.id;
      // questionList.push(theQuestion);
    });

    // editedData.quizQuestions.forEach((question) => {
    //   const present = questionList.find((qstn) => qstn.id === question.id);
    //   if (!present)
    //     questionList.push({
    //       chapterId: question.chapterId,
    //       id: question.id,
    //       questionId: question.questionId,
    //       active: question.active,
    //       removed: false,
    //     });
    // });
    return questionList;
  };

  const updateQuizData = async (event) => {
    event.preventDefault();
    let items = {
      id: editedData.id,
      name: editedData.name,
      boardId: editedData.boardId,
      gradeId: editedData.gradeId,
      subjectId: editedData.subjectId,
      subTopicId: editedData.subTopicId,
      academicYearId: editedData.academicYearId,
      quizTypeId: editedData.quizTypeId,
      totalQuestions: editedData.totalQuestions,
      totalMarks: editedData.totalMarks,
      withTiming: editedData.withTiming,
      duration: editedData.duration,
      quizQuestions: generatQuestionList(),
      containsCaseStudy: editedData.containsCaseStudy,
      active: true,
      boy: editedData.boy,
      fromGradeId: editedData.fromGradeId,
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz`,
        items,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    
      navigate(`/dashboard/create%20quiz?isEdit=true&score=${editedData.totalMarks}`);
    } catch (error) {
      // console.error("Error fetching data:", error);
      setWarningModalOpen(true);
    }
  };

  const closeWarningModal = () => {
    setWarningModalOpen(false);
  };

  return (
    <>
      <div>
        <Modal
          open={openEditableQuizModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              style={{ color: "orange", fontSize: "20px", fontWeight: "bold" }}
            >
              Update {theQuizData.quizType}
            </Typography>

            <Divider sx={{ marginTop: "10px" }} />

            <Box sx={Gridstyle}>
              <Grid
                container
                rowSpacing={5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {/*-------| Quiz Name Field |-------*/}
                <Grid xs={6}>
                  <TextField
                    id="outlined-basic"
                    label={
                      <span style={{ fontSize: "18px" }}>Quiz Name *</span>
                    }
                    variant="outlined"
                    value={editedData.name}
                    onChange={(event) =>
                      handleEditChange("name", event.target.value)
                    }
                  />
                </Grid>

                {/*-------| Total Marks Field |-------*/}
                <Grid xs={6}>
                  <TextField
                    id="outlined-basic"
                    label={
                      <span style={{ fontSize: "18px" }}>Total Marks *</span>
                    }
                    variant="outlined"
                    type="number"
                    value={editedData.totalMarks}
                    onChange={(event) =>
                      handleEditChange("totalMarks", event.target.value)
                    }
                  />
                </Grid>

                {/*-------| Duration Field |-------*/}
                <Grid xs={6}>
                  <TextField
                    id="outlined-basic"
                    label={<span style={{ fontSize: "18px" }}>Duration *</span>}
                    variant="outlined"
                    type="number"
                    value={editedData.duration}
                    onChange={(event) =>
                      handleEditChange("duration", event.target.value)
                    }
                  />
                </Grid>

                {/*-------| Academic Year Field |-------*/}
                <Grid xs={6}>
                  <TextField
                    disabled
                    id="outlined-basic"
                    label={
                      <span style={{ fontSize: "18px" }}>Selected Year</span>
                    }
                    variant="outlined"
                    value={editedData.academicYear}
                  />
                </Grid>
              </Grid>
              {/*-------| Button Section |-------*/}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  padding: "20px",
                }}
              >
                <div>
                  <button
                    style={{
                      width: "85px",
                      height: "35px",
                      fontSize: "15px",
                      background: "white",
                      border: "1px solid gray",
                      borderRadius: "6px",
                    }}
                    onClick={handleCloseEditableQuizModal}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <Link to="/dashboard/create%20quiz?isEdit=true">
                    <button
                      style={{
                        width: "85px",
                        height: "35px",
                        fontSize: "15px",
                        color: "white",
                        background: "blue",
                        border: "1px solid blue",
                        borderRadius: "6px",
                      }}
                      // onClick={async () => {
                      //   try {
                      //     await updateQuizData();
                      //   } catch (error) {
                      //     console.error('Error fetching data:', error);
                      //   }
                      // }}
                      onClick={updateQuizData}
                    >
                      Update
                    </button>
                  </Link>
                </div>
              </div>
            </Box>
          </Box>
        </Modal>
      </div>

      {/*-------| Warning Modal |-------*/}
      <Modal open={warningModalOpen}>
        <Box sx={warningModalStyle}>
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Typography style={{ fontSize: "18px" }}>
              Updation of the released quiz is not allowed.
            </Typography>

            <Button
              onClick={closeWarningModal}
              style={{
                width: "120px",
                height: "38px",
                background: "#FD8C00",
                color: "white",
                marginTop: "30px",
                fontSize: "16px",
              }}
            >
              Close
            </Button>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}

export default EditableQuizModal;
