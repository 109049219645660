import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AnnualSelectSection from "./AnnualSelectSection";
import BreadCrums from "./BreadCrums";
import QuizCard from "./QuizCard";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import _ from "lodash";
import axios from "axios";
import CreateQuizModal from "./Modals/CreateQuizModal";
import { isNOTNullOrUndefined } from "../../../../utils/helper";

function MultiChaptersSelect({ onNext, quiz }) {
  const local_data = JSON.parse(localStorage.getItem("quiz_data"));
  const [selectedQuizes, setSelectedQuizes] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedFromGrade, setSelectedFromGrade] = useState(null);
  const [boyChecked, setBoyChecked] = useState(false);
  const [selectedSubTopic, setSelectedSubTopic] = useState([]);
  const [quizList, setQuizList] = useState([]);
//console.log("====================================");
//console.log(selectedSubTopic);
//console.log("====================================");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const TOKEN = sessionStorage.getItem("token");
  const BASE_URL = process.env.REACT_APP_SWAGGER_URL;

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${BASE_URL}v1/api/master/chapters/all-chapters?${
            isNOTNullOrUndefined(selectedBoard?.id)
              ? `boardId=${selectedBoard?.id}`
              : ""
          }		
				${
          isNOTNullOrUndefined(selectedSubject?.id)
            ? `&subjectId=${selectedSubject?.id}`
            : ""
        }${
            isNOTNullOrUndefined(selectedGrade?.id)
              ? `&gradeId=${selectedGrade?.id}`
              : ""
          }${
            isNOTNullOrUndefined(selectedSubTopic?.id)
              ? `&subTopicId=${selectedSubTopic.id}`
              : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        )
        .then((response) => {
          const { data } = response.data;
        //console.log(data, "data");
          setQuizList(data);
        })
        .catch((error) => console.error(error));
    };
    if (!boyChecked) {
      fetchData();
    }
  }, [
    selectedSubject,
    selectedBoard,
    selectedGrade,
    selectedYear,
    selectedSubTopic,
    selectedFromGrade,
    boyChecked,
  ]);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${BASE_URL}v1/api/master/chapters/all-chapters?${
            isNOTNullOrUndefined(selectedBoard?.id)
              ? `boardId=${selectedBoard?.id}`
              : ""
          }		
				${
          isNOTNullOrUndefined(selectedSubject?.id)
            ? `&subjectId=${selectedSubject?.id}`
            : ""
        }${
            isNOTNullOrUndefined(selectedFromGrade?.id)
              ? `&gradeId=${selectedFromGrade?.id}`
              : ""
          }${
            isNOTNullOrUndefined(selectedSubTopic?.id)
              ? `&subTopicId=${selectedSubTopic.id}`
              : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        )
        .then((response) => {
          const { data } = response.data;
        //console.log(data, "data");
          setQuizList(data);
        })
        .catch((error) => console.error(error));
    };
    if (boyChecked) {
      fetchData();
    }
  }, [
    selectedSubject,
    selectedBoard,
    selectedYear,
    selectedSubTopic,
    selectedFromGrade,
    boyChecked,
  ]);

  function handleRemove(data) {
    setSelectedSubTopic(selectedSubTopic.filter((item) => item.id !== data.id));
  }
//console.log(selectedQuizes, "tesssszsst");
  const handleSelect = (data) => {
    const index = selectedQuizes.findIndex((element) => element.id === data.id);
    if (index !== -1) {
      setSelectedQuizes(selectedQuizes.filter((item) => item.id !== data.id));
    } else {
      setSelectedQuizes(_.uniqWith([...selectedQuizes, data], _.isEqual));
    }
  };

  const filterChapter = (
    arr,
    subject,
    board,
    grade,
    academicYear,
    subTitle
  ) => {
    let filterData = arr.filter(
      (data) =>
        data.subject === subject &&
        data.board === board &&
        data.grade === grade &&
        data.academicYear === academicYear &&
        data.subTitle === subTitle
    );
    return filterData;
  };

  function renderButton(item) {
    const index = selectedQuizes.findIndex((element) => element.id === item.id);
    if (index !== -1) {
      return (
        <Tick>
          <DoneOutlinedIcon />
        </Tick>
      );
    } else {
      return <Tick></Tick>;
    }
  }

  useEffect(() => {
  //console.log(quizList, "list");
  }, [quizList]);

  const onCreate = () => {
    const selectedChapters = selectedQuizes;

    try {
      localStorage.setItem(
        "quiz_data",
        JSON.stringify({
          ...local_data,
          selected_chapters: selectedChapters,
        })
      );
      setIsModalOpen(true);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Container>
      <BreadCrums
        currentPage={"create quizz"}
        date={"23 Dec 2021"}
        time={"10:30 AM"}
      />
      <AnnualSelectSection
        selectedSubject={selectedSubject}
        setSelectedSubject={setSelectedSubject}
        title={"Create quizz"}
        selectedBoard={selectedBoard}
        setSelectedBoard={setSelectedBoard}
        selectedGrade={selectedGrade}
        setSelectedGrade={setSelectedGrade}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedSubTopic={selectedSubTopic}
        setSelectedSubTopic={setSelectedSubTopic}
        onCreateClick={() => onCreate()}
        selectedFromGrade={selectedFromGrade}
        setSelectedFromGrade={setSelectedFromGrade}
        btnDisabled={selectedQuizes.length > 0 ? false : true}
        quiz={quiz}
        setBoyChecked={setBoyChecked}
        boyChecked={boyChecked}
      />

      {/* it was for showing multiple selected sub topics */}
      {/* {selectedSubTopic?.length > 0 && (
				<SelectedQuiz>
					{selectedSubTopic?.map((item, i) => (
						<Quiz onClick={() => handleRemove(item)} key={i}>
							<CloseOutlinedIcon />
							<p>{item.subTopic}</p>
						</Quiz>
					))}
				</SelectedQuiz>
			)} */}
      {selectedBoard && selectedGrade && selectedSubject && selectedYear ? (
        quizList.length > 0 ? (
          <QuizList>
            {quizList?.map((data, i) => (
              <CardCover onClick={() => handleSelect(data)} key={i}>
                <QuizCard img={data.thumbnailPath} title={data.chapter} />
                <SelectSection>
                  {renderButton(data)}
                  <p>Select</p>
                </SelectSection>
              </CardCover>
            ))}
          </QuizList>
        ) : (
          <p className="message">No Data</p>
        )
      ) : (
        <>
          {selectedGrade == selectedFromGrade ? (
            <p className="message">
              Previous and selected grade cannot be same
            </p>
          ) : (
            <p className="message">Select Grade, Board, Subject and Year</p>
          )}
        </>
      )}

      <CreateQuizModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmitPress={() => onNext()}
      />
    </Container>
  );
}

export default MultiChaptersSelect;
const Container = styled.div`
  padding: 20px;
  width: 100%;
  .message {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    color: red;
  }
`;
const QuizList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 30px;
  grid-gap: 20px;
`;
const CardCover = styled.div`
  cursor: pointer;
`;
const SelectSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 10px;
  p {
    font-size: 14px;
    margin-left: 5px;
    color: blue;
  }
`;
const Tick = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid blue;
  svg {
    display: block;
    width: 100%;
  }
`;
const SelectedQuiz = styled.div`
  margin-top: 30px;
  background-color: #fff;
  border: 1px solid #000;
  padding: 20px 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 20px;
  :before {
    content: "*Selected Subtopics";
    display: block;
    position: absolute;
    top: -14px;
    background: #fff;
    padding: 0 5px;
    font-size: 13px;
    color: #fd8c00;
  }
`;
const Quiz = styled.span`
  display: block;
  padding: 5px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #a6c7fc;
  border: 1px solid #d6d5df;

  color: #fff;
  border-radius: 20px;
  p {
    font-size: 14px;
    color: #fff;
  }
`;
