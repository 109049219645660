import React, { useContext, useEffect, useState } from 'react'
import mainContainerStyles from '../../../css/mainContainer.module.css'
import dashboardContentStyles from '../../../css/dashboardContent.module.css'
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField
} from '@mui/material'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined'
import { useLocation, useNavigate } from 'react-router-dom'
import searchIcn from '../../../../../src/img/Search.png'
import { isNOTNullOrUndefined } from '../../../../utils/helper'
import { Popover } from 'antd'
import NotificationList from '../../NotificationList'
import { NotificationContext } from '../../../../context/notification/NotificationContext'
{
  /*  copy of MainContainer.js for coordinator only : 9/may */
}

const MainContainer = props => {
  const [contentDevOrAdmin, setContentDevOrAdmin] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [topTitle, setTopTitle] = useState('Dashboard')
  const [openNotification, setOpenNotification] = useState(false)
  const open = Boolean(anchorEl)
  const location = useLocation()

  const notificationList = useContext(NotificationContext)
  const { notifications } = notificationList || {};

  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.split('/')
      const title = path.includes('ConceptTreeView')
        ? path[path.length - 2].replaceAll('%20', ' ')
        : path[path.length - 1].replaceAll('%20', ' ')
      setTopTitle(
        title === 'template'
          ? 'Dashboard'
          : title === 'content approval'
          ? 'Content Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'global training approval'
          ? 'Global Training Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'concept tree approval'
          ? 'Concept Tree Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'view concept tree'
          ? 'View Concept Tree'
          : title === 'template'
          ? 'Dashboard'
          : title === 'csp'
          ? 'Change Student Profile'
          : title === 'template'
          ? 'Dashboard'
          : title === 'branchplan'
          ? 'Plan'
          : title === 'template'
          ? 'Dashboard'
          : title === 'branchsection'
          ? 'Sections'
          : title === 'template'
          ? 'Dashboard'
          : title === 'teacherId'
          ? 'Academic Staff'
          : title === 'template'
          ? 'Dashboard'
          : title === 'student'
          ? 'Students'
          : title === 'template'
          ? 'Dashboard'
          : title === 'tokenList'
          ? 'Tokens'
          : title === 'template'
          ? 'Dashboard'
          : title === 'logo'
          ? 'Branch Logo'
          : title === 'template'
          ? 'Dashboard'
          : title === 'TokenList'
          ? 'Tokens'
          : title === 'template'
          ? 'Dashboard'
          : title === 'admin users'
          ? 'Admin Users'
          : title === 'template'
          ? 'Dashboard'
          : title === 'upload content'
          ? 'Upload Content'
          : title === 'template'
          ? 'Dashboard'
          : title === 'global training'
          ? 'Global Training'
          : title === 'template'
          ? 'Dashboard'
          : title === 'associate concepts'
          ? 'Associate Concepts'
          : title === 'template'
          ? 'Dashboard'
          : title === 'blue print'
          ? 'Blue Print'
          : title === 'template'
          ? 'Dashboard'
          : title === 'CreateManagement'
          ? 'Create Management'
          : title === 'template'
          ? 'Dashboard'
          : title === 'add Management'
          ? 'Add Management'
          : title === 'template'
          ? 'Dashboard'
          : title === 'academicid'
          ? 'Academic Id'
          : title === 'template'
          ? 'Dashboard'
          : title === 'createschool'
          ? 'Create School'
          : title === 'template'
          ? 'Dashboard'
          : title === 'CreateChapter'
          ? 'Create Chapter'
          : title === 'template'
          ? 'Dashboard'
          : title === 'AddTeacherFiles'
          ? 'Add Teacher Files'
          : title === 'template'
          ? 'Dashboard'
          : title === 'CreateTrainingCategory'
          ? 'Create Training Category'
          : title === 'template'
          ? 'Dashboard'
          : title === 'CreateTraining'
          ? 'Create Training'
          : title === 'template'
          ? 'Dashboard'
          : title === 'addBranch'
          ? 'Branches'
          : title === 'template'
          ? 'Dashboard'
          : title === 'addbranch'
          ? 'Branches'
          : title === 'template'
          ? 'Dashboard'
          : title === 'GlobalTrainingApprovalApproved'
          ? 'Global Training Approval '
          : title === 'template'
          ? 'Dashboard'
          : title === 'Edit Concept'
          ? 'Create/Edit Concept'
          : title === 'template'
          ? 'Dashboard'
          : title === 'StudentFileApproval'
          ? 'Student File Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'TeacherFileApproval'
          ? 'Teacher File Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'Content Approval'
          ? 'Content Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'GlobalTrainingApprovalRejected'
          ? 'Global Training Approval '
          : title === 'template'
          ? 'Dashboard'
          : title === 'ConceptTreeApprovalRejected'
          ? 'Concept Tree Approval '
          : title === 'template'
          ? 'Dashboard'
          : title === 'ConceptTreeApprovalPending'
          ? 'Concept Tree Approval '
          : title === 'template'
          ? 'Dashboard'
          : title === 'GlobalTrainingApprovalApproved'
          ? 'Global Training Approval '
          : title === 'template'
          ? 'Dashboard'
          : title === 'ContentApprovalRejected'
          ? 'Content Approval '
          : title === 'template'
          ? 'Dashboard'
          : title === 'ContentApprovalApproved'
          ? 'Content Approval '
          : title === 'template'
          ? 'Dashboard'
          : title === 'CreateTeacher'
          ? 'Add Academic Staff'
          : title === 'template'
          ? 'Dashboard'
          : title === 'Edit Sub & Root Concepts'
          ? 'Create/Edit Sub & Root Concepts'
          : title === 'template'
          ? 'Dashboard'
          : title === 'contentapprovalviewfile'
          ? 'Content Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'ContentApprovalTeacherViewFile'
          ? 'Content Approval'
          : title === 'template'
          ? 'Dashboard'
          : title === 'study'
          ? 'Study'
          : title === 'template'
          ? 'Dashboard'
          : title === 'enrich'
          ? 'Enrich'
          : title === 'template'
          ? 'Dashboard'
          : title === 'teacherid'
          ? 'Academic Staff'
          : title
      )
    }
  }, [location.pathname])
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e, iconValue) => {
    //
    const value = e.target.innerText
    if (value === 'Profile' || iconValue === 'Profile') {
      navigate('/dashboard/PrincipalProfile', {
        state: { id: 1, value: 'profile' }
      })
    } else if (value === 'School Access' || iconValue === 'School Access') {
      navigate('/dashboard/PrincipalProfile', {
        state: { id: 3, value: 'School Access' }
      })
    } else if (value === 'Teacher Access' || iconValue === 'Teacher Access') {
      navigate('/dashboard/PrincipalProfile', {
        state: { id: 2, value: 'Teacher Access' }
      })
    }

    setAnchorEl(null)
  }
  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')

  const navigate = useNavigate()
  const tempMenu = localStorage.getItem('menus')

  const getTextShortForm = (text) => {
    const words = text.split("_");
    if (words.length === 1) {
      return words[0][0];
    } else {
      return words[0][0] + words[1][0];
    }
  };

  const headerStyle={
    background:'#403e75',
    width: '101%',
    // height: "10vh",
    height: "11.5%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: '-15px', paddingLeft: '15px'
  }

  const handleNotification = () => {
    // console.log("notification click");
    setOpenNotification(!openNotification)
  }
  return (
    <div className={mainContainerStyles.main_container}>
      <header
        style={headerStyle}
      >
        <>
          <h3 className={mainContainerStyles.header_title}>
            {/* Welcome to {firstName} {lastName} */}
          </h3>
          <div className={mainContainerStyles.header_teacher}>
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                '& .MuiInputBase-root': {
                  left: '-48px',
                  width: '342px',
                  height: '44px',
                  background: ' #F3F2FF',
                  borderRadius: '10px',
                  opacity: 1
                }
              }}
              type={'text'}
              placeholder={'Search anything....'}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: 'top',
                        fontSize: 'large',
                        color: '#27334E'
                      }}
                      aria-hidden='true'
                    />
                  </IconButton>
                )
              }}
              size={'small'}
            />
                <Popover
              content={<NotificationList/>}
              placement="bottom"
              title=""
              trigger="click"
              open={openNotification}
              onOpenChange={handleNotification}
            >

            <div className={mainContainerStyles.teacher_badge}>
              <Badge
                variant='dot'
                sx={{ color: '#403b97', fontSize: '1.5vw' }}
                color={'secondary'}
                id="notification-badge"
                className="cursorPointer"
              >
                <NotificationsNoneOutlinedIcon style={{ fontSize: '1.5vw' }} />
              </Badge>
            </div>
            </Popover>
            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                columnGap: '10px',
                padding: '10px'
              }}
            >
              <Avatar
                sx={{
                  background: 'orange',
                  width: '2.8vw',
                  height: '2.8vw',
                  fontSize: '1.4vw'
                }}
              >
                {isNOTNullOrUndefined(firstName) ?  firstName[0]?.toUpperCase():''}
              </Avatar>
              <div className={mainContainerStyles.profile_Name}>
              <div  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",
                    font: "13px"
                  }}>
                  Welcome
                </div>
                <div className={mainContainerStyles.teacher_name}>
                {firstName} {lastName} {"("+getTextShortForm(localStorage.getItem("role"))+")"}
                </div>
               
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'end',
                  rowGap: '20px'
                }}
              >
                <div>
                  {open ? (
                    <KeyboardArrowUpIcon
                      style={{ fontSize: '1.5vw', color: 'black' }}
                    />
                  ) : (
                    <KeyboardArrowDownOutlinedIcon
                      style={{ fontSize: '1.5vw', color: 'black' }}
                    />
                  )}
                </div>
                <div />
              </div>
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={e => handleClose(e)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 20,
                    width: 10,
                    height: 12,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={e => handleClose(e)}>
                <ListItemIcon onClick={e => handleClose(e, 'Profile')}>
                  <AccountCircleOutlinedIcon
                    fontSize='small'
                    sx={{ color: '#403b97' }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ color: '#403b97' }}>Profile</ListItemText>
              </MenuItem>
              <MenuItem onClick={e => handleClose(e)}>
                <ListItemIcon onClick={e => handleClose(e, 'Teacher Access')}>
                  <AccountCircleOutlinedIcon
                    fontSize='small'
                    sx={{ color: '#403b97' }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ color: '#403b97' }}>
                  Teacher Access
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={e => handleClose(e)}>
                <ListItemIcon onClick={e => handleClose(e, 'School Access')}>
                  <ImportContactsOutlinedIcon
                    fontSize='small'
                    sx={{ color: '#403b97' }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ color: '#403b97' }}>
                  School Access
                </ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </>
      </header>
      <div className={dashboardContentStyles.dashboard}>{props.children}</div>
    </div>
  )
}

export default MainContainer