import { Modal } from "@mui/material";
import React from "react";
import "./ReasonModal.css";
import { Close } from "@material-ui/icons";

const BluePrintReasonModal = ({
  title,
  showReasonModal,
  onReasonModalClose,
  data,
}) => {
  return (
    // console.log(title, data, "reasonmodal"),
    < Modal
      sx={{ display: "grid", placeContent: "center" }
      }
      open={showReasonModal}
      onClose={onReasonModalClose}
    >
      <div className="msg-modal-body" style={{ width: "550px" }}>
        <div
          style={{
            borderBottom: "4px Solid #DAEBFF",
          }}
        >
          <p
            className="msg-modal-title"
            style={{
              marginBottom: "15px",
            }}
          >
            {title}
          </p>
        </div>

        {
          data?.rejectComments &&
          <p className="msg-modal-content" style={{ marginBottom: "0px" }}>
            {data?.rejectComments[data?.rejectComments?.length - 1]?.comment || ""}
          </p>}
        <p
          className="msg-modal-content"
          style={{ marginBottom: "0px", marginTop: "10px" }}
        >
          Rejected By : {" " + data?.rejectedBy + " , Content Reviewer"}
        </p>
        <p className="msg-modal-content" style={{ marginBottom: "0px" }}>
          Rejected At : {" " + data?.rejectedAt}
        </p>
        <div className="btn-container-modal">
          <button
            onClick={onReasonModalClose}
            className="btn-modal"
            type="button"
          >
            Ok
          </button>
        </div>

        <div onClick={onReasonModalClose} className="msg-modal-close-btn">
          <Close style={{ fontSize: "16px", color: "#ccc" }} />
        </div>
      </div>
    </Modal >
  );
};

export default BluePrintReasonModal;
