import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import sharestyle from "../css/shareModal.module.css"; //share modal css
import qbStyles from "./../css/questionbank.module.css";
import contentApprovalStyles from "../css/contetnApproval.module.css";
import tickImage from "./../img/tick-inside-a-circle.svg";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
// import fileStatus_pending_get from '../API_CONTROLLER/chapters-controller/chapters_controller_get';
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import fileStatus_pending_get from "../API_CONTROLLER/File-status-controller/fileStatus_pending_get";
import chapter_reason_by_id_get from "../API_CONTROLLER/chapters-controller/chapters_reason_by_id_get";
import Pagination1 from "./pagination1";
import quiz_paginated_get from "../API_CONTROLLER/quiz-controller/quiz_paginated_get";
import dashHome from "../../../src/img/dashHome.svg";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import searchIcn from "../../../src/img/search.svg";

const QbReviewApproved = () => {
  const [gradesName, setGradesName] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [lastmodifieddate, setLastModifiedDate] = useState("");
  const [quiztable, setQuiztable] = useState({});

  const [boardInput, setBoardInput] = useState("");
  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [showModalShare, setShowModalShare] = useState(false);

  const navigate = useNavigate();

  const handleShareModal = () => {
    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };
  // searching
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    e.preventDefault();
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    fileStatus_pending_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      boardInput
    );
  };

  useEffect(() => {
    if (search.search === "") {
      fileStatus_pending_get(
        setChapters,
        rowsInput,
        page,
        setPageDetail,
        search,
        gradeInput,
        subjectInput,
        subTopicInput,
        boardInput
      );
    }
    return () => {};
  }, [rowsInput, search]);

  useEffect(() => {
    fileStatus_pending_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      boardInput
    );
  }, [rowsInput, page]);

  useEffect(() => {
    fileStatus_pending_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      boardInput
    );
    return () => {};
  }, [search, gradeInput, subjectInput, subTopicInput, boardInput]);

  useEffect(() => {
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    chapters_last_modified_get(setLastModifiedDate);
    quiz_paginated_get(setQuiztable);
    return () => {};
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        {/* article start  */}
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>

            {"  "}
            <a>Content Approval</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {lastmodifieddate.data}
            </p>
          </div>
        </article>
        {/* article end  */}
        {/* drop down search row start  */}
        <div
          className={contentApprovalStyles.flex}
          style={{ marginTop: "55px", position: "relative" }}
        >
          <div className={contentApprovalStyles.flex}>
            <i
              style={{ color: "grey", fontSize: "36px", margin: "10px" }}
              className="fa-solid fa-user-gear"
            ></i>
            <p
              style={{ color: "grey ", fontSize: "1.2vw", margin: "auto 10px" }}
            >
              Question Bank Pending
            </p>
          </div>
          <div style={{ display: "flex", marginLeft: "15px" }}>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  className={qbStyles.select_input}
                  id="demo-select-small"
                >
                  Board
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Board"
                  //   onChange={selectBoardHandler}
                  style={{ background: "#fff", borderRadius: 150 }}
                  //   MenuProps={MenuProps}
                >
                  {/* <MenuItem value={"all"}>All</MenuItem> */}
                  {board.length > 0 ? (
                    board.map((board, i) => {
                      return (
                        <MenuItem key={i} value={board.board}>
                          <ListItemText primary={board.board} />
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 150 }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  className={qbStyles.select_input}
                  id="demo-select-small"
                >
                  Grade
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Grade"
                  value={gradeInput}
                  onChange={(e) => setGradeInput(e.target.value)}
                  style={{ background: "#fff", borderRadius: 150 }}
                  //   MenuProps={MenuProps}
                >
                  <MenuItem value="">All</MenuItem>
                  {/* {optionsRender(gradesName)} */}
                  {gradesName.length > 0 ? (
                    gradesName?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText primary={item.grade} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 180 }}>
              <FormControl fullWidth size="small" style={{ width: "12vw" }}>
                <InputLabel
                  className={qbStyles.select_input}
                  id="demo-select-small"
                >
                  Select subject
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={subjectInput}
                  label="subject"
                  onChange={(e) => setSubjectInput(e.target.value)}
                  style={{ background: "#fff", borderRadius: 150 }}
                  //   MenuProps={MenuProps}
                >
                  <MenuItem value="">All</MenuItem>

                  {subject.length > 0 ? (
                    subject.map((subject, i) => {
                      return (
                        <MenuItem key={i} value={subject.id}>
                          <ListItemText primary={subject.subject} />
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div style={{ position: "absolute", display: "flex", right: "10px" }}>
            <TextField
              type={"search"}
              name="search"
              // type='search'
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  font: "normal normal normal 13px/20px Poppins !important",
                  opacity: 1,
                },
              }}
              placeholder={"Search...."}
              onChange={handleSearch}
              Value={search.search}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                    />
                  </IconButton>
                ),
              }}
            />
          </div>
        </div>
        {/* drop down search row  end  */}

        {/* content start  */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 15px",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "49px",
              backgroundColor: "white",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                display: "flex",
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid lightgrey",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/QbReviewPending");
                }}
              >
                PENDING
              </p>
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid lightgrey",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/QbReviewRejected");
                }}
              >
                REJECTED
              </p>
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  color: "#FD8C00",
                  borderRight: "1px solid lightgrey",
                  borderBottom: "7px solid #FD8C00",
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                APPROVED
              </p>
            </div>
          </div>
        </Paper>

        {/* content start  */}

        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          style={{ marginLeft: "15px" }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  >
                    Quizzes Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  >
                    Board
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  >
                    Grade
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  >
                    Subject
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  >
                    Subtopic
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  >
                    Chapter
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      background: "#d9dcfe",
                    }}
                  ></TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: "blue",
                      fontWeight: 600,
                      paddingRight: "60px",
                      background: "#d9dcfe",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quiztable && quiztable.length > 0 ? (
                  quiztable?.map((elem, i) => (
                    <TableRow>
                      {/* index */}
                      <TableCell className={contentApprovalStyles.table_cell}>
                        {i + 1}
                      </TableCell>
                      {/* index */}
                      <TableCell className={contentApprovalStyles.table_cell}>
                        {elem.name}
                      </TableCell>
                      {/* index */}
                      <TableCell
                        className={contentApprovalStyles.table_cell}
                        align="center"
                      >
                        {elem.board}
                      </TableCell>
                      {/* index */}
                      <TableCell
                        className={contentApprovalStyles.table_cell}
                        align="center"
                      >
                        {elem.grade}
                      </TableCell>
                      {/* index */}
                      <TableCell
                        className={contentApprovalStyles.table_cell}
                        align="center"
                      >
                        {elem.subject}
                      </TableCell>
                      {/* index */}
                      <TableCell
                        className={contentApprovalStyles.table_cell}
                        align="center"
                      >
                        {elem.subTopic}
                      </TableCell>
                      {/* active btn */}

                      <TableCell
                        className={contentApprovalStyles.table_cell}
                        align="center"
                      >
                        Chapter Name
                      </TableCell>
                      <TableCell
                        className={contentApprovalStyles.table_cell}
                        align="center"
                      >
                        <div style={{ display: "flex" }}>
                          <i className="bi bi-chat-right-text"></i>
                          <p>-1</p>
                        </div>
                      </TableCell>

                      <TableCell
                        className={contentApprovalStyles.table_cell}
                        align="center"
                      >
                        <div>
                          <button
                            className={qbStyles.orangeBorder_btn}
                            onClick={(e) => {
                              e.preventDefault();
                              // handleShareModal() ;
                              navigate("/dashboard/StudentFileApproval");
                              var chapterId = elem.id;
                              sessionStorage.setItem("chapterId", chapterId);
                              chapter_reason_by_id_get();
                            }}
                          >
                            Preview
                          </button>
                          <button
                            className={qbStyles.grey_btn}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/dashboard/TeacherFileApproval");
                              var chapterId = elem.id;
                              sessionStorage.setItem("chapterId", chapterId);
                              chapter_reason_by_id_get();
                            }}
                          >
                            Reject
                          </button>
                          <button
                            className={qbStyles.orange_btn}
                            onClick={(e) => {
                              e.preventDefault();
                              // navigate('/dashboard/TeacherFileApproval');
                              var chapterId = elem.id;
                              sessionStorage.setItem("chapterId", chapterId);
                              chapter_reason_by_id_get();
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <td
                    colSpan="10"
                    style={{ height: "25rem", textAlign: "center" }}
                  >
                    {" "}
                    NO DATA AVAILABLE{" "}
                  </td>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* pagination container - Start */}
          <div
            className={contentApprovalStyles.pagination}
            style={{ width: "100%" }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <span className={contentApprovalStyles.rows}>
                <label htmlFor="rows">Rows per page</label>
                <select
                  name="rows"
                  id="rows"
                  value={rowsInput.rows}
                  onChange={handlerRowsInputs}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </span>
            </form>
            <span style={{ marginRight: "20px" }}>
              <Pagination1
                pageDetail={pageDetail}
                handleChange={handleChange}
              />
            </span>
          </div>
          {/* pagination container - End */}
        </Paper>

        <div
          className={contentApprovalStyles.flex_container}
          id={contentApprovalStyles.modal_id}
          style={
            showModalShare
              ? { display: "flex", zIndex: "10" }
              : { display: "none" }
          }
        >
          <div
            className={contentApprovalStyles.main_container}
            id={contentApprovalStyles.modal_content}
          >
            <div style={{ padding: "20px 23px 25px", position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <p style={{ fontSize: "16px" }}>Chapter name</p>
                <p
                  style={{
                    marginLeft: "auto",
                    fontSize: "16px",
                    color: "black",
                    marginRight: "36px",
                  }}
                >
                  15 files Rejected
                </p>
                <p
                  onClick={handleShareModalOk}
                  style={{
                    position: "absolute",
                    right: "15px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  X
                </p>
              </div>
              <div style={{ marginTop: "20.5px" }}>
                <p
                  style={{
                    fontSize: "13px",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  Reason
                </p>
                <p style={{ fontSize: "14px", color: "black" }}>
                  {" "}
                  The href attribute requires a valid value to be accessible.
                  Provide a valid, navigable address as the href value. If you
                  cannot provide a valid href, but still need the element to
                  resemble a link, use a butto{" "}
                </p>
              </div>
              <div
                style={{ position: "absolute", left: "34%", marginTop: "12px" }}
              >
                <button
                  onClick={handleShareModalOk}
                  style={{
                    padding: "10px 32px",
                    borderRadius: "21px",
                    backgroundColor: "#FD8C00",
                    color: "white",
                    border: "none",
                  }}
                >
                  UPLOAD AGAIN
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* own modal end  */}
      </div>
    </>
  );
};

export default QbReviewApproved;
