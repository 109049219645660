import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";

const Green100Button = (props) => {
  const AddButton = styled(Button)(() => ({
    color: "#fff",
    backgroundColor: "rgba(6,181,9,0.89)",
    borderColor: green[500],
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    width: 100,
    "&:hover": {
      color: "#fff",
      backgroundColor: "rgba(6,181,9,0.89)",
      border: 0,
      boxShadow: "0px 3px 5px #78be20",
    },
  }));
  return (
    <AddButton onClick={props.onClick} style={props.style} disabled={props.disabled}>
      {props.children}
    </AddButton>
  );
};
export default Green100Button;
