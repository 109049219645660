import React, { useState } from "react";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createDocStyles from "./../css/createDocCategory.module.css";
import createschool from "./../css/CreateSchool.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { SearchOutlined } from "@mui/icons-material";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

const CreateDocCategory = () => {
  const [filterValue, setFilterValue] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <a>Content Admin </a>
            {/* <span className={dashboardContentStyles.link_text}>Content Admin</span> */}
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Master</a>
            {/* <span className={dashboardContentStyles.link_text}>Master</span> */}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Add Document Category </a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {/* {modifiedDate.data} */}
            </p>
          </div>
        </article>

        {/* first content box start  */}
        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
            marginRight: "15px",
            // minWidth: '1385px'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
              marginTop: "69px",
            }}
          >
            <div className={createDocStyles.training_container}>
              <div className={createDocStyles.flex} style={{ height: "73px" }}>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Document Category Name
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    //   value={gradeData}
                    //   onChange={gradeHandleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  ></Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *For Which Role
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    //   value={gradeData}
                    //   onChange={gradeHandleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  ></Select>
                </FormControl>

                <div className={createschool.upload_logo_container}>
                  <span>*Upload Logo</span>
                  <span className={createschool.custom_file_container}>
                    <input
                      type="file"
                      name="logoUrl"
                      // id={sessionStorage.getItem("logoUrl")}
                      // filename={sessionStorage.getItem("logoUrl")}
                      //   Value={schoolInputs.logoUrl}
                      // onError={setIsError(true)}
                      //   onChange={imageChange}
                      className={createschool.custom_file_input}
                    />

                    {/* {schoolInputs.logoUrl && ( */}
                    <span>
                      {/* <img
                    //   src={schoolInputs.logoUrl}
                      alt="Thumb"
                      width="75"
                      height="75"
                    /> */}
                      <span>
                        <a>
                          {/* {schoolInputs.logoUrl.substring(
                          schoolInputs.logoUrl.lastIndexOf("_") + 1,
                          schoolInputs.logoUrl.length
                        )} */}
                        </a>
                        <br></br>
                        <br></br>
                        {/* <a 
                    //   onClick={removeSelectedImage} 
                      style={{color:'red' , textDecoration:'underline'}}
                      >Remove This Image</a> */}
                      </span>
                    </span>
                    {/* )} */}
                  </span>
                </div>
                {/* raio btn end */}

                {/* </div> */}

                {/* btn */}
                {/* <div style={{ display: 'inline', position: 'absolute', right: '50px', marginTop: '94px' }}>
                                    <BlueButton >UPLOAD</BlueButton>
                                </div> */}
              </div>

              <div className={createDocStyles.flex}>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Short Name
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    //   value={gradeData}
                    //   onChange={gradeHandleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  ></Select>
                </FormControl>

                <div
                  style={{
                    display: "inline",
                    position: "absolute",
                    right: "50px",
                  }}
                >
                  <BlueButton>UPLOAD</BlueButton>
                </div>
              </div>
            </div>
          </div>
        </Paper>

        {/* first content box en  */}

        {/* search row start  */}
        <div className={createDocStyles.flex} style={{ margin: "20px auto" }}>
          <TextField
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                font: "normal normal normal 13px/20px Poppins !important",
                opacity: 1,
              },
            }}
            style={{ marginLeft: "auto", marginRight: "33px" }}
            type={"text"}
            placeholder={"Search...."}
            //   onChange={onSearch}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
        </div>
        {/* search row end  */}

        {/* table start  */}
        <Paper sx={{ width: "100%", overflow: "hidden", marginLeft: "15px" }}>
          <TableContainer
            sx={{ maxHeight: 500, overflow: "auto", paddingRight: "33px" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="right"
                    className={createDocStyles.table_head}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="center"
                    className={createDocStyles.table_head}
                  >
                    Document Category
                  </TableCell>
                  <TableCell
                    align="center"
                    className={createDocStyles.table_head}
                  >
                    Short Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className={createDocStyles.table_head}
                  >
                    status
                  </TableCell>
                  <TableCell
                    align="center"
                    className={createDocStyles.table_head}
                  >
                    Operation
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {filterValue
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => ( */}
                <TableRow
                  //   key={row?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="right"
                    component="th"
                    scope="row"
                    className={createDocStyles.table_cell}
                  >
                    1{/* {page * rowsPerPage + (index + 1)} */}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={createDocStyles.table_cell}
                  >
                    {/* {row?.role} */}
                    Category Name
                  </TableCell>
                  <TableCell
                    align="center"
                    className={createDocStyles.table_cell}
                  >
                    {/* {row?.role} */}
                    Short Name
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <Switch
                        // checked={row?.active}
                        checked
                        // onChange={(e) => switchHandler(row?.id, e)}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {/* <EditIconButton onClick={() => updatedRoleHandler(row)}> */}
                      {/* <Tooltip title="Edit" placement={"top"}> */}
                      <div className={createDocStyles.pencil_icon}>
                        <i className="bi bi-pencil"></i>
                      </div>
                      {/* <EditOutlinedIcon style={{ fontSize: "25px" }} /> */}
                      {/* </Tooltip> */}
                      <div className={createDocStyles.icon}>
                        <div className={createDocStyles.tooltip}>
                          <p className="az_cursor_pointer" style={{ fontSize: "13px" }}>Remove</p>
                        </div>
                        <span>
                          <i className="fa-solid fa-trash-can"></i>
                        </span>
                      </div>
                      {/* <Tooltip title="Edit" placement={"top"}>
                           <i  className="fa-solid fa-trash-can"></i>
                          </Tooltip> */}
                      {/* </EditIconButton> */}
                      {/* <DeleteIconButton onClick={() => handleOpen(row)}>
                          <Tooltip title="Remove" placement={"top"}>
                            <DeleteOutlinedIcon style={{ fontSize: "25px" }} />
                          </Tooltip>
                        </DeleteIconButton> */}
                    </div>
                  </TableCell>
                </TableRow>
                {/* ) */}
                {/* ) */}
                {/* } */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ paddingRight: "33px" }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filterValue?.length || 0}
            page={page}
            // onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            // onRowsPerPageChange={handleRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Paper>
        {/* table start  */}
      </div>
    </>
  );
};

export default CreateDocCategory;
