import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TablePagination,
} from "@mui/material";
import CommonSort from "../js/CommonSort"

const Template = () => {
  const [updatedData, setUpdatedData] = useState({});
  const token = sessionStorage.getItem("token");
  const [filterValue, setFilterValue] = useState([]);
  const [modifiedDate, setModifiedDate] = useState({});
  const [boardList, setBoardList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [subtopicList, setSubtopicList] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [boardId, setBoardId] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [subtopic, setSubtopicId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (subtopic || gradeId || boardId || subjectId) {
      fetchFilterData();
    }
  }, [subtopic, gradeId, boardId, subjectId]);

  const fetchFilterData = () => {
    if (gradeId && !subjectId && !subtopic && !boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&gradeId=${gradeId}`;
    }
    if (!gradeId && subjectId && !subtopic && !boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&subjectId=${subjectId}`;
    }
    if (!gradeId && !subjectId && !subtopic && boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&boardId=${boardId}`;
    }
    if (!gradeId && subjectId && subtopic && !boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&subjectId=${subjectId}&subTopicId=${subtopic}`;
    }
    if (gradeId && !subjectId && !subtopic && boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&boardId=${boardId}&gradeId=${gradeId}`;
    }
    if (gradeId && subjectId && !subtopic && !boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&subjectId=${subjectId}&gradeId=${gradeId}`;
    }
    if (!gradeId && subjectId && !subtopic && boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&subjectId=${subjectId}&boardId=${boardId}`;
    }
    if (gradeId && subjectId && !subtopic && boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&gradeId=${gradeId}&subjectId=${subjectId}&boardId=${boardId}`;
    }
    if (gradeId && subjectId && subtopic && boardId) {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?pageNumber=0&pageSize=100&gradeId=${gradeId}&subjectId=${subjectId}&boardId=${boardId}&subTopicId=${subtopic}`;
    }
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async function (response) {
        response.data.data.data = await CommonSort(response?.data?.data?.data || [], "chapter", false);
        setFilterValue(response?.data?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    UpdatedData();
    fetchBoardData();
    fetchGradeData();
    fetchSubjectData();
    return () => { };
  }, []);
  useEffect(() => {
    selectSubjectHandler();
  }, []);

  const { teacherFiles, studentFiles, studyFiles, revisionFiles } = updatedData;
  const UpdatedData = async () => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/total/count`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setUpdatedData(response?.data?.data);
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/count?&pageSize=150`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        response.data.data.data = await CommonSort(response?.data?.data?.data || [], "chapter", false);
        setFilterValue(response?.data?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchBoardData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBoardList(response?.data?.data);
  };
  const fetchGradeData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setGradeList(response?.data?.data);
  };
  const fetchSubjectData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setSubjectList(response?.data?.data);
  };
  const selectSubjectHandler = (subject) => {
    setSubjectId(subject);
  //console.log("subject", subject, subjectList)
    if (subject) {
      const selectedSubject = subjectList.find(val => val.id === String(subject));
    //console.log(selectedSubject)
    //console.log(selectedSubject?.hideSubtopics, selectedSubject.id)
      if (selectedSubject && selectedSubject.hideSubtopics) {
        setShowSubTopic(false);
        // console.log("false")
        // alert("template false");
      } else {
        // console.log("true")
        setShowSubTopic(true);
        // alert("template true");
      }
    }
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${subject}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSubtopicList(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const nevigateFunction = (id, URL, status) => {
    navigate(URL);
    sessionStorage.setItem("status", status);
    sessionStorage.setItem("isStatus", true);
    sessionStorage.setItem("chapterId", id);
    // chapter_reason_by_id_get();
  };

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>

          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}
            onClick={() => navigate("/dashboard/template")}
          >
            Home
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282" }} />
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282" }} />
          </span>
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Dashboard
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>

      <div style={{ width: "83vw", marginTop: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            gap: "15px",
            borderBottom: "solid 1px black",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "110px",
            }}
          >
            <div>
              Teacher Files{" "}
              <span style={{ color: "#403e75" }}>({teacherFiles})</span>
            </div>
            <div>
              Student Files{" "}
              <span style={{ color: "#403e75" }}>({studentFiles})</span>
            </div>
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "110px",
            }}
          >
            <div>
              Study Files <span style={{ color: "#403e75" }}>({studyFiles})</span>
            </div>
            <div>
              Revision Files{" "}
              <span style={{ color: "#403e75" }}>({revisionFiles})</span>
            </div>
          </div>


          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "90px",
              marginLeft: "80px",
              fontSize: "smaller",
            }}
          >
            <div>
              Study Files <span style={{ color: "blue" }}>({studyFiles})</span>
            </div>
            <div>
              Revision Files{" "}
              <span style={{ color: "blue" }}>({revisionFiles})</span>
            </div>
          </div> */}


          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "15px",
              marginLeft: "530px",
            }}
          >
            <FormControl sx={{ width: 140, backGround: "#fff" }} size="small">
              <InputLabel id="demo-select-small">Board</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={boardId}
                input={<OutlinedInput label="Tag" />}
                // MenuProps={MenuProps}
                style={{
                  borderRadius: 150,
                  background: "#fff",
                }}
                onChange={(event) => {
                  setBoardId(event.target.value);
                  setGradeId("");
                  setSubjectId("");
                  setSubtopicId("");
                }}
              >
                {/* <MenuItem
                  className={dashboardContentStyles.menu_item}
                  value={"all"}
                >
                  All
                </MenuItem> */}
                {boardList?.map((item) => (
                  <MenuItem
                    key={item?.id}
                    value={item?.id}
                    className={dashboardContentStyles.menu_item}
                    id={`az-content-boarddropdown-${item.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: '#FFA500',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#FFA500',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item?.board} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 140, backGround: "#fff" }} size="small">
              <InputLabel id="demo-select-small">Grade</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={gradeId}
                input={<OutlinedInput label="Tag" />}
                style={{
                  borderRadius: 150,
                  background: "#fff",
                }}
                // MenuProps={MenuProps}
                onChange={(event) => {
                  setGradeId(event.target.value);
                  setSubjectId("");
                  setSubtopicId("");
                }}
              >
                {/* <MenuItem
                  className={dashboardContentStyles.menu_item}
                  value={"all"}
                >
                  All
                </MenuItem> */}
                {gradeList?.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    className={dashboardContentStyles.menu_item}
                    id={`az-content-gradedropdown-${item.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: '#FFA500',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#FFA500',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item.grade} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 170, backGround: "#fff" }} size="small">
              <InputLabel id="demo-select-small">Select Subject</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={subjectId}
                input={<OutlinedInput label="Tag" />}
                //MenuProps={MenuProps}
                style={{
                  borderRadius: 150,
                  background: "#fff",
                }}
                onChange={(event) => {
                  selectSubjectHandler(event.target.value);
                  setSubtopicId("");
                }}
              >
                {/* <MenuItem
                  className={dashboardContentStyles.menu_item}
                  value={"all"}
                >
                  All
                </MenuItem> */}
                {subjectList?.map((item) => (
                  <MenuItem
                    key={item?.id}
                    value={item?.id}
                    className={dashboardContentStyles.menu_item}
                    id={`az-content-subjectdropdown-${item.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: '#FFA500',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#FFA500',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item?.subject} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {
              showSubTopic &&

              <FormControl sx={{ width: 180, backGround: "#fff" }} size="small">
                <InputLabel id="demo-select-small">Select Subtopic</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={subtopic}
                  input={<OutlinedInput label="Tag" />}
                  //MenuProps={MenuProps}
                  style={{
                    borderRadius: 150,
                    background: "#fff",
                  }}
                  onChange={(event) => {
                    setSubtopicId(event.target.value);
                  }}
                >
                  {/* <MenuItem
                  className={dashboardContentStyles.menu_item}
                  value={"all"}
                >
                  All
                </MenuItem> */}
                  {subtopicList?.map((item) => (
                    <MenuItem
                      key={item?.id}
                      value={item?.id}
                      className={dashboardContentStyles.menu_item}
                      id={`az-content-subtopicdropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: '#FFA500',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#FFA500',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                    >
                      <ListItemText primary={item?.subTopic} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </div>
        </div>


        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {filterValue.length > 0 &&
            filterValue?.map((v, i) => {
              return (
                <div
                  style={{
                    height: "200px",
                    width: "31%",
                    border: "solid 1px grey",
                    borderRadius: "5px",
                    marginLeft: "25px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      color: "blue",
                      paddingLeft: "10px",
                      height: "30px",
                      fontSize: "13px",
                      backgroundColor: "#d9dcfe",
                    }}
                  >
                    {v?.chapter} : {v?.totalCount}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      borderBottom: "solid 1px grey",
                      padding: "5px",
                      color: "blue",
                      fontSize: "smaller",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ minWidth: "130px" }} />
                    <div style={{ marginRight: "5px", color: "orange" }}>Pending</div>
                    <div style={{ marginRight: "5px", color: "green" }}>Approved</div>
                    <div style={{ color: "red" }}>Rejected</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      color: "black",
                      fontSize: "smaller",
                      marginBottom: "15px",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ borderBottom: "solid 1px grey", fontSize: "13px", }}>
                      Teacher
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        marginLeft: "10px",
                        cursor:
                          v?.count?.teacherPending > 0 ? "pointer" : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL = "/dashboard/TeacherFileApproval";
                          if (v.count.teacherPending)
                            nevigateFunction(v.id, redirectURL, "PENDING");
                        }
                      }}
                    >
                      {v.count ? v.count.teacherPending : 0}
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        cursor:
                          v?.count?.teacherApproved > 0 ? "pointer" : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL =
                            "/dashboard/ContentApprovalTeacherViewFile";
                          if (v.count.teacherApproved)
                            nevigateFunction(v.id, redirectURL, "APPROVED");
                        }
                      }}
                    >
                      {v.count ? v.count.teacherApproved : 0}
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        cursor:
                          v?.count?.teacherRejected > 0 ? "pointer" : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL =
                            "/dashboard/ContentrejectTeacherviewfile";
                          if (v.count.teacherRejected)
                            nevigateFunction(v.id, redirectURL, "REJECTED");
                        }
                      }}
                    >
                      {v.count ? v.count.teacherRejected : 0}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      color: "black",
                      fontSize: "smaller",
                      marginBottom: "15px",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ borderBottom: "solid 1px grey", fontSize: "13px", }}>
                      Student Study
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        marginLeft: "-35px",
                        cursor:
                          v?.count?.studentStudyPending > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL = "/dashboard/StudentFileApproval";
                          if (v.count.studentStudyPending)
                            nevigateFunction(v.id, redirectURL, "PENDING");
                        }
                      }}
                    >
                      {v.count ? v.count.studentStudyPending : 0}
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        cursor:
                          v?.count?.studentStudyApproved > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL =
                            "/dashboard/contentapprovalviewfile";
                          if (v.count.studentStudyApproved)
                            nevigateFunction(v.id, redirectURL, "APPROVED");
                        }
                      }}
                    >
                      {v.count ? v.count.studentStudyApproved : 0}
                    </div>

                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        cursor:
                          v?.count?.studentStudyRejected > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL =
                            "/dashboard/contentrejectStudentviewfile";
                          if (v.count.studentStudyRejected)
                            nevigateFunction(v.id, redirectURL, "REJECTED");
                        }
                      }}
                    >
                      {v.count ? v.count.studentStudyRejected : 0}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      color: "black",
                      fontSize: "smaller",
                      marginBottom: "15px",
                      backgroundColor: "white",
                    }}
                  >
                    <div style={{ borderBottom: "solid 1px grey", fontSize: "13px", }}>
                      Student Revision
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        marginLeft: "-50px",
                        cursor:
                          v?.count?.studentRevisionPending > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL = "/dashboard/StudentFileApproval";
                          if (v.count.studentRevisionPending)
                            nevigateFunction(v.id, redirectURL, "PENDING");
                        }
                      }}
                    >
                      {v.count ? v.count.studentRevisionPending : 0}
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        cursor:
                          v?.count?.studentRevisionApproved > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL =
                            "/dashboard/contentapprovalviewfile";
                          if (v.count.studentRevisionApproved)
                            nevigateFunction(v.id, redirectURL, "APPROVED");
                        }
                      }}
                    >
                      {v.count ? v.count.studentRevisionApproved : 0}
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px grey",
                        cursor:
                          v?.count?.studentRevisionRejected > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={(e) => {
                        if (v?.count) {
                          e.preventDefault();
                          let redirectURL =
                            "/dashboard/contentrejectStudentviewfile";
                          if (v.count.studentRevisionRejected)
                            nevigateFunction(v.id, redirectURL, "REJECTED");
                        }
                      }}
                    >
                      {v.count ? v.count.studentRevisionRejected : 0}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Template;
