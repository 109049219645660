import { useState, useEffect } from "react";
import { Box, Modal, Typography } from "@mui/material";
import studentFileStyles from "./../css/studentFiles.module.css";
import createschool from "./../css/CreateSchool.module.css";
import map_post from "../API_CONTROLLER/map-controller/map_post";
import map_content_get from "../API_CONTROLLER/map-controller/map_content_get";
import BlueButton from "../../components/common/BlueButton";
import map_img_post from "../API_CONTROLLER/map-controller/map_image_post";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 770,
	bgcolor: "background.paper",
	// border: "2px solid #000",
	boxShadow: 24,
	borderRadius: 4,
	p: 3,
};

const UploadMapModal = ({
	open,
	closeModalAPI,
	close,
	handleImageListModalOpen,
	uploadImgMapData,
	removeUploadImgMap,
	selectedImageName,
	selectedImageUrl,
}) => {
	const questionType = sessionStorage.getItem("QuestionType");
	const [selectedImage, setSelectedIMage] = useState("");
	const [mapcontent, setMapcontent] = useState({});
	const [uploadedImg, SetuploadedImg] = useState("");
	const [mapInputs, setMapInputs] = useState({
		mapType: "",
		mapUrl: "",
		title: "",
	});
	const [isError, setIsError] = useState(false);

	let MapImage =
		mapInputs.mapUrl &&
		mapInputs.mapUrl.substring(
			mapInputs.mapUrl.lastIndexOf("_") + 1,
			mapInputs.mapUrl.length
		);

	const mapUploadHandler = () => {
		if (!mapInputs.title || !mapInputs.mapType || !radio) {
			setIsError(true);
			return;
		}
		if (radio == "Machine") {
			if (!mapInputs.mapUrl) {
				setIsError(true);
				return;
			}
		}
		//sessionStorage.setItem("mapUrl", MapImage || selectedImageName);
		map_post(mapInputs);
		// sessionStorage.removeItem("mapUrl");
		uploadImgMapData(uploadedImg)
		map_content_get(setMapcontent);
		setMapInputs({
			mapType: "",
			mapUrl: "",
			title: "",
		});
		setSelectedIMage(null);
		setIsError(false);
		closeModalAPI();
		// map_content_get(setMapcontent);
	};
	const imageChange = (e) => {

		e.preventDefault();
		const formData = new FormData();
		SetuploadedImg(e.target.files[0]);
		formData.append("file", e.target.files[0]);
		map_img_post(formData, mapInputs, setMapInputs, setSelectedIMage);
	};
	const removeSelectedImage = (e) => {
		e.preventDefault();
		setMapInputs({
			...mapInputs,
			mapUrl: null,
		});
	};

	const removeUploadMapImage = () => {
		setMapInputs({
			...mapInputs,
			mapUrl: null,
		});
		SetuploadedImg('')
		uploadImgMapData('')
	}

	useEffect(() => {
		removeUploadImgMap.current = removeUploadMapImage
	}, [])

	useEffect(() => {
		let type = "";
		if (questionType === "MAP POLITICAL" || questionType === "MAP_POLITICAL") type = "POLITICAL";
		if (questionType === "MAP PHYSICAL" || questionType === "MAP_PHYSICAL") type = "PHYSICAL";
		setMapInputs({
			...mapInputs,
			mapType: type,
		});
	}, [questionType, open]);

	const handleMapInputs = (e) => {
		setMapInputs({
			...mapInputs,
			[e.target.name]: e.target.value,
		});
	};
	const onclose = () => {
		setIsError(false);
		setMapInputs({
			mapType: "",
			mapUrl: "",
			title: "",
		});
		setSelectedIMage(null);
		close();
	};
	const [radio, setRadio] = useState("");
	const handleChange = (event) => {
		console.log("repository   ", selectedImageName, mapInputs,event.target.value)
		setRadio(event.target.value);
		if (event.target.value === "Repository") {
			handleImageListModalOpen();
			setMapInputs({
				...mapInputs,
				mapUrl: selectedImageName, // Set the image URL from the repository to mapUrl
			});
		};
	}
	// console.log(mapInputs, "iam map")
	return (
		<Modal
			open={open}
			onClose={close}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div>
					<div style={{ position: "relative" }}>
						<span style={{ position: "absolute", left: "0" }}>
							Upload Image
						</span>
						<span
							onClick={onclose}
							style={{ position: "absolute", right: "0", cursor: 'pointer' }}
						>
							X
						</span>
					</div>
					<div>
						<div>
							<input
								style={{
									marginTop: "73px",
									marginBottom: "20px",
									border: "1px solid grey",
									borderRadius: "6px",
									padding: "8px",
									width: "650px",
								}}
								name="title"
								type="text"
								value={mapInputs.title}
								onChange={handleMapInputs}
								placeholder="*Name"
							/>
							{isError && !mapInputs.title && (
								<p
									style={{
										fontSize: "10px",
										color: "red",
										padding: "5px",
									}}
								>
									Title is Required
								</p>
							)}
						</div>
						<div style={{ display: "flex", marginBottom: "20px" }}>
							<div>
								<div
									className={studentFileStyles.input_element}
								>
									<select
										className={
											`${studentFileStyles.form_input_element} cursorPointer`
										}
										name="mapType"
										id="cityName"
										style={{
											width: "200px",
											height: "48px",
											cursor: 'pointer'
										}}
										value={mapInputs.mapType}
										onChange={handleMapInputs}
										disabled={
											questionType === "MAP_PHYSICAL" ||
											questionType === "MAP_POLITICAL" ||
											questionType === "MAP PHYSICAL" ||
											questionType === "MAP POLITICAL"
										}
										placeholder=" "
									>
										<option value="">Select</option>
										<option
											value="PHYSICAL"
											className={`${studentFileStyles.option} cursorPointer`}
										>
											Map Physical
										</option>
										<option
											value="POLITICAL"
											className={`${studentFileStyles.optionn} cursorPointer`}
										>
											Map Political
										</option>
									</select>

									<label
										style={{ fontWeight: "300" }}
										for="cityName"
									>
										*Question Type
									</label>
									{isError && !mapInputs.mapType && (
										<p
											style={{
												fontSize: "10px",
												color: "red",
												padding: "5px",
											}}
										>
											Type is Required
										</p>
									)}
								</div>
							</div>
							<div>
								<div style={{ display: "flex" }}>
									<div
										style={{
											marginInline: "10px",
											display: "flex",
										}}
										className={
											studentFileStyles.radio_teacherid
										}
									>
										<input
											type="radio"
											style={{ accentColor: "orange" }}
											name="selection"
											value="Machine"
											onChange={handleChange}
										/>
										<label for="test_teacherid">
											Upload from my machine
										</label>
									</div>

									<div
										style={{
											marginInline: "10px",
											display: "flex",
										}}
										className={
											studentFileStyles.radio_teacherid
										}
									>
										<input
											type="radio"
											style={{ accentColor: "orange" }}
											name="selection"
											value="Repository"
											onClick={handleChange}
										/>
										<label for="test_teacherid">
											Use from Repository
										</label>
									</div>
								</div>
								{isError && !radio && (
									<p
										style={{
											fontSize: "10px",
											color: "red",
											padding: "15px",
										}}
									>
										Radio is Required
									</p>
								)}
								{radio == "Machine" && (
									<div>
										<div
											className={
												createschool.upload_logo_container
											}
										>
											<span>*Upload Files</span>
											{isError && !mapInputs.mapUrl && (
												<p
													style={{
														fontSize: "10px",
														color: "red",
														padding: "5px",
													}}
												>
													File is Required
												</p>
											)}
											<span
												className={
													createschool.custom_file_container
												}
											>
												<input
													type="file"
													name="contentUrl"
													style={{
														width: "13rem",
														marginTop: "20px",
													}}
													id={sessionStorage.getItem(
														"mapUrl"
													)}
													filename={sessionStorage.getItem(
														"mapUrl"
													)}
													Value={mapInputs.mapUrl}
													// onError={setIsError(true)}
													onChange={imageChange}
													className={
														createschool.custom_file_input
													}
												/>

												{mapInputs.mapUrl && (
													<span>
														<img
															src={
																mapInputs.mapUrl
															}
															alt="Thumb"
															width="75"
															height="75"
														/>
														<span>
															<a className="cursorDefault">
																{mapInputs.mapUrl.substring(
																	mapInputs.mapUrl.lastIndexOf(
																		"_"
																	) + 1,
																	mapInputs
																		.mapUrl
																		.length
																)}
															</a>
															<br></br>
															<br></br>
															<a
																onClick={
																	removeSelectedImage
																}
																style={{
																	color: "red",
																	textDecoration:
																		"underline",
																	top: "2rem",
																}}
															>
																Remove This
																Image
															</a>
														</span>
													</span>
												)}
											</span>
										</div>
									</div>
								)}
								{radio == "Repository" && (
									<span>
										{selectedImageName}

									</span>
								)}
							</div>
						</div>
					</div>
					<div>
						<BlueButton
							onClick={() => {
								mapUploadHandler();
							}}
						>
							Submit
						</BlueButton>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default UploadMapModal;
