import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//API Used
import GetLastModifiedAt from "../../../API/school-controller/GetLastModifiedAt";

// Css
import "../../../css/Student/FormalAssessmentQuiz/Student_Formal_Assessment_Quiz.css";
import dashboardContentStyles from "../../../css/dashboardContent.module.css";

//Images Used
import dashHome from "../../../../../src/img/dashHome.svg";

// Other Components

// Mui
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  OutlinedInput
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Faq_Quiz_Listing from "./Faq_Quiz_Listing";
import { rightClickEnable, CDNBASEURL } from "../../../../../src/Serviceworker"
import mixpanel from "mixpanel-browser";

var CDN_BASE_URL = CDNBASEURL
function Student_Formal_Assessment_Quiz() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [modifiedDate, setModifiedDate] = useState({});
  const [planData, setPlanData] = useState([]);
  const [planId, setPlanId] = useState("");
  const [loading, setLoading] = useState(true);
  const [subjectList, setSubjectList] = useState([]);
  const InboxSubject = sessionStorage.getItem('InboxSubject2');
  const InboxSubjectName = sessionStorage.getItem('InboxSubjectName2');
  const InboxQuizType = sessionStorage.getItem('InboxQuizType');
  const [selectedSubject, setSelectedSubject] = useState("");
  const [quizTypes, setQuizTypes] = useState([]);
  const [selectedQuizTypes, setSelectedQuizTypes] = useState("");
  const [viewQuizData, setViewQuizData] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [branchId, setBranchId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [loadQuizData, setLoadQuizData] = useState(false);
  const [studentDetail, setStudentDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const user_role = localStorage.getItem('role');
  const user_id = localStorage.getItem('id');
  const boardId = sessionStorage.getItem('boardId');
  const branchIdd = sessionStorage.getItem('branchId');
  const gradeId = sessionStorage.getItem('gradeId');
  const gradeName = sessionStorage.getItem('gradeName');
  const schoolIdd = sessionStorage.getItem('schoolId');
  const sectionId = sessionStorage.getItem('sectionId');
  const sectionName = sessionStorage.getItem('sectionName');

  useEffect(()=>{
    // console.log('user',userName);
    // console.log('wwww',role);
  },[userName,role])

  useEffect(()=>{
  if(user_role === "STUDENT"){
  mixpanel.track('Student_Formal_Assessment_Clicked',{
    "user_id":user_id,
    "username":userName,
    "boardId" :boardId,
    "branchId" :branchIdd,
    "gradeId" :gradeId,
    "gradeName" :gradeName,
    "schoolId" :schoolIdd,
    "sectionId" :sectionId,
    "sectionName" :sectionName,
  })
}
},[])

  //-------| Student Data Api |-------//
  const fetchStudentData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBranchId(res.data.data.student.branchId);
    setStudentId(res.data.data.student.id);
    setSchoolId(res.data.data.student.schoolId);
    sessionStorage.setItem("gradeId", res.data.data.student.gradeId);
    sessionStorage.setItem("boardId", res.data.data.student.boardId);
    sessionStorage.setItem("sectionId", res.data.data.student.sectionId);
    sessionStorage.setItem("branchId", res.data.data.student.branchId);
    sessionStorage.setItem("schoolId", res.data.data.student.schoolId);
    sessionStorage.setItem("studentId", res.data.data.student.id);
  };

  useEffect(async () => {
    await fetchStudentData(userName, role);
    const studentData = await fetchStudentData(userName, role);
    setStudentDetail(studentData);
    // GetLastModifiedAt(setModifiedDate);
    var sessData = JSON.parse(sessionStorage.getItem("itemload"));
    var VviewQuizData = JSON.parse(sessionStorage.getItem("viewQuizData"));
    // console.log(VviewQuizData)
    // console.log(sessData);
    if (VviewQuizData?.length > 0) {
      setViewQuizData(VviewQuizData);
    }


    // return ()=>{
    //   sessionStorage.removeItem('InboxQuizType');
    //   sessionStorage.removeItem('InboxSubject');
    //   sessionStorage.removeItem('InboxSubjectName');
    // }

  }, []);

  //-------| Plan Api |-------//
  const fetchPlanDetails = async () => {
    const branchId = sessionStorage.getItem("branchId");

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlanId(res?.data?.data?.plans[0]?.planId);
      return res.data.data || [];
    } catch (error) {
      // console.error("Error fetching plan Data:", error);
      return [];
    }
  };

  //-------| Subject Api |-------//
  const fetchSubjectData = async () => {
    const gradeId = sessionStorage.getItem("gradeId");
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan?planId=${planId}&gradeId=${gradeId}&menuName=ENRICH`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data.data || [];


    // const boardId = sessionStorage.getItem("boardId");
    // // const gradeId = sessionStorage.getItem("gradeId");
    // const sectionId = sessionStorage.getItem("sectionId");
    // const branchId = sessionStorage.getItem("branchId");
    // const schoolId = sessionStorage.getItem("schoolId");
    // console.log(boardId, gradeId, sectionId, branchId, schoolId);
    // // https://azvasa-lms-dev-s3-app-backup.s3.ap-south-1.amazonaws.com
    // // var CDN_BASE_URL = "${CDNBASEURL}"

    // const response = await fetch(CDN_BASE_URL + '/grades/' + gradeId + '/subjects-' + gradeId + '.json');
    // // console.log("response123", response);
    // const data = await response.json();
    // console.log("jondata", data)
    // var myCurrentGrade = data.data.grades.find(item => item.gradeId == gradeId);
    // console.log("myCurrentGrade", myCurrentGrade,response1?.data.data)
    // return myCurrentGrade?.subjects || [];
  };

  //-------| Annual-Diagnostic Quiz-Types Api |-------//
  const fetchQuizTypes = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-types/annual-diagnostic`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res?.data?.data || [];
    } catch (error) {
      // console.error("Error fetching quiz-types:", error);
      return [];
    }
  };

  useEffect(() => {
    const fethPlanData = async () => {
      const branchId = sessionStorage.getItem("branchId");
      if (branchId) {
        const planDatas = await fetchPlanDetails();
        setPlanData(planDatas);
      }
    };

    const fethQuizTypeData = async () => {
      const quizTypesList = await fetchQuizTypes();
      setQuizTypes(quizTypesList);

      const matchedQuizType = quizTypesList.find(quizType => quizType.description === InboxQuizType);
      if (matchedQuizType) {
        setSelectedQuizTypes(matchedQuizType.id);
      } else if (quizTypesList.length > 0) {
        setSelectedQuizTypes(quizTypesList[0].id);
      }
    }

    fethPlanData();
    fethQuizTypeData();
  }, [InboxQuizType]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var subList = await fetchSubjectData();
      // console.log("4-7-24 b4",subList)
      subList = subList?.filter(v => v.skilledSubject === true) || [];
      // console.log("aftr",subList)
      setSubjectList(subList);
      const matchedSubject = subList.find(subject => subject.subjectId === InboxSubject);
      if (matchedSubject) {
        setSelectedSubject(matchedSubject.subjectId);
      } else {
        setSelectedSubject(subList.length > 0 ? subList[0].subjectId : "");
      }
      setLoading(false);
    };

    fetchData();

  }, [planId, InboxSubject]);

  //-------| Listing the Quiz Api |-------//
  const fetchViewQuiz = async (studentId, schoolId, branchId) => {
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const studentIdd = sessionStorage.getItem("studentId");
    const schoolId1 = sessionStorage.getItem("schoolId");
    var branchId1 = sessionStorage.getItem("branchId");
    // console.log("schoolId1", schoolId1, "----", schoolId, `v1/api/student/examination/listing?studentId=${studentIdd}&schoolId=${schoolId || schoolId1}&branchId=${branchId || branchId1}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&quizTypeId=${selectedQuizTypes}&academicYearId=${academicYearList}&reviewStatus=APPROVED`)
    const res = await axios.get(
      // `https://test-be-lms.azvasa.onlinev1/api/student/examination/listing?studentId=4028938b8a8f7500018a92436e5e001c&schoolId=4028938b89ab385c0189afe2c2200007&branchId=4028938b8a829dde018a8377044d0001&boardId=4028938b8968a6640189770833d1005d&gradeId=4028938b8968a6640189823e371a00a3&sectionId=4028938b8a829d62018a83784eb2001d&subjectId=4028938b8968a6640189770b64e9006c&chapterId=4028938b8968a664018977ed6c1e0097&academicYearId=4028938b8968a664018986c6a0f300b3&reviewStatus=APPROVED`,
      // https://test-be-lms.azvasa.onlinev1/api/student/examination/listing?studentId=4028938b8a8f7500018a92436e5e001c&schoolId=4028938b89ab385c0189afe2c2200007&branchId=4028938b8a829dde018a8377044d0001&boardId=4028938b8968a6640189770833d1005d&gradeId=4028938b8968a6640189823e371a00a3&sectionId=4028938b8a829d62018a83784eb2001d&subjectId=4028938b8968a6640189770b64e9006c&chapterId=4028938b8968a664018977ed6c1e0097&academicYearId=4028938b8968a664018986c6a0f300b3&reviewStatus=APPROVED

      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/listing?studentId=${studentIdd}&schoolId=${schoolId || schoolId1}&branchId=${branchId || branchId1}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&quizTypeId=${selectedQuizTypes}&academicYearId=${academicYearList}&reviewStatus=APPROVED`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(res.data.data, "student formal")
    return res.data.data || [];
  };

  //-------| Academic year api |-------//
  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data.data[0].id || [];
  };

  useEffect(() => {
    const fetchViewQuizData = async () => {
      try {
        const data = await fetchViewQuiz(
          studentId,
          schoolId,
          branchId,
          selectedSubject
        );
        // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data)
        var vdata = data.filter(obj => obj.endDate !== null && obj.endDate !== '')
        // console.log("vdata", vdata)
        var VviewQuizData = JSON.parse(sessionStorage.getItem("viewQuizData"));
        // console.log(VviewQuizData)
        if (VviewQuizData?.length > 0) {
        } else {
          setViewQuizData(vdata);
        }

        // sessionStorage.setItem()
      } catch (error) {
        // console.error(error);
      }
    };

    const fetchAcademicYearList = async () => {
      try {
        const data = await fetchAcademicYearData();
        setAcademicYearList(data);
      } catch (error) {
        // console.error(error);
      }
    };

    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchAcademicYearList(), fetchViewQuizData(),]);
      setLoading(false);
    };

    fetchData();

    return () => {
      isMounted = false;
      // sessionStorage.removeItem('InboxSubjectName2');
      // sessionStorage.removeItem('InboxSubject2');
      // sessionStorage.removeItem('InboxQuizType');
    };
  }, [studentId, selectedSubject, selectedQuizTypes, loadQuizData]);

  const handleSubject = (event) => {
    const subject = subjectList.find((sub)=>sub.subjectId ===event.target.value)
    setSelectedSubject(event.target.value);
    const quizType = quizTypes.find((quiz)=>quiz?.id === selectedQuizTypes);
    if(user_role === "STUDENT"){
    mixpanel.track('Student_FAQ_Subject_Select',{
      "user_id":user_id,
      "subject":subject?.subject,
      "subjectId":subject?.subjectId,
      "quizType":quizType?.quizType,
      "username":userName
    })
  }
  };


  const handleQuizTypes = (event) => {
    const quizType = quizTypes.find((quiz)=>quiz?.id === event.target.value);
    // console.log('asasasasas',quizType);
    setSelectedQuizTypes(event.target.value);
     if(user_role === "STUDENT"){
    mixpanel.track('Student_QuizType_Select',{
      "user_id":user_id,
      "quizType":quizType?.quizType,
      "username":userName
    })
  }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            width: "80vw",
          }}
        >
          {/* <CircularProgress /> loaderdisabled*/}
        </Box>
      ) : (
        <div className="faq_division">
          {/*-------| Bread Crumbs Starts |-------*/}
          <article>
            <div style={{ position: 'relative', margin: '14px' }}>
              <span className={dashboardContentStyles.link_icon}>
                <img
                  src={dashHome}
                  alt="no img"
                  className={dashboardContentStyles.az_menu_icon}
                />
              </span>
              <span
                className={dashboardContentStyles.az_menu_text}
                style={{ cursor: 'pointer' }}
                id={`az-breadscrum-home`}
                onClick={() => {
                  sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                  sessionStorage.removeItem("sideNavSubMenuNames");
                  navigate("/dashboard/template");
                }}
              >
                Home
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span
                className={dashboardContentStyles.az_selected_menu_text}
              >
                Formal Assessment
              </span>
            </div>
            {/* <div className={dashboardContentStyles.dashboard_last_updated}>
              <p>Last Update: {modifiedDate.data}</p>
            </div> */}
          </article>
          {/*-------| Bread Crumbs Ends |-------*/}

          {/*-------| DropDown Division Starts |-------*/}
          <div className="faq_dropdown_div">
            {/*-------| DD1 |-------*/}
            <div>
              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#FD8C00 !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "148px",
                      height: "32px",

                    },

                  }}
                >
                  {!selectedSubject && (
                    <InputLabel id="demo-simple-select-label"
                      sx={{
                        top: '-10px',
                        '&.Mui-focused, &.MuiFormLabel-filled': {
                          top: 0,
                          color: '#FD8C00'
                        },
                        fontSize: '14px'
                      }}>
                      Select Subject
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedSubject}
                    label=""
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={handleSubject}
                    setSubjectList={selectedSubject}
                    className={selectedSubject ? dashboardContentStyles.az_select_menu_selected : dashboardContentStyles.az_select_menu}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedSubject ? "orange" : "black",
                    }}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF",
                        // border: "2px solid orange"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                        // border: "2px solid orange"
                      },
                    }}

                  >
                    {subjectList?.map((item) => {
                      return (
                        <MenuItem value={item.subjectId}
                          id={`az-student-subject-dropdown-${item.subjectId}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: 'orange',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#fcedc2',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}>
                          <ListItemText
                            primary={
                              <Typography
                                noWrap
                                sx={{
                                  overflow: 'hidden !important',
                                  textOverflow: 'ellipsis !important',
                                  '& span': {
                                    fontSize: '12px',
                                    textOverflow: 'ellipsis !important',
                                  }
                                }}
                              >
                                {item.subjectName}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth >
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }} className='dropDownLabel'
                    id="demo-simple-select-label"
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedSubject}
                    input={<OutlinedInput label='Subject' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                          maxHeight: '190px',
                          overflowY: 'auto',   // Enable scrolling if content exceeds maxHeight
                          '&::-webkit-scrollbar': {
                            width: '2px', // Width of the scrollbar
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1', // Background color of the scrollbar track
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#888', // Color of the scrollbar thumb
                            borderRadius: '4px', // Rounded corners for the scrollbar thumb
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555', // Color of the scrollbar thumb when hovered
                          },
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px',
                          overflowY: 'auto',
                        },
                      },
                    }}
                    IconComponent={props => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                    onChange={handleSubject}
                    setSubjectList={selectedSubject}
                  >
                    {subjectList?.map((item) => {
                      return (
                        <MenuItem value={item.subjectId}
                          id={`az-student-subject-dropdown-${item.subjectId}`}
                          sx={{
                            fontSize: '10px',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#e7ab3c',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                            },
                          }}>

                          {item.subject}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/*-------| DD2 |-------*/}
            <div>
              <Box sx={{ minWidth: 120 }}>
                {/* <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#7A7A7A !important",
                      fontSize: "14px !important",
                      opacity: 0.9,
                    },
                    "& .MuiInputBase-root": {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50px",
                      width: "100%",
                      height: "32px",
                    },
                    background: "white",
                    borderRadius: 150,
                    width: '200px',
                    height: '32px'
                  }}
                >
                  {!selectedQuizTypes && (
                    <InputLabel id="demo-simple-select-label"
                      sx={{
                        top: '-10px',
                        '&.Mui-focused, &.MuiFormLabel-filled': {
                          top: 0,
                          color: '#FD8C00'
                        },
                        fontSize: '14px'
                      }}
                    //  shrink={false}
                    >
                      Quiz Type
                    </InputLabel>
                  )}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedQuizTypes}
                    label=""
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={handleQuizTypes}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedSubject ? "orange" : "black",
                    }}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF",
                        // border: "2px solid orange"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                        // border: "2px solid orange"
                      },
                    }}
                  >
                    {quizTypes?.map((item) => {
                      return (
                        <MenuItem value={item.id}
                          id={`az-student-quiztype-dropdown-${item.id}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: 'orange',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#fcedc2',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                noWrap
                                sx={{
                                  overflow: 'hidden !important',
                                  textOverflow: 'ellipsis !important',
                                  '& span': {
                                    fontSize: '14px',
                                    textOverflow: 'ellipsis !important',
                                  }
                                }}
                              >
                                {item.quizType}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }} className='dropDownLabel'
                    id="demo-simple-select-label">
                    Quiz Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedQuizTypes}
                    onChange={handleQuizTypes}
                    input={<OutlinedInput label='Quiz Type' />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    IconComponent={props => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                    )}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                  >
                    {quizTypes?.map((item) => {
                      return (
                        <MenuItem value={item.id}
                          id={`az-student-quiztype-dropdown-${item.id}`}
                          sx={{
                            fontSize: '10px',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#e7ab3c',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                            },
                          }}
                        >
                          {item.quizType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          {/*-------| DropDown Division Ends |-------*/}

          {/*-------| Quiz Listing Division Starts |-------*/}
          <div>
            {/*-------| Name division |-------*/}
            {viewQuizData.length === 0 ? (
              <div></div>
            ) : (
              <div className="faq_name_division">
                <h6>
                  All&nbsp;
                  {InboxQuizType ? InboxQuizType : selectedQuizTypes && (
                    <span>
                      {
                        quizTypes.find((itm) => itm.id === selectedQuizTypes)
                          ?.quizType
                      }
                    </span>
                  )}
                  &nbsp;For&nbsp;
                  {selectedSubject && (
                    <span>
                      {InboxSubjectName ? InboxSubjectName :
                        subjectList.find(
                          (itm) => itm.subjectId === selectedSubject
                        )?.subject
                      }
                    </span>
                  )}
                </h6>
              </div>
            )}

            {/*-------| Listing the Quizes Division|-------*/}
            <div className="faq_quizListing_division">
              {viewQuizData.length === 0 ? (
                <div className="no_faq">No Quiz for the Selected Filters</div>
              ) : (
                <div>
                  <Faq_Quiz_Listing
                    fetchQuizTypes={fetchQuizTypes}
                    selectedQuizTypes={selectedQuizTypes}
                    viewQuizData={viewQuizData}
                    selectedSubject={selectedSubject}
                    academicYearList={academicYearList}
                    schoolId={schoolId}
                    branchId={branchId}
                    studentId={studentId}
                    loadQuizData={loadQuizData}
                    setLoadQuizData={setLoadQuizData}
                    fetchViewQuiz={fetchViewQuiz}
                  />
                </div>
              )}
            </div>
          </div>
          {/*-------| Quiz Listing Division Ends |-------*/}
        </div>
      )}
    </>
  );
}

export default Student_Formal_Assessment_Quiz;
