import React, { useEffect, useState } from "react";
import QuizLandingPage from "./QuizType/QuizLandingPage";
import UnitQuizCreation from "./QueSelection/UnitQuizCreation";
import ListQuiz from "./ListQuizz/ListQuiz";
import MultiChaptersSelect from "./ListQuizz/MultiChaptersSelect";
import PracticeQuizCreation from "./QueSelection/PracticeQuizCreation";
import AnnualQuizCreation from "./QueSelection/AnnualQuizCreation";
import DiagnosticQuizCreation from "./QueSelection/DiagnosticQuizCreation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  reset_quiz_creation,
  reset_selected_questions,
  set_quiz_creation_step,
  set_quiz_type,
} from "../../../actions/quizAction";
import { useSearchParams } from "react-router-dom";

function CreateQuizParent() {
  const [data, setData] = useState({});

  // const onNext = (formData) => {
  //   setData({ ...data, ...formData });
  //   setStep(step + 1);
  // };
  const [searchParams] = useSearchParams();
  const isEditFlow = searchParams.get("isEdit");
  const local_data = JSON.parse(localStorage.getItem("quiz_data"));
  const { quiz_type, quiz_step } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();

  const onNext = () => {
  //console.log("next");
    dispatch(set_quiz_creation_step(quiz_step + 1));
  };
  useEffect(() => {
    return () => {
    //console.log("unmount");
      dispatch(reset_quiz_creation());
    };
  }, []);

  useEffect(() => {
    if (isEditFlow) {
      let ViewQuizData = sessionStorage.getItem("questionIdView");
      if (ViewQuizData) ViewQuizData = JSON.parse(ViewQuizData);

      if (ViewQuizData) {
        const selected_chapters = [];
        ViewQuizData.quizQuestions.forEach((q) => {
          // const isPresent = selected_chapters.find((c) => c.id === q.chapterId);
          // if (!isPresent)
          selected_chapters.push({ id: q.chapterId, chapter: q.chapter });
        });
        localStorage.setItem(
          "quiz_data",
          JSON.stringify({
            ...local_data,
            selected_chapter: {
              id: ViewQuizData.quizQuestions[0].chapterId,
              chapter: ViewQuizData.quizQuestions[0].chapter,
            },
            selected_chapters,
            quiz_name: ViewQuizData.name,
            duration: ViewQuizData.duration,
            score: ViewQuizData.totalMarks,
            selected_subject: {
              id: ViewQuizData.subjectId,
              subject: ViewQuizData.subject,
            },
            selected_grade: {
              id: ViewQuizData.gradeId,
              grade: ViewQuizData.grade,
            },
            selected_fromGrade: {
              id: ViewQuizData.fromGradeId,
              grade: ViewQuizData.fromGrade,
            },
            boy: ViewQuizData.boy,
            selected_year: {
              id: ViewQuizData.academicYearId,
              academicYear: ViewQuizData.academicYear,
            },
            selected_sub_topic: {
              id: ViewQuizData.subTopicId,
              subTopic: ViewQuizData.subTopic,
            },
            selected_board: {
              id: ViewQuizData.boardId,
              board: ViewQuizData.board,
            },
            quiz_type: ViewQuizData.quizType,
            quiz_type_id: ViewQuizData.quizTypeId,
          })
        );
        dispatch(set_quiz_type(ViewQuizData.quizType));
        dispatch(set_quiz_creation_step(3));
        dispatch(reset_selected_questions([]));
      }
    } else {
      sessionStorage.removeItem("questionIdView");
      localStorage.removeItem("quiz_data");
      dispatch(set_quiz_creation_step(quiz_step));
    }
  }, [isEditFlow]);

  const onBack = () => {};
  switch (quiz_step) {
    case 1:
      return <QuizLandingPage onNext={onNext} />;
    case 2:
      switch (quiz_type) {
        case "Unit Quiz":
          return <ListQuiz onNext={onNext} onBack={onBack} />;
        case "Practice Quiz":
          return <ListQuiz onNext={onNext} onBack={onBack} />;
        case "Annual/Term Quiz":
          return <MultiChaptersSelect onNext={onNext} onBack={onBack} />;
        default:
          return (
            <MultiChaptersSelect
              onNext={onNext}
              onBack={onBack}
              quiz={"Diagnostic Quiz"}
            />
          );
      }
    case 3:
      switch (quiz_type) {
        case "Unit Quiz":
          return <UnitQuizCreation onNext={onNext} onBack={onBack} />;
        case "Practice Quiz":
          return <PracticeQuizCreation onNext={onNext} onBack={onBack} />;
        case "Annual/Term Quiz":
          return <AnnualQuizCreation onNext={onNext} onBack={onBack} />;
        case "Diagnostic Quiz":
          return <DiagnosticQuizCreation onNext={onNext} onBack={onBack} />;
        default:
          return null;
      }

    default:
      return null;
  }
}

//page 2 ListQuiz
//page 3 UnitQuizCreation

//page 2  MultiChapterSelect
//page 3 Practice Quiz Creation

////page 2  MultiChapterSelect
//page 3 Annual Quiz Creation

//page 2  MultiChapterSelect
//page 3 Diagnostic Creation

export default CreateQuizParent;
