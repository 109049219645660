import React, { useState, useEffect, useRef } from 'react'
import { Button, Paper, TextField } from '@mui/material'
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import '../../../css/switch.css'
import styled from '@emotion/styled'
import { nameHandler } from '../../../../utils/helper'


const PresentationView = ({ selectedUrl, selectedMenu }) => {
  const [presentationNumPages, setPresentationNumPages] = useState(null)

  const [presentationPageNumber, setPresentationPageNumber] = useState(1)

  const changePresentationPage = offset => {
    setPresentationPageNumber(prevPageNumber => prevPageNumber + offset)
  }
  const previousPresentationPage = () => {
    changePresentationPage(-1)
  }
  const nextPresentationPage = () => {
    changePresentationPage(1)
  }

  const PresentationButton = styled(Button)(() => ({
    color: 'black',
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '0.9vw',
    backgroundColor: 'rgb(207,205,205)',
    border: '0px',
    borderRadius: '150px'
  }))

  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '10px',
          alignItems: 'center',
          padding: '10px 0px'
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontWeight: 'bold',
            paddingBottom: '10px',
            fontSize: '13px'
          }}
        >
          {nameHandler(selectedMenu)}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          background: 'rgb(241, 237, 237)',
          padding: '15px',
          borderRadius: '10px',
          height: '100%',
          // minHeight: "100vh",
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            height: '520px',
            width: '100%',
            overflow: 'auto',
            padding: '10px'
          }}
        >

          <iframe
            src={selectedUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '2px',
            alignItems: 'center',
            background: 'rgb(192 190 190)',
            borderRadius: '150px',
            marginTop: '10px'
          }}
        >
          <PresentationButton
            onClick={previousPresentationPage}
            disabled={presentationPageNumber <= 1}
            sx={{
              borderTopLeftRadius: '150px',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '150px'
            }}
          >
            <ArrowDropDownIcon />
          </PresentationButton>
          <TextField
            type={'number'}
            sx={{
              width: '60px',
              margin: '2px',
              background: 'white',
              borderRadius: '3px'
              // height: "50px",
            }}
            value={presentationPageNumber}
            onChange={e => {
              const number = e.target.value
              if (number) {
                setPresentationPageNumber(Number(number))
              } else {
                setPresentationPageNumber('')
              }
            }}
            size={'small'}
          />
          <PresentationButton
            onClick={nextPresentationPage}
            disabled={presentationPageNumber >= presentationNumPages}
            sx={{
              borderBottomRightRadius: '150px',
              borderTopRightRadius: '150px',
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px'
            }}
          >
            <ArrowDropUpIcon />
          </PresentationButton>
        </div>
      </div>
    </Paper>
  )
}

export default PresentationView
