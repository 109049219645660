import axios from 'axios'

 async function chapters_edit_getby_id(setChapterInputs,setSubtopicId,setChapName){
    let token = sessionStorage.getItem("token")

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/${sessionStorage.getItem("chapterId")}` , 
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setChapterInputs({...res.data.data});
        setSubtopicId(res.data.data.subTopicId)
        setChapName(res.data.data.chapter)
        // console.log(res,"inputs")
    })
    .catch(err => console.log(err))
}
export default chapters_edit_getby_id;