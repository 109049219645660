import React, { useEffect, useState } from "react";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import axios from "axios";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import CardMembershipSharpIcon from "@mui/icons-material/CardMembershipSharp";
import { SearchOutlined } from "@mui/icons-material";
import contentApprovalStyles from "../css/contetnApproval.module.css";
import TeacherAccessModal from "./TeacherAccessModal";
import ProfileUpdateModal from "./ProfileUpdateModal";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id";
import TeacherControllerGet from "../API/teacher-controller/User_Teacher_Get";
import TeacherProfileUpdateModal from "./TeacherProfileUpdateModal";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { async } from "q";
import { log } from "util";
import dashHome from "../../../src/img/dashHome.svg";
import { event } from "jquery";
import searchIcn from "../../../src/img/search.svg";
import teacheridstyles from "../css/TeacherId.module.css";
import Pagination1 from "./pagination1";
import sortIcon from "../img/sort_1.png"
import ClearIcon from "@mui/icons-material/Clear";
import commonsort from "../js/CommonSort"
import { useNavigate } from "react-router-dom";


const TeacherAccess = (props) => {
  const [modifiedDate, setModifiedDate] = useState({});
  const [filterValue, setFilterValue] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updatedData, setUpdatedData] = useState([]);
  const token = sessionStorage.getItem("token");
  const [selectedItem, setSelectedItem] = useState("");
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [principalProfileModal, setPrincipalModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [branches, setBranches] = useState();
  const [grades, setGrades] = useState([]);
  const [planId, setPlanId] = useState("");
  const [names, setName] = useState("");
  const [schoolDetails, setSchoolDetails] = useState({
    branch: "",
  });
  const [branchList, setBranchList] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [isStatus, setStatus] = useState(true);
  const [pageDetail, setPageDetail] = useState({});
  const [branch, setBranch] = useState([]);
  const [totalele, setTotalelements] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [sortOrder, setSortOrder] = useState(true)
  const navigate = useNavigate();


  const [selectAll, setSelectAll] = useState(false);
  const [individualCheckboxes, setIndividualCheckboxes] = useState([]);

  useEffect(() => {
    setIndividualCheckboxes(Array(filterValue.length).fill(false));
  //console.log(">>> ", individualCheckboxes.length)
  }, [filterValue]);

  // Function to handle the change in state of the table header checkbox
  const handleSelectAll = (event) => {
    // Update the state to the new checked status of the table header checkbox
    const newState = event.target.checked;
    setSelectAll(newState);
    setIndividualCheckboxes(individualCheckboxes.map(() => newState));
  };

  // Function to handle the change in state of individual table body checkboxes
  const handleIndividualCheckboxChange = (index) => {
    // Update the state of individual checkbox at the given index
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);


  };

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    //fetchData();
    Branches_Changer_School_Id(setBranchList);
    return () => { };
  }, []);

  useEffect(() => {
    if (branchList.length > 0 && isStatus) {
      // SchoolDetailsInputs(branchList[0] ? branchList[0].branchId : "");
      setBranch(branchList[0]?.branchId);
      setStatus(false);
    }
  }, [branchList]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const [isSearch, setisSearch] = useState(true);
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    SchoolDetailsInputs();
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.name?.toLowerCase().includes(inputValue);
      });
      setFilterValue(result)
    } else {
      SchoolDetailsInputs();
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (event) => {
    setRowsInputs({
      ...rowsInput,
      [event.target.name]: event.target.value,
    });
  };

  const searchingData = async (branch) => {
    const schoolId = sessionStorage.getItem("schoolid");
    setisSearch(false);
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageSize=10&pageNumber=0&sortOrder=true&sortBy=createdAt&schoolId=${schoolId}&branchId=${branch}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },);

    const totlaele = result?.data?.data?.totalElements;
    var totalData = []
    if (totlaele > 0) {
      // console.log("api/user/teachers?pageSize", totlaele)
      let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageSize=${totlaele}&pageNumber=0&sortOrder=true&sortBy=createdAt&schoolId=${schoolId}&branchId=${branch}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          }
        },)

      totalData = response?.data?.data?.data
    }
    setTotalelements(totalData);
  }

  useEffect(() => {
    if (branchList.length > 0) {
      SchoolDetailsInputs(branchList[0]?.branchId);
    }
    if (branch) {
      searchingData(branch);
      setisSearch(false);
    }
    return () => { };
  }, [page, branch, rowsInput, branchList]);

  const SchoolDetailsInputs = (value) => {
    const schoolId = sessionStorage.getItem("schoolid");
    //setSchoolDetails({ branch: branch });
  //console.log("api/user/teachers?pageSize")
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageSize=${rowsInput.rows}&pageNumber=${page - 1
        }&sortOrder=true&sortBy=createdAt&schoolId=${schoolId}&branchId=${value||branchList[0]?.branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setUpdatedData(response?.data?.data?.data);
        setFilterValue(response?.data?.data?.data);
        setPageDetail(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  const onSort = async (field) => {
  //console.log(field, filterValue)
    setSortOrder(!sortOrder)
    var sorted = await commonsort(filterValue || [], field, !sortOrder);
  //console.log(sorted)
    setFilterValue(sorted)
  }
  const ProfileModal = (item) => {

    const { email, school, name, id, branch, branchId, schoolId } = item;
    setSelectedProfile({ email, school, name, id, branch });
    setName(name);
    getGrades(branchId, schoolId);
    setSelectedItem(item);
    setOpenProfileModal(true);
    getTeacherData(id);

  };


  const [teachergrade, setTeacherGrade] = useState([]);
  const getTeacherData = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/list/assigned-access?teacherId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setTeacherGrade(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const closeProfileModal = () => {
    setOpenProfileModal(false);
  };

  const closeTeacherProfileModal = () => {
    setPrincipalModal(false);
  };


  const giveAccessSuccess = (message) => {
    setSuccessMessage(message);
    setOpenProfileModal(false);
    setOpenUpdateModal(true);
    //fetchData();
    setPrincipalModal(false);
  };
  const closeUpdateModal = () => {
    setOpenUpdateModal(false);
    //Branches_Changer_School_Id(setBranchList);
  };

  const getGrades = (branchId, schoolId) => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/grades?branchId=${branchId}&schoolId=${schoolId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setGrades(response?.data?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setPlanId(response?.data?.data?.plans[0]?.planId);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <article>
        {/* <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>School</span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Edit City</a>
        </div> */}

        <div className={dashboardContentStyles.dashboard_link}>
          <div style={{ display: 'flex', position: 'relative', }}  >
            <div >
              <span
                className={dashboardContentStyles.link_icon}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={dashHome}
                  alt="no img"
                  className={dashboardContentStyles.az_menu_icon}
                // width="15px"
                // height="20px"
                // style={{ verticalAlign: "middle", marginRight: '5px' }}
                />
              </span>
            </div>
            <div>
              <span
                id="az-small"
                className={dashboardContentStyles.az_menu_text}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`/dashboard/template`)

                }}
              >
                Home
              </span>

              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span
                className={`${dashboardContentStyles.az_menu_text} cursorPointer`}
              >
                School
              </span>
              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span>
                <i className="fa-solid fa-angle-right" />
              </span>


              <span
                className={dashboardContentStyles.az_selected_menu_text}
              >
                Edit City
              </span>

            </div>
          </div>

        </div>


        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>

      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "-5px",
            gap: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                // flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "5px",
              }}
            >
              <CardMembershipSharpIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                List
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
              }}
            >
              <div>
                <div>
                  <TextField
                    id={`az-schooladmin-search-field`}
                    onChange={handleInputChange}
                    type={"text"}
                    value={query}
                    placeholder={"Search anything..."}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "247px !important",
                        height: "32px !important",
                        borderRadius: "50px !important",
                        fontFamily: "Poppins !important",
                        fontSize: "13px",
                        marginLeft: "20px !important",
                        background: "#FFFFFF",

                        opacity: 1,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {showClear && (
                            <IconButton
                              id={`az-schooladmin-clear-btn`}
                              onClick={handleClear}>
                              <ClearIcon
                                style={{ color: "#F05262", fontSize: "1vw" }}
                              />
                            </IconButton>
                          )}
                          {!showClear && (
                            <IconButton>
                              <img
                                src={searchIcn}
                                style={{
                                  verticalAlign: "top",
                                  fontSize: "large",
                                }}
                                aria-hidden="true"
                              />
                            </IconButton>
                          )}
                        </>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className={branchchangerstyle.Grid_school_name_branch_teacherid}
              >
                <select
                  className={branchchangerstyle.branchselect_Dropdown}
                  name="branch"
                  id="branch"
                  value={schoolDetails.branchId}
                  onChange={(event) => SchoolDetailsInputs(event.target.value)}
                >
                  {branchList.length > 0 ? (
                    branchList.map((branchList) => {
                      return (
                        <option
                          id={`az-schooladmin-branchdropdown-${branchList.branchId}`}
                          value={branchList.branchId}>
                          {branchList.branch}
                        </option>
                      );
                    })
                  ) : (
                    <option
                      className={branchchangerstyle.option}
                      value=""
                    ></option>
                  )}
                </select>
              </div>
            </div>
          </div>

          <Paper
            sx={{ width: "100%", overflow: "hidden" }}
            style={{ marginTop: "-12px" }}
          >
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{ maxHeight: 640, overflow: "auto" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      <input
                      className="cursorPointer"
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectAll}
                        id={`az-schooladmin-multicheckbox-btn`}
                        style={{
                          position: "absolute",
                          left: "15px",
                          top: "23px",
                          outline: "2px solid orange",
                          outlineOffset: "-2px",
                          transform: "scale(1.5)",
                          backgroundColor: "orange"
                        }}

                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Teacher Name
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={() => {
                          onSort("name")
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Email
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={() => {
                          onSort("email")
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Branch
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={() => {
                          onSort("branch")
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filterValue.length > 0 &&
                    filterValue.map((item, index) => {
                      return (
                        <TableRow
                          key={item?.id}
                          sx={{
                            "&:nth-child(even)": {
                              background: "#F5F5F8"
                            }, "&:hover": {
                              background: "#fff !important",
                              border: "2px solid orange !important"
                            }
                          }}
                          style={{ backgroundColor: (index % 2 === 0) ? "white" : "#f5f5f8" }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={dashboardContentStyles.table_cell}
                          >
                            <input
                            className="cursorPointer"
                              type="checkbox"
                              id={`az-schooladmin-checkbox-btn-${index}`}
                              checked={individualCheckboxes[index]}
                              onChange={() => handleIndividualCheckboxChange(index)}
                              style={{
                                marginTop: "-7px",
                                outline: "2px solid orange",
                                outlineOffset: "-2px",
                                transform: "scale(1.5)",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cell}
                          >
                            {item?.name}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cell}
                          >
                            {item?.email}
                          </TableCell>

                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cell}
                          >
                            {item?.branch}
                          </TableCell>
                          <TableCell align="center">
                            <button
                              id={`az-schooladmin-update-btn-${index}`}
                              className={contentApprovalStyles.orange_btn}
                              onClick={() => {
                                ProfileModal(item);
                              }}
                            >
                              Update Profile
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <div className={teacheridstyles.pagination}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={teacheridstyles.rows}>
                  <label for="rows">Rows per page</label>
                  <select
                    name="rows"
                    id="rows"
                    value={rowsInput.rows}
                    onChange={handlerRowsInputs}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </span>
              </form>
              <span>
                <Pagination1
                  pageDetail={pageDetail}
                  handleChange={handleChange}
                />
              </span>
            </div>
          </Paper>
        </div>
        <TeacherProfileUpdateModal
          open={openProfileModal}
          close={closeProfileModal}
          selectedProfile={selectedProfile}
          teachergrade={teachergrade}
          addAcess={() => {
            setPrincipalModal(true);
          }}
          show={false}
        />
        <TeacherAccessModal
          open={principalProfileModal}
          close={closeTeacherProfileModal}
          selectedItem={selectedItem}
          grades={grades}
          planId={planId}
          success={giveAccessSuccess}
        />

        <ProfileUpdateModal
          open={openUpdateModal}
          names={names}
          close={closeUpdateModal}
          successMessage={successMessage}
        />
      </div>
    </>
  );
};
export default TeacherAccess;


