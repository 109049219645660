import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_is_scrolling_to_top } from "../../../../../actions/bluePrintActions";
import BluePrintErrorModal from "../../../../../components/common/BluePrintErrorModal";
import ConfigurationSuccessModal from "../../../../../components/common/ConfigurationSuccessModal";
import dashHome from "../../../../../img/dashHome.svg";
import upArrow from "../../../../../img/upArrow.svg";
import { isNOTNullOrUndefined } from "../../../TeachValidate";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  createUploadAssessment,
  getAssessmentSingleContent,
  uploadFilePath,
} from "../Apis/RevisionAdminBluePrintApis";
import { validationSchema } from "../Schema/schema";

export default function CreateUpload({
  boardList,
  gradeList,
  subjectList,
  levelList,
  academicYearList,
  setFormSelectedBoard,
  setFormSelectedGrade,
  setCreated,
  setSelectedForEdit,
  selectedForEdit,
}) {
  let initialData = {
    paperTitle: "",
    blueprintLevelId: "",
    boardId: "",
    gradeId: "",
    subjectId: "",
    academicYearId: "",
  };

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialData);
  const [uploadedFilePath, setUploadedFilePath] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [errorCreateModal, setErrorCreateModal] = useState(false);
  const [modalMessageError, setModalMessageError] = useState("");
  const [showError, SetShowError] = useState(false);
  const [errorMessage, SetErrorMessage] = useState("");


  const [showCancelButton, setShowCancelButton] = useState(false);

  useEffect(() => {
    if (selectedForEdit) {
      dispatch(set_is_scrolling_to_top(true));
      editUploadAssessment(selectedForEdit);
    }
  }, [selectedForEdit]);

  const editUploadAssessment = async () => {
    const response = await getAssessmentSingleContent(selectedForEdit);
    if (response) {
      setFormData({
        id: response?.id,
        paperTitle: response?.paperTitle,
        blueprintLevelId: response?.blueprintLevelId,
        boardId: response?.boardId,
        gradeId: response?.gradeId,
        subjectId: response?.subjectId,
        academicYearId: response?.academicYearId,
      });
      setFormSelectedBoard(response?.boardId);
      setFormSelectedGrade(response?.gradeId);
      setUploadedFilePath(response?.paperUrl);
      setIsUploaded(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (e.target.name == "boardId") {
      setFormSelectedBoard(e.target.value);
    }
    if (e.target.name == "gradeId") {
      setFormSelectedGrade(e.target.value);
    }
  };

  const handleSubmit = async () => {
    // e.preventDefault();

    try {
      // Validate the form data using Yup
      await validationSchema.validate(formData, { abortEarly: false });
      onSubmit(formData);
      // Form data is valid, you can handle the submission here
      // Clear form errors
      setFormErrors({});
      SetErrorMessage("");
      SetShowError(false);
    } catch (error) {
      // Handle validation errors
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFormErrors(errors);
    }
  };

  const onSubmit = async (data) => {
    let objData = {
      id: data?.id || "",
      paperTitle: data?.paperTitle,
      boardId: data?.boardId,
      gradeId: data?.gradeId,
      subjectId: data?.subjectId,
      blueprintLevelId: data?.blueprintLevelId,
      academicYearId: data?.academicYearId,
      paperUrl: uploadedFilePath,
    };
    // Handle form submission here
    const response = await createUploadAssessment(objData);
    if (response?.success == true) {
      if (!selectedForEdit) {
        setModalMessage("Successfully assessment paper created");

        setFormData(initialData);
        setFormSelectedBoard("");
        setFormSelectedGrade("");
        setUploadedFilePath("");
        setIsUploaded(false);
        setCreated(true);
        setSelectedForEdit(null);
        setTimeout(() => {
          setSuccessCreateModal(true);
        }, 500);
      } else {
        setModalMessage("Successfully assessment paper updated");
        setTimeout(() => {
          setSuccessCreateModal(true);
        }, 500);
        setFormData(initialData);
        setFormSelectedBoard("");
        setFormSelectedGrade("");
        setUploadedFilePath("");
        setIsUploaded(false);
        setCreated(true);
        setSelectedForEdit(null);
      }
    } else {
      setModalMessageError(response.message);
      setTimeout(() => {
        setErrorCreateModal(true);
      }, 500);
    }
  };

  const resetForm = () => {
    setFormData(initialData);
    setFormErrors({});
    setFormSelectedBoard("");
    setFormSelectedGrade("");
    setIsUploaded(false);
    setUploadedFilePath("");
    setSelectedForEdit(null);
  };

  const handleFileSelect = (e) => {
    // fetchFilePath(e.target?.files[0]);
    const file = e.target?.files[0];
    // console.log("file", file)
    if (file && !file.name.toLowerCase().endsWith('.pdf')) {
      // alert('Only PDF files are allowed.');
      SetShowError(true);
      SetErrorMessage('Only PDF files are allowed.');
      setTimeout(() => {
        SetShowError(false);
        SetErrorMessage('');
      }, 5000);
      e.target.value = null; // Clear the input value to allow re-selection
      return;
    }
    fetchFilePath(file);
  };

  const fetchFilePath = async (file) => {
    const response = await uploadFilePath(file);
    if (response?.success) {
      setUploadedFilePath(response?.data);
      setIsUploaded(true);
    }
  };

  const onInputClick = (e) => {
    e.target.value = "";
  };

  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
  };
  const closeErrorModal = () => {
    setErrorCreateModal(false);
  };

  useEffect(() => {
    if (
      formData.paperTitle ||
      formData.boardId ||
      formData.gradeId ||
      formData.subjectId ||
      formData.blueprintLevelId ||
      formData.academicYearId ||
      isUploaded
    ) {
      setShowCancelButton(true);
    } else {
      setShowCancelButton(false);
    }
  }, [formData, isUploaded]);

  return (
    <div className="blue-print-wrap">
      <div style={{ marginLeft: "5px" }}>
        <span style={{ display: "flex", alignItems: "center" }}>
          <img
            src={dashHome}
            alt="no img"
            width="20px"
            height="20px"
            style={{ verticalAlign: "middle", marginRight: "10px" }}
          />
          Upload Assessment paper
        </span>
      </div>
      <div className="upload-assessment-form-container">
        <div className="form-wrapper">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "20ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                size="small"
                id="outlined-password-input"
                label="Assessment paper title"
                required
                name="paperTitle"
                onChange={handleChange}
                error={!!formErrors.paperTitle}
                helperText={formErrors.paperTitle}
                value={
                  isNOTNullOrUndefined(formData?.paperTitle)
                    ? formData.paperTitle
                    : ""
                }
              />
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Board
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.boardId)
                      ? formData.boardId
                      : ""
                  }
                  label="Board"
                  name="boardId"
                  onChange={handleChange}
                  error={!!formErrors.boardId}
                //   helperText={formErrors.paperTypeId}
                >
                  {boardList?.map((item) => {
                    return <MenuItem value={item?.id}
                      id={`az-revisionadmin-boarddropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>{item?.board}</MenuItem>;
                  })}
                </Select>
                {formErrors.boardId && (
                  <>
                    <p className="error-style">{formErrors.boardId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Grade
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.gradeId)
                      ? formData.gradeId
                      : ""
                  }
                  label="Grade"
                  name="gradeId"
                  onChange={handleChange}
                  error={!!formErrors.gradeId}
                //   helperText={formErrors.paperTypeId}
                >
                  {gradeList?.map((item) => {
                    return <MenuItem value={item?.id}
                      id={`az-revisionadmin-gradedropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>{item?.grade}</MenuItem>;
                  })}
                </Select>
                {formErrors.gradeId && (
                  <>
                    <p className="error-style">{formErrors.gradeId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.subjectId)
                      ? formData.subjectId
                      : ""
                  }
                  label="Subject"
                  name="subjectId"
                  onChange={handleChange}
                  error={!!formErrors.subjectId}
                //   helperText={formErrors.paperTypeId}
                >
                  {subjectList?.map((item) => {
                    return (
                      <MenuItem value={item?.subjectId}
                        id={`az-revisionadmin-subjectdropdown-${item.subjectId}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        {item?.subject}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formErrors.subjectId && (
                  <>
                    <p className="error-style">{formErrors.subjectId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.blueprintLevelId)
                      ? formData.blueprintLevelId
                      : ""
                  }
                  label="Board"
                  name="blueprintLevelId"
                  onChange={handleChange}
                  error={!!formErrors.blueprintLevelId}
                //   helperText={formErrors.paperTypeId}
                >
                  {levelList?.map((item) => {
                    return (
                      <MenuItem value={item?.id}
                        id={`az-revisionadmin-leveldropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <Tooltip title={item?.description} placement="right">
                          {item?.levelName}
                        </Tooltip>
                      </MenuItem>
                    );
                  })}
                </Select>
                {formErrors.blueprintLevelId && (
                  <>
                    <p className="error-style">{formErrors.blueprintLevelId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Academic year
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.academicYearId)
                      ? formData.academicYearId
                      : ""
                  }
                  label="Academic year"
                  name="academicYearId"
                  onChange={handleChange}
                  error={!!formErrors.academicYearId}
                >
                  {academicYearList?.map((item) => {
                    return (
                      <MenuItem value={item?.id}
                        id={`az-revisionadmin-aademicyeardropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>{item?.academicYear}</MenuItem>
                    );
                  })}
                </Select>
                {formErrors.academicYearId && (
                  <>
                    <p className="error-style">{formErrors.academicYearId}</p>
                  </>
                )}
              </FormControl>
              {uploadedFilePath == "" && !selectedForEdit ? (
                <label
                  className="image-button-upload"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    position: "relative",
                    backgroundColor: "#403e75"
                  }}
                >
                  <input
                    type="file"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                    onClick={onInputClick}
                  />
                  <span className="btn-text">Upload File</span>
                  <img
                    src={upArrow}
                    width="23px"
                    height="23px"
                    style={{ marginLeft: "25px" }}
                  />{" "}
                </label>
              ) : (
                ""
              )}
              {uploadedFilePath && !selectedForEdit ? (
                <label
                  className="file-upload-text"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10px",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                    onClick={onInputClick}
                  />
                  <span className="btn-text">File Uploaded</span>
                </label>
              ) : (
                ""
              )}
              {showError && (
                <Alert
                  action={
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => {
                        SetShowError(false);
                      }}
                    >
                      <CancelOutlinedIcon />
                    </IconButton>
                  }
                  icon={false}
                  sx={{
                    color: "red",
                    fontWeight: 500,
                    fontSize: "0.93vw",
                    width: "30%",
                    justifyContent: "flex-end",
                    // background: "#bddabca1",
                    background: "rgba(200,255,200,0.63)",
                  }}
                >
                  {/* Successfully Saved the Question Paper */}
                  {errorMessage}
                </Alert>
              )}
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "5px",
                  paddingRight: "20px",
                  position: "absolute",
                  bottom: "12px",
                  right: "12px",
                }}
              >
                <button
                  onClick={resetForm}
                  type="button"
                  className="button-cancel-assessment"
                  style={{
                    backgroundColor: showCancelButton ? "red" : "",
                    color: showCancelButton ? "#ffff" : "",
                    cursor: showCancelButton ? "pointer" : "default",
                  }}
                >
                  <span className="btn-text">Cancel</span>
                </button>
                {selectedForEdit ? (
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="button-submit-update"
                    style={{
                      backgroundColor: isUploaded ? "" : "#403e75",
                      cursor: "pointer",
                    }}
                    disabled={isUploaded ? false : true}
                  >
                    <span className="btn-text">
                      {selectedForEdit ? "Update" : "Create"}
                    </span>

                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="button-submit"
                    style={{
                      backgroundColor: isUploaded ? "" : "#403e75",
                      cursor: showCancelButton ? "pointer" : "default",
                    }}
                    disabled={isUploaded ? false : true}
                  >
                    <span className="btn-text">
                      {selectedForEdit ? "Update" : "Create"}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
      <ConfigurationSuccessModal
        userType={modalMessage}
        open={successCreateModal}
        handleOk={closeSuccessModal}
      />
      <BluePrintErrorModal
        userType={modalMessageError}
        open={errorCreateModal}
        handleOk={closeErrorModal}
      />
    </div>
  );
}
