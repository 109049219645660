import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import "./assignassessment.css";
import { ExpandMore, ExpandLess, Close } from "@material-ui/icons";
import { Collapse, ListItem } from "@mui/material";
import { Checkbox } from "@mui/material";
import {
  boxStyle,
  getFormattedBranchSchoolData,
  getFormattedObjectForSubmition,
  listItemStyle,
} from "../Data/DataHelpers";
import { isNOTNullOrUndefined } from "../../../TeachValidate";
import { paperAssignPost } from "../Apis/RevisionAdminBluePrintApis";
import ConfigurationSuccessModal from "../../../../../components/common/ConfigurationSuccessModal";

export default function AssignAssessmentModal({
  show,
  close,
  schoolData,
  paperData,
  onSubmitAssignSuccessfull,
}) {
  const [selectedSchoolForExpand, setSelectedSchoolForExpand] = useState("");
  const [selectedSchoolBranches, setSelectedSchoolBranches] = useState([]);
  const [selectedPapers, setSelectedPapers] = useState([]);

  const handleExpand = (id) => {
    if (id === selectedSchoolForExpand) {
      setSelectedSchoolForExpand("");
    } else {
      setSelectedSchoolForExpand(id);
    }
  };

  const handleSchoolCheck = (e, id) => {
    let schoolSelected = selectedSchoolBranches?.find((i) => i.schoolId === id);
    if (isNOTNullOrUndefined(schoolSelected)) {
      setSelectedSchoolBranches((prev) => prev.filter((i) => i.schoolId != id));
    } else {
      alert("Please do select atleast one branch");
    }
  };

  const handleBranchCheck = (e, branchId, schoolId) => {
    const selections = getFormattedBranchSchoolData(
      branchId,
      schoolId,
      selectedSchoolBranches
    );
    setSelectedSchoolBranches(selections);
  };

  const handlePaperCheck = (e, id) => {
    if (selectedPapers.includes(id)) {
      setSelectedPapers((prev) => prev.filter((i) => i != id));
    } else {
      setSelectedPapers((prev) => [...prev, id]);
    }
  };

  const submitAssignations = async () => {
    const obj = getFormattedObjectForSubmition(
      selectedSchoolBranches,
      selectedPapers
    );
    const response = await paperAssignPost(obj);
    if (response === 200) {
      setSelectedSchoolBranches([]);
      setSelectedPapers([]);
      setSelectedSchoolForExpand("");
      onSubmitAssignSuccessfull();
    } else {
      alert("error occured please try again later");
    }
    // console.log(response, "res");
  };
  const handleClose = () => {
    setSelectedSchoolBranches([]);
    setSelectedPapers([]);
    setSelectedSchoolForExpand("");
    close();
  };

  const isConfirmDisabled =
    selectedPapers.length === 0 || selectedSchoolBranches.length === 0;

  return (
    <Modal
      open={show}
      // onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <div className="modal-head">
          <p className="title">Assessment Paper</p>
          <div onClick={handleClose} className="modal-close">
            <Close style={{ color: "#292D32" }} />
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-left">
            <p className="title">Select School</p>
            <div className="modal-scroll">
              {schoolData?.map((i) => {
                const schoolSelected = isNOTNullOrUndefined(
                  selectedSchoolBranches
                )
                  ? selectedSchoolBranches?.find(
                      (item) => item.schoolId === i.schoolId
                    )
                  : {};

                return (
                  <div key={i.schoolId} style={{ marginBottom: "22px" }}>
                    <ListItem
                      sx={listItemStyle}
                      onClick={() => handleExpand(i.schoolId)}
                    >
                      <div className="flexed">
                        <Checkbox
                          sx={{
                            color: "#FAA220",
                            "&.Mui-checked": {
                              color: "#FAA220",
                            },
                          }}
                          onClick={(e) => handleSchoolCheck(e, i.schoolId)}
                          checked={isNOTNullOrUndefined(schoolSelected)}
                        />
                        <p className="school-text">{i.schoolName} </p>
                      </div>
                      <div>
                        {i.schoolId === selectedSchoolForExpand ? (
                          <ExpandLess style={{ color: "#FAA220" }} />
                        ) : (
                          <ExpandMore style={{ color: "#FAA220" }} />
                        )}
                      </div>
                    </ListItem>
                    <Collapse
                      in={selectedSchoolForExpand === i.schoolId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <div
                        style={{
                          width: "100%",
                          background: "#E5F4FF",
                          paddingLeft: "64px",
                        }}
                      >
                        {i?.branches?.map((branch) => {
                          return (
                            <div
                              key={branch.branchId}
                              style={{ marginBottom: "0px" }}
                              className="flexed"
                            >
                              <Checkbox
                                sx={{
                                  color: "#0D70B8",
                                  "&.Mui-checked": {
                                    color: "#0D70B8",
                                  },
                                }}
                                onClick={(e) =>
                                  handleBranchCheck(
                                    e,
                                    branch.branchId,
                                    i.schoolId
                                  )
                                }
                                checked={schoolSelected?.branches?.includes(
                                  branch.branchId
                                )}
                              />
                              <p className="branch-text">
                                {branch.branchName}{" "}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </Collapse>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-right">
            <p className="title">Select Assessment Paper</p>
            <div className="modal-scroll">
              {paperData?.map((i) => {
                return (
                  <div key={i.id} style={{ marginBottom: "22px" }}>
                    <ListItem sx={listItemStyle}>
                      <div className="flexed">
                        <Checkbox
                          sx={{
                            color: "#FAA220",
                            "&.Mui-checked": {
                              color: "#FAA220",
                            },
                          }}
                          onClick={(e) => handlePaperCheck(e, i.id)}
                          checked={selectedPapers.includes(i.id)}
                        />
                        <p className="school-text">{i.paperTitle} </p>
                      </div>
                    </ListItem>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="modal-bottom">
          <button
            disabled={isConfirmDisabled}
            onClick={() => submitAssignations()}
            className="modal-btn"
            style={{ background: isConfirmDisabled ? "#ccc" : "" }}
          >
            Confirm
          </button>
        </div>
      </Box>
    </Modal>
  );
}
