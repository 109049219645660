import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Tabs, Tooltip, TextField } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Yellow100Button from "../../../components/common/Yellow100Button";
import Green100Button from "../../../components/common/Green100Button";
import GetOTPModal from "../GetOTPModal";
import StudentSectionModal from "../StudentSectionModal";
import axios from "axios";
import GetLastModifiedAt from "../../API/teacher-controller/GetLastModifiedAt";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dashHome from "../../../../src/img/dashHome.svg";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import EditIconButton from "../../../components/common/EditIconButton";
import actionEdit from "../../../../src/img/Edit.svg";
import moment from 'moment';
const Student_StudentProfile = () => {
  const [value, setValue] = useState("profile");
  const [showGetOTPModal, setShowGetOTPModal] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({});
  const [currentAccessData, setCurrentAccessData] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location?.state) {
      setValue(location?.state?.value?.toString());
    } else {
      setValue("profile");
    }
  }, [location?.state]);
  const [studentDetail, setStudentDetail] = useState({
    firstName: "",
    emailId: "",
    dateOfBirth: "",
    motherTongue: "",
    admissionDate: "",
    address: "",
    lastName: "",
    mobileNum: "",
    gender: "",
    gradeSection: "",
    secondLang: "",
  });
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const token = sessionStorage.getItem("token");
  // const [teacherId, setTeacherId] = useState([]);
  const [studentId, setStudentId] = useState([]);

  useEffect(() => {
    // GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchStudentData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("schoolId", res.data.data.student.schoolId);
    sessionStorage.setItem("branchId", res.data.data.student.branchId);
    setStudentId(res?.data?.data?.student);
    // return res?.data?.data?.teacher || [];
  };

  // console.log("studentId", studentId);

  useEffect(async () => {
    if (userName && role) {
      const teacherData = await fetchStudentData(userName, role);
      setStudentDetail(teacherData);
    }
  }, []);

  return (
    <div style={{ width: "100%", background: "#EFEFFC" }}>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          <span
            style={{
              fontSize: "10px",
              marginLeft: "4px",
              color: "#7A7A7A",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/dashboard/template");
            }}
          >
            Home ⨠
          </span>
          <span
            style={{
              fontSize: "12px",
              color: "#001AF3",
              marginLeft: "4px",
            }}
          >
            Profile
          </span>
        </div>
        {/* <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div> */}
      </article>
      <div
        style={{
          marginTop: "5px",
          paddingRight: "33px",
          position: "absolute",
          right: "15px",
          left: "15px",
          width: "100%",
          height: "699px",
        }}
      >
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={(e, v) => handleChange(e, v)}
            textColor="inherit"
            // indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                background: "white",
                height: "10px",
                top: "50px",
              },
            }}
          ></Tabs>
          <TabPanel value="profile" sx={{ padding: "5px 5px" }}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                height: "auto",
              }}
            >
              <div
                style={{
                  background: "#545282",
                  color: "#EFEFFC",
                  padding: "15px",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                  fontSize: "16px",
                }}
              >
                Personal Information
              </div>
              <div
                style={{
                  padding: "20px 20px 60px 40px",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} lg={6} md={6} sm={12}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1.8rem",
                          paddingLeft: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "70px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            First Name
                          </div>
                          <TextField
                            name={"firstName"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={studentId.firstName || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "85px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Email Id
                          </div>
                          <TextField
                            type="email"
                            name={"emailId"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={studentId.email || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "59px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Date of Birth
                          </div>
                          <TextField
                            type={"text"}
                            name={"dateOfBirth"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={ studentId.dob != null && studentId.dob ? moment(studentId.dob).format('DD-MM-YYYY'): ''}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "40px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Mother Tongue
                          </div>
                          <TextField
                            type={"text"}
                            name={"motherTongue"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={studentId.firstLanguage || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "38px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Admission Date
                          </div>
                          <TextField
                            type={"text"}
                            name={"admissionDate"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={studentId.admissionDate != null && studentId.admissionDate ? moment(studentId.admissionDate).format('DD-MM-YYYY'): ''}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "85px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Address
                          </div>
                          <TextField
                            type={"text"}
                            name={"address"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "auto",
                                font: "normal normal normal 15px/20px Poppins",
                                whiteSpace: "pre-wrap",
                              },
                            }}
                            value={studentId.address || ""}
                            disabled
                            multiline
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                backgroundColor: "#E6E6E6",
                                "&:before": {
                                  borderBottom: "none",
                                },
                                "&:after": {
                                  borderBottom: "none",
                                },
                              },
                            }}
                            FormHelperTextProps={{
                              sx: {
                                color: "#464646",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={8} lg={6} md={6} sm={12}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1.8rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "60px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Last Name
                          </div>
                          <TextField
                            name={"lastName"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={studentId.lastName || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "30px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Mobile Number
                          </div>
                          <TextField
                            name={"mobileNum"}
                            sx={{
                              width: "328px",
                              borderRadius: 0,
                              background: "#E6E6E6",
                              color: "#464646",
                              // border: "1px solid",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal bold 15px/20px Poppins",
                              },
                            }}
                            value={studentId.mobile || ""}
                            disabled
                          />
                          {/*-------| Mobile No Edit Option |-------*/}
                          {/* <div style={{ marginLeft: "-80px" }}>
                            <EditIconButton
                              onClick={() => setShowGetOTPModal(true)}
                            >
                              <Tooltip title="Edit" placement={"top"}>
                                <img
                                  className={
                                    dashboardContentStyles.btnHoverEffect
                                  }
                                  src={actionEdit}
                                  alt="Edit-btn"
                                />
                              </Tooltip>
                            </EditIconButton>
                          </div> */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "85px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Gender
                          </div>
                          <TextField
                            name={"grade"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={studentId.gender || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "40px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            Grade Section
                          </div>
                          <TextField
                            name={"gradeSection"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={
                              studentId.grade + "," + " " + studentId.section ||
                              ""
                            }
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "38px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "13px",
                              color: "#333333",
                            }}
                          >
                            2nd Language
                          </div>
                          <TextField
                            name={"secondLang"}
                            sx={{
                              width: "328px",
                              background: "#E6E6E6",
                              color: "#464646",
                              "& .MuiInputBase-input": {
                                height: "10px",
                                font: "normal normal normal 15px/20px Poppins",
                              },
                            }}
                            value={studentId.secondLanguage || ""}
                            disabled
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    columnGap: "1rem",
                  }}
                >
                  <Yellow100Button
                    onClick={(e) => {
                      navigate("/dashboard/template");
                    }}
                  >
                    Cancel
                  </Yellow100Button>
                  <Green100Button
                   // Comment the below onClick
                    onClick={async () => {
                      const updatedData = {
                        firstName: "",
                        lastName: "",
                        email: "",
                        mobile: "",
                        dob: "",
                        gender: "",
                        documentUrl: null,
                        firstLanguageId: "",
                        secondLanguageId: "",
                        admissionDate: "",
                        studentCategoryId: "",
                        gradeId: "",
                        sectionId: "",
                        branch: "",
                        school: "",
                        isPromoted: true,
                        yearEndProcess: true,
                        address: "",
                        active: true,
                        lmsEnv: null,
                      };
                      await updateTeacherData(updatedData);
                      await fetchStudentData(userName, role);
                    }}
                  >
                    Save
                  </Green100Button>
                </div> */}
              </div>
            </Paper>
          </TabPanel>
        </TabContext>
      </div>
      {showGetOTPModal && (
        <GetOTPModal
          open={showGetOTPModal}
          close={() => setShowGetOTPModal(false)}
          phoneNumber={studentId.mobile}
        />
      )}
      {showStudentModal && studentData && (
        <StudentSectionModal
          open={showStudentModal}
          close={() => {
            setShowStudentModal(false);
            setSelectedSectionId("");
            setStudentData([]);
          }}
          data={studentData?.length && studentData}
        />
      )}
    </div>
  );
};
export default Student_StudentProfile;
