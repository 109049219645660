import React, { useState } from "react";

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import PlayLessonOutlinedIcon from "@mui/icons-material/PlayLessonOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import { makeStyles } from "@material-ui/core/styles";
import mindMap from "../../../../src/img/mind.png";
import handBurger_icn from "../../../../src/img/handBurger.png";
import { Button } from "@mui/material";
import "../../../../src/pages/css/Student/Student_SideMenu.css";
import { Typography } from "@material-ui/core";
const Student_SideMenu = ({
  revisionMenu,
  studyMenu,
  menuRevisionClickHandler,
  menuStudyClickHandler,
  subMenuHandlerRev,
  subMenuHandlerStu,
  collapseId,
  startIndex,
  selectedUrl,
}) => {
  const [openRivisionBtn, setOpenRivisionBtn] = useState(true);
  const [openStudyBtn, setOpenStudyBtn] = useState(true);
  const token = sessionStorage.getItem("token");

  const handleStudyBtn = () => {
    setOpenStudyBtn(!openStudyBtn);
  };

  const handleRivisionBtn = () => {
    setOpenRivisionBtn(!openRivisionBtn);
  };
  const [showIcon, setShowIcon] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: showIcon ? 80 : 250,
      backgroundColor: "#1D1A76",
      color: "white",
      minHeight: "130%",
      whiteSpace: "nowrap",
      overflowY: "auto",
      overflowX: "hidden",
    },
    nested: {
      paddingLeft: "0px",
      "&:hover": {
        color: "white",
      },
    },
    nestedSecondLevel: {
      paddingLeft: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const trimStringHandler = (value) => {
    return value?.replace(/ /g, "");
  };
  const IconHandler = (type, index, url) => {
    const filter = trimStringHandler(type);
    return filter?.includes(trimStringHandler("textBook")) ||
      filter?.includes(trimStringHandler("Book")) ? (
      // <img
      //   src={workBook}
      //   alt="searchIcon"
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "#ffcf09"
      //           : "white"
      //         : startIndex === index
      //         ? "#ffcf09"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("mindMap")) ? (
      <img
        src={mindMap}
        alt="searchIcon"
        sx={{
          color:
            url.length !== 0
              ? selectedUrl === url
                ? "#ffcf09"
                : "white"
              : startIndex === index
                ? "#ffcf09"
                : "white",
        }}
      />
    ) : // <PsychologyAltOutlinedIcon
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "#ffcf09"
      //           : "white"
      //         : startIndex === index
      //         ? "#ffcf09"
      //         : "white",
      //   }}
      // />
      filter?.includes(trimStringHandler("video")) ||
        filter?.includes(trimStringHandler("mp4")) ||
        filter?.includes(trimStringHandler("webm")) ||
        filter?.includes(trimStringHandler("ogv")) ||
        filter?.includes(trimStringHandler("mov")) ||
        filter?.includes(trimStringHandler("avi")) ? (
        // <img
        //   src={videoIcn}
        //   alt="searchIcon"
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      ) : filter?.includes(trimStringHandler("Workbook")) ? (
        <CardMembershipOutlinedIcon
          sx={{
            color:
              url.length !== 0
                ? selectedUrl === url
                  ? "#ffcf09"
                  : "white"
                : startIndex === index
                  ? "#ffcf09"
                  : "white",
          }}
        />
      ) : filter?.includes(trimStringHandler("Presentation")) ||
        filter?.includes(trimStringHandler("ppt")) ||
        filter?.includes(trimStringHandler("pptx")) ? (
        // <img
        //   src={presentation}
        //   alt="searchIcon"
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      ) : filter?.includes(trimStringHandler("lessonPlan")) ? (
        <PlayLessonOutlinedIcon
          sx={{
            color:
              url.length !== 0
                ? selectedUrl === url
                  ? "#ffcf09"
                  : "white"
                : startIndex === index
                  ? "#ffcf09"
                  : "white",
          }}
        />
      ) : filter?.includes(trimStringHandler("jpg")) ||
        filter?.includes(trimStringHandler("jpeg")) ||
        filter?.includes(trimStringHandler("png")) ||
        filter?.includes(trimStringHandler("svg")) ||
        filter?.includes(trimStringHandler("image")) ? (
        // <ImageIcon
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      ) : filter?.includes(trimStringHandler("pdf")) ||
        filter?.includes(trimStringHandler(".pdf")) ? (
        // <PictureAsPdfIcon
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      ) : filter?.includes(trimStringHandler("doc")) ||
        filter?.includes(trimStringHandler("docx")) ? (
        // <DocumentScannerIcon
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      ) : filter?.includes(trimStringHandler("Category")) ? (
        <CategoryIcon
          sx={{
            color:
              url.length !== 0
                ? selectedUrl === url
                  ? "#ffcf09"
                  : "white"
                : startIndex === index
                  ? "#ffcf09"
                  : "white",
          }}
        />
      ) : filter?.includes(trimStringHandler("zip")) ? (
        // <FolderZipIcon
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      ) : filter?.includes(trimStringHandler("audio")) ||
        filter?.includes(trimStringHandler("mp3")) ||
        filter?.includes(trimStringHandler("wav")) ||
        filter?.includes(trimStringHandler("wma")) ||
        filter?.includes(trimStringHandler("aac")) ||
        filter?.includes(trimStringHandler("ogg")) ? (
        // <LibraryMusicIcon
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      ) : (
        // <InsertDriveFileIcon
        //   sx={{
        //     color:
        //       url.length !== 0
        //         ? selectedUrl === url
        //           ? "#ffcf09"
        //           : "white"
        //         : startIndex === index
        //         ? "#ffcf09"
        //         : "white",
        //   }}
        // />
        ""
      );
  };
  const nameHandler = (name) => {
    // console.log("namehandler", name)
    const data = name.split("/")?.[name.split("/").length - 1]?.split(".");
    // const first = data[0].split("_");
    const first = data;
    // return `${first[0]}.${data?.[data.length - 1]}`;
    var str = first[0];
    return `${str[0].toUpperCase()}${str.slice(1)}`;

  };

  // console.log("revisionMenu", revisionMenu);
  // console.log("studyMenu", studyMenu);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{
            backgroundColor: "#061ca5",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "54px",
          }}
          onClick={() => setShowIcon(!showIcon)}
        >
          {/* <MenuOutlinedIcon /> */}
          <img src={handBurger_icn} width="33px" height="30px" />
        </ListSubheader>
      }
      className={classes.root}
    >
      {/*------------------------------------------------| Study Details |------------------------------------------------------*/}
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            id={`az-study-btn`}
            onClick={handleStudyBtn}
            style={{ color: "white", fontSize: "13px", fontWeight: "bold", fontFamily: "poppins" }}
          >
            {openStudyBtn ? "Study" : "Study"}
          </Button>
        </div>
        <Collapse in={openStudyBtn}>
          <div>
            {studyMenu?.map((item, index) => {
              return (
                <div key={item?.documentCategoryId}>
                  <div
                    className={`studyHeading ${startIndex === index ? "borderLeft" : ""
                      }`}
                  >
                    <ListItem
                      button
                      key={item?.documentCategoryId}
                      id={`az-study-${index}`}
                      onClick={() =>
                        menuStudyClickHandler(
                          item?.documentCategoryId,
                          item,
                          index
                        )
                      }
                    >
                      <div className="listItemIcon">
                        {/* {IconHandler(item?.documentType, index, "")} */}
                        <img
                          src={item.documentUrl}
                          width="20px"
                          height="20px"
                        />
                      </div>
                      {!showIcon && (
                        <ListItemText
                          // primary={item.documentType}
                          // sx={{
                          //   color: startIndex === index ? "#ffcf09" : "white",
                          // }}
                          key={index}
                          primary={
                            <Typography
                              style={{
                                fontSize: "13px",
                                color:
                                  startIndex === index ? "#ffcf09" : "white",
                                textTransform: "capitalize",
                                fontFamily: "poppins",
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                              }}
                            >
                              {item.documentType}
                            </Typography>
                          }
                        />
                      )}
                    </ListItem>
                  </div>
                  {item?.studentStudyContent?.map((sub) => {
                    return (
                      <Collapse
                        in={
                          startIndex === index
                            ? true
                            : collapseId === item.documentCategoryId
                        }
                        timeout="auto"
                        unmountOnExit
                        key={sub.id}
                        id={`az-sublist-${item.documentCategoryId}`}
                        onClick={() =>
                          subMenuHandlerStu(
                            sub?.contentWithIndux,
                            sub?.contentUrl
                          )
                        }
                      >
                        <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            <ListItemIcon>
                              {IconHandler(
                                sub?.contentWithIndux,
                                "",
                                sub?.contentUrl
                              )}
                            </ListItemIcon>
                            {!showIcon && (
                              <ListItemText
                                id="101"
                                // primary={nameHandler(sub?.contentWithIndux)}
                                sx={{
                                  // color:
                                  //   selectedUrl === sub?.contentUrl
                                  //     ? "#ffcf09"
                                  //     : "white",
                                  color: "white",
                                  fontFamily: "poppins"
                                }}
                                key={index}
                                primary={
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      color: "white",
                                      textTransform: "capitalize",
                                      fontFamily: "poppins",
                                      whiteSpace: 'normal',
                                      overflowWrap: 'break-word',
                                    }}
                                  >
                                    {nameHandler(sub?.contentWithIndux)}
                                  </Typography>
                                }
                              />
                            )}
                          </ListItem>
                        </List>
                      </Collapse>
                    );
                  })}
                </div>
              );
            })}{" "}
          </div>
        </Collapse>
      </div>

      {/*------------------------------------------------| Revision Details |------------------------------------------------------*/}
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            id={`az-revision-btn`}
            onClick={handleRivisionBtn}
            style={{ color: "white", fontSize: "13px", fontWeight: "bold", fontFamily: "poppins" }}
          >
            {openRivisionBtn ? "Revision" : "Revision"}
          </Button>
        </div>
        <Collapse in={openRivisionBtn}>
          <div>
            {revisionMenu?.map((item, index) => {
              return (
                <div key={item?.documentCategoryId}>
                  <div
                    className={`studyHeading ${startIndex === index ? "borderLeft" : ""
                      }`}
                  >
                    <ListItem
                      button
                      key={item?.documentCategoryId}
                      id={`az-revision-${index}`}
                      onClick={() =>
                        menuRevisionClickHandler(
                          item?.documentCategoryId,
                          item,
                          index
                        )
                      }
                    >
                      <div className="listItemIcon">
                        {/* {IconHandler(item?.documentType, index, "")} */}
                        <img
                          src={item.documentUrl}
                          width="20px"
                          height="20px"
                        />
                      </div>
                      {!showIcon && (
                        <ListItemText
                          // primary={item.documentType}
                          // sx={{
                          //   color: startIndex === index ? "#ffcf09" : "white",
                          // }}
                          key={index}
                          primary={
                            <Typography
                              style={{
                                fontSize: "13px",
                                color:
                                  startIndex === index ? "#ffcf09" : "white",
                                textTransform: "capitalize",
                                fontFamily: "poppins",
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                              }}
                            >
                              {item.documentType}
                            </Typography>
                          }
                        />
                      )}
                    </ListItem>
                  </div>
                  {item?.studentRevisionContent?.map((sub) => {
                    return (
                      <Collapse
                        in={
                          startIndex === index
                            ? true
                            : collapseId === item.documentCategoryId
                        }
                        timeout="auto"
                        unmountOnExit
                        key={sub.id}
                        id={`az-sublist-${item.documentCategoryId}`}
                        onClick={() =>
                          subMenuHandlerRev(
                            sub?.contentWithIndux,
                            sub?.contentUrl
                          )
                        }
                      >
                        <List component="div" disablePadding>
                          <ListItem button className={classes.nested}>
                            <ListItemIcon>
                              {IconHandler(
                                sub?.contentWithIndux,
                                "",
                                sub?.contentUrl
                              )}
                            </ListItemIcon>
                            {!showIcon && (
                              <ListItemText
                                id="102"
                                // primary={nameHandler(sub?.contentWithIndux)}
                                sx={{
                                  // color:
                                  //   selectedUrl === sub?.contentUrl
                                  //     ? "#ffcf09"
                                  //     : "white",
                                  color: "white",
                                }}
                                key={index}
                                primary={
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      color: "white",
                                      textTransform: "capitalize",
                                      whiteSpace: 'normal',
                                      overflowWrap: 'break-word',
                                    }}
                                  >
                                    {nameHandler(sub?.contentWithIndux)}
                                  </Typography>
                                }
                              />
                            )}
                          </ListItem>
                        </List>
                      </Collapse>
                    );
                    <List>
                      <ListItem>hai</ListItem>
                    </List>;
                  })}
                </div>
              );
            })}
          </div>
        </Collapse>
      </div>
    </List>
  );
};
export default Student_SideMenu;
