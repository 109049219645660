import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../../App.css";
import TopRightPopup from "../../components/common/TopRightPopup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import studentFilesStyles from "./../css/studentFiles.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
// take this css copy in original file
import Pagination1 from "./pagination1";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import document_category_get from "../API_CONTROLLER/document-category-controller/document_category_get";
import student_upload_chapter_get from "../API_CONTROLLER/student-upload-content-controller/student_upload_chapter_get";
import student_content_post from "../API_CONTROLLER/student-upload-content-controller/student_content_post";
import student_file_post from "../API_CONTROLLER/student-upload-content-controller/student_file_post";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import student_contentType_getall from "../API_CONTROLLER/student-upload-content-controller/student_contentType_getall";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import RejectedModal from "./RejectedModal";
import ApprovalModal from "./ApprovalModal";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import action__Delete from "../../../src/img/Delete.svg";
import dashHome from "../../../src/img/dashHome.svg";
import teacher_paginated_content_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_paginated_content_get";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import fileApprovalStyles from "../css/fileApproval.module.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import ContentViewer from '../../components/common/ContentViewer';

const ContentApprovalViewFile = () => {
  const [chapters, setChapters] = useState({});
  const [show, setShow] = useState(false);
  const token = sessionStorage.getItem("token");
  const [docCategory, setDocCategory] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [updatedata, setUpdatedata] = useState({});
  const [filterval, setFilterval] = useState({});
  const [chaptersList, setChaptersList] = useState({});
  const [contenttype, setContenttype] = useState({});
  const [modifiedDate, setModifiedDate] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [approveModel, setapproveModal] = useState(false);
  const [rejecteModel, setrejecteModal] = useState(false);
  const [rejectComment, setrejecetComment] = useState("");
  const [message, setMessage] = useState();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleOpen = (elem) => {
    setOpenDeleteModal(true);
    setSelectedItem(elem);
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/${selectedItem.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        student_upload_chapter_get(setUpdatedata);
        hideDeleteModal();
      })
      .catch((err) => console.log(err));

    hideDeleteModal();
  };

  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  const [chapterName, setChapterName] = useState("");
  useEffect(() => {
    // console.log("updatedata", updatedata)
    setFilterval(updatedata);
    setChapterName(updatedata[0]?.chapterName);
  }, [updatedata]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }, [show]);

  const [url, setUrl] = useState();
  const openIframe = (contentUrl) => {
    if (
      contentUrl.includes(".xlsx") ||
      contentUrl.includes(".pptx") ||
      contentUrl.includes(".doc") ||
      contentUrl.includes(".ppt") ||
      contentUrl.includes(".docx")
    ) {
      setUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${contentUrl}`
      );
    } else {
      setUrl(contentUrl);
    }
  };

  const [uptdata, setUptDta] = useState([]);
  const [isDisabled, setDisabled] = useState();
  useEffect(() => {
    teacher_paginated_content_get(setUptDta);
    return () => { };
  }, [sessionStorage.getItem("chapterId")]);

  useEffect(() => {
    if (uptdata.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [uptdata]);

  useEffect(() => {
    // chapters_controller_getall(setChapters);
    document_category_get(setDocCategory);
    student_upload_chapter_get(setUpdatedata);
    chapters_list_getall(setChaptersList);
    student_contentType_getall(setContenttype);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <span className={dashboardContentStyles.link_text}>
              Content Approval
            </span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Student Files</a>
            {"  "}
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={`${dashboardContentStyles.az_menu_icon} cursorPointer`}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate("/dashboard/template")}
            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span className={dashboardContentStyles.az_menu_text}>
              Content Approval
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Student Files
            </span>
          </div>

          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div>
        </article>
        <div
          style={{ marginTop: "65px", position: "relative" }}
          className={fileApprovalStyles.flex}
        >
          <div
            id={`az-content-backarrow`}
            style={{ width: "15px", }}
            className={fileApprovalStyles.left_row}>
            <i
              onClick={(e) => {
                if (
                  ((filterval[0]?.studyDocMaterials || []).length > 0 ||
                    (filterval[0]?.revisionDocMaterials || []).length > 0) &&
                  sessionStorage.getItem("isStatus") == "true"
                ) {
                  navigate(
                    `/dashboard/ContentApprovalApproved?chapterId=${updatedata[0].chapterId}`
                  );
                } else if (searchParams.get("chapterId")) {
                  navigate(
                    `/dashboard/ContentApprovalApproved?chapterId=${searchParams.get(
                      "chapterId"
                    )}`
                  );
                } else {
                  navigate("/dashboard/ContentApprovalApproved");
                }
              }}
              className="fa-solid fa-arrow-left cursorPointer"
            ></i>
            <i
              style={{ color: "grey", fontSize: "24px", margin: "10px" }}
              className="fa-solid fa-user-gear"
            ></i>
            <p>Approved Files</p>
          </div>

          <div style={{ position: "absolute", right: "15px" }}>
            <button
              id={`az-content-student-button`}
              style={{
                color: "white",
                backgroundColor: "#FD8C00",
                cursor: "pointer",
              }}
              className={fileApprovalStyles.orange_btn}
              onClick={(event) => {
                event.preventDefault();
                navigate("/dashboard/contentapprovalviewfile");
                sessionStorage.setItem("status", "APPROVED");
              }}
            >
              Student
            </button>
            <button
              id={`az-content-teacher-button`}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/ContentApprovalTeacherViewFile");
                sessionStorage.setItem("status", "APPROVED");
              }}
              className={
                !isDisabled
                  ? fileApprovalStyles.orange_btn
                  : fileApprovalStyles.orange_btn_disabled_grey
              }
              disabled={isDisabled}
            >
              Teacher
            </button>
          </div>
        </div>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 15px",
            marginLeft: "15px",
          }}
          style={{ display: "flex" }}
        >
          <div
            style={{
              // minWidth: '1166px',
              width: "40%%",
              height: "667px",
              backgroundColor: "white",
            }}
          >
            <Table
              style={{
                width: "40%",
              }}
            >
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    className={studentFilesStyles.table_head}
                    align="left"
                    style={{ margin: "8px 8px" }}
                  >
                    {/* <Checkbox
                      style={{
                        color: "blue",
                        margin: "1px",
                        left: "10px",
                        backgroundColor: "#D9DCFE",
                        transform: "scale(0.8)",
                      }}
                      onChange={(event) => handleallChapterlist(event)}
                    />{" "} */}
                    {chapterName}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "100%",
                      overflowY: "scroll",
                      height: "600px",
                      borderRight: "1px solid lightgrey",
                      position: "relative",
                    }}
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ backgroundColor: "#92A2E8" }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "white" }}>
                          STUDY MATERIAL -{" "}
                          {filterval[0]?.studyDocWithCount
                            ?.match(/\d/g)
                            .toString()
                            .replaceAll(",", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {filterval[0]?.studyDocMaterials &&
                          filterval[0]?.studyDocMaterials.length > 0 ? (
                          filterval[0]?.studyDocMaterials.map((item) => {
                            return (
                              <Accordion>
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.documentType} -{" "}
                                    {item.studentStudyContent?.length}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {item.studentStudyContent &&
                                    item.studentStudyContent.length > 0 ? (
                                    item.studentStudyContent.map((elem) => {
                                      return (
                                        <Accordion>
                                          <AccordionSummary
                                            style={{
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          // expandIcon={<ExpandMoreIcon />}
                                          >
                                            <div
                                              style={{
                                                marginTop: "-13px",
                                                width: "332px",
                                                whiteSpace: "nowrap",
                                                direction: "rtl",
                                                overflow: "auto",
                                              }}
                                            >
                                              {/* {elem.reviewStatus !=
                                                "APPROVED" && (
                                                <Checkbox
                                                  style={{
                                                    color: "#fb9d2a",
                                                    width: "10px",
                                                    position: "absolute",
                                                    left: "8px",
                                                    top: "8px",
                                                  }}
                                                  onChange={(event) =>
                                                    handleChapterlist(
                                                      event,
                                                      elem
                                                    )
                                                  }
                                                  checked={
                                                    ids.includes(elem?.id)
                                                      ? true
                                                      : false
                                                  }
                                                  // disabled={
                                                  //   elem.reviewStatus ==
                                                  //   "APPROVED"
                                                  // }
                                                />
                                              )} */}
                                              <a
                                                // href={elem.contentUrl}
                                                // download={elem.contentWithIndux}
                                                onClick={() =>
                                                  openIframe(elem?.contentUrl)
                                                }
                                                style={{
                                                  // display: 'flex',
                                                  textDecoration: "none",
                                                  fontSize: "1vw",
                                                  color: "grey",
                                                  // left: "20px",
                                                  left: "40px",
                                                  position: "absolute",
                                                  width: "260px",
                                                  whiteSpace: "nowrap",
                                                  direction: "rtl",
                                                  overflow: "auto",
                                                  textOverflow: "ellipsis",
                                                  // word wrap style (...)
                                                  inlineSize: "200px",
                                                  overflowWrap: "break-word",
                                                  hyphens: "manual",
                                                }}
                                                data-toggle="tooltip"
                                                title={elem.contentWithIndux}
                                              >
                                                {elem.contentWithIndux.length >
                                                  20
                                                  ? elem.contentWithIndux.slice(
                                                    0,
                                                    15
                                                  ) + "...."
                                                  : elem.contentWithIndux}
                                              </a>
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  left: "320px",
                                                  marginTop: "-7px",
                                                }}
                                              >
                                                <DeleteIconButton
                                                  onClick={() => {
                                                    // showDeleteModal(elem);
                                                    // setSelectedItem(elem);
                                                    handleOpen(elem);
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Remove"
                                                    placement={"top"}
                                                  >
                                                    <img
                                                      className={
                                                        dashboardContentStyles.aRoleActionBtn
                                                      }
                                                      style={{
                                                        width: "38px",
                                                      }}
                                                      src={action__Delete}
                                                      alt="Dlt-Btn"
                                                    />
                                                  </Tooltip>
                                                </DeleteIconButton>
                                              </span>
                                              {/* <i style={{position:"absolute",right:"10px"}} className="bi bi-download"></i> */}
                                            </div>
                                          </AccordionSummary>
                                        </Accordion>
                                      );
                                    })
                                  ) : (
                                    <p>-</p>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        ) : (
                          <p>-</p>
                        )}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        style={{ backgroundColor: "#92A2E8" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "white" }}>
                          REVISION MATERIAL -{" "}
                          {filterval[0]?.revisionDocWithCount
                            ?.match(/\d/g)
                            .toString()
                            .replaceAll(",", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {filterval[0]?.revisionDocMaterials &&
                          filterval[0]?.revisionDocMaterials.length > 0 ? (
                          filterval[0]?.revisionDocMaterials.map((item) => {
                            return (
                              <Accordion>
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.documentType} -{" "}
                                    {item.studentRevisionContent?.length}{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {item.studentRevisionContent &&
                                    item.studentRevisionContent.length > 0 ? (
                                    item.studentRevisionContent.map((elem) => {
                                      return (
                                        <Accordion>
                                          <AccordionSummary
                                            style={{
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          // expandIcon={<ExpandMoreIcon />}
                                          >
                                            <div
                                              style={{
                                                marginTop: "-13px",
                                                width: "332px",
                                                whiteSpace: "nowrap",
                                                direction: "rtl",
                                                overflow: "auto",
                                              }}
                                            >
                                              {/* {elem.reviewStatus !=
                                                "APPROVED" && (
                                                <Checkbox
                                                  style={{
                                                    color: "#fb9d2a",
                                                    width: "10px",
                                                    position: "absolute",
                                                    left: "8px",
                                                    top: "8px",

                                                    // outline:
                                                    //   "2px solid #fb9d2a",
                                                    // outlineOffset: "-2px",
                                                    // transform: "scale(0.8)",
                                                  }}
                                                  onChange={(event) =>
                                                    handleChapterlist(
                                                      event,
                                                      elem
                                                    )
                                                  }
                                                  checked={
                                                    ids.includes(elem?.id)
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              )} */}
                                              <a
                                                // href={elem.contentUrl}
                                                // download={elem.contentWithIndux}
                                                onClick={() =>
                                                  openIframe(elem?.contentUrl)
                                                }
                                                style={{
                                                  textDecoration: "none",
                                                  fontSize: "1vw",
                                                  color: "grey",
                                                  // left: "20px",
                                                  left: "40px",
                                                  position: "absolute",
                                                  width: "332px",
                                                  whiteSpace: "nowrap",
                                                  direction: "rtl",
                                                  overflow: "auto",
                                                  textOverflow: "ellipsis",
                                                  inlineSize: "200px",
                                                  overflowWrap: "break-word",
                                                  hyphens: "manual",
                                                }}
                                                data-toggle="tooltip"
                                                title={elem.contentWithIndux}
                                              >
                                                {elem.contentWithIndux.length >
                                                  20
                                                  ? elem.contentWithIndux.slice(
                                                    0,
                                                    15
                                                  ) + "...."
                                                  : elem.contentWithIndux}
                                              </a>
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  left: "320px",
                                                  marginTop: "-7px",
                                                }}
                                              >
                                                <DeleteIconButton
                                                  onClick={() => {
                                                    showDeleteModal(elem);
                                                    // setSelectedItem(elem);
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Remove"
                                                    placement={"top"}
                                                  >
                                                    <img
                                                      className={
                                                        dashboardContentStyles.aRoleActionBtn
                                                      }
                                                      style={{
                                                        width: "38px",
                                                      }}
                                                      src={action__Delete}
                                                      alt="Dlt-Btn"
                                                    />
                                                  </Tooltip>
                                                </DeleteIconButton>
                                              </span>
                                              {/* <i style={{position:"absolute",right:"10px"}} className="bi bi-download"></i> */}
                                            </div>
                                          </AccordionSummary>
                                        </Accordion>
                                      );
                                    })
                                  ) : (
                                    <p>-</p>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        ) : (
                          <p>-</p>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* <div
                    style={{
                      position: "absolute",
                      left: "100px",
                      bottom: "-170px",
                    }}
                  >
                    <button
                      className={fileApprovalStyles.orange_btn}
                      onClick={(e) => {
                        rejecteModal();
                      }}
                    >
                      REJECT
                    </button>
                    <button
                      style={{ color: "white", backgroundColor: "#FD8C00" }}
                      className={fileApprovalStyles.orange_btn}
                      onClick={(e) => {
                        approveModal();
                      }}
                    >
                      APPROVE
                    </button>
                  </div> */}
                </div>
              </TableBody>
            </Table>
          </div>
          <div onContextMenu={handleRightClick}>
          {url && (<ContentViewer contentstyle={{ height: "700px", width: "1170px" }}
              url={url} />)}
            {/* {url && (
              // <Worker 
              //   workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div
                  style={{ height: "700px", width: "1170px" }}>
                  <Viewer
                    fileUrl={'https://cors-anywhere.herokuapp.com/' + url}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              // </Worker>
              // <iframe
              //   src={url}
              //   style={{ height: "700px", width: "1170px" }}
              // ></iframe>
            )} */}
          </div>
        </Paper>

        {/* content end */}
        {/* <ApprovalModal open={approveModel} approve={approve} />
        <RejectedModal
          open={rejecteModel}
          close={closerejectmodel}
          rejecte={rejecte}
        >
          <TextField
            label="Reason"
            rows={5}
            multiline
            style={{ width: 450 }}
            value={rejectComment}
            onChange={(event) => setrejecetComment(event.target.value)}
          />
        </RejectedModal> */}
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Content"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem.contentWithIndux}
        />
        <div
          className="box animate fadeInUp one"
          style={
            show
              ? {
                color: "red",
                position: "absolute",
                right: "10px",
                top: "10px",
              }
              : { display: "none" }
          }
        >
          <TopRightPopup
            style={{ color: "red", position: "absolute", right: "10px" }}
          />
        </div>
      </div>
    </>
  );
};

export default ContentApprovalViewFile;
