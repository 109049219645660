import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  IconButton,
} from "@mui/material";
import ExcelJS from 'exceljs';
import moment from 'moment';
import sortIcon from "../../../../../src/pages/img/sort_1.png";
import commonsort from "../../../js/CommonSort";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const StudentWisePerformance = ({ data, absenteeData, downloadReport, setDownloadReport, loading, studentTabDataError }) => {
  // const [conceptList, setConceptList] = useState([]);
  // console.log('absenteeData data >> ', data, absenteeData, studentTabDataError);
  const [studentData, setStudentData] = useState([]);
  const [absentstudentData, setAbsentStudentData] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(true);
  const [CreateAlert, setCreateAlert] = useState(false)
  const [CreateAlertMessage, setCreateAlertMessage] = useState("")


  const sortData = () => {
    const sortedData = [...studentData].sort((a, b) => {
      if (sortDirection === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setStudentData(sortedData);
    // console.log("setStudentData( 2", sortedData)
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };


  useEffect(() => {
    const setConceptLevelData = () => {
      //console.log('data >>setConceptLevelData ' + data);
      let alteredData = [];
      data?.map(x => {
        let concept = [];
        x.concepts?.map(item => {
          concept.push({
            id: `${item?.conceptName}`,
            name: item?.conceptName,
            subConcept: item?.subConcepts?.map(subItem => {
              return {
                subConceptName: subItem?.subConceptName,
                rootConcept: subItem?.rootConcepts !== null ? subItem?.rootConcepts?.map(rootItem => rootItem?.rootConceptName ? rootItem?.rootConceptName : '') : [],
                // marksArr: subItem?.rootConcepts !== null
                //   ? subItem?.rootConcepts?.map(rootItem => rootItem?.marks !== null ? rootItem?.marks : subItem?.mark)
                //   : [subItem?.mark]
                marksArr: {
                  rootMark: subItem?.rootConcepts !== null
                    ? subItem?.rootConcepts?.map(root => root?.marks) : [],
                  subMark: subItem?.mark !== null ? subItem?.mark : ''
                }
              }
            }),
          });
        });

        x["concepts"] = concept;
        x["timeTaken"] = x?.averageTimeTaken;
        x["attemptOn"] = x?.attemptOn;
        x["timePerQuestion"] = x?.avgTimePerQuestion;
        x["pq1"] = x?.pqAttempt1;
        x["pq2"] = x?.pqAttempt2;
        alteredData.push(x);
      })
      alteredData?.sort((a, b) => b?.percentage - a?.percentage);
      // console.log(alteredData);
      // setAbsentStudentData(data.absentees);  
      // alteredData = alteredData?.filter(a => a.concepts.length > 0)
      setStudentData(alteredData);
      // console.log("setStudentData1  ", alteredData)
      if (!data) {
        setCreateAlert(true);
        setCreateAlertMessage(studentTabDataError?.error)
      } else if (alteredData?.length == 0) {
        setCreateAlert(true);
        setCreateAlertMessage("NO DATA AVAILABLE")
      } else {
        setCreateAlert(false);
        setCreateAlertMessage("")
      }

    }
    setConceptLevelData();
    // setAbsentStudentData = absenteeData;
    // console.log('setAbsentStudentData data >> '+absentstudentData);
  }, [data]);

  // useEffect(() => {   
  //   let absData = [];
  //   absData = absenteeData;
  //   setAbsentStudentData = absData;
  // //console.log('setAbsentStudentData data >> '+absentstudentData);
  // }, [absenteeData]);

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    const bodyColor = {
      argb: 'CF82E9F5',
    };

    const headerColor = {
      argb: '4080C0',
    };

    const headerCellStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: headerColor,
      },
      font: { color: { argb: 'FFFFFF' }, bold: true },
      border: {
        top: { style: 'thin' }, left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: { vertical: 'middle', horizontal: 'center' },
    };

    const bodyCellStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: bodyColor,
      },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: { vertical: 'middle', horizontal: 'center' },
      wrapText: true,
    };

    const columnNames = [
      'S. No',
      'Student Name',
      'Concept',
      'Sub Concept',
      'Root-Concept',
      'Marks',
      'Percentage',
      'Time Taken(Min)',
      'Avg. time per Question',
      'Attempted on',
      'PQ 1 Attempt',
      'PQ 2 Attempt',
      'Highest Score (PQ)',
    ];

    worksheet.addRow(columnNames);
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.style = headerCellStyle;
    });

    let lastUnmergedRow = 2;
    let lastStudentId = null;
    let rowStart = 2;

    // console.log("excel data >>> ", studentData);



    studentData?.forEach((student, index) => {
      const studentId = index + 1;
      const studentName = student?.name ?? '-';
      const percentage = student?.percentage ? Math.round(student?.percentage) : 0;
      const Vpercentage = student?.percentage ? Math.round(student?.percentage) : "-";
      const timeTaken = student?.timeTaken ? simplifiedTime(student?.timeTaken) : '-';
      const avgTimeTaken = student?.timePerQuestion ? simplifiedTime(student?.timePerQuestion) : '-';
      const attemptOn = student?.attemptOn ? moment(student?.attemptOn).format('DD-MM-YYYY') : '-';
      const pq1 = student?.pq1 ?? "-";
      const pq2 = student?.pq2 ?? "-";
      const highestPQScore = (pq1 === 0 && pq2 === 0) ? '0' : Math.round(student?.highestScore) ?? '-';
      // console.log(student?.name, student?.percentage, "--------------")
      // If student has no concepts, use the fallback behavior.
      if (student?.concepts.length == 0 || student?.concepts == undefined) {
        student.concepts = [{ name: "-" }]
      }
      student?.concepts?.forEach(concept => {
        const conceptName = concept?.name;
        if (concept?.subConcept?.length == 0 || concept?.subConcept == undefined) {
          concept.subConcept = [{ subConceptName: "-" }]
        }
        concept?.subConcept?.forEach((subConcept, subIndex) => {
          var subConceptName = subConcept?.subConceptName;
          var rootConcepts = subConcept?.rootConcept ? subConcept?.rootConcept : [];
          var marks = subConcept?.marksArr?.rootMark !== null ? subConcept?.marksArr?.rootMark : ['-'];
          const subItemMark = (subConcept?.marksArr?.subMark !== null || subConcept?.marksArr?.subMark !== '') ? subConcept?.marksArr?.subMark : '-';

          // console.log(subItemMark, "marks ", marks, subConcept, subConcept?.marksArr?.subMark)
          const rowData = [
            studentId,
            studentName,
            conceptName,
            subConceptName,
            '-',
            marks?.length > 0 ? marks[0] : subItemMark || '-',
            `${percentage}%`,
            timeTaken,
            avgTimeTaken,
            attemptOn,
            pq1,
            pq2,
            `${highestPQScore}%`
          ];

          let backgroundColor = '';
          if (percentage >= 80) {
            backgroundColor = '9BC2E6'; // Green
          } else if (percentage >= 60) {
            backgroundColor = 'C6E0B4'; // Yellow
          } else if (percentage >= 40) {
            backgroundColor = 'FFE699'; // Yellow
          } else {
            backgroundColor = 'FF6699'; // Red
          }

          const dataRow = worksheet.addRow(rowData);
          // console.log("backgroundColor", backgroundColor, studentName, percentage)
          dataRow.eachCell((cell) => {
            cell.style = {
              ...bodyCellStyle,
              fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
            };
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true, // Wrap text in cells
            };
          });

          if (subIndex === 0) {
            if (rowStart < dataRow.number) {
              worksheet.mergeCells(`C${rowStart}:C${dataRow.number - 1}`);
              rowStart = dataRow.number;
            }
          }
          // worksheet.mergeCells(`D${dataRow.number}:D${dataRow.number + 1}`);

          // if(rootIndex === 0) {
          //   if(subRow < dataRow.number) {
          //     worksheet.mergeCells(`D${subRow}:D${dataRow.number - 1}`);
          //     subRow = dataRow.number;
          //   }
          // }

          if (lastStudentId !== studentId) {
            if (lastUnmergedRow < dataRow.number) {
              worksheet.mergeCells(`A${lastUnmergedRow}:A${dataRow.number - 1}`);
              worksheet.mergeCells(`B${lastUnmergedRow}:B${dataRow.number - 1}`);
              worksheet.mergeCells(`G${lastUnmergedRow}:G${dataRow.number - 1}`);
              worksheet.mergeCells(`H${lastUnmergedRow}:H${dataRow.number - 1}`);
              worksheet.mergeCells(`I${lastUnmergedRow}:I${dataRow.number - 1}`);
              worksheet.mergeCells(`J${lastUnmergedRow}:J${dataRow.number - 1}`);
              worksheet.mergeCells(`K${lastUnmergedRow}:K${dataRow.number - 1}`);
              worksheet.mergeCells(`L${lastUnmergedRow}:L${dataRow.number - 1}`);
              worksheet.mergeCells(`M${lastUnmergedRow}:M${dataRow.number - 1}`);
              lastUnmergedRow = dataRow.number;
            }
            lastStudentId = studentId;
          }
        })
      })
    });

    // console.log("studentData ", studentData)
    if (rowStart < worksheet.rowCount) {
      worksheet.mergeCells(`C${rowStart}:C${worksheet.rowCount}`);
    }
    // console.log("worksheet ", worksheet, worksheet.rowCount)

    if (lastStudentId !== null && lastUnmergedRow < worksheet.rowCount) {
      worksheet.mergeCells(`A${lastUnmergedRow}:A${worksheet.rowCount}`);
      worksheet.mergeCells(`B${lastUnmergedRow}:B${worksheet.rowCount}`);
      worksheet.mergeCells(`G${lastUnmergedRow}:G${worksheet.rowCount}`);
      worksheet.mergeCells(`H${lastUnmergedRow}:H${worksheet.rowCount}`);
      worksheet.mergeCells(`I${lastUnmergedRow}:I${worksheet.rowCount}`);
      worksheet.mergeCells(`J${lastUnmergedRow}:J${worksheet.rowCount}`);
      worksheet.mergeCells(`K${lastUnmergedRow}:K${worksheet.rowCount}`);
      worksheet.mergeCells(`L${lastUnmergedRow}:L${worksheet.rowCount}`);
      worksheet.mergeCells(`M${lastUnmergedRow}:M${worksheet.rowCount}`);
    }

    const gapRow = worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '']);
    gapRow.eachCell((cell, index) => {
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
        },
        border: {},
      };
    });
    // console.log("gapRow ", gapRow)
    const legendRow1 = worksheet.addRow(['', '', '>= 80 Blue']);
    // console.log("legendRow1 ", legendRow1)
    legendRow1.height = 30;
    legendRow1.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = '9BC2E6';
            break;

          default:
            break;
        }

        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });

    const legendRow2 = worksheet.addRow(['', '', '60 - 79 Green']);
    legendRow2.height = 30;
    legendRow2.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = 'C6E0B4';
            break;

          default:
            break;
        }
        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });

    const legendRow3 = worksheet.addRow(['', '', '40 - 59 Yellow']);
    legendRow3.height = 30;
    legendRow3.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = 'FFE699';
            break;

          default:
            break;
        }
        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });

    const legendRow4 = worksheet.addRow(['', '', '<= 39 Red']);
    legendRow4.height = 30;
    legendRow4.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = 'FF6699';
            break;

          default:
            break;
        }
        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });



    const gapRow2 = worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '']);
    gapRow2.eachCell((cell, index) => {
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
        },
        border: {},
      };
    });



    const headerColor1 = {
      argb: '',
    };

    const headerCellStyle1 = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: headerColor1,
      },
      font: { color: { argb: 'FFFFFF' }, bold: true },
      border: {
        top: { style: 'thin' }, left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: { vertical: 'middle', horizontal: 'center' },
    };

    const columnNames1 = [
      'S. No',
      'Absentees',
    ];
    // console.log("danger",studentData?.length)
    worksheet.addRow(columnNames1);
    const headerRow1 = worksheet.getRow(studentData?.length + 2);
    // headerRow1.eachCell((cell) => {
    //   cell.style = headerCellStyle1;
    // });

    absenteeData?.forEach((student, index) => {
      const studentId = index + 1;
      const studentName = student?.name;

      const rowData = [
        studentId,
        studentName
      ];

      let backgroundColor = 'FFFFFF';
      const dataRow = worksheet.addRow(rowData);

      dataRow.eachCell((cell) => {
        cell.style = {
          ...bodyCellStyle,
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true, // Wrap text in cells
        };
      });

    });

    worksheet.columns.forEach((column, index) => {
      if (index === 0) {
        column.width = 5; // S. No column width
      } else {
        column.width = 25; // Other columns width
      }
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.xlsx';
      a.click();
    });
    // Save the workbook or do further processing here.
  };

  useEffect(() => {
    if (downloadReport) {
      downloadExcel();
    }
    setDownloadReport(false);
  }, [downloadReport]);

  const redColor = "#ff6698";
  const yellowColor = "#fde9a0";
  const greenColor = "#c3e1b7";
  const blueColor = "#9cc0e4";

  const convertToInt = stringVal => {
    const percentageValue = stringVal;
    return percentageValue <= 39
      ? redColor
      : percentageValue <= 59
        ? yellowColor
        : percentageValue <= 79
          ? greenColor
          : blueColor
  }
  const onSort = async (field) => {
    //console.log("field", field, studentData)
    setSortOrder(!sortOrder)
    var VstudentData = studentData;
    if (field === "avgTimePerQuestion") {
      field = "avgTimePerQuestionNew"
    } else if (field === "attemptOn") {
      field = "attemptOnNew"
      VstudentData?.forEach(student => {
        student.attemptOnNew = new Date(student?.attemptOn)
      })
    } else if (field == "total") {
      field = "totalNew"
    }
    var sorted = await commonsort(VstudentData || [], field, !sortOrder);
    setStudentData(sorted)
    // console.log("setStudentData( 3", sorted)
  }
  const simplifiedTime = (time) => {
    const parts = time?.split(' ');

    let hours = null;
    let minutes = null;
    let seconds = null;

    if (parts?.length) {
      for (const part of parts) {
        if (part.endsWith('Sec')) {
          seconds = part;
        } else if (part.endsWith('Mins') && part !== '00Mins') {
          minutes = part;
        } else if (part.endsWith('Hrs') && part !== '00Hrs') {
          hours = part;
        }
      }
    }
    return `${hours ? hours : ''} ${minutes ? minutes : ''} ${seconds}`
  }

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        padding: "10px",
        overflow: "hidden",
        boxShadow: "0px 0px 0px 0px",
      }}
    >
      {(loading && !CreateAlert) &&
        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          FETCHING...
        </div>
      }

      {(CreateAlert) &&
        // !loading &&
        //   (studentData === null ||
        //     studentData?.length === 0) &&

        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          {CreateAlertMessage}
        </div>

        // <Alert
        //   action={
        //     <IconButton
        //       color="inherit"
        //       size="small"
        //       onClick={() => {
        //         // setloading(false);
        //         setCreateAlert(false)
        //       }}
        //     >
        //       <CancelOutlinedIcon />
        //     </IconButton>
        //   }
        //   icon={false}
        //   sx={{
        //     color: "#FFFEFA",
        //     fontWeight: 500,
        //     fontSize: "0.93vw",
        //     marginRight: "10%",
        //     // background: "#bddabca1",
        //     background: "red",
        //   }}
        // >
        //   {CreateAlertMessage}
        // </Alert>
        // <div
        //   style={{
        //     position: 'relative',
        //     fontSize: '18px',
        //     fontWeight: 500,
        //     color: '#9b9fa7',
        //     opacity: 0.8,
        //   }}
        // >
        // {/* NO DATA AVAILABLE */}

        // </div>
      }
      {
        !loading &&
        studentData !== null &&
        studentData?.length > 0 &&
        <>
          <TableContainer sx={{ maxHeight: 600, overflowX: 'hidden', overflowY: 'auto', scrollbarWidth: 'thin' }}>
            <Table
              id="report-table"
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead sx={{ overflowY: "auto", minWidth: "1152px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12
                    }}
                  >
                    S. No
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: 300,
                      fontFamily: 'Poppins, sans-serif',
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontSize: 12
                    }}
                  >
                    Student
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={sortData} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      padding: 0,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          padding: 10,
                          borderRight: '2px solid #d0cdcd',
                          height: '61px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 150,
                          fontWeight: 300,
                          fontSize: 12
                        }}
                      >
                        Concept
                      </div>
                      <div
                        style={{
                          padding: 10,
                          borderRight: '2px solid #d0cdcd',
                          height: '61px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 150,
                          fontWeight: 300,
                          fontSize: 12
                        }}
                      >
                        Sub Concept
                      </div>
                      <div
                        style={{
                          padding: 10,
                          borderRight: '2px solid #d0cdcd',
                          height: '61px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 150,
                          fontWeight: 300,
                          fontSize: 12
                        }}
                      >
                        Root Concept
                      </div>
                      <div
                        style={{
                          padding: 10,
                          margin: 'auto',
                          fontWeight: 300,
                          fontSize: 12
                        }}
                      >
                        Marks
                        {/* <img src={sortIcon} alt="Sort"
                          style={{
                            verticalAlign: "middle",
                            marginLeft: "5px",
                            paddingLeft: "3px",
                            marginBottom: "2px",
                          }}
                          className="cursor-pointer"
                          width="14"
                          height="15"
                          onClick={() => {
                            onSort("total")
                          }}

                        /> */}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12
                    }}
                  >
                    Percentage
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={() => { onSort("percentage") }} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12,
                      textAlign: 'left'
                    }}
                  >
                    Time Taken(Min)
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={() => { onSort("averageTimeTaken") }} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12,
                      textAlign: 'left'
                    }}
                  >
                    Avg. time per Question
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={() => { onSort("avgTimePerQuestion") }} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12,
                      textAlign: 'left',
                      width: 100
                    }}
                  >
                    Attempted on
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={() => { onSort("attemptOn") }} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12,
                      textAlign: 'left'
                    }}
                  >
                    PQ 1 Attempt
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={() => { onSort("pqAttempt1") }} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12,
                      textAlign: 'left'
                    }}
                  >
                    PQ 2 Attempt
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={() => { onSort("pqAttempt2") }} />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 12,
                      textAlign: 'left'
                    }}
                  >
                    Highest Score (PQ)
                    <img src={sortIcon} alt="Sort"
                      style={{
                        position: "absolute",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      onClick={() => { onSort("highestScore") }} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {studentData?.map((item, id) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        color: "black",
                      }}
                      key={id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                          textAlign: 'center'
                        }}
                      >
                        {id + 1}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontWeight: "900",
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        align={"top"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          textAlign: 'center',
                          padding: 0
                        }}
                      >
                        {item?.concepts?.map((sub, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                minHeight: 50
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderBottom: item?.concepts?.length - 1 === index
                                    ? 0 : '1px solid #d0cdcd',
                                  padding: '0 5px',
                                  width: 150,
                                  borderRight: '2px solid #d0cdcd',
                                }}
                                key={sub?.id}
                              >
                                {sub?.name}
                              </div>
                              <div>
                                {sub?.subConcept?.map((subItem, ind) =>
                                  <div
                                    key={ind}
                                    style={{ display: 'flex', minHeight: 50 }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderBottom: item?.concepts?.length - 1 === index && sub?.subConcept?.length - 1 === ind
                                          ? 0 : '1px solid #d0cdcd',
                                        padding: '0 5px',
                                        width: 150,
                                        borderRight: '2px solid #d0cdcd'
                                      }}
                                      key={`${sub?.id}${ind}`}
                                    >
                                      {subItem?.subConceptName}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderBottom: item?.concepts?.length - 1 === index && sub?.subConcept?.length - 1 === ind
                                          ? 0 : '1px solid #d0cdcd',
                                        width: 150,
                                        borderRight: '2px solid #d0cdcd'
                                      }}
                                    >
                                      {subItem?.rootConcept?.length > 0 ?
                                        <>
                                          {subItem?.rootConcept?.map((root, rootInd) => (
                                            <div
                                              key={`${sub?.id}${rootInd}`}
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: '0 5px',
                                                width: 150,
                                                minHeight: 60,
                                                borderBottom: `${subItem?.rootConcept?.length - 1 === rootInd ? 0 : '1px solid #d0cdcd'}`,
                                              }}
                                            >
                                              {root}
                                            </div>
                                          )
                                          )}
                                          {subItem?.marksArr?.subMark?.length > 0 &&
                                            <div style={{ minHeight: 60, borderTop: '1px solid #d0cdcd', width: '100%' }}></div>
                                          }
                                        </>
                                        : <>-</>
                                      }
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderBottom: item?.concepts?.length - 1 === index && sub?.subConcept?.length - 1 === ind
                                          ? 0 : '1px solid #d0cdcd',
                                        width: 100,
                                      }}
                                    >
                                      {subItem?.marksArr?.rootMark?.length > 0 &&
                                        subItem?.marksArr?.rootMark?.map((mark, i) =>
                                          <div
                                            key={`${sub?.id}${i}`}
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: 10,
                                              minHeight: 60,
                                              borderBottom: `${subItem?.marksArr?.rootMark?.length - 1 === i ? 0 : '1px solid #d0cdcd'}`,
                                            }}
                                          >
                                            {mark}
                                          </div>
                                        )
                                      }
                                      {subItem?.marksArr?.subMark !== '' &&
                                        <div
                                          style={{
                                            borderTop: '1px solid #d0cdcd',
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: 60,
                                            width: '100%'
                                          }}
                                        >
                                          {subItem?.marksArr?.subMark}
                                        </div>
                                      }
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })
                        }
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontWeight: "900",
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {
                          //console.log("item?.percentage",item?.percentage)
                        }
                        {/* {`${item?.percentage}`}% */}
                        {/* {item?.percentage === null ? "0%" :item?.percentage == '-1'?"-": `${item?.percentage}%`} */}
                         {item?.percentage === null ? "-" : `${item?.percentage}%`}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {item?.timeTaken ? simplifiedTime(item?.timeTaken) : '-'}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {item?.timePerQuestion ? simplifiedTime(item?.timePerQuestion) : '-'}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                          padding: 0
                        }}
                      >
                        {item?.attemptOn == null ? "-" : moment(item?.attemptOn).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {/* {item?.pq1} */}
                        {item?.pq1 == null ? "-" : item?.pq1}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {/* {item?.pq2} */}
                        {item?.pq2 == null ? "-" : item?.pq2}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontWeight: "900",
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {((item?.pq1 === 0 && item?.pq2 === 0) || item?.highestScore == null)
                          ? '-'
                          : `${item?.highestScore}%`
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1rem",
              rowGap: "1rem",
              padding: "10px",
            }}
          >

            <div
              style={{
                background: "rgba(130,233,245,0.81)",
                padding: "8px",
              }}
            />
            <div>{'>='} 80 Blue</div>
            <div
              style={{
                background: "rgba(208,233,196,0.81)",
                padding: "8px",
              }}
            />
            <div>60 - 79 Green</div>
            <div
              style={{
                background: "rgba(241,241,155,0.81)",
                padding: "8px",
              }}
            />
            <div>40 - 59 Yellow</div>
            <div
              style={{
                background: "rgba(248,179,179,0.81)",
                padding: "8px",
              }}
            />
            <div>{'<='} 39 Red</div>
          </div>

        </>



      }
    </Paper>
  );
};
export default StudentWisePerformance;