import React, { useState, memo } from "react";

//Css Pages
import "../../css/Student/Student_Enrich_Quiz_MSQ.css";

//Mui
import Checkbox from "@mui/material/Checkbox";

import Student_Image_Open_Modal from "./Student_Image_Open_Modal";
import { useEffect } from "react";
import checkboxPlain from "../../../../src/img/checkbox-plain.svg";
import checkboxGreen from "../../../../src/img/checkbox-green-tick.svg";
import checkboxCross from "../../../../src/img/checkbox-cross.svg";
import { Grid } from "@mui/material";
import { rightClickEnable } from "../../../../src/Serviceworker"
function Student_Enrich_Quiz_MSQ({
  questions,
  currentQuestion,
  setAttendMsq,
  attendMsq,
  setAttendedMsq,
  attendedMsq,
  resumeBtnClicked,
  from,
  subItem,
  Vvalue,
  setCommonAttendedData,
  commonAttendedData,
  list,
  ispreview,
  Previewvalue,
  tempCaseStudy,
  settempCaseStudy,
  showExplanation
}) {
  // console.log("msq11111", questions, currentQuestion, attendMsq, attendedMsq, "resumeBtnClicked", resumeBtnClicked, from, subItem, Vvalue, ispreview, "-------------------------", list, Previewvalue);
  const [modalOpen, setModalOpen] = useState(false);
  const [vchecked, setVChecked] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [hoverColor, setHoverColor] = useState('orange');
  const [checkedArray, setCheckedArray] = useState([])
  const [isAuthenticatedShift, setIsAuthenticatedShift] = useState(true);
  const currentQuestionData = (!ispreview || !resumeBtnClicked) ? questions[currentQuestion] : list.questionAnswerKeys[currentQuestion];
  // console.log("currentQuestionData", currentQuestionData)
  const listData = (ispreview && resumeBtnClicked) ? list?.questionAnswerKeys[currentQuestion] : {};
  useEffect(() => {
    //console.log("41111111111111111111111111", ispreview, resumeBtnClicked)
    if (!ispreview) {
      // console.log("currentQuestionData", currentQuestionData)
      if (currentQuestionData && Object.keys(currentQuestionData).length !== 0) {
        if (attendedMsq[currentQuestionData.id]) {
          attendedMsq[currentQuestionData.id]?.forEach((ques) => {
            attendMsq.push(ques);
            attendMsq.sort((a, b) => a.option - b.option)
          })
        } else {
          //console.log("else executing")
        }



        if (resumeBtnClicked) {
          // setAttendedMsq([])
          if (attendedMsq[currentQuestionData.questionId]) {
            attendedMsq[currentQuestionData.questionId]?.forEach((ques) => {
              attendMsq.push(ques);
              attendMsq.sort((a, b) => a.option - b.option)
            })
          }
          if (!attendedMsq[currentQuestionData.questionId]) {
            currentQuestionData.mcsqAnswerByStudent?.forEach((itm) => {
              attendMsq.push(itm);
              setAttendedMsq((prevAttendedQuestions) => {
                const updatedQuestions = { ...prevAttendedQuestions };
                if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
                  updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
                }
                const foundIndex = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]?.findIndex(
                  (item) => item.order === itm.order
                );
                if (foundIndex === -1) {
                  updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].push({
                    "option": itm.option,
                    "order": itm.order,
                    "answerKey": itm.answerKey,
                  });
                } else {
                  updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][foundIndex] = {
                    "option": itm.option,
                    "order": itm.order,
                    "answerKey": itm.answerKey,
                  };
                }
                return updatedQuestions;
              });
            })
          }


        }
      }
    }
  }, [currentQuestion]);
  useEffect(() => {
    // console.log("12-4-24")
    //console.log("useeffectt valled.")
    const handleContextMenu = (e) => {
      // if (!authContext.isAuthenticated) { this is to restrict right click based on loggedin info
      // alert("context menu msq")
      e.preventDefault();
      //console.log("handleContextMenu called")
    };
    if (rightClickEnable && isAuthenticatedShift) {
      document.addEventListener('contextmenu', handleContextMenu);

      document.addEventListener('keydown', function (event) {
        //console.log(event);
        // alert(event.getModifierState)
        const isModifierKey = event.getModifierState('Alt') ||
          event.getModifierState('Tab') ||
          event.getModifierState('Shift');

        if (event.key === 'F12' || isModifierKey) {
          //console.log("Modifier key pressed");
          event.preventDefault();
        } else if (event.key === "Enter" || event.key === " ") {
          //console.log(`${event.key} key pressed`);

          // Check if no modifier keys are pressed
          const noModifiers = !event.getModifierState('Alt') &&
            !event.getModifierState('Control') &&
            !event.getModifierState('Meta') &&
            !event.getModifierState('Shift');

          if (noModifiers) {
            //console.log("No modifier keys pressed");
            // Prevent the default behavior only for <button> elements
            if (event.target.tagName === 'BUTTON') {
              event.preventDefault();
              //console.log("Key event prevented for <button>");
            }
          }
        }
      });

      const handleBeforeUnload = (event) => {
        //console.log("close tab", event.type); // For reload and close tab
        const isRefresh = !event.currentTarget.performance.navigation.type;
        //console.log("isRefresh", isRefresh);

        if (event.type === 'beforeunload') {
          // sessionStorage.removeItem("itemload");
          sessionStorage.removeItem("viewQuizData");
          sessionStorage.removeItem("practiceQuizAnswer");
          sessionStorage.removeItem("drag_drop_array");
          sessionStorage.removeItem("drag_drop_array_final");
          sessionStorage.removeItem("shuffledQuiz");
          sessionStorage.removeItem("alltheQuizAnswer");
          sessionStorage.removeItem("exid");
          sessionStorage.removeItem("exIdTwo");
          sessionStorage.removeItem("drag_drop_array_final")
          sessionStorage.removeItem("case_help");
          const message = "Are you sure you want to leave? Your progress may be lost.";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        } else {
          //console.log("else close tab");
          const message = "Are you sure you want to close the tab?";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        }
      }
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        // console.log("12-4-24")
        document.removeEventListener('contextmenu', handleContextMenu);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [])
  const onHandleClick = (option, order, answerKey, isChecked, varray, no) => {
    // console.log(option, order, answerKey, isChecked, varray, no)
    // alert("click called")
    // console.log("varrayvarrayvarrayvarray", isChecked, attendMsq, attendedMsq, varray, "numberrrrrrrr", no, questions[currentQuestion], currentQuestionData, attendedMsq[currentQuestionData?.id || currentQuestionData?.questionId])
    var newAttendMsq = [];
    // isChecked = false
    if (isChecked) { ////uncheck
      // remove the unchecked value from the array
      newAttendMsq = attendMsq.filter(
        (item) => item.answerKey !== answerKey
      );
      var VVnewAttendMsq = [];
      for (let i = 0; i < attendMsq.length; i++) {
        //console.log("newAttendMsq[i]", attendMsq[i].answerKey == answerKey, attendMsq[i].answerKey, answerKey, attendMsq[i].order == order, attendMsq[i].order, order)
        if (attendMsq[i].answerKey == answerKey || attendMsq[i].order == order) {
        } else {
          VVnewAttendMsq.push(attendMsq[i])
        }
      };
      newAttendMsq = newAttendMsq.filter((obj, index, self) => {
        const uniqueKey = obj.answerKey;
        return index === self.findIndex((o) => o.answerKey === uniqueKey);
      });
      //console.log("newAttendMsq", VVnewAttendMsq, newAttendMsq, attendedMsq)
      setCheckedArray(VVnewAttendMsq)

    } else {////check
      // console.log("trying to check")
      // add the checked value to the array
      //console.log(currentQuestionData, questions, currentQuestion)
      var STUDENT_ANSWERED = attendedMsq[currentQuestionData?.id || currentQuestionData?.questionId] || [];
      // console.log(currentQuestionData, "454555555", STUDENT_ANSWERED)
      var otheroption = currentQuestionData?.options && currentQuestionData?.options[0]?.filter(e => e.correctAnswer == true)?.length;
      if (typeof otheroption === 'undefined') {
        otheroption = currentQuestionData?.multiChoiceOrSelect && currentQuestionData?.multiChoiceOrSelect?.filter(e => e.correctAnswer == true)?.length;
      }
      if (currentQuestionData?.questionTypeCode == "CASE") {
        if (Array.isArray(currentQuestionData?.subQuestions)) {
          const msqSubQuestion = currentQuestionData.subQuestions.find(subQ => subQ.questionTypeCode === "MSQ") || [];
          // console.log("msqSubQuestion", msqSubQuestion)
          var msqOptions = msqSubQuestion.options[0]
          // console.log("msqOptions", msqOptions)
          msqOptions = msqOptions.filter(e => e.correctAnswer == true)
          // console.log(msqOptions, "msqOptions", msqOptions.length);
          otheroption = msqOptions.length || 2
        }
      }
      // console.log("currentQuestionData", currentQuestionData, otheroption)

      // console.log(STUDENT_ANSWERED.length, "otheroption", otheroption)
      var total_options_length = otheroption;
      // console.log("total_options_length", total_options_length)
      if (STUDENT_ANSWERED.length == total_options_length) {
        return
      }
      newAttendMsq = [
        ...attendMsq,
        {
          "option": option,
          "order": order,
          "answerKey": answerKey,
        },
      ];
      // } else {
      //   newAttendMsq = [
      //     ...attendMsq
      //   ];
      // }
    }
    newAttendMsq = newAttendMsq.filter((obj, index, self) => {
      const uniqueKey = obj.answerKey;
      return index === self.findIndex((o) => o.answerKey === uniqueKey);
    });

    newAttendMsq.sort((a, b) => a.order - b.order);
    // console.log(newAttendMsq, "newAttendMsq")
    setAttendMsq(newAttendMsq);
    if (from == "CASE") {
      var Data = JSON.parse(sessionStorage.getItem("casestudy")) || []
      Data = Data.filter(item => !item[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]);
      var v = { [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]: newAttendMsq, type: "MSQ", currentQuestion };
      Data.push(v)
      sessionStorage.setItem("casestudy", JSON.stringify(Data));
      // commonAttendedData.push(Data[2])
      commonAttendedData.push(v)
      setCommonAttendedData(commonAttendedData)

      var vtempCaseStudy = tempCaseStudy || [];
      const existingIndex = vtempCaseStudy.findIndex(item => item.order === order);
      // console.log("existingIndex", existingIndex)
      if (existingIndex !== -1) {
        vtempCaseStudy.splice(existingIndex, 1);
      }
      vtempCaseStudy.push({
        [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]: [
          {
            "option": option,
            "order": order,
            "answerKey": answerKey,
          }
        ],
        "type": "MSQ",
        "currentQuestion": currentQuestion
      });
      // console.log("vtempCaseStudy", vtempCaseStudy)
      settempCaseStudy([...vtempCaseStudy]);

    }
    if (isChecked) {
      // console.log("currentQuestionData", currentQuestionData)
      setAttendedMsq((prevAttendedQuestions) => {
        const updatedQuestions = { ...prevAttendedQuestions }
        // console.log("prevupdatedQuestions", updatedQuestions, newAttendMsq, attendMsq, attendedMsq, attendedMsq)
        var tempattendedMsq = attendedMsq[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id];
        // console.log("tempattendedMsq",tempattendedMsq)
        const newFilter = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]?.findIndex(itm => itm.order === order);
        // console.log("newFilter", newFilter, resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id);
        // console.log(updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id])
        // updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]?.splice(newFilter, 1);
        var v = tempattendedMsq?.filter(obj => obj.order !== order);
        updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = v
        // console.log("updatedQuestions", updatedQuestions)
        return updatedQuestions
      })
    }
    else {
      setAttendedMsq((prevAttendedQuestions) => {
        const updatedQuestions = { ...prevAttendedQuestions };
        if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
        }
        const foundIndex = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]?.findIndex(
          (item) => item.order === order
        );
        if (foundIndex === -1) {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].push({
            "option": option,
            "order": order,
            "answerKey": answerKey,
          });
        } else {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][foundIndex] = {
            "option": option,
            "order": order,
            "answerKey": answerKey,
          };
        }
        // console.log("updatedQuestions", updatedQuestions)
        return updatedQuestions;
      });
    }
  }

  function mapAndCheckArrays(originalArray, checkArray) {
    return originalArray.map(item => {
      const found = checkArray.find(checkItem => checkItem.order === item.order);
      if (found) {
        return {
          ...item,
          label: "Your answer"
        };
      } else {
        return {
          ...item,
          label: item.correctAnswer ? "Correct Answer" : ""
        };
      }
    });
  }
  useEffect(() => {
    if (!ispreview && resumeBtnClicked) {
      // console.log("useeffect calling", attendedMsq)
      var isalreadyPreviewdArray = JSON.parse(sessionStorage.getItem("MSQ_det")) || [];
      // console.log(isalreadyPreviewdArray?.preview, "sessionwork")
      var isalreadypreviewed = isalreadyPreviewdArray.filter(v => v.questionId == questions[currentQuestion].questionId);
      // console.log("isalreadypreviewed", isalreadypreviewed)
      if (Object.keys(attendedMsq).length > 0 && (isalreadypreviewed.length == 0 || !isalreadypreviewed[0]?.preview)) {
        // console.log("currentQuestionData=====================", currentQuestionData, attendedMsq, attendedMsq[currentQuestionData.questionId])
        currentQuestionData?.multiChoiceOrSelect?.forEach((value, ind) => {
          const dynamicId = `az-answer-msq-${ind}`;
          var isChecked = Object.keys(attendedMsq).length > 0 && attendedMsq[currentQuestionData.questionId]
            ? attendedMsq[currentQuestionData.questionId].some((item) => item.option === value.option)
            : false;

          // console.log(isChecked, "clue---------------1111", value)
          if (isChecked) {
            const checkbox = document.getElementById(dynamicId);
            // console.log("checkboxcheckboxcheckboxcheckbox", checkbox)
            if (checkbox) {
              // console.log("clicking on msq", checkbox.checked)
              // checkbox.click();
              onHandleClick(value.option, value.order, value.answerKey, false, currentQuestionData?.multiChoiceOrSelect, 33)
              // onHandleClick()
              // onHandleClick = (option, order, answerKey, isChecked, varray, no) => {

              // checkbox.click();
            }
          }
          let MSQ_det = JSON.parse(sessionStorage.getItem("MSQ_det")) || [];
          const questionId = questions[currentQuestion].questionId;
          const isQuestionIdPresent = MSQ_det.some(item => item.questionId === questionId);
          // console.log(questions[currentQuestion], currentQuestionData)
          if (!isQuestionIdPresent) {
            // alert("MSQ" + questionId + "   ----   " + currentQuestion)
            MSQ_det.push({ questionId: questionId, preview: true });
            sessionStorage.setItem("MSQ_det", JSON.stringify(MSQ_det));
          } else {
            // console.log("QuestionId already present in MSQ_det array");
          }
        });
      }
    }
  }, [currentQuestion, attendedMsq]);



  return (
    <>
      {
        ispreview ?

          (!resumeBtnClicked) ? <>
            <Grid
              item
              xs={12}
              mg={12}
              lg={12}
              style={{
                display: "flex",
              }}
            >

              <div
                style={{
                  width: "100%",
                  height: "auto",
                  border: "1px solid #dbdee6",
                  fontSize: "13px",
                  color: "#1b1b1b",
                  opacity: "0.9",
                  marginTop: "5px",
                  lineHeight: "1",
                  padding: "10px",
                  borderRadius: "5px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  id="fullWidth"
                  sx={{
                    color: "#001AF3",
                    position: "absolute",
                    top: "50%", // Adjust the top position to center the checkbox
                    transform: "translateY(-50%)", // Center the checkbox vertically
                    left: "5px",
                    "&.Mui-checked": {
                      color: "rgba(0, 0, 0, 0.26)",
                    },
                  }}
                  disabled={true}
                // {/* commented for teach not see */}
                // disabled={!Previewvalue?.correctAnswer}
                // checked={Previewvalue?.correctAnswer}
                />

                <div
                  style={{
                    marginLeft: "40px", // Adjust the spacing between checkbox and text
                    overflow: "hidden", // Hide overflow if the text is too long
                  }}
                  dangerouslySetInnerHTML={{ __html: Previewvalue?.htmlTag }}
                >
                  
                  {/* {Previewvalue.answerKey} */}
                </div>
              </div>
            </Grid>
          </> : <>

            <div>
              {/*---------------------------| Quiz Question & Answer Division Starts Here |---------------------------*/}
              <div className="cqk__Quiz_Main_div_MSQ">
                {/*---------------------------| Quiz Question Division |---------------------------*/}
                <div className="cqk__Qstn_and_Ans_MSQ">
                  <div className="cqk__Qstn_div_MSQ">
                    <h3 className="cqk__Msq_Head Paragraph_title" id="az-qstn-msq">Question:-</h3>
                    <p
                      className="Paragraph"
                      dangerouslySetInnerHTML={{
                        __html: listData?.question,
                      }}
                    ></p>
                    {
                      //console.log("listData", listData)
                    }
                    {
                      showExplanation == true &&
                      <div>
                        {listData?.explanation && <h3 id="az-expl-msq" className="Paragraph_title" style={{marginTop:"10px"}}>Explanation</h3>}
                        <p
                          className="Paragraph "
                          dangerouslySetInnerHTML={{
                            __html: listData?.explanation,
                          }}
                        ></p>
                      </div>
                    }
                    {listData?.imageUrl && (
                            <div style={{ marginTop: '20px' }}>
                              <img
                                className="MSQ_Qstn_Img"
                                src={listData?.imageUrl}
                                alt="Image"
                                onClick={() => {
                                  setSelectedImageUrl(listData?.imageUrl);
                                  setModalOpen(true);
                                }}
                              />
                            </div>
                          )}
                    {/* <div>
              <img src="" className="cqk__MSQ_img" alt="Image" />
            </div> */}


                  </div>
                  {/*---------------------------| Quiz Answer Division |---------------------------*/}

                  <div className="cqk__Ans_div_MSQ">
                    <div>

                    </div>
                    <div>
                      {/*---------------------------| Ans |---------------------------*/}
                      {
                        //console.log(listData?.multiChoiceOrSelect, "============", listData?.mcsqAnswerByStudent)
                      }

                      {listData?.multiChoiceOrSelect.map((itm, index) => {
                        const name = listData?.mcsqAnswerByStudent
                          ? listData?.mcsqAnswerByStudent.some(
                            (item) => item.option === itm.option
                          )
                          : false;
                        const dynamicId = `az-answer-preview-msq-${index}`;
                        //console.log("name", name)
                        var vv = name ? "Your Answer" : itm.correctAnswer === true ? "Correct Answer" : "";
                        return (
                          <>

                            {/* <h6 className="cqk__Head_MSQ">Answer</h6> */}

                            <div
                              className="cqk__Ans1_MSQ"
                              id={dynamicId}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                background:
                                  itm.correctAnswer === true
                                    ? "#E7FFE8" //green
                                    : name === false
                                      ? "white"
                                      : "#FCD3D3", //red
                              }}
                            >
                              <span>
                                {itm.correctAnswer === true ? (
                                  <img
                                    src={checkboxGreen}
                                    width="20px"
                                    height="20px"
                                  />
                                ) : name === false ? (
                                  <img
                                    src={checkboxPlain}
                                    width="20px"
                                    height="20px"
                                  />
                                ) : (
                                  <img
                                    src={checkboxCross}
                                    width="20px"
                                    height="20px"
                                  />
                                )}
                              </span>
                              {itm.htmlTag === null && itm.answerKey}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: itm.htmlTag,
                                }}
                              ></p>
                            </div>
                            <div
                              style={{
                                color: "#1B1B1B",
                                fontSize: "15px",
                                fontWeight: "bold",
                                margin: "5px 0 0 25px",
                              }}
                            >
                              {vv}
                            </div>
                            {/* {name === true && (
                              <div
                                style={{
                                  color: "#1B1B1B",
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  margin: "5px 0 0 25px",
                                }}
                              >
                                Your Answer
                              </div>
                            )} */}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {/*---------------------------| Quiz Question & Answer Division Ends Here |---------------------------*/}
            </div>
          </>
          :
          <>{
            //console.log("from", from, "list", list)
          }
            {
              from == "CASE" ? (
                <>{
                  // console.log("============================", Vvalue, subItem, "currentQuestionData", currentQuestionData)
                }
                  <div>
                    {!resumeBtnClicked ? [Vvalue]?.map((value, ind) => {
                      const isChecked = Object.keys(attendedMsq).length > 0 && attendedMsq[currentQuestionData.id] ? attendedMsq[currentQuestionData.id].some(
                        (item) => item.option === value.option
                      )
                        :
                        false;
                      const dynamicId = `az-answer-msq-check-${ind}`;
                      return (
                        <div className="Ans1_MSQ" key={ind} style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            id={dynamicId}
                            size="small"
                            sx={{
                              color: isChecked ? 'orange' : 'grey', // Color when checked
                              '&:hover': {
                                color: 'orange', // Color on hover
                              },
                              '&.Mui-checked': {
                                color: 'orange', // Override default checked color
                              },
                            }}
                            checked={isChecked}
                            onClick={() => onHandleClick(value.option, value.order, value.answerKey.replace(/\s/g, ""), isChecked, [Vvalue], 3)}
                          />
                          {value.htmlTag === null && value.answerKey}
                          <p dangerouslySetInnerHTML={{ __html: value.htmlTag }}></p>
                        </div>
                      );
                    })
                      :
                      currentQuestionData?.multiChoiceOrSelect?.map((value, ind) => {
                        const isChecked = Object.keys(attendedMsq).length > 0 && attendedMsq[currentQuestionData.questionId] ? attendedMsq[currentQuestionData.questionId].some(
                          (item) => item.option === value.option
                        )
                          :
                          false;
                        const dynamicId = `az-answer-msq-${ind}`;
                        return (
                          <div className="Ans1_MSQ" key={ind}>
                            <Checkbox
                              size="small"
                              id={dynamicId}
                              // sx={{
                              //   color: "#001AF3",
                              //   "&.Mui-checked": {
                              //     color: "#001AF3",
                              //   },
                              // }}
                              sx={{
                                color: isChecked ? 'orange' : 'grey', // Color when checked
                                '&:hover': {
                                  color: 'orange', // Color on hover
                                },
                                '&.Mui-checked': {
                                  color: 'orange', // Override default checked color
                                },
                              }}
                              checked={
                                isChecked
                              }
                              onClick={() => onHandleClick(value.option, value.order, value.answerKey.replace(/\s/g, ""), isChecked, currentQuestionData?.multiChoiceOrSelect, 4)}
                            />
                            {value.htmlTag === null && value.answerKey}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: value.htmlTag,
                              }}
                            ></p>
                          </div>
                        );
                      })}
                  </div>
                  {/*---------------------------| Quiz Question & Answer Division Ends Here |---------------------------*/}
                  <Student_Image_Open_Modal
                    selectedImageUrl={selectedImageUrl}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  />
                </>
              ) : (
                <>
                  {/*---------------------------| Quiz Question & Answer Division Starts Here |---------------------------*/}
                  <div className="Quiz_Main_div_MSQ">
                    {/*---------------------------| Quiz Question Division |---------------------------*/}
                    <div className="Qstn_and_Ans_MSQ">
                      <div className="Qstn_div_MSQ">
                        {/* <h6 className="Msq_Head">Multi Select Question</h6> */}
                        {/* <p
                          className="Qstn_content_1_MSQ"
                          dangerouslySetInnerHTML={{
                            __html: questions[currentQuestion]?.question,
                          }}
                        ></p> */}
                        <div className="Para_div_1">
                          <h3 className="Paragraph_title" id="az-msq-qstn">Question:-</h3>
                          <p
                            className="Paragraph"
                            dangerouslySetInnerHTML={{
                              __html: questions[currentQuestion]?.question,
                            }}
                          ></p>
                        </div>
                        <div className="Para_div_1">
                          {questions && questions[currentQuestion]?.explanation && (
                            <>
                              <h3 className="Paragraph_title" id="az-msq-expln">Explanation :-</h3>
                              <p
                                className="Paragraph"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    questions &&
                                    questions[currentQuestion]?.explanation,
                                }}
                              ></p>
                            </>
                          )}
                        </div>
                        {questions && questions[currentQuestion]?.imageUrl && (
                          <div>
                            <img
                              src={questions[currentQuestion]?.imageUrl}
                              className="MSQ_img"
                              alt="Image"
                              onClick={() => {
                                setSelectedImageUrl(questions[currentQuestion]?.imageUrl);
                                setModalOpen(true);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {/*---------------------------| Quiz Answer Division |---------------------------*/}

                      <div className="Ans_div_MSQ">
                        <div>
                          <h6 className="Head_MSQ">Answer</h6>
                        </div>
                        <div>
                          {/*---------------------------| Ans |---------------------------*/}

                          {!resumeBtnClicked ? questions[currentQuestion]?.options[0]?.map((value, ind) => {
                            // console.log("main place", attendMsq, "-------", attendedMsq, checkedArray)
                            const isChecked = Object.keys(attendedMsq).length > 0 && attendedMsq[currentQuestionData.id] ? attendedMsq[currentQuestionData.id].some(
                              (item) => item.option === value.option
                            )
                              :
                              false;

                            //console.log("setVCheckedsetVChecked", isChecked)
                            const dynamicId = `az-answer-preview-msq-${ind}`;
                            return (
                              <div className="Ans1_MSQ" key={ind} style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                  id={dynamicId}
                                  size="small"
                                  sx={{
                                    color: isChecked ? 'orange' : 'grey', // Color when checked
                                    '&:hover': {
                                      color: 'orange', // Color on hover
                                    },
                                    '&.Mui-checked': {
                                      color: 'orange', // Override default checked color
                                    },
                                  }}
                                  // sx={{
                                  //   color: "#001AF3",
                                  //   "&.Mui-checked": {
                                  //     color: "#001AF3",
                                  //   },
                                  // }}
                                  checked={isChecked}
                                  onClick={() => onHandleClick(value.option, value.order, value.answerKey.replace(/\s/g, ""), isChecked, questions[currentQuestion]?.options[0], 1)}
                                />
                                {value.htmlTag === null && value.answerKey}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: value.htmlTag,
                                  }}
                                ></p>
                              </div>
                            );
                          })
                            :
                            currentQuestionData?.multiChoiceOrSelect?.map((value, ind) => {
                              const dynamicId = `az-answer-msq-${ind}`;
                              // console.log("attendedMsq", attendedMsq)
                              var isChecked = Object.keys(attendedMsq).length > 0 && attendedMsq[currentQuestionData.questionId] ? attendedMsq[currentQuestionData.questionId].some(
                                (item) => item.option === value.option
                              ) : false;

                              // console.log("isChecked734", isChecked, attendedMsq, currentQuestionData.questionId)
                              // console.log(isChecked, "clue---------------22222")
                              return (
                                <div className="Ans1_MSQ" key={ind}>
                                  <Checkbox
                                    size="small"
                                    id={dynamicId}
                                    sx={{
                                      color: isChecked ? 'orange' : 'grey', // Color when checked
                                      '&:hover': {
                                        color: 'orange', // Color on hover
                                      },
                                      '&.Mui-checked': {
                                        color: 'orange', // Override default checked color
                                      },
                                    }}
                                    // sx={{
                                    //   color: "#001AF3",
                                    //   "&.Mui-checked": {
                                    //     color: "#001AF3",
                                    //   },
                                    // }}
                                    checked={
                                      isChecked
                                    }
                                    onClick={() => onHandleClick(value.option, value.order, value.answerKey.replace(/\s/g, ""), isChecked, currentQuestionData?.multiChoiceOrSelect, 2)}
                                  />
                                  {value.htmlTag === null && value.answerKey}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: value.htmlTag,
                                    }}
                                  ></p>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*---------------------------| Quiz Question & Answer Division Ends Here |---------------------------*/}
                  <Student_Image_Open_Modal
                    selectedImageUrl={selectedImageUrl}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  /></>
              )
            }
          </>

      }</>
  );
}

export default Student_Enrich_Quiz_MSQ;
