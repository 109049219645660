import axios from "axios";

async function User_Branches_School_Id(
  schoolDetails,
  setBranchList,
  studentDetails
) {
  let token = sessionStorage.getItem("token");
  let role = localStorage.getItem("role")
  let userId = sessionStorage.getItem("userId");
  let schoolId = sessionStorage.getItem("schoolid");

  // console.log(schoolId,"sss")
  //  
  for (const input in schoolDetails) {
    if (schoolDetails[input] !== "") {
      var schoolid = schoolDetails.school;
      sessionStorage.setItem("schoolid", schoolid);
      // return;
    }
  }
  for (const input in studentDetails) {
    if (studentDetails[input] !== "") {
      var schoolid = studentDetails.studentschool;
      sessionStorage.setItem("schoolid", schoolid);
      // return;
    }
  }
  // console.log(schoolid);
  if (role==='SCHOOL_ADMIN'){
    await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/all?schoolId=${sessionStorage.getItem(
      "schoolid"
    )}&userId=${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      setBranchList(response.data.data)
    })
    .catch(err=>{
      // console.log(err)
    })
}
else{
  await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/all?schoolId=${sessionStorage.getItem(
    "schoolid"
  )}`,
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
  .then((response)=>{
    setBranchList(response.data.data)
  })
  .catch(err=>{
    // console.log(err)
  })
}}

export default User_Branches_School_Id;
