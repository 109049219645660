import React, { useState, useEffect } from "react";
import {
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import addstudentstyle from "./../css/NavbarAddStudentModal.module.css";
import Green100Button from "../../components/common/Green100Button";
import axios from "axios";
import { Checkbox } from "@mui/material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
};
const PrincipalAccessModal = ({
  open,
  close,
  grades,
  planId,
  success,
  selectedProfile,
}) => {
  const token = sessionStorage.getItem("token");
  const [section, setSection] = useState([]);
  const [subtopic, setSubtopic] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [gradeId, setGradeId] = useState("");
  const [sectionId, setSectionId] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [subtopicId, setSubtopicId] = useState([]);
  const [grade, setGrades] = useState([])
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isBtnEnabled, setIsBtnEnabled] = useState(false);


  useEffect(() => {
    setGrades(grades);
  }, [grades])



  const handlerTeacherDetailsInputs = (gradeId) => {
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    setGradeId(gradeId);
    setSection([]);
    setSubject([]);
    setSubtopic([]);
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${gradeId}&branchId=${branchId}&schoolId=${schoolId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSection(response?.data?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan?planId=${planId}&gradeId=${gradeId}`
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${planId}&gradeId=${gradeId}`
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        // console.log(response?.data?.data, "27-6-24 required")
        // response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || [];
        // console.log(response.data.data)
        setSubject(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const handlerSubjectDetails = (subject) => {
    setSubjectId(subject);
    // console.log(subject);
    // console.log("subjects", subjects)
    const selectedSubject = subjects.find(val => val.subjectId === String(subject));
    // console.log(selectedSubject, selectedSubject?.subTopics)
    // Assuming selectedSubject has a hideSubtopics property that determines whether to show subtopics
    // console.log(selectedSubject?.hideSubtopics, selectedSubject.subjectId)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      // console.log("false")
      // alert("pac false");
    } else {
      // console.log("true")
      setShowSubTopic(true);
      // alert("pac true");
    }
    setSubtopicId([]);
    setSubtopic(selectedSubject?.subTopics);
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${subject}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        // setSubtopic(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const [isError, setError] = useState(false);
  const GiveAccessHandler = () => {
    setIsBtnEnabled(true);
    // console.log(subtopicId, selectedItems, sectionId)
    // console.log(gradeId, sectionId, subjectId)
    if (!gradeId || sectionId.length == 0 || !subjectId) {
      setError(true);
      setIsBtnEnabled(false);
      return;
    }
    setError(false);
    // console.log(subtopic, selectedItems)
    // if (subtopic.length > 0 && selectedItems.length == 0) {
    //   setError(true);
    //   return;
    // }
    const { id } = selectedProfile;

    const data = {
      gradeId: gradeId,
      sectionIds: [...sectionId],
      subjectId: subjectId,
    };
    if (selectedItems && showSubTopic) {
      data['subtopicIds'] = [...selectedItems];
    }
    axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${id}/map-subjects`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setGradeId("");
        setSectionId([]);
        setSubjectId("");
        setSubtopicId([]);
        setIsBtnEnabled(false);
        success(response?.data?.message);
      })
      .catch((error) => {
        success(error?.response?.data?.message);
        setIsBtnEnabled(false);
        setGradeId("");
        setSectionId([]);
        setSubjectId("");
        setSubtopicId([]);
      });

  };
  const onclose = () => {
    // console.log("onclosecalled")
    setSelectedItems([])
    setError(false);
    close();
    setSectionId([]);
    setSubtopicId([]);
    window.location.reload()
  };
  const handleSelectAll = () => {
    // console.log("select all called", selectAll, subtopic)
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(subtopic.map(option => option.id));
    }
    // console.log(selectedItems)
    setSelectAll(!selectAll);
  };
  const handleOptionToggle = (option) => () => {
    // console.log("option", selectedItems)
    if (selectedItems.length == 0) {
      setSelectAll(option.id)
    }
    const selectedIndex = selectedItems.indexOf(option.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, option.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelected);
    setSelectAll(newSelected.length === subtopic.length);
    // console.log(selectAll, selectedItems, "method2")
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ width: "900px", height: "350px" }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ flexDirection: "column", display: "flex" }}>
            <label style={{ color: "navy" }} className="cursorText">Add Grade and Subject</label>
            <i
              className="fa-solid fa-xmark cursorPointer"
              style={{ marginLeft: "840px", marginTop: "-27px" }}
              onClick={onclose}
            ></i>
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              marginTop: "45px",
            }}
          >
            <div
              className={addstudentstyle.input_element}
              style={{ width: "31%" }}
            >
              <select
                className={`${addstudentstyle.form_select_element} cursorPointer`}
                name="grade"
                id="Grade"
                onChange={(event) =>
                  handlerTeacherDetailsInputs(event.target.value)
                }
                style={{ padding: "23px 0px 12px 16px", cursor: 'pointer' }}
              >
                <option value="">Select</option>
                {grade && grade.length > 0 ? (
                  grade.map((gradesName, index) => {
                    return (
                      <option value={gradesName.id} className='cursorPointer' style={{ cursor: 'pointer' }}>{gradesName.grade}</option>
                    );
                  })
                ) : (
                  <option className='cursorPointer' style={{ cursor: 'pointer' }} value=""></option>
                )}
              </select>
              <label for="Grade">*Grade</label>
              {isError && !gradeId && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  Grade is Required
                </p>
              )}
            </div>

            <div
              className={addstudentstyle.input_element}
              style={{ width: "31%" }}
            >
              <select
                className={`${addstudentstyle.form_select_element} cursorPointer`}
                name="subject"
                id="subject"
                // value={studentDetails.section}
                onChange={(event) => handlerSubjectDetails(event.target.value)}
                style={{ padding: "23px 0px 12px 16px", cursor: 'pointer' }}
              >
                <option value="">Select</option>
                {subjects && subjects.length > 0 ? (
                  subjects.map((sectionName, index) => {
                    return (
                      <option value={sectionName.subjectId} className="cursorPointer" style={{ cursor: 'pointer' }}>
                        {sectionName.subject}
                      </option>
                    );
                  })
                ) : (
                  <option className='cursorPointer' style={{ cursor: 'pointer' }} value=""></option>
                )}
              </select>
              <label for="section">*Subject</label>
              {isError && !subjectId && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  Subject is Required
                </p>
              )}
            </div>
            <div
              className={addstudentstyle.input_element}
              style={{ width: "31%",  }}
            >
              {/* {
                showSubTopic &&

                <FormControl>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    style={{ top: "0px" }}
                    sx={{
                      fontSize: "14px",
                      marginBottom: 6,
                      "&.Mui-focused": {
                        color: "orange",
                      },
                    }}
                  >
                    Subtopic 161616
                  </InputLabel>
                  <Select
                    sx={{
                      borderRadius: "10px",
                      width: "250px",
                      height: "50px",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "14px !important",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& > fieldset": {
                          border: "1px solid #D6D5DF !important",
                        },
                      },
                      "&.MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          border: "1px solid #272727 !important",
                        },
                        ".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root": {
                          fontSize: "16px",
                          fontFamily: "Poppins",
                          color: "#fd8c00 !important",
                          backgroundColor: "#fff",
                          fontWeight: "500 !important",
                        },
                      },
                      fontSize: "14px !important",
                    }}
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={subtopicId}
                    name="first"
                    onChange={(e) => {
                      //  setSubtopicId(e?.target?.value); 
                      //console.log(e.target.value, subtopic)
                    }}
                    input={<OutlinedInput label="Subtopic" />}

                    renderValue={(selected) =>
                      selected
                        ?.map((obj) => {
                          return subtopic?.find((item) => {
                            return item.id === obj;
                          })?.subTopic;
                        })
                        .join(", ")
                    }
                  >
                    {subtopic?.map((subtopic) => (
                      <MenuItem key={subtopic.id} value={subtopic.id}>
                        <Checkbox
                          checked={subtopicId.indexOf(subtopic.id) > -1}
                        />
                        <ListItemText primary={subtopic.subTopic} />
                      </MenuItem>
                    ))}
                  </Select>
                  {isError &&
                    subtopicId.length === 0 &&
                    subtopic.length > 0 && (
                      <span style={{ color: "red", padding: "5px" }}>
                        Subtopic is Required
                      </span>
                    )}{" "}
                </FormControl>

              } */}

              {
                showSubTopic &&
                <FormControl variant="outlined" sx={{ width: '250px' }}>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    style={{ top: "0px" }}
                    sx={{
                      fontSize: "15px",
                      marginBottom: 6,
                      "&.Mui-focused": {
                        color: "orange",
                      },
                    }}
                  >
                    *Subtopic
                  </InputLabel>
                  <Select
                    sx={{
                      borderRadius: "10px",
                      width: "250px",
                      height: "50px",
                      "& .css-10hburv-MuiTypography-root": {
                        fontSize: "14px !important",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& > fieldset": {
                          border: "1px solid #D6D5DF !important",
                        },
                      },
                      "&.MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          border: "1px solid #272727 !important",
                        },
                        ".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root": {
                          fontSize: "16px",
                          fontFamily: "Poppins",
                          color: "#fd8c00 !important",
                          backgroundColor: "#fff",
                          fontWeight: "500 !important",
                        },
                      },
                      fontSize: "14px !important",
                    }}
                    labelId="multi-select-checkbox-label"
                    id="multi-select-checkbox"

                    multiple
                    value={selectedItems}
                    onChange={() => { }} // We handle selection in handleOptionToggle
                    input={<OutlinedInput label="Select Options" />}
                    renderValue={(selected) => subtopic.filter(option => selected.includes(option.id)).map(option => option.subTopic).join(', ')}
                  >
                    {
                      subtopic.length > 0 &&
                      <MenuItem key="select-all" onClick={handleSelectAll}>
                        <Checkbox checked={selectAll} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    }
                    {subtopic.map((option) => (
                      <MenuItem key={option.id} onClick={handleOptionToggle(option)}>
                        <Checkbox checked={selectedItems.indexOf(option.id) > -1} />
                        <ListItemText primary={option.subTopic} className="cursorPointer" />
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <label for="section" style={{ color: "orange" }}>*Subtopic</label> */}
                  {isError &&
                    selectedItems.length === 0 &&
                    subtopic.length > 0 && (
                      <span style={{ color: "red", padding: "5px" }}>
                        Subtopic is Required
                      </span>
                    )}{" "}
                </FormControl>
              }
            </div>
          </div>

          <div
            className={addstudentstyle.input_element}
            style={{ width: "31%", marginTop: "24px", marginLeft: "10px" }}
          >
            <FormControl>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ top: "0px" }}
                sx={{
                  fontSize: "14px",
                  marginBottom: 6,
                  "&.Mui-focused": {
                    color: "orange",
                  },
                }}
              >
                Section
              </InputLabel>
              <Select
                sx={{
                  borderRadius: "10px",
                  width: "250px",
                  height: "50px",
                  "& .css-10hburv-MuiTypography-root": {
                    fontSize: "14px !important",
                  },
                  "&.MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "1px solid #D6D5DF !important",
                    },
                  },
                  "&.MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      border: "1px solid #272727 !important",
                    },
                    ".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root": {
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      color: "#fd8c00 !important",
                      backgroundColor: "#fff",
                      fontWeight: "500 !important",
                    },
                  },
                  fontSize: "14px !important",
                }}
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={sectionId}
                name="first"
                onChange={(e) => setSectionId(e?.target?.value)}
                input={<OutlinedInput label="Subtopic" />}
                renderValue={(selected) =>
                  selected
                    ?.map((obj) => {
                      return section?.find((item) => {
                        return item.id === obj;
                      })?.section;
                    })
                    .join(", ")
                }
              >
                {section?.map((subtopic) => (
                  <MenuItem key={subtopic.id} value={subtopic.id} className="cursorPointer">
                    <Checkbox
                      checked={sectionId.indexOf(subtopic.id) > -1}
                    />
                    <ListItemText primary={subtopic.section} />
                  </MenuItem>
                ))}
              </Select>
              {isError &&
                sectionId.length === 0 &&
                section.length > 0 && (
                  <span style={{ color: "red", padding: "5px" }}>
                    Section is Required
                  </span>
                )}{" "}
            </FormControl>
          </div>

        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Green100Button disabled={isBtnEnabled} onClick={GiveAccessHandler}>Submit</Green100Button>
        </div>
      </Box>
    </Modal>
  );
};

export default PrincipalAccessModal;
