import axios from 'axios'

async function City_Controller_getall(setCityName) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/all`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      if(json.data.data){
        // console.log(json.data.data,"citNam")
        setCityName(json.data.data)
      }
    })
    .catch(err=>console.log(err))
}

export default City_Controller_getall;
