import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuProps } from "./Data";
import { styled } from "@mui/material/styles";
import QuestionModal from "./QuestionModal";
import QuestionModalPage from "./QuestionModalPage";
import ReleaseModal from "./ReleaseModal";
import axios from "axios";
import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
// import { SearchOutlined } from "@mui/icons-material";
import ReReleaseModal from "./ReReleaseModal";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../components/common/TopBar";
import searchIcn from "../../../src/img/search.svg";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import "../css/Student/Student_Enrich_Quiz.css";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../js/TeachValidate";
import TeacherMessage from "./TeacherMessage";
import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import ReleaseSuccess from "./CreateQuiz/QueSelection/ReleaseSuccess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CommonSort from "../js/CommonSort"
const Item = styled(Paper)(({ theme, quizType }) => ({
	backgroundColor: "#fff",
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	// boxShadow:
	// 	"0px 2px 1px -1px rgba(138,203,249,0.77), 0px 1px 1px 0px rgba(138,203,249,0.77), 0px 1px 3px 0px rgba(138,203,249,0.77)",
	boxShadow:
		quizType === "Practice Quiz"
			? "0px 2px 1px -1px  #8ed4d3, 0px 1px 1px 0px  #8ed4d3, 0px 1px 3px 0px  #8ed4d3"
			: "0px 2px 1px -1px rgba(138,203,249,0.77), 0px 1px 1px 0px rgba(138,203,249,0.77), 0px 1px 3px 0px rgba(138,203,249,0.77)",

	display: "flex",
	flexDirection: "column",
	alignItems: "start",
}));
// const ChapterItem = styled(Paper)(({ theme }) => ({
// backgroundColor: "#fff",
// ...theme.typography.body2,
// padding: theme.spacing(1),
// textAlign: "center",
// color: theme.palette.text.secondary,
// display: "flex",
// justifyContent: "center",
// alignItems: "center",
// }));
const CancelButton = styled(Button)(({ theme }) => ({
	color: "#ed9b00",
	width: "5.2vw",
	backgroundColor: "#fff",
	borderRadius: 150,
	border: "1px solid #757575",
	borderColor: "#ed9b00",
	textTransform: "none",
	fontSize: "0.78vw",
}));
const SaveButton = styled(Button)(() => ({
	color: "#fff",
	width: "5.2vw",
	backgroundColor: "#ed9b00",
	borderRadius: 150,
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#ed9b00",
		border: 0,
		top: -2,
		boxShadow: "0px 3px 5px #FFA700",
	},
}));
const ReReleaseButton = styled(Button)(() => ({
	color: "#fff",
	width: "7.8vw",
	backgroundColor: "#ed9b00",
	borderRadius: 150,
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#ed9b00",
		border: 0,
		top: -2,
		boxShadow: "0px 3px 5px #FFA700",
	},
}));

const useStyles = makeStyles((theme) => ({
	tooltip: {
		maxWidth: '190px',
		fontSize: '10px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
}));

const AssignQuiz = (props) => {
	// console.log("Assignquiz.js --------------")
	const navigate = useNavigate();
	const [preSelectedData, setPreSelectedData] = useState({});
	const [questionModalData, setQuestionModalData] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showReleaseModal, setShowReleaseModal] = useState(false);
	const [showReReleaseModal, setShowReReleaseModal] = useState(false);
	const token = sessionStorage.getItem("token");
	const [modifiedDate, setModifiedDate] = useState({});
	const [subjectList, setSubjectList] = useState([]);
	const [showSubTopic, setShowSubTopic] = useState(false);
	const [gradeList, setGradeList] = useState([]);
	const [selectedGrade, setSelectedGrade] = useState("");
	const [selectedSubject, setSelectedSubject] = useState("");
	const [selectedBoard, setSelectedBoard] = useState("");
	const [selectedSection, setSelectedSection] = useState("");
	const [selectedSubTopic, setSelectedSubTopic] = useState("");
	const [academicYearList, setAcademicYearList] = useState([]);
	const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
	const [allQuizData, setAllQuizData] = useState([]);
	const [updatedData, setUpdatedData] = useState([]);
	const [selectedChapterId, setSelectedChapterId] = useState("");
	const [chapterList, setChapterList] = useState([]);
	const [chapterPageSize, setChapterPageSize] = useState(100);
	const [selectedQuizId, setSelectedQuizId] = useState("");
	const [selectedPreviewId, setSelectedPreviewId] = useState("");
	const [isError, setIsError] = useState(false);
	const [quizIds, setQuizIds] = useState([]);
	const [quizDuraction, setQuizDuration] = useState("");
	const [BadError, setBadError] = useState("");
	const [selectedSectionReleaseData, setSelectedSectionReleaseData] =
		useState([]);
	const [isSucessModal, setSucessModal] = useState(false);
	const [quizType, setQuizType] = useState("");
	const [SucessMessage, setSucessMessage] = useState(
		"Quiz Released Sucessfully"
	);
	const [selectedSectionName, setSelectedSectionName] = useState("");
	const [rmLockUnlockDatas, setRmLockUnlockDatas] = useState([]);
	const [planData, setPlanData] = useState([]);
	const [branchId, setBranchId] = useState("");
	const classes = useStyles();
	const [hasStartAndEndDate, setHasStartAndEndDate] = useState(false);

	const [
		isCheckingWhetherChapterIsEnded,
		setisCheckingWhetherChapterIsEnded,
	] = useState(false);

	const userName = localStorage.getItem("userName");
	const role = localStorage.getItem("role");

	const [teacherId, setTeacherId] = useState("");
	const [gradeSection, setGradeSection] = useState([]);
	//console.log("teacherId", teacherId.id)
	const location = useLocation();

	//-------| Plan Api |-------//
	const fetchPlanDetails = async () => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			sessionStorage.setItem("planId", res?.data?.data?.plans[0]?.planId)
			return res.data || [];
		} catch (error) {
			// console.error("Error fetching plan Data:", error);
			return [];
		}
	};

	//-------| Plan Template rm-lock/unlock Api |-------//
	const fetchRmlockUnlockData = async () => {
		const planId = sessionStorage.getItem("planId");
		const gradeId = sessionStorage.getItem("gradeId");
		//console.log(planId, gradeId);
		//console.log(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/subjects-by-template-feature?planId=${planId}&gradeId=${gradeId}&columnName=RM_LOCK`)
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/subjects-by-template-feature?planId=${planId}&gradeId=${gradeId}&columnName=RM_LOCK`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			//console.log(res.data.data)
			return res.data.data || [];
		} catch (error) {
			// console.error("Error fetching RmLock/Unlock Data:", error);
			return [];
		}
	};
	const tochangestatus = () => {
		// console.log("tochangestatus called from assign quiz page", questionModalData, showModal);
		setShowModal(false)
	}
	useEffect(() => {
		const fethPlanData = async () => {
			if (branchId) {
				const planDatas = await fetchPlanDetails();
				setPlanData(planDatas);
			}
		};
		// navigate('../quiz')
		fethPlanData();
	}, [branchId]);

	useEffect(() => {
		const fetchRmData = async () => {
			const rmDataList = await fetchRmlockUnlockData();
			//console.log(rmDataList)
			setRmLockUnlockDatas(rmDataList);
		};

		fetchRmData();
	}, [planData, selectedGrade, selectedSubject]);

	const subjectID = sessionStorage.getItem("subjectId");
	const idArray = rmLockUnlockDatas.map((item) => item.subjectId)
	const activeStatusArray = rmLockUnlockDatas.map((item) => item.active);
	const foundIndex = idArray.indexOf(subjectID);
	//console.log(subjectID, activeStatusArray, rmLockUnlockDatas)
	//console.log(foundIndex)
	//console.log(activeStatusArray[foundIndex])
	let isActive = false;
	if (foundIndex !== -1) {
		isActive = activeStatusArray[foundIndex];
	}

	useEffect(() => {
		if (location.state) {
			setPreSelectedData(location?.state);
			// console.log("setPreSelectedData", location)
		}
	}, [location?.state]);

	useEffect(async () => {
		fetchTeacherData(userName, role);
		const academic = await fetchAcademicYearData();

		// GetLastModifiedAt(setModifiedDate);
		// Set the initial value of the filter
		const storedAcademicYearId = sessionStorage.getItem("academicYearId");
		if (storedAcademicYearId) {
			setSelectedAcademicYear(storedAcademicYearId);
		} else if (academic.length > 0) {
			setSelectedAcademicYear(academic[0].id);
			sessionStorage.setItem("academicYearId", academic[0].id);
		}
	}, []);

	//auto populate filters start scripts
	const [isAutoPopulating, setIsAutoPopulating] = useState(false);
	useEffect(() => {
		if (
			isNOTNullOrUndefined(gradeSection) &&
			gradeSection?.length &&
			isNullOrUndefined(preSelectedData.gradeId)
		) {
			startAutoPopulate();
		}
	}, [gradeSection]);

	const fetchSubjectForAutoPopulate = async (gradId, secId) => {
		const id = teacherId.id;
		const response = await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${gradId}&sectionId=${secId}&menuName=ASSIGN_QUIZ`,
				// `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${sessionStorage.getItem("planId")}&gradeId=${gradId || sessionStorage.getItem("gradeId")}`,

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.catch((err) => console.log(err));
		// console.log(response.data.data, "=========2")
		// console.log(response?.data?.data, "4-7-24")
		response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || []
		// console.log(response?.data?.data)

		setSubjectList(response?.data?.data);
		return response?.data?.data;
	};

	const startAutoPopulate = async () => {
		setIsAutoPopulating(true);
		setSelectedGrade(gradeSection[0]);
		setSelectedSection(gradeSection[0]?.sections[0].id);
		const subList = await fetchSubjectForAutoPopulate(
			gradeSection[0]?.id,
			gradeSection[0]?.sections[0].id
		);
		setSelectedSubject(subList[0]);
		// console.log("subb1", subList[0]);
		setShowSubTopic(subList[0]?.hideSubtopics ? false : true);
		//console.log(subList[0]?.hideSubtopics ? false : true)
		// alert("======")
		sessionStorage.setItem("gradeId", gradeSection[0]?.id);
		sessionStorage.setItem("sectionId", gradeSection[0]?.sections[0].id);
		sessionStorage.setItem("subjectId", subList[0]?.subjectId);
		fetchPlanDetails()
		fetchRmlockUnlockData()

		setIsAutoPopulating(false);
		// setTimeout(() => {
		// setIsAutoPopulating(false);
		// }, 2000);
	};

	const RenderLoader = () => {
		return (
			<div
				style={{
					height: "100%",
					width: "100%",
					zindex: 99,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	};

	//Auto populate ends

	const quizRelease = async (item) => {
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const gradeId = sessionStorage.getItem("gradeId");
		const sectionId = sessionStorage.getItem("sectionId");
		const subjectId = sessionStorage.getItem("subjectId");
		const subTopicId = selectedSubTopic;
		const academicYearId = selectedAcademicYear
			? selectedAcademicYear
			: academicYearList[0]?.id;

		const chapterId = item.id;

		const data = {
			bordId,
			gradeId,
			schoolId,
			branchId,
			subjectId,
			subTopicId,
			sectionId,
			chapterId,
			academicYearId,
			subjectList// i added
		};

		setPreSelectedData(data);
		// console.log("setPreSelectedData", data)
	};

	useEffect(async () => {
		if (isNOTNullOrUndefined(preSelectedData.gradeId)) {
			setSelectedGrade(
				gradeSection?.filter(
					(item) => item.id === preSelectedData.gradeId
				)[0]
			);
			// console.log("subb2", preSelectedData)
			setSelectedSubject(
				subjectList?.find(
					(item) => item.subjectId === preSelectedData.subjectId
				)
			);
			var Vsubject = subjectList?.find(
				(item) => item.subjectId === preSelectedData.subjectId
			)
			// console.log("Vsubject", Vsubject)
			// alert("alert Vsubject" + JSON.stringify(Vsubject))
			// console.log(subjectList?.find(
			// 	(item) => item.subjectId === preSelectedData.subjectId
			// ), "subb3")
			if (Vsubject?.hideSubtopics) {
				// console.log("deleting subtopic id bcz")
				delete preSelectedData.subTopicId;
			}
			const chaptersFetched = await fetchChapterDataForPreselection(
				preSelectedData
			);
			setChapterList(chaptersFetched);
			// console.log("setChapterList", chaptersFetched)
			setSelectedChapterId(
				chaptersFetched?.filter(
					(item) => item.id === preSelectedData.chapterId
				)[0]
			);

			const storedAcademicYearId =
				sessionStorage.getItem("academicYearId");
			setSelectedAcademicYear(storedAcademicYearId);
		}
	}, [preSelectedData, gradeSection, academicYearList]);

	useEffect(() => {
		setSelectedSection(preSelectedData.sectionId);
	}, [preSelectedData?.gradeId]);

	useEffect(() => {
		// console.log("preSelectedData", preSelectedData, showSubTopic)

		if (preSelectedData?.subTopicId) {
			if (preSelectedData?.subTopicId?.id) {
				setSelectedSubTopic(
					selectedSubject?.subTopics?.find(
						(item) => item.id === preSelectedData?.subTopicId?.id
					)
				);
			} else {
				setSelectedSubTopic(
					selectedSubject?.subTopics?.find(
						(item) => item.id === preSelectedData?.subTopicId
					)
				);
			}
		} else if (selectedSubject?.subTopics) {
			if (selectedSubject?.hideSubtopics) {
			} else {
				setSelectedSubTopic(selectedSubject?.subTopics[0]);//reg math hide
			}

		} else {
			setSelectedSubTopic("");
		}
	}, [selectedSubject]);

	const fetchChapterDataForPreselection = async (preselected) => {
		// console.log("fetchChapterDataForPreselection", preselected, "showSubTopic", showSubTopic)
		setChapterList([]);
		let result = [];

		const bordId = sessionStorage.getItem("boardId");
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/master/chapters/all-chapters?subjectId=${preselected.subjectId
				}&gradeId=${preselected.gradeId}&boardId=${bordId}${(isNOTNullOrUndefined(preselected?.subTopicId?.id) && !selectedSubject.hideSubtopics)
					? `&subTopicId=${preselected?.subTopicId?.id}`
					: ""
				}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.status === 200) {
					result = response?.data?.data;
					result = await CommonSort(result || [], "chapter", false);
				} else {
					result = [];
				}
			})
			.catch((err) =>{

			}
				// console.log(
				// 	err,
				// 	"error on fetching chapter list on preselection"
				// )
			);
		return result;
	};

	useEffect(() => {
		if (!isAutoPopulating) {
			fetchSubject();
		}
	}, [selectedGrade, selectedSection]);

	useEffect(async () => {
		const grade = await fetchGrade();
		const academic = await fetchAcademicYearData();
		await fetchChapterData();
		setAcademicYearList(academic);
		setGradeList(grade);
		// setChapterList(chapters)
	}, [teacherId.id]);

	useEffect(async () => {
		if (
			isNOTNullOrUndefined(selectedGrade) &&
			isNOTNullOrUndefined(selectedSection) &&
			isNOTNullOrUndefined(selectedSubject)
		) {
			setIsLoadingList(true);
			// if (
			// 	selectedSubject?.subTopics &&
			// 	selectedSubject?.subTopics?.length > 0 &&
			// 	isNullOrUndefined(selectedSubTopic)
			// ) {
			// } else
			if (
				selectedSubject?.subTopics &&
				isNOTNullOrUndefined(selectedSubTopic) &&
				selectedSubject?.subTopics?.length > 0
			) {
				showQuiz();
			} else {
				showQuiz();
			}
		} else {
			setIsLoadingList(false);
		}
	}, [
		selectedChapterId,
		selectedGrade,
		selectedSubject,
		selectedSection,
		selectedSubTopic,
	]);

	useEffect(async () => {
		var VselectedSubTopic = selectedSubTopic
		if (selectedSubject?.hideSubtopics) {
			VselectedSubTopic = {}
		}
		// console.log("selectedSubject", selectedSubject, selectedSubTopic)
		if (
			isNOTNullOrUndefined(selectedSubject) &&
			isNOTNullOrUndefined(selectedGrade) &&
			isNOTNullOrUndefined(selectedSection)
		) {


			if (
				selectedSubject?.subTopics &&
				selectedSubject?.subTopics?.length > 0 &&
				isNullOrUndefined(VselectedSubTopic)
			) {
			} else if (
				isNOTNullOrUndefined(selectedSubject?.subTopics) &&
				selectedSubject?.subTopics?.length > 0
			) {
				if (isNOTNullOrUndefined(VselectedSubTopic)) {
					setTimeout(() => {
						fetchChapterData();
					}, 800);
				}
			} else {
				setTimeout(() => {
					fetchChapterData();
				}, 800);
			}
		}
	}, [selectedSubject, selectedSubTopic, selectedSection]);

	const fetchTeacherData = async (userName, role) => {
		const res = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);

		var result = res?.data?.data?.teacher || {};
		sessionStorage.setItem("Basic_info", JSON.stringify(result))

		sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
		sessionStorage.setItem("schoolId", res.data.data.teacher.school);
		sessionStorage.setItem("branchId", res.data.data.teacher.branch);
		setTeacherId(res.data.data.teacher);
		sessionStorage.setItem("teacherId", res.data.data.teacher.id)
		setBranchId(res.data.data.teacher.branch)
	};

	// grade - section API

	const fetchGrade = async () => {
		const id = teacherId.id;
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				setGradeSection(response.data.data);
			})
			.catch((err) => console.log(err));
	};

	// subject - subtopic API

	const fetchSubject = async () => {
		// const id = teacherId?.id;
		const teacherrId = sessionStorage.getItem("teacherId")
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/user/teachers/subjects?teacherId=${teacherrId}&gradeId=${selectedGrade?.id || selectedGrade
				}&sectionId=${selectedSection?.id || selectedSection
				}&menuName=ASSIGN_QUIZ`,
				// `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${sessionStorage.getItem("planId")}&gradeId=${selectedGrade?.id || selectedGrade || sessionStorage.getItem("gradeId")}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				//console.log(response.data.data, "=========1");
				// console.log(response?.data?.data, "4-7-24")
				response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || []
				// console.log(response?.data?.data)
				setSubjectList(response.data.data);
			})
			.catch((err) => {
				// console.log(err)
			});
	};

	// Academic year api

	const fetchAcademicYearData = async () => {
		const res = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return res?.data.data || [];
	};

	const fetchChapterData = async () => {
		// console.log("fetchChapterData", selectedSubTopic, selectedSubject)
		setChapterList([]);
		const bordId = sessionStorage.getItem("boardId");
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/master/chapters/all-chapters?subjectId=${selectedSubject?.subjectId
					? selectedSubject.subjectId
					: selectedSubject
				}&gradeId=${selectedGrade?.id ? selectedGrade.id : selectedGrade
				}&boardId=${bordId}${(isNOTNullOrUndefined(selectedSubTopic?.id) && !selectedSubject.hideSubtopics)
					? `&subTopicId=${selectedSubTopic?.id}`
					: ""
				}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.status === 200) {
					// var chapterLIST = response?.data?.data.filter(obj => obj.startDate !== null && obj.startDate !== '') || [];
					// setChapterList(chapterLIST);

					// console.log(response.data.data)
					response.data.data = await CommonSort(response?.data?.data || [], "chapter", false);
					// console.log(response.data.data)
					setChapterList(response?.data?.data);
					// console.log("response?.data?.data", response?.data?.data)
				} else {
					setChapterList([]);
				}
			})
			.catch((err) => console.log(err, "error on fetching chapter list"));
	};

	const [isLoadingList, setIsLoadingList] = useState(false);
	const selectchapterData = (item) => {
		// alert(item);
		// console.log(item);
		setSelectedChapterId(item?.id)
	}

	const showQuiz = async () => {
		setAllQuizData([]);
		setBadError("");
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const subTopicId = sessionStorage.getItem("subTopicId");
		// console.log("***************", selectedSubject, selectedSubTopic, "***************", selectedChapterId)
		// const chapterId = id;
		// const selectedSub = subjectList.find(val => val.subjectId === selectedSubject?.subjectId);

		// if (selectedSub?.subjectId && !selectedSub.hideSubtopics) {
		// 	setShowSubTopic(false);
		// } else {
		// 	setShowSubTopic(true);
		// }

		// console.log("showQuizimport", selectedChapterId, selectedChapterId?.id || selectedChapterId)
		if (selectedChapterId?.id || selectedChapterId) {
			await axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL
					}v1/api/content/quiz-release/list-to-teacher?reviewStatus=APPROVED&schoolId=${schoolId}&branchId=${branchId}&boardId=${bordId}&sectionId=${selectedSection}&gradeId=${selectedGrade?.id ? selectedGrade?.id : selectedGrade
					}&subjectId=${selectedSubject?.subjectId
						? selectedSubject.subjectId
						: selectedSubject
					}${isNOTNullOrUndefined(selectedChapterId)
						? `&chapterId=${selectedChapterId?.id || selectedChapterId}`
						: ""
					}&academicYearId=${selectedAcademicYear?.id
						? selectedAcademicYear.id
						: selectedAcademicYear
					}${(isNOTNullOrUndefined(selectedSubTopic?.id) && !selectedSubject?.hideSubtopics)// reg
						? "&subTopicId=" + selectedSubTopic?.id
						: ""
					}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((response) => {
					// console.log(response, response.status === 200)
					if (response.status === 200) {
						// console.log("response?.data?.data", response?.data?.data)
						setAllQuizData(response?.data?.data);
						setIsLoadingList(false);
						const hasStartAndEndDate = response?.data?.data.some(quiz => quiz.startDate && quiz.endDate);
						setHasStartAndEndDate(hasStartAndEndDate);
					} else {
						setAllQuizData([]);
						setIsLoadingList(false);
					}
				})
				.catch((err) => {
					// console.log(err, "error fetching quiz list", err.response.data.message)
					setBadError(err?.response?.data?.message)
				});
		}
	};
	const showQuiz1 = async (item) => {
		setSelectedChapterId(item);
		setBadError("")
		// console.log(item)
		// setAllQuizData([]);

		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const subTopicId = sessionStorage.getItem("subTopicId");
		// console.log("***************", selectedSubject, selectedSubTopic, "***************", selectedChapterId)
		// const chapterId = id;
		// console.log("showQuiz", selectedChapterId)
		if (item?.id || selectedChapterId) {
			await axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL
					}v1/api/content/quiz-release/list-to-teacher?reviewStatus=APPROVED&schoolId=${schoolId}&branchId=${branchId}&boardId=${bordId}&sectionId=${selectedSection}&gradeId=${selectedGrade?.id ? selectedGrade?.id : selectedGrade
					}&subjectId=${selectedSubject?.subjectId
						? selectedSubject.subjectId
						: selectedSubject
					}${isNOTNullOrUndefined(selectedChapterId ? selectedChapterId : item?.id)
						? `&chapterId=${item?.id || selectedChapterId?.id || selectedChapterId}`
						: ""
					}&academicYearId=${selectedAcademicYear?.id
						? selectedAcademicYear.id
						: selectedAcademicYear
					}${(isNOTNullOrUndefined(selectedSubTopic?.id) && !selectedSubject?.hideSubtopics)// reg
						? "&subTopicId=" + selectedSubTopic?.id
						: ""
					}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((response) => {
					// console.log(response, response.status === 200)
					if (response.status === 200) {
						// console.log("response?.data?.data", response?.data?.data)
						setAllQuizData(response?.data?.data);
						setIsLoadingList(false);
						const hasStartAndEndDate = response?.data?.data.some(quiz => quiz.startDate && quiz.endDate);
						setHasStartAndEndDate(hasStartAndEndDate);
					} else {
						setAllQuizData([]);
						setIsLoadingList(false);
					}
				})
				.catch((err) => {
					// console.log(err, "error fetching quiz list");
					setBadError(err?.response?.data?.message)
				});
		}
	};

	const handleChangeGrade = (e) => {
		// setSelectedGrade(e.target.value);
		setSelectedGrade(
			gradeSection?.filter((grade) => grade.id === e.target.value)[0]
		);
		setSelectedSection("");

		sessionStorage.setItem("gradeId", e.target.value);
	};

	const handleChangeSection = (event) => {
		setAllQuizData([]);
		setSelectedSection(event.target.value);
		setSelectedChapterId("");
		sessionStorage.setItem("chapterId", null);
		setSelectedSubject("");
		sessionStorage.setItem("sectionId", event.target.value);
	};

	const handleChangeSubject = (event) => {

		//console.log("subject selected", event.target.value)
		//console.log(subjectList?.find((item) => item.subjectId === event.target.value))
		const selectedSubject = subjectList.find(val => val.subjectId === String(event.target.value));
		// console.log(selectedSubject, "swewewewewewewewewewewe")
		//console.log(selectedSubject?.hideSubtopics, selectedSubject.subjectId)
		if (selectedSubject && selectedSubject.hideSubtopics) {
			setShowSubTopic(false);
			//console.log("false")
			// alert("pac false");
		} else {
			//console.log("true")
			setShowSubTopic(true);
			// alert("pac true");
		}
		setAllQuizData([]);
		setSelectedSubject(
			subjectList?.find((item) => item.subjectId === event.target.value)
		);
		// console.log("subb4", subjectList?.find((item) => item.subjectId === event.target.value))
		sessionStorage.setItem("subjectId", event.target.value);
	};

	const handleChangeChapter = (event) => {
		setAllQuizData([]);
		setSelectedChapterId(
			chapterList?.filter((item) => item.id === event.target.value)[0]
		);
		var seltdchptr = chapterList?.filter((item) => item.id === event.target.value)[0];
		// console.log("seltdchptr", seltdchptr)
		sessionStorage.setItem("chapterId", event.target.value);
		showQuiz1(seltdchptr)
	};

	const handleChangeSubtopic = (event) => {
		setAllQuizData([]);
		setSelectedSubTopic(
			selectedSubject?.subTopics?.find(
				(item) => item.id === event.target.value
			)
		);
		sessionStorage.setItem("subTopicId", event.target.value);
	};

	const onPreviewClick = async (item) => {

		//console.log("onpreview called in asignquiz")
		// sessionStorage.setItem('quizmenu','assignquiz')
		sessionStorage.setItem("selectedquestionModal", JSON.stringify(item));
		// navigate(`shared/assign quiz`);
		// navigate('../quiz'); //commented on 10-april this is issue
		sessionStorage.setItem("navbarHide", true);
		sessionStorage.setItem("redirectUrl", 'assign quiz');

		// previous code
		await setQuestionModalData(item);
		await setShowModal(true);//to show questionbank // enabled on 10-april
	};

	const onReleaseClick = (item) => {
		checkIfChapterEnded(item);
	};

	// function to handle Re-release
	const OpenReRelease = (item) => {
		setSelectedQuizId(item);
		setShowReReleaseModal(true);
		setShowReleaseModal(false);
	};

	const previewHandler = async () => {
		setShowReleaseModal(false);
		setSelectedQuizId(selectedPreviewId);
		setShowModal(true);
	};

	const releaseQuiz = async (quizid) => {
		const id = teacherId.id;
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const subTopicId = sessionStorage.getItem("subTopicId");
		// const branchId = sessionStorage.getItem("branchId");

		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-release/list-of-section?quizId=${quizid}&teacherId=${id}&gradeId=${selectedGrade.id}&sectionId=${selectedSection}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${selectedSubject.subjectId}&boardId=${bordId}&academicYearId=${selectedAcademicYear}${(isNOTNullOrUndefined(subTopicId) && !selectedSubject?.hideSubtopics) ? `&subTopicId=${subTopicId}` : ''}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setQuizIds(response.data.data);
	};

	const checkIfChapterEnded = async (item) => {
		setisCheckingWhetherChapterIsEnded(true);
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		// console.log("selectedChapterId", selectedChapterId)
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/teacher/teach/chapter/min/details?gradeId=${selectedGrade.id
				}&sectionId=${selectedSection}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${selectedSubject.subjectId
				}&boardId=${bordId}&academicYearId=${selectedAcademicYear}${selectedSubTopic?.id
					? "&subTopicId=" + selectedSubTopic?.id
					: ""
				}&chapterId=${selectedChapterId?.id || selectedChapterId}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				const endDateFetched = res?.data?.data?.endDate;
				if (isNOTNullOrUndefined(endDateFetched)) {
					setisCheckingWhetherChapterIsEnded(false);
					setShowReleaseModal(true);
					setSelectedPreviewId(item.id);
					setSelectedQuizId(item.id);
					setQuizDuration(item.duration);
					releaseQuiz(item.id);
				} else {
					setChapterStatusMessage("Study of Chapter not Completed");
				}
			})
			.catch((err) => {
				setChapterStatusMessage("Some Error Occured try again");
				//console.log(err);
			});
	};

	const onChapterStatusModalClose = () => {
		setisCheckingWhetherChapterIsEnded(false);
		setChapterStatusMessage(null);
	};
	const [query, setQuery] = useState("");

	function isPastEndTimeAndDate(endTime, endDate) {
		var currentTime = new Date();

		var endHour = parseInt(endTime.split(":")[0]);
		var endMinute = parseInt(endTime.split(":")[1].split(" ")[0]);
		var endMeridian = endTime.split(" ")[1];

		var endDay = parseInt(endDate.split("/")[0]);
		var endMonth = parseInt(endDate.split("/")[1]) - 1;
		var endYear = parseInt(endDate.split("/")[2]);

		var endDateTime = new Date(
			endYear,
			endMonth,
			endDay,
			endMeridian === "PM"
				? endHour !== 12
					? endHour + 12
					: endHour
				: endMeridian === "AM" && endHour === 12
					? (endHour = 1)
					: endHour,
			endMinute
		);
		return currentTime > endDateTime;
	}

	const [chapterStatusMessage, setChapterStatusMessage] = useState(null);

	// if (isAutoPopulating) {
	// 	return <RenderLoader />;
	// }

	const purchasedFeature = localStorage.getItem("purchasedFeatures");
	const purchasedFeatures = JSON.parse(purchasedFeature);

	const rm_Lock = purchasedFeatures.find(item => item.name === 'rm_lock')

	let toggle_status = rm_Lock.toggleStatus;
	// console.log("toggle_status", toggle_status)
	//-------| Truncate the Chapter Name |-------//
	const maxLength = 20;

	const labelStyle = {
		fontSize: "14px",
		color: selectedGrade ? '#ffffff' : '#ffa500',
	}

	return (
		<div style={{ width: "100%" }}>
			<ReleaseSuccess
				open={isSucessModal}
				onClose={() => {
					setSucessModal(false);
				}}
				title={SucessMessage}
				selectedSectionName={selectedSectionName}
			/>
			{/* {
				console.log("preSelectedData", preSelectedData)
			} */}
			<TopBar
				title={"Home"}
				subSubTitle={"Assign Quiz"}
				modifiedDate={modifiedDate}
				nav1={"TeacherDashboard"}

			/>
			{preSelectedData?.chapterId ? (
				<div
					style={{
						marginTop: "5px",
						paddingRight: "33px",
						position: "absolute",
						right: "15px",
						left: "15px",
						width: "100%",
						display: "flex",
						flexDirection: "column",
						rowGap: "1.2rem",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							columnGap: "10px",
						}}
					>
						<Box sx={{ minWidth: 120 }}>
							{/* <FormControl
								fullWidth
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										color: "#FD8C00 !important",
										fontSize: "14px !important",
										opacity: 0.9,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "148px",
										height: "32px",
									},
								}}
							>
								{!selectedGrade && (
									<InputLabel
										id="az-teacher-grade-select-label"
										shrink={false}
									>
										Select Grade
									</InputLabel>
								)}
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-grade-select"
									value={
										selectedGrade?.id
											? selectedGrade?.id
											: selectedGrade
									}
									onChange={handleChangeGrade}
									style={{
										borderRadius: "150px",
										minWidth: "180px",
										maxWidth: "180px",
										textOverflow: "ellipsis",
									}}
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
									sx={{
										height: "100%",
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
								>
									{gradeSection?.map((item) => (
										<MenuItem
											id={`az-teacher-grade-dropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
											key={item.id} value={item.id}>
											{item?.grade}
										</MenuItem>
									))}
								</Select>
							</FormControl> */}

							<FormControl fullWidth >
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}}
									id="az-teacher-grade-select-label" className='dropDownLabel'>Grade</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-grade-select"
									input={<OutlinedInput label='Grade' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									value={
										selectedGrade?.id
											? selectedGrade?.id
											: selectedGrade
									}
									onChange={handleChangeGrade}
									IconComponent={props => (
										<ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
									)}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									{gradeSection?.map((item) => (
										<MenuItem
											id={`az-teacher-grade-dropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
											key={item.id} value={item.id}
											className={
												dashboardContentStyles.menu_item
											}
											sx={{
												fontSize: '10px',
												backgroundColor: 'white',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
												'&.Mui-selected': {
													backgroundColor: '#e7ab3c',
													'&:hover': {
														backgroundColor: '#f4d788',
													},
												},
											}}>
											{item?.grade}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<Box sx={{ minWidth: 120 }}>
							{/* <FormControl
								fullWidth
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										color: "#FD8C00 !important",
										fontSize: "14px !important",
										opacity: 0.9,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "148px",
										height: "32px",
									},
								}}
							>
								{!selectedSection && (
									<InputLabel
										id="az-teacher-section-select-label"
										shrink={false}
									>
										Section 123
									</InputLabel>
								)}
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-section-select"
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
									style={{
										borderRadius: "150px",
										minWidth: "180px",
										maxWidth: "180px",
										textOverflow: "ellipsis",
										fontSize: 8,
										// padding: "4px",
									}}
									sx={{
										fontSize: 8,
										height: "100%",
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
									size={"small"}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									value={
										isNOTNullOrUndefined(selectedSection)
											? selectedSection
											: ""
									}
									// onChange={(e) => setSelectedSection(e.target.value)}
									onChange={handleChangeSection}
								>
									{selectedGrade &&
										gradeSection
											?.find(
												(i) => i.id === selectedGrade.id
											)
											?.sections.map((sec) => {
												// if (sec.hasOwnProperty("section")) {
												return (
													<MenuItem
														id={`az-teacher-section-dropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
														value={sec.id}
														key={sec.id}
														className={
															dashboardContentStyles.menu_item
														}
														sx={{
															"&.Mui-selected": {
																backgroundColor: 'orange',
																color: "#FFFFFF"
															},
															"&.Mui-selected:hover": {
																backgroundColor: '#fcedc2',
																color: "#000000"
															},
															"&:not(.Mui-selected):hover": {
																backgroundColor: '#fcedc2',
																color: '#000000',
															},
														}}
													>
														<ListItemText
															primary={
																sec.section
															}
														/>
													</MenuItem>
												);
												// }
											})}
								</Select>
							</FormControl> */}

							<FormControl fullWidth>
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}}
									id="az-teacher-section-select-label" className='dropDownLabel'>Section</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-section-select"
									input={<OutlinedInput label='Section' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									value={
										isNOTNullOrUndefined(selectedSection)
											? selectedSection
											: ""
									}
									// onChange={(e) => setSelectedSection(e.target.value)}
									onChange={handleChangeSection}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									{selectedGrade &&
										gradeSection
											?.find(
												(i) => i.id === selectedGrade.id
											)
											?.sections.map((sec) => {
												// if (sec.hasOwnProperty("section")) {
												return (
													<MenuItem
														id={`az-teacher-section-dropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
														value={sec.id}
														key={sec.id}
														className={
															dashboardContentStyles.menu_item
														}
														sx={{
															fontSize: '10px',
															backgroundColor: 'white',
															'&:hover': {
																backgroundColor: '#f4d788',
															},
															'&.Mui-selected': {
																backgroundColor: '#e7ab3c',
																'&:hover': {
																	backgroundColor: '#f4d788',
																},
															},
														}}
													>
														<ListItemText
															primary={
																sec.section
															}
														/>
													</MenuItem>
												);
												// }
											})}
								</Select>
							</FormControl>
						</Box>
						<Box sx={{ minWidth: 120 }}>
							{/* <FormControl
								fullWidth
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										color: "#FD8C00 !important",
										fontSize: "14px !important",
										opacity: 0.9,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "148px",
										height: "32px",
									},
								}}
							>
								{!selectedSubject && (
									<InputLabel
										id="az-teacher-subject-select-label"
										shrink={false}
									>
										Subject
									</InputLabel>
								)}
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-subject-select"
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
									style={{
										borderRadius: "150px",
										minWidth: "180px",
										maxWidth: "180px",
										textOverflow: "ellipsis",
										fontSize: 8,
									}}
									sx={{
										fontSize: 8,
										height: "100%",
									}}
									onChange={handleChangeSubject}
									size={"small"}
									value={
										isNOTNullOrUndefined(
											selectedSubject?.subjectId
										)
											? selectedSubject.subjectId
											: ""
									}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
								>
									{subjectList?.map((item) => (
										<MenuItem
											id={`az-teacher-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
											value={item.subjectId}
											key={item.subjectId}
											className={
												dashboardContentStyles.menu_item
											}
											sx={{
												"&.Mui-selected": {
													backgroundColor: 'orange',
													color: "#FFFFFF"
												},
												"&.Mui-selected:hover": {
													backgroundColor: '#fcedc2',
													color: "#000000"
												},
												"&:not(.Mui-selected):hover": {
													backgroundColor: '#fcedc2',
													color: '#000000',
												},
											}}
										>
											<ListItemText
												primary={item?.subject}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl> */}

							<FormControl fullWidth>
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}}
									id="az-teacher-subject-select-label" className='dropDownLabel'>Subject
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-subject-select"
									input={<OutlinedInput label='Subject' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									onChange={handleChangeSubject}
									value={
										isNOTNullOrUndefined(
											selectedSubject?.subjectId
										)
											? selectedSubject.subjectId
											: ""
									}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									{subjectList?.map((item) => (
										<MenuItem
											id={`az-teacher-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
											value={item.subjectId}
											key={item.subjectId}
											className={
												dashboardContentStyles.menu_item
											}
											sx={{
												fontSize: '10px',
												backgroundColor: 'white',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
												'&.Mui-selected': {
													backgroundColor: '#e7ab3c',
													'&:hover': {
														backgroundColor: '#f4d788',
													},
												},
											}}
										>
											<ListItemText
												primary={item?.subject}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						{/* {
							console.log('*****', selectedSubTopic, showSubTopic)
						} */}
						{
							showSubTopic &&
							<Box sx={{ minWidth: 120 }}>
								{/* <FormControl
									fullWidth
									size="small"
									sx={{
										"& .MuiInputLabel-root": {
											color: "#FD8C00 !important",
											fontSize: "14px !important",
											opacity: 0.9,
										},
										"& .MuiInputBase-root": {
											backgroundColor: "#FFFFFF",
											borderRadius: "50px",
											width: "148px",
											height: "32px",
										},
									}}
								>

									{!selectedSubTopic && (

										<InputLabel
											id="az-teacher-subtopic-select-label"
											shrink={false}
										>
											Selected SubTopic
										</InputLabel>
									)}
									<Select
										labelId="demo-simple-select-label"
										id="az-teacher-subtopic-select"
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											getContentAnchorEl: null,
											style: {
												maxHeight: "200px",
											},
										}}
										style={{
											borderRadius: "150px",
											minWidth: "180px",
											maxWidth: "180px",
											textOverflow: "ellipsis",
											fontSize: 8,
										}}
										sx={{
											fontSize: 8,
											height: "100%",
										}}
										onChange={handleChangeSubtopic}
										size={"small"}
										value={
											isNOTNullOrUndefined(
												selectedSubTopic?.id
											)
												? selectedSubTopic?.id
												: ""
										}
										IconComponent={(props) => (
											<ExpandMoreIcon
												{...props}
												sx={{ fontSize: "1.5vw" }}
											/>
										)}
									>
										{selectedSubject?.subTopics?.map((item) => (
											<MenuItem
												id={`az-teacher-subject-dropdown-${item.subTopic.toLowerCase().replace(/ /g, "_")}`}
												value={item.id}
												key={item.id}
												className={
													dashboardContentStyles.menu_item
												}
												sx={{
													"&.Mui-selected": {
														backgroundColor: 'orange',
														color: "#FFFFFF"
													},
													"&.Mui-selected:hover": {
														backgroundColor: '#fcedc2',
														color: "#000000"
													},
													"&:not(.Mui-selected):hover": {
														backgroundColor: '#fcedc2',
														color: '#000000',
													},
												}}
											>
												<ListItemText
													primary={item?.subTopic}
												/>
											</MenuItem>
										))}
									</Select>
								</FormControl> */}

								<FormControl fullWidth>
									<InputLabel
										sx={{
											fontSize: '13px',
											color: '#e7ab3c',
											top: '-10px',
											'&.MuiInputLabel-shrink': {
												top: '0',
											},
											'&.Mui-focused': {
												color: '#e7ab3c'
											}
										}}
										id="az-teacher-subtopic-select-label" className='dropDownLabel'>SubTopic</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="az-teacher-subtopic-select"
										input={<OutlinedInput label='SubTopic' />}
										MenuProps={{
											PaperProps: {
												sx: {
													width: '150px',
													marginTop: '2px',
												},
											},
											MenuListProps: {
												sx: {
													padding: '0px'
												},
											},
										}}
										onChange={handleChangeSubtopic}
										value={
											isNOTNullOrUndefined(
												selectedSubTopic?.id
											)
												? selectedSubTopic?.id
												: ""
										}
										IconComponent={(props) => (
											<ExpandMoreIcon
												{...props}
												sx={{ fontSize: "1.5vw" }}
											/>
										)}
										sx={{
											width: '150px',
											height: '30px',
											padding: '10px',
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: '#000',
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: '#000',
											},
											'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: '#e7ab3c',
											}
										}}
										className='dropdownSelect'
									>
										{selectedSubject?.subTopics?.map((item) => (
											<MenuItem
												id={`az-teacher-subject-dropdown-${item.subTopic.toLowerCase().replace(/ /g, "_")}`}
												value={item.id}
												key={item.id}
												className={
													dashboardContentStyles.menu_item
												}
												sx={{
													fontSize: '10px',
													backgroundColor: 'white',
													'&:hover': {
														backgroundColor: '#f4d788',
													},
													'&.Mui-selected': {
														backgroundColor: '#e7ab3c',
														'&:hover': {
															backgroundColor: '#f4d788',
														},
													},
												}}
											>
												<ListItemText
													primary={item?.subTopic}
												/>
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						}

						<Box sx={{ minWidth: 120 }}>
							{/* <FormControl
								fullWidth
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										color: "#FD8C00 !important",
										fontSize: "14px !important",
										opacity: 0.9,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "148px",
										height: "32px",
									},
								}}
							>

								{!selectedChapterId && (
									<InputLabel
										id="az-teacher-chapter-select-label"
										shrink={false}
									>
										Selected Chapter
									</InputLabel>
								)}
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-chapter-select"
									label=""
									value={
										isNOTNullOrUndefined(
											selectedChapterId?.id
										)
											? selectedChapterId?.id
											: ""
									}
									onChange={handleChangeChapter}
									style={{
										width: "180px",
										padding: "10px",
										borderRadius: "20px",
										textOverflow: "ellipsis",
									}}
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
								>
									{chapterList?.map((item) => (
										<MenuItem
											id={`az-teacher-chapter-dropdown-${item.chapter.toLowerCase().replace(/ /g, "_")}`}
											key={item.id}
											value={item.id}
											className={
												dashboardContentStyles.menu_item
											}
											sx={{
												"&.Mui-selected": {
													backgroundColor: 'orange',
													color: "#FFFFFF"
												},
												"&.Mui-selected:hover": {
													backgroundColor: '#fcedc2',
													color: "#000000"
												},
												"&:not(.Mui-selected):hover": {
													backgroundColor: '#fcedc2',
													color: '#000000',
												},
											}}
										>
											{item?.chapter}
										</MenuItem>
									))}
								</Select>
							</FormControl> */}

							<FormControl fullWidth>
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}}
									id="az-teacher-chapter-select-label" className='dropDownLabel'>Chapter</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-chapter-select"
									input={<OutlinedInput label='Chapter' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
												maxHeight: '190px',
												overflowY: 'auto',   // Enable scrolling if content exceeds maxHeight
												'&::-webkit-scrollbar': {
													width: '2px', // Width of the scrollbar
												},
												'&::-webkit-scrollbar-track': {
													background: '#f1f1f1', // Background color of the scrollbar track
												},
												'&::-webkit-scrollbar-thumb': {
													background: '#888', // Color of the scrollbar thumb
													borderRadius: '4px', // Rounded corners for the scrollbar thumb
												},
												'&::-webkit-scrollbar-thumb:hover': {
													background: '#555', // Color of the scrollbar thumb when hovered
												},
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px',
												overflowY: 'auto',
											},
										},
									}}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									value={
										isNOTNullOrUndefined(
											selectedChapterId?.id
										)
											? selectedChapterId?.id
											: ""
									}
									onChange={handleChangeChapter}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									{chapterList?.map((item) => (
										<MenuItem
											id={`az-teacher-chapter-dropdown-${item.chapter.toLowerCase().replace(/ /g, "_")}`}
											key={item.id}
											value={item.id}
											className={
												dashboardContentStyles.menu_item
											}
											sx={{
												fontSize: '10px',
												backgroundColor: 'white',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
												'&.Mui-selected': {
													backgroundColor: '#e7ab3c',
													'&:hover': {
														backgroundColor: '#f4d788',
													},
												},
											}}
										>
											{item?.chapter}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<Box sx={{ minWidth: 120 }}>
							{/* <FormControl
								fullWidth
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										color: "#FD8C00 !important",
										fontSize: "14px !important",
										opacity: 0.9,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "120%",
										height: "32px",
									},
								}}
							>
								{!selectedAcademicYear && (
									<InputLabel
										id="az-teacher-acdyear-select-label"
										shrink={false}
									>
										Selected year
									</InputLabel>
								)}
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-acdyear-select"
									size={"small"}
									value={selectedAcademicYear}
									IconComponent={KeyboardArrowDownIcon}
									disabled
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
								>
									<MenuItem
										id={`az-teacher-acdyear-dropdown`}
										value={academicYearList[0]?.id}
										key={academicYearList[0]?.id}
										className={
											dashboardContentStyles.menu_item
										}
										sx={{
											"&.Mui-selected": {
												color: '#ffa500', // Change the color for the selected item
											},
										}}
									>
										<ListItemText
											primary={
												academicYearList[0]
													?.academicYear
											}
										/>
									</MenuItem>
								</Select>
							</FormControl> */}

							<FormControl fullWidth>
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}}
									id="az-teacher-acdyear-select-label" className='dropDownLabel'>Academic Year</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="az-teacher-acdyear-select"
									input={<OutlinedInput label='Academic Year' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									value={selectedAcademicYear}
									disabled
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									<MenuItem
										id={`az-teacher-acdyear-dropdown`}
										value={academicYearList[0]?.id}
										key={academicYearList[0]?.id}
										className={
											dashboardContentStyles.menu_item
										}
										sx={{
											fontSize: '10px',
											backgroundColor: 'white',
											'&:hover': {
												backgroundColor: '#f4d788',
											},
											'&.Mui-selected': {
												backgroundColor: '#e7ab3c',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
											},
										}}
									>
										<ListItemText
											primary={
												academicYearList[0]
													?.academicYear
											}
										/>
									</MenuItem>
								</Select>
							</FormControl>
						</Box>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							rowGap: "1rem",
							height: "100%",
						}}
					>
						<div style={{ fontWeight: "600", fontSize: 18 }}>
							All Quiz For this Chapter {allQuizData?.length}
						</div>
						{isLoadingList ? (
							(BadError !== "" && BadError !== null) ? <div>{BadError}</div> : <div>Fetching....</div>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									columnGap: "1rem",
									padding: "30px",
								}}
							>{
									// console.log(selectedChapterId, allQuizData, selectedGrade, selectedSubject, selectedSection, "1558")
								}
								{isNOTNullOrUndefined(selectedGrade) &&
									isNOTNullOrUndefined(selectedSubject) &&
									isNOTNullOrUndefined(selectedSection) &&
									isNOTNullOrUndefined(selectedChapterId) ? (
									<Box sx={{ flexGrow: 1 }}>
										<Grid
											id='az-quiz-card-parent'
											container spacing={3}>
											{(!showModal && allQuizData?.length > 0) ? (
												allQuizData
													.sort((a, b) => {

														if (a.quizType === b.quizType) {

															return a.name.localeCompare(b.name);
														}

														return a.quizType === "Unit Quiz" ? -1 : 1;
													})
													.map((item) => (
														<Grid
															id={`az-quiz-card-child-${item?.id}`}
															item
															xs={12}
															md={4}
															sm={6}
															key={item?.id}
														>
															<Item quizType={item.quizType}>
																<div
																	style={{
																		padding:
																			"15px 10px",
																		color: "#f19e00",
																		fontWeight: 600,
																		fontSize:
																			"0.93vw",
																		wordWrap:
																			"break-word",

																		maxWidth:
																			"300px",

																	}}
																>
																	{item.quizType}{" "}
																	| {item.name}
																</div>
																<div
																	style={{
																		// background:
																		// 	"rgba(138,203,249,0.77)",
																		background: item.quizType === "Practice Quiz" ? "#8ed4d3" : "rgba(138,203,249,0.77)",
																		width: "100%",
																		padding:
																			"20px",
																		fontSize:
																			"0.93vw",
																	}}
																>
																	<div
																		style={{
																			display:
																				"flex",
																			flexDirection:
																				"row",
																			justifyContent:
																				"space-between",
																			padding:
																				"5px",
																		}}
																	>
																		<div>
																			Questions
																		</div>
																		<div
																			style={{
																				minWidth:
																					"80px",
																			}}
																		>
																			{
																				item.totalQuestions
																			}
																		</div>
																	</div>
																	<div
																		style={{
																			display:
																				"flex",
																			flexDirection:
																				"row",
																			justifyContent:
																				"space-between",
																			padding:
																				"5px",
																		}}
																	>
																		<div>
																			Marks
																		</div>
																		<div
																			style={{
																				minWidth:
																					"80px",
																			}}
																		>
																			{
																				item.totalMarks
																			}
																		</div>
																	</div>
																	<div
																		style={{
																			display:
																				"flex",
																			flexDirection:
																				"row",
																			justifyContent:
																				"space-between",
																			padding:
																				"5px",
																		}}
																	>
																		<div>
																			Duration
																		</div>
																		<div
																			style={{
																				minWidth:
																					"80px",
																			}}
																		>
																			{`${item.duration} mins`}
																		</div>
																	</div>
																</div>
																<div
																	style={{
																		// background:
																		// 	"rgba(4,59,189,0.91)",
																		background: item.quizType === "Practice Quiz" ? "#3ba5a3" : "rgba(4,59,189,0.91)",
																		width: "100%",
																		padding:
																			"20px",
																		color: "white",
																		fontSize:
																			"0.93vw",
																	}}
																>
																	<div
																		style={{
																			display:
																				"flex",
																			flexDirection:
																				"row",
																			justifyContent:
																				"space-between",
																			padding:
																				"5px",
																		}}
																	>
																		<div
																			style={{
																				minWidth:
																					"280px",
																			}}
																		>
																			{Object.keys(
																				item?.taxonomyPercentage
																			).map(
																				(
																					v,
																					i
																				) => {
																					return (
																						<div
																							style={{
																								display:
																									"flex",
																								justifyContent:
																									"space-between",
																							}}
																						>
																							<p
																								style={{
																									color: "white",
																								}}
																							>
																								{
																									v
																								}
																							</p>
																							<p
																								style={{
																									color: "white",
																								}}
																							>
																								{
																									item
																										?.taxonomyPercentage[
																									v
																									]
																								}

																								%
																							</p>
																						</div>
																					);
																				}
																			)}
																		</div>
																	</div>
																</div>
																{item.containsCaseStudy ===
																	true && (
																		<div
																			style={{
																				// background:
																				// 	"rgb(3,38,120)",
																				background: item.quizType === "Practice Quiz" ? " #23706f" : "rgb(3,38,120)",
																				width: "100%",
																				padding:
																					"20px",
																				color: "white",
																				display:
																					"flex",
																				justifyContent:
																					"center",
																				alignItems:
																					"center",
																			}}
																		>
																			Contains
																			CaseStudy
																		</div>
																	)}
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		padding: "10px",
																		alignItems: "center",
																		width: "100%",
																		columnGap: "1rem",
																	}}
																>
																	<CancelButton
																		id="az-teacher-quiz-preview-btn"
																		onClick={() => onPreviewClick(item)}>
																		Preview
																	</CancelButton>
																	{/* <SaveButton
onClick={() => {
//console.log('Clicked on ReRelease');
onReleaseClick(item);
setQuizType(item.quizType);
}}
>
ReRelease
</SaveButton> */}

																	{/* {console.log('Checking Practice Quiz condition:', item.quizType === "Practice Quiz", item.endDate, (item.endDate !== "" && item.endDate))} */}
																	{
																		// (item.endDate !== "" && item.endDate) &&
																		//hasStartAndEndDate &&
																		<>
																			{item.quizType === "Practice Quiz"
																				? !item.released && toggle_status && (isActive || subjectList[0].active) && (
																					//console.log('Executing Release block for Practice Quiz'),
																					<SaveButton
																						id="az-teacher-quiz-release-btn"
																						onClick={() => {
																							//console.log('Clicked on Release for Practice Quiz');
																							onReleaseClick(item);
																							setQuizType(item.quizType);
																						}}
																						style={{ visibility: item.quizType === "Practice Quiz" ? "hidden" : "visible" }}
																					>
																						Release
																					</SaveButton>
																				)
																				: (
																					// console.log('Checking other conditions for Release or ReRelease', item, item.allAttended, toggle_status, isActive, (!item.allAttended && toggle_status && isActive), subjectList, selectedSubTopic),
																					(!item.allAttended && toggle_status && (isActive || subjectList[0].active)) ? (
																						// item.endTime !== null
																						true ?
																							//? isPastEndTimeAndDate(item.endTime, item.endDate) && (
																							true && (
																								item.released ? (
																									// console.log('Executing ReRelease block'),
																									<SaveButton
																										id="az-teacher-quiz-rerelease-btn"
																										onClick={() => {
																											// console.log('Clicked on ReRelease');
																											onReleaseClick(item);
																											setQuizType(item.quizType);
																										}}
																									>
																										ReRelease
																									</SaveButton>
																								) : (
																									// console.log('Executing Release block'),
																									<SaveButton
																										id="az-teacher-quiz-release-btn"
																										onClick={() => {
																											// console.log('Clicked on Release');
																											onReleaseClick(item);
																											setQuizType(item.quizType);
																										}}
																									>
																										Release
																									</SaveButton>
																								)
																							)
																							: (
																								// console.log('Checking other conditions for Release or ReRelease------', item.released),
																								item.released ? (
																									// console.log('Executing ReRelease block'),
																									<SaveButton
																										id="az-teacher-quiz-rerelease-btn"
																										onClick={() => {
																											// console.log('Clicked on ReRelease');
																											onReleaseClick(item);
																											setQuizType(item.quizType);
																										}}
																									>
																										ReRelease
																									</SaveButton>
																								) : (
																									// console.log('Executing Release block'),
																									<SaveButton
																										id="az-teacher-quiz-release-btn"
																										onClick={() => {
																											// console.log('Clicked on Release');
																											onReleaseClick(item);
																											setQuizType(item.quizType);
																										}}
																									>
																										Release
																									</SaveButton>
																								)
																							)
																					) : (<></>)
																				)
																			}
																		</>}
																</div>

															</Item>
														</Grid>
													))
											) : (
												<div style={{ color: "red" }}>
													No Quiz for selected Filters
												</div>
											)}
										</Grid>
									</Box>
								) : (
									<div>
										Please do select Grade , Section and
										Subject
									</div>
								)}
							</div>
						)}
					</div>
					{questionModalData && showModal && (
						<QuestionModal
							open={showModal}
							close={() => {
								setShowModal(false);
								setQuestionModalData(null);
								setSelectedQuizId("");
							}}
							data={questionModalData}
						// tochangestatus={tochangestatus}
						/>

					)}
					{showReleaseModal && (
						<ReleaseModal
							open={showReleaseModal}
							close={() => {
								setShowReleaseModal(false);
								setSelectedQuizId("");
								setSelectedPreviewId("");
							}}
							previewHandler={previewHandler}
							fetchSection={quizIds}
							selectedQuizId={selectedQuizId}
							quizDuraction={quizDuraction}
							teacherId={teacherId.id}
							OpenReRelease={OpenReRelease}
							setSucessModal={setSucessModal}
							setSelectedSectionReleaseData={
								setSelectedSectionReleaseData
							}
							quizType={quizType}
							setSucessMessage={setSucessMessage}
							selectedSubTopic={selectedSubTopic?.id}
							setSelectedSectionName={setSelectedSectionName}
						/>
					)}
					{showReReleaseModal && (
						<ReReleaseModal
							open={showReReleaseModal}
							close={() => setShowReReleaseModal(false)}
							selectedQuizId={selectedQuizId}
							teacherId={teacherId.id}
							selectedSectionReleaseData={
								selectedSectionReleaseData
							}
							setSucessModal={setSucessModal}
							setSucessMessage={setSucessMessage}
							selectedSubTopic={selectedSubTopic?.id}
							subjectList={subjectList}
						/>
					)}
					{isCheckingWhetherChapterIsEnded ? (
						<TeacherMessage
							message={chapterStatusMessage}
							open={isCheckingWhetherChapterIsEnded}
							close={onChapterStatusModalClose}
						/>
					) : null}
				</div>
			) : (
				<div
					style={{
						marginTop: "5px",
						paddingRight: "33px",
						position: "absolute",
						right: "15px",
						left: "15px",
						width: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							paddingBottom: "10px",
							alignItems: "center",
							columnGap: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								columnGap: "10px",
							}}
						>
							{/* <FormControl
								sx={{
									minWidth: "7.8vw",
									background: "white",
									borderRadius: 150,
									"&.Mui-selected": {
										backgroundColor: 'orange',
										color: "#FFFFFF"
									},
									"&.Mui-selected:hover": {
										backgroundColor: '#fcedc2',
										color: "#000000"
									},
									"&:not(.Mui-selected):hover": {
										backgroundColor: '#fcedc2',
										color: '#000000',
									},
									margin: "10px"
								}}
							>
								<InputLabel id='demo-simple-select-grade'
									// sx={labelStyle}
									style={{ marginBottom: "8px" }}
								>
									Grades
								</InputLabel>
								<Select
									labelId="demo-simple-select-grade-label"
									id="demo-simple-grade"
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
									className={dashboardContentStyles.az_select_menu_selected}//here not working
									style={{
										borderRadius: "150px",
										padding: "4px",
										color: "#ffa500"
									}}
									size={"small"}
									sx={{
										"& .css-10hburv-MuiTypography-root": {
											font: "normal normal normal 15px/15px Poppins !important",
										},
										"&.MuiFormControl-marginNormal": {
											marginTop: "8px", // Add margin top for spacing
										},
									}}
									value={
										isNOTNullOrUndefined(selectedGrade)
											? selectedGrade?.id
											: ""
									}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									onChange={handleChangeGrade}
								>
									{gradeSection?.map((item) => {
										return (
											<MenuItem
												id={`az-teacher-gradesection-dropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
												value={item?.id}
												key={item.id}
												className={
													dashboardContentStyles.menu_item
												}
												sx={{
													"&.Mui-selected": {
														backgroundColor: 'orange',
														color: "#FFFFFF"
													},
													"&.Mui-selected:hover": {
														backgroundColor: '#fcedc2',
														color: "#000000"
													},
													"&:not(.Mui-selected):hover": {
														backgroundColor: '#fcedc2',
														color: '#000000',
													},
												}}
											>
												<ListItemText
													primary={item.grade}
												/>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl> */}

							<FormControl fullWidth>
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}}
									id="demo-simple-select-grade" className='dropDownLabel'>Grade</InputLabel>
								<Select
									labelId="demo-simple-select-grade-label"
									id="demo-simple-grade"
									input={<OutlinedInput label='Grades' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									value={
										isNOTNullOrUndefined(selectedGrade)
											? selectedGrade?.id
											: ""
									}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									onChange={handleChangeGrade}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									{gradeSection?.map((item) => {
										return (
											<MenuItem
												id={`az-teacher-gradesection-dropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
												value={item?.id}
												key={item.id}
												className={
													dashboardContentStyles.menu_item
												}
												sx={{
													fontSize: '10px',
													backgroundColor: 'white',
													'&:hover': {
														backgroundColor: '#f4d788',
													},
													'&.Mui-selected': {
														backgroundColor: '#e7ab3c',
														'&:hover': {
															backgroundColor: '#f4d788',
														},
													},
												}}
											>
												<ListItemText
													primary={item.grade}
												/>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							{/* <FormControl
								sx={{
									minWidth: "7.8vw",
									background: "white",
									borderRadius: 150,
									margin: "10px"
								}}
							>
								<InputLabel id="demo-simple-select-section">
									Section
								</InputLabel>
								<Select
									labelId="demo-simple-select-section-label"
									id="demo-simple-section"
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
									className={dashboardContentStyles.az_select_menu_selected}//here not working
									style={{
										borderRadius: "150px",
										padding: "4px",
										color: "#ffa500"
									}}
									sx={{
										"& .css-10hburv-MuiTypography-root": {
											font: "normal normal normal 15px/15px Poppins !important",
										},
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
									size={"small"}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									value={
										isNOTNullOrUndefined(selectedSection)
											? selectedSection
											: ""
									}
									// onChange={(e) => setSelectedSection(e.target.value)}
									onChange={handleChangeSection}
								>
									{selectedGrade &&
										gradeSection
											?.find(
												(i) =>
													i.id === selectedGrade?.id
											)
											?.sections?.map((sec) => {
												// if (sec.hasOwnProperty("section")) {
												return (
													<MenuItem
														id={`az-teacher-section-dropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
														value={sec.id}
														key={sec.id}
														className={
															dashboardContentStyles.menu_item
														}
														sx={{
															"&.Mui-selected": {
																backgroundColor: 'orange',
																color: "#FFFFFF"
															},
															"&.Mui-selected:hover": {
																backgroundColor: '#fcedc2',
																color: "#000000"
															},
															"&:not(.Mui-selected):hover": {
																backgroundColor: '#fcedc2',
																color: '#000000',
															},
														}}
													>
														<ListItemText
															primary={
																sec.section
															}
														/>
													</MenuItem>
												);
												// }
											})}
								</Select>
							</FormControl> */}


							<FormControl fullWidth >
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}}
									id="demo-simple-select-section" className='dropDownLabel'>Section</InputLabel>
								<Select
									labelId="demo-simple-select-section-label"
									id="demo-simple-section"
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									input={<OutlinedInput label='Section' />}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									value={
										isNOTNullOrUndefined(selectedSection)
											? selectedSection
											: ""
									}
									// onChange={(e) => setSelectedSection(e.target.value)}
									onChange={handleChangeSection}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									{selectedGrade &&
										gradeSection
											?.find(
												(i) =>
													i.id === selectedGrade?.id
											)
											?.sections?.map((sec) => {
												// if (sec.hasOwnProperty("section")) {
												return (
													<MenuItem
														id={`az-teacher-section-dropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
														value={sec.id}
														key={sec.id}
														className={
															dashboardContentStyles.menu_item
														}
														sx={{
															fontSize: '10px',
															backgroundColor: 'white',
															'&:hover': {
																backgroundColor: '#f4d788',
															},
															'&.Mui-selected': {
																backgroundColor: '#e7ab3c',
																'&:hover': {
																	backgroundColor: '#f4d788',
																},
															},
														}}
													>
														<ListItemText
															primary={
																sec.section
															}
														/>
													</MenuItem>
												);
												// }
											})}
								</Select>
							</FormControl>

							{/* <FormControl
									sx={{
										background: "white",
										borderRadius: 150,
										width: "100%",
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
								>
									<InputLabel id="demo-simple-select-subject">
										Subjects
									</InputLabel>
									<Select
										labelId="demo-simple-select-subject-label"
										id="demo-simple-subject"
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											getContentAnchorEl: null,
											style: {
												maxHeight: "200px",
											},
										}}
										style={{
											borderRadius: "150px",
											padding: "4px",
											paddingRight: 28,
											color: "#ffa500"
										}}
										sx={{
											"& .css-10hburv-MuiTypography-root":
											{
												font: "normal normal normal 15px/14px Poppins !important",
											},
										}}
										size={"small"}
										value={
											isNOTNullOrUndefined(
												selectedSubject
											)
												? selectedSubject?.subjectId
												: ""
										}
										IconComponent={(props) => (
											<ExpandMoreIcon
												{...props}
												sx={{ fontSize: "1.5vw" }}
											/>
										)}
										// onChange={(e) => setSelectedSubject(e.target.value)}
										onChange={handleChangeSubject}
									>
										{subjectList?.map((item) => {
											return (
												<MenuItem
													id={`az-teacher-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
													value={item.subjectId}
													key={item.subjectId}
													className={
														dashboardContentStyles.menu_item
													}
													sx={{
														"&.Mui-selected": {
															backgroundColor: 'orange',
															color: "#FFFFFF"
														},
														"&.Mui-selected:hover": {
															backgroundColor: '#fcedc2',
															color: "#000000"
														},
														"&:not(.Mui-selected):hover": {
															backgroundColor: '#fcedc2',
															color: '#000000',
														},
													}}
												>
													<ListItemText
														primary={item.subject}
													/>
												</MenuItem>
											);
										})}
									</Select>
							</FormControl> */}

							<FormControl fullWidth >
								<InputLabel
									sx={{
										fontSize: '13px',
										color: '#e7ab3c',
										top: '-10px',
										'&.MuiInputLabel-shrink': {
											top: '0',
										},
										'&.Mui-focused': {
											color: '#e7ab3c'
										}
									}} id="demo-simple-select-subject" className='dropDownLabel'>Subjects</InputLabel>
								<Select
									labelId="demo-simple-select-subject-label"
									id="demo-simple-subject"
									input={<OutlinedInput label='Subjects' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									value={
										isNOTNullOrUndefined(
											selectedSubject
										)
											? selectedSubject?.subjectId
											: ""
									}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									// onChange={(e) => setSelectedSubject(e.target.value)}
									onChange={handleChangeSubject}
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										}
									}}
									className='dropdownSelect'
								>
									{subjectList?.map((item) => {
										return (
											<MenuItem
												id={`az-teacher-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
												value={item.subjectId}
												key={item.subjectId}
												className={
													dashboardContentStyles.menu_item
												}
												sx={{
													fontSize: '10px',
													backgroundColor: 'white',
													'&:hover': {
														backgroundColor: '#f4d788',
													},
													'&.Mui-selected': {
														backgroundColor: '#e7ab3c',
														'&:hover': {
															backgroundColor: '#f4d788',
														},
													},
												}}
											>
												<ListItemText
													primary={item.subject}
												/>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							{
								showSubTopic &&

								// <FormControl
								// 		sx={{
								// 			background: "white",
								// 			borderRadius: 150,
								// 			width: "100%",
								// 		}}
								// 	>
								// 		<InputLabel id="demo-simple-select-subtopic">
								// 			SubTopic
								// 		</InputLabel>
								// 		<Select
								// 			labelId="demo-simple-select-subtopic-label"
								// 			id="demo-simple-subtopic"
								// 			MenuProps={{
								// 				anchorOrigin: {
								// 					vertical: "bottom",
								// 					horizontal: "left",
								// 				},
								// 				transformOrigin: {
								// 					vertical: "top",
								// 					horizontal: "left",
								// 				},
								// 				getContentAnchorEl: null,
								// 				style: {
								// 					maxHeight: "200px",
								// 				},
								// 			}}
								// 			sx={{
								// 				"& .css-10hburv-MuiTypography-root":
								// 				{
								// 					font: "normal normal normal 15px/15px Poppins !important",
								// 				},
								// 			}}
								// 			style={{
								// 				borderRadius: "150px",
								// 				padding: "4px",
								// 				paddingRight: 28,
								// 				color: "#ffa500"
								// 			}}
								// 			size={"small"}
								// 			value={
								// 				isNOTNullOrUndefined(
								// 					selectedSubTopic?.id
								// 				)
								// 					? selectedSubTopic?.id
								// 					: ""
								// 			}
								// 			IconComponent={(props) => (
								// 				<ExpandMoreIcon
								// 					{...props}
								// 					sx={{ fontSize: "1.5vw" }}
								// 				/>
								// 			)}
								// 			onChange={handleChangeSubtopic}
								// 		>
								// 			{selectedSubject?.subTopics?.map(
								// 				(item) => (
								// 					<MenuItem
								// 						id={`az-teacher-subject-dropdown-${item.subTopic.toLowerCase().replace(/ /g, "_")}`}
								// 						value={item.id}
								// 						key={item.id}
								// 						className={
								// 							dashboardContentStyles.menu_item
								// 						}
								// 						sx={{
								// 							"&.Mui-selected": {
								// 								backgroundColor: 'orange',
								// 								color: "#FFFFFF"
								// 							},
								// 							"&.Mui-selected:hover": {
								// 								backgroundColor: '#fcedc2',
								// 								color: "#000000"
								// 							},
								// 							"&:not(.Mui-selected):hover": {
								// 								backgroundColor: '#fcedc2',
								// 								color: '#000000',
								// 							},
								// 						}}
								// 					// disabled={!item.active}
								// 					>
								// 						<ListItemText
								// 							primary={item?.subTopic}
								// 						/>
								// 					</MenuItem>
								// 				)
								// 			)}
								// 		</Select>
								// </FormControl>

								<FormControl fullWidth >
									<InputLabel
										sx={{
											fontSize: '13px',
											color: '#e7ab3c',
											top: '-10px',
											'&.MuiInputLabel-shrink': {
												top: '0',
											},
											'&.Mui-focused': {
												color: '#e7ab3c'
											}
										}}
										id="demo-simple-select-subtopic" className='dropDownLabel'>SubTopic</InputLabel>
									<Select
										labelId="demo-simple-select-subtopic-label"
										id="demo-simple-subtopic"
										input={<OutlinedInput label='Sub Topic' />}
										MenuProps={{
											PaperProps: {
												sx: {
													width: '150px',
													marginTop: '2px',
												},
											},
											MenuListProps: {
												sx: {
													padding: '0px'
												},
											},
										}}
										value={
											isNOTNullOrUndefined(
												selectedSubTopic?.id
											)
												? selectedSubTopic?.id
												: ""
										}
										IconComponent={(props) => (
											<ExpandMoreIcon
												{...props}
												sx={{ fontSize: "1.5vw" }}
											/>
										)}
										onChange={handleChangeSubtopic}
										sx={{
											width: '150px',
											height: '30px',
											padding: '10px',
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: '#000',
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: '#000',
											},
											'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: '#e7ab3c',
											}
										}}
										className='dropdownSelect'
									>
										{selectedSubject?.subTopics?.map(
											(item) => (
												<MenuItem
													id={`az-teacher-subject-dropdown-${item.subTopic.toLowerCase().replace(/ /g, "_")}`}
													value={item.id}
													key={item.id}
													className={
														dashboardContentStyles.menu_item
													}
													sx={{
														fontSize: '10px',
														backgroundColor: 'white',
														'&:hover': {
															backgroundColor: '#f4d788',
														},
														'&.Mui-selected': {
															backgroundColor: '#e7ab3c',
															'&:hover': {
																backgroundColor: '#f4d788',
															},
														},
													}}
												// disabled={!item.active}
												>
													<ListItemText
														primary={item?.subTopic}
													/>
												</MenuItem>
											)
										)}
									</Select>
								</FormControl>
							}
							{/* <FormControl
								sx={{
									minWidth: "7.8vw",
									background: "white",
									borderRadius: 150,
								}}
							>
								<InputLabel id="demo-simple-select-label">
									Academic Years
								</InputLabel>
								<Select
									labelId="demo-simple-select-acdyr-label"
									id="demo-simple-acdyr-select"
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
									style={{
										borderRadius: "150px",
										padding: "4px",
									}}
									size={"small"}
									sx={{
										"& .css-10hburv-MuiTypography-root": {
											font: "normal normal normal 15px/15px Poppins !important",
										},
									}}
									value={selectedAcademicYear}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									onChange={(e) =>
										setSelectedAcademicYear(e.target.value)
									}
									disabled
								>
									<MenuItem
										id={`az-teacher-acdyear-dropdown`}
										value={academicYearList[0]?.id}
										key={academicYearList[0]?.id}
										className={
											dashboardContentStyles.menu_item
										}
										sx={{
											"&.Mui-selected": {
												color: '#ffa500', // Change the color for the selected item
											},
										}}
									>
										<ListItemText
											primary={
												academicYearList[0]
													?.academicYear
											}
										/>
									</MenuItem>
								</Select>
							</FormControl> */}

							<FormControl fullWidth>
								<InputLabel sx={{
									fontSize: '13px',
									color: '#e7ab3c',
									top: '-10px',
									'&.MuiInputLabel-shrink': {
										top: '0',
									},
									'&.Mui-focused': {
										color: '#e7ab3c'
									}
								}}
									id="demo-simple-select-acdyr-label" className='dropDownLabel'>
									Academic Year
								</InputLabel>
								<Select
									labelId="demo-simple-select-acdyr-label"
									id="demo-simple-acdyr-select"
									input={<OutlinedInput label='Academic Year' />}
									MenuProps={{
										PaperProps: {
											sx: {
												width: '150px',
												marginTop: '2px',
											},
										},
										MenuListProps: {
											sx: {
												padding: '0px'
											},
										},
									}}
									value={selectedAcademicYear}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
									onChange={(e) =>
										setSelectedAcademicYear(e.target.value)
									}
									disabled
									sx={{
										width: '150px',
										height: '30px',
										padding: '10px',
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#000',
										},
										'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: '#e7ab3c',
										},
									}}
									className='dropdownSelect'
								>
									<MenuItem
										id={`az-teacher-acdyear-dropdown`}
										value={academicYearList[0]?.id}
										key={academicYearList[0]?.id}
										className={
											dashboardContentStyles.menu_item
										}
										sx={{
											fontSize: '10px',
											backgroundColor: 'white',
											'&:hover': {
												backgroundColor: '#f4d788',
											},
											'&.Mui-selected': {
												backgroundColor: '#e7ab3c',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
											},
										}}
									>
										<ListItemText
											primary={
												academicYearList[0]
													?.academicYear
											}
										/>
									</MenuItem>
								</Select>
							</FormControl>

						</div>
						<TextField
							type={"text"}
							id={`az-teacher-search`}
							placeholder={"Search anything...."}
							onChange={(e) =>
								setQuery(e.target.value.toLowerCase())
							}
							InputProps={{
								endAdornment: (
									<IconButton>
										<img
											src={searchIcn}
											style={{
												verticalAlign: "top",
												fontSize: "large",
											}}
											aria-hidden="true"
											alt=""
										/>
									</IconButton>
								),
							}}
							sx={{
								background: !isError && "#fff",
								borderRadius: "150px",
								"& .MuiInputBase-root": {
									width: "200px !important",
									height: "30px !important",
									borderRadius: "50px !important",
									background: "#fff !important",
									lineHeight: "21px !important",
									font: "normal normal normal 15px/15px Poppins !important",
								},
							}}
							error={isError}
							helperText={
								isError && "Please Enter min 3 character."
							}
						/>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							height: 700,
							width: "100%",
							padding: "25px 20px",
							overflow: "auto",
							scrollbarWidth: "thin"
						}}
					>
						{selectedGrade && selectedSection && selectedSubject ? (
							<Box sx={{ flexGrow: 1 }}>
								{/* <Grid container spacing={2}>
{chapterList?.length > 0 ? (
chapterList
?.filter((itm) =>
itm.chapter
.toLowerCase()
.includes(query)
)
.map((item) => {

return (
<Grid
item
xs={12}
md={2.4}
sm={4}
key={item.id}
onClick={() => {
quizRelease(item);
}}
style={{ marginRight: "10px" }}

>{
console.log(item, "itemitem")
}


<div
key={item?.id}
style={{
width: '200px',
height: '332px',
border: '1px solid #ddd',
borderRadius: '8px',
overflow: 'hidden',
margin: '20px',
position: 'relative',
boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
backgroundColor: "white"
}}
className={dashboardContentStyles.az_card}
onMouseOver={(e) => { e.currentTarget.style.border = '2px solid #FFA500'; e.currentTarget.style.backgroundColor = "#fcedc2" }}
onMouseOut={(e) => { e.currentTarget.style.border = '1px solid #ddd'; e.currentTarget.style.backgroundColor = "white" }}
>
<img src={item?.thumbnailPath} alt="" style={{ width: '200px', height: '278px', boxShadow: '0 4px 8px grey', borderRadius: "10px", margin: "10px" }} />

<div
style={{
top: '90%',
left: '30%',
textAlign: 'center',
padding: '1px',
boxSizing: 'border-box',
fontSize: "10px !important",
}}
className={dashboardContentStyles.az_card_img}
>
<p>
{item?.chapter.length > maxLength ? (
<Tooltip arrow title={item?.chapter} classes={{ tooltip: classes.tooltip }}>
<span>{`${item?.chapter.substring(0, maxLength)}...`}</span>
</Tooltip>
) : (
item?.chapter
)}
</p>
</div>
</div>


</Grid>
);
})
) : (
<div className={dashboardContentStyles.small_font}>
No data for the selected filters
</div>
)}
</Grid> */}

								<div className={dashboardContentStyles.cardContainer}>
									{chapterList.length > 0 ? (
										chapterList
											?.filter((itm) =>
												itm.chapter.toLowerCase().includes(query)
											)
											.map((item, i) => (
												<div
													id={`az-teacher-chapter-card-${item.chapter.toLowerCase().replace(/ /g, "_")}`}
													key={item.id}
													onClick={() => { selectchapterData(item);  quizRelease(item); showQuiz1(item) }}
													className={dashboardContentStyles.card}
												>
													<img
														src={item?.thumbnailPath}
														width={110}
														alt={""}
														// style={{ maxHeight: "138px" }}
														className={dashboardContentStyles.az_quiz_card_img} />
													<div
														style={{
															fontSize: "14px",
															fontWeight: 600,
															color: "#423d5c",
														}}
													>
														{item?.chapter}
													</div>
												</div>
											))
									) : (
										<div>No data for the selected filters</div>
									)}
								</div>
							</Box>
						) : (
							<div className={dashboardContentStyles.small_font}>Please select Grade , Section & Subject</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
export default AssignQuiz;