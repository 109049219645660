import axios from 'axios'

function subConcept_controller_get(conceptInputs, setSubconceptList) {
    // console.log("conceptInputs", conceptInputs)
    let token = sessionStorage.getItem("token")

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/all?conceptId=${typeof conceptInputs === 'object' ? conceptInputs?.conceptId : conceptInputs}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        .then((res) => {
            setSubconceptList(res.data.data.data);
        })
        .catch(err => console.log(err))
}

export default subConcept_controller_get