import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import YellowButton from './yellowButton';
import crossIcon from "../../img/cross icon.svg";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

const CityModal = ({ open, close, boards }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // height:500,
    backgroundColor: "white",
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ padding: "10px",marginLeft:"50px"}}>
              {boards.city}
            </h3>
            <img style={{ padding: "15px" }} src={crossIcon} onClick={close} />
          </div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 ,marginLeft:"40px"}}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    School Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    Branch
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    Grades
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    Total Students
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {boards.schoolDetails?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{ borderBottom: "0" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0" }}>
                      {row.branchCount}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0" }}>
                      {row.totalGrades}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0" }}>
                      {row.totalStudents}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </>
  );
};

export default CityModal;
