import React, { useState, useEffect } from "react";
import { Link, navigate, useNavigate } from "react-router-dom";
// import fileApprovalStyles from './../css/studentFiles.module.css'
import fileApprovalStyles from "../css/fileApproval.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
// take this css copy in original file
import Pagination1 from "./pagination1";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import document_category_get from "../API_CONTROLLER/document-category-controller/document_category_get";
import student_upload_chapter_get from "../API_CONTROLLER/student-upload-content-controller/student_upload_chapter_get";
import dashHome from "../../../src/img/dashHome.svg";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TrainingApprovalAid = () => {
  const [chapters, setChapters] = useState({});
  const [docCategory, setDocCategory] = useState({});
  const [chaptercontent, setChapterContent] = useState({});
  const [pageDetail, setPageDetail] = useState({});
  const [page, setPage] = useState(1);
  const [revision, setRevision] = useState(true);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    chapters_controller_getall(setChapters);
    document_category_get(setDocCategory);
    student_upload_chapter_get(setChapterContent);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <a>Content Approval</a>
            {"  "}
            {/* <span>
                            <i className="fa-solid fa-angle-right"></i>
                        </span>
                        <span>
                            <i className="fa-solid fa-angle-right"></i>
                        </span>
                        <a >Upload Content</a>
                        <span>
                            <i className="fa-solid fa-angle-right"></i>
                        </span>
                        <span>
                            <i className="fa-solid fa-angle-right"></i>
                        </span>
                        <a >Add Student Files</a> */}
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: XX:XX</p>
          </div>
        </article>

        {/* first row content start */}
        <div
          style={{ marginTop: "65px", position: "relative" }}
          className={fileApprovalStyles.flex}
        >
          <div className={fileApprovalStyles.left_row}>
            <i
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/Global%20Training%20Approval");
              }}
              className="fa-solid fa-arrow-left"
            ></i>
            <i
              style={{ color: "grey", fontSize: "24px", margin: "10px" }}
              className="fa-solid fa-user-gear"
            ></i>
            <p>Global Training Approval</p>
          </div>
          <div style={{ position: "absolute", right: "15px" }}>
            <button
              style={{ color: "white", backgroundColor: "#FD8C00" }}
              className={fileApprovalStyles.orange_btn}
            >
              Aid
            </button>
            <button className={fileApprovalStyles.orange_btn}>Quiz</button>
          </div>
        </div>
        {/* first row content end */}

        {/* content start */}
        <div
          style={{
            marginTop: "75px",
            marginInline: "20px",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "25%",
              boxShadow: "0px 2px 3px #00000029",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ width: "100%", height: "600px", position: "relative" }}
            >
              <div
                style={{
                  backgroundColor: "#D9DCFE",
                  display: "flex",
                  height: "48px",
                }}
              >
                <Checkbox
                  // {...label}
                  // defaultChecked
                  sx={{
                    color: `#111fee !important`,
                    "&.Mui-checked": {
                      color: blue[900],
                    },
                  }}
                  // checked={gradeData?.indexOf(name?.grade) > -1}
                />
                <p style={{ color: "blue", margin: "auto 5px" }}>
                  List of Files
                </p>
              </div>
              <Accordion>
                <AccordionSummary
                  style={{ backgroundColor: "#92A2E8", width: "100%" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ color: "white", marginLeft: "10px" }}>
                    Video{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Video-1</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Video-2</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Video-3</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Video-4</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>video-5</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  style={{ backgroundColor: "#92A2E8", width: "100%" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ color: "white", marginLeft: "10px" }}>
                    Document{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Book-1</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Book-2</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Book-3</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>Book-4</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
                <AccordionDetails
                  className={fileApprovalStyles.accordion_detail}
                >
                  <Typography>video-5</Typography>
                  <i className="fa-solid fa-trash-can"></i>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div
            style={{
              width: "70%",
              marginLeft: "20px",
              boxShadow: "0px 2px 3px #00000029",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "#D9DCFE",
                height: "48px",
              }}
            >
              <p style={{ color: "blue", margin: "auto 15px" }}>
                Preview Video-8
              </p>
            </div>
            <div
              style={{
                backgroundColor: "#ECEAEA",
                position: "relative",
                textAlign: "center",
                height: "85%",
                paddingTop: "156px",
              }}
            >
              <i
                style={{
                  color: "orange",
                  display: "flex",
                  fontSize: "66px",
                  justifyContent: "center",
                }}
                className="far fa-play-circle"
              ></i>
              <input
                style={{
                  position: "absolute",
                  bottom: "25px",
                  width: "60%",
                  left: "21%",
                  accentColor: "#FD8C00",
                }}
                type="range"
              />
            </div>
          </div>
        </div>

        {/* content end */}
      </div>
    </>
  );
};

export default TrainingApprovalAid;
