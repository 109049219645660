import axios from "axios";

async function CsvFile(templateName,setCsvData) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/misc/templates?templateType=${templateName}`,
    {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      if(response.data.data){
        setCsvData(response.data.data)
        // console.log(response.data.data,"csv");
       window.open(response.data.data)
      }


    })
    .catch((err)=>{
      //console.log(err)
     })
}

export default CsvFile;
