import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { MenuProps } from "../Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "chartjs-plugin-datalabels";
import GetLastModifiedAt from "../../API/token-controller/GetLastModifiedAt";
import axios from "axios";
import DarkYellowButton from "../../../components/common/DarkYellowButton";
import ViewImage from "../../../components/common/ViewImage";
import StudentWisePerformance from "./PerformanceWiseReports/StudentWisePerformance";
import QuestionWisePerformance from "./PerformanceWiseReports/QuestionWisePerformance";
import TopBar from "../../../components/common/TopBar";
import "../../css/switch.css";
import QuizOverview from "./PerformanceWiseReports/QuizOverview";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import {
  studentPerformanceData,
  accordionData,
  graphData,
  studentPerformanceDataNew,
} from "./PerformanceWiseReports/dummyData";
import { CSVLink } from "react-csv";
import { isNOTNullOrUndefined } from '../../../utils/helper'
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { selectedDashboardData } from "../../../actions/dashboardTableAction";
import CommonSort from "../../js/CommonSort";
const ChapterPerformance = ({ isFormative }) => {
  // console.log("ChapterPerformance", isFormative)
  const abortController = new AbortController();
  const token = sessionStorage.getItem("token");
  const boardId = sessionStorage.getItem("boardId");
  const schoolId = sessionStorage.getItem("schoolId");
  const branchId = sessionStorage.getItem("branchId");
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [downloadReport, setDownloadReport] = useState(false);
  const [teacherId, setTeacherId] = useState("");
  const [tabsValue, setTabsValue] = useState("1");
  const [subjectList, setSubjectList] = useState([]);
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [gradeList, setGradeList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [studentPerformanceReport, setStudentPerformanceReport] = useState([]);
  const [quizOverviewData, setQuizOverviewData] = useState([]);
  const [chaptersList, setChaptersList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedChapterName, setSelectedChapterName] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [chapterConcepts, setChapterConcepts] = useState([]);
  const [questionWisePerformanceData, setQuestionWisePerformanceData] =
    useState([]);
  const [mostDifficultyQuestionData, setMostDifficultyQuestionData] = useState([]);
  useState([]);
  const [modifiedDate, setModifiedDate] = useState({});
  const [expanded, setExpanded] = useState("");
  const [intIndex, setIntIndex] = useState(0);
  const [questionExpanded, setQuestionExpanded] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const [csvReport, setCsvReport] = useState();
  const [changedFilter, setChangedFilter] = useState('nochange')
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isGraphLoading, setIsGraphLoading] = useState(false)
  const [gradeSection, setGradeSection] = useState([])
  const [studentTabData, setStudentTabData] = useState();
  const [studentTabDataError, setStudentTabDataError] = useState(null);
  const [absenteestudentTabData, setAbsenteeStudentTabData] = useState();
  const [sectionAvgData, setSectionAvgData] = useState([]);
  const [globalAvgData, setGlobalAvgData] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [questionLoader, setQuestionLoader] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const dispatch = useDispatch()


  const selectedData = useSelector((state) => state?.dashboard?.is_selected_data);

  // console.log(selectedData, "<<<< selectedData")

  const accordionChangeHandler = (e, index) => {
    let value = e;
    if (expanded === value) {
      value = "";
    } else {
      value = e;
    }
    setExpanded(value);
    setIntIndex(index);
  };

  const questionAccordionHandler = (e, index) => {
    let value = e;
    if (questionExpanded === value) {
      value = "";
    } else {
      value = e;
    }
    setQuestionExpanded(value);
    setQuestionIndex(index);
  };

  const tabsChangeHandle = (event, newValue) => {
    setTabsValue(newValue);
  };

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    var result = res?.data?.data?.teacher || {};
    sessionStorage.setItem("Basic_info", JSON.stringify(result))
    return res?.data?.data?.teacher || []
  };

  const downloadStudentPerformance = async () => {
    let report = [];
    studentPerformanceData?.map(stud => {
      let obj = {
        id: stud?.id,
        student: stud?.student,
        concept: stud?.concept,
        subConcepts: stud?.subConcepts !== stud?.subConcepts?.toString() ? stud?.subConcepts?.map(x => x?.title)?.toString() : stud?.subConcepts,
        rootConcepts: stud?.rootConcepts !== stud.rootConcepts.toString() ? stud?.rootConcepts?.map(x => x?.title)?.toString() : stud?.rootConcepts,
        marks: stud?.marks !== stud?.marks?.toString() ? stud?.marks?.map(x => x?.mark)?.toString() : stud?.marks,
        percentage: stud?.percentage,
        timeToken: stud?.timeToken[0]?.min + " min" + stud?.timeToken[0]?.second + " second",
        timePerQuestion: stud?.timePerQuestion[0]?.min + " min" + stud?.timePerQuestion[0]?.second + " second",
        attempt: moment(stud?.attempt).format('DD-MM-YYYY'),
        pq1: stud?.pq1,
        pq2: stud?.pq2,
        highestScore: stud?.highestScore
      }
      report.push(obj);
    })

    const headers = [
      { label: "S.No", key: "id" },
      { label: "Student", key: "student" },
      { label: "Concept", key: "concept" },
      { label: "Sub Concept", key: "subConcepts" },
      { label: "Root Concept", key: "rootConcepts" },
      { label: "Marks", key: "marks" },
      { label: "Percentage", key: "percentage" },
      { label: "Time Taken(min)", key: "timeToken" },
      { label: "Avg.Time per Question", key: "timePerQuestion" },
      { label: "Attempt on", key: "attempt" },
      { label: "PQ 1 Attemp", key: "pq1" },
      { label: "PQ 2 Attemp", key: "pq2" },
      { label: "Highest Score(PQ)", key: "highestScore" },


    ];

    const csvReport = {
      data: report,
      headers: headers,
      filename: 'Studentwiseperformance.csv'
    };

    setCsvReport(csvReport);
  }

  const fetchSubjectData = async (params) => {
    // console.log("fetchSubjectData")
    const token = sessionStorage.getItem("token");
    const teacherId = sessionStorage.getItem("teacherId");

    const branchId = sessionStorage.getItem("branchId")
    var planIdd = ""
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("res?.data?.data", res?.data?.data)
      planIdd = res?.data?.data?.plans[0]?.planId
    } catch (error) {
      // console.error("Error fetching plan Data:", error);
    }



    // console.log("getsubject", token, teacherId)
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${params?.teacherId ? params.teacherId : teacherId}&gradeId=${params?.gradeId}&sectionId=${params?.sectionId}&menuName=${params?.menuName}`,
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${planIdd || sessionStorage.getItem("planId")}&gradeId=${params?.gradeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => { });
    // console.log(response);
    return response?.data?.data || [];
  };

  const fetchGradeData = async (tId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${tId}&menuName=TEACH`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchChapterNames = async ({ gradeId, subjectId, subTopicId, sectionId }) => {

    let url;

    if (subTopicId == null || subTopicId == undefined || subTopicId == '') {
      url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters?gradeId=${gradeId}&subjectId=${subjectId}&boardId=${boardId}&studentLogin=false&schoolId=${schoolId}&branchId=${branchId}&sectionId=${sectionId}`;
    } else {
      url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters?gradeId=${gradeId}&subjectId=${subjectId}&boardId=${boardId}&studentLogin=false&schoolId=${schoolId}&branchId=${branchId}&sectionId=${sectionId}&subTopicId=${subTopicId}`;
    }
    const res = await axios.get(
      url,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    res.data.data = await CommonSort(res?.data?.data || [], "chapter", false);
    return res?.data?.data || [];
  };

  const fetchAllAcademicYear = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data || [];
  };

  const fetchPerformanceReportChapterConcept = async (
    grade,
    subject,
    section,
    subTopic,
    academicYear,
    chapter
  ) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/chapters-concept-tree?teacherId=${teacherId}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchPerformanceReportStudentPerformance = async (
    grade,
    subject,
    section,
    subTopic,
    academicYear,
    chapter
  ) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/exam-performance?teacherId=${teacherId}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`,
      {
        signal: abortController.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];

  };

  const fetchPerformanceReportQuizOverview = async (
    grade,
    subject,
    section,
    subTopic,
    academicYear,
    chapter
  ) => {
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/quiz-overview-report?teacherId=${teacherId}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${academicYear}`;

    // if(subTopic === null || subTopic === undefined || subTopic === '') {
    //   url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/quiz-overview-report?teacherId=${teacherId}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${academicYear}`
    // }

    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    //Demo use only
    // let temp = res?.data?.data?.concepts;
    // let temp_obj = {
    //   caseStudy: res?.data?.data?.caseStudy,
    //   classAverage: res?.data?.data?.classAverage,
    //   concepts: [...temp, ...temp]
    // }
    // return temp_obj || [];

    return res?.data?.data || [];
  };

  const fetchQuestionWiseQuizPerformance = async (
    grade,
    subject,
    section,
    subTopic,
    academicYear,
    chapter
  ) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/questionwise-quiz-performance?teacherId=${teacherId}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchMostDifficultyQuestions = async (
    subject, grade, section, subTopicId, chapter
  ) => {
    let url;
    // console.log(teacherId, subTopicId === undefined || subTopicId === null || subTopicId === '')
    if (subTopicId === undefined || subTopicId === null || subTopicId === '') {
      url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/get-top-five-difficult-question?teacherId=${teacherId}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`
    } else {
      url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/get-top-five-difficult-question?teacherId=${teacherId?.id || teacherId}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&subtopicId=${subTopicId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`
    }
    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  }
  function convertFractionToNumber(marks) {
    if (!marks) return 0;
    const parts = marks.split("/");
    if (parts.length !== 2) {
      throw new Error("Invalid fraction format");
    }
    const numerator = parseInt(parts[0], 10);
    const denominator = parseInt(parts[1], 10);
    if (isNaN(numerator) || isNaN(denominator)) {
      throw new Error("Invalid numbers in fraction");
    }
    return numerator / denominator;
  }
  function convertTimeFormat(timeString) {
    const mins = parseInt(timeString.match(/(\d+)Mins/)[1], 10);
    const secs = parseInt(timeString.match(/(\d+)Sec/)[1], 10);
    const hours = Math.floor(mins / 60);
    const remainingMins = mins % 60;
    return `${String(hours).padStart(2, '0')}Hrs ${String(remainingMins).padStart(2, '0')}Mins ${String(secs).padStart(2, '0')}Sec`;
  }



  function parseDateString(dateString) {
    if (!dateString) return null;
    const timeParts = dateString.match(/(\d+)Hrs (\d+)Mins (\d+)Sec/);
    const hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const seconds = parseInt(timeParts[3], 10);
    return (hours * 3600) + (minutes * 60) + seconds;
  }

  const fetchStudentWisePerformanceTabData = async (grade, section, subject, subTopic, chapter) => {
    if (subTopic === undefined || subTopic === null || subTopic === '') {
      // console.log("iffffffffffffffffffffffffffffffffffffffffffffffffff")
      var item = {
        boardId: boardId,
        gradeId: grade,
        subjectId: subject,
        subtopicId: subTopic,
        chapterId: chapter,
        schoolId: schoolId,
        academicYearId: selectedAcademicYear,
        branchId: branchId,
        sectionId: section,
        teacherId: teacherId
      }
      // console.log("item,", item)

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/report/studentwise-performance`, item
          ,
          {
            signal: abortController.signal,
            headers: {
              "Content-TYpe": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("resresresres", res?.data?.data)
        const studentReports = res?.data?.data?.studentWiseReport ?? [];

        res.data.data.studentWiseReport = studentReports.map(entry => {
          // Safely split and parse the numerator and denominator
          const [numerator, denominator] = entry?.total?.split("/") || [];
          const num = parseFloat(numerator);
          const denom = parseFloat(denominator);
          // console.log(num, denom, typeof num, typeof denom,"x1x1")
          // Calculate percentage only if num and denom are valid numbers
          const percentage = (num / denom) ? Math.round((num / denom) * 100) : entry?.percentage;

          return {
            ...entry,
            percentage,
            totalNew: entry?.total ? convertFractionToNumber(entry.total) : null,
            attemptOnNew: entry?.attemptOn ? new Date(entry.attemptOn) : null,
            avgTimePerQuestionNew: (entry?.avgTimePerQuestion) ? convertTimeFormat(entry?.avgTimePerQuestion) : null,
            // entry.avgTimePerQuestion ? parseDateString(entry?.avgTimePerQuestion) : null,
          };
        });

        // console.log("Processed Reports:", res.data.data.studentWiseReport);
        // console.log("Original Data:", res?.data?.data);
        return res?.data?.data || [];
      } catch (error) {
        // console.log("error", error.response.data.error)
        var data = {}
        data.error = error?.response?.data?.error || error?.response?.data?.message;
        return data
      }
    } else {
      // console.log("elseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
      var item = {
        boardId: boardId,
        gradeId: grade,
        subjectId: subject,
        subtopicId: subTopic,
        chapterId: chapter,
        schoolId: schoolId,
        academicYearId: selectedAcademicYear,
        branchId: branchId,
        sectionId: section,
        teacherId: teacherId
      }
      // console.log("item", item)
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/report/studentwise-performance`, item
          ,
          {
            headers: {
              "Content-TYpe": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("res.data.data", res.data.data)

        const studentReports = res?.data?.data?.studentWiseReport ?? [];

        res.data.data.studentWiseReport = studentReports.map(entry => {
          // Safely split and parse the numerator and denominator
          const [numerator, denominator] = entry?.total?.split("/") || [];
          const num = parseFloat(numerator);
          const denom = parseFloat(denominator);
          // console.log(num, denom, typeof num, typeof denom,"x2x2")
          // Calculate percentage only if num and denom are valid numbers
          const percentage = (num / denom) ? Math.round((num / denom) * 100) : entry?.percentage;

          return {
            ...entry,
            percentage,
            totalNew: entry?.total ? convertFractionToNumber(entry.total) : null,
            attemptOnNew: entry?.attemptOn ? new Date(entry.attemptOn) : null,
            avgTimePerQuestionNew: (entry?.avgTimePerQuestion) ? convertTimeFormat(entry?.avgTimePerQuestion) : null,
            // entry.avgTimePerQuestion ? parseDateString(entry?.avgTimePerQuestion) : null,
          };
        });

        // console.log("Processed Reports:", res.data.data.studentWiseReport);
        // console.log("Original Data:", res?.data?.data);
        return res?.data?.data || [];
      } catch (error) {
        // console.log("error", error.response)
        var data = {}
        data.error = error?.response?.data?.error || error?.response?.data?.message;
        return data
      }
    }
  }

  const fetchSectionAvgData = async (
    grade, section, subject, chapter
  ) => {

    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/section-average-chapter-performance?boardId=${boardId}&gradeId=${grade}&subjectId=${subject}&chapterId=${chapter}&schoolId=${schoolId}&academicYearId=${selectedAcademicYear}&branchId=${branchId}&sectionId=${section}&teacherId=${teacherId}`;

    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data || [];
  };

  const fetchGlobalAvgData = async (
    grade, section, subject, chapter
  ) => {

    // let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/global-average-chapter-performance?boardId=${boardId}&gradeId=${grade}&subjectId=${subject}&chapterId=${chapter}&schoolId=${schoolId}&academicYearId=${selectedAcademicYear}&branchId=${branchId}&sectionId=${section}&teacherId=${teacherId}`;

    // const res = await axios.get(
    //   url,
    //   {
    //     signal: abortController.signal,
    //     headers: {
    //       "Content-TYpe": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    // return res?.data?.data || [];
  };

  useEffect(() => {
    firstRender();
    downloadStudentPerformance();
    return () => {
      cleanupOnUnmount()
    }
  }, [token])

  const cleanupOnUnmount = () => {
    setSelectedGrade('')
    setSelectedSection('')
    setSelectedSubject('')
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('gradeId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
    dispatch(selectedDashboardData({}))
  }

  const firstRender = async () => {
    setIsPageLoading(true)
    const teacherData = await fetchTeacherData(userName, role);

    sessionStorage.setItem('teacherId', teacherData?.id)
    sessionStorage.setItem('boardId', teacherData?.boardId)
    sessionStorage.setItem('schoolId', teacherData?.school)
    sessionStorage.setItem('branchId', teacherData?.branch)
    setTeacherId(teacherData?.id);
    // await GetLastModifiedAt(setModifiedDate)
    const gradeSectionList = await fetchGradeData(teacherData?.id)
    setGradeSection(gradeSectionList)
    const academic = await fetchAllAcademicYear()
    setAcademicYearList(academic);
    // setSelectedAcademicYear(academic[academic?.length - 1]?.id);
    setSelectedAcademicYear(academic[0]?.id);
    //auto population
    await autoPopulate(gradeSectionList, teacherData?.id)
  }

  const autoPopulate = async (gradeSectionList, teacherId) => {
    const { selectedTableGrade, selectedTableSubject, chapterId, chapter, sectionId, subTopicId } = selectedData || {};
    const preSelectedGradeId = gradeSectionList.find(o => o.id === selectedTableGrade);
    const gradeSectionFirstElement = gradeSectionList[0]
    const sectionfirstElement = gradeSectionFirstElement?.sections[0]
    let subjectParams = {
      gradeId: selectedTableGrade ? selectedTableGrade : gradeSectionFirstElement?.id,
      sectionId: sectionId ? sectionId : sectionfirstElement?.id,
      teacherId: teacherId,
      menuName: "TEACH"
    }
    var subjectListFetched = await fetchSubjectData(subjectParams);

    // console.log("b4 4-7-24", subjectListFetched);
    subjectListFetched = subjectListFetched?.filter(v => v.skilledSubject === true) || [];
    // console.log("aftr",subjectListFetched);

    const selectedTableSubtopics = subjectListFetched && subjectListFetched?.find(subj => subj.subjectId === selectedTableSubject)
    // console.log("subjectListFetched", subjectListFetched,"selectedData",selectedData)
    if (subjectListFetched?.length === 0) {
      setErrorMsg('NO SUBJECTS ARE AVAILABLE');
      setSelectedSubject('');
    } else {
      setSubjectList(subjectListFetched);
      setSelectedSubject(selectedTableSubject ? selectedTableSubject : subjectListFetched[0]?.subjectId);
      // setShowSubTopic(subjectListFetched[0].hideSubtopics == true ? true : true);
      // console.log(subjectListFetched[0].hideSubtopics == true ? false : true)
      // alert("cp")
      setErrorMsg('');
    }

    let params = {
      gradeId: selectedTableGrade ? selectedTableGrade : gradeSectionFirstElement?.id,
      subjectId: selectedTableSubject ? selectedTableSubject : subjectListFetched[0]?.subjectId,
      subTopicId: !selectedTableSubtopics?.hideSubtopics ? subTopicId ? subTopicId : subjectListFetched[0]?.subTopics[0]?.id : '',
      sectionId: sectionId ? sectionId : sectionfirstElement?.id
    }
    const chaptersfetched = await fetchChapterNames(params);
    if (chaptersfetched?.length > 0) {
      setChaptersList(chaptersfetched);
      setSelectedChapter(chapterId ? chapterId : chaptersfetched[0]?.id);
      setSelectedChapterName(chapter ? chapter : chaptersfetched[0]?.chapter);
    } else {
      setChaptersList([]);
      setSelectedChapter('');
      setSelectedChapterName("");
      setChapterName("");
      setQuizOverviewData({});
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      setStudentTabData([]);
      // console.log("setStudentTabData1")
      setStudentPerformanceReport([]);
      setQuestionWisePerformanceData({});
      setQuestionWisePerformanceData({});
    }

    setSectionList(preSelectedGradeId?.sections ? preSelectedGradeId?.sections : gradeSectionFirstElement?.sections);
    setGradeList(gradeSectionList);
    setSelectedGrade(selectedTableGrade ? selectedTableGrade : gradeSectionFirstElement?.id);
    setSelectedSection(sectionId ? sectionId : sectionfirstElement?.id);
    setSubTopicList(selectedTableSubtopics ? selectedTableSubtopics?.subTopics : subjectListFetched[0]?.subTopics);
    setSelectedSubTopic(subTopicId ? subTopicId : subjectListFetched[0]?.subTopics[0]?.id);

    sessionStorage.setItem('gradeId', selectedTableGrade ? selectedTableGrade : gradeSectionFirstElement?.id)
    sessionStorage.setItem('subjectId', selectedTableSubject ? selectedTableSubject : subjectListFetched[0]?.subjectId)
    sessionStorage.setItem('sectionId', sectionId ? sectionId : sectionfirstElement?.id)
    // console.log("check setting subtopic in session here")
    sessionStorage.setItem('subTopicId', subTopicId ? subTopicId : subjectListFetched[0]?.subTopics[0]?.id)
    setIsPageLoading(false)
  }

  {/*
  useEffect(async () => {
    if (
      isNOTNullOrUndefined(selectedGrade) &&
      isNOTNullOrUndefined(selectedSection) &&
      isNOTNullOrUndefined(selectedSubject) && changedFilter != "chapter"
    ) {
      let params = {
        subjectId: selectedSubject,
        gradeId: selectedGrade,
        subTopicId: selectedSubTopic
      }
      const chaptersfetched = await fetchChapterNames(params);
      if(chaptersfetched?.length > 0) {
        setChaptersList(chaptersfetched);
        setSelectedChapter(chaptersfetched?.length ? chaptersfetched[0]?.id : "");
      } else {
        setChaptersList([]);
        setSelectedChapter('');
        setChapterName("");
        setQuizOverviewData({});
        setChapterConcepts([]);
        setSectionAvgData([]);
        setStudentTabData([]);
        setQuestionWisePerformanceData({});
        setQuestionWisePerformanceData({});
      }
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic]);
  */}

  useEffect(async () => {
    if (!isPageLoading) {
      if (selectedGrade && selectedSubject && selectedChapter && selectedAcademicYear && tabsValue === '1') {
        // fetch overview data which contains student count in section, no. of questions, no. of case studies etc.
        const fetchReportQuizOverview = async () => {
          try {
            const report = await fetchPerformanceReportQuizOverview(
              selectedGrade,
              selectedSubject,
              selectedSection,
              selectedSubTopic,
              selectedAcademicYear,
              selectedChapter
            );
            setQuizOverviewData(report);
          } catch (err) {
            // console.log("not able to fetch quiz overview data !");
            setQuizOverviewData({});
          }
        };
        fetchReportQuizOverview();

        // fetch student list for top 5, bottom 5, absentees
        const fetchReportStudentPerformance = async () => {
          const report = await fetchPerformanceReportStudentPerformance(
            selectedGrade,
            selectedSubject,
            selectedSection,
            selectedSubTopic,
            selectedAcademicYear,
            selectedChapter
          ).catch(err => {
            // console.log("not able to fetch student performance data !")
          });
          setStudentPerformanceReport(report);
        };
        fetchReportStudentPerformance();

        // fetch chapter concepts, sub-concepts, root-concepts to display in slider.
        const fetchReport = async () => {
          try {
            const report = await fetchPerformanceReportChapterConcept(
              selectedGrade,
              selectedSubject,
              selectedSection,
              selectedSubTopic,
              selectedAcademicYear,
              selectedChapter
            );
            if (report) {
              setChapterName(
                chaptersList.find((item) => item?.id === selectedChapter)?.chapter ||
                ""
              );
            } else {
              setChapterName("");
            }
            setChapterConcepts(report);
          } catch (err) {
            setChapterName("");
            setChapterConcepts([]);
            // console.log("not able to fetch concept chart data !")
          }
        };
        fetchReport();
      }
    }

    return () => {
      abortController.abort();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic, selectedChapter, isPageLoading, tabsValue]);

  useEffect(() => {
    if (selectedGrade && selectedSubject && selectedChapter && tabsValue === '2') {
      // fetch student wise performance data
      const fetchstudentwiseTableData = async () => {
        try {
          setIsPageLoading(true);
          const report = await fetchStudentWisePerformanceTabData(
            selectedGrade,
            selectedSection,
            selectedSubject,
            selectedSubTopic,
            selectedChapter
          );
          // console.log('report data >> ', report);
          setStudentTabDataError(report)
          setStudentTabData(report?.studentWiseReport);
          // console.log("setStudentTabData2", report?.studentWiseReport)
          setAbsenteeStudentTabData(report?.absentees);
          // console.log('abs data >> ', absenteestudentTabData);
        } catch (err) {
          setStudentTabData([]);
          // console.log("setStudentTabData3", err)
          // console.log("not able to fetch student wise performance tab data !")
        } finally {
          setIsPageLoading(false);
        }
      }
      fetchstudentwiseTableData();
    }

    return () => {
      abortController.abort();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic, selectedChapter, tabsValue]);

  useEffect(async () => {
    if (!isPageLoading) {
      if (selectedGrade && selectedSubject && selectedChapter &&
        selectedAcademicYear && tabsValue === '3') {
        // fetch question wise performance report
        const fetchReportQuestionWiseQuizPerformance = async () => {
          try {
            setQuestionLoader(true);
            const report = await fetchQuestionWiseQuizPerformance(
              selectedGrade,
              selectedSubject,
              selectedSection,
              selectedSubTopic,
              selectedAcademicYear,
              selectedChapter
            );
            setQuestionWisePerformanceData(report);
            setQuestionLoader(false);
            setErrorMsg('');
          } catch (err) {
            setQuestionLoader(false);
            setQuestionWisePerformanceData({});
            setErrorMsg('NO QUESTIONS AVAILABLE');
            // console.log("not able to fetch question quiz performance !")
          }
        };
        fetchReportQuestionWiseQuizPerformance();
      } else if (selectedGrade && selectedSubject && selectedChapter &&
        selectedAcademicYear && tabsValue === '4') {
        // fetch most difficulty question report
        const fetchMostDifficultyQuestionsData = async () => {
          try {
            setQuestionLoader(true);
            const report = await fetchMostDifficultyQuestions(
              selectedSubject,
              selectedGrade,
              selectedSection,
              selectedSubTopic,
              selectedChapter
            );
            setMostDifficultyQuestionData(report);
            setQuestionLoader(false);
          } catch (err) {
            setQuestionLoader(false);
            setMostDifficultyQuestionData({});
            // console.log("not able to fetch most difficulty question!")
          }
        }
        fetchMostDifficultyQuestionsData();
      }
    }

    return () => {
      abortController.abort();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic, selectedChapter, isPageLoading, tabsValue]);

  useEffect(() => {
    if (selectedGrade && selectedSubject && selectedChapter && tabsValue === '1') {
      // fetch graph data
      const fetchSectionAvg = async () => {
        try {
          setIsGraphLoading(true);
          const report = await fetchSectionAvgData(
            selectedGrade,
            selectedSection,
            selectedSubject,
            selectedChapter
          );
          setSectionAvgData(report);
        } catch (err) {
          // console.log("not able to fetch graph data")
          setSectionAvgData([]);
        } finally {
          setIsGraphLoading(false);
        }
      };
      fetchSectionAvg();

      const fetchGlobalAvg = async () => {
        try {
          setIsGraphLoading(true);
          const report = await fetchGlobalAvgData(
            selectedGrade,
            selectedSection,
            selectedSubject,
            selectedChapter
          );
          setGlobalAvgData(report);
        } catch (err) {
          // console.log("not able to fetch graph data")
          setGlobalAvgData([]);
        } finally {
          setIsGraphLoading(false);
        }
      }
      fetchGlobalAvg();
    }

    return () => {
      abortController.abort();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic, selectedChapter, tabsValue]);

  const handleChangeClear = (filter) => {
    switch (filter) {
      case "grade":
        setSelectedGrade("");
        setSelectedSection("");
        setSelectedSubject("");
        setSelectedSubTopic("");
        setSelectedChapter("");
        break;

      case "section":
        setSelectedSection("");
        setSelectedSubject("");
        setSelectedSubTopic("");
        setSelectedChapter("");
        break;

      case "subject":
        setSelectedSubject("");
        setSelectedSubTopic("");
        setSelectedChapter("");
        break;

      case "subtopic":
        setSelectedChapter("");
        break;
    }
  }

  const handleChangeGrade = async (value) => {
    dispatch(selectedDashboardData({}))
    handleChangeClear("grade");
    setChangedFilter("grade");
    const selectedId = value;
    setSelectedGrade(selectedId)
    const sections = gradeSection.find(i => i?.id === selectedId)?.sections
    sessionStorage.setItem('gradeId', selectedId)
    setSelectedSubject('')
    setSelectedSubTopic('')
    setSectionList(sections)
    setSelectedSection(sections?.length ? sections[0]?.id : "")
    let subjectParams = {
      gradeId: value,
      sectionId: sections?.length ? sections[0]?.id : "",
      menuName: "TEACH"
    }
    var subjectsFetchList = await fetchSubjectData(subjectParams);

    // console.log("b4 4-7-24", subjectsFetchList);
    subjectsFetchList = subjectsFetchList?.filter(v => v.skilledSubject === true) || [];
    // console.log("aftr", subjectsFetchList);

    setSubjectList(subjectsFetchList)
    let subject = subjectsFetchList?.length ? subjectsFetchList[0] : [];
    if (subjectsFetchList?.length === 0) {
      setQuizOverviewData({});
      setSelectedSubject('');
      setStudentPerformanceReport({});
      setChapterName("");
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      setQuestionWisePerformanceData({});
      setMostDifficultyQuestionData({});
      // setShowSubTopic(true);
    } else {
      setSelectedSubject(subject?.subjectId);
      // setShowSubTopic(subject?.hideSubtopics == true ? true : false);
      // console.log(subject?.hideSubtopics == true ? false : true)
      // alert("======2323")
    }
    let subTopicParam;
    if (subject?.subTopics?.length > 0) {
      setSubTopicList(subject?.subTopics);
      setSelectedSubTopic(subject?.subTopics[0]?.id);
      subTopicParam = subject?.subTopics[0]?.id;
      // console.log("check setting subtopic in session here")
      sessionStorage.setItem('subTopicId', subject?.subTopics[0]?.id);
    } else {
      setSelectedSubTopic("");
      subTopicParam = "";
      setSubTopicList([]);
      setChaptersList([]);
      setSelectedChapter("");
      setSelectedChapterName("");
      sessionStorage.removeItem('subTopicId')
    }
    let params = {
      gradeId: value,
      subjectId: subject?.subjectId,
      subTopicId: subTopicParam,
      sectionId: sections[0]?.id
    }
    const chaptersfetched = await fetchChapterNames(params);
    if (chaptersfetched?.length > 0) {
      setChaptersList(chaptersfetched);
      setSelectedChapter(chaptersfetched[0]?.id);
      setSelectedChapterName(chaptersfetched[0]?.chapter);
    } else {
      setChaptersList([]);
      setSelectedChapter('');
      setSelectedChapterName('');
      setChapterName("");
      setQuizOverviewData({});
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      // console.log("setStudentTabData5")
      setStudentTabData([]);
      setStudentPerformanceReport([]);
      setQuestionWisePerformanceData({});
      setQuestionWisePerformanceData({});
    }

    sessionStorage.setItem('sectionId', sections?.length ? sections[0]?.id : "")
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSection = async value => {
    dispatch(selectedDashboardData({}))
    handleChangeClear("section");
    setChangedFilter("section");
    const secId = value
    setSelectedSection(secId)
    setSelectedSubject('')
    setSelectedSubTopic("")
    let subjectParams = {
      gradeId: selectedGrade,
      sectionId: secId,
      menuName: "TEACH"
    }
    var subjectsFetchList = await fetchSubjectData(subjectParams);

    // console.log("b4 4-7-24", subjectsFetchList);
    subjectsFetchList = subjectsFetchList?.filter(v => v.skilledSubject === true) || [];
    // console.log("aftr", subjectsFetchList);

    setSubjectList(subjectsFetchList);
    let subject = subjectsFetchList?.length ? subjectsFetchList[0] : [];

    if (subjectsFetchList?.length === 0) {
      setQuizOverviewData({});
      setSelectedSubject('');
      setStudentPerformanceReport({});
      setChapterName("");
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      setQuestionWisePerformanceData({});
      setMostDifficultyQuestionData({});
    } else {
      setSelectedSubject(subject?.subjectId);
    }
    let subTopicParam;
    if (subject?.subTopics?.length > 0) {
      setSubTopicList(subject?.subTopics)
      setSelectedSubTopic(subject?.subTopics[0]?.id);
      subTopicParam = subject?.subTopics[0]?.id;
      // console.log("check setting subtopic in session here")
      sessionStorage.setItem('subTopicId', subject?.subTopics[0]?.id)
    } else {
      setSelectedSubTopic("");
      subTopicParam = "";
      setSubTopicList([]);
      setChaptersList([]);
      setSelectedChapter("");
      setSelectedChapterName('');
      sessionStorage.removeItem('subTopicId')
    }
    let params = {
      gradeId: selectedGrade,
      subjectId: subject?.subjectId,
      subTopicId: subTopicParam,
      sectionId: value
    }
    const chaptersfetched = await fetchChapterNames(params);
    if (chaptersfetched?.length > 0) {
      setChaptersList(chaptersfetched);
      setSelectedChapter(chaptersfetched[0]?.id);
      setSelectedChapterName(chaptersfetched[0]?.chapter);
    } else {
      setChaptersList([]);
      setSelectedChapter('');
      setSelectedChapterName('');
      setChapterName("");
      setQuizOverviewData({});
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      // console.log("setStudentTabData6")
      setStudentTabData([]);
      setStudentPerformanceReport([]);
      setQuestionWisePerformanceData({});
      setQuestionWisePerformanceData({});
    }
    sessionStorage.setItem('sectionId', value)
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSubject = async value => {
    dispatch(selectedDashboardData({}))
    // console.log("value", value)
    handleChangeClear("subject");
    setChangedFilter("subject");
    const subject = subjectList?.find(
      item => item?.subjectId === value
    );
    // console.log("subject", subject)
    // setShowSubTopic(true)
    setSelectedSubject(value);
    let subTopicParam;
    if (subject?.subTopics?.length > 0) {
      setSubTopicList(subject?.subTopics)
      setSelectedSubTopic(subject?.subTopics[0]?.id);
      subTopicParam = subject?.subTopics[0]?.id;
      // console.log("check setting subtopic in session here")
      sessionStorage.setItem('subTopicId', subject?.subTopics[0]?.id)
    } else {
      setSelectedSubTopic("");
      subTopicParam = "";
      setSubTopicList([]);
      setChaptersList([]);
      setSelectedChapter("");
      setSelectedChapterName('');
      sessionStorage.removeItem('subTopicId')
    }
    let params = {
      gradeId: selectedGrade,
      subjectId: value,
      sectionId: selectedSection,
      subTopicId: subTopicParam
    }
    const chaptersfetched = await fetchChapterNames(params);
    if (chaptersfetched?.length > 0) {
      setChaptersList(chaptersfetched);
      setSelectedChapter(chaptersfetched[0]?.id);
      setSelectedChapterName(chaptersfetched[0]?.chapter);
    } else {
      setChaptersList([]);
      setSelectedChapter('');
      setSelectedChapterName('');
      setChapterName("");
      setQuizOverviewData({});
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      // console.log("setStudentTabData7")
      setStudentTabData([]);
      setStudentPerformanceReport([]);
      setQuestionWisePerformanceData({});
      setQuestionWisePerformanceData({});
    }

    sessionStorage.setItem('subjectId', value)
  }

  const handleChangeSubTopic = async value => {
    dispatch(selectedDashboardData({}))
    handleChangeClear("subtopic");
    setChangedFilter("subtopic");
    setSelectedSubTopic(value);
    let params = {
      gradeId: selectedGrade,
      subjectId: selectedSubject,
      sectionId: selectedSection,
      subTopicId: value
    }
    const chaptersfetched = await fetchChapterNames(params);
    if (chaptersfetched?.length > 0) {
      setChaptersList(chaptersfetched);
      setSelectedChapter(chaptersfetched[0]?.id);
      setSelectedChapterName(chaptersfetched[0]?.chapter);
    } else {
      setChaptersList([]);
      setSelectedChapter('');
      setSelectedChapterName('');
      setChapterName("");
      setQuizOverviewData({});
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      // console.log("setStudentTabData8")
      setStudentTabData([]);
      setStudentPerformanceReport([]);
      setQuestionWisePerformanceData({});
      setQuestionWisePerformanceData({});
    }
    sessionStorage.setItem('subTopicId', value)

  }

  const handleChapter = value => {
    setSelectedChapter(value);
    const chapter = chaptersList?.find(
      item => item?.id === value
    );
    setSelectedChapterName(chapter?.chapter);
    dispatch(selectedDashboardData({}))
  }

  const handleChangeAcademicYear = value => {
    setChangedFilter("year");
    setSelectedAcademicYear(value)
    sessionStorage.setItem('academicYearId', value)
  }

  return (
    <div
      style={{
        width: "100%",
        height: '100%',
        backgroundColor: `${tabsValue === '2'
          ? '#fff'
          : '#efeef3'}`,
        height: `${tabsValue === '1'
          ? '147vh'
          : '100%'
          }`
      }}
    >
      <TopBar
        title={"Home"}
        role={role}
        nav1={
          role === "TEACHER"
            ? "TeacherDashboard"
            : "principal/dashboard"
        }
        subTitle={"Reports"}
        subSubTitle={isFormative ? "Formal Assessment" : "Chapter Performance"}
        modifiedDate={modifiedDate}
      />
      <div
        style={{
          marginTop: "15px",
          paddingRight: "33px",
          position: "absolute",
          left: "23px",
          width: "98%",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        {/* Select component starts */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "1rem",
          }}
        >
          {/* <FormControl
            sx={{
              minWidth: "9.3vw",
              width: "150px",
              height: '32px',
              borderRadius: 150,
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                  // #fcedc2 #FD8C00
                },
                fontSize: '16px',
                // "&:hover": {
                //   backgroundColor: 'red',
                //   color: '#000000',
                // },
              }}
            >
              Select Grade
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2em'
                    },
                  }}
                />
              }
              className={dashboardContentStyles.az_blue_card}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: 'white', // Set the default background color when not selected
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: '32px',
                color: '#FD8C00'
              }}

              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedGrade}
              onChange={e => handleChangeGrade(e.target.value)}
            >
              {gradeList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.grade}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >Grade
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Grade' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedGrade}
              onChange={e => handleChangeGrade(e.target.value)}
            >
              {gradeList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.grade} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              minWidth: "9.3vw",
              width: "150px",
              borderRadius: 150,
              height: '32px',
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Section
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: 'white', // Set the default background color when not selected
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: '32px',
                color: selectedSection ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSection}
              onChange={e => handleChangeSection(e.target.value)}
            >
              {sectionList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.section}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        },
                        "&:hover": {
                          backgroundColor: 'orange',
                          color: '#000000',
                        },
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000",
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Section
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Section' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedSection}
              onChange={e => handleChangeSection(e.target.value)}
            >
              {sectionList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.section} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              minWidth: "9.3vw",
              width: "150px",
              height: '32px',
              borderRadius: 150,
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Subject
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: 'white', // Set the default background color when not selected
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: '32px',
                color: selectedSubject ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSubject}
              onChange={e => handleChangeSubject(e.target.value)}
            >
              {subjectList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.subjectId}
                    key={item?.subjectId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF",
                        // border: "2px solid orange"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                        // border: "2px solid orange"
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.subject}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        },
                        "&:hover": {
                          backgroundColor: 'orange',
                          color: '#000000',
                        },
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000",
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label">
              Subject
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Subject' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedSubject}
              onChange={e => handleChangeSubject(e.target.value)}
            >
              {subjectList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.subjectId}
                    key={item?.subjectId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.subject} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* changes mage here showSubTopic instead selectedSubTopic */}
          {showSubTopic &&
            // <FormControl
            //   sx={{
            //     borderRadius: 150,
            //     minWidth: "9.3vw",
            //     width: "150px",
            //     height: '32px',
            //   }}
            // >
            //   <InputLabel
            //     id="demo-simple-select-label"
            //     sx={{
            //       top: '-10px',
            //       '&.Mui-focused, &.MuiFormLabel-filled': {
            //         top: 0,
            //         color: "#7A7A7A"
            //       },
            //       fontSize: '14px'
            //     }}
            //   >
            //     Select Subtopic
            //   </InputLabel>
            //   <Select
            //     labelId="demo-simple-select-label"
            //     id="demo-simple-select"
            //     MenuProps={MenuProps}
            //     input={
            //       <OutlinedInput
            //         label="Tag"
            //         sx={{
            //           '& legend': {
            //             fontSize: '2em'
            //           }
            //         }}
            //       />
            //     }
            //     sx={{
            //       "&.Mui-selected": {
            //         backgroundColor: 'orange',
            //         color: "#FFFFFF"
            //       },
            //       "&.Mui-selected:hover": {
            //         backgroundColor: '#fcedc2',
            //         color: "#000000"
            //       },
            //       "&:not(.Mui-selected):hover": {
            //         backgroundColor: '#fcedc2',
            //         color: '#000000',
            //       },
            //       "&:not(.Mui-selected)": {
            //         backgroundColor: 'white', // Set the default background color when not selected
            //         color: '#000000',
            //       },
            //     }}
            //     style={{
            //       borderRadius: "150px",
            //       padding: "4px",
            //       // background: "white",
            //       height: '32px',
            //       color: selectedSubTopic ? '#FD8C00' : "#000000"
            //     }}
            //     size={"small"}
            //     IconComponent={(props) => (
            //       <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
            //     )}
            //     value={selectedSubTopic}
            //     onChange={e => handleChangeSubTopic(e.target.value)}
            //   >
            //     {subTopicList?.map((item) => {
            //       return (
            //         <MenuItem
            //           value={item?.id}
            //           key={item?.id}
            //           className={dashboardContentStyles.menu_item}
            //           sx={{
            //             "&.Mui-selected": {
            //               backgroundColor: 'orange',
            //               color: "#FFFFFF",
            //             },
            //             "&.Mui-selected:hover": {
            //               backgroundColor: '#fcedc2',
            //               color: "#000000",
            //             },
            //             "&:not(.Mui-selected):hover": {
            //               backgroundColor: '#fcedc2',
            //               color: '#000000',
            //             },
            //           }}
            //         >
            //           <ListItemText
            //             primary={item?.subTopic}
            //             sx={{
            //               overflow: 'hidden !important',
            //               textOverflow: 'ellipsis !important',
            //               '& span': {
            //                 fontSize: '14px',
            //                 textOverflow: 'ellipsis !important',
            //               }
            //             }}
            //           />
            //         </MenuItem>
            //       );
            //     })}
            //   </Select>
            // </FormControl>

            <FormControl fullWidth>
              <InputLabel
                sx={{
                  top: '-10px',
                  '&.MuiInputLabel-shrink': {
                    top: '0',
                  },
                }}
                className='dropDownLabel'
                id="demo-simple-select-label">
                Sub Topic
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                input={<OutlinedInput label='Sub Topic' />}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: '150px',
                      marginTop: '2px',
                    },
                  },
                  MenuListProps: {
                    sx: {
                      padding: '0px'
                    },
                  },
                }}
                IconComponent={props => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                )}
                sx={{
                  width: '150px',
                  height: '30px',
                  padding: '10px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e7ab3c',
                  }
                }}
                className='dropdownSelect'
                value={selectedSubTopic}
                onChange={e => handleChangeSubTopic(e.target.value)}
              >
                {subTopicList?.map((item) => {
                  return (
                    <MenuItem
                      value={item?.id}
                      key={item?.id}
                      className={dashboardContentStyles.menu_item}
                      sx={{
                        fontSize: '10px',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#e7ab3c',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                        },
                      }}
                    >
                      <ListItemText
                        primary={item?.subTopic}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          }
          {/* <FormControl
            sx={{
              borderRadius: 150,
              minWidth: "9.3vw",
              width: "150px",
              height: '32px',
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Chapter Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: 'white', // Set the default background color when not selected
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: '32px',
                color: selectedChapter ? '#FD8C00' : "#000000"
              }}

              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedChapter}
              onChange={(e) => { handleChapter(e.target.value); setChangedFilter('chapter') }}
            >
              {chaptersList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000",
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.chapter}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        },

                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Chapter
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Chapter' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                    maxHeight: '190px',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '2px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555',
                    },
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px',
                    overflowY: 'auto',
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedChapter}
              onChange={(e) => { handleChapter(e.target.value); setChangedFilter('chapter') }}
            >
              {chaptersList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.chapter}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              borderRadius: 150,
              minWidth: "9.3vw",
              width: "150px",
              height: '32px',
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Academic Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2em'
                    }
                  }}
                />
              }
              style={{
                borderRadius: "150px",
                padding: "4px",
                background: "white",
                height: '32px',
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedAcademicYear}
              onChange={e => handleChangeAcademicYear(e.target.value)}
              disabled
            >
              {academicYearList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                  >
                    <ListItemText
                      primary={item?.academicYear}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Academic Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Academic Year' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedAcademicYear}
              onChange={e => handleChangeAcademicYear(e.target.value)}
              disabled
            >
              {academicYearList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.academicYear}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {/* Select component ends */}
        {/* Tabpanel starts here to switch between quiz overview, student wise performance etc. */}
        <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
          <TabContext value={tabsValue}>
            <Box
              sx={{
                borderBottom: 2,
                borderColor: "#403E75",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TabList
                onChange={(e, value) => tabsChangeHandle(e, value)}
                aria-label="lab API tabs example"
                textColor="inherit"
                indicatorColor="secondary"
              >
                <Tab
                  label="Quiz Overview"
                  value="1"
                  sx={{
                    background: tabsValue === "1" ? "#403E75" : "",
                    color: tabsValue === "1" ? "white" : "black",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Student Wise Performance"
                  value="2"
                  sx={{
                    background: tabsValue === "2" ? "#403E75" : "",
                    color: tabsValue === "2" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Question Wise Performance"
                  value="3"
                  sx={{
                    background: tabsValue === "3" ? "#403E75" : "",
                    color: tabsValue === "3" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Top 5 Difficult Questions"
                  value="4"
                  sx={{
                    background: tabsValue === "4" ? "#403E75" : "",
                    color: tabsValue === "4" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
              </TabList>
              {tabsValue === "2" && (
                <DarkYellowButton
                  style={{ marginBottom: "5px" }}
                  onClick={() => setDownloadReport(true)}
                  disabled={studentTabData?.length === 0}
                >
                  Download Report
                </DarkYellowButton>
              )}
            </Box>
            {(tabsValue === '1' || tabsValue === '2') &&
              <Paper
                sx={{
                  width: "100%",
                  minHeight: "650px",
                  backgroundColor: `${tabsValue === '1' ? '#f7f7fc' : '#fff'}`,
                  boxShadow: '0 0 0 0'
                }}
              >
                {/* Quiz Overview panel */}
                <TabPanel value="1">
                  <QuizOverview
                    chapterConcepts={chapterConcepts}
                    chapterName={chapterName}
                    studentPerformanceReport={studentPerformanceReport}
                    quizOverviewData={quizOverviewData}
                    averageGraphData={sectionAvgData}
                    globalAvgData={globalAvgData}
                    loading={isPageLoading}
                    graphLoading={isGraphLoading}
                    selectedChapterName={selectedChapterName}
                  />
                </TabPanel>
                {/* studentwise performance panel */}

                <TabPanel value="2">
                  <StudentWisePerformance
                    data={studentTabData}
                    absenteeData={absenteestudentTabData}
                    downloadReport={downloadReport}
                    setDownloadReport={setDownloadReport}
                    loading={isPageLoading}
                    studentTabDataError={studentTabDataError}
                  />
                </TabPanel>
                {/* question wise performance panel */}
              </Paper>}
            <TabPanel value="3">
              <QuestionWisePerformance
                accordionChangeHandler={accordionChangeHandler}
                accordionData={questionWisePerformanceData}
                errorMsg={errorMsg}
                expanded={expanded}
                intIndex={intIndex}
                setSelectedImageUrl={setSelectedImageUrl}
                setOpenImageModal={setOpenImageModal}
                loading={questionLoader}
                tabsValue={tabsValue}
              />
            </TabPanel>
            {/* top 5 difficult questions panel */}
            {/* question wise performance and top 5 difficult panel designs are same so we are using same component */}
            <TabPanel value="4">
              {/* <QuestionWisePerformance
                  accordionChangeHandler={questionAccordionHandler}
                  accordionData={accordionData}
                  expanded={questionExpanded}
                  intIndex={questionIndex}
                  setSelectedImageUrl={setSelectedImageUrl}
                  setOpenImageModal={setOpenImageModal}
                /> */}

              <QuestionWisePerformance
                accordionChangeHandler={accordionChangeHandler}
                accordionData={mostDifficultyQuestionData}
                expanded={expanded}
                intIndex={intIndex}
                setSelectedImageUrl={setSelectedImageUrl}
                setOpenImageModal={setOpenImageModal}
                loading={questionLoader}
                tabsValue={tabsValue}
              />
            </TabPanel>
          </TabContext>
        </Box>
        {/* Tab panel ends here */}
      </div>
      {openImageModal && (
        <ViewImage
          open={openImageModal}
          close={() => {
            setOpenImageModal(false);
            setSelectedImageUrl("");
          }}
          url={selectedImageUrl}
        />
      )}
    </div>
  );
};
export default ChapterPerformance;
