import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Yellow100Button from "../../components/common/Yellow100Button";
import Green100Button from "../../components/common/Green100Button";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import GetOTPModal from "./GetOTPModal";
import StudentSectionModal from "./StudentSectionModal";
import axios from "axios";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import { useLocation } from "react-router-dom";
import TopBar from "../../components/common/TopBar";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const TeacherProfile = () => {
  const [value, setValue] = useState("profile");
  const [showGetOTPModal, setShowGetOTPModal] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({});
  const [currentAccessData, setCurrentAccessData] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [pageSize, setPageSize] = useState(1000);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location?.state) {
      setValue(location?.state?.value?.toString());
    } else {
      setValue("profile");
    }
  }, [location?.state]);
  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
  });
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const token = sessionStorage.getItem("token");
  const [teacherId, setTeacherId] = useState([]);

  useEffect(() => {
    // GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("branchId", res.data.data.teacher.branch);
    setTeacherId(res?.data?.data?.teacher);
    // return res?.data?.data?.teacher || [];
  };

  const fetchTeacherId = async () => {
    const id = teacherId.id;
    // console.log("abc", id);
    const res = await axios.put(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data?.teacher || [];
  };

  const updateTeacherData = async () => {
    // const id = teacherId.id;
    const data = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      dob: "",
      gender: "",
      documentUrl: null,
      firstLanguageId: "",
      secondLanguageId: "",
      admissionDate: "",
      studentCategoryId: "",
      gradeId: "",
      sectionId: "",
      branch: "",
      school: "",
      isPromoted: true,
      yearEndProcess: true,
      address: "",
      active: true,
      lmsEnv: null,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${""}`,
      // updatedData,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data?.teacher || [];
  };

  const fetchStudentDataUsingSectionId = async (sectionId) => {
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const grades = currentAccessData[0]?.grades; // Get all grades from the response
    const gradeIds = grades.map((grade) => grade.gradeId); // Extract grade IDs

    const requests = gradeIds.map((gradeId) =>
      axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageSize=${pageSize}&sortOrder=true&sectionId=${sectionId}&gradeId=${gradeId}&branchId=${branchId}&schoolId=${schoolId}&active=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );



    const responses = await Promise.all(requests); // Send multiple requests concurrently
    // console.log("responses", responses)
    var studentData = responses.flatMap((res) => res?.data?.data?.data || []);
    function parseDateString(dateString) {
      if (!dateString) return null;

      const [datePart, timePart, period] = dateString.split(' ');
      const [day, month, year] = datePart.split('-').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);

      let adjustedHours = hours;
      if (period === 'PM' && hours < 12) {
        adjustedHours += 12;
      }
      if (period === 'AM' && hours === 12) {
        adjustedHours = 0;
      }

      return new Date(year, month - 1, day, adjustedHours, minutes, seconds);
    }
    studentData = studentData?.map(entry => {
      return {
        ...entry,
        lastUnitQuizSubmissionNew: entry.lastUnitQuizSubmission ? parseDateString(entry?.lastUnitQuizSubmission) : null,
        lastLoginTimeNew: entry.lastLoginTime ? parseDateString(entry?.lastLoginTime) : null
      };
    });

    const totalElement = responses[0]?.data?.data?.totalElements;
    if (totalElement >= pageSize) {
      setPageSize(totalElement);
    }

    return studentData;
  };

  const fetchTeacherAccessData = async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/grade-access-information/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(res?.data.data, "27-6-24")
    // res.data.data = res?.data?.data?.filter(v => v.skilledSubject === true) || []
    return res?.data.data;
  };
  useEffect(async () => {
    if (userName && role) {
      const teacherData = await fetchTeacherData(userName, role);
      setTeacherDetail(teacherData);
    }
  }, []);
  useEffect(async () => {
    if (teacherId.id) {
      const AccessData = await fetchTeacherAccessData(teacherId.id);
      await setCurrentAccessData(AccessData);
    }
  }, [teacherId]);
  useEffect(async () => {
    if (selectedSectionId) {
      const student = await fetchStudentDataUsingSectionId(selectedSectionId);
      await setStudentData(student);
    }
  }, [selectedSectionId]);
  return (
    <div style={{ width: "100%" }}>
      <TopBar
        title={"Home"}
        nav1={"TeacherDashboard"}
        subSubTitle={"Teacher Profile"}
        modifiedDate={modifiedDate?.data}
      />
      <div
        style={{
          marginTop: "10px",
          paddingRight: "33px",
          position: "absolute",
          right: "15px",
          left: "15px",
          width: "100%",
        }}
      >
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={(e, v) => handleChange(e, v)}
            textColor="inherit"
            // indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                background: "white",
                height: "10px",
                top: "50px",
              },
            }}
          >
            <Tab
              value="profile"
              label="My Detail"
              sx={{
                fontWeight: 600,
                background: value === "profile" ? "white" : "",
                border: value === "profile" ? "1px solid #757575" : "",
              }}
            />
            <Tab
              value="currentAccess"
              label="Current Access"
              sx={{
                fontWeight: 600,
                background: value === "currentAccess" ? "white" : "",
                border: value === "currentAccess" ? "1px solid #757575" : "",
              }}
            />
          </Tabs>
          <TabPanel value="profile" sx={{ padding: "24px 5px" }}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                height: "670px",
              }}
            >
              <div
                style={{
                  background: "rgba(96, 91, 161, 0.81)",
                  color: "white",
                  padding: "15px",
                  borderTopLeftRadius: "7px",
                  borderTopRightRadius: "7px",
                }}
              >
                Personal Information
              </div>
              <div
                style={{
                  padding: "20px 40px",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} lg={6} md={6} sm={12}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>First Name</div>
                          <TextField
                            name={"firstName"}
                            sx={{ width: "100%" }}
                            value={teacherId.firstName || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Primary Email</div>
                          <TextField
                            name={"email"}
                            sx={{ width: "100%" }}
                            value={teacherId.email || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Date of Joining</div>
                          <TextField
                            type={"text"}
                            sx={{ width: "100%" }}
                            // value={teacherId.joinDate}
                            value={teacherId.joinDate != null && teacherId.joinDate ? (moment(teacherId.joinDate)).format('DD-MM-YYYY') : ''}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Designation</div>
                          <TextField
                            type={"text"}
                            name={"Designation"}
                            sx={{ width: "100%" }}
                            value={teacherId.designation || ""}
                            disabled
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={8} lg={6} md={6} sm={12}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Last Name</div>
                          <TextField
                            name={"lastName"}
                            sx={{ width: "100%" }}
                            value={teacherId.lastName || ""}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>Primary Phone No</div>
                          <TextField
                            name={"phoneNo"}
                            sx={{ width: "100%", borderTopLeftRadius: 0 }}
                            value={teacherId.mobile || ""}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  style={{ marginRight: "20px" }}
                                  onClick={() => setShowGetOTPModal(true)}
                                >
                                  <ModeEditOutlineOutlinedIcon
                                    style={{ color: "#FFA700" }}
                                  />
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "50%" }}>School Name</div>
                          <TextField
                            name={"hrs"}
                            sx={{ width: "100%" }}
                            value={teacherId.branchName || ""}
                            disabled
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    columnGap: "1rem",
                  }}
                >
                  <Yellow100Button
                    onClick={(e) => {
                      navigate("/dashboard/TeacherDashboard");
                    }}
                  >
                    Cancel
                  </Yellow100Button>
                  <Green100Button
                    onClick={async () => {
                      const updatedData = {
                        firstName: "",
                        lastName: "",
                        email: "",
                        mobile: "",
                        dob: "",
                        gender: "",
                        documentUrl: null,
                        firstLanguageId: "",
                        secondLanguageId: "",
                        admissionDate: "",
                        studentCategoryId: "",
                        gradeId: "",
                        sectionId: "",
                        branch: "",
                        school: "",
                        isPromoted: true,
                        yearEndProcess: true,
                        address: "",
                        active: true,
                        lmsEnv: null,
                      };
                      await updateTeacherData(updatedData);
                      await fetchTeacherData(userName, role);
                    }}
                  >
                    Save
                  </Green100Button>
                </div>
              </div>
            </Paper>
          </TabPanel>
          <TabPanel value="currentAccess" sx={{ padding: "24px 5px" }}>
            <Paper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                  height: "670px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    background: "rgba(96, 91, 161, 0.81)",
                    color: "white",
                    padding: "15px",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                >
                  Current Access Information
                </div>
                <div
                  style={{
                    padding: "20px",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    rowGap: "1rem",
                    minHeight: "595px",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {currentAccessData?.map((data) => {
                    //console.log(currentAccessData);
                    return (
                      <div
                        key={data?.subjectId}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "rgb(227 225 225 / 61%)",
                            padding: "10px",
                            fontWeight: 600,
                          }}
                          key={data?.subjectId}
                        >
                          {data?.subject}
                        </div>
                        <Box
                          sx={{
                            flexGrow: 1,
                            height: "200px",
                            overflow: "auto",
                            minHeight: "200px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {data?.grades?.map((item) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  sm={6}
                                  key={item?.gradeId}
                                >
                                  <div
                                    style={{
                                      border:
                                        "1px solid rgb(168 168 168 / 55%)",
                                      padding: "10px",
                                      borderRadius: "7px",
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: "10px",
                                      width: "100%",
                                    }}
                                  >
                                    <div>{item?.grade || "tempGrade"}</div>
                                    <Grid container spacing={2}>
                                      {item?.sections.map((sec, secIndex) => {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sm={6}
                                            key={sec.sectionId}
                                            onClick={() => {
                                              setSelectedSectionId(
                                                sec.sectionId
                                              );
                                              setShowStudentModal(true);
                                            }}
                                          >
                                            <div
                                              style={{
                                                background:
                                                  secIndex === 0
                                                    ? "rgb(244, 57, 107)"
                                                    : secIndex === 1
                                                      ? "rgb(237, 155, 0)"
                                                      : secIndex === 2
                                                        ? "#0492e2"
                                                        : "#675dc6",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                color: "white",
                                                display: "flex",
                                                flexDirection: "row",
                                                columnGap: "5px",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                fontSize: "1.04vw",
                                              }}
                                            >
                                              <div>{sec?.section}</div>
                                              <MenuBookOutlinedIcon
                                                style={{ fontSize: "1.04vw" }}
                                              />
                                              <div>{sec?.studentCount}</div>
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </TabPanel>
        </TabContext>
      </div>
      {showGetOTPModal && (
        <GetOTPModal
          open={showGetOTPModal}
          close={() => setShowGetOTPModal(false)}
          phoneNumber={teacherId.mobile}
        />
      )}
      {showStudentModal && studentData && (
        <StudentSectionModal
          open={true}
          close={() => {
            setShowStudentModal(false);
            setSelectedSectionId("");
            setStudentData([]);
          }}
          data={studentData?.length && studentData}
        />
      )}
    </div>
  );
};
export default TeacherProfile;
