import {
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import queSelectionStyles from '../../../../css/queSelectionStyles.module.css'
import axios from 'axios'
import { useSelector } from 'react-redux'

const QuestionsHead = ({ totalQues, onTaxonomyButtonClick }) => {
  // v1/api/content/quiz/calculate-taxonmy?questionIds=4028928887084262018709637dda0045%2C4028928887376b2501874ff18cd700f9&totalMarks=100

  const { selected_questions, total_selected_marks, quiz_type } = useSelector(
    state => state.quiz
  )
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [startFetching, setStartFetching] = useState(true)
  const [calculations, setCalculations] = useState([])
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setStartFetching(false)
    setAnchorEl(null)
  }

  const local_data = JSON.parse(localStorage.getItem('quiz_data'))

  const cellItemsStyle = {
    color: '#354052',
    fontSize: 15,
    padding: 0,
    paddingLeft: 20,
    paddingRight: 20
  }
  const headItemsStyle = { color: '#001AF3', fontSize: 13 }

  const TOKEN = sessionStorage.getItem('token')

  const handleClick = event => {
    setAnchorEl(event.currentTarget)

  }

 useEffect(()=>{
  const fetchData = async  ()=> {
    let endpoint = ''
    selected_questions.forEach((item, index) => endpoint + `%${item.id}`)
    let a = selected_questions.map((item, index) => {
      if (index === 0) {
        return `${item.id}`
      } else {
        return `%2C${item.id}`
      }
    })
    let joined = a.join('')
  //console.log(joined, 'endpoints')

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/calculate-taxonmy?questionIds=${joined}&totalMarks=${local_data?.score}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`
          }
        }
      )
    //console.log(response?.data?.data, 'tax0 response')

      setCalculations(response?.data?.data)
    } catch (error) {
    //console.log(error)
    }
  }
  if(anchorEl){
    fetchData()

  }
 },[anchorEl])
 

  return (
    <div
      style={{
        height: '50px',
        width: '100%',
        background: '#d9dcfe',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '20px',
        paddingRight: '20px'
      }}
    >
      <p style={{ fontSize: 14, color: '#0d26f4' }}>
        Total Ques : {totalQues ? totalQues : 0}
      </p>
      <div>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            backgroundColor: '#FD8C00',
            fontSize: 13,
            color: '#fff',
            borderRadius: 100,
            textTransform: 'capitalize'
          }}
        >
          Taxonomy
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          style={{ padding: 0 }}
          sx={{
            paddingtop:0
          }}
        >
          <div
            className={queSelectionStyles.dropdown_container}
            style={{
              width: 334,
              height: 160,
              backgroundColor: '#fff',
              borderRadius: 10,
              padding: 0,
              margin: 0
            }}
          >
            <Table sx={{ minWidth: 300 }} aria-label='simple table'>
              <TableHead style={{ backgroundColor: '#D9DCFE' }}>
                <TableRow>
                  <TableCell style={headItemsStyle}>Taxonomy</TableCell>
                  <TableCell align='right' style={headItemsStyle}>
                    Marks
                  </TableCell>
                  <TableCell align='right' style={headItemsStyle}>
                    Percentage
                  </TableCell>
                </TableRow>
              </TableHead>
              {calculations && calculations.length > 0 ? (
                <TableBody>
                  {calculations.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component='th'
                        scope='row'
                        style={cellItemsStyle}
                      >
                        {row.taxonomy}
                      </TableCell>
                      <TableCell align='right' style={cellItemsStyle}>
                        {row.marks}
                      </TableCell>
                      <TableCell align='right' style={cellItemsStyle}>
                        {row.percentage}%
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          </div>
        </Menu>
      </div>
    </div>
  )
}

export default QuestionsHead
