import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, IconButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import axios from 'axios';
const ReadingPassportAccess = (props) => {
    const { gradeList, readingPassportEditOpen, fetchSchoolStrength, type, selectedProfile } = props
    // console.log(props)
    const [typeVal, setTypeVal] = useState(type)
    const ID = sessionStorage.getItem('userId');
    const TOKEN = sessionStorage.getItem('token');

    const handleType = (event) => {
        const selectedType = event.target.value
        if (window.confirm(`Are you sure change as ${selectedType}?`)) {
            setTypeVal(selectedType == "librarian" ? "librarian" : "classTeacher");
            let requestData = {
                "userType": "user",
                "librarian": selectedType == "librarian",
                "access": {
                    "grades": []
                },
                "schoolId": sessionStorage.getItem("schoolId"),
                "branchId": sessionStorage.getItem("branchId")
            }
            // console.log("requestData",requestData)
            axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/readingPassportAccess/${selectedProfile.userId}`, requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${TOKEN}`,
                    },

                })
                .then((res) => {
                    fetchSchoolStrength()
                }).catch(err => console.log(err))

        }
    }

    return (
        <>
            <div>
                <FormControl>
                    <RadioGroup
                        row
                        defaultValue={type}
                        value={type}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="classTeacher" control={<Radio onChange={handleType} sx={{ color: 'orange', '&.Mui-checked': { color: 'orange' } }} />} label="Class Teacher" />
                        <FormControlLabel value="librarian" control={<Radio onChange={handleType} sx={{ color: 'orange', '&.Mui-checked': { color: 'orange' } }} />} label="Librarian" />
                    </RadioGroup>
                </FormControl>
                {type == "classTeacher" &&
                    <div>

                        {gradeList?.map((grade, index) => {
                            return (
                                <div key={index}>

                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            style={{ backgroundColor: "#e4e4f1" }}
                                        >
                                            <h3> {grade.grade}</h3>

                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <div style={{ textAlign: "end" }}>
                                                <IconButton
                                                    sx={{
                                                        backgroundColor: 'primary.main',
                                                        color: 'white',
                                                        '&:hover': {
                                                            backgroundColor: 'primary.dark',
                                                        },
                                                        borderRadius: '50%', // Makes the button round
                                                        width: 35, // this can adjust the width and height to make it a perfect circle
                                                        height: 35,
                                                    }}
                                                    onClick={() => readingPassportEditOpen(grade)}
                                                >
                                                    <EditIcon style={{ backgroundColor: '#1976d2', border: "1px solid" }} />
                                                </IconButton>
                                            </div>
                                            <div>

                                                {/* {getSections(grade)} */}
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        columnGap: "1rem",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    {grade?.sections?.map((sectionData, idx) => {
                                                        if (sectionData.checked) {
                                                            return (
                                                                <div key={idx}>
                                                                    {sectionData.section}
                                                                </div>
                                                            )
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </>
    )
}

export default ReadingPassportAccess;