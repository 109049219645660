import axios from "axios";

export default async function sectionDataGet(setHaveSectionData) {
  let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/section-data`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      if (resp.data.length !== 0) {
        setHaveSectionData(resp.data.data);
      }
    })
    .catch((err)=>{
      setHaveSectionData([
        { sectionData: "", sectionName: "Unable to fetch section data" },
      ]);
      // console.log(err);
    })
}
