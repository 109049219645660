import axios from "axios";
import commonsort from "../../js/CommonSort";
async function BranchControllerGet(
  setBranchData,
  setPageDetail,
  rowsInput,
  page,
  sortBy,
  sortOrder
) {
  let token = sessionStorage.getItem("token");
  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches?pageNumber=${page - 1}&pageSize=${rowsInput.rows
    }&sortBy=${sortBy || "id"}&order=${sortOrder || false}&schoolId=${sessionStorage.getItem("schoolid")}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async(response) => {
      if (response.data.data) {
        if (response.data.data)
          response.data.data.data = await commonsort(response.data.data.data || [], sortBy || "id", !sortOrder || false);
          setPageDetail(response.data.data)
        setBranchData(response.data.data.data)
      }
    })
    .catch(err => {
      // console.log(err)
    })
}

export default BranchControllerGet;
