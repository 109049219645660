import React from "react";
import axios from "axios";

async function getSubQuestions(setSubQuestions) {
    let token = sessionStorage.getItem("token");
    let parentId = sessionStorage.getItem("parentId");
    if (parentId) {
        let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${parentId}/sub-questions`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((json) => {
                if (json.data.data) {
                    setSubQuestions(json.data.data)
                }
            })
            .catch(err => console.log(err))
    }
}

export default getSubQuestions;