//
// --- ❗❕❗ FIB-> FILL IN THE BLANKS ❗❕❗ --- //
//
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { orange } from "@mui/material/colors";
// import { MathJax, MathJaxContext } from 'react-mathjax-preview';

//Css Pages
import "../../../css/Student/Student_Enrich_Quiz_FIB.css";
import ChapterStyles from "../../../css/createChapter.module.css"

import {
    Box,
    Button,
    Modal,
    FormControl,
    Typography,
    Grid,
    TextField,
    styled
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from "@mui/material/Checkbox";
import get_question from "../../../API_CONTROLLER/create-questions/get_questionbyid";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "45vw",
    // overflow: "scroll",
    overflowX: "scroll",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "4px",
    p: 4,
};

const CustomTextField = styled('div')(({ }) => ({
    width: "96%",
    boxShadow: "0px 3px 6px #00000029",
    overflow: "scroll",
    borderRadius: "2px !important",
    opacity: "1 !important",
    padding: "3%",
    background: "rgb(255 255 255 / 31%) !important",
    //     background: "rgb(255 255 255 / 31%) !important",
    // "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    //     opacity: "1 !important",
    //     background: "rgb(255 255 255 / 31%) !important",
    //     border: "1px solid #B5B6B6",
    //     borderRadius: "2px !important",
    // }
}))

const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: "3px",
    color: "#FFFFFF",
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    backgroundColor: "#2AA571",
    fontSize: '10px',
    width: 'auto',
    padding: "0.6vw 1vw",
    '&:hover': {
        backgroundColor: '#056039',
    },
}));

const CustomRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto orange',
        color: orange[600],
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: orange[800],
    },
});

function CustomRadio(props) {
    return (
        <Radio
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

//for mathml content previewer ------------------------
// const MathRenderer = ({ content }) => {
//     useEffect(() => {
//       // Ensure MathJax processes the content after it is inserted into the DOM
//       if (window.MathJax) {
//         window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
//       }
//     }, [content]);
//     return (
//       <div dangerouslySetInnerHTML={{ __html: content }} />
//     );
//   };
function Preview_FIB(props) {
   // console.log("preview fib",props);
    const [closeModal, setCloseModal] = useState(false);
    const [responseValue, setResponseValue] = useState();

    const { state } = useLocation();
    // const questionContent = sessionStorage.getItem("questioncontent");
    // const answerKeyContent = sessionStorage.getItem("answerkeycontent");
    // const optionsArray = sessionStorage.getItem("options");
    // const options = JSON.parse(optionsArray);
    let questionContent;
    let answerKeyContent;
    let options;
    let images;
   
    if (responseValue) {
       // console.log("responseValue ",responseValue)
        questionContent = responseValue.question;
        answerKeyContent = responseValue.explanation;
        options = responseValue.options[0];
        images = responseValue.imageUrl;
    }

    useEffect(() => {
        if (props.previewFromModal) {
           // console.log("happening in preview")
            get_question(props.responseValue.id, setResponseValue);
        }
    }, [props.previewFromModal]);

    // function MathMl(props) {
    //    // console.log(props.childern);
    //     let m = String.raw`${props.answerContent[index] && props.answerContent[index].htmlTag[0]}`;
    //     return <MathJax math={m} />
    // }

    const onExitButton = () => {
        // setCloseModal(true);
        props.handlePreviewModalClose();
        setResponseValue();
        props.setPreviewFromModal(false);
    }

    return (
        <>
            <Modal
                open={props.openPreviewModal ? props.openPreviewModal : state}
                onClose={props.handlePreviewModalClose ? props.handlePreviewModalClose : closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={5} sx={{ height: "42vw" }}>
                        <Grid item xs={12} md={6} lg={6} sx={{ height: "40vw", overflow: "Scroll", overflowY: "scroll", "&::-webkit-scrollbar": { width: "6px", backgroundColor: "white", position: "relative", display: "block" }, "&::-webkit-scrollbar-thumb": { borderRadius: "10px", backgroundColor: "#b4b4b4", }, "&::-webkit-scrollbar-track": { backgroundColor: "#ffffff" } }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12} lg={12}>
                                    {props.questionCode === "FILL" ?
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Fill in The Blank
                                        </Typography>
                                        :
                                        props.questionCode === "MCQ" ?
                                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                Multi Choice Question
                                            </Typography>
                                            :
                                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                Multi Select Question
                                            </Typography>
                                    }
                                        {/* <MathRenderer content={ props.previewFromModal ? questionContent : props.questionContent} /> */}
                                    <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                                        dangerouslySetInnerHTML={{ __html:props.previewFromModal ? questionContent : props.questionContent}}
                                    >
                                    </Typography>
                                    <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                </Grid>
                                {(answerKeyContent || props.explanation) ?
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Explanation
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                                            dangerouslySetInnerHTML={{ __html: props.previewFromModal ? answerKeyContent : props.explanation }}
                                        >
                                        </Typography>
                                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                    </Grid>
                                    :
                                    <>
                                    </>
                                }
                                {(props.imagePreview || props.selectedImageUrl || props?.responseValue?.imageUrl) ?
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Uploaded Image
                                        </Typography>
                                        <Typography>
                                            <img src={props.previewFromModal ? (images||props.selectedImageUrl || props?.responseValue?.imageUrl) : props.selectedImageUrl || props?.responseValue?.imageUrl} width="30%" height="200px" />
                                        </Typography>
                                    </Grid>
                                    :
                                    <>
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sx={{ height: "40vw", overflow: "Scroll", overflowY: "scroll", "&::-webkit-scrollbar": { width: "6px", backgroundColor: "white", position: "relative", display: "block" }, "&::-webkit-scrollbar-thumb": { borderRadius: "10px", backgroundColor: "#b4b4b4", }, "&::-webkit-scrollbar-track": { backgroundColor: "#ffffff" } }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                Answer
                            </Typography>
                            <Grid container spacing={4} sx={{ p: 2 }}>
                                {props.previewFromModal ?
                                    options && options.map(value => {
                                        return (
                                            props.questionCode === "FILL" ?
                                                <Grid item xs={12} mg={12} lg={12}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                            {String.fromCharCode(value.order + 64)}
                                                        </Typography>
                                                        {/* <CustomTextField id="fullWidt" disabled value={value.answerKey[0]} /> */}
                                                        <CustomTextField id="fullWidt" dangerouslySetInnerHTML={{ __html: value.htmlTag[0] }} ></CustomTextField>
                                                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                    </Box>
                                                </Grid>
                                                :
                                                props.questionCode === "MCQ" ?
                                                    <>
                                                        <div id="modal-modal-description" style={{ paddingRight: "20px", paddingTop: "40px" }} sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                            {String.fromCharCode(value)}
                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            overflow: "scroll",
                                                            minHeight: "60px",
                                                            border: "1px solid #dbdee6",
                                                            fontSize: "18px",
                                                            color: "#1b1b1b",
                                                            opacity: "0.9",
                                                            marginTop: "30px",
                                                            lineHeight: "1",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            position: "relative",
                                                            paddingLeft: "45px",
                                                        }}
                                                        >
                                                            <CustomRadio
                                                                id="fullWidth"
                                                                sx={{
                                                                    color: "white",
                                                                    position: "absolute",
                                                                    top: "10px",
                                                                    left: "10px",
                                                                    "&.Mui-checked": {
                                                                        color: "rgba(0, 0, 0, 0.26)",

                                                                    },
                                                                }}
                                                                // checked={Boolean(answerContent.find(ans => ans.order === value)?.correctAnswer)}
                                                                // value={answerContent.find(ans => ans.order === value)?.answerKey} disabled
                                                                checked={value.correctAnswer}
                                                                value={value.answerKey} disabled
                                                            />
                                                            <p dangerouslySetInnerHTML={{ __html: value.htmlTag }}></p>
                                                        </div>
                                                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                    </>
                                                    :
                                                    <>
                                                        <div id="modal-modal-description" style={{ paddingRight: "20px", paddingTop: "40px" }} sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                            {String.fromCharCode(value)}
                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            overflow: "scroll",
                                                            minHeight: "60px",
                                                            border: "1px solid #dbdee6",
                                                            fontSize: "18px",
                                                            color: "#1b1b1b",
                                                            opacity: "0.9",
                                                            marginTop: "30px",
                                                            lineHeight: "1",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            borderRadius: "5px",
                                                            position: "relative",
                                                            paddingLeft: "60px",
                                                            paddingTop: "20px",
                                                        }}
                                                        >
                                                            <Checkbox
                                                                id="fullWidth"
                                                                sx={{
                                                                    color: "#001AF3",
                                                                    position: "absolute",
                                                                    top: "10px",
                                                                    left: "10px",
                                                                    "&.Mui-checked": {
                                                                        color: "rgba(0, 0, 0, 0.26)",
                                                                    },
                                                                }}
                                                                // checked={Boolean(answerContent.find(ans => ans.order === value)?.correctAnswer)}
                                                                // value={answerContent.find(ans => ans.order === value)?.answerKey} disabled
                                                                checked={value.correctAnswer}
                                                                value={value.answerKey} disabled
                                                            />
                                                            <p dangerouslySetInnerHTML={{ __html: value.htmlTag }}></p>
                                                        </div>
                                                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                    </>
                                        )
                                    })
                                    :
                                    props.addQuestionCount && props.addQuestionCount.map((value, index) => {
                                        return (
                                            props.questionCode === "FILL" ?
                                                <Grid item xs={12} mg={12} lg={12}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                            {String.fromCharCode(index + 65)}
                                                        </Typography>
                                                        {/* <CustomTextField id="fullWidth" disabled value={props.answerContent[index] && props.answerContent[index].answerKey[0]} /> */}
                                                        {/* <MathMl> */}
                                                        {/* <MathJax> */}
                                                        <CustomTextField id="fullWidt" dangerouslySetInnerHTML={{ __html: props.answerContent[index] && props.answerContent[index].htmlTag[0] }}></CustomTextField>
                                                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                        {/* </MathJax> */}
                                                        {/* </MathMl> */}
                                                    </Box>
                                                </Grid>
                                                :
                                                props.questionCode === "MCQ" ?
                                                    <Grid item xs={12} mg={12} lg={12} style={{ display: "flex" }}>
                                                        <div id="modal-modal-description" style={{ paddingRight: "20px", paddingTop: "40px" }} sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                        {String.fromCharCode(index + 65)}
                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            overflow: "scroll",
                                                            minHeight: "60px",
                                                            border: "1px solid #dbdee6",
                                                            fontSize: "18px",
                                                            color: "#1b1b1b",
                                                            opacity: "0.9",
                                                            marginTop: "30px",
                                                            lineHeight: "1",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            position: "relative",
                                                            paddingLeft: "45px",
                                                        }}
                                                        >
                                                            <CustomRadio
                                                                id="fullWidth"
                                                                sx={{
                                                                    color: "white",
                                                                    position: "absolute",
                                                                    top: "10px",
                                                                    left: "10px",
                                                                    "&.Mui-checked": {
                                                                        color: "rgba(0, 0, 0, 0.26)",

                                                                    },
                                                                }}
                                                                checked={Boolean(props.answerContent && props.answerContent.find(ans => ans.order === value)?.correctAnswer)}
                                                                value={props.answerContent && props.answerContent.find(ans => ans.order === value)?.answerKey} disabled
                                                            />
                                                            <p dangerouslySetInnerHTML={{ __html: props.answerContent && props.answerContent.find(ans => ans.order === value)?.htmlTag }}
                                                            ></p>
                                                        </div>
                                                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                    </Grid>
                                                    :
                                                    <>
                                                        <div id="modal-modal-description" style={{ paddingRight: "20px", paddingTop: "40px" }} sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                            {String.fromCharCode(index+65)}
                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            overflow: "scroll",
                                                            minHeight: "60px",
                                                            border: "1px solid #dbdee6",
                                                            fontSize: "18px",
                                                            color: "#1b1b1b",
                                                            opacity: "0.9",
                                                            marginTop: "30px",
                                                            lineHeight: "1",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            borderRadius: "5px",
                                                            position: "relative",
                                                            paddingLeft: "60px",
                                                            paddingTop: "20px",
                                                        }}
                                                        >
                                                            <Checkbox
                                                                id="fullWidth"
                                                                sx={{
                                                                    color: "#001AF3",
                                                                    position: "absolute",
                                                                    top: "10px",
                                                                    left: "10px",
                                                                    "&.Mui-checked": {
                                                                        color: "rgba(0, 0, 0, 0.26)",
                                                                    },
                                                                }}
                                                                checked={Boolean(props.answerContent && props.answerContent.find(ans => ans.order === value)?.correctAnswer)}
                                                                value={props.answerContent && props.answerContent.find(ans => ans.order === value)?.answerKey} disabled
                                                            />
                                                            <p dangerouslySetInnerHTML={{ __html: props.answerContent && props.answerContent.find(ans => ans.order === value)?.htmlTag }}
                                                            ></p>
                                                        </div>
                                                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                    </>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton onClick={() => onExitButton()}>Exit</CustomButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        </>
    );
}

export default Preview_FIB;
