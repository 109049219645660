import axios from 'axios'

function rootlist_searchable_get(setRootConceptList,subconceptId,rootSearch){
    let token = sessionStorage.getItem("token")
    axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/all?pageSize=1000&subConceptId=${subconceptId}&search=${rootSearch.search}`,{
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        // console.log(res.data.data.data,'55555');
        setRootConceptList(res.data.data.data);
    })
    .catch(err => console.log(err))
}

export default rootlist_searchable_get