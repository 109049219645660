import axios from 'axios'

async function changePasswordPut(userName, userId, password,persona,setPSuccess) {
    let token = sessionStorage.getItem("token");
    let item = {
        userId: userId,
        password: password,
        username:userName,
        persona: persona,
      lmsEnv: process.env.REACT_APP_LMS_ENV,
    };
    let result = await axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/update-password`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
         setPSuccess(response.success);
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  export default changePasswordPut