import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import quebankStyle from "./../css/questionbank.module.css";
import fileStatusStyles from "./../css/fileStatus.module.css";
import sharestyle from "../css/shareModal.module.css"; //share modal css
import tickImage from "./../img/tick-inside-a-circle.svg";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import chapter_reason_by_id_get from "../API_CONTROLLER/chapters-controller/chapters_reason_by_id_get";
import Pagination1 from "./pagination1";
import dashHome from "../../../src/img/dashHome.svg";
import EditIconButton from "../../components/common/EditIconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import action__Edit from "../../../src/img/Edit.svg";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import action__Delete from "../../../src/img/Delete.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import CommonDeleteModal from "./CommonDeleteModal";
import queType_get from "../API_CONTROLLER/question-controller/queType_get";
import taxonomy_categorylist_get from "../API_CONTROLLER/taxonomy-controller/taxonomy_categorylist_get";
import taxonomy_get from "../API_CONTROLLER/taxonomy-controller/taxonomy_get";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { CommentsDisabledOutlined, SearchOutlined } from "@mui/icons-material";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import searchIcn from "../../../src/img/search.svg";
import PreviewModal from "./PreviewModal";
import BreadCrumbs from "../js/RevisionAdmin/General/BreadCrumbs";

const ViewAllQue = () => {
  const [gradesName, setGradesName] = useState([]);
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const token = sessionStorage.getItem("token");
  const [boardId, setBoardId] = useState("");
  const [gradeId, setGradeId] = useState("");
  // const [subjectInput, setSubjectInput] = useState("");
  const [subtopicId, setSubtopicId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [modifiedDate, setModifiedDate] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState([]);
  const [filterValueNew, setFilterValueNew] = useState([]);
  const [showModalShare, setShowModalShare] = useState(false);
  const [conceptList, setConceptList] = useState([]);
  const [selectedConceptId, setSelectedConceptId] = useState("");
  const [selectedSubConceptId, setSelectedSubConceptId] = useState("");
  const [selectedRootConceptId, setSelectedRootCoceptId] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [questionslength, setquestionsLength] = useState(0);
  const [panelExpanded, setPanelExpanded] = useState("");

  const [expanded, setExpanded] = useState({
    mainAccordion: true,
    subAccordion: true,
    rootAccordion: true,
  });

  const handleMainAccordionChange = () => {
    setExpanded({ ...expanded, mainAccordion: !expanded.mainAccordion });
  };

  const handleSubAccordionChange = () => {
    setExpanded({ ...expanded, subAccordion: !expanded.subAccordion });
  };

  const handleRootAccordionChange = () => {
    setExpanded({ ...expanded, rootAccordion: !expanded.rootAccordion });
  };

  const navigate = useNavigate();

  const handleShareModal = () => {
    setShowModalShare(true);
  };
  const [questionPreview, setQuestionPreview] = useState();
  const [currentindex, setCurrentIndex] = useState(0);
  const [imgUrl, setImgUrl] = useState();
  const [questionType, setquestiontype] = useState();
  const [queType, setQueType] = useState({});
  const [selectedTaxonomyCategory, setSelectedTaxonomyCategory] = useState("");
  const [taxonomyCategoryList, setTaxonomyCategoryList] = useState();
  const [taxonomyList, setTaxonomyList] = useState();
  const [selectedTaxonomy, setSelectedTaxonomy] = useState("");
  const [selectedQuestionType, setSelectedQuestionType] = useState("");
  const [selectedQuestionPattern, setSelectedQuestionPattern] = useState("");
  const [quePattern, setPattern] = useState(["OBJECTIVE", "SUBJECTIVE"]);
  const [subQuestions, setsubquestions] = useState([]);
  const [isSubQuestions, setSubQuestions] = useState(false);
  const showPreviewModal = (id, totalQuestions, currentquestionindex) => {
    // console.log("showPreviewModal called", id)
    setQuestionPreview({});
    //console.log(id);
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${id}?showCorrectAnswer=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        // console.log("responseresponseresponseresponse ", response)
        setCreateModal(true);
        setQuestionPreview(response?.data?.data);
        setquestionsLength(totalQuestions);
        setCurrentIndex(currentquestionindex);
        setImgUrl(response?.data?.data?.imageUrl);
        setquestiontype(response?.data?.data?.questionTypeCode);
        if (response?.data?.data?.hasSubQuestions) {
          axios
            .get(
              `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${response?.data?.data?.id}/sub-questions?showCorrectAnswer=true`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(function (response) {
              //console.log(response);
              setSubQuestions(true);
              setsubquestions(response?.data?.data);
            });
        } else {
          setsubquestions([]);
          setSubQuestions(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const closeCreateModal = () => {
    setCreateModal(false);
  };
  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState("");
  const showDeleteModal = async (v) => {
    setOpenDeleteModal(true);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/confirmation-api?id=${v.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMessage(response?.data?.data?.message);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${selectedQuestion?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        getRootConceptsQuestion();
        getConceptsQuestion();
        getSubConceptsQuestion();
        hideDeleteModal();
      })
      .catch((error) => {
        hideDeleteModal();
      });
  };
  // searching
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    e.preventDefault();
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   const search = {
  //     search: e.target.value,
  //   };
  // };
  const handleSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
  //console.log('search >> ' + value);
    if (value) {
      let result = [...filterValue];

      let temp = [...result]?.filter((data) => {
        return data?.concept?.toLowerCase().includes(value);
      });
      setConceptList(temp);
      setPage(0);
    } else {
      let result = [...filterValue];
      setConceptList(result);
      setPage(0);

    }
  };

  const handleSearchNew = async (event) => {
    let value = event?.target?.value?.toLowerCase();
  //console.log('search >> ' + value);
    if (value) {
      let result = [...filterValueNew];

      let temp = [...result]?.filter((data) => {
        return data?.question?.toLowerCase().includes(value);
      });
      // setConceptList(temp);
      setQuestions(temp);
      // setPage(0);
    } else {
      let result = [...filterValueNew];
      // setConceptList(result);
      setQuestions(result);
      // setPage(0);

    }
  };

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    taxonomy_categorylist_get(setTaxonomyCategoryList);
    return () => { };
  }, []);
  const selectSubjectHandler = (subjectId) => {
    setSubjectId(subjectId);
  //console.log(subjectId, subject);

    const selectedSubject = subject.find(val => val.id === String(subjectId));
    if (selectedSubject !== undefined) {
    //console.log(selectedSubject)
    //console.log(selectedSubject?.hideSubtopics, selectedSubject.id)
      if (selectedSubject && selectedSubject.hideSubtopics) {
        setShowSubTopic(false);
      //console.log("false")
        // alert("vwAll false");
      } else {
      //console.log("true")
        setShowSubTopic(true);
        // alert("vwAll true");
      }
    }

    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${subjectId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSubtopic(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const onChangeQuestionPattern = (e) => {
    setSelectedQuestionPattern(e.target.value);
    sessionStorage.setItem("questionPattern", e.target.value);
    queType_get(setQueType)
    const fetchQuestions = async () => {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&questionPattern=${e.target.value}&showCorrectAnswer=false`;
      if (selectedConceptId !== "") {
        URL += `&conceptId=${selectedConceptId}`
      }
      if (selectedSubConceptId !== "") {
        URL += `&subConceptId=${selectedSubConceptId}`
      }
      if (selectedRootConceptId !== "") {
        URL += `&rootConceptId=${selectedRootConceptId}`
      }
      if (selectedQuestionType !== "") {
        URL += `&questionTypeId=${selectedQuestionType}`
      }
      if (selectedTaxonomyCategory !== "") {
        URL += `&taxonomyCategoryId=${selectedTaxonomyCategory}`;
      }
      if (selectedTaxonomy !== "") {
        URL += `&taxonomyId=${selectedTaxonomy}`;
      }
      const response = await axios.get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      var temp = {
        boardId,
        gradeId,
        subjectId: subjectId,
        selectedConceptId,
        selectedSubConceptId,
        rootConceptId: selectedRootConceptId,
      };
      sessionStorage.setItem("selectedQuestion", JSON.stringify(temp));
      setQuestions(response?.data?.data?.data);
    }
    fetchQuestions();
  }

  const onChangeQuestionType = (e) => {
    setSelectedQuestionType(e.target.value);
    const fetchQuestions = async () => {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&questionTypeId=${e.target.value}&showCorrectAnswer=false`;
      if (selectedConceptId !== "") {
        URL += `&conceptId=${selectedConceptId}`
      }
      if (selectedSubConceptId !== "") {
        URL += `&subConceptId=${selectedSubConceptId}`
      }
      if (selectedRootConceptId !== "") {
        URL += `&rootConceptId=${selectedRootConceptId}`
      }
      if (selectedQuestionPattern !== "") {
        URL += `&questionPattern=${selectedQuestionPattern}`
      }
      if (selectedTaxonomyCategory !== "") {
        URL += `&taxonomyCategoryId=${selectedTaxonomyCategory}`;
      }
      if (selectedTaxonomy !== "") {
        URL += `&taxonomyId=${selectedTaxonomy}`;
      }
      const response = await axios.get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      var temp = {
        boardId,
        gradeId,
        subjectId: subjectId,
        selectedConceptId,
        selectedSubConceptId,
        rootConceptId: selectedRootConceptId,
      };
      sessionStorage.setItem("selectedQuestion", JSON.stringify(temp));
      setQuestions(response?.data?.data?.data);
    }
    fetchQuestions();
  }

  const onChangeTaxonomyCategory = (e) => {
    setSelectedTaxonomyCategory(e.target.value);
    taxonomy_get(setTaxonomyList, e.target.value, selectedQuestionType === "402881858369cb30018369d7dfd70000" ? true : false);

    const fetchQuestions = async () => {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&taxonomyCategoryId=${e.target.value}&showCorrectAnswer=false`;
      if (selectedConceptId !== "") {
        URL += `&conceptId=${selectedConceptId}`
      }
      if (selectedSubConceptId !== "") {
        URL += `&subConceptId=${selectedSubConceptId}`
      }
      if (selectedRootConceptId !== "") {
        URL += `&rootConceptId=${selectedRootConceptId}`
      }
      if (selectedQuestionPattern !== "") {
        URL += `&questionPattern=${selectedQuestionPattern}`
      }
      if (selectedQuestionType !== "") {
        URL += `&questionTypeId=${selectedQuestionType}`
      }
      if (selectedTaxonomy !== "") {
        URL += `&taxonomyId=${selectedTaxonomy}`;
      }
      const response = await axios.get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      var temp = {
        boardId,
        gradeId,
        subjectId: subjectId,
        selectedConceptId,
        selectedSubConceptId,
        rootConceptId: selectedRootConceptId,
      };
      sessionStorage.setItem("selectedQuestion", JSON.stringify(temp));
      setQuestions(response?.data?.data?.data);
    }
    fetchQuestions();
  }

  const onChangeTaxonomy = (e) => {
    setSelectedTaxonomy(e.target.value);
    const fetchQuestions = async () => {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&taxonomyId=${e.target.value}&showCorrectAnswer=false`;
      if (selectedConceptId !== "") {
        URL += `&conceptId=${selectedConceptId}`
      }
      if (selectedSubConceptId !== "") {
        URL += `&subConceptId=${selectedSubConceptId}`
      }
      if (selectedRootConceptId !== "") {
        URL += `&rootConceptId=${selectedRootConceptId}`
      }
      if (selectedQuestionPattern !== "") {
        URL += `&questionPattern=${selectedQuestionPattern}`
      }
      if (selectedQuestionType !== "") {
        URL += `&questionTypeId=${selectedQuestionType}`
      }
      if (selectedTaxonomyCategory !== "") {
        URL += `&taxonomyCategoryId=${selectedTaxonomyCategory}`;
      }
      const response = await axios.get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      var temp = {
        boardId,
        gradeId,
        subjectId: subjectId,
        selectedConceptId,
        selectedSubConceptId,
        rootConceptId: selectedRootConceptId,
      };
      sessionStorage.setItem("selectedQuestion", JSON.stringify(temp));
      setQuestions(response?.data?.data?.data);
    }
    fetchQuestions();
  }

  useEffect(() => {
    if (gradeId && boardId && subjectId) {
      fetchFilterData();
    }
  }, [boardId, gradeId, subjectId]);

  const fetchFilterData = async () => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/concept-list?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const tempArray = response?.data?.data?.data[0]?.concepts;
    for (let element of tempArray) {
      element["subTopics"] = await subConceptsfetch(element?.id);
    }

    for (let ele of tempArray) {
      for (let v of ele.subTopics) {
        v["rootConcepts"] = await rootConcepts(ele?.id, v?.id);
      }
    }
    setQuestions([]);
    setConceptList(tempArray);
    setFilterValue(tempArray);
  };

  const subConceptsfetch = async (csc) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/sub-root/concept-list?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&conceptId=${csc}`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data?.data;
  };

  const rootConcepts = async (csc, sid) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/sub-root/concept-list?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&conceptId=${csc}&subConceptId=${sid}`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data?.data;
  };

  const [questions, setQuestions] = useState([]);
  const [conceptName, setConceptName] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState();
  const getConceptsQuestion = async (elem) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&conceptId=${elem?.id}&showCorrectAnswer=false`;
    if (selectedQuestionType !== "") {
      URL += `&questionTypeId=${selectedQuestionType}`
    }
    if (selectedQuestionPattern !== "") {
      URL += `&questionPattern=${selectedQuestionPattern}`;
    }
    if (selectedTaxonomyCategory !== "") {
      URL += `&taxonomyCategoryId=${selectedTaxonomyCategory}`;
    }
    if (selectedTaxonomy !== "") {
      URL += `&taxonomyId=${selectedTaxonomy}`;
    }
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    var temp = {
      boardId,
      gradeId,
      subjectId: subjectId,
      conceptId: elem?.id,
      subConceptId: "",
      rootConceptId: "",
    };
    sessionStorage.setItem("selectedQuestion", JSON.stringify(temp));
    setConceptName(elem?.concept);
    setSelectedConceptId(elem?.id);
    setQuestions(response?.data?.data?.data);
    setFilterValueNew(response?.data?.data?.data);
  };

  const getSubConceptsQuestion = async (conceptId, elem) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&conceptId=${conceptId}&subConceptId=${elem?.id}&showCorrectAnswer=false`;
    if (selectedQuestionType !== "") {
      URL += `&questionTypeId=${selectedQuestionType}`
    }
    if (selectedQuestionPattern !== "") {
      URL += `&questionPattern=${selectedQuestionPattern}`;
    }
    if (selectedTaxonomyCategory !== "") {
      URL += `&taxonomyCategoryId=${selectedTaxonomyCategory}`;
    }
    if (selectedTaxonomy !== "") {
      URL += `&taxonomyId=${selectedTaxonomy}`;
    }
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    var temp = {
      boardId,
      gradeId,
      subjectId: subjectId,
      conceptId,
      subConceptId: elem?.id,
      rootConceptId: "",
    };
    sessionStorage.setItem("selectedQuestion", JSON.stringify(temp));
    setConceptName(elem?.conceptName);
    setSelectedConceptId(conceptId);
    setSelectedSubConceptId(elem?.id);
    setQuestions(response?.data?.data?.data);
    setFilterValueNew(response?.data?.data?.data);
  };

  const getRootConceptsQuestion = async (conceptId, subConceptId, elem) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/view-quiz/or/listing-for-quiz-create?pageNumber=0&pageSize=1000&boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&conceptId=${conceptId}&subConceptId=${subConceptId}&rootConceptId=${elem?.id}&showCorrectAnswer=false`;
    if (selectedQuestionType !== "") {
      URL += `&questionTypeId=${selectedQuestionType}`
    }
    if (selectedQuestionPattern !== "") {
      URL += `&questionPattern=${selectedQuestionPattern}`;
    }
    if (selectedTaxonomyCategory !== "") {
      URL += `&taxonomyCategoryId=${selectedTaxonomyCategory}`;
    }
    if (selectedTaxonomy !== "") {
      URL += `&taxonomyId=${selectedTaxonomy}`;
    }
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    var temp = {
      boardId,
      gradeId,
      subjectId: subjectId,
      conceptId,
      subConceptId,
      rootConceptId: elem?.id,
    };
    sessionStorage.setItem("selectedQuestion", JSON.stringify(temp));
    setConceptName(elem?.conceptName);
    setSelectedConceptId(conceptId);
    setSelectedSubConceptId(subConceptId);
    setSelectedRootCoceptId(elem?.id);
    setQuestions(response?.data?.data?.data);
    setFilterValueNew(response?.data?.data?.data);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {/* article start  */}
        <article>
          <BreadCrumbs currentPage={"View All Questions"} />
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>QuestionBank</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update: {modifiedDate.data}
            </p>
          </div> */}
        </article>
        {/* article end  */}
        {/* drop down search row start  */}
        <div
          // className={fileStatusStyles.flex}
          style={{
            marginTop: "55px",
            marginInline: "15px",
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }
          }
        >
          <Box style={{ margin: "auto 8px 0px 2px" }} sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Board*</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Board"
                value={boardId}
                onChange={(event) => {
                  setBoardId(event.target.value);
                  setQuestions([]);
                  setGradeId(null);
                  selectSubjectHandler(null);
                }}
                style={{ background: "#fff", borderRadius: 150 }}
              //   MenuProps={MenuProps}
              >
                {/* <MenuItem value={"all"}>All</MenuItem> */}
                {board?.length > 0 ? (
                  board.map((board, i) => {
                    return (
                      <MenuItem key={i} value={board.id}

                        id={`az-revisionadmin-boarddropdown-${board.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={board.board} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Grade*</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Grade"
                value={gradeId}
                onChange={(event) => {
                  setGradeId(event.target.value);
                  setQuestions([]);
                  selectSubjectHandler(null)
                  sessionStorage.removeItem("parentId")
                }}
                style={{ background: "#fff", borderRadius: 150 }}
              //   MenuProps={MenuProps}
              >
                {/* <MenuItem value="">All</MenuItem> */}
                {gradesName?.length > 0 ? (
                  gradesName.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id}
                        id={`az-revisionadmin-gradedropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={elem.grade} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Select subject*</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={subjectId}
                label="Subject"
                onChange={(event) => {
                  selectSubjectHandler(event.target.value);
                  setQuestions([]);
                  sessionStorage.removeItem("parentId")
                }}
                style={{ background: "#fff", borderRadius: 150 }}
              //   MenuProps={MenuProps}
              >
                {/* <MenuItem value="">All</MenuItem> */}
                {subject?.length > 0 ? (
                  subject.map((subject, i) => {
                    return (
                      <MenuItem key={i} value={subject.id}
                        id={`az-revisionadmin-subjectdropdown-${subject.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={subject.subject} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          {
            showSubTopic &&

            <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">Sub Topic </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="SubTopic"
                  value={subtopicId}
                  style={{ background: "#fff", borderRadius: 150 }}
                  onChange={(e) => { setSubtopicId(e.target.value); setExpanded(false); }}
                //   MenuProps={MenuProps}/
                >
                  {/* <MenuItem value="">All</MenuItem> */}
                  {subtopic.length > 0 ? (
                    subtopic.map((subtopic, i) => {
                      return (
                        <MenuItem key={i} value={subtopic.id}
                          id={`az-revisionadmin-subtopicdropdown-${subtopic.id}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: 'orange',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#fcedc2',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}>
                          <ListItemText primary={subtopic.subTopic} />
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          }
          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">
                Question Pattern
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="SubTopic"
                value={selectedQuestionPattern}
                style={{ background: "#fff", borderRadius: 150 }}
                onChange={onChangeQuestionPattern}
              >
                {quePattern.map((elem, i) => {
                  return (
                    <MenuItem key={i} value={elem}
                      id={`az-revisionadmin-quespatterndropdown-${i}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>
                      <ListItemText primary={elem} />
                    </MenuItem>
                  );
                })
                }
              </Select>
            </FormControl>
          </Box>
        </div>
        <div
          // className={fileStatusStyles.flex}
          style={{
            marginTop: "25px",
            marginInline: "9px",
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }
          }
        >
          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">
                Question Type
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="SubTopic"
                value={selectedQuestionType}
                style={{ background: "#fff", borderRadius: 150 }}
                onChange={onChangeQuestionType}
              >
                {queType?.length > 0 ? (
                  queType.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id}
                        id={`az-revisionadmin-questypedropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={elem.questionType} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>SELECT</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">
                Taxonomy Category
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="SubTopic"
                value={selectedTaxonomyCategory}
                style={{ background: "#fff", borderRadius: 150 }}
                onChange={onChangeTaxonomyCategory}
              >
                {taxonomyCategoryList?.length > 0 ? (
                  taxonomyCategoryList.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id}
                        id={`az-revisionadmin-taxonomycategorydropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={elem.taxonomyCategory} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>SELECT</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">
                Taxonomy
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="SubTopic"
                value={selectedTaxonomy}
                style={{ background: "#fff", borderRadius: 150 }}
                onChange={onChangeTaxonomy}
              >
                {taxonomyList?.length > 0 ? (
                  taxonomyList.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id}
                        id={`az-revisionadmin-taxonomylistdropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={elem.taxonomyName} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>SELECT</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 200 }}>
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "200px !important",
                  height: "41px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  opacity: 1,
                  font: "normal normal normal 13px/20px Poppins !important",
                },
              }}
              // style={{ position: "absolute", right: "20px" }}
              type={"search"}
              placeholder={"Search...."}
              name="search"
              onChange={handleSearchNew}
              Value={search.search}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                    />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </div>
        {/* drop down search row  end  */}
        <Paper
          sx={{ width: "100%", overflow: "hidden", display: "flex" }}
          style={{ paddingTop: "15px", arginInline: "15px", marginTop: "55px" }}
        >
          <div className={quebankStyle.shadow_container}>
            {conceptList &&
              conceptList.length > 0 &&
              conceptList.map((elem, index) => (
                <li className={quebankStyle.plan_links_items} key={index}>
                  <Accordion
                    expanded={expanded.mainAccordion}
                    onChange={handleMainAccordionChange}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <a
                        className={quebankStyle.plan_container_link}
                        onClick={() => getConceptsQuestion(elem)}
                      >
                        <span className={quebankStyle.plan_link_text}>
                          {elem.concept}
                        </span>
                      </a>
                    </AccordionSummary>
                    <AccordionDetails>
                      {elem.subTopics &&
                        elem.subTopics.length > 0 &&
                        elem.subTopics.map((v, i) => (
                          <Accordion
                            key={i}
                            expanded={expanded.subAccordion}
                            onChange={handleSubAccordionChange}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <a
                                className={quebankStyle.plan_container_link}
                                onClick={() => getSubConceptsQuestion(elem?.id, v)}
                              >
                                <span className={quebankStyle.plan_link_text}>
                                  {v.conceptName}
                                </span>
                              </a>
                            </AccordionSummary>
                            <AccordionDetails>
                              {v.rootConcepts &&
                                v.rootConcepts.length > 0 &&
                                v.rootConcepts.map((va, j) => (
                                  <a
                                    key={j}
                                    className={quebankStyle.plan_container_link}
                                    onClick={() =>
                                      getRootConceptsQuestion(elem?.id, v?.id, va)
                                    }
                                  >
                                    <span className={quebankStyle.plan_link_text}>
                                      {va.conceptName}
                                    </span>
                                  </a>
                                ))}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </li>
              ))}

          </div>
          <div className={quebankStyle.shadow_container}>
            {questions.length > 0 &&
              questions.map((v, i, questions) => {
              //console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv")
                return (
                  <div>
                    <p style={{ color: "orange", margin: "10px" }}>
                      {conceptName}
                    </p>
                    <div>
                      <li className={quebankStyle.plan_links_items} style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "410px", height: "85px", overflowX: "scroll", overflowY: "scroll" }}>
                          <a className={quebankStyle.plan_container_link}>
                            <span
                              className={quebankStyle.plan_link_text}
                              style={{ whiteSpace: "nowrap" }}
                              dangerouslySetInnerHTML={{ __html: String(v.question).trim() + "   [" + v?.marks + "]" }}
                            >
                            </span>
                          </a>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <span style={{ marginLeft: "5px" }}>
                            <button
                              style={{
                                borderRadius: "20px",
                                padding: "7px 14px",
                                border: "solid 1px black",
                                backgroundColor: "snow",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                showPreviewModal(v.id, questions.length, i);
                              }}
                            >
                              Preview
                            </button>
                          </span>
                          <span
                            style={{
                              // backgroundColor: "orange",
                              borderRadius: "35px",
                              marginLeft: "5px",
                              height: "35px"
                            }}
                          >
                            {" "}
                            <EditIconButton
                              onClick={(e) => {
                                e.preventDefault();
                                if (v.questionPattern === 'SUBJECTIVE') navigate(`/dashboard/QuestionBank/Subjective/${v.id}`);
                                else navigate(`/dashboard/QuestionBank/${v.id}`);
                                sessionStorage.setItem("questiondetails", JSON.stringify(v));
                              }}
                            >
                              <Tooltip title="Edit" placement={"top"}>
                                <img src={action__Edit} alt="Edit-Btn" />
                              </Tooltip>
                            </EditIconButton>
                          </span>
                          <span style={{ marginRight: "-34px" }}>
                            <DeleteIconButton
                              onClick={() => {
                                showDeleteModal(v);
                                setSelectedQuestion(v);
                              }}
                            >
                              <Tooltip title="Remove" placement={"top"}>
                                <img src={action__Delete} alt="Dlt-Btn" />
                              </Tooltip>
                            </DeleteIconButton>
                          </span>
                        </div>
                      </li>
                    </div>
                  </div>
                );
              })}
          </div>
        </Paper>
        <div
          className={fileStatusStyles.flex_container}
          id={fileStatusStyles.modal_id}
          style={
            showModalShare
              ? { display: "flex", zIndex: "10" }
              : { display: "none" }
          }
        >
          <div
            className={fileStatusStyles.main_container}
            id={fileStatusStyles.modal_content}
          >
            <div style={{ padding: "20px 23px 25px", position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <p style={{ fontSize: "16px" }}>Chapter name</p>
                <p
                  style={{
                    marginLeft: "auto",
                    fontSize: "16px",
                    color: "black",
                    marginRight: "36px",
                  }}
                >
                  15 files Rejected
                </p>
                <p
                  onClick={handleShareModalOk}
                  style={{
                    position: "absolute",
                    right: "15px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  X
                </p>
              </div>
              <div style={{ marginTop: "20.5px" }}>
                <p
                  style={{
                    fontSize: "13px",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  Reason
                </p>
                <p style={{ fontSize: "14px", color: "black" }}>
                  {" "}
                  The href attribute requires a valid value to be accessible.
                  Provide a valid, navigable address as the href value. If you
                  cannot provide a valid href, but still need the element to
                  resemble a link, use a butto{" "}
                </p>
              </div>
              <div
                style={{ position: "absolute", left: "34%", marginTop: "12px" }}
              >
                <button
                  onClick={handleShareModalOk}
                  style={{
                    padding: "10px 32px",
                    borderRadius: "21px",
                    backgroundColor: "#FD8C00",
                    color: "white",
                    border: "none",
                  }}
                >
                  UPLOAD AGAIN
                </button>
              </div>
            </div>
          </div>
        </div>
        {
          // console.log("PreviewModal ", questionPreview)
        }
        <PreviewModal
          open={createModal}
          close={closeCreateModal}
          currentindex={currentindex}
          questionslength={questionslength}
          questionPreview={questionPreview}
          allquestions={questions}
          imgUrl={imgUrl}
          questionType={questionType}
          subQuestions={subQuestions}
          isSubQuestions={isSubQuestions}
        />
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Question"}
          deleteHandler={deleteHandler}
          deleteId={selectedQuestion?.id}
          deleteName={message}
        />
      </div >
    </>
  );
};
export default ViewAllQue;