import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import TrainingCategoryStyles from "./../css/TrainingCategory.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import Pagination1 from "./pagination1";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import training_category_controller_getall from "../API_CONTROLLER/training-category-controller/training_category_controller_getall";
import training_subcategory_controller_getall from "../API_CONTROLLER/training-category-controller/training_subcategory_controller_getall";
import training_subtraining_table_get from "../API_CONTROLLER/training-category-controller/training_subtraining_table_get";
import training_subtraining_table_post from "../API_CONTROLLER/training-category-controller/training_subtraining_table_post";
import training_category_getby_id from "../API_CONTROLLER/training-category-controller/training_category_getby_id";
import training_category_id_delete from "../API_CONTROLLER/training-category-controller/training_category_id_delete";
import trainingCat_get_modifieddate from "../API_CONTROLLER/training-category-controller/training_category_get_modifiedDate";
import sortIcon from "../../../src/pages/img/sort_1.png";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import dashHome from "../../../src/img/dashHome.svg";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";

import { SearchOutlined } from "@mui/icons-material";
import searchIcn from "../../../src/img/search.svg";

const CreateTrainingCategory = () => {
  const [modifiedDate, setModifiedDate] = useState({});
  const [traininglist, setTraininglist] = useState({});
  const [subtraininglist, setSubtraininglist] = useState({});
  const [trainingtableData, setTrainingtableData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [showModalShare, setShowModalShare] = useState(false);
  const [isAddCategory, setIsaddCategory] = useState(true);
  const [categoryInputs, setCategoryInputs] = useState({
    category: "",
    subCategories: [""],
  });

  const navigate = useNavigate();
  const prevLocation = useLocation();

  if (prevLocation.state === "edit") {
    setIsaddCategory(false);
    prevLocation.state = null;
  }

  if (prevLocation.state === "delete") {
    setShowModalShare(true);
    prevLocation.state = null;
  }

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });//should not
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleShareModal = () => {
    setShowModalShare(true);
  };
  var trainingCategoryId = sessionStorage.getItem("trainingCatId");
  const handleDeleteCategory = (e) => {
    e.preventDefault();
    training_category_id_delete(trainingCategoryId);
    navigate("/dashboard/Add%20Training%20Category");
    setShowModalShare(false);
  };

  // pagination ends

  const handleCreateTrainingCategory = () => {
    training_subtraining_table_post(categoryInputs);
    navigate("/dashboard/Add%20Training%20Category");
  };

  const handleCategoryInputs = (e) => {
    e.preventDefault();
    setCategoryInputs({
      ...categoryInputs,
      [e.target.name]: e.target.value,
    });
  };

  // search
  useEffect(() => {
    if (isAddCategory == false) {
      training_category_getby_id(setCategoryInputs);
      return () => {};
    }
  }, []);

  const [search, setSearch] = useState({ search: "" });

  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    training_subtraining_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search,sortOrder,sortBy
    );
  };

  useEffect(() => {
    if (search.search === "") {
      training_subtraining_table_get(
        setTrainingtableData,
        rowsInput,
        page,
        setPageDetail,
        search,sortOrder,sortBy
      );
    }
    return () => {};
  }, [rowsInput, search]);

  useEffect(() => {
    training_subtraining_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search,sortOrder,sortBy
    );
    return () => {};
  }, [rowsInput, page]);

  useEffect(() => {
    training_category_controller_getall(setTraininglist);
    training_subcategory_controller_getall(setSubtraininglist);
    // trainingCat_get_modifieddate(modifiedDate);
    // training_subtraining_table_get(setTrainingtableData)
    GetLastModifiedAt(setModifiedDate);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
      <div className="az_home_navigation_section" style={{top:"65px"}}>
          <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}>
                <div style={{ display: "flex", alignItems: "flex-start"}}>
                    <p className="az_navigation_text"><span style={{position:"relative"}}>
                        <img style={{position: "absolute", top:"1px"}}
                          src={dashHome}
                          alt="no img"
                          width="18px"
                          height="18px"
                          
                        /> </span>
                        <span onClick={() => navigate("/dashboard/template")} className="az_navigation_content" style={{marginLeft:"20px"}}>Home</span>
                    </p>
                </div>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p className="az_navigation_text"><span>Content Admin</span></p>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p className="az_navigation_text"><span>Master</span></p>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p><span style={{color: "#ffa500"}}>Add category</span></p>
          </div>
          <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
        </div>

        {/* create category start */}
        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
            // minWidth: '1385px'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
              marginTop: "69px",
            }}
          >
            <div className={TrainingCategoryStyles.training_container}>
              <div className={TrainingCategoryStyles.flex}>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    width: "270px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "25px !important",
                    },
                  }}
                >
                  <TextField
                    id="demo-helper-text-aligned-no-helper"
                    label="*Category Name"
                    name="category"
                    value={categoryInputs.category}
                    onChange={handleCategoryInputs}
                  />
                </FormControl>

                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "25px !important",
                    },
                  }}
                  // sx={{ width: "16vw" }}
                >
                  <InputLabel
                    className={TrainingCategoryStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Select Sub-Category
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    //   value={gradeData}
                    //   onChange={gradeHandleChange}
                    name="subCategories"
                    input={<OutlinedInput label="Tag" />}
                    type="text"
                    value={categoryInputs.subCategories}
                    onChange={handleCategoryInputs}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {subtraininglist.length > 0 ? (
                      subtraininglist.map((elem, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={elem.subCategory}
                            className={dashboardContentStyles.menu_item}
                          >
                            <ListItemText primary={elem.subCategory} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                {/* btn */}
                <div
                  style={{
                    display: "inline",
                    position: "absolute",
                    right: "50px",
                  }}
                >
                  <WhiteAddButton
                    onClick={() => {
                      navigate("/dashboard/Add%20Training%20Category");
                    }}
                    style={{ marginRight: "12px" }}
                  >
                    BACK
                  </WhiteAddButton>
                  {isAddCategory ? (
                    <button
                      onClick={handleCreateTrainingCategory}
                      className={TrainingCategoryStyles.blue_btn}
                    >
                      CREATE
                    </button>
                  ) : (
                    <button
                      onClick={handleCreateTrainingCategory}
                      className={TrainingCategoryStyles.blue_btn}
                    >
                      UPDATE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Paper>
        {/* create category end */}

        {/* search row start  */}
        <div className={TrainingCategoryStyles.flex}>
          <TextField
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                font: "normal normal normal 13px/20px Poppins !important",
                opacity: 1,
              },
            }}
            style={{ position: "absolute", margin: "38px auto", right: "38px" }}
            type={"text"}
            placeholder={"Search...."}
            name="search"
            onChange={handleSearch}
            Value={search.search}
            //   onChange={onSearch}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    alt="no img"
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
        </div>
        {/* search row start  */}

        {/* table start  */}
        <div style={{ paddingRight: "33px" }}>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              marginTop: "126px",
              marginLeft: "15px",
            }}
          >
            <TableContainer
              sx={{
                // maxHeight: 500,
                overflow: "auto",
              }}
            >
              <Table
                // sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={TrainingCategoryStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={TrainingCategoryStyles.table_head}
                    >
                      Category Name
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("operation");
                    }}
                    />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={TrainingCategoryStyles.table_head}
                    >
                      Sub-category Name
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("operation");
                    }}
                    />
                    </TableCell>

                    <TableCell
                      align="center"
                      className={TrainingCategoryStyles.table_head}
                    >
                      Status
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("operation");
                    }}
                    />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ zIndex: "3" }}
                      className={TrainingCategoryStyles.table_head}
                    >
                      Operation
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("operation");
                    }}
                    />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainingtableData.length > 0 ? (
                    trainingtableData.map((elem, i) => {
                      return (
                        <TableRow>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                          >
                            {i + 1}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                          >
                            {elem.category}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {elem.subCategories &&
                              elem.subCategories.length > 0 ? (
                                elem?.subCategories?.map((data) => (
                                  <p>{data.subCategory},</p>
                                ))
                              ) : (
                                <p>-</p>
                              )}
                            </div>
                          </TableCell>

                          {/* active btn */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                            align="center"
                          >
                            {elem.active ? (
                              <button
                                className={TrainingCategoryStyles.green_btn}
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                className={TrainingCategoryStyles.red_btn}
                              >
                                in-Active
                              </button>
                            )}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                            style={{ textAlign: "center" }}
                          >
                            {/* <WhiteAddButton style={{backgroundColor :'transparent' , color:'black' , border:'1px solid'}}>
                        <AddCircleOutlineOutlinedIcon style={{ marginRight: "6px" ,height:'16px',width:'16px' }} />
                        Training Aids
                        </WhiteAddButton> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{
                                  color: "white",
                                  backgroundColor: "orange",
                                  height: "32px",
                                  width: "32px",
                                  borderRadius: "50%",
                                  margin: "auto 7px",
                                }}
                              ></i>
                              <div className={TrainingCategoryStyles.icon}>
                                <div className={TrainingCategoryStyles.tooltip}>
                                  <p style={{ fontSize: "13px" }}>Remove</p>
                                </div>
                                <span>
                                  <i className="fa-solid fa-trash-can"></i>
                                </span>
                              </div>
                            </div>
                            {/* <WhiteAddButton style={{backgroundColor :'transparent', color:'black' , border:'1px solid'}}>
                        <AddCircleOutlineOutlinedIcon style={{ marginRight: "6px",height:'16px',width:'16px' }} />
                        Add Student Files
                        </WhiteAddButton> */}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}

                  {/* {filterValue
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className={TrainingCategoryStyles.table_cell}
                        component="th"
                        scope="row"
                        style={{ fontSize: "15px" }}
                      >
                        {page * rowsPerPage + (index + 1)}
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: "15px" }}>
                        {row?.role}
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          <Switch
                            checked={row?.active}
                            onChange={(e) => switchHandler(row?.id, e)}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <EditIconButton onClick={() => updatedRoleHandler(row)}>
                          <Tooltip title="Edit" placement={"top"}>
                            <EditOutlinedIcon style={{ fontSize: "25px" }} />
                          </Tooltip>
                        </EditIconButton>
                        <DeleteIconButton onClick={() => handleOpen(row)}>
                          <Tooltip title="Remove" placement={"top"}>
                            <DeleteOutlinedIcon style={{ fontSize: "25px" }} />
                          </Tooltip>
                        </DeleteIconButton>
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filterValue?.length || 0}
            page={page}
            // onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            // onRowsPerPageChange={handleRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          /> */}
            {/* pagination container - Start */}
            <div
              className={TrainingCategoryStyles.pagination}
              style={{ width: "100%" }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={TrainingCategoryStyles.rows}>
                  <label htmlFor="rows">Rows per page</label>
                  <select
                  className="cursorPointers"
                    name="rows"
                    id="rows"
                    value={rowsInput.rows}
                    onChange={handlerRowsInputs}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </span>
              </form>
              <span>
                <Pagination1
                  pageDetail={pageDetail}
                  handleChange={handleChange}
                />
              </span>
            </div>
          </Paper>
        </div>
        {/* table start  */}

        {/* own modal start  */}

        <div
          className={TrainingCategoryStyles.flex_container}
          id={TrainingCategoryStyles.modal_id}
          style={
            showModalShare
              ? { display: "flex", zIndex: "10" }
              : { display: "none" }
          }
        >
          <div
            className={TrainingCategoryStyles.main_container}
            id={TrainingCategoryStyles.modal_content}
          >
            <div style={{ padding: "20px 23px 25px", position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <ReportGmailerrorredOutlinedIcon
                  style={{ color: "#FFA700", fontSize: 120 }}
                />
                {/* <p style={{fontSize : '16px'}}>Chapter name</p>
              <p style={{marginLeft : 'auto' , fontSize : '16px' , color : 'black' , marginRight : '36px'}}>15 files Rejected</p> */}
                {/* <p onClick={handleDeleteCategory} style={{position: 'absolute', right: '15px' , fontSize : '20px' , cursor:'pointer'}}>X</p> */}
              </div>
              <div style={{ marginTop: "20.5px" }}>
                {/* <p style={{fontSize : '13px'  , marginRight : 'auto' , display : 'block'}}>Reason</p> */}
                <p style={{ fontSize: "14px" }}>
                  {" "}
                  Are you sure you want to delete this Category ?{" "}
                </p>
              </div>
              {/* <div  style={{position : 'absolute' , left : '34%' , marginTop : '12px'}}>  */}
              <center>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard/Add%20Training%20Category");
                    sessionStorage.removeItem("trainingCatId");
                  }}
                  className={TrainingCategoryStyles.white_btn}
                >
                  CANCEL
                </button>
                <button
                  onClick={handleDeleteCategory}
                  style={{
                    padding: "10px 32px",
                    borderRadius: "21px",
                    margin: "auto 3px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                  }}
                >
                  DELETE
                </button>
              </center>
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* own modal end  */}
      </div>
    </>
  );
};

export default CreateTrainingCategory;
