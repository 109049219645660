import React, { useState, useEffect } from "react";
import Tokenparse from "./../css/Tokenpg36.module.css";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import User_Token_get from "./../API/token-controller/User_Token_get";
import User_Token_get_Count from "./../API/token-controller/User_Token_get_Count";
import RolesControllerGetall from "./../API_CONTROLLER/role-controller/role_controller_getall";
import Pagination1 from "./pagination1";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id"; //branch list changer
import School_name from "./school_name";
import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import studentIcn from "../../../src/img/Group 1131.svg";
import coordinatorIcn from "../../../src/img/Group 1132.svg";
import teacherIcn from "../../../src/img/Group 1133.svg";
import principleIcn from "../../../src/img/Path 4915.svg";
import ClearIcon from "@mui/icons-material/Clear";
import sortIcon from "../../../src/pages/img/sort_1.png";
import axios from "axios";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import {
  IconButton,
  TextField,
} from "@mui/material";
import moment from 'moment';
const TokenSecond = (props) => {
  const TokenList = props.TokenList;
  // const updateTokenList =props.updateTokenList;
  const navigate = useNavigate();
  const TeacherRoleList = TokenList.filter((Role) => Role.Role === "Teacher");
  const StudentRoleList = TokenList.filter((Role) => Role.Role === "Student");
  const CoordinatorRoleList = TokenList.filter(
    (Role) => Role.Role === "Coordinator"
  );
  const PrincipalRoleList = TokenList.filter(
    (Role) => Role.Role === "Principal"
  );
  // search starts
  const [search, setSearch] = useState({
    search: "",
  });
  const [pageDetail, setPageDetail] = useState({});
  const [tokenData, setTokenDta] = useState([]);
  const [tokenCount, setTokenCount] = useState({});
  const [roleName, setRoleName] = useState({});
  const [isSearch, setisSearch] = useState(true);
  const [totalele, setTotalelements] = useState(0);
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    User_Token_get(
      setTokenDta,
      setPageDetail,
      page,
      rowsInput,
      search
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.role?.toLowerCase().includes(inputValue);
      });
      setTokenDta(result)
    } else {
      User_Token_get(
        setTokenDta,
        setPageDetail,
        page,
        rowsInput,
        search
      );
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };
  useEffect(() => {
    if (isSearch) {
      searchingData()
    }
  }, [])

  const searchingData = async () => {
    setisSearch(false);
    let token = sessionStorage.getItem("token");
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");
    async function fetchData() {
      try {
        const headers = {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };

        // First API call to get the total number of elements
        const result = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/tokens?pageNumber=0&pageSize=1000&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&branchId=${branchid}&schoolId=${schoolid}&search=${search.search}`,
          { headers }
        );

        const totalElements = result?.data?.data?.totalElements;

        // Second API call to fetch data based on the total elements
        const response = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/tokens?pageNumber=0&pageSize=${totalElements}&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&branchId=${branchid}&schoolId=${schoolid}&search=${search.search}`,
          { headers }
        );

        // Handle the response
      //console.log(response.data);
        const totalData = response?.data?.data?.data
        setTotalelements(totalData);
      } catch (error) {
        // Log any errors
        // console.error("Error fetching data:", error);
      }
    }

    fetchData();

  }
  // search ends

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10, //should not change scA
    roleId: "",
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  // pagination ends



  useEffect(() => {
    User_Token_get_Count(setTokenCount);
    RolesControllerGetall(setRoleName);

    return () => { };
  }, []);

  useEffect(() => {
    User_Token_get(setTokenDta, setPageDetail, page, rowsInput, search);

    return () => { };
  }, [page, rowsInput, sessionStorage.getItem("branchid")]);
  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => { };
  }, [schoolDetails]);

  //BranchController ends

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState([]);
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends

  return (
    <>
      <SchoolAdminBreadcrumb
        dashHome={dashHome}
        sectionName={'Token List'}
        date={modifiedDate}
      />
      <article className={Tokenparse.article_36}>
        <div className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>

        <div className={Tokenparse.grid_container_header_plan_36}>
          <div
            className={Tokenparse.item_plan1_36 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={Tokenparse.item_plan2_36 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={Tokenparse.item_plan3_36 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={Tokenparse.item_plan4_36 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={Tokenparse.item_plan5_36 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={Tokenparse.item_plan6_36 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/TokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={Tokenparse.item_plan7_36 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>
        <div className={Tokenparse.grid_item_36}>
          <div className={Tokenparse.column_36}>
            <span className={Tokenparse.link_icon_item1_36}>
              <img
                src={teacherIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={Tokenparse.school_data_36}>
              <span>{tokenCount.teacherCount}</span>
              <span>Teachers</span>
            </span>
          </div>
          <div className={Tokenparse.column_36}>
            <span className={Tokenparse.link_icon_item1_36}>
              <img
                src={coordinatorIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={Tokenparse.school_data_36}>
              <span>{tokenCount.coordinatorCount}</span>
              <span>Coordinators</span>
            </span>
          </div>
          <div className={Tokenparse.column_36}>
            <span className={Tokenparse.link_icon_item1_36}>
              <img
                src={principleIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={Tokenparse.school_data_36}>
              <span>{tokenCount.principalCount}</span>
              <span> Principals</span>
            </span>
          </div>
          <div className={Tokenparse.column_36}>
            <span className={Tokenparse.link_icon_item1_36}>
              {" "}
              <img
                src={studentIcn}
                style={{
                  verticalAlign: "middle",
                  fontSize: "large",
                }}
                alt=""
              />
            </span>
            <span className={Tokenparse.school_data_36}>
              <span>{tokenCount.studentCount}</span>
              <span>Students</span>
            </span>
          </div>
          <div className={Tokenparse.input_element_36}>
            <select
              className={Tokenparse.form_select_element_36 + ' ' + 'az_select' + ' ' + 'cursorPointer'}
              name="roleId"
              id="roleId"
              value={rowsInput.roleId}
              onChange={handlerRowsInputs}
            >
              <option value="">Select</option>
              {roleName.length > 0 ? (
                roleName.map((roleName) => {
                  return <option Value={roleName.id}>{roleName.role}</option>;
                })
              ) : (
                <option value="">Loading....</option>
              )}
            </select>
            {/* <label htmlFor="Role">*Role</label> */}
            <label
              htmlFor="roleId"
              className={rowsInput.roleId === "" ? Tokenparse.std_cat : "NONE"}
            >
              *Role
            </label>
            <label
              htmlFor="roleId"
              className={rowsInput.roleId !== "" ? Tokenparse.std_cat : "NONE"}
            >
              *Role
            </label>
          </div>
          <div className={Tokenparse.input_element_36}>
            <select
              className={Tokenparse.form_select_element_36 + ' ' + 'az_select' + ' ' + 'cursorPointer'}
              name="Used"
              id="Used"
              value=""
            >
              {/* <option value="Select">Select</option> */}
              <option value="">Select</option>
            </select>
            {/* <label htmlFor="Used">*Used</label> */}
            <label
              htmlFor="Used"
              className={"" === "" ? Tokenparse.std_cat : "NONE"}
            >
              *Used
            </label>
            <label
              htmlFor="Used"
              className={"" !== "" ? Tokenparse.std_cat : "NONE"}
            >
              *Used
            </label>
          </div>
        </div>

        <div className={Tokenparse.schoollist_32}>
          <div>
            <i className="fa-solid fa-house-user" aria-hidden="true"></i> Token Id
            List
          </div>

          <div className={Tokenparse.school_list_search_container_32}>
            <div>
              <TextField
                onChange={handleInputChange}
                type={"text"}
                value={query}
                placeholder={"Search anything..."}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    fontFamily: "Poppins !important",
                    fontSize: "13px",
                    marginLeft: "250px !important",
                    background: "#FFFFFF",

                    opacity: 1,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {showClear && (
                        <IconButton onClick={handleClear}>
                          <ClearIcon
                            style={{ color: "#F05262", fontSize: "1vw" }}
                          />
                        </IconButton>
                      )}
                      {!showClear && (
                        <IconButton>
                          <img
                            src={searchIcn}
                            style={{
                              verticalAlign: "top",
                              fontSize: "large",
                            }}
                            aria-hidden="true"
                          />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />

            </div>
            <div>
              <input
                className={Tokenparse.add_school_button_32}
                type="submit"
                value="Create Token"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/token");
                }}
              />
            </div>
          </div>
        </div>
        <div className={Tokenparse.section_list_table}>
          <table className={Tokenparse.table_section + ' ' + 'az_table'}>
            <thead className={Tokenparse.thead_36 + ' ' + 'az_table_head'}>
              <tr className={Tokenparse.table_head_section}>
                <th className={Tokenparse.cbse_table_head}>
                  #
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("token");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "token",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th className={Tokenparse.cbse_table_head}>
                  Token ID
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("token");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "token",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th className={Tokenparse.cbse_table_head}>
                  Role
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("role");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "role",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th
                  className={Tokenparse.cbse_table_head}
                  id={Tokenparse.Email}
                >
                  {" "}
                  Email
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("email");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "email",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th className={Tokenparse.cbse_table_head1}>
                  Multi User
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("multiUser");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "multiUser",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th className={Tokenparse.cbse_table_head2}>
                  No. of User
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("noOfUsers");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "noOfUsers",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th className={Tokenparse.cbse_table_head3}>
                  Used User Id
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("id");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "id",
                        !sortOrder
                      );
                    }}
                  />
                </th>
                <th className={Tokenparse.cbse_table_head3}>
                  Date Created
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("id");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "id",
                        !sortOrder
                      );
                    }} />
                </th>
                <th className={Tokenparse.cbse_table_head3}>
                  Expiry Date
                  <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("expiaryDate");
                      await User_Token_get(
                        setTokenDta,
                        setPageDetail,
                        page,
                        rowsInput,
                        search,
                        "expiaryDate",
                        !sortOrder
                      );
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="az_table_body">
              {tokenData.length > 0 ? (
                tokenData.map((tokenData, index) => {
                  return (
                    <tr key={index}>
                      {/* <th className={Tokenparse.cbse_checkbox_thead}><input type="checkbox" id="test2" />
                                            <label htmlFor="test2"></label></th> */}
                      <td>{(page - 1) * rowsInput.rows + (index + 1)}</td>
                      <td>
                        <div>
                          {tokenData.token}
                        </div>
                      </td>

                      <td className={Tokenparse.type_of_school}>
                        {tokenData.role}
                      </td>
                      <td className={Tokenparse.cbse_table_head1}>
                        {tokenData.email}
                      </td>
                      <td>{tokenData.multiUser}</td>
                      <td>{tokenData.noOfUsers}</td>
                      <td>
                        {" "}
                        <div className={Tokenparse.name}>
                          {tokenData.usedUserId}{" "}
                        </div>
                      </td>
                      <td>
                        {/* {tokenData.dateCreated.substring(
                          0,
                          tokenData.dateCreated.indexOf(" ")
                        )} */}
                        {moment(tokenData.dateCreated).format('DD-MM-YYYY HH:mm:ss')}
                        {/* <br /> */}
                        {/* {tokenData.dateCreated.substring(
                          tokenData.dateCreated.indexOf(" ") + 1
                        )} */}
                      </td>
                      {/* <td>{tokenData.expiaryDate}</td> */}
                      <td>{moment(tokenData.expiaryDate).format('DD-MM-YYYY')}</td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    // "white-space": "nowrap",/
                    height: "23rem",
                    // overflow: "scroll",
                    // "text-overflow": "clip",
                    // margin: "0px 10px",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
        </div>
        {/* pagination container - Start */}
        <div className={Tokenparse.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={Tokenparse.rows}>
              <label htmlFor="rows">Rows per page</label>
              <select
                className="cursorPointer"
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
      </article>
    </>
  );
};
export default TokenSecond;
