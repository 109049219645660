import React, { useEffect, useState } from 'react'
import { Box, Divider, Grid } from '@mui/material'
import axios from 'axios'
import GetLastModifiedAt from '../../../API/token-controller/GetLastModifiedAt'
import TopBar from '../../../../components/common/TopBar'
import ContentDisplay from '../common/ContentDisplay'
import { isNOTNullOrUndefined } from '../../../../utils/helper'
import { fetchAcademicYearData, fetchChapterListData, fetchGradeSectionList, fetchSubjectList, fetchTeacherData, fetchPlanDetails } from '../assignQuiz/api/fetchCalls'
import FullPageLoader from '../common/FullPageLoader'
import ChapterCard from '../teach/ChapterCard'
import FiltersBar from '../teach/FiltersBar'
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
import cdn_response_validator from "../../../../utils/CdnResponseValidator";
import { rightClickEnable, CDNBASEURL } from "../../../../../src/Serviceworker"

const StudentRevisionContentOptimised = () => {
  const [showDetail, setShowDetail] = useState([])
  const [updatedData, setUpdatedData] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [academicYearList, setAcademicYearList] = useState([])
  const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
  const [selectedSubTopic, setSelectedSubTopic] = useState('')
  const [selectedGrade, setSelectedGrade] = useState('')
  const [selectedSubject, setSelectedSubject] = useState('')
  const [showSubTopic, setShowSubTopic] = useState(true)

  const token = sessionStorage.getItem('token')
  const [modifiedDate, setModifiedDate] = useState({})
  const [showContentModal, setShowContentModal] = useState(false)
  const [selectedChapterId, setSelectedChapterId] = useState('')
  const [selectedSection, setSelectedSection] = useState('')
  const [sectionList, setSectionList] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const userName = localStorage.getItem('userName')
  const role = localStorage.getItem('role')

  const [teacherId, setTeacherId] = useState('')
  const [gradeSection, setGradeSection] = useState([])

  const CDN_URL = `https://cors-anywhere.herokuapp.com/${CDNBASEURL}/`;


  useEffect(() => {
    firstRender()
    return () => {
      cleanupOnUnmount()
    }
  }, [token])

  const cleanupOnUnmount = () => {
    setSelectedGrade('')
    setSelectedSection('')
    setSelectedSubject('')
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('gradeId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const firstRender = async () => {
    setIsPageLoading(true)
    const teacherData = await fetchTeacherData(userName, role)

    sessionStorage.setItem('teacherId', teacherData.id)
    sessionStorage.setItem('boardId', teacherData.boardId)
    sessionStorage.setItem('schoolId', teacherData.school)
    sessionStorage.setItem('branchId', teacherData.branch)
    setTeacherId(teacherData)
    // await GetLastModifiedAt(setModifiedDate)
    const gradeSectionList = await fetchGradeSectionList(teacherData.id)
    setGradeSection(gradeSectionList)
    const academic = await fetchAcademicYearData()
    setAcademicYearList(academic)
    const storedAcademicYearId = sessionStorage.getItem('academicYearId')
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId)
    } else if (academic?.length > 0) {
      setSelectedAcademicYear(academic[0].id)
      sessionStorage.setItem('academicYearId', academic[0].id)
    }
    fetchPlanDetails();
    //auto population
    await autoPopulate(gradeSectionList, teacherData.id)
  }

  const autoPopulate = async (gradeSectionList, teacherId) => {
    const gradeSectionFirstElement = gradeSectionList[0]
    const sectionfirstElement = gradeSectionFirstElement?.sections[0]
    let subjectParams = {
      gradeId: gradeSectionFirstElement?.id,
      sectionId: sectionfirstElement?.id,
      teacherId: teacherId,
      menuName: "STUDENT_REVISION",
    }
    var subjectListFetched = await fetchSubjectList(subjectParams);
  //console.log("b4", subjectListFetched);
    subjectListFetched = subjectListFetched?.filter(v => v.skilledSubject === true) || [];
  //console.log("aftr",subjectListFetched);
    setSubjectList(subjectListFetched);
    setSectionList(gradeSectionFirstElement?.sections)

    setSelectedGrade(gradeSectionFirstElement?.id)
    setSelectedSection(sectionfirstElement?.id)
    setSelectedSubject(subjectListFetched[0]?.subjectId)
    setSelectedSubTopic(subjectListFetched[0]?.subTopics[0]?.id)

    let params = {
      subjectId: subjectListFetched[0]?.subjectId,
      gradeId: gradeSectionFirstElement?.id,
      subTopicId: subjectListFetched[0]?.subTopics[0]?.id
    }
  //console.log("params", params, "subjectListFetched", subjectListFetched)
    const chaptersListFetched = await fetchChapterListData(params)
    setUpdatedData(chaptersListFetched)
    sessionStorage.setItem('gradeId', gradeSectionFirstElement?.id)
    sessionStorage.setItem('subjectId', subjectListFetched[0]?.subjectId)
    sessionStorage.setItem('sectionId', sectionfirstElement?.id)
    if (subjectListFetched[0]?.hideSubtopics) {
    //console.log("setting subtopic in session not here")
    } else {
    //console.log("setting subtopic in session here")
      sessionStorage.setItem('subTopicId', subjectListFetched[0]?.subTopics[0]?.id) //reg no subtopic
    }
    setIsPageLoading(false)
    const contentPlanDetails = await fetchContentPlanDetails();
  }


  useEffect(async () => {
    if (!isPageLoading) {
      if (
        isNOTNullOrUndefined(selectedGrade) &&
        isNOTNullOrUndefined(selectedSection) &&
        isNOTNullOrUndefined(selectedSubject)
      ) {
        let params = {
          subjectId: selectedSubject,
          gradeId: selectedGrade,
          subTopicId: selectedSubTopic
        }
      //console.log("params", params, "selectedSubject", selectedSubject)
        const chaptersfetched = await fetchChapterListData(params)
        setUpdatedData(chaptersfetched)
      }
      const contentPlanDetails = await fetchContentPlanDetails();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic])

  //////////

  const fetchDataUsingChapterId = async (chapterId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/page?pageSize=1000&sortOrder=true&reviewStatus=APPROVED&chapterId=${chapterId}&contentType=REVISION`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response?.data?.data?.data || []
  }

  const fetchDataFromCDN = async () => {

    ///new CDN call
    const response_newcontentdata = await fetch(`${CDN_URL}/teacher-CDN/teacher_content_chapter_${selectedChapterId}.json`);
    if (!response_newcontentdata.ok) {
      throw new Error('Network response was not ok');
    }
    let contentDataResponseNew = await response_newcontentdata.json();

    // console.log("contentDataResponseNew >>> ", contentDataResponseNew)

    var response_iterator = cdn_response_validator(contentDataResponseNew);

  //console.log("revision content format >>> ", response_iterator.data.documentTypesStudentRevision)

    return response_iterator.data.documentTypesStudentRevision;
  };

  const fetchContentPlanDetails = async () => {
    const planId = sessionStorage.getItem("planId");
    const bordId = sessionStorage.getItem("boardId");

    if (planId !== null) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/template-screen?planId=${planId}&boardId=${bordId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const contentPlanDetails = response?.data?.data || [];
    //console.log("planDetails >>> ", contentPlanDetails)

      const grade = contentPlanDetails?.planGrades.find(grade => grade.gradeId === selectedGrade);

      const planTemplate = grade ? grade.planTemplates.find(template => template.subjectId === selectedSubject) : null;

      const wsDownload = planTemplate ? planTemplate.wsDownload : null;
      const wbDownload = planTemplate ? planTemplate.wbDownload : null;
      localStorage.setItem("wsDownload", wsDownload);
      localStorage.setItem("wbDownload", wbDownload);
    //console.log("wsDownload,wbDownload >>> ", wsDownload, wbDownload)

      return response?.data?.data || [];
    }

  };


  useEffect(async () => {
    if (selectedChapterId) {
      const menuData = await fetchDataUsingChapterId(selectedChapterId);

      //   const menuData =  await fetchDataFromCDN();
    //console.log("menu data >>> ", menuData)


      //// CDN /////
      //    if (menuData?.length !== 0 && menuData[0]?.length !== 0) {
      //   //console.log("11111111111111 >>>",menuData)
      //    setShowDetail(menuData);
      //    setShowContentModal(true);
      //  } else {
      //  //console.log("222222222 >>>")
      //    setShowDetail([]);
      //    setShowContentModal(false);
      //  }


      /// API call
      if (menuData?.length !== 0) {
        setShowDetail(menuData[0]?.revisionDocMaterials);
        setShowContentModal(true);
      } else {
        setShowDetail([]);
      }
    }
  }, [selectedChapterId]);

  const handleChangeGrade = e => {
    const selectedId = e.target.value
    setSelectedGrade(selectedId)
    sessionStorage.setItem('gradeId', selectedId)
    const sections = gradeSection.find(i => i.id === selectedId)?.sections
    setSelectedSubject('')
    setSelectedSection('')
    setSelectedSubTopic('')
    setSectionList(sections)
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSection = async event => {
    const secId = event.target.value
    setSelectedSection(secId)
    setSelectedSubject('')
    setSelectedSubTopic("")
    let subjectParams = {
      gradeId: selectedGrade,
      sectionId: secId,
      menuName: "STUDENT_REVISION"
    }
    var subjectsFetchList = await fetchSubjectList(subjectParams);

  //console.log("b4", subjectsFetchList)
    subjectsFetchList = subjectsFetchList?.filter(v => v.skilledSubject === true) || [];
  //console.log("aftr",subjectsFetchList)

    setSubjectList(subjectsFetchList)
    sessionStorage.setItem('sectionId', event.target.value)
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSubject = event => {
  //console.log("handle change subject", event.target.value)
    const subject = subjectList?.find(
      item => item.subjectId === event.target.value
    );
    setShowSubTopic(subject.hideSubtopics ? false : true);
  //console.log(subject)
    setSelectedSubject(event.target.value)
    if (subject?.subTopics?.length > 0) {
    //console.log("subject", subject)
      if (subject?.hideSubtopics) {
      //console.log("setting subtopic in session not here")
      } else {
      //console.log("setting subtopic in session here")
        setSelectedSubTopic(subject?.subTopics[0]?.id)//reg maths subtopic hide
        sessionStorage.setItem('subTopicId', subject?.subTopics[0]?.id)
      }

    } else {
      setSelectedSubTopic("")
      sessionStorage.removeItem('subTopicId')
    }
    sessionStorage.setItem('subjectId', event.target.value)
  }

  const handleChangeSubTopic = event => {
    setSelectedSubTopic(event.target.value)
    sessionStorage.setItem('subTopicId', event.target.value)
  }

  const handleChangeAcademicYear = event => {
    setSelectedAcademicYear(event.target.value)
    sessionStorage.setItem('academicYearId', event.target.value)
  }

  const [query, setQuery] = useState('')
  const [isfetchingList, setisfetchingList] = useState(false)

  if (isPageLoading) {
    // return <FullPageLoader /> loaderdisabled
    return null
  }
  return (
    <>
      {!showContentModal ? (
        <div style={{ width: '80%' }}>
          <TopBar
            title={'Home'}
            subSubTitle={'Student Revision'}
            nav1={"principal/dashboard"}
            modifiedDate={modifiedDate}
            role={role}
          />
          <div
            style={{
              marginTop: '10px',
              paddingRight: '33px',
              position: 'absolute',
              right: '15px',
              left: '15px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <FiltersBar
              handleChangeSection={handleChangeSection}
              subjectList={subjectList}
              gradeSection={gradeSection}
              selectedSubTopic={selectedSubTopic}
              selectedAcademicYear={selectedAcademicYear}
              selectedGrade={selectedGrade}
              handleChangeGrade={handleChangeGrade}
              handleChangeSubject={handleChangeSubject}
              handleChangeSubTopic={handleChangeSubTopic}
              handleChangeAcademicYear={handleChangeAcademicYear}
              academicYearList={academicYearList}
              searchHandler={e => setQuery(e.target.value.toLowerCase())}
              isError={isError}
              selectedSection={selectedSection}
              selectedSubject={selectedSubject}
              sectionList={sectionList}
              showSubTopic={showSubTopic}
            />
            <Divider />
            {isfetchingList ? (
              <div>Fetching ....</div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: 700,
                  width: '100%',
                  padding: '25px 20px',
                  overflow: 'auto',
                  scrollbarWidth:"thin"
                }}
              >
                {selectedGrade && selectedSection && selectedSubject ? (
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      {updatedData?.length > 0 ? (
                        updatedData
                          ?.filter(itm =>
                            itm.chapter.toLowerCase().includes(query)
                          )
                          .map((item, i) => (
                            <div
                              key={item.id}
                              onClick={() => setSelectedChapterId(item?.id)}
                              className={dashboardContentStyles.card}
                            >
                              <img
                                src={item?.thumbnailPath}
                                width={110}
                                alt={""}
                                // style={{ maxHeight: "138px" }}
                                className={dashboardContentStyles.az_quiz_card_img} />
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  color: "#423d5c",
                                }}
                              >
                                {item?.chapter}
                              </div>
                            </div>
                            // <ChapterCard
                            //   item={item}
                            //   onChapterClick={() => {
                            //     setSelectedChapterId(item?.id)
                            //   }}
                            // />
                          ))
                      ) : (
                        <div>No data for the selected filters</div>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  <div>Please Select Grade , Section and Subject</div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <ContentDisplay
          studyData={showDetail}
          menuType={'student'}
          closeModal={() => {
            setShowContentModal(false)
            setSelectedChapterId('')
            setShowDetail([])
          }}
          selectedChapterId={selectedChapterId}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          setSelectedAcademicYear={setSelectedAcademicYear}
          selectedAcademicYear={selectedAcademicYear}
          pageName={'Student Revision'}
        />
      )}
    </>
  )
}
export default StudentRevisionContentOptimised
