import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { EducationContext } from "../../../../context/quiz/EducationContext";
import useEducationData from "../../../../hooks/quiz/useEducationData";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import axios from "axios";
import icon from "../../../../img/Reports.svg";
import {	
	ListItemText,
	MenuItem
  } from "@mui/material";

function SelectSection({
	title,
	selectedSubject,
	setSelectedSubject,
	setSearchParams,
	selectedBoard,
	setSelectedBoard,
	selectedGrade,
	setSelectedGrade,
	selectedYear,
	setSelectedYear,
	selectedSubTopic,
	setSelectedSubTopic,
}) {
	const local_data = JSON.parse(localStorage.getItem("quiz_data"));
	const onSubmit = (data) => {
		// console.log(data);
	};
	const [subTopics, setSubTopic] = useState([]);

	const educationData = useContext(EducationContext);

	const ed = useEducationData();

	const [boards, setBoards] = useState([]);
	const [grades, setGrades] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [academicYears, setAcademicYears] = useState([]);
	const [showSubTopic, setShowSubTopic] = useState(true);

	// Api calls <================================================

	const TOKEN = sessionStorage.getItem("token");
	const BASE_URL = process.env.REACT_APP_SWAGGER_URL;
	const fetchBoards = () => {
		axios
			.get(`${BASE_URL}v1/api/master/boards/all`, {
				headers: {
					Authorization: `Bearer ${TOKEN}`,
				},
			})
			.then((res) => setBoards(res.data.data));
	};
	const fetchGrades = () => {
		axios
			.get(`${BASE_URL}v1/api/master/grades/all`, {
				headers: {
					Authorization: `Bearer ${TOKEN}`,
				},
			})
			.then((res) => setGrades(res.data.data));
	};
	const fetchSubjects = () => {
		axios
			.get(`${BASE_URL}v1/api/master/subjects/all`, {
				headers: {
					Authorization: `Bearer ${TOKEN}`,
				},
			})
			.then((res) => setSubjects(res.data.data));
	};
	const fetchAcademicYears = () => {
		axios
			.get(`${BASE_URL}v1/api/master/academic-years/all`, {
				headers: {
					Authorization: `Bearer ${TOKEN}`,
				},
			})
			.then((res) => setAcademicYears(res.data.data));
	};
	useEffect(() => {
		fetchBoards();
		fetchGrades();
		fetchSubjects();
		fetchAcademicYears();
	}, []);

	// api calls ends here =====================================

	// const boards = educationData.boards.data;
	// const grades = educationData.grades.data;
	// const subjects = educationData.subjects.data;
	// const academicYears = educationData.academicYears.data;

	const handleSelect = (e) => {
		// console.log(e.target.id);
		if (e.target.id === "subjects") {
			setSelectedSubject(
				() =>
					subjects.filter(
						(item) => item.subject === e.target.value
					)[0]
			);
			var selectedSub = subjects.filter((item) => item.subject === e.target.value)[0];
			setShowSubTopic(selectedSub.hideSubtopics == true ? false : true);
		} else if (e.target.id === "board") {
			// console.log("board");
			setSelectedBoard(
				() => boards.filter((item) => item.board === e.target.value)[0]
			);
		} else if (e.target.id === "grade") {
			setSelectedGrade(
				() => grades.filter((item) => item.grade === e.target.value)[0]
			);
		} else if (e.target.id === "year") {
			setSelectedYear(
				() =>
					academicYears.filter(
						(item) => item.academicYear === e.target.value
					)[0]
			);
		} else if (e.target.id === "sub_topic") {
			setSelectedSubTopic(
				() =>
					subTopics.filter(
						(item) => item.subTopic === e.target.value
					)[0]
			);
		} else if (e.target.id === "search") {
			setSearchParams(() => e.target.value);
		} else {
		}
	};

	useEffect(() => {
		if (selectedSubject) {
			setTimeout(() => {
				if (selectedSubject.subTopics === null) {
					setSubTopic(null);
				} else {
					setSubTopic(selectedSubject.subTopics);
				}
			}, 1000);
		}
	}, [selectedSubject]);

	// console.log(selectedSubTopic, "teessrrrrr");

	useEffect(() => {
		localStorage.setItem(
			"quiz_data",
			JSON.stringify({
				...local_data,
				selected_subject: selectedSubject,
				selected_grade: selectedGrade,
				selected_year: selectedYear,
				selected_sub_topic: selectedSubTopic,
				selected_board: selectedBoard,
			})
		);
	}, [
		selectedSubject,
		selectedGrade,
		selectedYear,
		selectedSubTopic,
		selectedBoard,
		local_data,
	]);
	const truncate = (str, maxLength = 100) => {
		if (str.length > maxLength) {
			return str.substring(0, maxLength) + "...";
		}

		return str;
	};

	return (
		<Container>
			<LeftSection>
				<Icon>
					<img src={icon} alt="icon" />
				</Icon>
				<Title>Create&nbsp;Quiz</Title>
				<SelectorContainer>
					<TopCover>
						<Cover>
							<Select
							className="cursorPointer"
								name="board"
								id="board"
								onChange={handleSelect}
								value={selectedBoard?.board}
							// className="select-wrapper"
							// 		classNamePrefix="select"
							>
								{/* {boards?.length > 0 ? (
									boards.map((board, i) => {
										return (
											<MenuItem key={i} value={board.id}

												id={`az-revisionadmin-boarddropdown-${board.id}`}
												sx={{
													"&.Mui-selected": {
														backgroundColor: 'orange',
														color: "#FFFFFF"
													},
													"&.Mui-selected:hover": {
														backgroundColor: '#fcedc2',
														color: "#000000"
													},
													"&:not(.Mui-selected):hover": {
														backgroundColor: '#fcedc2',
														color: '#000000',
													},
												}}>
												<ListItemText primary={boards.board} />
											</MenuItem>
										);
									})
								) : (
									<MenuItem>Select</MenuItem>
								)} */}
								<option value={""}>Board</option>{" "}
								{boards
									? boards?.map((data) => (
										<option 
										className="select-wrapper cursorPointer"
										classNamePrefix="select"
										 value={data.board}>
											{data.board}
										</option>
									))
									: null}
							</Select>
						</Cover>
						<Arrow>
							<KeyboardArrowDownOutlinedIcon />
						</Arrow>
					</TopCover>
					<TopCover>
						<Cover>
							<Select
							className="cursorPointer"
								name="grage"
								id="grade"
								onChange={handleSelect}
								value={selectedGrade?.grade}
							>
								<option value={""}>Grade</option>{" "}
								{grades ? (
									grades?.map((data) => (
										<option value={data.grade}>
											{data.grade}
										</option>
									))
								) : (
									<option value="">none</option>
								)}
							</Select>
						</Cover>
						<Arrow>
							<KeyboardArrowDownOutlinedIcon />
						</Arrow>
					</TopCover>
					<TopCover>
						<Cover>
							<Select
							className="cursorPointer"
								name="subjects"
								id="subjects"
								onChange={handleSelect}
								value={selectedSubject?.subject}
							>
								<option value={""}>Subject</option>{" "}
								{subjects ? (
									subjects?.map((data) => (
										<option value={data.subject}

										>
											{data.subject}
										</option>
									))
								) : (
									<option value="">none</option>
								)}
							</Select>
							<Arrow>
								<KeyboardArrowDownOutlinedIcon />
							</Arrow>
						</Cover>
					</TopCover>
					{
						showSubTopic &&

						<TopCover>
							<Cover>
								<Select
								className="cursorPointer"
									name="sub_topic"
									id="sub_topic"
									onChange={handleSelect}
									value={selectedSubTopic?.subTopic}
								>
									<option value={""}>SubTopic</option>{" "}
									{subTopics
										? subTopics?.map((data) => (
											<option value={data.subTopic}>
												{data.subTopic}
											</option>
										))
										: null}
								</Select>
							</Cover>
							<Arrow>
								<KeyboardArrowDownOutlinedIcon />
							</Arrow>
						</TopCover>
					}
					<TopCover>
						<Cover>
							<Select
							className="cursorPointer"
								name="year"
								id="year"
								onChange={handleSelect}
								value={selectedYear?.academicYear}
							>
								<option value={""}>year</option>{" "}
								{academicYears ? (
									academicYears?.map((data) => (
										<option value={data.academicYear}>
											{data.academicYear}
										</option>
									))
								) : (
									<option value="">none</option>
								)}
							</Select>
						</Cover>
						<Arrow>
							<KeyboardArrowDownOutlinedIcon />
						</Arrow>
					</TopCover>
				</SelectorContainer>
			</LeftSection>
			<RightSection>
				<InputCover>
					<Input
						placeholder="Search..."
						id="search"
						onChange={handleSelect}
					/>
					<SearchIcon>
						<SearchOutlinedIcon />
					</SearchIcon>
				</InputCover>
			</RightSection>
		</Container>
	);
}

export default SelectSection;
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 30px;
	width: 100%;
`;
const LeftSection = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
`;
const RightSection = styled.div`
	display: block;
	width: 100%;
	max-width: 200px;
`;
const Title = styled.p`
	margin-right: 20px;
	width: 120px;
`;
const SelectorContainer = styled.form`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	position: relative;
`;
const Cover = styled.span`
	padding: 0px 16px;
	background-color: #fff;
	width: 130px;
	border-radius: 20px;
	/* overflow: scroll; */
	display: block;
	position: relative;
`;
const Select = styled.select`
	color: #fd8c00;
	padding: 10px 0px;
	border: none;
	outline: none;
	font-size: 13px;
	padding-right: 20px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-appearance: none;
	-moz-appearance: none;
	position: relative;
	z-index: 50;
	background: transparent;
	:focus,
	:hover {
		border: none;
		color: #000000;
	}
`;
const InputCover = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	width: 100%;
	padding: 0 20px;
	border: 1px solid #9b9fa7;
	border-radius: 20px;
`;
const Input = styled.input`
	border: none;
	border: none;
	padding: 10px 0;
	flex: 1;
	outline: none;
	:focus,
	:hover {
		border: none;
	}
`;

const SearchIcon = styled.span`
	display: block;
	width: 20px;
	svg {
		width: 100%;
		display: block;
	}
`;
const Arrow = styled.span`
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: flex;
	align-items: center;
	background-color: #fff;
	padding-right: 10px;
	svg {
		font-size: 22px !important;
		color: #354052 !important;
	}
`;
const TopCover = styled.div`
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	border: 1px solid #9b9fa7;
`;
const Icon = styled.span`
	display: block;
	width: 25px;
	margin-right: 10px;
	img {
		display: block;
		width: 100%;
	}
`