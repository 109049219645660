import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Paper,
	Select,
	TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuProps } from "./Data";
import { styled } from "@mui/material/styles";
import QuestionModal from "./QuestionModal";
import ReleaseModal from "./ReleaseModal";
import axios from "axios";
import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
// import { SearchOutlined } from "@mui/icons-material";
import ReReleaseModal from "./ReReleaseModal";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../components/common/TopBar";
import searchIcn from "../../../src/img/search.svg";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import "../css/Student/Student_Enrich_Quiz.css";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../js/TeachValidate";
import TeacherMessage from "./TeacherMessage";
import { CircularProgress } from "@material-ui/core";
import ReleaseSuccess from "./CreateQuiz/QueSelection/ReleaseSuccess";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { fetchPlanDetails, fetchRmlockUnlockData } from "../js/Cordinator/assignQuiz/api/fetchCalls";
import "../css/commonStyling.module.css"

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "#fff",
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	boxShadow:
		"0px 2px 1px -1px rgba(138,203,249,0.77), 0px 1px 1px 0px rgba(138,203,249,0.77), 0px 1px 3px 0px rgba(138,203,249,0.77)",
	display: "flex",
	flexDirection: "column",
	alignItems: "start",
}));
const ChapterItem = styled(Paper)(({ theme }) => ({
	backgroundColor: "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));
const CancelButton = styled(Button)(({ theme }) => ({
	color: "#ed9b00",
	width: "5.2vw",
	backgroundColor: "#fff",
	borderRadius: 150,
	border: "1px solid #757575",
	borderColor: "#ed9b00",
	textTransform: "none",
	fontSize: "0.78vw",
}));
const SaveButton = styled(Button)(() => ({
	color: "#fff",
	width: "5.2vw",
	backgroundColor: "#ed9b00",
	borderRadius: 150,
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#ed9b00",
		border: 0,
		top: -2,
		boxShadow: "0px 3px 5px #FFA700",
	},
}));
const ReReleaseButton = styled(Button)(() => ({
	color: "#fff",
	width: "7.8vw",
	backgroundColor: "#ed9b00",
	borderRadius: 150,
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#ed9b00",
		border: 0,
		top: -2,
		boxShadow: "0px 3px 5px #FFA700",
	},
}));
const FormativeAssessment = (props) => {
	const navigate = useNavigate();
	const [preSelectedData, setPreSelectedData] = useState({});
	const [questionModalData, setQuestionModalData] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showReleaseModal, setShowReleaseModal] = useState(false);
	const [showReReleaseModal, setShowReReleaseModal] = useState(false);
	const token = sessionStorage.getItem("token");
	const [modifiedDate, setModifiedDate] = useState({});
	const [subjectList, setSubjectList] = useState([]);
	const [showSubTopic, setShowSubTopic] = useState(false);
	const [gradeList, setGradeList] = useState([]);
	const [selectedGrade, setSelectedGrade] = useState("");
	const [selectedSubject, setSelectedSubject] = useState("");
	const [selectedBoard, setSelectedBoard] = useState("");
	const [selectedSection, setSelectedSection] = useState("");
	const [selectedSubTopic, setSelectedSubTopic] = useState("");
	const [academicYearList, setAcademicYearList] = useState([]);
	const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
	const [allQuizData, setAllQuizData] = useState([]);
	const [updatedData, setUpdatedData] = useState([]);
	const [selectedChapterId, setSelectedChapterId] = useState("");
	const [chapterList, setChapterList] = useState([]);
	const [chapterPageSize, setChapterPageSize] = useState(100);
	const [selectedQuizId, setSelectedQuizId] = useState("");
	const [selectedPreviewId, setSelectedPreviewId] = useState("");
	const [isError, setIsError] = useState(false);
	const [quizIds, setQuizIds] = useState([]);
	const [quizDuraction, setQuizDuration] = useState("");
	const [selectedSectionReleaseData, setSelectedSectionReleaseData] =
		useState([]);
	const [isSucessModal, setSucessModal] = useState(false);
	const [quizType, setQuizType] = useState("");
	const [SucessMessage, setSucessMessage] = useState(
		"Quiz Released Sucessfully"
	);
	const [selectedSectionName, setSelectedSectionName] = useState("");

	const [
		isCheckingWhetherChapterIsEnded,
		setisCheckingWhetherChapterIsEnded,
	] = useState(false);

	const userName = localStorage.getItem("userName");
	const role = localStorage.getItem("role");

	const [teacherId, setTeacherId] = useState("");
	const [gradeSection, setGradeSection] = useState([]);

	const location = useLocation();

	const [rmLockUnlockDatas, setRmLockUnlockDatas] = useState([]);
	const [planData, setPlanData] = useState([]);

	// useEffect(() => {
	//   console.log(location.state);
	// 	if (location.state) {
	// 		setPreSelectedData(location?.state);
	// 	}
	// }, [location?.state]);

	useEffect(async () => {
		// console.log("formativeassessment.js ---------------")
		fetchTeacherData(userName, role);
		const academic = await fetchAcademicYearData();

		// GetLastModifiedAt(setModifiedDate);
		// Set the initial value of the filter
		const storedAcademicYearId = sessionStorage.getItem("academicYearId");
		if (storedAcademicYearId) {
			setSelectedAcademicYear(storedAcademicYearId);
		} else if (academic.length > 0) {
			setSelectedAcademicYear(academic[0].id);
			sessionStorage.setItem("academicYearId", academic[0].id);
		}
	}, []);

	//auto populate filters start scripts
	const [isAutoPopulating, setIsAutoPopulating] = useState(false);
	useEffect(() => {
		if (
			isNOTNullOrUndefined(gradeSection) &&
			gradeSection?.length &&
			isNullOrUndefined(preSelectedData.gradeId)
		) {
			startAutoPopulate();
		}
	}, [gradeSection]);

	const fetchSubjectForAutoPopulate = async (gradId, secId) => {
		const id = teacherId.id;
		if (id && gradId && secId) {
			const response = await axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${gradId}&sectionId=${secId}&menuName=FORMAL_ASSESSMENT`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.catch((err) => {

				});
			// console.log(response?.data?.data, selectedSubject)

			// console.log(response?.data?.data, "4-76-24 required")
			response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || []
			// console.log(response?.data?.data)

			setSubjectList(response?.data?.data);
			return response?.data?.data;
		}
	};

	const startAutoPopulate = async () => {
		// console.log("startAutoPopulate", JSON.parse(sessionStorage.getItem("isBackValue")), typeof sessionStorage.getItem("isBack"), sessionStorage.getItem("isBack"), sessionStorage.getItem("isBack") === true)
		if (sessionStorage.getItem("isBack") === true || sessionStorage.getItem("isBack") === "true") {
			var ss = JSON.parse(sessionStorage.getItem("isBackValue"))
			// console.log("1111111111111111111111111111111111111111",ss,ss?.selectedGrade,ss?.selectedSection,ss?.selectedSubject?.subjectId)
			// selectedSubject, selectedGrade, selectedSection
			setIsAutoPopulating(true);
			setSelectedGrade(ss?.selectedGrade);
			setSelectedSection(ss?.selectedSection);
			setSelectedChapterId(ss?.selectedChapterId);
			setChapterList(ss?.chapterList);
			// console.log(ss, "===================", ss?.selectedChapterId, ss?.chapterList)
			// console.log("chapterList", chapterList)
			const subList = await fetchSubjectForAutoPopulate(
				ss?.selectedGrade?.id,
				ss?.selectedSection
			);
			// console.log(subList,"subList")
			setSelectedSubject(ss?.selectedSubject);
			sessionStorage.setItem("gradeId", ss?.selectedGrade?.id);
			sessionStorage.setItem("sectionId", ss?.selectedSection);
			sessionStorage.setItem("subjectId", ss?.selectedSubject?.subjectId);
			fetchPlanDetails()
			fetchRmlockUnlockData()

			setTimeout(() => {
				setIsAutoPopulating(false);
				sessionStorage.setItem("isBack", false);
				sessionStorage.setItem("isBackValue", null)
			}, 2000);

		} else {
			setIsAutoPopulating(true);
			setSelectedGrade(gradeSection[0]);
			setSelectedSection(gradeSection[0]?.sections[0]?.id);
			const subList = await fetchSubjectForAutoPopulate(
				gradeSection[0]?.id,
				gradeSection[0]?.sections[0]?.id
			);
			// console.log("elseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",gradeSection[0],gradeSection[0]?.sections[0].id,subList[0]?.subjectId)

			setSelectedSubject(subList[0]);
			sessionStorage.setItem("gradeId", gradeSection[0]?.id);
			sessionStorage.setItem("sectionId", gradeSection[0]?.sections[0]?.id);
			sessionStorage.setItem("subjectId", subList[0]?.subjectId);
			fetchPlanDetails()
			fetchRmlockUnlockData()
			// console.log("startAutoPopulate called", sessionStorage.getItem("isBack"), "isBack", gradeSection[0], gradeSection[0]?.sections[0].id, subList[0]?.subjectId)

			setTimeout(() => {
				setIsAutoPopulating(false);
			}, 2000);
		}
	};

	const RenderLoader = () => {
		return (
			<div
				style={{
					height: "100%",
					width: "100%",
					zindex: 99,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CircularProgress />
			</div>
		);
	};

	//Auto populate ends

	useEffect(() => {
		const fethPlanData = async () => {
			const branchId = sessionStorage.getItem("branchId")
			if (branchId) {
				const planDatas = await fetchPlanDetails();
				setPlanData(planDatas);
			}
		};

		fethPlanData();
	}, []);

	useEffect(() => {
		const fetchRmData = async () => {
			const rmDataList = await fetchRmlockUnlockData(selectedGrade.id);
			setRmLockUnlockDatas(rmDataList);
		};

		fetchRmData();
	}, [planData, selectedGrade.id, selectedSubject]);

	const subjectID = sessionStorage.getItem("subjectId");
	const idArray = rmLockUnlockDatas.map((item) => item.subjectId)
	const activeStatusArray = rmLockUnlockDatas.map((item) => item.active);
	const foundIndex = idArray.indexOf(subjectID);

	let isActive = false;
	if (foundIndex !== -1) {
		isActive = activeStatusArray[foundIndex];
	}

	const quizRelease = async (item) => {
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const gradeId = sessionStorage.getItem("gradeId");
		const sectionId = sessionStorage.getItem("sectionId");
		const subjectId = sessionStorage.getItem("subjectId");
		const subTopicId = selectedSubTopic;
		const academicYearId = selectedAcademicYear
			? selectedAcademicYear
			: academicYearList[0]?.id;

		const chapterId = item.id;

		const data = {
			bordId,
			gradeId,
			schoolId,
			branchId,
			subjectId,
			subTopicId,
			sectionId,
			chapterId,
			academicYearId,
		};

		setPreSelectedData(data);
	};

	useEffect(async () => {
		// if (isNOTNullOrUndefined(preSelectedData.gradeId)) {
		// 	setSelectedGrade(
		// 		gradeSection?.filter(
		// 			(item) => item.id === preSelectedData.gradeId
		// 		)[0]
		// 	);
		// 	setSelectedSubject(
		// 		subjectList?.find(
		// 			(item) => item.subjectId === preSelectedData.subjectId
		// 		)
		// 	);
		const chaptersFetched = await fetchChapterDataForPreselection(
			preSelectedData
		);
		if (sessionStorage.getItem("isBack") === true || sessionStorage.getItem("isBack") === "true") {
		} else {
			setSelectedChapterId(chaptersFetched[0]);
		}
		setChapterList(chaptersFetched);
		// setSelectedChapterId(
		// 	chaptersFetched?.filter(
		// 		(item) => item.id === preSelectedData.chapterId
		// 	)[0]
		// );

		// const storedAcademicYearId =
		// 	sessionStorage.getItem("academicYearId");
		// setSelectedAcademicYear(storedAcademicYearId);
		//}
	}, [preSelectedData, gradeSection, academicYearList]);

	useEffect(() => {
		setSelectedSection(preSelectedData.sectionId);
	}, [preSelectedData?.gradeId]);

	useEffect(() => {
		if (preSelectedData?.subTopicId) {
			if (preSelectedData?.subTopicId?.id) {
				setSelectedSubTopic(
					selectedSubject?.subTopics?.find(
						(item) => item.id === preSelectedData?.subTopicId?.id
					)
				);
			} else {
				setSelectedSubTopic(
					selectedSubject?.subTopics?.find(
						(item) => item.id === preSelectedData?.subTopicId
					)
				);
			}
		} else if (selectedSubject?.subTopics) {
			setSelectedSubTopic(selectedSubject?.subTopics[0]);
		} else {
			setSelectedSubTopic("");
		}
	}, [selectedSubject]);

	const fetchChapterDataForPreselection = async (preselected) => {
		setChapterList([]);
		let result = [];
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/content/quiz-types/annual-diagnostic`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					result = response?.data?.data;
				} else {
					result = [];
				}
			})
			.catch((err) =>{}
				// console.log(
				// 	err,
				// 	"error on fetching chapter list on preselection"
				// )
			);
		return result;
	};

	useEffect(() => {
		if (!isAutoPopulating) {
			fetchSubject();
		}
	}, [selectedGrade, selectedSection]);

	useEffect(async () => {
		const grade = await fetchGrade();
		const academic = await fetchAcademicYearData();
		//await fetchChapterData();
		setAcademicYearList(academic);
		setGradeList(grade);
		// setChapterList(chapters)
	}, [teacherId.id]);

	useEffect(async () => {
		if (
			isNOTNullOrUndefined(selectedGrade) &&
			isNOTNullOrUndefined(selectedSection) &&
			isNOTNullOrUndefined(selectedSubject)
		) {
			setIsLoadingList(true);
			if (
				selectedSubject?.subTopics &&
				selectedSubject?.subTopics?.length > 0 &&
				isNullOrUndefined(selectedSubTopic)
			) {
			} else if (
				selectedSubject?.subTopics &&
				isNOTNullOrUndefined(selectedSubTopic) &&
				selectedSubject?.subTopics?.length > 0
			) {
				setTimeout(() => {
					showQuiz();
				}, 100);
			} else {
				setTimeout(() => {
					showQuiz();
				}, 200);
			}
		}
	}, [
		selectedChapterId,
		selectedGrade,
		// selectedSubject,
		selectedSection,
		selectedSubTopic,
	]);



	const fetchTeacherData = async (userName, role) => {
		const res = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		var result = res?.data?.data?.teacher || {};
		sessionStorage.setItem("Basic_info", JSON.stringify(result))

		sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
		sessionStorage.setItem("schoolId", res.data.data.teacher.school);
		sessionStorage.setItem("branchId", res.data.data.teacher.branch);
		setTeacherId(res.data.data.teacher);
	};

	// grade - section API

	const fetchGrade = async () => {
		const id = teacherId.id;
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				setGradeSection(response.data.data);
			})
			.catch((err) => console.log(err));
	};

	// subject - subtopic API

	const fetchSubject = async () => {
		const id = teacherId?.id;
		if (id && token) {
			await axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL
					}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${selectedGrade?.id ? selectedGrade?.id : selectedGrade
					}&sectionId=${selectedSection?.id ? selectedSection?.id : selectedSection
					}&menuName=FORMAL_ASSESSMENT`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((response) => {
					// console.log(response?.data?.data, "4-76-24 required")
					response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || []
					// console.log(response?.data?.data)


					setSubjectList(response.data.data);
				})
				.catch((err) => { });
		}
	};

	// Academic year api

	const fetchAcademicYearData = async () => {
		const res = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return res?.data.data || [];
	};



	const [isLoadingList, setIsLoadingList] = useState(false);

	const showQuiz = async () => {
		setAllQuizData([]);
		// console.log("***********************************************")
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const subTopicId = sessionStorage.getItem("subTopicId");

		// const chapterId = id;
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/content/quiz-release/list-to-teacher?reviewStatus=APPROVED&schoolId=${schoolId}&branchId=${branchId}&boardId=${bordId}&sectionId=${selectedSection}&gradeId=${selectedGrade?.id ? selectedGrade?.id : selectedGrade
				}&subjectId=${selectedSubject?.subjectId
					? selectedSubject.subjectId
					: selectedSubject
				}${isNOTNullOrUndefined(selectedChapterId)
					? `&quizTypeId=${selectedChapterId?.id}`
					: ""
				}&academicYearId=${selectedAcademicYear?.id
					? selectedAcademicYear.id
					: selectedAcademicYear
				}`
				// commented this bcz this page we dont select subtopic and if i pass subtopic for already release coming as not release(released - false) without subtopic id only it is providing better result.
				// ${isNOTNullOrUndefined(selectedSubject?.hideSubtopics === true ? null : selectedSubTopic?.id)
				// 	? "&subTopicId=" + selectedSubTopic?.id
				// 	: ""
				// }
				,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					setAllQuizData(response?.data?.data);
					setIsLoadingList(false);
				} else {
					setAllQuizData([]);
					setIsLoadingList(false);
				}
			})
			.catch((err) => {
				// console.log(err, "error fetching quiz list")
			});
	};

	const handleChangeGrade = (e) => {
		// setSelectedGrade(e.target.value);
		setSelectedGrade(
			gradeSection?.filter((grade) => grade.id === e.target.value)[0]
		);
		setSelectedSection("");

		sessionStorage.setItem("gradeId", e.target.value);
	};

	const handleChangeSection = (event) => {
		setAllQuizData([]);
		setSelectedSection(event.target.value);
		setSelectedChapterId("");
		sessionStorage.setItem("chapterId", null);
		setSelectedSubject("");
		sessionStorage.setItem("sectionId", event.target.value);
	};

	const handleChangeSubject = (event) => {
		setAllQuizData([]);
		const selectedSubject = subjectList.find(val => val.subjectId === String(event.target.value));
		// console.log(selectedSubject)
		// console.log(selectedSubject?.hideSubtopics, selectedSubject.subjectId)
		// if (selectedSubject && selectedSubject.hideSubtopics) {//not hided for teacher.principal,coordinator
		// 	setShowSubTopic(false);
		// 	console.log("false")
		// 	// alert("pac false");
		// } else {
		// 	console.log("true")
		// 	setShowSubTopic(true);
		// 	// alert("pac true");
		// }
		setSelectedSubject(
			subjectList?.find((item) => item.subjectId === event.target.value)
		);
		sessionStorage.setItem("subjectId", event.target.value);
	};

	const handleChangeChapter = (event) => {
		setAllQuizData([]);
		setSelectedChapterId(
			chapterList?.filter((item) => item.id === event.target.value)[0]
		);
		sessionStorage.setItem("chapterId", event.target.value);
	};

	const handleChangeSubtopic = (event) => {
		setAllQuizData([]);
		setSelectedSubTopic(
			selectedSubject?.subTopics?.find(
				(item) => item.id === event.target.value
			)
		);
		sessionStorage.setItem("subTopicId", event.target.value);
	};

	const onPreviewClick = async (item) => {
		await setQuestionModalData(item);
		await setShowModal(true);
		sessionStorage.setItem("selectedquestionModal", JSON.stringify(item));
		navigate(`../quiz`, { state: { selectedSubject, selectedGrade, selectedSection, selectedChapterId, chapterList } });
		sessionStorage.setItem("redirectUrl", 'formal assessment');

	};

	const onReleaseClick = (item) => {
		releaseQuiz(item.id);
		setSelectedPreviewId(item.id);
		setSelectedQuizId(item.id);
		setQuizDuration(item.duration);
		//	checkIfChapterEnded(item);
	};

	// function to handle Re-release
	const OpenReRelease = (item) => {
		setSelectedQuizId(item);
		setShowReReleaseModal(true);
		setShowReleaseModal(false);
	};

	const previewHandler = async () => {
		setShowReleaseModal(false);
		setSelectedQuizId(selectedPreviewId);
		setShowModal(true);
	};

	const releaseQuiz = async (quizid) => {
		const id = teacherId.id;
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		const subTopicId = sessionStorage.getItem("subTopicId");
		// const branchId = sessionStorage.getItem("branchId");

		const response = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-release/list-of-section?quizId=${quizid}&teacherId=${id}&gradeId=${selectedGrade.id}&sectionId=${selectedSection}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${selectedSubject.subjectId}&boardId=${bordId}&academicYearId=${selectedAcademicYear}
			`,
			// ${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''} cmnted bcz of issue rprtd
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		setQuizIds(response.data.data);
		setShowReleaseModal(true);
	};

	const checkIfChapterEnded = async (item) => {
		setisCheckingWhetherChapterIsEnded(true);
		const bordId = sessionStorage.getItem("boardId");
		const schoolId = sessionStorage.getItem("schoolId");
		const branchId = sessionStorage.getItem("branchId");
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/teacher/teach/chapter/min/details?gradeId=${selectedGrade.id
				}&sectionId=${selectedSection}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${selectedSubject.subjectId
				}&boardId=${bordId}&academicYearId=${selectedAcademicYear}${selectedSubTopic?.id
					? "&subTopicId=" + selectedSubTopic?.id
					: ""
				}&chapterId=${selectedChapterId?.id}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				const endDateFetched = res?.data?.data?.endDate;
				if (isNOTNullOrUndefined(endDateFetched)) {
					setisCheckingWhetherChapterIsEnded(false);
					setShowReleaseModal(true);
					setSelectedPreviewId(item.id);
					setSelectedQuizId(item.id);
					setQuizDuration(item.duration);
					releaseQuiz(item.id);
				} else {
					setChapterStatusMessage("Study of Chapter not Completed");
				}
			})
			.catch((err) => {
				setChapterStatusMessage("Some Error Occured try again");
				// console.log(err);
			});
	};

	const onChapterStatusModalClose = () => {
		setisCheckingWhetherChapterIsEnded(false);
		setChapterStatusMessage(null);
	};
	const [query, setQuery] = useState("");

	function isPastEndTimeAndDate(endTime, endDate) {
		var currentTime = new Date();

		var endHour = parseInt(endTime.split(":")[0]);
		var endMinute = parseInt(endTime.split(":")[1].split(" ")[0]);
		var endMeridian = endTime.split(" ")[1];

		var endDay = parseInt(endDate.split("/")[0]);
		var endMonth = parseInt(endDate.split("/")[1]) - 1;
		var endYear = parseInt(endDate.split("/")[2]);

		var endDateTime = new Date(
			endYear,
			endMonth,
			endDay,
			endMeridian === "PM"
				? endHour !== 12
					? endHour + 12
					: endHour
				: endMeridian === "AM" && endHour === 12
					? (endHour = 1)
					: endHour,
			endMinute
		);
		return currentTime > endDateTime;
	}

	const [chapterStatusMessage, setChapterStatusMessage] = useState(null);

	// if (isAutoPopulating) {
	// 	return <RenderLoader />;
	// }

	const purchasedFeature = localStorage.getItem("purchasedFeatures");
	const purchasedFeatures = JSON.parse(purchasedFeature);

	const rm_Lock = purchasedFeatures.find(item => item.name === 'rm_lock')

	let toggle_status = rm_Lock.toggleStatus;
	// console.log(toggle_status);
	// console.log(isActive);

	return (
		<div style={{ width: "100%" }}>
			<ReleaseSuccess
				open={isSucessModal}
				onClose={() => {
					setSucessModal(false);
				}}
				title={SucessMessage}
				selectedSectionName={selectedSectionName}
			/>
			<TopBar
				title={"Home"}
				nav1={"TeacherDashboard"}
				subSubTitle={"Formative Assessment"}
				modifiedDate={modifiedDate}
			/>

			<div
				style={{
					marginTop: "10px",
					paddingRight: "33px",
					position: "absolute",
					right: "15px",
					left: "15px",
					width: "100%",
					display: "flex",
					flexDirection: "column",
					rowGap: "1.2rem",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						columnGap: "10px",
					}}
				>
					<Box sx={{ minWidth: 120 }}>
						{/* <FormControl
							fullWidth
							size="small"
							className={dashboardContentStyles.formControl}
							id="formControl"
							sx={{
								"& .MuiInputLabel-root": {
									color: "#FD8C00 !important",
									fontSize: "14px !important",
									opacity: 0.9,
								},
								"& .MuiInputBase-root": {
									backgroundColor: "#FFFFFF",
									borderRadius: "50px",
									width: "148px",
									height: "32px",
								},
							}}
						>
							{!selectedGrade && (
								<InputLabel
									id="az-teacher-grade-select-label"
									shrink={false}
								>
									Select Grade
								</InputLabel>
							)}
							<Select
								labelId="demo-simple-select-label"
								id="az-teacher-grade-select"
								value={
									selectedGrade?.id
										? selectedGrade?.id
										: selectedGrade
								}
								onChange={handleChangeGrade}
								style={{
									borderRadius: "150px",
									minWidth: "180px",
									maxWidth: "180px",
									textOverflow: "ellipsis",
									color: selectedGrade ? "orange" : "black"
								}}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
									getContentAnchorEl: null,
									style: {
										maxHeight: "200px",
									},
								}}
								sx={{
									height: "100%",
									"&.Mui-selected": {
										backgroundColor: 'orange',
										color: "#FFFFFF"
									},
									"&.Mui-selected:hover": {
										backgroundColor: '#fcedc2',
										color: "#000000",
									},
									"&:not(.Mui-selected):hover": {
										backgroundColor: '#fcedc2',
										color: '#000000',
									},
								}}
							>
								{gradeSection?.map((item, index) => (

									<MenuItem key={item.id} value={item.id}
										id={`az-dropdown-t-${item.grade.toLowerCase().replace(/ /g, "_")}`}
										sx={{
											"&.Mui-selected": {
												backgroundColor: 'orange',
												color: "#FFFFFF"
											},
											"&.Mui-selected:hover": {
												backgroundColor: '#fcedc2',
												color: "#000000"
											},
											"&:not(.Mui-selected):hover": {
												backgroundColor: '#fcedc2',
												color: '#000000',
											}
										}}>
										{item?.grade}
									</MenuItem>
								))}
							</Select>
						</FormControl> */}

						<FormControl fullWidth>
							<InputLabel
								sx={{
									fontSize: '13px',
									color: '#e7ab3c',
									top: '-10px',
									'&.MuiInputLabel-shrink': {
										top: '0',
									},
									'&.Mui-focused': {
										color: '#e7ab3c'
									}
								}}
								id="az-teacher-grade-select-label" className='dropDownLabel'>Grade</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="az-teacher-grade-select"
								input={<OutlinedInput label='Grade' />}
								MenuProps={{
									PaperProps: {
										sx: {
											width: '150px',
											marginTop: '2px',
										},
									},
									MenuListProps: {
										sx: {
											padding: '0px'
										},
									},
								}}
								value={
									selectedGrade?.id
										? selectedGrade?.id
										: selectedGrade
								}
								onChange={handleChangeGrade}
								IconComponent={props => (
									<ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
								)}
								sx={{
									width: '150px',
									height: '30px',
									padding: '10px',
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#e7ab3c',
									}
								}}
								className='dropdownSelect'
							>
								{gradeSection?.map((item, index) => (

									<MenuItem key={item.id} value={item.id}
										id={`az-dropdown-t-${item.grade.toLowerCase().replace(/ /g, "_")}`}
										sx={{
											fontSize: '10px',
											backgroundColor: 'white',
											'&:hover': {
												backgroundColor: '#f4d788',
											},
											'&.Mui-selected': {
												backgroundColor: '#e7ab3c',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
											},
										}}>
										{item?.grade}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box sx={{ minWidth: 120 }}>


						<FormControl fullWidth>
							<InputLabel
								sx={{
									fontSize: '13px',
									color: '#e7ab3c',
									top: '-10px',
									'&.MuiInputLabel-shrink': {
										top: '0',
									},
									'&.Mui-focused': {
										color: '#e7ab3c'
									}
								}}
								id="az-teacher-section-select-label" className='dropDownLabel' >Section</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="az-teacher-section-select"
								input={<OutlinedInput label='Section' />}
								MenuProps={{
									PaperProps: {
										sx: {
											width: '150px',
											marginTop: '2px',
										},
									},
									MenuListProps: {
										sx: {
											padding: '0px'
										},
									},
								}}
								IconComponent={(props) => (
									<ExpandMoreIcon
										{...props}
										sx={{ fontSize: "1.5vw" }}
									/>
								)}
								value={
									isNOTNullOrUndefined(selectedSection)
										? selectedSection
										: ""
								}
								// onChange={(e) => setSelectedSection(e.target.value)}
								onChange={handleChangeSection}
								sx={{
									width: '150px',
									height: '30px',
									padding: '10px',
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#e7ab3c',
									}
								}}
								className='dropdownSelect'
							>
								{selectedGrade &&
									gradeSection
										?.find(
											(i) => i.id === selectedGrade.id
										)
										?.sections.map((sec) => {
											// if (sec.hasOwnProperty("section")) {
											return (
												<MenuItem
													value={sec.id}
													key={sec.id}
													// className={
													// 	dashboardContentStyles.menu_item
													// }
													id={`az-section-dropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
													sx={{
														fontSize: '10px',
														backgroundColor: 'white',
														'&:hover': {
															backgroundColor: '#f4d788',
														},
														'&.Mui-selected': {
															backgroundColor: '#e7ab3c',
															'&:hover': {
																backgroundColor: '#f4d788',
															},
														},
													}}
												>
													<ListItemText
														primary={
															sec.section
														}
													/>
												</MenuItem>
											);
											// }
										})}
							</Select>
						</FormControl>
					</Box>
					<Box sx={{ minWidth: 120 }}>


						<FormControl fullWidth>
							<InputLabel
								sx={{
									fontSize: '13px',
									color: '#e7ab3c',
									top: '-10px',
									'&.MuiInputLabel-shrink': {
										top: '0',
									},
									'&.Mui-focused': {
										color: '#e7ab3c'
									}
								}}
								className='dropDownLabel'
								id="az-teacher-subject-select-label">Subject</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="az-teacher-subject-select"
								input={<OutlinedInput label='Subject' />}
								MenuProps={{
									PaperProps: {
										sx: {
											width: '150px',
											marginTop: '2px',
										},
									},
									MenuListProps: {
										sx: {
											padding: '0px'
										},
									},
								}}
								onChange={handleChangeSubject}
								value={
									isNOTNullOrUndefined(
										selectedSubject?.subjectId
									)
										? selectedSubject.subjectId
										: ""
								}
								IconComponent={(props) => (
									<ExpandMoreIcon
										{...props}
										sx={{ fontSize: "1.5vw" }}
									/>
								)}
								sx={{
									width: '150px',
									height: '30px',
									padding: '10px',
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#e7ab3c',
									}
								}}
								className='dropdownSelect'
							>
								{subjectList?.map((item, index) => (
									<MenuItem
										value={item.subjectId}
										key={item.subjectId}
										// className={
										// 	dashboardContentStyles.menu_item
										// }
										id={`az-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
										sx={{
											fontSize: '10px',
											backgroundColor: 'white',
											'&:hover': {
												backgroundColor: '#f4d788',
											},
											'&.Mui-selected': {
												backgroundColor: '#e7ab3c',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
											},
										}}
									>
										<ListItemText
											primary={item?.subject}
										/>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					{/* {
						// showSubTopic &&

						<Box sx={{ minWidth: 120 }}>
							<FormControl
								fullWidth
								size="small"
								sx={{
									"& .MuiInputLabel-root": {
										color: "#FD8C00 !important",
										fontSize: "14px !important",
										opacity: 0.9,
									},
									"& .MuiInputBase-root": {
										backgroundColor: "#FFFFFF",
										borderRadius: "50px",
										width: "148px",
										height: "32px",
									},
								}}
							>
								{
									// !showSubTopic &&
									!selectedSubTopic &&
									<InputLabel
										id="demo-simple-select-label"
										shrink={false}
									>
										Selected SubTopic
									</InputLabel>}
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										getContentAnchorEl: null,
										style: {
											maxHeight: "200px",
										},
									}}
									style={{
										borderRadius: "150px",
										minWidth: "180px",
										maxWidth: "180px",
										textOverflow: "ellipsis",
										fontSize: 8,
									}}
									sx={{
										fontSize: 8,
										height: "100%",
									}}
									onChange={handleChangeSubtopic}
									size={"small"}
									value={
										isNOTNullOrUndefined(
											selectedSubTopic?.id
										)
											? selectedSubTopic?.id
											: ""
									}
									IconComponent={(props) => (
										<ExpandMoreIcon
											{...props}
											sx={{ fontSize: "1.5vw" }}
										/>
									)}
								>
									{selectedSubject?.subTopics?.map((item) => (
										<MenuItem
											value={item.id}
											key={item.id}
											className={
												dashboardContentStyles.menu_item
											}
										>
											<ListItemText
												primary={item?.subTopic}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					} */}
					<Box sx={{ minWidth: 120 }}>


						<FormControl fullWidth>
							<InputLabel
								sx={{
									fontSize: '13px',
									color: '#e7ab3c',
									top: '-10px',
									'&.MuiInputLabel-shrink': {
										top: '0',
									},
									'&.Mui-focused': {
										color: '#e7ab3c'
									}
								}}
								className='dropDownLabel'
								id="az-teacher-quiztype-select-label">Quiz Type</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="az-teacher-quiztype-select"
								input={<OutlinedInput label='Quiz Type' />}
								MenuProps={{
									PaperProps: {
										sx: {
											width: '150px',
											marginTop: '2px',
										},
									},
									MenuListProps: {
										sx: {
											padding: '0px'
										},
									},
								}}
								value={
									isNOTNullOrUndefined(
										selectedChapterId?.id
									)
										? selectedChapterId?.id
										: ""
								}
								onChange={handleChangeChapter}
								IconComponent={(props) => (
									<ExpandMoreIcon
										{...props}
										sx={{ fontSize: "1.5vw" }}
									/>
								)}
								sx={{
									width: '150px',
									height: '30px',
									padding: '10px',
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#e7ab3c',
									}
								}}
								className='dropdownSelect'
							>
								{chapterList?.map((item, index) => (
									<MenuItem
										key={item.id}
										value={item.id}
										// className={
										// 	dashboardContentStyles.menu_item
										// }
										id={`az-dropdown-type-${index}`}
										sx={{
											fontSize: '10px',
											backgroundColor: 'white',
											'&:hover': {
												backgroundColor: '#f4d788',
											},
											'&.Mui-selected': {
												backgroundColor: '#e7ab3c',
												'&:hover': {
													backgroundColor: '#f4d788',
												},
											},
										}}
									>
										{item?.quizType}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box sx={{ minWidth: 120 }}>


						<FormControl fullWidth>
							<InputLabel
								sx={{
									fontSize: '13px',
									color: '#e7ab3c',
									top: '-10px',
									'&.MuiInputLabel-shrink': {
										top: '0',
									},
									'&.Mui-focused': {
										color: '#e7ab3c'
									}
								}}
								className='dropDownLabel'
								id="az-teacher-acdyear-select-label">Academic year</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="az-teacher-acdyear-select"
								input={<OutlinedInput label='Academic Year' />}
								MenuProps={{
									PaperProps: {
										sx: {
											width: '150px',
											marginTop: '2px',
										},
									},
									MenuListProps: {
										sx: {
											padding: '0px'
										},
									},
								}}
								value={selectedAcademicYear}
								disabled
								IconComponent={(props) => (
									<ExpandMoreIcon
										{...props}
										sx={{ fontSize: "1.5vw" }}
									/>
								)}
								sx={{
									width: '150px',
									height: '30px',
									padding: '10px',
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#000',
									},
									'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#e7ab3c',
									}
								}}
								className='dropdownSelect'

							>
								<MenuItem
									value={academicYearList[0]?.id}
									key={academicYearList[0]?.id}
									// className={
									// 	dashboardContentStyles.menu_item
									// }
									sx={{
										fontSize: '10px',
										backgroundColor: 'white',
										'&:hover': {
											backgroundColor: '#f4d788',
										},
										'&.Mui-selected': {
											backgroundColor: '#e7ab3c',
											'&:hover': {
												backgroundColor: '#f4d788',
											},
										},
									}}
								>
									<ListItemText
										primary={
											academicYearList[0]
												?.academicYear
										}
									/>
								</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						rowGap: "1rem",
						height: "100%",
					}}
				>
					{/* <div style={{ fontWeight: "600", fontSize: 18 }}>
							All Quiz For this Chapter {allQuizData?.length}
						</div> */}
					{isLoadingList ? (
						<div>Fetching....</div>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								columnGap: "1rem",
								padding: "30px",
							}}
						>
							{isNOTNullOrUndefined(selectedGrade) &&
								isNOTNullOrUndefined(selectedSubject) &&
								isNOTNullOrUndefined(selectedSection) &&
								isNOTNullOrUndefined(selectedChapterId?.id) ? (
								<Box sx={{ flexGrow: 1 }}>
									<Grid container spacing={3}>
										{allQuizData?.length > 0 ? (
											allQuizData.map((item) => (
												<Grid
													item
													xs={12}
													md={4}
													sm={6}
													key={item.id}
												>
													<div className={dashboardContentStyles.az_full_quiz_card} id="az_quiz_card">
														<div id="az_full_quiz_card_customText"
															className={dashboardContentStyles.az_full_quiz_card_customText}
														>
															{item.quizType}{" "}
															| {item.name}
														</div>
														<div className={dashboardContentStyles.az_full_quiz_card_customContainer} id="az_full_quiz_card_customContainer"
														// style={{
														// background:
														// 	"rgba(138,203,249,0.77)",
														// width: "100%",
														// padding:
														// 	"20px",
														// fontSize:
														// 	"0.93vw",
														// }}
														>
															<div className={dashboardContentStyles.az_full_quiz_card_customContainer1} id="az_full_quiz_card_customContainer1"
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row",
																	justifyContent:
																		"space-between",
																	padding:
																		"5px",
																}}
															>
																<div id="az-qstn">
																	Questions
																</div>
																<div
																	style={{
																		minWidth:
																			"80px",
																	}}
																>
																	{
																		item.totalQuestions
																	}
																</div>
															</div>
															<div
																className={dashboardContentStyles.az_full_quiz_card_customContainer1} id="az_full_quiz_card_customContainer1"
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row",
																	justifyContent:
																		"space-between",
																	padding:
																		"5px",
																}}
															>
																<div>
																	Marks
																</div>
																<div
																	style={{
																		minWidth:
																			"80px",
																	}}
																>
																	{
																		item.totalMarks
																	}
																</div>
															</div>
															<div
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row",
																	justifyContent:
																		"space-between",
																	padding:
																		"5px",
																}}
															>
																<div>
																	Duration
																</div>
																<div
																	style={{
																		minWidth:
																			"80px",
																	}}
																>
																	{`${item.duration} mins`}
																</div>
															</div>
														</div>
														<div className={dashboardContentStyles.az_blue_card} id="az_blue_card"
															style={{
																background:
																	"rgba(4,59,189,0.91)",
																width: "100%",
																padding:
																	"20px",
																color: "white",
																fontSize:
																	"0.93vw",
															}}
														>
															<div
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row",
																	justifyContent:
																		"space-between",
																	padding:
																		"5px",
																}}
															>
																<div
																	style={{
																		minWidth:
																			"280px",
																	}}
																>
																	{Object.keys(
																		item?.taxonomyPercentage
																	).map(
																		(
																			v,
																			i
																		) => {
																			return (
																				<div
																					style={{
																						display:
																							"flex",
																						justifyContent:
																							"space-between",
																					}}
																				>
																					<p
																						style={{
																							color: "white",
																						}}
																					>
																						{
																							v
																						}
																					</p>
																					<p
																						style={{
																							color: "white",
																						}}
																					>
																						{
																							item
																								?.taxonomyPercentage[
																							v
																							]
																						}

																						%
																					</p>
																				</div>
																			);
																		}
																	)}
																</div>
															</div>
														</div>
														{item.containsCaseStudy ===
															true && (
																<div
																	style={{
																		background:
																			"rgb(3,38,120)",
																		width: "100%",
																		padding:
																			"20px",
																		color: "white",
																		display:
																			"flex",
																		justifyContent:
																			"center",
																		alignItems:
																			"center",
																	}}
																>
																	Contains
																	CaseStudy
																</div>
															)}
														<div
															style={{
																display:
																	"flex",
																justifyContent:
																	"center",
																padding:
																	"10px",
																alignItems:
																	"center",
																width: "100%",
																columnGap:
																	"1rem",
															}}
														>
															<CancelButton
																id="az-preview-button"
																onClick={() =>
																	onPreviewClick(
																		item
																	)
																}
															>
																Preview
															</CancelButton>
															{/* <SaveButton
																onClick={() => {
																	onReleaseClick(
																		item
																	);
																	setQuizType(
																		item.quizType
																	);
																}}
															>
																ReRelease
															</SaveButton> */}
															{
																//(item.endDate !== "" && item.endDate) &&
																<>
																	{item.quizType ===
																		"Practice Quiz"
																		? !item.released && toggle_status && isActive && (
																			<SaveButton
																				id="az-relaese-btn"
																				onClick={() => {
																					onReleaseClick(
																						item
																					);
																					setQuizType(
																						item.quizType
																					);
																				}}
																			>
																				Release
																			</SaveButton>
																		)
																		: !item.allAttended && toggle_status && isActive &&
																		(
																			//item.endTime !== null 
																			true
																				? (
																					// isPastEndTimeAndDate																		
																					// (
																					// 	item.endTime,
																					// 	item.endDate
																					// ) 
																					true
																					&&
																					(item.released ? toggle_status && isActive && (
																						<SaveButton
																							id="az-rerelaese-btn"
																							onClick={() => {
																								onReleaseClick(
																									item
																								);
																								setQuizType(
																									item.quizType
																								);
																							}}
																						>
																							ReRelease
																						</SaveButton>
																					) : (
																						<SaveButton
																							id="az-release-btn"
																							onClick={() => {
																								onReleaseClick(
																									item
																								);
																								setQuizType(
																									item.quizType
																								);
																							}}
																						>
																							Release
																						</SaveButton>
																					))
																				) : item.released ? toggle_status && isActive && (
																					<SaveButton
																						id="az-rerelaese-btn"
																						onClick={() => {
																							onReleaseClick(
																								item
																							);
																							setQuizType(
																								item.quizType
																							);
																						}}
																					>
																						ReRelease
																					</SaveButton>
																				) : (
																					<SaveButton
																						id="az-relaese-btn"
																						onClick={() => {
																							onReleaseClick(
																								item
																							);
																							setQuizType(
																								item.quizType
																							);
																						}}
																					>
																						Release
																					</SaveButton>
																				))}
																	{ }
																</>
															}
														</div>
													</div>
												</Grid>
											))
										) : (
											<div style={{ color: "red" }}>
												No Quiz for selected Filters
											</div>
										)}
									</Grid>
								</Box>
							) : (
								<div>
									Please do select Grade , Section and
									Subject
								</div>
							)}
						</div>
					)}
				</div>
				{/* {questionModalData && showModal && (
						<QuestionModal
							open={showModal}
							close={() => {
								setShowModal(false);
								setQuestionModalData(null);
								setSelectedQuizId("");
							}}
							data={questionModalData}
						/>
					)} */}
				{showReleaseModal && (
					<ReleaseModal
						open={showReleaseModal}
						close={() => {
							setShowReleaseModal(false);
							setSelectedQuizId("");
							setSelectedPreviewId("");
						}}
						previewHandler={previewHandler}
						fetchSection={quizIds}
						selectedQuizId={selectedQuizId}
						quizDuraction={quizDuraction}
						teacherId={teacherId.id}
						OpenReRelease={OpenReRelease}
						setSucessModal={setSucessModal}
						setSelectedSectionReleaseData={
							setSelectedSectionReleaseData
						}
						quizType={quizType}
						setSucessMessage={setSucessMessage}
						selectedSubTopic={selectedSubject?.hideSubtopics === true ? null : selectedSubTopic?.id}
						setSelectedSectionName={setSelectedSectionName}
					/>
				)}

				{showReReleaseModal && (
					<ReReleaseModal
						open={showReReleaseModal}
						close={() => setShowReReleaseModal(false)}
						selectedQuizId={selectedQuizId}
						teacherId={teacherId.id}
						selectedSectionReleaseData={
							selectedSectionReleaseData
						}
						setSucessModal={setSucessModal}
						setSucessMessage={setSucessMessage}
						selectedSubTopic={selectedSubject?.hideSubtopics === true ? null : selectedSubTopic?.id}
					/>
				)}
				{isCheckingWhetherChapterIsEnded ? (
					<TeacherMessage
						message={chapterStatusMessage}
						open={isCheckingWhetherChapterIsEnded}
						close={onChapterStatusModalClose}
					/>
				) : null}
			</div>

		</div>
	);
};
export default FormativeAssessment;
