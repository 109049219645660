import axios from "axios";

async function BranchController_post(branchInputs, num, setSuccess, setShowError) {
  // console.log(branchInputs, "branchInputs")
  let token = sessionStorage.getItem("token");
  var item = {
    boardId: branchInputs.boardId,
    cityId: branchInputs.cityId,
    locality: branchInputs.locality,
    logoUrl: branchInputs.logoUrl,
    name: branchInputs.name,
    phoneNumber: num,
    schoolCode: branchInputs.schoolCode,
    plans: branchInputs.plans,
    pocEmail: branchInputs.pocEmail,
    schoolId: sessionStorage.getItem("schoolid"),
    branchCode: branchInputs?.branchCode
  };


  let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches`, item,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // console.log(response)
      setShowError(response.message)
      setSuccess(response.data)
    })
    .catch(err => {
      // console.log(err, err.response.data.message, "==========");
      setShowError(err.response.data.message)
      // setSuccess(err.response.data.message)
      sessionStorage.setItem("branchError", err.response.data.message)

    })
}

export default BranchController_post;
