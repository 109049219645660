import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Paper,
  Box,
  InputLabel
} from '@mui/material'
import dashboardContentStyles from '../../../css/dashboardContent.module.css'
import { MenuProps } from '../../Data'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DarkGreenButton from '../../../../components/common/DarkGreenButton'
import { styled } from '@mui/material/styles'
import DarkYellowButton from '../../../../components/common/DarkYellowButton'
import '../../../css/switch.css'
import { isNOTNullOrUndefined } from '../../../../utils/helper'
import Tooltip from '@material-ui/core/Tooltip';

const RedButton = styled(Button)((props) => ({
  color: props.disabled ? "#fff" : '#fff',
  backgroundColor: props.disabled ? "#ccc" : 'red',
  borderColor: 'red',
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '10px',
  fontSize: '0.9vw',
  '&:hover': {
    color: '#fff',
    backgroundColor: 'red',
    border: 0,
    boxShadow: '0px 3px 5px red'
  }
}))
const ContentDisplayTopBar = ({
  Section,
  onAcademicYearSelection,
  selectedAcademicYear,
  selectedChapterId,
  academicYearList,
  selectedSection,
  onSelectSection,
  onAssignQuizClick,
  onStartClick,
  onEndClick,
  buttonsActiveState,
  endDate,
  startDate,
  pageName
}) => {

  const isActiveitm = sessionStorage.getItem("isActiveBoo")
  const parsedisActiveitm = JSON.parse(isActiveitm)

  const selectedGrade = sessionStorage.getItem('gradeId')

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
      className='outer_DIV'
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap', // Ensure items stay in one row
          justifyContent: 'space-between', // Spread items evenly
          alignItems: 'center',
          columnGap: '0.5rem',
          overflowX: 'auto', // Add scroll if content overflows
        }}
        className='inner_DIV'
      >
        <FormControl sx={{ minWidth: '150px', flexShrink: 0 }}>
          <InputLabel
            sx={{
              fontSize: '13px',
              color: '#e7ab3c',
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
              '&.Mui-focused': {
                color: '#e7ab3c',
              },
            }}
            id="az-section-select-label"
            className="dropDownLabel"
          >
            Section
          </InputLabel>
          <Select
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop: '2px',
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px',
                },
              },
            }}
            input={<OutlinedInput label="Section" />}
            IconComponent={(props) => (
              <ExpandMoreIcon
                {...props}
                sx={{
                  fontSize: '1.5vw',
                }}
              />
            )}
            value={selectedSection}
            onChange={onSelectSection}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              width: '150px',
              height: '30px',
              padding: '10px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c',
              },
            }}
            className="dropdownSelect"
          >
            {Section?.map((item) => {
              return (
                <MenuItem
                  value={item.id}
                  key={item.id}
                  className={dashboardContentStyles.menu_item}
                  sx={{
                    fontSize: '10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  {item.hasOwnProperty('section') ? (
                    <ListItemText primary={item.section} />
                  ) : null}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: '150px', flexShrink: 0 }}>
          <InputLabel
            sx={{
              fontSize: '13px',
              color: '#e7ab3c',
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
              '&.Mui-focused': {
                color: '#e7ab3c',
              },
            }}
            id="az-acdyear-select-label"
            className="dropDownLabel"
          >
            Academic Year
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="az-acdyear-select"
            input={<OutlinedInput label="Academic Year" />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop: '2px',
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px',
                },
              },
            }}
            disabled
            IconComponent={(props) => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            value={selectedAcademicYear}
            onChange={onAcademicYearSelection}
            sx={{
              width: '150px',
              height: '30px',
              padding: '10px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c',
              },
            }}
            className="dropdownSelect"
          >
            {academicYearList?.map((item) => {
              return (
                <MenuItem
                  value={item.id}
                  key={item.id}
                  className={dashboardContentStyles.menu_item}
                  sx={{
                    fontSize: '10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  <ListItemText primary={item.academicYear} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            minWidth: '105px',
            rowGap: '5px',
            alignItems: 'left',
            fontSize: '0.93vw',
          }}
        >
          <div style={{ fontWeight: 600, color: 'rgb(66, 61, 92)' }}>Start Date:</div>
          <div style={{ color: '#01b5eb' }}>{startDate ? startDate : 'Not started yet'}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            width: '170px',
            rowGap: '5px',
            alignItems: 'left',
            fontSize: '0.93vw',
          }}
        >
          <div style={{ fontWeight: 600, color: 'rgb(66, 61, 92)' }}>End Date:</div>
          <div style={{ color: '#01b5eb' }}>{endDate ? endDate : 'Not ended yet'}</div>
        </div>
      </div>

      {selectedChapterId && pageName === "Teach" ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
            columnGap: '10px'
          }}
        >
          <DarkGreenButton disabled={!buttonsActiveState.startButton} onClick={onStartClick}>Start</DarkGreenButton>
          <RedButton onClick={onEndClick} disabled={!buttonsActiveState.endButton}>End</RedButton>

          {parsedisActiveitm ?
            (
              <DarkYellowButton disabled={!buttonsActiveState.assignButton} onClick={onAssignQuizClick}>
                Assign Quiz
              </DarkYellowButton>
            ) : (
              <button
                style={{
                  height: "auto",
                  fontSize: "14px",
                  padding: "8px",
                  color: "white",
                  background: "#707070",
                  borderRadius: "5px",
                  border: "none",
                  outline: "none"
                }}
              >
                Assign Quiz
              </button>
            )}
        </div>
      ) : null}
    </div>
  )
}

export default ContentDisplayTopBar
