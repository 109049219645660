import React, { useState, useEffect } from "react";
import DashboardBluePrintLayer from "../DashboardBluePrintLayer/DashboardBluePrintLayer";
import AddBluePrint from "./AddBluePrint";
import BluePrintFilter from "../../../../components/BluePrintCommonTable/BluePrintFilter";
import BluePrintTab from "../../../../components/BluePrintCommonTable/BluePrintTab";

import {
  bluePrintsGrades,
  bluePrintsPaperType,
  fetchAllBluePrints,
  bluePrintsSubjects,
  bluePrintsBoards,
  fetchBluePrintsListForListView,
  toggleActiveStatus,
  deleteBluePrint,
  getBluePriintSingleContent,
  fetchBluePrintLevels,
  bluePrintsAllGrades,
  bluePrintsAllSubjects,
} from "../Apis/BluePrintApis";
import readXlsxFile from "read-excel-file/web-worker";
import SheetTable from "./SheetTable";
import { tableData, tableHead, tableHeadRejected } from "../Schema/schema";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { TablePagination } from "@mui/material";
import { BlueprintTableBody } from "./BlueprintTableBody";
import BluePrintTablehead from "./BluePrintTablehead";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { set_initial_data } from "../../../../actions/bluePrintActions";

export default function BluePrintUpload() {
  const [paperList, setPaperList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [contentList, setContentList] = useState([]);

  const [allGradeList, setAllGradeList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);

  // const [bluePrintData, setBluePrintData] = useState({});
  const [excelData, setExcelData] = useState([]);
  const [excelDataUpload, setExcelDataUpload] = useState([]);
  const [showExcel, setShowExcel] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalMarks, setTotalMarks] = useState("");
  const [totalQuestions, setTotalQuestions] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setpageSize] = useState(5);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [search, setSearch] = useState("");
  const [reviewStatus, setReviewStatus] = useState("Pending");
  const [blueprintsData, setBluePrintsData] = useState([]);
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [buttonEdit, setButtonEdit] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [isPreview, setIsPreview] = useState(false);

  const [isCreated, setCreated] = useState(false);

  const [formSelectedGrade, setFormSelectedGrade] = useState("");
  const [formSelectedBoard, setFormSelectedBoard] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const dispatch = useDispatch();
  // dispatch(set_is_scrolling_to_top(true))

  useEffect(() => {
    fetchBluePrintList();
    fetchLevelList();
    fetchAllGradeList();
    fetchAllSubjectList();
    fetchBoardList();
    return () => {
      dispatch(set_initial_data());
    };
  }, []);

  useEffect(() => {
    if (formSelectedBoard) fetchGradeList();
  }, [formSelectedBoard]);

  useEffect(() => {
    if (formSelectedBoard && formSelectedGrade) {
      fetchSubjectList();
    }
  }, [formSelectedBoard, formSelectedGrade]);

  const fetchBluePrintList = async () => {
    const response = await bluePrintsPaperType();
    setPaperList(response);
  };

  const fetchLevelList = async () => {
    const response = await fetchBluePrintLevels();
    setLevelList(response);
  };

  const fetchAllGradeList = async () => {
    const response = await bluePrintsAllGrades();
    setAllGradeList(response);
  };

  const fetchAllSubjectList = async () => {
    const response = await bluePrintsAllSubjects();
    setAllSubjectList(response);
  };

  const fetchGradeList = async () => {
    const response = await bluePrintsGrades(formSelectedBoard);
    setGradeList(response);
  };

  const fetchSubjectList = async () => {
    const response = await bluePrintsSubjects(
      formSelectedBoard,
      formSelectedGrade
    );
    setSubjectList(response);
  };

  const fetchBoardList = async () => {
    const response = await bluePrintsBoards();
    setBoardList(response);
  };

  const fetchBluePrints = async () => {
    setCreated(false);
    let pageNumber = page;
    const response = await fetchBluePrintsListForListView(
      pageNumber,
      pageSize,
      selectedBoard,
      selectedGrade,
      selectedSubject,
      search,
      reviewStatus
    );
    setBluePrintsData(response?.data);
    setTotalPage(response?.totalPages);
    setTotalElements(response?.totalElements);
    setCurrentPage(response?.pageNumber);
  };

  const handleFileSelect = (event, data) => {
    if (!selectedForEdit) {
      const file = event.target.files[0];
      var arr = [];
      readXlsxFile(file).then((rows) => {
        rows?.slice(1).forEach((elem, index) => {
          let objectItem = {
            print: elem[0],
            whatToPrint: elem[1],
            questionNumber: elem[2] || "",
            questionSubNumber: elem[3],
            marks: elem[4],
            type: elem[5],
            subType: elem[6],
            taxonomyCategory: elem[7],
            taxonomy: elem[8],
            internalChoice: elem[9],
            insertionOrder: index + 1,
          };
          arr.push(objectItem);
        });
        setExcelData(arr);
        setShowExcel(true);
      });
    } else {
      const file = data;
      var arr = [];
      file?.forEach((item) => {
        let objectItem = {
          print: item?.print,
          whatToPrint: item?.whatToPrint,
          questionNumber: item?.questionNumber,
          questionSubNumber: item?.questionSubNumber,
          marks: item?.marks,
          type: item?.type,
          subType: item?.subType,
          taxonomyCategory: item?.taxonomyCategory,
          taxonomy: item?.taxonomy,
          internalChoice: item?.internalChoice,
        };
        arr.push(objectItem);
        setExcelData(arr);
        setShowExcel(true);
        setExcelDataUpload(arr);
      });
    }
  };

  const handleSave = (inputData) => {
    // console.log(inputData);
    // e.preventDefault();
    setIsUploaded(true);
    setShowExcel(false);
    let result = inputData.reduce(function (acc, obj) {
      return acc + obj.marks;
    }, 0);
    let question = inputData.reduce(function (acc, obj) {
      if (obj.questionNumber) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
    setTotalMarks(result);
    setTotalQuestions(question);
    setExcelDataUpload(inputData);
    setSelectedForEdit(false);
  };

  useEffect(() => {
    fetchBluePrints();
  }, [
    selectedBoard,
    selectedGrade,
    selectedSubject,
    search,
    reviewStatus,
    pageSize,
    page,
    isCreated,
  ]);

  const handleToggle = async (e, id) => {
    const response = await toggleActiveStatus(e.target.checked, id);
    fetchBluePrints();
  };

  const onDeleteConfirm = async () => {
    const response = await deleteBluePrint(selectedForDelete);
    setSelectedForDelete(null);
    setShowDeleteModal(false);
    fetchBluePrints();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setpageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onCancelPreview = () => {
    setIsPreview(false);
    setShowExcel(false);
    setExcelData([]);
  };

  const onPreviewClick = async (id) => {
    const response = await getBluePriintSingleContent(id);
    if (isNOTNullOrUndefined(response?.blueprintTemplate)) {
      setIsPreview(true);
      setExcelData(response?.blueprintTemplate);
      setShowExcel(true);
    }
    // console.log(response, "rewsss");
  };

 

  let tableHeadData =
    reviewStatus === "Rejected" ? tableHeadRejected : tableHead;

  return (
    <DashboardBluePrintLayer>
      {!showExcel && (
        <div
          className="filter-wrapper"
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          {" "}
          <AddBluePrint
            paperList={paperList}
            subjectList={subjectList}
            gradeList={gradeList}
            boardList={boardList}
            levelList={levelList}
            handleFileSelect={handleFileSelect}
            isUploaded={isUploaded}
            setIsUploaded={setIsUploaded}
            totalMarks={totalMarks}
            totalQuestions={totalQuestions}
            excelDataUpload={excelDataUpload}
            selectedForEdit={selectedForEdit}
            buttonEdit={buttonEdit}
            setButtonEdit={setButtonEdit}
            setSelectedForEdit={setSelectedForEdit}
            setExcelDataUpload={setExcelDataUpload}
            setCreated={setCreated}
            formSelectedBoard={formSelectedBoard}
            setFormSelectedBoard={setFormSelectedBoard}
            formSelectedGrade={formSelectedGrade}
            setFormSelectedGrade={setFormSelectedGrade}
          />
          <BluePrintFilter
            selectedBoard={selectedBoard}
            setSelectedBoard={(i) => {
              setPage(0);
              setSelectedBoard(i);
            }}
            selectedGrade={selectedGrade}
            setSelectedGrade={(i) => {
              setPage(0);
              setSelectedGrade(i);
            }}
            selectedSubject={selectedSubject}
            setSelectedSubject={(i) => {
              setPage(0);
              setSelectedSubject(i);
            }}
            search={search}
            setSearch={(i) => {
              setPage(0);
              setSearch(i);
            }}
            gradeList={allGradeList}
            subjectList={allSubjectList}
            boardList={boardList}
          />
          <BluePrintTab
            Selected={reviewStatus}
            setSelected={(i) => {
              setPage(0);
              setReviewStatus(i);
            }}
          />
          <table className="table-wrapper">
            <BluePrintTablehead
              tableHeadData={tableHeadData}
            />
            <BlueprintTableBody
              blueprintsData={blueprintsData}
              handleToggle={handleToggle}
              setShowDeleteModal={setShowDeleteModal}
              setSelectedForDelete={setSelectedForDelete}
              setSelectedForEdit={setSelectedForEdit}
              setButtonEdit={setButtonEdit}
              pageSize={pageSize}
              totalElements={totalElements}
              currentPage={currentPage}
              onPreviewClick={onPreviewClick}
            />
          </table>
          {blueprintsData?.length > 0 && (
            <div style={{ marginTop: "5px" }}>
              <span>
                <TablePagination
                  style={{
                    bottom: "0",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={totalElements}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </span>
            </div>
          )}
        </div>
      )}
      {showExcel && (
        <SheetTable
          onCancelPreview={onCancelPreview}
          isPreview={isPreview}
          data={excelData}
          handleSave={handleSave}
        />
      )}
      {showDeleteModal ? (
        <DeleteModal
          open={setShowDeleteModal}
          handleClose={() => {
            setShowDeleteModal(false);
            setSelectedForDelete(null);
          }}
          onConfirm={onDeleteConfirm}
        />
      ) : null}
    </DashboardBluePrintLayer>
  );
}
