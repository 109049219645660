import axios from "axios";

async function gradeSectionGet(setGradeSection) {
  let token = sessionStorage.getItem("token");
  let schoolid = sessionStorage.getItem("schoolid");
  let branchid = sessionStorage.getItem("branchid");
  // let gradeId = sessionStorage.getItem("gradeId");
  
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section?schoolId=${schoolid}&branchId=${branchid}`,
    // let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${gradeId}&branchId=${branchid}&schoolId=${schoolid}`,
    
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      if (response.data.length !== 0) {
        // console.log(response.data.data,"xx")
        setGradeSection(response.data.data);
      }
    })
    .catch(err=>{
      // console.log(err)
    })
}

export default gradeSectionGet;
