import React, { useEffect, useState } from "react";
import axios from "axios";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashHome from "../../../../src/img/dashHome.svg";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Alert,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    ListItemText,
    OutlinedInput,
    MenuItem,
    Paper,
    Radio,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { MenuProps, startingYearData } from "../../js/Data";

const AddCourse = (props) => {
    const token = sessionStorage.getItem("token");
    const formatDateTime = (dateTime) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(dateTime);

        return formattedDateTime.replace(/\//g, '-');
    };
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        type: '',
        category: '',
        location: '',
        mode: '',
        status: '',
        createdBy: '',
        description: '',
        createdDate: formatDateTime(new Date())
    })
    const [modifiedDate, setModifiedDate] = useState({});
    const [courseCodes, setCourseCodes] = useState([])
    const [selectedCourse, setSelectedCourse] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        GetLastModifiedAt(setModifiedDate);
        GetCoursetCodeFn()
    }, [])
    const GetCoursetCodeFn = () => {
        let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/courses/getCourses/pages`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
              //console.log(res?.data?.data?.content, "code data")

            })
            .catch(err => console.log(err))
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
      //console.log("New course :", formData);
        try {
            let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/courses`, {
                "courseName": "test course may",
                "code": "testing_update",
                "description": "can change the world",
                "courseStatus": "INACTIVE",
                "application": "Upskill",
                "type": "",
                "category": "",
                "courseMode": "TIMED",
                "prerequistes": [
                    {
                        "course": "Course A",
                        "type": "Mandatory"
                    },
                    {
                        "course": "Course B",
                        "type": "Preferred"
                    }
                ],
                "duration": [
                    {
                        "noOfSessions": 5,
                        "sessionTime": 40
                    }
                ],
                "imageUrl": "www.azvasa.in/template/c1.html",
                "thumbnailUrl": "www.azvasa.in/template/c1.html",
                "currentVersion": [
                    {
                        "versionNo": 3,
                        "createdBy": "xxxx",
                        "createdDate": "17-03-2024 11:37 AM",
                        "createdUserName": "Lalitha",
                        "approvedDate": "17-03-2024 11:37 AM",
                        "approvedBy": "xxxx",
                        "approverUserName": "Lalitha"
                    }
                ],
                "createdBy": "xxxx",
                "createdUserName": "Lalitha",
                "updateHistory": [
                    {
                        "updatedBy": "xxxx",
                        "updatedDate": "17-03-2024 11:37 AM",
                        "updatedUserName": "Veni"
                    }
                ],
                "approvalHistory": [
                    {
                        "approvalStatus": "Pending",
                        "requestDate": "17-03-2024 11:37 AM",
                        "requestor": "xxxx",
                        "requestorName": "Lalitha",
                        "requestorComments": "",
                        "responseDate": "17-03-2024 11:37 AM",
                        "respondor": "xxxx",
                        "respondorName": "Lalitha",
                        "respondercomments": "",
                        "isApproved": "0"
                    }
                ],
                "versionHistory": [
                    {
                        "versionNo": 3,
                        "createdBy": "xxxx",
                        "createdDate": "17-03-2024 11:37 AM",
                        "createdUserName": "Lalitha",
                        "approvedDate": "17-03-2024 11:37 AM",
                        "approvedBy": "xxxx",
                        "approverUserName": "Lalitha"
                    }
                ],
                "tags": {
                    "tags": [
                        "Training",
                        "Teaching"
                    ]
                },
                "certificate": {
                    "templateURL": "www.azvasa.in/template/c1.html"
                }
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    //console.log("successfully saves", response)
                })
                .catch(err => {
                    //console.log("successfully err", err)
                });

            setFormData({
                name: '',
                code: '',
                type: '',
                category: '',
                location: '',
                mode: '',
                status: '',
                createdBy: '',
                description: '',
                createdDate: new Date().toLocaleDateString()
            });

            navigate("/dashboard/upskills");
        } catch (error) {
            // console.error("Error creating new course:", error);
        }
    };

    return (

        <div style={{ width: "100%" }}>
            <article>

                <div style={{ position: 'relative', margin: '14px' }}>
                    <span className={dashboardContentStyles.link_icon}>
                        <img
                            src={dashHome}
                            alt="no img"
                            className={dashboardContentStyles.az_menu_icon}
                        />
                    </span>
                    <span
                        id={`az-breadscrum-home`}
                        className={dashboardContentStyles.az_menu_text}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                            sessionStorage.removeItem("sideNavSubMenuNames");
                            navigate("/dashboard/template");
                        }}
                    >
                        Home
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span
                        className={dashboardContentStyles.az_menu_text}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/dashboard/upskills')
                        }}
                    >
                        Upskills
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span
                        className={dashboardContentStyles.az_selected_menu_text}
                    >
                        AddCourse
                    </span>
                </div>

                <div className={dashboardContentStyles.dashboard_last_updated}>
                    <p>Last Update: {modifiedDate.data}</p>
                </div>
            </article>
            <div className="addCourseForm">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box >
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="name">Course Name </label>
                                    <input type="text" id="name" name="name" onChange={(e) => handleChange(e)} required placeholder="Enter course name" />

                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                {/* <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="code">Course Code </label>
                                    <input type="text" id="code" name="code" onChange={(e) => handleChange(e)} required placeholder="Enter course code" />
                                </div> */}
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="code">Course Code </label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        MenuProps={MenuProps}
                                        input={<OutlinedInput label="Tag" />}
                                        sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                                backgroundColor: 'orange',
                                                color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                                backgroundColor: '#fcedc2',
                                                color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                                backgroundColor: '#fcedc2',
                                                color: '#000000',
                                            },
                                        }}
                                        style={{
                                            borderRadius: "150px",
                                            padding: "4px",
                                            color: selectedCourse ? "orange" : "black"
                                        }}
                                        size={"small"}
                                        IconComponent={(props) => (
                                            <ExpandMoreIcon
                                                {...props}
                                                sx={{ fontSize: "1.5vw" }}
                                            />
                                        )}
                                        value={selectedCourse}
                                        onChange={async (e) => {
                                            //console.log(e.target.value);
                                        }}
                                    >
                                        {courseCodes?.map((item) => {
                                            return (
                                                <MenuItem
                                                    value={item.id}
                                                    key={item.id}
                                                    className={dashboardContentStyles.menu_item}
                                                    sx={{
                                                        "&.Mui-selected": {
                                                            backgroundColor: 'orange',
                                                            color: "#FFFFFF"
                                                        },
                                                        "&.Mui-selected:hover": {
                                                            backgroundColor: '#fcedc2',
                                                            color: "#000000"
                                                        },
                                                        "&:not(.Mui-selected):hover": {
                                                            backgroundColor: '#fcedc2',
                                                            color: '#000000',
                                                        }
                                                    }}
                                                >
                                                    <ListItemText primary={item.discription} />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="type">Type </label>
                                    <input type="text" id="type" name="type" onChange={(e) => handleChange(e)} required placeholder="Enter course code" />
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="category">Category </label>
                                    <input type="text" id="category" name="category" onChange={(e) => handleChange(e)} required placeholder="Enter course code" />
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="location">Location </label>
                                    <input type="text" id="location" name="location" onChange={(e) => handleChange(e)} required placeholder="Enter course code" />
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="mode">Mode </label>
                                    <input type="text" id="mode" name="mode" onChange={(e) => handleChange(e)} required placeholder="Enter course code" />
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="author">Author </label>
                                    <input type="text" id="createdBy" name="createdBy" onChange={(e) => handleChange(e)} required placeholder="Enter author name" />
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="status">Status </label>
                                    <input type="text" id="status" name="status" onChange={(e) => handleChange(e)} required placeholder="Status" />
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <div className="inputDiv">
                                    <label className="inputLabel" htmlFor="description">Description </label>
                                    <textarea type="text" id="description" name="description" onChange={(e) => handleChange(e)} required placeholder="Enter description"></textarea>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <div className="btnGrid">
                        <button onSubmit={(e) => handleSubmit(e)} className="addCBtn" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCourse;