import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import ChapterStyles from "../../css/createChapter.module.css";
import axios from "axios";
import boards_controller_getall from "../../API_CONTROLLER/boards-controller/boards_controller_getall";
import gradeListGet from "../../API/grade-section-mapping-controller/grade_get";
import { blue, orange } from "@mui/material/colors";
import SelectBoardAndGrade from "../../js/QuestionBank/CommonComponents/SelectBoardAndGrade";
import TextEditor from "../../js/QuestionBank/CommonComponents/TextEditor";
import DeleteConfirmationAlert from "../../js/QuestionBank/CommonComponents/DeleteConfirmation";
import DeleteBlanksModal from "../../js/QuestionBank/CommonComponents/DeleteBlanksModal";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import create_question_post from "../../API_CONTROLLER/create-questions/question_post";
import ImageListModal from "../../js/QuestionBank/CommonComponents/ImageListModal";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Preview_MTF_DD from "../../js/QuestionBank/CommonComponents/Preview_MatchTheFollowing_DD";
import Preview_MTF_DL from "../../js/QuestionBank/CommonComponents/Preview_MatchTheFollowing_DL";

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Grid,
    Box,
    TextField,
    Typography,
    styled,
    Modal,
    Paper,
    Select,
    Alert,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import action__Add from "../../../../src/img/AddDefault.svg";
import get_question from "../../API_CONTROLLER/create-questions/get_questionbyid";
import update_question_post from "../../API_CONTROLLER/create-questions/question_put";


const CustomHead = styled(Typography)(({ theme }) => ({
    '&': {
        color: "#FD8C00",
        fontFamily: "Poppins",
        fontSize: "1.3vw",
        fontWeight: 400,
        lineHeight: "27px",
    },
}))

const CustomRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto orange',
        color: orange[600],
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: orange[800],
    },
});

const CustomButton = styled(Button)(({ }) => ({
    color: "#FFFFFF",
    backgroundColor: '#FD8C00',
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    fontSize: '10px',
    borderRadius: '30px',
    width: 'auto',
    padding: "0.6vw 1vw",
    '&:hover': {
        backgroundColor: '#FD8C00',
    },
}));

function CustomRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

const MatchTheFollowing = (props) => {

    const [boardsList, setBoardsList] = useState();
    const [boardSelect, setBoardSelect] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState(false);
    const [gradeList, setGradeList] = useState();
    const [gradeSelect, setGradeSelect] = useState([]);

    const [boardAndGrade, setBoardAndGrade] = useState([]);

    const [boardAndGradeCombo, setBoardAndGradeCombo] = useState([]);

    const [clearContent, setClearContent] = useState(false);
    const [questionContent, setQuestionContent] = useState({ content: "" });
    const [questionEditContent, setEditQuestionContent] = useState({ content: "" });

    const [radioButtonValue, setRadioButtonValue] = useState("machine");
    const [radioButtonEnable, setRadioButtonEnable] = useState(false);

    const [uploadImage, setUploadImage] = useState();
    const [selectedImageName, setSelectedImageName] = useState("");
    const [selectedImageUrl, setSelectedImageUrl] = useState();

    const [imagePreview, setImagePreview] = useState();

    const [openImageListModal, setOpenImageListModal] = React.useState(false);
    const handleImageListModalOpen = () => setOpenImageListModal(true);
    const handleImageListModalClose = () => setOpenImageListModal(false);

    const [clueTypeList, setClueTypeList] = useState(["EXACT_MATCH", "MULTI_ANSWER"]);
    const [selectedClueType, setSelectedClueType] = useState("EXACT_MATCH");
    const [count, setCount] = useState(1);
    const [updateCount, setUpdateCount] = useState([65]);

    const [finalAnswers, setFinalAnswers] = useState(["A"]);
    const [selectedFinalAnswer, setSelectedFinalAnswers] = useState("");
    const [selectedAnswers, setSelectedAnswers] = useState([])

    const [options, setOptions] = useState([]);
    // const [jumbledContent, setJumbledContent] = useState([]);

    const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
    const handlePreviewModalOpen = () => setOpenPreviewModal(true);
    const handlePreviewModalClose = () => setOpenPreviewModal(false);

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [errorField, setErrorField] = useState({});
    const [errorFieldCombo, setErrorFieldCombo] = useState({});
    const [errorCountField, setErrorCountField] = useState({});
    const [errorFieldIndex, setErrorFieldIndex] = useState({});

    const [clearFields, setClearFields] = useState(false);

    const [responseValue, setResponseValue] = useState();
    const [edit, setEdit] = useState(false);
    const [questionValue, setQuestionValue] = useState();
    const [optionsEdit, setOptionsEdit] = useState();
    const [previewFromModal, setPreviewFromModal] = useState(false);
    const [editButton, setEditButton] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [createQuizType, setCreateQuizType] = useState("");
    const [temporaryQuestionText, setTemporaryQuestionText] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showerrorMessage, setShowErroMessage] = useState();
    const subjectId = sessionStorage.getItem("subjectId");
    const conceptId = sessionStorage.getItem("conceptId");
    const subConceptId = sessionStorage.getItem("subConceptId");
    const rootConcept = sessionStorage.getItem("rootConceptId");
    const questionTypeId = sessionStorage.getItem("questionType");
    const taxonomyCategory = sessionStorage.getItem("taxonomyCategory");
    const taxonomy = sessionStorage.getItem("taxonomy");
    const matchType = sessionStorage.getItem("matchType");
    const token = sessionStorage.getItem("token");

    const fileInput = React.useRef();

    const navigate = useNavigate();
    useEffect(() => {
        var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
        //console.log("basic information", basic_info?.boardId, props.selectedGrade)
        // setBoardSelect(basic_info?.boardId);
        // setGradeSelect(props.selectedGrade);
        handleBoardChange(basic_info?.boardId);
        handleGradeChange(props.selectedGrade);
        setTimeout(() => {
            handleBoardAndGradeChange()
        }, 3000);

    }, [])

    useEffect(() => {
        boards_controller_getall(setBoardsList);
        gradeListGet(setGradeList);
        setClearContent(false);
        // onMatchQuestionHandler();
    }, []);

    // const handleBoardChange = (e) => {
    //     setBoardSelect(e.target.value);
    //     setErrorFieldCombo(prevState => {
    //         delete prevState['board'];
    //         return prevState;
    //     })
    // }

    // const handleGradeChange = (e) => {
    //     const value = e.target.value;
    //     setGradeSelect(typeof value === "string" ? value.split(",") : value);
    //     setErrorFieldCombo(prevState => {
    //         delete prevState['grade'];
    //         return prevState;
    //     })
    // }


    const handleBoardChange = (e) => {
        //console.log(e, "handleBoardChange")
        // setBoardSelect(e.target.value)
        setBoardSelect(e)
        setErrorFieldCombo(prevState => {
            delete prevState['board'];
            return prevState;
        })
    }

    const handleGradeChange = (e) => {
        //console.log("handleGradeChange", e)
        // const value = e.target.value;
        const value = e;
        setGradeSelect(typeof value === "string" ? value.split(",") : value);
        setErrorFieldCombo(prevState => {
            delete prevState['grade'];
            return prevState;
        })
    }

    const handleBoardAndGradeChange = () => {
        let errorObj = {};
        let error = false;
        if (boardSelect === "") {
            errorObj['board'] = "Please choose the Board";
            error = true;
        }
        if (gradeSelect.length === 0) {
            errorObj['grade'] = "Please choose atleast one Grade";
            error = true;
        }
        setErrorFieldCombo(errorObj);
        if (!error) {
            setErrorField(prevState => {
                delete prevState['combo'];
                return prevState;
            })
            if (Object.keys(boardAndGradeCombo).length > 0) {
                setErrorField({ message: "", id: "" });
                const foundKey = Object.hasOwn(boardAndGradeCombo, boardSelect);
                if (foundKey) {
                    let found;
                    found = gradeSelect.filter(item => !boardAndGradeCombo[boardSelect].includes(item))
                    const concatArray = boardAndGradeCombo[boardSelect].concat(found);
                    setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: concatArray } });
                    setBoardSelect('');
                    setGradeSelect([]);
                }
                else {
                    setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
                    setBoardSelect('');
                    setGradeSelect([]);
                }
            }
            else {
                setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
                setBoardSelect('');
                setGradeSelect([]);
            }
            gradeSelect.map((grade, ind) => {
                let obj = {
                    "boardId": boardSelect,
                    "gradeId": grade
                };
                setBoardAndGrade(oldArray => [...oldArray, obj]);
            })
        }
    }

    const onRemoveCombo = (boardId, gradeId) => {
        boardAndGrade.map((key, index) => {
            if (key.boardId === boardId && key.gradeId === gradeId) {
                boardAndGrade.splice(index, 1);
            };
        })
        Object.keys(boardAndGradeCombo).map(key => {
            if (key === boardId) {
                boardAndGradeCombo[key].map((value, i) => {
                    if (value === gradeId) {
                        boardAndGradeCombo[key].splice(i, 1);
                        if (boardAndGradeCombo[key].length === 0) {
                            delete boardAndGradeCombo[key];
                            setBoardSelect('');
                            setGradeSelect([]);
                        }
                        else {
                            setBoardSelect(key);
                            setGradeSelect([...boardAndGradeCombo[key]]);
                        }
                    }
                })
            }
        })
    }

    const onQuestionHandler = (content) => {
        //console.log("when typing the question", content)
        setTemporaryQuestionText(content)
        setQuestionContent({ content: content });
        setErrorField(prevState => {
            delete prevState['question'];
            return prevState;
        })
    }

    const imageUploadHandler = (e) => {
        setRadioButtonValue(e.target.value)
        if (e.target.value === "repository") {
            handleImageListModalOpen();
        }
    }

    const onFileChange = (e) => {
        const file = e.target.files[0];
        setUploadImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
        var fileMode = new File([file], file.name, { type: file.type });
        const formData = new FormData();
        formData.append("file", fileMode);

        const file_upload_post = async () => {
            let token = sessionStorage.getItem("token");
            let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=QUESTIONS`, formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(
                    (response) => {
                        setSelectedImageUrl(response.data.data)
                        sessionStorage.setItem("logoUrl", response.data.data)
                    }
                )
                .catch(err => console.log(err))
        }
        file_upload_post();
    }

    const onRemoveImage = (type) => {
        if (type === "upload") {
            setUploadImage();
            setSelectedImageUrl()
            setUploadImage()
        }
        else {
            setImagePreview();
            setSelectedImageUrl();
            setSelectedImageName("");
        }
    }

    useEffect(() => {
        if (uploadImage || selectedImageName) {
            setRadioButtonEnable(true);
        }
        else {
            setRadioButtonEnable(false);
        }
    }, [uploadImage, selectedImageName]);

    const handlerClueType = (e) => {
        setSelectedClueType(e.target.value);
        setErrorField(prevState => {
            delete prevState['clueType'];
            return prevState;
        })
    }

    const countHandler = (operationType) => {
        if (operationType === "add") {
            setCount(count + 1);
            const countUpdate = updateCount[updateCount.length - 1];
            setUpdateCount(oldArray => [...oldArray, (countUpdate) + 1]);
            setErrorCountField(prevState => {
                delete prevState["countOne"]
                return prevState
            })
        }
        else {
            let errorObj = {};
            if (count === 1) {
                errorObj['countOne'] = "Atleast one is required";
                setErrorCountField(errorObj);
            }
            else {
                setErrorCountField(prevState => {
                    delete prevState["countOne"]
                    return prevState
                })
                setCount(count - 1);
                updateCount.pop();
                if (updateCount.length > options.length) {
                    !edit ? options.pop()
                        :
                        optionsEdit.pop()
                }
                selectedAnswers.pop();
            }
        }
    }

    useEffect(() => {
        if (selectedClueType === "EXACT_MATCH") {
            let answers = []
            let forSelect = []
            updateCount.map((count) => {
                let found = -1;
                answers = [...answers, String.fromCharCode(count)];
                found = selectedAnswers.findIndex(question => {
                    return question.order === String.fromCharCode(count);
                })
                if (found !== -1) {
                    return false;
                }
                else {
                    forSelect = [...forSelect, { order: String.fromCharCode(count), selectedValue: "" }];
                }
            })
            setFinalAnswers([...answers]);
            if (forSelect.length > 0) {
                setSelectedAnswers([...selectedAnswers, forSelect[0]]);
            }
        }
        else {
            let answers = []
            updateCount.map(count => {
                answers = [...answers, String.fromCharCode(count)];
            })
            setFinalAnswers([...answers]);
            if (updateCount.length > options.length) {
                !edit ?
                    options.pop()
                    :
                    optionsEdit.pop()
            }
        }
    }, [updateCount.length]);

    const handleCreateQuestion = (type) => {
        setIsBtnEnabled(true);
        setCreateQuizType(type)
        let isError = false;
        let errorObj = {};
        let errorObjIndex = {
            "question": [],
            "jumbledAnswer": [],
            "answers": []
        };

        if (selectedClueType !== "MULTI_ANSWER") {

            let questionContentFoundEmpty = options.some(option => option.question === '' && option.htmlTagQuestion === '');
            let jumbledContentFoundEmpty = options.some(option => option.jumbledAnswer === '' && option.htmlTagJumbled === '');
            let clueContentFoundEmpty = options.some(option => option.answers[0] === '');

            // options.map((answer, i) => {
            //     if (options[i].htmlTagQuestion[0] === '' && options[i].htmlTagJumbled[0] === '') {
            //         errorObjIndex.push(true);
            //     }
            //     else if (options[i].htmlTagQuestion[0] !== '' && options[i].htmlTagJumbled[0] !== '') {
            //         errorObjIndex.push(false);
            //     }
            // })

            const seenQuestions = new Set();
            //console.log("options", options)
            const hasDuplicateQuestions = options.some((option) => {
                if (seenQuestions.has(option.question)) {
                    // Duplicate found
                    return true;
                } else {
                    seenQuestions.add(option.question);
                    return false;
                }
            });

            const hasDuplicateJumbled = options.some((option) => {
                if (seenQuestions.has(option.jumbledAnswer)) {
                    // Duplicate found
                    return true;
                } else {
                    seenQuestions.add(option.jumbledAnswer);
                    return false;
                }
            });

            if (hasDuplicateQuestions) {
                setShowAlert(true)
                setAlertMessage("Question got duplicated please check");
            }
            else if (hasDuplicateJumbled) {
                setShowAlert(true)
                setAlertMessage("Jumbled Answer got duplicated please check");
            }

            options.map(option => {
                if (option.htmlTagQuestion === '') {
                    errorObjIndex["question"].push(true);
                }
                else if (option.htmlTagQuestion !== '') {
                    errorObjIndex["question"].push(false);
                }
                if (option.htmlTagJumbled === '') {
                    errorObjIndex["jumbledAnswer"].push(true);
                }
                else if (option.htmlTagJumbled !== '') {
                    errorObjIndex["jumbledAnswer"].push(false);
                }
                if (option.answers[0] === '') {
                    errorObjIndex["answers"].push(true);
                }
                else if (option.answers[0] !== '') {
                    errorObjIndex["answers"].push(false);
                }
            })

            setErrorFieldIndex(errorObjIndex);
            // Object.keys(boardAndGradeCombo).length === 0 ||
            if (questionContent.content === "" || selectedClueType === "" || questionContentFoundEmpty === true || jumbledContentFoundEmpty === true || clueContentFoundEmpty === true || options.length !== count || hasDuplicateQuestions || hasDuplicateJumbled) {
                isError = true;
                // if (boardAndGradeCombo.length === 0) {
                //     errorObj['combo'] = "Please provide the combination of board and grade";
                // }
                // if (Object.keys(boardAndGradeCombo).length === 0) {
                //     errorObj['combo'] = "Please provide the combination of board and grade";
                // }
                if (questionContent.content === "") {
                    errorObj['question'] = "Please provide the question";
                }
                if (selectedClueType === "") {
                    errorObj['clueType'] = "Please choose one Clue type";
                }
                if (questionContentFoundEmpty === true) {
                    errorObj['questionFoundEmpty'] = "Question content found empty";
                }
                if (jumbledContentFoundEmpty === true) {
                    errorObj['jumbledFoundEmpty'] = "Jumbled content found empty";
                }
                if (clueContentFoundEmpty === true) {
                    errorObj['clueFoundEmpty'] = "Clues content found empty";
                }
                if (options.length !== count) {
                    errorObj['count'] = "Please fill all the fields";
                }
            }
            //console.log("ifffffffffffffffffffff", errorObj)
            setIsBtnEnabled(false);
        }
        else {
            // || boardAndGradeCombo.length === 0 || Object.keys(boardAndGradeCombo).length === 0
            if (questionContent.content === "" || selectedClueType === "") {
                isError = true;
                // if (boardAndGradeCombo.length === 0) {
                //     errorObj['combo'] = "Please provide the combination of board and grade";
                // }
                // if (Object.keys(boardAndGradeCombo).length === 0) {
                //     errorObj['combo'] = "Please provide the combination of board and grade";
                // }
                if (questionContent.content === "") {
                    errorObj['question'] = "Please provide the question";
                }
                if (selectedClueType === "") {
                    errorObj['clueType'] = "Please choose on Clue type";
                }
            }
            //console.log("elseeeeeeeeeeeeeeeee")
        }
        //console.log(errorObj, "errorObj", isError, errorObj == {}, Object.keys(errorObj).length === 0)
        setErrorField(errorObj);

        const callback = (response) => {
            //console.log(response, "<<<<<<< res")
        }

        if (!isError || Object.keys(errorObj).length === 0) {
            setClearContent(false);
            if (type === "update") {
                // setEdit(false);
                const payload1 = {
                    id: questionValue.id,
                    subjectId: subjectId,
                    conceptId: conceptId,
                    subConceptId: subConceptId,
                    rootConceptId: rootConcept !== "undefined" ? rootConcept : null,
                    questionTypeId: questionTypeId,
                    taxonomyCategoryId: taxonomyCategory,
                    taxonomyId: taxonomy,
                    // boardAndGrades: boardAndGrade,
                    boardAndGrade: boardAndGrade.length > 0 ? boardAndGrade : [{ boardId: boardSelect, gradeId: gradeSelect[0] }],
                    question: temporaryQuestionText,
                    explanation: null,
                    imageUrl: selectedImageUrl ? selectedImageUrl : null,
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: false,
                    matchType: matchType,
                    clueType: selectedClueType,
                    questionPattern: "OBJECTIVE",
                    numberOfMatches: count,
                    options: options,
                }
                const payload = removeNewlines(payload1);
                //console.log(">>>", payload);
                update_question_post(payload, setShowSuccessAlert, setResponseValue, setShowAlert, setAlertMessage, props.iwillprovideownCallback);
            }
            else {
                const payload1 = {
                    subjectId: subjectId,
                    conceptId: conceptId,
                    subConceptId: subConceptId,
                    rootConceptId: rootConcept !== "undefined" ? rootConcept : null,
                    questionTypeId: questionTypeId,
                    taxonomyCategoryId: taxonomyCategory,
                    taxonomyId: taxonomy,
                    boardAndGrades: boardAndGrade.length > 0 ? boardAndGrade : [{ boardId: boardSelect, gradeId: gradeSelect[0] }],
                    question: temporaryQuestionText,
                    explanation: null,
                    imageUrl: selectedImageUrl ? selectedImageUrl : null,
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: false,
                    matchType: matchType,
                    clueType: selectedClueType,
                    questionPattern: "OBJECTIVE",
                    numberOfMatches: count,
                    options: options,
                }

                const payload = removeNewlines(payload1);
                //console.log(">>>", payload);
                // create_question_post(payload, setShowSuccessAlert, setResponseValue, setShowAlert, setAlertMessage, props.getResponse);
                Save_question(payload, setShowSuccessAlert, setResponseValue, setShowAlert, setAlertMessage);
            }
            sessionStorage.setItem("matchoptions", JSON.stringify(options));
        } else {
            setIsBtnEnabled(false);
        }
    }
    const Save_question = async (payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage) => {
        //console.log(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage, "Save_question called", props?.selectedQuestionData, props?.selectedQuestionData?.marks, payload?.options?.length)
        if (payload.question !== "") {

            if (props?.selectedQuestionData?.marks !== payload?.options?.length) {
                setErrorField({ "message": "Mark does not match the selected question." });
                setIsBtnEnabled(false);
                setTimeout(() => {
                    setErrorField("")
                }, 5000);
            } else {
                try {
                    let token = sessionStorage.getItem("token");
                    //console.log("payload", payload)
                    let response = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`, payload,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                        });
                    //console.log("response data", response.data.data);
                    // sessionStorage.setItem("newQuestion", JSON.stringify(response.data.data));
                    // setShowSuccessAlert(true);
                    // setResponseValue(response.data.data);
                    // response.data.data.payload = payload;
                    setIsBtnEnabled(false);
                    props.getResponse(response.data.data)
                } catch (error) {
                    //console.log(error, "errrpppppp");
                    // setShowErrorAlert(true);
                    // setShowErroMessage(error?.response?.data?.message || "error");
                    // setResponseValue({ error });
                    // setTimeout(() => {
                    //     setShowErrorAlert(false);
                    //     setShowErroMessage("");
                    //     setResponseValue({});
                    // }, 10000);
                    setIsBtnEnabled(false);
                    setErrorField(error.response.data)
                    setTimeout(() => {
                        setErrorField("")
                    }, 5000);

                }
            }
        } else {
            // callback({ error: "504", errorMessage: "Please fill the Required fields" });
            // alert({ error: "504", errorMessage: "Please fill the Required fields" });
            setErrorField("Please fill the Required fields");
            setIsBtnEnabled(false);
            setTimeout(() => {
                setErrorField("")
            }, 5000);

        }
    }
    const removeNewlines = (obj) => {
        const newObj = {};
        // Recursively traverse the object
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                obj[key] = obj[key].replace(/\n/g, '');
            } else if (typeof obj[key] === 'object') {
                removeNewlines(obj[key]);
            }
        }
        return obj;
    };

    useEffect(() => {
        if (!edit) {
            if (showSuccessAlert) {
                setBoardSelect('');
                setGradeSelect([]);
                setClearContent(true);
                setUploadImage();
                setSuccessMessage(`You have successfully created Match the Following Quesiton Exact Match`);
                setImagePreview();
                setSelectedImageName("");
                setSelectedImageUrl();
                setBoardAndGradeCombo([]);
                setSelectedClueType("EXACT_MATCH");
                options.splice(1);
                options[0].answers = [""];
                setCount(1);
                setUpdateCount([65]);
                setErrorCountField({});
                setBoardAndGrade([]);
                setErrorField({});
                onMatchQuestionHandler();
                setSelectedAnswers([]);
                setEdit(false);
                setShowEditor(false);
                props.setDisableTopFields(false);
            }
        }
        else {
            if (showSuccessAlert) {
                setBoardSelect('');
                setGradeSelect([]);
                setClearContent(true);
                setUploadImage();
                setSuccessMessage(`You have successfully updated Match the Following Quesiton Exact Match`);
                setImagePreview();
                setSelectedImageName("");
                setSelectedImageUrl();
                setBoardAndGradeCombo([]);
                setSelectedClueType("EXACT_MATCH");
                options.splice(1);
                options[0].answers = [""];
                options[0].htmlTagJumbled = "";
                options[0].htmlTagQuestion = "";
                options[0].jumbledAnswer = "";
                options[0].question = "";
                setOptionsEdit();
                setCount(1);
                setUpdateCount([65]);
                setErrorCountField({});
                setBoardAndGrade([]);
                setErrorField({});
                onMatchQuestionHandler();
                setSelectedAnswers([]);
                setEdit(false);
                setShowEditor(false);
                props.setDisableTopFields(false);
            }
        }
    }, [showSuccessAlert]);

    const onFinalAnswerHandler = (e, value, type) => {
        if (type === "exact") {
            const answerKeys = updateCount.map((val, index) => {
                let obj;
                let found = -1;
                let foundSelectedValue = -1;
                if (val === value) {
                    found = options.findIndex(question => {
                        return question.order === String.fromCharCode(value);
                    })
                    foundSelectedValue = options.findIndex(question => {
                        return question.answers[0] === e.target.value;
                    })
                }
                if (foundSelectedValue !== -1) {
                    let errorObj = {}
                    errorObj['existed'] = "This options is already selected";
                    setErrorField({ ...errorField, ...errorObj });
                    return false;
                }
                if (found !== -1) {
                    setErrorField(prevState => {
                        delete prevState['existed'];
                        return prevState;
                    })
                    let newlyAdded = [...options]
                    options[found].answers = [e.target.value];
                    setOptions(newlyAdded);

                    let errorUpdate = { ...errorFieldIndex };
                    if (errorFieldIndex.hasOwnProperty("answers")) {
                        errorFieldIndex["answers"][found] = false
                    }
                    setErrorFieldIndex(errorUpdate)
                }
                else {
                    return obj;
                }
            }).filter(Boolean);
            if (answerKeys.length > 0) {
                setOptions([...options, answerKeys[0]]);
            }
        }
        else {
            const choosed = e.target.value;
            setSelectedFinalAnswers(typeof choosed === 'string' ? choosed.split(',') : choosed);
            const answerKeys = updateCount.map((val, index) => {
                let obj;
                let found = -1;
                if (val === value) {
                    found = options.findIndex(question => {
                        return question.order === String.fromCharCode(value);
                    })
                }
                if (found !== -1) {
                    options[found].answers = typeof choosed === 'string' ? choosed.split(',') : choosed;
                }
                else {
                    return obj;
                }
            }).filter(Boolean);
            if (answerKeys.length > 0) {
                setOptions([...options, answerKeys[0]]);
            }
        }
    }

    const onMatchQuestionHandler = (content, value, type) => {
        options.sort(function (a, b) {
            var textA = a.order.toUpperCase();
            var textB = b.order.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        if (content !== "") {
            function convertHtmlToText(html) {
                const element = document.createElement('div');
                element.innerHTML = html;

                // Check if the element contains only an image
                const imgElement = element.querySelector('img');
                if (imgElement && element.childNodes.length === 1 && imgElement === element.childNodes[0]) {
                    return imgElement.outerHTML; // Return the <img> tag itself if it's the only child
                } else {
                    // Check if there's an <img> tag directly within a <p> tag
                    const imgWithinP = element.querySelector('p > img');
                    if (imgWithinP && imgWithinP.parentNode.tagName.toLowerCase() === 'p') {
                        // Clone the <p> tag and return it along with its content
                        setQuestionContent(prevState => ({ ...prevState, content: imgWithinP.outerHTML }));
                        return imgWithinP.outerHTML;
                    }

                    // Otherwise, iterate through child nodes to check for text content
                    let textContent = '';
                    for (let node of element.childNodes) {
                        if (node.nodeType === Node.TEXT_NODE) {
                            textContent += node.textContent || node.data || ''; // Extract text content of text nodes
                        } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'p') {
                            textContent += node.textContent || node.innerText || ''; // Extract text content of <p> tags
                        }
                    }
                    setQuestionContent(prevState => ({ ...prevState, content: textContent.trim() }));
                    return textContent.trim(); // Trim leading and trailing whitespace
                }
            }

            if (selectedClueType === "EXACT_MATCH") {
                const answerKeys = updateCount.map((val, index) => {
                    let obj;
                    let found = -1;
                    if (val === value) {
                        found = options.findIndex(question => {
                            return question.order === String.fromCharCode(value);
                        })
                        obj = {
                            order: String.fromCharCode(value),
                            question: type === "question" ? convertHtmlToText(content) : "",
                            htmlTagQuestion: type === "question" ? content : "",
                            jumbledAnswer: type === "jumbled" ? convertHtmlToText(content) : "",
                            htmlTagJumbled: type === "jumbled" ? content : "",
                            answers: type === "clues" ? [content] : [],
                        }
                    }
                    if (found !== -1) {
                        if (type === "question") {
                            options[found].question = convertHtmlToText(content)
                            options[found].htmlTagQuestion = content
                            const errorUpdate = { ...errorFieldIndex };
                            if (errorFieldIndex.hasOwnProperty("question")) {
                                errorFieldIndex["question"][found] = false
                            }
                            setErrorFieldIndex(errorUpdate)
                        }
                        else if (type === "jumbled") {
                            options[found].jumbledAnswer = convertHtmlToText(content)
                            options[found].htmlTagJumbled = content;
                            const errorUpdate = { ...errorFieldIndex };
                            if (errorFieldIndex.hasOwnProperty("jumbledAnswer")) {
                                errorFieldIndex["jumbledAnswer"][found] = false
                            }
                            setErrorFieldIndex(errorUpdate)
                        }
                    }
                    else {
                        return obj;
                    }
                }).filter(Boolean);
                if (answerKeys.length > 0) {
                    setOptions([...options, answerKeys[0]]);
                }
            }
            else {
                const answerKeys = updateCount.map((val, index) => {
                    let obj;
                    let found = -1;
                    if (val === value) {
                        found = options.findIndex(question => {
                            return question.order === String.fromCharCode(value);
                        })
                        obj = {
                            order: String.fromCharCode(value),
                            question: type === "question" ? convertHtmlToText(content) : "",
                            htmlTagQuestion: type === "question" ? content : "",
                            jumbledAnswer: type === "jumbled" ? convertHtmlToText(content) : "",
                            htmlTagJumbled: type === "jumbled" ? content : "",
                            answers: type === "clues" ? [content] : [],
                        }
                    }
                    if (found !== -1) {
                        if (type === "question") {
                            options[found].question = convertHtmlToText(content)
                            options[found].htmlTagQuestion = content
                        }
                        else if (type === "jumbled") {
                            options[found].jumbledAnswer = convertHtmlToText(content)
                            options[found].htmlTagJumbled = content
                        }
                        else {
                            options[found].answers = [content]
                        }
                    }
                    else {
                        return obj;
                    }
                }).filter(Boolean);
                if (answerKeys.length > 0) {
                    setOptions([...options, answerKeys[0]]);
                }
            }
        }
    }

    useEffect(() => {
        if (clearFields) {
            setBoardSelect('');
            setGradeSelect([]);
            setClearContent(true);
            setUploadImage();
            setQuestionContent({ content: "" });
            setOptions([]);
            setOptionsEdit([]);
            // setSuccessMessage(`You have successfully created Match the Following Quesiton Multi Answer`);
            setImagePreview();
            setSelectedImageName("");
            setSelectedImageUrl();
            setBoardAndGradeCombo([]);
            setCount(1);
            setUpdateCount([65]);
            setErrorCountField({});
            setBoardAndGrade([]);
            setErrorField({});
            setClearFields(false);
            props.setDisableTopFields(false);
            setEdit(false);
            setShowEditor(false);
            setQuestionValue();
        }
    }, [clearFields])

    const handleEditQuestion = () => {
        get_question(responseValue.id, setQuestionValue);
        setEditButton(true);
        setOptions([]);
    }

    const onHideSuccessModal = () => {
        setShowSuccessAlert(false);
    }

    const onHideModal = () => {
        setShowAlert(false);
    }

    useEffect(() => {
        if (props.questionData) {
            if (!editButton) {
                setQuestionValue(props.questionData);
            }
        }
        if (questionValue) {
            props.setDisableTopFields(true);
            if (questionValue.clueType === "EXACT_MATCH") {
                const answerKeys = questionValue.options[0].map((val, index) => {
                    const preAns = {
                        order: val.order,
                        jumbledAnswer: val.jumbledAnswer,
                        htmlTagJumbled: val.htmlTagJumbled,
                        answers: [val.answers[0]],
                        question: val.question,
                        htmlTagQuestion: val.htmlTagQuestion
                    }
                    return preAns;
                })
                !editButton ?
                    setOptions(...options, answerKeys)
                    :
                    setOptions(answerKeys);
            }
            else {
                const answerKeys = questionValue.options[0].map((val, index) => {
                    const preAns = {
                        order: val.order,
                        jumbledAnswer: val.jumbledAnswer,
                        htmlTagJumbled: val.htmlTagJumbled,
                        answers: val.answers,
                        question: val.question,
                        htmlTagQuestion: val.htmlTagQuestion
                    }
                    return preAns
                })
                !editButton ?
                    setOptions(...options, answerKeys)
                    :
                    setOptions(answerKeys);
            }
            setEdit(true);
            setCount(questionValue.options[0].length);
            setEditQuestionContent({ content: questionValue.question });
            setQuestionContent({ content: questionValue.question });

            // setOptions(questionValue.options[0]);
            setSelectedClueType(questionValue.clueType);
            setOptionsEdit(questionValue.clueType === "EXACT_MATCH" ? questionValue.options[0] : questionValue.options[0]);
            setSelectedImageUrl(questionValue.imageUrl);
            setSelectedImageName(questionValue.imageUrl);
            if (questionValue.imageUrl) {
                setRadioButtonValue("repository");
            }
            let count = [65];
            questionValue.options[0].length > 0 && questionValue.options[0].map((ans, index) => {
                if (index > 0) {
                    const updateCount = count[count.length - 1];
                    count = [...count, updateCount + 1];
                }
            })
            setUpdateCount(count);
            let myObj = [];
            if (!props.caseStudy) {
                questionValue.boardAndGrades.map((boardGrade, ind) => {
                    let obj = {
                        "boardId": boardGrade.boardId,
                        "gradeId": boardGrade.gradeId
                    };
                    setBoardAndGrade(oldArray => [...oldArray, obj]);
                    if (ind !== 0) {
                        setErrorField(prevState => {
                            delete prevState['combo'];
                            return prevState;
                        })
                        const foundKey = Object.hasOwn(myObj, boardGrade.boardId);
                        if (foundKey) {
                            myObj[boardGrade.boardId].push(boardGrade.gradeId);
                            setBoardAndGradeCombo(myObj);
                            setBoardSelect('');
                            setGradeSelect([]);
                        }
                        else {
                            myObj[boardGrade.boardId] = [boardGrade.gradeId];
                            setBoardAndGradeCombo(myObj);
                            setBoardSelect('');
                            setGradeSelect([]);
                        }
                    }
                    else {
                        myObj = { [boardGrade.boardId]: [] };
                        myObj[boardGrade.boardId].push(boardGrade.gradeId);
                        setBoardAndGradeCombo(myObj);
                        setBoardSelect('');
                        setGradeSelect([]);
                    }
                })
            }
        }
    }, [props.questionData, questionValue, editButton]);

    useEffect(() => {
        if (questionEditContent.content !== "") {
            setShowEditor(true);
        }
    }, [questionEditContent]);

    const handleCancelUpdate = () => {
        setIsBtnEnabled(false);
        if (props.fromViewAllQuestion !== undefined) {
            navigate("/dashboard/View All Questions");
        }
        setBoardSelect('');
        setGradeSelect([]);
        setClearContent(true);
        setUploadImage();
        setQuestionContent({ content: "" });
        setOptions([]);
        setOptionsEdit([]);
        // setSuccessMessage(`You have successfully created Match the Following Quesiton Multi Answer`);
        setImagePreview();
        setSelectedImageName("");
        setSelectedImageUrl();
        setBoardAndGradeCombo([]);
        setCount(1);
        setUpdateCount([65]);
        setErrorCountField({});
        setBoardAndGrade([]);
        setErrorField({});
        setClearFields(false);
        props.setDisableTopFields(false);
        setEdit(false);
        setShowEditor(false);
    }

    return (
        <>
            {/* <Grid container spacing={2} sx={{ p: 2 }}>
                <SelectBoardAndGrade
                    boardsList={boardsList}
                    gradeList={gradeList}
                    boardValue={boardSelect}
                    handleBoardChange={handleBoardChange}
                    gradeValue={gradeSelect}
                    handleGradeChange={handleGradeChange}
                    isError={errorFieldCombo}
                />
                <Grid item xs={3} md={3} lg={3}>
                    <img
                        className={
                            dashboardContentStyles.aRoleActionBtn
                        }
                        onClick={handleBoardAndGradeChange}
                        src={action__Add}
                        alt="Add-Btn"
                        style={{ width: "18%", height: "auto" }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    {!!errorField['combo'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['combo']}</Typography>}
                    {Object.keys(boardAndGradeCombo).length > 0 &&
                        <Grid container sx={{ border: "1px solid #B5B6B6", borderRadius: "4px", boxShadow: "0px 3px 6px #00000029" }}>
                            {Object.keys(boardAndGradeCombo).length > 0 ?
                                Object.keys(boardAndGradeCombo).map(key => {
                                    return (
                                        boardsList && boardsList.map(board => {
                                            return (
                                                board.id === key &&
                                                gradeList && gradeList.map(grade => {
                                                    return (
                                                        boardAndGradeCombo[key].map((value, i) => {
                                                            return (
                                                                value === grade.id &&
                                                                <Grid item xs={6} md={4} lg={4} key={key} sx={{ p: 1 }}>
                                                                    <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                                                        {board.board}/{grade.grade}
                                                                        <CloseOutlinedIcon onClick={() => onRemoveCombo(board.id, grade.id)} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                                                    </Typography>
                                                                </Grid>

                                                            )
                                                        })
                                                    )
                                                })
                                            )
                                        })
                                    )
                                })
                                :
                                null
                            }
                        </Grid>
                    }
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                </Grid>
            </Grid> */}
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <CustomHead >Match The Following</CustomHead>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography sx={{ fontSize: "15px", marginBottom: "10px", fontWeight: "700" }}>Question</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                boxShadow: 3,
                                borderRadius: 2,
                            }}
                        >
                            {showEditor && <TextEditor onEditorContentChange={onQuestionHandler} id={"editQuestion"} initialValue={questionEditContent.content} clearContent={clearContent && clearContent} />}
                            {!edit && <TextEditor onEditorContentChange={onQuestionHandler} id={"question"} clearContent={clearContent && clearContent} />}
                        </Box>
                        {!!errorField['question'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['question']}</Typography>}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={radioButtonValue}
                                    onChange={imageUploadHandler}
                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                >
                                    <FormControlLabel disabled={radioButtonEnable} value="machine" control={<CustomRadio />} label="Upload from my machine" sx={{ fontSize: '1.5vw' }} />
                                    <FormControlLabel disabled={radioButtonEnable} value="repository" control={<CustomRadio />} label="Use from repository" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {radioButtonValue === "machine" &&
                            <>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography sx={{ color: '#272727', fontSize: '1vw', fontWeight: '500', fontFamily: 'normal, normal, medium, Poppins' }}>Upload Image</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography>
                                        <input ref={fileInput} onChange={onFileChange} type="file" accept="image/*" style={{ display: 'none' }} disabled={radioButtonEnable} /><CustomButton onClick={() => fileInput.current.click()}><ImageOutlinedIcon fontSize="small" />Choose File</CustomButton>
                                    </Typography>
                                    {uploadImage &&
                                        <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                            {uploadImage && uploadImage.name}
                                            <CloseOutlinedIcon onClick={() => onRemoveImage("upload")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                        </Typography>
                                    }
                                </Grid>
                            </>
                        }
                        {radioButtonValue === "repository" &&
                            <Grid item xs={12} md={12} lg={12}>
                                {selectedImageName &&
                                    <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                        {selectedImageName && selectedImageName.split('/').pop()}
                                        <CloseOutlinedIcon onClick={() => onRemoveImage("repository")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                    </Typography>
                                }
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} sx={{ mt: 2 }}>
                        <Typography>Answer Structure</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <hr />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ px: 5, py: 2 }}>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ width: "18vw" }}>
                        <InputLabel
                            className={ChapterStyles.select_input}
                            id="demo-simple-select-required-label"
                        >
                            *Clue Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            className={ChapterStyles.select_selected}
                            name="taxonomy"
                            value={selectedClueType}
                            onChange={handlerClueType}
                            input={<OutlinedInput label="Tag" />}
                        >
                            {clueTypeList.length > 0 ? (
                                clueTypeList.map((elem, i) => {
                                    return (
                                        <MenuItem key={i} value={elem} className={ChapterStyles.select_menu}>
                                            {elem}
                                        </MenuItem>
                                    );
                                })
                            ) : (
                                <MenuItem>Select</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {errorField["clueType"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["clueType"]}</Typography>}
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper
                        component="form"
                        sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
                    >
                        <IconButton type="button" onClick={() => countHandler("remove")} sx={{ p: '10px' }} aria-label="remove">
                            <RemoveIcon />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={count}
                            inputProps={{ 'aria-label': 'count' }}
                        />
                        <IconButton type="button" onClick={() => countHandler("add")} sx={{ p: '10px' }} aria-label="add">
                            <AddIcon />
                        </IconButton>
                    </Paper>
                    {!!errorCountField["countOne"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["countOne"]}</Typography>}
                </Grid>
            </Grid>
            {/* {selectedClueType === "EXACT_MATCH" ? */}
            <Grid container spacing={4} sx={{ px: 5, py: 2 }}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography>Question</Typography>
                    {updateCount.map((value, index) => {
                        return (
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={1} lg={1} sx={{ alignContent: "center", flexWrap: "wrap", display: "flex" }}>
                                        <Typography>{index + 1})</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={11} lg={11}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                boxShadow: 3,
                                                border: "1px solid #001AF3",
                                                m: 1
                                            }}
                                        >
                                            {showEditor && <TextEditor onEditorContentChange={(content) => onMatchQuestionHandler(content, value, "question")} name={`editQuestion${String.fromCharCode(value)}`} initialValue={edit ? optionsEdit[index] ? optionsEdit[index].htmlTagQuestion : "" : ""} clearContent={clearContent && clearContent} paragraphStyle={true} />}
                                            {!edit && <TextEditor onEditorContentChange={(content) => onMatchQuestionHandler(content, value, "question")} name={`question${String.fromCharCode(value)}`} clearContent={clearContent && clearContent} paragraphStyle={true} />}
                                        </Box>
                                    </Grid>
                                </Grid>
                                {errorField["questionFoundEmpty"] && errorFieldIndex["question"][index] === true && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["questionFoundEmpty"]}</Typography>}
                                {errorField["count"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["count"]}</Typography>}
                            </>
                        )
                    })
                    }
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography>Jumbled Answer</Typography>
                    {updateCount.map((value, index) => {
                        return (
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={1} lg={1} sx={{ alignContent: "center", flexWrap: "wrap", display: "flex" }}>
                                        <Typography>{String.fromCharCode(value)})</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={11} lg={11}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                boxShadow: 3,
                                                border: "1px solid #001AF3",
                                                m: 1
                                            }}
                                        >
                                            {showEditor && <TextEditor onEditorContentChange={(content) => onMatchQuestionHandler(content, value, "jumbled")} name={`editJumbled${String.fromCharCode(value)}`} initialValue={edit ? optionsEdit[index] ? optionsEdit[index].htmlTagJumbled : "" : ""} clearContent={clearContent && clearContent} paragraphStyle={true} />}
                                            {!edit && <TextEditor onEditorContentChange={(content) => onMatchQuestionHandler(content, value, "jumbled")} name={`jumbled${String.fromCharCode(value)}`} clearContent={clearContent && clearContent} paragraphStyle={true} />}
                                        </Box>
                                    </Grid>
                                </Grid>
                                {errorField["jumbledFoundEmpty"] && errorFieldIndex["jumbledAnswer"][index] === true && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["jumbledFoundEmpty"]}</Typography>}
                                {errorField["count"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["count"]}</Typography>}
                            </>
                        )
                    })
                    }
                </Grid>
                {selectedClueType === "EXACT_MATCH" ?
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography>Final Answer</Typography>
                        {updateCount.map((count, index) => {
                            return (
                                <>
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: '200px',
                                                    maxWidth: '100%',
                                                    boxShadow: 3,
                                                    border: "1px solid #001AF3",
                                                    m: 1,
                                                    p: 2
                                                }}
                                            >
                                                <FormControl sx={{ width: "22vw" }}>
                                                    <InputLabel
                                                        className={ChapterStyles.select_input}
                                                        id="demo-simple-select-required-label"
                                                    >
                                                        *Choose Answers
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-required-label"
                                                        id="demo-simple-select-required"
                                                        name={String.fromCharCode(count)}
                                                        value={options.length > 0 && options[index] ? options[index].answers[0] : ""}
                                                        onChange={(e) => onFinalAnswerHandler(e, count, "exact")}
                                                        input={<OutlinedInput label="Tag" />}
                                                    >
                                                        {finalAnswers && finalAnswers.length > 0 ? (
                                                            finalAnswers.map((elem, i) => {
                                                                return (
                                                                    <MenuItem key={i} value={elem}>
                                                                        {elem}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        ) : (
                                                            <MenuItem>Select</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                {errorField["existed"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["existed"]}</Typography>}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {errorField["clueFoundEmpty"] && errorFieldIndex["answers"][index] === true && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["clueFoundEmpty"]}</Typography>}
                                    {errorField["count"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["count"]}</Typography>}
                                </>
                            )
                        })
                        }
                    </Grid>
                    :
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography>Final Answer</Typography>
                        {updateCount.map((count, index) => {
                            return (
                                <>
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: '200px',
                                                    maxWidth: '100%',
                                                    boxShadow: 3,
                                                    border: "1px solid #001AF3",
                                                    m: 2,
                                                    p: 2
                                                }}
                                            >
                                                <FormControl sx={{ width: "22vw" }}>
                                                    <InputLabel
                                                        className={ChapterStyles.select_input}
                                                        id="demo-simple-select-required-label"
                                                    >
                                                        *Choose Answers
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-required-label"
                                                        id="demo-simple-select-required"
                                                        name="subConceptId"
                                                        multiple
                                                        value={options.length > 0 && options[index] ? options[index].answers : []}
                                                        onChange={(e) => onFinalAnswerHandler(e, count, "multi")}
                                                        input={<OutlinedInput label="Tag" />}
                                                    >
                                                        {finalAnswers && finalAnswers.length > 0 ? (
                                                            finalAnswers.map((elem, i) => {
                                                                return (
                                                                    <MenuItem key={i} value={elem}>
                                                                        {elem}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        ) : (
                                                            <MenuItem>Select</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {!!errorField["clueFoundEmpty"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["clueFoundEmpty"]}</Typography>}
                                </>
                            )
                        })
                        }
                    </Grid>
                }
                {
                    showErrorAlert &&
                    <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Alert
                            action={
                                <IconButton
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setShowErrorAlert(false);
                                        setShowErroMessage("")
                                    }}
                                >
                                    <CancelOutlinedIcon />
                                </IconButton>
                            }
                            icon={false}
                            sx={{
                                color: "#FFFEFA",
                                fontWeight: 500,
                                fontSize: "0.93vw",
                                marginRight: "10%",
                                // background: "#bddabca1",
                                background: "red",
                            }}
                        >
                            {showerrorMessage}
                        </Alert>
                    </Grid>
                }
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {errorField.message && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField.message}</Typography>}
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <FormControl sx={{ ml: 1 }}>
                        <button
                            style={{ maxHeight: "50px" }}
                            onClick={handlePreviewModalOpen}
                            className={ChapterStyles.blueWhite_btn}
                        >
                            {"PREVIEW"}
                        </button>
                    </FormControl>
                    {edit ?
                        <>
                            <FormControl sx={{ ml: 1 }}>
                                <button
                                    style={{ maxHeight: "50px" }}
                                    onClick={handleCancelUpdate}
                                    className={ChapterStyles.blue_btn}
                                >
                                    CANCEL
                                </button>
                            </FormControl>
                            <FormControl sx={{ ml: 1 }}>
                                <button
                                    style={{ maxHeight: "50px" }}
                                    disabled={isBtnEnabled}
                                    onClick={() => handleCreateQuestion("update")}
                                    className={ChapterStyles.blue_btn}
                                >
                                    UPDATE
                                </button>
                            </FormControl>
                        </>
                        :
                        <FormControl sx={{ ml: 1 }}>
                            <button
                                style={{ maxHeight: "50px" }}
                                disabled={isBtnEnabled}
                                onClick={() => handleCreateQuestion("create")}
                                className={ChapterStyles.blue_btn}
                            >
                                CREATE
                            </button>
                        </FormControl>
                    }
                </Grid>
            </Grid>
            {/* :
                <MatchTheFollowingMultiAnswer
                    isEdit={edit}
                    setResponseValue={setResponseValue}
                    options={options}
                    updateCount={updateCount}
                    count={count}
                    boardAndGrade={boardAndGrade}
                    combo={boardAndGradeCombo}
                    question={questionContent.content}
                    imagePreview={imagePreview}
                    selectedImageUrl={selectedImageUrl}
                    handleEditQuestion={handleEditQuestion}
                    setErrorField={setErrorField}
                    setClearFields={setClearFields}
                    setShowSuccessAlert={setShowSuccessAlert}
                    setSuccessMessage={setSuccessMessage}
                />
            } */}
            {/* //comment on 25-5-24 */}
            {/* {matchType === "DRAG_DROP" ?
                <Preview_MTF_DD
                    open={openPreviewModal}
                    handlePreviewModalClose={handlePreviewModalClose}
                    options={options}
                    questionContent={questionContent.content}
                    imagePreview={imagePreview}
                    selectedImageUrl={selectedImageUrl}
                    responseValue={responseValue}
                    previewFromModal={previewFromModal}
                    setPreviewFromModal={setPreviewFromModal}
                    temporaryQuestionText={temporaryQuestionText}
                />
                :
                <Preview_MTF_DL
                    open={openPreviewModal}
                    close={handlePreviewModalClose}
                    options={options}
                    questionContent={questionContent.content}
                    imagePreview={imagePreview}
                    selectedImageUrl={selectedImageUrl}
                    responseValue={responseValue}
                    previewFromModal={previewFromModal}
                    setPreviewFromModal={setPreviewFromModal}
                    temporaryQuestionText={temporaryQuestionText}
                />
            }
            <ImageListModal
                isMaps={false}
                openImageModal={openImageListModal}
                closeImageModal={handleImageListModalClose}
                selectedImage={setSelectedImageUrl}
                selectedImageName={setSelectedImageName}
            />
            {/* <DeleteConfirmationAlert
                open={showSuccessAlert}
                close={onHideSuccessModal}
                alert={successMessage}
                caseStudy={true}
            /> */}
            {/* <DeleteBlanksModal
                open={showAlert}
                close={onHideModal}
                alert={alertMessage}
            />
            <DeleteConfirmationAlert
                open={showSuccessAlert}
                close={onHideSuccessModal}
                alert={successMessage}
                caseStudy={true}
                openPreview={handlePreviewModalOpen}
                isEditQuestion={handleEditQuestion}
                setNewConcept={props.setNewConcept}
                setGoToQuestionBank={props.setGoToQuestionBank}
                setPreviewFromModal={setPreviewFromModal}
                setDisableTopFields={props.setDisableTopFields}
                onBackdropClickable={createQuizType === "create" ? true : false}
            /> */}
        </>
    )
}

export default MatchTheFollowing;