import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  darken,
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SearchOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteModal from "./DeleteModal";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import LockIconButton from "../../components/common/LockIconButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import axios from "axios";
import GreenAddButton from "../../components/common/GreenAddButton";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import UpdatedModal from "./UpdatedModal";

//Action Icon Images
import action_Lock from "../../../src/img/password.svg";
import action_Edit from "../../../src/img/Edit.svg";
import action_Share from "../../../src/img/Share.svg";
import action_Delete from "../../../src/img/Delete.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort_1.png";
import { updateUserInfo } from '../../actions/userActions';

const EditAdminUser = () => {
  const dispatch = useDispatch();
  const [adminUsers, setAdminUsers] = useState([]);
  const token = sessionStorage.getItem("token");
  const [isEdit, setIsEdit] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [updatedData, setUpdatedData] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const [userlist, setUserlist] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addAdminVisible, setAddAdminVisible] = useState(true);
  const [s, sets] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mail, setMail] = useState("");
  const [num, setNum] = useState("");
  const [userinputs, setUserinputs] = useState("");
  const [isAddUser, setIsAddUser] = useState(true);
  const [userRoles, setUserRoles] = useState("");
  const [isError, setIsError] = useState("");
  const [showError, setShowError] = useState("");

  //sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");

  const [UserId, setFirstName] = useState(useSelector((state) => state.user.id))
  const [modifiedDate, setModifiedDate] = useState({});


  useEffect(() => {
    initData();
  }, [sortOrder, sortBy]);

  const navigate = useNavigate();
  const prevLocation = useLocation();

  if (prevLocation.state === "edit") {
    setIsAddUser(false);
    setIsEdit(true);
    prevLocation.state = null;
  }

  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.userName?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };


  const [updatedName, setUpdatedName] = useState("");

  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false);
  const closeModal = () => {
    setSuccesUpdatesModal(false);
    navigate("/dashboard/admin%20users");
  };

  const handlerUserInputs = (e) => {
    e.preventDefault();
    setUserinputs({
      ...userinputs,
      [e.target.name]: e.target.value,
    });
  };

  // const handleUserUpdate = (e) => {

  //   if(userinputs.firstName === "" ||
  //     userInputs.lastName === "" ||
  //     userInputs.email === "" ||
  //     userInputs.phoneNumber === "" )

  //     {
  //       setIsError(true);
  //       setShowError("Please enter all the required values");
  //       return;
  //     }

  //   setIsError(false);

  // };

  const hendleRoleSelect = (data) => {
    setUserRoles(data);
  };

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  useEffect(() => {
    setFilterValue(adminUsers);
  }, [adminUsers]);

  const getAllUser = (setUserlist) => {
    let token = sessionStorage.getItem("token");

    let result = axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/admin-roles`,
        // let result = axios.get(`http://3.111.249.182:9004v1/api/master/roles/admin-roles` ,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserlist(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  function update_user_controller(userinputs, callback) {
    let token = sessionStorage.getItem("token");

    if (!userinputs.firstName.trim() ||
      !userinputs.lastName.trim() ||
      !userinputs.email.trim() ||
      !userinputs.phoneNumber.trim()) {
      setIsError(true);
      setShowError("Please enter all the required values");
      callback(false);
      return;


    }

    const errorMessagesPresent = isError;

    if (!errorMessagesPresent) {

      callback(true);
    } else {

      callback(false);
    }

    var item = {
      lmsEnv: process.env.REACT_APP_LMS_ENV,
      id: sessionStorage.getItem("userId"),
      firstName: userinputs.firstName,
      lastName: userinputs.lastName,
      email: userinputs.email.toLowerCase(),
      phoneNumber: userinputs.phoneNumber,
      userRoles: [
        {
          // "roleId": '2c91808582fc425e0182fd8d59130007',
          roleId: userRoles,
          active: true,
        },
      ],
      // "lmsEnv": "DEV"


    };

    setUpdatedName(item.firstName + " " + item.lastName);

    let result = axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data)
        var LoggedId = localStorage.getItem("id");
        // console.log("LoggedId", LoggedId)
        setSuccesUpdatesModal(true);
        if (LoggedId === res.data.data.id) {
          const newFirstName = res.data.data.firstName;
          const newLastName = res.data.data.lastName;
          // Dispatch the action to update user info in the Redux store
          dispatch(updateUserInfo(newFirstName, newLastName, res.data.data.id, res.data.data.userName));
          localStorage.setItem("firstName", newFirstName);
          localStorage.setItem("lastName", newLastName)

        }
      });
  }

  const addAdmin = () => {
    let token = sessionStorage.getItem("token");

    var item = {
      // "id": "ff80818180f65df90180f67e409a0172",
      firstName: fname,
      lastName: lname,
      email: mail.toLowerCase(),
      phoneNumber: num,
      ss: [
        {
          roleId: s,
          active: true,
        },
      ],
      lmsEnv: process.env.REACT_APP_LMS_ENV,
    };

    let result = axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("success",res)
      })
      .catch((err) => console.log(err));

    setAddAdminVisible(false);
  };

  const deleteHandler = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter((data) => data.id !== id);
          setFilterValue(find);
        }
      })
      .catch(function (response) { });
    hideDeleteModal();
    navigate("/dashboard/admin%20users");
  };
  const initData1 = async (UserId) => {
    // console.log(localStorage.getItem("firstName"))
    // console.log(UserId, "==============================")
    const { data } = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/all?pageSize=500&sortOrder=true&sortBy=userName`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.data.data.length > 0) {
      setAdminUsers(data.data.data);
      // console.log(data.data.data, UserId);
      const userid = UserId;
      // const userUserName = useSelector((state) => state.user.userName);
      // console.log(userid,);
      var userdata = data.data.data.filter(user => user.id === userid);
      // console.log(userdata);
      // if (!userid) {

      // } else {
      // console.log("else works")
      const newFirstName = "userdata[0].firstName";
      const newLastName = " userdata[0].lastName";
      // Dispatch the action to update user info in the Redux store
      dispatch(updateUserInfo(newFirstName, newLastName, userid, ""));
      // }
    } else {
      setAdminUsers([]);
    }
  };
  const initData = async () => {
    // console.log(sortOrder, typeof sortOrder, sortBy, typeof sortBy)
    const { data } = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/all?pageSize=500&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.data.data.length > 0) {
      setAdminUsers(data.data.data);
      // console.log(data.data.data, UserId);
      // const userid = UserId;
      // // const userUserName = useSelector((state) => state.user.userName);
      // console.log(userid,);
      // var userdata = data.data.data.filter(user => user.id === userid);
      // console.log(userdata);
      // if (!userid) {

      // } else {
      //   console.log("else works")
      //   const newFirstName = userdata[0].firstName;
      //   const newLastName = userdata[0].lastName;
      //   // Dispatch the action to update user info in the Redux store
      //   dispatch(updateUserInfo(newFirstName, newLastName, userid, ""));
      // }
    } else {
      setAdminUsers([]);
    }
  };

  // function get_userBy_id() {}

  useEffect(() => {
    // get_userBy_id()
    let token = sessionStorage.getItem("token");

    let result = axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/admin-users/${sessionStorage.getItem("userId")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserinputs(res.data.data);
        setUserRoles(res.data.data.roles[0].id);
      })
      .catch((err) => console.log(err));
  }, [sessionStorage.getItem("userId")]);

  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);

  useEffect(() => {
    getAllUser(setUserlist);
    setFilterValue(adminUsers);
  }, []);

  const textFieldStyles = {
    fontSize: "14px !important",
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important"
      }
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#ffa500 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#B5B6B6 !important",
      fontSize: "14px"
    },
    "& .MuiInputBase-root": {
      height: "48px !important",
      width: "270px !important",
      borderRadius: "150px !important",
    },
    "& .MuiInputBase-input": {
      color: "#ffa500",
    }
  };

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              className={dashboardContentStyles.az_menu_icon}

            />
          </span>

          <span
            id={`az-breadscrum-home`}
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate("/dashboard/admin%20users");
            }}

          >
            Admin User
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }} ></i>
          </span>
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Edit Admin User
          </span>

        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>

      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addAdminVisible && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Admin Role" : "Add Admin Role"}
                </div>
              </div>
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    padding: "20px 20px",
                  }}
                >
                  <div>
                    <Box style={{ margin: "auto 1px", width: "247px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Admin Role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Admin role"
                          // value={userinputs?.firstName}
                          // inputProps={{
                          //   value:userinputs?.roles&&userinputs?.roles[0]&&userinputs?.roles[0]?.role
                          // }}
                          sx={{
                            "& .css-10hburv-MuiTypography-root": {
                              fontSize: "12px !important",
                            },
                            "&.MuiOutlinedInput-root": {
                              "& > fieldset": {
                                border: "1px solid #D6D5DF !important"
                              }
                            },
                            "&.MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                border: "1px solid #272727 !important"
                              }
                            },
                            fontSize: "14px !important",
                            borderRadius: "150px !important"
                          }}
                          value={userRoles ?? ""}
                          onChange={(data) => {
                            hendleRoleSelect(data.target.value);
                          }}
                          style={{ background: "#fff" }}
                        //   MenuProps={MenuProps}
                        >
                          <MenuItem
                            value=""
                            className={dashboardContentStyles.menuitem}
                            style={{ fontSize: "13px", paddingLeft: "20px" }}

                          >
                            All
                          </MenuItem>
                          {userlist.length > 0 ? (
                            userlist?.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                className={dashboardContentStyles.dropdowns}
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: '#FFA500',
                                    color: "#FFFFFF"
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: '#FFA500',
                                    color: "#000000"
                                  },
                                  "&:not(.Mui-selected):hover": {
                                    backgroundColor: '#fcedc2',
                                    color: '#000000',
                                  },
                                }}
                              >
                                <ListItemText
                                  primaryTypographyProps={{ fontSize: "13px" }}
                                  className={
                                    dashboardContentStyles.listitemtext
                                  }
                                  primary={item.role}
                                />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>Select</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      //   label="First Name"
                      name="firstName"
                      value={userinputs.firstName}
                      onChange={handlerUserInputs}
                      sx={textFieldStyles}
                    />
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      //   label="Last Name"
                      name="lastName"
                      value={userinputs.lastName}
                      onChange={handlerUserInputs}
                      sx={textFieldStyles}


                    />
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      //   label="Primary Email"
                      name="email"
                      value={userinputs.email}
                      onChange={handlerUserInputs}
                      sx={textFieldStyles}
                    />
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      //   label="Primary Phone no"
                      name="phoneNumber"
                      value={userinputs.phoneNumber}
                      onChange={handlerUserInputs}
                      sx={textFieldStyles}
                    />
                  </div>
                  <div
                    style={{ color: 'red', display: isError ? "block" : "none" }}
                  >
                    {showError}
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => {
                        // setAddAdminVisible(false);
                        // setIsEdit(false);
                        navigate("/dashboard/admin%20users");
                      }}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {/* {isEdit ? (
                      <BlueButton>Update</BlueButton>
                    ) : ( */}
                    <BlueButton
                      // onClick={(e) => {
                      //   update_user_controller(userinputs);

                      //   // initData1();
                      //   setTimeout(() => {
                      //     navigate("/dashboard/admin%20users");
                      //     // Do some stuff here
                      //   }, 2000);
                      //   // initData()

                      //   // setAddAdminVisible(false);
                      // }}
                      // disabled={roleDetail.hasRoleError}

                      onClick={(e) => {
                        update_user_controller(userinputs, (updateSuccessful) => {
                          if (updateSuccessful) {
                            //console.log("successfully updated admin user")
                            // navigate("/dashboard/admin%20users");
                            // Do some stuff here

                          }
                        });
                      }}
                    >
                      UPDATE
                    </BlueButton>
                    {/* )} */}
                  </div>
                </div>
              </TableContainer>
            </div>

          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
              <span>Admin User List</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                type={"text"}
                placeholder={"Search...."}
                onChange={onSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />

              {/* <GreenAddButton
                onClick={() => {
                  setAddAdminVisible(true);
                  // navigate('/dashboard/CreateAdminUser')
                }}
              >
                <AddCircleOutlineOutlinedIcon style={{ marginRight: "10px" }} />
                Add Admin Role
              </GreenAddButton> */}
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Name
                      <img
                        className="cursor-pointer"
                        width="11"
                        height="16"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 1px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("userName");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Role
                      <img
                        className="cursor-pointer"
                        width="11"
                        height="16"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 1px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("role");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Email
                      <img
                        className="cursor-pointer"
                        width="11"
                        height="16"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 2px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("email");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Mobile
                      <img
                        className="cursor-pointer"
                        width="11"
                        height="16"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 1px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("phoneNumber");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },

                          backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {
                              borderLeft: "0",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },
                        }}

                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={dashboardContentStyles.table_cell}
                        >
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {row?.firstName + row?.lastName}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {row.roles.length &&
                            row.roles.map((v, i) => {
                              return <span key={i}>{v.role}</span>;
                            })}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {row?.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={dashboardContentStyles.table_cell}
                        >
                          {row?.phoneNumber}
                        </TableCell>
                        <TableCell align="right">
                          <div>
                            <Switch
                              checked={row?.active}
                            // onChange={(e) => switchHandler(row?.id, e)}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <LockIconButton>
                            <CustomTooltip title="Change Password" placement={"top"}>
                              {/* <LockOutlinedIcon style={{ fontSize: "25px" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_Lock}
                                alt="Dlt-btn"
                              />
                            </CustomTooltip>
                          </LockIconButton>
                          <EditIconButton
                            onClick={(e) => {
                              e.preventDefault();
                              var userId = row.id;
                              // navigate('/dashboard/CreateAdminuser', { state: "edit" })
                              sessionStorage.setItem("userId", userId);
                            }}
                          >
                            <CustomTooltip title="Edit" placement={"top"}>
                              {/* <EditOutlinedIcon style={{ fontSize: "25px" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_Edit}
                                alt="Dlt-btn"
                              />
                            </CustomTooltip>
                          </EditIconButton>
                          <ShareIconButton>
                            <CustomTooltip title="Share " placement={"top"}>
                              {/* <ShareOutlinedIcon style={{ fontSize: "25px" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_Share}
                                alt="Dlt-btn"
                              />
                            </CustomTooltip>
                          </ShareIconButton>
                          {/* <DeleteIconButton
                            onClick={() => {
                              showDeleteModal(row);
                            }}
                          >
                            <Tooltip title="Remove" placement={"top"}> */}
                          {/* <DeleteOutlinedIcon
                                style={{ fontSize: "25px" }}
                              /> */}
                          {/* <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_Delete}
                                alt="Dlt-btn"
                              />
                            </Tooltip>
                          </DeleteIconButton> */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterValue?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Admin User"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem?.firstName}
        />
        <UpdatedModal
          userType={"Admin User"}
          userName_Modal={
            updatedName
          }
          open={succesUpdatesModal}
          handleOk={closeModal}
        // close={closeModal}
        />
      </div>
    </>
  );
};

export default EditAdminUser;