import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import YellowButton from './yellowButton';
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import crossIcon from "../../img/cross icon.svg";

const BoardModal = ({ open, close, boards }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    // height:300,
    backgroundColor: "white",
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{padding:"10px", marginLeft:"40px"}}>{boards.schoolName}</h3>
            <img className="cursorPointer" style={{ padding: "15px" }} src={crossIcon} onClick={close} />
          </div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650,marginLeft:"40px" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{color:"#5563FF",borderBottom:"0"}}>Branch Name</TableCell>
                  <TableCell sx={{color:"#5563FF",borderBottom:"0"}}>Location</TableCell>
                  <TableCell sx={{color:"#5563FF",borderBottom:"0"}}>Grades</TableCell>
                  <TableCell sx={{color:"#5563FF",borderBottom:"0"}}>Total Students</TableCell>
                  <TableCell sx={{color:"#5563FF",borderBottom:"0"}}>Board</TableCell>
                  <TableCell sx={{color:"#5563FF",borderBottom:"0"}}>Plan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {boards.branches?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell> */}
                    <TableCell  align="left" sx={{borderBottom:"0"}}>{row.name}</TableCell>
                    <TableCell sx={{borderBottom:"0"}}>{row.locality}</TableCell>
                    <TableCell sx={{borderBottom:"0"}}>{row.gradeCount}</TableCell>
                    <TableCell sx={{borderBottom:"0"}}>{row.numberOfStudents}</TableCell>
                    <TableCell sx={{borderBottom:"0"}}>{row.board}</TableCell>
                    <TableCell sx={{borderBottom:"0"}}>{row.plan}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </>
  );
};

export default BoardModal;
