import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from "react";
import {
  quizOverviewParentDiv,
  absenteesPaper,
  absenteesStyles,
} from "./styles";
import { Box, Divider, Grid, Paper } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Bar } from "react-chartjs-3";
import { BarChart, Tooltip, XAxis, YAxis, Bar, Cell, Text, ResponsiveContainer, LabelList } from "recharts";
import StudentCountSvg from "../../../../img/StudentCount.svg";
import StudentCompletedQuizSvg from "../../../../img/StudentCompletedQuiz.svg";
import QuizAttendanceSvg from "../../../../img/QuizAttendance.svg";
import QuizReleasedSvg from "../../../../img/QuizReleased.svg";
import ReleasedDateSvg from "../../../../img/ReleaseDate.svg";
import TimeTaken from "../../../../img/TimeTaken.svg"
import { styled } from "@mui/material/styles";

const staticOverviewData = {
  "quizId": "",
  "quizName": "",
  "studentCountInSection": 0,
  "studentCompletedQuiz": 0,
  "quizAttendance": '0%',
  "noOfTimesQuizReleased": 0,
  "noOfQuestions": 0,
  "noOfCaseStudies": 0,
  "firstReleaseDate": "DD/MM/YYYY",
  "latestReleaseDate": "DD/MM/YYYY",
  "averageTimeTaken": "-",
  "averageTimeTakenByQuestion": "-",
  "taxonomyCalculations": []
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  padding: "6px",
  border: "1px solid #757575",
}));

const ChartItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: "20px",
  border: "1px solid #757575",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const style = {
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  height: "185px",
  overflowY: "Scroll",
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "white",
    position: "relative",
    display: "block",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    backgroundColor: "#ffffff",
  },
  "&::-webkit-scrollbar-track": { backgroundColor: "rgba(245, 85, 127, 0.89)" },
};

// icons to display in top tiles like student count in section, student completed quiz, no. of question etc.
const iconsForReport = [
  { icon: () => <></> },
  { icon: () => <></> },
  {
    icon: () => (
      <img
        src={StudentCountSvg}
        alt={'Student Count'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
  {
    icon: () => (
      <img
        src={StudentCompletedQuizSvg}
        alt={'Student Completed Quiz'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
  {
    icon: () => (
      <img
        src={QuizAttendanceSvg}
        alt={'Quiz Attendance'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
  {
    icon: () => (
      <img
        src={QuizReleasedSvg}
        alt={'Quiz Released'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
  { icon: () => <></> },
  { icon: () => <></> },
  {
    icon: () => (
      <img
        src={ReleasedDateSvg}
        alt={'First Release Date'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
  {
    icon: () => (
      <img
        src={ReleasedDateSvg}
        alt={'Last Release Date'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
  {
    icon: () => (
      <img
        src={TimeTaken}
        alt={'Avg. Time Taken To Complete Quiz'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
  {
    icon: () => (
      <img
        src={TimeTaken}
        alt={'Avg. Time Taken To Complete Question'}
      // style={{
      //   fontSize: "3vw",
      //   background: "rgb(202 28 99)",
      //   borderRadius: "55%",
      // }}
      />
    ),
  },
];

// datas and labels, options for slider starts here
const settingsData = {
  dots: true,
  infinite: true,
  speed: 700,
  slidesToShow: 3,
  slidesToScroll: 3,
};

const options = {
  responsive: true,
  scales: {
    yAxes: [
      {
        display: false,
        stacked: false,
        ticks: {
          min: 0,
          max: 120,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        }
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 10,
          fontColor: "#2D3962",
          fontFamily: "Poppins",
          fontWeight: 800,
          padding: 10,
          maxRotation: 0,
          autoSkip: false,
        },
        gridLines: {
          color: "#767676",
          drawOnChartArea: false,
          lineWidth: 2,
          drawTicks: false
        },
      },
    ],
  },
  plugins: {
    tooltip: {
      callbacks: {
        title: function (items) {
          if (items.length > 0) {
            const label = items[0].raw.label || '';
            return label.replaceAll(',', '-');
          }
          return '';
        },
      },
    },
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          return Math.round(value) + "%";
        }
      },
      display: true,
      color: "#423d5c",
      align: "end",
      anchor: "end",
      font: { size: "13", weight: 400 },
    },
  },
  legend: {
    display: false,
  },
};
const chartLabelData = [
  {
    id: 1,
    label: "Concept",
    background: "#439240",
  },
  {
    id: 2,
    label: "Sub concept",
    background: "#352fc8",
  },
  {
    id: 3,
    label: "Root concept",
    background: "#05e999",
  },
];

const QuizOverview = (props) => {
  const { loading, graphLoading } = props;
  const slidRef = useRef(null);
  const styleParentDiv = quizOverviewParentDiv(0, [28, 45, 119], -8);
  const styleParentDivWithoutValue = quizOverviewParentDiv();

  const [labels, setLabels] = useState([
    "concept",
    "sub concept",
    "root concept",
  ]);

  const [sectionAvgData, setSectionAvgData] = useState([]);
  const [globalAvgData, setGlobalAvgData] = useState([]);
  const [graphBgColor, setGraphBgColor] = useState([
    "#439240",
    "#352fc8",
    "#05e999",
  ]);

  const [chapter, setChapter] = useState({});
  const [chapterName, setChapterName] = useState("");
  const [subConceptCount, setSubConceptCount] = useState(0);
  const [subconcept_boxes, setSubConceptBoxes] = useState([]);
  const [chartWidth, setChartWidth] = useState(600);
  const [numOfConcepts, setNumOfConcepts] = useState(0);

  const sectionData = {
    labels,
    datasets: [
      {
        label: "#",
        data: sectionAvgData,
        backgroundColor: graphBgColor,
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
    ],
  };
  const globalData = {
    labels,
    datasets: [
      {
        label: "#",
        data: globalAvgData,
        backgroundColor: graphBgColor,
        barPercentage: 0.7,
        categoryPercentage: 0.4,
      },
    ],
  };

  const tooltipContentStyle = {
    width: 'auto',
    height: '60px',
    background: '#f0f0f0',
    border: '1px solid #ccc',
    fontSize: '14px',
    textAlign: 'left',
    padding: '5px',
  };

  useEffect(() => {
    if (props.averageGraphData) {
      generateGraphs();
    }
    if (props.globalAvgData) {
      generateGlobalGraph();
    }

    if (props.chapterConcepts) {
      setSubConceptBoxes(getSliderContent());
      let temp_obj = {};
      let index = 0;
      props.chapterConcepts?.map((x) => {
        if (x?.subConcepts?.length > 3) {
          let count = x?.subConcepts?.length;
          while (count > 0) {
            count -= 3;
            temp_obj[index] = x?.concept;
            index += 1;
          }
        } else if (x?.subConcepts?.length > 0) {
          temp_obj[index] = x?.concept;
          index += 1;
        }
      });
      setChapter(temp_obj);
      setChapterName(chapter[0]);
    }
  }, [props]);

  {/*
  const generateGraphs = useCallback(() => {
    let temp_section_graph = [];
    let temp_global_graph = [];
    props.averageGraphData.section_avg.concepts.map((x) => {
      let temp_label = [];
      let bg_color = [];
      let temp_section_scores = [];

      temp_label.push(x.concept_name);
      bg_color.push("#439240");
      temp_section_scores.push(x.score);
      if (x.sub_concepts.length) {
        x.sub_concepts.map((y) => {
          temp_label.push(y.sub_concept_name);
          bg_color.push("#352fc8");
          temp_section_scores.push(y.score);
          if (y.root_concepts) {
            y.root_concepts.map((z) => {
              temp_label.push(z.root_concept_name);
              bg_color.push("#05e999");
              temp_section_scores.push(z.score);
            });
          }
        });
      }
      let section_data = {
        labels: temp_label,
        datasets: [
          {
            label: "#",
            data: temp_section_scores,
            backgroundColor: bg_color,
            barPercentage: 0.7,
            categoryPercentage: 0.4,
          },
        ],
      };

      temp_section_graph.push(
        <Bar
          key={x.concept_name}
          options={options}
          data={section_data}
          width={40}
          height={20}
        />
      );
    });

    props.averageGraphData.global_avg.concepts.map((x) => {
      let temp_label = [];
      let bg_color = [];
      let temp_global_scores = [];
      temp_label.push(x.concept_name);
      bg_color.push("#439240");
      temp_global_scores.push(x.score);
      if (x.sub_concepts.length) {
        x.sub_concepts.map((y) => {
          temp_label.push(y.sub_concept_name);
          bg_color.push("#352fc8");
          temp_global_scores.push(y.score);
          if (y.root_concepts) {
            y.root_concepts.map((z) => {
              temp_label.push(z.root_concept_name);
              bg_color.push("#05e999");
              temp_global_scores.push(z.score);
            });
          }
        });
      }
      let global_data = {
        labels: temp_label,
        datasets: [
          {
            label: "#",
            data: temp_global_scores,
            backgroundColor: bg_color,
            barPercentage: 0.7,
            categoryPercentage: 0.4,
          },
        ],
      };
      temp_global_graph.push(
        <Bar
          key={x.concept_name}
          options={options}
          data={global_data}
          width={40}
          height={20}
        />
      );
    });

    setGlobalAvgData(temp_global_graph);
    // setGraphBgColor(bg_color);
    // setLabels(temp_label);
    setSectionAvgData(temp_section_graph);
  }, [props?.averageGraphData])
  */}

  const calculateChartWidth = (screenWidth, dataLength) => {
    if (dataLength > 0) {
      if (screenWidth < 1000) {
        // console.log(screenWidth, '1');
        if (dataLength <= 4) return 350;
        if (dataLength <= 7) return 680;
        if (dataLength <= 10) return 750;
        return 850;
      } else if (screenWidth <= 1200) {
        // console.log(screenWidth, '2');
        if (dataLength <= 4) return 350;
        if (dataLength <= 7) return 580;
        if (dataLength <= 10) return 792;
      } else if (screenWidth <= 1336) {
        // console.log(screenWidth, '3');
        if (dataLength <= 4) return 396;
        if (dataLength <= 7) return 580;
        if (dataLength <= 10) return 792;
      } else if (screenWidth <= 1440) {
        // console.log(screenWidth, '4');
        if (dataLength <= 5) return 432;
        if (dataLength <= 7) return 580;
        if (dataLength <= 14) return 900;
      } else {
        // console.log(screenWidth, '5');
        // if (dataLength <= 5) return 432;
        if (dataLength <= 7) return 580;
        if (dataLength <= 14) return 900;
        return 1000;
      }
    } else {
      // console.log(screenWidth, '5');
      return 590;
    }
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newWidth = calculateChartWidth(screenWidth, numOfConcepts);
      // console.log(newWidth);
      setChartWidth(newWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [numOfConcepts, setChartWidth, chartWidth]);

  const generateGraphs = useCallback(() => {
    let temp_section_graph = [];
    let temp_label = [];
    let bg_color = [];
    let temp_section_scores = [];
    let temp_label_v = [];
    let bg_color_V = [];
    let temp_section_scores_V = [];
    // console.log("props.averageGraphData", props.averageGraphData)
    // props.averageGraphData?.map((x) => {
    //   x?.score !== null && temp_label.push(x?.conceptName);
    //   x?.score !== null && bg_color.push("#439240");
    //   x?.score !== null && temp_section_scores.push(x?.score);
    //   console.log(x?.subConceptReportResponseDtoList)
    //   if (x?.subConceptReportResponseDtoList?.length) {
    //     const groupedBySubConceptName = x?.subConceptReportResponseDtoList?.reduce((acc, curr) => {
    //       const { subConceptName, score } = curr;
    //       if (!acc[subConceptName]) {
    //         acc[subConceptName] = { totalScore: 0, count: 0 };
    //       }
    //       acc[subConceptName].totalScore += score;
    //       acc[subConceptName].count += 1;
    //       return acc;
    //     }, {});
    //     const result = Object.entries(groupedBySubConceptName).map(([name, data]) => {
    //       const averageScore = Math.round(data.totalScore / data.count);
    //       return {
    //         subConceptName: name,
    //         score: averageScore
    //       };
    //     });
    //     console.log(result, "result===========");
    //     x.subConceptReportResponseDtoList = result;
    //     console.log(x?.subConceptReportResponseDtoList, result, "average formal overview")
    //     x?.subConceptReportResponseDtoList?.map((y) => {
    //       y?.score !== null && temp_label.push(y?.subConceptName);
    //       y?.score !== null && bg_color.push("#352fc8");
    //       y?.score !== null && temp_section_scores.push(y?.score);
    //       if (y?.rootConceptReportResponseDtoList) {
    //         y?.rootConceptReportResponseDtoList.map((z) => {
    //           temp_label.push(z?.rootConceptName);
    //           bg_color.push("#05e999");
    //           temp_section_scores.push(z?.score);
    //         });
    //       }
    //     });
    //   }
    // });

    const groupedData = props?.averageGraphData.reduce((acc, item) => {
      const subConcepts = item.subConceptReportResponseDtoList;

      subConcepts.forEach(subConcept => {
        const { subConceptName, score } = subConcept;

        if (!acc[subConceptName]) {
          acc[subConceptName] = { count: 0, totalScore: 0 };
        }

        // Increment count and total score
        acc[subConceptName].count += 1;
        acc[subConceptName].totalScore += score;
      });

      return acc;
    }, {});

    // Calculating the average score for each group
    const result = Object.keys(groupedData).map(subConceptName => {
      const group = groupedData[subConceptName];
      const averageScore = group.totalScore / group.count;

      return {
        subConceptName,
        averageScore: Math.round(averageScore) // Rounded to nearest whole number
      };
    });

    // console.log(result);

    result?.map((y) => {
      y?.score !== null && temp_label_v.push(y?.subConceptName);
      y?.score !== null && bg_color_V.push("#352fc8");
      y?.score !== null && temp_section_scores_V.push(y?.averageScore);
      if (y?.rootConceptReportResponseDtoList) {
        y?.rootConceptReportResponseDtoList.map((z) => {
          temp_label_v.push(z?.rootConceptName);
          bg_color_V.push("#05e999");
          temp_section_scores_V.push(z?.averageScore);
        });
      }
    });

    // console.log("temp_label", temp_label, temp_section_scores, bg_color)
    // console.log("temp_labellllllllll", temp_label_v, temp_section_scores_V, bg_color_V)


    const data = temp_label_v?.map((label, index) => ({
      label,
      score: temp_section_scores_V[index],
      bg_color: bg_color_V[index]
    }));
    setNumOfConcepts(data.length);

    const AxisTick = (props) => {
      const { x, y, payload } = props;
      const words = payload.value.split(" ");
      return (
        <g transform={`translate(${x},${y})`}>
          {words.map((word, index) => (
            <Text
              key={index}
              fontSize="8px"
              x={0}
              y={index * 12}
              dy={16}
              textAnchor="middle"
              fill="#666"
            >
              {word}
            </Text>
          ))}
        </g>
      );
    };

    const renderCustomizedLabel = (props) => {
      const { x, y, width, height, value } = props;
      const radius = 0;

      return (
        <g>
          <text
            x={x + width / 2}
            y={y - 10}
            fill="#2D3962"
            fontWeight={600}
            fontSize={13}
            textAnchor="middle"
            dominantBaseline="top"
          >
            {Math.round(value)}% {/* //--roundoff--  */}
          </text>
        </g>
      );
    };

    temp_section_graph.push(
      <ResponsiveContainer
        width={chartWidth}
        height={300}
      >
        <BarChart
          width={chartWidth}
          height={300}
          data={data}
          margin={{ bottom: 30, left: -80 }}
          key={data?.label}
        >
          <XAxis
            dataKey="label"
            textAnchor="middle"
            tick={<AxisTick />}
            tickLine={{ display: 'none' }}
            interval={0}
            angle={0}
            padding={{ left: 20, right: 20 }}
          >
          </XAxis >
          <YAxis
            domain={[0, 120]}
            tick={{ display: 'none' }}
            tickLine={{ display: 'none' }}
            axisLine={{ display: 'none' }}
          />
          <Tooltip
            contentStyle={tooltipContentStyle}
            formatter={(value) => [`${Math.round(value)} %`]}
          />
          <Bar
            dataKey="score"
            barSize={30}
          >
            {
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.bg_color} />
              ))
            }
            <LabelList dataKey="score" content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
    setSectionAvgData(temp_section_graph);
  }, [props?.averageGraphData, chartWidth, setNumOfConcepts]);

  const generateGlobalGraph = useCallback(() => {
    let temp_global_graph = [];
    let temp_label = [];
    let bg_color = [];
    let temp_global_scores = [];
    let temp_label_v = [];
    let bg_color_V = [];
    let temp_global_scores_v = [];
    // console.log("global average data", props.globalAvgData)
    // props.globalAvgData?.map((x) => {
    //   x?.score !== null && temp_label.push(x?.conceptName);
    //   x?.score !== null && bg_color.push("#439240");
    //   x?.score !== null && temp_global_scores.push(x?.score);
    //   if (x?.subConceptReportResponseDtoList?.length) {
    //     const groupedBySubConceptName = x?.subConceptReportResponseDtoList?.reduce((acc, curr) => {
    //       const { subConceptName, score } = curr;
    //       if (!acc[subConceptName]) {
    //         acc[subConceptName] = { totalScore: 0, count: 0 };
    //       }
    //       acc[subConceptName].totalScore += score;
    //       acc[subConceptName].count += 1;
    //       return acc;
    //     }, {});
    //     const result = Object.entries(groupedBySubConceptName).map(([name, data]) => {
    //       const averageScore = Math.round(data.totalScore / data.count);
    //       return {
    //         subConceptName: name,
    //         score: averageScore
    //       };
    //     });
    //     console.log(result);
    //     x.subConceptReportResponseDtoList = result;
    //     console.log(x?.subConceptReportResponseDtoList, result, "global formal overview")
    //     x?.subConceptReportResponseDtoList?.map((y) => {
    //       y?.score !== null && temp_label.push(y?.subConceptName);
    //       y?.score !== null && bg_color.push("#352fc8");
    //       y?.score !== null && temp_global_scores.push(y?.score);
    //       if (y?.rootConceptReportResponseDtoList) {
    //         y?.rootConceptReportResponseDtoList.map((z) => {
    //           temp_label.push(z?.rootConceptName);
    //           bg_color.push("#05e999");
    //           temp_global_scores.push(z?.score);
    //         });
    //       }
    //     });
    //   }
    // });

    const groupedData = props?.averageGraphData.reduce((acc, item) => {
      const subConcepts = item.subConceptReportResponseDtoList;

      subConcepts.forEach(subConcept => {
        const { subConceptName, score } = subConcept;

        if (!acc[subConceptName]) {
          acc[subConceptName] = { count: 0, totalScore: 0 };
        }

        // Increment count and total score
        acc[subConceptName].count += 1;
        acc[subConceptName].totalScore += score;
      });

      return acc;
    }, {});

    // Calculating the average score for each group
    const result = Object.keys(groupedData).map(subConceptName => {
      const group = groupedData[subConceptName];
      const averageScore = group.totalScore / group.count;

      return {
        subConceptName,
        averageScore: Math.round(averageScore) // Rounded to nearest whole number
      };
    });

    // console.log(result);

    result?.map((y) => {
      y?.score !== null && temp_label_v.push(y?.subConceptName);
      y?.score !== null && bg_color_V.push("#352fc8");
      y?.score !== null && temp_global_scores_v.push(y?.averageScore);
      if (y?.rootConceptReportResponseDtoList) {
        y?.rootConceptReportResponseDtoList.map((z) => {
          temp_label_v.push(z?.rootConceptName);
          bg_color_V.push("#05e999");
          temp_global_scores_v.push(z?.averageScore);
        });
      }
    });

    // console.log("temp_labelglobal", temp_label, temp_global_scores, bg_color)
    // console.log("temp_labellllllllllglobal", temp_label_v, temp_global_scores_v, bg_color_V)

    const data = temp_label_v?.map((label, index) => ({
      label,
      score: temp_global_scores_v[index],
      bg_color: bg_color_V[index]
    }));

    const AxisTick = (props) => {
      const { x, y, payload } = props;
      const words = payload.value.split(" ");
      return (
        <g transform={`translate(${x},${y})`}>
          {words.map((word, index) => (
            <Text
              key={index}
              fontSize="8px"
              x={0}
              y={index * 12}
              dy={16}
              textAnchor="middle"
              fill="#666"
            >
              {word}
            </Text>
          ))}
        </g>
      );
    };

    const renderCustomizedLabel = (props) => {
      const { x, y, width, height, value } = props;
      const radius = 0;

      return (
        <g>
          <text
            x={x + width / 2}
            y={y - 10}
            fill="#2D3962"
            fontWeight={600}
            fontSize={13}
            textAnchor="middle"
            dominantBaseline="top"
          >
            {Math.round(value)}%{/* ----roundoff---- */}
          </text>
        </g>
      );
    };

    temp_global_graph.push(
      <ResponsiveContainer
        width={chartWidth}
        height={300}
      >
        <BarChart
          width={chartWidth}
          height={300}
          data={data}
          key={data?.label}
          margin={{ bottom: 30, left: -80 }}
        >
          <XAxis
            dataKey="label"
            textAnchor="middle"
            tick={<AxisTick />}
            tickLine={{ display: 'none' }}
            interval={0}
            angle={0}
            padding={{ left: 20, right: 20 }}
          >
          </XAxis >
          <YAxis
            domain={[0, 120]}
            tick={{ display: 'none' }}
            tickLine={{ display: 'none' }}
            axisLine={{ display: 'none' }}
          />
          <Tooltip
            contentStyle={tooltipContentStyle}
            formatter={(value) => [`${Math.round(value)} %`]}
          />
          <Bar
            dataKey="score"
            barSize={30}
          >
            {
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.bg_color} />
              ))
            }
            <LabelList dataKey="score" content={renderCustomizedLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );

    setGlobalAvgData(temp_global_graph);
  }, [props.globalAvgData, chartWidth, setNumOfConcepts]);

  const getRemainingBoxes = (remaining) => {
    let temp = [];
    while (remaining) {
      temp.push(
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            marginRight: "10%",
            height: "125px",
            overflowY: "scroll",
            "::-webkit-scrollbar": {
              display: "block",
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "#fff",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "gray",
              borderRadius: "4px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "darkgray",
            },
          }}
          key={Math.random() * Math.random()}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: 600,
            }}
            key={Math.random() * Math.random()}
          ></div>
        </Box>
      );
      remaining--;
    }
    return temp;
  };

  const getSliderContent = () => {
    let remaining_boxes = [];
    let subconcept_boxes = [];
    {
      props.chapterConcepts?.length > 0 &&
        props.chapterConcepts?.map((item, index) => {
          let remaining = 0;
          const validSubConcepts = item?.subConcepts?.filter(item => item !== null);
          if (validSubConcepts?.length != 0 && validSubConcepts?.length >= 3) {
            let reminder = item?.subConcepts?.length % 3;
            remaining = reminder > 0 ? 3 - reminder : reminder;
          } else if (validSubConcepts?.length > 0) {
            remaining = 3 - validSubConcepts?.length;
          }

          if (remaining > 0) {
            remaining_boxes = getRemainingBoxes(remaining);
          }

          validSubConcepts?.length > 0 &&
            validSubConcepts?.map((subConcept, sub_index) => {
              subconcept_boxes.push(
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    marginRight: "10%",
                    height: "125px",
                    borderRight: item?.subConcepts?.length - 1 === sub_index
                      ? 0 : '1px solid #7e7e7e',
                    overflowY: "scroll",
                    "::-webkit-scrollbar": {
                      display: "block",
                      width: "5px",
                    },
                    "::-webkit-scrollbar-track": {
                      backgroundColor: "#fff",
                    },
                    "::-webkit-scrollbar-thumb": {
                      backgroundColor: "gray",
                      borderRadius: "4px",
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "darkgray",
                    },
                  }}
                  key={index}
                >
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      marginLeft: '10px',
                      textTransform: 'capitalize'
                    }}
                    key={subConcept?.id}
                  >
                    <span
                      style={{
                        marginBottom: '10px',
                        textAlign: 'center',
                        fontSize: 14
                      }}
                    >
                      SUB CONCEPT {" - "}
                    </span>
                    {subConcept?.subConcept}
                  </div>
                  {subConcept?.hasOwnProperty("rootConceptResponses") &&
                    subConcept?.rootConceptResponses?.map((rootConcept) => {
                      return (
                        <div
                          style={{
                            fontSize: 15,
                            marginLeft: '10px'
                          }}
                          key={rootConcept?.id}
                        >
                          <span
                            style={{
                              marginBottom: '10px',
                              textAlign: 'center',
                              fontSize: 14
                            }}
                          >
                            ROOT CONCEPT {" - "}
                          </span>
                          {rootConcept?.rootConcept}
                        </div>
                      );
                    })}
                </Box>
              );
              if (sub_index + 1 == validSubConcepts?.length && remaining > 0) {
                subconcept_boxes = subconcept_boxes.concat(remaining_boxes);
              }
            });
        });
    }
    setSubConceptCount(subconcept_boxes?.length);
    return subconcept_boxes;
  };

  function formatDate(inputDate) {
    const parts = inputDate?.toString()?.split('/');
    return parts?.join('-');
  }

  const simplifiedTime = (time) => {
    const parts = time?.split(' ');

    let hours = null;
    let minutes = null;
    let seconds = null;

    if (parts?.length) {
      for (const part of parts) {
        if (part.endsWith('Sec')) {
          seconds = part;
        } else if (part.endsWith('Mins') && part !== '00Mins') {
          minutes = part;
        } else if (part.endsWith('Hrs') && part !== '00Hrs') {
          hours = part;
        }
      }
    }


    return `${hours ? hours : ''} ${minutes ? minutes : ''} ${seconds}`
  }

  return (
    <>
      {/* {loading &&
        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          FETCHING...
        </div>
      } */}
      {/* {!loading && */}
      <div style={styleParentDivWithoutValue}>
        <div style={styleParentDiv}>
          {Object.keys(props.quizOverviewData).length ?
            <>
              <div
                style={{
                  fontWeight: "bolder",
                  fontSize: "1.14vw",
                  textTransform: "capitalize",
                  marginLeft: '10px'
                }}
              >
                {props.quizName
                  ? props.quizName
                  : 'Loading...'}
              </div>
              {/* grid to display in top tiles like student count in section, student completed quiz, no. of question etc. */}
              <Grid container spacing={2} sx={{ width: "100%", marginLeft: '-20px' }}>
                {Object.entries(props.quizOverviewData ? props.quizOverviewData : {})?.map(([key, value], index) => {
                  const modifiedKey =
                    key.charAt(0).toUpperCase() +
                    key
                      .slice(1)
                      .replace(/([A-Z])/g, " $1")
                      .trim();
                  return (
                    key !== "quizId" &&
                    key !== "quizName" && (
                      <>
                        {key !== "taxonomyCalculations" && modifiedKey && <Grid item xs={12} md={3} sm={4} key={key} sx={{ paddingLeft: '30px !important', paddingTop: '30px !important' }}>
                          <Item sx={{
                            border: '1px solid #BEBEBE',
                            boxShadow: '0px 3px 6px #00000014'
                          }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                columnGap: "10px",
                                alignItems: "center",
                                maxHeight: "100px",
                                padding: "6px 0px 0px 10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "5px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="quizOverviewTiles"
                                  style={{
                                    color: "rgba(0,0,0,0.93)",
                                    fontWeight: `${(modifiedKey === 'No Of Questions' || modifiedKey === 'No Of Case Studies')
                                      ? 700
                                      : (modifiedKey === 'First Release Date' || modifiedKey === 'Latest Release Date')
                                        ? 600
                                        : 500}`,
                                    textAlign: "start",
                                  }}
                                >
                                  {key !== "taxonomyCalculations" &&
                                    modifiedKey === 'Student Count In Section'
                                    ? 'Students In Sections'
                                    : modifiedKey === 'No Of Times Quiz Released'
                                      ? 'No. Of Times Quiz Released'
                                      : modifiedKey === 'No Of Questions'
                                        ? 'No. Of Questions'
                                        : modifiedKey === 'No Of Case Studies'
                                          ? 'No. Of Case Studies'
                                          : modifiedKey === 'Average Time Taken'
                                            ? 'Avg. Time Taken By Class To Complete Quiz'
                                            : modifiedKey === 'Average Time Taken By Question'
                                              ? 'Avg. Time Taken Per Question By Class'
                                              : modifiedKey
                                  }
                                </div>
                                <div
                                  style={{
                                    fontWeight: 700,
                                    fontSize: "1.04vw",
                                    color: "rgb(28,45,119)",
                                    textAlign: "start",
                                  }}
                                >
                                 
                                  {key !== "taxonomyCalculations" &&
                                    (key === "quizAttendance"
                                      ? typeof (value) === "string"
                                        ? value
                                        : `${Number.isInteger(value)
                                          ? value
                                          : Math.round(value)}%`
                                      : key === "firstReleaseDate"
                                        || key === "latestReleaseDate"
                                        ? formatDate(value)
                                        : key === "noOfTimesQuizReleased"
                                          ? value === null ? 0 : value
                                          : key === "averageTimeTaken" ||
                                            key === "averageTimeTakenByQuestion"
                                            ? value === null ? "-" : value //simplifiedTime(value)
                                            : value)}
                                </div>
                              </div>
                              {/* {iconsForReport.map(icon => {
                                return( */}
                              <div
                                style={{
                                  width: "30%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  className={`${(index === 2 || index === 3) ? 'quizTileIcon' : 'quizTileIcons'}`}
                                  style={{
                                    // background: data?.background,
                                    // padding: "1px",
                                    borderRadius: 150,
                                    width: "3.1vw",
                                    height: "3.1vw",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    // minWidth: "3.64vw",
                                  }}
                                >
                                  {iconsForReport[index]?.icon()}
                                </div>
                              </div>
                              {/* )
                              })
                            } */}
                            </div>
                          </Item>
                        </Grid>}
                        {key === "taxonomyCalculations" && (
                          <Grid item xs={12} md={6} sm={8} sx={{ paddingLeft: '30px !important', paddingTop: '30px !important' }}>
                            <Item sx={{
                              border: '1px solid #BEBEBE',
                              boxShadow: '0px 3px 6px #00000014'
                            }}>
                              <div
                                className="taxonomyTileBox"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "5px",
                                  alignItems: "start",
                                  padding: "6px 10px 0 10px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "rgba(0,0,0,0.93)",
                                    fontWeight: 700,
                                    fontSize: "0.8vw",
                                    textAlign: "start",
                                  }}
                                >
                                  Taxonomy:
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "93%",
                                    justifyContent: "space-between",
                                    columnGap: "20px",
                                    overflowX: 'auto',
                                  }}
                                >
                                  {value?.map((val) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "5px",
                                        }}
                                      >
                                        <div
                                          className="taxonomyText"
                                          style={{
                                            color: "rgba(0,0,0,0.93)",
                                            fontWeight: 500,
                                            width: '100px'
                                          }}
                                        >
                                          {val?.taxonomy}
                                        </div>
                                        <div
                                          className="taxonomyText"
                                          style={{
                                            color: "rgba(0,0,0,0.93)",
                                            fontWeight: 700,
                                            width: '100px'
                                          }}
                                        >
                                          {val?.percentage}% |{" "}
                                          {val?.taxonomyQuestionCount}Qs
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </Item>
                          </Grid>
                        )}
                      </>
                    )
                  );
                })}
              </Grid>
              {/* grid for tiles ends here */}
            </> :
            <>
              <div
                style={{
                  fontWeight: "bolder",
                  fontSize: "1.14vw",
                  textTransform: "capitalize",
                  marginLeft: '10px'
                }}
              >
                {props.quizName
                  ? props.quizName
                  : 'NO DATA AVAILABLE'}
              </div>
              {/* grid to display in top tiles like student count in section, student completed quiz, no. of question etc. */}
              <Grid container spacing={2} sx={{ width: "100%", marginLeft: '-20px' }}>
                {Object.entries(staticOverviewData)?.map(([key, value], index) => {
                  const modifiedKey =
                    key.charAt(0).toUpperCase() +
                    key
                      .slice(1)
                      .replace(/([A-Z])/g, " $1")
                      .trim();
                  return (
                    key !== "quizId" &&
                    key !== "quizName" && (
                      <>
                        {key !== "taxonomyCalculations" && modifiedKey && <Grid item xs={12} md={3} sm={4} key={key} sx={{ paddingLeft: '30px !important', paddingTop: '30px !important' }}>
                          <Item sx={{
                            border: '1px solid #BEBEBE',
                            boxShadow: '0px 3px 6px #00000014'
                          }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                columnGap: "10px",
                                alignItems: "center",
                                maxHeight: "100px",
                                padding: "6px 0px 0px 10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "5px",
                                  width: "100%",
                                }}
                              >
                                {/* {
                                  //console.log("modifiedKey",modifiedKey,"value ",value)
                                } */}
                                <div
                                  className="quizOverviewTiles"
                                  style={{
                                    color: "rgba(0,0,0,0.93)",
                                    fontWeight: `${(modifiedKey === 'No Of Questions' || modifiedKey === 'No Of Case Studies')
                                      ? 700
                                      : (modifiedKey === 'First Release Date' || modifiedKey === 'Latest Release Date')
                                        ? 600
                                        : 500}`,
                                    textAlign: "start",
                                  }}
                                >
                                  {modifiedKey === 'Student Count In Section'
                                    ? 'Students In Sections'
                                    : modifiedKey === 'No Of Times Quiz Released'
                                      ? 'No. Of Times Quiz Released'
                                      : modifiedKey === 'No Of Questions'
                                        ? 'No. Of Questions'
                                        : modifiedKey === 'No Of Case Studies'
                                          ? 'No. Of Case Studies'
                                          : modifiedKey === 'Average Time Taken'
                                            ? 'Avg. Time Taken By Class To Complete Quiz'
                                            : modifiedKey === 'Average Time Taken By Question'
                                              ? 'Avg. Time Taken Per Question By Class'
                                              : modifiedKey
                                  }
                                </div>
                                <div
                                  style={{
                                    fontWeight: 700,
                                    fontSize: "1.04vw",
                                    color: "rgb(28,45,119)",
                                    textAlign: "start",
                                  }}
                                >
                                  {value}
                                </div>
                              </div>
                              {/* {iconsForReport.map(icon => {
                                return( */}
                              <div
                                style={{
                                  width: "30%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  className={`${(index === 2 || index === 3) ? 'quizTileIcon' : 'quizTileIcons'}`}
                                  style={{
                                    // background: data?.background,
                                    // padding: "1px",
                                    borderRadius: 150,
                                    width: "3.1vw",
                                    height: "3.1vw",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    // minWidth: "3.64vw",
                                  }}
                                >
                                  {iconsForReport[index]?.icon()}
                                </div>
                              </div>
                              {/* )
                              })
                            } */}
                            </div>
                          </Item>
                        </Grid>}
                        {key === "taxonomyCalculations" && (
                          <Grid item xs={12} md={6} sm={8} sx={{ paddingLeft: '30px !important', paddingTop: '30px !important' }}>
                            <Item sx={{
                              border: '1px solid #BEBEBE',
                              boxShadow: '0px 3px 6px #00000014'
                            }}>
                              <div
                                className="taxonomyTileBox"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "5px",
                                  alignItems: "start",
                                  padding: "6px 10px 0 10px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "rgba(0,0,0,0.93)",
                                    fontWeight: 700,
                                    fontSize: "0.8vw",
                                    textAlign: "start",
                                  }}
                                >
                                  Taxonomy:
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    columnGap: "20px",
                                    overflowX: 'auto'
                                  }}
                                >
                                  {value?.map((val) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "5px",
                                        }}
                                      >
                                        <div
                                          className="taxonomyText"
                                          style={{
                                            color: "rgba(0,0,0,0.93)",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {val?.taxonomy}
                                        </div>
                                        <div
                                          className="taxonomyText"
                                          style={{
                                            color: "rgba(0,0,0,0.93)",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {val?.percentage}% |{" "}
                                          {val?.taxonomyQuestionCount}Qs
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </Item>
                          </Grid>
                        )}
                      </>
                    )
                  );
                })}
              </Grid>
              {/* grid for tiles ends here */}
            </>
          }
        </div>
        {/* grid for top 5 and bottom 5, absentees */}
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item xs={12} md={4} sm={6} sx={{ flexBasis: '35% !important', maxWidth: '35% !important', paddingLeft: '20px !important' }}>
            <Paper
              style={{
                width: "100%",
                // background: "#d6f742",
                background: "rgb(202 225 97)",
                padding: "15px",
                color: "black",
                overflow: "hidden",
                maxHeight: "255px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <div style={{ fontWeight: 700 }}>Top 5 Student Names</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    minHeight: "225px",
                    padding: '0 17px'
                  }}
                >
                  {/* {
                  //console.log(props.studentPerformanceReport,"props.studentPerformanceReport")
                } */}
                  {props.studentPerformanceReport?.topStudents?.map((data) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                        key={data?.studentId}
                      >
                        <div style={{ textTransform: 'capitalize' }}>{data?.studentName}</div>
                        <div
                          style={{ fontWeight: "600", width: '45px' }}
                        >{`${data?.percentage}%`}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} sm={6} sx={{ flexBasis: '35% !important', maxWidth: '35% !important', paddingLeft: '20px !important' }}>
            <Paper
              style={{
                width: "100%",
                background: "#38e3e9",
                padding: "15px",
                maxHeight: "255px",
                color: "black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <div style={{ fontWeight: 700 }}>Bottom 5 Student Names</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    minHeight: "225px",
                    padding: '0 17px'
                  }}
                >
                  {props.studentPerformanceReport?.bottomStudents?.map((data) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                        key={data?.studentId}
                      >
                        <div style={{ textTransform: 'capitalize' }}>{data?.studentName}</div>
                        <div
                          style={{ fontWeight: "600", width: '45px' }}
                        >{`${data?.percentage}%`}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} sm={6} sx={{ flexBasis: '30% !important', paddingLeft: '16px !important' }}>
            <Paper style={absenteesPaper}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                  padding: '0 17px'
                }}
              >
                <div style={{ fontWeight: 500 }}>Absentees</div>
                <Box sx={style}>
                  {props.studentPerformanceReport?.absentStudents?.map((data) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                        key={data?.studentId}
                      >
                        <div style={{ fontWeight: 300, textTransform: 'capitalize' }}>{data?.studentName}</div>
                      </div>
                    );
                  })}
                </Box>
              </div>
            </Paper>
          </Grid>
        </Grid>
        {/* top 5, bottom 5, absentees grid ends here */}
        {/* grid for chapter concepts slider */}
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item xs={12} md={12} sm={12}>
            <Paper
              sx={{
                padding: "15px 40px",
                border: "1px solid #757575",
                minHeight: '240px',
                position: 'relative'
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                  color: "rgb(28,45,119)",
                }}
              >
                <div
                  style={{
                    fontWeight: "800",
                    fontSize: "1.14vw",
                    marginBottom: "15px",
                    textTransform: 'capitalize'
                  }}
                >
                  {chapterName &&
                    <>
                      <span
                        style={{
                          textAlign: 'center',
                          display: 'inline-block',
                          marginRight: '5px',
                          fontSize: 14
                        }}
                      >CONCEPT {" - "}</span>
                      {chapterName}
                    </>
                  }
                </div>
                <div className="slider__conatiner" style={{ padding: "0% 2%" }}>
                  <i
                    className="fas fa-chevron-left slider-backward-arrow"
                    onClick={() => slidRef?.current?.slickPrev()}
                    style={{
                      top: '36%',
                      left: '1%'
                    }}
                  />
                  <i
                    className="fas fa-chevron-right slider-forward-arrow"
                    onClick={() => slidRef?.current?.slickNext()}
                    style={{
                      top: '37%',
                      right: '1%'
                    }}
                  />
                  <Slider
                    ref={slidRef}
                    {...settingsData}
                    afterChange={(e) => {
                      setChapterName(chapter[e / 3]);
                    }}
                  >
                    {subconcept_boxes}
                  </Slider>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
        {/* slider grid ends here */}
        {/* grid for section and global average chart */}
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item xs={12} md={12} sm={12}>
            <ChartItem>
              <Grid item xs={12} md={5} sm={12}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      color: "#3e54f8",
                      fontWeight: 700,
                      textAlign: "left",
                      fontSize: "22px",
                      marginBottom: 10
                    }}
                  >
                    Section Average
                  </div>
                  <div className={`scrollbar scroll-smooth ${props.averageGraphData?.length <= 1 ? 'removeScrollbar' : ''}`}>
                    {graphLoading &&
                      <div
                        style={{
                          position: 'relative',
                          fontSize: '18px',
                          fontWeight: 500,
                          color: '#9b9fa7',
                          opacity: 0.8,
                          height: 200,
                          margin: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        FETCHING...
                      </div>
                    }
                    {!graphLoading && sectionAvgData}
                  </div>
                  {/* <Divider style={{ border: "1px solid" }} /> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "5px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      marginTop: '10px'
                    }}
                  >
                    {chartLabelData?.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "5px",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              background: item?.background,
                              borderRadius: 10,
                            }}
                          />
                          <div
                            style={{
                              fontSize: "0.78vw",
                              fontWeight: 600,
                            }}
                          >
                            {item?.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={5} sm={12}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      color: "#3e54f8",
                      fontWeight: 700,
                      textAlign: "left",
                      fontSize: "22px",
                      marginBottom: 10
                    }}
                  >
                    Global Average
                  </div>
                  <div className={`scrollbar scroll-smooth ${props.averageGraphData?.length <= 1 ? 'removeScrollbar' : ''}`}>
                    {globalAvgData}
                  </div>
                  {/* <Divider style={{ border: "1px solid" }} /> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "5px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      marginTop: '10px'
                    }}
                  >
                    {chartLabelData?.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "5px",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              background: item?.background,
                              borderRadius: 10,
                            }}
                          />
                          <div
                            style={{
                              fontSize: "0.78vw",
                              fontWeight: 600,
                            }}
                          >
                            {item?.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
            </ChartItem>
          </Grid>
        </Grid>
        {/* chart grid ends here */}
      </div>
      {/* } */}
    </>
  );
};
export default QuizOverview;
