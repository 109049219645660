import axios from 'axios'

async function create_caseStudy_question_post(
    payload,
    setShowSuccessAlert,
    setResponseValue,
    setShowErrorAlert,
    setShowErroMessage,
    callback
) {
    // console.log("create_caseStudy_question_post called", payload,
    //     setShowSuccessAlert,
    //     setResponseValue,
    //     setShowErrorAlert,
    //     setShowErroMessage,
    //     callback);
    let token = sessionStorage.getItem("token");
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`, payload,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        .then(
            (response) => {
                setShowSuccessAlert(true);
                sessionStorage.setItem("parentId", response.data.data.id);
                setResponseValue(response.data.data);
                if (callback) {
                    callback(response.data.data);
                }
            }
        )
        .catch((err) => {
            // console.log(err);
            if (callback) {
                callback(err)
            }
        })
}

export default create_caseStudy_question_post;
