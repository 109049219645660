export const isNOTNullOrUndefined = (value) => {
  return !isNullOrUndefined(value);
};

export const isNullOrUndefined = (value) => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "null" ||
    value === "undefined"
  );
};

export const truncateString = (str, limit) => {
  if (isNullOrUndefined(str)) {
    return str;
  }

  if (limit === null) {
    limit = 10;
  }
  if (str.length > limit) {
    return str.slice(0, limit) + "...";
  } else {
    return str;
  }
};

export const nameHandler = (name) => {
  // console.log("handler", name)
  const data = name.split("/")?.[name.split("/").length - 1]?.split(".");
  // return `${data[0]}.${data?.[data.length - 1]}`;
  // return `${data[0]}`;
  var str = data[0];
  return `${str[0].toUpperCase()}${str.slice(1)}`;

};
