import React, { useState, useEffect } from "react";
import { Box, Modal, Typography, Grid, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
import Green100Button from "../../components/common/Green100Button";
import axios from "axios";
import CSPForm from "./../css/csppg34.module.css";
import { Tabs } from "antd";
import AccessPassport from "./AccessPassport";
import ReadingPassportAccess from "./ReadingPassportAccess";
import { data } from "jquery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
};
const TeacherProfileUpdateModal = ({
  open,
  addAcess,
  close,
  selectedProfile,
  teachergrade,
  grades,
  planId,
  show,
}) => {
  const [userProfile, setuserProfile] = useState();
  const [gradeList, setGradelist] = useState([]);
  const [checklist, setChecklist] = useState({});
  const [message, setMessage] = useState("");
  const token = sessionStorage.getItem("token");
  const [teachergradeupdate, setTeacherGrade] = useState([]);
  const [tabsView, setTabsView] = useState(true);
  const [accessEdit, setAccessEdit] = useState(false);
  const [readingAccessEdit, setReadingAccessEdit] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState({});
  const [selectedGradeSections, setSelectedGradeSections] = useState([]);
  const [readingPassportList, setReadingPassportList] = useState([]);
  const [selectedReadingAccess, setSelectedReadingAccess] = useState([])
  const [defaultReadingPassportList, setDefaultReadingPassportList] = useState([])
  const [schoolStrengthData, setSchoolStrengthData] = useState()
  const [type, setType] = useState("classTeacher")
  const [isBtnEnabled, setIsBtnEnabled] = useState(false);
  const TOKEN = sessionStorage.getItem('token');

  const userId = sessionStorage.getItem('userId');

  const Id = sessionStorage.getItem("id");

  const BASE_URL = process.env.REACT_APP_SWAGGER_URL;

  // const fetchSchoolStrength = async () => {
  //   let data1;
  //   let data2;
  //   if (selectedProfile.userId !== null & selectedProfile.userId !== undefined) {
  //     try {
  //       await axios
  //         .get(`${BASE_URL}v1/api/user/readingPassportAccess/${selectedProfile.userId}`, {
  //           headers: {
  //             Authorization: `Bearer ${TOKEN}`,
  //           },
  //         })
  //         .then((response) => {
  //         //console.log("type", response.data.data.librarian)
  //           setType(response.data.data.librarian ? "librarian" : "classTeacher");
  //         //console.log(response.data.data,"response.data.data")
  //           data1 = response.data.data.access?.grades;
  //         //console.log("data1",data1)
  //           setDefaultReadingPassportList(response.data)
  //         })
  //         .catch((error) => console.error(error,"errorrrrrrr"));
  //       await axios
  //         .get(`${BASE_URL}v1/api/user/teachers/school-strength/${Id}`, {
  //           headers: {
  //             Authorization: `Bearer ${TOKEN}`,
  //           },
  //         })
  //         .then((response) => {
  //           setSchoolStrengthData(response.data)
  //           data2 = response.data.data[0].grades
  //         //console.log("dddddddddd", data1, data2)
  //           if (data1) {
  //           //console.log("dddddddddd111111", data1, data2)
  //             data2?.forEach(grade1 => {
  //             //console.log("grade1", grade1)
  //               const grade2 = data1.find(g => g.gradeId === grade1.gradeId);
  //             //console.log("grade2", grade2)
  //               if (grade2) {
  //                 grade1.sections.forEach(section1 => {
  //                   const section2 = grade2.sections.find(s => s.sectionId === section1.sectionId);
  //                   if (section2) {
  //                     section1.checked = true;
  //                   } else {
  //                     section1.checked = false;
  //                   }
  //                 });
  //               } else {
  //                 grade1?.sections?.forEach(section1 => {
  //                   section1.checked = false;
  //                 });
  //               }
  //             });
  //           }
  //         //console.log("data2", data2)
  //           setReadingPassportList(data2)
  //         })
  //         .catch((error) => console.error(error,"second error"));
  //     } catch (error) {
  //       console.error("Error fetching school strength:", error);
  //     }
  //   }
  // }

  // const fetchAccessData = () => {
  // //console.log("accessdata" , gradeList,schoolStrengthData?.data[0].grades,teachergrade);
  //   let temp = [...teachergrade]
  //   let schoolGrades = schoolStrengthData?.data[0].grades

  // temp.forEach((grade) => {
  //   const localGrade = schoolGrades.find(g => g.gradeId === grade.gradeId);
  //   grade.subjects.forEach(subject => {
  //     if(subject.sectionsForSubject){

  //       subject.sectionsForSubject.forEach(section1=>{
  //         const section2 = localGrade.sections.forEach((section)=>{
  //           if(section.sectionId === section1.sectionId){
  //               section.checked = true
  //           }else{
  //             section.checked = false
  //           }

  //         })

  //         subject.sectionsForSubject = section2
  //         // if (section2) {
  //         //   section1.checked = true;
  //         // } else {
  //         //   section1.checked = false;
  //         // }
  //       })
  //     }
  //   })
  // })
  // console.log("temp=========",temp)
  // let tempTeacherGrade = teachergrade?.map((gradeData,idx1) => {
  //   let gradeFind = schoolStrengthData.data[0].grades.find(g => g.gradeId === gradeData.gradeId);
  // //console.log("gradefind",gradeFind)
  //   return gradeData?.subjects.map((subjectData,idx2) => {
  //        if(subjectData.sectionsForSubject){
  //           return subjectData.sectionsForSubject.map((subJectSectionData,idx3) => {
  //             let tempSections = gradeFind.sections.map((sec) => {
  //               if(sec.id === schoolStrengthData.id){
  //                 sec.checked = true
  //               }else{
  //                 sec.checked = false
  //               }
  //               return sec
  //             })
  //                    return tempSections
  //           })
  //        }
  //   })
  // })
  // console.log("==========",tempTeacherGrade);
  // }

  const fetchSchoolStrength = async () => {
    if (selectedProfile.userId == null) return;
    try {
      // First API call
      const response1 = await axios.get(
        `${BASE_URL}v1/api/user/readingPassportAccess/${selectedProfile.userId}`,
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        }
      );

      // console.log("type", response1.data.data.librarian);
      setType(response1.data.data.librarian ? "librarian" : "classTeacher");
      // console.log(response1.data.data, "response.data.data");

      const data1 = response1.data.data.access?.grades;
      // console.log("data1", data1);
      setDefaultReadingPassportList(response1.data);

      // Second API call
      const response2 = await axios.get(
        `${BASE_URL}v1/api/user/teachers/school-strength/${Id}`,
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        }
      );

      setSchoolStrengthData(response2.data);
      const data2 = response2.data.data[0]?.grades || [];
      // console.log("dddddddddd", data1, data2);

      // Process data2 based on data1
      if (data1) {
        //console.log("Processing grades...", data1, data2);
        data2.forEach((grade1) => {
          //console.log("grade1", grade1);
          const grade2 = data1.find((g) => g.gradeId === grade1.gradeId);
          //console.log("grade2", grade2);
          if (grade2) {
            grade1.sections.forEach((section1) => {
              section1.checked = grade2?.sections.some((s) => s.sectionId === section1.sectionId) || false;
            });
          }
        });
      }

      //console.log("data2", data2);
      setReadingPassportList(data2);
    } catch (error) {
      // console.error("Error fetching school strength:", error);
    }
  };


  useEffect(() => {
    setTabsView(true)
    setReadingAccessEdit(false)
    setAccessEdit(false)
    const getInitialValues = () => {
      fetchSchoolStrength();
      //  fetchAccessData();
    }
    getInitialValues();

  }, [selectedProfile])
  useEffect(() => {
    if (Object.keys(selectedProfile).length > 0) {
      setuserProfile(selectedProfile);
    } else {
      setuserProfile({});
    }
    if (teachergrade.length > 0) {
      setGradelist(teachergrade);
    } else {
      setGradelist([]);
    }
  }, [selectedProfile, teachergrade]);

  useEffect(() => {
    if (teachergradeupdate.length > 0) {
      setGradelist(teachergradeupdate);
    } else {
      setGradelist([]);
    }
  }, [teachergradeupdate]);


  const onclose = () => {
    close();
  };

  const Access = () => {
    onclose();
    addAcess();
  };

  const checkSection = (event, section, gradeId, sectionId, subjectId, subTopicId, message) => {

    if (event.target?.checked) {
      setChecklist({ ...checklist, [section]: true });
      //console.log("--------", { ...checklist, [section]: true })
    } else {
      const { id } = selectedProfile;
      const payload = {
        "gradeId": gradeId,
        "sectionIds": [
          sectionId
        ],
        "subjectId": subjectId,
        "subtopicIds": subTopicId != "" ? [
          subTopicId
        ] : []
      }
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/edit-teacher-access/subjects?teacherId=${id}`,
          payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
        )
        .then(function (response) {

          axios
            .get(
              `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/list/assigned-access?teacherId=${id}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(function (response) {
              setTeacherGrade(response?.data?.data);
              setMessage(message);
              setChecklist({ ...checklist, [section]: false });
              //console.log("-----------", { ...checklist, [section]: false })
              setTimeout(() => {
                setMessage("")
              }, 4000)
            })
            .catch((err) => console.log(err));

        })
        .catch(function (response) {

        });
    }
  };

  const onChangeTab = (key) => {
    //console.log(key);
    fetchSchoolStrength()
  };

  const gradeEditOpen = (grade) => {
    //console.log("grade======", grade);
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");

    if (tabsView) {

      setSelectedGrade(grade)
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${grade?.gradeId}&branchId=${branchId}&schoolId=${schoolId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,

            },
          }
        )
        .then(function (response) {
          setSelectedGradeSections(response?.data?.data);
          //console.log("response?.data?.data", response?.data?.data)
        })
        .catch((err) => console.log(err));

    } else {
      setSelectedGrade({})
    }
    setTabsView(false)
    setAccessEdit(true)
  }

  const readingPassportEditOpen = async (grade) => {
    //console.log("grade", grade)

    // const res = await axios.get(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/school-strength/${Id}`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    // const tempCurrent = [...res.data.data];
    // let checkGrade = tempCurrent[0].grades.filter((data) => grade.gradeId == data.gradeId)[0];



    // let checVal = checkGrade?.sections?.map((data) => {
    //   let checkSection = grade.sections.filter((section) => section.sectionId == data.sectionId).length
    //   if (checkSection == 0) {
    //     data.checked = false
    //   } else {
    //     data.checked = true
    //   }

    //   return data
    // })
    // grade.sections = checVal
    // console.log("checVal", checVal)
    setSelectedReadingAccess(grade)
    setTabsView(false)
    setReadingAccessEdit(true)
  }
  const handleChangeReading = (e, selectedSection, grade) => {
    const updatedSections = selectedReadingAccess.sections.map((section) => {
      if (section.sectionId === selectedSection.sectionId) {
        return { ...section, checked: !section.checked };
      }
      return section;
    });

    setSelectedReadingAccess({
      ...selectedReadingAccess,
      sections: updatedSections,
    });
  }

  const submitReadingPassport = async () => {
    setIsBtnEnabled(true);
    //console.log("selectedProfile",selectedProfile)
    if (window.confirm("Are you sure you want to update the access?")) {
      let prev = { ...selectedReadingAccess }
      let sectionUpdate = selectedReadingAccess.sections?.filter((section) => {
        return section.checked
      })
      prev.sections = sectionUpdate.map((data) => {
        let sectionFormat = {
          section: data.section,
          sectionId: data.sectionId
        }
        return sectionFormat
      })
      // console.log("prev",prev)
      let gradeFilter = []
      if (!defaultReadingPassportList.data?.access) {
        let temp = defaultReadingPassportList
        temp.data.access = { grades: [] }
        setDefaultReadingPassportList(temp)
      }
      if (defaultReadingPassportList.data.access?.grades) {
        gradeFilter = defaultReadingPassportList.data.access.grades.filter((data) => {
          return data.gradeId == selectedReadingAccess.gradeId
        }).length
      } else {
        gradeFilter = []
      }
      let updateGrade = [];
      if (gradeFilter === 0) {
        let gradeArr = defaultReadingPassportList.data.access?.grades
        gradeArr.push(prev)
        updateGrade = gradeArr
      } else {
        updateGrade = defaultReadingPassportList.data.access.grades?.map((data) => {
          let obj
          if (data.gradeId == prev.gradeId) {
            obj = prev
          } else {
            obj = data
          }
          return obj
        })


      }
      //console.log("updateGrade",prev)

      // let gradeAccess = []
      // for(let i=0; i< updateGrade.length; i++){
      //   let sectionList1 = []
      //   for(let y=0; y<updateGrade[i].sections.length; y++){
      //     sectionList1.push(updateGrade[i].sections[y].sectionId)
      //   }
      //   let grade1 = {
      //     [updateGrade[i].gradeId] : sectionList1
      //   }
      //   gradeAccess.push(grade1)
      // } 

      // let newGrade = {
      //   [prev.gradeId]:prev.sections.map((section) =>{
      //     return section.sectionId
      //   })
      // }

      let gradeAccess = "";
      for (let i = 0; i < updateGrade.length; i++) {
        for (let y = 0; y < updateGrade[i].sections.length; y++) {
          if (gradeAccess != "") {
            gradeAccess += ","
          }
          gradeAccess = gradeAccess + updateGrade[i].gradeId + ":" + updateGrade[i].sections[y].sectionId
        }
      }
      let newGrade = "";
      for (let i = 0; i < prev.sections.length; i++) {
        if (newGrade != "") {
          newGrade += ","
        }
        newGrade = newGrade + prev.gradeId + ":" + prev.sections[i].sectionId
      }

      let requestData = {
        "userType": "user",
        "librarian": false,
        "access": {
          "grades": defaultReadingPassportList.data.access.grades ? updateGrade : [prev]
        },
        "gradeAccess": defaultReadingPassportList.data.access.grades ? gradeAccess : newGrade,
        "schoolId": selectedProfile.schoolId,
        "branchId": selectedProfile.branchId
      }
      //console.log("requseteData", requestData)



      axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/readingPassportAccess/${selectedProfile.userId}`, requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },

        })
        .then((res) => {
          // setDefaultReadingPassportList(res.data)
          //console.log("publishCDN >>> ", res);
          fetchSchoolStrength()
          setTabsView(true);
          setReadingAccessEdit(false);
          setIsBtnEnabled(false);
        }).catch((e) => {
          setIsBtnEnabled(false);
        })

    } else {
      setIsBtnEnabled(false)
    }
    //alert(response);


  }
  const cancelUpdate = () => {
    setTabsView(true)
    setAccessEdit(false)
    setReadingAccessEdit(false)
    fetchSchoolStrength();
    setIsBtnEnabled(false);
  }

  const handleChangeAccess = (e, status, subJectIndux, sectionIndex, type, sectionId, gradeId, sections, selectedSection) => {
    // console.log(
    //   "ddddddddddd",
    //   // e,
    //   // status,
    //   // subJectIndux,
    //   // sectionIndex,
    //   // type,
    //   // sectionId,
    //   // gradeId,
    //   sections,
    //   // selectedSection
    // )
    //console.log("fffffffffff", selectedGrade);
    let selectedGradeTemp = { ...selectedGrade }
    // let sectionsTemp= sections.map((section)=>{
    //   section.id=section.sectionId
    // })
    // if(status){
    //   sectionsTemp.splice(sectionIndex,1)
    // }else{
    //   sectionsTemp.push(selectedSection)
    // }

    // selectedGradeTemp.subjects[subJectIndux].sectionsForSubject=sectionsTemp

    // setSelectedGrade({...selectedGrade,selectedGradeTemp})

    let grades = []

    // const payload = {
    //   "gradeId": gradeId,
    //   "sectionIds": [
    //     sectionId
    //   ],
    //   "subjectId": subjectId,
    //   "subtopicIds": subTopicId != "" ? [
    //     subTopicId
    //   ] : []
    // }
  }
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        style={{ width: "1000px", height: "600px", overflowY: "scroll", scrollbarWidth: "thin", overflowX: "hidden" }}
      >

        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ flexDirection: "column", display: "flex" }}>
            {show && (
              <p style={{ fontSize: "0.5rem", color: "navy" }}>
                Select Add Access option if new Access needs to be Added
                <br></br>
                Deselect the section for grade and subject and select Update
                Access option if the current access need to be updated
              </p>
            )}
            <div>
              <i
                className="fa-solid fa-xmark cursorPointer"
                style={{ marginLeft: "920px" }}
                onClick={onclose}
              ></i>
            </div>
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              marginTop: "45px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "25px"
              }}
            >
              {/* <div style={{ width: "50%" }}>Name</div> */}
              <TextField
                name={"firstName"}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '26px',
                  },
                }}
                value={userProfile?.name || ""}
                disabled
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <div style={{ width: "50%" }}>Email</div> */}
              <TextField
                name={"email"}
                sx={{
                  width: "100%", '& .MuiOutlinedInput-root': {
                    borderRadius: '26px',
                  },
                }}
                value={userProfile?.email || ""}
                disabled
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <div style={{ width: "50%" }}>School</div> */}
              <TextField
                name={"hrs"}
                sx={{
                  width: "100%", '& .MuiOutlinedInput-root': {
                    borderRadius: '26px',
                  },
                }}
                value={userProfile?.school || ""}
                disabled
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <div style={{ width: "50%" }}>Branch</div> */}
              <TextField
                name={"email"}
                sx={{
                  width: "100%", '& .MuiOutlinedInput-root': {
                    borderRadius: '26px',
                  },
                }}
                value={userProfile?.branch || ""}
                disabled
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            {
              //console.log("readingPassportList", readingPassportList)
            }
            {tabsView &&

              <Tabs
                onChange={onChangeTab}
                tabBarStyle={{ color: "red" }}
                type="card"
                items={[
                  {
                    label: `Access`,
                    key: 0,
                    children: <AccessPassport gradeList={gradeList} gradeEditOpen={gradeEditOpen} checkSection={checkSection} checklist={checklist} show={show} CSPForm={CSPForm} message={message} Access={Access} />,
                  },
                  {
                    label: `Reading Passport Access`,
                    key: 1,
                    children: <ReadingPassportAccess type={type} selectedProfile={selectedProfile} gradeList={readingPassportList} readingPassportEditOpen={readingPassportEditOpen} fetchSchoolStrength={fetchSchoolStrength} />,
                  },
                  // {
                  //   label: `Default`,
                  //   key: 2,
                  //   children: <div>
                  //     <div
                  //       style={{
                  //         display: "flex",
                  //         flexDirection: "row",
                  //         alignItems: "center",
                  //         marginLeft: "490px",
                  //       }}
                  //     >
                  //       <div>
                  //         {!show && (
                  //           <div className={CSPForm.wrapper_teacherid}>
                  //             <div className={CSPForm.icon}>
                  //               Current Access
                  //               <span className={CSPForm.i_button}>
                  //                 <i className="fas fa-info-circle"></i>
                  //               </span>
                  //               <div className={CSPForm.tooltip} style={{ bottom: "75%" }}>
                  //                 Select Add Access option if new Access needs to be Added
                  //                 Deselect the section for grade and subject and select Update
                  //                 Access option if the current access need to be updated
                  //               </div>
                  //             </div>
                  //           </div>
                  //         )}
                  //       </div>
                  //     </div>
                  //     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  //       <div
                  //         style={{
                  //           padding: "20px 40px",
                  //           height: "100%",
                  //           width: "100%",
                  //           display: "flex",
                  //           flexDirection: "column",
                  //           justifyContent: "space-between",
                  //         }}
                  //       >
                  //         <Box sx={{ flexGrow: 1 }}>
                  //           <Grid container spacing={2}>
                  //             <Grid item xs={8} lg={6} md={6} sm={12}>
                  //               <div
                  //                 style={{
                  //                   width: "100%",
                  //                   display: "flex",
                  //                   flexDirection: "column",
                  //                   rowGap: "1rem",
                  //                 }}
                  //               >
                  //                 <div
                  //                   style={{
                  //                     display: "flex",
                  //                     flexDirection: "row",
                  //                     alignItems: "center",
                  //                     justifyContent: "space-between",
                  //                   }}
                  //                 >
                  //                   <div style={{ width: "50%" }}>Name</div>
                  //                   <TextField
                  //                     name={"firstName"}
                  //                     sx={{ width: "100%" }}
                  //                     value={userProfile?.name || ""}
                  //                     disabled
                  //                   />
                  //                 </div>
                  //                 <div
                  //                   style={{
                  //                     display: "flex",
                  //                     flexDirection: "row",
                  //                     alignItems: "center",
                  //                     justifyContent: "space-between",
                  //                   }}
                  //                 >
                  //                   <div style={{ width: "50%" }}>Email</div>
                  //                   <TextField
                  //                     name={"email"}
                  //                     sx={{ width: "100%" }}
                  //                     value={userProfile?.email || ""}
                  //                     disabled
                  //                   />
                  //                 </div>
                  //                 <div
                  //                   style={{
                  //                     display: "flex",
                  //                     flexDirection: "row",
                  //                     alignItems: "center",
                  //                     justifyContent: "space-between",
                  //                   }}
                  //                 >
                  //                   <div style={{ width: "50%" }}>School</div>
                  //                   <TextField
                  //                     name={"hrs"}
                  //                     sx={{ width: "100%" }}
                  //                     value={userProfile?.school || ""}
                  //                     disabled
                  //                   />
                  //                 </div>
                  //                 <div
                  //                   style={{
                  //                     display: "flex",
                  //                     flexDirection: "row",
                  //                     alignItems: "center",
                  //                     justifyContent: "space-between",
                  //                   }}
                  //                 >
                  //                   <div style={{ width: "50%" }}>Branch</div>
                  //                   <TextField
                  //                     name={"email"}
                  //                     sx={{ width: "100%" }}
                  //                     value={userProfile?.branch || ""}
                  //                     disabled
                  //                   />
                  //                 </div>
                  //               </div>
                  //             </Grid>
                  //             <Grid item xs={8} lg={6} md={6} sm={12}>
                  //               <div
                  //                 style={{
                  //                   width: "100%",
                  //                   height: "350px",
                  //                   display: "flex",
                  //                   flexDirection: "column",
                  //                   rowGap: "1rem",
                  //                   marginLeft: "50px",
                  //                   padding: "10px",
                  //                   overflowY: "scroll",
                  //                 }}
                  //               >
                  //                 {gradeList.length > 0 &&
                  //                   gradeList.map((v, i) => {
                  //                     return (
                  //                       <>
                  //                         <div
                  //                           style={{
                  //                             display: "flex",
                  //                             flexDirection: "row",
                  //                             alignItems: "center",
                  //                             justifyContent: "space-between",
                  //                           }}
                  //                         >
                  //                           <div
                  //                             style={{
                  //                               width: "50%",
                  //                               fontSize: "20px",
                  //                               fontWeight: "bolder",
                  //                             }}
                  //                           >
                  //                             {v.grade}
                  //                           </div>
                  //                         </div>
                  //                         <div
                  //                           style={{
                  //                             // display: "flex",
                  //                             // flexDirection: "row",
                  //                             alignItems: "center",
                  //                             justifyContent: "space-between",
                  //                             marginLeft: "30px",
                  //                           }}
                  //                         >
                  //                           {(v.subjects || []).length > 0 &&
                  //                             v.subjects.map((value, index) => {
                  //                               return (
                  //                                 <div>
                  //                                   <div style={{ fontSize: "18px", fontWeight: "600", }}>{value?.subject}</div>
                  //                                   {(value?.subTopics || []).length > 0
                  //                                     ? value.subTopics.map((val, ind) => {
                  //                                       return (
                  //                                         <div>
                  //                                           <div style={{ fontSize: "16px", fontWeight: "500" }}>{val.subTopic}</div>
                  //                                           {val.sectionsForSubTopic
                  //                                             .length > 0 &&
                  //                                             val.sectionsForSubTopic.map(
                  //                                               (va, i) => {
                  //                                                 return (
                  //                                                   <div
                  //                                                     style={{
                  //                                                       alignItems:
                  //                                                         "center",
                  //                                                       marginLeft: "25px",
                  //                                                       columnGap: "1rem",
                  //                                                     }}
                  //                                                   >
                  //                                                     {
                  //                                                       //console.log(checklist, va.sectionId + val.subTopic + value.subjectId + v.gradeId, "++++++++++++++")
                  //                                                     }
                  //                                                     <input
                  //                                                       type="checkbox"
                  //                                                       // checked={true}
                  //                                                       checked={
                  //                                                         Object.keys(checklist).includes(va.sectionId + val.subTopic + value.subjectId + v.gradeId) ?
                  //                                                           checklist[va.sectionId + val.subTopic + value.subjectId + v.gradeId] : true
                  //                                                       }
                  //                                                       onChange={(
                  //                                                         event
                  //                                                       ) => {
                  //                                                         checkSection(
                  //                                                           event,
                  //                                                           va.sectionId +
                  //                                                           val.subTopic + value.subjectId + v.gradeId,
                  //                                                           v.gradeId,
                  //                                                           va.sectionId,
                  //                                                           value.subjectId,
                  //                                                           val.subTopicId,
                  //                                                           `you have unchecked: ${v.grade},${value.subject},${val.subTopic},${va.section}`
                  //                                                         );
                  //                                                       }}
                  //                                                       style={{
                  //                                                         top: "23px",
                  //                                                         outlineOffset:
                  //                                                           "-2px",
                  //                                                         transform:
                  //                                                           "scale(1.1)",
                  //                                                         padding: "10px",
                  //                                                         accentColor:
                  //                                                           "orange",
                  //                                                       }}
                  //                                                     />
                  //                                                     <span
                  //                                                       style={{
                  //                                                         padding: "10px",
                  //                                                         fontSize: "16px",
                  //                                                         fontStyle: "italic",
                  //                                                       }}
                  //                                                     >
                  //                                                       {va.section}
                  //                                                     </span>
                  //                                                   </div>
                  //                                                 );
                  //                                               }
                  //                                             )}
                  //                                         </div>
                  //                                       );
                  //                                     })
                  //                                     : value.sectionsForSubject.map(
                  //                                       (item, i) => {
                  //                                         return (
                  //                                           <div
                  //                                             style={{
                  //                                               alignItems: "center",
                  //                                               marginLeft: "25px",
                  //                                               columnGap: "1rem",
                  //                                             }}
                  //                                           >{
                  //                                               //console.log(checklist, item.sectionId + item.section + value.subjectId, "==================")
                  //                                             }
                  //                                             <input
                  //                                               type="checkbox"
                  //                                               // checked={true}

                  //                                               checked={
                  //                                                 Object.keys(checklist).includes(item.sectionId + item.section + value.subjectId) ?
                  //                                                   checklist[item.sectionId + item.section + value.subjectId] : true
                  //                                               }
                  //                                               style={{
                  //                                                 top: "23px",
                  //                                                 outlineOffset: "-2px",
                  //                                                 transform: "scale(1.1)",
                  //                                                 padding: "10px",
                  //                                                 accentColor: "orange",
                  //                                               }}
                  //                                               onChange={(event) => {
                  //                                                 checkSection(
                  //                                                   event,
                  //                                                   item.sectionId + item.section + value.subjectId,
                  //                                                   v.gradeId,
                  //                                                   item.sectionId,
                  //                                                   value.subjectId,
                  //                                                   "",
                  //                                                   `you have unchecked:${v.grade},${value.subject},${item.section}`
                  //                                                 );
                  //                                               }}
                  //                                             />

                  //                                             <span
                  //                                               style={{
                  //                                                 padding: "10px",
                  //                                                 fontSize: "16px",
                  //                                                 fontStyle: "italic",
                  //                                               }}
                  //                                             >
                  //                                               {item.section}
                  //                                             </span>
                  //                                           </div>
                  //                                         );
                  //                                       }
                  //                                     )}
                  //                                 </div>
                  //                               );
                  //                             })}
                  //                         </div>
                  //                       </>
                  //                     );
                  //                   })}
                  //               </div>
                  //             </Grid>
                  //           </Grid>
                  //         </Box>
                  //       </div>
                  //     </Typography>
                  //     <div
                  //       style={{
                  //         display: "flex",
                  //         flexDirection: "row",
                  //         justifyContent: "end",
                  //         columnGap: "1rem",
                  //       }}
                  //     >
                  //       <div style={{ color: "blue", fontStyle: "italic", fontSize: "13px" }}>{message}</div>
                  //       <div>
                  //         <Green100Button onClick={Access}>AddAccess</Green100Button>
                  //       </div>
                  //     </div>
                  //   </div>,
                  // }
                ]}
              />
            }
            {accessEdit &&
              <div style={{
                padding: "20px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                marginTop: "10px",

              }}>
                <h3> {selectedGrade.grade} </h3>
                <div>
                  {selectedGrade.subjects?.map((subject, idx) => {
                    return (
                      <div key={idx} style={{ padding: "10px" }}>
                        <h3> {subject.subject}</h3>
                        {subject.subTopics ?
                          subject?.subTopics?.map((subTopic, idx1) => {
                            return (
                              <div>
                                <h4> {subTopic.subTopic}</h4>
                                <div>
                                  {selectedGradeSections?.map((section) => {
                                    let filtered = subTopic?.sectionsForSubTopic?.filter((data) => data.sectionId == section.id).length
                                    return (

                                      <FormControlLabel
                                        control={
                                          <Checkbox checked={filtered == 1} name={section.section} onChange={(e) => handleChangeAccess(e, filtered == 1, idx, idx1, 'sectionsForSubTopic', section.sectionId, selectedGrade.gradeId, subTopic?.sectionsForSubTopic, section)} />
                                        }
                                        label={section.section}
                                      />
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })
                          :
                          <div>
                            {selectedGradeSections?.map((section, idx2) => {
                              //console.log("subject----------", section)
                              let filtered = subject?.sectionsForSubject?.filter((data) => data.sectionId == section.id).length
                              //console.log("count", filtered)
                              return (

                                <FormControlLabel
                                  control={
                                    <Checkbox checked={filtered == 1} name={section.section} onChange={(e) => handleChangeAccess(e, filtered == 1, idx, idx2, 'sectionsForSubject', section.sectionId, selectedGrade.gradeId, subject?.sectionsForSubject, section)} />
                                  }
                                  label={section.section}
                                />
                              )
                            })}
                          </div>
                        }


                      </div>
                    )
                  })}
                </div>
                <Button color="primary" onClick={cancelUpdate}>Cancel</Button>
              </div>
            }

            {readingAccessEdit &&
              <div style={{
                padding: "20px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                marginTop: "10px",

              }}>
                <h3> {selectedReadingAccess?.grade} </h3>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                  {selectedReadingAccess?.sections?.map((section, idx) => {
                    //console.log(section)
                    return (
                      <div>

                        <FormControlLabel
                          control={
                            <Checkbox name="rerer"
                              checked={section.checked}
                              onChange={(e) => handleChangeReading(e, section, selectedReadingAccess, idx)}
                              sx={{
                                color: "#ffa500",
                                '&.Mui-checked': {
                                  color: "#ffa500",
                                },
                              }} />
                          }
                          label={section.section}
                        />
                      </div>
                    )
                  })}

                </div>

                <div style={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  marginTop: "20px"
                }}>
                  <Button color="warning" variant="contained" onClick={cancelUpdate} style={{ borderRadius: "20px" }}>Cancel</Button>
                  <Button color="warning" variant="contained" disabled={isBtnEnabled} onClick={submitReadingPassport} style={{ borderRadius: "20px" }}>Update</Button>
                </div>
              </div>
            }
          </div>
        </Typography>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "490px",
          }}
        >
          <div>
            {!show && (
              <div className={CSPForm.wrapper_teacherid}>
                <div className={CSPForm.icon}>
                  Current Access
                  <span className={CSPForm.i_button}>
                    <i className="fas fa-info-circle"></i>
                  </span>
                  <div className={CSPForm.tooltip} style={{ bottom: "75%" }}>
                    Select Add Access option if new Access needs to be Added
                    Deselect the section for grade and subject and select Update
                    Access option if the current access need to be updated
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              padding: "20px 40px",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8} lg={6} md={6} sm={12}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%" }}>Name</div>
                      <TextField
                        name={"firstName"}
                        sx={{ width: "100%" }}
                        value={userProfile?.name || ""}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%" }}>Email</div>
                      <TextField
                        name={"email"}
                        sx={{ width: "100%" }}
                        value={userProfile?.email || ""}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%" }}>School</div>
                      <TextField
                        name={"hrs"}
                        sx={{ width: "100%" }}
                        value={userProfile?.school || ""}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%" }}>Branch</div>
                      <TextField
                        name={"email"}
                        sx={{ width: "100%" }}
                        value={userProfile?.branch || ""}
                        disabled
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={8} lg={6} md={6} sm={12}>
                  <div
                    style={{
                      width: "100%",
                      height: "350px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "1rem",
                      marginLeft: "50px",
                      padding: "10px",
                      overflowY: "scroll",
                    }}
                  >
                    {gradeList.length > 0 &&
                      gradeList.map((v, i) => {
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "50%",
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              >
                                {v.grade}
                              </div>
                            </div>
                            <div
                              style={{
                                // display: "flex",
                                // flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginLeft: "30px",
                              }}
                            >
                              {(v.subjects || []).length > 0 &&
                                v.subjects.map((value, index) => {
                                  return (
                                    <div>
                                      <div style={{ fontSize: "18px", fontWeight: "600", }}>{value?.subject}</div>
                                      {(value?.subTopics || []).length > 0
                                        ? value.subTopics.map((val, ind) => {
                                          return (
                                            <div>
                                              <div style={{ fontSize: "16px", fontWeight: "500" }}>{val.subTopic}</div>
                                              {val.sectionsForSubTopic
                                                .length > 0 &&
                                                val.sectionsForSubTopic.map(
                                                  (va, i) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          alignItems:
                                                            "center",
                                                          marginLeft: "25px",
                                                          columnGap: "1rem",
                                                        }}
                                                      >
                                                        {
                                                          //console.log(checklist, va.sectionId + val.subTopic + value.subjectId + v.gradeId, "++++++++++++++")
                                                        }
                                                        <input
                                                          type="checkbox"
                                                          // checked={true}
                                                          checked={
                                                            Object.keys(checklist).includes(va.sectionId + val.subTopic + value.subjectId + v.gradeId) ?
                                                              checklist[va.sectionId + val.subTopic + value.subjectId + v.gradeId] : true
                                                          }
                                                          onChange={(
                                                            event
                                                          ) => {
                                                            checkSection(
                                                              event,
                                                              va.sectionId +
                                                              val.subTopic + value.subjectId + v.gradeId,
                                                              v.gradeId,
                                                              va.sectionId,
                                                              value.subjectId,
                                                              val.subTopicId,
                                                              `you have unchecked: ${v.grade},${value.subject},${val.subTopic},${va.section}`
                                                            );
                                                          }}
                                                          style={{
                                                            top: "23px",
                                                            outlineOffset:
                                                              "-2px",
                                                            transform:
                                                              "scale(1.1)",
                                                            padding: "10px",
                                                            accentColor:
                                                              "orange",
                                                          }}
                                                        />
                                                        <span
                                                          style={{
                                                            padding: "10px",
                                                            fontSize: "16px",
                                                            fontStyle: "italic",
                                                          }}
                                                        >
                                                          {va.section}
                                                        </span>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          );
                                        })
                                        : value.sectionsForSubject.map(
                                          (item, i) => {
                                            return (
                                              <div
                                                style={{
                                                  alignItems: "center",
                                                  marginLeft: "25px",
                                                  columnGap: "1rem",
                                                }}
                                              >{
                                                  //console.log(checklist, item.sectionId + item.section + value.subjectId, "==================")
                                                }
                                                <input
                                                  type="checkbox"
                                                  // checked={true}

                                                  checked={
                                                    Object.keys(checklist).includes(item.sectionId + item.section + value.subjectId) ?
                                                      checklist[item.sectionId + item.section + value.subjectId] : true
                                                  }
                                                  style={{
                                                    top: "23px",
                                                    outlineOffset: "-2px",
                                                    transform: "scale(1.1)",
                                                    padding: "10px",
                                                    accentColor: "orange",
                                                  }}
                                                  onChange={(event) => {
                                                    checkSection(
                                                      event,
                                                      item.sectionId + item.section + value.subjectId,
                                                      v.gradeId,
                                                      item.sectionId,
                                                      value.subjectId,
                                                      "",
                                                      `you have unchecked:${v.grade},${value.subject},${item.section}`
                                                    );
                                                  }}
                                                />

                                                <span
                                                  style={{
                                                    padding: "10px",
                                                    fontSize: "16px",
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  {item.section}
                                                </span>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            columnGap: "1rem",
          }}
        >
          <div style={{ color: "blue", fontStyle: "italic", fontSize: "13px" }}>{message}</div>
          <div>
            <Green100Button onClick={Access}>AddAccess</Green100Button>
          </div>
        </div> */}
      </Box>
    </Modal>
  );
};

export default TeacherProfileUpdateModal;
