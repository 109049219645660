import { useState, useEffect, useRef } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Snackbar } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CloseIcon from '@mui/icons-material/Close';
import { ckEditorStyle, templates, defaultHtmlScript1, fontSizeDefault1, pinchStyleCss, pinchZoomContainer, pinchStyleScript } from './HTMLContent';
import MathType from '@wiris/mathtype-ckeditor5/dist/index.js';
import CancelIcon from '@mui/icons-material/Cancel'
// import localTemp from './template_list.json'

import {
    ClassicEditor,
    AccessibilityHelp,
    Autoformat,
    AutoImage,
    Autosave,
    Alignment,
    BalloonToolbar,
    Base64UploadAdapter,
    BlockQuote,
    Bold,
    CloudServices,
    Code,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    Markdown,
    MediaEmbed,
    Mention,
    PageBreak,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline,
    Undo
} from 'ckeditor5';
import {
    AIAssistant,
    CaseChange,
    ExportPdf,
    ExportWord,
    FormatPainter,
    ImportWord,
    MergeFields,
    OpenAITextAdapter,
    PasteFromOfficeEnhanced,
    SlashCommand,
    TableOfContents,
    Template
} from 'ckeditor5-premium-features';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

const LICENSE_KEY = 'WjF4K2V1aU9Jd1F2cE5meTZhcVZVdytsNDZyc20zaURlWllESnpJNFg3cDhGTE4yV3lyTWNObVFHajduT2c9PS1NakF5TkRFeU1URT0=';
const AI_AUTH_TOKEN = 'SYTKxMel05Nssqg8bUF56WP45xTsSIQJjZyP';
const AI_API_URL = 'https://120009.cke-cs.com/token/dev/SYTKxMel05Nssqg8bUF56WP45xTsSIQJjZyP?limit=10';

export default function CreateHTMLContent2() {
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const inputFileRef = useRef(null);

    const allowedExtensions = ['.txt', '.html', '.docx'];
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        vertical: 'top',
        horizontal: 'center'
    });
    const { open, message, vertical, horizontal } = snackbar;

    const [editorData, setEditorData] = useState('<p></p>');
    const [fileSelected, setFileSelected] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState();
    const [fileHandle, setFileHandle] = useState(null);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [previewData, setPreviewData] = useState('<p></p>');
    const [iframeSrc, setIframeSrc] = useState('<p></p>');
    const [isLessonPlan,setIsLessonPlan] = useState(false)
    const [templateList, setTemplateList] = useState([])
    
    useEffect(() => {
        setIsLayoutReady(true);
        // console.log('localTemp',localTemp);
        // setTemplateList(localTemp?.templates)
        return () => setIsLayoutReady(false);
    }, []);



    // useEffect(() => {
    //     fetch('https://s3.ap-south-1.amazonaws.com/azvasa-lms-cdn-dev/Content-Service/Staff/868f0118-90da-4361-b74b-ce5165dd410d_HTMLContent.js')
    //       .then(response => response.text())
    //       .then(scriptContent => {
    //         const scriptFunction = new Function('module', scriptContent);
    //         const module = { exports: {} };
    //         scriptFunction(module);
    //         console.log(module.exports.ckEditorStyle);
    //       })
    //       .catch(error => console.error('Error fetching data:', error));
    //   }, []);
    
    useEffect(() => {
        getTemplate()
    }, []);
     const getTemplate = async() => {
        try {
            const res = await fetch('https://s3.ap-south-1.amazonaws.com/azvasa-lms-cdn-dev/CONTENT-SERVICE/template/template_list.json');
            if (res.ok) {
              const data = await res.json(); // Parse the JSON response
              console.log('Response Data:', data?.templates); // Log the data
              console.log('template:', templates); 
              setTemplateList(data?.templates)
            //   return data; // Return the data if needed
            } else {
              console.error('Error fetching data:', res.statusText); // Handle HTTP errors
            }
          } catch (error) {
            console.error('Error fetching data:', error); // Handle any errors that occur during the fetch
          }
        
    }

    


    const defaultHtmlScript = `
    <script>
        let fontSizeSelect = document.getElementById('fontSize');
        const content = document.querySelector('.content');
        let initialFontSizes = {}; // Store initial font sizes

        // Store the initial font size of each element
        const body = document.body;
        const elements = body.querySelectorAll('*:not(select):not(option):not(button)');
        console.log(elements);
        elements.forEach((element, index) => {
            const currentFontSize = parseFloat(window.getComputedStyle(element).fontSize);
            initialFontSizes[index] = currentFontSize;
        });

        function changeFontSize() {
            const selectedSize = fontSizeSelect.value;  // Get the selected value (Small, Medium, Large)
            
            elements.forEach((element, index) => {
                const initialFontSize = initialFontSizes[index];  // Get the initial font size for the element
                console.log(initialFontSize)
                if (selectedSize === 'Small') {
                    element.style.fontSize = \`\${initialFontSize - 5}px\`;
                } else if (selectedSize === 'Large') {
                    element.style.fontSize = \`\${initialFontSize + 15}px\`;
                } else {
                    element.style.fontSize = \`\${initialFontSize}px\`;  // Reset to the initial size for 'Medium'
                }
            });
        }

        fontSizeSelect.addEventListener('change', changeFontSize);
            function resetToMedium() {
            // Reset all elements to their initial (Medium) size
            elements.forEach((element, index) => {
                const initialFontSize = initialFontSizes[index];  // Get the initial font size
                element.style.fontSize = \`\${initialFontSize}px\`;  // Reset to initial size (Medium)
            });
        }
         function downloadHTML() {
             resetToMedium(); 
            const htmlContent =  document.documentElement.outerHTML;
            const blob = new Blob([htmlContent], { type: 'text/html' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'document.html';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    </script>
`;

    const fontSizeDefault = `<div className="fontChoose">
        <button onclick=downloadHTML()>Print </button>
        <select name="fontSize" id="fontSize">
            <option value="Small">Small</option>
            <option value="Medium" selected>Medium</option>
            <option value="Large">Large</option>
        </select>
    </div>`

    const editorConfig = {
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'insertMergeField',
                'previewMergeFields',
                '|',
                'aiCommands',
                'aiAssistant',
                '|',
                'sourceEditing',
                'importWord',
                'exportWord',
                'exportPdf',
                'showBlocks',
                'formatPainter',
                'caseChange',
                'findAndReplace',
                '|',
                'heading',
                '|',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'subscript',
                'superscript',
                'code',
                'removeFormat',
                '|',
                'specialCharacters',
                'pageBreak',
                'link',
                'insertImage',
                'insertImageViaUrl',
                'mediaEmbed',
                'insertTable',
                'tableOfContents',
                'insertTemplate',
                'highlight',
                'blockQuote',
                'htmlEmbed',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                'outdent',
                'indent',
                '|',
                'alignment',
                '|',
                'MathType',
                'ChemType'
            ],
            shouldNotGroupWhenFull: true
        },
        plugins: [
            AccessibilityHelp,
            AIAssistant,
            Autoformat,
            AutoImage,
            Autosave,
            Alignment,
            BalloonToolbar,
            Base64UploadAdapter,
            BlockQuote,
            Bold,
            CaseChange,
            CloudServices,
            Code,
            Essentials,
            ExportPdf,
            ExportWord,
            FindAndReplace,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            FormatPainter,
            FullPage,
            GeneralHtmlSupport,
            Heading,
            Highlight,
            HtmlComment,
            HtmlEmbed,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            ImportWord,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            Markdown,
            MediaEmbed,
            Mention,
            MergeFields,
            OpenAITextAdapter,
            PageBreak,
            Paragraph,
            PasteFromMarkdownExperimental,
            PasteFromOffice,
            PasteFromOfficeEnhanced,
            RemoveFormat,
            SelectAll,
            ShowBlocks,
            SlashCommand,
            SourceEditing,
            SpecialCharacters,
            SpecialCharactersArrows,
            SpecialCharactersCurrency,
            SpecialCharactersEssentials,
            SpecialCharactersLatin,
            SpecialCharactersMathematical,
            SpecialCharactersText,
            Strikethrough,
            Subscript,
            Superscript,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableOfContents,
            TableProperties,
            TableToolbar,
            Template,
            TextTransformation,
            TodoList,
            Underline,
            Undo,
            MathType
        ],
        ai: {
            openAI: {
                apiUrl: AI_API_URL,
                requestHeaders: {
                    Authorization: AI_AUTH_TOKEN
                },
                requestParameters: {
                    model: 'gpt-3.5-turbo-1106',
                    max_tokens: 4000
                }
            },
            aiAssistant: {
                contentAreaCssClass: 'formatted'
            }
        },
        balloonToolbar: ['aiAssistant', '|', 'bold', 'italic', '|', 'link', 'insertImage', '|', 'bulletedList', 'numberedList'],
        exportPdf: {
            stylesheets: [
                /* This path should point to application stylesheets. */
                /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-pdf.html */
                './App.css',
                /* Export PDF needs access to stylesheets that style the content. */
                'https://cdn.ckeditor.com/ckeditor5/43.2.0/ckeditor5.css',
                'https://cdn.ckeditor.com/ckeditor5-premium-features/43.2.0/ckeditor5-premium-features.css'
            ],
            fileName: 'export-pdf-demo.pdf',
            converterOptions: {
                format: 'Tabloid',
                margin_top: '20mm',
                margin_bottom: '20mm',
                margin_right: '24mm',
                margin_left: '24mm',
                page_orientation: 'portrait'
            }
        },
        exportWord: {
            stylesheets: [
                /* This path should point to application stylesheets. */
                /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-word.html */
                './App.css',
                /* Export Word needs access to stylesheets that style the content. */
                'https://cdn.ckeditor.com/ckeditor5/43.2.0/ckeditor5.css',
                'https://cdn.ckeditor.com/ckeditor5-premium-features/43.2.0/ckeditor5-premium-features.css'
            ],
            fileName: 'export-word-demo.docx',
            converterOptions: {
                document: {
                    orientation: 'portrait',
                    size: 'Tabloid',
                    margins: {
                        top: '20mm',
                        bottom: '20mm',
                        right: '24mm',
                        left: '24mm'
                    }
                }
            }
        },
        fontFamily: {
            options: [
                'Algerian',
                'Arial',
                'American Typewriter, serif',
                'Arial Narrow',
                'Avantgarde',
                'Blippo, fantasy',
                'Brush Script MT',
                'Courier New',
                'Comic Sans MS, Comic Sans, cursive',
                'OCR A Std, monospace',
                'Snell Roundhand, cursive',
                'Tahoma',
                'Georgia',
                'Garamond',
                'Trebuchet MS',
                'Times New Roman',
                'Verdana',
            ],
            supportAllValues: true
        },
        fontSize: {
            options: [10, 12, 14, 'default', 18, 20, 22, 24, 26, 30, 40],
            supportAllValues: true
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ],
        },
        contentStyles: `
        h1 {
            font-size: 32px !important;
            font-weight: bold;
            color: #333;
        }
        h2 {
            font-size: 24px !important;
            font-weight: bold;
            color: #666;
        }`,
        htmlSupport: {
            allow: [
                {
                    name: /^.*$/,
                    styles: true,
                    attributes: true,
                    classes: true
                }
            ]
        },
        image: {
            toolbar: [
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage'
            ]
        },
        initialData:
            '<p></p>',
        licenseKey: LICENSE_KEY,
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                }
            }
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true
            }
        },
        mention: {
            feeds: [
                {
                    marker: '@',
                    feed: [
                        /* See: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html */
                    ]
                }
            ]
        },
        menuBar: {
            isVisible: true
        },
        mergeFields: {
            /* Read more: https://ckeditor.com/docs/ckeditor5/latest/features/merge-fields.html#configuration */
        },
        placeholder: 'Type or paste your content here!',
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
        // mediaEmbed: {
        //     previewsInData: true,
        // },
        template: {
            definitions: templateList
        },
        mediaEmbed: {
            previewsInData: true, // Show embedded media directly
            // providers: [
            //     {
            //         name: 's3',
            //         url: /^https:\/\/your-s3-bucket\.s3\.amazonaws\.com\/(.*)/,
            //         html: match => {
            //             const videoUrl = match[0];
            //             return (
            //                 `<video width="100%" height="auto" controls>
            //                     <source src="${videoUrl}" type="video/mp4">
            //                     Your browser does not support the video tag.
            //                 </video>`
            //             );
            //         }
            //     }
            // ]
        }
    };

    configUpdateAlert(editorConfig);


    const handleTemplateChange = (e) =>{
        console.log('=======',e.target.value);
        const selectedTemp = e.target.value
        let prewContent = editorData + selectedTemp.data
        setEditorData(prewContent)
        if(selectedTemp.title === 'Lesson Plan'){
            setIsLessonPlan(true)
        }
        
    }
    const handlePreview = () => {    
        if (isEditorDataEmpty(editorData)) {
            // console.log("Editor data is empty");
        } else {
            setOpenPreview(!openPreview)
        }

    }

    const isEditorDataEmpty = (editorData) => {
        // Remove common placeholder tags and trim the result
        const cleanedData = editorData.replace(/<p>(&nbsp;|\s)*<\/p>/g, '').trim();

        // Check if the cleaned data is empty
        return cleanedData === '';
    };

    const handleFileOpen = async () => {
        // console.log('as', editorData.length, editorData, fileSelected)
        if (editorData.length > 0 || fileSelected) {
            if (window.confirm('Please clear the content before opening a new file. Make sure you have saved the changes')) {
                // User confirms, clear the content
                setEditorData('<p></p>'); // Clear the editor data
                setFileSelected(false); // Reset file selection status
                setFileHandle(null); // Clear file handle
                setSelectedFileType(null); // Reset selected file type
                setIsSaveEnabled(false); // Disable Save after clearing
                handleSnackbarOpen('Content has been cleared!');
            } else {
                // User clicked 'Cancel', do nothing
                return;
            }
        }
        inputFileRef.current.click();
    };

    const handleSnackbarOpen = (message) => {
        setSnackbar({ ...snackbar, open: true, message });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file selected
        if (file) {
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                handleSnackbarOpen("Unsupported file format! Only .txt, .html are allowed.");
                return;
            }

            setFileSelected(true);
            setSelectedFileType(fileExtension)

            if (fileExtension === '.txt' || fileExtension === '.html') {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const fileContent = e.target.result;
                    setEditorData(fileContent);
                };
                reader.readAsText(file);
            } else if (fileExtension === '.docx') {
                // Placeholder for handling DOCX, just show a simple message
                handleSnackbarOpen("Please import word file from toolbar ");
            }
        }
    };

    const ensureMetaTag = (content) => {
        const hasMetaTag = content.includes('<meta charset="utf-8">');
        if (!hasMetaTag) {
            const headTagIndex = content.indexOf('<head>') + '<head>'.length;
            content = content.slice(0, headTagIndex) + '\n<meta charset="utf-8">\n' + content.slice(headTagIndex);
        }
        return content;
    };

    const handleSaveAs = async () => {
        if (fileSelected) {
            // Check if the opened file is an HTML file
            // console.log('ddd', selectedFileType);
            if (selectedFileType === '.html') {
                // Prepare to build the complete HTML structure
                let contentToSave = editorData; // Start with the existing editor data

                // Check for the existence of tags
                const hasHtmlTag = contentToSave.includes('<html') && contentToSave.includes('</html>');
                const hasHeadTag = contentToSave.includes('<head') && contentToSave.includes('</head>');
                const hasStyleTag = contentToSave.includes('<style') && contentToSave.includes('</style>');
                const hasBodyTag = contentToSave.includes('<body') && contentToSave.includes('</body>');
                const hasFontTag = contentToSave.includes('selectFontSize');
                const hasScriptTag = contentToSave.includes('<script') && contentToSave.includes('</script>');;
                // console.log('sdsd', hasFontTag, hasScriptTag)


                // Building the complete HTML structure
                if (!hasHtmlTag) {
                    // If <html> is missing, add the full structure
                    contentToSave = `<html>\n<head>\n<meta charset="utf-8">\n<style>${ckEditorStyle}\n</style>\n</head>\n<body>${fontSizeDefault1}\n${contentToSave.trim()}\n ${defaultHtmlScript1}</body>\n</html>`;
                } else {
                    // If <html> is present, we check inside it
                    if (!hasHeadTag) {
                        // If <head> is missing, insert it with <style>
                        const htmlEndIndex = contentToSave.indexOf('</html>');
                        contentToSave = contentToSave.slice(0, htmlEndIndex) +
                            `<head>\n<meta charset="utf-8">\n<style>${ckEditorStyle}\n</style>\n</head>\n` +
                            contentToSave.slice(htmlEndIndex);
                    } else {
                        // If <head> exists but <style> is missing, add <style>
                        if (!hasStyleTag) {
                            const headEndIndex = contentToSave.indexOf('</head>');
                            contentToSave = contentToSave.slice(0, headEndIndex) +
                                `<meta charset="utf-8">\n<style>${ckEditorStyle}\n</style>\n` +
                                contentToSave.slice(headEndIndex);
                        } else {
                            // If the <style> tag exists, check its content
                            const styleStartIndex = contentToSave.indexOf('<style>') + '<style>'.length;
                            const styleEndIndex = contentToSave.indexOf('</style>');
                            let existingStyles = contentToSave.slice(styleStartIndex, styleEndIndex).trim();

                            // Compare the existing styles with ckEditorStyle
                            if (!existingStyles.includes(ckEditorStyle)) {
                                // If ckEditorStyle is not present, add it to the existing styles
                                existingStyles += `\n${ckEditorStyle}`;
                                contentToSave = contentToSave.slice(0, styleStartIndex) +
                                    existingStyles + '\n' +
                                    contentToSave.slice(styleEndIndex);
                            }
                        }
                    }

                    // Check for <body> tag
                    if (!hasBodyTag) {
                        // If <body> is missing, add it around the existing content
                        const htmlEndIndex = contentToSave.indexOf('</html>');
                        contentToSave = contentToSave.slice(0, htmlEndIndex) +
                            '<body>\n' + fontSizeDefault1 +
                            contentToSave.slice(htmlEndIndex) + defaultHtmlScript1;
                        contentToSave += '\n</body>';
                    } else {
                        // If <body> exists, ensure the editor data goes inside it
                        const bodyStartIndex = contentToSave.indexOf('<body>') + '<body>'.length;
                        const bodyEndIndex = contentToSave.indexOf('</body>');
                        // contentToSave = contentToSave.slice(0, bodyStartIndex) +
                        //     `${contentToSave.slice(bodyStartIndex, bodyEndIndex).trim()}\n` +
                        //     contentToSave.slice(bodyEndIndex);

                        // if (!hasFontTag) {
                        //     contentToSave = contentToSave.slice(0, bodyStartIndex) + fontSizeDefault1 + contentToSave.slice(bodyStartIndex);
                        // }

                        // if (!hasScriptTag) {
                        //     contentToSave = contentToSave.slice(0, bodyEndIndex) + defaultHtmlScript1 + contentToSave.slice(bodyEndIndex);
                        // }
                        let bodyContent = contentToSave.slice(bodyStartIndex, bodyEndIndex).trim();

                        // Only add the font tag and script if they're missing
                        if (!hasScriptTag) {
                            bodyContent = `${fontSizeDefault1}\n${bodyContent}\n${defaultHtmlScript1}`;
                        }
                        // if (!hasScriptTag) {
                        //     bodyContent += `\n${defaultHtmlScript1}`;
                        // }

                        contentToSave = contentToSave.slice(0, bodyStartIndex) +
                            bodyContent + '\n' +
                            contentToSave.slice(bodyEndIndex);

                    }
                }
                contentToSave = ensureMetaTag(contentToSave);
                // Create and save the file
                try {
                    const fileHandle = await window.showSaveFilePicker({
                        suggestedName: 'content.html',
                        types: [
                            {
                                description: 'HTML Files',
                                accept: { 'text/html': ['.html'] },
                            },
                        ],
                    });

                    const writableStream = await fileHandle.createWritable();
                    await writableStream.write(contentToSave);
                    await writableStream.close();
                    setFileHandle(fileHandle);
                    setIsSaveEnabled(true); // Enable the Save button after saving as HTML
                    // handlePreview();
                    handleSnackbarOpen('File has been saved as new!');
                } catch (error) {
                    // console.error('Error saving file:', error);
                }
            }
            // Check if the opened file is a DOCX file
            // Check if the opened file is a plain text file
            else if (selectedFileType.type === 'text/plain') {
                try {
                    const fileHandle = await window.showSaveFilePicker({
                        suggestedName: 'converted.html',
                        types: [
                            {
                                description: 'HTML Files',
                                accept: { 'text/html': ['.html'] },
                            },
                        ],
                    });

                    const writableStream = await fileHandle.createWritable();
                    await writableStream.write(
                        `
                    <html>
                        <head>
                            <meta charset="utf-8">
                            <style>
                                ${ckEditorStyle}
                            </style>
                        </head>
                        <body>
                            <pre>${editorData}</pre> <!-- Wrap plain text inside <pre> to preserve formatting -->
                        </body>
                    </html>
                    `
                    );
                    await writableStream.close();
                    handleSnackbarOpen('File has been saved as new!');
                } catch (error) {
                    // console.error('Error saving TXT as HTML:', error);
                }
            }
        } else {
            // No file is selected: create a new HTML file with a basic structure
            try {
                const fileHandle = await window.showSaveFilePicker({
                    suggestedName: 'new-file.html',
                    types: [
                        {
                            description: 'HTML Files',
                            accept: { 'text/html': ['.html'] },
                        },
                    ],
                });

                const writableStream = await fileHandle.createWritable();
                // Basic HTML structure for new files
                const defaultHtmlContent = `
                <html>
                    <head>
                        <meta charset="utf-8">
                        <style>
                            ${ckEditorStyle}
                         
                        </style>
                    </head>
                    <body>
                   
                         ${fontSizeDefault1}
                        ${editorData}
                         ${defaultHtmlScript1}
                   
                    </body>
                </html>
            `;
                await writableStream.write(defaultHtmlContent);
                await writableStream.close();
                setFileHandle(fileHandle);
                setIsSaveEnabled(true); // Enable the Save button after new file creation
            } catch (error) {
                // console.error('Error creating and saving new file:', error);
            }

        };
    }

    const handleSave = async () => {
        if (!fileHandle) {
            // console.log("No file handle, falling back to Save As.");
            await handleSaveAs();  // Fallback if no file handle is available
            return;
        }

        try {
            // Prepare the content to be saved
            let contentToSave = editorData; // Start with the existing editor data

            // Check for the existence of <html>, <head>, <style>, and <body> tags
            const hasHtmlTag = contentToSave.includes('<html') && contentToSave.includes('</html>');
            const hasHeadTag = contentToSave.includes('<head') && contentToSave.includes('</head>');
            const hasStyleTag = contentToSave.includes('<style') && contentToSave.includes('</style>');
            const hasBodyTag = contentToSave.includes('<body') && contentToSave.includes('</body>');
            const hasScriptTag = contentToSave.includes('<script') && contentToSave.includes('</script>');
            const hasFontTag = contentToSave.includes('selectFontSize');
            // const hasFontTag = contentToSave.includes(fontSizeDefault1);
            // const hasScriptTag = contentToSave.includes(defaultHtmlScript1);

            // Build the complete HTML structure if necessary
            if (!hasHtmlTag) {
                // If <html> is missing, add the full structure
                contentToSave = `<html>\n<head>\n<meta charset="utf-8">\n<style>${ckEditorStyle}\n</style>\n</head>\n<body>\n${fontSizeDefault1}${contentToSave.trim()}${defaultHtmlScript1}\n</body>\n</html>`;
            } else {
                // If <html> exists, check for <head>, <style>, and <body> tags
                if (!hasHeadTag) {
                    // Add missing <head> and <style> if <head> is not present
                    const htmlEndIndex = contentToSave.indexOf('</html>');
                    contentToSave = contentToSave.slice(0, htmlEndIndex) +
                        `<head>\n<meta charset="utf-8">\n<style>${ckEditorStyle}\n</style>\n</head>\n` +
                        contentToSave.slice(htmlEndIndex);
                } else if (!hasStyleTag) {
                    // If <style> is missing but <head> is present, add <style> within <head>
                    const headEndIndex = contentToSave.indexOf('</head>');
                    contentToSave = contentToSave.slice(0, headEndIndex) +
                        `\n<meta charset="utf-8"><style>${ckEditorStyle}\n</style>\n` +
                        contentToSave.slice(headEndIndex);
                }

                if (!hasBodyTag) {
                    // Add missing <body> tags if they don't exist
                    const htmlEndIndex = contentToSave.indexOf('</html>');
                    contentToSave = contentToSave.slice(0, htmlEndIndex) +
                        '<body>\n' +
                        contentToSave.slice(htmlEndIndex);
                    contentToSave += '\n</body>';
                } else {
                    // Ensure the editor data is properly placed inside the <body> tag
                    const bodyStartIndex = contentToSave.indexOf('<body>') + '<body>'.length;
                    const bodyEndIndex = contentToSave.indexOf('</body>');
                    contentToSave = contentToSave.slice(0, bodyStartIndex) +
                        `${contentToSave.slice(bodyStartIndex, bodyEndIndex).trim()}\n` +
                        contentToSave.slice(bodyEndIndex);

                    if (!hasFontTag) {
                        contentToSave = contentToSave.slice(0, bodyStartIndex) + fontSizeDefault1 + contentToSave.slice(bodyStartIndex);
                    }

                    if (!hasScriptTag) {
                        contentToSave = contentToSave.slice(0, bodyEndIndex) + defaultHtmlScript1 + contentToSave.slice(bodyEndIndex);
                    }
                }
            }

            // Write the final content to the file
            const writableStream = await fileHandle.createWritable();
            await writableStream.write(contentToSave);
            await writableStream.close();

            handleSnackbarOpen('File has been saved!');
        } catch (error) {
            // console.error('Error saving file:', error);
        }
    };

    const handleClear = () => {
        if (editorData || fileSelected) {
            if (window.confirm('You have unsaved changes. Are you sure you want to clear the content without saving?')) {
                // If the user confirms, clear the editor and reset the file
                setEditorData('<p></p>');
                setFileSelected(false);
                setFileHandle(null);
                setSelectedFileType(null);
                setIsSaveEnabled(false); // Disable Save after clearing
                handleSnackbarOpen('Content has been cleared!');
                setIsLessonPlan(false)
                
            } else {
                // User clicked 'Cancel', do nothing
                return;
            }
        }
    };

    const getFormattedEditorData = (data) => {
        // console.log(" data.includes(defaultHtmlScript1)", data.includes('<script'),data.includes('<select'),data.includes(defaultHtmlScript1));
        
        // Check if the data already contains the necessary HTML structure
        if (
            data.includes('<html') &&
            data.includes('<head') &&
            data.includes('<style') &&
            // data.includes(fontSizeDefault1) &&
            data.includes('selectFontSize') &&
            data.includes('<body')
        ) {
            // Return the existing data if it already has the full structure
            return data;
        } else {
            // If necessary tags are missing, wrap the content in a new HTML structure
            return `
            <html>
                <head>
                    <meta charset="utf-8">
                    <style>
                        ${ckEditorStyle} 
                  
                    </style>
                </head>
                <body>
                    ${fontSizeDefault1}
                    ${data}${defaultHtmlScript1}
           
                </body>
            </html>
        `;
        }
    };
 

    return (
        <div>
            <div className="main-container">
                <div className="editor-container editor-container_classic-editor editor-container_include-outline" ref={editorContainerRef}>
                    <div className="editor-container__editor-wrapper">
                        <div className="editor-container__editor">
                            <div className='htmlCkEditor' ref={editorRef}>
                            
                                {isLayoutReady && templateList.length > 0 &&
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={editorConfig}
                                        data={editorData}
                                        onReady={(editor) => {
                                            // Listen for the input transformation event, which occurs when content is pasted into the editor
                                            editor.plugins.get('ClipboardPipeline').on('inputTransformation', (evt, data) => {
                                                // If the content is from a Word document, set fileSelected to true
                                                if (data.content.getChildren().length > 0) {
                                                    // Here you can check if the content is coming from Word by inspecting data.content
                                                    setFileSelected(true); // Set fileSelected to true when a Word document is imported
                                                }
                                            });
                                        }}
                                        onChange={(event, editor) => {
                                            // const data = editor.getData();
                                            // setEditorData(data); // Update editorData when content changes
                                            //console.log('content', event )
                                            setTimeout(() => {
                                                try {
                                                    const data = editor.getData() || '<p> </p>';
                                                    //console.log('content', data)
                                                    setEditorData(data); // Use a fallback if undefined
                                                } catch (error) {
                                                    // console.error("Editor data processing error:", error);
                                                    setEditorData('<p></p>'); // Reset to valid HTML if error occurs
                                                }
                                            }, 100);
                                        }}
                                    />
                                }
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
                padding: '10px',
                background: 'rgb(221 221 221 / 74%)'
            }}>
                <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: 'none' }} // Hide the file input
                    onChange={handleFileChange}
                    accept=".txt,.html,.docx,.pdf" // Acceptable file formats
                />
                <Button sx={{
                    backgroundColor: 'darkgray',
                    color: '#000',
                    '&:hover': {
                        backgroundColor: '#a9a9a9',
                    }
                }}
                    variant='contained'
                    onClick={handleFileOpen}
                >Open HTML</Button>
                <Button sx={{
                    backgroundColor: 'darkgray',
                    color: '#000',
                    '&:hover': {
                        backgroundColor: '#a9a9a9',
                    }
                }}
                    variant='contained'
                    onClick={handlePreview}
                // disabled={!fileSelected || !editorData}
                >Preview & Save</Button>
                <Button sx={{
                    backgroundColor: 'darkgray',
                    color: '#000',
                    '&:hover': {
                        backgroundColor: '#a9a9a9',
                    }
                }}
                    variant='contained'
                    onClick={handleClear}
                >Clear</Button>
                  {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Template</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
                   
                    onChange={handleTemplateChange}
                    label="Template"
                    >
                    <MenuItem value="">
                        <em>Select Template</em>
                    </MenuItem>
                    {templates.map((data,idx) =>{
                        return (
                            <MenuItem value={data}>{data.title}</MenuItem>
                        )
                    })}
                    </Select>
                </FormControl> */}
            </Box>
            <Modal
                open={openPreview}
                onClose={handlePreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: '100%',
                    height: '100%',
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 4,
                    p: 1,
                }}>
                    <div className='closeIconCk'>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            {/* {fileSelected || editorData && <button onClick={handleSaveAs}>Save as</button>} */}
                            <Button
                                sx={{
                                    backgroundColor: 'darkgray',
                                    color: '#000',
                                    '&:hover': {
                                        backgroundColor: '#a9a9a9',
                                    }
                                }}
                                variant='contained'
                                onClick={handleSave}
                                disabled={!isSaveEnabled}  // Disable if Save As hasn't been done yet or file is not .html
                            >
                                Save
                            </Button>
                            <Button sx={{
                                backgroundColor: 'darkgray',
                                color: '#000',
                                '&:hover': {
                                    backgroundColor: '#a9a9a9',
                                }
                            }}
                                variant='contained'
                                onClick={handleSaveAs}
                            >Save As</Button>
                        </Box>
                        {/* <CloseIcon onClick={handlePreview} /> */}
                        <CancelIcon onClick={handlePreview} />
                    </div>
                    <Box sx={{ width: '100%', height: '100%' }} >
                        {isLayoutReady && (
                            // <div>
                            //     {editorData && (
                            //         <iframe
                            //             title="external-content"
                            //             src={iframeSrc}
                            //             style={{ width: '100%', height: '500px', border: 'none' }}
                            //         ></iframe>
                            //     )}
                            // </div>
                            <div style={{ height: '100%' }}>
                                {editorData && (
                                    <iframe
                                        title="external-content"
                                        srcDoc={getFormattedEditorData(editorData)} // Use srcDoc to render the HTML content directly
                                        style={{ width: '100%', height: '100%', border: 'none' }}
                                    ></iframe>
                                )}
                            </div>
                        )}
                    </Box>
                </Box>
            </Modal>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleSnackbarClose}
                message={message}
                key={vertical + horizontal}
                autoHideDuration={3000} // Automatically close the snackbar after 3 seconds
            />
        </div>
    );
}


function configUpdateAlert(config) {
    if (configUpdateAlert.configUpdateAlertShown) {
        return;
    }

    const isModifiedByUser = (currentValue, forbiddenValue) => {
        if (currentValue === forbiddenValue) {
            return false;
        }

        if (currentValue === undefined) {
            return false;
        }

        return true;
    };

    const valuesToUpdate = [];

    configUpdateAlert.configUpdateAlertShown = true;

    if (!isModifiedByUser(config.licenseKey, '<YOUR_LICENSE_KEY>')) {
        valuesToUpdate.push('LICENSE_KEY');
    }

    if (!isModifiedByUser(config.ai?.openAI?.requestHeaders?.Authorization, '<YOUR_AI_AUTH_TOKEN>')) {
        valuesToUpdate.push('AI_AUTH_TOKEN');
    }

    if (!isModifiedByUser(config.ai?.openAI?.apiUrl, '<YOUR_AI_API_URL>')) {
        valuesToUpdate.push('AI_API_URL');
    }

    if (valuesToUpdate.length) {
        window.alert(
            [
                'Please update the following values in your editor config',
                'in order to receive full access to the Premium Features:',
                '',
                ...valuesToUpdate.map(value => ` - ${value}`)
            ].join('\n')
        );
    }
}

