import axios from 'axios'

async function users_forgotpassword_Email(email, data, setSuccess, setDataPassword, setUserLink) {
  let token = sessionStorage.getItem("token");
  // let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/forgot-password?email=${email.toLowerCase()}&lmsEnv=${process.env.REACT_APP_LMS_ENV}&userName=${data}`,
  var item = {
    "email": email,
    "userName": data
  }
  let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/forgot-password/generate-otp`, item,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    })
    .then((json) => {
      setSuccess(json)
      setDataPassword(json.data.data)
      setUserLink(json.data.data.resetLink)
      sessionStorage.setItem("email", json.data.data.email)
      localStorage.setItem("email", json.data.data.email)
    })
    .catch(err => {
      // console.log(err)
      throw err
    })
}

export default users_forgotpassword_Email;
