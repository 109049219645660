import React, { useEffect, useState } from "react";
import teacheridstyles from "../css/TeacherId.module.css";
import changepasswordstyle from "../css/changePassword.module.css"; //Change password model css file
import studentedit from "./../css/TeacherEditModal.module.css"; //Edit model css file
import sharestyle from "../css/shareModal.module.css"; //share model css file
import { useNavigate, useLocation } from "react-router-dom";
import tickImage from "./../img/tick-inside-a-circle.svg";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import TeacherController_post from "../API/teacher-controller/User_Teacher_post";
import TeacherControllerGet from "../API/teacher-controller/User_Teacher_Get";
import TeacherIDControllerGet from "../API/teacher-controller/User_TeacherId_Get";
import Coordinator_Type_Get from "../API/misc-controller/coordinator_type_get"; //teacher profile
import Coordinator_type_get_All from "../API_CONTROLLER/coordinator-type-controller/coordinator_type_get_All";
import Gender_Controller_Get from "../API/misc-controller/gender_controller_get";
import Pagination1 from "./pagination1";
import branchchangerstyle from "../css/School_Branch_Changer.module.css";
import Branches_Changer_School_Id from "../API/branch-controller/Branches_Changer_School_Id";
import CSVfile_upload_controller_post from "../API/file-upload-controller/CSVfile_tearcher_upload_controller_post";
import School_name from "./school_name"; //school name changer
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import addacademicstyle from "./../css/AddAcademicModal.module.css";
import SchoolControllerAll from "../API/school-controller/user_schools_all";
import User_Branches_School_Id from "../API/branch-controller/User_Branches_School_Id";
import moment from "moment";
import * as XLSX from 'xlsx';
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
// import School_Branch_Changer from "./School_Branch_Changer";
import searchIcn from "../../../src/img/search.svg";
//Action buttons
import LockIconButton from "../../components/common/LockIconButton";
import EditIconButton from "../../components/common/EditIconButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
//Action Icon Images
import action_sa_Lock from "../../../src/img/password.svg";
import action_sa_Edit from "../../../src/img/Edit.svg";
import action_sa_Share from "../../../src/img/Share.svg";
import action_sa_Delete from "../../../src/img/Delete.svg";
import dashHome from "../../../src/img/dashHome.svg";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";

// input choose file label image
import Cf_img from "../../../src/img/csv.svg";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import changePasswordPut from "../ChangePassword";
import CsvFile from "./CsvFile";
import share from "../Share";
import CommonDeleteModal from "./CommonDeleteModal";
import axios from "axios";
import Download from "../../../src/img/Downloads.svg";
import ClearIcon from "@mui/icons-material/Clear";
import SchoolAdminBreadcrumb from "./SchoolAdminBreadcrumb";
import {
  IconButton,
  TextField,
} from "@mui/material";
const CoordinatorInput = (props) => {
  return (
    <>
      <div className={teacheridstyles.input_element_teacher_teacherid}>
        <select
          className={teacheridstyles.form_input_element_teacher_teacherid_s}
          name="coordinatorTypeId"
          id="coordinatorTypeId"
          value={props.TeacherInputs.coordinatorTypeId}
          onChange={props.onChangeHandler}
        >
          {/* <option value="">Select</option> */}
          <option value="">Select</option>

          {props.subCoordinatorType?.data?.length > 0 ? (
            props.subCoordinatorType?.data?.map((elem) => {
              return <option value={elem.id}>{elem.coordinatorType}</option>;
            })
          ) : (
            <option value="">Select</option>
          )}
        </select>
        {/* <label for="coordinatorType">*Coordinator Type</label> */}
        <label
          htmlFor="coordinatorTypeId"
          className={
            props.TeacherInputs.coordinatorTypeId === ""
              ? teacheridstyles.std_cat
              : "NONE"
          }
        >
          *Coordinator Type
        </label>
        <label
          htmlFor="coordinatorTypeId"
          className={
            props.TeacherInputs.coordinatorTypeId !== ""
              ? teacheridstyles.std_cat
              : "NONE"
          }
        >
          *Coordinator Type
        </label>
      </div>
    </>
  );
};

const TeacherDesignationInput = (props) => {
  return (
    <>
      <div className={teacheridstyles.input_element_teacher_teacherid}>
        <input
          className={teacheridstyles.form_input_element_teacher_teacherid}
          type="text"
          name="designation"
          id="designation"
          autocomplete="off"
          value={props.TeacherInputs.designation}
          onChange={props.onChangeHandler}
          placeholder=" "
        />
        {/* <label for="designation">*Designation</label> */}
        <label for="designation">Designation</label>
      </div>
    </>
  );
};

const PrincipalInput = (props) => {
  return (
    <div className={teacheridstyles.input_element_teacher_teacherid}>
      <input
        className={teacheridstyles.form_input_element_teacher_teacherid}
        type="text"
        name="designation"
        id="designation"
        autocomplete="off"
        value={props.TeacherInputs.designation}
        onChange={props.onChangeHandler}
        placeholder=" "
      />
      {/* <label for="designation">*Designation</label> */}
      <label for="designation">Designation</label>
    </div>
  );
};

const TeacherId = (props) => {
  const navigate = useNavigate();
  const [TeacherInputs, setTeacherInputs] = useState({
    address: "",
    // branch: "4028818380665dd20180667ee7ee0003",
    academicStaffProfile: "PRINCIPAL",
    // coordinatorTypeId: "",
    // designation: "",
    dob: "",
    email: "",
    firstName: "",
    gender: "",
    joinDate: "",
    lastName: "",
    mobile: "",
    previousWorkExp: "",
    // roleId: "ff80818180cce35f0180ccf1e6b30005",
    // school: "2c91808480bc79330180c20aa7b60004",
  });
  const [studentDetails, setStudentDetails] = useState({
    studentschool: "",
    studentbranch: "",
    grade: "",
    section: "",
  });
  // const [schoolDetails, setSchoolDetails] = useState({
  // });
  const [schoolIdData, setSchoolIdData] = useState({});

  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [schoolList, setSchoolList] = useState({});
  const [selectedCSV, setSelectedCSV] = useState();
  const [uploadCSV, setUploadCSV] = useState({});
  const [schoolSelected, setSchoolSelected] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [csvData, setCsvData] = useState("");

  const handleCSV = (e) => {
    const templateName = "TEACHERS_CSV";
    CsvFile(templateName);
    setSelectedCSV(e.target.files[0]);
    setShareFile(e.target.files[0].name);
    setIsFilePicked(true);
  };

  const handleCsvFile = (e) => {
    const templateName = "TEACHERS_CSV";
    CsvFile(templateName, setCsvData);
    window.open(csvData);
  };

  const [successModal_Coordinator, setSuccessModal_Coordinator] =
    useState(false);
  const [successModal_Teacher, setSuccessModal_Teacher] = useState(false);
  const [successModal_Principal, setSuccessModal_Principal] = useState(false);
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const persona = "ACADEMIC_STAFF";

  const prevLocation = useLocation();

  if (prevLocation.state === "edit") {
    prevLocation.state = null;
  }
  // const [showAddAcademicModal, setShowAddAcademicModal] = useState(prevLocation.state == "fromTeacher" ? false : true);
  const [showAddAcademicModal, setShowAddAcademicModal] = useState(true);

  const handleAddAcademicSubmit = (e) => {
    e.preventDefault();

    if (!schoolDetails.school || !schoolDetails.branch) {
      setValidation(true);
      return;
    }

    for (const input in schoolDetails) {
      if (schoolDetails[input] === "") {
        return;
      }
    }
    setShowAddAcademicModal(false);
    // navigate("/dashboard/teacherId");
    // props.handleHeaderTitle("Registered Schools"); // addedd by sg
    var schoolid = schoolDetails.school;
    sessionStorage.setItem("schoolid", schoolid);
    // var branchid = schoolDetails.branch;
    // sessionStorage.setItem("branchid", branchid);
  };

  let schoolid = sessionStorage.getItem("schoolid");
  sessionStorage.setItem("schoolid", schoolid);

  const [isValid, setValidation] = useState(false);
  const handleCross = (e) => {
    let role = localStorage.getItem("role");
    if (role === "SCHOOL_ADMIN") {
      navigate("/dashboard/Branches");

      // SS for Highlighthing the Sidenav Menu Name
      sessionStorage.setItem("sideNavMainMenuNames", "Schools");
      sessionStorage.setItem("sideNavSubMenuNames", "Branches");
    } else {
      navigate("/dashboard/Registered%20Schools");

      // SS for Highlighthing the Sidenav Menu Name
      sessionStorage.setItem("sideNavMainMenuNames", "Schools");
      sessionStorage.setItem("sideNavSubMenuNames", "Registered Schools");
    }
    setShowAddStudentModal(false);
    setShowAddAcademicModal(false);
  };

  const closeSuccessModal = () => {
    setTeacherInputs({
      address: "",
      academicStaffProfile: "PRINCIPAL",
      dob: "",
      email: "",
      firstName: "",
      gender: "",
      joinDate: "",
      lastName: "",
      mobile: "",
      previousWorkExp: "",
    });
    setNum("")
    TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search,
      sortBy,
      sortOrder
    );
    setSuccessModal_Teacher(false);
    setSuccessModal_Coordinator(false);
    setSuccessModal_Principal(false);
    navigate("/dashboard/teacherid");
  };
  const [message, setMessage] = useState("")

  const handleUploadModal = async () => {
    const formData = new FormData();

    formData.append("file", selectedCSV);
    const fileType = "TEACHERS";

    try {
      // Assuming CSVfile_upload_controller_post returns a promise
      await CSVfile_upload_controller_post(formData, setUploadCSV, fileType, setMessage);
      // console.log("upload csv >>> ", sessionStorage.getItem('csv_message'))
      // setShowModalUpload(true);

    } catch (error) {

    }
  };


  const downloadSucessCSV = () => {
    const excelBuffer = convertToExcel(uploadCSV.data.data.passedCSV);
    downloadExcel(excelBuffer, 'passedcsv.xlsx');
  }

  const downloadFailureCSV = () => {
    const excelBuffer = convertToExcel(uploadCSV.data.data.failedCSV);
    downloadExcel(excelBuffer, 'failedcsv.xlsx');
  }

  const convertToExcel = (jsonData) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    return excelBuffer;
  };

  const downloadExcel = (excelBuffer, fileName) => {
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // useEffect(() => {
  //   // This effect runs whenever uploadCSV or message changes
  //   console.log('Teacher upload csv >> ', uploadCSV, message);

  //   if (uploadCSV !== null && message !== 'Failed') {
  //     setShowModalUpload(true);
  //     setIsError(false);
  //   } else {
  //     setShowModalUpload(false);
  //     setIsError(true);
  //     setShowError(message);
  //   }
  // }, [uploadCSV, message]);

  // const csv_message = sessionStorage.getItem('csv_message');
  // console.log('csv_message >> ',csv_message)
  // useEffect(() => {

  //   if(csv_message !== '')
  //    {
  //     setShowModalUpload(true);
  //     setIsError(false);
  //    }else
  //    {
  //       setShowModalUpload(false);
  //       setIsError(true);
  //       setShowError('An error occurred during file upload.');
  //    }
  // }, [csv_message]);

  const handlerTeacherInputs = (e) => {
    if (e.target.type === "radio") {
      setTeacherInputs({
        ...TeacherInputs,
        [e.target.name]: e.target.id,
      });
    } else if (e.target.type === "date") {
      const selectedDate = new Date(e.target.value);
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        setShowError("Please select a valid date");
        return;
      } else {
        setTeacherInputs({
          ...TeacherInputs,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setTeacherInputs({
        ...TeacherInputs,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handlerTeacherNameInputs = (e) => {
    const result = e.target.value.replace(/[^a-z]/gi, "");
    setTeacherInputs({
      ...TeacherInputs,
      [e.target.name]: result,
    });
  };

  const teacherList = props.teacherList;
  const updateteacherList = props.updateteacherList;
  const [isError, setIsError] = useState("");
  const [showError, setShowError] = useState("");
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [shareFile, setShareFile] = useState("");
  const [shareFirstName, setShareFirstName] = useState("");
  const [shareLastName, setShareLastName] = useState("");
  const [totalele, setTotalelements] = useState(0);
  const [regexPass, setRegexPass] = useState(true);
  const [num, setNum] = useState("")

  function validateEmail(emailValue) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(emailValue);
  }
  function validateMobile(mobileValue) {
    const regex = /^[\+]?[(]?[6-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(mobileValue);
  }

  function validateDates(dobValue, joinDateValue) {
    const [dobYear, dobMonth, dobDay] = dobValue.split("-").map(Number);
    const [joinYear, joinMonth, joinDay] = joinDateValue.split("-").map(Number);
    if (dobYear > joinYear) return false;
    if (dobYear < joinYear) return true;
    if (dobMonth > joinMonth) return false;
    if (dobMonth < joinMonth) return true;
    return dobDay < joinDay;
  }

  function validateWorkExperience(yearsValue) {
    if (yearsValue.trim() === '') {
      return true;
    }
    const regex = /^(?:0|[1-9]\d*)(\.\d+)?$/;
    return regex.test(yearsValue);
  }

  function validatePassword(passValue) {
    const regex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+_()=])(?=\\S+$).{8,}$"
    );
    return regex.test(passValue);
  }

  const handleKeyPress = (e) => {
    const result = e.target.value.replace(/[^0-9]/g, "");
    setNum(result);
  };

  const handleCreateTeacher = (e) => {
    // for (const input in TeacherInputs) {
    //   if (TeacherInputs[input] === "") {
    //     setIsError(true);
    //     setShowError("Please enter all the required values");
    //     return;
    //   }
    // }


    if (TeacherInputs.firstName === "" ||
      TeacherInputs.lastName === "" ||
      TeacherInputs.email === "" ||
      num === "" ||
      TeacherInputs.gender === "") {
      setIsError(true);
      setShowError("Please enter all the required values");
      return;
    }

    // if((!selectedCSV)  || selectedCSV?.name == ""){
    //   setIsError(true);
    //   setShowError("Please Upload a File");
    //   return;
    // }File Validation is not mandatory
    // other validation condtions
    // if (!validateMobile(TeacherInputs.mobile)) {
    //   setIsError(true);
    //   setShowError("Phone number invalid");
    // }

    if (TeacherInputs.academicStaffProfile === "COORDINATOR") {
      if (!TeacherInputs.coordinatorTypeId) {
        setIsError(true);
        setShowError("Please select coordinator type");
      }
    }

    if (!validateMobile(num)) {
      setIsError(true);
      setShowError("Phone number invalid");
      return;
    }

    if (TeacherInputs.dob !== '' && TeacherInputs.joinDate !== '') {
      if (!validateDates(TeacherInputs.dob, TeacherInputs.joinDate)) {
        setIsError(true);
        //alert("The DOB must be before the Admission date.")
        setShowError("The DOB must be before the joining date.");
        return;
      }
    }

    if (!validateWorkExperience(TeacherInputs.previousWorkExp)) {
      setIsError(true);
      setShowError("Please enter a valid value for work experience");
    }

    if (!validateEmail(TeacherInputs.email)) {
      setIsError(true);
      setShowError("Please enter a valid email address");
    }
    //  else {
    //   if (active === "FirstInputBox") {
    //     setSuccessModal_Teacher(true);
    //   } else if (active === "SecondInputBox") {
    //     setSuccessModal_Coordinator(true);
    //   } else if (active === "3rdInputBox") {
    //     setSuccessModal_Principal(true);
    //   }
    // }

    setIsError(false);

    async function TeacherController_post(
      TeacherInputs,
      setSuccess,
      setShowError
    ) {
      let token = sessionStorage.getItem("token");
      if (TeacherInputs.academicStaffProfile === "COORDINATOR") {
        var item = {
          academicStaffProfile: TeacherInputs.academicStaffProfile,
          address: TeacherInputs.address,
          branch: sessionStorage.getItem("branchid"),
          coordinatorTypeId: TeacherInputs.coordinatorTypeId,
          dob: TeacherInputs.dob,
          email: TeacherInputs.email.toLowerCase(),
          firstName: TeacherInputs.firstName,
          gender: TeacherInputs.gender,
          joinDate: TeacherInputs.joinDate,
          lastName: TeacherInputs.lastName,
          mobile: num,
          previousWorkExp: TeacherInputs.previousWorkExp,
          // roleId: "ff8081818041dfd1018041e62d970006",
          school: sessionStorage.getItem("schoolid"),
          lmsEnv: process.env.REACT_APP_LMS_ENV,
          // token: "770-1798-MMARS",
        };
      } else {
        var item = {
          address: TeacherInputs.address,
          branch: sessionStorage.getItem("branchid"),
          academicStaffProfile: TeacherInputs.academicStaffProfile,

          designation: TeacherInputs.designation,
          // coordinatorTypeId: TeacherInputs.coordinatorTypeId,
          dob: TeacherInputs.dob,
          email: TeacherInputs.email.toLowerCase(),
          firstName: TeacherInputs.firstName,
          gender: TeacherInputs.gender,
          joinDate: TeacherInputs.joinDate,
          lastName: TeacherInputs.lastName,
          mobile: num,
          previousWorkExp: TeacherInputs.previousWorkExp,
          // roleId: "ff8081818041dfd1018041e62d970006",
          school: sessionStorage.getItem("schoolid"),
          lmsEnv: process.env.REACT_APP_LMS_ENV,
        };
      }

      await axios
        .post(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers`,
          item,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((json) => {
          setSuccess(json.data.success);
          setShowError(json.message);
          // console.log(active, "active");
          if (json.data.success === true) {
            if (active === "FirstInputBox") {
              setSuccessModal_Teacher(true);
            } else if (active === "SecondInputBox") {
              setSuccessModal_Coordinator(true);
            } else if (active === "3rdInputBox") {
              setSuccessModal_Principal(true);
            }
          }

        })
        .catch((err) => {
          if (err.message.includes("409")) {
            setShowError(
              "Academic staff already exists with this same email or mobile."
            );
            setIsError(true);
          } else if (err.message.includes("400")) {
            setIsError(true);
            setShowError(
              "Please enter a valid data."
            );
            return false
          }
          else if (err.message.includes("500")) {
            setIsError(true);
            setShowError(
              "failed to update Teacher."
            );
          } else {
            setIsError(true);
            setShowError(err.message);
          }
        });
    }

    TeacherController_post(TeacherInputs, setSuccess, setShowError);


    // TeacherController_post(TeacherInputs, setSuccess, setShowError)
    // .then((response) => {
    //   console.log(">>>>>>>>>>>", response)
    // if (response.status == 200) {
    //   setShareFirstName(
    //     e.target.parentNode.parentNode.children[0].children[0].value
    //   );
    //   setShareLastName(
    //     e.target.parentNode.parentNode.children[1].children[0].value
    //   );
    //   setShowModalCreate(true);
    // } else if (response.status != 200) {
    //   setIsError("true");
    //   setShowError(response?.data?.message ? response.data.message : "Failed To Add a Teacher");
    //   return false;
    // }
    // });

    // setShareFirstName(
    //   e.target.parentNode.parentNode.children[0].children[0].value
    // );
    // setShareLastName(
    //   e.target.parentNode.parentNode.children[1].children[0].value
    // );
    // setShowModalCreate(true);
  };

  const handleUploadModalOk = (e) => {
    setShowModalUpload(false);
    // navigate("/dashboard/academicid");
  };


  const [active, setActive] = useState("3rdInputBox");

  const ChangeButtonHandler = (e) => {
    if (e.target.id === "TEACHER") {
      setActive("FirstInputBox");
    }
    if (e.target.id === "COORDINATOR") {
      setActive("SecondInputBox");
      // Coordinator_Type_Get(setCoordinatorType);
    }
    if (e.target.id === "PRINCIPAL") {
      setActive("3rdInputBox");
    }
    setTeacherInputs({
      ...TeacherInputs,
      [e.target.name]: e.target.id,
    });
  };

  // search starts
  const [search, setSearch] = useState({
    search: "",
  });
  const [isSearch, setisSearch] = useState(true);
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search,
      sortBy,
      sortOrder
    );
  };
  const handleInputChange = (e) => {
    const inputValue = e?.target?.value?.toLowerCase();
    if (inputValue) {
      let result = [...totalele];
      result = [...totalele]?.filter((data) => {
        return data?.userName?.toLowerCase().includes(inputValue);
      });
      setTeacherData(result)
    } else {
      TeacherControllerGet(
        setTeacherData,
        setPageDetail,
        page,
        rowsInput,
        search,
        sortBy,
        sortOrder
      );
    }
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };

  useEffect(() => {
    if (isSearch) {
      searchingData()
    }
  }, [])

  const searchingData = async () => {
    setisSearch(false);
    let token = sessionStorage.getItem("token");
    let schoolid = sessionStorage.getItem("schoolid");
    let branchid = sessionStorage.getItem("branchid");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageNumber=0&pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&schoolId=${schoolid ?? ""}&branchId=${branchid || ""}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totlaele = result?.data?.data?.totalElements
    let response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageNumber=0&pageSize=${totlaele}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search || ""}&schoolId=${schoolid ?? ""}&branchId=${branchid || ""}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      },)
    const totalData = response?.data?.data?.data
    setTotalelements(totalData);
  }

  // search ends

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  // pagination ends

  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends

  const [pageDetail, setPageDetail] = useState({});
  const [teacherData, setTeacherData] = useState({});
  const [coordinatorType, setCoordinatorType] = useState({});
  const [genderType, setGenderType] = useState({});
  const [subCoordinatorType, setSubCoordinatorType] = useState({});
  useEffect(() => {
    Coordinator_Type_Get(setCoordinatorType);
    Gender_Controller_Get(setGenderType);
    Coordinator_type_get_All(setSubCoordinatorType);
    return () => { };
  }, []);
  const [success, setSuccess] = useState({});

  useEffect(() => {
    if (success.errorCode === 409) {
      setIsError(true);
      setShowError("Teacher already exist");
      return () => { };
    }
  }, [success]);

  // sort
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  let branchids = sessionStorage.getItem("branchid")
  useEffect(() => {
    schoolid && branchids && TeacherControllerGet(
      setTeacherData,
      setPageDetail,
      page,
      rowsInput,
      search,
      sortBy,
      sortOrder
    );
    return () => { };
  }, [page, rowsInput, sessionStorage.getItem("branchid"), sortOrder]);

  // Teacher id data fetching
  const [teacherIDData, setTeacherIDData] = useState({});
  // popup modal starts
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [shareEmail, setShareEmail] = useState("");
  const [shareClick, setShareClick] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false);
  // const handleChangePassword = (e) => {
  //   setShowModalChangePassword(true);
  // };
  const handleUpdatedPassword = (e) => {
    setShowModalChangePassword(false);
  };
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };
  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };
  const [successP, setPSuccess] = useState(false);
  const handleSubmit = (e) => {
    changePasswordPut(userName, userId, password, persona, setPSuccess);
    e.preventDefault();
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      return;
    }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    const pass = validatePassword(loginPassword);
    if (pass === false) {
      setRegexPass(pass);
      setIsTooltipOpen(true);
      return;
    }
    if (successP === true) {
      return;
    }
    setPasswordMatch(true);
    setShowModalChangePassword(false);
  };
  const handleEditModalEdit = (e) => {
    setShowModalEdit(false);
    navigate("/dashboard/academicid");
  };

  const handleEditModalcross = (e) => {
    setShowModalEdit(false);
    // navigate("/dashboard/studentTable");
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  // popup modal ends

  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    // branch: sessionStorage.getItem("branchid"),
    branch: "",
  });

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
      schoolName: e.target.id,
    });
    setSchoolSelected("SCHOOL_SELECTED");
  };

  useEffect(() => {
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    return () => { };
  }, [schoolDetails]);

  useEffect(() => {
    // setShowAddAcademicModal(true);
    //school Input
    SchoolControllerAll(setSchoolList);
    User_Branches_School_Id(schoolDetails, setBranchList, studentDetails);
    GetLastModifiedAt(setModifiedDate);
  }, []);
  //BranchController ends
  useEffect(() => {
    // branch input for school //
    User_Branches_School_Id(schoolDetails, setBranchList, studentDetails);
    return () => { };
  }, [schoolDetails.school, studentDetails]);

  // useEffect(() => {
  //   SchoolControllerIdGet(setSchoolIdData);
  //   return () => {};
  // }, [teacherData]);

  const [selectedItem, setSelectedItem] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const showDeleteModal = (id) => {
    setSelectedItem(id);
    setOpenDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  const userType = selectedItem.role;

  const deleteHandler = () => {
    let token = sessionStorage.getItem("token");
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${selectedItem.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        // if (response?.data) {
        //   const find = updatedData?.filter(
        //     (data) => data.id !== selectedItem.id
        //   );
        //   setFilterValue(find);
        setOpenDeleteModal(false);
        TeacherControllerGet(
          setTeacherData,
          setPageDetail,
          page,
          rowsInput,
          search,
          sortBy,
          sortOrder
        );
      })
      .catch(function (response) { });
    // hideDeleteModal();
  };

  const PassOnChange = (e) => {
    setLoginPassword(e.target.value);
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (shareClick) { share(userName, userId, persona) };
  }, [userName, userId]);

  const [selectedCode, setSelectedCode] = useState("");

  const handleCodeChange = (event) => {
    setSelectedCode(event.target.value);
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpen(false);

  };


  const handleTooltipClose = () => {
    setIsTooltipOpen(false);

  };

  const shareHandler = (row) => {
    var teacherid = row.id;
    sessionStorage.setItem("teacherid", teacherid);
    TeacherIDControllerGet(
      setTeacherIDData,
    );
    setUserId(row.userId);
    setUserName(row.userName);
    userId && share(userName, userId, persona);
    setShowModalShare(true);
    setShareClick(true)
  };

  return (
    <>
      <SchoolAdminBreadcrumb
        dashHome={dashHome}
        sectionName={'Academic Staff'}
        date={modifiedDate.data}
        isSecondOne={true}
        sectionLink={''}
        secondSectionName={'Add Teacher'}
      />
      <article className={teacheridstyles.article_teacherid}>
        <div style={{ marginTop: "10px" }} className={branchchangerstyle.Grid_school_name_branch_teacherid}>
          <div className={branchchangerstyle.school_name_teacherid}>
            <School_name />
          </div>
          <select
            className={branchchangerstyle.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={branchchangerstyle.option} value=""></option>
            )}
          </select>
        </div>
        <div className={teacheridstyles.grid_container_header_plan_teacherid}>
          <div
            className={teacheridstyles.item_plan1_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={teacheridstyles.item_plan2_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={teacheridstyles.item_plan3_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId");
            }}
          >
            Academic Staff
          </div>
          <div
            className={teacheridstyles.item_plan4_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={teacheridstyles.item_plan5_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={teacheridstyles.item_plan6_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/TokenList");
            }}
          >
            Tokens
          </div>
          <div
            className={teacheridstyles.item_plan7_teacherid + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/Logo");
            }}
          >
            Branch Logo
          </div>
        </div>
        <div className={teacheridstyles.create_teacher_teacherid}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={teacheridstyles.teacher1_teacherid}>
              <div
                className={teacheridstyles.create_HOD_box_teacherid}
                style={{ color: "#ff9100" }}
              >
                {" "}
                Create{" "}
              </div>

              <div className={teacheridstyles.create_HOD_box1_teacherid}>
                {coordinatorType.length > 0 ? (
                  coordinatorType.map((coordinatorType, index) => {
                    return (
                      <div className={teacheridstyles.radio_teacherid}>
                        <input
                          type="radio"
                          name="academicStaffProfile"
                          id={coordinatorType.code}
                          defaultChecked="checked"
                          className={teacheridstyles.radio_active + ' ' + 'az_input_radio'}
                          placeholder=" "
                          value={TeacherInputs.academicStaffProfile}
                          onClick={ChangeButtonHandler}
                        // onChange={handlerTeacherInputs}
                        />
                        <label for="test_teacherid">
                          {coordinatorType.name}
                        </label>
                      </div>
                    );
                  })
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className={teacheridstyles.input_form_teacher_teacherid}>
              <div className={teacheridstyles.input_element_teacher_teacherid}>
                <input
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid + ' ' + 'az_text_input'
                  }
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder=" "
                  autocomplete="off"
                  value={TeacherInputs.firstName}
                  onChange={handlerTeacherNameInputs}
                />
                <label for="firstName">*First Name</label>

                <p>
                  <spam className={teacheridstyles.logo4}>
                    <i className="material-icons"> &#x26a0;</i>
                  </spam>
                  You{" "}
                  <spam className={teacheridstyles.logo4}> cannot change </spam>{" "}
                  the name after creating a profile
                </p>
              </div>
              <div className={teacheridstyles.input_element_teacher_teacherid}>
                <input
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid + ' ' + 'az_text_input'
                  }
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder=" "
                  autocomplete="off"
                  value={TeacherInputs.lastName}
                  onChange={handlerTeacherNameInputs}
                />
                <label for="Name">*Last Name</label>
                <p>
                  <spam className={teacheridstyles.logo3}>
                    <i className="material-icons"> &#x26a0;</i>
                  </spam>
                  You{" "}
                  <spam className={teacheridstyles.logo3}> cannot change </spam>{" "}
                  the name after creating a profile
                </p>
              </div>
              <div className={teacheridstyles.input_element_teacher_teacherid}>
                <input
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid + ' ' + 'az_text_input'
                  }
                  type="email"
                  name="email"
                  id="email"
                  placeholder=" "
                  autocomplete="off"
                  value={TeacherInputs.email}
                  onChange={handlerTeacherInputs}
                />
                <label for="email">*Primary Email</label>
              </div>

              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <select
                  style={{ width: "60px", height: "45px", borderRadius: "8px" }}
                  value={selectedCode}
                  onChange={handleCodeChange}
                  className="az_select_1"
                >
                  <option value="+1">+91</option>
                  <option value="+44">
                    +44
                  </option>
                  <option value="+91">+1</option>
                </select>

                <div className={teacheridstyles.input_element_teacher_teacherid}>
                  <input
                    className={
                      teacheridstyles.form_input_element_teacher_teacherid + ' ' + 'az_text_input'
                    }
                    type="tel"
                    name="mobile"
                    id="mobile"
                    placeholder=""
                    max={10}
                    maxLength={10}
                    autocomplete="off"
                    value={num}
                    onChange={(e) => handleKeyPress(e)}
                  />
                  <label for="mobile">*Primary Phone no.</label>
                </div>
              </div>
              <div className={teacheridstyles.input_element_teacher_teacherid}>
                <input
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid_s + ' ' + 'az_text_input'
                  }
                  type="date"
                  name="dob"
                  id="dob"
                  placeholder=" "
                  value={TeacherInputs.dob}
                  onChange={handlerTeacherInputs}
                />
                {/* <label for="dateOfBirth">*Date of Birth</label> */}
                <label
                  htmlFor="dob"
                  className={
                    TeacherInputs.dob === "" ? teacheridstyles.std_cat : "NONE"
                  }
                >
                  Date of Birth
                </label>
                <label
                  htmlFor="dob"
                  className={
                    TeacherInputs.dob !== "" ? teacheridstyles.std_cat : "NONE"
                  }
                >
                  Date of Birth
                </label>
              </div>
              <div className={teacheridstyles.input_element_teacher_teacherid}>
                <select
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid_s + ' ' + 'az_select_1'
                  }
                  name="gender"
                  id="gender"
                  value={TeacherInputs.gender}
                  onChange={handlerTeacherInputs}
                >
                  <option value="">Select</option>
                  {/* <option value="select">Select</option> */}
                  {genderType.length > 0 ? (
                    genderType.map((genderType) => {
                      return (
                        <option value={genderType.code}>
                          {genderType.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">Loading....</option>
                  )}
                </select>
                {/* <label for="gender">*Gender</label> */}
                <label
                  htmlFor="gender"
                  className={
                    TeacherInputs.gender === ""
                      ? teacheridstyles.std_cat
                      : "NONE"
                  }
                >
                  *Gender
                </label>
                <label
                  htmlFor="gender"
                  className={
                    TeacherInputs.gender !== ""
                      ? teacheridstyles.std_cat
                      : "NONE"
                  }
                >
                  *Gender
                </label>
              </div>

              <div className={teacheridstyles.input_element_teacher_teacherid}>
                <input
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid_s + ' ' + 'az_text_input'
                  }
                  type="date"
                  name="joinDate"
                  id="joinDate"
                  placeholder=" "
                  value={TeacherInputs.joinDate}
                  onChange={handlerTeacherInputs}
                />
                {/* <label for="dateofjoining">*Date of Joining</label> */}
                <label
                  htmlFor="joinDate"
                  className={
                    TeacherInputs.joinDate === ""
                      ? teacheridstyles.std_cat
                      : "NONE"
                  }
                >
                  Date of Joining
                </label>
                <label
                  htmlFor="joinDate"
                  className={
                    TeacherInputs.joinDate !== ""
                      ? teacheridstyles.std_cat
                      : "NONE"
                  }
                >
                  Date of Joining
                </label>
              </div>

              <div className={teacheridstyles.input_element_teacher_teacherid}>
                {/* <select
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid_s + ' ' + 'az_select_1'
                  }
                  name="previousWorkExp"
                  id="previousWorkExp"
                  value={TeacherInputs.previousWorkExp}
                  onChange={handlerTeacherInputs}
                > */}
                {/* <option value="select">Select</option> */}
                {/* <option value="">Select</option>
                  <option value="1 yrs">1 Year</option>
                  <option value="2 yrs">2 Year</option>
                  <option value="3 yrs">3 Year</option>
                  <option value="4 yrs">4 Year</option>
                  <option value="5 yrs">5 Year</option>
                  <option value="6 yrs">6 Year</option>
                </select> */}
                {/* <label for="experience">*Previous work experience</label> */}
                <input
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid + ' ' + 'az_text_input'
                  }
                  type="text"
                  name="previousWorkExp"
                  id="previousWorkExp"
                  placeholder=" "
                  autocomplete="off"
                  value={TeacherInputs.previousWorkExp}
                  onChange={handlerTeacherInputs}

                />

                <label
                  htmlFor="previousWorkExp"
                  className={
                    TeacherInputs.previousWorkExp === ""
                      ? teacheridstyles.std_cat
                      : "NONE"
                  }
                >
                  Previous work experience
                </label>
                <label
                  htmlFor="previousWorkExp"
                  className={
                    TeacherInputs.previousWorkExp !== ""
                      ? teacheridstyles.std_cat
                      : "NONE"
                  }
                >
                  Previous work experience
                </label>
              </div>

              <div className={teacheridstyles.input_element_teacher_teacherid}>
                <input
                  className={
                    teacheridstyles.form_input_element_teacher_teacherid + ' ' + 'az_text_input'
                  }
                  type="text"
                  name="address"
                  id="address"
                  placeholder=" "
                  autocomplete="off"
                  value={TeacherInputs.address}
                  onChange={handlerTeacherInputs}

                />
                {/* <label for="address">Address</label> */}
                <label for="address">Address</label>
              </div>

              {active === "FirstInputBox" && (
                <TeacherDesignationInput
                  onClickHandler={ChangeButtonHandler}
                  onChangeHandler={handlerTeacherInputs}
                  TeacherInputs={TeacherInputs}
                />
              )}
              {active === "SecondInputBox" && (
                <CoordinatorInput
                  onClickHandler={ChangeButtonHandler}
                  onChangeHandler={handlerTeacherInputs}
                  TeacherInputs={TeacherInputs}
                  subCoordinatorType={subCoordinatorType}
                />
              )}
              {active === "3rdInputBox" && (
                <PrincipalInput
                  onChangeHandler={handlerTeacherInputs}
                  TeacherInputs={TeacherInputs}
                />
              )}

              <div
                className={
                  teacheridstyles.input_block_element_teacher_teacherid
                }
              ></div>
              <div className={teacheridstyles.teacher5_teacherid_2}>
                <button
                  className={teacheridstyles.create_button_teacher_teacherid_2}
                  onClick={handleCreateTeacher}
                  style={{ backgroundColor: "#403E75" }}
                >
                  create
                </button>
              </div>

              <div className={teacheridstyles.teacher5_teacherid}>
                <button
                  className={teacheridstyles.create_button_teacher_teacherid}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard/teacherId");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
          <div
            className={teacheridstyles.error_box}
            style={isError ? { display: "block" } : { display: "none" }}
          >
            {showError}
          </div>
          <div className={teacheridstyles.box_container}></div>
          <div className={teacheridstyles.teacher2_teacherid}>
            <div className={teacheridstyles.icon_remove_teacherid}>
              <div className={teacheridstyles.wrapper_teacherid}>
                <label
                  for="img"
                  style={{
                    color: "#272727",
                    position: "relative",
                    top: "4px",
                  }}
                >
                  Upload CSV
                  <div className={teacheridstyles.icon}>
                    <div className={teacheridstyles.tooltip}>
                      Download Sample CSV
                    </div>
                    <span onClick={handleCsvFile}>
                      <img
                        src={Download}
                        alt="img"
                        style={{
                          position: "relative",
                          top: "10px",

                          fontSize: "large",
                        }}
                      />
                    </span>
                  </div>
                </label>
              </div>
            </div>

            <div>
              <input
                type="file"
                name="file"
                accept=".csv"
                style={{ display: "none" }}
                id="testId"
                onChange={handleCSV}
                className={teacheridstyles.custom_file_input}
              />
              <div className={teacheridstyles.chooseFile_btn_wrapper}>
                <img src={Cf_img} alt="img" />
                <label
                  htmlFor="testId"
                  className={teacheridstyles.chooseFile_btn}
                >
                  Choose File
                </label>
              </div>
              {isFilePicked && (
                <button
                  onClick={handleUploadModal}
                  id={teacheridstyles.submit_teacherid}
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div>
            {/* <p>sampleteacher.csv</p>
                                    <p className={teacheridstyles.remove_teacherid}><a href="">Remove</a></p>
                                    <input id={teacheridstyles.submit_teacherid} type="submit"/> */}
          </div>
        </div>
        <div className={teacheridstyles.teacher_list_teacherid}>
          <div className={teacheridstyles.list1_teacherid}>
            <i className="fa-solid fa-chalkboard-user" aria-hidden="true">
              {" "}
            </i>{" "}
            Teacher/Coordinator/Principal List
          </div>
          <div>
            <TextField
              onChange={handleInputChange}
              type={"text"}
              value={query}
              placeholder={"Search anything..."}
              sx={{
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  fontFamily: "Poppins !important",
                  fontSize: "13px",
                  marginLeft: "20px !important",
                  background: "#FFFFFF",

                  opacity: 1,
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {showClear && (
                      <IconButton onClick={handleClear}>
                        <ClearIcon
                          style={{ color: "#F05262", fontSize: "1vw" }}
                        />
                      </IconButton>
                    )}
                    {!showClear && (
                      <IconButton>
                        <img
                          src={searchIcn}
                          style={{
                            verticalAlign: "top",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          </div>
        </div>
        {/* <!-- ===========TABLE STARTS FROM HERE=========== --> */}
        <div className={teacheridstyles.list_table_teacher_div_teacherid}>
          <table
            className={teacheridstyles.table_section_teacher_list_teacherid + ' ' + 'az_table'}
          >
            <thead className="az_table_head">
              <tr
                className={
                  teacheridstyles.table_head_section_teacher_list_teacherid
                }
              >
                <td style={{ width: "4%", justifyContent: "center" }}
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                >
                  #{/* <i className="fa-solid fa-arrows-up-down"></i> */}
                </td>
                <td
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                  id={teacheridstyles.email_td}
                >
                  <div className={teacheridstyles.name}>
                    Email
                    {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("email");
                        // await initData(!sortOrder, "id");
                      }}
                    />
                  </div>
                </td>
                <td
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                >
                  Password
                  {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="14"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("password");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                <td
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                >
                  Role
                  {/* <i className="fa-solid fa-arrows-up-down"></i>{" "} */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="14"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("role");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                <td
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                >
                  {" "}
                  User Name
                  {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="14"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("userName");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                {/* <td
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                >
                  User Id */}
                {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                {/* <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="14"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("userId");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td> */}
                <td
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                >
                  Login Time
                  {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                  <img
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "3px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="14"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("loginTime");
                      // await initData(!sortOrder, "id");
                    }}
                  />
                </td>
                <td
                  className={teacheridstyles.list_table_teacher_list_teacherid}
                  style={{ paddingRight: "30px" }}
                >
                  Action
                  {/* <i className="fa-solid fa-arrows-up-down"></i> */}
                </td>
              </tr>
            </thead>
            <tbody className="az_table_body">
              {teacherData.length > 0 ? (
                teacherData.map((teacherData, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * rowsInput.rows + (index + 1)}</td>
                      <td
                        id={teacheridstyles.email}
                        onClick={(e) => {
                          e.preventDefault();
                          var teacherid = teacherData.id;
                          sessionStorage.setItem("teacherid", teacherid);
                          TeacherIDControllerGet(setTeacherIDData);
                          setShowModalEdit(true);
                        }}
                      >
                        {teacherData.email}
                      </td>
                      <td className={teacheridstyles.type_of_school}>
                        {teacherData.password}
                      </td>
                      <td className={teacheridstyles.list_role}>
                        {teacherData.academicStaffProfile}
                      </td>
                      <td>{teacherData.userName}</td>
                      {/* <td>
                        <div className={teacheridstyles.userId}>
                          {teacherData.userId}{" "}
                        </div>
                      </td> */}
                      <td>
                        {teacherData.lastLoginTime !== null &&
                          moment(teacherData.lastLoginTime).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                      </td>
                      <td
                        className={
                          teacheridstyles.list_body_table_teache_list_teacherid
                        }
                      >
                        <div className={teacheridstyles.wrapper_teacherid}>
                          <div
                            className={teacheridstyles.icon}
                            onClick={() => {
                              setShowModalChangePassword(true);
                              setUserId(teacherData.userId);
                              setUserName(teacherData.userName);
                              setLoginPassword("");
                              setConfirmLoginPassword("");
                              setRegexPass(true);
                            }}
                          >
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>
                              Change Password
                            </div>
                            <span
                              id={teacheridstyles.change_password_teacherid}
                            >
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Lock}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div className={teacheridstyles.icon}>
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>Edit</div>
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                var teacherid = teacherData.id;
                                sessionStorage.setItem("teacherid", teacherid);

                                navigate("/dashboard/academicid");
                              }}
                            >
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Edit}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div
                            className={teacheridstyles.icon}
                            // onClick={handleShareModal}
                            onClick={() => shareHandler(teacherData)}
                          >
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>Share</div>
                            <span>
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Share}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                          <div
                            className={teacheridstyles.icon}
                            onClick={() => {
                              showDeleteModal(teacherData);
                            }}
                          >
                            <div className={teacheridstyles.tooltip + ' ' + 'az_tooltip'}>
                              Remove
                            </div>
                            <span>
                              <img
                                className={
                                  dashboardContentStyles.btnHoverEffect
                                }
                                src={action_sa_Delete}
                                alt="Lock-btn"
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td
                  colSpan="10"
                  style={{
                    // "white-space": "nowrap",/
                    height: "23rem",
                    // overflow: "scroll",
                    // "text-overflow": "clip",
                    // margin: "0px 10px",
                  }}
                >
                  NO DATA AVAILABLE
                </td>
              )}
            </tbody>
          </table>
        </div>
        {/* <!-- =================================TABLE ENDS HERE============= --> */}

        {/* --------------------------------------parth25-07--------------------------- */}

        <div
          className={addacademicstyle.flex_container}
          id={addacademicstyle.modal}
          style={
            showAddAcademicModal ? { display: "flex" } : { display: "none" }
          }
        >
          <div
            className={addacademicstyle.main_content}
            id={addacademicstyle.modal_content}
          >
            <div className={addacademicstyle.main_content_head}>
              <div>Add Academic Staff</div>
              <div>
                <i
                  className="fa-solid fa-xmark cursorPointer"
                  onClick={handleCross}
                  style={{ paddingRight: "15px", color: "#354052" }}
                ></i>
              </div>
            </div>

            <div className={addacademicstyle.main_content_box_head}>
              School Details
            </div>
            <div className={addacademicstyle.main_content_box}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className={addacademicstyle.input_element}>
                  <select
                    className={addacademicstyle.form_select_element + " " + "az_select_1" + ' ' + 'cursorPointer'}
                    name="school"
                    id={schoolDetails.schoolName}
                    value={schoolList.school}
                    onChange={handlerSchoolDetailsInputs}
                  >
                    <option value="">Select</option>
                    {schoolList.length > 0 ? (
                      schoolList.map((schoolList, index) => {
                        return (
                          <option
                            key={index}
                            value={schoolList.schoolId}
                            id={schoolList.schoolName}
                          >
                            {schoolList.schoolName}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={addacademicstyle.option}
                        value=""
                      ></option>
                    )}
                  </select>
                  {/* <label for="SchoolName">*School Name</label> */}
                  <label
                    htmlFor={schoolDetails.schoolName}
                    className={
                      schoolList.school === ""
                        ? addacademicstyle.std_cat
                        : "NONE"
                    }
                  >
                    *School Name
                  </label>
                  <label
                    htmlFor={schoolDetails.schoolName}
                    className={
                      schoolList.school !== ""
                        ? addacademicstyle.std_cat
                        : "NONE"
                    }
                  >
                    *School Name
                  </label>
                  {isValid && !schoolDetails.school && (
                    <p
                      style={{ fontSize: "15px", color: "red", padding: "5px" }}
                    >
                      School Name is Required
                    </p>
                  )}
                </div>
                <div className={addacademicstyle.input_element}>
                  <select
                    className={addacademicstyle.form_select_element + " " + "az_select_1" + ' ' + 'cursorPointer'}
                    name="branch"
                    id="branch"
                    disabled={schoolSelected !== "SCHOOL_SELECTED"}
                    value={schoolDetails.branch}
                    onChange={handlerSchoolDetailsInputs}
                  >
                    <option value="">Select</option>
                    {branchList.length > 0 ? (
                      branchList.map((branchList, index) => {
                        return (
                          <option key={index} value={branchList.branchId}>
                            {branchList.branch}
                          </option>
                        );
                      })
                    ) : (
                      <option
                        className={addacademicstyle.option}
                        value=""
                      ></option>
                    )}
                  </select>
                  {/* <label for="BranchName">*Branch Name</label> */}
                  <label
                    htmlFor="branch"
                    className={
                      schoolDetails.branch === ""
                        ? addacademicstyle.std_cat
                        : "NONE"
                    }
                  >
                    *Branch Name
                  </label>
                  <label
                    htmlFor="branch"
                    className={
                      schoolDetails.branch !== ""
                        ? addacademicstyle.std_cat
                        : "NONE"
                    }
                  >
                    *Branch Name
                  </label>
                  {isValid && !schoolDetails.branch && (
                    <p
                      style={{ fontSize: "15px", color: "red", padding: "5px" }}
                    >
                      Branch Name is Required
                    </p>
                  )}
                </div>
              </form>
            </div>
            <div className={addacademicstyle.modal_buttons}>
              <button
                className={addacademicstyle.choosefile_button_2}
                id={addacademicstyle.choose_button_id_2}
                onClick={handleAddAcademicSubmit}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>

        {/* <div className={teacheridstyles.data_available_teacherid}>
            <p className={teacheridstyles.data_placeholder_teacherid}>NO DATA AVAILABLE</p>
          </div> */}
        {/* upload modal starts  */}
        {/*
        before bulk csv implementation
        <div
          className={teacheridstyles.flex_container_teacherid}
          id={teacheridstyles.modal_teacherid}
          style={showModalUpload ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={teacheridstyles.main_content_teacherid}
            id={teacheridstyles.modal_content_teacherid}
          >
            <span className={teacheridstyles.img}>
            {message !== "failed to create the users from CSV file" && <i
              style={{ color: "orange", fontSize: "50px" }}
              className="fa-regular fa-circle-check"
            ></i>}
            {message=== "failed to create the users from CSV file" && <i className="fa fa-times-circle" style={{fontSize:"48px",color:"red"}}></i>}
            </span>

            <h5 className={teacheridstyles.head}>
              {message}
            </h5>

            <p className={teacheridstyles.para}> {shareFile}</p>
            <div className={teacheridstyles.modal_buttons_teacherid}>
              <button
                className={teacheridstyles.ok_btn}
                id={teacheridstyles.ok_btn_1_teacherid}
                onClick={handleUploadModalOk}
              >
                OK
              </button>
            </div>
          </div>
        </div> */}
        <div
          className={teacheridstyles.flex_container_teacherid}
          id={teacheridstyles.modal_teacherid}
          style={showModalUpload ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={teacheridstyles.main_content_teacherid}
            id={teacheridstyles.modal_content_teacherid}
          >
            {/* <span className={teacheridstyles.img}>
              {uploadCSV?.data.success !== false && <i
                style={{ color: "orange", fontSize: "50px" }}
                className="fa-regular fa-circle-check"
              ></i>}
              {uploadCSV?.data.success === false && <i className="fa fa-times-circle" style={{ fontSize: "48px", color: "red" }}></i>}
            </span> */}

            <h5 className={teacheridstyles.head}>
              {message !== 'Failed' ? message : "An error occurred during file upload."}
            </h5>

            {message !== 'Failed' ? <div className="row" style={{ textAlign: 'center' }}>

              <button
                className={`${teacheridstyles.ok_btn} mr-2`}
                style={{ marginRight: '50%', backgroundColor: '#69ca69', border: 'none' }}
                id={teacheridstyles.ok_btn_1_teacherid}
                onClick={downloadSucessCSV}
              >
                sucess csv
              </button>

              <button
                className={teacheridstyles.ok_btn}
                style={{ backgroundColor: '#FF0000', border: 'none' }}
                id={teacheridstyles.ok_btn_1_teacherid}
                onClick={downloadFailureCSV}
              >
                failure csv
              </button>


            </div> : <div></div>}

            <div className={teacheridstyles.modal_buttons_teacherid}>
              <button
                className={teacheridstyles.ok_btn}
                id={teacheridstyles.ok_btn_1_teacherid}
                onClick={handleUploadModalOk}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {/* <div
          className={teacheridstyles.flex_container_teacherid}
          id={teacheridstyles.modal_teacherid}
          style={showModalCreate ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={teacheridstyles.main_content_teacherid}
            id={teacheridstyles.modal_content_teacherid}
          >
            <span className={teacheridstyles.img}>
              <img src={tickImage} alt="red checkmark" />
            </span>

            <h5 className={teacheridstyles.head}>
              You have successfully added Teacher
            </h5>

            <p className={teacheridstyles.para}>
              {shareFirstName} {shareLastName}
            </p>
            <div className={teacheridstyles.modal_buttons_teacherid}>
              <button
                className={teacheridstyles.ok_btn}
                id={teacheridstyles.ok_btn_1_teacherid}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/Add%20Academic%20Staff");
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div> */}
        {/* pagination container - Start */}
        <div className={teacheridstyles.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={teacheridstyles.rows}>
              <label for="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
        {/* change password modal starts  */}
        <div
          className={changepasswordstyle.flex_container_teacherid}
          id={changepasswordstyle.modal_teacherid}
          style={
            showModalChangePassword ? { display: "flex" } : { display: "none" }
          }
        >
          <form className={changepasswordstyle.setPassword_login_form}>
            <div className={changepasswordstyle.login_form_title}>
              <h5>Change Password</h5>
              <span>
                <i
                  className="fa-solid fa-xmark cursorPointer"
                  onClick={handleUpdatedPassword}
                ></i>
              </span>
            </div>
            <div className={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>New Password</legend>
                <input
                  type={passwordShown ? "text" : "password"}
                  name="userPassword"
                  id={changepasswordstyle.password}
                  value={loginPassword}
                  onChange={PassOnChange}
                />
                <i
                  className={`bi ${passwordShown ? "bi-eye" : "bi-eye-slash"} ${changepasswordstyle.bi_eye_slash
                    }`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleLoginPasswordVisibility("show");
                  }}
                  onMouseLeave={() => {
                    toggleLoginPasswordVisibility("dontShow");
                  }}
                ></i>
              </fieldset>

            </div>
            <div className={changepasswordstyle.password_container}>
              <fieldset className={changepasswordstyle.password_fieldset}>
                <legend>Confirm Password</legend>
                <Tooltip title="Password length must be greater than or equal to 8,
                          must contain atleast one numeric, alphanumeric,
                          upper-case and lower-case alphabet"
                  enterTouchDelay={1000000}
                  open={isTooltipOpen}
                  onOpen={handleTooltipOpen}
                  onClose={handleTooltipClose}

                >
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    id={changepasswordstyle.password}
                    value={confirmLoginPassword}
                    onChange={(e) => setConfirmLoginPassword(e.target.value)}
                  /></Tooltip>
                <i
                  className={`bi ${confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                    } ${changepasswordstyle.bi_eye_slash}`}
                  id={changepasswordstyle.togglePassword}
                  onMouseEnter={() => {
                    toggleconfirmLoginPasswordVisibility("Confirmshow");
                  }}
                  onMouseLeave={() => {
                    toggleconfirmLoginPasswordVisibility("ConfirmdontShow");
                  }}
                ></i>
              </fieldset>
              <span
                className={changepasswordstyle.error_span}
                style={isPassEmpty ? { display: "inline" } : { display: "none" }}
              >
                Password cannot be empty
              </span>
              <span
                className={changepasswordstyle.error_span}
                style={
                  passwordMatch ? { display: "none" } : { display: "inline" }
                }
              >
                Passwords do not match
              </span>

              <span
                className={changepasswordstyle.error_span}
                style={regexPass ? { display: "none" } : { display: "inline" }}
              >

              </span>
            </div>
            <div className={changepasswordstyle.login_container}>
              <button
                className={changepasswordstyle.sp_login_btn}
                onClick={handleSubmit}
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
        {/* change password modal ends  */}
        {/* Edit modal starts  */}
        <div
          className={studentedit.flex_container_teacherid}
          id={studentedit.modal_teacherid}
          style={showModalEdit ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={studentedit.main_content_teacherid}
            id={studentedit.modal_content_teacherid}
          >
            <div className={studentedit.EditHeadContainer}>
              <h5 className={studentedit.head}>Academic Staff Details</h5>
              <i onClick={handleEditModalcross} className="fa-solid fa-xmark"></i>
            </div>
            <div className={studentedit.EditBox}>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> First Name</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.firstName}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Last Name</label>
                <label className={studentedit.Input}>
                  {teacherIDData.lastName}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Primary . </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.mobile}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Primary Email</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.email}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Date of Birth</label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.dob}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}> Gender </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.gender}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Date of Joining
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.joinDate}{" "}
                </label>
              </div>
              <div className={studentedit.Edithead}>
                <label className={studentedit.Input_head}>
                  {" "}
                  Previous work experience{" "}
                </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.previousWorkExp}{" "}
                </label>
              </div>
              {teacherIDData.academicStaffProfile === "COORDINATOR" && (
                <div className={studentedit.Edithead}>
                  <label className={studentedit.Input_head}>
                    {" "}
                    Coordinator Type{" "}
                  </label>
                  <label className={studentedit.Input}>
                    {" "}
                    {teacherIDData.coordinatorType}
                  </label>
                </div>
              )}
              {teacherIDData.academicStaffProfile !== "COORDINATOR" && (
                <div className={studentedit.Edithead}>
                  <label className={studentedit.Input_head}>
                    {" "}
                    Designation{" "}
                  </label>
                  <label className={studentedit.Input}>
                    {" "}
                    {teacherIDData.designation}
                  </label>
                </div>
              )}

              <div className={studentedit.Edithead} id={studentedit.Addressbox}>
                <label className={studentedit.Input_head}> Address </label>
                <label className={studentedit.Input}>
                  {" "}
                  {teacherIDData.address}{" "}
                </label>
              </div>
            </div>

            <div className={studentedit.modal_buttons_teacherid}>
              <button
                className={studentedit.Edit_btn}
                id={studentedit.Edit_btn_1_teacherid}
                onClick={handleEditModalEdit}
              >
                <i className="fa-solid fa-pen"></i> Edit
              </button>
            </div>
          </div>
        </div>
        {/* Edit modal ends  */}
        {/* share modal starts  */}
        <div
          className={sharestyle.flex_container_teacherid}
          id={sharestyle.modal_teacherid}
          style={showModalShare ? { display: "flex" } : { display: "none" }}
        >
          <div
            className={sharestyle.main_content_teacherid}
            id={sharestyle.modal_content_teacherid}
          >
            <span className={sharestyle.img}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "70px", marginLeft: "10px", color: "#ffa500" }}
              />{" "}
            </span>

            <h5 className={sharestyle.head}>Successfully shared User Id</h5>

            <p className={sharestyle.para}> "{teacherIDData.userName}" </p>
            <div className={sharestyle.modal_buttons_teacherid}>
              <button
                className={sharestyle.ok_btn}
                id={sharestyle.ok_btn_1_teacherid}
                onClick={handleShareModalOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
        {/* share modal ends */}
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={userType}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem?.name}
        />

        <Success_OK_Modal
          userType={"Teacher"}
          userName_Modal={
            TeacherInputs.firstName + " " + TeacherInputs.lastName
          }
          open={successModal_Teacher}
          // close={closeSuccessModal}
          // addedItem={}
          handleOk={closeSuccessModal}
        />
        <Success_OK_Modal
          userType={"Coordinator"}
          userName_Modal={
            TeacherInputs.firstName + " " + TeacherInputs.lastName
          }
          open={successModal_Coordinator}
          // close={closeSuccessModal}
          // addedItem={}
          handleOk={closeSuccessModal}
        />
        <Success_OK_Modal
          userType={"Principal"}
          userName_Modal={
            TeacherInputs.firstName + " " + TeacherInputs.lastName
          }
          open={successModal_Principal}
          // close={closeSuccessModal}
          // addedItem={}
          handleOk={closeSuccessModal}
        />
      </article>
    </>
  );
};
export default TeacherId;