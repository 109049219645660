import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PlayLessonOutlinedIcon from "@mui/icons-material/PlayLessonOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import NotStartedOutlinedIcon from "@mui/icons-material/NotStartedOutlined";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CategoryIcon from "@mui/icons-material/Category";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { makeStyles } from "@material-ui/core/styles";
import mindMap from "../../../../src/img/mind.png";
import videoIcn from "../../../../src/img/lms_video.png";
import workBook from "../../../../src/img/lms_book.png";
import presentation from "../../../../src/img/Presentation.png";
import handBurger_icn from "../../../../src/img/handBurger.png";

const Principal_SideMenu = ({
  props,
  menu,
  menuType,
  menuClickHandler,
  subMenuHandler,
  collapseId,
  startIndex,
  selectedUrl,
}) => {
  // console.log(menu);
  const [showIcon, setShowIcon] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: showIcon ? 80 : 250,
      backgroundColor: "#1D1A76",
      color: "white",
      height: "100%",
      // minHeight: "100%",
      minHeight: "140%",
      whiteSpace: "nowrap",
      overflow: "auto",
    },
    nested: {
      paddingLeft: theme.spacing(4),
      "&:hover": {
        color: "white",
        // backgroundColor: "#3833d5",
        // borderTopRightRadius: "150px",
        // borderBottomRightRadius: "150px",
      },
    },
    nestedSecondLevel: {
      // paddingLeft: theme.spacing(8),
      paddingLeft: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const trimStringHandler = (value) => {
    return value?.replace(/ /g, "");
  };
  const IconHandler = (type, index, url) => {
    const filter = trimStringHandler(type);
    return filter?.includes(trimStringHandler("textBook")) ||
      filter?.includes(trimStringHandler("Book")) ? (
      // <img
      //   src={workBook}
      //   alt="searchIcon"
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("mindMap")) ? (
      <img
        src={mindMap}
        alt="searchIcon"
        sx={{
          color:
            url.length !== 0
              ? selectedUrl === url
                ? "yellow"
                : "white"
              : startIndex === index
                ? "yellow"
                : "white",
        }}
      />
    ) : filter?.includes(trimStringHandler("video")) ||
      filter?.includes(trimStringHandler("mpeg")) ||
      filter?.includes(trimStringHandler("avi")) ||
      filter?.includes(trimStringHandler("mpg")) ||
      filter?.includes(trimStringHandler("webm")) ||
      filter?.includes(trimStringHandler("mp4")) ? (
      // <img
      //   src={videoIcn}
      //   alt="searchIcon"
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("Workbook")) ? (
      <CardMembershipOutlinedIcon
        sx={{
          color:
            url.length !== 0
              ? selectedUrl === url
                ? "yellow"
                : "white"
              : startIndex === index
                ? "yellow"
                : "white",
        }}
      />
    ) : filter?.includes(trimStringHandler("Presentation")) ||
      filter?.includes(trimStringHandler("xlsx")) ||
      filter?.includes(trimStringHandler("docx")) ||
      filter?.includes(trimStringHandler("doc")) ||
      filter?.includes(trimStringHandler("pdf")) ||
      filter?.includes(trimStringHandler("xls")) ||
      filter?.includes(trimStringHandler("ppt")) ||
      filter?.includes(trimStringHandler("pptx")) ? (
      // <img
      //   src={presentation}
      //   alt="searchIcon"
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("lessonPlan")) ? (
      <PlayLessonOutlinedIcon
        sx={{
          color:
            url.length !== 0
              ? selectedUrl === url
                ? "yellow"
                : "white"
              : startIndex === index
                ? "yellow"
                : "white",
        }}
      />
    ) : filter?.includes(trimStringHandler("jpg")) ||
      filter?.includes(trimStringHandler("png")) ||
      filter?.includes(trimStringHandler("jpeg")) ||
      filter?.includes(trimStringHandler("svg")) ||
      filter?.includes(trimStringHandler("bmp")) ||
      filter?.includes(trimStringHandler("tif")) ||
      filter?.includes(trimStringHandler("tiff")) ||
      filter?.includes(trimStringHandler("raw")) ||
      filter?.includes(trimStringHandler("image")) ? (
      // <ImageIcon
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("pdf")) ||
      filter?.includes(trimStringHandler(".pdf")) ? (
      // <PictureAsPdfIcon
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("doc")) ||
      filter?.includes(trimStringHandler("docx")) ? (
      // <DocumentScannerIcon
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("1")) ? (
      <CategoryIcon
        sx={{
          color:
            url.length !== 0
              ? selectedUrl === url
                ? "yellow"
                : "white"
              : startIndex === index
                ? "yellow"
                : "white",
        }}
      />
    ) : filter?.includes(trimStringHandler("xlsx")) ? (
      <CategoryIcon
        sx={{
          color:
            url.length !== 0
              ? selectedUrl === url
                ? "yellow"
                : "white"
              : startIndex === index
                ? "yellow"
                : "white",
        }}
      />
    ) : filter?.includes(trimStringHandler("zip")) ? (
      // <FolderZipIcon
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : filter?.includes(trimStringHandler("audio")) ||
      filter?.includes(trimStringHandler("wav")) ||
      filter?.includes(trimStringHandler("wma")) ||
      filter?.includes(trimStringHandler("aac")) ||
      filter?.includes(trimStringHandler("ogg")) ||
      filter?.includes(trimStringHandler("mp3")) ? (
      // <AudioFileIcon
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    ) : (
      // <InsertDriveFileIcon
      //   sx={{
      //     color:
      //       url.length !== 0
      //         ? selectedUrl === url
      //           ? "yellow"
      //           : "white"
      //         : startIndex === index
      //         ? "yellow"
      //         : "white",
      //   }}
      // />
      ""
    );
  };
  const nameHandler = (name) => {
    const data = name.split("/")?.[name.split("/").length - 1]?.split(".");
    // const first = data[0].split("_");
    const first = data;
    // return `${first[0]}.${data?.[data.length - 1]}`;
    var str = first[0];
    return `${str[0].toUpperCase()}${str.slice(1)}`;

  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{
            backgroundColor: "#061ca5",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "54px",
          }}
          onClick={() => setShowIcon(!showIcon)}
        >
          {/* <MenuOutlinedIcon /> */}
          <img src={handBurger_icn} width="33px" height="30px" />
        </ListSubheader>
      }
      className={classes.root}
    >
      {console.log(menuType, "abcdef")}
      {menuType === "student"
        ? menu?.map((item, index) => {
          return (
            <div key={item?.documentCategoryId}>
              <ListItem
                button
                key={item?.documentCategoryId}
                onClick={() =>
                  menuClickHandler(item?.documentCategoryId, item, index)
                }
              >
                <ListItemIcon>
                  {IconHandler(item?.documentType, index, "")}
                </ListItemIcon>
                {!showIcon && (
                  <ListItemText
                    // primary={item.documentType}
                    // sx={{ color: startIndex === index ? "yellow" : "white" }}
                    key={index}
                    primary={
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: startIndex === index ? "#ffcf09" : "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.documentType}
                      </Typography>
                    }
                  />
                )}
              </ListItem>

              {item?.studentRevisionContent?.map((sub) => {
                return (
                  <Collapse
                    in={
                      startIndex === index
                        ? true
                        : collapseId === item.documentCategoryId
                    }
                    timeout="auto"
                    unmountOnExit
                    key={sub.id}
                    onClick={() =>
                      subMenuHandler(sub?.contentWithIndux, sub?.contentUrl)
                    }
                  >
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          {IconHandler(
                            sub?.contentWithIndux,
                            "",
                            sub?.contentUrl
                          )}
                        </ListItemIcon>
                        {!showIcon && (
                          <ListItemText
                            // primary={nameHandler(sub?.contentWithIndux)}
                            sx={{
                              // color:
                              //   selectedUrl === sub?.contentUrl
                              //     ? "#ffcf09"
                              //     : "white",
                              color: "white",
                            }}
                            primary={
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color: "white",
                                  textTransform: "capitalize",
                                }}
                              >
                                {nameHandler(sub?.contentWithIndux)}
                              </Typography>
                            }
                          />
                        )}
                      </ListItem>
                    </List>
                  </Collapse>
                );
              })}
            </div>
          );
        })
        : menu?.map((item, index) => {
          return (
            <div key={item?.documentTypeId}>
              <ListItem
                button
                key={item?.documentTypeId}
                onClick={() =>
                  menuClickHandler(item?.documentTypeId, item, index)
                }
              >
                <ListItemIcon>
                  {IconHandler(item?.documentType, index, "")}
                </ListItemIcon>
                {!showIcon && (
                  <ListItemText
                    // primary={item.documentType}
                    // sx={{ color: startIndex === index ? "yellow" : "white" }}
                    key={index}
                    primary={
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: startIndex === index ? "#ffcf09" : "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.documentType}
                      </Typography>
                    }
                  />
                )}
              </ListItem>

              {item?.uploadContents?.map((sub) => {
                return (
                  <Collapse
                    in={
                      startIndex === index
                        ? true
                        : collapseId === item.documentTypeId
                    }
                    timeout="auto"
                    unmountOnExit
                    key={sub.id}
                    onClick={() =>
                      subMenuHandler(sub?.contentWithIndux, sub?.contentUrl)
                    }
                  >
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          {IconHandler(
                            sub?.contentWithIndux,
                            "",
                            sub?.contentUrl
                          )}
                        </ListItemIcon>
                        {!showIcon && (
                          <ListItemText
                            // primary={nameHandler(sub?.contentWithIndux)}
                            sx={{
                              // color:
                              //   selectedUrl === sub?.contentUrl
                              //     ? "yellow"
                              //     : "white",
                              color: "white",
                            }}
                            key={index}
                            primary={
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color: "white",
                                  textTransform: "capitalize",
                                }}
                              >
                                {nameHandler(sub?.contentWithIndux)}
                              </Typography>
                            }
                          />
                        )}
                      </ListItem>
                    </List>
                  </Collapse>
                );
              })}
            </div>
          );
        })}
    </List>
  );
};
export default Principal_SideMenu;
