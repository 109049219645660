import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { MenuProps } from "./Data";
import { SearchOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
// import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
import ViewContentPage from "./ViewContentPage";
import TopBar from "../../components/common/TopBar";
import CommonSort from "../js/CommonSort"
import searchIcn from "../../../src/img/search.svg";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../js/TeachValidate";
import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import { fetchPlanDetails, fetchEnableAssignBtn, } from "../js/Cordinator/assignQuiz/api/fetchCalls"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: '190px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
}));

const Teach = (props) => {
  // console.log("teach called")
  const [showDetail, setShowDetail] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [showSubTopic, setShowSubTopic] = useState(true);
  const token = sessionStorage.getItem("token");
  const [modifiedDate, setModifiedDate] = useState({});
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [boardPageSize, setBoardPageSize] = useState(100);
  const [isError, setIsError] = useState(false);
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const [teacherId, setTeacherId] = useState("");
  const [gradeSection, setGradeSection] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const classes = useStyles();

  //Session DATA for API
  const schoolId = sessionStorage.getItem('schoolId')
  const branchId = sessionStorage.getItem('branchId')
  const gradeId = sessionStorage.getItem('gradeId')
  const sectionId = sessionStorage.getItem('sectionId')
  const subjectId = sessionStorage.getItem('subjectId')
  const subTopicId = sessionStorage.getItem('subTopicId')
  const academicYearId = sessionStorage.getItem('academicYearId')

  useEffect(() => {
    fetchTeacherData(userName, role);
    const teacherData = fetchTeacherData(userName, role);
    setTeacherDetail(teacherData);
    // GetLastModifiedAt(setModifiedDate);
  }, []);

  //auto populate filters start scripts
  const [isAutoPopulating, setIsAutoPopulating] = useState(false);
  useEffect(() => {
    if (isNOTNullOrUndefined(gradeSection) && gradeSection?.length) {
      startAutoPopulate();
    }
  }, [gradeSection]);

  const fetchSubjectForAutoPopulate = async (gradId, secId) => {
    const id = teacherId.id;
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${gradId}&sectionId=${secId}&menuName=TEACH`,
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${sessionStorage.getItem("planId")}&gradeId=${selectedGrade || sessionStorage.getItem("gradeId")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => console.log(err));
    // console.log(response?.data?.data)
    return response?.data?.data;
  };

  const startAutoPopulate = async () => {
    fetchPlanDetails()
    fetchEnableAssignBtn()
    setIsAutoPopulating(true);
    setSelectedGrade(gradeSection[0]?.id);
    setSelectedSection(gradeSection[0]?.sections[0].id);
    var subList = await fetchSubjectForAutoPopulate(
      gradeSection[0]?.id,
      gradeSection[0]?.sections[0]?.id
    );
    // console.log(subList, "27-6-24 required")
    // subList = subList?.filter(v => v.skilledSubject === true) || []
    // console.log(subList)
    setSubjectList(subList);
    setSelectedSubject(subList[0]?.subjectId);
    setShowSubTopic(subList[0]?.hideSubtopics == true ? false : true);
    // console.log(subList[0]?.hideSubtopics)
    // console.log(subList[0]?.hideSubtopics == true ? false : true)
    // alert("teach ");
    sessionStorage.setItem("gradeId", gradeSection[0]?.id);
    sessionStorage.setItem("sectionId", gradeSection[0]?.sections[0].id);
    sessionStorage.setItem("subjectId", subList[0]?.subjectId);

    setIsAutoPopulating(false);
    // setTimeout(() => {
    //   setIsAutoPopulating(false);
    // }, 1000);
  };

  const RenderLoader = () => {
    return (
      <div
        style={{
          // background: 'rgba(0,0,0,0.6)',
          height: "100%",
          width: "100%",
          zindex: 99,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  //Auto populate ends


  useEffect(() => {
    // console.log("teach.js page----------")
    const fethPlanData = async () => {
      const branchId = sessionStorage.getItem("branchId")
      if (branchId) {
        const planDatas = await fetchPlanDetails();
        setPlanData(planDatas);
      }
    };

    fethPlanData();
  }, []);

  useEffect(() => {
    const fetchTheData = async () => {
      const list = await fetchEnableAssignBtn(selectedGrade);
      setApiData(list);
    };

    fetchTheData();
  }, [planData, selectedGrade, selectedSubject]);

  const subjectID = selectedSubject;
  const idArray = apiData.map((item) => item.subjectId)
  const activeStatusArray = apiData.map((item) => item.active);
  const foundIndex = idArray.indexOf(subjectID);


  let isActive = false;
  if (foundIndex !== -1) {
    isActive = activeStatusArray[foundIndex];
    sessionStorage.setItem("isActiveBoo", isActive)
  }

  // Student, teacher,only have access

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("branchId", res.data.data.teacher.branch);

    var result = res?.data?.data?.teacher || {};
    sessionStorage.setItem("Basic_info", JSON.stringify(result))
    sessionStorage.setItem("teacherId", res?.data?.data?.teacher?.id)
    sessionStorage.setItem("sectionId", res.data.data.teacher.sectionId);
    setTeacherId(res.data.data.teacher);
  };
  async function GetLastModifiedAt(setModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        setModifiedDate(json.data.data)
        // console.log(JSON.stringify(json), "date")
      })
      .catch(err => console.log(err))
  }

  // grade - section API

  const fetchGrade = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    const id = teacherId.id;

    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=TEACH`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setGradeSection(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  // subject - subtopic API

  const fetchSubject = async (selectedSection) => {
    const id = teacherId.id;
    // const id = '4028928886f00aec0186fda2e0a70004'

    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${selectedGrade}${selectedSection ? `&sectionId=${selectedSection}` : ""
        }&menuName=TEACH`,
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${sessionStorage.getItem("planId")}&gradeId=${selectedGrade}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response?.data?.data, "skilled subject")
        // response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || []
        setSubjectList(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  // Academic year api

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data.data || [];
  };

  const [isfetchingList, setIsFetchingList] = useState(false);
  const fetchChapterData = async (subjectId, gradeId) => {
    // console.log("fetchChapterData", selectedSubTopic)
    setUpdatedData([]);
    const bordId = sessionStorage.getItem("boardId");
    const hideSubtopicsValue = subjectList.find(item => item.subjectId === subjectId)?.hideSubtopics;
    // let subTopicUrl = !hideSubtopicsValue ? isNOTNullOrUndefined(selectedSubTopic)
    // && `&subTopicId=${selectedSubTopic}`: ""
    // console.log(subTopicUrl," <<<< subTopicUrl ");
    // console.log("hideSubtopicsValue >>>> ",hideSubtopicsValue);
    let subTopicUrl = '';
    if (!hideSubtopicsValue) {
      subTopicUrl = `&subTopicId=${selectedSubTopic}`;
    }
    // let subTopicUrl = !false ? isNOTNullOrUndefined(selectedSubTopic)
    // && `&subTopicId=${selectedSubTopic}`: ""
    // console.log("subtopic url >>>> ", subTopicUrl);
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/master/chapters/all-chapters?subjectId=${subjectId}&gradeId=${gradeId}&boardId=${bordId}${subTopicUrl}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {
        if (response.status === 200) {
          // console.log(response.data.data);
          response.data.data = await CommonSort(response.data.data || [], "chapter", false);
          setUpdatedData(response?.data?.data);
          setIsFetchingList(false);

          // setFilterValue(response?.data?.data)
        } else {
          setUpdatedData([]);
          setIsFetchingList(false);
        }
      });
  };
  const fetchChapterBySearch = async (value) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageSize=${pageSize}&sortOrder=true&search=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data?.data || [];
  };

  const fetchBoardData = async (pageSize) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/page?pageSize=1000&sortOrder=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.data?.data?.totalElements > pageSize) {
      setBoardPageSize(response?.data?.data?.totalElements);
    }
    return response?.data?.data?.data || [];
  };

  const fetchDataUsingChapterId = async (chapterId) => {
    // console.log("api/content/teacher-chapter/page?pageSize");
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/page?pageSize=1000&sortOrder=true&reviewStatus=APPROVED&chapterId=${chapterId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&subTopicId=${subTopicId}&academicYearId=${academicYearId}&includeStudentFile=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.data || [];
  };

  const fetchContentPlanDetails = async () => {
    const planId = sessionStorage.getItem("planId");
    const bordId = sessionStorage.getItem("boardId");
    if (planId && bordId) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/template-screen?planId=${planId}&boardId=${bordId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data?.data || [];
    }
  };

  useEffect(async () => {
    if (selectedChapterId) {
      const menuData = await fetchDataUsingChapterId(selectedChapterId);
      const contentPlanDetails = await fetchContentPlanDetails();
      // console.log("planDetails >>> ", contentPlanDetails)

      // Filter the planGrades array to find the desired grade
      const grade = contentPlanDetails?.planGrades.find(grade => grade.gradeId === selectedGrade);

      // If grade is found, find the planTemplate object for the specified subjectId
      const planTemplate = grade ? grade.planTemplates.find(template => template.subjectId === selectedSubject) : null;
      // console.log("plantemplate >>> ", planTemplate);
      // Extract wsDownload and wbDownload values if planTemplate is found
      const wsDownload = planTemplate ? planTemplate.wsDownload : null;
      const wbDownload = planTemplate ? planTemplate.wbDownload : null;
      localStorage.setItem("wsDownload", wsDownload);
      localStorage.setItem("wbDownload", wbDownload);


      // console.log("wsDownload,wbDownload >>> ", wsDownload, wbDownload, menuData)

      if (menuData?.length !== 0) {
        setShowDetail(menuData[0]?.documentTypes);
        setShowContentModal(true);
      } else {
        setShowDetail([]);
      }
    }

  }, [selectedChapterId]);

  useEffect(async () => {
    if (
      isNOTNullOrUndefined(selectedSubject) &&
      isNOTNullOrUndefined(selectedGrade) &&
      isNOTNullOrUndefined(selectedSection)
    ) {
      const subtopicsInSelectedSubject = subjectList?.find(
        (i) => i.subjectId === selectedSubject
      )?.subTopics;
      if (
        isNOTNullOrUndefined(subtopicsInSelectedSubject) &&
        subtopicsInSelectedSubject.length > 0
      ) {
        if (isNOTNullOrUndefined(selectedSubTopic)) {
          setIsFetchingList(true);
          await fetchChapterData(selectedSubject, selectedGrade);
        }
      } else {
        setIsFetchingList(true);
        await fetchChapterData(selectedSubject, selectedGrade);
      }

      const contentPlanDetails = await fetchContentPlanDetails();
      // console.log("planDetails >>> ", contentPlanDetails)
      // Filter the planGrades array to find the desired grade
      const grade = contentPlanDetails?.planGrades.find(grade => grade.gradeId === selectedGrade);

      // If grade is found, find the planTemplate object for the specified subjectId
      const planTemplate = grade ? grade.planTemplates.find(template => template.subjectId === selectedSubject) : null;

      // Extract wsDownload and wbDownload values if planTemplate is found
      const wsDownload = planTemplate ? planTemplate.wsDownload : null;
      const wbDownload = planTemplate ? planTemplate.wbDownload : null;
      sessionStorage.setItem("wsDownload", wsDownload);
      sessionStorage.setItem("wbDownload", wbDownload);
      // console.log("wsDownload,wbDownload >>> ", wsDownload, wbDownload)
    }
  }, [
    selectedSubject,
    selectedSubTopic,
    selectedGrade,
    selectedSection,
    selectedBoard,
  ]);

  useEffect(async () => {
    const grade = await fetchGrade();
    const board = await fetchBoardData(boardPageSize);
    const section = await fetchGrade();
    const academic = await fetchAcademicYearData();
    setAcademicYearList(academic);
    setSectionList(section);
    setBoardList(board);
    setGradeList(grade);
    // Set the initial value of the filter
    const storedAcademicYearId = sessionStorage.getItem("academicYearId");
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId);
    } else if (academic.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem("academicYearId", academic[0].id);
    }
    // setSubjectList(subject);
  }, [teacherId.id]);

  useEffect(() => {
    const subjectObj = subjectList.filter(
      (i) => i.subjectId === selectedSubject
    )[0];
    if (subjectObj?.subTopics) {
      setSelectedSubTopic(subjectObj?.subTopics[0]?.id);
      sessionStorage.setItem("subTopicId", subjectObj?.subTopics[0]?.id);
    } else {
      setSelectedSubTopic("");
    }
  }, [selectedSubject]);

  const handleChangeGrade = (e) => {
    setSelectedGrade(e.target.value);
    sessionStorage.setItem("gradeId", e.target.value);
    setSelectedSubject("");
    setSelectedSection("");
    setSelectedSubTopic("");
    sessionStorage.removeItem("sectionId");
    sessionStorage.removeItem("subjectId");
    sessionStorage.removeItem("subTopicId");
    fetchSubject(selectedSection);
  };

  const handleChangeSection = (event) => {
    setSelectedSection(event.target.value);
    setSelectedSubject("");
    fetchSubject(event.target.value);
    sessionStorage.setItem("sectionId", event.target.value);
  };

  const handleChangeSubject = (event) => {
    // console.log(event.target.value, subjectList)
    const selectedSubject = subjectList.find(val => val.subjectId === String(event.target.value));
    // console.log(selectedSubject)
    // console.log(selectedSubject?.hideSubtopics, selectedSubject.subjectId)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      // console.log("false")
      // alert("pac false");
    } else {
      // console.log("true")
      setShowSubTopic(true);
      // alert("pac true");
    }
    setSelectedSubject(event.target.value);
    sessionStorage.setItem("subjectId", event.target.value);
  };

  const handleChangeSubTopic = (event) => {
    setSelectedSubTopic(event.target.value);
    sessionStorage.setItem("subTopicId", event.target.value);
  };

  const handleChangeAcademicYear = (event) => {
    setSelectedAcademicYear(event.target.value);
    sessionStorage.setItem("academicYearId", event.target.value);
  };
  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  useEffect(() => {
    if (isNOTNullOrUndefined(selectedGrade)) {
      const filtered = gradeSection?.find(
        (i) => i.id === selectedGrade
      )?.sections;
      setSectionFilteredList(filtered);
    }
  }, [selectedGrade]);
  const [query, setQuery] = useState("");

  // if (isAutoPopulating) {
  //   return <RenderLoader />;
  // }

  //-------| Truncate the Chapter Name |-------//
  const maxLength = 20;

  return (
    <>
      {!showContentModal ? (
        <div style={{ width: "100%" }}>
          <TopBar
            title={"Home"}
            nav1={"TeacherDashboard"}
            subSubTitle={"Teach"}
            modifiedDate={modifiedDate}
          />
          <div
            style={{
              marginTop: "10px",
              paddingRight: "33px",
              position: "absolute",
              right: "15px",
              left: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                <Box sx={{ minWidth: 120 }}>
                  {/* <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#FD8C00 !important",
                        fontSize: "14px !important",
                        opacity: 0.9,
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "50px",
                        width: "148px",
                        height: "32px",

                      },

                    }}
                  >
                    {!selectedGrade && (
                      <InputLabel id="az-teacher-gardes-select-label" shrink={false}>
                        Grades
                      </InputLabel>
                    )}

                    <Select
                      labelId="az-teacher-gardes-select-label"
                      id="az-teacher-gardes-select"
                      className={selectedGrade ? dashboardContentStyles.az_select_menu_selected : dashboardContentStyles.az_select_menu}
                      style={{
                        borderRadius: "150px",
                        padding: "4px",
                        color: selectedGrade ? "orange" : "black"
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: "200px",
                        },
                      }}
                      sx={{
                        "& .css-10hburv-MuiTypography-root": {
                          font: "normal normal normal 15px/15px Poppins !important",
                        },
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF",
                          // border: "2px solid orange"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000",
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                          // border: "2px solid orange"
                        },
                      }}
                      size={"small"}
                      value={selectedGrade}
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      onChange={handleChangeGrade}

                    >

                      {gradeSection?.map((item) => {
                        return (
                          <MenuItem
                            value={item?.id}
                            key={item.id}
                            // className={dashboardContentStyles.menu_item}
                            id={`az-teacher-gradedropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: 'orange',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#fcedc2',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={item.grade} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}

                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        fontSize: '13px',
                        color: '#e7ab3c',
                        top: '-10px',
                        '&.MuiInputLabel-shrink': {
                          top: '0',
                        },
                        '&.Mui-focused': {
                          color: '#e7ab3c'
                        }
                      }}
                      className='dropDownLabel'
                      id="az-teacher-gardes-select-label">Grade</InputLabel>

                    <Select
                      labelId="az-teacher-gardes-select-label"
                      id="az-teacher-gardes-select"
                      input={<OutlinedInput label='Grade' />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            width: '150px',
                            marginTop: '2px',
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: '0px'
                          },
                        },
                      }}
                      value={selectedGrade}
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      onChange={handleChangeGrade}
                      sx={{
                        width: '150px',
                        height: '30px',
                        padding: '10px',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#e7ab3c',
                        }
                      }}
                      className='dropdownSelect'
                    >

                      {gradeSection?.map((item) => {
                        return (
                          <MenuItem
                            value={item?.id}
                            key={item.id}
                            // className={dashboardContentStyles.menu_item}
                            id={`az-teacher-gradedropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
                            sx={{
                              fontSize: '10px',
                              backgroundColor: 'white',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#e7ab3c',
                                '&:hover': {
                                  backgroundColor: '#f4d788',
                                },
                              },
                            }}
                          >
                            <ListItemText primary={item.grade} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  {/* <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#FD8C00 !important",
                        fontSize: "14px !important",
                        opacity: 0.9,
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "50px",
                        width: "148px",
                        height: "32px",
                      },
                    }}
                  >
                    {!selectedSection && (
                      <InputLabel id="az-teacher-section-select-label" shrink={false}>
                        Section
                      </InputLabel>
                    )}
                    <Select
                      labelId="az-teacher-section-select-label"
                      id="az-teacher-section-select"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: "200px",
                        },
                      }}
                      sx={{
                        "& .css-10hburv-MuiTypography-root": {
                          font: "normal normal normal 15px/15px Poppins !important",
                        },
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                      className={selectedSection ? dashboardContentStyles.az_select_menu_selected : dashboardContentStyles.az_select_menu}
                      style={{
                        borderRadius: "150px",
                        padding: "4px",
                        color: selectedSection ? "#ffa500" : "#000000"
                      }}
                      size={"small"}
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      value={selectedSection}
                      // onChange={(e) => setSelectedSection(e.target.value)}
                      onChange={handleChangeSection}
                    >
                      {sectionFilteredList?.map((sec) => {
                        if (sec.hasOwnProperty("section")) {
                          return (
                            <MenuItem
                              value={sec.id}
                              key={sec.id}
                              className={dashboardContentStyles.menu_item}
                              id={`az-teacher-sectiondropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: 'orange',
                                  color: "#FFFFFF"
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: '#fcedc2',
                                  color: "#000000"
                                },
                                "&:not(.Mui-selected):hover": {
                                  backgroundColor: '#fcedc2',
                                  color: '#000000',
                                },
                              }}
                            >
                              <ListItemText primary={sec.section} />
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl> */}

                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        fontSize: '13px',
                        color: '#e7ab3c',
                        top: '-10px',
                        '&.MuiInputLabel-shrink': {
                          top: '0',
                        },
                        '&.Mui-focused': {
                          color: '#e7ab3c'
                        }
                      }}
                      className='dropDownLabel'
                      id="az-teacher-section-select-label" >Section</InputLabel>
                    <Select
                      labelId="az-teacher-section-select-label"
                      id="az-teacher-section-select"
                      input={<OutlinedInput label='Section' />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            width: '150px',
                            marginTop: '2px',
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: '0px'
                          },
                        },
                      }}
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      value={selectedSection}
                      // onChange={(e) => setSelectedSection(e.target.value)}
                      onChange={handleChangeSection}
                      sx={{
                        width: '150px',
                        height: '30px',
                        padding: '10px',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#e7ab3c',
                        }
                      }}
                      className='dropdownSelect'
                    >
                      {sectionFilteredList?.map((sec) => {
                        if (sec.hasOwnProperty("section")) {
                          return (
                            <MenuItem
                              value={sec.id}
                              key={sec.id}
                              className={dashboardContentStyles.menu_item}
                              id={`az-teacher-sectiondropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
                              sx={{
                                fontSize: '10px',
                                backgroundColor: 'white',
                                '&:hover': {
                                  backgroundColor: '#f4d788',
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#e7ab3c',
                                  '&:hover': {
                                    backgroundColor: '#f4d788',
                                  },
                                },
                              }}
                            >
                              <ListItemText primary={sec.section} />
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ minWidth: 120 }}>
                  {/* <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#FD8C00 !important",
                        fontSize: "14px !important",
                        opacity: 0.9,
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "50px",
                        width: "148px",
                        height: "32px",
                      },
                    }}
                  >
                    {!selectedSubject && (
                      <InputLabel id="az-teacher-subjects-select-label" shrink={false}>
                        Subjects
                      </InputLabel>
                    )}

                    <Select
                      labelId="az-teacher-subjects-select-label"
                      id="az-teacher-subjects-select"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: "200px",
                        },
                      }}
                      sx={{
                        "& .css-10hburv-MuiTypography-root": {
                          font: "normal normal normal 15px/15px Poppins !important",
                        },
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                      className={selectedSubject ? dashboardContentStyles.az_select_menu_selected : dashboardContentStyles.az_select_menu}
                      style={{
                        borderRadius: "150px",
                        padding: "4px",
                        color: selectedSubject ? "#ffa500" : "#000000"
                      }}
                      size={"small"}
                      value={
                        isNOTNullOrUndefined(selectedSubject)
                          ? selectedSubject
                          : ""
                      }
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      onChange={handleChangeSubject}
                    >
                      {subjectList?.map((item) => {
                        return (
                          <MenuItem
                            value={item.subjectId}
                            key={item.subjectId}
                            className={dashboardContentStyles.menu_item}
                            id={`az-teacher-subjectdropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: 'orange',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#fcedc2',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={item.subject} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}

                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        fontSize: '13px',
                        color: '#e7ab3c',
                        top: '-10px',
                        '&.MuiInputLabel-shrink': {
                          top: '0',
                        },
                        '&.Mui-focused': {
                          color: '#e7ab3c'
                        }
                      }}
                      className='dropDownLabel'
                      id="az-teacher-subjects-select-label">Subject</InputLabel>
                    <Select
                      labelId="az-teacher-subjects-select-label"
                      id="az-teacher-subjects-select"
                      input={<OutlinedInput label='Subject' />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            width: '150px',
                            marginTop: '2px',
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: '0px'
                          },
                        },
                      }}
                      value={
                        isNOTNullOrUndefined(selectedSubject)
                          ? selectedSubject
                          : ""
                      }
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      onChange={handleChangeSubject}
                      sx={{
                        width: '150px',
                        height: '30px',
                        padding: '10px',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#e7ab3c',
                        }
                      }}
                      className='dropdownSelect'
                    >
                      {subjectList?.map((item) => {
                        return (
                          <MenuItem
                            value={item.subjectId}
                            key={item.subjectId}
                            id={`az-teacher-subjectdropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                            sx={{
                              fontSize: '10px',
                              backgroundColor: 'white',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#e7ab3c',
                                '&:hover': {
                                  backgroundColor: '#f4d788',
                                },
                              },
                            }}
                          >
                            <ListItemText primary={item.subject} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                {
                  showSubTopic &&
                  <Box sx={{ minWidth: 120 }}>
                    {/* <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#FD8C00 !important",
                          fontSize: "14px !important",
                          opacity: 0.9,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50px",
                          width: "148px",
                          height: "32px",
                        },

                      }}
                    >
                        {!selectedSubTopic && <InputLabel id="az-teacher-subtopic-select-label" shrink={false}>
                          SubTopic
                        </InputLabel>}

                      <Select
                        labelId="az-teacher-subtopic-select-label"
                        id="az-teacher-subtopic-select"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        sx={{
                          "& .css-10hburv-MuiTypography-root": {
                            font: "normal normal normal 15px/15px Poppins !important",
                          },
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000",
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}
                        style={{
                          borderRadius: "150px",
                          padding: "4px",
                          color: "#ffa500"
                        }}
                        size={"small"}
                        value={
                          isNOTNullOrUndefined(selectedSubTopic)
                            ? selectedSubTopic
                            : ""
                        }
                        IconComponent={(props) => (
                          <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                        )}
                        onChange={handleChangeSubTopic}
                      >
                        {subjectList
                          ?.find((i) => i.subjectId === selectedSubject)
                          ?.subTopics?.map((sub) => {
                            if (sub.hasOwnProperty("subTopic")) {
                              return (
                                <MenuItem
                                  // value={sub.id}
                                  // key={sub.id}
                                  // className={dashboardContentStyles.menu_item}
                                  // disabled={!sub.active}
                                  value={sub.id}
                                  key={sub.id}
                                  className={dashboardContentStyles.menu_item}
                                  id={`az-teacher-subtopicdropdown-${sub.subTopic.toLowerCase().replace(/ /g, "_")}`}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: 'orange',
                                      color: "#FFFFFF"
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: '#fcedc2',
                                      color: "#000000"
                                    },
                                    "&:not(.Mui-selected):hover": {
                                      backgroundColor: '#fcedc2',
                                      color: '#000000',
                                    },
                                  }}
                                >
                                  <ListItemText primary={sub.subTopic} />
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    </FormControl> */}

                    <FormControl fullWidth>
                      <InputLabel
                        sx={{
                          fontSize: '13px',
                          color: '#e7ab3c',
                          top: '-10px',
                          '&.MuiInputLabel-shrink': {
                            top: '0',
                          },
                          '&.Mui-focused': {
                            color: '#e7ab3c'
                          }
                        }}
                        className='dropDownLabel'
                        id="az-teacher-subtopic-select-label">SubTopic</InputLabel>
                      <Select
                        labelId="az-teacher-subtopic-select-label"
                        id="az-teacher-subtopic-select"
                        input={<OutlinedInput label='SubTopic' />}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              width: '150px',
                              marginTop: '2px',
                            },
                          },
                          MenuListProps: {
                            sx: {
                              padding: '0px'
                            },
                          },
                        }}
                        value={
                          isNOTNullOrUndefined(selectedSubTopic)
                            ? selectedSubTopic
                            : ""
                        }
                        IconComponent={(props) => (
                          <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                        )}
                        onChange={handleChangeSubTopic}
                        sx={{
                          width: '150px',
                          height: '30px',
                          padding: '10px',
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000',
                          },
                          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#e7ab3c',
                          }
                        }}
                        className='dropdownSelect'
                      >
                        {subjectList
                          ?.find((i) => i.subjectId === selectedSubject)
                          ?.subTopics?.map((sub) => {
                            if (sub.hasOwnProperty("subTopic")) {
                              return (
                                <MenuItem
                                  // value={sub.id}
                                  // key={sub.id}
                                  // className={dashboardContentStyles.menu_item}
                                  // disabled={!sub.active}
                                  value={sub.id}
                                  key={sub.id}
                                  className={dashboardContentStyles.menu_item}
                                  id={`az-teacher-subtopicdropdown-${sub.subTopic.toLowerCase().replace(/ /g, "_")}`}
                                  sx={{
                                    fontSize: '10px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                      backgroundColor: '#f4d788',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#e7ab3c',
                                      '&:hover': {
                                        backgroundColor: '#f4d788',
                                      },
                                    },
                                  }}
                                >
                                  <ListItemText primary={sub.subTopic} />
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                }

                <Box sx={{ minWidth: 120 }}>
                  {/* <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#FD8C00 !important",
                        fontSize: "14px !important",
                        opacity: 0.9,
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "50px",
                        width: "148px",
                        height: "32px",
                      },
                    }}
                  >
                    {!selectedAcademicYear && (
                      <InputLabel id="az-teacher-academic-select-label" shrink={false}>
                        Academic Year
                      </InputLabel>
                    )}

                    <Select
                      labelId="az-teacher-academic-select-label"
                      id="az-teacher-academic-select"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: "200px",
                        },
                      }}
                      sx={{
                        "& .css-10hburv-MuiTypography-root": {
                          font: "normal normal normal 15px/15px Poppins !important",
                        },
                      }}
                      style={{
                        borderRadius: "150px",
                        padding: "4px",
                      }}
                      size={"small"}
                      value={selectedAcademicYear}
                      disabled
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      onChange={handleChangeAcademicYear}
                    >
                      {academicYearList?.map((item) => {
                        return (
                          <MenuItem
                            value={item.id}
                            key={item.id}
                            className={dashboardContentStyles.menu_item}
                          >
                            <ListItemText primary={item.academicYear} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}

                  <FormControl fullWidth>
                    <InputLabel
                      sx={{
                        fontSize: '13px',
                        color: '#e7ab3c',
                        top: '-10px',
                        '&.MuiInputLabel-shrink': {
                          top: '0',
                        },
                        '&.Mui-focused': {
                          color: '#e7ab3c'
                        }
                      }}
                      className='dropDownLabel'
                      id="az-teacher-academic-select-label">Academic Year</InputLabel>

                    <Select
                      labelId="az-teacher-academic-select-label"
                      id="az-teacher-academic-select"
                      input={<OutlinedInput label='Academic Year' />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            width: '150px',
                            marginTop: '2px',
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: '0px'
                          },
                        },
                      }}
                      value={selectedAcademicYear}
                      disabled
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      onChange={handleChangeAcademicYear}
                      sx={{
                        width: '150px',
                        height: '30px',
                        padding: '10px',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#e7ab3c',
                        }
                      }}
                      className='dropdownSelect'
                    >
                      {academicYearList?.map((item) => {
                        return (
                          <MenuItem
                            value={item.id}
                            key={item.id}
                            className={dashboardContentStyles.menu_item}
                            sx={{
                              fontSize: '10px',
                              backgroundColor: 'white',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#e7ab3c',
                                '&:hover': {
                                  backgroundColor: '#f4d788',
                                },
                              },
                            }}
                          >
                            <ListItemText primary={item.academicYear} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <TextField
                type={"text"}
                id={`az-teacher-search`}
                placeholder={"Search anything...."}
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        alt="searchIcon"
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
                size={"small"}
                sx={{
                  background: !isError && "#fff",
                  borderRadius: "150px",
                  "& .MuiInputBase-root": {
                    width: "200px !important",
                    height: "30px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 15px/15px Poppins !important",
                  },
                }}
                error={isError}
                helperText={isError && "Please Enter min 3 character."}
              />
            </div>
            <Divider />
            {isfetchingList ? (
              <div>Fetching ....</div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: 700,
                  width: "100%",
                  padding: "25px 20px",
                  overflow: "auto",
                  scrollbarWidth: "thin"
                }}
              >
                {selectedGrade && selectedSection && selectedSubject ? (
                  <Box sx={{ flexGrow: 1 }}>

                    <div id="az-teacher-chapter-parent-card" className={dashboardContentStyles.cardContainer}>
                      {updatedData.length > 0 ? (
                        updatedData
                          ?.filter((itm) =>
                            itm.chapter.toLowerCase().includes(query)
                          )
                          .map((item, i) => (
                            <div
                              key={item.chapterId}
                              id={`az-teacher-chapter-card-${item.chapter.toLowerCase().replace(/ /g, "_")}`}
                              onClick={() => setSelectedChapterId(item?.id)}
                              className={dashboardContentStyles.card}
                            >
                              <img
                                src={item?.thumbnailPath}
                                width={110}
                                alt={""}
                                // style={{ maxHeight: "138px" }}
                                className={dashboardContentStyles.az_quiz_card_img} />
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  color: "#423d5c",
                                }}
                              >
                                {item?.chapter}
                              </div>
                            </div>
                          ))
                      ) : (
                        <div>No data for the selected filters</div>
                      )}
                    </div>
                  </Box>
                ) : (
                  <div style={{ fontSize: "16px" }} className={dashboardContentStyles.small_font}>Please Select Grade , Section and Subject</div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <ViewContentPage
          menuType={"teach"}
          studyData={showDetail}
          closeModal={() => {
            setShowContentModal(false);
            setSelectedChapterId("");
            setShowDetail([]);
          }}
          selectedChapterId={selectedChapterId}
          pageName={"Teach"}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          setSelectedAcademicYear={setSelectedAcademicYear}
          selectedAcademicYear={selectedAcademicYear}
          selectedSubject={selectedSubject}
          showSubTopic={showSubTopic}
        />
      )}
    </>
  );
};
export default Teach;
