import {
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useMutation } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { reset_quiz_creation } from "../../../../../actions/quizAction";
import { useNavigate, useSearchParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  header: {
    backgroundColor: "#D9DCFE ",
  },
  headerText: {
    color: "#001AF3",
  },
}));

const CreateQuizModal = ({
  open,
  handleClose,
  onSubmitSuccess,
  isUnitQuiz,
  boyChecked,
  selectedFromGrade,
  Typequiz,
}) => {
  const { selected_questions, quiz_type } = useSelector((state) => state.quiz);
  // console.log(selected_questions, quiz_type, "heeeeeelllllllo");
  document.getElementById("PrevCreate").style.visibility = "visible";


  const classes = useStyles();
  const [chapeter, setChapter] = useState([]);
  const [formData, setFormData] = useState("");
  const [calculations, setCalculations] = useState([]);
  const [searchParams] = useSearchParams();
  const isEditFlow = searchParams.get("isEdit");

  const data = [
    { id: 1, Taxonomy: "UnderStanding", Marks: 7, Percentage: "58%" },
    { id: 2, Taxonomy: "Synthesis", Marks: 1, Percentage: "8%" },
    { id: 3, Taxonomy: "Application", Marks: 4, Percentage: "33%" },
  ];

  const [quiz, setQuiz] = useState("");

  const local_data = JSON.parse(localStorage.getItem("quiz_data"));
  const chapters = local_data?.selected_chapters;
  const chapter = local_data?.selected_chapter;

  const navigate = useNavigate();
  const TOKEN = sessionStorage.getItem("token");

  const dispatch = useDispatch();
  let ViewQuizData = sessionStorage.getItem("questionIdView");
  if (ViewQuizData) ViewQuizData = JSON.parse(ViewQuizData);

  const storedData = localStorage.getItem("editedData");
  const editedQuizData = JSON.parse(storedData);

  const updateCreateQuiz = () => {
    let ViewQuizData = sessionStorage.getItem("questionIdView");
    if (ViewQuizData) ViewQuizData = JSON.parse(ViewQuizData);
    if (ViewQuizData) {
      updateQuiz(ViewQuizData);
    } else createQuiz();
  };

  const generatQuestionList = () => {
    const questionList = [];
    selected_questions.forEach((item, index) => {
      questionList.push({
        chapterId: isUnitQuiz
          ? local_data?.selected_chapter?.id
          : item.chapterId,
        questionId: item.id,
        insertionOrder: index + 1,
      });
      // const question = {
      //   chapterId: isUnitQuiz
      //     ? local_data?.selected_chapter?.id
      //     : item.chapterId,
      //   questionId: item.id,
      //   insertionOrder: index + 1,
      //   // active: true,
      //   // removed: false,
      // };
      // const presentid = ViewQuizData.quizQuestions.find(
      //   (q) => q.questionId === question.questionId
      // );
      // if (presentid) question.id = presentid.id;
      // questionList.push(question);
    });
    // ViewQuizData.quizQuestions.forEach((q) => {
    //   const present = questionList.find((que) => que.id === q.id);
    //   if (!present)
    //     questionList.push({
    //       chapterId: q.chapterId,
    //       id: q.id,
    //       questionId: q.questionId,
    //       active: q.active,
    //       removed: true,
    //     });
    // });
    return questionList;
  };

  const updateQuiz = async (ViewQuizData) => {
    const BASE_URL = process.env.REACT_APP_SWAGGER_URL;
    const getSubtopicID =
      local_data?.selected_sub_topic?.length > 0
        ? local_data.selected_sub_topic[0].id
        : local_data.selected_sub_topic?.id;
    await axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz`,
        {
          id: ViewQuizData.id,
          name: editedQuizData?.name || local_data?.quiz_name,
          boardId: local_data?.selected_board?.id,
          gradeId: local_data?.selected_grade?.id,
          subjectId: local_data?.selected_subject?.id,
          subTopicId: getSubtopicID,
          academicYearId: local_data?.selected_year?.id,
          quizTypeId: local_data?.quiz_type_id,
          totalQuestions: selected_questions?.length,
          totalMarks:
            parseInt(editedQuizData?.totalMarks) || parseInt(local_data?.score),
          withTiming: true,
          duration:
            parseInt(editedQuizData?.duration) ||
            parseInt(local_data?.duration),
          quizQuestions: generatQuestionList(),
          containsCaseStudy: selected_questions.some(
            (item) => item.questionTypeCode === "CASE"
          ),
          active: true,
          boy: editedQuizData?.boy,
          fromGradeId: editedQuizData?.boy
            ? local_data.selected_fromGrade?.id
            : "",
        },
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      )
      .then((res) => {
      //console.log(res);
        onSubmitSuccess();
        setTimeout(() => {
          navigate("/dashboard/View Quizzes");
        }, 500);
      });
  };

  const createQuiz = async () => {
    const BASE_URL = process.env.REACT_APP_SWAGGER_URL;
    const getSubtopicID =
      local_data?.selected_sub_topic?.length > 0
        ? local_data.selected_sub_topic[0].id
        : local_data.selected_sub_topic?.id;

    await axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz`,
        {
          name: local_data?.quiz_name,
          boardId: local_data?.selected_board?.id,
          gradeId: local_data?.selected_grade?.id,
          subjectId: local_data?.selected_subject?.id,
          subTopicId: getSubtopicID,
          academicYearId: local_data?.selected_year?.id,
          quizTypeId: local_data?.quiz_type_id,
          totalQuestions: selected_questions?.length,
          totalMarks: parseInt(local_data?.score),
          withTiming: true,
          duration: parseInt(local_data?.duration),
          quizQuestions: selected_questions.map((item, index) => {
            return {
              chapterId: isUnitQuiz
                ? local_data?.selected_chapter?.id
                : item.chapterId,
              questionId: item.id,
              insertionOrder: index + 1,
              // active: true,
              // removed: false
            };
          }),
          containsCaseStudy: selected_questions.some(
            (item) => item.questionTypeCode === "CASE"
          ),
          active: true,
          boy: local_data?.boy,
          fromGradeId: local_data?.boy ? local_data.selected_fromGrade?.id : "",
        },
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      )
      .then(res => {
        // console.log(res)
        onSubmitSuccess()

      })
      .catch((err) => {
        // console.log(err,err.response.data.message)
        alert(err.response.data.message);
        dispatch(reset_quiz_creation());
      });
  };

  useEffect(() => {
  //console.log(quiz, "quiz");
  }, [quiz]);

  useEffect(() => {
    const fetchData = async () => {
      let endpoint = "";
      selected_questions.forEach((item, index) => endpoint + `%${item.id}`);
      let a = selected_questions.map((item, index) => {
        if (index === 0) {
          return `${item.id}`;
        } else {
          return `%2C${item.id}`;
        }
      });
      let joined = a.join("");
      // console.log(joined, "endpoints");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/calculate-taxonmy?questionIds=${joined}&totalMarks=${local_data?.score}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        // console.log(response?.data?.data, "tax0 response");
        setCalculations(response?.data?.data);
      } catch (error) {
      //console.log(error);
      }
    };
    if (open) {
      fetchData();
    }
  }, [open]);

  const getChaptermarks = (id) => {
    return selected_questions
      .filter((item) => item.chapterId === id)
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.marks,
        0
      );
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper} style={{ overflowY: "scroll" }}>
          <h2 style={{ color: "#FD8C02", textTransform: "capitalize" }}>
            {ViewQuizData
              ? `Update ${local_data?.quiz_type}`
              : `Create ${local_data?.quiz_type}`}
          </h2>
          <div style={{ height: 20 }} />
          {/* <div style={{height:200,overflowY:'scroll',marginBottom:20}}> */}
          {chapters && chapters.length > 0 && !isUnitQuiz ? (
            <Table>
              <TableHead style={{ color: "#001AF3" }}>
                <TableRow className={classes.header}>
                  <TableCell className={classes.headerText}>Chapter</TableCell>
                  <TableCell className={classes.headerText}>Marks</TableCell>
                  <TableCell className={classes.headerText}>
                    Percentage
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chapters.map((data) => {
                  if (getChaptermarks(data.id) > 0) {
                    return (
                      <TableRow key={data.id}>
                        <TableCell>{data.chapter}</TableCell>
                        <TableCell>{getChaptermarks(data.id)}</TableCell>
                        <TableCell>
                          {Math.trunc(
                            (getChaptermarks(data.id) * 100) / local_data?.score
                          )}
                          %
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          ) : (
            <Table>
              <TableHead style={{ color: "#001AF3" }}>
                <TableRow className={classes.header}>
                  <TableCell className={classes.headerText}>Chapter</TableCell>
                  <TableCell className={classes.headerText}>Marks</TableCell>
                  <TableCell className={classes.headerText}>
                    Percentage
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={chapter.id}>
                  <TableCell>{chapter.chapter}</TableCell>
                  <TableCell>{getChaptermarks(chapter.id)}</TableCell>
                  <TableCell>
                    {Math.trunc(
                      (getChaptermarks(chapter.id) * 100) / local_data?.score
                    )}
                    %
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {/* </div> */}
          <Table>
            <TableHead style={{ color: "#001AF3" }}>
              <TableRow className={classes.header}>
                <TableCell className={classes.headerText}>Taxonomy</TableCell>
                <TableCell className={classes.headerText}>Marks</TableCell>
                <TableCell className={classes.headerText}>Percentage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calculations && calculations.length > 0
                ? calculations.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.taxonomy}</TableCell>
                    <TableCell>{data.marks}</TableCell>
                    <TableCell>{data.percentage}%</TableCell>
                  </TableRow>
                ))
                : null}
            </TableBody>
          </Table>
          <div style={{ marginTop: "24px" }}>
            <form>
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    width: "400px",
                    marginLeft: "40px",
                  }}
                >
                  <TextField
                    required
                    id="outlined-required"
                    label="Quiz name"
                    variant="outlined"
                    value={editedQuizData?.name || local_data?.quiz_name}
                    disabled
                    InputLabelProps={{
                      style: { color: "#FD8C02" }, // set the color to red
                    }}
                  />
                </span>
                <span style={{ width: "400px" }}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Total Marks"
                    variant="outlined"
                    value={editedQuizData?.totalMarks || local_data?.score}
                    disabled
                    InputLabelProps={{
                      style: { color: "#FD8C02" }, // set the color to red
                    }}
                  />
                </span>
              </div>
              <div style={{ display: "flex", marginTop: "22px" }}>
                <span
                  style={{
                    width: "400px",
                    marginLeft: "40px",
                  }}
                >
                  <TextField
                    required
                    variant="outlined"
                    id="outlined-required"
                    type="number"
                    label="Duration"
                    value={editedQuizData?.duration || local_data?.duration}
                    disabled
                    InputLabelProps={{
                      style: { color: "#FD8C02" }, // set the color to red
                    }}
                  />
                </span>
                <span style={{ width: "400px" }}>
                  {/* <TextField
                                        required
                                        id="outlined-required"
                                        label="Accademic year"
                                        InputLabelProps={{
                                            style: { color: '#FD8C02' } 
                                          }}
                                        variant="outlined"
                                    /> */}
                  <TextField
                    variant="outlined"
                    id="outlined-required"
                    type="text"
                    label="Selected year"
                    value={local_data?.selected_year.academicYear}
                    disabled
                    InputLabelProps={{
                      style: { color: "#FD8C02" }, // set the color to red
                    }}
                  />
                </span>
              </div>
            </form>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                style={{
                  marginTop: "26px",
                  marginLeft: "400px",
                }}
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                color="primary"
                variant="contained"
                shape="oval"
                style={{
                  marginTop: "26px",
                  mamarginLeft: "1200px",
                }}
                onClick={() => updateCreateQuiz()}
              >
                {isEditFlow ? "UPDATE" : "CREATE"}
              </Button>
            </Stack>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateQuizModal;
