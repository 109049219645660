import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import { SearchOutlined } from "@mui/icons-material";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import associateChapter_get from "../API_CONTROLLER/associate-chapters-controller/associateChapters_get";
import PracticeQuizModal from "./PracticeQuizModal";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

const PracticeQuiz = () => {
  const [modal, setModal] = useState(true);
  const [board, setBoard] = useState([]);
  const [subject, setSubject] = useState([]);
  const [gradesName, setGradesName] = useState([]);
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const [chapters, setChapters] = useState([]);
  const [gradeInput, setGradeInput] = useState("");
  const [boardInput, setBoardInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [search, setSearch] = useState({
    search: "",
  });

  const navigate = useNavigate();
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    associateChapter_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      boardInput
    );
  };

  const closeModal = () => {
    setModal(false);
  };
  const closeModalAPI = () => {};

  useEffect(() => {
    if (subjectInput || gradeInput || boardInput) {
      associateChapter_get(
        setChapters,
        page,
        setPageDetail,
        // rowsInput,
        boardInput,
        gradeInput,
        subjectInput
      );
    }
  }, [boardInput, subjectInput, gradeInput]);

  // useEffect(() => {
  //   associateChapter_get(
  //     setChapters,
  //     page,
  //     setPageDetail,
  //     // rowsInput,
  //     boardInput,
  //     gradeInput,
  //     subjectInput
  //   );
  // }, [rowsInput, page])

  useEffect(() => {
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    GradesControllerGetall(setGradesName);
    // console.log(board, "565656");
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Associate concepts</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>
            Last Update:
            {/* {lastModifiedDate.data} */}
          </p>
        </div>
      </article>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "0.5vw",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <LibraryBooksRoundedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                Create Quiz
              </div>
            </div>
            <Box sx={{ width: "9vw" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">*Boards</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={{
                    fontSize: "1vw",
                    marginTop: "5px",
                  }}
                  value={boardInput}
                  label="Board"
                  onChange={(e) => {
                    setBoardInput(e.target.value);
                  }}
                  style={{ background: "#fff", borderRadius: 150 }}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="">All</MenuItem>
                  {board.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.board} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "10vw" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">Subject</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={{
                    fontSize: "1vw",
                    marginTop: "5px",
                  }}
                  value={subjectInput}
                  label="Subject"
                  style={{ background: "#fff", borderRadius: 150 }}
                  onChange={(e) => {
                    setSubjectInput(e.target.value);
                  }}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="">All</MenuItem>
                  {subject?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.subject} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "9vw" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">Grade</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={{
                    fontSize: "1vw",
                    marginTop: "5px",
                  }}
                  value={gradeInput}
                  label="Grade"
                  onChange={(e) => {
                    setGradeInput(e.target.value);
                  }}
                  style={{ background: "#fff", borderRadius: 150 }}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="">All</MenuItem>
                  {gradesName?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.grade} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "9vw" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">Sub Topic</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={{
                    fontSize: "1vw",
                    marginTop: "5px",
                  }}
                  value={gradeInput}
                  label="Grade"
                  onChange={(e) => {
                    setGradeInput(e.target.value);
                  }}
                  style={{ background: "#fff", borderRadius: 150 }}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="">All</MenuItem>
                  {gradesName?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.grade} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "9vw" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">Academic Year</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={{
                    fontSize: "1vw",
                    marginTop: "5px",
                  }}
                  value={gradeInput}
                  label="Grade"
                  onChange={(e) => {
                    setGradeInput(e.target.value);
                  }}
                  style={{ background: "#fff", borderRadius: 150 }}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="">All</MenuItem>
                  {gradesName?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.grade} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        {/* <div style={{textAlign: 'center',position: 'relative',borderBottom: '2px solid orange'}}>
                <p style={{padding:"0 15px" , color:'orange' ,backgroundColor:"#f8f4fc",position: 'absolute',left:'45%', top: '-15px'}}>Physics</p>
              </div> */}

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {chapters && chapters.length > 0
            ? chapters.map((elem) => {
                return (
                  <div
                    onClick={() => {
                      sessionStorage.setItem("board", elem.board);
                      sessionStorage.setItem("boardId", elem.boardId);
                      sessionStorage.setItem("grade", elem.grade);
                      sessionStorage.setItem("gradeId", elem.gradeId);
                      sessionStorage.setItem("subject", elem.subject);
                      sessionStorage.setItem("subjectId", elem.subjectId);
                      sessionStorage.setItem("chapter", elem.chapter);
                      sessionStorage.setItem("chapterId", elem.id);

                      navigate("/dashboard/AssociateCptCreated");
                    }}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      margin: "8px",
                      position: "relative",
                      width: "212px",
                      height: "275px",
                    }}
                  >
                    {elem.thumbnailPath !== null ? (
                      <img
                        width="212px"
                        height="275px"
                        src={elem.thumbnailPath}
                        alt={elem.chapter}
                      />
                    ) : (
                      <img alt="" />
                    )}
                    <p
                      style={{
                        backgroundColor: "white",
                        padding: "9px",
                        height: "40px",
                        textAlign: "center",
                        width: "100%",
                        position: "absolute",
                        bottom: "0px",
                        fontSize: "1.1vw",
                      }}
                    >
                      {elem.chapter}
                    </p>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <PracticeQuizModal
        open={true}
        close={closeModal}
        closeModalAPI={closeModalAPI}
      />
    </div>
  );
};

export default PracticeQuiz;
