import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BreadCrumbs from "./General/BreadCrumbs";
import FilterBar from "./FilterBar";
import TabMenu from "./TabMenu";
import TableComponent from "./TableComponent";
import axios from "axios";
import RejectCommentModal from "./General/Modal/RejectCommentModal";
import RejectConfirmModal from "./General/Modal/RejectConfirmModal";
import SuccessModal from "./General/Modal/SuccessModal";
import { useLocation } from "react-router";
import RejectedModal from "./General/Modal/RejectedModal";
import { isNOTNullOrUndefined } from "../../../utils/helper";

function RevisionAdminDashboard() {

	const [selectedMenu, setSelectedMenu] = useState("PENDING");
	useEffect(() => {
		setSelectedMenu("PENDING");
	}, []);

	const [selectedQuiz, setSelectedQuiz] = useState({});
	const [isCommentModal, setCommentModal] = useState(false);
	const [isRejectModal, setRejectModal] = useState(false);
	const [comment, setComment] = useState("");
	const [isSuccessModal, setSuccessModal] = useState(false);
	const [isRejectedModal, setRejectedModal] = useState(false);
	const [isPreview, setPreview] = useState(false);
	// <-----------state for Tabmenu componenet-------------->
	const [quizes, setQuizes] = useState([]);
	const [pageSize, setPageSize] = useState(20);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(1);
	const [sortOrder, setSortOrder] = useState(true);
	const [sortBy, setSortBy] = useState("id");
	// <-----------states for filter componenet-------------->
	const [grades, setGrades] = useState([]);
	const [subjects, setSubject] = useState([]);
	const [showSubTopic, setShowSubTopic] = useState(true);
	const [boards, setBoard] = useState([]);
	const [quizTypes, setQuizType] = useState([])
	const [subTopics, setSubTopics] = useState([])

	const [isCommentError, setCommentError] = useState(false);

	const [filterState, setFilterState] = useState({
		grade: {},
		board: {},
		subject: {},
		searchParams: "",
		quizType: {},
		subTopic: {}
	});

	const [changeCount, setChangeCount] = useState(1);
	const [totalQuizCount, setTotalQuizCount] = useState(0)
	// <-----------state for Tabmenu componenet-------------->

	const handleChange = (e) => {
		setCurrentPage(0);
		setChangeCount((prev) => prev + 1);
		let currentData = filterState;
		let value = e.target.value;
		let name = e.target.name;
		if (name === "grade") {
			currentData[name] = grades.find((item) => item.id === value);
		} else if (name === "board") {
			currentData[name] = boards.find((item) => item.id === value);
		} else if (name === "subject") {
			currentData[name] = subjects.find((item) => item.id === value);
			setShowSubTopic(currentData[name].hideSubtopics == true ? false : true);
			setSubTopics(
				currentData[name]?.subTopics && currentData[name]?.subTopics
			);
		} else if (name === "quiz_type") {
			currentData["quizType"] = quizTypes.find((item) => item.id === value);
		} else if (name === "subTopic") {
			currentData["subTopic"] = subTopics.find((item) => item.id === value);

		} else {
			currentData[name] = value;
		}
		setFilterState(currentData)
	};

	//<--------------Api to fetch quiz data -------------------->
	const getTotalData = () => {
		// console.log("total record fetching api")
		const token = sessionStorage.getItem("token");
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/content/quiz/page?pageNumber=${currentPage}&pageSize=${pageSize}
				&sortOrder=${sortOrder}&sortBy=${sortBy}&active=true
				${isNOTNullOrUndefined(filterState?.subTopic?.id)
					? "&subTopicId=" + filterState?.subTopic?.id
					: ""
				}${isNOTNullOrUndefined(filterState.board?.id)
					? "&boardId=" + filterState.board?.id
					: ""
				}${isNOTNullOrUndefined(filterState.grade?.id)
					? "&gradeId=" + filterState.grade?.id
					: ""
				}${isNOTNullOrUndefined(filterState.subject?.id)
					? "&subjectId=" + filterState.subject?.id
					: ""
				}${isNOTNullOrUndefined(filterState.quizType?.id)
					? "&quizTypeId=" + filterState.quizType?.id
					: ""
				}
				`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				if (res.data.success) {
					setTotalQuizCount(res.data?.data?.totalElements)
				} else {
					alert("Something went wrong");
				}
			})
			.catch((err) => {
				// console.log(err);
				alert(err);
			});
	};
	const sortByField = (array, field, order) => {
		// console.log(array, field, order)
		order = order == true ? "desc" : "asc";
		if (array?.length > 0) {
			var nullsFirst = false;
			// console.log("asssss", order);
			if (order == "asc") {
				nullsFirst = true
			}
			return array.sort((a, b) => {

				if (a[field] === null && b[field] === null) return 0;
				if (a[field] === null) return nullsFirst ? -1 : 1;
				if (b[field] === null) return nullsFirst ? 1 : -1;

				if (typeof a[field] === 'boolean') {
					if (order === 'asc') {
						return a[field] === b[field] ? 0 : a[field] ? 1 : -1;
					} else {
						return a[field] === b[field] ? 0 : a[field] ? -1 : 1;
					}
				}
				else if (typeof a[field] === 'string') {
					if (order === 'asc') {
						return a[field].localeCompare(b[field]);
					} else {
						return b[field].localeCompare(a[field]);
					}
				} else if (typeof a[field] === 'number' || a[field] instanceof Date) {
					if (order === 'asc') {
						return a[field] - b[field];
					} else {
						return b[field] - a[field];
					}
				}
			});
		} else {
			return []
		}
	};

	const getQuizData = () => {
		// console.log("pagination api")
		const token = sessionStorage.getItem("token");
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/content/quiz/page?pageNumber=${currentPage}&pageSize=${pageSize}
				&sortOrder=${sortOrder}&sortBy=${sortBy}&active=true
				${isNOTNullOrUndefined(filterState?.subTopic?.id)
					? "&subTopicId=" + filterState?.subTopic?.id
					: ""
				}${isNOTNullOrUndefined(filterState.board?.id)
					? "&boardId=" + filterState.board?.id
					: ""
				}${isNOTNullOrUndefined(filterState.grade?.id)
					? "&gradeId=" + filterState.grade?.id
					: ""
				}${isNOTNullOrUndefined(filterState.subject?.id)
					? "&subjectId=" + filterState.subject?.id
					: ""
				}${isNOTNullOrUndefined(filterState.quizType?.id)
					? "&quizTypeId=" + filterState.quizType?.id
					: ""
				}
				&reviewStatus=REJECTED
				`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				if (res.data.success) {
					// console.log(sortOrder, sortBy, "-------------")
					var sortedData = sortByField(res.data.data.data, sortBy, !sortOrder);
					// console.log("sortedData", sortedData)
					if (sortedData) {
						setQuizes(sortedData);
					} else {
						setQuizes(res.data.data.data);
					}

					setTotalPages(res.data.data.totalPages);
				} else {
					alert("Something went wrong");
				}
			})
			.catch((err) => {
				// console.log(err);
				alert(err);
			});
	};


	useEffect(() => {
		getTotalData()
		getQuizData();
	}, [
		changeCount,
		pageSize,
		currentPage,
		selectedMenu,
		isSuccessModal,
		isCommentModal,
		sortBy,
		sortOrder,
	]);

	const rejectQuiz = () => {
		const token = sessionStorage.getItem("token");
		if (comment) {
			axios
				.put(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/review`,
					{
						id: selectedQuiz.id,
						status: "REJECTED",
						comment: comment,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					if (res.data.success) {
						setCommentModal(false);
						setRejectedModal(true);
						setComment("");
					} else {
						alert("Something went wrong");
					}
				})
				.catch((err) => {
					// console.log(err);
					alert(err);
				});
		} else {
			setCommentError(true);
		}
	};



	const addComment = () => {
		const token = sessionStorage.getItem("token");
		if (comment) {
			axios
				.post(
					`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/reject-comment`,
					{
						id: selectedQuiz.id,
						comment: comment,
						status: selectedQuiz.status,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					if (res.data.success) {
						alert("Comment added successfully");
						setCommentModal(false);
						setComment("");
					} else {
						alert("Something went wrong");
					}
				})
				.catch((err) => {
					// console.log(err);
					alert(err);
				});
		} else {
			setCommentError(true);
		}
	};

	return (
		<Container>
			<RejectCommentModal
				isOpen={isCommentModal}
				setCommentModal={setCommentModal}
				selectedQuiz={selectedQuiz}
				setComment={setComment}
				comment={comment}
				rejectQuiz={rejectQuiz}
				addComment={addComment}
				isCommentError={isCommentError}
				setCommentError={setCommentError}
			/>
			<SuccessModal
				isOpen={isSuccessModal}
				setSuccessModal={setSuccessModal}
			/>
			<RejectedModal
				isOpen={isRejectedModal}
				setSuccessModal={setRejectedModal}
			/>

			<RejectConfirmModal
				isOpen={isRejectModal}
				setRejectModal={setRejectModal}
				selectedQuiz={selectedQuiz}
				setSelectedQuiz={setSelectedQuiz}
				setCommentModal={setCommentModal}
			/>

			<MainContainer>
				<BreadCrumbs currentPage={"Dashboard"} />
				<FilterBar
					// title={`Total Quiz Created: ${quizes?.length}`}
					title={`Total Quiz Created: ${totalQuizCount}`}

					selectedMenu={selectedMenu}
					filterState={filterState}
					setFilterState={setFilterState}
					setGrades={setGrades}
					grades={grades}
					setSubject={setSubject}
					subjects={subjects}
					boards={boards}
					setBoard={setBoard}
					handleChange={handleChange}
					setQuizType={setQuizType}
					quizTypes={quizTypes}
					subTopics={subTopics}
					showSubTopic={showSubTopic}
				/>
				<TabMenu
					selectedMenu={"REJECTED"}
				/>
				<TableComponent
					quizes={quizes}
					setQuizes={setQuizes}
					pageSize={pageSize}
					setPageSize={setPageSize}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					totalPages={totalPages}
					setSelectedQuiz={setSelectedQuiz}
					setCommentModal={setCommentModal}
					setRejectModal={setRejectModal}
					setPreview={setPreview}
					setSortOrder={setSortOrder}
					setSortBy={setSortBy}
				/>
			</MainContainer>

		</Container>
	);
}

export default RevisionAdminDashboard;
const Container = styled.div`
	width: 100% !important;
	display: flex;
`;

const MainContainer = styled.div`
	padding: 10px 20px;
	width: 100% !important;
	background-color: #f3f2ff;
`;
