import React from 'react'
import sortIcon from "../../../img/sort_1.png"
import { useState } from "react";
import { sortBy } from 'lodash';


const BluePrintTablehead = ({ tableHeadData, onSort }) => {
  // console.log(tableHeadData,onSort)
  const [newTableHeadData, setNewTableHeadData] = useState(tableHeadData);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  // console.log("--------------", tableHeadData)
  return (
    <thead>
      <tr className="table-data-head">
        {newTableHeadData?.map((item) => {
          return (
            <>
              <th
                style={{
                  textAlign: item?.title == "Action" ? "start" : "",
                  borderRight: "1px Solid #0000001F",
                  paddingLeft: item?.title == "Action" ? "10px" : "",
                }}
              >
                {item?.title}
                <img
                  style={{
                    verticalAlign: "middle",
                    marginLeft: "5px",
                    marginBottom: "1px",
                  }}
                  className="cursor-pointer"
                  width="12"
                  height="15"
                  src={sortIcon}
                  onClick={async () => {
                    onSort(item.title)
                  }}
                />
              </th>
            </>
          );
        })}
      </tr>
    </thead>
  )
}

export default BluePrintTablehead