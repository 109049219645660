import axios from "axios";

export default async function createGradeSectionMapping(payload,cb, errorMessageFunc) {
  let token = sessionStorage.getItem("token");
    await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section`,payload,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      // console.log(resp.message)
      cb();
    })
    .catch((err)=>{
      // console.log("error");
      errorMessageFunc()
    })
}
