import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import queSelectionStyles from '../../../css/queSelectionStyles.module.css';
import quebankStyle from '../../../css/questionbank.module.css';
import RootConceptQuestions from './RootConceptQuestions';
import { isNOTNullOrUndefined } from '../../../../utils/helper';

const RootConceptsList = ({ IsSubconceptExpanded, conceptId, chapterId, subConceptId, taxonomySelected, questiontypeId, search }) => {

  const [rootConceptsList, setRootConceptsList] = useState([]);

  const local_data = JSON.parse(localStorage.getItem('quiz_data'))


  const boardId = local_data?.selected_board?.id
  const gradeId = local_data?.selected_grade?.id
  const subjectId = local_data?.selected_subject?.id

  const subTopicId = local_data?.selected_sub_topic?.id

  const TOKEN = sessionStorage.getItem('token');


  const showtaxonomy = () => {
    if (questiontypeId && questiontypeId.code === 'CASE') {
      return false
    } else if (isNOTNullOrUndefined(taxonomySelected)) {
      return true
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/sub-root/concept-list?pageSize=1000&conceptId=${conceptId}&subConceptId=${subConceptId}&chapterId=${chapterId}${isNOTNullOrUndefined(boardId) ? `&boardId=${boardId}` : ''}${isNOTNullOrUndefined(gradeId) ? `&gradeId=${gradeId}` : ''
          }${isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}` : ''
          }${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''}${showtaxonomy() ? `&taxonomyId=${taxonomySelected?.id}` : ''
          }${isNOTNullOrUndefined(questiontypeId.id)
            ? `&questionTypeId=${questiontypeId.id}`
            : ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        setRootConceptsList(response.data.data.data);
      } catch (error) {
        // console.log(error);
      }
    };
    if (IsSubconceptExpanded) {
      fetchData()
    }
  }, [conceptId, subConceptId, chapterId, taxonomySelected, questiontypeId, IsSubconceptExpanded, search]);

  const [IsRootconceptExpanded, setIsRootconceptExpanded] = useState(true)

  return (
    <AccordionDetails>
      {
        rootConceptsList.length > 0 &&
        rootConceptsList.map((sub, i) => {
          return (
            <Accordion key={sub.id}
              onChange={(e, expanded) => setIsRootconceptExpanded(expanded)}
              expanded={setIsRootconceptExpanded}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <p
                  className={
                    quebankStyle.plan_container_link
                  }
                >
                  <span
                    className={
                      queSelectionStyles.breadcrumbs
                    }
                  >
                    {sub.conceptName}
                  </span>
                </p>
              </AccordionSummary>
              <RootConceptQuestions
                IsRootconceptExpanded={IsRootconceptExpanded}
                taxonomySelected={taxonomySelected} chapterId={chapterId} subConceptId={subConceptId} questiontypeId={questiontypeId} conceptId={conceptId} rootConceptId={sub.id}
                search={search} />
            </Accordion>
          );
        })}
    </AccordionDetails>
  );
};


export default RootConceptsList