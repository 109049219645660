import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";

const DashboardContents = () => {
  const [second, setSecond] = useState(900);
  const navigate = useNavigate();
  let [intervalId, setIntervalId] = useState(null);
  const [modifiedDate, setModifiedDate] = useState({});

  useEffect(() => {
    // console.log("second ",second)
    if (second) {
      setIntervalId(
        setInterval(() => {
          setSecond((prevSec) => prevSec - 1);
        }, 1000)
      );
    }
  }, []);

  // useEffect(()=> {
  //   GetLastModifiedAt(setModifiedDate);
  //   return () => {};
  // } , [])

  useEffect(() => {
    if (second <= 0) {
      if (intervalId) {
        clearInterval(intervalId);
        localStorage.removeItem("token");
        // navigate("/login", { state: { id: 7, color: "green" } });
      }
    }
  }, [second]);

  const timeOut = () => {
    const minutes = Math.floor(second / 60);

    // 👇️ get remainder of seconds
    const seconds = second % 60;

    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }

    // ✅ format as MM:SS
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  };

  return (
    <section className={dashboardContentStyles.dashboard}>
      <div className={dashboardContentStyles.dashboard_link}>
        {/* <span class={dashboardContentStyles.link_icon}>
           <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />           
         */}
      </div>

      {/* <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div> */}
      {/* <p class={dashboardContentStyles.dashboard_placeholder}>Dashboard</p> */}

      <Outlet />
    </section>
  );
};

export default DashboardContents;
