import React, { useState, useEffect } from "react";
import ChapterStyles from "../../css/createChapter.module.css";
import boards_controller_getall from "../../API_CONTROLLER/boards-controller/boards_controller_getall";
import gradeListGet from "../../API/grade-section-mapping-controller/grade_get";
import { orange } from "@mui/material/colors";
import TextEditor from "./CommonComponents/TextEditor";
import DeleteConfirmationAlert from "./CommonComponents/DeleteConfirmation";
import create_question_post from "../../API_CONTROLLER/create-questions/question_post";
import Preview_MTF_DD from "./CommonComponents/Preview_MatchTheFollowing_DD";
import Preview_MTF_DL from "./CommonComponents/Preview_MatchTheFollowing_DL";

import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Grid,
    Box,
    Typography,
    styled,
    Select,
} from "@mui/material";
import update_question_post from "../../API_CONTROLLER/create-questions/question_put";


const CustomRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto orange',
        color: orange[600],
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: orange[800],
    },
});

const MatchTheFollowingMultiAnswer = (props) => {

    const [boardsList, setBoardsList] = useState();

    const [gradeList, setGradeList] = useState();
    const [boardAndGrade, setBoardAndGrade] = useState([]);

    const [clearContent, setClearContent] = useState(false);

    const [openImageListModal, setOpenImageListModal] = React.useState(false);
    const handleImageListModalOpen = () => setOpenImageListModal(true);
    const handleImageListModalClose = () => setOpenImageListModal(false);

    const [updateCount, setUpdateCount] = useState(props.updateCount);

    const [finalAnswers, setFinalAnswers] = useState(["A"]);
    const [selectedFinalAnswer, setSelectedFinalAnswers] = useState([]);

    const [options, setOptions] = useState([]); 
    const [optionsEdit, setOptionsEdit] = useState();

    const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
    const handlePreviewModalOpen = () => setOpenPreviewModal(true);
    const handlePreviewModalClose = () => setOpenPreviewModal(false);

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState();

    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");


    const [errorField, setErrorField] = useState({});

    const [responseValue, setResponseValue] = useState();
    const [previewFromModal, setPreviewFromModal] = useState(false);

    const subjectId = sessionStorage.getItem("subjectId");
    const conceptId = sessionStorage.getItem("conceptId");
    const subConceptId = sessionStorage.getItem("subConceptId");
    const rootConcept = sessionStorage.getItem("rootConceptId");
    const questionTypeId = sessionStorage.getItem("questionType");
    const taxonomyCategory = sessionStorage.getItem("taxonomyCategory");
    const taxonomy = sessionStorage.getItem("taxonomy");
    const matchType = sessionStorage.getItem("matchType");
    const token = sessionStorage.getItem("token");


    useEffect(() => {
        boards_controller_getall(setBoardsList);
        gradeListGet(setGradeList);
        setClearContent(false);
    }, []);

    useEffect(() => {
        setUpdateCount(props.updateCount);
    }, [props.updateCount]);

    useEffect(() => {
        let answers = []
        updateCount.map(count => {
            answers = [...answers, String.fromCharCode(count)];
        })
        setFinalAnswers([...answers]);
        if (updateCount.length < options.length) {
            options.pop();
        }
    }, [updateCount.length]);

    const onMatchQuestionHandler = (content, value, type) => {
        const answerKeys = updateCount.map((val, index) => {
            let obj;
            let found = -1;
            if (val === value) {
                found = options.findIndex(question => {
                    return question.order === String.fromCharCode(value);
                })
                obj = {
                    order: String.fromCharCode(value),
                    question: type === "question" && content,
                    jumbledAnswer: type === "jumbled" && content,
                    answers: type === "clues" ? [content] : [],
                }
            }
            if (found !== -1) {
                if (type === "question") {
                    options[found].question = content
                }
                else if (type === "jumbled") {
                    options[found].jumbledAnswer = content;
                }
                else {
                    options[found].answers = [content];
                }
            }
            else {
                return obj;
            }
        }).filter(Boolean);
        if (answerKeys.length > 0) {
            setOptions([...options, answerKeys[0]]);
        }
    }

    const onFinalAnswerHandler = (e, value, type) => {
        const choosed = e.target.value;
        setSelectedFinalAnswers(typeof choosed === 'string' ? choosed.split(',') : choosed);
        const answerKeys = updateCount.map((val, index) => {
            let obj;
            let found = -1;
            if (val === value) {
                found = options.findIndex(question => {
                    return question.order === String.fromCharCode(value);
                })
            }
            if (found !== -1) {
                options[found].answers = typeof choosed === 'string' ? choosed.split(',') : choosed;
            }
            else {
                return obj;
            }
        }).filter(Boolean);
        if (answerKeys.length > 0) {
            setOptions([...options, answerKeys[0]]);
        }
    }

    const handleCreateQuestion = (type) => {
        let isError = false;
        let errorObj = {};
        let questionContentFoundEmpty = options[0].question === '';
        let jumbledContentFoundEmpty = options[0].jumbledAnswer === '';
        let clueContentFoundEmpty = options[0].answers[0] === '';

        if (props.question === "" || props.combo.length === 0 || Object.keys(props.combo).length === 0 || questionContentFoundEmpty === true || jumbledContentFoundEmpty === true || clueContentFoundEmpty === true) {
            isError = true;
            if (props.combo.length === 0) {
                errorObj['combo'] = "Please provide the combination of board and grade";
            }
            if (Object.keys(props.combo).length === 0) {
                errorObj['combo'] = "Please provide the combination of board and grade";
            }
            if (props.question === "") {
                errorObj['question'] = "Please provide the question";
            }
            if (questionContentFoundEmpty === true) {
                errorObj['questionFoundEmpty'] = "Atleast one Question is needed";
            }
            if (jumbledContentFoundEmpty === true) {
                errorObj['jumbledFoundEmpty'] = "Atleast one Jumbled Answer is needed";
            }
            if (clueContentFoundEmpty === true) {
                errorObj['clueFoundEmpty'] = "Atleast one Clue is needed";
            }
        }

        props.setErrorField(errorObj);
        setErrorField(errorObj);

        if (!isError) {
            setClearContent(false);
            if (type === "update") {
                // setEdit(false);
                const payload1 = {
                    id: props.questionValue.id,
                    subjectId: subjectId,
                    conceptId: conceptId,
                    subConceptId: subConceptId,
                    rootConceptId: rootConcept !== "undefined" ? rootConcept : null,
                    questionTypeId: questionTypeId,
                    taxonomyCategoryId: taxonomyCategory,
                    taxonomyId: taxonomy,
                    boardAndGrades: boardAndGrade,
                    question: props.question,
                    explanation: null,
                    imageUrl: props.selectedImageUrl ? props.selectedImageUrl : null,
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: false,
                    matchType: matchType,
                    clueType: "MULTI_ANSWER",
                    questionPattern: "OBJECTIVE",
                    numberOfMatches: props.count,
                    options: options,
                }
                const payload = removeNewlines(payload1);
              //console.log(">>>",payload);
                update_question_post(payload, setShowSuccessAlert, setResponseValue);
            }
            else {
                const payload1 = {
                    subjectId: subjectId,
                    conceptId: conceptId,
                    subConceptId: subConceptId,
                    rootConceptId: rootConcept !== "" ? rootConcept : null,
                    questionTypeId: questionTypeId,
                    taxonomyCategoryId: taxonomyCategory,
                    taxonomyId: taxonomy,
                    boardAndGrades: props.boardAndGrade,
                    question: props.question,
                    explanation: null,
                    imageUrl: props.selectedImageUrl ? props.selectedImageUrl : null,
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: false,
                    matchType: matchType,
                    clueType: "MULTI_ANSWER",
                    questionPattern: "OBJECTIVE",
                    numberOfMatches: props.count,
                    options: options,
                }
                const payload = removeNewlines(payload1);
              //console.log(">>>",payload);
                create_question_post(payload, setShowSuccessAlert, props.setResponseValue, setShowErrorAlert, setShowErrorMessage);
            }
            sessionStorage.setItem("matchoptions", JSON.stringify(options));
        }
    }

    const removeNewlines = (obj) => {
        const newObj = {};
        // Recursively traverse the object
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
              obj[key] = obj[key].replace(/\n/g, '');
            } else if (typeof obj[key] === 'object') {
              removeNewlines(obj[key]);
            }
          }
        return obj;
    };

    useEffect(() => {
        if (showSuccessAlert) {
            setClearContent(true);
            window.tinymce.activeEditor.setContent('');
            props.setSuccessMessage(`You have successfully created Match the Following Quesiton Multi Answer`);
            setErrorField({});
            setBoardAndGrade([]);
            props.setClearFields(true);
            options.splice(1);
            options[0].answers = [];
          //console.log(responseValue)
            // props.setResponseValue(responseValue);
            props.setShowSuccessAlert(true);
        }
    }, [showSuccessAlert]);

    const onHideSuccessModal = () => {
        setShowSuccessAlert(false);
    }

    const onHideErrorModal = () => {
        setShowErrorAlert(false);
    }

    const handleCancelUpdate = () => {
        setClearContent(true);
        window.tinymce.activeEditor.setContent('');
        props.setSuccessMessage(`You have successfully created Match the Following Quesiton Multi Answer`);
        setErrorField({});
        setBoardAndGrade([]);
        props.setClearFields(true);
        options.splice(1);
        options[0].answers = [];
      //console.log(responseValue)
        // props.setResponseValue(responseValue);
        props.setShowSuccessAlert(true);
    }

    useEffect(() => {
        if (props.options[0]) {
          //console.log(props.questionValue);
        }
            setOptionsEdit(props.questionValue.options[0]);
            const answerKeys =  props.questionValue.options[0].map((val, index) => {
                // let found = options && options.findIndex(question => {
                //     return question.order === val.order;
                // })
                // if (found !== -1) {
                //     const newlyAdded = [...options];
                //     newlyAdded[index].answers = [val.answers[0]];
                //     return newlyAdded;
                // }
                // else{
                const preAns = {
                    order: val.order,
                    jumbledAnswer: val.jumbledAnswer,
                    answers: [val.answers[0]],
                    question: val.question
                }
                return preAns;
                // }
            })
            setOptions(...options, answerKeys);
    }, [props.options[0]])

  //console.log(props.options);

    return (
        <>
            <Grid container spacing={4} sx={{ px: 5, py: 2 }}>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography>Question</Typography>
                    {updateCount.map((count, index) => {
                        //console.log(optionsEdit)
                        return (
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={1} lg={1} sx={{ alignContent: "center", flexWrap: "wrap", display: "flex" }}>
                                        <Typography>{index + 1})</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={11} lg={11}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                boxShadow: 3,
                                                border: "1px solid #001AF3",
                                                m: 2
                                            }}
                                        >
                                            <TextEditor onEditorContentChange={(content) => onMatchQuestionHandler(content, count, "question")} id={`question${String.fromCharCode(count)}`} initialValue={props.isEdit ? optionsEdit[index] && optionsEdit[index].question : ""} clearContent={clearContent && clearContent} paragraphStyle={true} format="text" />
                                        </Box>
                                    </Grid>
                                </Grid>
                                {!!errorField["questionFoundEmpty"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["questionFoundEmpty"]}</Typography>}
                            </>
                        )
                    })
                    }
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography>Jumbled Answer</Typography>
                    {updateCount.map((count, index) => {
                        return (
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={1} lg={1} sx={{ alignContent: "center", flexWrap: "wrap", display: "flex" }}>
                                        <Typography>{String.fromCharCode(count)})</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={11} lg={11}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                boxShadow: 3,
                                                border: "1px solid #001AF3",
                                                m: 2
                                            }}
                                        >
                                            <TextEditor onEditorContentChange={(content) => onMatchQuestionHandler(content, count, "jumbled")} id={`jumbled${String.fromCharCode(count)}`} initialValue={props.isEdit ? optionsEdit[index] && optionsEdit[index].jumbledAnswer : ""} clearContent={clearContent && clearContent} paragraphStyle={true} format="text" />
                                        </Box>
                                    </Grid>
                                </Grid>
                                {!!errorField["jumbledFoundEmpty"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["jumbledFoundEmpty"]}</Typography>}
                            </>
                        )
                    })
                    }
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Typography>Final Answer</Typography>
                    {updateCount.map((count, index) => {
                        return (
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '200px',
                                                maxWidth: '100%',
                                                boxShadow: 3,
                                                border: "1px solid #001AF3",
                                                m: 2,
                                                p: 2
                                            }}
                                        >
                                            <FormControl sx={{ width: "22vw" }}>
                                                <InputLabel
                                                    className={ChapterStyles.select_input}
                                                    id="demo-simple-select-required-label"
                                                >
                                                    *Choose Answers
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    name="subConceptId"
                                                    multiple
                                                    value={options.length > 0 && options[index] ? options[index].answers : []}
                                                    onChange={(e) => onFinalAnswerHandler(e, count, "final")}
                                                    input={<OutlinedInput label="Tag" />}
                                                >
                                                    {finalAnswers && finalAnswers.length > 0 ? (
                                                        finalAnswers.map((elem, i) => {
                                                            return (
                                                                <MenuItem key={i} value={elem}>
                                                                    {elem}
                                                                </MenuItem>
                                                            );
                                                        })
                                                    ) : (
                                                        <MenuItem>Select</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {!!errorField["clueFoundEmpty"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["clueFoundEmpty"]}</Typography>}
                            </>
                        )
                    })
                    }
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <FormControl sx={{ ml: 1 }}>
                        <button
                            style={{ maxHeight: "50px" }}
                            onClick={handlePreviewModalOpen}
                            className={ChapterStyles.blueWhite_btn}
                        >
                            {"PREVIEW"}
                        </button>
                    </FormControl>
                    {props.isEdit ?
                        <>
                            <FormControl sx={{ ml: 1 }}>
                                <button
                                    style={{ maxHeight: "50px" }}
                                    onClick={handleCancelUpdate}
                                    className={ChapterStyles.blue_btn}
                                >
                                    CANCEL
                                </button>
                            </FormControl>
                            <FormControl sx={{ ml: 1 }}>
                                <button
                                    style={{ maxHeight: "50px" }}
                                    onClick={() => handleCreateQuestion("update")}
                                    className={ChapterStyles.blue_btn}
                                >
                                    UPDATE
                                </button>
                            </FormControl>
                        </>
                        :
                        <FormControl sx={{ ml: 1 }}>
                            <button
                                style={{ maxHeight: "50px" }}
                                onClick={() => handleCreateQuestion("create")}
                                className={ChapterStyles.blue_btn}
                            >
                                CREATE
                            </button>
                        </FormControl>
                    }
                </Grid>
            </Grid>
            {matchType === "DRAG_DROP" ?
                <Preview_MTF_DD
                    open={openPreviewModal}
                    close={handlePreviewModalClose}
                    options={options}
                    questionContent={props.question}
                    imagePreview={props.imagePreview}
                    selectedImageUrl={props.selectedImageUrl}
                    responseValue={responseValue}
                    previewFromModal={previewFromModal}
                    setPreviewFromModal={setPreviewFromModal}
                />
                :
                <Preview_MTF_DL
                    open={openPreviewModal}
                    close={handlePreviewModalClose}
                    options={options}
                    questionContent={props.question}
                    imagePreview={props.imagePreview}
                    selectedImageUrl={props.selectedImageUrl}
                    responseValue={responseValue}
                    previewFromModal={previewFromModal}
                    setPreviewFromModal={setPreviewFromModal}
                />
            }
            <DeleteConfirmationAlert
                open={showSuccessAlert}
                close={onHideSuccessModal}
                alert={successMessage}
                caseStudy={true}
                onBackdropClickable={false}
            />
            {/* <DeleteConfirmationAlert
                open={showErrorAlert}
                close={onHideErrorModal}
                alert={showErrorMessage}
                caseStudy={false}
                openPreview={handlePreviewModalOpen}
                isEditQuestion={onEditQuestion}
                setNewConcept={props.setNewConcept}
                setGoToQuestionBank={props.setGoToQuestionBank}
                setPreviewFromModal={setPreviewFromModal}
            /> */}
        </>
    )
}

export default MatchTheFollowingMultiAnswer;