import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    TextField,
    FormControlLabel,
    FormLabel,
    Grid,
    LinearProgress,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { blue, orange } from "@mui/material/colors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import QuestionViewModal from "./QuestionViewModal";
import { Navigate, useLocation, useNavigate, } from "react-router-dom";
import "./QuestionModalPage.css";
// import { Navigate } from "react-router";
const style = {
    position: "absolute",
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 1000,
    padding: 5,
    rowGap: "1rem",
};
const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    marginBottom: "-20px"


};
const LinearProgressWithLabel = (props) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <Box
                    sx={{
                        minWidth: 35,
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10",
                    }}
                >
                    <Typography
                        fontSize={18}
                        fontWeight={600}
                        sx={{ padding: "10px 0px" }}
                        dangerouslySetInnerHTML={{ __html: props.name }}
                    ></Typography>
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        fontSize={18}
                        sx={{ padding: "10px 0px" }}
                    >{`${Math.round(props.value)} out of ${props.totalQuestions
                        }`}</Typography>
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={Math.round((100 * props.value) / props.totalQuestions)}
                    sx={{ height: 10, borderRadius: "150px", color: "#FFA700" }}
                    color={"inherit"}
                />
            </Box>
        </Box>
    );
};
const CustomTextField = styled(TextField)(({ }) => ({
    width: "96%",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "2px !important",
    height: "100%",
    "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
        opacity: "1 !important",
        background: "#f8f4fc !important",
        border: "1px solid #B5B6B6",
        borderRadius: "2px !important",
    },
}));
const CustomRadioIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: "inset 0 0 0 3px orange, inset 0 -1px 0 orange",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
        theme.palette.mode === "dark"
            ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
            : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
        outline: "2px auto orange",
        color: orange[600],
        outlineOffset: 2,
    },
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background:
            theme.palette.mode === "dark"
                ? "rgba(57,75,89,.5)"
                : "rgba(206,217,224,.5)",
    },
}));
const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: orange[800],
    },
});

function CustomRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

const ExitButton = styled(Button)(() => ({
    color: "#fff",
    width: 80,
    backgroundColor: "rgba(5,175,116,0.89)",
    borderRadius: 5,
    textTransform: "none",
    "&:hover": {
        color: "#fff",
        backgroundColor: "rgba(5,175,116,0.89)",
        border: 0,
    },
}));
const PreviewButton = styled(Button)(() => ({
    color: "#fff",
    width: 200,
    backgroundColor: "#8589be",
    borderRadius: 5,
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    "&:hover": {
        color: "#fff",
        backgroundColor: "#8589be",
        border: 0,
    },
}));
const NextButton = styled(Button)(() => ({
    color: "#fff",
    width: 200,
    backgroundColor: "#FFA700",
    borderRadius: 5,
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    "&:hover": {
        color: "#fff",
        backgroundColor: "#FFA700",
        border: 0,
    },
}));
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "rgba(5,175,116,0.89)",
    ...theme.typography.body2,
    padding: "4px",
    textAlign: "center",
    color: "white",
    borderRadius: "150px",
    width: 35,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));
const AssignQuizPagePreView = (data) => {
    const navigate = useNavigate();
    const location = useLocation();
    const sourceMenu = location.state && location.state.sourceMenu;

    // console.log(sourceMenu, localStorage.getItem("role"));
    window.history.pushState(null, null, window.location.href);
    // console.log("questionmodel called", data);
    const [loggedInUser, setLoggedInUser] = useState(localStorage.getItem("role"));
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [storeData, setStoreData] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [answerByOrder, setAnswerByOrder] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [MSQList, setMSQList] = useState([]);
    const [MCQ, setMCQ] = useState("");
    const [subMCQ, setSubMCQ] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState({
        question: "",
        color: "",
    });
    const [selectedData, setSelectedData] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState("");
    const [selectedImgData, setSelectedImgData] = useState([]);
    const [selectedAnswerData, setSelectedAnswerData] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [questionIds, setQuestionIds] = useState([]);
    const [isViewModal, setViewModal] = useState(false);
    const [queston, setQuestion] = useState("");
    const [exitClicked, setExitClicked] = useState(false);

    const token = sessionStorage.getItem("token");

    const fetchDataUsingQuizId = async (data) => {
        // console.log("111111111111111111111111111111111111")
        const quizId = data.id;
        const randomizeQuestions = false;
        if (localStorage.getItem("role") == "STUDENT") {
            randomizeQuestions = true;
        }
        const response = await axios
            .get(
                `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/${quizId}?showCorrectAnswers=false&randomizeQuestions=${randomizeQuestions}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                // console.log("11111111111", response)
                setQuestionIds(response?.data.data.questionIdList);
            });
    };



    useEffect(() => {
        // document.getElementsByClassName("navbar_nav__rvSlM").style.display = "none";
        // document.getElementsByClassName("mainContainer_header__JNdBp").style.display = "none";

        // console.log("exitClicked", exitClicked, window.location.href)
        // console.log("useeffect1", exitClicked)
        var data1 = JSON.parse(sessionStorage.getItem("selectedquestionModal"));
        document.addEventListener('keydown', function (event) {
            // console.log(event)
            if (event.key === "Enter") {
                // console.log("Enter key pressed");
                if (loggedInUser == "STUDENT") {
                    const isDisabledButton =
                        event.target === document.getElementById('exitButton') ||
                        event.target === document.getElementById('previewButton') ||
                        event.target === document.getElementById('nextButton');

                    // Prevent the default behavior only for the specified buttons
                    if (isDisabledButton) {
                        event.preventDefault();
                    }
                } else {
                    event.preventDefault();
                }
            } else if (event.key === " ") {
                // console.log("Spacebar pressed", loggedInUser);
                if (loggedInUser == "STUDENT") {
                    const isDisabledButton =
                        event.target === document.getElementById('exitButton') ||
                        event.target === document.getElementById('previewButton') ||
                        event.target === document.getElementById('nextButton');

                    // Prevent the default behavior only for the specified buttons
                    if (isDisabledButton) {
                        event.preventDefault();
                    }
                } else {
                    event.preventDefault();
                }
            }
        });


        fetchDataUsingQuizId(data1);
        var elements = document.getElementsByClassName("navbar_nav__rvSlM");
        for (let i = 0; i < elements.length; i++) {
            // console.log(elements[i].textContent);
            elements[i].style.display = 'none';
        }
        var elements1 = document.getElementsByClassName("mainContainer_header__JNdBp")
        for (let i = 0; i < elements1.length; i++) {
            // console.log(elements1[i].textContent);
            elements1[i].style.visibility = 'hidden';
        }
        // console.log("css apply")
        const handleBeforeUnload = (event) => {
            // console.log("close tab", event.type)//for reload and close tab


            if (event.type === 'beforeunload') {

                const message = "Are you sure you want to leave? Your progress may be lost.";
                event.returnValue = message; // Standard for most browsers
                return message; // For some older browsers
            } else {
                // console.log("else close tab")
            }
        };

        const handlePopstate = (event) => {
            // console.log("handlepopstate", event.type, window.location.href)
            // Prevent the default behavior of navigating back
            window.history.pushState(null, document.title, window.location.href);
        };

        const handleUnload = (event) => {
            // console.log("handleunload", event.type)
            const message = "Are you sure you want to close the tab?";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };
        const handleContextMenu = (event) => {
            // Prevent the default right-click behavior
            // console.log("right click prevent")
            if (loggedInUser == "STUDENT") {
                event.preventDefault();
            }
        };
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('copy', handleContextMenu);
        window.addEventListener('beforeunload', handleBeforeUnload);
        // window.addEventListener('popstate', handlePopstate);
        window.addEventListener('unload', handleUnload);

        const handleBackButton = () => {
            // console.log("handleBackButton called")
        }

        // Prevent going back when leaving the page
        const handleUnload1 = () => {
            // console.log("prevent back load")
            return null;
        };

        // Attach the event listener for preventing back navigation
        window.addEventListener('unload', handleUnload);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('unload', handleUnload);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            // window.removeEventListener('popstate', handlePopstate);
            window.removeEventListener('unload', handleUnload1);
            // window.removeEventListener('popstate', handlePopstate);
            document.removeEventListener('contextmenu', handleContextMenu);
        };




        // const handleBeforeUnload = (event) => {
        //     if (event.type === 'beforeunload' && sourceMenu !== 'assignQuiz') {
        //         const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
        //         event.returnValue = confirmationMessage;
        //         return confirmationMessage;
        //     }
        // };
        // window.addEventListener('beforeunload', handleBeforeUnload);
        // return () => {
        //     window.removeEventListener('beforeunload', handleBeforeUnload);
        // };

    }, []);

    const response = async () => {
        // console.log("22222222222222222222222222222222222")
        const promises = questionIds.map((questionId) => {
            // console.log("questionId", questionId);
            return axios.get(
                `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${questionId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        });

        const responses = await Promise.all(promises);
        const questionsids = responses.map((v, i) => {
            return {
                subQuestions: v.data.data.hasSubQuestions,
                Id: v.data.data.id,
            };
        });

        for (let element of questionsids) {
            if (element.subQuestions == true) {
                element["subQuestions"] = await subConceptsfetch(element?.Id);
            } else {
                element["subQuestions"] = [];
            }
        }

        const questionss = responses?.map((response) => response.data.data);

        let questionandSubquestions = [];
        questionss.forEach((v, i) => {
            questionsids.forEach((value, id) => {
                if (v.id == value.Id) {
                    questionandSubquestions.push({ ...v, ...value });
                }
            });
        });
        setQuestions(questionandSubquestions);
    };
    useEffect(() => {
        // console.log("useeffect2")
        var elements = document.getElementsByClassName("navbar_nav__rvSlM");
        for (let i = 0; i < elements.length; i++) {
            // console.log(elements[i].textContent);
            elements[i].style.display = 'none';
        }
        var elements1 = document.getElementsByClassName("mainContainer_header__JNdBp")
        for (let i = 0; i < elements1.length; i++) {
            // console.log(elements1[i].textContent);
            elements1[i].style.visibility = 'hidden';
        }
        var elements2 = document.getElementsByClassName("mainContainer_main_container__hyS8u")
        for (let i = 0; i < elements2.length; i++) {
            // console.log(elements2[i].textContent);
            elements2[i].style.backgroundColor = '#f8f4fc';
        }
        response();
    }, [questionIds]);

    const subConceptsfetch = async (Id) => {
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${Id}/sub-questions?showCorrectAnswers=false`;
        const response = await axios.get(URL, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        return response?.data?.data;
    };

    const nextQuestionHandler = () => {
        if (currentQuestion + 1 < questionIds?.length) {
            setCurrentQuestion(currentQuestion + 1);
            // fetchDataUsingQuestionId();
        }
    };

    const preQuestionHandler = () => {
        if (currentQuestion >= 1) {
            setCurrentQuestion(currentQuestion - 1);
            // fetchDataUsingQuestionId();

            // setMatchData([]);
        }
    };
    const currentQuestionHandler = (i) => {
        // console.log("currentQuestionHandler")
        setCurrentQuestion(i);
    };

    return (
        <div>
            <Box
                sx={style}
                style={{ width: "100%", overflowY: "auto", backgroundColor: '#f8f4fc', width: '100vw', height: '100vh', backgroundColor: "#f8f4fc", marginBottom: "-20px" }}
                className="scrollable-section">
                {questions &&
                    questions[currentQuestion]?.questionTypeCode !== "MATCH" ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            padding: "40px",
                            // borderRadius: "10px",
                        }}
                    >
                        <div
                            style={{
                                width: "45%",
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "0.7rem",
                                overflowY: "auto",
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 400,
                                    color: "rgba(5,175,116,0.89)",
                                    fontSize: 16,
                                }}
                            >
                                {data?.name}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "10px",
                                    fontSize: 13,
                                    overflow: "auto",
                                }}
                            >
                                <div style={{ fontWeight: 400 }}>{` ${questions && questions[currentQuestion]?.questionType
                                    }`}</div>
                                <div className="Para_div_1">
                                    <h4>Question :-</h4>
                                    <p
                                        className="para"
                                        dangerouslySetInnerHTML={{
                                            __html: questions[currentQuestion]?.question,
                                        }}
                                    ></p>
                                </div>
                                <div className="Para_div_1">
                                    {questions && questions[currentQuestion]?.explanation && (
                                        <>
                                            <h4>Explanation :-</h4>
                                            <p
                                                className="Para"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        questions &&
                                                        questions[currentQuestion]?.explanation,
                                                }}
                                            ></p>
                                        </>
                                    )}
                                </div>
                                {questions && questions[currentQuestion]?.imageUrl && (
                                    <div className="Para_div_1">
                                        <h4>Uploaded Image</h4>
                                        <img
                                            src={questions && questions[currentQuestion]?.imageUrl}
                                            width="50%"
                                            height="240px"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                width: "55%",
                                padding: "0px 20px",
                                minHeight: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                overflowY: "auto",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "1rem",
                                }}
                            >
                                <LinearProgressWithLabel
                                    value={currentQuestion + 1}
                                    name={"Quiz"}
                                    totalQuestions={questions?.length}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "0.5rem",
                                        // overflow: "auto",
                                        height: "auto",
                                        padding: "10px",
                                    }}
                                >
                                    <div id="Anser_screen"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            // rowGap: "0.1rem",
                                            // border: "1px solid black",
                                            paddingBottom: "10px"
                                        }}
                                    >
                                        {questions &&
                                            !questions[currentQuestion]?.hasSubQuestions && (
                                                <div style={{ color: "black" }}>Answer</div>
                                            )}
                                        <FormControl
                                            sx={{ paddingLeft: "10px" }}
                                            key={questions && !questions[currentQuestion]?.id}
                                        >
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    rowGap: "0.1rem",
                                                }}
                                                // onChange={handleChange}
                                                defaultValue="female"
                                            >
                                                {questions &&
                                                    !questions[currentQuestion]?.hasSubQuestions
                                                    ? questions[currentQuestion]?.questionTypeCode ===
                                                        "MSQ"
                                                        ? questions &&
                                                        questions[currentQuestion]?.options[0].map(
                                                            (value, index, array) => {
                                                                //console.log("item", array);

                                                                return (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        mg={12}
                                                                        lg={12}
                                                                        style={{
                                                                            display: "flex",
                                                                        }}
                                                                    >

                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "auto",
                                                                                border: "1px solid #dbdee6",
                                                                                fontSize: "13px",
                                                                                color: "#1b1b1b",
                                                                                opacity: "0.9",
                                                                                marginTop: "5px",
                                                                                lineHeight: "1",
                                                                                padding: "10px",
                                                                                borderRadius: "5px",
                                                                                position: "relative",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Checkbox
                                                                                id="fullWidth"
                                                                                sx={{
                                                                                    color: "#001AF3",
                                                                                    position: "absolute",
                                                                                    top: "50%", // Adjust the top position to center the checkbox
                                                                                    transform: "translateY(-50%)", // Center the checkbox vertically
                                                                                    left: "5px",
                                                                                    "&.Mui-checked": {
                                                                                        color: "rgba(0, 0, 0, 0.26)",
                                                                                    },
                                                                                }}
                                                                                disabled={!value?.correctAnswer}
                                                                                checked={value?.correctAnswer}
                                                                            />

                                                                            <div
                                                                                style={{
                                                                                    marginLeft: "40px", // Adjust the spacing between checkbox and text
                                                                                    overflow: "hidden", // Hide overflow if the text is too long
                                                                                }}
                                                                            >
                                                                                {value.answerKey}
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                );
                                                            }
                                                        )
                                                        : questions &&
                                                            questions[currentQuestion]?.questionTypeCode ===
                                                            "MCQ"
                                                            ? questions &&
                                                            questions[currentQuestion]?.options[0].map(
                                                                (value, index, array) => {
                                                                    return (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            mg={12}
                                                                            lg={12}
                                                                            style={{ display: "flex" }}
                                                                        >

                                                                            <div
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "auto",
                                                                                    border: "1px solid #dbdee6",
                                                                                    fontSize: "13px",
                                                                                    color: "#1b1b1b",
                                                                                    opacity: "0.9",
                                                                                    marginTop: "10px",
                                                                                    lineHeight: "1",
                                                                                    padding: "10px",
                                                                                    borderRadius: "5px",
                                                                                    position: "relative",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <CustomRadio
                                                                                    id="fullWidth"
                                                                                    sx={{
                                                                                        color: "white",
                                                                                        position: "absolute",
                                                                                        top: "50%", // Center the checkbox vertically
                                                                                        transform: "translateY(-50%)", // Center the checkbox vertically
                                                                                        left: "10px",
                                                                                        "&.Mui-checked": {
                                                                                            color: "rgba(0, 0, 0, 0.26)",
                                                                                        },
                                                                                    }}
                                                                                    disabled={!value?.correctAnswer}
                                                                                    checked={value?.correctAnswer}
                                                                                />
                                                                                <div
                                                                                    style={{
                                                                                        marginLeft: "40px", // Adjust the spacing between checkbox and text
                                                                                        overflow: "hidden", // Hide overflow if the text is too long
                                                                                    }}
                                                                                >
                                                                                    {value?.answerKey}
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    );
                                                                }
                                                            )
                                                            : questions &&
                                                                questions[currentQuestion]?.questionTypeCode ===
                                                                "FILL"
                                                                ? questions &&
                                                                questions[currentQuestion]?.options[0]?.map(
                                                                    (v, index) => {
                                                                        const findIndex =
                                                                            answerByOrder &&
                                                                            answerByOrder?.findIndex(
                                                                                (item) => item === v?.order
                                                                            );
                                                                        return (
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                mg={12}
                                                                                lg={12}
                                                                                style={{ display: "flex" }}
                                                                            >


                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "auto",
                                                                                        border: "1px solid #dbdee6",
                                                                                        fontSize: "13px",
                                                                                        color: "#1b1b1b",
                                                                                        opacity: "0.9",
                                                                                        marginTop: "10px",
                                                                                        lineHeight: "1",
                                                                                        padding: "10px",
                                                                                        borderRadius: "5px",
                                                                                        position: "relative",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            marginLeft: "30px", // Adjust the spacing between checkbox and text
                                                                                            overflow: "hidden", // Hide overflow if the text is too long
                                                                                        }}
                                                                                    >
                                                                                        {v?.answerKey}
                                                                                    </div>

                                                                                </div>
                                                                            </Grid>
                                                                        );
                                                                    }
                                                                )
                                                                : " "
                                                    : questions &&
                                                    questions[currentQuestion]?.subQuestions?.map(
                                                        (subItem, index) => {
                                                            return (
                                                                <>
                                                                    {/* To display uploaded sub question image and question*/}
                                                                    {subItem.imageUrl !== null ? (
                                                                        <>
                                                                            <Grid container>

                                                                                <div className="Para_div_1">
                                                                                    <p
                                                                                        className="para"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: `Q${index + 1})${subItem.question
                                                                                                }`,
                                                                                        }}
                                                                                    ></p>
                                                                                </div>

                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    md={12}
                                                                                    lg={12}
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        justifyContent: "left",
                                                                                        flexWrap: "wrap",
                                                                                    }}
                                                                                >
                                                                                    {subItem &&
                                                                                        subItem?.explanation && (
                                                                                            <>
                                                                                                <Typography
                                                                                                    id="modal-modal-title"
                                                                                                    variant="h6"
                                                                                                    component="h2"
                                                                                                    sx={{
                                                                                                        color: "#1B1B1B",
                                                                                                        font: "normal normal 600 16px/25px Poppins",
                                                                                                    }}
                                                                                                >
                                                                                                    Explanation:-
                                                                                                </Typography>
                                                                                                <Typography
                                                                                                    id="modal-modal-title"
                                                                                                    sx={{
                                                                                                        color: "#1B1B1B",
                                                                                                        font: "normal normal 400 15px/25px Poppins",
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            subItem.explanation,
                                                                                                    }}
                                                                                                ></Typography>
                                                                                            </>
                                                                                        )}
                                                                                </Grid>
                                                                                <div className="Para_div_1">
                                                                                    <h4>Uploaded Image</h4>
                                                                                    <img
                                                                                        src={subItem.imageUrl}
                                                                                        width="50%"
                                                                                        height="240px"
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                md={12}
                                                                                lg={12}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "left",
                                                                                    flexWrap: "wrap",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    id="modal-modal-title"
                                                                                    sx={{
                                                                                        color: "#1B1B1B",
                                                                                        font: "normal normal 400 15px/25px Poppins",
                                                                                        textAlign: "left",
                                                                                        display: "flex",
                                                                                        alignItems: "flex-start", // Align to the top
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "20px"
                                                                                    }}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: `<span style="display: block;font-weight: bold;">Q${index + 1}.</span>${subItem.question}`,
                                                                                    }}
                                                                                ></Typography>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                md={12}
                                                                                lg={12}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "left",
                                                                                    flexWrap: "wrap",
                                                                                }}
                                                                            >
                                                                                {subItem && subItem?.explanation && (
                                                                                    <>
                                                                                        <Typography
                                                                                            id="modal-modal-title"
                                                                                            variant="h6"
                                                                                            component="h2"
                                                                                            sx={{
                                                                                                color: "#1B1B1B",
                                                                                                font: "normal normal 600 14px/25px Poppins",
                                                                                            }}
                                                                                        >
                                                                                            Explanation:-
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            id="modal-modal-title"
                                                                                            sx={{
                                                                                                color: "#1B1B1B",
                                                                                                font: "normal normal 400 13px/25px Poppins",
                                                                                                textAlign: "left",
                                                                                            }}
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: subItem.explanation,
                                                                                            }}
                                                                                        ></Typography>
                                                                                    </>
                                                                                )}
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                    <Typography
                                                                        id="modal-modal-title"
                                                                        variant="h6"
                                                                        component="h2"
                                                                        sx={{
                                                                            color: "#1B1B1B",
                                                                            font: "normal normal 600 13px/25px Poppins",
                                                                        }}
                                                                    >
                                                                        Answer
                                                                    </Typography>
                                                                    {/* To display answer content fields */}
                                                                    <Grid container spacing={4} sx={{ p: 2 }}>
                                                                        {subItem.options[0].length > 0 &&
                                                                            subItem.options[0].map((value, ind) => {
                                                                                return subItem.questionTypeCode ===
                                                                                    "FILL" ? (
                                                                                    <Grid item xs={12} mg={12} lg={12} style={{ marginBottom: "-10px" }}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                    "space-between",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                id="modal-modal-description"
                                                                                                sx={{
                                                                                                    mt: 2,
                                                                                                    mr: 2,
                                                                                                    color: "#000000",
                                                                                                    font: "normal normal normal 15px/30px Poppins",
                                                                                                }}
                                                                                            >
                                                                                                {String.fromCharCode(
                                                                                                    ind + 65
                                                                                                )}
                                                                                            </Typography>
                                                                                            <CustomTextField
                                                                                                id="fullWidth"
                                                                                                disabled
                                                                                                value={value.answerKey[0]}
                                                                                                inputProps={{
                                                                                                    style: {
                                                                                                        minHeight: "15px", // Set a minimum height
                                                                                                        maxHeight: "50px", // Set a maximum height if needed
                                                                                                        fontSize: "15px",
                                                                                                        // color:"#000000",
                                                                                                        // fontWeight:"bold"
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                ) : subItem.questionTypeCode ===
                                                                                    "TEXT" ? (
                                                                                    <Grid item xs={12} mg={12} lg={12}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent:
                                                                                                    "space-between",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                id="modal-modal-description"
                                                                                                sx={{
                                                                                                    mt: 2,
                                                                                                    mr: 2,
                                                                                                    color: "#000000",
                                                                                                    font: "normal normal normal 15px/30px Poppins",
                                                                                                }}
                                                                                            >
                                                                                                {String.fromCharCode(
                                                                                                    ind + 65
                                                                                                )}
                                                                                            </Typography>
                                                                                            <CustomTextField
                                                                                                id="fullWidth"
                                                                                                disabled
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                ) : subItem.questionTypeCode ===
                                                                                    "MCQ" ? (
                                                                                    <Grid item xs={12} md={12} lg={12}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center",
                                                                                                marginBottom: "-25px"
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                id="modal-modal-description"
                                                                                                sx={{
                                                                                                    mt: 2,
                                                                                                    mr: 2,
                                                                                                    color: "#000000",
                                                                                                    font: "normal normal normal 15px/30px Poppins",
                                                                                                    display: "flex",
                                                                                                    alignItems: "center",
                                                                                                }}
                                                                                            >
                                                                                                {String.fromCharCode(ind + 65)}
                                                                                            </Typography>
                                                                                            <div
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    minHeight: "40px", // Set a minimum height
                                                                                                    border: "1px solid #dbdee6",
                                                                                                    fontSize: "13px",
                                                                                                    color: "#1b1b1b",
                                                                                                    opacity: "0.9",
                                                                                                    lineHeight: "1",
                                                                                                    padding: "2px",
                                                                                                    borderRadius: "5px",
                                                                                                    overflow: "auto", // Use "auto" instead of "scroll" for a more natural behavior
                                                                                                }}
                                                                                            >
                                                                                                <CustomRadio
                                                                                                    id="fullWidth"
                                                                                                    sx={{
                                                                                                        color: "white",
                                                                                                        "&.Mui-checked": {
                                                                                                            color: "rgba(0, 0, 0, 0.26)",
                                                                                                        },
                                                                                                    }}
                                                                                                    checked={value.correctAnswer}
                                                                                                />
                                                                                                {value.answerKey}
                                                                                            </div>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                ) : subItem.questionTypeCode ===
                                                                                    "MSQ" ? (
                                                                                    <Grid item xs={12} md={12} lg={12}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center", // Align items vertically
                                                                                                marginBottom: "-25px"
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                id="modal-modal-description"
                                                                                                sx={{
                                                                                                    mt: "auto", // Adjust to vertically center
                                                                                                    mr: 2,
                                                                                                    color: "#000000",
                                                                                                    font: "normal normal normal 15px/30px Poppins",
                                                                                                }}
                                                                                            >
                                                                                                {String.fromCharCode(ind + 65)}
                                                                                            </Typography>

                                                                                            <div
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    height: "auto",
                                                                                                    border: "1px solid #dbdee6",
                                                                                                    fontSize: "13px",
                                                                                                    color: "#1b1b1b",
                                                                                                    opacity: "0.9",
                                                                                                    marginTop: "5px",
                                                                                                    lineHeight: "1",
                                                                                                    padding: "8px",
                                                                                                    borderRadius: "5px",
                                                                                                    position: "relative",
                                                                                                    display: "flex",
                                                                                                    alignItems: "center",
                                                                                                }}
                                                                                            >
                                                                                                <Checkbox
                                                                                                    id="fullWidth"
                                                                                                    sx={{
                                                                                                        color: "#001AF3",
                                                                                                        position: "absolute",
                                                                                                        top: "50%", // Center the checkbox vertically
                                                                                                        transform: "translateY(-50%)",
                                                                                                        left: "5px",
                                                                                                        "&.Mui-checked": {
                                                                                                            color: "rgba(0, 0, 0, 0.26)",
                                                                                                        },
                                                                                                    }}
                                                                                                    checked={value.correctAnswer}
                                                                                                />
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginLeft: "35px", // Adjust the spacing between checkbox and text
                                                                                                        overflow: "hidden", // Hide overflow if the text is too long
                                                                                                    }}
                                                                                                >
                                                                                                    {value.answerKey}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                ) : (
                                                                                    <>
                                                                                        <p>There is no Sub Question</p>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                    </Grid>
                                                                </>
                                                            );
                                                        }
                                                    )}
                                            </RadioGroup>
                                        </FormControl>

                                    </div>
                                </div>
                            </div>
                            {/* <div style={{
                            // padding: "10px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            columnGap: "1rem",
                            width: "100%",
                            paddingRight: "50px"

                        }}>
                            <ExitButton
                                onClick={() => {
                                    setCurrentQuestion(0);
                                    navigate(-1);
                                    sessionStorage.removeItem('selectedquestionModal');
                                }}
                            >
                                Exit3
                            </ExitButton>
                            <PreviewButton
                                onClick={preQuestionHandler}
                                disabled={currentQuestion + 1 === 1}
                            >
                                <KeyboardArrowLeftIcon style={{ fontSize: "15px" }} />
                                Previous Question
                            </PreviewButton>

                            <NextButton
                                onClick={nextQuestionHandler}
                                disabled={currentQuestion + 1 === data?.quizQuestions?.length}
                            >
                                Next Question
                                <KeyboardArrowRightIcon style={{ fontSize: "15px" }} />
                            </NextButton>
                        </div> */}

                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: "15px",
                            borderRadius: "10px",
                            rowGap: "10px",
                            // height: "600px",
                            height: "auto",

                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "1rem",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 400,
                                    color: "rgba(5,175,116,0.89)",
                                    fontSize: 16,
                                    width: "100%",
                                }}
                            >
                                {data?.name}
                            </div>
                            <LinearProgressWithLabel
                                value={currentQuestion + 1}
                                name={"Quiz"}
                                totalQuestions={questions?.length}
                            />
                        </div>
                        <Grid container p={2}>
                            <Grid
                                item
                                xs={6}
                                md={6}
                                lg={6}
                                sx={{ display: "flex", justifyContent: "left" }}
                            >
                                <Typography
                                    sx={{
                                        color: "#1B1B1B",
                                        fontFamily: "Poppins",
                                        fontWeight: "600",
                                        fontSize: "11px",
                                        lineHeight: "25px",
                                    }}
                                >
                                    Question
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={6}
                                lg={6}
                                sx={{ display: "flex", justifyContent: "left" }}
                            >
                                {questions && questions[currentQuestion]?.imageUrl && (
                                    <Typography
                                        sx={{
                                            color: "#1B1B1B",
                                            fontFamily: "Poppins",
                                            fontWeight: "400",
                                            fontSize: "11px",
                                            lineHeight: "25px",
                                        }}
                                    >
                                        Uploaded Image
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={6}
                                lg={6}
                                sx={{ display: "flex", justifyContent: "left" }}
                                style={{ height: "60px", overflow: "scroll" }}
                            >
                                <p
                                    className="para"
                                    dangerouslySetInnerHTML={{
                                        __html: questions && questions[currentQuestion]?.question,
                                    }}
                                ></p>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={6}
                                lg={6}
                                sx={{ display: "flex", justifyContent: "left" }}
                                style={{
                                    height: "60px",
                                    overflow: "scroll"
                                }}
                            >
                                <Typography>
                                    {questions && questions[currentQuestion]?.imageUrl && (
                                        <img
                                            src={questions && questions[currentQuestion]?.imageUrl}
                                            width="100%"
                                            height="auto"
                                        />
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <div
                            style={{
                                justifyContent: "space-between",
                                height: "auto",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    flexDirection: "row",
                                    columnGap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        flexDirection: "column",
                                        rowGap: "1rem",
                                        // height: "100%",
                                        // overflow: "auto",
                                    }}
                                >
                                    {/* <div className="MTF_DD_Box" style={{ height: "250px" }}> previous
                                 */}
                                    <div className="MTF_DD_Box" style={{}}>

                                        <div className="MTF_DD_Div">
                                            <div className="MTF_DD_Qstn_And_Field_div">
                                                {/*------------------| Qstn And Field 1 |-----------------*/}
                                                {questions &&
                                                    questions[currentQuestion]?.options[0]?.length > 0 &&
                                                    questions[currentQuestion]?.options[0].map((v) => {
                                                        return (
                                                            <div className="MTF_DD_Fields_1">
                                                                <>
                                                                    <div className="MTF_DD_Qstn_Field_1_B1">
                                                                        <p className="para">
                                                                            {v?.question.length > 100
                                                                                ? v.question.slice(0, 40)
                                                                                : v.question}
                                                                            {v.question.length > 100 && (
                                                                                <span style={{ color: "orange", fontSize: "small" }} onClick={() => {
                                                                                    setViewModal(true);
                                                                                    setQuestion(v.question);
                                                                                }}>...seeMore</span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="MTF_DD_Qstn_Field_1_B2">
                                                                        <p className="para">
                                                                            {v.answers.length > 0 && v?.answers.map((v, i) => {
                                                                                return (
                                                                                    <span>{v}</span>
                                                                                )
                                                                            })}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                            {/*------------------| MTF DD Ans IMages Div |-----------------*/}
                                            <div>
                                                <div className="MTF_DD_Ans">
                                                    {questions &&
                                                        questions[currentQuestion]?.options[0]?.length >
                                                        0 &&
                                                        questions[currentQuestion]?.options[0].map((v) => {
                                                            return (
                                                                <div className="MTF_DD_Ans_Field_1">
                                                                    <p>
                                                                        {v?.jumbledAnswer.length > 100
                                                                            ? v.jumbledAnswer.slice(0, 35)
                                                                            : v.jumbledAnswer}
                                                                        {v.jumbledAnswer.length > 100 && (
                                                                            <span style={{ color: "orange", fontSize: "small" }} onClick={() => {
                                                                                setViewModal(true);
                                                                                setQuestion(v.jumbledAnswer);
                                                                            }}>...seeMore</span>
                                                                        )}</p>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* second exit btn only for drag and drop */}
                            {/* <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "100%",
                                flexDirection: "row",
                                columnGap: "1rem",
                            }}
                        >
                            <ExitButton
                                onClick={() => {
                                    // close();
                                    setCurrentQuestion(0);
                                    // navigate("assign quiz");
                                    navigate(-1);
                                    sessionStorage.removeItem('selectedquestionModal');
                                }}
                            >
                                Exit2
                            </ExitButton>
                            <PreviewButton
                                onClick={preQuestionHandler}
                                disabled={currentQuestion + 1 === 1}
                            >
                                <KeyboardArrowLeftIcon style={{ fontSize: "25px" }} />
                                Previous Question
                            </PreviewButton>

                            <NextButton
                                onClick={nextQuestionHandler}
                                disabled={currentQuestion + 1 === data?.quizQuestions?.length}
                            >
                                Next Question
                                <KeyboardArrowRightIcon style={{ fontSize: "25px" }} />
                            </NextButton>
                        </div> */}
                        </div>

                    </div>

                )}
                {/* which i prefferred */}
                {
                    // questions[currentQuestion]?.questionTypeCode == "MATCH" &&
                    <div style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        columnGap: "1rem",
                        width: "100%",
                        paddingRight: "50px"

                    }}>
                        <ExitButton
                            onClick={() => {
                                //console.log("exit button clicked")
                                setCurrentQuestion(0);
                                // navigate(-1);
                                sessionStorage.removeItem('selectedquestionModal');
                                // setExitClicked(true);
                                // window.history.back();

                                var previousUrl = `../${sessionStorage.getItem("redirectUrl")}`;
                                var urlValue = sessionStorage.getItem("redirectUrl")
                                //console.log("previousUrl", previousUrl);
                                if (urlValue) {
                                    navigate(previousUrl);
                                } else {
                                    window.history.back();
                                }
                                var elements = document.getElementsByClassName("navbar_nav__rvSlM");
                                for (let i = 0; i < elements.length; i++) {
                                    //console.log(elements[i].textContent);
                                    elements[i].style.display = 'block';
                                }
                                var elements1 = document.getElementsByClassName("mainContainer_header__JNdBp")
                                for (let i = 0; i < elements1.length; i++) {
                                    //console.log(elements1[i].textContent);
                                    elements1[i].style.visibility = 'visible';
                                }


                            }}
                        >
                            Exit  {/*  1Outer */}
                        </ExitButton>
                        <PreviewButton
                            onClick={preQuestionHandler}
                            disabled={currentQuestion + 1 === 1}
                        >
                            <KeyboardArrowLeftIcon style={{ fontSize: "20px" }} />
                            Previous Question
                        </PreviewButton>

                        <NextButton
                            onClick={nextQuestionHandler}
                            disabled={currentQuestion + 1 === data?.quizQuestions?.length}
                        >
                            Next Question
                            <KeyboardArrowRightIcon style={{ fontSize: "20px" }} />
                        </NextButton>
                    </div>
                }


                <hr></hr>


                <div
                    style={{
                        minHeight: "80px",
                        // borderRadius: "10px",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "1rem",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            fontSize: 10,
                            fontWeight: 400,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            rowGap: "5px",
                            width: "10%",
                        }}
                    >
                        <span>Total</span>
                        <span>Questions</span>
                        <span>{data.totalQuestions}</span>
                    </div>
                    <div
                        style={{
                            padding: "10px",
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "1rem",
                            width: "100%",
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {questions?.map((item, index) => {
                                    return (
                                        <Grid item xs={0.6} md={0.6} key={index}>
                                            <Item
                                                sx={{
                                                    background:
                                                        currentQuestion === index ||
                                                            currentQuestion >= index
                                                            ? ""
                                                            : "#928d8d",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => currentQuestionHandler(index)}
                                            >
                                                {index + 1}
                                            </Item>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <QuestionViewModal open={isViewModal} deleteName={queston} close={() => { setViewModal(false) }} />
                        </Box>

                    </div>
                </div>
            </Box>

            {/* </Modal> */}

        </div>
    );
};
export default AssignQuizPagePreView;