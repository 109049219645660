function cdn_response_validator(response) {

    var teacherUploadContents = [];
    var documentTypesStudentStudy = [];
    var studentRevisionUploadContent = [];

    if (response.chapter.teacherUploadContents != null) {
        teacherUploadContents =
            response.chapter.teacherUploadContents.reduce((acc, content) => {
                const documentType = content.documentCategories.documentType;
                if (!acc[documentType]) {
                    acc[documentType] = {
                        documentTypeId: content.documentCategories.documentTypeId,
                        documentType: documentType,
                        documentUrl: content.documentCategories.documentUrl,
                        documentTypeWithCount: "Learning Resource Key - 2",
                        uploadContents: []
                    };
                }
                acc[documentType].uploadContents.push({
                    id: content.id,
                    contentWithIndux: "Sample.pdf -",
                    contentUrl: content.contentUrl,
                    reviewStatus: content.reviewStatus,
                    uploadTime: content.uploadTime,
                    active: content.active,
                    actualDuration: content.actualDuration,
                    pasuedOn: content.pasuedOn,
                    remainingTime: content.remainingTime,
                    watchingCompleted: content.watchingCompleted,
                    lastWatchedOn: content.lastWatchedOn,
                    approvedBy: content.approvedBy,
                    approvedAt: content.approvedAt,
                    rejectedBy: content.rejectedBy,
                    rejectedAt: content.rejectedAt,
                    reason: content.reason,
                    teacherContentFlag: true
                });
                return acc;
            }, {});
    }

    if (response.chapter.studentStudyUploadContents != null) {
        documentTypesStudentStudy = response.chapter.studentStudyUploadContents.reduce((acc, content) => {
            const documentType = content.documentCategories.documentType;
            if (!acc[documentType]) {
                acc[documentType] = {
                    documentTypeId: content.documentCategories.documentTypeId,
                    documentType: documentType,
                    documentUrl: content.documentCategories.documentUrl,
                    documentTypeWithCount: "Learning Resource Key - 2",
                    uploadContents: []
                };
            }
            acc[documentType].uploadContents.push({
                id: content.id,
                contentWithIndux: "Sample.pdf -",
                contentUrl: content.contentUrl,
                reviewStatus: content.reviewStatus,
                uploadTime: content.uploadTime,
                active: content.active,
                actualDuration: content.actualDuration,
                pasuedOn: content.pasuedOn,
                remainingTime: content.remainingTime,
                watchingCompleted: content.watchingCompleted,
                lastWatchedOn: content.lastWatchedOn,
                approvedBy: content.approvedBy,
                approvedAt: content.approvedAt,
                rejectedBy: content.rejectedBy,
                rejectedAt: content.rejectedAt,
                reason: content.reason,
                teacherContentFlag: true
            });
            return acc;
        }, {});
    }


    if (response.chapter.studentRevisionUploadContents != null) {
        studentRevisionUploadContent = response.chapter.studentRevisionUploadContents.reduce((acc, content) => {
            const documentType = content.documentCategories.documentType;
            if (!acc[documentType]) {
                acc[documentType] = {
                    documentTypeId: content.documentCategories.documentTypeId,
                    documentType: documentType,
                    documentUrl: content.documentCategories.documentUrl,
                    documentTypeWithCount: "Learning Resource Key - 2",
                    uploadContents: []
                };
            }
            acc[documentType].uploadContents.push({
                id: content.id,
                contentWithIndux: "Sample.pdf -",
                contentUrl: content.contentUrl,
                reviewStatus: content.reviewStatus,
                uploadTime: content.uploadTime,
                active: content.active,
                actualDuration: content.actualDuration,
                pasuedOn: content.pasuedOn,
                remainingTime: content.remainingTime,
                watchingCompleted: content.watchingCompleted,
                lastWatchedOn: content.lastWatchedOn,
                approvedBy: content.approvedBy,
                approvedAt: content.approvedAt,
                rejectedBy: content.rejectedBy,
                rejectedAt: content.rejectedAt,
                reason: content.reason,
                teacherContentFlag: true
            });
            return acc;
        }, {});
    }

    // Step 3: Structure the extracted information to match the format of data_2
    const structuredData = {
        data: {
            chapterId: response.chapter.chapterId,
            documentTypesTeacherContent: Object.values(teacherUploadContents).concat(Object.values(documentTypesStudentStudy)),
            documentTypesStudentRevision: Object.values(studentRevisionUploadContent),
            // documentTypesTeacherContent:Object.values(teacherUploadContents) ,
            // documentTypesStudentStudy: Object.values(documentTypesStudentStudy),
            // documentTypesStudentRevision: Object.values(studentRevisionUploadContent) ,   
            // documentTypesTeacherContent: teacherUploadContents.length > 0 ? Object.values(teacherUploadContents) : [],
            // documentTypesStudentStudy: documentTypesStudentStudy.length > 0 ? Object.values(documentTypesStudentStudy) : [],
            // documentTypesStudentRevision: studentRevisionUploadContent.length > 0 ? Object.values(studentRevisionUploadContent) : [],
        },
        message: "Teacher uploaded content fetched successfully",
        success: true,
        errorCode: null,
        path: null
    };


    return structuredData;


}

export default cdn_response_validator