import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import TrainingCategoryStyles from "./../css/TrainingCategory.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import Pagination1 from "./pagination1";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import training_category_controller_getall from "../API_CONTROLLER/training-category-controller/training_category_controller_getall";
import training_subcategory_controller_getall from "../API_CONTROLLER/training-category-controller/training_subcategory_controller_getall";
import training_subtraining_table_get from "../API_CONTROLLER/training-category-controller/training_subtraining_table_get";
import training_subtraining_table_post from "../API_CONTROLLER/training-category-controller/training_subtraining_table_post";
import trainingCat_get_modifieddate from "../API_CONTROLLER/training-category-controller/training_category_get_modifiedDate";
import sortIcon from "../../../src/pages/img/sort_1.png";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";

import { SearchOutlined } from "@mui/icons-material";

const TrainingCategory = () => {
  const [modifiedDate, setModifiedDate] = useState({});

  const [traininglist, setTraininglist] = useState({});
  const [subtraininglist, setSubtraininglist] = useState({});
  const [trainingtableData, setTrainingtableData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");
  const [filterValue, setFilterValue] = useState();
  const [categoryInputs, setCategoryInputs] = useState({
    category: "",
    subCategories: [""],
  });
  useEffect(() => {
    training_subtraining_table_get(setTrainingtableData ,
      rowsInput ,
      page , 
      setPageDetail ,
      search,sortOrder,sortBy);
  }, [sortOrder, sortBy]);
  const navigate = useNavigate();

  const handleCreateTrainingCategory = () => {
    training_subtraining_table_post(categoryInputs);
  };

  const handleCategoryInputs = (e) => {
    e.preventDefault();
    setCategoryInputs({
      ...categoryInputs,
      [e.target.name]: e.target.value,
    });
  };

  const [search, setSearch] = useState({
    search: "",
  });
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10, //should not
  });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    training_subtraining_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search,sortOrder,sortBy
    );
  };

  useEffect(() => {
    if (search.search === "") {
      training_subtraining_table_get(
        setTrainingtableData,
        rowsInput,
        page,
        setPageDetail,
        search,sortOrder,sortBy
      );
    }
    return () => {};
  }, [rowsInput, search]);

  useEffect(() => {
    training_subtraining_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search
    );
    return () => {};
  }, [rowsInput, page]);

  useEffect(() => {
    training_category_controller_getall(setTraininglist);
    training_subcategory_controller_getall(setSubtraininglist);
    GetLastModifiedAt(setModifiedDate);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="az_home_navigation_section">
          <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}>
                <div style={{ display: "flex", alignItems: "flex-start"}}>
                    <p className="az_navigation_text"><span style={{position:"relative"}}>
                        <img style={{position: "absolute", top:"1px"}}
                          src={dashHome}
                          alt="no img"
                          width="18px"
                          height="18px"
                          
                        /> </span>
                        <span onClick={() => {
                          sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                          sessionStorage.removeItem("sideNavSubMenuNames");
                          navigate("/dashboard/template")
                        }} className="az_navigation_content" style={{marginLeft:"20px"}}>Home</span>
                    </p>
                </div>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p onClick={() => {
                navigate("/dashboard/upload content")
                sessionStorage.setItem("sideNavMainMenuNames", "Upload Content");
                sessionStorage.removeItem("sideNavSubMenuNames");
              }} className="az_navigation_text"><span>Content Admin</span></p>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p className="az_navigation_text"><span>Master</span></p>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p><span style={{color: "#ffa500"}}>Add category</span></p>
          </div>
          <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
        </div>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <a>Content Admin</a>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Master</a>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Add category</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update: {modifiedDate.data}
            </p>
          </div>
        </article>

        {/* search row start  */}
        <div
          className={TrainingCategoryStyles.flex}
          style={{ position: "absolute", right: "15px", marginTop: "50px" }}
        >
          <TextField
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                opacity: 1,
                font: "normal normal normal 13px/20px Poppins !important",
              },
            }}
            type={"text"}
            placeholder={"Search...."}
            name="search"
            onChange={handleSearch}
            Value={search.search}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    alt="no img"
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />

          <div style={{ marginLeft: "18px" }}>
            <Link
              to="/dashboard/CreateTrainingCategory"
              style={{ textDecoration: "none" }}
            >
              <button className={TrainingCategoryStyles.green_btn}>
                ADD TRAINING CATEGORY
              </button>
            </Link>
          </div>
        </div>
        {/* search row start  */}

        {/* table start  */}
        <div style={{ paddingRight: "33px" }}>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              marginTop: "126px",
              marginLeft: "15px",
            }}
          >
            <TableContainer
              sx={{
                overflow: "auto",
              }}
            >
              <Table stickyHeader={true} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell className={TrainingCategoryStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={TrainingCategoryStyles.table_head}
                    >
                      Category Name
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("categoryName");
                    }}
                    />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={TrainingCategoryStyles.table_head}
                    >
                      Sub-category Name
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("subCategoryName");
                    }}
                    />
                    </TableCell>

                    <TableCell
                      align="center"
                      className={TrainingCategoryStyles.table_head}
                    >
                      Status
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("status");
                    }}
                    />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ zIndex: "3" }}
                      className={TrainingCategoryStyles.table_head}
                    >
                      Operation
                      <img
                    style={{ verticalAlign: "middle", margin: "0 0 2px 1px" }}
                    className="cursor-pointer"
                    width="11"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      // await setSortBy("operation");
                    }}
                    />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainingtableData.length > 0 ? (
                    trainingtableData.map((elem, i) => {
                      return (
                        <TableRow>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                          >
                            {i + 1}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                          >
                            {elem.category}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {elem.subCategories &&
                              elem.subCategories.length > 0 ? (
                                elem?.subCategories?.map((data) => (
                                  <p>{data.subCategory},</p>
                                ))
                              ) : (
                                <p>-</p>
                              )}
                            </div>
                          </TableCell>

                          {/* active btn */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                            align="center"
                          >
                            {elem.active ? (
                              <button
                                className={TrainingCategoryStyles.green_btn}
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                className={TrainingCategoryStyles.red_btn}
                              >
                                PENDING
                              </button>
                            )}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            className={TrainingCategoryStyles.table_cell}
                            style={{ textAlign: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                onClick={(e) => {
                                  e.preventDefault();
                                  var trainingCatId = elem.id;
                                  navigate(
                                    "/dashboard/CreateTrainingCategory",
                                    { state: "edit" }
                                  );
                                  sessionStorage.setItem(
                                    "trainingCatId",
                                    trainingCatId
                                  );
                                }}
                                style={{
                                  color: "white",
                                  backgroundColor: "orange",
                                  height: "32px",
                                  width: "32px",
                                  borderRadius: "50%",
                                  margin: "auto 7px",
                                }}
                              ></i>
                              <div className={TrainingCategoryStyles.icon}>
                                <div className={TrainingCategoryStyles.tooltip}>
                                  <p style={{ fontSize: "13px" }}>Remove</p>
                                </div>
                                <span>
                                  <i
                                    className="fa-solid fa-trash-can"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // handleShareModal() ;
                                      navigate(
                                        "/dashboard/CreateTrainingCategory",
                                        { state: "delete" }
                                      );
                                      var trainingCatId = elem.id;
                                      sessionStorage.setItem(
                                        "trainingCatId",
                                        trainingCatId
                                      );
                                    }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <td
                      colSpan="10"
                      style={{ height: "25rem", textAlign: "center" }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* pagination container - Start */}
            <div
              className={TrainingCategoryStyles.pagination}
              style={{ width: "100%" }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={TrainingCategoryStyles.rows}>
                  <label htmlFor="rows">Rows per page</label>
                  <select
                  className="cursorPointer"
                    name="rows"
                    id="rows"
                    value={rowsInput.rows}
                    onChange={handlerRowsInputs}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </span>
              </form>
              <span>
                <Pagination1
                  pageDetail={pageDetail}
                  handleChange={handleChange}
                />
              </span>
            </div>
            {/* pagination container - End */}
          </Paper>
        </div>
        {/* table end  */}
      </div>
    </>
  );
};

export default TrainingCategory;
