
// import axios from "axios";

// export default async function updateGradeSectionMapping(payload, cb, errorMessageFunc) {
//   try {
//     console.log(payload);
//     let token = sessionStorage.getItem("token");
//     const resp = await axios.put(
//       `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section`,
//       payload,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     console.log("resp", resp);
//     console.log(resp.message);

//     // Additional logic after the initial API call
//     const paths = ["teacher/track/pre-class-activity", "student/quiz", "user/teachers", "content/quiz"];
//     const apiCalls = paths.map(path => updateAsperServices(payload, resp.data.data.sectionId, path));

//     try {
//       const results = await Promise.all(apiCalls);
//       console.log(results);
//       cb();
//     } catch (error) {
//       console.error(error.message);
//       errorMessageFunc();
//     }

//     // Execute the callback function
    
//   } catch (err) {
//     console.error("error", err);
//     errorMessageFunc();
//   }
// }

// const updateAsperServices = async (payload, newid, service) => {
//   let token = sessionStorage.getItem("token");
//   console.log(payload, newid, service);
//   try {
//     const res = await axios.put(
//       `${process.env.REACT_APP_SWAGGER_URL}v1/api/${service}/update-sectionId/${payload.sectionId}/${newid}`,
//       {
//       },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return res.data;
//   } catch (error) {
//     throw new Error(`Error updating: ${error.message}`);
//   }
// };





















import axios from "axios";

export default async function updateGradeSectionMapping(payload, cb, errorMessageFunc) {
  // console.log(payload);
  let token = sessionStorage.getItem("token");
  await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((resp) => {
      // console.log("resp", resp)
      // console.log(resp.message)
      cb();
    })
    .catch((err) => {
      // console.log("error");
      errorMessageFunc()
    })
}
