import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
//API Used
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";

//Css Pages
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import "../../css/Student/Student_Enrich_Quiz.css";

//Images Used
import dashHome from "../../../../src/img/dashHome.svg";
import quizLockIcon from "../../../../src/img/SaLock.svg";
import quizLockOpenIcon from "../../../../src/img/SaLockOpen.svg";
import quizInfoIcon from "../../../../src/img/quiz-info-3.svg";
import withTimeIcon from "../../../../src/img/withTime.png";
import withOutTimeIcon from "../../../../src/img/withoutTime.png";

// Mui
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Student_Enrich_Quiz_Attend from "./Student_Enrich_Quiz_Attend";
import Quiz_Common_component from "./Quiz_Common_component";
import ChapterQuizKeyModal from "./ChapterQuizKey/ChapterQuizKeyModal";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


import Student_Enrich_Quiz_Analysis_Modal from "./Student_Enrich_Quiz_Analysis_Modal";
import { Flex } from "antd";
import QuizNewComponent from "./QuizNewComponent";
import { fetch_Chapter_Quiz_Key_Api } from "./Apis/Chapter_Quiz_Key_Api";
import QuizNewResumeComponent from "./QuizNewResumeComponent";
import Student_Enrich_Quiz_Analysis_Modal2 from "./Student_Enrich_Quiz_Analysis_Modal2";
import QuizOptimizedComponent from "./QuizOptimizedComponent";
import QuizResumeOptimizedComponent from "./QuizResumeOptimizedComponent";
import mixpanel from "mixpanel-browser";
import MoodBadIcon from '@mui/icons-material/MoodBad';
import LogoutIcon from '@mui/icons-material/Logout';


function Student_Enrich_Quiz({
  closeModal,
  selectedChapterId,
  selectedCardName,
  selectedSubject,
  viewQuizData,
  setLoadQuizData,
  academicYearList,
  schoolId,
  branchId,
  selectedsubTopic,
  studentId,
  subDD,
  subTopicDd,
  fetchViewQuiz,
  loadQuizData,
  subjectList,
  setViewQuizData
}) {
  // console.log('enrichprops', selectedChapterId,
  //   selectedCardName,
  //   selectedSubject,
  //   viewQuizData,
  //   setLoadQuizData,
  //   academicYearList,
  //   schoolId,
  //   branchId,
  //   selectedsubTopic,
  //   studentId,
  //   subDD,
  //   subTopicDd,
  //   fetchViewQuiz,
  //   loadQuizData,
  //   subjectList,
  //   setViewQuizData)
  // console.log(" student_enrich_quiz selectedSubject", selectedSubject, "selectedsubTopic", selectedsubTopic, "subjectList", subjectList)
  sessionStorage.setItem('selectedSub', selectedSubject)
  sessionStorage.setItem('academicYear', academicYearList)
  var Vsubtopic = subjectList?.find((item) => item.subjectId === selectedSubject)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // console.log(selectedSubject ? !Vsubtopic.hideSubtopics : false)
  const navigate = useNavigate();
  const [modifiedDate, setModifiedDate] = useState({});
  const token = sessionStorage.getItem("token");
  const [internetTime, setInternetTime] = useState(new Date());
  const [showQuizAttend, setShowQuizAttend] = useState(false);
  const [quizData, setQuizData] = useState();
  const [quizExaminationId, setQuizExaminationId] = useState("");
  const [resumeBtnClicked, setResumeBtnClicked] = useState(false);
  const [showChapterQuizKey, setShowChapterQuizKey] = useState(false);

  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [quizListData, setQuizListData] = useState();
  const [quizListDataSample, setQuizListDataSample] = useState();
  const [vvviewQuizData, setvvviewQuizData] = useState(viewQuizData);
  const [InboxSubjectName, setInboxSubjectName] = useState(sessionStorage.getItem('InboxSubjectName1'))
  const [InboxSubtopicName, setInboxSubtopicName] = useState("")
  const [inboxChapterName, setinboxChapterName] = useState(sessionStorage.getItem('InboxChapterName'))


  // var InboxSubjectName = sessionStorage.getItem('InboxSubjectName1');
  // const inboxChapterName = sessionStorage.getItem('InboxChapterName');
  const inboxChapterId = sessionStorage.getItem('InboxChapter');
  const InboxSub = sessionStorage.getItem('InboxSubject1');
  // const [selectedChapterId1, setSelectedChapterId1] = useState(inboxChapterId?inboxChapterId:selectedChapterId);
  const user_role = localStorage.getItem('role');
  const user_id = localStorage.getItem('id');
  const userName = localStorage.getItem('userName')

  const [showSubTopic, setshowSubTopic] = useState(selectedSubject ? !Vsubtopic?.hideSubtopics : false);
  // const [isEnd, setIsEnd] = useState(false);
  const [withTimeBtnClicked, setWithTimeBtnClicked] = useState(false);
  const [withOutTimeBtnClicked, setWithOutTimeBtnClicked] = useState(false);
  const [practiceQuizReportData, setpracticeQuizReportData] = useState([]);


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [resumeOpen, setResumeOpen] = React.useState(false)
  const [quizChange, setQuizChange] = useState('')
  const handleResume = () => setResumeOpen(true)
  const handleResumeClose = () => setResumeOpen(false)
  const [newQuizData, setNewQuizData] = useState('')
  const [resumeQuestions, setResumeQuestions] = useState([])

  const [isExamOver, setIsExamOver] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);


  // Academic year api
  const callbackforafterquizexit = async () => {
    const studentId = sessionStorage.getItem("studentId");
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");

    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/listing?studentId=${studentId}&schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&chapterId=${selectedChapterId}&academicYearId=${academicYearList}&reviewStatus=APPROVED`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    var valll = res.data.data || [];
    sessionStorage.setItem("quiz_listings", JSON.stringify(valll));
    // alert("1111111111");
    setQuizListData(valll)
    return valll
  }
  const fetchViewQuiz2 = async () => {
    const studentId = sessionStorage.getItem("studentId");
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");

    const sessionchaptervalue = JSON.parse(sessionStorage.getItem("selectedChapterId"))


    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/listing?studentId=${studentId}&schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${InboxSub}&chapterId=${inboxChapterId}&academicYearId=${academicYearList}&reviewStatus=APPROVED`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    var valll = res.data.data || [];
    // console.log('quizzzzzzzlistt', valll);
    // unregister();
    sessionStorage.setItem("quiz_listings", JSON.stringify(valll));
    setQuizListData(valll)

  };

  const location = useLocation();
  // useEffect(() => {
  //   setTimeout(async () => {
  //     if (location?.state) {
  //       console.log("setPreSelectedData >>> ", location?.state, location);

  //       const res1 = await axios.get(
  //         `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       sessionStorage.setItem("academicYear1", res1?.data.data[0].id)
  //       sessionStorage.setItem("selectedSub1", location?.state?.subjectId)

  //       const studentId = sessionStorage.getItem("studentId");
  //       const branchId = location?.state?.branchId;
  //       const schoolId = location?.state?.schoolId;
  //       const boardId = location?.state?.bordId;
  //       const gradeId = location?.state?.gradeId;
  //       const sectionId = location?.state?.sectionId;
  //       const subjectId = location?.state?.subjectId;
  //       const chapterId = location?.state?.chapterId
  //       const academicYearList = res1?.data.data[0].id || sessionStorage.getItem("academicYear");
  //       var planSub = JSON.parse(sessionStorage.getItem("planSubjects")) || [];
  //       var planChap = JSON.parse(sessionStorage.getItem("planSubtopics")) || [];

  //       var subnam = planSub?.find((v) => v => v.subjectId === subjectId) || {};
  //       console.log("subnam", subnam)
  //       if (subnam) {
  //         setInboxSubjectName(subnam?.subject || "");
  //         var temp = subnam?.subTopics.find((v) => v.id === location?.state?.subTopicId);
  //         console.log("temp", temp)
  //         setInboxSubtopicName(temp?.subTopic || "");
  //         setshowSubTopic(temp?.subTopic ? true : false)
  //       }
  //       var chap = planChap?.find((v) => v => v.id === location?.state?.chapterId) || {};
  //       setinboxChapterName(chap?.chapter || "")
  //       console.log("big changes", planSub, subjectId, location?.state, InboxSubjectName)
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/listing?studentId=${studentId}&schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&chapterId=${chapterId}&academicYearId=${academicYearList}&reviewStatus=APPROVED`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       var valll = res.data.data || [];
  //       // unregister();
  //       sessionStorage.setItem("quiz_listings", JSON.stringify(valll));
  //       setQuizListData(valll)
  //     }

  //   }, 1500)
  // }, [location?.state]);



  useEffect(() => {
    // console.log("12/18", loadQuizData)

    if (inboxChapterId) {
      fetchViewQuiz2();
    } else {
      if (loadQuizData) {
        fetchViewQuiz();
      }
      // console.log("12/18", viewQuizData)
      setQuizListData(viewQuizData);
      // alert("22222222")
      // console.log("quiz data", viewQuizData)
      setQuizListDataSample(viewQuizData)
    }
  }, [loadQuizData, viewQuizData, inboxChapterId, InboxSub]);

  const handleAnalysisModalClose = () => {
    setOpenAnalysisModal(false);
  };

  sessionStorage.setItem("chapterQuizKeyBtn", false);
  // console.log("setting false")


  async function fetchInternetTime() {
    // try {
    //   const response = await fetch(
    //     // "https://api.timezonedb.com/v2.1/get-time-zone?key=LEPDE3WSUFYM&format=json&by=zone&zone=Asia/Kolkata"
    //     "https://worldtimeapi.org/api/timezone/Asia/Kolkata"
    //   );
    //   const data = await response.json();
    //   const currentTime = new Date(data.utc_datetime);
    //   setCurrentDate(currentTime);
    //   return currentTime;
    // } catch (error) {
    //   console.error(error);
    // }
  }

  useEffect(() => {
    // GetLastModifiedAt(setModifiedDate);
    var sessData = JSON.parse(sessionStorage.getItem("itemload"));

    // console.log(sessData)
    if (sessData) {
      // console.log("setting true", resumeBtnClicked)
      setShowQuizAttend(true);
      setResumeBtnClicked(true);
      setQuizData(sessData);
      // console.log("resume2check----", sessData)
      setQuizExaminationId(
        sessData?.examinationId
      );

      // setShowQuizAttend(false)
      setOpenAnalysisModal(false)
      // sessionStorage.setItem(
      //   "exIdTwo",
      //   item?.pqList[item?.pqList.length - 1]
      //     ?.examinationId
      // );
    }
    const intervalId = setInterval(async () => {
      const currentTime = await fetchInternetTime();
      setInternetTime(currentTime);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      sessionStorage.removeItem('InboxSubject1');
      sessionStorage.removeItem('InboxChapterName1');
      sessionStorage.removeItem('InboxChapter');
    }
  }, []);

  useEffect(() => {

    const fetchServerTime = async () => {
      const serverDateResponse = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/configurations/server-datetime`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      const serverTime = serverDateResponse?.data
      // console.log('server', serverTime);
      setCurrentDate(serverTime);
    }
    fetchServerTime();

  }, [])

  const [currentDate, setCurrentDate] = useState();
  // const [currentDate, setCurrentDate] = useState(internetTime);

  const check = quizListData?.map((itm) => {
    if (itm.quizTypeName === "Unit Quiz") {
      if (itm.examinationStatus === "END") {
        return true;
      } else {
        return false;
      }
    } else {
      return "practice";
    }
  });

  const filteredCheck = check?.filter((element) => element !== "practice");

  const handleBack = () => {
    setOpen(false)
  }

  const handleHide = () => {
    // Get elements by class name
    // const header = document.querySelector("#top-header");
    // const navbar = document.querySelector("#nav-bar-side");
    // console.log(header, navbar)
    // // Check if elements exist
    // if (header && navbar) {
    //   // Toggle a class to hide/show the elements
    //   header.classList.toggle("hidden");
    //   navbar.classList.toggle("hidden");
    // }
  };

  sessionStorage.setItem('quizData', JSON.stringify([quizData]))
  const handleTimedQuiz = () => {
    if (withTimeBtnClicked === true) {
      sessionStorage.setItem("withtime", true)
    } else if (withOutTimeBtnClicked) {
      sessionStorage.setItem("withtime", false)
    }
  }
  // const handleResume = ()=>{
  //   setResumeOpen(true)

  // }
  handleTimedQuiz()
  // console.log('quizdata quiz id',quizData.quizId)

  // sessionStorage.setItem('quizDataId',quizData.quizId)
  // console.log('quizdataaaa quiz id',newQuizData.quizId)
  // sessionStorage.setItem('quizId',newQuizData.quizId)
  //   const resumeAction  = () =>{
  //     console.log('clickkk',resumeBtnClicked);
  //   if (resumeBtnClicked) {
  //     const resumeDataFetch = async () => {
  //       const data = await fetch_Chapter_Quiz_Key_Api();
  //       // console.log(data, "resumeBtnClicked quiz component")
  //       // alert(JSON.stringify(data))
  //       var arr = data.data?.questionAnswerKeys.sort((a, b) => a.questionOrder - b.questionOrder);
  //       console.log("2vvvvvvvvvvvvvvvvvvvvvvvvvvv", data.data?.questionAnswerKeys, arr)
  //       data.data.questionAnswerKeys = arr
  //       // setQuestions(data.data.questionAnswerKeys);
  //       setResumeQuestions(data.data.questionAnswerKeys);
  //       sessionStorage.setItem('resumedata',data?.data?.questionAnswerKeys)
  //       // sessionStorage.setItem("shuffledQuiz", JSON.stringify(data.data.questionAnswerKeys));

  //     };
  //     resumeDataFetch();
  //   }

  // }
  // resumeAction()

  //   const CapturePracticeWithTime = (item) =>{
  //     console.log('wihtttt',item)
  // if(user_role === "STUDENT"){
  //     mixpanel.track('Practice_Quiz_WithTime',{
  //       "user_id":user_id,
  //       "quizId":item?.quizId,
  //       "quizName":item?.quizName,
  //       "quizType":item?.quizTypeName,
  //       "startDate":item?.startDate,
  //       "startTime":item?.startTime,
  //       "endDate":item?.endDate,
  //       "endTime":item?.endTime,
  //       "totalDuration":item?.totalDuration
  //     })
  //   }
  //   }

  const unitQuizStart = (item) => {
    // console.log('startttttt', item);

    mixpanel.track('Quiz_Instruction_Start', {
      "user_id": user_id,
      "username": userName,
      "quizId": item?.quizId,
      "quizName": item?.quizName,
      "startDate": item?.startDate,
      "startTime": item?.startTime,
      "endDate": item?.endDate,
      "endTime": item?.endTime,
      "totalDuration": item?.totalDuration,
      "releasedId": item?.releasedId,
      "examinationStatus": item?.examinationStatus
    })

  }

  const quizStartCapture = () => {
    // console.log('wewewew', quizData);
    mixpanel.track('Quiz_Start', {
      "user_id": user_id,
      "username": userName,
      "quizId": quizData?.quizId,
      "quizName": quizData?.quizName,
      "startDate": quizData?.startDate,
      "startTime": quizData?.startTime,
      "endDate": quizData?.endDate,
      "endTime": quizData?.endTime,
      "totalDuration": quizData?.totalDuration,
      "releasedId": quizData?.releasedId,
      "examinationStatus": quizData?.examinationStatus
    })
  }

  const checkExamOver = async (item) => {
    // console.log('dsds', item);

    let timeLeft;
    const endTime = item?.endTime
    const serverDateResponse = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/configurations/server-datetime`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    const serverTime = serverDateResponse?.data;
    setIsBtnDisabled(false);
    // console.log('server', serverTime);
    const quizEndTime = moment(endTime, 'hh:mm A');
    timeLeft = quizEndTime.diff(serverTime, 'minutes') + 1
    // console.log('timeleft', timeLeft);
    if (timeLeft > 0) {
      setIsExamOver(false);
      setOpen(true);
    } else {
      setIsExamOver(true)
      setOpen(false)
    }
  }
  const handleExit = () => {
    setIsExamOver(false)
  }


  return (
    <>
      <>
        <div>
          {/*---------------------------| Common Items Across The Project Starts Here |---------------------------*/}
          <article>
            <div style={{ position: 'relative', margin: '14px' }}>
              <span className={dashboardContentStyles.link_icon}>
                <img
                  src={dashHome}
                  alt="no img"
                  className={dashboardContentStyles.az_menu_icon}
                />
              </span>
              <span
                className={dashboardContentStyles.az_menu_text}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate("/dashboard/template");
                }}
              >
                Home
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span
                className={dashboardContentStyles.az_menu_text}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // closeModal !== undefined ? closeModal() : navigate('/dashboard/enrich', {
                  //   state: null,
                  // }); 
                  closeModal()
                  // console.log(closeModal);
                }}
              >

                Subjects
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span
                className={`${dashboardContentStyles.az_menu_text} cursorPointer`}
              >
                {inboxChapterName ? inboxChapterName : selectedCardName}
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span
                className={`${dashboardContentStyles.az_selected_menu_text} cursorPointer`}
              >
                Quiz
              </span>
            </div>
            {/* <div className={dashboardContentStyles.dashboard_last_updated}>
              <p>Last Update: {modifiedDate.data}</p>
            </div> */}
          </article>
          {/*---------------------------| Common Items Across The Project Ends Here |---------------------------*/}

          <div>
            {/*---------------------------| DropDown Division Starts Here |---------------------------*/}
            <div className="dropDowns">
              <div className="dropdown_div">
                {/*---------------------------| DD-1 |---------------------------*/}
                <div>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#FD8C00 !important",
                          fontSize: "12px !important",
                          opacity: 0.9,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50px",
                          width: "120%",
                          height: "32px",
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        shrink={false}
                        // value={selectedSubject}
                        style={{
                          lineHeight: "1.2",
                        }}
                      >
                        {InboxSubjectName ? InboxSubjectName : subDD}
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value=""
                        label=""
                        IconComponent={KeyboardArrowDownIcon}
                        disabled={true}
                      >
                        <MenuItem value=""></MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                {/*---------------------------| DD-2 |---------------------------*/}
                {
                  showSubTopic &&
                  <div>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "#FD8C00 !important",
                            fontSize: "12px !important",
                            opacity: 0.9,
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#FFFFFF",
                            borderRadius: "50px",
                            width: "148px",
                            height: "32px",
                          },
                        }}
                      >

                        <InputLabel
                          id="demo-simple-select-label"
                          shrink={false}
                          style={{
                            lineHeight: "1.2",
                          }}
                        >
                          {subTopicDd ? subTopicDd : InboxSubtopicName ? InboxSubtopicName : "SubTopics"}
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label=""
                          value=""
                          IconComponent={KeyboardArrowDownIcon}
                          disabled={true}
                        >
                          <MenuItem value=""></MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                }

              </div>
            </div>
            {/*---------------------------| DropDown Division Ends Here |---------------------------*/}

            {/*---------------------------| Heading Division Starts Here |---------------------------*/}
            <div className="Heading_div">
              <div className="Heading">
                <p>All Quiz For  {inboxChapterName ? inboxChapterName : selectedCardName}</p>
              </div>
            </div>
            {/*---------------------------| Heading Division Ends Here |---------------------------*/}

            {/*---------------------------| Quiz Card Division Starts Here |---------------------------*/}

            <div className="Quiz_Cards_Wrapper">
              {/* ---------------------------| Quiz Card-1 |--------------------------- */}
              {quizListData
                ?.sort((a, b) => {
                  if (a.quizTypeName === b.quizTypeName) {
                    return a.quizName.localeCompare(b.quizName);
                  }
                  return a.quizTypeName === "Unit Quiz" ? 1 : -1;
                })
                .map((item, index) => {
                  const startDate = moment(
                    item.startDate,
                    "DD/MM/YYYY"
                  ).toDate();
                  const endDate = moment(item.endDate, "DD/MM/YYYY").toDate();
                  const startTime = moment(item.startTime, "hh:mm A").toDate();
                  const endTime = moment(item.endTime, "hh:mm A").toDate();
                  // ------------Testing manual time--------------------
                  // const startDate = moment(
                  //   "07 / 08 / 2023",
                  //   "DD/MM/YYYY"
                  // ).toDate();
                  // const endDate = moment("07 / 08 / 2023", "DD/MM/YYYY").toDate();
                  // const startTime = moment("12:42 AM", "hh:mm A").toDate();
                  // const endTime = moment("11:46 PM", "hh:mm A").toDate();
                  //console.log('currentdate', currentDate);
                  const currentDateTime = currentDate
                    ? new Date(
                      currentDate
                      // currentDate.getFullYear(),
                      // currentDate.getMonth(),
                      // currentDate.getDate(),
                      // currentDate.getHours(),
                      // currentDate.getMinutes(),
                      // currentDate.getSeconds()
                    )
                    : null;

                  const startDateTime = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate(),
                    startTime.getHours(),
                    startTime.getMinutes(),
                    startTime.getSeconds()
                  );
                  const endDateTime = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate(),
                    endTime.getHours(),
                    endTime.getMinutes(),
                    endTime.getSeconds()
                  );

                  const falseCheck = filteredCheck.some((itm) => itm === false);

                  return (
                    <div className="Quiz_Card_1" id={`quizId_${item.quizId}`
                    }
                      style={{ border: item.quizTypeName === "Unit Quiz" ? '1px solid #5ab0de' : '1px solid #8ed4d3' }}
                    >
                      {item.quizTypeName !== "Practice Quiz" ? (
                        <div
                          className={
                            item.examinationStatus == "NOT_RELEASED" ?
                              "Card_Mask" :
                              item.examinationStatus === "END"
                                ? currentDateTime?.getTime() >=
                                  startDateTime?.getTime() &&
                                  currentDateTime?.getTime() <=
                                  endDateTime?.getTime()
                                  ? "Card_Mask"//
                                  : ""
                                : item.examinationStatus === null ||
                                  item.examinationStatus === "NOT_YET_STARTED"
                                  ? currentDateTime?.getTime() >=
                                    startDateTime?.getTime() &&
                                    currentDateTime?.getTime() <=
                                    endDateTime?.getTime()
                                    ? ""
                                    : "Card_Mask"//
                                  : item.examinationStatus === "IN_PROGRESS"
                                    ? currentDateTime?.getTime() >=
                                      startDateTime?.getTime() &&
                                      currentDateTime?.getTime() <=
                                      endDateTime?.getTime()
                                      ? ""
                                      : "Card_Mask" //
                                    : item.examinationStatus === "START"
                                      ? currentDateTime?.getTime() >=
                                        startDateTime?.getTime() &&
                                        currentDateTime?.getTime() <=
                                        endDateTime?.getTime()
                                        ? ""
                                        : "Card_Mask"//
                                      : ""
                          }
                        ></div>
                      ) : (
                        <div
                        // className={
                        //   filteredCheck.length === 0
                        //     ? "Card_Mask"//
                        //     : falseCheck === true
                        //       ? "Card_Mask"//
                        //       : ""
                        // }
                        ></div>
                      )}

                      <div className="Quiz_Tittle_Box">
                        <p>
                          {item.quizTypeName} | {item.quizName}
                        </p>
                        {/* <p>High score- 20%</p> */}
                      </div>
                      {/* <div className="Quiz_Box_1"> */}
                      <div className={item.quizTypeName === "Practice Quiz" ? "Quiz_Box_1_Practice" : "Quiz_Box_1"}>
                        <div className="Quiz_Box_Detail_1">
                          <p>Questions</p>
                          <p>{item.totalQuestions}</p>
                        </div>
                        <div className="Quiz_Box_Detail_1">
                          <p>Marks</p>
                          <p>{item.totalMarks}</p>
                        </div>
                        <div className="Quiz_Box_Detail_1">
                          <p>Duration</p>
                          <p>{item.totalDuration} Mins</p>
                        </div>
                        {/* <div className="Quiz_Box_Detail_1">
                        <p>Remain time Duration</p>
                        <p>Mins</p>
                      </div> */}
                      </div>
                      {Object.keys(item.taxonomyPercentage).length !== 0 && (
                        // <div className="Quiz_Box_2">
                        <div className={item.quizTypeName === "Practice Quiz" ? "Quiz_Box_2_Practice" : "Quiz_Box_2"}>
                          <div className="Quiz_Box_Detail_2">
                            {Object.keys(item.taxonomyPercentage).map(
                              (keyy) => {
                                return (
                                  <>
                                    <p>{keyy}</p>
                                    <p className="percentage_val">
                                      {item.taxonomyPercentage[keyy]} %
                                    </p>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}

                      {item.containsCaseStudy ? (
                        // <div className="Quiz_Box_3">
                        <div className={item.quizTypeName === "Practice Quiz" ? "Quiz_Box_3_Practice" : "Quiz_Box_3"}>
                          <p>Contains Casestudy</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.quizTypeName !== "Practice Quiz" ? (
                        <div className="Quiz_Box_4">
                          <div>

                            <img
                              className="lock_icon"
                              src={
                                item.examinationStatus === "NOT_RELEASED"
                                  ? quizLockIcon
                                  :
                                  item.examinationStatus === "NOT_YET_STARTED"
                                    ? currentDateTime?.getTime() >=
                                      startDateTime?.getTime() &&
                                      currentDateTime?.getTime() <=
                                      endDateTime?.getTime()
                                      ? quizLockOpenIcon
                                      : quizLockIcon
                                    : item.examinationStatus === "START"
                                      ? currentDateTime?.getTime() >=
                                        startDateTime?.getTime() &&
                                        currentDateTime?.getTime() <=
                                        endDateTime?.getTime()
                                        ? quizLockOpenIcon
                                        : quizLockIcon
                                      : item.examinationStatus === "IN_PROGRESS"
                                        ? currentDateTime?.getTime() >=
                                          startDateTime?.getTime() &&
                                          currentDateTime?.getTime() <=
                                          endDateTime?.getTime()
                                          ? quizLockOpenIcon
                                          : quizLockIcon
                                        : item.examinationStatus === "END" &&
                                        quizLockIcon
                              }
                              alt="Lock/Unlock"
                            />
                            <img
                              className="info_icon"
                              src={quizInfoIcon}
                              alt="info"
                            />
                          </div>
                          <div>
                            {(item.quizTypeName !== "Practice Quiz" &&
                              item.examinationStatus === "START")
                              ||
                              (item.examinationStatus == "IN_PROGRESS"
                                // && (item.endDate == "" || item.endDate == null)
                              )
                              ? (
                                // <Link to="quiz" element={<QuizNewComponent resumeQuestions = {resumeQuestions} />}>

                                <button
                                  onClick={() => {
                                    // handleOpen()
                                    // setQuizChange('Resume')

                                    checkExamOver(item);
                                    sessionStorage.setItem('quizChange', 'Resume')
                                    setResumeBtnClicked(true);
                                    // setShowQuizAttend(false);
                                    setShowChapterQuizKey(true)
                                    setQuizData(item);
                                    setQuizExaminationId(item.examinationId);
                                    sessionStorage.setItem("quiztype", "unit")
                                    sessionStorage.setItem(
                                      "exIdTwo",
                                      item.examinationId
                                    );
                                    sessionStorage.setItem(
                                      "itemload",
                                      JSON.stringify(item)
                                    );
                                    sessionStorage.setItem(
                                      "itemloadInfo",
                                      JSON.stringify(item)
                                    );
                                    sessionStorage.setItem("alltheQuizAnswer", JSON.stringify([]));
                                    sessionStorage.setItem("QUIZ_DATA", JSON.stringify([]));
                                    sessionStorage.setItem("MSQ_det", JSON.stringify([]))

                                    // enterFullscreen();
                                  }}
                                  className="cursor-pointer"
                                >
                                  RESUME
                                </button>
                                // </Link>

                              ) : (item.examinationStatus === "END" || item.examinationStatus == "IN_PROGRESS") ? (
                                <button
                                  onClick={() => {
                                    setResumeBtnClicked(true);
                                    // setShowQuizAttend(true);
                                    // setQuizData(item);
                                    // console.log("item.examinationId check------", item)
                                    setQuizExaminationId(item.examinationId);
                                    sessionStorage.setItem(
                                      "exIdTwo",
                                      item.examinationId
                                    );
                                    setOpenAnalysisModal(true);
                                    if (item.quizTypeName === "Unit Quiz") {
                                      sessionStorage.setItem("quiztype", "unit")
                                    }
                                    // enterFullscreen();
                                  }}
                                  className="cursor-pointer"
                                >
                                  VIEW REPORT
                                </button>
                              ) : (
                                <button
                                  id="fullscreenButton"
                                  onClick={() => {
                                    // handleOpen()
                                    // setQuizChange('Start')
                                    // sessionStorage.setItem('quizChange','Start')
                                    // setShowQuizAttend(false);

                                    sessionStorage.removeItem("itemload")
                                    setQuizData(item);
                                    unitQuizStart(item);

                                    checkExamOver(item);

                                    sessionStorage.removeItem("exIdTwo");
                                    // console.log("start5check----", item)
                                    // enterFullscreen();
                                    sessionStorage.setItem(
                                      "itemload1",
                                      JSON.stringify(item)
                                    );
                                    sessionStorage.setItem(
                                      "itemloadInfo",
                                      JSON.stringify(item)
                                    );
                                    sessionStorage.setItem("quiztype", "unit");
                                    sessionStorage.setItem("alltheQuizAnswer", JSON.stringify([]))
                                    sessionStorage.setItem("exid", "")

                                  }}
                                  className="cursor-pointer"
                                >
                                  START
                                </button>
                              )}

                          </div>
                        </div>
                      ) : (
                        <>
                          {/* on 18/12 for making always practice quiz start button no resume */}
                          {/* <div className="Quiz_Time_Btn_Box" >
                              <div className="Time_btns_div">
                                <div>
                                  <img
                                    className="Time_Btn_Box_info_icon"
                                    src={quizInfoIcon}
                                    alt="info"
                                  />
                                </div>

                                {item.quizTypeName === "Practice Quiz" &&
                                  ["START", "IN_PROGRESS"].includes(
                                    item?.pqList?.length > 0
                                      ? item?.pqList[item?.pqList.length - 1]
                                        ?.examinationStatus
                                      : ""
                                  ) ? (
                                  <div>
                                    <button
                                      className="time_btn1"
                                      onClick={() => {
                                        setShowQuizAttend(true);
                                        setResumeBtnClicked(true);
                                        setQuizData(item);
                                        //console.log("resume2check----", item)
                                        setQuizExaminationId(
                                          item?.pqList[item?.pqList.length - 1]
                                            ?.examinationId
                                        );
                                        sessionStorage.setItem(
                                          "exIdTwo",
                                          item?.pqList[item?.pqList.length - 1]
                                            ?.examinationId
                                        );
                                      }}
                                    >
                                      RESUME 2
                                      <img
                                        src={withTimeIcon}
                                        alt="With Time Icon"
                                      />
                                    </button>
                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      className="time_btn1"
                                      onClick={() => {
                                        setWithTimeBtnClicked(true);
                                        setWithOutTimeBtnClicked(false);
                                        setShowQuizAttend(true);
                                        setQuizData(item);
                                        sessionStorage.setItem("quiztype", "practice")
                                        //console.log("itemcheck----", item)

                                      }}
                                    >
                                      WITH TIME
                                      <img
                                        src={withTimeIcon}
                                        alt="With Time Icon"
                                      />
                                    </button>
                                  </div>
                                )}


                                {item.quizTypeName === "Practice Quiz" &&
                                  ["START", "IN_PROGRESS"].includes(
                                    item?.pqList?.length > 0
                                      ? item?.pqList[item?.pqList.length - 1]
                                        ?.examinationStatus
                                      : ""
                                  ) ? (
                                  <div>

                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      className="time_btn2"
                                      onClick={() => {
                                        setWithOutTimeBtnClicked(true);
                                        setWithTimeBtnClicked(false);
                                        setShowQuizAttend(true);
                                        setQuizData(item);
                                        //console.log("check----", item)
                                        sessionStorage.setItem("quiztype", "practice")
                                      }}
                                    >
                                      WITHOUT TIME
                                      <img
                                        src={withOutTimeIcon}
                                        alt="Without Time Icon"
                                      />
                                    </button>
                                  </div>
                                )}

                              </div>
                            </div> */}
                          {/* upto this on 18/12 */}

                          <div className="Quiz_Time_Btn_Box" >
                            <div className="Time_btns_div">
                              <div>
                                <img
                                  className="Time_Btn_Box_info_icon"
                                  src={quizInfoIcon}
                                  alt="info"
                                />
                              </div>

                              {/*-------| Practice Quiz WITH-TIME Button |-------*/}
                              {/* {item.quizTypeName === "Practice Quiz" &&
                                  ["START", "IN_PROGRESS"].includes(
                                    item?.pqList?.length > 0
                                      ? item?.pqList[item?.pqList.length - 1]
                                        ?.examinationStatus
                                      : ""
                                  ) ? (
                                  <div>
                                    <button
                                      className="time_btn1"
                                      onClick={() => {
                                        setShowQuizAttend(true);
                                        setResumeBtnClicked(true);
                                        setQuizData(item);
                                        //console.log("resume2check----", item)
                                        setQuizExaminationId(
                                          item?.pqList[item?.pqList.length - 1]
                                            ?.examinationId
                                        );
                                        sessionStorage.setItem(
                                          "exIdTwo",
                                          item?.pqList[item?.pqList.length - 1]
                                            ?.examinationId
                                        );
                                        if (item.quizTypeName === "Practice Quiz") {
                                          sessionStorage.setItem("quiztype", "practice")
                                          sessionStorage.setItem("practiceQuizAnswer", JSON.stringify([]));
                                          var formattedTime = moment().format('h:mm A');
                                          sessionStorage.setItem("practicequizStartTime", JSON.stringify(formattedTime))
                                        } else {
                                          sessionStorage.setItem("quiztype", "unit");
                                          sessionStorage.setItem("practicequizStartTime", JSON.stringify(""))
                                        }
                                        
                                       
                                      }}
                                    >
                                      RESUME 2
                                      <img
                                        src={withTimeIcon}
                                        alt="With Time Icon"
                                      />
                                    </button>
                                  </div>
                                ) : ( */}
                              {/* commented on 7-3-24 after confirmation */}
                              <div>
                                <button
                                  className="time_btn1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleOpen()
                                    setResumeBtnClicked(false);
                                    setWithTimeBtnClicked(true);
                                    setWithOutTimeBtnClicked(false);
                                    // setShowQuizAttend(true);
                                    if (item.startTime == null) {
                                      const currentTime = moment();
                                      const endTime = moment(currentTime).add(item.totalDuration, 'minutes');
                                      // "startTime": "06:08 PM",
                                      // "startDate": "04/04/2024",
                                      // "endTime": "12:08 AM",
                                      // "endDate": "04/04/2024",
                                      const startTimeFormatted = currentTime.format('hh:mm A');
                                      const startDateFormatted = currentTime.format('MM/DD/YYYY');
                                      const endTimeFormatted = endTime.format('hh:mm A');
                                      const endDateFormatted = endTime.format('MM/DD/YYYY');
                                      item.startTime = startTimeFormatted
                                      item.startDate = startDateFormatted
                                      item.endTime = endTimeFormatted
                                      item.endDate = endDateFormatted
                                      // Output the formatted values
                                      // console.log("Start Time:", startTimeFormatted);
                                      // console.log("Start Date:", startDateFormatted);
                                      // console.log("End Time:", endTimeFormatted);
                                      // console.log("End Date:", endDateFormatted);
                                    }

                                    setQuizData(item);
                                    setNewQuizData(item.quizId)

                                    // console.log("item", item)


                                    sessionStorage.setItem("quiztype", "practice")
                                    sessionStorage.setItem("practiceQuizAnswer", JSON.stringify([]));
                                    sessionStorage.setItem("drag_drop_array", JSON.stringify([]));
                                    sessionStorage.setItem("drag_drop_array_final", JSON.stringify([]));
                                    sessionStorage.setItem("draw_line_array", JSON.stringify([]));
                                    sessionStorage.setItem("alltheQuizAnswer", JSON.stringify([]))

                                  }}
                                >
                                  WITH TIME
                                  <img
                                    src={withTimeIcon}
                                    alt="With Time Icon"
                                  />
                                </button>
                              </div>
                              {/* // )} */}


                              {/*-------| Practice Quiz WITHOUT-TIME Button |-------*/}
                              {/* {item.quizTypeName === "Practice Quiz" &&
                                  ["START", "IN_PROGRESS"].includes(
                                    item?.pqList?.length > 0
                                      ? item?.pqList[item?.pqList.length - 1]
                                        ?.examinationStatus
                                      : ""
                                  ) ? (
                                  <div>

                                  </div>
                                ) : ( */}
                              <div>
                                <button
                                  className="time_btn2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleOpen()
                                    setResumeBtnClicked(false);
                                    setWithOutTimeBtnClicked(true);
                                    setWithTimeBtnClicked(false);
                                    // setShowQuizAttend(true);
                                    setQuizData(item);
                                    setNewQuizData(item)
                                    // console.log("check----", item, item.examinationId, quizListData, index, quizListData[index]?.examinationId)
                                    sessionStorage.setItem("quiztype", "practice");
                                    sessionStorage.setItem("practiceQuizAnswer", JSON.stringify([]))
                                    sessionStorage.setItem("drag_drop_array", JSON.stringify([]));
                                    sessionStorage.setItem("drag_drop_array_final", JSON.stringify([]));
                                    sessionStorage.setItem("draw_line_array", JSON.stringify([]));
                                    sessionStorage.setItem("alltheQuizAnswer", JSON.stringify([]))
                                    // sessionStorage.setItem("exIdTwo", quizListData[index]?.examinationId);
                                  }}
                                >
                                  WITHOUT TIME
                                  <img
                                    src={withOutTimeIcon}
                                    alt="Without Time Icon"
                                  />
                                </button>
                              </div>
                              {/* // )} */}

                            </div>
                          </div>

                          {/* <div className="Quiz_Time_Btn_Box">
                              <div className="Time_btns_div">
                                {item.quizTypeName === "Practice Quiz" && item.pqList && item.pqList.length > 0 && (
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {item.pqList.map((pqItem, index) => (
                                      pqItem.examinationStatus === "END" && (
                                        <div key={index} style={{ flexBasis: "calc(33.33% - 20px)", boxSizing: "border-box", marginBottom: "10px" }}>
                                          <button
                                            className="reportButton"
                                            onClick={() => {
                                              //console.log("Button clicked", pqItem);
                                              setQuizExaminationId(pqItem.examinationId);
                                              sessionStorage.setItem("exIdTwo", item.examinationId);
                                              setOpenAnalysisModal(true);
                                            }}
                                          >
                                            Report
                                            <span className="badge badge-pill badge-success" style={{ borderRadius: "5px", marginRight: "5px", backgroundColor: "orange", width: "20px" }}>{index + 1}</span>
                                          </button>
                                        </div>
                                      )
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div> */}
                          {item.quizTypeName === "Practice Quiz" && item.pqList && item.pqList.length > 0 && (
                            <div className="Quiz_Time_Btn_Box" style={{ backgroundColor: "#dfefef", height: "auto !important" }}>
                              <div className="Time_btns_div">

                                <>
                                  {item.pqList
                                    .filter((pqItem) => pqItem.examinationStatus === "END")
                                    .map((pqItem, index) => (
                                      <div key={index} className="time-btn-item">
                                        <button
                                          className="reportButton"
                                          onClick={() => {
                                            setQuizExaminationId(pqItem.examinationId);
                                            sessionStorage.setItem("exIdTwo", pqItem.examinationId);
                                            setOpenAnalysisModal(true);
                                            sessionStorage.setItem("quiztype", "practice");
                                          }}
                                        >
                                          Report
                                          <span className="badge badge-pill badge-success" style={{ borderRadius: "5px", marginRight: "5px", backgroundColor: "orange", width: "20px" }}>{index + 1}</span>
                                        </button>
                                      </div>
                                    ))
                                  }

                                </>

                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>

            {isExamOver ? <>
              <Modal open={isExamOver}
                onClose={!isExamOver}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal modalPopUp">
                <Box className="modalBox2">

                  <Typography id="modal-modal-description" className="resumeType" sx={{ mt: 2, fontSize: '25px', fontWeight: '500' }}>
                    Quiz has already ended
                  </Typography>
                  <div className="buttonDiv">
                    <button className="startButton" onClick={() => handleExit()}>Exit</button>
                    {/* <Link to={"quiz/resume"} element={<QuizNewResumeComponent />}>
                    <button className="startButton" onClick={() => handleHide()}>Resume</button>
                  </Link> */}
                  </div>
                </Box>
              </Modal>
            </> : <>
              {open && resumeBtnClicked ? <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal modalPopUp"
              >
                {/* <Box className="modalBox">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  General Instructions
                </Typography>
                <Divider />

                <Typography id="modal-modal-description" className="resumeType" sx={{ mt: 2 }}>
                  Are you sure to Resume the Quiz
                </Typography>
                <div className="buttonDiv">
                  <button className="backButton" onClick={() => handleBack()}>back</button>
                  <Link to={"quiz/resume"} element={<QuizNewResumeComponent />}>
                    <button className="startButton" onClick={() => handleHide()}>Resume</button>
                  </Link>
                </div>
              </Box> */}

                <Box className="modalBox2">

                  <Typography id="modal-modal-description" className="resumeType" sx={{ mt: 2 }}>
                    Are you sure to Resume the Quiz
                  </Typography>
                  <div className="buttonDiv">
                    <button className="backButton" onClick={() => handleBack()}>back</button>
                    {/* <Link to={"quiz/resume"} element={<QuizNewResumeComponent />}>
                    <button className="startButton" onClick={() => handleHide()}>Resume</button>
                  </Link> */}
                    <Link to={"quiz/resume"} element={<QuizResumeOptimizedComponent />}>
                      <button className="startButton" onClick={() =>{
                         setIsBtnDisabled(true); handleHide()}}>Resume</button>
                    </Link>
                  </div>
                </Box>
              </Modal> : <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal modalPopUp"
              >
                {/* <Box className="modalBox">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  General Instructions
                </Typography>
                <Divider />
                <div className="modal_contents">
                  <h6 className="modal_content_1">
                    All questions are compulsory
                  </h6>
                  <h6 className="modal_content_2">
                    The <span>maximum marks is {quizData?.totalMarks}  </span>
                  </h6>
                  <div className="modal_content_3" style={{ marginBottom: '10px' }}>
                    The duration of the paper is: . (15 min
                    reading time &  writing time) Read all the
                    questions carefully before attempting. A neat and legible
                    presentation will be appreciated.
                  </div>
                  <h6 className="modal_content_1">
                    Please take a few minutes to read the instructions carefully before starting the quiz:
                  </h6>
                  <div className="modal_content_3">
                    <ol>
                      <li>This assessment contains Multiple Choice Questions, with 1 correct answer for every question.</li>
                      <li>Other questions – like Multiselect and Match the following, Fill in the blanks & Case study have specific instructions given.</li>
                      <li>Read the questions carefully and choose the appropriate option.</li>
                      <li>It is mandatory to attempt all the questions.</li>
                      <li>No marks are awarded for unattempted questions.</li>
                      <li>Mark the answers for each question. If you want to attempt any questions later, you can move to the next question without marking the answer. Press the back arrow and attempt the questions later before you submit the quiz.</li>
                      <li>If you are taking the quiz through the mobile app, avoid making/receiving calls.</li>
                      <li>In case of any challenges regarding the quiz, please feel free to contact your teacher immediately.</li>
                      <li>Once quiz Time is over student will receive pop up “Time is over” click on “Ok” to submit quiz.</li>
                    </ol>
                  </div>
                </div>
                <Divider />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Are you ready to start the Quiz
                </Typography>
                <div className="buttonDiv">
                  <button className="backButton" onClick={() => handleBack()}>Back</button>
                  <Link to={"quiz"} element={<QuizNewComponent />}>
                    <button className="startButton" onClick={() => handleHide()}>Start</button>
                  </Link>
                </div>
              </Box> */}

                <Box className="modalBox">
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    General Instructions
                  </Typography>
                  <Divider />
                  <div className="modal_contents">
                    <h6 className="modal_content_1">
                      Please read the instructions carefully before starting the quiz:
                    </h6>

                    <div className="modal_content_3">
                      <ul>
                        <li>This quiz includes Multiple Choice Questions (MCQs) with one correct answer per question. </li>
                        <li>Other question types like Multiselect, Match the Following, Fill in the Blanks, and Case Study, have specific instructions given.	 </li>
                        <li>Attempt all questions as marks are not awarded for unattempted questions.</li>
                        <li>Use navigation to move between questions; avoid calls if using the mobile app. </li>
                        <li>Contact your teacher for quiz-related challenges. </li>
                        <li>Once quiz Time is over, you will receive a pop up “Time is over” click on “Ok” to submit the quiz.</li>
                      </ul>
                    </div>
                  </div>
                  <Divider />
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you ready to start the Quiz
                  </Typography>
                  <div className="buttonDiv">
                    <button className="backButton" onClick={() => handleBack()}>Back</button>
                    {/* <Link to={"quiz"} element={<QuizNewComponent />}>
                    <button className="startButton" onClick={() => handleHide()}>Start</button>
                  </Link> */}
                    <Link to={"quiz"} element={<QuizOptimizedComponent />}>
                      <button className="startButton"
                        disabled={isBtnDisabled}
                        onClick={() => {
                          setIsBtnDisabled(true);
                          var ss = JSON.parse(sessionStorage.getItem("itemload1"));
                          sessionStorage.setItem("itemload", JSON.stringify(ss))
                          quizStartCapture();
                          handleHide();
                        }
                        }>Start</button>
                    </Link>
                  </div>
                </Box>
              </Modal>}
            </>
            }

            {/*---------------------------| Quiz Card Division Ends Here |---------------------------*/}
          </div>
        </div>

        {openAnalysisModal && (
          <Student_Enrich_Quiz_Analysis_Modal2
            showAnalysisModal={openAnalysisModal}
            closeModal={handleAnalysisModalClose}
            closeModall={() => {
              setShowQuizAttend(false);
            }}
            // closeModall={closeModall}
            chapterQuizKey={false}
            callbackforafterquizexit={callbackforafterquizexit}
          />
        )}
      </>

    </>
  );
}

export default Student_Enrich_Quiz;
