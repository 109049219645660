import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Doughnut } from "react-chartjs-3";
import { Link, useNavigate, useLocation } from "react-router-dom";
//API Used
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";

// Css Used
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/Teacher_New_Dashboard.css";
import dashboardContentStyles from "../css/dashboardContent.module.css";

//Images & Icons Used
import dashHome from "../../../src/img/dashHome.svg";
import sections from "../../../src/img/no-of-grades.png";
import students from "../../../src/img/no-of-students.png";
import Assessment from "../../../src/img/Assessment.png";
import refreshIcn from "../../../src/img/refresh.svg";
import redIcn from "../../../src/img/red-circle.svg";
import sqrIcn1 from "../../../src/img/square.svg";
import sqrIcn2 from "../../../src/img/square1.svg";
import sqrIcn3 from "../../../src/img/square2.svg";
import sqrIcn4 from "../../../src/img/square3.svg";
import sqrIcn5 from "../../../src/img/square5.svg";
import sqrIcn6 from "../../../src/img/square4.svg";
import emberIcn from "../../../src/img/ember.svg";
import kindleIcn from "../../../src/img/kindle.svg";
import glowIcn from "../../../src/img/glow.svg";
import sparkleIcn from "../../../src/img/sparkle.svg";

// Mui
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { async } from "q";

function Teacher_New_Dashboard() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const userName = localStorage.getItem("userName");
  const [modifiedDate, setModifiedDate] = useState({});
  const [teacherId, setTeacherId] = useState("");
  const [gradeList, setGradeList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [gradeSection, setGradeSection] = useState([]);
  const [subTopicz, setSubTopicz] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [studentLevel, setStudentLevel] = useState([]);
  const [count, setCount] = useState({});
  const [chaptersData, setChaptersData] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [chaptersCpmpleted, setChaptersComplted] = useState([]);
  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const [selectedSubjectName,setSelectedSubjectName] = useState('');
  const [selectedSubTopicName, setSelectedSubtopicName] = useState("");

  //-------| Fetch Teacher Data API |-------//
  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("branchId", res.data.data.teacher.branch);
    sessionStorage.setItem("teacherId", res.data.data.teacher.id)
    setTeacherId(res.data.data.teacher);
  };

  //-------| Fetch Grade & Section Data API |-------//
  const fetchGrade = async () => {
    const id = teacherId.id;

    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=TEACH`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setGradeSection(response.data.data);
        setSelectedGrade(response.data.data[0]?.id);
        setSectionId(response?.data?.data[0]?.sections[0]?.id);
      })
      .catch((err) => console.log(err));
  };

  //-------| Fetch Subject & Subtopic Data API |-------//
  const fetchSubject = async (gradeId) => {
    const id = teacherId.id;

    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${gradeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSubjectList(response?.data?.data);
        setSelectedSubject(response?.data?.data[0]?.subjectId);
        setSelectedSubjectName(response?.data?.data[0]?.subject);
        setSubTopicz(response?.data?.data[0]?.subTopics || []);
        setSelectedSubTopic(response?.data?.data[0]?.subTopics[0]?.id || "")
        setSelectedSubtopicName(response?.data?.data[0]?.subTopics.length > 0 ? response?.data?.data[0]?.subTopics[0]?.subTopic : "" )

      })
      .catch((err) => console.log(err));
  };

  const fetchStudentLevel = async (grade,subjectId,subTopic  = "") => {
    const id = teacherId.id;
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const boardId = sessionStorage.getItem("boardId");

    if(grade && subjectId){
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/student-level?teacherId=${id}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${grade}&subjectId=${subjectId}&subTopicId=${subTopic}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setStudentLevel(response?.data?.data);
      });
    }
  }
    const fetchCountLevel = async(grade,subjectId,subTopic  = "") =>{
      const id = teacherId.id;
      const schoolId = sessionStorage.getItem("schoolId");
      const branchId = sessionStorage.getItem("branchId");

    if(grade && subjectId){
      const url = subTopic != "" && subTopic ?
      `v1/api/user/dashboard/count-for-dashboard?teacherId=${id}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${grade}&subjectId=${subjectId}&subtopicId=${subTopic}`:
      `v1/api/user/dashboard/count-for-dashboard?teacherId=${id}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${grade}&subjectId=${subjectId}`

      await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}${url}`
      ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setCount("");
      //console.log("Triggered");
        setCount(response.data.data);
      })

      .catch((err) => console.log(err));
    }
  }
  const fetchChaptersCompleted = async (grade,subjectId,subTopic = "") => {
    const id = teacherId.id;
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const boardId = sessionStorage.getItem("boardId");
    const url = subTopic != "" && subTopic ?
    `v1/api/user/dashboard/chapters-completed-vs-quizzes-releases?teacherId=${id}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}&subTopicId=${subTopic}`:
    `v1/api/user/dashboard/chapters-completed-vs-quizzes-releases?teacherId=${id}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}`
    if(grade,subjectId){
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}${url}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setChaptersComplted(response?.data?.data);
      })
      .catch((err) => console.log(err));
    }
  };
  const [fetchQuizData,setUnitQuizData] = useState([]);
  const fetchUnitQuizData = async (grade,subjectId,selectedChapter,subTopic = "") => {
    const id = teacherId.id;
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const boardId = sessionStorage.getItem("boardId");
    const url = subTopic != "" && subTopic ?
    `v1/api/user/dashboard/unit-quiz-performance?teacherId=${id}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}&chapterId=${selectedChapter}&subTopicId=${subTopic}`:
    `v1/api/user/dashboard/unit-quiz-performance?teacherId=${id}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}&chapterId=${selectedChapter}`

    if(grade && subjectId && selectedChapter){
      await axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}${url}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUnitQuizData(response.data.data.averageResponseDto)
        })
        .catch((err) => console.log(err));
      }
  }
  const fetchFormativeAssessment = async (grade,subjectId) => {
    const id = teacherId.id;
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const boardId = sessionStorage.getItem("boardId");
    if(grade,subjectId){
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/formative-assessment?teacherId=${id}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
      //console.log(response);
      })
      .catch((err) => console.log(err));
    }
  };

  const fetchChapters = async (grade,subject,subtopic) => {
  //console.log(grade,subject,subtopic)
    const boardId = sessionStorage.getItem("boardId");
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters?subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&subTopicId=${subtopic}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
       setChaptersData(response.data.data);
      })
      .catch((err) => console.log(err));
  };
  const handleChangeGrade = (e) => {
    setSelectedGrade(e.target.value);
    sessionStorage.setItem("gradeId", e.target.value);
    setCount({});
    setStudentLevel([]);
    setUnitQuizData([])
    setSelectedSubject("");
    setSelectedSubTopic("");
  };

  const handleChangeSubject = (event) => {
    setSelectedSubject(event.target.value);
    const temp = subjectList.filter(v => v.subjectId === event.target.value);
   setSelectedSubjectName(temp[0]?.subject);
    const filteredSubtopics = subjectList.filter(v => v.subjectId == event.target.value);
    setSubTopicz(filteredSubtopics[0].subTopics);
    setSelectedSubTopic("");
  };

  const handleSubtopicChange = (event) => {
    setSelectedSubTopic(event.target.value);
    let temp = subTopicz.filter(v => v.id === event.target.value);
    setSelectedSubtopicName(temp[0].subTopic)
  };

  const handleChangeChapter = (event) => {
    setSelectedChapter(event.target.value);
  };
  useEffect(() =>{
    if (
      selectedGrade !== "" &&
      selectedSubject !== "" &&
      selectedChapter !== ""
    ){
      if((subTopicz.length >  0 && selectedSubTopic != "")){
      fetchUnitQuizData(selectedGrade, selectedSubject,selectedChapter,selectedSubTopic);
      }
      else{
        fetchUnitQuizData(selectedGrade, selectedSubject,selectedChapter);
      }
    }
    fetchChapters(selectedGrade,selectedSubject,selectedSubTopic)
  },[selectedGrade, selectedSubject,selectedChapter,selectedSubTopic]);

  useEffect(() => {
    fetchTeacherData(userName, role);
    const teacherData = fetchTeacherData(userName, role);
    setTeacherDetail(teacherData);
    GetLastModifiedAt(setModifiedDate);
  }, []);

  useEffect(() => {
    const fetchGradeData = async () => {
      const grade = await fetchGrade();
      setGradeList(grade);
    };

    fetchGradeData();
  }, [teacherId.id]);

  useEffect(() => {
    if (selectedGrade !== "") {
      fetchSubject(selectedGrade,sectionId);
    }
  }, [selectedGrade,sectionId]);
  useEffect(() => {
    if (
      selectedGrade !== "" &&
      selectedSubject !== ""
    ) {
      if((subTopicz.length >  0 && selectedSubTopic != "")){
        fetchStudentLevel(selectedGrade, selectedSubject,selectedSubTopic);
        fetchCountLevel(selectedGrade, selectedSubject,selectedSubTopic);
        fetchChaptersCompleted(selectedGrade, selectedSubject,selectedSubTopic);
        fetchFormativeAssessment(selectedGrade, selectedSubject,selectedSubTopic);
      }else
      {
        fetchStudentLevel(selectedGrade, selectedSubject);
        fetchCountLevel(selectedGrade, selectedSubject);
        fetchChaptersCompleted(selectedGrade, selectedSubject);
        fetchFormativeAssessment(selectedGrade, selectedSubject);
       }

    }
  }, [selectedGrade, selectedSubject,selectedSubTopic]);

  //-------| Doughnut Chart Details Starts |-------//
  const doughnutdata = {
    labels: ["Ember", "Kindle", "Glow", "Sparkle"],
    datasets: [
      {
        label: "My First Dataset",
        data: [40, 20, 20, 20],
        //data: ['0-39', '40-59', '60-80', '81-100'],
        backgroundColor: ["#E619B7", "#A6EF74", "#8CD7F8", "#FFB427"],
        borderWidth: 0,
      },
    ],
    text: "",
  };
  const doughnutOption = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "My Doughnut Chart",
      },
      position: "start",
      datalabels: false,
    },
    legend: {
      display: false,
      position: "right",
      labels: {
        fontColor: "#FFFFFF",
        usePointStyle: true,
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 0,
        top: 0,
        bottom: 10,
      },
    },
    cutoutPercentage: 55,
    tooltips: false,
  };
  //-------| Doughnut Chart Details Ends |-------//

  //-------| Slick-Carousel Button Details Ends |-------//

  // For Custom BarChart
  const barSettings = {
    dots: false,
    infinite: false,
    speed: "500",
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: (
      <button type="button" className="slick-prev"  >
        Previous
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next"  >
        Next
      </button>
    ),
  };

  // For Custom ProgressBar
  const pbSettings = {
    dots: false,
    infinite: false,
    speed: "500",
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: (
      <button type="button" className="slick-prev">
        Previous
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        Next
      </button>
    ),
  };
  //-------| Slick-Carousel Button Details Ends |-------//

  return (
    <>
      <div className="Whole_DashPage">
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle", marginLeft: "14px" }}
              />
            </span>
            <span
              style={{ fontSize: "12px", marginLeft: "4px", color: "#7A7A7A" }}
            >
              Home ⨠
            </span>
            <span
              style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
            >
              Dashboard
            </span>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div>
        </article>
        {/*-------| Dropdowns Section Starts Here |-------*/}
        <div className="Teacher_dropdown_div">
          {/*-------| DD-1 |-------*/}
          <div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#FD8C00 !important",
                    fontSize: "14px !important",
                    opacity: 0.9,
                    lineHeight: 1.1,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50px",
                    width: "113px",
                    height: "32px",
                  },
                }}
              >
                {!selectedGrade && (
                  <InputLabel id="demo-simple-select-label" shrink={false}>
                    Grades
                  </InputLabel>
                )}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedGrade}
                  label=""
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={handleChangeGrade}
                >
                  {gradeSection?.map((item) => {
                    return (
                      <MenuItem value={item?.id} key={item.id}>
                        <ListItemText
                          primary={
                            <Typography
                              noWrap
                              style={{
                                fontSize: "12px",
                                color: "#FD8C00",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%",
                              }}
                            >
                              {item.grade}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          {/*-------| DD-2 |-------*/}
          <div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#FD8C00 !important",
                    fontSize: "14px !important",
                    opacity: 0.9,
                    lineHeight: 1.1,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50px",
                    width: "156px",
                    height: "32px",
                  },
                }}
              >
                {!selectedSubject && (
                  <InputLabel id="demo-simple-select-label" shrink={false}>
                    Subjects
                  </InputLabel>
                )}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSubject}
                  label=""
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={handleChangeSubject}
                >
                  {subjectList?.map((item) => {
                    return (
                      <MenuItem value={item.subjectId} key={item.subjectId}>
                        <ListItemText
                          primary={
                            <Typography
                              noWrap
                              style={{
                                fontSize: "12px",
                                color: "#FD8C00",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%",
                              }}
                            >
                              {item.subject}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          {/*-------| DD-3 |-------*/}
          <div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#FD8C00 !important",
                    fontSize: "14px !important",
                    opacity: 0.9,
                    lineHeight: 1.1,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50px",
                    width: "156px",
                    height: "32px",
                  },
                }}
              >
                {!selectedSubTopic && (
                  <InputLabel id="demo-simple-select-label" shrink={false}>
                    SubTopic
                  </InputLabel>
                )}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSubTopic}
                  label=""
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={handleSubtopicChange}
                >
                  {subTopicz.map((itm) => {
                    return (
                      <MenuItem key={itm.id} value={itm.id}>
                        <ListItemText
                          primary={
                            <Typography
                              noWrap
                              style={{
                                fontSize: "12px",
                                color: "#FD8C00",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%",
                              }}
                            >
                              {itm.subTopic}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        {/*-------| Dropdowns Section Ends Here |-------*/}

        {/*-------| Teacher Dashboard GRID Starts Here |-------*/}
        <div className="Teacher_Grid">
          {/*-------| No of Sections |-------*/}
          <div className="Num_of_Sec">
            <div>
              <h6>No of Sections</h6>
              <img src={sections} alt="" style={{backgroundColor:"orange",borderRadius: "30px"}} />
            </div>
            <div>
              <h6>{count.sectionsCount}</h6>
            </div>
          </div>

          {/*-------| No of Students |-------*/}
          <div className="Num_of_Stud">
            <div>
              <h6>No of Students</h6>
              <img src={students} alt="" style={{backgroundColor:"blue",borderRadius: "30px"}} />
            </div>
            <div>
              <h6>{count.studentCount}</h6>
            </div>
          </div>

          {/*-------| Formative Assessment |-------*/}
          <div className="Formative_Assess">
            <div>
              <h6>Formative Assessment</h6>
              <img src={Assessment} alt="" style={{backgroundColor:"skyblue",borderRadius: "30px"}} />
            </div>
            <div>Revision test - 1</div>
            <div>Coming Soon Loading...</div>
            <div>
              <button className="viewBtn"  onClick={(e) => {
                e.preventDefault();
                      navigate("/dashboard/Chapter%20Performance");
                    }}>View</button>
            </div>
          </div>

          {/*-------| Students Level |-------*/}
          <div className="Stud_Level">
            <div>Students Level</div>
            <div className="Doughnut_and_Legend">
              <div>
                <Doughnut
                  data={doughnutdata}
                  options={doughnutOption}
                  width={110}
                  height={140}
                />
              </div>
              <div>
                {studentLevel.map((v, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "200px",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          src={
                            v.rangeName == "Ember"
                              ? emberIcn
                              : v.rangeName == "kindle"
                              ? kindleIcn
                              : v.rangeName == "Glow"
                              ? glowIcn
                              : sparkleIcn
                          }
                        />
                        <p> {v.rangeName}</p>
                      </div>
                      <div>
                        <p>{v.percentageMarks}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/*-------| Chapters Completed vs Quizzes Releases |-------*/}
          <div className="ChapCom_vs_QuizRele" style={{height: "360px"}}>
            <div>
              <h6>Chapters Completed vs Quizzes Releases</h6>
              <div className="Legendss">
                <div>
                  <img src={sqrIcn4} />
                  <h6>Total Chapters</h6>
                </div>
                <div>
                  <img src={sqrIcn5} />
                  <h6>Chapters</h6>
                </div>
                <div>
                  <img src={sqrIcn6} />
                  <h6>Quizzes</h6>
                </div>
              </div>
            </div>
            <div className="Pb_Main_Sec" style={{marginLeft:"30px"}}>
            <Slider {...pbSettings}>
              {
                chaptersCpmpleted?.sections?.length  > 0 && chaptersCpmpleted?.sections?.map((v,i)=>{
                  return(

                    <div className="Pb_container" >
                      {/*------------------| PB-1 |------------------*/}
                      <div className="containerr__PB1">
                        <div className="barcontainer__PB1">
                          <div
                            className="barr__PB1"
                            style={{ height: v.totalChapters > 0 ? "100%" : "0"}}
                          ></div>
                          <div className="circleeee__PB1"></div>
                          <div className="circleeee2__PB1"></div>
                          <div className="ticks-container__PB1">
                            <div className="percentage-container__PB1">
                              <span className="percentage__PB1">{v.totalChapters}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*------------------| PB-2 |------------------*/}
                      <div className="containerr__PB2">
                        <div className="barcontainer__PB2">
                          <div
                            className="barr__PB2"
                            style={{ height: v.totalChapters > 0 ?  parseInt((v.completedChapters * 100)/v.totalChapters) + "%" : "0" }}
                          ></div>
                          <div className="circleeee__PB2"></div>
                          <div className="circleeee2__PB2"></div>
                          <div className="ticks-container__PB2">
                            <div className="percentage-container__PB2">
                              <span className="percentage__PB2">{v.completedChapters}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*------------------| PB-3 |------------------*/}
                      <div className="containerr__PB3">
                        <div className="barcontainer__PB3">
                          <div
                            className="barr__PB3"
                            style={{ height:parseInt(v.releasedQuizzes) + "%" }}
                          ></div>
                          <div className="circleeee__PB3"></div>
                          <div className="circleeee2__PB3"></div>
                          <div className="ticks-container__PB3">
                            <div className="percentage-container__PB3">
                              <span className="percentage__PB3">{v.releasedQuizzes}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Subject_and_Section">
                        {
                        selectedSubTopicName.length > 0 ? <div className="Subject_name">{selectedSubTopicName}</div> :
                         <div className="Subject_name">{selectedSubjectName}</div>
                         }
                        <div className="Section_name">Section-{v.section}</div>
                      </div>
                    </div>

                  )
                })
              }
             </Slider>
            </div>
          </div>

          {/*-------| Upcoming Tasks |-------*/}
          <div className="Up_Tasks" style={{height: "360px"}}>
            <div>
              <h6>Upcoming Tasks</h6>
              <img src={refreshIcn} title="Refresh" alt="" />
            </div>
            <div className="UP_TaskSec">
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Coming Soon Loading...</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>

              {/*----------Dummy details starts----------*/}
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Coming Soon Loading...</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>

              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>

              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              <div className="The_Tasks_and_Priority">
                <div className="The_Task">
                  <h6>Magnetic Effect of Current of lighting</h6>
                  <h6>Prepare questions for unit test for Grade 3</h6>
                </div>
                <div className="Priority">
                  <img src={redIcn} />
                  <h6>High</h6>
                </div>
              </div>
              {/*----------Dummy details ends----------*/}
            </div>
            <div className="ULine_and_Heading">
              <div className="ULine"></div>
              <h6 className="VAT_heading">View All Tasks</h6>
            </div>
          </div>

          {/*-------| Unit Quiz Performance |-------*/}
          <div className="Unit_Quiz_Perform">
            <div className="Unit_Quiz_Details">
              <div className="Heading_and_DD">
                <h6>Unit Quiz Performance</h6>
                <div className="DropDowns">
                  {/*-------| DD-1 |-------*/}
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#2D3962!important",
                          fontSize: "14px !important",
                          opacity: 0.9,
                          lineHeight: 1.1,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50px",
                          width: "156px",
                          height: "32px",
                        },
                      }}
                    >
                {!selectedChapter && (
                  <InputLabel id="demo-simple-select-label" shrink={false}>
                    Chapter
                  </InputLabel>
                )}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedChapter}
                        label=""
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={handleChangeChapter}
                      >
                        {chaptersData.length > 0 && chaptersData?.map((item)=>{
                          return (
                          <MenuItem value={item.id}>
                            <ListItemText
                             value={item.id}
                              primary={
                                <Typography
                                  noWrap
                                  style={{
                                    fontSize: "12px",
                                    color: "#FD8C00",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                  }}
                                >
                                  {item.chapter}
                                </Typography>
                              }
                            />
                          </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div style={{borderBottom:"solid 3px #ffc0f0",width:"610px",marginLeft:"100px"}}></div>
              <div className="BarCharts_Wrapperr">
                <Slider {...barSettings}>
                  {
                    fetchQuizData.map((v,i)=>{
                      return(
                    <div className="MainDivv">
                    <div className="BarCharts_Divv">
                      <div className="BarContainerr1">
                        <div className="BarChartt1" style={{ height: parseInt(v.sectionAverage) + "%" }}>
                          <div className="progressTextt">{v.sectionAverage}</div>
                        </div>
                      </div>
                      <div className="BarContainerr2">
                        <div className="BarChartt2" style={{ height: parseInt(v.attendeesAvg) + "%" }}>
                          <div className="progressTextt">{v.attendeesAvg}</div>
                        </div>
                      </div>
                    </div>
                    <div className="BarChart_Namee">{v.sectionName}</div>
                  </div>
                      )
                    })
                  }


                </Slider>
              </div>

              <div className="BarCharts_legends">
                <div>
                  <img src={sqrIcn1} />
                  <h6>Section Average Score</h6>
                </div>
                <div>
                  <img src={sqrIcn2} />
                  <h6>Attendance Average</h6>
                </div>
                <div>
                  <img src={sqrIcn3} />
                  <h6>Global Average Score</h6>
                </div>
              </div>
            </div>
            <div className="sectionScroll">
              <div className="SectionDiv">
                <div className="Section_Details">
                  <div>
                    <h6>Section A</h6>
                    <p>
                      <span>Start Date :</span> 10 July 2022
                    </p>
                    <p>
                      <span>End Date :</span> 10 July 2022
                    </p>
                  </div>
                  <div>
                    <p>Ember - 40% &#40; 10 &#41;</p>
                    <p>Kindle - 30% &#40; 4 &#41;</p>
                    <p>Glow - 20% &#40; 5 &#41;</p>
                    <p>Sparkle - 10% &#40; 7 &#41;</p>
                  </div>
                </div>
                <div>
                  <button>Report</button>
                </div>
              </div>

              {/*----------Dummy details starts----------*/}
              <div className="SectionDiv">
                <div className="Section_Details">
                  <div>
                    <h6>Section A</h6>
                    <p>
                      <span>Start Date :</span> 10 July 2022
                    </p>
                    <p>
                      <span>End Date :</span> 10 July 2022
                    </p>
                  </div>
                  <div>
                    <p>Ember - 40% ( 10 )</p>
                    <p>Kindle - 30% ( 8 )</p>
                    <p>Glow - 20% ( 5 )</p>
                    <p>Sparkle - 10% ( 2 )</p>
                  </div>
                </div>
                <div>
                  <button>Report</button>
                </div>
              </div>
              <div className="SectionDiv">
                <div className="Section_Details">
                  <div>
                    <h6>Section A</h6>
                    <p>
                      <span>Start Date :</span> 10 July 2022
                    </p>
                    <p>
                      <span>End Date :</span> 10 July 2022
                    </p>
                  </div>
                  <div>
                    <p>Ember - 40% &#40; 10 &#41;</p>
                    <p>Kindle - 30% &#40; 4 &#41;</p>
                    <p>Glow - 20% &#40; 5 &#41;</p>
                    <p>Sparkle - 10% &#40; 7 &#41;</p>
                  </div>
                </div>
                <div>
                  <button>Report</button>
                </div>
              </div>
              <div className="SectionDiv">
                <div className="Section_Details">
                  <div>
                    <h6>Section A</h6>
                    <p>
                      <span>Start Date :</span> 10 July 2022
                    </p>
                    <p>
                      <span>End Date :</span> 10 July 2022
                    </p>
                  </div>
                  <div>
                    <p>Ember - 40% ( 10 )</p>
                    <p>Kindle - 30% ( 8 )</p>
                    <p>Glow - 20% ( 5 )</p>
                    <p>Sparkle - 10% ( 2 )</p>
                  </div>
                </div>
                <div>
                  <button>Report</button>
                </div>
              </div>
              {/*----------Dummy details ends----------*/}
            </div>
          </div>
        </div>
        {/*-------| Teacher Dashboard GRID Ends Here |-------*/}
      </div>
    </>
  );
}

export default Teacher_New_Dashboard;
