import React, { useEffect, useState, useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../js/TeachValidate";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import { MenuProps, startingYearData } from "./Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import food from "../img/Login.png";
import logo from "../img/Azvasa_logo.png";
import dashboardContentStyles from "../css/dashboardContent.module.css";
import AddQuestionModal from "./AddQuestionModal";
import AddQuestionModalObjective from "./AddQuestionModalObjective";
import DarkGreenButton from "../../components/common/DarkGreenButton";
import DarkRedButton from "../../components/common/DarkRedButton";
import DarkYellowButton from "../../components/common/DarkYellowButton";
import DarkOrangeButton from "../../components/common/DarkOrangeButton";

import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import TabPanel from "@mui/lab/TabPanel";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import TabList from "@mui/lab/TabList";
import { TabContext } from "@mui/lab";
import TopBar from "../../components/common/TopBar";
import { makeStyles } from "@material-ui/core/styles";
import { History } from "@mui/icons-material";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import ContentViewer from '../../components/common/ContentViewer';
import html2canvas from 'html2canvas';
import Modal from "@mui/material/Modal";
import { Backdrop } from "@mui/material";
import QuestionPaperRejectModal from '../js/ContentReviewer/QuestionPaperRejectModal'
import $ from 'jquery';
import html2pdf from 'html2pdf.js';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import QstnData from "./SubjectiveQuestion.json"
import jsPDF from "jspdf";

import DocumentViewer from '../../../src/utils/DocumentViewer';
import CommonSort from "../js/CommonSort"

const useStyles = makeStyles({
  root: {
    maxWidth: 310,
    transition: "transform 0.15s ease-in-out",
  },
  cardHovered: {
    transform: "scale3d(1.05, 1.05, 1)",
  },
});
const SkyBlueButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "rgba(20,163,209,0.89)",
  borderColor: "rgba(20,163,209,0.89)",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgba(20,163,209,0.89)",
    border: 0,
    boxShadow: "0px 3px 5px rgba(20,163,209,0.89)",
  },
}));
const EditButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "rgb(92 85 156)",
  borderColor: "rgb(92 85 156)",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgb(92 85 156)",
    border: 0,
    // boxShadow: "0px 3px 5px rgb(103,93,198)",
  },
}));

const modalStylePreview = {
  width: '90vw',
  height: '85vh',
  position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
  bgcolor: "white",
  // borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  overflow: "scroll",
};

const modalPreview = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: "white"

};


const monthList = [{
  "id": "1",
  "value": "Jan"
}, {
  "id": "2",
  "value": "Feb"
}, {
  "id": "3",
  "value": "Mar"
}, {
  "id": "4",
  "value": "Apr"
}, {
  "id": "5",
  "value": "May"
}, {
  "id": "6",
  "value": "Jun"
}, {
  "id": "7",
  "value": "Jul"
}, {
  "id": "8",
  "value": "Aug"
}, {
  "id": "9",
  "value": "Sep"
}, {
  "id": "10",
  "value": "Oct"
}, {
  "id": "11",
  "value": "Nov"
}, {
  "id": "12",
  "value": "Dec"
}];
// var loggedInUserInfoSession = JSON.parse(sessionStorage.getItem("Basic_info")) || {};
// console.log("loggedInUserInfoSession", loggedInUserInfoSession)
const SubjectivePapers = () => {
  const pageDiv = useRef(null);
  var serial_number = 0;
  // console.log("SubjectivePapers")
  const token = sessionStorage.getItem("token");
  const classes = useStyles();
  const [state, setState] = useState({
    raised: false,
    shadow: 1,
    id: 0,
  });
  const [selectedValue, setSelectedValue] = useState("a");
  const [visibleQuestionModal, setVisibleQuestionModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showSuccessQstnMessage, setshowSuccessQstnMessage] = useState("");
  const [showSuccessQstnMessageEnable, setshowSuccessQstnMessageEnable] = useState(false);

  const [userLoggedIn, SetUserLoggedIn] = useState(JSON.parse(sessionStorage.getItem("Basic_info")) || {})
  const [isBtnEnabled, setIsBtnEnabled] = useState(false);
  const [isCreateBtnEnabled, setIsCreateBtnEnabled] = useState(false);
  const [isBtnEnabledReplace, setIsBtnEnabledReplace] = useState(false);
  const [isBtnEnabledOwn, setIsBtnEnabledOwn] = useState(false);
  const [role, setRole] = useState(null);
  useEffect(() => {
    const roleFromLocalStorage = localStorage.getItem('role');
    setRole(roleFromLocalStorage);
  }, []);

  const [showerror, setShowerror] = useState(false);
  const [showmarkingValue, setshowmarkingVlaue] = useState(false);
  const [showerrorMessage, setShowerrorMessage] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [subjectListHOP, setSubjectListHOP] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [historyOfPapersData, setHistoryOfPapersData] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [showHistoryOfPapers, setShowHistoryOfPapers] = useState(false);
  const [tabsValue, setTabsValue] = useState("1");
  const [selectedHistoryGrade, setSelectedHistoryGrade] = useState("");
  const [selectedHistorySubject, setSelectedHistorySubject] = useState("");
  const [yearList, setYearList] = useState([]);
  const [yearListMenu, setYearListMenu] = useState([]);
  // const [monthList, setmonthList] = useState(monthsArray);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedYearId, setSelectedYearId] = useState("");
  const [selectedmonth, setSelectedMonth] = useState("");
  const [chapterList, setChapterList] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedsubjectName, setSelectedsubjectName] = useState([]);
  const [papertypeList, setPapertypeList] = useState([]);
  const [papertypeListMenu, setPapertypeListMenu] = useState([]);
  const [listOfLevel, setListOfLevel] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");



  const [titleError, setTitleError] = useState(false);
  const [papertypeError, setPapertypeError] = useState(false);
  const [blueprintLevelId, setblueprintlevelid] = useState("")
  const [blueprintId, setblueprintid] = useState("")
  const [QisSelected, setQIsSelected] = useState(false);
  const [qstnpaperData, setQstnPaperData] = useState([]);
  const [QstnPaperFormData, setQstnPaperFormData] = useState({});
  const [SelectedQuestionId, setSelectedQuestionId] = useState(null)
  const [SelectedQuestionIdIndex, setSelectedQuestionIdIndex] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({});
  const [editState, setEditState] = useState({
    isEdit: false,
    id: 0,
  });
  const [title, setTitle] = useState('');
  const [selectedPaper, setSelectedPaper] = useState("")
  const [selectedOption, setSelectedOption] = useState(null);
  const [radioSelection, setRadioSelection] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedQuestionData, setSelectedQuestionData] = useState({})
  const [selectedQuestionOptionData, setselectedQuestionOptionData] = useState([])
  const [newQuestion, setnewQuestion] = useState({})

  const [bluePrintD, setBluePrintD] = useState([])
  const [bluePrintDDropDown, setBluePrintDDropDown] = useState([])
  const [selectedPaperName, setSelectedPaperName] = useState()
  const [selectedBluePrint, setSelectedBluePrint] = useState([])
  const [chosenQuestion, setChosenQuestion] = useState(null)
  const [selectedUrl, setSelectedUrl] = useState("");

  const [historyPapers, setHistoryPapers] = useState([])
  const [pendingPapers, setPendingPapers] = useState([])
  const [uploadedPapers, setUploadedPapers] = useState([])
  const [draftedPapers, setDraftedPapers] = useState([])
  const [approvedPapers, setApprovedPapers] = useState([])
  const [rejectedPapers, setRejectedPapers] = useState([])
  const [filteredHistoryPapers, setFilteredHistoryPapers] = useState([])

  const [newlyCreatedQstnId, setNewlyCreatedQstnId] = useState("")
  const [subjectivePaperID, setSubjectivePaperID] = useState("")
  const [savedqstnResponse, setSavedQstnPaperResponse] = useState({})
  const [showQuestnPaperPage, SetshowQuestnPaperPage] = useState(false);

  const [showTermQstnPaperNotEnabled, SetshowTermQstnPaperNotEnabled] = useState(true);
  const [showPeriodicQstnPaperNotEnabled, SetshowPeriodicQstnPaperNotEnabled] = useState(true);
  const [showPracticeQstnPaperNotEnabled, SetshowPacticeQstnPaperNotEnabled] = useState(true);

  const [ChapterListError, setChapterListError] = useState(false);
  const [istitleEmpty, setistitleEmpty] = useState(false);
  const [boardList, setBoardList] = useState([])
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [IsPendingClicked, setIsPendingClicked] = useState(false);
  const [IsApproveRejectEnabled, setIsApproveRejectEnabled] = useState(false);
  const [selectedPaperOfHOP, setselectedPaperOfHOP] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [previewData, setPreviewData] = useState({})

  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [rejectReason, setrejectReason] = useState('');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isRejectScreenOpen, setIsRejectScreenOpen] = useState(false);
  const [error, setError] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorGrade, setErrorGrade] = useState('');
  const [errorLevel, setErrorLevel] = useState('');
  const [errorSubject, setErrorSubject] = useState('');
  const [errorBluePrint, seterrorBluePrint] = useState('');
  const [CreateAlert, setCreateAlert] = useState("");
  const [showDownloadPDF, setshowDownloadPDF] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [hidethesiccessMessage, setHidetheSuccessMessage] = useState(false);
  const [OpenPreviewComponent, SetOpenPreviewComponent] = useState(false)

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const popupOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const popupContentStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "5px",
    padding: "25px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  };


  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); setIsBtnEnabled(false); }
  const handleCloseQstnpaper = () => setShowPopup(false)
  const handleClose1 = () => SetOpenPreviewComponent(false);



  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };


  const handleRadioChange = (id, question, qdata, options, QIndex) => {
    setIsBtnEnabled(false);
    setIsBtnEnabledReplace(false);
    // console.log("handleradiochange", id, question, selectedOptions, qdata, options);
    setSelectedQuestionData(qdata);
    setSelectedQuestionId(id);
    setSelectedQuestionIdIndex(QIndex)
    setselectedQuestionOptionData(options);
    setSelectedOptions(prevSelectedOptions => {
      const updatedOptions = {};

      updatedOptions[question] = id;

      Object.keys(prevSelectedOptions).forEach(prevQuestion => {
        if (prevQuestion !== question) {
          updatedOptions[prevQuestion] = null;
        }
      });
      // console.log("updatedOptions", updatedOptions)
      return updatedOptions;
    });
  };

  useEffect(() => {
    SetUserLoggedIn(JSON.parse(sessionStorage.getItem("Basic_info")) || {});
  }, [])
  useEffect(async () => {
    // v1/api/content/paper-type/all


    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        // console.log(res.data.data, "year data list")
        setYearList(res?.data?.data || [])
        setYearListMenu(res?.data?.data || [])
        if (res?.data?.data?.length > 0) {
          setSelectedYearId(res.data.data[0]?.id)
          setSelectedYear(res.data.data[0]?.academicYear)
        }
      })
      .catch(err => console.log(err))
    // console.log("result", result)


    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/paper-type/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("paper type", response?.data?.data || [], tabsValue)
    var primarypapertype = response?.data?.data?.filter(v => v.paperType == 'Term') || []
    setPapertypeListMenu(primarypapertype)
    setSelectedPaper(primarypapertype[0]?.id || "")
    setPapertypeList(response?.data?.data || [])

  }, [])
  const setblueprintData = async (schoolId, branchId, boardId, gradeId, subjectId) => {
    // alert("blueprint detail called")
    // console.log("boardId, gradeId, subjectId", boardId, gradeId, subjectId)

    // sortBy for createdAt
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint/page?pageSize=1000`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("setbluprintlevelid data", response?.data?.data)
    return response?.data?.data
  }

  const handleTitleChange = (event) => {
    // console.log("event.target.value", event.target.value, title)
    setTitle(event.target.value);
  };
  // v1/api/master/chapters/all-chapters?subjectId
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const tabsChangeHandle = (event, newValue) => {
    // console.log("tab change", event, newValue, typeof newValue, event.target.value, papertypeList, yearList)
    setTabsValue(newValue);
    setShowEditBtn(false);
    setSelectedIds([])
    setSelectedGrade("");
    setSelectedLevel("");
    // console.log("setSelectedLevel 5", "")
    setSelectedsubjectName("");
    setSelectedSubject("");
    setSelectedMonth("");
    setSelectedPaperName("")
    setTitle("");
    setShowPrint(false);
    setIsBtnEnabled(false);
    setIsBtnEnabledOwn(false);
    setIsBtnEnabledReplace(false);
    setIsCreateBtnEnabled(false);
    if (newValue == "1" || newValue == 1) {
      SetshowTermQstnPaperNotEnabled(true)
    } else if (newValue == "2" || newValue == 2) {
      SetshowPeriodicQstnPaperNotEnabled(true)
    } else {
      SetshowPacticeQstnPaperNotEnabled(true)
    }

    if (papertypeList?.length > 0) {
      var filteredPaperTypeList = []
      if (newValue == "1" || newValue == 1) {
        filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Term");
      } else if (newValue == "2" || newValue == 2) {
        filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Periodic");
      } else {
        filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Practice");
      }
      // console.log("tab selection ", newValue, papertypeList, filteredPaperTypeList, filteredPaperTypeList[0]?.id)
      setSelectedPaper(filteredPaperTypeList[0]?.id)
      setPapertypeListMenu(filteredPaperTypeList)
    }
    if (yearList?.length > 0) {
      // selectedYear
      setSelectedYearId(yearList[0]?.id)
      setSelectedYear(yearList[0]?.academicYear)
    }

  };
  useEffect(() => {
    // GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const handleChange = (event) => {
    // console.log(event.target.value, "handleChange")
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const fetchBoardData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response?.data?.data)
    setBoardList(response?.data?.data || []);
    // console.log("response?.data?.data", response?.data?.data);
  };

  const fetchLevelData = async () => {
    var planid = "";
    // v1/api/content/blueprint-level/with-or-without/id
    var teacherID = sessionStorage.getItem("teacherId");
    // console.log("teacherID", teacherID, selectedGrade)
    const id = teacherID;
    // const response = await axios.get(
    //   // https://dev-be-lms.azvasa.onlinev1/api/content/blueprint-level/with-or-without/ids?ids=4028938b8b2438e2018b274fd38a0002
    //   //  v1/api/content/blueprint-level/with-or-without/ids?ids=${id}
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint-level
    //   `,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    // setListOfLevel(response?.data?.data?.levels || []);
    var userLoggedIn = JSON.parse(sessionStorage.getItem("Basic_info"))
    // console.log("userLoggedIn", userLoggedIn)
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/blueprint-level-details?branchId=${userLoggedIn?.branch}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("listof leveldata", response?.data?.data);
    setListOfLevel(response?.data?.data || []);
  };

  const fetchSubjectData = async (gradeId) => {
    // console.log("fetchSubjectData called")
    // const response1 = await axios.get(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    // console.log(response1.data.data, "old===========")

    var teacherID = sessionStorage.getItem("teacherId");
    // console.log("teacherID", teacherID, selectedGrade)
    const id = teacherID;
    var GRADEID = gradeId ? gradeId : selectedGrade;
    // console.log("GRADEID", GRADEID);
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${GRADEID}&menuName=ASSIGN_QUIZ`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => console.log(err));
    // console.log(response?.data?.data, "=========2")

    // console.log("b4 4-7-24", response?.data?.data);
    response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || [];
    // console.log("aftr",response?.data?.data);


    response?.data?.data?.forEach(item => {
      item.id = item.subjectId;
    });
    // console.log(response.data.data, "=========3");

    // const id = teacherId.id;
    // const response1 = await axios
    //   .get(
    //     `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${gradId}&sectionId=${secId}&menuName=ASSIGN_QUIZ`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   .catch((err) => console.log(err));
    // console.log(response1.data.data, "=========2")
    return response?.data?.data || [];
  };
  const fetchGradeData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("b4", response?.data.data);
    var teacherID = sessionStorage.getItem("teacherId");
    // console.log("teacherID", teacherID)
    var VV1data = response?.data.data
    var vvData = []
    const id = teacherID;
    await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((responseV) => {
        // console.log("requiredasdasdasdasd ", responseV.data.data);
        vvData = responseV.data.data || []
      })
      .catch((err) => {
        // console.log(err);
      });

    function matchGrades(a, b) {
      var matches = [];
      b.forEach(function (bElement) {
        a.forEach(function (aElement) {
          if (bElement.grade === aElement.grade) {
            matches.push(bElement);
          }
        });
      });
      return matches;
    }

    var matchingArray = matchGrades(vvData, VV1data);
    // console.log(matchingArray, "VV1data");
    return matchingArray

    // var teacherID = sessionStorage.getItem("teacherId");
    // console.log("teacherID", teacherID)
    // const id = teacherID;
    // var final = []
    // await axios
    //   .get(
    //     `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   .then((responseV) => {
    //     // console.log("required ", responseV.data.data);
    //     // return response?.data?.data || [];
    //     responseV?.data?.data.forEach(item => {
    //       item.description = item.grade;
    //     });
    //     // console.log("required1", responseV?.data?.data)
    //     final = responseV?.data?.data
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //     final = []
    //   });
    // console.log("final", final, final || response?.data.data)
    // return final || response?.data.data

  };
  const fetchHistoryOfPapers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/page`,

        {
          params: { pageSize: 1000, branchId: sessionStorage.getItem("branchId"), schoolId: sessionStorage.getItem("schoolId") },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("history records", response?.data.data)
      return response?.data.data || [];
    } catch (error) {
      // console.log(error, error?.message)
    }
  };
  const fetchUploadedPapers = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/page`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("history records", response?.data.data)
    return response?.data.data || [];
  };

  useEffect(async () => {
    const subject = await fetchSubjectData();
    const grade = await fetchGradeData();
    const Board = await fetchBoardData();
    await fetchLevelData();
    // console.log("subject24-4-24", subject, grade,)
    const bluePrint = await setblueprintData();
    // console.log("bluePrint", bluePrint)
    setBluePrintD(bluePrint)
    setSubjectList(subject);
    setGradeList(grade);

  }, []);
  const nameHandler = (name) => {
    const data = name.split("/")?.[name.split("/").length - 1]?.split(".");
    return `${data[0]}.${data?.[data.length - 1]}`;
  };
  const CreateQuestionPaper = async (subjectID) => {
    var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
    setChapterList([]);
    // console.log("setchapterlist", selectedGrade, selectedSubject, selectedYear, selectedmonth, basic_info, basic_info?.boardId, selectedSubTopic);
    // console.log("danger", subjectList, selectedsubjectName, selectedSubject, subjectID, chapterList)
    var selectedSubTopic = "";
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/master/chapters/all-chapters?subjectId=${subjectID || selectedSubject}&gradeId=${selectedGrade}&boardId=${basic_info?.boardId}${isNOTNullOrUndefined(selectedSubTopic)
          ? `&subTopicId=${selectedSubTopic}`
          : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {
        if (response.status === 200) {
          // console.log(response?.data?.data, "11-3-24");
          response.data.data = await CommonSort(response?.data?.data || [], "chapter", false);
          setChapterList(response?.data?.data);
          setQuestionData(response?.data?.data)
        } else {
          setChapterList([]);
          // console.log(response, "11-3-24")
        }
      });
  }
  // const handleRadioChange = (id, question) => {
  //   // console.log("idddddddddddddd", id, question)
  //   // console.log("id", id, selectedIds)
  //   // Check if the ID is already in the selectedIds array
  //   const isSelected = selectedIds.includes(id);

  //   // If selected, remove from the array; otherwise, add to the array
  //   if (isSelected) {
  //     setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
  //   } else {
  //     setSelectedIds([...selectedIds, id]);
  //   }
  // };
  const handleCheckboxChange = (event, id) => {
    // console.log(event.target.checked, id, selectedIds)
    if (event.target.checked) {
      // Add the selected ID to the state
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      // Remove the unselected ID from the state
      setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((selectedId) => selectedId !== id));
    }
  }
  const providemyown = async () => {
    // console.log(SelectedQuestionId)
    setIsBtnEnabledOwn(true);
    sessionStorage.removeItem("parentId");
    if (SelectedQuestionId) {
      setVisibleQuestionModal(true)
      setShowHistoryOfPapers(false);
      // console.log(selectedQuestionData, qstnpaperData, QstnPaperFormData, QstnPaperFormData?.data?.subjectId, "----ok", selectedQuestionData?.questionTypeId)
      sessionStorage.setItem("questionType", selectedQuestionData?.questionTypeId)
      sessionStorage.setItem("subjectId", selectedSubject)
      sessionStorage.setItem("conceptId", selectedQuestionData?.conceptId)
      // sessionStorage.setItem("subQuestionConceptId", selectedQuestionData?.subConceptId);
      sessionStorage.setItem("subConceptId", selectedQuestionData?.subConceptId);
      sessionStorage.setItem("rootConceptId", selectedQuestionData?.rootConceptId !== null ? selectedQuestionData?.rootConceptId : "");
      sessionStorage.setItem("taxonomyCategory", selectedQuestionData?.taxonomyCategoryId);
      sessionStorage.setItem("taxonomy", selectedQuestionData?.taxonomyId);
      sessionStorage.setItem("subjectId", QstnPaperFormData?.subjectId || QstnPaperFormData?.data?.subjectId)
      sessionStorage.setItem("matchType", selectedQuestionData?.matchType);
      sessionStorage.setItem("QuestionType", selectedQuestionData?.questionType);
      sessionStorage.setItem("questionPattern", selectedQuestionData?.questionPattern)
      sessionStorage.setItem("subjectiveType", selectedQuestionData?.subjectiveType);
      setIsBtnEnabledOwn(false);
    } else {
      setShowerror(true);
      setShowerrorMessage("Select Question to proceed further");
      setIsBtnEnabledOwn(false);
      setTimeout(() => {
        setShowerror(false)
      }, 10000);
    }
  }

  const extractIds = (inputData) => {
    const result = [];
    inputData.forEach((section) => {
      if (section.data) {
        section.data.forEach((item) => {
          if (item.id) {
            result.push(item.id);
          }
        });
      }
    });
    return result;
  };

  const findObjectById = async (id, inputData, additionalObject) => {
    let replaceableObj = {};

    // Find and prepare replaceable question object and options
    if (additionalObject.questionOptions?.length > 0) {
      const replaceOptions = additionalObject.questionOptions.filter(v => v.questionId === additionalObject.questions[0].id) || [];
      // console.log("replaceOptionsreplaceOptions", replaceOptions)
      replaceableObj = { ...additionalObject.questions[0], options: replaceOptions[0]?.options || [] };
      // console.log("additionalobject", additionalObject, SelectedQuestionIdIndex, SelectedQuestionId)
      if (additionalObject.questions[0].subQuestions?.length > 0) {
        replaceableObj.subQuestions = additionalObject.questions[0].subQuestions.map(subQ => {
          const subOptions = additionalObject.questionOptions.filter(v => v.questionId === subQ.id) || [];
          // console.log("subOptionssubOptions", subOptions)
          return { ...subQ, options: subOptions[0]?.options || [] };
          // seltd?.questionNumber + "." + (subindex + 1)
        });
      }
    }

    let isReplaced = false;

    // Find and replace the question object in the inputData structure
    for (const section of inputData) {

      if (section?.data) {
        const foundObjectIndex = section.data.findIndex(item => item.id === id);

        if (foundObjectIndex !== -1) {
          section.data[foundObjectIndex] = replaceableObj;
          // console.log(inputData, section, "879999999999999999999999")
          // Update QstnPaperFormData and UI state variables
          const updatedQstnPaperFormData = { ...QstnPaperFormData, questionPaperBody: section.data };
          // console.log("updatedQstnPaperFormData", updatedQstnPaperFormData, inputData)
          setQstnPaperFormData({});
          setQstnPaperData([]);
          setQstnPaperData(inputData);
          SetshowPacticeQstnPaperNotEnabled(false);
          SetshowPeriodicQstnPaperNotEnabled(false);
          SetshowTermQstnPaperNotEnabled(false);

          // Set final state and call async functions
          setQstnPaperFormData(updatedQstnPaperFormData);
          isReplaced = true;
          break;  // Exit loop once replacement is done
        }
      }
    }

    // Only proceed with async calls if a replacement occurred
    if (isReplaced) {
      await handleSaveClick(QstnPaperFormData, inputData, true, 1);
      await exceptionMsg("Question replaced successfully");
    }

    // console.log("Final inputData", inputData);
  };



  const exceptionMsg = async (msg) => {
    setshowSuccessQstnMessageEnable(true);
    setshowSuccessQstnMessage(msg);
    setTimeout(() => {
      setshowSuccessQstnMessageEnable(false)
    }, 10000);
  }

  const replaceSelected = async (VqstnpaperData, VQstnPaperFormData) => {
    // console.log("savedqstnResponse", VqstnpaperData)

    if (Object.keys(savedqstnResponse).length == 0 && !selectedPaperOfHOP) {
      // console.log("=========================", showerrorMessage)
      setShowerror(true);
      setShowerrorMessage("Save Question Paper first to replace question");
      setIsBtnEnabledReplace(false);
      setTimeout(() => {
        setShowerror(false)
      }, 10000);

    } else {
      setIsBtnEnabledReplace(true);
      // console.log("replacing929", QstnPaperFormData, SelectedQuestionId, savedqstnResponse, selectedPaperOfHOP,"VQstnPaperFormData",VQstnPaperFormData)
      var selectedbluprint = bluePrintD?.data?.filter(v => v.paperTitle == selectedPaperName)
      var idsArray = extractIds(qstnpaperData);
      idsArray = idsArray.filter(id => id !== SelectedQuestionId);
      idsArray = [...idsArray, SelectedQuestionId]
      if (SelectedQuestionId) {
        var item = {
          "id": savedqstnResponse?.data?.id || VQstnPaperFormData?.id || SelectedQuestionId,// "402892888ed0f2bc018ef12815b2036e", //selectedbluprint[0]?.id,
          "replacedQuestionCount": QstnPaperFormData?.data?.questionFrequency || VQstnPaperFormData?.questionFrequency,
          "replacingQuestionId": SelectedQuestionId,
          "visitedQuestions": idsArray
        }
        // console.log("itemitem",item)
        let result = await axios
          .put(
            `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/question-list/for-replacing`,
            item,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (response) => {
            // console.log("response for-replacing", response, response?.data?.data[0]);
            // console.log("for-replacing response", qstnpaperData);

            if (response.status == "204" || response.status == 204 || response.status == 404) {
              setShowerror(true)
              setShowerrorMessage(response.message || response.statusText);
              setIsBtnEnabledReplace(false);
              setTimeout(() => {
                setShowerror(false)
              }, 10000);
            } else {
              if (response?.data?.data?.length > 0) {
                const foundObject = await findObjectById(SelectedQuestionId, qstnpaperData, response?.data?.data[0]);
                // console.log(foundObject, "foundObject", qstnpaperData,);
                // console.log(QstnPaperFormData);
                setIsBtnEnabledReplace(false);
              } else {
                // You’ve already reached the limit
                setShowerror(true)
                setShowerrorMessage("You’ve already reached the limit");
                setIsBtnEnabledReplace(false);
                setTimeout(() => {
                  setShowerror(false)
                }, 10000);
              }
              // setPSuccess(response.success);
            }
          }).catch(err => {
            setShowerror(true);
            setShowerrorMessage(err?.response?.data?.message || "Error in Question replace");
            setIsBtnEnabledReplace(false);
            setTimeout(() => {
              setShowerror(false);
              setShowerrorMessage("");
            }, 10000);
          })
      } else {
        setShowerror(true);
        setShowerrorMessage("Select Question to proceed further");
        setTimeout(() => {
          setShowerror(false)
        }, 10000);
      }
    }
  }
  const stripHtmlTags = (html) => {
    // alert(html)
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    // console.log(tmp.textContent || tmp.innerText || "")
    return tmp.textContent || tmp.innerText || "";
  }
  let iwillprovideownCallback = (res) => {
    // console.log("iwillprovideownCallback called", res);

  }

  let getResponse = async (res) => {
    setHidetheSuccessMessage(true);
    // console.log(res)
    setVisibleQuestionModal(false);
    sessionStorage.removeItem("parentId");

    setshowSuccessQstnMessage("Own Question Provided Successfully");
    setshowSuccessQstnMessageEnable(true)
    res.options = res?.options[0] || res?.options;

    // console.log(res, res.urlofimage, res?.payload?.markList, "swe>>>>>>>>>>>>>>>>> getResponse", qstnpaperData, selectedQuestionData, SelectedQuestionId)
    setSubjectivePaperID(res.id)
    var nestedObject = {}, replacementObject = {};
    const targetId = SelectedQuestionId;
    var jsonArray = qstnpaperData
    const sectionIndex = jsonArray.findIndex(section => section.data.some(obj => obj.id === targetId));
    if (sectionIndex !== -1) {
      const dataIndex = jsonArray[sectionIndex].data.findIndex(obj => obj.id === targetId);
      const convertedStructure = res?.payload?.markList?.map((item, index) => ({
        [`Order_${index + 1}`]: {
          order: index + 1,
          answerKey: item.step,
          htmlTag: null,
          mark: parseInt(item?.marks) // Convert marks to integer
        }
      }));
      if (convertedStructure) {
        // console.log("convertedStructure", convertedStructure)
        const result = Object.assign({}, ...convertedStructure);
        // console.log(result, "convertedStructureconvertedStructure", dataIndex);
      }
      if (dataIndex !== -1) {
        // jsonArray[sectionIndex].data[dataIndex].question = stripHtmlTags(res.question);
        // jsonArray[sectionIndex].data[dataIndex].imageUrl = res.urlofimage;
        jsonArray[sectionIndex].data[dataIndex] = res;
        const modifiedObject = jsonArray[sectionIndex].data[dataIndex];
        jsonArray[sectionIndex].data.splice(dataIndex, 1, modifiedObject);
      }
    }
    // console.log(jsonArray, "setQstnPaperData jsonArrayjsonArray");
    setQstnPaperData(jsonArray)
    setSelectedQuestionId("");
    setSelectedQuestionIdIndex(null)
    setshowSuccessQstnMessage("Own Question Provided Successfully!!");

    setShowHistoryOfPapers(false)//showHistoryOfPapers

    await handleSaveClick(QstnPaperFormData, jsonArray, true, 2)


    setTimeout(() => {
      setshowSuccessQstnMessage("");
      setshowSuccessQstnMessageEnable(false);
    }, 5000);
    // const retrievedObject = findReplaceObject(qstnpaperData?.data?.questionPaperBody, SelectedQuestionId);
    // const updatedNestedObject = findReplaceObject(qstnpaperData?.data?.questionPaperBody, SelectedQuestionId, replacementObject);
    // // console.log(updatedNestedObject);
    // console.log(retrievedObject);
  }
  let findReplaceObject = (nestedObject, targetId, replacement) => {
    for (let section of nestedObject) {
      for (let selectedQuestion of section.selectedQuestions) {
        // console.log("selectedQuestion", selectedQuestion)
        if (selectedQuestion) {
          if (selectedQuestion?.selectedQuestions) {
            var sq = selectedQuestion?.selectedQuestions
            for (let question of sq) {
              // console.log("question564", question)
              if (question.id === targetId) {
                if (replacement) {
                  // Replace the object with the replacement
                  const index = sq.findIndex(q => q.id === targetId);
                  if (index !== -1) {
                    selectedQuestion.selectedQuestions[index] = replacement;
                    return nestedObject;
                  }
                } else {
                  // Return the object matching the ID
                  return question;
                }
              }
            }
          }
        }
      }
    }
    // If the target ID is not found, return null
    return null;
  }
  const approveQstnPAper = async () => {

    // console.log("approve api called", selectedBluePrint, selectedPaperOfHOP, selectedPaperOfHOP?.id, savedqstnResponse, savedqstnResponse?.data?.id)
    var item = {
      "id": selectedPaperOfHOP?.id || savedqstnResponse?.data?.id,
      "status": "APPROVED",
      "comment": "APPROVED"
    }
    try {
      const result = await axios.put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/review`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("result approve", result);
      setIsApproveRejectEnabled(true);
      setshowSuccessQstnMessageEnable(true);
      setshowSuccessQstnMessage("Question Paper approved Successfully")//bcz msg not rlvnt
      setShowPrint(true);
      setShowHistoryOfPapers(true)
      setTimeout(() => {
        openHistoryofPaper();
        if (tabsValue == "1" || tabsValue == 1) {
          SetshowTermQstnPaperNotEnabled(true)
        } else if (tabsValue == "2" || tabsValue == 2) {
          SetshowPeriodicQstnPaperNotEnabled(true)
        } else {
          SetshowPacticeQstnPaperNotEnabled(true)
        }
      }, 500);

      setTimeout(() => {
        setshowSuccessQstnMessageEnable(false);
        setshowSuccessQstnMessage("")
      }, 10000);
    } catch (err) {
      // console.log(err, "error inapproval");
      setShowerror(true)
      setShowerrorMessage(err?.data?.message || "Error in approval")
      setTimeout(() => {
        setShowerror(false);
        setShowerrorMessage("");
      }, 10000);
    }
  }

  const rejectQstnPaper = async () => {

    // console.log("reject qstn api called", rejectReason, selectedPaper, savedqstnResponse);
    var item = {
      "id": selectedPaperOfHOP?.id || savedqstnResponse?.data?.id,
      "status": "REJECTED",
      "comment": rejectReason
    }
    if (rejectReason !== "" && rejectReason !== null) {
      try {
        const result = await axios.put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/review`,
          item,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("result reject", result)
        setIsApproveRejectEnabled(false);
        setIsRejectScreenOpen(false);
        setshowSuccessQstnMessageEnable(true);
        setshowSuccessQstnMessage("Question Paper rejected Successfully")//bcz msg not rlvnt

        // setShowPrint(false)
        setShowHistoryOfPapers(true)
        openHistoryofPaper();
        setTimeout(() => {
          setshowSuccessQstnMessageEnable(false);
          setshowSuccessQstnMessage("");
        }, 10000);

      } catch (err) {
        // console.log(err, "error in rejection");
      }
    }
  }
  const showMarking = async () => {
    // console.log("replaceSelected", SelectedQuestionId);
    // if(showMarking){
    setshowmarkingVlaue(!showmarkingValue)
    // }else{
    // setshowmarkingVlaue(!showMarking)
    // }
    // if (chosenQuestion) {
    // if (showmarkingValue == true) {

    // setshowmarkingVlaue(!showmarkingValue)
    // } 
    // else {
    //   setshowmarkingVlaue(true)
    // }

    // } else {
    // setShowerror(true);
    // setshowmarkingVlaue(!showmarkingValue)
    // setShowerrorMessage("Select Question to proceed further");
  }
  const reopenQstnPaperGeneration = async (paper, tabsValue) => {
    // console.log("pending click",tabsValue)
    try {
      setIsPendingClicked(true)
      setselectedPaperOfHOP(paper);
      setShowEditBtn(false);
      setIsApproveRejectEnabled(false);
      setIsApproveRejectEnabled(false)
      setPreviewData(paper)
      setSelectedGrade(paper?.gradeId)
      // console.log("reopenQstnPaperGeneration", paper, tabsValue, JSON.parse(sessionStorage.getItem("Basic_info")) || {})
      var loggedInUserInfo = JSON.parse(sessionStorage.getItem("Basic_info")) || {};
      setSelectedBluePrint(paper?.blueprintLevelId)
      setShowHistoryOfPapers(false)
      await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/view/${paper?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {

          if (tabsValue == "0" || tabsValue == 0) {
            SetshowPacticeQstnPaperNotEnabled(false);
          } else if (tabsValue == "1" || tabsValue == 1) {
            SetshowTermQstnPaperNotEnabled(false)
          } else {
            SetshowPeriodicQstnPaperNotEnabled(false)
          }

          res.data.data.splittedHeading = res?.data?.data?.heading.split("\n")
          var result = {
            data: res?.data?.data
          }
          // console.log("res", res.data);
          var availableOptions = result.data?.data?.questionOptions || result.data?.questionOptions
          setQstnPaperFormData(res?.data?.data)
          setTitle(null);
          var tempArr = []
          res?.data?.data?.questionPaperBody?.map((sectionData, sectionIndex) => {
            // console.log(sectionData)
            tempArr.push({ section: sectionData?.section, text: sectionData?.text })
            sectionData?.selectedQuestions.map((seltd, sindex) => {
              // console.log("seltd", seltd, seltd?.internalChoiceOrder)
              seltd?.selectedQuestions?.map((data, index) => {
                var options = availableOptions.filter((e) => e.questionId == data?.id) || [];
                var correctResponse = options
                options = options[0]?.options?.[0] || [];
                data.internalChoiceOrder = seltd?.internalChoiceOrder?.join(",") || ""
                data.options = options;
                data.serialNumber = seltd?.questionNumber;
                data.correctResponse = correctResponse?.length > 0 ? correctResponse[0]?.correctResponse : {};
                if (data?.subQuestions?.length > 0) {
                  var SubQstns = [];
                  data?.subQuestions?.map((subData, subindex) => {
                    var suboptn = availableOptions.filter((e) => e.questionId == subData?.id) || [];
                    // console.log("sub question", subData?.question, suboptn, subData?.id, availableOptions)
                    subData.serialNumber = seltd?.questionNumber + "." + (subindex + 1)
                    subData.options = suboptn[0]?.options[0] || [];
                    SubQstns.push(subData);
                  });
                  data.subQuestions = SubQstns
                }
                tempArr[sectionIndex].data = tempArr[sectionIndex]?.data || [];
                tempArr[sectionIndex].data.push(data);
              })
            })
          })
          // console.log("setQstnPaperData tempArr", tempArr)
          setQstnPaperData(tempArr)
          setTimeout(async () => {
            // await handleSaveClickWithoutMessage(res?.data?.data, tempArr, true, 5);
            // document.getElementById('save-btn').click();
          }, 200);
        })
        .catch(err => {
          // console.log(err, err.message, tabsValue);
          setShowHistoryOfPapers(true);
          setShowerror(true);
          setShowerrorMessage(err.message);
          setQstnPaperData([])
          setTimeout(() => {
            setShowerror(false)
          }, 10000);
          return
        })

    } catch (err) {
      // console.log(err, "error in question paper generation");
      setQstnPaperData([]);
      setQstnPaperFormData({});
      if (tabsValue == "0" || tabsValue == 0) {
        SetshowPacticeQstnPaperNotEnabled(true);
      } else if (tabsValue == "1" || tabsValue == 1) {
        SetshowTermQstnPaperNotEnabled(true)
      } else {
        SetshowPeriodicQstnPaperNotEnabled(true)
      }
      setShowerror(true);
      setShowerrorMessage(err?.response?.data?.message);
      setQstnPaperData([])
      setTimeout(() => {
        setShowerror(false)
      }, 10000);
    }

  }



  const createBtn1 = async () => {
    setQstnPaperData(QstnData?.data);
    if (tabsValue == "1") {
      SetshowTermQstnPaperNotEnabled(false)
    } else if (tabsValue == "2") {
      SetshowPeriodicQstnPaperNotEnabled(false)
    } else {
      SetshowPacticeQstnPaperNotEnabled(false)
    }
  }
  const createBtn = async () => {

    setIsCreateBtnEnabled(true);
    setHidetheSuccessMessage(true);
    // console.log('createBtn called', bluePrintDDropDown, tabsValue, typeof tabsValue, title, "title", title == "", selectedIds.length)
    // console.log(selectedBluePrint, selectedGrade, selectedSubject, selectedsubjectName, selectedmonth, selectedPaperName);
    setSelectedOptions({});
    setSelectedQuestionId("");
    setSelectedQuestionIdIndex(null)
    setShowEditBtn(false);
    if (title == "") {
      setistitleEmpty(true);
      setCreateAlert("*Title Mandatory")
      setTimeout(() => {
        setCreateAlert("");
        setCreateAlert(false);

      }, 10000);
      return
    } else {
      setistitleEmpty(false)
      setCreateAlert("");
      
    }
    if (selectedGrade == "") {
      setCreateAlert("*Grade Mandatory");
      setTimeout(() => {
        setCreateAlert("");
        setCreateAlert(false);
        setIsCreateBtnEnabled(false);
      }, 10000);
      return
    } else {
      setCreateAlert("");
      
    }
    if (selectedSubject == "") {
      setCreateAlert("*Subject Mandatory");
      setTimeout(() => {
        setCreateAlert("");
        setCreateAlert(false);
        // setIsBtnEnabled(false);
        // setIsBtnEnabledOwn(false);
        // setIsBtnEnabledReplace(false);
        // console.log("setting false here");setIsCreateBtnEnabled(false);
      }, 10000);
      return
    } else {
      setCreateAlert("");
      // setCreateAlert(false);
      // setIsBtnEnabled(false);
      // setIsBtnEnabledOwn(false);
      // setIsBtnEnabledReplace(false);
      
    }

    if (selectedLevel == "") {
      setCreateAlert("*Level Mandatory");
      setTimeout(() => {
        setCreateAlert("");
        setCreateAlert(false);

      }, 10000);
      return
    } else {
      setCreateAlert("");
      
    }

    if (selectedBluePrint == "" || !selectedBluePrint && bluePrintDDropDown?.length == 0) {
      setCreateAlert("*No relevant Blueprint associated");
      setTimeout(() => {
        setCreateAlert("");
        setCreateAlert(false);

      }, 10000);
      return
    } else {
      setCreateAlert("");
      setCreateAlert(false);
      
    }
    if (selectedmonth == "") {
      setCreateAlert('*Month Mandatory');
      setTimeout(() => {
        setCreateAlert("");
        setCreateAlert(false);
        // setIsBtnEnabled(false);
        // setIsBtnEnabledOwn(false);
        // setIsBtnEnabledReplace(false);
        // console.log("setting false here");setIsCreateBtnEnabled(false);
      }, 10000);
      return
    } else {
      setCreateAlert('');
      
    }
    if (selectedIds.length == 0) {
      // setChapterListError(true)
      setCreateAlert("Choose one chapter atleast");
      setTimeout(() => {
        setCreateAlert("");
        setCreateAlert(false);
        // setIsBtnEnabled(false);
        // setIsBtnEnabledOwn(false);
        // setIsBtnEnabledReplace(false);
        // console.log("setting false here");setIsCreateBtnEnabled(false);
      }, 10000);
    } else {
      // setChapterListError(false)
      setCreateAlert("")
      
    }
    if (selectedIds.length > 0 && title && bluePrintDDropDown?.length > 0) {
      var selectedBluePrint = bluePrintDDropDown[0]

      var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
      var grade_information = JSON.parse(sessionStorage.getItem("grade_info"))
      var DATA = await setblueprintData(basic_info.schoolId, basic_info.branchId, basic_info.boardId, selectedGrade, selectedSubject);

      var bbdata = DATA?.data?.filter((v) => v.gradeId === selectedGrade && v.boardId === basic_info.boardId && v.subjectId === selectedSubject) || [];

      var item = {
        "schoolId": basic_info?.school,
        "branchId": basic_info?.branch,
        "blueprintLevelId": selectedBluePrint?.blueprintLevelId,
        "boardId": basic_info?.boardId,
        "academicYearId": selectedYearId,
        "gradeId": selectedGrade,
        "chapterIds": selectedIds,
        "month": selectedmonth || "Nov",
        "subjectId": selectedSubject,
        "paperTypeId": selectedBluePrint?.paperTypeId,//selectedPaper,
        "questionPaperName": title
      }
      try {
        const result = await axios.put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/generate/question-paper`,
          item,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("ddd", result);
        result.data.data.splittedHeading = result?.data?.data?.heading.split("\n");
        result.splittedHeading = result?.data?.data?.heading.split("\n");
        // console.log("eee", result?.data?.data);
        setTitle(result?.data?.data?.questionPaperName)

        setQstnPaperFormData(result.data)
        sessionStorage.setItem("QstnPaperFormData", JSON.stringify(result?.data))
        var tempArr = []
        result?.data?.data?.questionPaperBody?.map((sectionData, sectionIndex) => {
          // console.log(sectionData)
          tempArr.push({ section: sectionData?.section, text: sectionData?.text })
          sectionData?.selectedQuestions.map((seltd, sindex) => {
            // console.log("seltd", seltd, seltd?.internalChoiceOrder)
            seltd?.selectedQuestions?.map((data, index) => {
              var options = result.data?.data?.questionOptions.filter((e) => e.questionId == data?.id) || [];
              var correctResponse = options
              options = options[0]?.options?.[0] || [];
              data.internalChoiceOrder = seltd?.internalChoiceOrder?.join(",") || ""
              data.options = options;
              data.serialNumber = seltd?.questionNumber;
              data.correctResponse = correctResponse?.length > 0 ? correctResponse[0]?.correctResponse : {};
              if (data?.subQuestions?.length > 0) {
                var SubQstns = [];
                data?.subQuestions?.map((subData, subindex) => {
                  var suboptn = result.data?.data?.questionOptions.filter((e) => e.questionId == subData?.id) || [];
                  // console.log("sub question", subData?.question, suboptn)
                  subData.serialNumber = seltd?.questionNumber + "." + (subindex + 1)
                  subData.options = suboptn[0]?.options[0] || [];
                  SubQstns.push(subData);
                });
                data.subQuestions = SubQstns
              }
              tempArr[sectionIndex].data = tempArr[sectionIndex]?.data || [];
              tempArr[sectionIndex].data.push(data);
            })
          })
        })
        // console.log("setQstnPaperData tempArr", tempArr);
        setQstnPaperData(tempArr)/// QstnData?.data
        sessionStorage.setItem("tempArr", JSON.stringify(tempArr))
        //  setQstnPaperData(QstnData?.data)/// QstnData?.data
        try {
          // console.log(result.data, tempArr, "1111111111111111111111111111")
          setTimeout(async () => {
            await handleSaveClick(result.data, tempArr, true, 3);
          }, 500);
        } catch (error) {
          // console.error("Error while saving createbtn:", error);
          setIsCreateBtnEnabled(false);
          setHidetheSuccessMessage(false);
          setCreateAlert(error?.response?.data?.message || "ERROR IN QUESTION PAPER CREATION");
          setTimeout(() => {
            setCreateAlert("")
          }, 10000);
        }


      } catch (err) {
        // console.log(err, "error in question paper generation");
        setShowerror(true);
        setIsCreateBtnEnabled(false);
        setShowerrorMessage(err?.response?.data?.message);
        setCreateAlert(err?.response?.data?.message || "ERROR IN QUESTION PAPER CREATION");
        setTimeout(() => {
          setCreateAlert("")
        }, 10000);
        setQstnPaperData([]);
        setTimeout(() => {
          setShowerror(false);
        }, 10000);
        return
      }
    } else {
      setIsCreateBtnEnabled(false);
      if (title == "" || title == null) {
        setTitleError(true)
      } else {
        setTitleError(false);
      }
    }
  }

  const DownloadPDF = async (htmlContent) => {
    // console.log(selectedUrl, htmlContent)
    var element = await document.getElementById('pdf_preview_term');

    var opt = {
      margin: 0.40,
      filename: 'document.pdf',
      image: { type: 'jpeg', quality: 0.20 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
      pagebreak: { mode: ['avoid-all', 'css'] }
    };
    html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setLineWidth(0.025);
          // Top border
          pdf.line(0.4, 0.4, 7.9, 0.4);
          // Bottom border
          pdf.line(0.4, 11.4, 7.9, 11.4);
          // Left border
          pdf.line(0.4, 0.4, 0.4, 11.4);
          // Right border
          pdf.line(7.9, 0.4, 7.9, 11.4)
        }
        pdf.save(); // Save the PDF
      })
      .catch(() => {
        alert('Error generating PDF.');
      });
    // .save();

    // doc.fromHTML($('#pdf_preview_term_inner_div').html(), 15, 15, {
    //   'width': 170,
    //   'elementHandlers': specialElementHandlers
    // });
    // doc.save('sample-file.pdf');
    // html2canvas(document.querySelector('#pdf_preview_term'), {
    //   useCORS: true,
    //   allowTaint: true,
    // }).then(function (canvas) {
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   // console.log("img.src", img.src)
    //   img.onload = function () {
    //     let pdf = new jsPDF('p', 'mm', 'a4');
    //     pdf.addImage(img, 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
    //     pdf.save('document.pdf');
    //   };
    // });
  };
  const handleSaveClickWithoutMessage = async (frmData, qstn, permission, n) => {
    // console.log(frmData, qstn, "22222222222222222222222", permission, hidethesiccessMessage, n)
    // console.log("handleSaveClick called", qstnpaperData, selectedPaperOfHOP, IsPendingClicked, savedqstnResponse, blueprintLevelId, blueprintId)
    var item = {}
    if (IsPendingClicked) {
      // console.log("IsPendingClicked save called", qstnpaperData);
      var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
      // console.log('Save button clicked!', basic_info, qstnpaperData);
      var USER_ID = localStorage.getItem("id");
      var formedQstns = [], temp_serial_number = 0;
      var tempqstnpaperData = qstnpaperData?.length > 0 ? qstnpaperData : JSON.parse(sessionStorage.getItem("tempArr")) || qstn
      tempqstnpaperData?.map((v1) => {
        v1?.data?.map((v2) => {
          temp_serial_number = temp_serial_number + 1
          var value = {
            "questionId": v2?.id,
            "hasSubQuestions": v2?.hasSubQuestions,
            "questionPattern": v2?.questionPattern,
            "subjectiveType": v2?.subjectiveType,
            "questionTypeCode": v2?.questionTypeCode,
            "section": v1?.section,
            "slNo": temp_serial_number,
            "hasInternalChoice": (v2?.internalChoiceOrder !== "" && v2?.internalChoiceOrder !== null) ? true : false,
            "internalChoiceOrder": v2?.internalChoiceOrder
          }
          if (v2?.hasSubQuestions) {
            var subvalueArr = [];
            v2.subQuestions?.map((v3) => {
              var subvalue = {
                "questionId": v3?.id,
                "hasSubQuestions": v3?.hasSubQuestions,
                "questionPattern": v3?.questionPattern,
                "subjectiveType": v3?.subjectiveType,
                "questionTypeCode": v3?.questionTypeCode,
                "section": v1?.section,
                "slNo": temp_serial_number,
                "hasInternalChoice": (v3?.internalChoiceOrder !== "" && v3?.internalChoiceOrder !== null) ? true : false,
                "internalChoiceOrder": v3?.internalChoiceOrder
              }
              subvalueArr.push(subvalue);
            });
            value["subQuestions"] = subvalueArr;
          }
          formedQstns.push(value)
        })
      })
      // console.log("qstnpaperDataselectedPaperOfHOP", qstnpaperData, selectedPaperOfHOP)
      item = {
        "schoolId": basic_info?.schoolId || basic_info?.school,
        "branchId": basic_info?.branchId || basic_info?.branch,
        "boardId": basic_info.boardId,
        "gradeId": frmData?.gradeId,
        "subjectId": frmData?.subjectId,
        "blueprintLevelId": frmData?.blueprintLevelId,
        "paperTypeId": selectedPaperOfHOP?.paperTypeId,
        "questionPaperName": frmData?.questionPaperName,
        "totalMark": selectedPaperOfHOP?.totalMark,
        "examDurationInMin": selectedPaperOfHOP?.examDurationInMin,
        "academicYearId": selectedPaperOfHOP?.academicYearId,
        "month": frmData?.month,
        "preview": selectedPaperOfHOP?.preview,
        "teacherId": selectedPaperOfHOP?.teacherId,
        "questionsUsed": formedQstns,
        "blueprintId": selectedPaperOfHOP?.blueprintId,
        "changedQuestionCount": selectedPaperOfHOP?.questionFrequency
      };
      // console.log(selectedPaperOfHOP?.id)
      if (selectedPaperOfHOP?.id) {
        item.id = selectedPaperOfHOP?.id
      }
      // console.log("item", item);
    } else {
      var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
      var tempQstnPaperFormData = Object.keys(QstnPaperFormData).length == 0 ? frmData || QstnPaperFormData : JSON.parse(sessionStorage.getItem("QstnPaperFormData"))
      // console.log('Save button clicked!', tempQstnPaperFormData, qstnpaperData, QstnPaperFormData, frmData, savedqstnResponse, savedqstnResponse?.data?.id);
      var USER_ID = localStorage.getItem("id");
      var formedQstns = [], temp_serial_number = 0;
      var tempqstnpaperData = qstnpaperData?.length > 0 ? qstnpaperData : JSON.parse(sessionStorage.getItem("tempArr")) || qstn
      tempqstnpaperData?.map((v1) => {
        v1?.data?.map((v2) => {
          // console.log(v2?.hasSubQuestions, v2.subQuestions)
          temp_serial_number = temp_serial_number + 1
          var value = {
            "questionId": v2?.id,
            "hasSubQuestions": v2?.hasSubQuestions,
            "questionPattern": v2?.questionPattern,
            "subjectiveType": v2?.subjectiveType,
            "questionTypeCode": v2?.questionTypeCode,
            "section": v1?.section,
            "slNo": temp_serial_number,
            "hasInternalChoice": (v2?.internalChoiceOrder !== "" && v2?.internalChoiceOrder !== null) ? true : false,
            "internalChoiceOrder": v2?.internalChoiceOrder
          }
          if (v2?.hasSubQuestions) {
            var subvalueArr = [];
            v2.subQuestions?.map((v3) => {
              var subvalue = {
                "questionId": v3?.id,
                "hasSubQuestions": v3?.hasSubQuestions,
                "questionPattern": v3?.questionPattern,
                "subjectiveType": v3?.subjectiveType,
                "questionTypeCode": v3?.questionTypeCode,
                "section": v1?.section,
                "slNo": temp_serial_number,
                "hasInternalChoice": (v3?.internalChoiceOrder !== "" && v3?.internalChoiceOrder !== null) ? true : false,
                "internalChoiceOrder": v3?.internalChoiceOrder
              }
              subvalueArr.push(subvalue);
            });
            value["subQuestions"] = subvalueArr;
          }
          formedQstns.push(value)
        })
      })
      var selectedBluePrint = bluePrintDDropDown[0];
      // console.log("selectedBluePrint14-5-24", selectedBluePrint, QstnPaperFormData)
      item = {
        "schoolId": basic_info?.schoolId || basic_info?.school,
        "branchId": basic_info?.branchId || basic_info?.branch,
        "boardId": basic_info.boardId,
        "gradeId": frmData?.gradeId,
        "subjectId": frmData?.subjectId,
        "blueprintLevelId": frmData?.blueprintLevelId,
        "paperTypeId": frmData?.paperTypeId,
        "questionPaperName": frmData?.questionPaperName,
        "totalMark": frmData?.totalMark,
        "examDurationInMin": frmData?.examDurationInMin,
        "academicYearId": selectedYearId,
        "month": frmData?.month,
        "preview": "<h1>" + frmData?.questionPaperName + "</h1>",
        "teacherId": USER_ID,
        "questionsUsed": formedQstns,
        "blueprintId": frmData?.blueprintId,
        "changedQuestionCount": frmData?.questionFrequency
      };
      if (frmData?.id) {
        item.id = frmData.id
      }
    }
    // console.log("susupect item", item)
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers`, item,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("successfully saves", response.data.data, response.data.message, hidethesiccessMessage, permission, !hidethesiccessMessage, !permission)
        setSavedQstnPaperResponse(response.data);
        SetshowQuestnPaperPage(true);
        if (tabsValue == "1") {
          SetshowTermQstnPaperNotEnabled(false)
        } else if (tabsValue == "2") {
          SetshowPeriodicQstnPaperNotEnabled(false)
        } else {
          SetshowPacticeQstnPaperNotEnabled(false)
        }
      })
      .catch(err => {
        // console.log(err, "error in save", tabsValue);
        // console.log(err.response.data, err.response.data.message)

        if (tabsValue == "0" || tabsValue == 0) {
          SetshowPacticeQstnPaperNotEnabled(true);
        } else if (tabsValue == "1" || tabsValue == 1) {
          SetshowTermQstnPaperNotEnabled(true);
        } else {
          SetshowPeriodicQstnPaperNotEnabled(true);
        }
        setCreateAlert(err?.response?.data?.message || "Error in Question paper generation")
        setShowerror(true);
        setShowerrorMessage(err?.response?.data?.message);
        setTimeout(() => {
          setShowerror(false);
          setCreateAlert("");
        }, 10000);
      });
    // console.log("result", result)
  }
  const handleSaveClick = async (frmData, qstn, permission, n) => {
    // console.log(IsPendingClicked, "IsPendingClicked", frmData, title, qstn, qstnpaperData, selectedBluePrint, selectedPaperOfHOP, n)
    var item = {}
    if (IsPendingClicked) {
      // console.log("IsPendingClicked save called", qstnpaperData);
      var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
      // console.log('Save button clicked!', basic_info, qstnpaperData);
      var USER_ID = localStorage.getItem("id");
      var formedQstns = [], temp_serial_number = 0;
      var tempqstnpaperData = qstnpaperData?.length > 0 ? qstnpaperData : JSON.parse(sessionStorage.getItem("tempArr")) || qstn;
      // console.log(tempqstnpaperData,qstnpaperData,JSON.parse(sessionStorage.getItem("tempArr")),qstn,"25-6-24")
      tempqstnpaperData?.map((v1) => {
        v1?.data?.map((v2) => {
          temp_serial_number = temp_serial_number + 1
          var value = {
            "questionId": v2?.id,
            "hasSubQuestions": v2?.hasSubQuestions,
            "questionPattern": v2?.questionPattern,
            "subjectiveType": v2?.subjectiveType,
            "questionTypeCode": v2?.questionTypeCode,
            "section": v1?.section,
            "slNo": temp_serial_number,
            "hasInternalChoice": (v2?.internalChoiceOrder !== "" && v2?.internalChoiceOrder !== null) ? true : false,
            "internalChoiceOrder": v2?.internalChoiceOrder
          }
          if (v2?.hasSubQuestions) {
            var subvalueArr = [];
            v2.subQuestions?.map((v3) => {
              var subvalue = {
                "questionId": v3?.id,
                "hasSubQuestions": v3?.hasSubQuestions,
                "questionPattern": v3?.questionPattern,
                "subjectiveType": v3?.subjectiveType,
                "questionTypeCode": v3?.questionTypeCode,
                "section": v1?.section,
                "slNo": temp_serial_number,
                "hasInternalChoice": (v3?.internalChoiceOrder !== "" && v3?.internalChoiceOrder !== null) ? true : false,
                "internalChoiceOrder": v3?.internalChoiceOrder
              }
              subvalueArr.push(subvalue);
            });
            value["subQuestions"] = subvalueArr;
          }
          formedQstns.push(value)
        })
      })
      // console.log("qstnpaperDataselectedPaperOfHOP", qstnpaperData, selectedPaperOfHOP)
      item = {
        "schoolId": basic_info?.schoolId || basic_info?.school,
        "branchId": basic_info?.branchId || basic_info?.branch,
        "boardId": basic_info.boardId,
        "gradeId": selectedPaperOfHOP?.gradeId,
        "subjectId": selectedPaperOfHOP?.subjectId,
        "blueprintLevelId": selectedPaperOfHOP?.blueprintLevelId,
        "paperTypeId": selectedPaperOfHOP?.paperTypeId,
        "questionPaperName": selectedPaperOfHOP?.questionPaperName,
        "totalMark": selectedPaperOfHOP?.totalMark,
        "examDurationInMin": selectedPaperOfHOP?.examDurationInMin,
        "academicYearId": selectedPaperOfHOP?.academicYearId,
        "month": selectedPaperOfHOP?.month,
        "preview": selectedPaperOfHOP?.preview,
        "teacherId": selectedPaperOfHOP?.teacherId,
        "questionsUsed": formedQstns,
        "blueprintId": selectedPaperOfHOP?.blueprintId,
        "changedQuestionCount": selectedPaperOfHOP?.questionFrequency
      };
      // console.log(selectedPaperOfHOP?.id)
      if (selectedPaperOfHOP?.id) {
        item.id = selectedPaperOfHOP?.id
      }
      // console.log("item", item);
    } else {
      var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
      var tempQstnPaperFormData = Object.keys(QstnPaperFormData).length == 0 ? frmData || QstnPaperFormData : JSON.parse(sessionStorage.getItem("QstnPaperFormData"))
      // console.log('Save button clicked!', tempQstnPaperFormData, QstnPaperFormData, frmData);
      var USER_ID = localStorage.getItem("id");
      var formedQstns = [], temp_serial_number = 0;
      var tempqstnpaperData = qstn?.length > 0 ? qstn : JSON.parse(sessionStorage.getItem("tempArr")) || qstnpaperData
      tempqstnpaperData?.map((v1) => {
        v1?.data?.map((v2) => {
          // console.log(v2?.hasSubQuestions, v2.subQuestions)
          temp_serial_number = temp_serial_number + 1
          var value = {
            "questionId": v2?.id,
            "hasSubQuestions": v2?.hasSubQuestions,
            "questionPattern": v2?.questionPattern,
            "subjectiveType": v2?.subjectiveType,
            "questionTypeCode": v2?.questionTypeCode,
            "section": v1?.section,
            "slNo": temp_serial_number,
            "hasInternalChoice": (v2?.internalChoiceOrder !== "" && v2?.internalChoiceOrder !== null) ? true : false,
            "internalChoiceOrder": v2?.internalChoiceOrder
          }
          if (v2?.hasSubQuestions) {
            var subvalueArr = [];
            v2.subQuestions?.map((v3) => {
              var subvalue = {
                "questionId": v3?.id,
                "hasSubQuestions": v3?.hasSubQuestions,
                "questionPattern": v3?.questionPattern,
                "subjectiveType": v3?.subjectiveType,
                "questionTypeCode": v3?.questionTypeCode,
                "section": v1?.section,
                "slNo": temp_serial_number,
                "hasInternalChoice": (v3?.internalChoiceOrder !== "" && v3?.internalChoiceOrder !== null) ? true : false,
                "internalChoiceOrder": v3?.internalChoiceOrder
              }
              subvalueArr.push(subvalue);
            });
            value["subQuestions"] = subvalueArr;
          }
          formedQstns.push(value)
        })
      });
      // console.log(formedQstns, blueprintId, selectedBluePrint?.id)
      var selectedBluePrint = bluePrintDDropDown[0];
      // console.log("selectedBluePrint14-5-24", selectedBluePrint, QstnPaperFormData, title)
      item = {
        "schoolId": basic_info?.schoolId || basic_info?.school,
        "branchId": basic_info?.branchId || basic_info?.branch,
        "boardId": basic_info.boardId,
        "gradeId": selectedGrade,
        "subjectId": selectedSubject,
        "blueprintLevelId": selectedBluePrint?.blueprintLevelId || blueprintLevelId,
        "paperTypeId": selectedPaper,
        "questionPaperName": title,
        "totalMark": tempQstnPaperFormData?.data?.totalMark || tempQstnPaperFormData?.totalMark,
        "examDurationInMin": tempQstnPaperFormData?.data?.examDurationInMin || tempQstnPaperFormData?.examDurationInMi,
        "academicYearId": selectedYearId,
        "month": selectedmonth,
        "preview": "<h1>" + tempQstnPaperFormData?.data?.questionPaperName + "</h1>",
        "teacherId": USER_ID,
        "questionsUsed": formedQstns,
        "blueprintId": blueprintId || selectedBluePrint?.id,
        "changedQuestionCount": tempQstnPaperFormData?.data?.questionFrequency
      };
      if (savedqstnResponse?.data?.id) {
        item.id = savedqstnResponse?.data?.id
      }
      // console.log("susupect item else", item, title)
    }
    // console.log("susupect item", item)
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers`, item,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("successfully saves", response.data.data, response.data.message, hidethesiccessMessage, permission, !hidethesiccessMessage, !permission)
        setSavedQstnPaperResponse(response.data);
        // console.log("response.data=======", response.data);
        // PreviewQstnPaper(response.data.data, true)

        SetshowQuestnPaperPage(true);
        if (!permission) {//false
          setshowSuccessQstnMessageEnable(true);
          setshowSuccessQstnMessage(response?.data?.message || "Successfully saved the subjective paper.");
          setIsBtnEnabled(false);
          setTimeout(() => {
            setshowSuccessQstnMessageEnable(false);
            setIsCreateBtnEnabled(false);
          }, 10000);
        } else {
          setHidetheSuccessMessage(false);
          setIsCreateBtnEnabled(false); setIsBtnEnabled(false);
        }

        if (tabsValue == "1") {
          SetshowTermQstnPaperNotEnabled(false)
        } else if (tabsValue == "2") {
          SetshowPeriodicQstnPaperNotEnabled(false)
        } else {
          SetshowPacticeQstnPaperNotEnabled(false)
        }
       
      })
      .catch(err => {
        // console.log(err, "error in save", tabsValue);
        // console.log(err.response.data, err.response.data.message)

        if (tabsValue == "0" || tabsValue == 0) {
          SetshowPacticeQstnPaperNotEnabled(true);
        } else if (tabsValue == "1" || tabsValue == 1) {
          SetshowTermQstnPaperNotEnabled(true);
        } else {
          SetshowPeriodicQstnPaperNotEnabled(true);
        }
        setCreateAlert(err?.response?.data?.message || "Error in Question paper generation")
        setShowerror(true);
        setIsBtnEnabled(false);
        setIsCreateBtnEnabled(false);
        setShowerrorMessage(err?.response?.data?.message);
        setTimeout(() => {
          setShowerror(false);
          setCreateAlert("");
        }, 10000);
      });
    // console.log("result", result)

  };

  // const PreviewQstnPaper = async () => {
  //   // console.log("PreviewQstnPaper called");
  //   const element = document.getElementById('pdf_preview');
  //   // console.log("element17424", element);
  //   element.style.display = 'block';
  //   element.style.width = '100%'; // Adjust as needed
  //   element.style.height = '100%'; // Adjust as needed

  //   html2canvas(element, {
  //     scrollX: 0,
  //     scrollY: 0,
  //     width: element.scrollWidth,
  //     height: element.scrollHeight
  //   }).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'px',
  //       format: [element.scrollWidth, element.scrollHeight]
  //     });
  //     const width = pdf.internal.pageSize.getWidth();
  //     const height = pdf.internal.pageSize.getHeight();
  //     pdf.addImage(imgData, 'PNG', 0, 0, width, height);
  //     pdf.save("screenshot.pdf");
  //   }).catch(error => {
  //     console.error('Error generating PDF:', error);
  //   });
  // }
  // const PreviewQstnPaper = async () => {
  //   // console.log(tabsValue)
  //   var ele = ""
  //   if (tabsValue == 0 || tabsValue == "0") {
  //     ele = document.getElementById('pdf_preview_practice')
  //   } else if (tabsValue == 1 || tabsValue == "1") {
  //     ele = document.getElementById('pdf_preview_term')
  //   } else {
  //     ele = document.getElementById('pdf_preview_periodic')
  //   }
  //   // console.log("pdf_preview_periodic", ele)
  //   const element = ele;
  //   element.style.display = 'block';
  //   element.style.width = '100%'; // Adjust as needed
  //   element.style.height = '100%'; // Adjust as needed

  //   // Function to hide elements by ID
  //   const hideElementsById = (ids) => {
  //     ids.forEach(id => {
  //       const el = document.getElementById(id);
  //       if (el) {
  //         el.style.display = 'none';
  //       }
  //     });
  //   };

  //   // IDs of elements to exclude from the PDF preview
  //   const excludedIds = ['show-marking-btn', 'provide-own-btn', 'replace-btn', 'preview-btn', 'save-btn', 'back-btn'];

  //   // Hide excluded elements before generating the canvas
  //   hideElementsById(excludedIds);

  //   html2canvas(element, {
  //     scrollX: 0,
  //     scrollY: 0,
  //     width: element.scrollWidth,
  //     height: element.scrollHeight
  //   }).then(canvas => {
  //     // Show excluded elements after canvas generation
  //     excludedIds.forEach(id => {
  //       const el = document.getElementById(id);
  //       if (el) {
  //         el.style.display = 'block'; // or any other appropriate display style
  //       }
  //     });

  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'px',
  //       format: [element.scrollWidth, element.scrollHeight]
  //     });
  //     const width = pdf.internal.pageSize.getWidth();
  //     const height = pdf.internal.pageSize.getHeight();
  //     pdf.addImage(imgData, 'PNG', 0, 0, width, height);

  //     // Generate Blob URL for the PDF
  //     const pdfBlob = pdf.output('blob');
  //     const pdfBlobUrl = URL.createObjectURL(pdfBlob);

  //     // Open the PDF in a new window for preview
  //     const previewWindow = window.open(pdfBlobUrl, '_blank');
  //     if (!previewWindow) {
  //       alert('Popup blocked! Please allow popups for this website.');
  //     }
  //   }).catch(error => {
  //     console.error('Error generating PDF:', error);
  //   });
  // };

  //uploadeddddddddddddddd
  const uploadedTerm = async () => {

    try {
      const basic_info = JSON.parse(sessionStorage.getItem("Basic_info")) || {};
      const schoolId = basic_info?.school;
      const branchId = basic_info?.branch;

      // https://dev-be-lms.azvasa.online/v1/api/content/assessment/paper/assigned?pageNumber=0&pageSize=500&schoolId=4028938b8978605e0189864db5230000&branchId=4028938b8978605e018986503da60001
      // await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/assigned?pageNumber=0&pageSize=500&schoolId=${schoolId}&branchId=${branchId}`,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`
      //     }
      //   }
      // ).then((res) => {
      //   // console.log("res uploaded term", res?.data?.data.data)
      //   return res?.data?.data.data || [];
      // }).catch(v => { console.log(v, "*********1") })


      let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/assigned?pageNumber=0&pageSize=1000&schoolId=${schoolId}&branchId=${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          // console.log(res.data.data, "year data list");
          return res?.data?.data?.data || []

        })
        .catch(err => console.log(err))
    } catch (err) {
      // console.log('error fetching data', err);
      return []
    }
  }
  const ViewUploadedTermPapers = async (paper) => {

    // console.log("ViewUploadedTermPapers", paper)
    var loggedInUserInfo = JSON.parse(sessionStorage.getItem("Basic_info")) || {};
    // console.log("loggedInUserInfo", loggedInUserInfo)
    try {
      await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/upload/${paper?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          var paperUrl = res?.data?.data?.paperUrl;
          if (paperUrl && paperUrl.endsWith(".pdf")) {
            setSelectedUrl(paperUrl)
            // console.log("res", paperUrl)
            SetOpenPreviewComponent(true)
          } else {
            setshowSuccessQstnMessageEnable(true);
            setshowSuccessQstnMessage("The file must be a PDF.");
            setTimeout(() => {
              setshowSuccessQstnMessageEnable(false);
              setshowSuccessQstnMessage("")
            }, 10000);
          }

        })
        .catch(err => console.log(err))

    } catch (err) {
      // console.log(err, "error in question paper generation");
      setShowerror(true);
      setShowerrorMessage(err?.response?.data?.message);
      setQstnPaperData([])
      setTimeout(() => {
        setShowerror(false)
      }, 10000);
    }
  }
  const PreviewQstnPaper = async (paper, create = false) => {
    setSelectedUrl("");
    // console.log("reopenQstnPaperGeneration", savedqstnResponse?.data?.id, "||", paper?.id, "||", previewData?.id, savedqstnResponse?.data?.id, "||", paper?.id, "||", previewData?.id)
    var loggedInUserInfo = JSON.parse(sessionStorage.getItem("Basic_info")) || {};
    // console.log("loggedInUserInfo", loggedInUserInfo)
    // if (loggedInUserInfo.designation !== "Teacher") {
    // console.log("if executed", savedqstnResponse?.data?.id || paper?.id, savedqstnResponse?.data?.id, paper?.id);

    try {

      await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/view/${savedqstnResponse?.data?.id || paper?.id || previewData?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          // console.log("res", res?.data?.data)
          // console.log("res", res?.data?.data?.heading.split("\n"))
          res.data.data.splittedHeading = res?.data?.data?.heading.split("\n")
          // res.data.data.heading.split("\n").map((instruction) => instruction)
          // console.log("res", res?.data?.data)
          var result = {
            data: res?.data?.data
          }
          // console.log("result", result, result.data?.data?.questionOptions);
          var availableOptions = result.data?.data?.questionOptions || result.data?.questionOptions
          setQstnPaperFormData(res?.data?.data)
          var tempArr = []
          res?.data?.data?.questionPaperBody?.map((sectionData, sectionIndex) => {
            // console.log(sectionData)
            tempArr.push({ section: sectionData?.section, text: sectionData?.text })
            sectionData?.selectedQuestions.map((seltd, sindex) => {
              // console.log("seltd", seltd, seltd?.internalChoiceOrder)
              seltd?.selectedQuestions?.map((data, index) => {
                var options = availableOptions?.filter((e) => e.questionId == data?.id) || [];
                // console.log(options, "options");
                var correctResponse = options
                options = options[0]?.options?.[0] || [];
                data.internalChoiceOrder = seltd?.internalChoiceOrder?.join(",") || ""
                data.options = options;
                data.serialNumber = seltd?.questionNumber;
                data.correctResponse = correctResponse?.length > 0 ? correctResponse[0]?.correctResponse : {}
                if (data?.subQuestions?.length > 0) {
                  var SubQstns = [];
                  data?.subQuestions?.map((subData, subindex) => {
                    var suboptn = availableOptions?.filter((e) => e.questionId == subData?.id) || [];
                    // console.log("sub question", subData?.question, suboptn)
                    subData.serialNumber = seltd?.questionNumber + "." + (subindex + 1)
                    subData.options = suboptn[0]?.options[0] || [];
                    SubQstns.push(subData);
                  });
                  data.subQuestions = SubQstns
                }
                tempArr[sectionIndex].data = tempArr[sectionIndex]?.data || [];
                tempArr[sectionIndex].data.push(data);
              })
            })
          })
          // console.log("setQstnPaperData tempArr123", tempArr)
          setQstnPaperData(tempArr)
          if (!create) {
            SetOpenPreviewComponent(true);
          }

        })
        .catch(err => console.log(err))

    } catch (err) {
      // console.log(err, "error in question paper generation");
      setShowerror(true);
      setShowerrorMessage(err?.response?.data?.message);
      setQstnPaperData([])
      setTimeout(() => {
        setShowerror(false)
      }, 10000);
    }

  };

  const getIncrementedSerialNumber = (serial_number) => {
    return serial_number + 1;
  };


  const handleBlueprintSelect = (selectedPaperName) => {
    if (selectedPaperName) {
      const newSelect = bluePrintD.data.find(item => item.paperTitle === selectedPaperName)
      setSelectedBluePrint(newSelect)
    }
  }
  const EditMethod = async () => {
    // console.log("EditMethod", showTermQstnPaperNotEnabled, tabsValue)
    // SetshowTermQstnPaperNotEnabled(true)
    setShowEditBtn(false)
  }

  const openHistoryofPaper = async (gid, sid) => {
    setIsBtnEnabledReplace(false);
    setIsBtnEnabled(false);
    setSelectedQuestionId(null)
    setSelectedQuestionIdIndex(null)
    // console.log(showTermQstnPaperNotEnabled, showPeriodicQstnPaperNotEnabled, showPracticeQstnPaperNotEnabled, "openHistoryofPaper")
    setSavedQstnPaperResponse({});
    // setShowEditBtn(true);
    setIsApproveRejectEnabled(false)
    // console.log("onclick open history of papers", selectedHistorySubject, sid, selectedHistoryGrade, gid, subjectList, gradeList)
    setShowHistoryOfPapers(true)
    const historyDataPapers = await fetchHistoryOfPapers();
    var uploadedHistory = []//await uploadedTerm();

    const basic_info = JSON.parse(sessionStorage.getItem("Basic_info")) || {};
    const schoolId = basic_info?.school;
    const branchId = basic_info?.branch;

    await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/assigned?pageNumber=0&pageSize=1000&schoolId=${schoolId}&branchId=${branchId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    ).then((res) => {
      // console.log("res uploaded term", res?.data?.data?.data)
      uploadedHistory = res?.data?.data?.data || [];
    }).catch(v => { console.log(v, "*********1") })
    // console.log('hiss', uploadedHistory);
    const uploadedData = await fetchUploadedPapers()
    setHistoryPapers(historyDataPapers)
    setUploadedPapers([]);
    // console.log("historyPapers", historyDataPapers, historyPapers, selectedHistoryGrade, selectedHistorySubject)
    var historyPapers = historyDataPapers;
    // console.log("historyPapers", historyPapers, gid, sid)
    if (gid && sid) {
      const selectedHistory = historyPapers?.data.filter(data => data.gradeId === gid && data.subjectId === sid);
      const pendingSelectedHistory = uploadedHistory.filter(data => data.gradeId === gid && data.subjectId === sid);
      setFilteredHistoryPapers(selectedHistory);
      // console.log(selectedHistory, "selectedHistory", pendingSelectedHistory);
      const pending = selectedHistory?.filter(data => data.reviewStatus === 'PENDING');
      const approved = selectedHistory?.filter(data => data.reviewStatus === 'APPROVED');
      const rejected = selectedHistory?.filter(data => data.reviewStatus === 'REJECTED');
      // console.log("2-5-24----", historyPapers?.data, pending)
      setPendingPapers(pendingSelectedHistory);
      setApprovedPapers(approved);
      setRejectedPapers(rejected);
      setDraftedPapers([...pending, ...rejected])

    } else {
      const pending = historyPapers?.data?.filter(data => data.reviewStatus === 'PENDING') || []
      const approved = historyPapers?.data?.filter(data => data.reviewStatus === 'APPROVED') || []
      const rejected = historyPapers?.data?.filter(data => data.reviewStatus === 'REJECTED') || [];
      const pendingSelectedHistory = uploadedHistory
      // console.log("2-5-24----", historyPapers?.data, pending, pendingSelectedHistory)
      setFilteredHistoryPapers([]);
      setPendingPapers(pendingSelectedHistory);
      setApprovedPapers(approved);
      setRejectedPapers(rejected);
      setDraftedPapers([...pending, ...rejected])
    }
  }
  const viewPDF = async (paper) => {
    // console.log("view pdf called", paper)
    setShowPopup(true)
    setshowDownloadPDF(true)
    setHistoryOfPapersData(true)
    // setSelectedUrl(paper?.pdfurl)
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/generate/question-paper/HTML/${paper.id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("successfully saves", response.data);
        setSelectedUrl(response?.data)
      })
      .catch(err => {
        // console.log(err, "error in save");
      });
    // console.log("result", result)
  }


  const getDownloadURL = async (paper) => {
    // console.log("getdownload url", paper)
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/subjective/papers/generate/question-paper/HTML/${paper.id}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("successfully saves", response.data)
        setSelectedUrl(response?.data)
      })
      .catch(err => {
        // console.log(err, "error in save");
      });

  }

  const functionToSetHistorySubject = (subjectId) => {
    // console.log("functionToSetHistorySubject", subjectId)
    setSelectedHistorySubject(subjectId);
    setTimeout(() => {
      openHistoryofPaper(selectedHistoryGrade, subjectId);
      // console.log(selectedHistorySubject, subjectId, subjectId, "swe", subjectList)
    }, 500);
  }

  const functionToSetHistoryGrade = async (gradeId) => {
    // console.log("functionToSetHistoryGrade", gradeId, subjectList)

    var filteredsubject = await fetchSubjectData(gradeId)
    setSubjectListHOP(filteredsubject)
    // console.log("vvvvvvvvvvvvvvvvvvvvvv", filteredsubject)
    setSelectedHistoryGrade(gradeId);
    setTimeout(() => {
      openHistoryofPaper(gradeId, selectedHistorySubject)
    }, 500);
  }

  function printDiv() {
    // console.log("print div called", tabsValue)
    var ID = "pdf_preview_periodic_inner_div"
    if (tabsValue == 0 || tabsValue == "0") {
      ID = 'pdf_preview_practice_inner_div';
    } else if (tabsValue == 1 || tabsValue == "1") {
      ID = 'pdf_preview_term_inner_div';
    }
    var divToPrint = document.getElementById(ID);
    // console.log("divToPrint.innerHTML", divToPrint.innerHTML)
    var newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
    newWin.document.close();
    setTimeout(function () { newWin.close(); }, 10);

    // var divToPrint = document.getElementById(ID);
    // var style = document.createElement('style');
    // style.innerHTML = `
    //     @media print {
    //         body {
    //             overflow: hidden !important;
    //         }
    //         ${ID} {
    //             overflow-y: hidden !important;
    //         }
    //     }
    // `;
    // var newWin = window.open('', 'Print-Window');
    // newWin.document.open();
    // newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
    // // newWin.document.write('<html><head><style>@media print { body, #' + ID + ' { overflow: hidden !important; } }</style></head><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
    // newWin.document.close();
    // setTimeout(function () { newWin.close(); }, 10);
  }

  // GET / v1 / api / content / question / { id }
  return (
    <section className="dashboard_section screenScrollCordinator">
      <div style={{ width: "100%" }}>
        {
          !OpenPreviewComponent ?
            <>
              <TopBar
                title={"Home"}
                nav1={"TeacherDashboard"}
                subSubTitle={"Subjective Papers"}
                modifiedDate={modifiedDate}
                role={role}
              />
              {!showHistoryOfPapers ? (
                <div
                  style={{
                    marginTop: "10px",
                    paddingRight: "33px",
                    paddingLeft: role === "COORDINATOR" ? "0px" : "0px",
                    position: "absolute",
                    right: "15px",
                    left: "15px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                    height: "100%",

                  }}
                >
                  <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
                    <TabContext value={tabsValue}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingLeft: "25px",
                          paddingRight: "25px",
                        }}
                      >
                        <TabList
                          onChange={(e, value) => tabsChangeHandle(e, value)}
                          aria-label="lab API tabs example"
                          textColor="inherit"
                          // indicatorColor="secondary"
                          TabIndicatorProps={{
                            style: {
                              background: "white",
                              height: "10px",
                              top: "50px",
                            },
                          }}
                        >
                          <Tab
                            label="Practice Papers"
                            value="0"
                            sx={{
                              fontWeight: 600,
                              background: tabsValue === "0" ? "white" : "",
                              border: tabsValue === "0" ? "1px solid #757575" : "",
                              fontSize: "0.88vw",
                              textTransform: "none",
                            }}
                          />
                          <Tab
                            label="Term Papers"
                            value="1"
                            sx={{
                              fontWeight: 600,
                              background: tabsValue === "1" ? "white" : "",
                              border: tabsValue === "1" ? "1px solid #757575" : "",
                              fontSize: "0.88vw",
                              textTransform: "none",
                            }}
                          />
                          <Tab
                            label="Periodic Papers"
                            value="2"
                            sx={{
                              fontWeight: 600,
                              background: tabsValue === "2" ? "white" : "",
                              border: tabsValue === "2" ? "1px solid #757575" : "",
                              fontSize: "0.88vw",
                              textTransform: "none",
                            }}
                          />
                        </TabList>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => openHistoryofPaper()}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              gap: "5px",
                              background: "#082fff",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <History sx={{ fontSize: "23px", color: "#ffffff" }} />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#ffffff",
                              }}
                            >
                              History of Papers
                            </span>
                          </div>
                        </div>

                      </Box>
                      {
                        (tabsValue == 0 || tabsValue == "0") &&

                        <TabPanel value="0" id="pdf_preview_practice" className="pdf_preview_practice">
                          <Paper sx={{ borderRadius: "7px", height: "100%" }}>
                            <div id="header-page"
                              style={{
                                background: "rgb(96 91 161 / 81%)",
                                padding: "15px",
                                color: "white",
                                borderTopLeftRadius: "7px",
                                borderTopRightRadius: "7px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>Practice Papers</div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                }}
                              >
                                {
                                  showEditBtn &&
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                      gap: "5px",
                                      background: "#082fff",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => { EditMethod() }}
                                  >Edit
                                  </div>
                                }
                                {
                                  (!showPracticeQstnPaperNotEnabled && !showEditBtn && !IsApproveRejectEnabled) &&
                                  <>
                                    <DarkGreenButton id="save-btn"
                                      disabled={isBtnEnabled}
                                      onClick={() => {
                                        setIsBtnEnabled(true);
                                        setHidetheSuccessMessage(false);
                                        setTimeout(() => {
                                          handleSaveClick({}, [], false, 11);
                                        }, 300);
                                      }}>Save</DarkGreenButton>
                                    <DarkYellowButton id="preview-btn" onClick={PreviewQstnPaper}>Preview</DarkYellowButton>
                                    <Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box sx={style1}>
                                        <Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                          sx={{ textAlign: 'center', fontFamily: 'Poppins' }}
                                        >
                                          Preview
                                        </Typography>
                                        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                                          <img
                                            id="image3"
                                            src={imageSrc}
                                            alt="Preview"
                                            style={{ width: '100%', maxHeight: '70vh', height: 'auto' }}
                                          />
                                        </div>
                                        <Button
                                          onClick={handleClose}
                                          sx={{
                                            position: 'absolute',
                                            right: 8,
                                            bottom: 8,
                                          }}
                                        >
                                          Close
                                        </Button>
                                      </Box>
                                    </Modal>
                                  </>
                                }
                                {
                                  (!showPracticeQstnPaperNotEnabled && !showEditBtn && !IsApproveRejectEnabled) &&
                                  <DarkOrangeButton id="cancel-btn" onClick={() => {
                                    SetshowPacticeQstnPaperNotEnabled(true);
                                    setIsPendingClicked(false);
                                    setSelectedIds([]);
                                    setSelectedGrade("");
                                    setSelectedLevel("");
                                    setSelectedsubjectName("");
                                    setSelectedSubject("");
                                    setSelectedMonth("");
                                    setSelectedPaperName("")
                                    setTitle("");
                                    setShowPrint(false);
                                    setChapterList([]);
                                    setHidetheSuccessMessage(true);
                                  }}>Cancel</DarkOrangeButton>
                                }
                                {/* {
                                showPrint &&
                                <DarkOrangeButton id="print-btn" onClick={() => { printDiv() }}>Print</DarkOrangeButton>
                              } */}
                              </div>
                            </div>
                            {
                              showPracticeQstnPaperNotEnabled &&

                              <div
                                style={{
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "10px",
                                  }}
                                >
                                  {/* <div style={{ fontSize: "16px", fontWeight: "bold" }}>Exam Paper Title Name</div> */}
                                  {/* <div>
                                    <TextField  className="text-field-subjective" id="text-field-subjective"
                                        type={"text"}
                                        placeholder={"Enter Your Title"}
                                        sx={{
                                          
                                          fontSize: "15px !important",
                                          '& input::placeholder': { // Styles for placeholder text
                                            fontSize: "13px !important",
                                            color: "gray",
                                          }
                                        }}
                                        
                                        value={title}
                                        onChange={handleTitleChange}
                                      />
                                      {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>}
                                  </div> */}



                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: "1rem",
                                      // alignItems: "center",s
                                      // justifyContent: "space-between",
                                    }}
                                  >
                                    {/* {
                          istitleEmpty &&
                          <span style={{ color: "red" }}>Enter title to proceed</span>
                        } */}
                                    {/* <TextField fullWidth className="text-field-subjective" id="text-field-subjective"
                                        type={"text"}
                                        placeholder={"Enter Your Title"}
                                        sx={{
                                          width: '100%',
                                          padding: '10px',
                                          fontSize: "15px !important",
                                          '& input::placeholder': { // Styles for placeholder text
                                            fontSize: "13px !important",
                                            color: "gray",
                                          }
                                        }}
                                        
                                        value={title}
                                        onChange={handleTitleChange}
                                      /> */}
                                    {/* {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>} */}
                                    {/* <FormControl
                                        sx={{ width: "150px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Grade
                                        </InputLabel>

                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedGrade ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedGrade}
                                          onChange={async (e) => {
                                            setSelectedGrade(e.target.value);
                                            CreateQuestionPaper(selectedSubject)
                                            setSelectedLevel("");
                                            // console.log("setSelectedLevel 6", "")
                                            // console.log(e.target.value);
                                            // console.log("gradeList", gradeList);
                                            // console.log(bluePrintD.data, "==============", bluePrintD)
                                            var bluprintBsdOnGrd = [];

                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Practice");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd.length > 0 && selectedSubject) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.gradeId === e.target.value);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList);
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject)
                                            }

                                            var sublist = await fetchSubjectData(e.target.value);
                                            // console.log("sublist", sublist, chapterList);
                                            setSubjectList(sublist)
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            setSelectedPaperName("")
                                          }}
                                        >
                                          {gradeList?.map((item) => {
                                            // console.log("Item:gradeList", item);
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.discription} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorGrade && <p style={{ color: 'red' }}>{errorGrade}</p>}
                                      </FormControl> */}
                                    <div>
                                      <TextField className="text-field-subjective"
                                        id="text-field-subjective"
                                        type="text"
                                        label="Exam Paper Title Name"
                                        placeholder="Enter Your Title"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                          sx: {
                                            color: '#e7ab3c',
                                            '&.Mui-focused': {
                                              color: '#e7ab3c',
                                            },
                                          },
                                        }}
                                        sx={{
                                          margin: '5px 0 ',
                                          width: '100%',
                                          fontSize: '15px !important',
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              borderColor: 'gray',
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#e7ab3c',
                                            },
                                            borderRadius: '8px',
                                          },
                                          '& input::placeholder': {
                                            fontSize: '13px !important',
                                            color: 'gray',
                                          },
                                          '& label': {
                                            fontSize: '15px',
                                            paddingRight: '5px',
                                            color: '#e7ab3c',
                                          },
                                        }}
                                        value={title}
                                        onChange={handleTitleChange}
                                      />
                                      {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>}
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                        alignItems: "center",

                                      }}
                                    >
                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Grade
                                        </InputLabel>

                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Grade' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={props => (
                                            <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedGrade}
                                          onChange={async (e) => {
                                            setSelectedGrade(e.target.value);
                                            CreateQuestionPaper(selectedSubject)
                                            setSelectedLevel("");
                                            // console.log("setSelectedLevel 6", "")
                                            // console.log(e.target.value);
                                            // console.log("gradeList", gradeList);
                                            // console.log(bluePrintD.data, "==============", bluePrintD)
                                            var bluprintBsdOnGrd = [];

                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Practice");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd.length > 0 && selectedSubject) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.gradeId === e.target.value);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList);
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject)
                                            }

                                            var sublist = await fetchSubjectData(e.target.value);
                                            // console.log("sublist", sublist, chapterList);
                                            setSubjectList(sublist)
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            setSelectedPaperName("")
                                          }}
                                        >
                                          {gradeList?.map((item) => {
                                            // console.log("Item:gradeList", item);
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.discription} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorGrade && <p style={{ color: 'red' }}>{errorGrade}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                        sx={{ width: "150px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Subject
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedSubject ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedSubject}
                                          onChange={(e) => {
                                            setSelectedSubject(e?.target?.value);
                                            setSelectedLevel("");
                                            //console.log("setSelectedLevel 7", "")
                                            setSelectedIds([])
                                            // console.log(e.target.value, subjectList);
                                            var idtocompare = e?.target?.value
                                            var subjectName = subjectList.find(e => e?.id === idtocompare);
                                            // console.log("subjectName", subjectName)
                                            setSelectedsubjectName(subjectName.subject);
                                            // to filter papertypelist,blueprint based on grade and subject 
                                            var bluprintBsdOnGrd = [];
                                            bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === e.target.value);
                                            // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Practice");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd?.length > 0 && selectedGrade) {
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id)
                                            }
                                            // console.log(bluprintBsdOnGrd, "10101602", e.target.value, selectedGrade, chapterList);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >

                                          {subjectList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.subject} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorSubject && <p style={{ color: 'red' }}>{errorSubject}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Subject
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Subject' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedSubject}
                                          onChange={(e) => {
                                            setSelectedSubject(e?.target?.value);
                                            setSelectedLevel("");
                                            //console.log("setSelectedLevel 7", "")
                                            setSelectedIds([])
                                            // console.log(e.target.value, subjectList);
                                            var idtocompare = e?.target?.value
                                            var subjectName = subjectList.find(e => e?.id === idtocompare);
                                            // console.log("subjectName", subjectName)
                                            setSelectedsubjectName(subjectName.subject);
                                            // to filter papertypelist,blueprint based on grade and subject 
                                            var bluprintBsdOnGrd = [];
                                            bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === e.target.value);
                                            // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Practice");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd?.length > 0 && selectedGrade) {
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id)
                                            }
                                            // console.log(bluprintBsdOnGrd, "10101602", e.target.value, selectedGrade, chapterList);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >

                                          {subjectList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.subject} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorSubject && <p style={{ color: 'red' }}>{errorSubject}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                        sx={{ width: "150px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Level
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedLevel ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedLevel}
                                          onChange={(e) => {
                                            setSelectedLevel(e?.target?.value);
                                            // console.log("setSelectedLevel 8", e.target.value)
                                            // console.log("e?.target?.value Term", e?.target?.value, listOfLevel, bluePrintD?.data)
                                            var levelData = listOfLevel?.filter(v => v.id == e?.target?.value) || []
                                            // console.log("levelData", levelData, levelData[0].levelName);
                                            var bluprintBsdOnGrd = [];
                                            if (levelData?.length > 0) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.levelName === levelData[0].levelName);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                              var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Practice");
                                              if (bluprintBsdOnGrd?.length > 0 && selectedGrade) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id);
                                                // console.log(bluprintBsdOnGrd, "11111111111111", e.target.value, selectedGrade, chapterList);
                                              }
                                              if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject);
                                                // console.log(bluprintBsdOnGrd, "22222222222222", e.target.value, selectedGrade, chapterList);
                                              }
                                            }
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            // CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {listOfLevel?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item?.levelName} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorLevel && <p style={{ color: 'red' }}>{errorLevel}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel'
                                          id="demo-simple-select-label"
                                        >
                                          Level
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Level ' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                                maxHeight: '190px',
                                                overflowY: 'auto',
                                                '&::-webkit-scrollbar': {
                                                  width: '2px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                  background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                  background: '#888',
                                                  borderRadius: '4px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                  background: '#555',
                                                },
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px',
                                                overflowY: 'auto',
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedLevel}
                                          onChange={(e) => {
                                            setSelectedLevel(e?.target?.value);
                                            // console.log("setSelectedLevel 8", e.target.value)
                                            // console.log("e?.target?.value Term", e?.target?.value, listOfLevel, bluePrintD?.data)
                                            var levelData = listOfLevel?.filter(v => v.id == e?.target?.value) || []
                                            // console.log("levelData", levelData, levelData[0].levelName);
                                            var bluprintBsdOnGrd = [];
                                            if (levelData?.length > 0) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.levelName === levelData[0].levelName);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                              var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Practice");
                                              if (bluprintBsdOnGrd?.length > 0 && selectedGrade) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id);
                                                // console.log(bluprintBsdOnGrd, "11111111111111", e.target.value, selectedGrade, chapterList);
                                              }
                                              if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject);
                                                // console.log(bluprintBsdOnGrd, "22222222222222", e.target.value, selectedGrade, chapterList);
                                              }
                                            }
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            // CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {listOfLevel?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item?.levelName} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorLevel && <p style={{ color: 'red' }}>{errorLevel}</p>}
                                      </FormControl>


                                      {/* <FormControl
                                  sx={{  width: "150px" }}
                                >
                                  <InputLabel id="demo-simple-select-label"
                                        style={{ fontSize: "18px" }}>
                                    Select Paper Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Tag" />}
                                    sx={{
                                      height: "100%",
                                      "&.Mui-selected": {
                                        backgroundColor: 'orange',
                                        color: "#FFFFFF"
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: '#fcedc2',
                                        color: "#000000",
                                      },
                                      "&:not(.Mui-selected):hover": {
                                        backgroundColor: '#fcedc2',
                                        color: '#000000',
                                      },
                                    }}
                                    style={{
                                      borderRadius: "150px",
                                      padding: "4px",
                                      color: selectedSubject ? "orange" : "black"
                                    }}
                                    size={"small"}
                                    IconComponent={(props) => (
                                      <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                      />
                                    )}
                                    value={selectedPaper}
                                    onChange={(e) => {
                                      //console.log(e?.target)
                                      setSelectedPaper(e?.target?.value);
                                      //console.log(bluePrintDDropDown)

                                      var bluprintBsdOnGrd = [];
                                      bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === selectedSubject);
                                      if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                        bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade)
                                      }
                                      if (bluprintBsdOnGrd.length > 0 && e.target.value) {
                                        bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.paperTypeId === e.target.value)

                                      }
                                      //console.log(bluprintBsdOnGrd, "after all filters", e.target.value, selectedGrade, selectedSubject);
                                      setBluePrintDDropDown(bluprintBsdOnGrd)

                                    }
                                    }
                                    disabled={selectedPaper ? true : false}
                                  >
                                    {papertypeListMenu?.map((item) => {
                                      return (
                                        <MenuItem
                                          value={item.id}
                                          key={item.id}
                                          className={dashboardContentStyles.menu_item}
                                          sx={{
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000"
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            }
                                          }}
                                        >
                                          <ListItemText primary={item.paperType} />
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>

                                </FormControl> */}
                                      {/* <FormControl
                                        sx={{ width: "150px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Year
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedGrade ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedYear}
                                          onChange={(e) => {
                                            setSelectedYear(e?.target?.value);
                                            const desiredAcademicYear = e?.target?.value
                                            // console.log("1091", e?.target?.value, yearList, desiredAcademicYear)
                                            var iid = yearList.find(item => item?.academicYear === desiredAcademicYear)
                                            // console.log(e.target.value, yearList, iid)
                                            if (iid) {
                                              setSelectedYearId(iid.id)
                                            }
                                          }}
                                          disabled={selectedYearId ? true : false}
                                        >
                                          {yearList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.academicYear}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.academicYear} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Academic Year
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Academic Year' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedYear}
                                          onChange={(e) => {
                                            setSelectedYear(e?.target?.value);
                                            const desiredAcademicYear = e?.target?.value
                                            // console.log("1091", e?.target?.value, yearList, desiredAcademicYear)
                                            var iid = yearList.find(item => item?.academicYear === desiredAcademicYear)
                                            // console.log(e.target.value, yearList, iid)
                                            if (iid) {
                                              setSelectedYearId(iid.id)
                                            }
                                          }}
                                          disabled={selectedYearId ? true : false}
                                        >
                                          {yearList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.academicYear}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.academicYear} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>

                                      {/* <FormControl
                                  sx={{  width: "150px" }}
                                >
                                  <InputLabel id="demo-simple-select-label"
                                        style={{ fontSize: "18px" }}>
                                    Select Blueprint
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Tag" />}
                                    sx={{
                                      height: "100%",
                                      "&.Mui-selected": {
                                        backgroundColor: 'orange',
                                        color: "#FFFFFF"
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: '#fcedc2',
                                        color: "#000000",
                                      },
                                      "&:not(.Mui-selected):hover": {
                                        backgroundColor: '#fcedc2',
                                        color: '#000000',
                                      },
                                    }}
                                    style={{
                                      borderRadius: "150px",
                                      padding: "4px",
                                      color: selectedPaperName ? "orange" : "black"
                                    }}
                                    size={"small"}
                                    IconComponent={(props) => (
                                      <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                      />
                                    )}
                                    value={selectedPaperName}
                                    onChange={(e) => {
                                      //console.log(e.target.value)
                                      setSelectedPaperName(e.target.value);
                                      //console.log(selectedPaperName)
                                      CreateQuestionPaper(selectedSubject)
                                      handleBlueprintSelect(e.target.value)
                                    }}
                                  >
                                    {
                                      //console.log("bluePrintDDropDown", bluePrintDDropDown, bluePrintD?.data)
                                    }
                                    {bluePrintDDropDown?.map((item) => {
                                      return (
                                        <MenuItem
                                          value={item.paperTitle}
                                          key={item.id}
                                          className={dashboardContentStyles.menu_item}
                                          sx={{
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000"
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            }
                                          }}
                                        >
                                          <ListItemText primary={item.paperTitle} />
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  {errorBluePrint && <p style={{ color: 'red' }}>{errorBluePrint}</p>}
                                </FormControl> */}
                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Month
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedmonth ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedmonth}
                                          onChange={(e) => {
                                            // console.log(e.target.value, "select month")
                                            setSelectedMonth(e.target.value);
                                            // console.log(selectedmonth)
                                            // CreateQuestionPaper(selectedSubject)
                                          }}
                                        >
                                          {monthList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.value}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.value} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel'
                                          id="demo-simple-select-label"
                                        >
                                          Month
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Month ' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                                maxHeight: '190px',
                                                overflowY: 'auto',
                                                '&::-webkit-scrollbar': {
                                                  width: '2px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                  background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                  background: '#888',
                                                  borderRadius: '4px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                  background: '#555',
                                                },
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px',
                                                overflowY: 'auto',
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedmonth}
                                          onChange={(e) => {
                                            // console.log(e.target.value, "select month")
                                            setSelectedMonth(e.target.value);
                                            // console.log(selectedmonth)
                                            // CreateQuestionPaper(selectedSubject)
                                          }}
                                        >
                                          {monthList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.value}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.value} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                      </FormControl>
                                    </div>
                                    
                                    <DarkYellowButton fullWidth
                                      style={{ borderRadius: "150px", height: "48px" }}
                                      disabled={isCreateBtnEnabled}
                                      onClick={() => {
                                        setIsCreateBtnEnabled(true); setTimeout(() => {
                                          createBtn()
                                        }, 300);
                                      }}
                                    >
                                      <NoteAddOutlinedIcon style={{ fontSize: "1.3vw", color: "white", backgroundColor: "#F78F02" }} />
                                      Create
                                    </DarkYellowButton>
                                  </div>
                                </div>

                                {/* {
                                  //console.log("CreateAlert", CreateAlert)
                                } */}
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  {CreateAlert && (
                                    <Alert
                                      action={
                                        <IconButton
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setCreateAlert(false);
                                            setIsBtnEnabled(false);
                                            setIsBtnEnabledOwn(false);
                                            setIsBtnEnabledReplace(false);
                                            setIsCreateBtnEnabled(false);
                                          }}
                                        >
                                          <CancelOutlinedIcon />
                                        </IconButton>
                                      }
                                      icon={false}
                                      sx={{
                                        color: "#FFFEFA",
                                        fontWeight: 500,
                                        fontSize: "0.93vw",
                                        marginRight: "10%",
                                        // background: "#bddabca1",
                                        background: "red",
                                      }}
                                    >
                                      {CreateAlert}
                                    </Alert>
                                  )}
                                </div>
                                <div
                                  style={{
                                    padding: "20px",
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    rowGap: "1rem",
                                    minHeight: "595px",
                                    overflow: "auto",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: "1rem",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        background: "rgb(227 225 225 / 61%)",
                                        padding: "10px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {selectedsubjectName}
                                    </div>
                                    {
                                      ChapterListError &&
                                      <p style={{ color: "red" }}>Choose chapter to proceed*</p>
                                    }
                                    <Box sx={{ flexGrow: 1 }}>
                                      <Grid container spacing={2}>
                                        {chapterList?.map((data, index) => {
                                          return (
                                            <Grid
                                              item
                                              xs={12}
                                              md={4}
                                              sm={6}
                                              lg={2.4}
                                              key={index}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: "1rem",
                                                  paddingTop: "5px",
                                                }}
                                                key={index}
                                              >
                                                <div
                                                  id={`az-teacher-chapter-card-${data?.chapter.toLowerCase().replace(/ /g, "_")}`}
                                                  key={data?.id}
                                                  className={dashboardContentStyles.card}
                                                >
                                                  <img
                                                    id="image4"
                                                    src={data?.thumbnailPath}
                                                    width={110}
                                                    alt={""}
                                                    // style={{ maxHeight: "138px" }}
                                                    className={dashboardContentStyles.az_quiz_card_img} />
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: 600,
                                                      color: "#423d5c",
                                                    }}
                                                  >
                                                    {data?.chapter}
                                                  </div>
                                                </div>

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      sx={{ color: 'blue' }}
                                                      color="default"
                                                      key={data?.id}
                                                      checked={selectedIds.includes(data?.id)}
                                                      onChange={(e) => handleCheckboxChange(e, data?.id)}
                                                    />
                                                  }
                                                  label="Select"
                                                  sx={{
                                                    color: "blue",
                                                    fontWeight: 600,
                                                    paddingLeft: "10px"
                                                  }}
                                                  key={data?.id}
                                                />
                                              </div>
                                            </Grid>
                                          );
                                        })}
                                      </Grid>
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              !showPracticeQstnPaperNotEnabled &&
                              <div id="pdf_preview_practice_inner_div" style={{ paddingBottom: "15px" }}>

                                {
                                  QstnPaperFormData &&
                                  <div
                                    style={{
                                      padding: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      // height: "620px",
                                      overflow: "hidden",
                                      padding: "30px",
                                      margin: "30px",
                                      borderRadius: "7px",
                                      border: "2px solid"
                                    }}
                                  >
                                    <div
                                      className="avoid-page-break"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row-reverse"
                                      }}>

                                      <img className="avoid-page-break" crossOrigin="anonymous" id="img81" src={QstnPaperFormData?.data?.branchLogo || QstnPaperFormData?.branchLogo} alt={""} height={50} />
                                    </div>
                                    <div
                                      style={{
                                        overflow: "scroll",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "1rem",
                                        padding: "30px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div style={{ width: "164px" }} />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              flexDirection: "column",
                                              rowGap: "5px",
                                              fontSize: "0.93vw",
                                            }}
                                          >
                                            <div className="question_paper_font">
                                              {(QstnPaperFormData?.data?.school || QstnPaperFormData?.school || "").toUpperCase()}
                                            </div>
                                            <div className="question_paper_font">
                                              {/* SAMSIDH MOUNT LITERA */}
                                              {QstnPaperFormData?.data?.branch || QstnPaperFormData?.branch}
                                            </div>
                                            <div
                                              className="question_paper_font"
                                            >
                                              {/* Samsidh Mount Litera, HSR Extension */}
                                              {QstnPaperFormData?.data?.schoolLocality || QstnPaperFormData?.schoolLocality}
                                            </div>
                                            <div className="question_paper_font" id="paperName">
                                              {/* Important Paper */}
                                              {/* {QstnPaperFormData?.data?.paperTitle} */}
                                              {
                                                title || QstnPaperFormData?.questionPaperName
                                              }
                                            </div>
                                            <div
                                              className="question_paper_font"
                                            >
                                              {QstnPaperFormData?.data?.subject || "Subject : " + QstnPaperFormData?.subject}
                                            </div>
                                          </div>
                                          <div style={{ width: "164px" }} />
                                          {/* <img crossOrigin="anonymous" id="img5" src={QstnPaperFormData?.data?.branchLogo || QstnPaperFormData?.branchLogo} alt={""} height={50} /> */}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            fontSize: "0.93vw",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "start",
                                              rowGap: "5px",
                                              // fontSize: "17px",
                                            }}
                                          >
                                            <div className="question_paper_font">{QstnPaperFormData?.data?.grade || QstnPaperFormData?.grade}</div>
                                            <div className="question_paper_font">Date : </div>
                                            {/* <div className="question_paper_font">Grade : {QstnPaperFormData?.data?.grade || QstnPaperFormData?.grade}</div>
                                            <div>Maximum Marks : {QstnPaperFormData?.data?.totalMark || QstnPaperFormData?.totalMark}</div> */}
                                          </div>
                                          {showMessage && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setShowMessage(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "green",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "rgba(200,255,200,0.63)",
                                              }}
                                            >
                                              Own Question Provide Successfully
                                            </Alert>
                                          )}

                                          {showSuccessQstnMessageEnable && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setshowSuccessQstnMessageEnable(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "green",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "rgba(200,255,200,0.63)",
                                              }}
                                            >
                                              {/* Successfully Saved the Question Paper */}
                                              {showSuccessQstnMessage}
                                            </Alert>
                                          )}
                                          {showerror && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setShowerror(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "blue",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "red",
                                              }}
                                            >
                                              {showerrorMessage}
                                            </Alert>
                                          )}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "end",
                                              rowGap: "5px",
                                              fontSize: "0.93vw",
                                            }}
                                          >
                                            <div className="question_paper_font">Maximum Marks : {QstnPaperFormData?.data?.totalMark || QstnPaperFormData?.totalMark}</div>
                                            <div className="question_paper_font">Duration : {QstnPaperFormData?.data?.examDurationInMin || QstnPaperFormData?.examDurationInMin} Minutes</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "1rem",
                                          fontSize: "0.93vw",
                                        }}
                                      >
                                        <div className="instructions_container">
                                          <div className="question_paper_instructions">General Instructions</div>
                                          <div>
                                            <ul className="unordered_list_format">
                                              {(QstnPaperFormData?.splittedHeading || QstnPaperFormData?.data?.splittedHeading)?.map((instruction, index) => {
                                                return (<li key={index} className="question_paper_instructions_list">{instruction}</li>)
                                              })}
                                            </ul>
                                          </div>

                                        </div>


                                        {
                                          qstnpaperData?.map((sectionData, sectionIndex) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: "1rem",
                                                  paddingTop: "10px",
                                                  paddingBottom: "10px"
                                                }}
                                                key={sectionIndex}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    // fontWeight: 600,
                                                    // fontSize: "1.04vw",
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    paddingBottom: 10
                                                  }}
                                                  className="question_paper_font section-heading"
                                                >
                                                  {sectionData?.section}

                                                </div>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    // fontWeight: 600,
                                                    // fontSize: "20px",
                                                    paddingLeft: "30px",
                                                  }}
                                                  className="question_paper_font_text 111"
                                                >
                                                  {sectionData?.text}

                                                </div>

                                                <>
                                                  {
                                                    // sectionData?.data.map((seltd, sindex) => {

                                                    // return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        paddingLeft: 10,
                                                        paddingRight: 10,
                                                        paddingBottom: 10
                                                        // rowGap: "1px",
                                                      }}
                                                    >
                                                      {sectionData?.data?.map((data, index) => {
                                                        // console.log("rerun", data)
                                                        const orderArray = Object.values(data?.correctResponse || {});
                                                        // console.log("orderArray", orderArray);
                                                        var markingOptions = []
                                                        if (orderArray?.length > 0) {
                                                          markingOptions = orderArray?.map((item) => ({
                                                            answerKey: item?.answerKey,
                                                            mark: item?.mark
                                                          }))
                                                        }
                                                        // console.log(data, "datadatadatadatta", data?.subQuestions, markingOptions);
                                                        const id = `SectionA${data?.id}`;
                                                        const isSelected = selectedIds.includes(id);
                                                        var options = Array.isArray(data?.options?.[0]) ? data.options[0] : Array.isArray(data?.options) ? data?.options : [data?.options] || [];
                                                        // console.log(data?.question, data?.questionTypeCode, "main question")
                                                        serial_number = getIncrementedSerialNumber(serial_number)
                                                        return (
                                                          <div style={{ marginLeft: "10px", marginTop: (index == 0 && data?.questionTypeCode == "MCQ") ? "1px" : "25px" }}>

                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                flexDirection: "row",

                                                                alignItems: "center",
                                                              }}
                                                              key={data?.id}
                                                            >
                                                              <div id="question"
                                                                style={{
                                                                  flex: "95%",
                                                                  display: "flex",
                                                                  flexDirection: "row",
                                                                  alignItems: "center",
                                                                }}
                                                                key={data?.id}
                                                              >
                                                                <Radio id="radio-btn"
                                                                  {...controlProps(data?.id)}
                                                                  checked={selectedOptions[data?.question] === data?.id}
                                                                  onChange={() => {
                                                                    handleRadioChange(data?.id, data?.question, data, options, index)
                                                                    setChosenQuestion(data?.id)
                                                                  }}

                                                                />
                                                                {
                                                                  data?.question &&
                                                                  <div style={{ display: "contents" }}>
                                                                    {/* <p
                                                                      id="qstn-3"
                                                                      style={{ display: "flex", padding: "10px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "wrap" }}
                                                                      className="queestion-text"
                                                                      dangerouslySetInnerHTML={{ __html: `${serial_number}. &nbsp; ${data?.question}` }}
                                                                    ></p> */}
                                                                    <div style={{
                                                                      display: "flex",
                                                                      flexDirection: "row", // Aligns the number and text at the top
                                                                      fontFamily: "Poppins",
                                                                      fontSize: "0.88vw",
                                                                      color: "#000",
                                                                      marginLeft: '10px'
                                                                    }} className="main-div1">
                                                                      <div>
                                                                        {data?.serialNumber || serial_number}.
                                                                      </div>
                                                                      <div style={{ marginLeft: '16px' }}>
                                                                        <p
                                                                          id="qstn-31"
                                                                          style={{ display: "flex", flexDirection: 'column', fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "normal" }}
                                                                          className="queestion-text"
                                                                          dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
                                                                        ></p>
                                                                      </div>

                                                                    </div>
                                                                  </div>
                                                                }
                                                              </div>
                                                              <div id="mark" style={{ flex: "5%", textAlign: "right", fontSize: "0.88vw" }}>[{data?.marks}]</div>
                                                            </div>
                                                            {
                                                              data?.imageUrl &&
                                                              <div className="avoid-page-break mainQ">
                                                                <img className="img-break-avoid" crossOrigin="anonymous" id="img1" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.imageUrl} width="50%" height="240px" />
                                                              </div>
                                                            }
                                                            {
                                                              data?.mapUrl &&
                                                              <div className="avoid-page-break mainQ">
                                                                <img className="img-break-avoid" crossOrigin="anonymous" id="map_img" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.mapUrl} height="240px" />
                                                              </div>
                                                            }
                                                            {
                                                              data?.subQuestions?.length > 0 && data?.subQuestions?.map((subQuestion, index) => {
                                                                return (
                                                                  <div key={`sub-question1-${index}`}>
                                                                    <div className="sub-qstns" key={index}>
                                                                      <p
                                                                        id="qstn-4"
                                                                        style={{ display: "flex", width: "95%", paddingLeft: "10px", paddingRight: "20px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", whiteSpace: "normal" }}
                                                                        className="sub-questions"
                                                                        // dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${index + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                        dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${index + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                      ></p>

                                                                    </div>

                                                                    {
                                                                      subQuestion?.imageUrl &&
                                                                      <div className="avoid-page-break subQ">
                                                                        <img className="img-break-avoid" crossOrigin="anonymous" id="img6" style={{ paddingLeft: "50px" }} src={subQuestion?.imageUrl} height="240px" />
                                                                      </div>
                                                                    }

                                                                    {/* {subQuestion && (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") && subQuestion?.options?.length > 0 && subQuestion?.options?.map((option, index) => (
                                                                      <div style={{ display: "block", padding: "10px" }} key={index}>
                                                                        <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                          <div style={{ paddingLeft: "50px", display: "inline-block" }} id="s1">
                                                                            {String.fromCharCode(97 + index)}. &nbsp;
                                                                          </div>
                                                                          <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                        </div>
                                                                      </div>
                                                                    ))} */}
                                                                    {
                                                                      subQuestion &&
                                                                      (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") &&
                                                                      (
                                                                        (subQuestion?.options?.length > 1
                                                                          ? subQuestion?.options.map((option, index) => (
                                                                            option?.htmlTag &&
                                                                            <div style={{ display: "block", padding: "10px" }}>
                                                                              <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="107" id="s1">
                                                                                <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                              </div>
                                                                              {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="107" id="s1">
                                                                                  {String.fromCharCode(97 + index)}. &nbsp;
                                                                                </div>
                                                                                <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                              </div> */}
                                                                            </div>
                                                                          ))
                                                                          : (subQuestion?.options[0]?.length > 0
                                                                            ? subQuestion?.options[0].map((option, index) => (
                                                                              option?.htmlTag &&
                                                                              <div style={{ display: "block", padding: "10px" }}>
                                                                                <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="108" id="s1">
                                                                                  <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                                </div>
                                                                                {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                  <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="108" id="s1">
                                                                                    {String.fromCharCode(97 + index)}. &nbsp;
                                                                                  </div>
                                                                                  <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                                </div> */}
                                                                              </div>
                                                                            ))
                                                                            : null
                                                                          )
                                                                        )
                                                                      )
                                                                    }
                                                                  </div>
                                                                );
                                                              })
                                                            }

                                                            {
                                                              (markingOptions && markingOptions?.length > 0 && markingOptions[0]?.mark !== null && showmarkingValue) &&
                                                              <div className=" answerBg">
                                                                <div className=" header answer">
                                                                  <div className="">Answer/Term</div>
                                                                  <div className="">Mark</div>
                                                                </div>
                                                                {
                                                                  markingOptions?.map((item, index) => {
                                                                    return (
                                                                      <div className="options " id={`dynamicContent_${index}`} key={index}>
                                                                        <div className="">{item?.answerKey}</div>
                                                                        <div className="">{item?.mark}</div>
                                                                      </div>
                                                                    )
                                                                  })
                                                                }
                                                              </div>
                                                            }



                                                            {
                                                              (data?.questionTypeCode !== "FILL" && data?.questionTypeCode !== "MATCH") ?
                                                                <div style={{ display: "block" }} className="question-options-main">
                                                                  {options?.length > 0 && options?.map((option, index) => (
                                                                    option?.htmlTag &&
                                                                    <div key={index} style={{ display: "inline-block", width: "100%" }} className="question-options-main-inner 5555">
                                                                      <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(index + 65)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                    </div>
                                                                  ))}
                                                                </div> :
                                                                data?.questionTypeCode !== "FILL" &&
                                                                <div style={{ display: "block" }} className="question-options-main">
                                                                  {(options?.length > 0 || options?.legnth !== 0) && options?.map((option, index) => (
                                                                    <div key={index} style={{ display: "flex", width: "100%", paddingLeft: "10%" }}>
                                                                      <div style={{ flex: "1", paddingRight: "5px" }} id="MTF2">
                                                                        <div style={{ whiteSpace: "nowrap", display: "flex" }} className="question-options3">
                                                                          <div style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: `${String.fromCharCode(index + 65)}.&nbsp; ${option?.htmlTagQuestion}` }}></div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ flex: "1", paddingLeft: "5px" }} id="MTF2">
                                                                        <div style={{ fontSize: "11px", alignContent: "center", fontFamily: "Poppins", overflow: "auto" }}>
                                                                          <div dangerouslySetInnerHTML={{ __html: option?.jumbledAnswer }}></div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  ))}
                                                                </div>
                                                            }
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                    //   )
                                                    // })
                                                  }
                                                </>
                                              </div>
                                            )
                                          })

                                        }
                                      </div>
                                    </div>

                                    {
                                      // !IsPendingClicked || (IsPendingClicked && !showEditBtn) &&
                                      // ((!IsPendingClicked || (IsPendingClicked && !showEditBtn)) && !IsApproveRejectEnabled && showEditBtn) &&
                                      ((!showEditBtn && !IsApproveRejectEnabled) || (!IsPendingClicked || (IsPendingClicked && !showEditBtn)) && !IsApproveRejectEnabled) &&

                                      < div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end",
                                          columnGap: "1rem",
                                          // padding: "10px",
                                        }}
                                      >
                                        <DarkGreenButton id="replace-btn"
                                          disabled={isBtnEnabledReplace}
                                          onClick={() => { replaceSelected(qstnpaperData, QstnPaperFormData) }}>Replace selected </DarkGreenButton>
                                        <SkyBlueButton id="provide-own-btn"
                                          disabled={isBtnEnabledOwn}
                                          onClick={() => { providemyown() }}
                                        >
                                          I will provide own
                                        </SkyBlueButton>
                                        <DarkYellowButton id="show-marking-btn" onClick={() => { showMarking() }}>{showmarkingValue ? <>Hide Marking</> : <>Show Marking</>}</DarkYellowButton>
                                      </div>
                                    }

                                    {
                                      (!IsApproveRejectEnabled && localStorage.getItem("role") !== "TEACHER") &&
                                      <div id="apprv_rjct"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end",
                                          columnGap: "1rem",
                                          padding: "10px",
                                        }}
                                      >
                                        <DarkGreenButton id="approve-btn" onClick={() => { approveQstnPAper() }}>Approve</DarkGreenButton>
                                        <DarkRedButton id="reject-btn" onClick={() => {
                                          //console.log("rejectQstnPaperBtn called");
                                          setrejectReason("");
                                          setIsRejectScreenOpen(true);
                                        }}>Reject</DarkRedButton>
                                      </div>
                                    }

                                  </div>
                                }

                              </div>
                            }
                          </Paper>
                        </TabPanel>
                      }
                      {
                        (tabsValue == 1 || tabsValue == "1") &&
                        <TabPanel value="1" id="pdf_preview_term" className="pdf_preview_term" ref={pageDiv}>
                          <Paper sx={{ borderRadius: "7px", height: "100%" }}>
                            <div id="header-page"
                              style={{
                                background: "rgb(96 91 161 / 81%)",
                                padding: "15px",
                                color: "white",
                                borderTopLeftRadius: "7px",
                                borderTopRightRadius: "7px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>Term Paper</div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                }}
                              >
                                {/* <DarkGreenButton id="save-btn" onClick={handleSaveClick}>Save</DarkGreenButton>
                      <DarkYellowButton id="preview-btn" onClick={PreviewQstnPaper}>Preview</DarkYellowButton> */}
                                {
                                  // (showEditBtn && !IsApproveRejectEnabled) &&
                                  showEditBtn &&
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                      gap: "5px",
                                      background: "#082fff",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => { EditMethod() }}
                                  >Edit
                                  </div>
                                }
                                {
                                  (!showTermQstnPaperNotEnabled && !showEditBtn && !IsApproveRejectEnabled) &&
                                  <>
                                    <DarkGreenButton id="save-btn"
                                      disabled={isBtnEnabled}
                                      onClick={() => {
                                        setIsBtnEnabled(true);
                                        setHidetheSuccessMessage(false);
                                        setTimeout(() => {
                                          handleSaveClick({}, [], false, 12);
                                        }, 300);
                                      }}>Save</DarkGreenButton>
                                    <DarkYellowButton id="preview-btn" onClick={PreviewQstnPaper}>Preview</DarkYellowButton>
                                    <Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box sx={style1}>
                                        <Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                          sx={{ textAlign: 'center', fontFamily: 'Poppins' }}
                                        >
                                          Preview
                                        </Typography>
                                        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                                          <img
                                            id="image5"
                                            src={imageSrc}
                                            alt="Preview"
                                            style={{ width: '100%', maxHeight: '70vh', height: 'auto' }}
                                          />
                                        </div>
                                        <Button
                                          onClick={handleClose}
                                          sx={{
                                            position: 'absolute',
                                            right: 8,
                                            bottom: 8,
                                          }}
                                        >
                                          Close
                                        </Button>
                                      </Box>
                                    </Modal>
                                  </>
                                }
                                {
                                  (!showTermQstnPaperNotEnabled && !showEditBtn && !IsApproveRejectEnabled) &&
                                  <DarkOrangeButton id="cancel-btn" onClick={() => {
                                    SetshowTermQstnPaperNotEnabled(true);
                                    setIsPendingClicked(false);
                                    setSelectedIds([]);
                                    setSelectedGrade("");
                                    setSelectedLevel("");
                                    setSelectedsubjectName("");
                                    setSelectedSubject("");
                                    setSelectedMonth("");
                                    setSelectedPaperName("")
                                    setTitle("");
                                    setShowPrint(false);
                                    setChapterList([]);
                                    setHidetheSuccessMessage(true);
                                  }}>Cancel</DarkOrangeButton>
                                }
                                {/* {
                                showPrint &&
                                <DarkOrangeButton id="print-btn" onClick={() => { printDiv() }
                                }>Print</DarkOrangeButton>

                              } */}
                              </div>
                            </div>
                            {
                              showTermQstnPaperNotEnabled &&
                              <div
                                style={{
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "10px",
                                  }}
                                >

                                  {/* <div style={{ fontSize: "16px", fontWeight: "bold" }}>Exam Paper Title Name</div> */}

                                  {/* <div>
                                    <TextField  className="text-field-subjective"
                                        id="text-field-subjective"
                                        type="text"
                                        label="Exam Paper Title Name aa"  
                                        placeholder="Enter Your Title"
                                        variant="outlined" 
                                        InputLabelProps={{
                                          shrink: true,
                                          sx: {
                                            color: '#e7ab3c', 
                                            '&.Mui-focused': {
                                              color: '#e7ab3c', 
                                            },
                                         }, 
                                        }}
                                        sx={{
                                          margin:'5px 0 ',
                                          fontSize: '15px !important',
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              borderColor: 'gray',
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#e7ab3c',
                                            },
                                            borderRadius: '8px',
                                          },
                                          '& input::placeholder': {
                                            fontSize: '13px !important',
                                            color: 'gray',
                                          },
                                          '& label': {
                                            fontSize: '15px', 
                                            paddingRight: '5px', 
                                            color:'#e7ab3c'
                                          },
                                        }}
                                        value={title}
                                        onChange={handleTitleChange}
                                      />
                                      {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>}
                                   </div> */}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: "1rem",
                                      // alignItems: "center",s
                                      // justifyContent: "space-between",
                                    }}
                                  >
                                    {/* {
                            istitleEmpty &&
                            <span style={{ color: "red" }}>Enter title to proceed</span>
                          } */}
                                    <div>
                                      <TextField className="text-field-subjective"
                                        id="text-field-subjective"
                                        type="text"
                                        label="Exam Paper Title Name"
                                        placeholder="Enter Your Title"
                                        variant="outlined"
                                        InputLabelProps={{
                                          shrink: true,
                                          sx: {
                                            color: '#e7ab3c',
                                            '&.Mui-focused': {
                                              color: '#e7ab3c',
                                            },
                                          },
                                        }}
                                        sx={{
                                          margin: '5px 0 ',
                                          fontSize: '15px !important',
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              borderColor: 'gray',
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#e7ab3c',
                                            },
                                            borderRadius: '8px',
                                          },
                                          '& input::placeholder': {
                                            fontSize: '13px !important',
                                            color: 'gray',
                                          },
                                          '& label': {
                                            fontSize: '15px',
                                            paddingRight: '5px',
                                            color: '#e7ab3c'
                                          },
                                        }}
                                        value={title}
                                        onChange={handleTitleChange}
                                      />
                                      {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                        alignItems: "center",

                                      }}
                                    >

                                      {/* <TextField className="text-field-subjective" id="text-field-subjective"
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Enter Your Title"}
                                        sx={{ fontSize: "15px !important" }}
                                        InputProps={{
                                          endAdornment: <IconButton />,
                                        }}
                                        style={{ borderRadius: "150px", width: "200px", fontSize: "13px !important" }}
                                        value={title}
                                        onChange={handleTitleChange}
                                      />
                                      {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>} */}

                                      {/* <FormControl
                                        sx={{ width: "150px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"

                                        >
                                          Select Grade
                                        </InputLabel>

                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedGrade ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedGrade}
                                          onChange={async (e) => {
                                            setSelectedGrade(e.target.value);
                                            CreateQuestionPaper(selectedSubject);
                                            setSelectedLevel("");

                                            var bluprintBsdOnGrd = [];

                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Term");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.gradeId === e.target.value);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList);
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject)
                                            }
                                            var sublist = await fetchSubjectData(e.target.value);
                                            // console.log("sublist", sublist, chapterList);
                                            setSubjectList(sublist);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd);
                                            setSelectedPaperName("");
                                          }}
                                        >
                                          {gradeList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.discription} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorGrade && <p style={{ color: 'red' }}>{errorGrade}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Grade
                                        </InputLabel>

                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Grade' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={props => (
                                            <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedGrade}
                                          onChange={async (e) => {
                                            setSelectedGrade(e.target.value);
                                            CreateQuestionPaper(selectedSubject);
                                            setSelectedLevel("");

                                            var bluprintBsdOnGrd = [];

                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Term");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.gradeId === e.target.value);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList);
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject)
                                            }
                                            var sublist = await fetchSubjectData(e.target.value);
                                            // console.log("sublist", sublist, chapterList);
                                            setSubjectList(sublist);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd);
                                            setSelectedPaperName("");
                                          }}
                                        >
                                          {gradeList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.discription} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorGrade && <p style={{ color: 'red' }}>{errorGrade}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Subject
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedSubject ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedSubject}
                                          onChange={(e) => {
                                            setSelectedSubject(e?.target?.value);
                                            setSelectedLevel("");
                                            // console.log("setSelectedLevel 10", "")
                                            setSelectedIds([])
                                            // console.log(e.target.value, subjectList);
                                            var idtocompare = e?.target?.value
                                            var subjectName = subjectList.find(e => e?.id === idtocompare);
                                            // console.log("subjectName", subjectName)
                                            setSelectedsubjectName(subjectName.subject);
                                            // to filter papertypelist,blueprint based on grade and subject 
                                            var bluprintBsdOnGrd = [];
                                            bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === e.target.value);
                                            // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Term");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd?.length > 0 && selectedGrade) {
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id)
                                            }
                                            // console.log(bluprintBsdOnGrd, "1010swe", e.target.value, selectedGrade, chapterList);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {subjectList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.subject} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorSubject && <p style={{ color: 'red' }}>{errorSubject}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Subject
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Subject' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedSubject}
                                          onChange={(e) => {
                                            setSelectedSubject(e?.target?.value);
                                            setSelectedLevel("");
                                            // console.log("setSelectedLevel 10", "")
                                            setSelectedIds([])
                                            // console.log(e.target.value, subjectList);
                                            var idtocompare = e?.target?.value
                                            var subjectName = subjectList.find(e => e?.id === idtocompare);
                                            // console.log("subjectName", subjectName)
                                            setSelectedsubjectName(subjectName.subject);
                                            // to filter papertypelist,blueprint based on grade and subject 
                                            var bluprintBsdOnGrd = [];
                                            bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === e.target.value);
                                            // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Term");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd?.length > 0 && selectedGrade) {
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id)
                                            }
                                            // console.log(bluprintBsdOnGrd, "1010swe", e.target.value, selectedGrade, chapterList);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {subjectList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.subject} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorSubject && <p style={{ color: 'red' }}>{errorSubject}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Level
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedLevel ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedLevel}
                                          onChange={(e) => {
                                            setSelectedLevel(e?.target?.value);
                                            // console.log("setSelectedLevel 1", e.target.value)
                                            // console.log("e?.target?.value Term", e?.target?.value, listOfLevel, bluePrintD?.data)
                                            var levelData = listOfLevel?.filter(v => v.id == e?.target?.value) || []
                                            // console.log("levelData", levelData, levelData[0].levelName, bluePrintD?.data);
                                            var bluprintBsdOnGrd = [];
                                            if (levelData?.length > 0) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.levelName === levelData[0].levelName);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                              var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Term");
                                              if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id);
                                                // console.log(bluprintBsdOnGrd, "11111111111111", e.target.value, selectedGrade, chapterList);
                                              }
                                              if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject);
                                                // console.log(bluprintBsdOnGrd, "22222222222222", e.target.value, selectedGrade, chapterList);
                                              }
                                            }
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            // CreateQuestionPaper(selectedSubject);
                                          }
                                          }
                                        >
                                          {listOfLevel?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item?.levelName} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorLevel && <p style={{ color: 'red' }}>{errorLevel}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel'
                                          id="demo-simple-select-label"
                                        >
                                          Level
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Level ' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                                maxHeight: '190px',
                                                overflowY: 'auto',
                                                '&::-webkit-scrollbar': {
                                                  width: '2px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                  background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                  background: '#888',
                                                  borderRadius: '4px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                  background: '#555',
                                                },
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px',
                                                overflowY: 'auto',
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedLevel}
                                          onChange={(e) => {
                                            setSelectedLevel(e?.target?.value);
                                            // console.log("setSelectedLevel 1", e.target.value)
                                            // console.log("e?.target?.value Term", e?.target?.value, listOfLevel, bluePrintD?.data)
                                            var levelData = listOfLevel?.filter(v => v.id == e?.target?.value) || []
                                            // console.log("levelData", levelData, levelData[0].levelName, bluePrintD?.data);
                                            var bluprintBsdOnGrd = [];
                                            if (levelData?.length > 0) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.levelName === levelData[0].levelName);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                              var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Term");
                                              if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id);
                                                // console.log(bluprintBsdOnGrd, "11111111111111", e.target.value, selectedGrade, chapterList);
                                              }
                                              if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject);
                                                // console.log(bluprintBsdOnGrd, "22222222222222", e.target.value, selectedGrade, chapterList);
                                              }
                                            }
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            // CreateQuestionPaper(selectedSubject);
                                          }
                                          }
                                        >
                                          {listOfLevel?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item?.levelName} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorLevel && <p style={{ color: 'red' }}>{errorLevel}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                  sx={{  width: "150px" }}
                                >
                                  <InputLabel id="demo-simple-select-label"
                                        style={{ fontSize: "18px" }}>
                                    Select Paper Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Tag" />}
                                    sx={{
                                      height: "100%",
                                      "&.Mui-selected": {
                                        backgroundColor: 'orange',
                                        color: "#FFFFFF"
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: '#fcedc2',
                                        color: "#000000",
                                      },
                                      "&:not(.Mui-selected):hover": {
                                        backgroundColor: '#fcedc2',
                                        color: '#000000',
                                      },
                                    }}
                                    style={{
                                      borderRadius: "150px",
                                      padding: "4px",
                                      color: selectedSubject ? "orange" : "black"
                                    }}
                                    size={"small"}
                                    IconComponent={(props) => (
                                      <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                      />
                                    )}
                                    value={selectedPaper}
                                    onChange={(e) => {
                                      //console.log(e?.target)
                                      setSelectedPaper(e?.target?.value);

                                      var bluprintBsdOnGrd = [];
                                      bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === selectedSubject);
                                      if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                        bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade)
                                      }
                                      if (bluprintBsdOnGrd.length > 0 && e.target.value) {
                                        bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.paperTypeId === e.target.value)
                                      }
                                      //console.log(bluprintBsdOnGrd, "after all filters", e.target.value, selectedGrade, selectedSubject);
                                      setBluePrintDDropDown(bluprintBsdOnGrd)
                                    }
                                    }
                                    disabled={selectedPaper ? true : false}
                                  >
                                    {papertypeListMenu?.map((item) => {
                                      return (
                                        <MenuItem
                                          value={item.id}
                                          key={item.id}
                                          className={dashboardContentStyles.menu_item}
                                          sx={{
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000"
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            }
                                          }}
                                        >
                                          <ListItemText primary={item.paperType} />
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl> */}

                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Year
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedGrade ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedYear}
                                          onChange={(e) => {
                                            setSelectedYear(e?.target?.value);
                                            const desiredAcademicYear = e?.target?.value
                                            // console.log("1091", e?.target?.value, yearList, desiredAcademicYear)
                                            var iid = yearList.find(item => item?.academicYear === desiredAcademicYear)
                                            // console.log(e.target.value, yearList, iid)
                                            if (iid) {
                                              setSelectedYearId(iid.id)
                                            }
                                          }}
                                          disabled={selectedYearId ? true : false}
                                        >
                                          {yearList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.academicYear}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.academicYear} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Academic Year
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Academic Year' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedYear}
                                          onChange={(e) => {
                                            setSelectedYear(e?.target?.value);
                                            const desiredAcademicYear = e?.target?.value
                                            // console.log("1091", e?.target?.value, yearList, desiredAcademicYear)
                                            var iid = yearList.find(item => item?.academicYear === desiredAcademicYear)
                                            // console.log(e.target.value, yearList, iid)
                                            if (iid) {
                                              setSelectedYearId(iid.id)
                                            }
                                          }}
                                          disabled={selectedYearId ? true : false}
                                        >
                                          {yearList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.academicYear}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.academicYear} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>

                                      {/* <FormControl
                                  sx={{  width: "150px" }}
                                >
                                  <InputLabel id="demo-simple-select-label"
                                        style={{ fontSize: "18px" }}>
                                    Select Blueprint
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Tag" />}
                                    sx={{
                                      height: "100%",
                                      "&.Mui-selected": {
                                        backgroundColor: 'orange',
                                        color: "#FFFFFF"
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: '#fcedc2',
                                        color: "#000000",
                                      },
                                      "&:not(.Mui-selected):hover": {
                                        backgroundColor: '#fcedc2',
                                        color: '#000000',
                                      },
                                    }}
                                    style={{
                                      borderRadius: "150px",
                                      padding: "4px",
                                      color: selectedPaperName ? "orange" : "black"
                                    }}
                                    size={"small"}
                                    IconComponent={(props) => (
                                      <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                      />
                                    )}
                                    value={selectedPaperName}
                                    onChange={(e) => {
                                      //console.log(e.target.value)
                                      setSelectedPaperName(e.target.value);
                                      //console.log(selectedPaperName, "selected blueprint", chapterList)
                                      CreateQuestionPaper(selectedSubject)
                                      handleBlueprintSelect(e.target.value)
                                    }}
                                  >
                                    {
                                      //console.log("bluePrintDDropDown", bluePrintDDropDown, bluePrintD?.data)
                                    }
                                    {bluePrintDDropDown?.map((item) => {
                                      return (
                                        <MenuItem
                                          value={item.paperTitle}
                                          key={item.id}
                                          className={dashboardContentStyles.menu_item}
                                          sx={{
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000"
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            }
                                          }}
                                        >
                                          <ListItemText primary={item.paperTitle} />
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  {errorBluePrint && <p style={{ color: 'red' }}>{errorBluePrint}</p>}
                                </FormControl> */}
                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Month
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedmonth ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedmonth}
                                          onChange={(e) => {
                                            // console.log(e.target.value, "select month")
                                            setSelectedMonth(e.target.value);
                                            // console.log(selectedmonth)
                                            // CreateQuestionPaper(selectedSubject)
                                          }}
                                        >
                                          {monthList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.value}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.value} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel'
                                          id="demo-simple-select-label"
                                        >
                                          Month
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Month ' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                                maxHeight: '190px',
                                                overflowY: 'auto',
                                                '&::-webkit-scrollbar': {
                                                  width: '2px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                  background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                  background: '#888',
                                                  borderRadius: '4px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                  background: '#555',
                                                },
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px',
                                                overflowY: 'auto',
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedmonth}
                                          onChange={(e) => {
                                            // console.log(e.target.value, "select month")
                                            setSelectedMonth(e.target.value);
                                            // console.log(selectedmonth)
                                            // CreateQuestionPaper(selectedSubject)
                                          }}
                                        >
                                          {monthList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.value}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.value} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                      </FormControl>

                                    </div>
                                    <DarkYellowButton fullWidth
                                      style={{ borderRadius: "150px", height: "48px" }}
                                      disabled={isCreateBtnEnabled}
                                      onClick={() => {
                                        setIsCreateBtnEnabled(true); setTimeout(() => {
                                          createBtn()
                                        }, 300);
                                      }}
                                    >
                                      <NoteAddOutlinedIcon style={{ fontSize: "1.3vw", color: "white", backgroundColor: "#F78F02" }} />
                                      Create
                                    </DarkYellowButton>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  {CreateAlert && (
                                    <Alert
                                      action={
                                        <IconButton
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setCreateAlert(false);
                                            setIsBtnEnabled(false);
                                            setIsBtnEnabledOwn(false);
                                            setIsBtnEnabledReplace(false);
                                            setIsCreateBtnEnabled(false);
                                          }}
                                        >
                                          <CancelOutlinedIcon />
                                        </IconButton>
                                      }
                                      icon={false}
                                      sx={{
                                        color: "#FFFEFA",
                                        fontWeight: 500,
                                        fontSize: "0.93vw",
                                        marginRight: "10%",
                                        // background: "#bddabca1",
                                        background: "red",
                                      }}
                                    >
                                      {CreateAlert}
                                    </Alert>
                                  )}
                                </div>
                                <div
                                  style={{
                                    padding: "20px",
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    rowGap: "1rem",
                                    minHeight: "595px",
                                    overflow: "auto",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: "1rem",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        background: "rgb(227 225 225 / 61%)",
                                        padding: "10px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {selectedsubjectName}
                                    </div>
                                    {
                                      ChapterListError &&
                                      <p style={{ color: "red" }}>Choose chapter to proceed*</p>
                                    }
                                    <Box sx={{ flexGrow: 1 }}>
                                      <Grid container spacing={2}>
                                        {chapterList?.map((data, index) => {
                                          return (
                                            <Grid
                                              item
                                              xs={12}
                                              md={4}
                                              sm={6}
                                              lg={2.4}
                                              key={index}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: "1rem",
                                                  paddingTop: "5px",
                                                }}
                                                key={index}
                                              >
                                                <div
                                                  id={`az-teacher-chapter-card-${data?.chapter.toLowerCase().replace(/ /g, "_")}`}
                                                  key={data?.id}
                                                  className={dashboardContentStyles.card}
                                                >
                                                  <img
                                                    id="image6"
                                                    src={data?.thumbnailPath}
                                                    width={110}
                                                    alt={""}
                                                    // style={{ maxHeight: "138px" }}
                                                    className={dashboardContentStyles.az_quiz_card_img} />
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: 600,
                                                      color: "#423d5c",
                                                    }}
                                                  >
                                                    {data?.chapter}
                                                  </div>
                                                </div>

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      sx={{ color: 'blue' }}
                                                      color="default"
                                                      key={data?.id}
                                                      checked={selectedIds.includes(data?.id)}
                                                      onChange={(e) => handleCheckboxChange(e, data?.id)}
                                                    />
                                                  }
                                                  label="Select"
                                                  sx={{
                                                    color: "blue",
                                                    fontWeight: 600,
                                                    paddingLeft: "10px"
                                                  }}
                                                  key={data?.id}
                                                />
                                              </div>
                                            </Grid>
                                          );
                                        })}
                                      </Grid>
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              !showTermQstnPaperNotEnabled &&
                              <div id="pdf_preview_term_inner_div" style={{ paddingBottom: "15px" }} className="kidn2">

                                {
                                  QstnPaperFormData &&
                                  <div
                                    style={{
                                      padding: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      // height: "620px",
                                      overflow: "hidden",
                                      padding: "30px",
                                      margin: "30px",
                                      borderRadius: "7px",
                                      border: "2px solid"
                                    }}
                                  >

                                    <div
                                      className="avoid-page-break"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row-reverse"
                                      }}>

                                      <img className="avoid-page-break" crossOrigin="anonymous" id="img81" src={QstnPaperFormData?.data?.branchLogo || QstnPaperFormData?.branchLogo} alt={""} height={50} />
                                    </div>
                                    <div
                                      style={{
                                        overflow: "scroll",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "1rem",
                                        padding: "30px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div style={{ width: "164px" }} />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              flexDirection: "column",
                                              rowGap: "5px",
                                              fontSize: "0.93vw",
                                            }}
                                          >
                                            <div className="question_paper_font">
                                              {String(QstnPaperFormData?.data?.school || QstnPaperFormData?.school || "").toUpperCase()}
                                            </div>
                                            <div className="question_paper_font">
                                              {QstnPaperFormData?.data?.branch || QstnPaperFormData?.branch}
                                            </div>
                                            <div
                                              className="question_paper_font"
                                            >
                                              {QstnPaperFormData?.data?.schoolLocality || QstnPaperFormData?.schoolLocality}
                                            </div>
                                            <div className="question_paper_font" id="paperName">

                                              {
                                                title || QstnPaperFormData?.questionPaperName
                                              }
                                            </div>
                                            <div
                                              className="question_paper_font"
                                            >
                                              {QstnPaperFormData?.data?.subject || "Subject : " + QstnPaperFormData?.subject}
                                            </div>
                                          </div>
                                          <div style={{ width: "164px" }} />
                                          {/* <img crossOrigin="anonymous" id="img8" src={logo} alt={""} height={60} /> */}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            fontSize: "0.93vw",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "start",
                                              rowGap: "5px",
                                              // fontSize: "17px",
                                            }}
                                          >

                                            <div className="question_paper_font">{QstnPaperFormData?.data?.grade || QstnPaperFormData?.grade}</div>
                                            <div className="question_paper_font">Date : </div>
                                          </div>
                                          {showMessage && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setShowMessage(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "green",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "rgba(200,255,200,0.63)",
                                              }}
                                            >
                                              Own Question Provide Successfully
                                            </Alert>
                                          )}
                                          {showSuccessQstnMessageEnable && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setshowSuccessQstnMessageEnable(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "green",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "rgba(200,255,200,0.63)",
                                              }}
                                            >
                                              {/* Successfully Saved the Question Paper */}
                                              {showSuccessQstnMessage}
                                            </Alert>
                                          )}

                                          {showerror && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setShowerror(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "blue",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "red",
                                              }}
                                            >
                                              {showerrorMessage}
                                            </Alert>
                                          )}

                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "end",
                                              rowGap: "5px",
                                              fontSize: "0.93vw",
                                            }}
                                          >
                                            <div className="question_paper_font">Maximum Marks : {QstnPaperFormData?.data?.totalMark || QstnPaperFormData?.totalMark}</div>
                                            <div className="question_paper_font">Duration : {QstnPaperFormData?.data?.examDurationInMin || QstnPaperFormData?.examDurationInMin} Minutes</div>
                                          </div>
                                        </div>

                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "1rem",
                                          fontSize: "0.93vw",
                                        }}
                                      >


                                        <div className="instructions_container">
                                          <div className="question_paper_instructions">General Instructions</div>
                                          <div>
                                            <ul className="unordered_list_format">
                                              {(QstnPaperFormData?.splittedHeading || QstnPaperFormData?.data?.splittedHeading)?.map((instruction, index) => {
                                                return (<li key={index} className="question_paper_instructions_list">{instruction}</li>)
                                              })}
                                            </ul>
                                          </div>

                                        </div>

                                        {
                                          qstnpaperData?.map((sectionData, sectionIndex) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: "1rem",
                                                  paddingTop: "10px",
                                                  paddingBottom: "10px"
                                                }}
                                                key={sectionIndex}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    // fontWeight: 600,
                                                    // fontSize: "1.04vw",
                                                    padding: 10,
                                                  }}
                                                  className="question_paper_font section-heading"
                                                >
                                                  {sectionData?.section}

                                                </div>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    // fontWeight: 600,
                                                    // fontSize: "20px",
                                                    paddingLeft: "30px",
                                                  }}
                                                  className="question_paper_font_text 222"
                                                >
                                                  {sectionData?.text}

                                                </div>
                                                <>
                                                  {
                                                    // sectionData?.data.map((seltd, sindex) => {

                                                    // return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "10px"
                                                        // rowGap: "1px",
                                                      }}
                                                    >
                                                      {sectionData?.data?.map((data, index) => {
                                                        const orderArray = Object.values(data?.correctResponse || {})
                                                        var markingOptions = [];
                                                        if (orderArray?.length > 0) {
                                                          markingOptions = orderArray?.map((item) => ({
                                                            answerKey: item?.answerKey,
                                                            mark: item?.mark
                                                          }))
                                                        }
                                                        const id = `SectionA${data?.id}`;
                                                        const isSelected = selectedIds.includes(id);
                                                        var options = Array.isArray(data?.options?.[0]) ? data.options[0] : Array.isArray(data?.options) ? data?.options : [data?.options] || [];
                                                        // console.log("optionsoptions", options, data, data?.options[0]?.length > 0 ? true : false, "==== ", Array.isArray(data?.options?.[0]))
                                                        serial_number = getIncrementedSerialNumber(serial_number)
                                                        return (
                                                          <div style={{ marginLeft: "10px" }}>

                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: (index == 0 && data?.questionTypeCode == "MCQ") ? "1px" : "25px"
                                                              }}
                                                              key={data?.id}
                                                            >
                                                              <div id="question2"
                                                                className="Qstn_2"
                                                                style={{
                                                                  flex: "95%",
                                                                  display: "flex",
                                                                  flexDirection: "row",
                                                                  alignItems: "center",
                                                                }}
                                                                key={data?.id}
                                                              >
                                                                <Radio id="radio-btn"
                                                                  {...controlProps(data?.id)}
                                                                  checked={selectedOptions[data?.question] === data?.id}
                                                                  onChange={() => {
                                                                    handleRadioChange(data?.id, data?.question, data, options, index)
                                                                    setChosenQuestion(data?.id)
                                                                  }}

                                                                />
                                                                {
                                                                  data?.question &&
                                                                  <div style={{ display: "contents" }}>
                                                                    {/* <p
                                                                      id="qstn-3"
                                                                      style={{ display: "flex", padding: "10px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "wrap" }}
                                                                      className="queestion-text"
                                                                      dangerouslySetInnerHTML={{ __html: `${serial_number}. &nbsp; ${data?.question}` }}
                                                                    ></p> */}
                                                                    <div style={{
                                                                      display: "flex",
                                                                      flexDirection: "row", // Aligns the number and text at the top
                                                                      fontFamily: "Poppins",
                                                                      fontSize: "0.88vw",
                                                                      color: "#000",
                                                                      marginLeft: '10px',

                                                                    }}
                                                                      className="main-div2">
                                                                      <div>
                                                                        {data?.serialNumber || serial_number}.
                                                                      </div>
                                                                      <div style={{ marginLeft: '16px' }}>
                                                                        <p
                                                                          id="qstn-31"
                                                                          style={{ display: "flex", flexDirection: 'column', fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "normal" }}
                                                                          className="queestion-text"
                                                                          dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
                                                                        ></p>
                                                                      </div>

                                                                    </div>
                                                                  </div>
                                                                }

                                                              </div>
                                                              <div id="mark" style={{ flex: "5%", textAlign: "right", fontSize: "0.88vw" }}>[{data?.marks}]</div>
                                                            </div>

                                                            {
                                                              data?.imageUrl &&
                                                              <div className="avoid-page-break mainQ">
                                                                <img className="img-break-avoid" crossOrigin="anonymous" id="img1" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.imageUrl} width="50%" height="240px" />
                                                              </div>
                                                            }
                                                            {
                                                              data?.mapUrl &&
                                                              <div className="avoid-page-break mainQ">
                                                                <img className="img-break-avoid" crossOrigin="anonymous" id="map_img" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.mapUrl} height="240px" />
                                                              </div>
                                                            }

                                                            {
                                                              data?.subQuestions?.length > 0 && data?.subQuestions?.map((subQuestion, index) => {
                                                                return (
                                                                  <div key={`sub-question2-${index}`}>
                                                                    <div className="sub-qstns" key={index}>
                                                                      <p
                                                                        id="qstn-41"
                                                                        style={{ display: "flex", width: "95%", paddingLeft: "10px", paddingRight: "20px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", whiteSpace: "normal" }}
                                                                        className="sub-questions"
                                                                        dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${index + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                      ></p>

                                                                    </div>
                                                                    {
                                                                      subQuestion?.imageUrl &&
                                                                      <div className="avoid-page-break subQ">
                                                                        <img className="img-break-avoid" crossOrigin="anonymous" id="img9" style={{ paddingLeft: "50px" }} src={subQuestion?.imageUrl} height="240px" />
                                                                      </div>
                                                                    }

                                                                    {/* {subQuestion && (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") &&
                                                                      subQuestion?.options?.length > 0 && subQuestion?.options?.map((option, index) => (
                                                                        <div style={{ display: "block", padding: "10px" }}>
                                                                          <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                            <div style={{ paddingLeft: "50px", display: "inline-block" }} id="s1">
                                                                              {String.fromCharCode(97 + index)}. &nbsp;
                                                                            </div>
                                                                            <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                          </div>
                                                                        </div>
                                                                      ))} */}

                                                                    {
                                                                      subQuestion &&
                                                                      (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") &&
                                                                      (
                                                                        (subQuestion?.options?.length > 1
                                                                          ? subQuestion?.options.map((option, index) => (
                                                                            option?.htmlTag &&
                                                                            <div style={{ display: "block", padding: "10px" }}>
                                                                              <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="109" id="s1">
                                                                                <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                              </div>
                                                                              {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="109" id="s1">
                                                                                  {String.fromCharCode(97 + index)}. &nbsp;
                                                                                </div>
                                                                                <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                              </div> */}
                                                                            </div>
                                                                          ))
                                                                          : (subQuestion?.options[0]?.length > 0
                                                                            ? subQuestion?.options[0].map((option, index) => (
                                                                              option?.htmlTag &&
                                                                              <div style={{ display: "block", padding: "10px" }}>
                                                                                <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="110" id="s1">
                                                                                  <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                                </div>
                                                                                {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                  <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="110" id="s1">
                                                                                    {String.fromCharCode(97 + index)}. &nbsp;
                                                                                  </div>
                                                                                  <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                                </div> */}
                                                                              </div>
                                                                            ))
                                                                            : null
                                                                          )
                                                                        )
                                                                      )
                                                                    }
                                                                  </div>
                                                                );
                                                              })
                                                            }

                                                            {
                                                              (markingOptions && (markingOptions[0]?.mark !== null) && showmarkingValue && markingOptions?.length > 0) &&
                                                              <div className=" answerBg">
                                                                <div className=" header answer">
                                                                  <div className="">Answer/Term</div>
                                                                  <div className="">Mark</div>
                                                                </div>
                                                                {
                                                                  markingOptions?.map((item, index) => {
                                                                    return (
                                                                      <div className="options " id={`dynamicContent_${index}`} key={index}>
                                                                        <div className="">{item?.answerKey}</div>
                                                                        <div className="">{item?.mark}</div>
                                                                      </div>
                                                                    )
                                                                  })
                                                                }
                                                              </div>
                                                            }

                                                            {

                                                              (data?.questionTypeCode !== "FILL" && data?.questionTypeCode !== "MATCH") ?
                                                                <div style={{ display: "block" }} className="question-options-main">
                                                                  {options?.length > 0 && options?.map((option, index) => (
                                                                    option?.htmlTag &&
                                                                    <div key={index} style={{ display: "inline-block", width: "100%", marginTop: "3px", marginBottom: "3px" }} className="question-options-main-inner pending1">
                                                                      <div className="question-options optncls" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(index + 65)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                    </div>
                                                                  ))}
                                                                </div> :
                                                                data?.questionTypeCode !== "FILL" &&
                                                                <div style={{ display: "block" }} className="question-options-main">
                                                                  {(options?.length > 0 || options?.legnth !== 0) && options?.map((option, index) => (
                                                                    <div key={index} style={{ display: "flex", width: "100%", paddingLeft: "10%" }}>
                                                                      <div style={{ flex: "1", paddingRight: "5px" }} id="MTF2">
                                                                        <div style={{ whiteSpace: "nowrap", display: "flex" }} className="question-options3">
                                                                          <div style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: `${String.fromCharCode(index + 65)}.&nbsp; ${option?.htmlTagQuestion}` }}></div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ flex: "1", paddingLeft: "5px" }} id="MTF2">
                                                                        <div style={{ fontSize: "11px", alignContent: "center", fontFamily: "Poppins", overflow: "auto" }}>
                                                                          <div dangerouslySetInnerHTML={{ __html: option?.jumbledAnswer }}></div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  ))}
                                                                </div>

                                                            }
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                    //   )
                                                    // })
                                                  }
                                                </>
                                              </div>
                                            )
                                          })

                                        }
                                      </div>
                                    </div>

                                    {
                                      // !IsPendingClicked || (IsPendingClicked && !showEditBtn) &&
                                      // ((!IsPendingClicked || (IsPendingClicked && !showEditBtn)) && !IsApproveRejectEnabled && showEditBtn) &&
                                      ((!showEditBtn && !IsApproveRejectEnabled) || (!IsPendingClicked || (IsPendingClicked && !showEditBtn)) && !IsApproveRejectEnabled) &&
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end",
                                          columnGap: "1rem",
                                          // padding: "10px",
                                        }}
                                      >
                                        <DarkGreenButton id="replace-btn" disabled={isBtnEnabledReplace} onClick={() => { replaceSelected(qstnpaperData, QstnPaperFormData) }}>Replace selected </DarkGreenButton>
                                        <SkyBlueButton id="provide-own-btn"
                                          disabled={isBtnEnabledOwn}
                                          onClick={() => { providemyown() }}
                                        >
                                          I will provide own
                                        </SkyBlueButton>
                                        <DarkYellowButton id="show-marking-btn" onClick={() => { showMarking() }}>{showmarkingValue ? <>Hide Marking</> : <>Show Marking</>}</DarkYellowButton>
                                      </div>
                                    }

                                    {
                                      (!IsApproveRejectEnabled && localStorage.getItem("role") !== "TEACHER") &&
                                      <div id="apprv_rjct"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end",
                                          columnGap: "1rem",
                                          padding: "10px",
                                        }}
                                      >
                                        <DarkGreenButton id="approve-btn" onClick={() => { approveQstnPAper() }}>Approve</DarkGreenButton>
                                        {/* <DarkRedButton id="replace-btn" onClick={() => { rejectQstnPaper() }}>Reject</DarkRedButton> */}
                                        <DarkRedButton id="reject-btn" onClick={() => {
                                          setrejectReason("");
                                          setIsRejectScreenOpen(true);
                                        }}>Reject</DarkRedButton>
                                      </div>
                                    }

                                  </div>
                                }
                              </div>
                            }

                          </Paper>
                        </TabPanel>
                      }
                      {
                        (tabsValue == 2 || tabsValue == "2") &&
                        <TabPanel value="2" id="pdf_preview_periodic" className="pdf_preview_periodic">
                          <Paper sx={{ borderRadius: "7px", height: "100%" }}>
                            <div id="header-page"
                              style={{
                                background: "rgb(96 91 161 / 81%)",
                                padding: "15px",
                                color: "white",
                                borderTopLeftRadius: "7px",
                                borderTopRightRadius: "7px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>Periodic Paper</div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                }}
                              >
                                {/* <DarkGreenButton id="save-btn" onClick={handleSaveClick}>Save</DarkGreenButton>
                      <DarkYellowButton id="preview-btn" onClick={PreviewQstnPaper}>Preview</DarkYellowButton> */}
                                {
                                  // (showEditBtn && !IsApproveRejectEnabled) &&
                                  showEditBtn &&

                                  < div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                      gap: "5px",
                                      background: "#082fff",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => { EditMethod() }}
                                  >Edit
                                  </div>
                                }
                                {
                                  (!showPeriodicQstnPaperNotEnabled && !showEditBtn && !IsApproveRejectEnabled) &&
                                  <>
                                    <DarkGreenButton id="save-btn"
                                      disabled={isBtnEnabled}
                                      onClick={() => {
                                        setIsBtnEnabled(true);
                                        setHidetheSuccessMessage(false);
                                        setTimeout(() => {
                                          handleSaveClick({}, [], false, 13);
                                        }, 300);
                                      }}>Save</DarkGreenButton>
                                    <DarkYellowButton id="preview-btn" onClick={PreviewQstnPaper}>Preview</DarkYellowButton>
                                    <Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Box sx={style1}>
                                        <Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                          sx={{ textAlign: 'center', fontFamily: 'Poppins' }}
                                        >
                                          Preview
                                        </Typography>
                                        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                                          <img
                                            id="image1"
                                            src={imageSrc}
                                            alt="Preview"
                                            style={{ width: '100%', maxHeight: '70vh', height: 'auto' }}
                                          />
                                        </div>
                                        <Button
                                          onClick={handleClose}
                                          sx={{
                                            position: 'absolute',
                                            right: 8,
                                            bottom: 8,
                                          }}
                                        >
                                          Close
                                        </Button>
                                      </Box>
                                    </Modal>
                                  </>
                                }
                                {
                                  (!showPeriodicQstnPaperNotEnabled && !showEditBtn && !IsApproveRejectEnabled) &&
                                  <DarkOrangeButton id="cancel-btn" onClick={() => {
                                    SetshowPeriodicQstnPaperNotEnabled(true);
                                    setIsPendingClicked(false);
                                    setSelectedIds([]);
                                    setSelectedGrade("");
                                    setSelectedLevel("");
                                    setSelectedsubjectName("");
                                    setSelectedSubject("");
                                    setSelectedMonth("");
                                    setSelectedPaperName("")
                                    setTitle("");
                                    setShowPrint(false);
                                    setChapterList([]);
                                    setHidetheSuccessMessage(true);
                                  }}>Cancel</DarkOrangeButton>
                                }
                                {/* {
                                showPrint &&
                                <DarkOrangeButton id="print-btn" onClick={() => { printDiv() }
                                }>Print</DarkOrangeButton>
                              } */}
                              </div>
                            </div>

                            {
                              showPeriodicQstnPaperNotEnabled &&
                              <div
                                style={{
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                  // rowGap: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "10px",
                                  }}
                                >
                                  {/* {
                          istitleEmpty &&
                          <span style={{ color: "red" }}>Enter title to proceed</span>
                        } */}
                                  {/* <div style={{ fontSize: "16px", fontWeight: "bold" }}>Exam Paper Title Name</div> */}

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: "1rem",
                                      alignItems: "center",
                                      // justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                        alignItems: "center",

                                      }}
                                    >
                                      {/* <TextField className="text-field-subjective" id="text-field-subjective"
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Enter Your Title"}
                                        sx={{ fontSize: "15px !important" }}
                                        InputProps={{
                                          endAdornment: <IconButton />,
                                        }}
                                        style={{ borderRadius: "150px", width: "200px", fontSize: "13px !important" }}
                                        value={title}
                                        onChange={handleTitleChange}
                                      />
                                      {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>} */}

                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"

                                        >
                                          Select Grade
                                        </InputLabel>

                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedGrade ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedGrade}
                                          onChange={async (e) => {
                                            setSelectedGrade(e.target.value);
                                            CreateQuestionPaper(selectedSubject)
                                            setSelectedLevel("");
                                            // console.log("setSelectedLevel 2", "")
                                            // console.log(e.target.value);
                                            // console.log("gradeList", gradeList);
                                            // console.log(bluePrintD.data, "==============", bluePrintD)
                                            var bluprintBsdOnGrd = [];
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Periodic");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.gradeId === e.target.value);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList);
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject)
                                            }
                                            var sublist = await fetchSubjectData(e.target.value);
                                            // console.log("sublist", sublist, chapterList);
                                            setSubjectList(sublist)
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            setSelectedPaperName("")
                                          }}
                                        >
                                          {gradeList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.discription} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorGrade && <p style={{ color: 'red' }}>{errorGrade}</p>}
                                      </FormControl> */}
                                      <div>
                                        <TextField className="text-field-subjective"
                                          id="text-field-subjective"
                                          type="text"
                                          label="Exam Paper Title Name"
                                          placeholder="Enter Your Title"
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                            sx: {
                                              color: '#e7ab3c',
                                              '&.Mui-focused': {
                                                color: '#e7ab3c',
                                              },
                                            },
                                          }}
                                          sx={{
                                            margin: '5px 0 ',
                                            width: 'max-content',
                                            fontSize: '15px !important',
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                borderColor: 'gray',
                                              },
                                              '&.Mui-focused fieldset': {
                                                borderColor: '#e7ab3c',
                                              },
                                              borderRadius: '8px',
                                            },
                                            '& input::placeholder': {
                                              fontSize: '13px !important',
                                              color: 'gray',
                                            },
                                            '& label': {
                                              fontSize: '15px',
                                              paddingRight: '5px',
                                              color: '#e7ab3c'
                                            },
                                          }}
                                          value={title}
                                          onChange={handleTitleChange}
                                        />
                                        {errorTitle && <p style={{ color: 'red' }}>{errorTitle}</p>}
                                      </div>
                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Grade
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Grade' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={props => (
                                            <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedGrade}
                                          onChange={async (e) => {
                                            setSelectedGrade(e.target.value);
                                            CreateQuestionPaper(selectedSubject)
                                            setSelectedLevel("");
                                            var bluprintBsdOnGrd = [];
                                            //console.log("papertypeListperiodic", papertypeList)
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Periodic");
                                            if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.gradeId === e.target.value);
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject)
                                            }
                                            var sublist = await fetchSubjectData(e.target.value);
                                            setSubjectList(sublist)
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd);
                                            setSelectedPaperName("")


                                          }}
                                        >
                                          {gradeList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.discription} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorGrade && <p style={{ color: 'red' }}>{errorGrade}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Subject
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedSubject ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedSubject}
                                          onChange={(e) => {
                                            setSelectedSubject(e?.target?.value);
                                            setSelectedLevel("");
                                            // console.log("setSelectedLevel 3", "")
                                            setSelectedIds([])
                                            // console.log(e.target.value, subjectList);
                                            var idtocompare = e?.target?.value
                                            var subjectName = subjectList.find(e => e?.id === idtocompare);
                                            // console.log("subjectName", subjectName)
                                            setSelectedsubjectName(subjectName.subject);
                                            // to filter papertypelist,blueprint based on grade and subject 
                                            var bluprintBsdOnGrd = [];
                                            bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === e.target.value);
                                            // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Periodic");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id)
                                            }
                                            // console.log(bluprintBsdOnGrd, "1010", e.target.value, selectedGrade, chapterList);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {subjectList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.subject} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorSubject && <p style={{ color: 'red' }}>{errorSubject}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Subject
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Subject' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedSubject}
                                          onChange={(e) => {
                                            setSelectedSubject(e?.target?.value);
                                            setSelectedLevel("");
                                            // console.log("setSelectedLevel 3", "")
                                            setSelectedIds([])
                                            // console.log(e.target.value, subjectList);
                                            var idtocompare = e?.target?.value
                                            var subjectName = subjectList.find(e => e?.id === idtocompare);
                                            // console.log("subjectName", subjectName)
                                            setSelectedsubjectName(subjectName.subject);
                                            // to filter papertypelist,blueprint based on grade and subject 
                                            var bluprintBsdOnGrd = [];
                                            bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === e.target.value);
                                            // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                            var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Periodic");
                                            // setPapertypeList(filteredPaperTypeList)
                                            if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                              bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id)
                                            }
                                            // console.log(bluprintBsdOnGrd, "1010", e.target.value, selectedGrade, chapterList);
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {subjectList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.id}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.subject} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorSubject && <p style={{ color: 'red' }}>{errorSubject}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Level
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedLevel ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedLevel}
                                          onChange={(e) => {
                                            setSelectedLevel(e?.target?.value);
                                            // console.log("setSelectedLevel 4", e.target.value)
                                            // console.log("e?.target?.value Term", e?.target?.value, listOfLevel, bluePrintD?.data)
                                            var levelData = listOfLevel?.filter(v => v.id == e?.target?.value) || []
                                            // console.log("levelData", levelData, levelData[0].levelName);
                                            var bluprintBsdOnGrd = [];
                                            if (levelData?.length > 0) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.levelName === levelData[0].levelName);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                              var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Periodic");
                                              if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id);
                                                // console.log(bluprintBsdOnGrd, "11111111111111", e.target.value, selectedGrade, chapterList);
                                              }
                                              if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject);
                                                // console.log(bluprintBsdOnGrd, "22222222222222", e.target.value, selectedGrade, chapterList);
                                              }
                                            }
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            // CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {listOfLevel?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item?.levelName} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorLevel && <p style={{ color: 'red' }}>{errorLevel}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel'
                                          id="demo-simple-select-label"
                                        >
                                          Level
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Level ' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                                maxHeight: '190px',
                                                overflowY: 'auto',
                                                '&::-webkit-scrollbar': {
                                                  width: '2px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                  background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                  background: '#888',
                                                  borderRadius: '4px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                  background: '#555',
                                                },
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px',
                                                overflowY: 'auto',
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedLevel}
                                          onChange={(e) => {
                                            setSelectedLevel(e?.target?.value);
                                            // console.log("setSelectedLevel 4", e.target.value)
                                            // console.log("e?.target?.value Term", e?.target?.value, listOfLevel, bluePrintD?.data)
                                            var levelData = listOfLevel?.filter(v => v.id == e?.target?.value) || []
                                            // console.log("levelData", levelData, levelData[0].levelName);
                                            var bluprintBsdOnGrd = [];
                                            if (levelData?.length > 0) {
                                              bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.levelName === levelData[0].levelName);
                                              // console.log("bluprintBsdOnGrd", bluprintBsdOnGrd, selectedSubject, papertypeList, e.target.value, selectedGrade);
                                              var filteredPaperTypeList = papertypeList?.filter(p => p.paperType == "Periodic");
                                              if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade && v.paperTypeId === filteredPaperTypeList[0]?.id);
                                                // console.log(bluprintBsdOnGrd, "11111111111111", e.target.value, selectedGrade, chapterList);
                                              }
                                              if (bluprintBsdOnGrd?.length > 0 && selectedSubject) {
                                                bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.subjectId === selectedSubject);
                                                // console.log(bluprintBsdOnGrd, "22222222222222", e.target.value, selectedGrade, chapterList);
                                              }
                                            }
                                            if (bluprintBsdOnGrd?.length > 0) {
                                              setSelectedBluePrint(bluprintBsdOnGrd[bluprintBsdOnGrd?.length - 1]?.id)
                                            }
                                            setBluePrintDDropDown(bluprintBsdOnGrd)
                                            // CreateQuestionPaper(e?.target?.value);
                                          }
                                          }
                                        >
                                          {listOfLevel?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item?.id}
                                                key={item?.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item?.levelName} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errorLevel && <p style={{ color: 'red' }}>{errorLevel}</p>}
                                      </FormControl>

                                      {/* <FormControl
                                  sx={{  width: "150px" }}
                                >
                                  <InputLabel id="demo-simple-select-label"
                                        style={{ fontSize: "18px" }}>
                                    Select Paper Type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Tag" />}
                                    sx={{
                                      height: "100%",
                                      "&.Mui-selected": {
                                        backgroundColor: 'orange',
                                        color: "#FFFFFF"
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: '#fcedc2',
                                        color: "#000000",
                                      },
                                      "&:not(.Mui-selected):hover": {
                                        backgroundColor: '#fcedc2',
                                        color: '#000000',
                                      },
                                    }}
                                    style={{
                                      borderRadius: "150px",
                                      padding: "4px",
                                      color: selectedSubject ? "orange" : "black"
                                    }}
                                    size={"small"}
                                    IconComponent={(props) => (
                                      <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                      />
                                    )}
                                    value={selectedPaper}
                                    onChange={(e) => {
                                      //console.log(e?.target)
                                      setSelectedPaper(e?.target?.value);

                                      var bluprintBsdOnGrd = [];
                                      bluprintBsdOnGrd = bluePrintD?.data?.filter(v => v.subjectId === selectedSubject);
                                      if (bluprintBsdOnGrd.length > 0 && selectedGrade) {
                                        bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.gradeId === selectedGrade)
                                      }
                                      if (bluprintBsdOnGrd.length > 0 && e.target.value) {
                                        bluprintBsdOnGrd = bluprintBsdOnGrd?.filter(v => v.paperTypeId === e.target.value)
                                      }
                                      //console.log(bluprintBsdOnGrd, "after all filters", e.target.value, selectedGrade, selectedSubject);
                                      setBluePrintDDropDown(bluprintBsdOnGrd)

                                    }
                                    }
                                    disabled={selectedPaper ? true : false}
                                  >
                                    {papertypeListMenu?.map((item) => {
                                      return (
                                        <MenuItem
                                          value={item.id}
                                          key={item.id}
                                          className={dashboardContentStyles.menu_item}
                                          sx={{
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000"
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            }
                                          }}
                                        >
                                          <ListItemText primary={item.paperType} />
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl> */}

                                      {/* <FormControl
                                        sx={{ width: "150px", fontSize: "15px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Year
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedGrade ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedYear}
                                          onChange={(e) => {
                                            setSelectedYear(e?.target?.value);
                                            const desiredAcademicYear = e?.target?.value
                                            // console.log("1091", e?.target?.value, yearList, desiredAcademicYear)
                                            var iid = yearList.find(item => item?.academicYear === desiredAcademicYear)
                                            // console.log(e.target.value, yearList, iid)
                                            if (iid) {
                                              setSelectedYearId(iid.id)
                                            }
                                          }}
                                          disabled={selectedYearId ? true : false}
                                        >
                                          {yearList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.academicYear}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.academicYear} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel' id="demo-simple-select-label"
                                        >
                                          Academic Year
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Academic Year' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px'
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedYear}
                                          onChange={(e) => {
                                            setSelectedYear(e?.target?.value);
                                            const desiredAcademicYear = e?.target?.value
                                            // console.log("1091", e?.target?.value, yearList, desiredAcademicYear)
                                            var iid = yearList.find(item => item?.academicYear === desiredAcademicYear)
                                            // console.log(e.target.value, yearList, iid)
                                            if (iid) {
                                              setSelectedYearId(iid.id)
                                            }
                                          }}
                                          disabled={selectedYearId ? true : false}
                                        >
                                          {yearList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.academicYear}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.academicYear} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>

                                      {/* <FormControl
                            sx={{  width: "150px" }}
                          >
                            <InputLabel id="demo-simple-select-label"
                                        style={{ fontSize: "18px" }}>
                              Select Month
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              MenuProps={MenuProps}
                              input={<OutlinedInput label="Tag" />}
                              sx={{
                                height: "100%",
                                "&.Mui-selected": {
                                  backgroundColor: 'orange',
                                  color: "#FFFFFF"
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: '#fcedc2',
                                  color: "#000000",
                                },
                                "&:not(.Mui-selected):hover": {
                                  backgroundColor: '#fcedc2',
                                  color: '#000000',
                                },
                              }}
                              style={{
                                borderRadius: "150px",
                                padding: "4px",
                                color: "orange"
                              }}
                              size={"small"}
                              IconComponent={(props) => (
                                <ExpandMoreIcon
                                  {...props}
                                  sx={{ fontSize: "1.5vw" }}
                                />
                              )}
                              value={selectedmonth}
                              onChange={(e) => {
                                setSelectedMonth(e.target.value);
                                //console.log(selectedmonth)
                                CreateQuestionPaper()
                              }}
                            >
                              <MenuItem
                                value={"month"}
                                className={dashboardContentStyles.menu_item}
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: 'orange',
                                    color: "#FFFFFF"
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: '#fcedc2',
                                    color: "#000000"
                                  },
                                  "&:not(.Mui-selected):hover": {
                                    backgroundColor: '#fcedc2',
                                    color: '#000000',
                                  }
                                }}
                              >
                                <ListItemText primary={"Month 1"} />
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                                      {/* <FormControl
                                  sx={{  width: "150px" }}
                                >
                                  <InputLabel id="demo-simple-select-label"
                                        style={{ fontSize: "18px" }}>
                                    Select Blueprint
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput label="Tag" />}
                                    sx={{
                                      height: "100%",
                                      "&.Mui-selected": {
                                        backgroundColor: 'orange',
                                        color: "#FFFFFF"
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: '#fcedc2',
                                        color: "#000000",
                                      },
                                      "&:not(.Mui-selected):hover": {
                                        backgroundColor: '#fcedc2',
                                        color: '#000000',
                                      },
                                    }}
                                    style={{
                                      borderRadius: "150px",
                                      padding: "4px",
                                      color: selectedPaperName ? "orange" : "black"
                                    }}
                                    size={"small"}
                                    IconComponent={(props) => (
                                      <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                      />
                                    )}
                                    value={selectedPaperName}
                                    onChange={(e) => {
                                      //console.log(e.target.value)
                                      setSelectedPaperName(e.target.value);
                                      //console.log(selectedPaperName)
                                      CreateQuestionPaper(selectedSubject)
                                      handleBlueprintSelect(e.target.value)
                                    }}
                                  >
                                    {
                                      //console.log("bluePrintDDropDown", bluePrintDDropDown, bluePrintD?.data)
                                    }
                                    {bluePrintDDropDown?.map((item) => {
                                      return (
                                        <MenuItem
                                          value={item.paperTitle}
                                          key={item.id}
                                          className={dashboardContentStyles.menu_item}
                                          sx={{
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000"
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            }
                                          }}
                                        >
                                          <ListItemText primary={item.paperTitle} />
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  {errorBluePrint && <p style={{ color: 'red' }}>{errorBluePrint}</p>}
                                </FormControl> */}

                                      {/* <FormControl
                                        sx={{ width: "150px" }}
                                      >
                                        <InputLabel id="demo-simple-select-label"
                                        >
                                          Select Month
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          MenuProps={MenuProps}
                                          input={<OutlinedInput label="Tag" />}
                                          sx={{
                                            height: "100%",
                                            "&.Mui-selected": {
                                              backgroundColor: 'orange',
                                              color: "#FFFFFF"
                                            },
                                            "&.Mui-selected:hover": {
                                              backgroundColor: '#fcedc2',
                                              color: "#000000",
                                            },
                                            "&:not(.Mui-selected):hover": {
                                              backgroundColor: '#fcedc2',
                                              color: '#000000',
                                            },
                                          }}
                                          style={{
                                            borderRadius: "5px",
                                            padding: "4px",
                                            color: selectedmonth ? "orange" : "black"
                                          }}
                                          size={"small"}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          value={selectedmonth}
                                          onChange={(e) => {
                                            // console.log(e.target.value, "select month")
                                            setSelectedMonth(e.target.value);
                                            // console.log(selectedmonth)
                                            // CreateQuestionPaper(selectedSubject)
                                          }}
                                        >
                                          {monthList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.value}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  "&.Mui-selected": {
                                                    backgroundColor: 'orange',
                                                    color: "#FFFFFF"
                                                  },
                                                  "&.Mui-selected:hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: "#000000"
                                                  },
                                                  "&:not(.Mui-selected):hover": {
                                                    backgroundColor: '#fcedc2',
                                                    color: '#000000',
                                                  }
                                                }}
                                              >
                                                <ListItemText primary={item.value} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                      </FormControl> */}

                                      <FormControl fullWidth>
                                        <InputLabel
                                          sx={{
                                            fontSize: '13px',
                                            color: '#e7ab3c',
                                            top: '-10px',
                                            '&.MuiInputLabel-shrink': {
                                              top: '0',
                                            },
                                            '&.Mui-focused': {
                                              color: '#e7ab3c'
                                            }
                                          }}
                                          className='dropDownLabel'
                                          id="demo-simple-select-label"
                                        >
                                          Month
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          input={<OutlinedInput label='Month ' />}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                width: '150px',
                                                marginTop: '2px',
                                                maxHeight: '190px',
                                                overflowY: 'auto',
                                                '&::-webkit-scrollbar': {
                                                  width: '2px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                  background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                  background: '#888',
                                                  borderRadius: '4px',
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                  background: '#555',
                                                },
                                              },
                                            },
                                            MenuListProps: {
                                              sx: {
                                                padding: '0px',
                                                overflowY: 'auto',
                                              },
                                            },
                                          }}
                                          IconComponent={(props) => (
                                            <ExpandMoreIcon
                                              {...props}
                                              sx={{ fontSize: "1.5vw" }}
                                            />
                                          )}
                                          sx={{
                                            width: '150px',
                                            height: '30px',
                                            padding: '10px',
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#000',
                                            },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#e7ab3c',
                                            }
                                          }}
                                          className='dropdownSelect'
                                          value={selectedmonth}
                                          onChange={(e) => {
                                            // console.log(e.target.value, "select month")
                                            setSelectedMonth(e.target.value);
                                            // console.log(selectedmonth)
                                            // CreateQuestionPaper(selectedSubject)
                                          }}
                                        >
                                          {monthList?.map((item) => {
                                            return (
                                              <MenuItem
                                                value={item.value}
                                                key={item.id}
                                                className={dashboardContentStyles.menu_item}
                                                sx={{
                                                  fontSize: '10px',
                                                  backgroundColor: 'white',
                                                  '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                  },
                                                  '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                      backgroundColor: '#f4d788',
                                                    },
                                                  },
                                                }}
                                              >
                                                <ListItemText primary={item.value} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                      </FormControl>

                                    </div>
                                    <DarkYellowButton fullWidth
                                      style={{ borderRadius: "150px", height: "48px", marginBottom: "11px" }}
                                      disabled={isCreateBtnEnabled}
                                      onClick={() => {
                                        setIsCreateBtnEnabled(true); setTimeout(() => {
                                          createBtn()
                                        }, 300);
                                      }}
                                    >
                                      <NoteAddOutlinedIcon style={{ fontSize: "1.3vw", color: "white", backgroundColor: "#F78F02" }} />
                                      Create
                                    </DarkYellowButton>
                                  </div>
                                </div>
                                {/* {
                                  //console.log("CreateAlert", CreateAlert)
                                } */}
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  {CreateAlert && (
                                    <Alert
                                      action={
                                        <IconButton
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                            setCreateAlert(false);
                                            setIsBtnEnabled(false);
                                            setIsBtnEnabledOwn(false);
                                            setIsBtnEnabledReplace(false);
                                            setIsCreateBtnEnabled(false);
                                          }}
                                        >
                                          <CancelOutlinedIcon />
                                        </IconButton>
                                      }
                                      icon={false}
                                      sx={{
                                        color: "#FFFEFA",
                                        fontWeight: 500,
                                        fontSize: "0.93vw",
                                        marginRight: "10%",
                                        // background: "#bddabca1",
                                        background: "red",
                                      }}
                                    >
                                      {CreateAlert}
                                    </Alert>
                                  )}
                                </div>
                                <div
                                  style={{
                                    padding: "20px",
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    rowGap: "1rem",
                                    minHeight: "595px",
                                    overflow: "auto",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: "1rem",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        background: "rgb(227 225 225 / 61%)",
                                        padding: "10px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {selectedsubjectName}
                                    </div>
                                    {
                                      ChapterListError &&
                                      <p style={{ color: "red" }}>Choose chapter to proceed*</p>
                                    }
                                    <Box sx={{ flexGrow: 1 }}>
                                      <Grid container spacing={2}>
                                        {chapterList?.map((data, index) => {
                                          //console.log("data chapter list", data)
                                          return (
                                            <Grid
                                              item
                                              xs={12}
                                              md={4}
                                              sm={6}
                                              lg={2.4}
                                              key={index}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: "1rem",
                                                  paddingTop: "5px",
                                                }}
                                                key={index}
                                              >
                                                <div
                                                  id={`az-teacher-chapter-card-${data?.chapter.toLowerCase().replace(/ /g, "_")}`}
                                                  key={data?.id}
                                                  className={dashboardContentStyles.card}
                                                >
                                                  <img
                                                    id="image2"
                                                    src={data?.thumbnailPath}
                                                    width={110}
                                                    alt={""}
                                                    // style={{ maxHeight: "138px" }}
                                                    className={dashboardContentStyles.az_quiz_card_img} />
                                                  <div
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: 600,
                                                      color: "#423d5c",
                                                    }}
                                                  >
                                                    {data?.chapter}
                                                  </div>
                                                </div>

                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      sx={{ color: 'blue' }}
                                                      color="default"
                                                      key={data?.id}
                                                      checked={selectedIds.includes(data?.id)}
                                                      onChange={(e) => handleCheckboxChange(e, data?.id)}
                                                    />
                                                  }
                                                  label="Select"
                                                  sx={{
                                                    color: "blue",
                                                    fontWeight: 600,
                                                    paddingLeft: "10px"
                                                  }}
                                                  key={data?.id}
                                                />
                                              </div>
                                            </Grid>
                                          );
                                        })}
                                      </Grid>
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              !showPeriodicQstnPaperNotEnabled &&
                              <div id="pdf_preview_periodic_inner_div" style={{ paddingBottom: "15px" }}>

                                {
                                  QstnPaperFormData &&
                                  <div
                                    style={{
                                      padding: "20px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      // height: "620px",
                                      overflow: "hidden",
                                      padding: "30px",
                                      margin: "30px",
                                      borderRadius: "7px",
                                      border: "2px solid"
                                    }}
                                  >
                                    <div
                                      className="avoid-page-break"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row-reverse"
                                      }}>

                                      <img className="avoid-page-break" crossOrigin="anonymous" id="img81" src={QstnPaperFormData?.data?.branchLogo || QstnPaperFormData?.branchLogo} alt={""} height={50} />
                                    </div>
                                    <div
                                      style={{
                                        overflow: "scroll",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "1rem",
                                        padding: "30px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div style={{ width: "164px" }} />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              flexDirection: "column",
                                              rowGap: "5px",
                                              fontSize: "0.93vw",
                                            }}
                                          >
                                            <div className="question_paper_font">
                                              {(QstnPaperFormData?.data?.school || QstnPaperFormData?.school || "").toUpperCase()}
                                            </div>
                                            <div className="question_paper_font">
                                              {/* SAMSIDH MOUNT LITERA */}
                                              {QstnPaperFormData?.data?.branch || QstnPaperFormData?.branch}
                                            </div>
                                            <div
                                              className="question_paper_font"
                                            >
                                              {/* Samsidh Mount Litera, HSR Extension */}
                                              {QstnPaperFormData?.data?.schoolLocality || QstnPaperFormData?.schoolLocality}
                                            </div>
                                            <div className="question_paper_font" id="paperName">
                                              {/* Important Paper */}
                                              {/* {QstnPaperFormData?.data?.paperTitle} */}
                                              {
                                                title || QstnPaperFormData?.questionPaperName
                                              }
                                            </div>
                                            <div
                                              className="question_paper_font"
                                            >
                                              {QstnPaperFormData?.data?.subject || "Subject : " + QstnPaperFormData?.subject}
                                            </div>
                                          </div>
                                          <div style={{ width: "164px" }} />
                                          {/* <img crossOrigin="anonymous" id="img2" src={QstnPaperFormData?.data?.branchLogo || QstnPaperFormData?.branchLogo} alt={""} height={50} /> */}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            fontSize: "0.93vw",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "start",
                                              rowGap: "5px",
                                              // fontSize: "17px",
                                            }}
                                          >

                                            <div className="question_paper_font">{QstnPaperFormData?.data?.grade || QstnPaperFormData?.grade}</div>
                                            <div className="question_paper_font">Date : </div>
                                          </div>
                                          {showMessage && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setShowMessage(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "green",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "rgba(200,255,200,0.63)",
                                              }}
                                            >
                                              Own Question Provide Successfully
                                            </Alert>
                                          )}

                                          {showSuccessQstnMessageEnable && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setshowSuccessQstnMessageEnable(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "green",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "rgba(200,255,200,0.63)",
                                              }}
                                            >
                                              {/* Successfully Saved the Question Paper */}
                                              {showSuccessQstnMessage}
                                            </Alert>
                                          )}

                                          {showerror && (
                                            <Alert
                                              action={
                                                <IconButton
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                    setShowerror(false);
                                                  }}
                                                >
                                                  <CancelOutlinedIcon />
                                                </IconButton>
                                              }
                                              icon={false}
                                              sx={{
                                                color: "blue",
                                                fontWeight: 500,
                                                fontSize: "0.93vw",
                                                // background: "#bddabca1",
                                                background: "red",
                                              }}
                                            >
                                              {showerrorMessage}
                                            </Alert>
                                          )}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "end",
                                              rowGap: "5px",
                                              fontSize: "0.93vw",
                                            }}
                                          >
                                            <div className="question_paper_font">Maximum Marks : {QstnPaperFormData?.data?.totalMark || QstnPaperFormData?.totalMark}</div>
                                            <div className="question_paper_font">Duration : {QstnPaperFormData?.data?.examDurationInMin || QstnPaperFormData?.examDurationInMin} Minutes</div>
                                          </div>
                                        </div>

                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "1rem",
                                          fontSize: "0.93vw",
                                        }}
                                      >
                                        <div className="instructions_container">
                                          <div className="question_paper_instructions">General Instructions</div>
                                          <div>
                                            <ul className="unordered_list_format">
                                              {(QstnPaperFormData?.splittedHeading || QstnPaperFormData?.data?.splittedHeading)?.map((instruction, index) => {
                                                return (<li key={index} className="question_paper_instructions_list">{instruction}</li>)
                                              })}
                                            </ul>
                                          </div>

                                        </div>


                                        {
                                          qstnpaperData?.map((sectionData, sectionIndex) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  rowGap: "1rem",
                                                  paddingTop: "10px",
                                                  paddingBottom: "10px"
                                                }}
                                                key={sectionIndex}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    // fontWeight: 600,
                                                    // fontSize: "1.04vw",
                                                    padding: 10,
                                                  }}
                                                  className="question_paper_font section-heading"
                                                >
                                                  {sectionData?.section}

                                                </div>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    // fontWeight: 600,
                                                    // fontSize: "20px",
                                                    paddingLeft: "30px",
                                                  }}
                                                  className="question_paper_font_text "
                                                >
                                                  {sectionData?.text}

                                                </div>
                                                <>
                                                  {
                                                    // sectionData?.data.map((seltd, sindex) => {

                                                    // return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "10px"
                                                        // rowGap: "1px",
                                                      }}
                                                    >
                                                      {sectionData?.data?.map((data, index) => {
                                                        // console.log("rerun", data)
                                                        const orderArray = Object.values(data?.correctResponse || {})
                                                        const markingOptions = orderArray.map((item) => ({
                                                          answerKey: item.answerKey,
                                                          mark: item?.mark
                                                        }))
                                                        // console.log(data, "datadatadatadatta", markingOptions);
                                                        const id = `SectionA${data?.id}`;
                                                        const isSelected = selectedIds.includes(id);
                                                        var options = Array.isArray(data?.options?.[0]) ? data.options[0] : Array.isArray(data?.options) ? data?.options : [data?.options] || [];
                                                        // console.log(data?.question, data?.questionTypeCode, "main question")
                                                        serial_number = getIncrementedSerialNumber(serial_number)
                                                        return (
                                                          <div style={{ marginLeft: "10px", }}>

                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: (index == 0 && data?.questionTypeCode == "MCQ") ? "1px" : "25px"
                                                              }}
                                                              key={data?.id}
                                                            >
                                                              <div id="question2" className="Qstn_1"
                                                                style={{
                                                                  flex: "95%",
                                                                  display: "flex",
                                                                  flexDirection: "row",
                                                                  alignItems: "center",
                                                                }}
                                                                key={data?.id}
                                                              >
                                                                <Radio id="radio-btn"
                                                                  {...controlProps(data?.id)}
                                                                  checked={selectedOptions[data?.question] === data?.id}
                                                                  onChange={() => {
                                                                    handleRadioChange(data?.id, data?.question, data, options, index)
                                                                    setChosenQuestion(data?.id)
                                                                  }}

                                                                />
                                                                {
                                                                  data?.question &&
                                                                  <div style={{ display: "contents" }}>
                                                                    {/* <p
                                                                      id="qstn-3"
                                                                      style={{ display: "flex", padding: "10px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "wrap" }}
                                                                      className="queestion-text"
                                                                      dangerouslySetInnerHTML={{ __html: `${serial_number}. &nbsp; ${data?.question}` }}
                                                                    ></p> */}
                                                                    <div style={{
                                                                      display: "flex",
                                                                      flexDirection: "row", // Aligns the number and text at the top
                                                                      fontFamily: "Poppins",
                                                                      fontSize: "0.88vw",
                                                                      color: "#000",
                                                                      marginLeft: '10px'
                                                                    }}
                                                                      className="main-div3">
                                                                      <div>
                                                                        {data?.serialNumber || serial_number}.
                                                                      </div>
                                                                      <div style={{ marginLeft: '16px' }}>
                                                                        <p
                                                                          id="qstn-31"
                                                                          style={{ display: "flex", flexDirection: 'column', fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "normal" }}
                                                                          className="queestion-text"
                                                                          dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
                                                                        ></p>
                                                                      </div>

                                                                    </div>
                                                                  </div>
                                                                }

                                                              </div>
                                                              <div id="mark" style={{ flex: "5%", textAlign: "right", fontSize: "0.88vw" }}>[{data?.marks}]</div>
                                                            </div>

                                                            {
                                                              data?.imageUrl &&
                                                              <div className="avoid-page-break mainQ">
                                                                <img className="img-break-avoid" crossOrigin="anonymous" id="img1" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.imageUrl} width="50%" height="240px" />
                                                              </div>
                                                            }
                                                            {
                                                              data?.mapUrl &&
                                                              <div className="avoid-page-break mainQ">
                                                                <img className="img-break-avoid" crossOrigin="anonymous" id="map_img" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.mapUrl} height="240px" />
                                                              </div>
                                                            }

                                                            {
                                                              data?.subQuestions?.length > 0 && data?.subQuestions?.map((subQuestion, subInd) => {
                                                                return (
                                                                  <div key={`sub-question3-${subInd}`}>
                                                                    <div className="sub-qstns" key={subInd}>
                                                                      <p
                                                                        id="qstn-43"
                                                                        style={{ display: "flex", width: "95%", paddingLeft: "10px", paddingRight: "20px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", whiteSpace: "normal" }}
                                                                        className="sub-questions"
                                                                        // dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${subInd + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                        dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${subInd + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                      ></p>

                                                                    </div>

                                                                    {
                                                                      subQuestion?.imageUrl &&
                                                                      <div className="avoid-page-break subQ">
                                                                        <img className="img-break-avoid" crossOrigin="anonymous" id="img3" style={{ paddingLeft: "50px" }} src={subQuestion?.imageUrl} height="240px" />
                                                                      </div>
                                                                    }

                                                                    {/* {subQuestion && (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") && subQuestion?.options?.length > 0 && subQuestion?.options?.map((option, subInd) => (
                                                                      <div className="question-options-main" style={{ display: "block", padding: "10px" }}>
                                                                        <div key={subInd} style={{ display: "inline-block", width: "100%" }}>
                                                                          <div style={{ paddingLeft: "50px", display: "inline-block" }} id="s1">
                                                                            {String.fromCharCode(97 + subInd)}. &nbsp;
                                                                          </div>
                                                                          <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                        </div>
                                                                      </div>
                                                                    ))} */}

                                                                    {
                                                                      subQuestion &&
                                                                      (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") &&
                                                                      (
                                                                        (subQuestion?.options?.length > 1
                                                                          ? subQuestion?.options.map((option, subInd) => (
                                                                            option?.htmlTag &&
                                                                            <div style={{ display: "block", padding: "10px" }}>
                                                                              <div key={subInd} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="101" id="s1">
                                                                                <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + subInd)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                              </div>
                                                                              {/* <div key={subInd} style={{ display: "flex", width: "100%" }}>
                                                                                <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="101" id="s1" >
                                                                                  {String.fromCharCode(97 + subInd)}. &nbsp;
                                                                                </div>
                                                                                <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                              </div> */}
                                                                            </div>
                                                                          ))
                                                                          : (subQuestion?.options[0]?.length > 0
                                                                            ? subQuestion?.options[0].map((option, subInd) => (
                                                                              option?.htmlTag &&
                                                                              <div style={{ display: "block", padding: "10px" }}>
                                                                                <div key={subInd} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="102" id="s1">
                                                                                  <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + subInd)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                                </div>
                                                                                {/* <div key={subInd} style={{ display: "inline-block", width: "100%" }}>
                                                                                  <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="102" id="s1">
                                                                                    {String.fromCharCode(97 + subInd)}. &nbsp;
                                                                                  </div>
                                                                                  <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                                </div> */}
                                                                              </div>
                                                                            ))
                                                                            : null
                                                                          )
                                                                        )
                                                                      )
                                                                    }
                                                                  </div>
                                                                );
                                                              })
                                                            }

                                                            {
                                                              (markingOptions && (markingOptions[0]?.mark !== null) && showmarkingValue && markingOptions?.length > 0) &&
                                                              <div className=" answerBg">
                                                                <div className=" header answer">
                                                                  <div className="">Answer/Term</div>
                                                                  <div className="">Mark</div>
                                                                </div>
                                                                {
                                                                  markingOptions?.map((item, index) => {
                                                                    return (
                                                                      <div className="options " id={`dynamicContent_${index}`} key={index}>
                                                                        <div className="">{item?.answerKey}</div>
                                                                        <div className="">{item?.mark}</div>
                                                                      </div>
                                                                    )
                                                                  })
                                                                }
                                                              </div>
                                                            }

                                                            {
                                                              (data?.questionTypeCode !== "FILL" && data?.questionTypeCode !== "MATCH") ?
                                                                <div style={{ display: "block" }} className="question-options-main">
                                                                  {options?.map((option, index) => (
                                                                    option?.htmlTag &&
                                                                    <div key={index} style={{ display: "inline-block", width: "100%", marginTop: "3px", marginBottom: "3px" }} className="question-options-main-inner 22222">
                                                                      <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(index + 65)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                    </div>
                                                                  ))}
                                                                </div> :
                                                                data?.questionTypeCode !== "FILL" &&
                                                                <div style={{ display: "block" }} className="question-options-main">
                                                                  {(options?.length > 0 || options?.legnth !== 0) && options?.map((option, index) => (
                                                                    <div key={index} style={{ display: "flex", width: "100%", paddingLeft: "10%" }}>
                                                                      <div style={{ flex: "1", paddingRight: "5px" }} id="MTF2">
                                                                        <div style={{ whiteSpace: "nowrap", display: "flex" }} className="question-options3">
                                                                          <div style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: `${String.fromCharCode(index + 65)}.&nbsp; ${option?.htmlTagQuestion}` }}></div>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ flex: "1", paddingLeft: "5px" }} id="MTF2">
                                                                        <div style={{ fontSize: "11px", alignContent: "center", fontFamily: "Poppins", overflow: "auto" }}>
                                                                          <div dangerouslySetInnerHTML={{ __html: option?.jumbledAnswer }}></div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  ))}
                                                                </div>
                                                            }
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                    //   )
                                                    // })
                                                  }
                                                </>
                                              </div>
                                            )
                                          })

                                        }
                                      </div>
                                    </div>

                                    {
                                      // ((!IsPendingClicked || (IsPendingClicked && !showEditBtn)) && !IsApproveRejectEnabled && showEditBtn) &&
                                      ((!showEditBtn && !IsApproveRejectEnabled) || (!IsPendingClicked || (IsPendingClicked && !showEditBtn)) && !IsApproveRejectEnabled) &&
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end",
                                          columnGap: "1rem",
                                          // padding: "10px",
                                        }}
                                      >
                                        <DarkGreenButton id="replace-btn" disabled={isBtnEnabledReplace} onClick={() => { replaceSelected(qstnpaperData, QstnPaperFormData); }}>Replace selected </DarkGreenButton>
                                        <SkyBlueButton id="provide-own-btn"
                                          disabled={isBtnEnabledOwn}
                                          onClick={() => { providemyown() }}
                                        >
                                          I will provide own
                                        </SkyBlueButton>
                                        <DarkYellowButton id="show-marking-btn" onClick={() => { showMarking() }}>{showmarkingValue ? <>Hide Marking</> : <>Show Marking</>}</DarkYellowButton>
                                      </div>
                                    }

                                    {
                                      (!IsApproveRejectEnabled && localStorage.getItem("role") !== "TEACHER") &&
                                      <div id="apprv_rjct"
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "end",
                                          columnGap: "1rem",
                                          padding: "10px",
                                        }}
                                      >
                                        <DarkGreenButton id="approve-btn" onClick={() => { approveQstnPAper() }}>Approve</DarkGreenButton>
                                        {/* <DarkRedButton id="replace-btn" onClick={() => { rejectQstnPaper() }}>Reject</DarkRedButton> */}
                                        <DarkRedButton id="reject-btn" onClick={() => {
                                          // console.log("rejectQstnPaperBtn called")
                                          setrejectReason("");
                                          setIsRejectScreenOpen(true);
                                        }}>Reject</DarkRedButton>
                                      </div>
                                    }

                                  </div>
                                }
                              </div>
                            }
                          </Paper>

                        </TabPanel>
                      }
                    </TabContext>
                    {visibleQuestionModal && (
                      // <AddQuestionModal
                      //   open={visibleQuestionModal}
                      //   close={() => { setVisibleQuestionModal(false); console.log("close modal called", selectedOption, selectedOptions, SelectedQuestionId); setSelectedOptions({}); setSelectedQuestionId("") }}
                      //   setShowMessage={setShowMessage}
                      //   selectedGrade={selectedGrade}
                      //   selectedSubject={selectedSubject}
                      //   selectedYear={selectedYear}
                      //   selectedMonth={selectedmonth}
                      //   selectedQuestionData={selectedQuestionData}
                      //   selectedQuestionOptionData={selectedQuestionOptionData}
                      //   setnewQuestion={setnewQuestion}
                      //   getResponse={getResponse}
                      //   qstnpaperData={qstnpaperData}
                      //   QstnPaperFormData={QstnPaperFormData}
                      // />
                      <AddQuestionModalObjective
                        open={visibleQuestionModal}
                        close={() => { setVisibleQuestionModal(false); console.log("close modal called", selectedOption, selectedOptions, SelectedQuestionId); sessionStorage.removeItem("parentId"); setSelectedOptions({}); setSelectedQuestionId(""); setSelectedQuestionIdIndex(null) }}
                        setShowMessage={setShowMessage}
                        selectedGrade={selectedGrade}
                        selectedSubject={selectedSubject}
                        selectedYear={selectedYear}
                        selectedMonth={selectedmonth}
                        selectedQuestionData={selectedQuestionData}
                        selectedQuestionOptionData={selectedQuestionOptionData}
                        setnewQuestion={setnewQuestion}
                        getResponse={getResponse}
                        qstnpaperData={qstnpaperData}
                        QstnPaperFormData={QstnPaperFormData}
                      />
                    )}

                    {
                      isRejectScreenOpen &&
                      <QuestionPaperRejectModal
                        handleCancel={() => {
                          //console.log("cancel called")
                          setrejectReason("");
                          setIsRejectScreenOpen(false);
                        }}
                        handleConfirm={() => rejectQstnPaper()}
                        open={true}
                        setrejectReason={setrejectReason}
                        rejectReason={rejectReason}
                      />
                    }
                  </Box>
                </div>
              ) : (
                <div
                  style={{
                    // marginTop: "55px",
                    // paddingRight: "33px",
                    // position: "absolute",
                    padding: "25px",
                    right: "15px",
                    left: "15px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                  }}
                >
                  <Paper sx={{ borderRadius: "7px", height: "100%" }} id="HOP_paper">
                    <div
                      style={{
                        background: "#545282",
                        padding: "10px",
                        color: "white",
                        borderTopLeftRadius: "7px",
                        borderTopRightRadius: "7px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      id="hop-paper1"
                    >
                      <div style={{ fontSize: "13px", marginLeft: "15px" }}>History Of Papers </div>
                      <div
                        id="back-btn"
                        style={{
                          padding: "5px 23px",
                          fontSize: "13px",
                          background: "#fd8c00",
                          borderRadius: "4px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setShowHistoryOfPapers(false);
                          setShowEditBtn(false);
                          setShowPrint(false);
                          setSelectedIds([]);
                          setSelectedGrade("");
                          setSelectedLevel("");
                          setSelectedsubjectName("");
                          setSelectedSubject("");
                          setSelectedMonth("");
                          setSelectedPaperName("")
                          setTitle("");
                          setShowPrint(false);
                          setChapterList([]);
                          setselectedPaperOfHOP(false);
                          setIsPendingClicked(false);
                          if (tabsValue == "1" || tabsValue == 1) {
                            SetshowTermQstnPaperNotEnabled(true);
                          } else if (tabsValue == "2" || tabsValue == 2) {
                            SetshowPeriodicQstnPaperNotEnabled(true);
                          } else {
                            SetshowPacticeQstnPaperNotEnabled(true);
                          }
                        }}
                      >
                        Back
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          flexDirection: "row",
                          width: '40%'
                        }}
                      >
                        {/* <FormControl
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontSize: "14px !important",
                              opacity: 0.9,
                              lineHeight: 0.8,
                              color: "#fd8c00"
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "#FFFFFF",
                              borderRadius: "50px",
                              width: "150px",
                              height: "30px",
                            },
                          }}
                        >
                          <InputLabel id="demo-simple-select-label"
                          >
                            Select grade
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            MenuProps={MenuProps}
                            input={<OutlinedInput label="Tag" />}
                            size={"small"}
                            IconComponent={(props) => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                            )}
                            style={{ color: selectedHistoryGrade ? "orange" : "black" }}
                            value={selectedHistoryGrade}
                            onChange={(e) => { functionToSetHistoryGrade(e.target.value) }}
                          >
                            {gradeList?.map((item) => {
                              return (
                                <MenuItem
                                  value={item.id}
                                  key={item.id}
                                  className={dashboardContentStyles.menu_item}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: 'orange',
                                      color: "#FFFFFF"
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: '#fcedc2',
                                      color: "#000000",
                                    },
                                    "&:not(.Mui-selected):hover": {
                                      backgroundColor: '#fcedc2',
                                      color: '#000000',
                                    },
                                  }}
                                >
                                  <ListItemText primary={item.discription} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl> */}

                        <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              fontSize: '13px',
                              color: '#e7ab3c',
                              top: '-10px',
                              '&.MuiInputLabel-shrink': {
                                top: '0',
                              },
                              '&.Mui-focused': {
                                color: '#e7ab3c'
                              }
                            }}
                            className='dropDownLabel' id="demo-simple-select-label">
                            Grade
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            input={<OutlinedInput label='Grade' />}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '150px',
                                  marginTop: '2px',
                                },
                              },
                              MenuListProps: {
                                sx: {
                                  padding: '0px'
                                },
                              },
                            }}
                            IconComponent={props => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                            )}
                            sx={{
                              width: '150px',
                              height: '30px',
                              padding: '10px',
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                              }
                            }}
                            className='dropdownSelect'
                            value={selectedHistoryGrade}
                            onChange={(e) => { functionToSetHistoryGrade(e.target.value) }}
                          >
                            {gradeList?.map((item) => {
                              return (
                                <MenuItem
                                  value={item.id}
                                  key={item.id}
                                  className={dashboardContentStyles.menu_item}
                                  sx={{
                                    fontSize: '10px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                      backgroundColor: '#f4d788',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#e7ab3c',
                                      '&:hover': {
                                        backgroundColor: '#f4d788',
                                      },
                                    },
                                  }}
                                >
                                  <ListItemText primary={item.discription} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        {/* <FormControl
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontSize: "14px !important",
                              opacity: 0.9,
                              lineHeight: 0.8,
                              color: "#fd8c00"
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "#FFFFFF",
                              borderRadius: "50px",
                              width: "170px",
                              height: "30px",
                            },
                          }}
                        >

                          <InputLabel id="demo-simple-select-label"
                          >
                            Select Subject
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            MenuProps={MenuProps}
                            input={<OutlinedInput label="Tag" />}
                            size={"small"}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: 'orange',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#fcedc2',
                                color: "#000000",
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                            style={{ color: selectedHistorySubject ? "orange" : "black" }}
                            IconComponent={(props) => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                            )}
                            value={selectedHistorySubject}
                            onChange={(e) => { functionToSetHistorySubject(e.target.value) }}
                          >
                            {subjectListHOP?.map((item) => {
                              return (
                                <MenuItem
                                  value={item.id}
                                  key={item.id}
                                  className={dashboardContentStyles.menu_item}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: 'orange',
                                      color: "#FFFFFF"
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: '#fcedc2',
                                      color: "#000000",
                                    },
                                    "&:not(.Mui-selected):hover": {
                                      backgroundColor: '#fcedc2',
                                      color: '#000000',
                                    },
                                  }}
                                >
                                  <ListItemText primary={item.subject} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl> */}

                        <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              fontSize: '13px',
                              color: '#e7ab3c',
                              top: '-10px',
                              '&.MuiInputLabel-shrink': {
                                top: '0',
                              },
                              '&.Mui-focused': {
                                color: '#e7ab3c'
                              }
                            }}
                            className='dropDownLabel' id="demo-simple-select-label"
                          >
                            Subject
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            input={<OutlinedInput label='Subject' />}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '150px',
                                  marginTop: '2px',
                                },
                              },
                              MenuListProps: {
                                sx: {
                                  padding: '0px'
                                },
                              },
                            }}
                            IconComponent={(props) => (
                              <ExpandMoreIcon
                                {...props}
                                sx={{ fontSize: "1.5vw" }}
                              />
                            )}
                            sx={{
                              width: '150px',
                              height: '30px',
                              padding: '10px',
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                              }
                            }}
                            className='dropdownSelect'
                            value={selectedHistorySubject}
                            onChange={(e) => { functionToSetHistorySubject(e.target.value) }}
                          >
                            {subjectListHOP?.map((item) => {
                              return (
                                <MenuItem
                                  value={item.id}
                                  key={item.id}
                                  className={dashboardContentStyles.menu_item}
                                  sx={{
                                    fontSize: '10px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                      backgroundColor: '#f4d788',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#e7ab3c',
                                      '&:hover': {
                                        backgroundColor: '#f4d788',
                                      },
                                    },
                                  }}
                                >
                                  <ListItemText primary={item.subject} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>


                        {showSuccessQstnMessageEnable && (
                          <Alert
                            action={
                              <IconButton
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setshowSuccessQstnMessageEnable(false);
                                }}
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            }
                            icon={false}
                            sx={{
                              color: "green",
                              fontWeight: 500,
                              fontSize: "0.93vw",
                              // background: "#bddabca1",
                              background: "rgba(200,255,200,0.63)",
                            }}
                          >
                            {/* Successfully Saved the Question Paper */}
                            {showSuccessQstnMessage}
                          </Alert>
                        )}

                      </div>

                      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            columnGap: "10px",
                            // height: "100%",
                            overflow: "auto",
                            // scrollbarWidth: "thin",
                            maxHeight: "425px",
                          }}
                        >
                          <div id="left_history_papers"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "1rem",
                              padding: "15px",
                              overflow: "auto",
                              height: "100%",
                              scrollbarWidth: "thin",
                              width: "100%"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: "10px",
                                paddingBottom: "15px"
                              }}
                            // key={item.id}
                            >
                              <div style={{ fontSize: "18px" }}>Finalized </div>
                              <Paper>
                                <TableContainer
                                // sx={{ maxHeight: 300, overflow: "auto" }}

                                >
                                  <Table
                                    // sx={{ minWidth: 300 }}
                                    stickyHeader={true}
                                    aria-label="customized table"

                                  >
                                    <TableHead >
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            background: "#d5d5d5",
                                            textAlign: 'left',
                                          }}
                                        >
                                          Question Paper
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            background: "#d5d5d5",
                                          }}
                                        >
                                          Paper Type
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            background: "#d5d5d5",
                                          }}
                                        >
                                          Level
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            background: "#d5d5d5",
                                          }}
                                        >
                                          Created By
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            background: "#d5d5d5",
                                          }}
                                        >
                                          Finalized By
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            background: "#d5d5d5",
                                          }}
                                        >
                                          Action
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody >
                                      {approvedPapers.map((paper) => (
                                        <TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th": {
                                              border: 0,
                                            },
                                          }}
                                          hover={false}
                                          style={{
                                            background: "#ffffff",
                                          }}
                                          key={paper?.id}
                                        >
                                          {paper.questionPaperName && (
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              component="th"
                                              scope="row"
                                            >
                                              {paper?.questionPaperName}
                                            </TableCell>
                                          )}
                                          {/* {paper.paperType && ( */}
                                          <TableCell
                                            className={
                                              dashboardContentStyles.table_cell
                                            }
                                            align="left"
                                          >
                                            {paper?.paperType || ""}
                                          </TableCell>
                                          {/* )} */}

                                          <TableCell
                                            className={
                                              dashboardContentStyles.table_cell
                                            }
                                            align="left"
                                          >
                                            {paper?.levelName || ""}
                                          </TableCell>
                                          {paper.academicYear && (
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                            >
                                              {paper?.createdBy || paper?.lastModifiedBy}
                                            </TableCell>
                                          )}
                                          {/* {paper.month && ( */}
                                          <TableCell
                                            className={
                                              dashboardContentStyles.table_cell
                                            }
                                            align="left"
                                          >
                                            {paper?.approvedBy || ""}
                                          </TableCell>
                                          {/* )} */}
                                          <TableCell
                                            className={
                                              dashboardContentStyles.table_cell
                                            }
                                            align="left"
                                            sx={{ textAlign: "center" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "left",
                                              }}
                                            >
                                              <DarkGreenButton onClick={() => {
                                                setQstnPaperData([]);
                                                setQstnPaperFormData({});
                                                PreviewQstnPaper(paper);
                                                // getDownloadURL(paper);
                                                setshowDownloadPDF(true);
                                              }}
                                                style={{ borderRadius: 150 }}
                                              >
                                                View
                                              </DarkGreenButton>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>

                                </TableContainer>
                              </Paper>
                            </div>

                            {pendingPapers?.length > 0 ?
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "10px",
                                }}
                              // key={item.id}
                              >
                                <div style={{ fontSize: "18px" }}>Uploaded Term Papers </div>
                                <Paper>
                                  <TableContainer
                                  // sx={{ maxHeight: 300, overflow: "auto" }}
                                  >
                                    <Table
                                      // sx={{ minWidth: 300 }}
                                      stickyHeader={true}
                                      aria-label="customized table"

                                    >
                                      <TableHead >
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Question Paper
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Paper Type
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Level
                                          </TableCell>

                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody >
                                        {/*  */}
                                        {pendingPapers?.map((paper) => (//uploadedPapers
                                          <TableRow
                                            sx={{
                                              "&:last-child td, &:last-child th": {
                                                border: 0,
                                              },
                                            }}
                                            hover={false}
                                            style={{
                                              background: "#ffffff",
                                            }}
                                            key={paper?.id}
                                          >

                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              component="th"
                                              scope="row"
                                            >
                                              {paper?.paperTitle}
                                            </TableCell>

                                            {/* {paper.paperType && ( */}
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                            >
                                              {"Term"}
                                            </TableCell>
                                            {/* )} */}
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                            >
                                              {paper.levelName}
                                            </TableCell>

                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                              sx={{ textAlign: "left" }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "left",
                                                  alignItems: "left",
                                                }}
                                              >
                                                <DarkGreenButton onClick={() => {
                                                  ; setQstnPaperData([]);
                                                  setQstnPaperFormData({});
                                                  // PreviewQstnPaper(paper);
                                                  ViewUploadedTermPapers(paper);
                                                  // getDownloadURL(paper);
                                                  setshowDownloadPDF(true);
                                                }}
                                                  style={{ borderRadius: 150 }}
                                                >
                                                  View
                                                </DarkGreenButton>
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>

                                  </TableContainer>
                                </Paper>



                              </div>
                              : <>No Uploaded Term papers</>
                            }
                            {draftedPapers?.length > 0 &&
                              // {rejectedPapers.length > 0 &&
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "10px",
                                }}
                              // key={item.id}
                              >
                                <div style={{ fontSize: "18px" }}>Drafts </div>
                                <Paper>
                                  <TableContainer
                                  // sx={{ maxHeight: 300, overflow: "auto" }}
                                  >
                                    <Table
                                      // sx={{ minWidth: 300 }}
                                      stickyHeader={true}
                                      aria-label="customized table"
                                    >
                                      <TableHead >
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Question Paper
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Paper Type
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Level
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Created By
                                          </TableCell>
                                          {/* <TableCell
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: 600,
                                        background: "#d5d5d5",
                                      }}
                                    >
                                      Finalized By
                                    </TableCell> */}
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Rejected reason
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                              background: "#d5d5d5",
                                            }}
                                          >
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody >
                                        {draftedPapers?.map((paper) => (

                                          // {rejectedPapers.map((paper) => (
                                          <TableRow
                                            sx={{
                                              "&:last-child td, &:last-child th": {
                                                border: 0,
                                              },
                                            }}
                                            hover={false}
                                            style={{
                                              background: "#ffffff",
                                            }}
                                            key={paper?.id}
                                          >
                                            {paper?.questionPaperName && (
                                              <TableCell
                                                className={
                                                  dashboardContentStyles.table_cell
                                                }
                                                component="th"
                                                scope="row"
                                                align="left"
                                              >
                                                {paper?.questionPaperName}
                                              </TableCell>
                                            )}
                                            {/* {paper.paperType && ( */}
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                            >
                                              {paper?.paperType || ""}
                                            </TableCell>
                                            {/* )} */}

                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                            >
                                              {paper?.levelName || ""}
                                            </TableCell>

                                            {/* {paper.academicYear && ( */}
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                            >
                                              {paper?.createdBy || paper?.lastModifiedBy}
                                            </TableCell>
                                            {/* )} */}
                                            {/* {paper.month && ( */}
                                            {/* <TableCell
                                        className={
                                          dashboardContentStyles.table_cell
                                        }
                                        align="left"
                                      >
                                        {paper?.lastModifiedBy || ""}
                                      </TableCell> */}
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                            >
                                              {paper?.rejectComments?.[0]?.comment || "-"}
                                            </TableCell>
                                            {/* )} */}
                                            <TableCell
                                              className={
                                                dashboardContentStyles.table_cell
                                              }
                                              align="left"
                                              sx={{ textAlign: "left" }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "left",
                                                  alignItems: "left",
                                                }}
                                              >
                                                {/* <DarkGreenButton

                                            style={{ borderRadius: 150 }}
                                            onClick={() => { PreviewQstnPaper(paper); setshowDownloadPDF(true)}}
                                          >
                                            View
                                          </DarkGreenButton> */}
                                                {
                                                  paper?.rejectedBy ?
                                                    <div
                                                      style={{
                                                        padding: "5px 23px",
                                                        fontSize: "13px",
                                                        background: "#fd8c00",
                                                        borderRadius: 150,
                                                        cursor: "pointer"
                                                      }}
                                                      onClick={() => reopenQstnPaperGeneration(paper, tabsValue)}
                                                    >
                                                      Rejected
                                                    </div> : <div
                                                      style={{
                                                        padding: "5px 23px",
                                                        fontSize: "13px",
                                                        background: "#fd8c00",
                                                        borderRadius: 150,
                                                        cursor: "pointer"
                                                      }}
                                                      onClick={() => reopenQstnPaperGeneration(paper, tabsValue)}
                                                    >
                                                      Pending
                                                    </div>

                                                }

                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                              </div>
                            }
                          </div>

                          {showPopup && (
                            <Modal id="popup-qstn"
                              open={showPopup}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              BackdropComponent={Backdrop} // Use Backdrop component for closing modal
                              BackdropProps={{ onClick: handleCloseQstnpaper }}
                            >
                              <Box sx={style1}>
                                <div id="right_history_papers"
                                  className="popup-content"
                                  style={{ paddingTop: "20%", }}

                                >
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div style={{ alignSelf: "flex-start", padding: "2%" }}>
                                      Question Paper
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center", // Align items in the center vertically
                                      }}
                                    >
                                      {/* Download PDF Button */}
                                      {showDownloadPDF && (
                                        <>
                                          <DarkGreenButton
                                            id="download-btn"
                                            onClick={DownloadPDF}
                                            style={{ marginRight: "10px" }} // Add some margin between buttons
                                          >
                                            Download PDF <i className="fa fa-download" aria-hidden="true"></i>
                                          </DarkGreenButton>
                                          <Button
                                            id="close-close-btn"
                                            onClick={handleCloseQstnpaper}
                                            sx={{
                                              position: 'absolute',
                                              right: 8,
                                              bottom: 8,
                                            }}
                                          >
                                            Close
                                          </Button></>

                                      )}
                                      {/* Close Button */}

                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      background: "#ebebeb",
                                      borderRadius: "8px",
                                      // width: "210mm", // A4 width
                                      height: "250mm", // A4 height
                                      margin: "0 auto", // Center the content horizontally
                                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: Add shadow
                                      overflowY: "scroll", // Always show scrollbar

                                    }}
                                  >

                                    <div style={{ height: "100%" }}>
                                      <style>
                                        {`
                        .html-viewer::-webkit-scrollbar {
                          width: 10px;
                          height: 8px;
                        }
                        .html-viewer::-webkit-scrollbar-track {
                          background: #F1F1F1;
                          box-shadow: inset 0 0 5px grey;
                        }
                        .html-viewer::-webkit-scrollbar-thumb {
                          background-color: darkgrey;
                          border-radius: 10px;
                        }
                        .html-viewer::-webkit-scrollbar-thumb:hover {
                          background: #555;
                        }
                      `}
                                      </style>
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "70%", // Ensure the inner div fills the height of the outer div
                                          overflowWrap: "break-word", // Allow long words to break
                                          overflowY: "auto", // Enable vertical scrolling if necessary
                                          overflowX: "auto"
                                        }}
                                        className="html-viewer"
                                        dangerouslySetInnerHTML={{
                                          __html: selectedUrl,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>

                              </Box>
                            </Modal>

                          )}
                        </div>
                      </div>
                    </div>
                  </Paper>
                </div>
              )
              }
            </>
            :
            <>



              {
                selectedUrl ?
                  <>
                    <Modal
                      open={OpenPreviewComponent}
                      onClose={handleClose1}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          // flexDirection: "column",
                        }}
                      >
                        <div style={{ width: '90%', background: 'white' }}>
                          <div style={{ padding: '20px', display: 'flex', flexDirection: 'row-reverse' }}>

                            <DarkOrangeButton id="cancel-btn-preview" onClick={() => {
                              SetOpenPreviewComponent(false);
                              setHidetheSuccessMessage(true);
                            }}>Cancel</DarkOrangeButton>
                          </div>
                          <DocumentViewer selectedUrl={selectedUrl} />
                        </div>

                      </div>
                    </Modal>
                  </> :
                  <Modal
                    open={OpenPreviewComponent}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >

                      <div style={{ width: "100%" }}>
                        <>
                          <div

                            style={{
                              marginTop: "10px",
                              paddingRight: "33px",
                              // paddingLeft: role === "COORDINATOR" ? "300px" : "0px",
                              position: "absolute",
                              right: "15px",
                              left: "15px",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "1rem",
                              height: "100%",

                            }}
                          >

                            <Box className="viewScroll" sx={{ width: "100%", typography: "body1", height: "100%", background: "white", paddingTop: "10px" }}>
                              <TabContext value={tabsValue}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginRight: "10px" }}>
                                  {
                                    showDownloadPDF &&
                                    <DarkGreenButton
                                      id="download-btn -preview"
                                      onClick={DownloadPDF}
                                      style={{ marginRight: "10px" }} // Add some margin between buttons
                                    >
                                      Download PDF <i className="fa fa-download" aria-hidden="true"></i>
                                    </DarkGreenButton>
                                  }
                                  <DarkOrangeButton id="cancel-btn-preview" onClick={() => {
                                    SetOpenPreviewComponent(false);
                                    setHidetheSuccessMessage(true);
                                  }}>Cancel</DarkOrangeButton>

                                </div>
                                <TabPanel value={tabsValue} className="pdf_preview_term" ref={pageDiv}>
                                  <Paper sx={{
                                    width: '215mm',
                                    // height: '297mm',
                                    padding: '10mm',
                                    margin: '0 auto',
                                    boxShadow: '0 0 60px rgba(0, 0, 0, 1.1)',
                                    backgroundColor: 'white',
                                    boxSizing: 'border-box',
                                  }}>

                                    <div style={{ border: "3px solid black" }} className="kidn">
                                      <div id="pdf_preview_term">
                                        <div id="pdf_preview_term_inner_div" className="question_container">
                                          {
                                            QstnPaperFormData &&
                                            <div
                                              style={{
                                                // right: "20px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                overflow: "hidden",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row-reverse",
                                                }}>

                                                <img crossOrigin="anonymous" id="img82" src={QstnPaperFormData?.data?.branchLogo || QstnPaperFormData?.branchLogo} alt={""} height={50} />
                                              </div>
                                              <div
                                                style={{
                                                  // overflow: "scroll",
                                                  width: "100%",
                                                  height: "100%",
                                                  padding: "20px",
                                                  // display: "flex",
                                                  // flexDirection: "column",
                                                  // rowGap: "1rem",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    rowGap: "10px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent: "space-between",
                                                    }}
                                                  >
                                                    <div style={{ width: "164px" }} />
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                        rowGap: "5px",
                                                        fontSize: "0.93vw",
                                                      }}
                                                    >
                                                      <div className="question_paper_font_new">
                                                        {String(QstnPaperFormData?.data?.school || QstnPaperFormData?.school || "").toUpperCase()}
                                                      </div>
                                                      <div className="question_paper_font_new">
                                                        {QstnPaperFormData?.data?.branch || QstnPaperFormData?.branch}
                                                      </div>
                                                      <div
                                                        className="question_paper_font_new"
                                                      >
                                                        {QstnPaperFormData?.data?.schoolLocality || QstnPaperFormData?.schoolLocality}
                                                      </div>
                                                      <div className="question_paper_font_new" id="paperName">

                                                        {
                                                          title || QstnPaperFormData?.questionPaperName
                                                        }
                                                      </div>
                                                      <div
                                                        className="question_paper_font_new"
                                                      >
                                                        {QstnPaperFormData?.data?.subject || "Subject : " + QstnPaperFormData?.subject}
                                                      </div>
                                                    </div>
                                                    <div style={{ width: "164px" }}></div>
                                                    {/* <img crossOrigin="anonymous" id="img8" src={logo} alt={""} height={60} /> */}
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent: "space-between",
                                                      fontSize: "0.93vw",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "start",
                                                        rowGap: "5px",
                                                      }}
                                                    >

                                                      <div className="question_paper_font">{QstnPaperFormData?.data?.grade || QstnPaperFormData?.grade}</div>
                                                      <div className="question_paper_font">Date : </div>
                                                    </div>


                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "end",
                                                        rowGap: "5px",
                                                        fontSize: "0.93vw",
                                                      }}
                                                    >
                                                      {/* <div style={{ fontWeight: 600 }}>
                                                          Subject :{QstnPaperFormData?.data?.subject || QstnPaperFormData?.subject}
                                                        </div> */}
                                                      <div className="question_paper_font">Maximum Marks : {QstnPaperFormData?.data?.totalMark || QstnPaperFormData?.totalMark}</div>
                                                      <div className="question_paper_font">Duration : {QstnPaperFormData?.data?.examDurationInMin || QstnPaperFormData?.examDurationInMin} Minutes</div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="instructions_container">
                                                  <div className="question_paper_instructions">General Instructions</div>
                                                  <div>
                                                    <ul className="unordered_list_format">
                                                      {QstnPaperFormData?.splittedHeading?.map((instruction, index) => {
                                                        return (<li key={index} className="question_paper_instructions_list">{instruction}</li>)
                                                      })}
                                                    </ul>
                                                  </div>

                                                </div>


                                                {
                                                  qstnpaperData?.map((sectionData, sectionIndex) => {
                                                    return (
                                                      <>
                                                        <div style={{
                                                          paddingTop: "10px",
                                                          paddingBottom: "10px"
                                                        }}>

                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent: "center",
                                                              alignItems: "center",
                                                              // fontWeight: 600,
                                                              // fontSize: "1.04vw",
                                                              padding: 10,
                                                            }}
                                                            className="question_paper_font section-heading"
                                                          >
                                                            {sectionData?.section}

                                                          </div>

                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent: "flex-start",
                                                              alignItems: "center",
                                                              // fontWeight: 600,
                                                              // fontSize: "20px",
                                                              paddingLeft: "30px",
                                                              paddingBottom: "20px"
                                                            }}
                                                            className="question_paper_font_text 444"
                                                          >
                                                            {sectionData?.text}

                                                          </div>

                                                          <>
                                                            {sectionData?.data?.map((data, index) => {

                                                              if (index === 0) {

                                                                const orderArray = Object.values(data?.correctResponse || {})

                                                                var markingOptions = [];
                                                                if (orderArray?.length > 0) {
                                                                  markingOptions = orderArray?.map((item) => ({
                                                                    answerKey: item?.answerKey,
                                                                    mark: item?.mark
                                                                  }))
                                                                }
                                                                const id = `SectionA${data?.id}`;
                                                                const isSelected = selectedIds.includes(id);

                                                                var options = Array.isArray(data?.options?.[0]) ? data.options[0] : Array.isArray(data?.options) ? data?.options : [data?.options] || [];
                                                                serial_number = getIncrementedSerialNumber(serial_number)


                                                                return (
                                                                  <div style={{ marginLeft: "10px", }}>
                                                                    <div
                                                                      style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                        // paddingTop: "15px",
                                                                      }}
                                                                      key={data?.id}
                                                                    >
                                                                      <div id="question"
                                                                        style={{
                                                                          flex: "95%",
                                                                          display: "flex",
                                                                          flexDirection: "row",
                                                                          alignItems: "center",
                                                                        }}
                                                                        key={data?.id}
                                                                      >

                                                                        {
                                                                          data?.question &&
                                                                          <div style={{ display: "contents" }}>
                                                                            {/* <p
                                                                              id="qstn-12"
                                                                              style={{ display: "flex", padding: "10px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "wrap",gap:'15px' }}
                                                                              className="queestion-text"
                                                                              dangerouslySetInnerHTML={{ __html: `${serial_number}. &nbsp; ${data?.question}` }}
                                                                            ></p> */}
                                                                            <div style={{
                                                                              display: "flex",
                                                                              flexDirection: "row", // Aligns the number and text at the top
                                                                              fontFamily: "Poppins",
                                                                              fontSize: "0.88vw",
                                                                              color: "#000",
                                                                              marginLeft: '10px',

                                                                              // alignItems: "center"
                                                                            }}>
                                                                              <div>
                                                                                {data?.serialNumber || serial_number}.
                                                                              </div>
                                                                              <div style={{ marginLeft: '16px' }}>
                                                                                <p
                                                                                  id="qstn-11"
                                                                                  style={{ display: "flex", flexDirection: 'column', fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "normal" }}
                                                                                  className="queestion-text"
                                                                                  dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
                                                                                ></p>
                                                                              </div>

                                                                            </div>

                                                                          </div>
                                                                        }

                                                                      </div>
                                                                      <div id="mark" style={{ flex: "5%", textAlign: "right", fontSize: "0.88vw" }}>[{data?.marks}]</div>
                                                                    </div>

                                                                    {
                                                                      data?.imageUrl &&
                                                                      <div className="avoid-page-break mainQ">
                                                                        <img className="img-break-avoid" crossOrigin="anonymous" id="img1" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.imageUrl} height="240px" />
                                                                      </div>
                                                                    }
                                                                    {
                                                                      data?.mapUrl &&
                                                                      <div className="avoid-page-break mainQ">
                                                                        <img className="img-break-avoid" crossOrigin="anonymous" id="map_img" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.mapUrl} height="240px" />
                                                                      </div>
                                                                    }
                                                                    {
                                                                      data?.subQuestions?.length > 0 && data?.subQuestions?.map((subQuestion, index) => {
                                                                        return (
                                                                          <div key={`sub-question4-${index}`}>
                                                                            <div className="sub-qstns" key={index}>
                                                                              <p
                                                                                id="qstn-44"
                                                                                style={{ display: "flex", width: "95%", paddingLeft: "10px", paddingRight: "20px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", whiteSpace: "normal" }}
                                                                                className="sub-questions"
                                                                                // dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${index + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                                dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${index + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                              ></p>

                                                                            </div>
                                                                            {
                                                                              subQuestion?.imageUrl &&
                                                                              <div className="avoid-page-break subQ">
                                                                                <img className="img-break-avoid" crossOrigin="anonymous" id="img9" style={{ paddingLeft: "50px" }} src={subQuestion?.imageUrl} height="240px" />
                                                                              </div>
                                                                            }


                                                                            {
                                                                              subQuestion &&
                                                                              (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") &&
                                                                              (
                                                                                (subQuestion?.options?.length > 1
                                                                                  ? subQuestion?.options.map((option, index) => (
                                                                                    option?.htmlTag &&
                                                                                    <div style={{ display: "block", padding: "10px" }}>
                                                                                      <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="103" id="s1">
                                                                                        <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                                      </div>
                                                                                      {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                        <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="103" id="s1">
                                                                                          {String.fromCharCode(97 + index)}. &nbsp;
                                                                                        </div>
                                                                                        <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                                      </div> */}
                                                                                    </div>
                                                                                  ))
                                                                                  : (subQuestion?.options[0]?.length > 0
                                                                                    ? subQuestion?.options[0].map((option, index) => (
                                                                                      option?.htmlTag &&
                                                                                      <div style={{ display: "block", padding: "10px" }}>
                                                                                        <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="104" id="s1">
                                                                                          <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                                        </div>
                                                                                        {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                          <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="104" id="s1">
                                                                                            {String.fromCharCode(97 + index)}. &nbsp;
                                                                                          </div>
                                                                                          <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                                        </div> */}
                                                                                      </div>
                                                                                    ))
                                                                                    : null
                                                                                  )
                                                                                )
                                                                              )
                                                                            }
                                                                            <div></div>
                                                                          </div>
                                                                        );
                                                                      })
                                                                    }

                                                                    {
                                                                      (markingOptions[0]?.mark !== null && showmarkingValue && markingOptions?.length > 0) &&
                                                                      <div className=" answerBg">
                                                                        <div className=" header answer">
                                                                          <div className="">Answer/Term</div>
                                                                          <div className="">Mark</div>
                                                                        </div>
                                                                        {
                                                                          markingOptions?.map((item, index) => {
                                                                            return (
                                                                              <div className="options " id={`dynamicContent_${index}`} key={index}>
                                                                                <div className="">{item?.answerKey}</div>
                                                                                <div className="">{item?.mark}</div>
                                                                              </div>
                                                                            )
                                                                          })
                                                                        }
                                                                      </div>
                                                                    }
                                                                    {
                                                                      //console.log("data?.questionTypeCode", data?.questionTypeCode, data?.question)
                                                                    }

                                                                    {
                                                                      (data?.questionTypeCode !== "FILL" && data?.questionTypeCode !== "MATCH") ?
                                                                        <div style={{ display: "block" }} className="question-options-main">
                                                                          {options?.length > 0 && options?.map((option, index) => (
                                                                            option?.htmlTag &&
                                                                            <div key={index} style={{ display: "inline-block", width: "100%", marginTop: "3px", marginBottom: "3px" }} className="question-options-main-inner 3333">
                                                                              <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(index + 65)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>

                                                                            </div>
                                                                          ))}
                                                                        </div> :
                                                                        data?.questionTypeCode !== "FILL" &&
                                                                        <div style={{ display: "block" }} className="question-options-main">
                                                                          {options?.legnth !== 0 && options?.map((option, index) => (
                                                                            <div key={index} style={{ display: "flex", width: "100%", paddingLeft: "10%" }} className="uniq_options">
                                                                              <div style={{ flex: "1", paddingRight: "5px" }} id="MTF2">
                                                                                <div style={{ align: "center", fontFamily: "Poppins", overflow: "auto" }}>
                                                                                  <div style={{ whiteSpace: "nowrap", display: "flex" }} className="question-options1">
                                                                                    <div style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: `${String.fromCharCode(index + 65)}.&nbsp; ${option?.htmlTagQuestion}` }}></div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <div style={{ flex: "1", paddingLeft: "5px" }} id="MTF2">
                                                                                <div style={{ fontSize: "11px", alignContent: "center", fontFamily: "Poppins", overflow: "auto" }}>
                                                                                  <div dangerouslySetInnerHTML={{ __html: option?.jumbledAnswer }}></div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          ))}
                                                                        </div>

                                                                    }
                                                                  </div>
                                                                );
                                                              }
                                                            })}
                                                          </>
                                                        </div>
                                                        <>
                                                          {

                                                            <>
                                                              {sectionData?.data?.map((data, index) => {

                                                                if (index !== 0) {
                                                                  const orderArray = Object.values(data?.correctResponse || {})

                                                                  var markingOptions = [];
                                                                  if (orderArray?.length > 0) {
                                                                    markingOptions = orderArray?.map((item) => ({
                                                                      answerKey: item?.answerKey,
                                                                      mark: item?.mark
                                                                    }))
                                                                  }
                                                                  // console.log(data, "datadatadatadatta", markingOptions);
                                                                  const id = `SectionA${data?.id}`;
                                                                  const isSelected = selectedIds.includes(id);

                                                                  var options = Array.isArray(data?.options) ? data?.options : []
                                                                  // console.log(data?.question, data?.questionTypeCode, "main question")
                                                                  serial_number = getIncrementedSerialNumber(serial_number)
                                                                  return (
                                                                    <div style={{ marginLeft: "10px", }}>
                                                                      <div
                                                                        style={{
                                                                          display: "flex",
                                                                          flexDirection: "row",
                                                                          alignItems: "center",
                                                                          paddingTop: "15px"
                                                                        }}
                                                                        key={data?.id}
                                                                      >
                                                                        <div id="question"
                                                                          style={{
                                                                            flex: "95%",
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            alignItems: "center",
                                                                          }}
                                                                          key={data?.id}
                                                                        >

                                                                          {
                                                                            data?.question &&
                                                                            <div style={{ display: "contents" }}>
                                                                              {/* <p
                                                                                id="qstn-11"
                                                                                style={{ display: "flex", gap: '15px', padding: "10px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "wrap" }}
                                                                                className="queestion-text"
                                                                                dangerouslySetInnerHTML={{ __html: `${serial_number}. &nbsp; ${data?.question}` }}
                                                                              ></p> */}

                                                                              <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row", // Aligns the number and text at the top
                                                                                fontFamily: "Poppins",
                                                                                fontSize: "0.88vw",
                                                                                color: "#000",
                                                                                marginLeft: '10px',

                                                                              }}>
                                                                                <div>
                                                                                  {data?.serialNumber || serial_number}.
                                                                                </div>
                                                                                <div style={{ marginLeft: '16px' }}>
                                                                                  <p
                                                                                    id="qstn-1"
                                                                                    style={{ display: "flex", flexDirection: 'column', fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", overflow: "auto", whiteSpace: "normal" }}
                                                                                    className="queestion-text"
                                                                                    dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
                                                                                  ></p>
                                                                                </div>

                                                                              </div>

                                                                            </div>
                                                                          }

                                                                        </div>
                                                                        <div id="mark" style={{ flex: "5%", textAlign: "right", fontSize: "0.88vw" }}>[{data?.marks}]</div>
                                                                      </div>

                                                                      {
                                                                        data?.imageUrl &&
                                                                        <div className="avoid-page-break mainQ">
                                                                          <img className="img-break-avoid" crossOrigin="anonymous" id="img1" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.imageUrl} height="240px" />
                                                                        </div>
                                                                      }
                                                                      {
                                                                        data?.mapUrl &&
                                                                        <div className="avoid-page-break mainQ">
                                                                          <img className="img-break-avoid" crossOrigin="anonymous" id="map_img" style={{ paddingLeft: "50px", width: "20%", height: "20%" }} src={data?.mapUrl} height="240px" />
                                                                        </div>
                                                                      }
                                                                      {
                                                                        data?.subQuestions?.length > 0 && data?.subQuestions?.map((subQuestion, index) => {
                                                                          return (
                                                                            <div key={`sub-question4-${index}`}>
                                                                              <div className="sub-qstns" key={index}>
                                                                                <p
                                                                                  id="qstn-45"
                                                                                  style={{ display: "flex", width: "95%", paddingLeft: "10px", paddingRight: "20px", marginTop: "10px", fontSize: "0.88vw", color: "#000", fontFamily: "Poppins", whiteSpace: "normal" }}
                                                                                  className="sub-questions"
                                                                                  // dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${index + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                                  dangerouslySetInnerHTML={{ __html: `${subQuestion?.serialNumber || `${data?.serialNumber || serial_number}.${index + 1}`}.  &nbsp; ${subQuestion?.question}` }}
                                                                                ></p>

                                                                              </div>
                                                                              {
                                                                                subQuestion?.imageUrl &&
                                                                                <div className="avoid-page-break subQ">
                                                                                  <img className="img-break-avoid" crossOrigin="anonymous" id="img9" style={{ paddingLeft: "50px" }} src={subQuestion?.imageUrl} height="240px" />
                                                                                </div>
                                                                              }

                                                                              {/* {subQuestion && (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") && subQuestion?.options?.length > 0 && subQuestion?.options?.map((option, index) => (
                                                                            <div style={{ display: "block", padding: "10px" }} key={index}>
                                                                              <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                <div style={{ paddingLeft: "50px", display: "inline-block" }} id="s1">
                                                                                  {String.fromCharCode(97 + index)}. &nbsp;
                                                                                </div>
                                                                                <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                              </div>
                                                                            </div>
                                                                          ))} */}
                                                                              {
                                                                                subQuestion &&
                                                                                (subQuestion?.questionTypeCode !== "FILL" && subQuestion?.questionTypeCode !== "TEXT") &&
                                                                                (
                                                                                  (subQuestion?.options?.length > 1
                                                                                    ? subQuestion?.options.map((option, index) => (
                                                                                      option?.htmlTag &&
                                                                                      <div style={{ display: "block", padding: "10px" }}>
                                                                                        <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="105" id="s1">
                                                                                          <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                                        </div>
                                                                                        {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                          <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="105" id="s1">
                                                                                            {String.fromCharCode(97 + index)}. &nbsp;
                                                                                          </div>
                                                                                          <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                                        </div> */}
                                                                                      </div>
                                                                                    ))
                                                                                    : (subQuestion?.options[0]?.length > 0
                                                                                      ? subQuestion?.options[0].map((option, index) => (
                                                                                        option?.htmlTag &&
                                                                                        <div style={{ display: "block", padding: "10px" }}>
                                                                                          <div key={index} style={{ display: "inline-block", width: "100%", marginLeft: "50px" }} className="106" id="s1">
                                                                                            <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(97 + index)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                                          </div>
                                                                                          {/* <div key={index} style={{ display: "inline-block", width: "100%" }}>
                                                                                            <div style={{ paddingLeft: "50px", display: "inline-block", fontSize: "0.88vw !important" }} className="106" id="s1">
                                                                                              {String.fromCharCode(97 + index)}. &nbsp;
                                                                                            </div>
                                                                                            <div id="s2" style={{ display: "inline-block", whiteSpace: "normal", fontSize: "0.88vw", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: option?.htmlTag }}></div>
                                                                                          </div> */}
                                                                                        </div>
                                                                                      ))
                                                                                      : null
                                                                                    )
                                                                                  )
                                                                                )
                                                                              }
                                                                              <div></div>
                                                                            </div>
                                                                          );
                                                                        })
                                                                      }

                                                                      {
                                                                        (markingOptions[0]?.mark !== null && showmarkingValue && markingOptions?.length > 0) &&
                                                                        <div className=" answerBg">
                                                                          <div className=" header answer">
                                                                            <div className="">Answer/Term</div>
                                                                            <div className="">Mark</div>
                                                                          </div>
                                                                          {
                                                                            markingOptions?.map((item, index) => {
                                                                              return (
                                                                                <div className="options " id={`dynamicContent_${index}`} key={index}>
                                                                                  <div className="">{item?.answerKey}</div>
                                                                                  <div className="">{item?.mark}</div>
                                                                                </div>
                                                                              )
                                                                            })
                                                                          }
                                                                        </div>
                                                                      }

                                                                      {
                                                                        (data?.questionTypeCode !== "FILL" && data?.questionTypeCode !== "MATCH") ?
                                                                          <div style={{ display: "block" }} className="question-options-main">
                                                                            {options?.length > 0 && options?.map((option, index) => (
                                                                              option?.htmlTag &&
                                                                              <div key={index} style={{ display: "inline-block", width: "100%", marginTop: "3px", marginBottom: "3px" }} className="question-options-main-inner 4444">
                                                                                <div className="question-options" style={{ display: "flex", gap: "10px", whiteSpace: "normal", fontFamily: "Poppins" }} dangerouslySetInnerHTML={{ __html: `<div>${String.fromCharCode(index + 65)}.&nbsp;</div>  <div>${option?.htmlTag}</div>` }}></div>
                                                                              </div>
                                                                            ))}
                                                                          </div> :
                                                                          data?.questionTypeCode !== "FILL" &&
                                                                          <div style={{ display: "block" }} className="question-options-main">
                                                                            {options?.legnth !== 0 && options?.map((option, index) => (
                                                                              <div key={index} style={{ display: "flex", width: "100%", paddingLeft: "10%" }} className="uniq_options">
                                                                                <div style={{ flex: "1", paddingRight: "5px" }} id="MTF2">
                                                                                  <div style={{ align: "center", fontFamily: "Poppins", overflow: "auto" }}>
                                                                                    <div style={{ whiteSpace: "nowrap", display: "flex" }} className="question-options1">
                                                                                      <div style={{ display: "flex" }} dangerouslySetInnerHTML={{ __html: `${String.fromCharCode(index + 65)}.&nbsp; ${option?.htmlTagQuestion}` }}></div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                                <div style={{ flex: "1", paddingLeft: "5px" }} id="MTF2">
                                                                                  <div style={{ fontSize: "11px", alignContent: "center", fontFamily: "Poppins", overflow: "auto" }}>
                                                                                    <div dangerouslySetInnerHTML={{ __html: option?.jumbledAnswer }}></div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            ))}
                                                                          </div>

                                                                      }
                                                                    </div>
                                                                  );
                                                                }
                                                              })}
                                                            </>

                                                          }
                                                        </>
                                                      </>
                                                    )
                                                  })

                                                }
                                                {/* </div> */}
                                              </div>


                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </Paper>
                                </TabPanel>


                              </TabContext>

                            </Box>
                          </div>
                        </>
                      </div >
                    </div>
                  </Modal>
              }
            </>

        }
      </div >
    </section>


  );
};
export default SubjectivePapers;



