import React, { useState, useEffect } from "react";
import DashboardBluePrintLayer from "./DashboardContentReviewerBluePrintLayer/DashboardContentReviewerBluePrintLayer";
import BluePrintFilter from "../../../components/BluePrintCommonTable/BluePrintFilter";
import BluePrintTab from "../../../components/BluePrintCommonTable/BluePrintTab";
import BluePrintTablehead from "../BluePrint/Uploads/BluePrintTablehead";
import { BlueprintTableBody } from "../BluePrint/Uploads/BlueprintTableBody";
import { isNOTNullOrUndefined } from "../TeachValidate";
import {
  bluePrintsAllGrades,
  bluePrintsAllSubjects,
  bluePrintsBoards,
  fetchBluePrintsListForListView,
  getBluePriintSingleContent,
} from "../BluePrint/Apis/BluePrintApis";
import { tableHead, tableHeadRejected } from "./Schema/schema";
import { TablePagination } from "@mui/material";
import ContentReviewerSheetTable from "./ContentReviewerSheetTable";
import commonsort from "../../js/CommonSort"

export default function BluePrintApproval() {
  const [reviewStatus, setReviewStatus] = useState("Pending");
  const [page, setPage] = useState(0);
  const [blueprintsData, setBluePrintsData] = useState([]);

  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setpageSize] = useState(5);//should not implement
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState(0);

  const [excelData, setExcelData] = useState([]);
  const [showExcel, setShowExcel] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [boardList, setBoardList] = useState([]);

  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");

  const [selectedIdForPreview, setSelectedIdForPreview] = useState(null);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    fetchGradeList();
    fetchSubjectList();
    fetchBoardList();
  }, []);

  useEffect(() => {
    fetchBluePrints();
  }, [
    selectedBoard,
    selectedGrade,
    selectedSubject,
    search,
    reviewStatus,
    pageSize,
    page,
  ]);

  const fetchGradeList = async () => {
    const response = await bluePrintsAllGrades();
    setGradeList(response);
  };

  const fetchSubjectList = async () => {
    const response = await bluePrintsAllSubjects();
    setSubjectList(response);
  };

  const fetchBoardList = async () => {
    const response = await bluePrintsBoards();
    setBoardList(response);
  };

  const fetchBluePrints = async () => {
    let pageNumber = page;
    const response = await fetchBluePrintsListForListView(
      pageNumber,
      pageSize,
      selectedBoard,
      selectedGrade,
      selectedSubject,
      // subTopicId,
      search,
      reviewStatus
    );
    setBluePrintsData(response?.data);
    setTotalPage(response?.totalPages);
    setTotalElements(response?.totalElements);
    setCurrentPage(response?.pageNumber);
  };

  const onPreviewClick = async (id) => {
    const response = await getBluePriintSingleContent(id);
    if (isNOTNullOrUndefined(response?.blueprintTemplate)) {
    //console.log(response);
      // setIsPending(true);
      setSelectedIdForPreview(response?.id);
      setExcelData(response?.blueprintTemplate);
      setShowExcel(true);
    }
  };

  const onCancelPreview = () => {
    setShowExcel(false);
    // setIsPending(false);
    setSelectedIdForPreview(null);
    setExcelData([]);
    fetchBluePrints();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setpageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
  //console.log("sortBy", sortBy, blueprintsData, tableHeadData)
  }, [sortBy])

  const fnsetSortBy = async (data) => {
  //console.log(data)
  }

  const handleSort = async (field) => {
  //console.log("handleSort", field, blueprintsData)
    var sortby = ""
    if (field === "Paper Title") {
      sortby = "paperTitle"
    } else if (field === "Paper Type") {
      sortby = "paperType"
    } else if (field === "Grade") {
      sortby = "grade"
    } else if (field === "Subject") {
      sortby = "subject"
    } else if (field === "Total Marks") {
      sortby = "totalMarks"
    } else if (field === "Total Questions") {
      sortby = "totalNumberOfQuestions"
    } else if (field === "Time") {
      sortby = "durationInMinutes"
    } else {
      sortby = "id"
    }
  //console.log("sortbysortby", sortby, sortOrder)
    setSortOrder(!sortOrder)
    var sortedData = await commonsort(blueprintsData || [], sortby, !sortOrder);
    setBluePrintsData(sortedData)
  };
  // let tableHeadData = tableHead;
  let tableHeadData =
    reviewStatus === "Rejected" ? tableHeadRejected : tableHead;
  return (
    <DashboardBluePrintLayer>
      {!showExcel && (
        <div
          className="filter-wrapper"
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <BluePrintFilter
            selectedBoard={selectedBoard}
            setSelectedBoard={(i) => {
              setPage(0);
              setSelectedBoard(i);
            }}
            selectedGrade={selectedGrade}
            setSelectedGrade={(i) => {
              setPage(0);
              setSelectedGrade(i);
            }}
            selectedSubject={selectedSubject}
            setSelectedSubject={(i) => {
              setPage(0);
              setSelectedSubject(i);
            }}
            search={search}
            setSearch={(i) => {
              setPage(0);
              setSearch(i);
            }}
            gradeList={gradeList}
            subjectList={subjectList}
            boardList={boardList}
          />
          <BluePrintTab
            Selected={reviewStatus}
            setSelected={(i) => {
              setPage(0);
              setReviewStatus(i);
            }}
          />
          <table className="table-wrapper">
            <BluePrintTablehead
              tableHeadData={tableHeadData}
              onSort={handleSort}
            // role={"content_reviewer"}
            />
            <BlueprintTableBody
              blueprintsData={blueprintsData}
              pageSize={pageSize}
              totalElements={totalElements}
              currentPage={currentPage}
              onPreviewClick={onPreviewClick}
              role={"content_reviewer"}
            />
          </table>
          {blueprintsData?.length > 0 && (
            <div style={{ marginTop: "5px" }}>
              <span>
                <TablePagination
                  style={{
                    bottom: "0",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={totalElements}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </span>
            </div>
          )}
        </div>
      )}
      {showExcel && (
        <ContentReviewerSheetTable
          onCancelPreview={onCancelPreview}
          bluePrintID={selectedIdForPreview}
          isPending={reviewStatus === "Pending"}
          data={excelData}
        />
      )}
    </DashboardBluePrintLayer>
  );
}
