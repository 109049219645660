import React, { useEffect, useState } from "react";
import Student_Enrich_Quiz_Analysis_Modal from "./Student_Enrich_Quiz_Analysis_Modal";

// Images Used
import warning_icon from "../../../../src/img/Warning.png";

//Css Pages
import "../../css/Student/Student_Enrich_Quiz_Submit_Modal.css";

// Mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

function Student_Enrich_Quiz_Submit_Modal(props) {
//console.log("Student_Enrich_Quiz_Submit_Modal", props)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "445px",
    height: "296px",
    bgcolor: "#FFFFFF",
    borderRadius: "6px",
    border: "none",
    boxShadow: 24,
    p: 4,
  };
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isAllAnswered, setIsAllAnswered] = useState(false);


  const handleAnalysisModalOpen = () => setOpenModal(true);
  const handleAnalysisModalClose = () => {
    setOpenModal(false)
    props.setLoadQuizData(true);
  };

  const SubmitBtn = () => {
    setShowAnalysisModal(true);
    handleAnalysisModalOpen();
    props.Submit_Exam_Put_Api();

    // props.fetchViewQuiz();
  };

  useEffect(() => {
  //console.log(JSON.parse(sessionStorage.getItem("alltheQuizAnswer")), "alltheQuizAnswer")
    var Data = JSON.parse(sessionStorage.getItem("practiceQuizAnswer")) || [];
    var QUIZ_DATA_LENGTH = JSON.parse(sessionStorage.getItem("QUIZ_DATA")) || [];
    var QQ = QUIZ_DATA_LENGTH.filter(e => e.isAnswered == true)
  //console.log("lengthAnswered", Data, QUIZ_DATA_LENGTH)
    var lengthAnswered = Data.filter(obj => obj.answer.length > 0);
  //console.log("lengthAnswered", lengthAnswered)
    if (QUIZ_DATA_LENGTH.length == QQ.length) {
      setIsAllAnswered(true)
    } else {
      setIsAllAnswered(false)
    }
    // if (props.questions.length >= lengthAnswered.length + 1) {
    //   setIsAllAnswered(false)
    // } else {
    //   setIsAllAnswered(true)
    // }
  }, [props])
  return (
    <>
      <div>
        {
        //console.log("midnight", props.showModal, isAllAnswered)
        }
        <Modal
          // 
          open={props.showModal}
          // onClose={props.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="Warning_img">
              <img src={warning_icon} width="96px" height="96px" />
            </div>
            {
              isAllAnswered == true ?
                <p className="Warning_text">
                  Do you want to submit the quiz?
                </p> :
                <p className="Warning_text">
                  You have some questions unanswered. Do you want to submit the quiz?
                </p>
            }
            <div className="Warning_Btns">
              <button className="Btn1" onClick={() => { props.closeModal(); props.setShowModal(false); }} style={{ cursor: "pointer" }}>
                Go To Quiz
              </button>  
              <button className="Btn2" onClick={SubmitBtn} style={{ cursor: "pointer" }}>
                Submit
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      {showAnalysisModal && (
        <Student_Enrich_Quiz_Analysis_Modal
          showAnalysisModal={openModal}
          closeModal={handleAnalysisModalClose}
          closeModall={props.closeModall}
          callbackforafterquizexit={props.callbackforafterquizexit}
        />
      )}
    </>
  );
}

export default Student_Enrich_Quiz_Submit_Modal;
