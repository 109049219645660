import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { EducationContext } from "../../../../context/quiz/EducationContext";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import icon from "../../../../img/Reports.svg";
import _ from "lodash";
import axios from "axios";
import { Checkbox, FormControlLabel } from "@mui/material";

// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function AnnualSelectSection({
  title,
  selectedSubject,
  setSelectedSubject,
  selectedBoard,
  setSelectedBoard,
  selectedGrade,
  setSelectedGrade,
  selectedYear,
  setSelectedYear,
  selectedSubTopic,
  setSelectedSubTopic,
  onCreateClick,
  removeSubList,
  btnDisabled,
  selectedFromGrade,
  setSelectedFromGrade,
  quiz,
  setBoyChecked,
  boyChecked,
}) {
  const local_data = JSON.parse(localStorage.getItem("quiz_data"));

  const educationData = useContext(EducationContext);
  // const boards = educationData.boards.data;
  // const grades = educationData.grades.data;
  // const subjects = educationData.subjects.data;
  // const academicYears = educationData.academicYears.data;
  const [subTopics, setSubTopic] = useState(null);
  const [list, setLists] = useState([]);
  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [academicYears, setAcademicYears] = useState([]);

  // Api calls <================================================

  const TOKEN = sessionStorage.getItem("token");
  const BASE_URL = process.env.REACT_APP_SWAGGER_URL;
  const fetchBoards = () => {
    axios
      .get(`${BASE_URL}v1/api/master/boards/all`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => setBoards(res.data.data));
  };
  const fetchGrades = () => {
    axios
      .get(`${BASE_URL}v1/api/master/grades/all`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => setGrades(res.data.data));
  };
  const fetchSubjects = () => {
    axios
      .get(`${BASE_URL}v1/api/master/subjects/all`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => setSubjects(res.data.data));
  };
  const fetchAcademicYears = () => {
    axios
      .get(`${BASE_URL}v1/api/master/academic-years/all`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => setAcademicYears(res.data.data));
  };
  useEffect(() => {
    fetchBoards();
    fetchGrades();
    fetchSubjects();
    fetchAcademicYears();
  }, []);

  useEffect(() => {
    setSelectedSubTopic(_.uniqWith(list, _.isEqual));
  }, [list]);

  useEffect(() => {
    localStorage.setItem("quiz_data", JSON.stringify({ ...local_data }));
    setSelectedSubTopic(null);
  }, [subTopics]);

  useEffect(() => {
    if (selectedSubject) {
      setTimeout(() => {
        if (selectedSubject.subTopics === null) {
          setSubTopic(null);
        } else {
          setSubTopic(selectedSubject.subTopics);
        }
      }, 1000);
    }
  }, [selectedSubject]);

  const handleSelect = (e) => {
    //console.log(e.target.id);
    //console.log(e.target.value);
    if (e.target.id === "subjects") {
      setSelectedSubject(
        () =>
          subjects.filter(
            (item) => item.subject === e.target.value
          )[0]
      );
      var selectedSub = subjects.filter((item) => item.subject === e.target.value)[0];
      setShowSubTopic(selectedSub.hideSubtopics == true ? false : true);
    } else if (e.target.id === "board") {
      //console.log("board");
      setSelectedBoard(
        () => boards.filter((item) => item.board === e.target.value)[0]
      );
    } else if (e.target.id === "fromGradeId") {
      setSelectedFromGrade(
        () => grades.filter((item) => item.grade === e.target.value)[0]
      );
    } else if (e.target.id === "grade") {
      setSelectedGrade(
        () => grades.filter((item) => item.grade === e.target.value)[0]
      );
    }
    else if (e.target.id === "year") {
      setSelectedYear(
        () =>
          academicYears.filter(
            (item) => item.academicYear === e.target.value
          )[0]
      );
    } else if (e.target.id === "sub_topic") {
      let data = subTopics.filter(
        (item) => item.subTopic === e.target.value
      )[0];
      // setLists(data);
      setSelectedSubTopic(data);
    } else {
    }
  };

  useEffect(() => {
    localStorage.setItem(
      "quiz_data",
      JSON.stringify({
        ...local_data,
        selected_subject: selectedSubject,
        selected_grade: selectedGrade,
        selected_year: selectedYear,
        selected_sub_topic: selectedSubTopic,
        selected_board: selectedBoard,
        selected_fromGrade: selectedFromGrade,
        boy: boyChecked,
      })
    );
  }, [
    selectedSubject,
    selectedGrade,
    selectedYear,
    selectedSubTopic,
    selectedBoard,
    selectedFromGrade,
    boyChecked,
  ]);

  const onSubmit = (data) => {
    //console.log(data);
  };
  const handleChange = () => { };

  const handleChecked = (e) => {
    setBoyChecked(e.target.checked);
  };

  return (
    <Container>
      <LeftSection>
        <Icon>
          <img src={icon} alt="icon" />
        </Icon>
        <Title>Create&nbsp;Quiz</Title>
        <SelectorContainer>
          {quiz ? (
            <FormControlLabel
              control={
                <Checkbox onChange={handleChecked} checked={boyChecked} />
              }
              label="BOY"
            />
          ) : null}
          <TopCover>
            <Cover>
              <Select
                name="board"
                id="board"
                onChange={handleSelect}
              >
                <option value={""}>Board</option>{" "}
                {boards ? (
                  boards?.map((data) => (
                    <option
                      value={data.board}
                      key={data.board}
                    >
                      {data.board}
                    </option>
                  ))
                ) : (
                  <option value="">none</option>
                )}
              </Select>
            </Cover>
            <Arrow>
              <KeyboardArrowDownOutlinedIcon />
            </Arrow>
          </TopCover>
          {boyChecked ? (
            <TopCover>
              <Cover>
                <Select
                  name="fromGradeId"
                  id="fromGradeId"
                  onChange={handleSelect}
                >
                  <option value={""}>Previous grade</option>{" "}
                  {grades ? (
                    grades?.map((data) => (
                      <option value={data.grade} key={data.grade}>
                        {data.grade}
                      </option>
                    ))
                  ) : (
                    <option value="">none</option>
                  )}
                </Select>
              </Cover>
              <Arrow>
                <KeyboardArrowDownOutlinedIcon />
              </Arrow>
            </TopCover>
          ) : null}
          <TopCover>
            <Cover>
              <Select
                name="grage"
                id="grade"
                onChange={handleSelect}
              >
                <option value={""}>Grade</option>{" "}
                {grades ? (
                  grades?.map((data) => (
                    <option
                      value={data.grade}
                      key={data.grade}
                    >
                      {data.grade}
                    </option>
                  ))
                ) : (
                  <option value="">none</option>
                )}
              </Select>
            </Cover>
            <Arrow>
              <KeyboardArrowDownOutlinedIcon />
            </Arrow>
          </TopCover>
          <TopCover>
            <Cover>
              <Select
                name="subjects"
                id="subjects"
                onChange={handleSelect}
              >
                <option value={""}>Subject</option>{" "}
                {subjects ? (
                  subjects?.map((data) => (
                    <option
                      value={data.subject}
                      key={data.subject}
                    >
                      {data.subject}
                    </option>
                  ))
                ) : (
                  <option value="">none</option>
                )}
              </Select>
            </Cover>
            <Arrow>
              <KeyboardArrowDownOutlinedIcon />
            </Arrow>
          </TopCover>
          {
            showSubTopic &&
            <TopCover>
              <Cover>
                <Select
                  name="sub_topic"
                  id="sub_topic"
                  onChange={handleSelect}
                  size="1"
                >
                  <option value={""}>SubTopic</option>{" "}
                  {subTopics
                    ? subTopics?.map((data) => (
                      <option
                        value={data.subTopic}
                        key={data.subTopic}
                      >
                        {data.subTopic}
                      </option>
                    ))
                    : null}
                </Select>
              </Cover>
              <Arrow>
                <KeyboardArrowDownOutlinedIcon />
              </Arrow>
            </TopCover>
          }
          <TopCover>
            <Cover>
              <Select
                name="year"
                id="year"
                onChange={handleSelect}
              >
                <option value={""}>Year</option>{" "}
                {academicYears ? (
                  academicYears?.map((data) => (
                    <option
                      value={data.academicYear}
                      key={data.acedemicYear}
                    >
                      {data.academicYear}
                    </option>
                  ))
                ) : (
                  <option value="">none</option>
                )}
              </Select>
            </Cover>
            <Arrow>
              <KeyboardArrowDownOutlinedIcon />
            </Arrow>
          </TopCover>
        </SelectorContainer>
      </LeftSection>
      <RightSection>
        <Button
          disabled={btnDisabled}
          style={{
            backgroundColor: btnDisabled ? "#ccc" : "#403E75",
            borderColor: btnDisabled ? "#ccc" : "#403E75",
            cursor: btnDisabled ? "default" : "pointer"
          }}
          onClick={() => onCreateClick()}
        >
          Create
        </Button>
      </RightSection>
    </Container>
  );
}

export default AnnualSelectSection;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`;
const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const RightSection = styled.div`
  display: block;
`;
const Title = styled.p`
  margin-right: 20px;
  width: 120px;
`;
const SelectorContainer = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

const Cover = styled.span`
  padding: 0px 16px;
  background-color: #fff;
  width: 130px;
  border-radius: 20px;
  /* overflow: scroll; */
  display: block;
  position: relative;
`;
const Select = styled.select`
  color: #fd8c00;
  padding: 10px 0px;
  border: none;
  outline: none;
  font-size: 13px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  z-index: 50;
  background: transparent;
  :focus,
  :hover {
    border: none;
  }
`;
const Button = styled.button`
  color: #fff;
  width: 150px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #001af3;
  background-color: #001af3;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px #001af380;
  font-size: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const Arrow = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-right: 10px;
  svg {
    font-size: 22px !important;
    color: #354052 !important;
  }
`;
const TopCover = styled.div`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #9b9fa7;
`;
const Icon = styled.span`
  display: block;
  width: 25px;
  margin-right: 10px;
  img {
    display: block;
    width: 100%;
  }
`;
