import React, { useState, useEffect } from "react";
import dashHome from "../../../../../img/dashHome.svg";
import {
  FormControl,
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  fabClasses,
} from "@mui/material";
import { isNOTNullOrUndefined } from "../../../TeachValidate";
import { validationSchemaAssign } from "../Schema/schema";
import ConfigurationSuccessModal from "../../../../../components/common/ConfigurationSuccessModal";
import BluePrintErrorModal from "../../../../../components/common/BluePrintErrorModal";
import { Checkbox, ListItemText, ListItemIcon } from "@material-ui/core";
import { MenuProps } from "../Data/DataHelpers";

export default function AssignAssessmentPaper({
  boardList,
  gradeList,
  subjectList,
  levelList,
  academicYearList,
  setFormSelectedBoard,
  setFormSelectedGrade,
  setFormSelectedSubject,
  setFormSelectedLevel,
  schoolList,
  paperList,
  setShowAssignModal,
  setSelectedSchool,
  setFormSelectedAcademicYear,
  formData,
  setFormData,
  selected,
  setSelected,
}) {
//console.log("schoolList", schoolList, selected, paperList)
  let initialData = {
    boardId: "",
    gradeId: "",
    subjectId: "",
    blueprintLevelId: "",
    academicYearId: "",
    selectedSchools: [],
  };
  const [formErrors, setFormErrors] = useState({});
  const [showCancelButton, setShowCancelButton] = useState(false);

  // const [successCreateModal, setSuccessCreateModal] = useState(false);
  // const [modalMessage, setModalMessage] = useState("");

  // const [errorCreateModal, setErrorCreateModal] = useState(false);
  // const [modalMessageError, setModalMessageError] = useState("");

  // const [selected, setSelected] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (e.target.name == "boardId") {
      setFormSelectedBoard(e.target.value);
    }
    if (e.target.name == "gradeId") {
      setFormSelectedGrade(e.target.value);
    }
    if (e.target.name == "subjectId") {
      setFormSelectedSubject(e.target.value);
    }
    if (e.target.name == "blueprintLevelId") {
      setFormSelectedLevel(e.target.value);
    }
    if (e.target.name == "academicYearId") {
      setFormSelectedAcademicYear(e.target.value);
    }
  };

  const handleSubmit = async () => {
    // e.preventDefault();
  //console.log("onsubmit called")
    try {
    //console.log("try called")
      // Validate the form data using Yup
      await validationSchemaAssign.validate(formData, { abortEarly: false });
      setShowAssignModal(true);
      // onSubmit(formData);
      // Form data is valid, you can handle the submission here
      // Clear form errors
      setFormErrors({});
    } catch (error) {
      // Handle validation errors
    //console.log("error called", error)
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFormErrors(errors);
    }
  };

  const resetForm = () => {
    setFormData(initialData);
    setFormErrors({});
    setFormSelectedBoard("");
    setFormSelectedGrade("");
    setFormSelectedLevel("");
    setFormSelectedSubject("");
    setSelected([]);
    setSelectedSchool([]);
  };

  // const closeSuccessModal = () => {
  //   setSuccessCreateModal(false);
  // };
  // const closeErrorModal = () => {
  //   setErrorCreateModal(false);
  // };

  const handleCheckBoxChange = (event) => {
    const value = event.target.value;
    // if (value[value.length - 1] === "all") {
    //   setSelected(selected.length === schoolList.length ? [] : schoolList);
    //   return;
    // }
    setFormData({
      ...formData,
      selectedSchools: value,
    });
    setSelected(value);
  };

  useEffect(() => {
    const filterData = schoolList?.filter((item) =>
      selected.includes(item?.schoolName)
    );
    // let result = filterData.map((elem) => elem.schoolId);
    setSelectedSchool(filterData);
  }, [selected]);

  useEffect(() => {
    if (
      formData.boardId ||
      formData.gradeId ||
      formData.subjectId ||
      formData.blueprintLevelId ||
      formData.academicYearId ||
      formData.selectedSchools.length > 0
    ) {
      setShowCancelButton(true);
    } else {
      setShowCancelButton(false);
    }
  }, [formData]);

  return (
    <div className="blue-print-wrap">
      <div style={{ marginLeft: "5px" }}>
        <span style={{ display: "flex", alignItems: "center" }}>
          <img
            src={dashHome}
            alt="no img"
            width="20px"
            height="20px"
            style={{ verticalAlign: "middle", marginRight: "10px" }}
          />
          Assign Assessment paper
        </span>
      </div>
      <div className="upload-assessment-form-container">
        <div className="form-wrapper">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "20ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Board
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.boardId)
                      ? formData.boardId
                      : ""
                  }
                  label="Board"
                  name="boardId"
                  onChange={handleChange}
                  error={!!formErrors.boardId}
                //   helperText={formErrors.paperTypeId}
                >
                  {boardList?.map((item) => {
                    return <MenuItem value={item?.id}
                      id={`az-revisionadmin-boarddropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>{item?.board}</MenuItem>;
                  })}
                </Select>
                {formErrors.boardId && (
                  <>
                    <p className="error-style">{formErrors.boardId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Grade
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.gradeId)
                      ? formData.gradeId
                      : ""
                  }
                  label="Grade"
                  name="gradeId"
                  onChange={handleChange}
                  error={!!formErrors.gradeId}
                //   helperText={formErrors.paperTypeId}
                >
                  {gradeList?.map((item) => {
                    return <MenuItem value={item?.id}
                      id={`az-revisionadmin-gradedropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: 'orange',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>{item?.grade}</MenuItem>;
                  })}
                </Select>
                {formErrors.gradeId && (
                  <>
                    <p className="error-style">{formErrors.gradeId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.subjectId)
                      ? formData.subjectId
                      : ""
                  }
                  label="Subject"
                  name="subjectId"
                  onChange={handleChange}
                  error={!!formErrors.subjectId}
                //   helperText={formErrors.paperTypeId}
                >
                  {subjectList?.map((item) => {
                    return (
                      <MenuItem value={item?.subjectId}
                        id={`az-revisionadmin-subjectdropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        {item?.subject}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formErrors.subjectId && (
                  <>
                    <p className="error-style">{formErrors.subjectId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.blueprintLevelId)
                      ? formData.blueprintLevelId
                      : ""
                  }
                  label="Board"
                  name="blueprintLevelId"
                  onChange={handleChange}
                  error={!!formErrors.blueprintLevelId}
                //   helperText={formErrors.paperTypeId}
                >
                  {levelList?.map((item) => {
                    return (
                      <MenuItem value={item?.id}
                        id={`az-revisionadmin-leveldropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <Tooltip title={item?.description} placement="right">
                          {item?.levelName}
                        </Tooltip>
                      </MenuItem>
                    );
                  })}
                </Select>
                {formErrors.blueprintLevelId && (
                  <>
                    <p className="error-style">{formErrors.blueprintLevelId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  Academic year
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.academicYearId)
                      ? formData.academicYearId
                      : ""
                  }
                  label="Academic year"
                  name="academicYearId"
                  onChange={handleChange}
                  error={!!formErrors.academicYearId}
                >
                  {academicYearList?.map((item) => {
                    return (
                      <MenuItem value={item?.id}
                        id={`az-revisionadmin-academicyeardropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>{item?.academicYear}</MenuItem>
                    );
                  })}
                </Select>
                {formErrors.academicYearId && (
                  <>
                    <p className="error-style">{formErrors.academicYearId}</p>
                  </>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "20ch", marginTop: "8px", m: 1 }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label" required>
                  School
                </InputLabel>
                <Select
                  labelId="mutiple-select-label"
                  id="demo-simple-select"
                  multiple
                  value={formData?.selectedSchools}
                  label="School"
                  name="Schools"
                  onChange={handleCheckBoxChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                // error={!!formErrors.schoolId}
                >
                  {schoolList?.map((item) => (
                    <MenuItem key={item?.schoolId} value={item?.schoolName}
                      id={`az-revisionadmin-schooldropdown-${item.id}`}
                      sx={{
                        // "&.Mui-selected": {
                        //   backgroundColor: 'orange',
                        //   color: "#FFFFFF"
                        // },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#fcedc2',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>
                      <ListItemIcon>
                        <Checkbox
                          style={{
                            color: selected.indexOf(item?.schoolName) > -1 ? "#FAA220" : "inherit"
                          }}
                          checked={selected.indexOf(item?.schoolName) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item?.schoolName} />
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.selectedSchools && (
                  <>
                    <p className="error-style">{formErrors.selectedSchools}</p>
                  </>
                )}
              </FormControl>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "5px",
                  paddingRight: "20px",
                  position: "absolute",
                  bottom: "12px",
                  right: "12px",
                }}
              >
                <button
                  onClick={resetForm}
                  type="button"
                  className="button-cancel-assessment"
                  style={{
                    backgroundColor: showCancelButton ? "red" : "",
                    color: showCancelButton ? "#ffff" : "",
                    cursor: showCancelButton ? "pointer" : 'default',
                  }}
                >
                  <span className="btn-text">Cancel</span>
                </button>
                {
                  //console.log(selected?.length > 0 && paperList?.length > 0 ? false : true, "507", selected, paperList)
                }
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="button-submit-assign"
                  style={{
                    backgroundColor:
                      selected?.length > 0 && paperList?.length > 0
                        ? ""
                        : "#403e75",
                    cursor: "pointer",
                  }}
                  disabled={
                    selected?.length > 0 && paperList?.length > 0 ? false : true
                  }
                >
                  <span className="btn-text">Assign</span>
                </button>
              </div>
            </div>
          </Box>
        </div>
      </div>
      {/* <ConfigurationSuccessModal
        userType={modalMessage}
        open={successCreateModal}
        handleOk={closeSuccessModal}
      />
      <BluePrintErrorModal
        userType={modalMessageError}
        open={errorCreateModal}
        handleOk={closeErrorModal}
      /> */}
    </div>
  );
}
