import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Preview, SearchOutlined, TwoK } from "@mui/icons-material";
import GreenAddButton from "../../components/common/GreenAddButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import { MenuProps } from "./Data";
import axios from "axios";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import CommonDeleteModal from "./CommonDeleteModal";
import ConfirmationModal from "./ConfirmationModal";
import dashHome from "../../../src/img/dashHome.svg";

//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";
import searchIcn from "../../../src/img/search.svg";

//sort icon
import sortIcon from "../../../src/pages/img/sort_1.png";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import UpdatedModal from "./UpdatedModal";

const City = () => {
  const token = sessionStorage.getItem("token");
  const [addCityVisible, setAddCityVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [updatedData, setUpdatedData] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(1000);
  const [countryList, setCountryList] = useState();
  const [pincodeList, setPincodeList] = useState([]);
  const [stateList, setStateList] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPincode, setSelectedPincode] = useState("");
  const [filterValue, setFilterValue] = useState();
  const [modifiedDate, setModifiedDate] = useState({});
  const [isValid, setValidation] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [message, setMessage] = useState("");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [id, setId] = useState();
  const [pincodeError, setPincodeError] = useState(false);
  const [pincodeMapErr, setPincodeMapErr] = useState(false);
  const navigate = useNavigate();


  //sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const [pincodeId, setPincodeId] = useState("")

  const showAddModal = (row) => {
    setAddCityVisible(true);
    setSelectedId(row?.id);
    setSelectedCountry(row?.countryId);
    setSelectedCity(row?.city);
    setSelectedState(row?.stateId);
    setSelectedPincode(row?.pincode);
    setPincodeId(row.pincodeId)
    setrowPincode(row?.pincode)
    // console.log(pincodeId, "idpin")
    // console.log(selectedPincode, "pin")
    // console.log(row.pincode, "pinno")
  };

  const hideAddModal = () => {
    setAddCityVisible(false);
    setIsEdit(false);
    setSelectedCountry("");
    setSelectedCity("");
    setSelectedState("");
    setSelectedPincode("");
  };
  const fetchData = async (pageSize) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities?pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.data) {
      const totalElement = response?.data?.data?.totalElements;
      if (totalElement >= pageSize) {
        setPageSize(totalElement);
      }
      setUpdatedData(response?.data?.data?.data);
    }
  };

  const handleOpen = async (row) => {
    setId(row.id);
    setTimeout(() => {
      setDeleteModal(true);
    }, 500);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/confirmation-api?id=${row.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMessage(response?.data?.data?.message);
  };

  const hideDeleteModal = () => {
    setDeleteModal(false);
  };

  const fetchCountry = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/countries/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCountryList(response?.data?.data);
  };
  const fetchState = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/states/all?countryId=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response.data);
    setStateList(response?.data?.data);
  };
  const [SuccessDisplayName, setSuccessDisplayName] = useState("")
  const addCityHandler = () => {
    if (
      !selectedCountry ||
      !selectedState ||
      !selectedPincode ||
      !selectedCity
    ) {
      setTimeout(() => {
        setValidation(true);
      }, 500);

      return;
    }
    setPincodeError(false);

    const newValue = {
      countryId: selectedCountry,
      stateId: selectedState,
      city: selectedCity,
    };
    setSuccessDisplayName(newValue.city)
    if (typeof selectedPincode === "object" && selectedPincode !== null) {
      newValue.pincodeId = selectedPincode?.id;
    } else {
      newValue.pincodeId = null;
      newValue.pincode = selectedPincode;
      if (selectedPincode.length < 6) {
        setTimeout(() => {
          setValidation(true);
        }, 500);
        setPincodeError(true);
        return;
      }
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {

        if (response.data.data) {
          setUpdatedData([...updatedData, response.data.data]);
          fetchData(pageSize)
          hideAddModal();
        }
        setTimeout(() => {
          setSuccessCreateModal(true)
        }, 500);
      })
      .catch(function (response) {
        // console.log("error ==> ", response);
      });
  };

  const deleteCity = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        fetchData(100);
        hideDeleteModal();
      })
      .catch(function (response) {
        hideDeleteModal();
      });
  };

  const fetchPincode = async (stateId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/pincodes/all?stateId=${stateId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setPincodeList(response?.data?.data);
  };
  useEffect(() => {
    if (selectedCountry) {
      fetchState(selectedCountry);
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (pageSize) {
      fetchData(pageSize);
      fetchCountry();
    }
  }, [pageSize, sortOrder]);
  useEffect(() => {
    if (selectedState) {
      fetchPincode(selectedState);
    }
  }, [selectedState]);
  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/toggle-active?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setUpdatedData(clone);
          handleConfirmationClose();
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e?.target?.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/toggle-active?id=${id}&active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setUpdatedData(clone);
          }
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const [rowPincode, setrowPincode] = useState("")
  const [updatedName, setUpdatedName] = useState("")
  const editHandler = (id, countryId, stateId, pincodeId, pincode, city) => {
    if (!countryId || !stateId || !pincodeId || !pincode || !city) {
      setTimeout(() => {
        setValidation(true);
      }, 500);
      return;
    }
    const updatedValue = {
      countryId: countryId,
      stateId: stateId,
      pincodeId: rowPincode === selectedPincode ? pincodeId : null,
      pincode: selectedPincode,
      city: city,
    };
    setUpdatedName(updatedValue.city)
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/${id}`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {

        if (response?.data?.data) {
          const find = updatedData?.find(
            (item) => item.id === response?.data?.data?.id
          );
          find.city = city;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          fetchData(pageSize)
          setUpdatedData(clone);
          hideAddModal();
        }
        setTimeout(() => {
          setSuccesUpdatesModal(true)
        }, 500);
      })
      .catch(function (response) {
        //handle error
        // console.log(response);
      });
  };
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.city?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
    setPageNumber(0);
  };
  useEffect(() => {
    // console.log("helloworld");

    fetchPincode();
  }, []);

  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)
  const [successCreateModal, setSuccessCreateModal] = useState(false)
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }

  const closeModal = () => {
    setSuccesUpdatesModal(false)
  }

  const textFieldStyles = {
    fontSize: "14px !important",
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important"
      }
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#ffa500 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#B5B6B6 !important",
      fontSize: "14px"
    },
    "& .MuiInputBase-root": {
      height: "48px !important",
      width: "270px !important",
      borderRadius: "150px !important",
    },
    "& .MuiInputBase-input": {
      color: "#ffa500",
    }
  };

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );


  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "}
          <a>City</a> */}
          <span
            className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          >
            City
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addCityVisible && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ApartmentRoundedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit City" : "Add City"}
                </div>
              </div>
              <Paper
                style={{
                  padding: "20px 20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: "1rem",
                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "1rem",
                      }}
                    >
                      <Grid item xs={3}>
                        <FormControl
                          sx={{ width: "100%", backGround: "#fff" }}
                        // size="small"
                        >
                          <Autocomplete
                            disablePortal
                            id="demo-select-small"
                            options={countryList}
                            getOptionLabel={(option) =>
                              option ? option.country : ""
                            }
                            onChange={(e, value) =>
                              setSelectedCountry(value?.id)
                            }
                            defaultValue={
                              selectedCountry
                                ? countryList.find(
                                  (v) => v.id == selectedCountry
                                )
                                : ""
                            }
                            // sx={{ width: 400 }}
                            renderInput={(params) => (
                              <TextField {...params} label="Country"
                                sx={textFieldStyles}
                              />
                            )}
                          />
                          {isValid && !selectedCountry && (
                            <span
                              className={dashboardContentStyles.cityCreateerror}
                            >
                              Country is Required
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          sx={{ width: "100%", backGround: "#fff" }}
                        // size="small"
                        >
                          <InputLabel id="demo-select-small">State</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            input={<OutlinedInput label="Tag" />}
                            MenuProps={MenuProps}
                            style={{
                              background: "#fff",
                            }}
                            sx={{
                              "& .css-10hburv-MuiTypography-root": {
                                fontSize: "12px !important",
                              },
                              "&.MuiOutlinedInput-root": {
                                "& > fieldset": {
                                  border: "1px solid #D6D5DF !important"
                                }
                              },
                              "&.MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  border: "1px solid #272727 !important"
                                }
                              },
                              fontSize: "14px !important",
                              borderRadius: "150px"
                            }}
                            value={selectedState ?? ""}
                            onChange={(e) => setSelectedState(e.target.value)}
                          >
                            {stateList?.map((name) => (
                              <MenuItem key={name?.id} value={name?.id}
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: '#FFA500',
                                    color: "#FFFFFF"
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: '#FFA500',
                                    color: "#000000"
                                  },
                                  "&:not(.Mui-selected):hover": {
                                    backgroundColor: '#fcedc2',
                                    color: '#000000',
                                  },
                                }}
                              >
                                <ListItemText primary={name?.state} />
                              </MenuItem>
                            ))}
                          </Select>
                          {isValid && !selectedState && (
                            <span
                              className={dashboardContentStyles.cityCreateerror}
                            >
                              State is Required
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          id="demo-helper-text-aligned-no-helper"
                          label="City"
                          autoComplete="off"
                          // onChange={subjectHandler}
                          style={{ width: "100%" }}
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.target.value)}

                          sx={textFieldStyles}
                        />
                        {isValid && !selectedCity && (
                          <span
                            className={dashboardContentStyles.cityCreateerror}
                          >
                            City is Required
                          </span>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </div>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={3}>
                      <FormControl
                        sx={{ width: "100%", backGround: "#fff" }}
                      // size="small"
                      >
                        <Autocomplete
                          value={selectedPincode}
                          disablePortal
                          id="demo-select-small"
                          options={pincodeList}
                          freeSolo
                          getOptionLabel={(option) =>
                            option?.pincode
                              ? option.pincode
                              : selectedPincode
                                ? selectedPincode
                                : ""
                          }
                          onChange={(e, value) => {
                            setSelectedPincode(value);
                            //console.log(selectedPincode, "pincode")
                          }}
                          autoSelect
                          defaultValue={
                            selectedPincode
                              ? pincodeList.find((v) => v.id == selectedPincode)
                              : ""
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Pincode"
                              value={selectedPincode}
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 6,
                              }}
                              sx={textFieldStyles}
                            />
                          )}
                        />
                        {isValid && !selectedPincode && (
                          <span
                            className={dashboardContentStyles.cityCreateerror}
                          >
                            Pincode is Required
                          </span>
                        )}
                        {pincodeError && (
                          <span
                            className={dashboardContentStyles.cityCreateerror}
                          >
                            Pincode should be a 6 digit number
                          </span>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.5}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "1rem",
                        }}
                      >
                        <HoverGreyCancelButton
                          variant="outlined"
                          onClick={() => {
                            hideAddModal();
                          }}
                        >
                          Cancel
                        </HoverGreyCancelButton>
                        {isEdit ? (
                          <BlueButton
                            onClick={() =>
                              editHandler(
                                selectedId,
                                selectedCountry,
                                selectedState,
                                pincodeId,
                                selectedPincode,
                                selectedCity
                              )
                            }
                          >
                            Update
                          </BlueButton>
                        ) : (
                          <BlueButton onClick={() => addCityHandler()}>
                            Create
                          </BlueButton>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <ApartmentRoundedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                City List
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Search...."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                onChange={onSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!addCityVisible && (
                <GreenAddButton
                  onClick={() => {
                    setAddCityVisible(true);
                  }}
                >
                  <AddCircleOutlineOutlinedIcon
                    style={{ marginRight: "10px" }}
                  />
                  Add City
                </GreenAddButton>
              )}
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{ maxHeight: addCityVisible ? 410 : 650, overflow: "auto" }}
            >
              <Table
                style={{ marginBottom: "90px" }}
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      City
                      <img
                        className="cursor-pointer"
                        width="11"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 2px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("city");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Pincode
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 2px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("pincode");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      State
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 2px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("state");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Country
                      <img
                        className="cursor-pointer"
                        width="10"
                        height="15"
                        src={sortIcon}
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 2px 3px",
                        }}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("country");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                      style={{ paddingRight: 60 }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      pageNumber * rowsPerPage,
                      pageNumber * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      return (
                        <TableRow
                          key={row?.id}
                          sx={{
                            // "&:last-child td, &:last-child th": { border: 0 },

                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                            "&:last-child td, &:last-child th": {
                              borderBottom: "none !important",
                            },

                            "&:hover": {

                              "& td, & th": {
                                borderTop: "1px solid #ff9702",
                                borderBottom: "1px solid #ff9702",
                              },
                              " & th:first-of-type": {
                                borderLeft: "1px solid #ff9702",
                              },

                              "& td:first-of-type": {
                                borderLeft: "0",

                              },

                              "&:last-child td, &:last-child th": {
                                borderBottom: "1px solid #ff9702 !important",
                              },

                              "& td:last-of-type": {
                                borderRight: "1px solid #ff9702",

                              },

                              "& th:last-of-type": {
                                borderRight: "0",
                              },
                            },

                            "& td, & th": {
                              border: "1px solid transparent",
                              borderTop: "1px solid transparent",
                              borderBottom: "1px solid transparent",
                              "&:not(:last-child)": {
                                borderRight: "1px solid transparent",
                              },
                            },
                          }}

                        // hover={true}
                        // style={{
                        //   background: "#f9f8f8",
                        // }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={dashboardContentStyles.table_cell}
                          >
                            {pageNumber * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cell}
                          >
                            {row.city}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cell}
                          >
                            {row.pincode}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cell}
                          >
                            {row.state}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={dashboardContentStyles.table_cell}
                          >
                            {row.country}
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              <Switch
                                checked={row?.active}
                                onChange={(e) => switchHandler(row?.id, e)}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <EditIconButton
                              onClick={() => {
                                showAddModal(row);
                                setIsEdit(true);
                              }}
                            >
                              <CustomTooltip title="Edit" placement={"top"}>
                                {/* <EditOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Edit}
                                  alt="Edit-Btn"
                                />
                              </CustomTooltip>
                            </EditIconButton>
                            <DeleteIconButton onClick={() => handleOpen(row)}>
                              <CustomTooltip title="Remove" placement={"top"}>
                                {/* <DeleteOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Delete}
                                  alt="Dlt-Btn"
                                />
                              </CustomTooltip>
                            </DeleteIconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{
                bottom: "0",
                width: "80.5%",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={updatedData?.length || 0}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
          <CommonDeleteModal
            open={deletemodal}
            close={hideDeleteModal}
            deleteTitle={"Subtopic"}
            deleteHandler={deleteCity}
            //  deleteId={item?.id}
            deleteName={message}
          />
          <ConfirmationModal
            open={toggleConfirmation}
            confirmationmessage={toggleMessage}
            close={handleConfirmationClose}
            updateToggle={updateToggle}
          />
          <Success_OK_Modal
            userType={"City"}
            userName_Modal={
              SuccessDisplayName
            }
            open={successCreateModal}
            handleOk={closeSuccessModal}
          />
          <UpdatedModal
            userType={"City"}
            userName_Modal={
              updatedName
            }
            open={succesUpdatesModal}
            handleOk={closeModal}
            close={closeModal}
          />
        </div>
      </div>
    </>
  );
};
export default City;
