import React, { useEffect, useState, useRef } from 'react'
import {
  Box, Button, FormControl, Grid, Modal, Radio, RadioGroup, styled, TextField, Typography,
  Alert,
  IconButton
} from '@mui/material'
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import chapterStyles from "../../css/createChapter.module.css";
import ChapterStyles from "../../css/createChapter.module.css";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import action__Add from "../../../../src/img/AddDefault.svg";
import action__Delete from "../../../../src/img/Delete.svg";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { orange } from '@mui/material/colors';
import axios from 'axios';
import SelectBoardAndGrade from '../../js/QuestionBank/CommonComponents/SelectBoardAndGrade';
import boards_controller_getall from '../../API_CONTROLLER/boards-controller/boards_controller_getall';
import gradeListGet from '../../API/grade-section-mapping-controller/grade_get';
import TextEditor from '../../js/QuestionBank/CommonComponents/TextEditor';
import create_question_post from '../../API_CONTROLLER/create-questions/question_post';
import DeleteConfirmationAlert from '../../js/QuestionBank/CommonComponents/DeleteConfirmation';
import UploadMapModal from '../UploadMapModal';
import map_content_get from '../../API_CONTROLLER/map-controller/map_content_get';
import ImageListModal from '../../js/QuestionBank/CommonComponents/ImageListModal';
import get_question from '../../API_CONTROLLER/create-questions/get_questionbyid';
import update_question_post from '../../API_CONTROLLER/create-questions/question_put';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router';
import DeleteBlanksModal from '../../js/QuestionBank/CommonComponents/DeleteBlanksModal';

const CustomHead = styled(Typography)(({ theme }) => ({
  '&': {
    color: "#FD8C00",
    fontFamily: "Poppins",
    fontSize: "1.3vw",
    fontWeight: 400,
    lineHeight: "27px",
  },
}))

const CustomRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  color: orange[600],
  boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto orange',
    color: orange[600],
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
  backgroundColor: orange[600],
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: orange[800],
  },
});

const CustomButton = styled(Button)(({ }) => ({
  color: "#FFFFFF",
  backgroundColor: '#FD8C00',
  fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
  fontSize: '10px',
  borderRadius: '30px',
  width: 'auto',
  padding: "0.6vw 1vw",
  '&:hover': {
    backgroundColor: '#FD8C00',
  },
}));

function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomRadioCheckedIcon />}
      icon={<CustomRadioIcon />}
      {...props}
    />
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '58%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "45vw",
  // overflow: "scroll",
  overflowX: "scroll",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "4px",
  p: 4,
};

const CustomTextField = styled(TextField)(({ }) => ({
  width: "96%",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "2px !important",
  "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    opacity: "1 !important",
    background: "rgb(255 255 255 / 31%) !important",
    border: "1px solid #B5B6B6",
    borderRadius: "2px !important",
  }
}))

const SubjectivePoliticalMap = (props) => {
  const removeUploadImgMap = useRef(null);
  const [isBtnEnabled, setIsBtnEnabled] = useState(false);
  const [boardsList, setBoardsList] = useState();
  const [boardSelect, setBoardSelect] = useState('');

  const [gradeList, setGradeList] = useState();
  const [gradeSelect, setGradeSelect] = useState([]);
  const [clearContent, setClearContent] = useState(false);

  const [boardAndGrade, setBoardAndGrade] = useState([]);

  const [boardAndGradeCombo, setBoardAndGradeCombo] = useState([]);
  const [errorField, setErrorField] = useState({});
  const [errorFieldCombo, setErrorFieldCombo] = useState({});
  const [questionContent, setQuestionContent] = useState({ content: "" });
  const [questionContentMap, setQuestionContentMap] = useState({ content: "" });
  const [questionList, setQuestionList] = useState([{ answerKey: '', mark: "", order: 1 }])
  const [answerKeyContent, setAnswerKeyContent] = useState({ content: "" });
  const [radioButtonValue, setRadioButtonValue] = useState("machine");

  const [uploadImage, setUploadImage] = useState();
  const [uploadImageMap, setUploadImageMap] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [imagePreviewMap, setImagePreviewMap] = useState();
  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedImageNameMap, setSelectedImageNameMap] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState();
  const [selectedImageUrlMap, setSelectedImageUrlMap] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [openImageListModal, setOpenImageListModal] = React.useState(false);
  const handleImageListModalOpen = () => setOpenImageListModal(true);
  const handleImageListModalClose = () => setOpenImageListModal(false);
  const [mapcontent, setMapcontent] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [showAlertType, setShowAlertType] = useState("");

  // Repo Map
  const [openImageListModalImage, setOpenImageListModalImage] = React.useState(false);
  const handleImageListModalOpenImage = () => setOpenImageListModalImage(true);
  const handleImageListModalCloseImage = () => setOpenImageListModalImage(false);

  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
  const handlePreviewModalOpen = () => setOpenPreviewModal(true);
  const handlePreviewModalClose = () => setOpenPreviewModal(false);
  const [errorModalDetails, setErrorModalDetails] = useState({ open: false, message: '' })

  const [title, setTitle] = useState(props?.selectedQuestionData?.marks || "");
  const navigate = useNavigate();

  const subjectId = sessionStorage.getItem("subjectId");
  const conceptId = sessionStorage.getItem("conceptId");
  const subConceptId = sessionStorage.getItem("subConceptId");
  const rootConcept = sessionStorage.getItem("rootConceptId");
  const questionTypeId = sessionStorage.getItem("questionType");
  const taxonomyCategory = sessionStorage.getItem("taxonomyCategory");
  const taxonomy = sessionStorage.getItem("taxonomy");
  const questionType = sessionStorage.getItem("questionType");
  const mapImage = sessionStorage.getItem("mapImage");
  const mapUrl = sessionStorage.getItem("mapUrl");
  const subjectiveType = sessionStorage.getItem("subjectiveType");
  const [radioButtonEnable, setRadioButtonEnable] = useState(false);


  // Edit 
  const [responseValue, setResponseValue] = useState();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showerrorMessage, setShowErroMessage] = useState();
  const [edit, setEdit] = useState(false);
  const [questionValue, setQuestionValue] = useState();
  const [previewFromModal, setPreviewFromModal] = useState(false);
  const [questionEditContent, setEditQuestionContent] = useState({ content: "" });
  const [answerEditKeyContent, setEditAnswerKeyContent] = useState({ content: "" });
  const [editmapContent, setEditMapContent] = useState({ content: "" });
  const [answerContent, setAnswerContent] = useState([]);
  const [optionsEdit, setOptionsEdit] = useState();
  const [showEditor, setShowEditor] = useState(false);
  const [uploadImgMap, setUploadImgMap] = useState('');
  const [StepMarkDetails, setStepMarkDetails] = useState([])
  const handleEditQuestion = () => {
    get_question(responseValue.id, setQuestionValue);
  }
  useEffect(() => {
    var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
    //console.log("basic information", basic_info?.boardId, props.selectedGrade)
    // setBoardSelect(basic_info?.boardId);
    // setGradeSelect(props.selectedGrade);
    handleBoardChange(basic_info?.boardId);
    handleGradeChange(props.selectedGrade);
    setTimeout(() => {
      handleBoardAndGradeChange()
    }, 3000);

  }, [])

  useEffect(() => {
    if (questionEditContent.content !== "") {
      setShowEditor(true);
    }
  }, [questionEditContent.content]);

  useEffect(() => {
    if (responseValue) {
      setQuestionContent({ content: responseValue.question });
      setAnswerKeyContent({ content: responseValue?.explanation });
      // setImagePreview(responseValue.imageUrl);
      setQuestionContentMap({ content: questionValue?.mapTitle });
      setSelectedImageUrl(responseValue?.imageUrl);
      setTitle(responseValue.marks)
    }
  }, [responseValue]);
  const handleBoardChange = (e) => {
    //console.log(e, "handleBoardChange")
    // setBoardSelect(e.target.value)
    setBoardSelect(e)
    setErrorFieldCombo(prevState => {
      delete prevState['board'];
      return prevState;
    })
  }

  const handleGradeChange = (e) => {
    //console.log("handleGradeChange", e)
    // const value = e.target.value;
    const value = e;
    setGradeSelect(typeof value === "string" ? value.split(",") : value);
    setErrorFieldCombo(prevState => {
      delete prevState['grade'];
      return prevState;
    })
  }
  useEffect(() => {
    if (props.questionData) {
      setQuestionValue(props.questionData);
      // setEdit(false);
    }
    if (questionValue) {
      let myObj = [];
      setEdit(true);
      if (props.setDisableTopFields) props.setDisableTopFields(true);
      map_content_get(setMapcontent);
      setEditQuestionContent({ content: questionValue.question });
      setEditAnswerKeyContent({ content: questionValue.explanation });
      setOptionsEdit(questionValue.options[0]);
      setTitle(questionValue.marks)
      setSelectedImageUrl(questionValue.imageUrl);
      setSelectedImageName(questionValue.imageUrl);
      if (questionValue?.imageUrl) {
        setRadioButtonValue("repository");
      }
      setEditMapContent({ content: questionValue?.mapTitle });
      // setQuestionContentMap({ content: questionValue.mapTitle })
      sessionStorage.setItem("mapImage", questionValue.mapUrl);
      setQuestionList(questionValue.options)
      props.setDisableTopFields(true)
      if (questionValue.mapUrl && !mapcontent.length) map_content_get(setMapcontent);
      if (!props.caseStudy) {
        questionValue.boardAndGrades.map((boardGrade, ind) => {
          let obj = {
            "boardId": boardGrade.boardId,
            "gradeId": boardGrade.gradeId
          };
          setBoardAndGrade(oldArray => [...oldArray, obj]);
          if (ind !== 0) {
            setErrorField(prevState => {
              delete prevState['combo'];
              return prevState;
            })
            const foundKey = Object.hasOwn(myObj, boardGrade.boardId);
            if (foundKey) {
              myObj[boardGrade.boardId].push(boardGrade.gradeId);
              setBoardAndGradeCombo(myObj);
              setBoardSelect('');
              setGradeSelect([]);
            }
            else {
              myObj[boardGrade.boardId] = [boardGrade.gradeId];
              setBoardAndGradeCombo(myObj);
              setBoardSelect('');
              setGradeSelect([]);
            }
          }
          else {
            myObj = { [boardGrade.boardId]: [] };
            myObj[boardGrade.boardId].push(boardGrade.gradeId);
            setBoardAndGradeCombo(myObj);
            setBoardSelect('');
            setGradeSelect([]);
          }
        })
      }
    }
  }, [props.questionData, questionValue])
  useEffect(() => {
    if (uploadImage || selectedImageName) {
      setRadioButtonEnable(true);
    }
    else {
      setRadioButtonEnable(false);
    }
  }, [uploadImage, selectedImageName]);
  useEffect(() => {
    boards_controller_getall(setBoardsList);
    gradeListGet(setGradeList);
    setClearContent(false);
  }, [])

  // const handleBoardChange = (e) => {
  //   setBoardSelect(e.target.value);
  //   setErrorFieldCombo(prevState => {
  //     delete prevState['board'];
  //     return prevState;
  //   })
  // }
  const onRemoveImage = (type) => {
    if (type === "upload") {
      setUploadImage();
      setSelectedImageUrl()
      setUploadImage()
    }
    else {
      setImagePreview();
      setSelectedImageUrl();
      setSelectedImageName("");
    }
  }

  const uploadImgMapData = (data) => {
    setUploadImgMap(data)
  }

  // const handleGradeChange = (e) => {
  //   const value = e.target.value;
  //   setGradeSelect(typeof value === "string" ? value.split(",") : value);
  //   setErrorFieldCombo(prevState => {
  //     delete prevState['grade'];
  //     return prevState;
  //   })
  // }

  const handleBoardAndGradeChange = () => {
    let errorObj = {};
    let error = false;
    if (boardSelect === "") {
      errorObj['board'] = "Please choose the Board";
      error = true;
    }
    if (gradeSelect.length === 0) {
      errorObj['grade'] = "Please choose atleast one Grade";
      error = true;
    }
    setErrorFieldCombo(errorObj);
    if (!error) {
      setErrorField(prevState => {
        delete prevState['combo'];
        return prevState;
      })
      if (Object.keys(boardAndGradeCombo).length > 0) {
        setErrorField({ message: "", id: "" });
        const foundKey = Object.hasOwn(boardAndGradeCombo, boardSelect);
        if (foundKey) {
          let found;
          found = gradeSelect.filter(item => !boardAndGradeCombo[boardSelect].includes(item))
          const concatArray = boardAndGradeCombo[boardSelect].concat(found);
          setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: concatArray } });
          setBoardSelect('');
          setGradeSelect([]);
        }
        else {
          setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
          setBoardSelect('');
          setGradeSelect([]);
        }
      }
      else {
        setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
        setBoardSelect('');
        setGradeSelect([]);
      }
      gradeSelect.map((grade, ind) => {
        let obj = {
          "boardId": boardSelect,
          "gradeId": grade
        };
        setBoardAndGrade(oldArray => [...oldArray, obj]);
      })
    }
  }

  const onRemoveCombo = (boardId, gradeId) => {
    boardAndGrade.map((key, index) => {
      if (key.boardId === boardId && key.gradeId === gradeId) {
        boardAndGrade.splice(index, 1);
      };
    })
    Object.keys(boardAndGradeCombo).map(key => {
      if (key === boardId) {
        boardAndGradeCombo[key].map((value, i) => {
          if (value === gradeId) {
            boardAndGradeCombo[key].splice(i, 1);
            if (boardAndGradeCombo[key].length === 0) {
              delete boardAndGradeCombo[key];
              setBoardSelect('');
              setGradeSelect([]);
            }
            else {
              setBoardSelect(key);
              setGradeSelect([...boardAndGradeCombo[key]]);
            }
          }
        })
      }
    })
  }

  const onQuestionHandler = (content) => {
    setQuestionContent({ content: content });
    setErrorField(prevState => {
      delete prevState['question'];
      return prevState;
    })
  }

  const onQuestionHandlerMap = (content) => {
    setQuestionContentMap({ content: content });
    setErrorField(prevState => {
      delete prevState['question'];
      return prevState;
    })
  }

  const addQuestion = () => {
    setQuestionList([...questionList, { answerKey: '', mark: '', order: questionList.length + 1 }]);
    //console.log([...questionList, { answerKey: '', mark: '', order: questionList.length + 1 }])
    sessionStorage.setItem("markArray", JSON.stringify([...questionList, { answerKey: '', mark: '', order: questionList.length + 1 }]))
  }

  const deleteQuestion = (order) => {
    const newList = questionList.filter(ques => ques.order !== order).map((ques, i) => ({ ...ques, order: i + 1 }));
    sessionStorage.setItem("markArray", JSON.stringify(newList))
    if (newList.length > 0) setQuestionList(newList)
  }

  const onQuestionChange = (order, key, value) => {
    const copyQuestions = JSON.parse(JSON.stringify(questionList))
    const questionIndex = copyQuestions.findIndex(ques => ques.order === order)
    let isValidValue = true
    if (key === 'mark') {
      if (!/^[0-9]*$/.test(value)) {
        isValidValue = false; return
      }

      let totalMark = +value;
      questionList.forEach((q => totalMark += +q.mark));
      // if (totalMark > title) isValidValue = false // for adding the mark validation. if i enable this it will not allow to enter the marks more than total marks
    }
    if (isValidValue) {
      copyQuestions[questionIndex] = { ...copyQuestions[questionIndex], [key]: value }
      setQuestionList(copyQuestions)
      if (key === 'mark') {
        if (title >= value) {
          copyQuestions[questionIndex] = { ...copyQuestions[questionIndex], [key]: value }
          setQuestionList(copyQuestions);
          sessionStorage.setItem("markArray", JSON.stringify(copyQuestions))
        }
        else {
          copyQuestions[questionIndex] = { ...copyQuestions[questionIndex], [key]: value }
          //console.log("copyQuestions", copyQuestions)
          sessionStorage.setItem("markArray", JSON.stringify(copyQuestions))
          errorField["foundEmptyMark"] = "Step Marks Not Should Be Grater than Total marks."
        }
      }
    }
    setStepMarkDetails(copyQuestions)
  }

  const handleCreateSubQuestion = (type) => {
    let isError = false;
    setIsBtnEnabled(true);
    let errorObj = {};
    // let answerContentFoundEmpty = answerKeyContent.some(ansContent => ansContent.answerKey[0] === '');
    //console.log(title, questionContent, JSON.parse(sessionStorage.getItem("markArray")))
    if (title === "" || questionContent.content === "" || !mapcontent.length || !mapcontent[0].id || questionContentMap.content === ""
      // || boardAndGradeCombo.length === 0 || Object.keys(boardAndGradeCombo).length === 0
    ) {
      //console.log("if-------------------------")
      isError = true;
      if (boardAndGradeCombo.length === 0) {
        errorObj['combo'] = "Please provide the combination of board and grade";
      }
      if (Object.keys(boardAndGradeCombo).length === 0) {
        errorObj['combo'] = "Please provide the combination of board and grade";
      }
      if (title === "") {
        errorObj['numberOfMarks'] = "Please Enter Number Of Marks";
      }
      if (questionContent.content === "") {
        errorObj['question'] = "Please provide the question";
      }
      if (questionContentMap.content === "") {
        errorObj['map'] = "Please provide the Map";
      }
      if (!mapcontent.length || !mapcontent[0].id) errorObj['mapId'] = "Please upload the Map";
      //console.log(errorObj)
    } else {
      //console.log("else ----------------------")
      var marksArray = JSON.parse(sessionStorage.getItem("markArray"));
      //console.log("marksArray", marksArray)
      const totalMarks = marksArray.reduce((acc, item) => acc + parseInt(item.mark), 0);
      //console.log("totalMarks", totalMarks, title)
      if (totalMarks > title) {
        isError = true;
        errorObj['numberOfMarks'] = "The entered mark exceeds the total marks";
      } else if (totalMarks < title) {
        //console.log("Entered mark ", totalMarks, title);
        isError = true;
        errorObj['numberOfMarks'] = "The entered mark does not match the total marks."
      }
      var emptyMark = marksArray.findIndex((val) => { return val.mark === "" });
      //console.log(emptyMark);
      if (emptyMark !== -1) {
        isError = true;
        // errorObj['inputValidatio'] = "Please fill this";
        errorObj['inputIndex'] = emptyMark
        errorObj['foundEmptyMark'] = "Please fill this"

      }
    }

    setErrorField(errorObj);

    if (!isError) {
      setClearContent(false);

      const formatedQueList = questionList.map(ques => ({ ...ques, mark: parseInt(ques.mark) }))
      if (type === "update") {
        setEdit(false);
        const payload1 = {
          id: questionValue?.id,
          subjectId: subjectId,
          conceptId: conceptId,
          subConceptId: subConceptId,
          rootConceptId: rootConcept,
          questionTypeId: questionTypeId,
          taxonomyCategoryId: taxonomyCategory,
          taxonomyId: taxonomy,
          boardAndGrades: boardAndGrade,
          question: questionContent.content,
          explanation: answerKeyContent.content,
          imageUrl: selectedImageUrl,
          marks: title,
          // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
          hasSubQuestions: false,
          options: formatedQueList,
          mapId: mapcontent.length ? mapcontent[0].id : null,
          questionPattern: "SUBJECTIVE",
          subjectiveType: "SIMPLE"
        }
        setResponseValue("");
        const payload = removeNewlines(payload1);
        //console.log(">>>", payload,);
        update_question_post(payload, setShowAlertType, setResponseValue, showErrorMessage,()=>setIsBtnEnabled(false));
      } else {
        //console.log(boardAndGrade, boardSelect, gradeSelect)
        const payload1 = {
          subjectId: subjectId,
          conceptId: conceptId,
          subConceptId: subConceptId,
          rootConceptId: rootConcept,
          questionTypeId: questionTypeId,
          taxonomyCategoryId: taxonomyCategory,
          taxonomyId: taxonomy,
          boardAndGrades: boardAndGrade.length > 0 ? boardAndGrade : [{ boardId: boardSelect, gradeId: gradeSelect[0] }],
          question: questionContent.content,
          explanation: answerKeyContent.content,
          imageUrl: selectedImageUrl,
          marks: title,
          // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
          hasSubQuestions: false,
          options: formatedQueList,
          mapId: mapcontent.length ? mapcontent[0].id : null,
          questionPattern: "SUBJECTIVE",
          subjectiveType: "SIMPLE"
        }
        const payload = removeNewlines(payload1);
        //console.log(">>>", payload);
        // create_question_post(payload, setShowSuccessAlert, setResponseValue, showErrorMessage);
        Save_question(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage)
        sessionStorage.removeItem("mapImage");
      }
    } else {
      setIsBtnEnabled(false);
    }
  }
  const Save_question = async (payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage) => {
    //console.log(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage, "Save_question called")
    if (payload.question !== "") {
      if (props?.selectedQuestionData?.marks !== payload?.options?.length) {
        setErrorField({ "message": "Mark does not match the selected question." });
        setIsBtnEnabled(false);
        setTimeout(() => {
          setErrorField("")
        }, 5000);
      } else {
        try {

          let token = sessionStorage.getItem("token");
          //console.log("payload", payload)
          let response = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`, payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });
          //console.log("response data", response.data.data);
          // sessionStorage.setItem("newQuestion", JSON.stringify(response.data.data));
          // setShowSuccessAlert(true);
          // setResponseValue(response.data.data);
          // response.data.data.payload = payload;
          setIsBtnEnabled(false);
          props.getResponse(response.data.data);
        } catch (error) {
          //console.log(error, "errrpppppp");
          // setShowErrorAlert(true);
          // setShowErroMessage(error?.response?.data?.message || "error");
          // setResponseValue({ error });
          // setTimeout(() => {
          //   setShowErrorAlert(false);
          //   setShowErroMessage("");
          //   setResponseValue({});
          // }, 10000);
          setErrorField(error.response.data);
          setIsBtnEnabled(false);
          setTimeout(() => {
            setErrorField("")
          }, 5000);
        }
      }
    } else {
      // callback({ error: "504", errorMessage: "Please fill the Required fields" });
      // alert({ error: "504", errorMessage: "Please fill the Required fields" });
      setErrorField("Please fill the Required fields");
      setIsBtnEnabled(false);
      setTimeout(() => {
        setErrorField("")
      }, 5000);

    }
  }
  const removeNewlines = (obj) => {
    const newObj = {};
    // Recursively traverse the object
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/\n/g, '');
      } else if (typeof obj[key] === 'object') {
        removeNewlines(obj[key]);
      }
    }
    return obj;
  };

  const onAnswerKeyHandler = (content) => {
    setAnswerKeyContent({ content: content });
  };

  const fileInput = React.useRef();
  const fileInputMap = React.useRef();

  const imageUploadHandler = (e) => {
    setUploadImage();
    setImagePreview();
    setSelectedImageUrl();
    setRadioButtonValue(e.target.value)
    if (e.target.value === "repository") {
      handleImageListModalOpen();
    }
  }

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setUploadImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    var fileMode = new File([file], file.name, { type: file.type });
    const formData = new FormData();
    formData.append("file", fileMode);
    const file_upload_post = async () => {
      let token = sessionStorage.getItem("token");
      let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=QUESTIONS`, formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            setSelectedImageUrl(response.data.data)
            sessionStorage.setItem("logoUrl", response.data.data)
          }
        )
        .catch(err => console.log(err))
    }
    file_upload_post();
  }

  const onMapFileChange = (e) => {
    const file = e.target.files[0];
    setUploadImageMap(e.target.files[0]);
    setImagePreviewMap(URL.createObjectURL(e.target.files[0]));
    let item = {
      mapType: "POLITICAL",
      mapUrl: "https://s3.ap-south-1.amazonaws.com/lmscontentupload/Content-Service/Chapters/1148bc0e-40cf-487b-a5e7-bc623974ffe7_img11.jpg",
      title: e.target.value
    }
    const mapfile_upload_post = async () => {
      let token = sessionStorage.getItem("token");
      let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/map`, item, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
        .then(
          (response) => {
            setSelectedImageUrlMap(response.data.data.id)
            sessionStorage.setItem("mapUrl", response.data.data.id)
          }
        )
        .catch(err => console.log(err))
    }
    mapfile_upload_post();
  }

  const onTitleChange = (e) => {
    const { value } = e.target
    if (/^[0-9]*$/.test(value)) setTitle(value)
  }

  useEffect(() => {
    if (showSuccessAlert) {
      setSuccessMessage(`You have successfully created Subjective Political Map question`);
      setBoardSelect('');
      setGradeSelect([]);
      setBoardAndGradeCombo([]);
      setClearContent(true);
      // setPreview(true);
      setUploadImage();
      setUploadImageMap();
      setImagePreview();
      setImagePreviewMap();
      setSelectedImageName("");
      setSelectedImageNameMap("");
      setSelectedImageUrl();
      setSelectedImageUrlMap();
      setQuestionList([{ answerKey: '', mark: "", order: 1 }])
      setTitle('')
      setShowEditor(false);
    }
  }, [showSuccessAlert]);

  const handleCancelUpdate = () => {
    setIsBtnEnabled(false);
    if (props.fromViewAllQuestion !== undefined) {
      navigate("/dashboard/View All Questions");
    }
    setBoardSelect('');
    setGradeSelect([]);
    setBoardAndGradeCombo([]);
    setClearContent(true);
    // setPreview(true);
    // setUploadImage();
    setUploadImageMap();
    setImagePreview();
    setSelectedImageName("");
    setSelectedImageUrl();
    setQuestionList([{ answerKey: '', mark: "", order: 1 }])
    setTitle('')
    setShowEditor(false);
    setBoardAndGrade([]);
    setErrorField({});
    setErrorFieldCombo({});
    if (!props.caseStudy) {
      props.setDisableTopFields(false);
    }
    setEdit(false);
    setShowEditor(false);
  }

  const onHideSuccessModal = () => {
    setShowSuccessAlert(false);
  }

  const onHideModal = () => {
    setShowAlert(false);
  }

  const onHideModalType = () => {
    setShowAlert(false);
    navigate("/dashboard/View All Questions");
  }

  const showErrorMessage = (message) => {
    setErrorModalDetails({ open: true, message })
  }
  const closeErrorModal = () => { setErrorModalDetails({ open: false, message: '' }) }

  const closeCreateModal = () => {
    setCreateModal(false);
  };

  const closeModalAPI = () => {
    setCreateModal(false);
    map_content_get(setMapcontent);
  };

  return (
    <>
      {/* <Grid container spacing={2} sx={{ p: 2 }}>
        <SelectBoardAndGrade
          boardsList={boardsList}
          gradeList={gradeList}
          boardValue={boardSelect}
          handleBoardChange={handleBoardChange}
          gradeValue={gradeSelect}
          handleGradeChange={handleGradeChange}
          isError={errorFieldCombo}
        />
        <Grid item xs={3} md={3} lg={3}>
          <img
            className={
              dashboardContentStyles.aRoleActionBtn
            }
            onClick={handleBoardAndGradeChange}
            src={action__Add}
            alt="Add-Btn"
            style={{ width: "18%", height: "auto" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {!!errorField['combo'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['combo']}</Typography>}
          {Object.keys(boardAndGradeCombo).length > 0 &&
            <Grid container sx={{ border: "1px solid #B5B6B6", borderRadius: "4px", boxShadow: "0px 3px 6px #00000029" }}>
              {Object.keys(boardAndGradeCombo).length > 0 ?
                Object.keys(boardAndGradeCombo).map(key => {
                  return (
                    boardsList && boardsList.map(board => {
                      return (
                        board.id === key &&
                        gradeList && gradeList.map(grade => {
                          return (
                            boardAndGradeCombo[key].map((value, i) => {
                              return (
                                value === grade.id &&
                                <Grid item xs={6} md={4} lg={4} key={key} sx={{ p: 1 }}>
                                  <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                    {board.board}/{grade.grade}
                                    <CloseOutlinedIcon onClick={() => onRemoveCombo(board.id, grade.id)} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                  </Typography>
                                </Grid>

                              )
                            })
                          )
                        })
                      )
                    })
                  )
                })
                :
                null
              }
            </Grid>
          }
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
        </Grid>
      </Grid> */}
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} md={12} lg={12}>
          <CustomHead >Simple Questions</CustomHead>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography>Question</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {!edit ? <TextEditor onEditorContentChange={onQuestionHandler} name={"question"} clearContent={clearContent && clearContent} /> :
              showEditor && <TextEditor onEditorContentChange={onQuestionHandler} name={"editQuestion"} initialValue={edit ? questionEditContent.content : ""} clearContent={clearContent && clearContent} />}
            {/* <TextEditor onEditorContentChange={onQuestionHandler} clearContent={clearContent && clearContent} /> */}
            {!!errorField['question'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['question']}</Typography>}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioButtonValue}
                  onChange={imageUploadHandler}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel disabled={radioButtonEnable} value="machine" control={<CustomRadio />} label="Upload from my machine" sx={{ fontSize: '1.5vw' }} />
                  <FormControlLabel disabled={radioButtonEnable} value="repository" control={<CustomRadio />} label="Use from repository" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {radioButtonValue === "machine" &&
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography sx={{ color: '#272727', fontSize: '1vw', fontWeight: '500', fontFamily: 'normal, normal, medium, Poppins' }}>Upload Image</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography>
                    <input ref={fileInput} onChange={onFileChange} type="file" accept="image/*" style={{ display: 'none' }} /><CustomButton onClick={() => fileInput.current.click()}><ImageOutlinedIcon fontSize="small" />Choose File</CustomButton>
                  </Typography>
                  {uploadImage &&
                    <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                      {uploadImage && uploadImage.name}
                      <CloseOutlinedIcon onClick={() => onRemoveImage("upload")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                    </Typography>
                  }
                </Grid>
              </>
            }
            {radioButtonValue === "repository" &&
              <Grid item xs={12} md={12} lg={12}>
                {selectedImageName &&
                  <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                    {selectedImageName && selectedImageName.split('/').pop()}
                    <CloseOutlinedIcon onClick={() => onRemoveImage("repository")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                  </Typography>
                }
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Typography>Explanation/Answer Key</Typography>
          {!edit ? <TextEditor onEditorContentChange={onAnswerKeyHandler} name={"explanation"} clearContent={clearContent && clearContent} /> :
            showEditor && <TextEditor onEditorContentChange={onAnswerKeyHandler} name={"editExplanation"} initialValue={answerEditKeyContent.content} clearContent={clearContent && clearContent} />}
          {/* <TextEditor onEditorContentChange={onAnswerKeyHandler} clearContent={clearContent && clearContent} /> */}
          <Grid item xs={12} md={6} lg={6} sx={{ marginLeft: 'auto' }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '100%',
                boxShadow: 3,
                borderRadius: 2,
                marginTop: '20px',
              }}
            >
              <input type="text" className={dashboardContentStyles.title} onChange={onTitleChange} placeholder="*Number of marks" value={title} disabled={true} />
            </Box>
            {!!errorField["numberOfMarks"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["numberOfMarks"]}</Typography>}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography>Map Name</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {!edit ? <TextEditor onEditorContentChange={onQuestionHandlerMap} name={"mapTitle"} clearContent={clearContent && clearContent} /> :
              showEditor && <TextEditor onEditorContentChange={onQuestionHandlerMap} name={"mapTitle"} initialValue={editmapContent.content} clearContent={clearContent && clearContent} />}
            {/* <TextEditor onEditorContentChange={onQuestionHandlerMap} initialValue={edit ? questionContentMap.content : ""} clearContent={clearContent && clearContent} /> */}
            {/* <TextEditor onEditorContentChange={onQuestionHandlerMap} clearContent={clearContent && clearContent} /> */}
            {!!errorField['map'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['map']}</Typography>}
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={12} lg={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioButtonValue}
                  onChange={imageUploadHandler}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel value="machine" control={<CustomRadio />} label="Upload from my machine" sx={{ fontSize: '1.5vw' }} />
                  <FormControlLabel value="repository" control={<CustomRadio />} label="Use from repository" />
                </RadioGroup>
              </FormControl>
            </Grid> */}
            {/* {radioButtonValue === "machine" &&
              <> */}
            <Grid item xs={12} md={12} lg={12}>
              <Typography sx={{ color: '#272727', fontSize: '1vw', fontWeight: '500', fontFamily: 'normal, normal, medium, Poppins', marginTop: "15px" }}>Upload MAP</Typography>
            </Grid>
            {uploadImgMap &&
              <Typography sx={{ marginTop: "10px", padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                {uploadImgMap && uploadImgMap.name}
                <CloseOutlinedIcon onClick={() => removeUploadImgMap.current && removeUploadImgMap.current()} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
              </Typography>
            }
            <Grid item xs={12} md={12} lg={12}>
              <Typography>
                <CustomButton onClick={setCreateModal}><ImageOutlinedIcon fontSize="small" />Choose File</CustomButton>
              </Typography>
              {!!errorField['mapId'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['mapId']}</Typography>}
              <Typography sx={{ fontSize: '1vw' }}>
                {mapImage && mapImage}
              </Typography>
            </Grid>
            {/* </>
            } */}
            {/* {radioButtonValue === "repository" &&
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: '1vw' }}>
                  {selectedImageNameMap}
                </Typography>
              </Grid>
            } */}
          </Grid>
        </Grid>
      </Grid>
      <hr />
      {questionList.length ? questionList.map((ques, ind) => {
        return <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} md={6} lg={5}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '100%',
                boxShadow: 3,
                borderRadius: 2,
                marginTop: '20px',
              }}
            >
              <input type="text" className={dashboardContentStyles.title} onChange={(e) => onQuestionChange(ques.order, "answerKey", e.target.value)} placeholder="Step Mark" value={ques.answerKey} />
            </Box>
            {!!errorField["foundEmpty"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["foundEmpty"]}</Typography>}

          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '100%',
                boxShadow: 3,
                borderRadius: 2,
                marginTop: '20px',
              }}
            >
              <input type="text" className={dashboardContentStyles.title} onChange={(e) => onQuestionChange(ques.order, "mark", e.target.value)} placeholder="Marks" value={ques.mark} />
            </Box>
            {
              //console.log(errorField["foundEmptyMark"], errorField["inputIndex"], ind)
            }
            {(!!errorField["foundEmptyMark"] && errorField["inputIndex"] == ind) && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["foundEmptyMark"]}</Typography>}
          </Grid>
          <Grid item xs={12} md={6} lg={1} sx={{ display: "flex", alignItems: "center" }} >
            <img
              className={
                dashboardContentStyles.aRoleActionBtn
              }
              onClick={addQuestion}
              src={action__Add}
              alt="Add-Btn"
              style={{ width: "70%", height: "70%" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={1} sx={{ display: "flex", alignItems: "center" }}>
            <img
              className={
                dashboardContentStyles.aRoleActionBtn
              }
              src={action__Delete}
              alt="Dlt-Btn"
              style={{ width: "70%", height: "70%" }}
              onClick={() => deleteQuestion(ques.order)}
            />
          </Grid>
        </Grid>
      }) : null}
      <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {errorField.message && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField.message}</Typography>}
      </Grid>
      <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControl sx={{ ml: 1 }}>
          <button
            style={{ maxHeight: "50px" }}
            onClick={handlePreviewModalOpen}
            className={ChapterStyles.blueWhite_btn}
          >
            {"PREVIEW"}
          </button>
        </FormControl>
        {edit ?
          <>
            <FormControl sx={{ ml: 1 }}>
              <button
                style={{ maxHeight: "50px" }}
                onClick={handleCancelUpdate}
                className={ChapterStyles.blue_btn}
              >
                CANCEL
              </button>
            </FormControl>
            <FormControl sx={{ ml: 1 }}>
              <button
                style={{ maxHeight: "50px" }}
                disabled={isBtnEnabled}
                onClick={() => handleCreateSubQuestion("update")}
                className={ChapterStyles.blue_btn}
              >
                UPDATE
              </button>
            </FormControl>
          </>
          :
          <FormControl sx={{ ml: 1 }}>
            <button
              style={{ maxHeight: "50px" }}
              disabled={isBtnEnabled}
              onClick={() => handleCreateSubQuestion("create")}
              className={chapterStyles.blue_btn}
            >
              CREATE
            </button>
            {errorField.id === "createButton" && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField.message}</Typography>}
          </FormControl>
        }
      </Grid>
      <UploadMapModal
        open={createModal}
        close={closeCreateModal}
        closeModalAPI={closeModalAPI}
        handleImageListModalOpen={handleImageListModalOpenImage}
        uploadImgMapData={uploadImgMapData}
        removeUploadImgMap={removeUploadImgMap}
        selectedImageName={mapImage}
      />
      {openImageListModal ? <ImageListModal
        openImageModal={openImageListModal}
        closeImageModal={handleImageListModalClose}
        selectedImage={setSelectedImageUrl}
        selectedImageName={setSelectedImageName}
      /> : null}
      {openImageListModalImage ? <ImageListModal
        openImageModal={openImageListModalImage}
        closeImageModal={handleImageListModalCloseImage}
        selectedImage={(imageUrl) => { sessionStorage.setItem("mapImage", imageUrl) }}
        selectedImageName={() => { }}
      /> : null}
      <Modal
        open={openPreviewModal}
        onClose={handlePreviewModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={10}>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={8} className='UP_TaskSec' style={{ maxHeight: "650px", overflowY: "auto" }}>
                <Grid item xs={12} md={12} lg={12} style={{ position: "fixed", width: "50%", zIndex: "9999", backgroundColor: "white" }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    {subjectiveType === "Simple" ? "Simple Map Political" : "Compound Map Political"}
                  </Typography>
                </Grid>
                <Grid style={{ paddingTop: "50px !important" }} item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" style={{ paddingTop: "35px" }} variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Question
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                    dangerouslySetInnerHTML={{ __html: questionContent.content }}
                  >
                  </Typography>
                </Grid>
                {answerKeyContent.content ? <Grid item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Explanation
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                    dangerouslySetInnerHTML={{ __html: answerKeyContent.content }}
                  >
                  </Typography>
                </Grid> : null}
                {imagePreview || selectedImageUrl ? <Grid item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Uploaded Image
                  </Typography>
                  <Typography>
                    <img src={imagePreview ? imagePreview : selectedImageUrl} width="50%" height="200px" />
                  </Typography>
                </Grid> : null}
                {/* <Grid item xs={12} md={12} lg={12}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Map Image
                  </Typography>
                  <Typography>
                    <img src={imagePreviewMap ? imagePreviewMap : selectedImageUrlMap} width="30%" height="200px" />
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid style={{ paddingTop: "50px !important", maxHeight: "650px", overflowY: "auto" }} item xs={12} md={6} lg={6} className='UP_TaskSec'>
              {title ? <Grid container spacing={4} sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                    Number Of Marks : {title}
                  </Typography>
                  {/* <CustomTextField id="fullWidth" value={title} disabled /> */}
                </Box>
              </Grid> : null}
              {/* <Grid item xs={12} md={12} lg={12}> */}
              {mapUrl || selectedImageUrlMap || mapImage ? <Grid>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginTop: "10px" }} sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                  Map Image
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                  dangerouslySetInnerHTML={{ __html: questionContentMap.content }}
                >
                </Typography>
                <Typography>
                  <img src={mapUrl || selectedImageUrlMap || mapImage} width="50%" height="200px" />
                </Typography>
              </Grid> : null}
              <Grid container>
                <Grid item xs={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "flex-start", fontWeight: "Bold" }}>
                  S.no
                </Grid>
                <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start", fontWeight: "Bold" }}>
                  Answerkey
                </Grid>
                <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start", fontWeight: "Bold" }}>
                  Mark
                </Grid>
              </Grid>
              {
                StepMarkDetails?.map((v, index) => {
                  //console.log(v.answerKey, v.mark); // Add console log here
                  return (
                    <Grid container key={v.answerKey}> {/* Make sure to add a unique key */}
                      <Grid item xs={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        {index + 1}.
                      </Grid>
                      <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        {v.answerKey}
                      </Grid>
                      <Grid item xs={5} md={5} lg={5} sx={{ display: "flex", justifyContent: "flex-start" }}>
                        {v.mark}
                      </Grid>
                    </Grid>
                  );
                })
              }
              <Grid container style={{ marginTop: "50px" }} spacing={4} sx={{ p: 2 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                  Answer
                </Typography>
                <TextArea rows={5} id="fullWidth" value={""} disabled />
              </Grid>
              {/* </Grid> */}
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                Step Mark
              </Typography>
              <Grid container spacing={4} sx={{ p: 2 }}>
                {questionList.map(value => {
                  return (
                    <Grid item xs={12} mg={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}
                          dangerouslySetInnerHTML={{ __html: questionList.answerKey }}
                        >
                        </Typography>
                        <CustomTextField id="fullWidth" value={value.answerKey} disabled />
                      </Box>
                    </Grid>
                  )
                })
                }
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                Marks
              </Typography>
              <Grid container spacing={4} sx={{ p: 2 }}>
                {questionList.map(value => {
                  return (
                    <Grid item xs={12} mg={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}
                          dangerouslySetInnerHTML={{ __html: questionList.mark }}
                        >
                        </Typography>
                        <CustomTextField id="fullWidth" value={value.mark} disabled />
                      </Box>
                    </Grid>
                  )
                })
                }
              </Grid>
            </Grid> */}

            <Grid container>
              <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <CustomButton onClick={handlePreviewModalClose}>Exit</CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal >
      <DeleteConfirmationAlert
        open={showAlert}
        close={onHideModal}
        alert={alertMessage}
        onBackdropClickable={false}
      />
      <DeleteBlanksModal
        open={showAlertType}
        close={onHideModalType}
        alert={"You have succefully Updated Subjective Political Map Question."}
        caseStudy={true}
      />
      <DeleteConfirmationAlert
        open={showSuccessAlert}
        close={onHideSuccessModal}
        alert={successMessage}
        caseStudy={true}
        openPreview={handlePreviewModalOpen}
        // closePreview={handlePreviewModalClose}
        isEditQuestion={handleEditQuestion}
        setNewConcept={props.setNewConcept}
        setGoToQuestionBank={props.setGoToQuestionBank}
        setPreviewFromModal={setPreviewFromModal}
        setDisableTopFields={props.setDisableTopFields}
        onBackdropClickable={true}
      />
      {errorModalDetails.open ? <DeleteConfirmationAlert
        open={errorModalDetails.open}
        close={closeErrorModal}
        alert={errorModalDetails.message}
      /> : null}
    </>
  )
}

export default SubjectivePoliticalMap;
