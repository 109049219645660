import axios from "axios";
import { isNOTNullOrUndefined } from "../../../../../utils/helper";

export const GetLastModifiedAt = async (setModifiedDate) => {
  let token = sessionStorage.getItem("token");
  let result = await axios
    .get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((resp) => {
      setModifiedDate(resp.data);
      return resp.data;
    })
    // .then((json) =>
    //  console.log(json)
    //  )
    .catch((err) => console.log(err));
};

export const bluePrintAcademicYear = async () => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const uploadFilePath = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CERTIFICATION_SERVICE&subFolders=BLUE_PRINT`;

  const res = await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data || [];
};

export const createUploadAssessment = async (data) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/upload`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(url, data, options);
    return res?.data || [];
  } catch (error) {
    return error.response.data;
  }
};

export const fetchUploadAssessmentForListView = async (
  pageNumber,
  pageSize,
  boardId,
  gradeId,
  subjectId,
  // subTopicId,
  search
) => {
  const token = sessionStorage.getItem("token");
  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/content/assessment/paper/upload/page?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=true&sortBy=createdAt${
    isNOTNullOrUndefined(boardId) ? `&boardId=${boardId}` : ""
  }${isNOTNullOrUndefined(gradeId) ? `&gradeId=${gradeId}` : ""}${
    isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}` : ""
  }${isNOTNullOrUndefined(search) ? `&search=${search}` : ""}`;
  // ${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` :'' }
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data || [];
  } catch {
    return [];
  }
};

export const toggleActiveStatus = async (isActive, id) => {
  const token = sessionStorage.getItem("token");

  let data = { mappingData: [] };
  data.id = id;
  data.active = isActive;
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/upload/toggle`;
  const res = await axios.put(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const deleteAssessment = async (id) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/upload/${id}`;

  const res = await axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const getAssessmentSingleContent = async (id) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/upload/${id}`;
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data || [];
  } catch (error) {
    return [];
  }
};

export const bluePrintsSchools = async (
  boardId,
  gradeId,
  subjectId,
  LevelId
) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/assign/institution/list?boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&levelId=${LevelId}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  // console.log("res",res)
  return res?.data?.data || [];
};

export const bluePrintsPaperList = async (
  boardId,
  gradeId,
  subjectId,
  LevelId,
  AcademicYear
) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/upload/min-details?boardId=${boardId}&gradeId=${gradeId}&subjectId=${subjectId}&blueprintLevelId=${LevelId}&academicYearId=${AcademicYear}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const paperAssignPost = async (data) => {
  const token = sessionStorage.getItem("token");
  let message = "";
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/assign/to-institutes`;
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  // console.log(response, "dataa res");
  return response?.status;
};
