import React from 'react';
import axios from 'axios';
async function GetLastModifiedAt(ModifiedDate) {
  let token = sessionStorage.getItem("token");
  
axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations/last-modified-at`,{
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
})
.then(response=>{
  ModifiedDate(response.data)
})
.catch(err=>{
  // console.log(err)
});

}


export default GetLastModifiedAt;
