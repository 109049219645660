import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../../App.css";
import TopRightPopup from "../../components/common/TopRightPopup";
import { Link, useNavigate } from "react-router-dom";
import studentFilesStyles from "./../css/studentFiles.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
// take this css copy in original file
import Pagination1 from "./pagination1";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import document_category_get from "../API_CONTROLLER/document-category-controller/document_category_get";
import student_upload_chapter_get from "../API_CONTROLLER/student-upload-content-controller/student_upload_chapter_get";
import student_content_post from "../API_CONTROLLER/student-upload-content-controller/student_content_post";
import student_file_post from "../API_CONTROLLER/student-upload-content-controller/student_file_post";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import student_contentType_getall from "../API_CONTROLLER/student-upload-content-controller/student_contentType_getall";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import dashHome from "../../../src/img/dashHome.svg";
import wordIcon from "../../../src/img/word.png";
import excelIcon from "../../../src/img/excel.png";
import pdfIcon from "../../../src/img/pdfIcon.png";
import musicIcon from "../../../src/img/music-file.png";
import videoIcon from "../../../src/img/play.png";
import pptIcon from "../../../src/img/ppticon.png";
import openInNewTabIcon from "../../../src/img/resize.png";
// input choose file label image
import Cf_img from "../../../src/img/image_icon.png";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import document_category_student_get from "../API_CONTROLLER/document-category-controller/document-category-controller-student";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import ContentViewer from '../../components/common/ContentViewer';
import { rightClickEnable, CDNBASEURL } from "../../../src/Serviceworker"

const AddStudentFiles = () => {
  const [chapters, setChapters] = useState({});
  const [show, setShow] = useState(false);
  const [docCategory, setDocCategory] = useState({});
  // const [docStudentCategory, setDocStudentCategory] = useState({})
  const [selectedItem, setSelectedItem] = useState("");
  const [updatedata, setUpdatedata] = useState({});
  const [filterval, setFilterval] = useState({});
  const [chapterContent, setChapterContent] = useState({});
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [pageDetail, setPageDetail] = useState({});
  const [chaptersList, setChaptersList] = useState({});
  const [contenttype, setContenttype] = useState({});
  const [page, setPage] = useState(1);
  const [revision, setRevision] = useState(true);
  const [studentInputs, setStudentInputs] = useState({
    active: "true",
    // chapterId: "",
    contentUrl: "",
    documentCategoryId: "",
    reviewStatus: "PENDING",
    contentType: "",
  });
  const [imgUploaderror, setImageUploadError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [row, setRow] = useState({});
  const [message, setMessage] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [active, setActive] = useState("2ndInputBox");

  //Screen responsive
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLaptopScreen = windowWidth >= 1024 && windowWidth < 1440;

  const headingStyles = {
    width: isLaptopScreen ? '200%' : '249%',
    height: isLaptopScreen ? '630px' : '630px',
  };
  // Screen Responsive Done

  const onError = (e) => {
    // console.log(e, "error in file-viewer");
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default right-click behavior
  };

  const navigate = useNavigate();
  const [checkedName, setcheckedName] = useState("");
  const typeBtnHandler = (e) => {
    if (e.target.id === "STUDY") {
      setActive("1stInputBox");
      // Coordinator_Type_Get(setCoordinatorType);
    }
    if (e.target.id === "REVISION") {
      setActive("3rdInputBox");
    }
    setStudentInputs({
      ...studentInputs,
      [e.target.name]: e.target.value,
    });
    setcheckedName(e.target.value);
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/${selectedItem?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        student_upload_chapter_get(setUpdatedata);
        hideDeleteModal();
      })
      .catch((err) => console.log(err));

    hideDeleteModal();
  };

  const handleOpen = async (item) => {
    setOpenDeleteModal(true);
    setRow(item);
    setSelectedItem(item)
    // console.log(item, "elem")
    // const response = await axios.get(
    //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/confirmation-api?id=${item?.id}&operationType=DELETE`,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // setMessage(response?.data?.data?.message);
  };

  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  const handleStudentInputs = (e) => {
    e.preventDefault();
    setStudentInputs({
      ...studentInputs,
      [e.target.name]: e.target.value,
    });
  };

  const uploadStudentContent = async (e) => {
    e.preventDefault();
    for (const input in studentInputs) {
      if (studentInputs[input] === "" || studentInputs[input] == null) {
        setIsError(true);
        setShowError("Please enter all required Values");
        return;
      }
    }
    setIsError(false);

    await student_content_post(studentInputs, () => {
      student_upload_chapter_get(setUpdatedata);
      setStudentInputs({
        active: "true",
        // chapterId: "",
        contentUrl: "",
        documentCategoryId: "",
        reviewStatus: "PENDING",
        contentType: "",
      });
    });
    setShow(true);
    // navigate("/dashboard/upload%20content");
    publishCDN();
    setcheckedName("");
    sessionStorage.removeItem("contentUrl");
  };

  /// CDN publish call
  const publishCDN = async () => {
    const token = sessionStorage.getItem("token");

    const response = await axios.post(
      `https://cors-anywhere.herokuapp.com/${CDNBASEURL}v1/api/file/entity/upload-entity-json-s3?entityName=teacherchapter-content`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //alert(response);
    // console.log("publishCDN >>> ", response);
  }

  const back = () => {
    navigate("/dashboard/Upload%20Content");
    setStudentInputs({
      ...studentInputs,
      contentUrl: null,
    });
    sessionStorage.removeItem("contentUrl");
  };
  const [fileName, setFileName] = useState("");
  const imageChange = (e) => {
    e.preventDefault();
    setFileName("");
    let file = null;
    const formData = new FormData();
    file = e.target.files[0];
    setFileName(file?.name);

    formData.append("file", file);
    student_file_post(
      setSelectedImage,
      formData,
      setStudentInputs,
      studentInputs,
      setImageUploadError
    );
  };

  const removeSelectedImage = (e) => {
    e.preventDefault();
    setStudentInputs({
      ...studentInputs,
      contentUrl: null,
    });
    sessionStorage.removeItem("contentUrl");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    // console.log("updatedata", updatedata)
    setFilterval(updatedata);
    if (updatedata && updatedata[0]?.studyDocMaterials && updatedata[0]?.studyDocMaterials[0].studentStudyContent.length && updatedata[0]?.studyDocMaterials[0].studentStudyContent[0].contentUrl) {
      openIframe(updatedata[0]?.studyDocMaterials[0].studentStudyContent[0].contentUrl);
    }
  }, [updatedata]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }, [show]);

  const [url, setUrl] = useState();
  const openIframe = (contentUrl) => {
    if (
      contentUrl.includes(".xlsx") ||
      contentUrl.includes(".pptx") ||
      contentUrl.includes(".doc") ||
      contentUrl.includes(".ppt") ||
      contentUrl.includes(".docx")
    ) {
      setUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${contentUrl}`
      );
    } else {
      setUrl(contentUrl);
    }
  };
  useEffect(() => {
    // chapters_controller_getall(setChapters);
    // document_category_get(setDocCategory);
    document_category_student_get(setDocCategory);
    student_upload_chapter_get(setUpdatedata);
    chapters_list_getall(setChaptersList);
    student_contentType_getall(setContenttype);
  }, []);
  // console.log(updatedata, "chapts")
  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <a>Content Admin</a>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Upload Content</a>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Add Student Files</a>
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                sessionStorage.removeItem("sideNavSubMenuNames");
                navigate("/dashboard/template");
              }}

            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Upload Content");
                sessionStorage.removeItem("sideNavSubMenuNames");
                navigate("/dashboard/upload%20content");
              }}
            >
              Content Admin - Upload Content
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Add Student Files
            </span>
          </div>

        </article>

        {/* upload file row start  */}
        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
            paddingBottom: "60px",
            // minWidth: '1385px'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
              marginTop: "69px",
            }}
          >
            <div className={studentFilesStyles.training_container}>
              <div className={studentFilesStyles.flex}>
                <div className={studentFilesStyles.input_element}>
                  <select
                    className={studentFilesStyles.form_input_element}
                    name="documentCategoryId"
                    id="cityName"
                    value={studentInputs.documentCategoryId}
                    onChange={handleStudentInputs}
                    placeholder=" "
                  >
                    <option className={studentFilesStyles.option}>
                      select
                    </option>
                    {docCategory.length > 0 ? (
                      docCategory.map((docCategory, index) => {
                        return (
                          <option
                            className={studentFilesStyles.option}
                            Value={docCategory.id}
                            id={docCategory.id}
                            placeholder=" "
                          >
                            {docCategory.documentType}
                          </option>
                        );
                      })
                    ) : (
                      <option className={studentFilesStyles.option} value="">
                        Select
                      </option>
                    )}
                  </select>

                  <label style={{ fontWeight: "300" }} for="cityName">
                    *Document Category
                  </label>
                </div>

                <div className={createschool.upload_logo_container}>
                  {/* <span>*Upload Files</span> */}
                  <span style={{ fontSize: "14px" }}>*Upload File</span>
                  <div className={createschool.chooseFile_btn_wrapper_ATF}>
                    <img src={Cf_img} alt="img" />
                    <label
                      htmlFor="testId"
                      className={createschool.chooseFile_btn_ATF}
                    >
                      Choose File
                    </label>
                  </div>
                  <span className={createschool.custom_file_container}>
                    <input
                      type="file"
                      name="contentUrl"
                      style={{ width: "14rem", display: "none" }}
                      id="testId"
                      // id={sessionStorage.getItem("contentUrl")}
                      // filename={sessionStorage.getItem("contentUrl")}
                      Value={fileName}
                      // onError={setIsError(true)}
                      onChange={imageChange}
                      className={createschool.custom_file_input}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />

                    {studentInputs.contentUrl ? (
                      <span>
                        {/* <img
                          src={studentInputs.contentUrl}
                          alt="Thumb"
                          width="75"
                          height="75"
                        /> */}
                        {studentInputs.contentUrl.endsWith(".jpg") ||
                          studentInputs.contentUrl.endsWith(".jpeg") ||
                          studentInputs.contentUrl.endsWith(".svg") ||
                          studentInputs.contentUrl.endsWith(".png") ? (
                          <img
                            src={studentInputs.contentUrl}
                            alt="Thumb"
                            width="75"
                            height="75"
                          />
                        ) : studentInputs.contentUrl.endsWith(".doc") ||
                          studentInputs.contentUrl.endsWith(".docx") ? (
                          <img src={wordIcon} width="34px" height="34px" />
                        ) : studentInputs.contentUrl.endsWith(".xls") ||
                          studentInputs.contentUrl.endsWith(".xlsx") ? (
                          <img src={excelIcon} width="34px" height="34px" />
                        ) : studentInputs.contentUrl.endsWith(".pdf") ? (
                          <img src={pdfIcon} width="34px" height="34px" />
                        ) : studentInputs.contentUrl.endsWith(".mp3") ||
                          studentInputs.contentUrl.endsWith(".wav") ||
                          studentInputs.contentUrl.endsWith(".aiff") ||
                          studentInputs.contentUrl.endsWith(".aif") ||
                          studentInputs.contentUrl.endsWith(".flac") ||
                          studentInputs.contentUrl.endsWith(".ogg") ||
                          studentInputs.contentUrl.endsWith(".wma") ||
                          studentInputs.contentUrl.endsWith(".aac") ? (
                          <img src={musicIcon} width="34px" height="34px" />
                        ) : studentInputs.contentUrl.endsWith(".mp4") ||
                          studentInputs.contentUrl.endsWith(".mov") ||
                          studentInputs.contentUrl.endsWith(".wmv") ||
                          studentInputs.contentUrl.endsWith(".avi") ||
                          studentInputs.contentUrl.endsWith(".flv") ||
                          studentInputs.contentUrl.endsWith(".avchd") ||
                          studentInputs.contentUrl.endsWith(".mkv") ||
                          studentInputs.contentUrl.endsWith(".webm") ? (
                          <img src={videoIcon} width="34px" height="34px" />
                        ) : studentInputs.contentUrl.endsWith(".pptx") ||
                          studentInputs.contentUrl.endsWith(".pptm") ||
                          studentInputs.contentUrl.endsWith(".ppt") ? (
                          <img src={pptIcon} width="34px" height="34px" />
                        ) : (
                          ""
                        )}
                        <span>
                          <a>
                            {studentInputs.contentUrl.substring(
                              studentInputs.contentUrl.lastIndexOf("_") + 1,
                              studentInputs.contentUrl.length
                            )}
                          </a>
                          <br></br>
                          <br></br>
                          <a
                            onClick={removeSelectedImage}
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                          >
                            Remove This Image
                          </a>
                        </span>
                      </span>
                    ) : imgUploaderror ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {imgUploaderror}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>

                {/* raio btn start */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // marginTop: "10px",
                    marginLeft: "50px",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <strong>Content Type</strong>
                  </div>
                  <div style={{ display: "flex ", marginTop: "10px" }}>
                    {contenttype && contenttype.length > 0 ? (
                      contenttype.map((res) => {
                        return (
                          <div
                            style={{ marginInline: "10px", display: "flex" }}
                            className={studentFilesStyles.radio_teacherid}
                          >
                            <input
                              type="radio"
                              name="contentType"
                              id={res.code}
                              // defaultChecked="checked"
                              className={studentFilesStyles.radio_active}
                              placeholder=" "
                              value={res.code}
                              onClick={typeBtnHandler}
                              checked={checkedName == res.code}
                            />
                            <label for="test_teacherid">{res.name}</label>
                          </div>
                        );
                      })
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
                {/* raio btn end */}

                {/* </div> */}

                {/* btn */}
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    right: "50px",
                    marginTop: "94px",
                  }}
                >
                  <p
                    style={
                      isError
                        ? {
                          display: "block",
                          color: "red",
                          margin: "10px 10px",
                        }
                        : { display: "none" }
                    }
                  >
                    {showError}
                  </p>
                  <BlueButton onClick={uploadStudentContent}>UPLOAD</BlueButton>
                </div>
              </div>
            </div>
          </div>
        </Paper>

        {/* upload file row start  */}

        {/* backbtn row start  */}
        <div
          className="teacherFilesStyles.flex"
          style={{ margin: "20px auto" }}
        >
          <div style={{ position: "absolute", right: "50px" }}>
            <WhiteAddButton onClick={back}> BACK</WhiteAddButton>
          </div>
        </div>
        {/* backbtn row start  */}

        {/* content start */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 75px",
            marginLeft: "15px",
          }}
          style={{ display: "flex" }}
        >
          <div
            style={{
              // minWidth: '1166px',
              // width: "30%",
              height: "667px",
              backgroundColor: "white",
            }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    className={studentFilesStyles.table_head}
                    align="left"
                    style={{
                      // margin: "8px 8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "30px", // set a maximum width for the cell
                    }}
                  >
                    {/* {updatedata[0]?.chapterName} */}

                    {updatedata[0]?.chapterName.length > 30 ? (
                      <Tooltip title={updatedata[0]?.chapterName} arrow>
                        {updatedata[0]?.chapterName.slice(0, 30) + "..."}
                      </Tooltip>
                    ) : (
                      updatedata[0]?.chapterName
                    )}
                    {/* {sessionStorage.getItem("chapterName")} */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      // width: "470px",
                      height: "600px",
                      position: "relative",
                      overflowY: "scroll",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        style={{ backgroundColor: "#92A2E8", }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "white" }}>
                          STUDY MATERIAL -{" "}
                          {filterval[0]?.studyDocWithCount
                            ?.match(/\d/g)
                            .toString()
                            .replaceAll(",", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {filterval[0]?.studyDocMaterials &&
                          filterval[0]?.studyDocMaterials.length > 0 ? (
                          filterval[0]?.studyDocMaterials.map((item) => {
                            return (
                              <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.documentType} -{" "}
                                    {item.studentStudyContent?.length}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {item.studentStudyContent &&
                                    item.studentStudyContent.length > 0 ? (
                                    item.studentStudyContent.map((elem) => {
                                      return (
                                        <Accordion defaultExpanded={true}>
                                          <AccordionSummary
                                            style={{
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          // expandIcon={<ExpandMoreIcon />}
                                          >
                                            <div
                                              style={{
                                                marginTop: "-13px",
                                                width: "332px",
                                                whiteSpace: "nowrap",
                                                overflow: "auto",
                                              }}
                                            >
                                              <Tooltip
                                                title={elem.contentWithIndux.length > 20 ? elem.contentWithIndux : null}
                                              >
                                                <a
                                                  // href={elem.contentUrl}
                                                  // download={elem.contentWithIndux}
                                                  onClick={() => {
                                                    openIframe(elem?.contentUrl);
                                                  }}
                                                  style={{
                                                    textDecoration: "none",
                                                    fontSize: "1vw",
                                                    color: "grey",
                                                    left: "0px",
                                                    position: "absolute",
                                                    width: "250px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    textAlign: "left",
                                                    marginLeft: "5px"

                                                  }}
                                                >
                                                  {elem.contentWithIndux.length > 20 ?
                                                    elem.contentWithIndux.slice(0, 20) + "..."
                                                    :
                                                    elem.contentWithIndux
                                                  }
                                                </a>
                                              </Tooltip>
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  right: "0",
                                                }}
                                              >
                                                <DeleteIconButton
                                                  // disabled={elem.reviewStatus === "APPROVED"}
                                                  onClick={() => {
                                                    // showDeleteModal(true);
                                                    // setSelectedItem(elem);
                                                    handleOpen(elem);
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Remove"
                                                    placement={"top"}
                                                  >
                                                    <DeleteOutlinedIcon
                                                      style={{
                                                        fontSize: "1.7vw",
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </DeleteIconButton>
                                              </span>
                                            </div>
                                          </AccordionSummary>
                                        </Accordion>
                                      );
                                    })
                                  ) : (
                                    <p>-</p>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        ) : (
                          <p>-</p>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        style={{ backgroundColor: "#92A2E8" }}
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "white" }}>
                          REVISION MATERIAL -{" "}
                          {filterval[0]?.revisionDocWithCount
                            ?.match(/\d/g)
                            .toString()
                            .replaceAll(",", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {filterval[0]?.revisionDocMaterials &&
                          filterval[0]?.revisionDocMaterials.length > 0 ? (
                          filterval[0]?.revisionDocMaterials.map((item) => {
                            return (
                              <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.documentType} -{" "}
                                    {item.studentRevisionContent?.length}{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {item.studentRevisionContent &&
                                    item.studentRevisionContent.length > 0 ? (
                                    item.studentRevisionContent.map((elem) => {
                                      return (
                                        <Accordion defaultExpanded={true}>
                                          <AccordionSummary
                                            style={{
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          // expandIcon={<ExpandMoreIcon />}
                                          >
                                            <div
                                              style={{
                                                marginTop: "-13px",
                                                width: "332px",
                                                whiteSpace: "nowrap",
                                                overflow: "auto",
                                              }}
                                            >
                                              <Tooltip
                                                title={elem.contentWithIndux.length > 20 ? elem.contentWithIndux : null}
                                              >
                                                <a
                                                  // href={elem.contentUrl}
                                                  // download={elem.contentWithIndux}
                                                  onClick={() => {
                                                    openIframe(elem?.contentUrl);
                                                  }}
                                                  style={{
                                                    textDecoration: "none",
                                                    fontSize: "1vw",
                                                    color: "grey",
                                                    left: "0px",
                                                    position: "absolute",
                                                    width: "250px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    textAlign: "left",
                                                    marginLeft: "5px"

                                                  }}
                                                >
                                                  {elem.contentWithIndux.length > 20 ?
                                                    elem.contentWithIndux.slice(0, 20) + "..."
                                                    :
                                                    elem.contentWithIndux
                                                  }
                                                </a>
                                              </Tooltip>
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  right: "0",
                                                }}
                                              >
                                                <DeleteIconButton
                                                  // disabled={elem.reviewStatus === "APPROVED"}
                                                  onClick={() => {
                                                    // showDeleteModal(true);
                                                    // setSelectedItem(elem);
                                                    handleOpen(elem);
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Remove"
                                                    placement={"top"}
                                                  >
                                                    <DeleteOutlinedIcon
                                                      style={{
                                                        fontSize: "1.7vw",
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </DeleteIconButton>
                                              </span>
                                            </div>
                                          </AccordionSummary>
                                        </Accordion>
                                      );
                                    })
                                  ) : (
                                    <p>-</p>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        ) : (
                          <p>-</p>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </TableBody>
            </Table>
          </div>
          <div>
            {url && (<ContentViewer contentstyle={{ height: "700px", width: "650px" }}
              url={url} />)}
            {/* {url && (
              url.includes(".pdf") ? (
               
                  <div style={{ height: "700px", width: "650px" }} onContextMenu={handleRightClick}>
                    <Viewer
                      fileUrl={{url}}
                      plugins={[defaultLayoutPluginInstance]}
                      defaultScale={SpecialZoomLevel.PageFit}
                    />
                  </div>
              
              ) : (
                <iframe
                  src={url}
                  style={headingStyles}
                ></iframe>
              )
            )} */}
            <Tooltip
              style={{ marginLeft: "10px" }}
              title="New Tab"
              placement="right"
              arrow
            >
              <IconButton>
                <img
                  onClick={() => window.open(url)}
                  src={openInNewTabIcon}
                  height="20px"
                  width="20px"
                />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>

        {/* content end */}
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Content"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem.contentWithIndux}
        />
        <div
          className="box animate fadeInUp one"
          style={
            show
              ? {
                color: "red",
                position: "absolute",
                right: "10px",
                top: "10px",
              }
              : { display: "none" }
          }
        >
          <TopRightPopup
            style={{ color: "red", position: "absolute", right: "10px" }}
          />
        </div>
      </div>
    </>
  );
};

export default AddStudentFiles;
