import React, { useState, useEffect, useRef } from "react";
import SelectSectionModal from "../../SelectSectionModal";
import "../../../css/switch.css";
import SideMenu from "../../SideMenu";
import GetLastModifiedAt from "../../../API/token-controller/GetLastModifiedAt";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VideoView from "./VideoView";
import PresentationView from "./PresentationView";
import ImageView from "./ImageView";
import WorkbookView from "./WorkbookView";
import StudentWorkbookView from "./StudentWorkbookView";
import RevisionView from "./RevisionView";
import DocTypeViewer from "./DocTypeViewer";
import BreadCrumbBar from "./BreadCrumbBar";
import ContentDisplayTopBar from "./ContentDisplayTopBar";
import AudioView from "./AudioView";
import {
  isNOTNullOrUndefined,
  isNullOrUndefined,
} from "../../../../utils/helper";
import { isNull } from "lodash";
import ExcelView from "./ExcelView";
import MessageModal from "./MessageModal";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from "../../../../utils/AppUtility";

import ContentViewer from "../../../../components/common/ContentViewer";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";

const wrapperStyle = {
  marginTop: "20px",
  paddingRight: "33px",
  marginLeft: "10px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
};

const ContentDisplay = ({
  studyData,
  closeModal,
  selectedChapterId,
  pageName,
  menuType,
  showSubTopic
}) => {
  // console.log(studyData, 'study data', pageName)
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [collapseId, setCollapseId] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isPlaying, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progressObj, setProgressObj] = useState({});
  const [subDurationValue, setSubDurationValue] = useState(0);
  const [teacherId, setTeacherId] = useState("");
  const [Section, setSection] = useState([]);
  const [docType, setDocType] = useState("");
  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const [buttonsActiveState, setButtonsActiveState] = useState({
    startButton: true,
    endButton: false,
    assignButton: false,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const videoRef = useRef(null);

  useEffect(async () => {
    // await GetLastModifiedAt(setModifiedDate);
    await fetchTeacherData(userName, role);
    const teacherData = await fetchTeacherData(userName, role);
    // Set the initial value of the filter
    const storedSectionId = sessionStorage.getItem("sectionId");
    if (storedSectionId) {
      setSelectedSection(storedSectionId);
    }
    fetchStartEnd();
    setTeacherDetail(teacherData);
    return () => { };
  }, []);

  useEffect(() => {
    if (Object.keys(progressObj).length !== 0) {
      if (progressObj && duration) {
        setSubDurationValue(duration - progressObj?.playedSeconds);
      }
    }
  }, [progressObj, duration]);

  const toolbarPluginInstance1 = toolbarPlugin();
  const { renderDefaultToolbar: renderDefaultToolbar1 } =
    toolbarPluginInstance1;

  const transform1 = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
  });

  const renderToolbar1 = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar1(transform1)}</Toolbar>
  );

  const defaultLayoutPluginInstance1 = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
    renderToolbar: renderToolbar1,
  });

  const checkLastIndexHandler = (menu, value) => {
    // console.log("checkLastIndexHandler >>> ", menu, ">>>", value);
    // const array = menu.split('.')
    // const lastIndex = array?.[array.length - 1]
    // return lastIndex.includes(value)
    // console.log(menu, value, menu.includes(value))
    return menu.includes(value);
  };

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTeacherId(res.data.data.teacher);
  };

  const fetchSection = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    const gradeId = sessionStorage.getItem("gradeId");
    const id = teacherId.id;
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let gradeFilter = response?.data?.data?.filter((i) => i.id === gradeId);

        setSection(gradeFilter);
        // console.log(response.data.data, 'response section')
      })
      .catch((err) => console.log(err));
  };

  const quizRelease = async () => {
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const chapterId = selectedChapterId;
    const data = {
      bordId,
      gradeId,
      schoolId,
      branchId,
      subjectId,
      subTopicId,
      sectionId,
      chapterId,
      academicYearId,
    };
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/content/quiz-release/list-to-teacher?schoolId=${schoolId}&branchId=${branchId}&boardId=${bordId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&chapterId=${selectedChapterId}&academicYearId=${academicYearId}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.data) {
          navigate("/dashboard/principal/assign quiz", {
            state: { ...data, id: selectedChapterId, from: "Teach" },
          });
        } else {
          alert("Something went wrong while releasing , try later");
          fetchStartEnd();
        }
      })
      .catch((err) => console.log(err.message, "error"));
  };

  useEffect(async () => {
    await fetchSection();
    const academic = await fetchAcademicYearData();
    setAcademicYearList(academic);

    // Set the initial value of the filter
    const storedAcademicYearId = sessionStorage.getItem("academicYearId");
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId);
    } else if (academic.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem("academicYearId", academic[0].id);
    }
  }, [teacherId.id]);

  useEffect(() => {
    if (studyData?.length) {
      const data =
        studyData[0]?.uploadContents || studyData[0]?.studentRevisionContent;
      if (!data?.length) {
        setSelectedMenu(data?.documentType);
      } else {
        setSelectedMenu(data[0]?.contentWithIndux);
        setSelectedUrl(data[0]?.contentUrl);
      }
    }
  }, [studyData]);

  const handleClick = (id, type, item, index) => {
    setCollapseId((pre) => (pre === id ? "" : id));
    if (!item.uploadContents?.length || !item.studentRevisionContent?.length) {
      setSelectedMenu(item?.documentType);
      // console.log("selected doc type >>> ", type, item?.documentType);
      setDocType(type);
    }
    setStartIndex(index);
  };

  const subMenuHandler = (item, url) => {
    if (item !== selectedMenu) {
      setPlaying(false);
      setProgressObj({});
      setDuration(0);
      setSubDurationValue(0);
    }
    setSelectedMenu(item);
    setSelectedUrl(url);
  };

  const [showMessageModal, setShowMessageModal] = useState(null);
  const onStartClick = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const id = teacherId.id;

    let data = {
      boardId: boardId,
      schoolId: schoolId,
      branchId: branchId,
      gradeId: gradeId,
      sectionId: sectionId,
      subjectId: subjectId,

      chapterId: selectedChapterId,
      academicYearId: academicYearId,
      operation: "START",
      teacherId: id,
    };
    // console.log("start2");
    if (isNOTNullOrUndefined(subTopicId) && showSubTopic) {
      data.subTopicId = subTopicId;
    }
    try {
      await axios
        .post(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/start`,
          data,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res, 'res dataa')
          setShowMessageModal("Start");
        })
        .catch((error) => {
          // console.log(error.response, 'errorr in catch inside')
          if (error.response?.data?.errorCode === 404) {
           // console.log("error.response?.data?.message ", error.response?.data?.message)
            alert(error.response?.data?.message);
            fetchStartEnd();
          } else {
            alert("Something went wrong try later");
            fetchStartEnd();
          }
        });
    } catch (error) {
      // console.error(error, "errorrrrd");
    }
    // setShowSectionModal(true)
  };

  const onEndClick = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const id = teacherId.id;

    let data = {
      boardId: boardId,
      schoolId: schoolId,
      branchId: branchId,
      gradeId: gradeId,
      sectionId: sectionId,
      subjectId: subjectId,

      chapterId: selectedChapterId,
      academicYearId: academicYearId,
      operation: "END",
      teacherId: id,
    };

    if (isNOTNullOrUndefined(subTopicId) && showSubTopic) {
      data.subTopicId = subTopicId;
    }

    // console.log("data",data)
    await axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/end/or/assign-quiz`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowMessageModal("End");
      })
      .catch((err) => {
        if (err.response?.data?.errorCode === 404) {
          alert(err.response?.data?.message);
          // alert("Something went wrong try later");
        } else {
          alert("Something went wrong try later");
        }
        fetchStartEnd();
      });
  };

  const onMesageModalClose = () => {
    setShowMessageModal(null);
    fetchStartEnd();
  };

  const onAssignQuizClick = () => {
    quizRelease();

    // SS for Highlighthing the Sidenav Menu Name
    sessionStorage.setItem("sideNavMainMenuNames", "Assign Quiz");
  };

  useEffect(() => {
    if (isNullOrUndefined(startDate) && isNullOrUndefined(endDate)) {
      setButtonsActiveState((prev) => ({
        endButton: false,
        startButton: true,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }

    if (startDate && isNullOrUndefined(endDate)) {
      setButtonsActiveState((prev) => ({
        endButton: true,
        startButton: false,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }
    if (endDate && isNullOrUndefined(startDate)) {
      setButtonsActiveState((prev) => ({
        startButton: true,
        endButton: false,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }
    if (isNOTNullOrUndefined(endDate) && isNOTNullOrUndefined(startDate)) {
      setButtonsActiveState({
        startButton: false,
        endButton: false,
        assignButton: true,
      });
    }
  }, [startDate, endDate]);

  const fetchStartEnd = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/min/details?schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&chapterId=${selectedChapterId}&academicYearId=${academicYearId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setStartDate(response.data.data.startDate);
        setEndDate(response.data.data.endDate);
      })
      .catch((err) => console.log(err));
  };

  const handleSectionSelect = (e) => {
    setSelectedSection(e.target.value);
    sessionStorage.setItem("sectionId", e.target.value);
    fetchStartEnd();
  };

  const nameHandler = (name) => {
    // console.log("nameHandler >>> ", name, selectedMenu);
    const data = name?.split("/")?.[name.split("/")?.length - 1]?.split(".");
    // return `${data[0]}`;
    var str = data[0];
    // console.log(str.split(' ').map(word => word.split('-').map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)).join('-')).join(' '))
    return str.split(' ').map(word => word.split('-').map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)).join('-')).join(' ');
    // return name;
  };

  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  useEffect(() => {
    const selectedGrade = sessionStorage.getItem("gradeId");
    if (isNOTNullOrUndefined(selectedGrade) && Section?.length) {
      const filtered = Section?.find((i) => i.id === selectedGrade)?.sections;
      setSectionFilteredList(filtered);
    }
  }, [Section]);

  const subMenuHandlerRevision = (item, url) => {
    if (item !== selectedMenu) {
      setPlaying(false);
      setProgressObj({});
      setDuration(0);
      setSubDurationValue(0);
    }
    setSelectedMenu(item);
    setSelectedUrl(url);
  };

  const handleClickRevision = (id, item, index) => {
    setCollapseId((pre) => (pre === id ? "" : id));
    if (!item?.studentRevisionContent?.length) {
      setSelectedMenu(item?.documentType);
    }
    setStartIndex(index);
  };

  const handleFullscreen = () => {
    const element = document.querySelector("iframe");
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
  };

  const [presentationPageNumber, setPresentationPageNumber] = useState(1);
  const [presentationNumPages, setPresentationNumPages] = useState(null);

  const changePresentationPage = (offset) => {
    setPresentationPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPresentationPage = () => {
    changePresentationPage(-1);
  };
  const nextPresentationPage = () => {
    changePresentationPage(1);
  };

  const purchasedFeature = localStorage.getItem("purchasedFeatures");
  const purchasedFeatures = JSON.parse(purchasedFeature);

  const wb_download = purchasedFeatures.find(
    (item) => item.name === "wb_download"
  );
  const ws_download = purchasedFeatures.find(
    (item) => item.name === "ws_download"
  );

  const isDownload =
    wb_download?.toggleStatus === true || ws_download?.toggleStatus === true;

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
      <SideMenu
        menuType={menuType}
        menu={studyData?.length && studyData}
        menuClickHandler={handleClick}
        subMenuHandler={subMenuHandler}
        menuRevisionClickHandler={handleClickRevision}
        subMenuHandlerRev={subMenuHandlerRevision}
        collapseId={collapseId}
        startIndex={startIndex}
        selectedUrl={selectedUrl}
      />
      <div style={{ width: "100%", overflow: "auto", scrollbarWidth: "thin" }}>
        <BreadCrumbBar
          modifiedDate={modifiedDate}
          closeContentDisplay={() => closeModal()}
          pageName={pageName}
        />
        <div style={wrapperStyle}>
          <ContentDisplayTopBar
            Section={sectionFilteredList}
            selectedAcademicYear={selectedAcademicYear}
            selectedChapterId={selectedChapterId}
            academicYearList={academicYearList}
            selectedSection={selectedSection}
            onAcademicYearSelection={(e) =>
              setSelectedAcademicYear(e.target.value)
            }
            onStartClick={onStartClick}
            onEndClick={onEndClick}
            onAssignQuizClick={onAssignQuizClick}
            onSelectSection={handleSectionSelect}
            buttonsActiveState={buttonsActiveState}
            startDate={startDate}
            endDate={endDate}
            pageName={pageName}
          />


          {selectedMenu === "Workbook" ? (
            ""
          ) : selectedMenu === "WB_Student" ? (
            ""
          ) : selectedMenu === "VIDEO" ||
            checkLastIndexHandler(selectedMenu, "mp4") ||
            checkLastIndexHandler(selectedMenu, "webm") ||
            checkLastIndexHandler(selectedMenu, "ogv") ||
            checkLastIndexHandler(selectedMenu, "mov") ||
            checkLastIndexHandler(selectedMenu, "avi") ? (
            <VideoView
              selectedUrl={selectedUrl}
              setDuration={setDuration}
              isPlaying={isPlaying}
              isDownload={isDownload}
              setProgressObj={setProgressObj}
              selectedMenu={selectedMenu}
              setPlaying={setPlaying}
            />
          ) : selectedMenu === "PRESENTATION" ||
            checkLastIndexHandler(selectedMenu, "pptx") ||
            checkLastIndexHandler(selectedMenu, "ppt") ||
            checkLastIndexHandler(selectedMenu, "doc") ||
            checkLastIndexHandler(selectedMenu, "docx") ? (
            <PresentationView
              selectedMenu={selectedMenu}
              selectedUrl={selectedUrl}
            />
          ) : selectedMenu === "Exel" ||
            checkLastIndexHandler(selectedMenu, "xls") ||
            checkLastIndexHandler(selectedMenu, "xlsx") ? (
            <ExcelView
              selectedUrl={selectedUrl}
              selectedMenu={selectedMenu}
              setPresentationPageNumber={setPresentationPageNumber}
              presentationPageNumber={presentationPageNumber}
              previousPresentationPage={previousPresentationPage}
              nextPresentationPage={nextPresentationPage}
              presentationNumPages={presentationNumPages}
            />
          ) : selectedMenu === "Audio" ||
            checkLastIndexHandler(selectedMenu, "mp3") ||
            checkLastIndexHandler(selectedMenu, "wav") ||
            checkLastIndexHandler(selectedMenu, "wma") ||
            checkLastIndexHandler(selectedMenu, "aac") ||
            checkLastIndexHandler(selectedMenu, "ogg") ? (
            <AudioView selectedUrl={selectedUrl} />
          ) : selectedMenu === "REVISION" ? (
            <RevisionView selectedUrl={selectedUrl} />
          ) : // checkLastIndexHandler(selectedMenu, 'pdf')||
            checkLastIndexHandler(selectedMenu, "html") ||
              checkLastIndexHandler(selectedMenu, "jpg") ||
              checkLastIndexHandler(selectedMenu, "jpeg") ||
              checkLastIndexHandler(selectedMenu, "png") ||
              checkLastIndexHandler(selectedMenu, "svg") ||
              checkLastIndexHandler(selectedMenu, "gif") ||
              checkLastIndexHandler(selectedMenu, "cur") ? (
              <ImageView selectedMenu={selectedMenu} selectedUrl={selectedUrl} />
            ) : checkLastIndexHandler(selectedMenu, "pdf") ? (
              <div
                style={{
                  display: "flex",
                  background: "white",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px",
                }}
              >

                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                    fontSize: "13px",
                  }}
                >
                  {nameHandler(selectedMenu)}
                </div>

                {
                  //( docType === 'Work Sheet' && localStorage.getItem("wsDownload") === true) || (docType === 'Learning Resource' && localStorage.getItem("wbDownload") === true) ? (
                  (docType === 'Work Sheet' && JSON.parse(localStorage.getItem("wsDownload"))) || (docType === 'Learning Resource' && JSON.parse(localStorage.getItem("wbDownload"))) ? (

                    <div style={{ height: "600px", width: "100%" }}
                      onContextMenu={handleRightClick}>
                      <Viewer
                        fileUrl={selectedUrl}
                        plugins={[defaultLayoutPluginInstance1]}
                        defaultScale={SpecialZoomLevel.PageWidth}
                      />
                    </div>

                  ) :

                    (docType === 'Doc_img' && !selectedUrl.includes(".pdf")) ?
                      <>

                        {

                          < iframe
                            title={nameHandler(selectedUrl)}
                            src={selectedUrl}
                            target="_blank"
                            style={{ height: "600px", width: "100%" }}
                          />
                        }
                      </> :

                      <ContentViewer contentstyle={{ height: "600px", width: "100%" }}
                        url={selectedUrl} />
                }

              </div>
            ) :
              // this div not implemented bcz no one is matching just copying the above code
              null
          }
        </div>
      </div>
      {showSectionModal && (
        <SelectSectionModal
          open={showSectionModal}
          close={() => setShowSectionModal(false)}
          sectionList={sectionList}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
      )}
      {isNOTNullOrUndefined(showMessageModal) ? (
        <MessageModal
          message={`You have successfully added ${showMessageModal} Date `}
          open={isNOTNullOrUndefined(showMessageModal) ? true : false}
          close={onMesageModalClose}
        />
      ) : null}
    </div>
  );
};
export default ContentDisplay;