import React, { useEffect, useState } from "react";
import DashboardLayer from "../../../../components/DashboardLayer/DashboardLayer";
import "./teacher_dashboard.css";
import TeacherCardV1 from "./TeacherCardV1";
import TeacherCardV2 from "./TeacherCardV2";
import HorizontalScrollContainer from "../../../../components/HorizontalScrollContainer/HorizontalScrollContainer";
import TeacherGrades from "../../../img/teachergrades.png";
import TotalStudents from "../../../img/totalstudents.png";
import SelectSearchDropdown from "./Components/SelectSearchDropdown";
import HorizontallyScrollableContainerV3 from "../../../../components/HorizontallyScrollableContainerV3/HorizontallyScrollableContainerV3";
import {
  isNOTNullOrUndefined,
  extractTextAfterExclamation,
  extractTextIncludingExclamation,
} from "../../../../utils/helper";
import {
  fetchTeacherData,
  fetchGradesStudentDetails,
  fetchAllGrades,
  fetchQuizPerformance,
  fetchDashboardChapterWisePerformance,
} from "../Apis/TeacherDashboard";
import SpinLoader from "../../../../components/SpinLoader/SpinLoader";
import DashboardMessageModal from "../../../../components/DashboardMessageModal/DashboardMessageModal";
import DashboardGradeModal from "../../../../components/DashboardGradeModal/DashboardGradeModal";
import { getDataStructureFormatted } from "./Components/DataHelper";
import { useSelector } from "react-redux";
import DashboardTableData from "../../../../components/DashboardTableData";
import DashboardProgressData from "../../../../components/DashboardProgressData";

const TeacherDashboardLatest = () => {
  const color1 = ["#FA9825", "#EC5683"];
  const color2 = ["#4FD8BF", "#384CE0"];

  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [teacherDetailsFetched, setTeacherDetailsFetched] = useState(false);
  const [chapterPerformanceData, setChapterPerformanceData] = useState([]);
  const [quizPerformanceData, setQuizPerformanceData] = useState([]);
  const [gradeStudentCount, setgradeStudentCount] = useState([]);
  const [gradeDetails, setGradeDetails] = useState([]);
  const [gradeList, setGradeList] = useState([]);

  const [subList, setSubList] = useState([]);
  const [subId, setSubId] = useState('');

  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [isTriggerProgressApi, setIsTriggerProgressApi] = useState(false);

  const [message, setMessage] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showEmberCategoryMessage, setShowEmberCategoryMessage] =
    useState(false);

  const [showGradeModal, setShowGradeModal] = useState(false);
  const [showEmberGradeMessage, setShowEmberGradeMessage] = useState(false);

  const [quizValue, setQuizValue] = useState([]);
  const [practiceValue, setPracticeValue] = useState([]);

  const { is_expanded } = useSelector((state) => state.navbar);

  const fetchAndSetTeacherData = async () => {
    const response = await fetchTeacherData(userName, role);
    sessionStorage.setItem("teacherId", response?.id);
    sessionStorage.setItem("schoolId", response?.school);
    sessionStorage.setItem("branchId", response?.branch);
    sessionStorage.setItem("sectionId", response?.sectionId);
    sessionStorage.setItem("boardId", response?.boardId);
    setTeacherDetailsFetched(true);
  };

  useEffect(() => {
    const teacherId = sessionStorage.getItem("teacherId");
    if (isNOTNullOrUndefined(teacherId)) {
      setTeacherDetailsFetched(true);
    } else {
      fetchAndSetTeacherData();
    }
  }, []);

  const fetchGradeDetails = async () => {
    setLoading(true);
    const response = await fetchGradesStudentDetails();
    setgradeStudentCount(response);
    let arr = [];
    response?.gradeDetails?.forEach((item) => {
      item?.sectionDetails?.forEach((elem) => {
        let objItem = {
          grade: item.grade,
          gradeId: item.gradeId,
          section: elem.section,
          sectionId: elem.sectionId,
          totalStudentsUnderSection: elem.totalStudentsUnderSection,
        };
        arr.push(objItem);
      });
    });
    setGradeDetails(arr);
    setLoading(false);
  };

  const fetchGradeList = async () => {
    setLoading(true);
    const response = await fetchAllGrades();
    setGradeList([]);
    const GradeOptions = response?.map((item) => {
      return {
        value: item.id,
        label: item.grade,
      };
    });
    setGradeList(GradeOptions);
    setSelectedGrade(response[0]?.id);
    setLoading(false);
  };

  const fetchQuizPerformanceData = async () => {
    setLoading(true);
    setQuizPerformanceData([]);
    const response = await fetchQuizPerformance(selectedGrade);
    const cdata = response;
    var formatted = getDataStructureFormatted(cdata);
    formatted = formatted?.filter(v => v.skilledSubject === true) || [];
    setQuizPerformanceData(formatted);
    // setSelectedSubject(formatted[0]);
    setLoading(false);
  };

  const fetchChapterWiseData = async () => {
    // console.log("selectedSubject >>> ", selectedSubject);
    setChapterPerformanceData([]);
    setTimeout(async () => {
      const response = await fetchDashboardChapterWisePerformance(
        selectedSubject?.gradeId,
        selectedSubject?.subjectId,
        selectedSubject?.sectionId,
        selectedSubject?.subtopicId
      );
      let arr = [];
      response?.chapters?.forEach((item) => {
        let objItem = {
          id: item?.chapterId,
          scorePercentage: item?.quizAverage,
          averagePercentage: item?.globalQuizAverage,
          chapterName: item?.chapter,
          emberStudents: item?.emberStudents,
          studentPercentageInEmber: item?.studentPercentageInEmber,
        };
        arr.push(objItem);
      });
      setChapterPerformanceData(arr);
    }, 1000);

  };

  useEffect(() => {
    if (teacherDetailsFetched) {
      fetchGradeDetails();
      fetchGradeList();
      // fetchQuizPerformanceData();
    }
  }, [teacherDetailsFetched]);

  // useEffect(() => {
  //   console.log(selectedGrade, selectedSubject, "useEffect")
  //   if (selectedGrade) {
  //     // console.log("call here")
  //     // fetchQuizPerformanceData();
  //   }
  // }, [selectedGrade,selectedSubject]);

  useEffect(() => {
    if (selectedSubject) {
      let arr = [
        selectedSubject?.unitGlobalQuizAttemptRate || 0,
        selectedSubject?.unitQuizAttemptRate || 0,
      ];
      let arr1 = [
        selectedSubject?.practiceGlobalQuizAttemptRate || 0,
        selectedSubject?.practiceQuizAttemptRate || 0,
      ];
      setQuizValue(arr);
      setPracticeValue(arr1);
    }
  }, [selectedSubject]);

  useEffect(() => {
    // console.log("yeah", selectedSubject)
    if (selectedSubject) {
      fetchChapterWiseData();
    }
  }, [selectedSubject]);


  const isOnTriggerProgressData = () => {
    setIsTriggerProgressApi(!isTriggerProgressApi)
  }

  // if (loading) return <SpinLoader />;

  const handleSelect = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedGrade(selectedOption.target.value);
    } else {
      setSelectedGrade(null);
    }
  };

  const isTableSubList = (data) => {
    setSubList(data)
  }

  const isTableSubId = (data) => {
    setSubId(data)
  }

  return (
    <DashboardLayer>
      <section
        className="TeacherDashWrapper"
        style={{ width: is_expanded ? "80vw" : "90vw" }}
      >
        <div className="teacher-card-section">
          <div className="teacher-count-card">
            <div className="count-card">
              <TeacherCardV1
                img={TeacherGrades}
                label={"Number of grades handling"}
                count={gradeStudentCount?.totalGradesTR}
                onLabelClick={(e) => {
                  e.stopPropagation();
                  setShowGradeModal(true);
                }}
              />
            </div>
            <div className="count-card">
              <TeacherCardV1
                img={TotalStudents}
                label={"Total number of students"}
                count={gradeStudentCount?.totalStudentsTR}
              />
            </div>
          </div>
          <div className="teacher-card">
            <TeacherCardV2
              color={color1}
              heading={"Unit Quiz Attempts"}
              boxClass={"box orange"}
              progressValue={quizValue}
              title={extractTextIncludingExclamation(
                selectedSubject?.unitAttemptRateMessage
              )}
              message={extractTextAfterExclamation(
                selectedSubject?.unitAttemptRateMessage
              )}
            />
          </div>

          <div className="teacher-card">
            <TeacherCardV2
              color={color2}
              heading={"Practice Quiz Attempts"}
              boxClass={"box blue"}
              progressValue={practiceValue}
              title={extractTextIncludingExclamation(
                selectedSubject?.practiceAttemptRateMessage
              )}
              message={extractTextAfterExclamation(
                selectedSubject?.practiceAttemptRateMessage
              )}
            />
          </div>
        </div>
        <div className="teacher-bargraph-wrapper">
          <div style={{ marginBottom: "40px" }}>
            <DashboardTableData
              userType={"Teacher"}
              handleSelect={handleSelect}
              gradeList={gradeList}
              selectedGrade={selectedGrade}
              isTableSubList={isTableSubList}
              isTableSubId={isTableSubId}
              isOnTriggerProgressData={isOnTriggerProgressData}
              VsetSelectedSubject={setSelectedSubject}
            />
          </div>
          {/* <div className="progress-section" style={{ marginBottom: "40px" }}>
            <h2 className="dashboard-heading" style={{ marginBottom: "40px" }}>Progress</h2>
            <DashboardProgressData
              gradeList={gradeList}
              selectedGrade={selectedGrade}
              subList={subList}
              subId={subId}
              isTriggerProgressApi={isTriggerProgressApi}
            />
          </div>
          <div className="head-wrapper">
            <h2 className="dashboard-heading">Quiz Performance</h2>
            <div>
              <SelectSearchDropdown
                handleSelect={handleSelect}
                grades={gradeList}
                selectedValue={selectedGrade}
              />
            </div>
          </div> */}

          {/* <div style={{ width: "100%", marginTop: "10px" }}>
            <HorizontalScrollContainer>
              {quizPerformanceData?.map((item) => {
                return (
                  <DashboardBarChartCard
                    key={item.id}
                    title={`${item.grade} ${item.section}`}
                    section={item.section}
                    subtitle={item.subject}
                    subtopic={item.subtopic}
                    first_label={"Average"}
                    second_label={"Global Score"}
                    averageMessage={item?.averageMessage || ""}
                    first_percent={item?.quizAverageScorePercentage}
                    second_percent={item?.globalQuizAverageScorePercentage}
                    selected={selectedSubject?.id === item?.id}
                    onCardClick={() => setSelectedSubject(item)}
                    onLabelClick={(e) => {
                      e.stopPropagation();
                      setShowMessageModal(true);
                      setMessage(item?.averageMessage);
                    }}
                  />
                );
              })}
            </HorizontalScrollContainer>
          </div> */}

          {/* <div style={{ width: "100%", marginTop: "5px" }}>
            <HorizontallyScrollableContainerV3
              displayEmberCategoryMessage={() => {
                setShowEmberCategoryMessage(true);
                setShowMessageModal(true);
              }}
              onLabelClick={(e) => {
                e.stopPropagation();
              }}
              data={chapterPerformanceData}
            ></HorizontallyScrollableContainerV3>
          </div> */}

        </div>
      </section>
      <DashboardMessageModal
        showMessageModal={showMessageModal}
        title={extractTextIncludingExclamation(message)}
        content={extractTextAfterExclamation(message)}
        onMessageModalClose={() => {
          setShowMessageModal(false);
          setShowEmberCategoryMessage(false);
          setMessage("");
        }}
        isEmberCategoryMessage={showEmberCategoryMessage}
      />

      <DashboardGradeModal
        showMessageModal={showGradeModal}
        data={gradeDetails}
        onMessageModalClose={() => {
          setShowGradeModal(false);
          setShowEmberGradeMessage(false);
          setMessage("");
        }}
        isEmberCategoryMessage={showEmberGradeMessage}
      />
    </DashboardLayer>
  );
};

export default TeacherDashboardLatest;