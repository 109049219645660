import React, { useEffect, useState } from "react";
import moment from "moment";

//Images Used
import quizLockIcon from "../../../../../src/img/SaLock.svg";
import quizLockOpenIcon from "../../../../../src/img/SaLockOpen.svg";
import quizInfoIcon from "../../../../../src/img/quiz-info-3.svg";

//Css
import "../../../css/Student/FormalAssessmentQuiz/Faq_Quiz_Listing.css";
import Student_Enrich_Quiz_Attend from "../Student_Enrich_Quiz_Attend";
import Student_Enrich_Quiz_Analysis_Modal from "../Student_Enrich_Quiz_Analysis_Modal";
import Quiz_Common_component from "../Quiz_Common_component"
import { Box, Divider, Modal, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import QuizNewComponent from "../QuizNewComponent";
import QuizNewResumeComponent from "../QuizNewResumeComponent";
import Student_Formal_Assesment_Analysis_Modal2 from "../Student_Formal_Assesment_Analysis_Modal2";
import axios from "axios";
import QuizOptimizedComponent from "../QuizOptimizedComponent";
import QuizResumeOptimizedComponent from "../QuizResumeOptimizedComponent";
import mixpanel from "mixpanel-browser";

function Faq_Quiz_Listing({
  viewQuizData,
  selectedSubject,
  academicYearList,
  schoolId,
  branchId,
  studentId,
  setLoadQuizData,
  fetchViewQuiz,
  loadQuizData,
  fetchQuizTypes
}) {

  const userName = localStorage.getItem("userName");
  const user_id = localStorage.getItem('id');
  // console.log("faq init page", viewQuizData, loadQuizData)
  let yetToStart = viewQuizData.filter((quiz) => quiz.examinationStatus === "NOT_YET_STARTED");
  // console.log('startUnit',yetToStart);
  const quizzesYetToStart = yetToStart.map((item) => ({
    "quizId": item?.quizId,
    "quizName": item?.quizName,
    "examinationStatus": item?.examinationStatus,
    "startDate": item?.startDate,
    "startTime": item?.startTime,
    "endDate": item?.endDate,
    "endTime": item?.endTime
  }));
  useEffect(() => {
    if (yetToStart.length > 0) {
      mixpanel.track('FAQ_Not_Yet_Started', {
        "user_id": user_id,
        "username": userName,
        "quizzes": quizzesYetToStart
      })
    }
  }, [viewQuizData])
  sessionStorage.setItem('selectedSub', selectedSubject)
  sessionStorage.setItem('academicYear', academicYearList)
  const token = sessionStorage.getItem("token");
  const [internetTime, setInternetTime] = useState(new Date());
  const [showQuizAttend, setShowQuizAttend] = useState(false);
  const [quizData, setQuizData] = useState();
  const [quizExaminationId, setQuizExaminationId] = useState("");
  const [resumeBtnClicked, setResumeBtnClicked] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [quizListData, setQuizListData] = useState();

  const inboxQuizType = sessionStorage.getItem('InboxQuizType');
  const InboxSubjectName = sessionStorage.getItem('InboxSubjectName2');
  const InboxSub = sessionStorage.getItem('InboxSubject2');
  const [quizTypes, setQuizTypes] = useState([]);
  const [selectedQuizTypes, setSelectedQuizTypes] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [resumeOpen, setResumeOpen] = React.useState(false)
  const [quizChange, setQuizChange] = useState('')
  const handleResume = () => setResumeOpen(true)
  const handleResumeClose = () => setResumeOpen(false)
  const [newQuizData, setNewQuizData] = useState('')
  const [resumeQuestions, setResumeQuestions] = useState([])

  const [isExamOver, setIsExamOver] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);


  const fetchViewQuiz2 = async (studentId, schoolId, branchId) => {
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const studentIdd = sessionStorage.getItem("studentId");
    const schoolId1 = sessionStorage.getItem("schoolId");
    var branchId1 = sessionStorage.getItem("branchId");
    // console.log("schoolId1", schoolId1, "----", schoolId, `v1/api/student/examination/listing?studentId=${studentIdd}&schoolId=${schoolId || schoolId1}&branchId=${branchId || branchId1}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&quizTypeId=${selectedQuizTypes}&academicYearId=${academicYearList}&reviewStatus=APPROVED`)
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/listing?studentId=${studentIdd}&schoolId=${schoolId || schoolId1}&branchId=${branchId || branchId1}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${InboxSub}&quizTypeId=${selectedQuizTypes}&academicYearId=${academicYearList}&reviewStatus=APPROVED`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(res.data.data, "student formal")
    setQuizListData(res.data.data || [])
  };

  setLoadQuizData(true)
  useEffect(() => {
    if (InboxSub && inboxQuizType) {
      const fethQuizTypeData = async () => {
        const quizTypesList = await fetchQuizTypes();
        setQuizTypes(quizTypesList);
        const matchedQuizType = quizTypesList.find(quizType => quizType.description === inboxQuizType);
        setSelectedQuizTypes(matchedQuizType.id);
      }
      fethQuizTypeData();
      fetchViewQuiz2();
    } else {
      if (loadQuizData) {
        fetchViewQuiz();
      }
      setQuizListData(viewQuizData);
    }

    // return () => {
    //   sessionStorage.removeItem('InboxSubjectName');
    //   sessionStorage.removeItem('InboxSubject');
    //   sessionStorage.removeItem('InboxQuizType');
    // };

  }, [loadQuizData, viewQuizData, InboxSub, inboxQuizType, selectedQuizTypes]);

  //-------| Fetching The Internet Time Api |-------//
  async function fetchInternetTime() {
    // try {
    //   const response = await fetch(
    //     // "https://api.timezonedb.com/v2.1/get-time-zone?key=LEPDE3WSUFYM&format=json&by=zone&zone=Asia/Kolkata"
    //     "https://worldtimeapi.org/api/timezone/Asia/Kolkata"
    //   );
    //   const data = await response.json();
    //   const currentTime = new Date(data.utc_datetime);
    //   setCurrentDate(currentTime);
    //   return currentTime;
    // } catch (error) {
    //   console.error(error);
    // }
  }

  useEffect(() => {


    var Vitemload = JSON.parse(sessionStorage.getItem("itemload"));
   // console.log("useffect of faq.j", Vitemload);

    if (Vitemload) {
     // console.log("setting true ", resumeBtnClicked)
      setResumeBtnClicked(true);
      // setShowQuizAttend(true);
      setQuizData(Vitemload);
      setQuizExaminationId(Vitemload.examinationId);
    }

    const intervalId = setInterval(async () => {
      const currentTime = await fetchInternetTime();
      setInternetTime(currentTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {

    const fetchServerTime = async () => {
      const serverDateResponse = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/configurations/server-datetime`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      const serverTime = serverDateResponse?.data
      // console.log('server', serverTime);
      setCurrentDate(serverTime);
    }
    fetchServerTime();

  }, [])
  // useEffect(() => {
  //  // console.log("what to do")
  // }, [showQuizAttend])
  const [currentDate, setCurrentDate] = useState();
  // const [currentDate, setCurrentDate] = useState(internetTime);

  const handleAnalysisModalClose = () => {
    setOpenAnalysisModal(false);
  };

  // console.log(showQuizAttend, "showQuizAttend");

  const handleBack = () => {
    // console.log('navigated')
    setOpen(false)
  }
  // console.log('quizdatainstenrichquiz', quizData)
  // console.log('quizdat', quizData)
  sessionStorage.setItem('quizData', JSON.stringify([quizData]))

  const unitQuizStart = (item) => {
    // console.log('startttttt',item);

    mixpanel.track('FAQ_Instruction_Start', {
      "user_id": user_id,
      "username": userName,
      "quizId": item?.quizId,
      "quizName": item?.quizName,
      "startDate": item?.startDate,
      "startTime": item?.startTime,
      "endDate": item?.endDate,
      "endTime": item?.endTime,
      "totalDuration": item?.totalDuration,
      "releasedId": item?.releasedId,
      "examinationStatus": item?.examinationStatus
    })

  }

  const quizStartCapture = () => {
    var ss = JSON.parse(sessionStorage.getItem("itemload1"));
    sessionStorage.setItem("itemload", JSON.stringify(ss))
    // console.log('wewewew',quizData);
    mixpanel.track('FAQ_Start', {
      "user_id": user_id,
      "username": userName,
      "quizId": quizData?.quizId,
      "quizName": quizData?.quizName,
      "startDate": quizData?.startDate,
      "startTime": quizData?.startTime,
      "endDate": quizData?.endDate,
      "endTime": quizData?.endTime,
      "totalDuration": quizData?.totalDuration,
      "releasedId": quizData?.releasedId,
      "examinationStatus": quizData?.examinationStatus
    })
  }

  const checkExamOver = async (item) => {
    // console.log('dsds',item);

    let timeLeft;
    const endTime = item?.endTime
    const serverDateResponse = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/configurations/server-datetime`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    const serverTime = serverDateResponse?.data
    // console.log('server', serverTime);
    const quizEndTime = moment(endTime, 'hh:mm A');
    timeLeft = quizEndTime.diff(serverTime, 'minutes') + 1
    // console.log('timeleft',timeLeft);
    setIsBtnDisabled(false);
    if (timeLeft > 0) {
      setIsExamOver(false);
      setOpen(true);
    } else {
      setIsExamOver(true)
      setOpen(false)
    }
  }
  const handleExit = () => {
    setIsExamOver(false)
  }


  return (
    <>
      <>
        <div className="Quiz_Cards_Wrapper">
          {/* ---------------------------| Quiz Card-1 |--------------------------- */}
          {/* {viewQuizData?.map((item) => { */}
          {quizListData?.map((item) => {
            const startDate = moment(item.startDate, "DD/MM/YYYY").toDate();
            const endDate = moment(item.endDate, "DD/MM/YYYY").toDate();
            const startTime = moment(item.startTime, "hh:mm A").toDate();
            const endTime = moment(item.endTime, "hh:mm A").toDate();

            // ------------Testing manual time--------------------
            // const startDate = moment(
            //   "04 / 08 / 2023",
            //   "DD/MM/YYYY"
            // ).toDate();
            // const endDate = moment("04 / 08 / 2023", "DD/MM/YYYY").toDate();
            // const startTime = moment("12:42 AM", "hh:mm A").toDate();
            // const endTime = moment("11:46 PM", "hh:mm A").toDate();

            // console.log('currentdate', currentDate);
            const currentDateTime = currentDate
              ? new Date(
                currentDate
                // currentDate.getFullYear(),
                // currentDate.getMonth(),
                // currentDate.getDate(),
                // currentDate.getHours(),
                // currentDate.getMinutes(),
                // currentDate.getSeconds()
              )
              : null;

            const startDateTime = new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              startTime.getHours(),
              startTime.getMinutes(),
              startTime.getSeconds()
            );
            const endDateTime = new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate(),
              endTime.getHours(),
              endTime.getMinutes(),
              endTime.getSeconds()
            );

            return (
              <div
                id={`az-card-parent`}
                className="Quiz_Card_1">
                <div
                  className={
                    item.examinationStatus == "NOT_RELEASED" ?
                      "Card_Mask" :
                      item.examinationStatus === "END"
                        ? currentDateTime?.getTime() >=
                          startDateTime?.getTime() &&
                          currentDateTime?.getTime() <=
                          endDateTime?.getTime()
                          ? "Card_Mask"
                          : ""
                        : item.examinationStatus === null ||
                          item.examinationStatus === "NOT_YET_STARTED"
                          ? currentDateTime?.getTime() >=
                            startDateTime?.getTime() &&
                            currentDateTime?.getTime() <=
                            endDateTime?.getTime()
                            ? ""
                            : "Card_Mask"
                          : item.examinationStatus === "IN_PROGRESS"
                            ? currentDateTime?.getTime() >=
                              startDateTime?.getTime() &&
                              currentDateTime?.getTime() <=
                              endDateTime?.getTime()
                              ? ""
                              : "Card_Mask"
                            : item.examinationStatus === "START"
                              ? currentDateTime?.getTime() >=
                                startDateTime?.getTime() &&
                                currentDateTime?.getTime() <=
                                endDateTime?.getTime()
                                ? ""
                                : "Card_Mask"
                              : ""
                  }
                ></div>

                <div className="Quiz_Tittle_Box">
                  <p>
                    {item.quizTypeName} | {item.quizName}
                  </p>
                  {/* <p>High score- 20%</p> */}
                </div>
                <div className="Quiz_Box_1">
                  <div className="Quiz_Box_Detail_1">
                    <p>Questions</p>
                    <p>{item.totalQuestions}</p>
                  </div>
                  <div className="Quiz_Box_Detail_1">
                    <p>Marks</p>
                    <p>{item.totalMarks}</p>
                  </div>
                  <div className="Quiz_Box_Detail_1">
                    <p>Duration</p>
                    <p>{item.totalDuration} Mins</p>
                  </div>
                  {/* <div className="Quiz_Box_Detail_1">
                        <p>Remain time Duration</p>
                        <p>Mins</p>
                      </div> */}
                </div>
                {Object.keys(item.taxonomyPercentage).length !== 0 && (
                  <div className="Quiz_Box_2">
                    <div className="Quiz_Box_Detail_2">
                      {Object.keys(item.taxonomyPercentage).map((keyy) => {
                        return (
                          <>
                            <p>{keyy}</p>
                            <p className="percentage_val">
                              {item.taxonomyPercentage[keyy]} %
                            </p>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}

                {item.containsCaseStudy ? (
                  <div className="Quiz_Box_3">
                    <p>Contains Casestudy</p>
                  </div>
                ) : (
                  ""
                )}
                <div className="Quiz_Box_4">
                  <div>
                    <img
                      className="lock_icon"
                      src={
                        item.examinationStatus === "NOT_RELEASED"
                          ? quizLockIcon
                          :
                          item.examinationStatus === "NOT_YET_STARTED"
                            ? currentDateTime?.getTime() >=
                              startDateTime?.getTime() &&
                              currentDateTime?.getTime() <=
                              endDateTime?.getTime()
                              ? quizLockOpenIcon
                              : quizLockIcon
                            : item.examinationStatus === "START"
                              ? currentDateTime?.getTime() >=
                                startDateTime?.getTime() &&
                                currentDateTime?.getTime() <=
                                endDateTime?.getTime()
                                ? quizLockOpenIcon
                                : quizLockIcon
                              : item.examinationStatus === "IN_PROGRESS"
                                ? currentDateTime?.getTime() >=
                                  startDateTime?.getTime() &&
                                  currentDateTime?.getTime() <=
                                  endDateTime?.getTime()
                                  ? quizLockOpenIcon
                                  : quizLockIcon
                                : item.examinationStatus === "END" &&
                                quizLockIcon
                      }
                      alt="Lock/Unlock"
                    />
                    <img
                      className="info_icon"
                      src={quizInfoIcon}
                      alt="info"
                    />
                  </div>
                  {/* formal aasessment quiz taken */}
                  {/* {console.log('examstatus', item.examinationId, item.examinationStatus)} */}
                  <div>

                    {(item.examinationId &&
                      item.examinationStatus === "START") ||
                      (item.examinationStatus == "IN_PROGRESS") ? (
                      // && (item.endDate == "" || item.endDate == null)
                      <button
                        id={`az-resume-btn`}
                        onClick={() => {
                          // handleOpen()
                          checkExamOver(item);
                          sessionStorage.setItem("quiztype", "formal")
                          sessionStorage.setItem("draw_line_array", JSON.stringify([]));
                          sessionStorage.setItem("drag_drop_array_final", JSON.stringify([]));
                          setResumeBtnClicked(true);
                          // setShowQuizAttend(true);
                          setQuizData(item);
                          setQuizExaminationId(item.examinationId);
                          sessionStorage.setItem(
                            "exIdTwo",
                            item.examinationId
                          );
                          sessionStorage.setItem(
                            "exIdTwo",
                            item.examinationId
                          );
                          //console.log("resume 4")
                          sessionStorage.setItem(
                            "itemload",
                            JSON.stringify(item)
                          );
                          // sessionStorage.setItem(
                          //   "viewQuizData",
                          //   JSON.stringify(viewQuizData)
                          // );
                          sessionStorage.setItem("alltheQuizAnswer", JSON.stringify([]));
                          sessionStorage.setItem("QUIZ_DATA", JSON.stringify([]));
                          sessionStorage.setItem("MSQ_det", JSON.stringify([]))
                          // enterFullscreen();
                        }}
                      >
                        RESUME
                      </button>
                    ) : (item.examinationStatus === "END" || item.examinationStatus == "IN_PROGRESS") ? (
                      <button
                        id={`az-viewreport-btn`}
                        onClick={() => {
                          setResumeBtnClicked(true);
                          // setShowQuizAttend(true);
                          // setQuizData(item);
                          setQuizExaminationId(item.examinationId);
                          sessionStorage.setItem(
                            "exIdTwo",
                            item.examinationId
                          );
                          setOpenAnalysisModal(true);
                          sessionStorage.setItem("quiztype", "formal")
                          // enterFullscreen();
                        }}
                      >
                        VIEW REPORT
                      </button>
                    )
                      : (
                        <button
                          id="fullscreenButton"

                          onClick={() => {
                            // console.log("item", item)
                            // handleOpen();
                            checkExamOver(item);
                            unitQuizStart(item);
                            sessionStorage.removeItem("itemload")
                            // setShowQuizAttend(true);
                            setQuizData(item);
                            sessionStorage.setItem("quiztype", "formal");
                            sessionStorage.setItem("draw_line_array", JSON.stringify([]));
                            sessionStorage.setItem("drag_drop_array_final", JSON.stringify([]));
                            sessionStorage.setItem("alltheQuizAnswer", JSON.stringify([]));
                            sessionStorage.setItem("practiceQuizAnswer", JSON.stringify([]));
                            sessionStorage.setItem("drag_drop_array", JSON.stringify([]));
                            sessionStorage.setItem("exid", "");
                            sessionStorage.setItem("alltheQuizAnswer", JSON.stringify([]))
                            // enterFullscreen();
                            sessionStorage.setItem("itemload1", JSON.stringify(item));
                          }}
                        >
                          START
                        </button>
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {isExamOver ? <>
          <Modal open={isExamOver}
            onClose={!isExamOver}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal modalPopUp">
            <Box className="modalBox2">

              <Typography id="modal-modal-description" className="resumeType" sx={{ mt: 2, fontSize: '25px', fontWeight: '500' }}>
                Quiz has already ended
              </Typography>
              <div className="buttonDiv">
                <button className="startButton" onClick={() => handleExit()}>Exit</button>
                {/* <Link to={"quiz/resume"} element={<QuizNewResumeComponent />}>
                    <button className="startButton" onClick={() => handleHide()}>Resume</button>
                  </Link> */}
              </div>
            </Box>
          </Modal>
        </> : <>

          {open && resumeBtnClicked ? <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal modalPopUp"
          >
            <Box className="modalBox2">

              <Typography id="modal-modal-description" className="resumeType" sx={{ mt: 2 }}>
                Are you sure to Resume the Quiz
              </Typography>
              <div className="buttonDiv">
                <button className="backButton" onClick={() => handleBack()}>back</button>
                {/* <Link to={"quiz/resume"} element={<QuizNewResumeComponent />}>
                <button className="startButton" >Resume</button>
              </Link> */}
                <Link to={"quiz/resume"} element={<QuizResumeOptimizedComponent />}>
                  <button className="startButton"
                    disabled={isBtnDisabled}
                    onClick={() => {
                      setIsBtnDisabled(true);
                    }} >Resume</button>
                </Link>
              </div>
            </Box>
          </Modal> : <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal modalPopUp"
          >
            {/* <Box className="modalBox">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              General Instructions
            </Typography>
            <Divider />
            <div className="modal_contents">
              <h6 className="modal_content_1">
                All questions are compulsory
              </h6>
              <h6 className="modal_content_2">
                The <span>maximum marks is {quizData?.totalMarks}  </span>
              </h6>
              <div className="modal_content_3" style={{ marginBottom: '10px' }}>
                
                The duration of the paper is: . (15 min
                reading time &  writing time) Read all the
                questions carefully before attempting. A neat and legible
                presentation will be appreciated.
              </div>
              <h6 className="modal_content_1">
                Please take a few minutes to read the instructions carefully before starting the quiz:
              </h6>
              <div className="modal_content_3">
                <ol>
                  <li>This assessment contains Multiple Choice Questions, with 1 correct answer for every question.</li>
                  <li>Other questions – like Multiselect and Match the following, Fill in the blanks & Case study have specific instructions given.</li>
                  <li>Read the questions carefully and choose the appropriate option.</li>
                  <li>It is mandatory to attempt all the questions.</li>
                  <li>No marks are awarded for unattempted questions.</li>
                  <li>Mark the answers for each question. If you want to attempt any questions later, you can move to the next question without marking the answer. Press the back arrow and attempt the questions later before you submit the quiz.</li>
                  <li>If you are taking the quiz through the mobile app, avoid making/receiving calls.</li>
                  <li>In case of any challenges regarding the quiz, please feel free to contact your teacher immediately.</li>
                  <li>Once quiz Time is over student will receive pop up “Time is over” click on “Ok” to submit quiz.</li>
                </ol>
              </div>
            </div>
            <Divider />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you ready to start the Quiz
            </Typography>
            <div className="buttonDiv">
              <button className="backButton" onClick={() => handleBack()}>Back</button>
              <Link to={"quiz"} element={<QuizNewComponent />}>
                <button className="startButton" >Start</button>
              </Link>
            </div>
          </Box> */}

            <Box className="modalBox">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                General Instructions
              </Typography>
              <Divider />
              <div className="modal_contents">
                <h6 className="modal_content_1">
                  Please read the instructions carefully before starting the quiz:
                </h6>

                <div className="modal_content_3">
                  <ul>
                    <li>This quiz includes Multiple Choice Questions (MCQs) with one correct answer per question. </li>
                    <li>Other question types like Multiselect, Match the Following, Fill in the Blanks, and Case Study, have specific instructions given.	 </li>
                    <li>Attempt all questions as marks are not awarded for unattempted questions.</li>
                    <li>Use navigation to move between questions; avoid calls if using the mobile app. </li>
                    <li>Contact your teacher for quiz-related challenges. </li>
                    <li>Once quiz Time is over, you will receive a pop up “Time is over” click on “Ok” to submit the quiz.</li>
                  </ul>
                </div>
              </div>
              <Divider />
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you ready to start the Quiz
              </Typography>
              <div className="buttonDiv">
                <button className="backButton" onClick={() => handleBack()}>Back</button>
                {/* <Link to={"quiz"} element={<QuizNewComponent />}>
                <button className="startButton" >Start</button>
              </Link> */}
                <Link to={"quiz"} element={<QuizOptimizedComponent />}>
                  <button className="startButton"
                    disabled={isBtnDisabled}
                    onClick={() => {
                      setIsBtnDisabled(true);
                      quizStartCapture();
                    }} >Start</button>
                </Link>
              </div>
            </Box>
          </Modal>}
        </>}

        {openAnalysisModal && (
          <Student_Formal_Assesment_Analysis_Modal2
            showAnalysisModal={openAnalysisModal}
            closeModal={handleAnalysisModalClose}
            closeModall={() => {
              // setShowQuizAttend(false);
            }}
            // closeModall={closeModall}

            chapterQuizKey={false}
          />
        )}
      </>
    </>
  );
}

export default Faq_Quiz_Listing;
