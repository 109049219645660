


import axios from "axios";

async function BranchControllerGet1(
    array,
    field,
    order
) {
    // console.log(array, field, order,"common sorting file")
    order = order == true ? "desc" : "asc";
    if (array?.length > 0) {
        var nullsFirst = false;
        if (order == "asc") {
            nullsFirst = true
        }
        return array.sort((a, b) => {

            if (a[field] === null && b[field] === null) return 0;
            if (a[field] === null) return nullsFirst ? -1 : 1;
            if (b[field] === null) return nullsFirst ? 1 : -1;

            if (typeof a[field] === 'boolean') {
                if (order === 'asc') {
                    return a[field] === b[field] ? 0 : a[field] ? 1 : -1;
                } else {
                    return a[field] === b[field] ? 0 : a[field] ? -1 : 1;
                }
            }
            else if (typeof a[field] === 'string') {
                if (order === 'asc') {
                    return a[field].localeCompare(b[field]);
                } else {
                    return b[field].localeCompare(a[field]);
                }
            } else if (typeof a[field] === 'number' || a[field] instanceof Date) {
                if (order === 'asc') {
                    return a[field] - b[field];
                } else {
                    return b[field] - a[field];
                }
            }
        });
    } else {
        return []
    }
}

export default BranchControllerGet1;
