import axios from "axios";

import commonsort from "../../js/CommonSort"

async function BranchControllerGet1(
  setBranchData,
  setPageDetail,
  rowsInput,
  page,
  sortBy,
  sortOrder
) {
  let token = sessionStorage.getItem("token");
  let userId = sessionStorage.getItem("userId");
  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches?pageNumber=${page - 1}&pageSize=${rowsInput.rows
    }&sortBy=${sortBy || "id"}&order=${sortOrder || false}&schoolId=${sessionStorage.getItem("schoolid")}&userId=${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      if (response.data.data) {
        // console.log(sortBy, sortOrder, "-=-=-", response.data.data.data, response.data.data)
        var sortedData = await commonsort(response.data.data.data || [], sortBy, sortOrder);
        // console.log("sortedData", sortedData, sortOrder)
        response.data.data.data = sortedData
        if (response.data.data) setPageDetail(response.data.data)
        setBranchData(response.data.data.data)
      }
    })
    .catch(err => {
      // console.log(err)
    })
}

export default BranchControllerGet1;
