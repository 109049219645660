import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useSelector } from 'react-redux';

export const NotificationContext = createContext();

const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const userDetails = useSelector(state => state?.userDetails?.userDetails);



  const BASE_URL = process.env.REACT_APP_SWAGGER_URL;
  const TOKEN = localStorage?.getItem('token');
  const ID = sessionStorage.getItem("userId") || localStorage?.getItem('id');

  const fetchNotifications = () => {
    const { token, id } = userDetails || {};
    const userId = ID || id;
    if (userId && TOKEN) {
      axios
        .get(`${BASE_URL}v1/api/content/inbox/${userId}`, {
          headers: {
            Authorization: `Bearer ${token || TOKEN}`,
          },
        })
        .then((response) => {
          setNotifications(response?.data?.data);
          if (response.data?.data?.count === 0) {
            $('.MuiBadge-badge').css('display', 'none');
          } else {
            $('.MuiBadge-badge').css('display', 'block');
            $('.MuiBadge-badge').html(response.data?.data?.count);
            $('.MuiBadge-badge').css('height', '15px');
            $('.MuiBadge-badge').css('width', '15px');
          }
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    // console.log("useEffect called");

    if (userDetails && BASE_URL) {
      fetchNotifications();
    }

    let intervalId;

    if (TOKEN && ID) {
      intervalId = setInterval(fetchNotifications, 30000);
    }

    return () => clearInterval(intervalId);
  }, [userDetails, BASE_URL, TOKEN, ID]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        fetchNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
