import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Doughnut, Chart } from "react-chartjs-3";
import student from "../img/d3.png";
import study from "../img/d2.png";
import quizze from "../img/d4.png";
import medal from "../img/medal.png";
import login from "../img/Login.png";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { styled } from "@mui/material/styles";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dashboardContentStyles from "../css/dashboardContent.module.css";
import "chartjs-plugin-datalabels";
import axios from "axios";
import { MenuProps } from "./Data";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import TopBar from "../../components/common/TopBar";
const DoughData = {
  labels: ["Completed", "InProgress", "To start"],
  datasets: [
    {
      data: [60, 10, 30],
      backgroundColor: ["#4a43ba", "#675dc6", "#918cd2"],
      hoverBackgroundColor: ["#4a43ba", "#675dc6", "#918cd2"],
      borderWidth: 0,
    },
  ],
  text: "120",
};
const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    display: true,
    position: "bottom",
    labels: {
      fontSize: 18,
      fontColor: "#423d5c",
      fontFamily: "kanit light",
      usePointStyle: true,
      boxWidth: 10,
    },
  },
};
const listData = [
  {
    id: "1",
    name: "Jeevan Kumar G",
    value: 65,
    color: "secondary",
  },
  {
    id: "2",
    name: "Ram Sankar K",
    value: 90,
    color: "success",
  },
  {
    id: "3",
    name: "S S Sankar",
    value: 35,
    color: "inherit",
  },
  {
    id: "4",
    name: "K Ram Sankar",
    value: 50,
    color: "inherit",
  },
];
const TableData = [
  {
    id: 1,
    name: "Ram Sankar K",
    date: "13 july 2022",
    status: "completed",
  },
  {
    id: 2,
    name: "S S Sankar",
    date: "15 june 2022",
    status: "completed",
  },
  {
    id: 3,
    name: "Sankar K",
    date: "20 may 2022",
    status: "pending",
  },
  {
    id: 4,
    name: "K Ram Sankar",
    date: "30 may 2022",
    status: "reject",
  },
];
const noteData = [
  {
    id: 1,
    title: "Prepare Question for Unit Test",
    description: "Prepare questions for unit test for the students of Grade 1",
    date: "1 July 2022",
  },
  {
    id: 2,
    title: "Prepare Question for Unit Test",
    description: "Prepare questions for unit test for the students of Grade 2",
    date: "4 July 2022",
  },
  {
    id: 3,
    title: "Prepare Question for Unit Test",
    description: "Prepare questions for unit test for the students of Grade 3",
    date: "13 July 2022",
  },
  {
    id: 4,
    title: "Prepare Question for Unit Test",
    description: "Prepare questions for unit test for the students of Grade 4",
    date: "16 July 2022",
  },
  {
    id: 5,
    title: "Prepare Question for Unit Test",
    description: "Prepare questions for unit test for the students of Grade 5",
    date: "5 July 2022",
  },
  {
    id: 6,
    title: "Prepare Question for Unit Test",
    description: "Prepare questions for unit test for the students of Grade 6",
    date: "15 July 2022",
  },
  {
    id: 7,
    title: "Prepare Question for Unit Test",
    description: "Prepare questions for unit test for the students of Grade 7",
    date: "29 July 2022",
  },
];
const originalDoughnutDraw = Chart?.controllers?.doughnut.prototype.draw;
Chart?.helpers.extend(Chart?.controllers?.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);
    const chart = this.chart;
    const width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;
    const fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.fillStyle = "#4a43ba";
    ctx.textBaseline = "center";
    const text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2.5;
    ctx.fillText(text, textX, textY);
  },
});
const TeacherDashboard = () => {
  const [subjectList, setSubjectList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectPerformanceSubject, setSelectPerformanceSubject] = useState("");
  const [selectPerformanceGrade, setSelectPerformanceGrade] = useState("");
  const [selectedCourseGrade, setSelectedCourseGrade] = useState("");
  const [selectedQuizGrade, setSelectedQuizGrade] = useState("");
  const [modifiedDate, setModifiedDate] = useState({});
  const token = sessionStorage.getItem("token");
  const colors = ["#ff3700", "#ff8c00", "#0000ff", "#63e31f", "#e73232"];
  const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };
  // const random_color = colors[Math.floor(Math.random() * colors.length)];
  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <Box
            sx={{
              minWidth: 35,
              display: "flex",
              justifyContent: "space-between",
              padding: "10",
            }}
          >
            <Typography fontSize={15} fontWeight={600} color={"#313038"}>
              {props.name}
            </Typography>
            <Typography
              variant="body2"
              color={"#423d5c"}
              fontWeight={600}
              fontSize={18}
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
          <LinearProgress variant="determinate" {...props} />
        </Box>
      </Box>
    );
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      padding: "4px",
      width: "30px",
      height: "20px",
      borderRadius: "4px",
      background: "#ff8c00",
    },
  }));
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  const fetchSubjectData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };
  const fetchGradeData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };
  const fetchConceptData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/dash-board/concepts-count`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("concept", res);
  };
  useEffect(async () => {
    const subject = await fetchSubjectData();
    const grade = await fetchGradeData();
    await fetchConceptData();
    setSubjectList(subject);
    setGradeList(grade);
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <TopBar
        title={"Home"}
        subSubTitle={"Dashboard"}
        modifiedDate={modifiedDate?.data}
      />
      <div
        style={{
          marginTop: "10px",
          paddingRight: "33px",
          position: "absolute",
          right: "15px",
          left: "15px",
          display: "flex",
          // margin: "25px 20px",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          columnGap: "1rem",
        }}
      >
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
            height: "100%",
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "15px",
              borderRadius: 10,
              minHeight: "160px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 18, fontWeight: 600, color: "#423d5c" }}>
                Subject Overview
              </div>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 110, color: "#494554" }}
              >
                <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="Age"
                  value={selectedSubject}
                  size={"small"}
                  disableUnderline
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  sx={{ color: "#6e6e82" }}
                  MenuProps={MenuProps}
                  onChange={(e) => setSelectedSubject(e.target.value)}
                >
                  {subjectList?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item?.id}>
                        {item.subject}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "20px",
                }}
              >
                <img src={student} style={{ width: 60 }} alt={""} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 3,
                    color: "#423d5c",
                  }}
                >
                  <div style={{ fontSize: 14, fontWeight: 600 }}>
                    No of Students
                  </div>
                  <div style={{ fontSize: 30, fontWeight: 600 }}>65</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "20px",
                }}
              >
                <img src={study} style={{ width: 60 }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 3,
                    color: "#423d5c",
                  }}
                >
                  <div style={{ fontSize: 14, fontWeight: 600 }}>
                    No of Study Materials
                  </div>
                  <div style={{ fontSize: 30, fontWeight: 600 }}>120</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "20px",
                }}
              >
                <img src={quizze} style={{ width: 60 }} alt={""} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 3,
                    color: "#423d5c",
                  }}
                >
                  <div style={{ fontSize: 14, fontWeight: 600 }}>
                    No of Quizzes
                  </div>
                  <div style={{ fontSize: 30, fontWeight: 600 }}>26</div>
                </div>
              </div>
            </div>
          </Paper>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "1rem",
              width: "100%",
            }}
          >
            <Paper
              style={{
                width: "60%",
                height: "310px",
                display: "flex",
                flexDirection: "column",
                rowGap: "3px",
                padding: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#423d5c",
                  }}
                >
                  Student Performance
                </div>
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 600,
                    color: "#0492e2",
                  }}
                >
                  View All Students
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // paddingBottom: "10px",
                  }}
                >
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 110 }}>
                    <InputLabel id="demo-simple-select-label">
                      Subject
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Age"
                      value={selectPerformanceSubject}
                      size={"small"}
                      disableUnderline
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      sx={{ color: "#6e6e82" }}
                      MenuProps={MenuProps}
                      onChange={(e) =>
                        setSelectPerformanceSubject(e.target.value)
                      }
                    >
                      {subjectList?.map((item) => {
                        return (
                          <MenuItem value={item.id} key={item?.id}>
                            {item.subject}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 110 }}>
                    <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="grade"
                      size={"small"}
                      value={selectPerformanceGrade}
                      disableUnderline
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      sx={{ color: "#6e6e82" }}
                      MenuProps={MenuProps}
                      onChange={(e) =>
                        setSelectPerformanceGrade(e.target.value)
                      }
                    >
                      {gradeList?.map((item) => {
                        return (
                          <MenuItem value={item.id} key={item?.id}>
                            {item.discription}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <Paper
                  sx={{
                    height: "190px",
                    overflow: "auto",
                    boxShadow: "0px 0px 0px 0px",
                  }}
                >
                  {listData.map((item) => {
                    const fistLetter = item?.name;
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                          paddingRight: 5,
                          paddingBottom: 20,
                        }}
                        key={item.id}
                      >
                        <Avatar
                          style={{
                            marginRight: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          sx={{
                            bgcolor: setBg(),
                            // colors[Math.floor(Math.random() * item.id)],
                          }}
                        >
                          {fistLetter[0]}
                        </Avatar>
                        <LinearProgressWithLabel
                          value={item.value}
                          name={item.name}
                          color={item.color}
                        />
                      </div>
                    );
                  })}
                </Paper>
              </div>
            </Paper>
            <Paper
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                maxHeight: "310px",
                padding: "10px",
                // rowGap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ fontSize: 18, fontWeight: 600, color: "#423d5c" }}
                >
                  Total Courses
                </div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 110 }}>
                  <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="grade"
                    size={"small"}
                    disableUnderline
                    value={selectedCourseGrade}
                    IconComponent={(props) => <ExpandMoreIcon {...props} />}
                    sx={{ color: "#6e6e82" }}
                    MenuProps={MenuProps}
                    onChange={(e) => setSelectedCourseGrade(e.target.value)}
                  >
                    {gradeList?.map((item) => {
                      return (
                        <MenuItem value={item.id} key={item?.id}>
                          {item.discription}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "0px 10px 0px 10px",
                }}
              >
                <Doughnut
                  data={DoughData}
                  options={options}
                  width={100}
                  height={50}
                />
              </div>
            </Paper>
          </div>
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 10,
              height: "270px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: 600, color: "#423d5c", fontSize: 18 }}>
                Submitted Quizs
              </div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 110 }}>
                <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="grade"
                  size={"small"}
                  value={selectedQuizGrade}
                  disableUnderline
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  sx={{ color: "#6e6e82" }}
                  MenuProps={MenuProps}
                  onChange={(e) => setSelectedQuizGrade(e.target.value)}
                >
                  {gradeList?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item?.id}>
                        {item.discription}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                boxShadow: "0px 0px 0px 0px",
              }}
            >
              <TableContainer sx={{ maxHeight: 210, overflow: "auto" }}>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader={true}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: 600,
                          background: "#f4f4f4",
                          color: "#3a3642",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: 600,
                          background: "#f4f4f4",
                          color: "#3a3642",
                        }}
                      >
                        Date of submisson
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: 600,
                          background: "#f4f4f4",
                          color: "#3a3642",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: 600,
                          background: "#f4f4f4",
                          color: "#3a3642",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TableData.map((row) => {
                      const firstLetter = row?.name;
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "10px",
                              color: "#575269",
                            }}
                          >
                            <Avatar
                              style={{
                                marginRight: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              sx={{
                                bgcolor:
                                  colors[Math.floor(Math.random() * row.id)],
                              }}
                            >
                              {firstLetter[0]}
                            </Avatar>
                            <div>{row.name}</div>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: "#575269" }}
                          >
                            {row.date}
                          </TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                background:
                                  row.status === "completed"
                                    ? "#c9f8b6"
                                    : row.status === "pending"
                                    ? "#f8d5aa"
                                    : "#f9c0c0",
                                borderRadius: "150px",
                                color:
                                  row.status === "completed"
                                    ? "#51ae2d"
                                    : row.status === "pending"
                                    ? "#aa7421"
                                    : "#b62121",
                                fontWeight: 500,
                                padding: "5px",
                              }}
                            >
                              {row.status}
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: "#575269" }}
                          >
                            <VisibilityOutlinedIcon
                              style={{ fontSize: "25px", cursor: "pointer" }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Paper>
        </div>
        <div
          style={{
            width: "30%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
          }}
        >
          <Paper
            style={{
              background: "rgb(92 86 129)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "15px",
              borderRadius: "10px",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              <div />
              <div style={{ fontSize: 13, fontWeight: 500 }}>
                <div>You Students completed </div>
                <div style={{ paddingTop: "5px" }}>
                  <span style={{ fontWeight: 600 }}>80%</span> of the tasks.
                </div>
                <div style={{ paddingTop: "5px" }}>
                  Progress is{" "}
                  <span style={{ fontWeight: 600 }}>very Good!</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <img src={medal} width={20} alt={""} />
                <div style={{ fontSize: 13 }}>Appreciated</div>
              </div>
            </div>
            <div style={{ width: "150px", height: "120px" }}>
              <img src={login} width={150} alt={""} />
            </div>
          </Paper>
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              height: "600px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "20px",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#423d5c",
                }}
              >
                My Notes
              </div>
              <StyledBadge badgeContent={10} color="primary" />
            </div>
            <Paper
              sx={{
                width: "100%",
                overflow: "auto",
                padding: "10px",
                boxShadow: "0px 0px 0px 0px",
                height: "510px",
              }}
            >
              {noteData.map((note) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                      padding: "10px",
                    }}
                    key={note.id}
                  >
                    <div
                      style={{
                        background: "#c2edf5",
                        width: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px",
                        borderRadius: "150px",
                        height: "30px",
                      }}
                      key={note.id}
                    >
                      <DescriptionOutlinedIcon
                        style={{ fontSize: "20px", color: "#24838c" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "5px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: "#424250",
                          }}
                        >
                          {note.title}
                        </div>
                        <div style={{ fontSize: 12, color: "#7a7979" }}>
                          {note.description}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#6d6c73",
                        }}
                      >
                        {note.date}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Paper>
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default TeacherDashboard;
