import axios from 'axios'

function teacher_content_post(teacherInputs,setSuccess,cb){
    let token = sessionStorage.getItem("token");

    var item = {
        "active": true,
        "chapterId":sessionStorage.getItem('chapterId'),
        "contentUrl": teacherInputs.contentUrl,
        "documentCategoryId": teacherInputs.documentCategoryId,
        "reason": "Reject reason: incorrect data",
        "reviewStatus": "PENDING"
      }

    let result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter` , item , 
    {
        headers: {
            "Content-Type" : "application/json" ,
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=> {
        // console.log(res.data);
        setSuccess(res);
        cb()
    })
    .catch(err => console.log(err))
}

export default teacher_content_post;