import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import chapterStyles from "./../css/createChapter.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createschool from "./../css/CreateSchool.module.css";
import Pagination1 from "./pagination1";
// get create school css in this css file
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { SearchOutlined } from "@mui/icons-material";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import academicYear_list_get from "../API_CONTROLLER/academic-year-controller/academicYear_list_get";
import chapters_controller_post from "../API_CONTROLLER/chapters-controller/chapters_controller_post";
import chapters_file_uploader_post from "../API_CONTROLLER/chapters-controller/chapters_file_uploader_post";
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import assessment_table_get from "../API_CONTROLLER/accessment-controller/assessment_table_get";
import chapters_edit_getby_id from "../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import assessment_post from "../API_CONTROLLER/accessment-controller/assessment_post";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

const AssignAssessPaper = () => {
  const [gradesName, setGradesName] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [chapter, setChapter] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [documenttype, setDocumenttype] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedDocumenttype, setSelectedDocumenttype] = useState("");
  const [docType, setDoctype] = useState({});
  const [showError, setShowError] = useState("");
  const [isError, setIsError] = useState(false);
  const [success, setSuccess] = useState({});
  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");
  const [assessTable, setAssessTable] = useState("");
  const [yearlist, setYearlist] = useState({});
  const navigate = useNavigate();

  const [asssessmentInputs, setAsssessmentInputs] = useState({
    // selectedBoard : "",
    // selectedGrade : "",
    // selectedSubject: "",
    // selectedSubtopic:"",
    // selectedChapter:"",
    boardId: "",
    gradeId: "",
    subjectId: "",
    chapter: "",
    docType: "",
    logoUrl: "",
    chapterStatus: "PENDING",
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();

  const handleCreateChapter = (e) => {
    e.preventDefault();
    chapters_controller_post(asssessmentInputs);
    // setAsssessmentInputs("")
    navigate("/dashboard/coadminDashboard");
  };

  const handlerAssessmentInputs = (e) => {
    e.preventDefault();
    setAsssessmentInputs({
      ...asssessmentInputs,
      [e.target.name]: e.target.value,
    });
  };

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    e.preventDefault();
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
  };

  const handleCreareAssessment = async () => {
    // for (const input in asssessmentInputs) {
    //   if (asssessmentInputs[input] === "") {
    //     setIsError(true);
    //     setShowError("Please enter all the required values");
    //     return;
    //   }
    // }

    await assessment_post(asssessmentInputs, setSuccess, setShowError);
    await navigate("/dashboard/upload%20assessments");
  };

  const imageChange = (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    chapters_file_uploader_post(
      setSelectedImage,
      setAsssessmentInputs,
      asssessmentInputs,
      formData
    );
    // window.alert("iiiiii");
  };

  const removeSelectedImage = () => {
    setAsssessmentInputs({
      ...asssessmentInputs,
      logoUrl: null,
    });
  };

  useEffect(() => {
    setAsssessmentInputs({
      ...asssessmentInputs,
      logourl: sessionStorage.getItem("logourl"),
    });
    return () => {};
  }, [sessionStorage.getItem("logoUrl")]);

  useEffect(() => {
    if (search.search === "") {
      chapters_controller_getall(
        setChapters,
        rowsInput,
        page,
        setPageDetail,
        search,
        gradeInput,
        subjectInput,
        subTopicInput
      );
    }
    return () => {};
  }, [rowsInput, search]);

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
  }, [rowsInput, page]);

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
    return () => {};
  }, [search, gradeInput, subjectInput, subTopicInput]);

  useEffect(() => {
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    academicYear_list_get(setYearlist);
    chapters_last_modified_get(setLastModifiedDate);
    assessment_table_get(setAssessTable);

    return () => {};
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>View Quizes</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {lastModifiedDate.data}
            </p>
          </div>
        </article>
        {/* create chapter */}

        <Paper
          style={{
            marginTop: "55px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
            }}
          >
            <div className={chapterStyles.chapter_container}>
              <div className={chapterStyles.flex}>
                <FormControl
                  style={{ marginInline: "8px", width: "19vw" }}
                  sx={{ width: 250 }}
                >
                  <TextField
                    id="demo-helper-text-aligned-no-helper"
                    label="*Paper Title"
                    name="paperTitle"
                    type="text"
                    style={{ fontWeight: "300" }}
                    className={chapterStyles.select_input}
                    // style={{ fontSize: "15px", width: "367px" }}
                    value={asssessmentInputs.paperTitle}
                    onChange={handlerAssessmentInputs}
                  />
                </FormControl>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Board
                  </InputLabel>
                  <Select
                    name="boardId"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={asssessmentInputs.boardId}
                    onChange={handlerAssessmentInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                  >
                    {/* {optionsRender(board)} */}
                    {board.length > 0 ? (
                      board.map((board, i) => {
                        return (
                          <MenuItem key={i} value={board.id}>
                            <ListItemText primary={board.board} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Grade(s)
                  </InputLabel>
                  <Select
                    name="gradeId"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    input={<OutlinedInput label="Tag" />}
                    value={asssessmentInputs.gradeId}
                    onChange={handlerAssessmentInputs}
                  >
                    {gradesName.length > 0 ? (
                      gradesName.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.grade} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                    {/* {optionsRender(gradesName)} */}
                  </Select>
                </FormControl>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Subject(s)
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="subjectId"
                    id="demo-multiple-checkbox"
                    value={asssessmentInputs.subjectId}
                    onChange={handlerAssessmentInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {subject.length > 0 ? (
                      subject.map((subject, i) => {
                        return (
                          <MenuItem key={i} value={subject.id}>
                            <ListItemText primary={subject.subject} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className={chapterStyles.flex} style={{ marginTop: "15px" }}>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    marginTop: "16px",
                  }}
                  sx={{ width: 250 }}
                >
                  <InputLabel
                    className={chapterStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Academic Year
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="academicYearId"
                    value={asssessmentInputs.academicYearId}
                    onChange={handlerAssessmentInputs}
                    input={<OutlinedInput label="Tag" />}
                  >
                    {yearlist.length > 0 ? (
                      yearlist.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.academicYear} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                {/* upload img */}
                <div className={createschool.add_schools_btn}>
                  <div className={createschool.upload_logo_container}>
                    <span>*Upload File</span>
                    <span className={createschool.custom_file_container}>
                      <input
                        type="file"
                        name="logoUrl"
                        // id={sessionStorage.getItem("logoUrl")}
                        // filename={sessionStorage.getItem("logoUrl")}
                        // Value={asssessmentInputs.logoUrl}
                        // onError={setIsError(true)}
                        onChange={imageChange}
                        className={createschool.custom_file_input}
                      />

                      {/* {asssessmentInputs.logoUrl && (
                  <span>
                    <img
                      src={asssessmentInputs.logoUrl}
                      alt="Thumb"
                      width="75"
                      height="75"
                    />
                    <span>
                      <a>
                        {asssessmentInputs.logoUrl.substring(
                          asssessmentInputs.logoUrl.lastIndexOf("_") + 1,
                          asssessmentInputs.logoUrl.length
                        )}
                      </a>
                      <br></br>
                      <br></br>
                      <a 
                      onClick={removeSelectedImage} 
                      style={{color:'red' , textDecoration:'underline'}}
                      >Remove This Image</a>
                    </span>
                  </span>
                )} */}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    position: "absolute",
                    right: "35px",
                  }}
                >
                  <WhiteAddButton
                    onClick={() => {
                      navigate("/dashboard/upload%20assessments");
                    }}
                    style={{ marginRight: "12px" }}
                  >
                    BACK
                  </WhiteAddButton>
                  <button
                    style={{ maxHeight: "50px" }}
                    onClick={handleCreareAssessment}
                    className={chapterStyles.blue_btn}
                  >
                    CREATE
                  </button>
                  {/* {isAddChapter ? 
            <button style={{maxHeight : '50px'}}
               onClick={handleCreareAssessment} className={chapterStyles.blue_btn}>CREATE</button> : <button style={{maxHeight : '50px'}}
               onClick={handleUpdateChapter} className={chapterStyles.blue_btn}>UPDATE</button> } */}
                </div>
              </div>

              <div
                style={
                  isError ? { visibility: "visible" } : { visibility: "hidden" }
                }
              >
                <p
                  style={{
                    display: "flex",
                    float: "right",
                    marginRight: "187px",
                    color: "red",
                  }}
                >
                  {showError}
                </p>
              </div>
              {/* search btn row */}
              <div
                className={chapterStyles.flex}
                style={{ marginTop: "70px", position: "relative" }}
              >
                <p
                  style={{
                    marginRight: "10px",
                    color: "#354052",
                    fontSize: "1.3vw",
                  }}
                >
                  <i className="fa-solid fa-chart-line"></i>Assessment Papers
                </p>

                <Box sx={{ minWidth: 115 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                    >
                      Grade
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Grade"
                      value={gradeInput}
                      onChange={(e) => setGradeInput(e.target.value)}
                      style={{ background: "#fff", borderRadius: 150 }}
                      //   MenuProps={MenuProps}
                    >
                      <MenuItem value="">All</MenuItem>
                      {/* {optionsRender(gradesName)} */}
                      {gradesName.length > 0 ? (
                        gradesName?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            <ListItemText primary={item.grade} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box style={{ margin: "auto 8px" }}>
                  <FormControl fullWidth size="small" style={{ width: "12vw" }}>
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                    >
                      Select subject
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={subjectInput}
                      label="subject"
                      onChange={(e) => setSubjectInput(e.target.value)}
                      style={{ background: "#fff", borderRadius: 150 }}
                      //   MenuProps={MenuProps}
                    >
                      <MenuItem value="">All</MenuItem>

                      {subject.length > 0 ? (
                        subject.map((subject, i) => {
                          return (
                            <MenuItem key={i} value={subject.id}>
                              <ListItemText primary={subject.subject} />
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 115 }}>
                  <FormControl fullWidth size="small" style={{ width: "9vw" }}>
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                    >
                      Sub Topic
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="subTopic"
                      style={{ background: "#fff", borderRadius: 150 }}
                      value={subTopicInput}
                      onChange={(e) => setSubTopicInput(e.target.value)}
                      //   MenuProps={MenuProps}/
                    >
                      <MenuItem value="">All</MenuItem>
                      {subtopic.length > 0 ? (
                        subtopic.map((subtopic, i) => {
                          return (
                            <MenuItem key={i} value={subtopic.id}>
                              <ListItemText primary={subtopic.subTopic} />
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>

                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    right: "-23px",
                  }}
                >
                  <TextField
                    sx={{
                      width: { sm: 200, md: 300 },
                      "& .MuiInputBase-root": {
                        width: "247px !important",
                        height: "32px !important",
                        borderRadius: "50px !important",
                        background: "#fff !important",
                        lineHeight: "21px !important",
                        font: "normal normal normal 13px/20px Poppins !important",
                        opacity: 1,
                      },
                    }}
                    type={"text"}
                    name="search"
                    // type='search'
                    placeholder={"Search...."}
                    onChange={handleSearch}
                    Value={search.search}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <img
                            src={searchIcn}
                            style={{
                              verticalAlign: "top",
                              fontSize: "large",
                            }}
                            aria-hidden="true"
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* table */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={chapterStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={chapterStyles.table_head}
                    >
                      Paper Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Board
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Grade
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Subject
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Academic Year
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assessTable.length > 0 ? (
                    assessTable.map((elem, i) => (
                      <TableRow>
                        {/* index */}
                        <TableCell className={chapterStyles.table_cell}>
                          {i + 1}
                        </TableCell>
                        {/* index */}
                        <TableCell className={chapterStyles.table_cell}>
                          {elem.paperTitle}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.board}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.grade}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.subject}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.academicYear}
                        </TableCell>
                        {/* active btn */}
                        <TableCell className={chapterStyles.table_cell}>
                          <label className={chapterStyles.active_switch}>
                            <input
                              type="checkbox"
                              id=""
                              name=""
                              value=""
                              // defaultChecked={schoolListData.active}
                              // onChange={handletoggleChange}
                            />
                            <span
                              className={chapterStyles.active_slider}
                            ></span>
                          </label>
                          {/* </Link> */}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          align="right"
                          className={chapterStyles.table_cell}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "tableCell" }}>
                            <EditIconButton
                              onClick={() => {
                                // openAddAcademic();
                                // setIsEdit(true);
                                // setStartingYear(item?.startingYear);
                                // setEndingYear(item?.endingYear);
                                // setSelectedItem(item);
                              }}
                            >
                              <Tooltip title="Edit" placement={"top"}>
                                <EditOutlinedIcon
                                  style={{ fontSize: "25px" }}
                                />
                              </Tooltip>
                            </EditIconButton>
                            <DeleteIconButton
                              onClick={() => {
                                // showDeleteModal(item);
                              }}
                            >
                              <Tooltip title="Remove" placement={"top"}>
                                <DeleteOutlinedIcon
                                  style={{ fontSize: "25px" }}
                                />
                              </Tooltip>
                            </DeleteIconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* pagination container - Start */}
            <div className={chapterStyles.pagination} style={{ width: "100%" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={chapterStyles.rows}>
                  <label for="rows">Rows per page</label>
                  <select
                    name="rows"
                    id="rows"
                    value={rowsInput.rows}
                    onChange={handlerRowsInputs}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </span>
              </form>
              <span>
                <Pagination1
                  pageDetail={pageDetail}
                  handleChange={handleChange}
                />
              </span>
            </div>
            {/* pagination container - End */}
          </Paper>
        </Paper>
      </div>
    </>
  );
};

export default AssignAssessPaper;
