import React, { useRef, useState, useEffect } from 'react';
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { pdfjs } from 'react-pdf';
import findLinksPlugin from '../../../src/findLinksPlugin';
import { Button } from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ContentViewer = ({ contentstyle, url }) => {
    const viewerRef = useRef(null); // Ref to the Viewer component

    const findLinksPluginInstance = findLinksPlugin();
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;
    const role = localStorage.getItem('role')

    useEffect(()=>{
        //  console.log('role',role);
         
    },[])
    const transform = (slot) => ({
        ...slot,
        Download: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    const renderToolbar = (Toolbar) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
            defaultTabs[0], // Thumbnails tab      
        ],
        renderToolbar,
    });

    
  const downloadFile = async () => {
    const fileUrl = url;
    
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'document'; // Set the file name
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      // console.error('There was an error downloading the file:', error);
    }
  };

  

    return (
        <>
        {/* <Button variant='contained'>Download</Button> */}
        <div>

               {url.toLowerCase().endsWith('.html') 
                  &&
                (role === "CONTENT_ADMIN" || role === "REVISION_ADMIN" || role === "CONTENT_REVIEWER")
                  &&
                <Button
                    // variant="contained"  // You can choose "text", "outlined", or "contained"
                    color="primary"
                    // endIcon
                    onClick={downloadFile}
                    endIcon={<SystemUpdateAltIcon />} // Icon at the start of the button
                  >
                    Download
                </Button>}
        </div>
        {url && (
            url.includes(".pdf") ? (
                <div onContextMenu={handleRightClick} style={{ ...contentstyle, userSelect: 'none' }}>
                    <Viewer
                        fileUrl={url}
                        plugins={[defaultLayoutPluginInstance,findLinksPluginInstance]}
                        defaultScale={SpecialZoomLevel.PageWidth}
                    />
                </div>
            ) : (
                <iframe
                    title="external-content"
                    src={url}
                    style={{ ...contentstyle, userSelect: 'none' }}
                    // style={contentstyle}
                ></iframe>
            )
        )}
                        </>
    );
};

export default ContentViewer;
