
//
// --- ❗❕❗ CS-> Case Study ❗❕❗ --- //
//
import React, { useEffect, useState } from "react";

// Css Pages
import "../../css/Student/Student_Enrich_Quiz_CS.css";

import tickIcon from "../../../../src/img/tick-circle.svg";
import cancelIcon from "../../../../src/img/cancel.svg";
import circleIcon from "../../../../src/img/circle-thin.svg";
import checkboxPlain from "../../../../src/img/checkbox-plain.svg";
import checkboxGreen from "../../../../src/img/checkbox-green-tick.svg";
import checkboxCross from "../../../../src/img/checkbox-cross.svg";

//Mui
import CloseIcon from "@mui/icons-material/Close";

// Mui
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Student_Image_Open_Modal from "./Student_Image_Open_Modal";
import Student_Enrich_Quiz_FIB from "./Student_Enrich_Quiz_FIB"
import Student_Enrich_Quiz_MCQ from './Student_Enrich_Quiz_MCQ'
import Student_Enrich_Quiz_MSQ from './Student_Enrich_Quiz_MSQ'
import Radio from '@mui/material/Radio';
import QuizCommonComponent from './Quiz_Common_component';
import { rightClickEnable } from "../../../../src/Serviceworker"

function CustomRadio(props) {
  return (
    <Radio
      color="default"
      checkedIcon={<CheckCircleIcon
        style={{
          color: "orange",
        }}
      />}
      icon={<RadioButtonUncheckedIcon />}
      {...props}
    />
  );
}

function Student_Enrich_Quiz_CS({
  questions,
  currentQuestion,
  setAttendCs,
  attendCs,
  setAttendedCs,
  attendedCs,
  examId,
  parentQuestionId,
  resumeBtnClicked,
  endDate,
  endTime,
  setAttendFib,
  attendFib,
  setAttendedFib,
  attendedFib,
  setAttendMcq,
  attendMcq,
  setAttendedMcq,
  attendedMcq,
  setAttendMsq,
  attendMsq,
  setAttendedMsq,
  attendedMsq,
  setCommonAttendedData,
  commonAttendedData,
  ispreview,
  list,
  tempCaseStudy,
  settempCaseStudy,
  showExplanation
}) {
  // console.log("caseeeeeee", questions, currentQuestion, attendCs, attendedCs, examId, parentQuestionId, "resumeBtnClicked", resumeBtnClicked, endDate, endTime, attendFib, attendedFib, attendMcq, attendedMcq, attendMsq, attendedMsq, "ispreview", ispreview)
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [csAttentedAns, setCsAttentedAns] = useState([]);
  const [hoveredFIB, setHoveredFIB] = useState(false);
  const [hoverColor, setHoverColor] = useState('orange');
  const [isAuthenticatedShift, setIsAuthenticatedShift] = useState(true);
  const currentQuestionData = !ispreview ? questions[currentQuestion] : list?.questionAnswerKeys[currentQuestion];
  const listData = ispreview ? list?.questionAnswerKeys[currentQuestion] : {};
  Array.from(
    { length: !ispreview ? questions[currentQuestion]?.subQuestions?.length : list?.questionAnswerKeys[currentQuestion]?.subQuestions?.length },
    () => null
  );

  const handleOptionChange = (subQuestionIndex, optionIndex) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedSelectedOptions = [...prevSelectedOptions];
      updatedSelectedOptions[subQuestionIndex] = optionIndex;
      return updatedSelectedOptions;
    });
  };

  const handleMouseEnter = () => {
    setHoveredFIB(true);
  };

  const handleMouseLeave = () => {
    setHoveredFIB(false);
  };


  useEffect(() => {
    //console.log("useeffectt valled.")
    const handleContextMenu = (e) => {
      // if (!authContext.isAuthenticated) { this is to restrict right click based on loggedin info
      // alert("context menu cs")
      e.preventDefault();
      //console.log("handleContextMenu called")
    };
    // console.log("rightClickEnablerightClickEnablerightClickEnable", rightClickEnable)
    if (rightClickEnable && isAuthenticatedShift) {
      document.addEventListener('contextmenu', handleContextMenu);
      document.addEventListener('keydown', function (event) {
        //console.log(event);
        // alert(event.getModifierState)
        const isModifierKey = event.getModifierState('Alt') ||
          event.getModifierState('Tab') ||
          event.getModifierState('Shift');

        if (event.key === 'F12' || isModifierKey) {
          //console.log("Modifier key pressed");
          event.preventDefault();
        } else if (event.key === "Enter" || event.key === " ") {
          //console.log(`${event.key} key pressed`);

          // Check if no modifier keys are pressed
          const noModifiers = !event.getModifierState('Alt') &&
            !event.getModifierState('Control') &&
            !event.getModifierState('Meta') &&
            !event.getModifierState('Shift');

          if (noModifiers) {
            //console.log("No modifier keys pressed");
            // Prevent the default behavior only for <button> elements
            if (event.target.tagName === 'BUTTON') {
              event.preventDefault();
              //console.log("Key event prevented for <button>");
            }
          }
        }
      });
      const handleBeforeUnload = (event) => {
        // console.log("close tab", event.type); // For reload and close tab
        const isRefresh = !event.currentTarget.performance.navigation.type;
        //console.log("isRefresh", isRefresh);

        if (event.type === 'beforeunload') {
          // console.log("ifffffffffffffffffffffffff")
          // sessionStorage.removeItem("itemload");
          sessionStorage.removeItem("viewQuizData");
          // setIsModalOpen(false)
          //   //console.log("exit clicked1", previousPage);
          sessionStorage.removeItem("practiceQuizAnswer");
          sessionStorage.removeItem("drag_drop_array");
          sessionStorage.removeItem("drag_drop_array_final");
          sessionStorage.removeItem("shuffledQuiz");
          sessionStorage.removeItem("alltheQuizAnswer");
          sessionStorage.removeItem("exid");
          sessionStorage.removeItem("exIdTwo");
          sessionStorage.removeItem("drag_drop_array_final")
          sessionStorage.removeItem("case_help");
          const message = "Are you sure you want to leave? Your progress may be lost.";
          // event.returnValue = message; // Standard for most browsers
          event.preventDefault();
          return message; // For some older browsers
        } else if (event.currentTarget.performance.navigation.type === 1) {
          // If it's a reload navigation type, prevent it
          // console.log("elseifffffffffffffffffffffff")
          const message = "Reloading the page is not allowed.";
          event.preventDefault(); // Prevent the reload
          return message;
        } else {
          // console.log("elseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
          // Handle other types of close events if needed
          const message = "Are you sure you want to close the tab?";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        }
      }
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [])
  const handleSavingAns = (e, id, type, value) => {
    // console.log("e, id, type, value", e, id, type, value, questions[currentQuestion])

    let obj;
    const existedVal = attendCs.findIndex((itm) => {
      return itm.questionId === id;
    });
    // console.log("attendCs", attendCs, existedVal)
    const existedOrder =
      attendCs[existedVal] &&
      attendCs[existedVal].answer.length > 0 &&
      attendCs[existedVal].answer.findIndex((itm) => {
        return itm.order === value.order;
      });
    if (type === "fill") {
      if (existedVal !== -1) {
        if (existedOrder !== -1) {

          attendCs[existedVal].answer[existedOrder].answerKey = e.target.value;

          if (attendCs[existedVal].answer[existedOrder].answerKey === "") {
            attendCs[existedVal].answer.splice(existedOrder, 1);
          }

          if (attendCs[existedVal].answer.length === 0) {
            attendCs.splice(existedVal, 1);
          }
        } else {
          const obj = {
            order: value.order,
            answerKey: e.target.value,
          };

          attendCs[existedVal].answer.push(obj);
        }
      } else {
        obj = {
          examId: examId,
          questionId: id,
          questionTypeCode: "FILL",
          // parentQuestionId: questions[currentQuestion]?.id,
          parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData?.id,
          answer: [
            {
              order: value.order,
              answerKey: e.target.value,
            },
          ],
          endDate: endDate,
          endTime: endTime,
        };
      }
      setAttendedCs((prevAttendedQuestions) => {
        const updatedQuestions = prevAttendedQuestions;
        if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
        }
        if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id)) {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id] = []
        }
        // const foundIndex = Object.keys(updatedQuestions).findIndex(itm => itm.id === resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id);
        const foundIndex = updatedQuestions.hasOwnProperty(resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id);
        const foundIndexofFib = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id);
        if (!foundIndex) {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({ "order": value.order, "answerKey": e.target.value });
        }
        else {
          if (foundIndexofFib) {
            const foundIndexOrder = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id]?.findIndex(itm => itm.order === value.order);
            if (foundIndexOrder === -1) {
              updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({ "order": value.order, "answerKey": e.target.value });
            }
            else {
              updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id][foundIndexOrder] = { "order": value.order, "answerKey": e.target.value };
            }
          }
          else {
            updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({ "order": value.order, "answerKey": e.target.value });
          }
        }

        var vtempCaseStudy = tempCaseStudy || [];
        // console.log(vtempCaseStudy)
        var a = {
          [id]: {
            "option": value.option,
            "order": value.order,
            "answerKey": e.target.value,
          }
        }
        const existingIndex = vtempCaseStudy.findIndex(item => item.hasOwnProperty(id));
        if (existingIndex !== -1) {
          vtempCaseStudy.splice(existingIndex, 1);
        }
        vtempCaseStudy.push({
          [id]: [{
            "option": value.option,
            "order": value.order,
            "answerKey": e?.target?.value?.replace(/\s/g, "")
          }],
          type: "FILL",
          currentQuestion: currentQuestion || 0, // Ensure currentQuestion is assigned
          parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id
        });
        // console.log(a, "***********************************", vtempCaseStudy,)
        settempCaseStudy([...vtempCaseStudy]);
        var tt = JSON.parse(sessionStorage.getItem("case_help")) || []
        tt.push({
          [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]:
            { "order": value.order, "answerKey": e.target.value }
        })
        sessionStorage.setItem("case_help", JSON.stringify(tt))
        return updatedQuestions;
      })
    } else if (type === "mcq") {
      if (existedVal !== -1) {

        attendCs[existedVal].answer[0].answerKey = e.target.value;
        attendCs[existedVal].answer[0].option = value.option;
        attendCs[existedVal].answer[0].order = value.order;
      } else {
        obj = {
          examId: examId,
          questionId: id,
          questionTypeCode: "MCQ",
          parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData?.id,
          answer: [
            {
              option: value.option,
              order: value.order,
              answerKey: e.target.value,
            },
          ],
          endDate: endDate,
          endTime: endTime,
        };
      }

      setAttendedCs((prevAttendedQuestions) => {
        const updatedQuestions = { ...prevAttendedQuestions };
        if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
        }
        var vv = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id];
        if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id)) {
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id] = []
        }

        updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].splice(0, 1);
        updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
          "option": value.option,
          "order": value.order,
          "answerKey": e.target.value,
        });

        var vtempCaseStudy = tempCaseStudy || [];
        // console.log(vtempCaseStudy)
        var a = {
          [id]: {
            "option": value.option,
            "order": value.order,
            "answerKey": e.target.value,
          }
        }
        const existingIndex = vtempCaseStudy.findIndex(item => item.hasOwnProperty(id));
        if (existingIndex !== -1) {
          vtempCaseStudy.splice(existingIndex, 1);
        }
        vtempCaseStudy.push({
          [id]: [{
            "option": value.option,
            "order": value.order,
            "answerKey": e?.target?.value?.replace(/\s/g, "")
          }],
          type: "MCQ",
          currentQuestion: currentQuestion || 0, // Ensure currentQuestion is assigned
          parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id
        });
        // console.log(a, "***********************************", vtempCaseStudy,)
        settempCaseStudy([...vtempCaseStudy]);
        // console.log("updatedQuestions", updatedQuestions, vtempCaseStudy)
        var tt = JSON.parse(sessionStorage.getItem("case_help")) || []
        tt.push({
          [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]: {
            "option": value.option,
            "order": value.order,
            "answerKey": e.target.value,
          }
        })
        sessionStorage.setItem("case_help", JSON.stringify(tt))
        return updatedQuestions;
      });

    } else {
      var Voptions = questions[currentQuestion]?.subQuestions.filter(r => r.id == id) || []
      if (Voptions?.length == 0) {
        Voptions = questions[currentQuestion]?.subQuestions.filter(r => r.questionId == id)
      }
      // console.log("Voptions", Voptions, id, resumeBtnClicked)
      if (!resumeBtnClicked) {
        if (Voptions.length > 0) {
          Voptions = Voptions[0]?.options[0] || Voptions[0]?.multiChoiceOrSelect || []
        }
      } else {
        if (Voptions?.length > 0) {
          Voptions = Voptions[0]?.multiChoiceOrSelect || []
        }
      }

      Voptions = Voptions?.filter(item => item?.correctAnswer === true);
      // console.log("Voptions11111", Voptions)
      if (existedVal !== -1) {
        if (e.target.checked) {
          if (existedOrder !== -1) {
            // console.log(attendCs, attendCs[existedVal], attendCs[existedVal].answer, attendCs[existedVal].answer[existedOrder], e.target.value)
            if (attendCs[existedVal].answer.length > 0) {
              attendCs[existedVal].answer[existedOrder].answerKey = e.target.value;
            }
          } else {
            const obj = {
              option: value.option,
              order: value.order,
              answerKey: e.target.value,
            };

            attendCs[existedVal].answer.push(obj);

            const removeDuplicateObjects = (arr, prop) => {
              const uniqueObjects = [];
              const encounteredValues = new Set();
              for (const obj of arr) {
                const value = obj[prop];
                if (!encounteredValues.has(value)) {
                  encounteredValues.add(value);
                  uniqueObjects.push(obj);
                }
              }

              return uniqueObjects;
            };

            const uniqueData = removeDuplicateObjects(attendCs[existedVal].answer, 'order');
            // csAttentedAns[existedVal].answer = uniqueData;
            attendCs[existedVal].answer = uniqueData;
          }
        } else {
          // csAttentedAns[existedVal].answer.splice(existedOrder, 1);
          attendCs[existedVal].answer.splice(existedOrder, 1);
          if (attendCs[existedVal].answer.length === 0) {
            // csAttentedAns.splice(existedVal, 1);
            attendCs.splice(existedVal, 1);
          }
        }
      } else {
        obj = {
          examId: examId,
          questionId: id,
          questionTypeCode: "MSQ",
          parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData?.id,
          answer: [
            {
              option: value?.option,
              order: value?.order,
              answerKey: e.target.value,
            },
          ],
          endDate: endDate,
          endTime: endTime,
        };
      }

      if (e.target.checked) {

        setAttendedCs((prevAttendedQuestions) => {
          const updatedQuestions = { ...prevAttendedQuestions };
          if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
            updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
          }
          if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id)) {
            updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id] = []
          }
          const foundIndex = updatedQuestions.hasOwnProperty(resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id);
          const foundIndexofMsq = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id);

          if (!foundIndex) {
            // console.log("internal111111", updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id])
            updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
              "option": value.option,
              "order": value.order,
              "answerKey": e.target.value,
            });
          } else {

            if (foundIndexofMsq) {
              const foundIndexOrder = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id]?.findIndex(
                (item) => item.order === value.order
              );
              if (foundIndexOrder) {
                // console.log("internal22222", updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id], updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id], updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].length, Voptions.length)
                if (updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].length <= Voptions.length) {
                  updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
                    "option": value.option,
                    "order": value.order,
                    "answerKey": e.target.value,
                  });
                }
              }
              else {
                // console.log("internal333333", updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id])
                updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id][foundIndexOrder] = {
                  "option": value.option,
                  "order": value.order,
                  "answerKey": e.target.value,
                };
              }
            }
            else {
              // console.log("internal444444", updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id])
              updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
                "option": value.option,
                "order": value.order,
                "answerKey": e.target.value,
              });
            }

          }

          var vtempCaseStudy = tempCaseStudy || [];
          // console.log(vtempCaseStudy)
          var a = {
            [id]: {
              "option": value.option,
              "order": value.order,
              "answerKey": e.target.value.replace(/\s/g, ""),
            }
          }

          const existingItemIndex = vtempCaseStudy.findIndex(item => item.hasOwnProperty(id));
          // console.log("existingItemIndex", existingItemIndex, id, vtempCaseStudy, Voptions);
          // console.log(Voptions.length, vtempCaseStudy.length)
          if (existingItemIndex !== -1) {
            // console.log("111111111111", Array.isArray(vtempCaseStudy[existingItemIndex][id]))
            if (Array.isArray(vtempCaseStudy[existingItemIndex][id])) {
              // console.log("222222222", vtempCaseStudy[existingItemIndex][id])
              const existingIndex = vtempCaseStudy[existingItemIndex][id].findIndex(item => item.order === value.order);
              // console.log("existingIndex", existingIndex)
              if (existingIndex !== -1) {
                vtempCaseStudy[existingItemIndex][id].splice(existingIndex, 1);
              }
              // console.log("111111111111111111111111111111111111111111111111111", vtempCaseStudy[existingItemIndex][id], vtempCaseStudy, Voptions.length)
              // Push the new object to the array
              // console.log(vtempCaseStudy[existingItemIndex][id]?.length, Voptions.length)
              if (vtempCaseStudy[existingItemIndex][id]?.length < Voptions.length) {
                vtempCaseStudy[existingItemIndex][id].push({
                  "option": value.option,
                  "order": value.order,
                  "answerKey": e?.target?.value.replace(/\s/g, "")
                });
              } else {
                // console.log("no  push")
              }
              // vtempCaseStudy[existingItemIndex][id].push({
              //   "option": value.option,
              //   "order": value.order,
              //   "answerKey": e?.target?.value.replace(/\s/g, "")
              // });
            } else {
              // console.log("3333333333", vtempCaseStudy[existingItemIndex][id])
              // console.log("2222222222222222222222222222222222222222222222222222222222222")
              vtempCaseStudy[existingItemIndex][id] = [vtempCaseStudy[existingItemIndex][id], {
                "option": value.option,
                "order": value.order,
                "answerKey": e?.target?.value.replace(/\s/g, "")
              }];
              // console.log("4444444444", vtempCaseStudy)
            }
          } else {
            // console.log("elseeeeeeeeeee")
            vtempCaseStudy.push({
              [id]: [{
                "option": value.option,
                "order": value.order,
                "answerKey": e?.target?.value.replace(/\s/g, "")
              }],
              type: "MSQ",
              currentQuestion: currentQuestion || 0, // Ensure currentQuestion is assigned
              parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id
            });
          }
          // console.log(a, "***********************************", vtempCaseStudy,)
          settempCaseStudy([...vtempCaseStudy]);
          // var tt = JSON.parse(sessionStorage.getItem("case_help")) || []
          // tt.push({
          //   [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]: {
          //     "option": value.option,
          //     "order": value.order,
          //     "answerKey": e.target.value,
          //   }
          // })
          // sessionStorage.setItem("case_help", JSON.stringify(tt))
          return updatedQuestions;
        });
      }
      else {
        // console.log("uncheck is happening")
        setAttendedCs((prevAttendedQuestions) => {
          const updatedQuestions = { ...prevAttendedQuestions }
          const newFilter = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].findIndex(itm => itm.answerKey === e.target.value)
          updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].splice(newFilter, 1);
          var tt = JSON.parse(sessionStorage.getItem("case_help")) || []
          tt.push(updatedQuestions);
          // console.log(updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id], updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id])
          // console.log("tempCaseStudy")
          var vtempCaseStudy = tempCaseStudy || [];
          const existingItemIndex = vtempCaseStudy.findIndex(item => item.hasOwnProperty(id));
          // console.log("existingItemIndex", existingItemIndex, id)
          if (existingItemIndex !== -1) {
            vtempCaseStudy[existingItemIndex][id] = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id]
          }
          // console.log(vtempCaseStudy, "vtempCaseStudyvtempCaseStudyvtempCaseStudy")
          settempCaseStudy([...vtempCaseStudy]);
          // console.log(updatedQuestions, updatedQuestions[id], resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData?.id, id, [...vtempCaseStudy])
          sessionStorage.setItem("case_help", JSON.stringify(tt))
          return updatedQuestions
        })
      }

    }

    if (obj) {
      // setCsAttentedAns([...csAttentedAns, obj]);
      setAttendCs([...attendCs, obj]);
    }
  };

  // Disable copy paste
  const handleDisableCopyPaste = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!ispreview) {
      if (attendedCs[currentQuestionData.id]) {
        const findCurrentSubQuestion = attendedCs[currentQuestionData.id];
        currentQuestionData.subQuestions.map((subItem) => {
          if (findCurrentSubQuestion.hasOwnProperty(subItem.id)) {
            let obj
            if (subItem.id.questionTypeCode === "FILL") {
              obj = {
                examId: examId,
                questionId: subItem.id,
                questionTypeCode: "FILL",
                parentQuestionId: currentQuestionData.id,
                answer: findCurrentSubQuestion[subItem.id],
                endDate: endDate,
                endTime: endTime,
              };
              obj.answer?.sort((a, b) => a.order - b.order);
            }
            else if (subItem.id.questionTypeCode === "MCQ") {
              obj = {
                examId: examId,
                questionId: subItem.id,
                questionTypeCode: "MCQ",
                parentQuestionId: currentQuestionData.id,
                answer: findCurrentSubQuestion[subItem.id],
                endDate: endDate,
                endTime: endTime,
              };
              obj.answer?.sort((a, b) => a.option - b.option);
            }
            else {
              obj = {
                examId: examId,
                questionId: subItem.id,
                questionTypeCode: "MSQ",
                parentQuestionId: currentQuestionData.id,
                answer: findCurrentSubQuestion[subItem.id],
                endDate: endDate,
                endTime: endTime,
              };
              obj.answer?.sort((a, b) => a.option - b.option);
            }
            if (obj) {
              attendCs.push(obj);
            }
          }
        })
      }

      if (resumeBtnClicked) {
        // alert(resumeBtnClicked)
        // console.log(attendedCs, currentQuestionData.questionId, currentQuestionData, "58000000000")
        if (attendedCs[currentQuestionData.questionId]) {
          const findCurrentSubQuestion = attendedCs[currentQuestionData.questionId];

          currentQuestionData.subQuestions.map((subItem) => {
            if (findCurrentSubQuestion.hasOwnProperty(subItem.questionId)) {
              let obj
              if (subItem.questionTypeCode === "FILL") {
                obj = {
                  examId: examId,
                  questionId: subItem.questionId,
                  questionTypeCode: "FILL",
                  parentQuestionId: currentQuestionData.questionId,
                  answer: findCurrentSubQuestion[subItem.questionId],
                  endDate: endDate,
                  endTime: endTime,
                }
                obj.answer?.sort((a, b) => a.order - b.order);
              }
              else if (subItem.questionTypeCode === "MCQ") {
                obj = {
                  examId: examId,
                  questionId: subItem.questionId,
                  questionTypeCode: "MCQ",
                  parentQuestionId: currentQuestionData.questionId,
                  answer: findCurrentSubQuestion[subItem.questionId],
                  endDate: endDate,
                  endTime: endTime,
                };
                obj.answer?.sort((a, b) => a.option - b.option);
              }
              else {
                obj = {
                  examId: examId,
                  questionId: subItem.questionId,
                  questionTypeCode: "MSQ",
                  parentQuestionId: currentQuestionData.questionId,
                  answer: findCurrentSubQuestion[subItem.questionId],
                  endDate: endDate,
                  endTime: endTime,
                };
                obj.answer?.sort((a, b) => a.option - b.option);
              }
              if (obj) {
                attendCs.push(obj);
                settempCaseStudy(attendCs)
              }
            }
          })
        }
        if (!attendedCs[currentQuestionData.questionId]) {
          currentQuestionData.subQuestions?.map((subItem) => {
            let obj;
            subItem.questionTypeCode === "FILL" ?
              subItem.ftbAnswerByStudent?.forEach((itm) => {
                obj = {
                  examId: examId,
                  questionId: subItem.questionId,
                  questionTypeCode: "FILL",
                  parentQuestionId: currentQuestionData.questionId,
                  answer: [],
                  endDate: endDate,
                  endTime: endTime,
                };
                obj.answer.push(itm);

                setAttendedCs((prevAttendedQuestions) => {
                  const updatedQuestions = prevAttendedQuestions;
                  if (!updatedQuestions[currentQuestionData.questionId]) {
                    updatedQuestions[currentQuestionData.questionId] = [];
                  }
                  if (!updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId)) {
                    updatedQuestions[currentQuestionData.questionId][subItem.questionId] = []
                  }
                  const foundIndex = updatedQuestions.hasOwnProperty(currentQuestionData.questionId);
                  const foundIndexofFib = updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId);
                  if (!foundIndex) {
                    updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({ "order": itm.order, "answerKey": itm.answerKey });
                  }
                  else {
                    if (foundIndexofFib) {
                      const foundIndexOrder = updatedQuestions[currentQuestionData.questionId][subItem.questionId]?.findIndex(item => item.order === itm.order);
                      if (foundIndexOrder === -1) {
                        updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({ "order": itm.order, "answerKey": itm.answerKey });
                      }
                      else {
                        updatedQuestions[currentQuestionData.questionId][subItem.questionId][foundIndexOrder] = { "order": itm.order, "answerKey": itm.answerKey };
                      }
                    }
                    else {
                      updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({ "order": itm.order, "answerKey": itm.answerKey });
                    }
                  }
                  return updatedQuestions;
                })

              })
              :
              subItem.questionTypeCode === "MCQ" ?
                subItem.mcsqAnswerByStudent?.forEach((itm) => {
                  obj = {
                    examId: examId,
                    questionId: subItem.questionId,
                    questionTypeCode: "MCQ",
                    parentQuestionId: currentQuestionData.questionId,
                    answer: [],
                    endDate: endDate,
                    endTime: endTime,
                  };
                  obj.answer.push(itm);

                  setAttendedCs((prevAttendedQuestions) => {
                    const updatedQuestions = { ...prevAttendedQuestions };
                    if (!updatedQuestions[currentQuestionData.questionId]) {
                      updatedQuestions[currentQuestionData.questionId] = [];
                    }
                    if (!updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId)) {
                      updatedQuestions[currentQuestionData.questionId][subItem.questionId] = []
                    }

                    updatedQuestions[currentQuestionData.questionId][subItem.questionId].splice(0, 1);
                    updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
                      "option": itm.option,
                      "order": itm.order,
                      "answerKey": itm.answerKey,
                    });
                    return updatedQuestions;
                  });

                })
                :
                subItem.mcsqAnswerByStudent?.forEach((itm) => {
                  obj = {
                    examId: examId,
                    questionId: subItem.questionId,
                    questionTypeCode: "MSQ",
                    parentQuestionId: currentQuestionData.questionId,
                    answer: [],
                    endDate: endDate,
                    endTime: endTime,
                  };
                  obj.answer.push(itm);

                  setAttendedCs((prevAttendedQuestions) => {
                    const updatedQuestions = { ...prevAttendedQuestions };
                    if (!updatedQuestions[currentQuestionData.questionId]) {
                      updatedQuestions[currentQuestionData.questionId] = [];
                    }
                    if (!updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId)) {
                      updatedQuestions[currentQuestionData.questionId][subItem.questionId] = []
                    }
                    const foundIndex = updatedQuestions.hasOwnProperty(currentQuestionData.questionId);
                    const foundIndexofMsq = updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId);

                    if (!foundIndex) {
                      updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
                        "option": itm.option,
                        "order": itm.order,
                        "answerKey": itm.answerKey,
                      });
                    } else {
                      if (foundIndexofMsq) {
                        const foundIndexOrder = updatedQuestions[currentQuestionData.questionId][subItem.questionId]?.findIndex(
                          (item) => item.order === itm.order
                        );
                        if (foundIndexOrder) {
                          updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
                            "option": itm.option,
                            "order": itm.order,
                            "answerKey": itm.answerKey,
                          });
                        }
                        else {
                          updatedQuestions[currentQuestionData.questionId][subItem.questionId][foundIndexOrder] = {
                            "option": itm.option,
                            "order": itm.order,
                            "answerKey": itm.answerKey,
                          };
                        }
                      }
                      else {
                        updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
                          "option": itm.option,
                          "order": itm.order,
                          "answerKey": itm.answerKey,
                        });
                      }

                    }
                    return updatedQuestions;
                  });

                })
            if (obj) {
              attendCs.push(obj);
            }
          })
        }
      }
    }
  }, [currentQuestion])

  useEffect(() => {
    if (!ispreview && resumeBtnClicked) {
      // console.log(attendedCs, tempCaseStudy, attendCs, "CASE STUDY PREPOPULATE", questions[currentQuestion])
      var vTemp = []
      attendCs?.map((v) => {
        if (v.answer) {
          v.answer.map((l) => {
            delete l?.currentQuestion;
            delete l?.index;
          })
        }
        vTemp.push({
          [v.questionId]: v.answer,
          "type": v.questionTypeCode,
          "currentQuestion": currentQuestion,
          "parentQuestionId": v.parentQuestionId
        })
      })
      // console.log("vTempvTemp", vTemp)
      settempCaseStudy(vTemp)
      // sessionStorage.setItem("case_help", JSON.stringify(tt))
    }

  }, [currentQuestion, attendedCs])

  return (

    // console.log(ispreview, resumeBtnClicked, "case studyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy"),
    ispreview ?
      <>
        <div>
          <div className="cqk_CS_div">
            {/*-------| Quiz Case-Study Para & Imge Division Starts Here |-------*/}
            <div className="cqk_Para_and_img">
              <div className="cqk_head_1">
              </div>
              <div className="cqk_Para_div_1">
                {listData?.question && <h3 id="az-qstn-text-cs" className="Paragraph_title">Question:-</h3>}
                <p
                  className="Paragraph"
                  // style={{ fontSize: "16px" }}
                  dangerouslySetInnerHTML={{
                    __html: listData?.question,
                  }}
                ></p>
                {
                  resumeBtnClicked &&
                  <>
                    {
                      //console.log("do you know", ispreview, resumeBtnClicked)
                    }
                    <p>
                      {/* {listData?.explanation && <h3 className="Paragraph_title">Description:-</h3>} */}

                    </p>
                    <p
                      className="Paragraph explain"
                      dangerouslySetInnerHTML={{
                        __html: listData?.explanation,
                      }}
                    ></p>
                    <div className="Para_div_1">
                      {listData && listData?.imageUrl && (
                        // <div>
                        <img
                          className="Cs_img"
                          src={listData?.imageUrl}
                          alt="Case-Study-Img"
                          onClick={() => {
                            setSelectedImageUrl(listData?.imageUrl);
                            setModalOpen(true);
                          }}
                        />
                        // </div>
                      )}
                    </div>
                  </>
                }

                {/* <div>
                <img className="cqk_Cs_img" src="" alt="Case-Study-Img" />
              </div> */}
              </div>

            </div>
            {/*-------| Quiz Case-Study Para & Imge Division Ends Here |------*/}

            {/*-------| Quiz Case-Study Sub-Question & Answer Division Starts Here |-------*/}
            <div className="cqk_SubQuestion_Section">
              <div className="cqk_Qstns_and_Ans">
                {/*-------|Sub-Question & Ans Division |-------*/}
                <div>
                  {listData?.subQuestions?.map((itm, index) => {
                    //console.log("item,", itm)
                    return (
                      <>
                        <div className="cqk_Img_and_Para">
                          <p
                            className="cqk_Q_para"
                            dangerouslySetInnerHTML={{
                              __html: `Q${index + 1})${itm?.question}`,
                            }}
                          ></p>

                          {/* <img className="cqk_sub_Qustn_img" src="" /> */}
                        </div>

                        {/*-------| Sub-Question Explanation |-------*/}
                        {/* p-t r-f */}
                        {
                          showExplanation == true &&
                          <div className="SubQstn_Explanation">
                            {itm?.explanation && <h3 id="az-exp-text-cs" className="Paragraph_title">Explanation:-</h3>}
                            <p
                              className="Paragraph"
                              dangerouslySetInnerHTML={{
                                __html: itm?.explanation,
                              }}
                            ></p>
                          </div>

                        }
                        {itm?.imageUrl && (
                          <img
                            className="sub_Qustn_img"
                            src={itm?.imageUrl}
                            onClick={() => {
                              setSelectedImageUrl(itm?.imageUrl);
                              setModalOpen(true);
                            }}
                          />
                        )}
                        {
                          //console.log("itm", itm)
                        }
                        {/*-------| Sub-Question Answer Fields (MSQ, MCQ, FIB) Starts Here  |-------*/}
                        <div className="cqk_Cs_Ans_Fields">
                          <div>
                            <h6 className="cqk_title_">Answer</h6>
                          </div>

                          {itm?.questionTypeCode === "FILL" ? (
                            <div>
                              <div>
                                {/*---------------------------| FIB Answer |---------------------------*/}
                                {itm?.fillInTheBlanks?.map((itm1, index) => {
                                  const name = itm?.ftbAnswerByStudent
                                    ? itm?.ftbAnswerByStudent.some(
                                      (item) => String(item?.order).toLowerCase() === String(itm1?.order).toLowerCase()
                                    )
                                    : false;

                                  const ansCheck = itm?.ftbAnswerByStudent
                                    ? itm?.ftbAnswerByStudent.some(
                                      (item) =>
                                        String(item?.answerKey).toLowerCase() == String(itm1?.answerKey[0]).toLowerCase()
                                    )
                                    : "";

                                  const answerFib = itm?.ftbAnswerByStudent?.filter(abs => abs?.order === itm1?.order)
                                  const dynamicId = `az-answer-text-cs-${index}`;
                                  var vv = name ? "Your Answer" : itm1.correctAnswer === true ? "Correct Answer" : "";
                                  return (
                                    <>
                                      <div className="cqk_CsFib_Option_and_Ans">
                                        <div className="cqk_CsFib_Option">
                                          {String.fromCharCode(64 + itm?.order)}
                                        </div>
                                        {/*-------| Displaying the FIB Answer |-------*/}
                                        <div
                                          className="cqk_CsFib_Ans_div"
                                          id={dynamicId}
                                          style={{
                                            background:
                                              ansCheck === true
                                                ? "#E7FFE8" //green
                                                : name === false
                                                  ? "white"
                                                  : "#FCD3D3", //red
                                          }}
                                        >
                                          <div className="cqk_CsFib_tick_And_Cancel_Icon">
                                            {ansCheck === true ? (
                                              <img
                                                src={tickIcon}
                                                width="22px"
                                                height="22px"
                                              />
                                            ) : name === false ? (
                                              ""
                                            ) : (
                                              <img
                                                src={cancelIcon}
                                                width="25px"
                                                height="25px"
                                              />
                                            )}
                                          </div>

                                          <div>
                                            <p>
                                              {/* {itm?.attended === false
                                              ? "Answer"
                                              : itm?.ftbAnswerByStudent &&
                                                itm?.ftbAnswerByStudent[index]
                                              ? itm?.ftbAnswerByStudent &&
                                                itm?.ftbAnswerByStudent[index]
                                                  .answerKey
                                              : "Answer"} */}
                                              {
                                                name === false
                                                  ? itm1?.answerKey[0]
                                                  : answerFib && answerFib[0].answerKey
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="cqk_CsFib_CorrectAnsLabel"
                                      >
                                        {vv}
                                      </div>
                                      {ansCheck === false && name === true && (
                                        <div className="cqk_CsFib_CorrectAnsLabel">
                                          Correct Answer: {itm1?.answerKey}
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          ) : itm?.questionTypeCode === "MCQ" ? (
                            <>
                              <div className="cqk_Ans_MCQ_cs">
                                {/*-------| Displaying the MCQ Ans |-------*/}
                                <div>
                                  {itm?.multiChoiceOrSelect.map((itm1, index) => {
                                    const name = itm?.mcsqAnswerByStudent
                                      ? itm?.mcsqAnswerByStudent.some(
                                        (item) => String(item?.option).toLowerCase() === String(itm1?.option).toLowerCase()
                                      )
                                      : false;
                                    const dynamicId = `az-answer2-text-cs-${index}`;
                                    var vv = name ? "Your Answer" : itm1.correctAnswer === true ? "Correct Answer" : "";
                                    return (
                                      <>
                                        <div
                                          className="Ans1"
                                          id={dynamicId}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background:
                                              itm1?.correctAnswer === true
                                                ? "#E7FFE8" //green
                                                : name === false
                                                  ? "white"
                                                  : "#FCD3D3", //red
                                          }}
                                        >
                                          <span>
                                            {itm1?.correctAnswer === true ? (
                                              <CheckCircleIcon
                                                style={{ color: "#4CAF50" }}
                                              />
                                            ) : name === false ? (
                                              <img
                                                src={circleIcon}
                                                width="25px"
                                                height="auto"
                                              />
                                            ) : (
                                              <CloseIcon style={{ color: "red" }} />
                                            )}
                                          </span>
                                          {itm1?.htmlTag === null && itm1?.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: itm1?.htmlTag,
                                            }}
                                          ></p>
                                        </div>
                                        <div
                                          style={{
                                            color: "#1B1B1B",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            margin: "5px 0 0 25px",
                                          }}
                                        >
                                          {vv}
                                        </div>
                                        {/* {name === true &&
                                          (
                                            <div
                                              style={{
                                                color: "#1B1B1B",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                margin: "5px 0 0 25px",
                                              }}
                                            >
                                              Your Answer
                                            </div>
                                          )} */}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="cqk_Ans1_MSQ_cs">
                                <div>
                                  {/*---------------------------| Displaying MSQ Answer |---------------------------*/}
                                  {itm?.multiChoiceOrSelect?.map((itm1, index) => {
                                    const name = itm?.mcsqAnswerByStudent
                                      ? itm?.mcsqAnswerByStudent.some(
                                        (item) => String(item?.option).toLowerCase() === String(itm1?.option).toLowerCase()
                                      )
                                      : false;
                                    const dynamicId = `az-answer1-text-cs-${index}`;
                                    var vv = name ? "Your Answer" : itm1.correctAnswer === true ? "Correct Answer" : "";
                                    return (
                                      <>
                                        <div
                                          className="Ans1_MSQ"
                                          id={dynamicId}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background:
                                              itm1?.correctAnswer === true
                                                ? "#E7FFE8" //green
                                                : name === false
                                                  ? "white"
                                                  : "#FCD3D3", //red
                                          }}
                                        >
                                          <span>
                                            {itm1?.correctAnswer === true ? (
                                              <img
                                                src={checkboxGreen}
                                                width="20px"
                                                height="20px"
                                              />
                                            ) : name === false ? (
                                              <img
                                                src={checkboxPlain}
                                                width="20px"
                                                height="20px"
                                              />
                                            ) : (
                                              <img
                                                src={checkboxCross}
                                                width="20px"
                                                height="20px"
                                              />
                                            )}
                                          </span>
                                          {itm1?.htmlTag === null && itm1?.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: itm1?.htmlTag,
                                            }}
                                          ></p>
                                        </div>
                                        {/* {name === true && (
                                          <div
                                            style={{
                                              color: "#1B1B1B",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                              margin: "5px 0 0 25px",
                                            }}
                                          >
                                            Your Answer
                                          </div>
                                        )} */}
                                        <div
                                          style={{
                                            color: "#1B1B1B",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            margin: "5px 0 0 25px",
                                          }}
                                        >
                                          {vv}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/*-------| Sub-Question Answer Fields (MSQ, MCQ, FIB) Ends Here  |-------*/}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/*-------| Quiz Case-Study Sub-Question & Answer Division Ends Here |-------*/}
          </div>
        </div>
      </> :
      <>
        <div style={{ overflowY: "scroll" }}>
          {/*---------------------------| Quiz Case-Study Division Starts Here |---------------------------*/}
          <div className="CS_div">
            {/*---------------------------| Quiz Case-Study Para & Imge Division Starts Here |---------------------------*/}
            <div className="Para_and_img" id="az-qstn-text-cs">
              {questions[currentQuestion]?.question && <h3 className="Paragraph_title">Question:-</h3>}
              <p
                className="Paragraph"
                id="az-student-quiz-text"
                dangerouslySetInnerHTML={{
                  __html: questions[currentQuestion]?.question,
                }}
              ></p>
              {
                questions[currentQuestion]?.question && !ispreview && !resumeBtnClicked &&
                // <h3 className="Paragraph_title">Explanation:-</h3>
                <></>
              }
              {
                (!ispreview && !resumeBtnClicked) &&
                <p
                  className="Paragraph"
                  id="az-student-quiz-text"
                  dangerouslySetInnerHTML={{
                    __html: questions[currentQuestion]?.explanation,
                  }}
                ></p>
              }
            </div>
            {/*---------------------------| Quiz Case-Study Para & Imge Division Ends Here |---------------------------*/}

            {/*---------------------------| Quiz Case-Study Question & Answer Division Starts Here |---------------------------*/}
            <div className="SubQuestion_Section">
              <div className="Qstns_and_Ans">
                {/*---------------------------| Question and Ans Division-1 |---------------------------*/}
                {!resumeBtnClicked ?
                  <div>
                    {questions &&
                      questions[currentQuestion]?.subQuestions?.map(
                        (subItem, subIndex) => {
                          const selectedOption = selectedOptions[subIndex];
                          return (
                            <>
                              <div className="Img_and_Para" style={{ display: 'flex' }}>
                                <p
                                  className="Q_para"
                                  style={{ display: 'flex' }}
                                  dangerouslySetInnerHTML={{
                                    __html: `<span style="font-weight: bold;color:black">Q${subIndex + 1})</span> <span style="margin-left: 8px;">${subItem.question}</span>`,
                                  }}
                                ></p>
                                {subItem.imageUrl && (
                                  <img
                                    className="sub_Qustn_img"
                                    src={subItem.imageUrl}
                                    onClick={() => {
                                      setSelectedImageUrl(subItem.imageUrl);
                                      setModalOpen(true);
                                    }}
                                  />
                                )}
                              </div>


                              <div className="Cs_Ans_Fields">
                                <div>
                                  <h6 className="title_">Answer</h6>
                                </div>
                                {/*---------------------------| Ans |---------------------------*/}
                                {
                                  //console.log("subItem", subItem)
                                }
                                {subItem?.options[0]?.length >= 0 &&
                                  subItem?.options[0]?.map((value, optionIndex) => {
                                    const ans = attendCs?.map(
                                      (itm) => {
                                        if (itm.questionId === subItem.id && subItem.questionTypeCode === "MCQ") {
                                          return itm
                                        }
                                      }
                                    ).filter(Boolean)
                                    const name = Object.keys(attendedCs).length > 0
                                      && attendedCs[currentQuestionData.id]?.[subItem.id]?.some(itm => itm.order === value.order);

                                    const answer = Object.keys(attendedCs).length > 0
                                      && attendedCs[currentQuestionData.id]?.[subItem.id]?.find(itm => itm.order === value.order);

                                    const isCheckedMcq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.id] ? attendedCs[currentQuestionData.id][subItem.id] ? attendedCs[currentQuestionData.id][subItem.id].findIndex(
                                      (item) => item.option === value.option
                                    )
                                      :
                                      -1
                                      :
                                      -1
                                      :
                                      -1;

                                    const isCheckedMsq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.id] ? attendedCs[currentQuestionData.id][subItem.id] ? attendedCs[currentQuestionData.id][subItem.id].some(
                                      (item) => item.option === value.option
                                    )
                                      :
                                      false
                                      :
                                      false
                                      :
                                      false;
                                    return subItem.questionTypeCode === "FILL" ? (
                                      <div
                                        className="Option_and_Ans_cs"
                                      // key={value.order}
                                      >
                                        <input
                                          className="Ans1_FIB_cs"
                                          placeholder="Answer"
                                          type="text"
                                          name={value.order}
                                          value={
                                            Object.keys(attendedCs).length > 0
                                              ? attendedCs[currentQuestionData.id]
                                                ? attendedCs[currentQuestionData.id][subItem.id]
                                                  ? name
                                                    ? answer.answerKey
                                                    : ""
                                                  : ""
                                                : ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleSavingAns(
                                              e,
                                              subItem.id,
                                              "fill",
                                              value
                                            )
                                          }
                                          onMouseEnter={handleMouseEnter}
                                          onMouseLeave={handleMouseLeave}
                                          style={{
                                            border: `1px solid ${hoveredFIB ? 'orange' : 'grey'}`,
                                            color: 'orange',
                                          }}

                                          // Disable copy paste
                                          onCut={handleDisableCopyPaste}
                                          onCopy={handleDisableCopyPaste}
                                          onPaste={handleDisableCopyPaste}
                                        />

                                        {/* <Student_Enrich_Quiz_FIB
                                          questions={questions}
                                          resumeBtnClicked={resumeBtnClicked}
                                          currentQuestion={currentQuestion}
                                          setAttendFib={setAttendFib}
                                          attendFib={attendFib}
                                          setAttendedFib={setAttendedFib}
                                          attendedFib={attendedFib}
                                          from={"CASE"}
                                          subItem={subItem}
                                          Vvalue={value}
                                          attendCs={attendCs}
                                          setAttendCs={setAttendCs}
                                          setCommonAttendedData={setCommonAttendedData}
                                          commonAttendedData={commonAttendedData}

                                          tempCaseStudy={tempCaseStudy}
                                          settempCaseStudy={settempCaseStudy}
                                          attendedCs={attendedCs}
                                          setAttendedCs={setAttendedCs}
                                        /> */}


                                      </div>
                                    ) : subItem.questionTypeCode === "MCQ" ? (
                                      <>
                                        {/* <div
                                          className="Ans_MCQ_cs"
                                          key={value.option}
                                        >
                                         
                                          <CustomRadio
                                            value={value.answerKey}
                                            sx={{
                                              color: "#EEF0F9",
                                            }}
                                            id={`checkbox-${subIndex}-${optionIndex}`}
                                            checked={isCheckedMcq !== -1}
                                            onChange={(e) =>
                                              handleSavingAns(
                                                e,
                                                subItem.id,
                                                "mcq",
                                                value
                                              )
                                            }
                                          />
                                          {value.htmlTag === null && value.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: value.htmlTag,
                                            }}
                                          ></p>
                                        </div> */}
                                        <div
                                          className="Ans_MCQ_cs"
                                          key={value.option}
                                          style={{ display: "flex", alignItems: "center" }}
                                        >
                                          <CustomRadio
                                            value={value.answerKey}
                                            // sx={{
                                            //   color: "#EEF0F9",
                                            // }}


                                            id={`checkbox-${subIndex}-${optionIndex}`}
                                            checked={isCheckedMcq !== -1}
                                            onChange={(e) =>
                                              handleSavingAns(e, subItem.id, "mcq", value)
                                            }
                                          />
                                          {value.htmlTag === null && value.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: value.htmlTag,
                                            }}
                                            style={{ marginLeft: "8px" }}
                                          ></p>
                                        </div>




                                        {/* <Student_Enrich_Quiz_MCQ
                                          questions={questions}
                                          resumeBtnClicked={resumeBtnClicked}
                                          currentQuestion={currentQuestion}
                                          setAttendMcq={setAttendMcq}
                                          attendMcq={attendMcq}
                                          setAttendedMcq={setAttendedMcq}
                                          attendedMcq={attendedMcq}
                                          from={"CASE"}
                                          subItem={subItem}
                                          Vvalue={value}
                                          attendCs={attendCs}
                                          setAttendCs={setAttendCs}
                                          setCommonAttendedData={setCommonAttendedData}
                                          commonAttendedData={commonAttendedData}
                                        /> */}
                                      </>
                                    ) : (
                                      // <>
                                      //   <Student_Enrich_Quiz_MSQ
                                      //     questions={questions}
                                      //     resumeBtnClicked={resumeBtnClicked}
                                      //     currentQuestion={currentQuestion}
                                      //     setAttendMsq={setAttendMsq}
                                      //     attendMsq={attendMsq}
                                      //     setAttendedMsq={setAttendedMsq}
                                      //     attendedMsq={attendedMsq}
                                      //     from={"CASE"}
                                      //     subItem={subItem}
                                      //     Vvalue={value}
                                      //     setCommonAttendedData={setCommonAttendedData}
                                      //     commonAttendedData={commonAttendedData}


                                      //     tempCaseStudy={tempCaseStudy}
                                      //     settempCaseStudy={settempCaseStudy}
                                      //     attendedCs={attendedCs}
                                      //     setAttendedCs={setAttendedCs}
                                      //   />
                                      // </>
                                      <>
                                        {/* <div
                                          className="Ans1_MSQ_cs"
                                          key={optionIndex}
                                        >
                                          <Checkbox
                                            value={value.option}
                                            size="small"
                                            checked={isCheckedMsq}
                                            sx={{
                                              color: "#001AF3",
                                              "&.Mui-checked": {
                                                color: "#001AF3",
                                              },
                                            }}
                                            onChange={(e) =>
                                              handleSavingAns(
                                                e,
                                                subItem.id,
                                                "msq",
                                                value
                                              )
                                            }
                                          />
                                          {value.htmlTag === null && value.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: value.htmlTag,
                                            }}
                                          ></p>
                                        </div> */}
                                        <div
                                          className="Ans1_MSQ_cs"
                                          key={optionIndex}
                                          style={{ display: "flex", alignItems: "center" }}
                                        >
                                          <Checkbox
                                            value={value.option}
                                            size="small"
                                            checked={isCheckedMsq}
                                            sx={{
                                              color: "grey",
                                              "&.Mui-checked": {
                                                color: "orange",
                                              },
                                              "&:hover": {
                                                borderColor: "orange",
                                              },
                                            }}
                                            onChange={(e) =>
                                              handleSavingAns(e, subItem.id, "msq", value)
                                            }
                                          />
                                          {value.htmlTag === null && value.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: value.htmlTag,
                                            }}
                                            style={{ marginLeft: "8px" }}
                                          ></p>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  :
                  <div>
                    {questions &&
                      questions[currentQuestion]?.subQuestions?.map(
                        (subItem, subIndex) => {
                          const selectedOption = selectedOptions[subIndex];
                          // const ans = attendCs?.map(
                          //   (itm) => {
                          //     if (itm.questionId === subItem.id && subItem.questionTypeCode === "MCQ") {
                          //       return itm
                          //     }
                          //   }
                          // ).filter(Boolean)

                          return (
                            <>
                              <div className="Img_and_Para">
                                <p
                                  className="Q_para"
                                  dangerouslySetInnerHTML={{
                                    __html: `Q${subIndex + 1})${subItem.question}`,
                                  }}
                                ></p>
                                {subItem.imageUrl && (
                                  <img
                                    className="sub_Qustn_img"
                                    src={subItem.imageUrl}
                                    onClick={() => {
                                      setSelectedImageUrl(subItem.imageUrl);
                                      setModalOpen(true);
                                    }}
                                  />
                                )}
                              </div>

                              <div className="Cs_Ans_Fields">
                                <div>
                                  <h6 className="title_">Answer</h6>
                                </div>
                                {/*---------------------------| Ans |---------------------------*/}

                                {subItem.questionTypeCode === "FILL" ? (
                                  subItem?.fillInTheBlanks?.map(itm => {
                                    const name = Object.keys(attendedCs).length > 0
                                      && attendedCs[currentQuestionData.questionId]?.[subItem.questionId]?.some(itm1 => itm1.order === itm.order);

                                    const answer = Object.keys(attendedCs).length > 0
                                      && attendedCs[currentQuestionData.questionId]?.[subItem.questionId]?.find(itm1 => itm1.order === itm.order);
                                    return (
                                      <div
                                        className="Option_and_Ans_cs"
                                        key={itm.order}
                                      >
                                        <input
                                          className="Ans1_FIB_cs"
                                          placeholder="Answer"
                                          type="text"
                                          name={itm.order}
                                          value={
                                            Object.keys(attendedCs).length > 0
                                              ? attendedCs[currentQuestionData.questionId]
                                                ? attendedCs[currentQuestionData.questionId][subItem.questionId]
                                                  ? name
                                                    ? answer.answerKey
                                                    : ""
                                                  : ""
                                                : ""
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleSavingAns(
                                              e,
                                              subItem.questionId,
                                              "fill",
                                              itm
                                            )
                                          }
                                          // Disable copy paste
                                          onCut={handleDisableCopyPaste}
                                          onCopy={handleDisableCopyPaste}
                                          onPaste={handleDisableCopyPaste}
                                        />
                                      </div>
                                    )
                                  })
                                ) : subItem.questionTypeCode === "MCQ" ? (
                                  subItem.multiChoiceOrSelect.map((itm, index) => {
                                    const isCheckedMcq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId].findIndex(
                                      (item) => item.option === itm.option
                                    )
                                      :
                                      -1
                                      :
                                      -1
                                      :
                                      -1;
                                    return (
                                      <>
                                        <div
                                          className="Ans_MCQ_cs"
                                          key={itm.option}
                                        >
                                          <CustomRadio
                                            value={itm.answerKey}
                                            sx={{
                                              color: "#EEF0F9",
                                            }}
                                            id={`checkbox-${subIndex}-${index}`}
                                            // checked={attendCs?.some(
                                            //   (itm) =>
                                            //     itm.answer[0]?.answerKey ===
                                            //     value.answerKey
                                            // )}
                                            // checked={ans[0] ? ans[0].answer?.some(itm => itm.answerKey === value.answerKey) : false}
                                            checked={isCheckedMcq !== -1}
                                            onChange={(e) =>
                                              handleSavingAns(
                                                e,
                                                subItem.questionId,
                                                "mcq",
                                                itm
                                              )
                                            }
                                          />
                                          {itm.htmlTag === null && itm.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: itm.htmlTag,
                                            }}
                                          ></p>
                                        </div>
                                      </>
                                    )
                                  })
                                ) : (
                                  subItem.multiChoiceOrSelect.map(itm => {
                                    const isCheckedMsq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId].some(
                                      (item) => item.option === itm.option
                                    )
                                      :
                                      false
                                      :
                                      false
                                      :
                                      false;
                                    return (
                                      <>
                                        <div
                                          className="Ans1_MSQ_cs"
                                          key={itm.option}
                                        >
                                          <Checkbox
                                            value={itm.answerKey}
                                            size="small"
                                            checked={isCheckedMsq}
                                            sx={{
                                              color: "#001AF3",
                                              "&.Mui-checked": {
                                                color: "#001AF3",
                                              },
                                            }}
                                            onChange={(e) =>
                                              handleSavingAns(
                                                e,
                                                subItem.questionId,
                                                "msq",
                                                itm
                                              )
                                            }
                                          />
                                          {itm.htmlTag === null && itm.answerKey}
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: itm.htmlTag,
                                            }}
                                          ></p>
                                        </div>
                                      </>
                                    )
                                  })
                                )
                                  // })}
                                }
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                }
              </div>
            </div>
          </div>
          {/*---------------------------| Quiz Case-Study Division Ends Here |---------------------------*/}
        </div>
        <Student_Image_Open_Modal
          selectedImageUrl={selectedImageUrl}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </>
  );
}

export default Student_Enrich_Quiz_CS;


// //
// // --- ❗❕❗ CS-> Case Study ❗❕❗ --- //
// //
// import React, { useEffect, useState } from "react";

// // Css Pages
// import "../../css/Student/Student_Enrich_Quiz_CS.css";

// // Mui
// import Checkbox from "@mui/material/Checkbox";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
// import Student_Image_Open_Modal from "./Student_Image_Open_Modal";
// import Student_Enrich_Quiz_FIB from "./Student_Enrich_Quiz_FIB"
// import Student_Enrich_Quiz_MCQ from './Student_Enrich_Quiz_MCQ'
// import Student_Enrich_Quiz_MSQ from './Student_Enrich_Quiz_MSQ'
// import Radio from '@mui/material/Radio';
// import QuizCommonComponent from './Quiz_Common_component'

// function CustomRadio(props) {
//   return (
//     <Radio
//       color="default"
//       checkedIcon={<CheckCircleIcon
//         style={{
//           color: "#4CAF50",
//         }}
//       />}
//       icon={<RadioButtonUncheckedIcon />}
//       {...props}
//     />
//   );
// }

// function Student_Enrich_Quiz_CS({
//   questions,
//   currentQuestion,
//   setAttendCs,
//   attendCs,
//   setAttendedCs,
//   attendedCs,
//   examId,
//   parentQuestionId,
//   resumeBtnClicked,
//   endDate,
//   endTime,
//   setAttendFib,
//   attendFib,
//   setAttendedFib,
//   attendedFib,
//   setAttendMcq,
//   attendMcq,
//   setAttendedMcq,
//   attendedMcq,
//   setAttendMsq,
//   attendMsq,
//   setAttendedMsq,
//   attendedMsq,
//   setCommonAttendedData,
//   commonAttendedData

// }) {
//   //console.log(questions[currentQuestion], attendCs, attendedCs, examId, parentQuestionId, resumeBtnClicked, endDate, endTime, attendFib, attendedFib, attendMcq, attendedMcq, attendMsq, attendedMsq)
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [selectedImageUrl, setSelectedImageUrl] = useState("");
//   const [csAttentedAns, setCsAttentedAns] = useState([]);
//   const currentQuestionData = questions[currentQuestion];

//   Array.from(
//     { length: questions[currentQuestion]?.subQuestions?.length },
//     () => null
//   );

//   const handleOptionChange = (subQuestionIndex, optionIndex) => {
//     setSelectedOptions((prevSelectedOptions) => {
//       const updatedSelectedOptions = [...prevSelectedOptions];
//       updatedSelectedOptions[subQuestionIndex] = optionIndex;
//       return updatedSelectedOptions;
//     });
//   };

//   const handleSavingAns = (e, id, type, value) => {
//     let obj;
//     const existedVal = attendCs.findIndex((itm) => {
//       return itm.questionId === id;
//     });
//     const existedOrder =
//       attendCs[existedVal] &&
//       attendCs[existedVal].answer.length > 0 &&
//       attendCs[existedVal].answer.findIndex((itm) => {
//         return itm.order === value.order;
//       });
//     if (type === "fill") {
//       if (existedVal !== -1) {
//         if (existedOrder !== -1) {

//           attendCs[existedVal].answer[existedOrder].answerKey = e.target.value;

//           if (attendCs[existedVal].answer[existedOrder].answerKey === "") {
//             attendCs[existedVal].answer.splice(existedOrder, 1);
//           }

//           if (attendCs[existedVal].answer.length === 0) {
//             attendCs.splice(existedVal, 1);
//           }
//         } else {
//           const obj = {
//             order: value.order,
//             answerKey: e.target.value,
//           };

//           attendCs[existedVal].answer.push(obj);
//         }
//       } else {
//         obj = {
//           examId: examId,
//           questionId: id,
//           questionTypeCode: "FILL",
//           // parentQuestionId: questions[currentQuestion]?.id,
//           parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData?.id,
//           answer: [
//             {
//               order: value.order,
//               answerKey: e.target.value,
//             },
//           ],
//           endDate: endDate,
//           endTime: endTime,
//         };
//       }
//       setAttendedCs((prevAttendedQuestions) => {
//         const updatedQuestions = prevAttendedQuestions;
//         if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
//           updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
//         }
//         if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id)) {
//           updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id] = []
//         }
//         // const foundIndex = Object.keys(updatedQuestions).findIndex(itm => itm.id === resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id);
//         const foundIndex = updatedQuestions.hasOwnProperty(resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id);
//         const foundIndexofFib = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id);
//         if (!foundIndex) {
//           updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({ "order": value.order, "answerKey": e.target.value });
//         }
//         else {
//           if (foundIndexofFib) {
//             const foundIndexOrder = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id]?.findIndex(itm => itm.order === value.order);
//             if (foundIndexOrder === -1) {
//               updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({ "order": value.order, "answerKey": e.target.value });
//             }
//             else {
//               updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id][foundIndexOrder] = { "order": value.order, "answerKey": e.target.value };
//             }
//           }
//           else {
//             updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({ "order": value.order, "answerKey": e.target.value });
//           }
//         }
//         return updatedQuestions;
//       })
//     } else if (type === "mcq") {
//       if (existedVal !== -1) {

//         attendCs[existedVal].answer[0].answerKey = e.target.value;
//         attendCs[existedVal].answer[0].option = value.option;
//         attendCs[existedVal].answer[0].order = value.order;
//       } else {
//         obj = {
//           examId: examId,
//           questionId: id,
//           questionTypeCode: "MCQ",
//           parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData?.id,
//           answer: [
//             {
//               option: value.option,
//               order: value.order,
//               answerKey: e.target.value,
//             },
//           ],
//           endDate: endDate,
//           endTime: endTime,
//         };
//       }

//       setAttendedCs((prevAttendedQuestions) => {
//         const updatedQuestions = { ...prevAttendedQuestions };
//         if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
//           updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
//         }
//         if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id)) {
//           updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id] = []
//         }

//         updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].splice(0, 1);
//         updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
//           "option": value.option,
//           "order": value.order,
//           "answerKey": e.target.value,
//         });
//         return updatedQuestions;
//       });

//     } else {
//       if (existedVal !== -1) {
//         if (e.target.checked) {
//           if (existedOrder !== -1) {

//             attendCs[existedVal].answer[existedOrder].answerKey =
//               e.target.value;
//           } else {
//             const obj = {
//               option: value.option,
//               order: value.order,
//               answerKey: e.target.value,
//             };

//             attendCs[existedVal].answer.push(obj);

//             const removeDuplicateObjects = (arr, prop) => {
//               const uniqueObjects = [];
//               const encounteredValues = new Set();
//               for (const obj of arr) {
//                 const value = obj[prop];
//                 if (!encounteredValues.has(value)) {
//                   encounteredValues.add(value);
//                   uniqueObjects.push(obj);
//                 }
//               }

//               return uniqueObjects;
//             };

//             const uniqueData = removeDuplicateObjects(attendCs[existedVal].answer, 'order');
//             // csAttentedAns[existedVal].answer = uniqueData;
//             attendCs[existedVal].answer = uniqueData;
//           }
//         } else {
//           // csAttentedAns[existedVal].answer.splice(existedOrder, 1);
//           attendCs[existedVal].answer.splice(existedOrder, 1);
//           if (attendCs[existedVal].answer.length === 0) {
//             // csAttentedAns.splice(existedVal, 1);
//             attendCs.splice(existedVal, 1);
//           }
//         }
//       } else {
//         obj = {
//           examId: examId,
//           questionId: id,
//           questionTypeCode: "MSQ",
//           parentQuestionId: resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData?.id,
//           answer: [
//             {
//               option: value?.option,
//               order: value?.order,
//               answerKey: e.target.value,
//             },
//           ],
//           endDate: endDate,
//           endTime: endTime,
//         };
//       }

//       if (e.target.checked) {

//         setAttendedCs((prevAttendedQuestions) => {
//           const updatedQuestions = { ...prevAttendedQuestions };
//           if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
//             updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
//           }
//           if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id)) {
//             updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id] = []
//           }
//           const foundIndex = updatedQuestions.hasOwnProperty(resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id);
//           const foundIndexofMsq = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].hasOwnProperty(id);

//           if (!foundIndex) {
//             updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
//               "option": value.option,
//               "order": value.order,
//               "answerKey": e.target.value,
//             });
//           } else {
//             if (foundIndexofMsq) {
//               const foundIndexOrder = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id]?.findIndex(
//                 (item) => item.order === value.order
//               );
//               if (foundIndexOrder) {
//                 updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
//                   "option": value.option,
//                   "order": value.order,
//                   "answerKey": e.target.value,
//                 });
//               }
//               else {
//                 updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id][foundIndexOrder] = {
//                   "option": value.option,
//                   "order": value.order,
//                   "answerKey": e.target.value,
//                 };
//               }
//             }
//             else {
//               updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].push({
//                 "option": value.option,
//                 "order": value.order,
//                 "answerKey": e.target.value,
//               });
//             }

//           }
//           return updatedQuestions;
//         });
//       }
//       else {
//         setAttendedCs((prevAttendedQuestions) => {
//           const updatedQuestions = { ...prevAttendedQuestions }
//           const newFilter = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].findIndex(itm => itm.answerKey === e.target.value)
//           updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][id].splice(newFilter, 1);
//           return updatedQuestions
//         })
//       }

//     }

//     if (obj) {
//       // setCsAttentedAns([...csAttentedAns, obj]);
//       setAttendCs([...attendCs, obj]);
//     }
//   };

//   // Disable copy paste
//   const handleDisableCopyPaste = (e) => {
//     e.preventDefault();
//   };

//   useEffect(() => {

//     if (attendedCs[currentQuestionData.id]) {
//       const findCurrentSubQuestion = attendedCs[currentQuestionData.id];
//       currentQuestionData.subQuestions.map((subItem) => {
//         if (findCurrentSubQuestion.hasOwnProperty(subItem.id)) {
//           let obj
//           if (subItem.id.questionTypeCode === "FILL") {
//             obj = {
//               examId: examId,
//               questionId: subItem.id,
//               questionTypeCode: "FILL",
//               parentQuestionId: currentQuestionData.id,
//               answer: findCurrentSubQuestion[subItem.id],
//               endDate: endDate,
//               endTime: endTime,
//             };
//             obj.answer?.sort((a, b) => a.order - b.order);
//           }
//           else if (subItem.id.questionTypeCode === "MCQ") {
//             obj = {
//               examId: examId,
//               questionId: subItem.id,
//               questionTypeCode: "MCQ",
//               parentQuestionId: currentQuestionData.id,
//               answer: findCurrentSubQuestion[subItem.id],
//               endDate: endDate,
//               endTime: endTime,
//             };
//             obj.answer?.sort((a, b) => a.option - b.option);
//           }
//           else {
//             obj = {
//               examId: examId,
//               questionId: subItem.id,
//               questionTypeCode: "MSQ",
//               parentQuestionId: currentQuestionData.id,
//               answer: findCurrentSubQuestion[subItem.id],
//               endDate: endDate,
//               endTime: endTime,
//             };
//             obj.answer?.sort((a, b) => a.option - b.option);
//           }
//           if (obj) {
//             attendCs.push(obj);
//           }
//         }
//       })
//     }

//     if (resumeBtnClicked) {
//       if (attendedCs[currentQuestionData.questionId]) {
//         const findCurrentSubQuestion = attendedCs[currentQuestionData.questionId];

//         currentQuestionData.subQuestions.map((subItem) => {
//           if (findCurrentSubQuestion.hasOwnProperty(subItem.questionId)) {
//             let obj
//             if (subItem.questionTypeCode === "FILL") {
//               obj = {
//                 examId: examId,
//                 questionId: subItem.questionId,
//                 questionTypeCode: "FILL",
//                 parentQuestionId: currentQuestionData.questionId,
//                 answer: findCurrentSubQuestion[subItem.questionId],
//                 endDate: endDate,
//                 endTime: endTime,
//               }
//               obj.answer?.sort((a, b) => a.order - b.order);
//             }
//             else if (subItem.questionTypeCode === "MCQ") {
//               obj = {
//                 examId: examId,
//                 questionId: subItem.questionId,
//                 questionTypeCode: "MCQ",
//                 parentQuestionId: currentQuestionData.questionId,
//                 answer: findCurrentSubQuestion[subItem.questionId],
//                 endDate: endDate,
//                 endTime: endTime,
//               };
//               obj.answer?.sort((a, b) => a.option - b.option);
//             }
//             else {
//               obj = {
//                 examId: examId,
//                 questionId: subItem.questionId,
//                 questionTypeCode: "MSQ",
//                 parentQuestionId: currentQuestionData.questionId,
//                 answer: findCurrentSubQuestion[subItem.questionId],
//                 endDate: endDate,
//                 endTime: endTime,
//               };
//               obj.answer?.sort((a, b) => a.option - b.option);
//             }
//             if (obj) {
//               attendCs.push(obj);
//             }
//           }
//         })
//       }
//       if (!attendedCs[currentQuestionData.questionId]) {
//         currentQuestionData.subQuestions?.map((subItem) => {
//           let obj;
//           subItem.questionTypeCode === "FILL" ?
//             subItem.ftbAnswerByStudent?.forEach((itm) => {
//               obj = {
//                 examId: examId,
//                 questionId: subItem.questionId,
//                 questionTypeCode: "FILL",
//                 parentQuestionId: currentQuestionData.questionId,
//                 answer: [],
//                 endDate: endDate,
//                 endTime: endTime,
//               };
//               obj.answer.push(itm);

//               setAttendedCs((prevAttendedQuestions) => {
//                 const updatedQuestions = prevAttendedQuestions;
//                 if (!updatedQuestions[currentQuestionData.questionId]) {
//                   updatedQuestions[currentQuestionData.questionId] = [];
//                 }
//                 if (!updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId)) {
//                   updatedQuestions[currentQuestionData.questionId][subItem.questionId] = []
//                 }
//                 const foundIndex = updatedQuestions.hasOwnProperty(currentQuestionData.questionId);
//                 const foundIndexofFib = updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId);
//                 if (!foundIndex) {
//                   updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({ "order": itm.order, "answerKey": itm.answerKey });
//                 }
//                 else {
//                   if (foundIndexofFib) {
//                     const foundIndexOrder = updatedQuestions[currentQuestionData.questionId][subItem.questionId]?.findIndex(item => item.order === itm.order);
//                     if (foundIndexOrder === -1) {
//                       updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({ "order": itm.order, "answerKey": itm.answerKey });
//                     }
//                     else {
//                       updatedQuestions[currentQuestionData.questionId][subItem.questionId][foundIndexOrder] = { "order": itm.order, "answerKey": itm.answerKey };
//                     }
//                   }
//                   else {
//                     updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({ "order": itm.order, "answerKey": itm.answerKey });
//                   }
//                 }
//                 return updatedQuestions;
//               })

//             })
//             :
//             subItem.questionTypeCode === "MCQ" ?
//               subItem.mcsqAnswerByStudent?.forEach((itm) => {
//                 obj = {
//                   examId: examId,
//                   questionId: subItem.questionId,
//                   questionTypeCode: "MCQ",
//                   parentQuestionId: currentQuestionData.questionId,
//                   answer: [],
//                   endDate: endDate,
//                   endTime: endTime,
//                 };
//                 obj.answer.push(itm);

//                 setAttendedCs((prevAttendedQuestions) => {
//                   const updatedQuestions = { ...prevAttendedQuestions };
//                   if (!updatedQuestions[currentQuestionData.questionId]) {
//                     updatedQuestions[currentQuestionData.questionId] = [];
//                   }
//                   if (!updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId)) {
//                     updatedQuestions[currentQuestionData.questionId][subItem.questionId] = []
//                   }

//                   updatedQuestions[currentQuestionData.questionId][subItem.questionId].splice(0, 1);
//                   updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
//                     "option": itm.option,
//                     "order": itm.order,
//                     "answerKey": itm.answerKey,
//                   });
//                   return updatedQuestions;
//                 });

//               })
//               :
//               subItem.mcsqAnswerByStudent?.forEach((itm) => {
//                 obj = {
//                   examId: examId,
//                   questionId: subItem.questionId,
//                   questionTypeCode: "MSQ",
//                   parentQuestionId: currentQuestionData.questionId,
//                   answer: [],
//                   endDate: endDate,
//                   endTime: endTime,
//                 };
//                 obj.answer.push(itm);

//                 setAttendedCs((prevAttendedQuestions) => {
//                   const updatedQuestions = { ...prevAttendedQuestions };
//                   if (!updatedQuestions[currentQuestionData.questionId]) {
//                     updatedQuestions[currentQuestionData.questionId] = [];
//                   }
//                   if (!updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId)) {
//                     updatedQuestions[currentQuestionData.questionId][subItem.questionId] = []
//                   }
//                   const foundIndex = updatedQuestions.hasOwnProperty(currentQuestionData.questionId);
//                   const foundIndexofMsq = updatedQuestions[currentQuestionData.questionId].hasOwnProperty(subItem.questionId);

//                   if (!foundIndex) {
//                     updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
//                       "option": itm.option,
//                       "order": itm.order,
//                       "answerKey": itm.answerKey,
//                     });
//                   } else {
//                     if (foundIndexofMsq) {
//                       const foundIndexOrder = updatedQuestions[currentQuestionData.questionId][subItem.questionId]?.findIndex(
//                         (item) => item.order === itm.order
//                       );
//                       if (foundIndexOrder) {
//                         updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
//                           "option": itm.option,
//                           "order": itm.order,
//                           "answerKey": itm.answerKey,
//                         });
//                       }
//                       else {
//                         updatedQuestions[currentQuestionData.questionId][subItem.questionId][foundIndexOrder] = {
//                           "option": itm.option,
//                           "order": itm.order,
//                           "answerKey": itm.answerKey,
//                         };
//                       }
//                     }
//                     else {
//                       updatedQuestions[currentQuestionData.questionId][subItem.questionId].push({
//                         "option": itm.option,
//                         "order": itm.order,
//                         "answerKey": itm.answerKey,
//                       });
//                     }

//                   }
//                   return updatedQuestions;
//                 });

//               })
//           if (obj) {
//             attendCs.push(obj);
//           }
//         })
//       }
//     }
//   }, [currentQuestion])

//   return (
//     <>
//       <div>
//         {/*---------------------------| Quiz Case-Study Division Starts Here |---------------------------*/}
//         <div className="CS_div">
//           {/*---------------------------| Quiz Case-Study Para & Imge Division Starts Here |---------------------------*/}
//           <div className="Para_and_img">
//             <div className="Para_div_1">
//               <p
//                 className="Para"
//                 dangerouslySetInnerHTML={{
//                   __html: questions[currentQuestion]?.question,
//                 }}
//               ></p>
//               <p
//                 className="Cs_Explanation"
//                 dangerouslySetInnerHTML={{
//                   __html: questions[currentQuestion]?.explanation,
//                 }}
//               ></p>
//               {questions && questions[currentQuestion]?.imageUrl && (
//                 <div>
//                   <img
//                     className="Cs_img"
//                     src={questions[currentQuestion]?.imageUrl}
//                     alt="Case-Study-Img"
//                     onClick={() => {
//                       setSelectedImageUrl(questions[currentQuestion]?.imageUrl);
//                       setModalOpen(true);
//                     }}
//                   />
//                 </div>
//               )}
//             </div>
//           </div>
//           {/*---------------------------| Quiz Case-Study Para & Imge Division Ends Here |---------------------------*/}

//           {/*---------------------------| Quiz Case-Study Question & Answer Division Starts Here |---------------------------*/}
//           <div className="SubQuestion_Section">
//             <div className="Qstns_and_Ans">
//               {/*---------------------------| Question and Ans Division-1 |---------------------------*/}
//               {!resumeBtnClicked ?
//                 <div>
//                   {questions &&
//                     questions[currentQuestion]?.subQuestions?.map(
//                       (subItem, subIndex) => {
//                         const selectedOption = selectedOptions[subIndex];
//                         return (
//                           <>
//                             <div className="Img_and_Para" style={{ display: 'flex' }}>
//                               <p
//                                 className="Q_para"
//                                 style={{ display:'flex' }}
//                                 dangerouslySetInnerHTML={{
//                                   __html: `<span style="font-weight: bold;color:black">Q${subIndex + 1})</span> <span style="margin-left: 8px;">${subItem.question}</span>`,
//                                 }}
//                               ></p>
//                               {subItem.imageUrl && (
//                                 <img
//                                   className="sub_Qustn_img"
//                                   src={subItem.imageUrl}
//                                   onClick={() => {
//                                     setSelectedImageUrl(subItem.imageUrl);
//                                     setModalOpen(true);
//                                   }}
//                                 />
//                               )}
//                             </div>


//                             <div className="Cs_Ans_Fields">
//                               <div>
//                                 <h6 className="title_">Your Answer</h6>
//                               </div>
//                               {/*---------------------------| Ans |---------------------------*/}
//                               {
//                                 //console.log("subItem", subItem)
//                               }
//                               {subItem?.options[0]?.length >= 0 &&
//                                 subItem?.options[0]?.map((value, optionIndex) => {
//                                   const ans = attendCs?.map(
//                                     (itm) => {
//                                       if (itm.questionId === subItem.id && subItem.questionTypeCode === "MCQ") {
//                                         return itm
//                                       }
//                                     }
//                                   ).filter(Boolean)
//                                   const name = Object.keys(attendedCs).length > 0
//                                     && attendedCs[currentQuestionData.id]?.[subItem.id]?.some(itm => itm.order === value.order);

//                                   const answer = Object.keys(attendedCs).length > 0
//                                     && attendedCs[currentQuestionData.id]?.[subItem.id]?.find(itm => itm.order === value.order);

//                                   const isCheckedMcq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.id] ? attendedCs[currentQuestionData.id][subItem.id] ? attendedCs[currentQuestionData.id][subItem.id].findIndex(
//                                     (item) => item.option === value.option
//                                   )
//                                     :
//                                     -1
//                                     :
//                                     -1
//                                     :
//                                     -1;

//                                   const isCheckedMsq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.id] ? attendedCs[currentQuestionData.id][subItem.id] ? attendedCs[currentQuestionData.id][subItem.id].some(
//                                     (item) => item.option === value.option
//                                   )
//                                     :
//                                     false
//                                     :
//                                     false
//                                     :
//                                     false;
//                                   return subItem.questionTypeCode === "FILL" ? (
//                                     <div
//                                       className="Option_and_Ans_cs"
//                                     // key={value.order}
//                                     >
//                                       {/* <input
//                                         className="Ans1_FIB_cs"
//                                         placeholder="Answer"
//                                         type="text"
//                                         name={value.order}
//                                         value={
//                                           Object.keys(attendedCs).length > 0
//                                             ? attendedCs[currentQuestionData.id]
//                                               ? attendedCs[currentQuestionData.id][subItem.id]
//                                                 ? name
//                                                   ? answer.answerKey
//                                                   : ""
//                                                 : ""
//                                               : ""
//                                             : ""
//                                         }
//                                         onChange={(e) =>
//                                           handleSavingAns(
//                                             e,
//                                             subItem.id,
//                                             "fill",
//                                             value
//                                           )
//                                         }
//                                         // Disable copy paste
//                                         onCut={handleDisableCopyPaste}
//                                         onCopy={handleDisableCopyPaste}
//                                         onPaste={handleDisableCopyPaste}
//                                       /> */}

//                                       <Student_Enrich_Quiz_FIB
//                                         questions={questions}
//                                         resumeBtnClicked={resumeBtnClicked}
//                                         currentQuestion={currentQuestion}
//                                         setAttendFib={setAttendFib}
//                                         attendFib={attendFib}
//                                         setAttendedFib={setAttendedFib}
//                                         attendedFib={attendedFib}
//                                         from={"CASE"}
//                                         subItem={subItem}
//                                         Vvalue={value}
//                                         attendCs={attendCs}
//                                         setAttendCs={setAttendCs}
//                                         setCommonAttendedData={setCommonAttendedData}
//                                         commonAttendedData={commonAttendedData}
//                                       />


//                                     </div>
//                                   ) : subItem.questionTypeCode === "MCQ" ? (
//                                     <>
//                                       {/* <div
//                                         className="Ans_MCQ_cs"
//                                         // key={value.option}
//                                       > */}
//                                       {
//                                         //console.log(subItem.questionTypeCode, questions[currentQuestion]?.subQuestions)
//                                       }
//                                       {/* <CustomRadio
//                                           value={value.answerKey}
//                                           sx={{
//                                             color: "#EEF0F9",
//                                           }}
//                                           id={`checkbox-${subIndex}-${optionIndex}`}
//                                           checked={isCheckedMcq !== -1}
//                                           onChange={(e) =>
//                                             handleSavingAns(
//                                               e,
//                                               subItem.id,
//                                               "mcq",
//                                               value
//                                             )
//                                           }
//                                         />
//                                         {value.htmlTag === null && value.answerKey}
//                                         <p
//                                           dangerouslySetInnerHTML={{
//                                             __html: value.htmlTag,
//                                           }}
//                                         ></p> */}

//                                       {/* </div> */}
//                                       <Student_Enrich_Quiz_MCQ
//                                         questions={questions}
//                                         resumeBtnClicked={resumeBtnClicked}
//                                         currentQuestion={currentQuestion}
//                                         setAttendMcq={setAttendMcq}
//                                         attendMcq={attendMcq}
//                                         setAttendedMcq={setAttendedMcq}
//                                         attendedMcq={attendedMcq}
//                                         from={"CASE"}
//                                         subItem={subItem}
//                                         Vvalue={value}
//                                         attendCs={attendCs}
//                                         setAttendCs={setAttendCs}
//                                         setCommonAttendedData={setCommonAttendedData}
//                                         commonAttendedData={commonAttendedData}
//                                       />
//                                     </>
//                                   ) : (
//                                     <>
//                                       <Student_Enrich_Quiz_MSQ
//                                         questions={questions}
//                                         resumeBtnClicked={resumeBtnClicked}
//                                         currentQuestion={currentQuestion}
//                                         setAttendMsq={setAttendMsq}
//                                         attendMsq={attendMsq}
//                                         setAttendedMsq={setAttendedMsq}
//                                         attendedMsq={attendedMsq}
//                                         from={"CASE"}
//                                         subItem={subItem}
//                                         Vvalue={value}
//                                         setCommonAttendedData={setCommonAttendedData}
//                                         commonAttendedData={commonAttendedData}
//                                       />
//                                     </>
//                                   );
//                                 })}
//                             </div>
//                           </>
//                         );
//                       }
//                     )}
//                 </div>
//                 :
//                 <div>
//                   {questions &&
//                     questions[currentQuestion]?.subQuestions?.map(
//                       (subItem, subIndex) => {
//                         const selectedOption = selectedOptions[subIndex];
//                         // const ans = attendCs?.map(
//                         //   (itm) => {
//                         //     if (itm.questionId === subItem.id && subItem.questionTypeCode === "MCQ") {
//                         //       return itm
//                         //     }
//                         //   }
//                         // ).filter(Boolean)

//                         return (
//                           <>
//                             <div className="Img_and_Para">
//                               <p
//                                 className="Q_para"
//                                 dangerouslySetInnerHTML={{
//                                   __html: `Q${subIndex + 1})${subItem.question}`,
//                                 }}
//                               ></p>
//                               {subItem.imageUrl && (
//                                 <img
//                                   className="sub_Qustn_img"
//                                   src={subItem.imageUrl}
//                                   onClick={() => {
//                                     setSelectedImageUrl(subItem.imageUrl);
//                                     setModalOpen(true);
//                                   }}
//                                 />
//                               )}
//                             </div>

//                             <div className="Cs_Ans_Fields">
//                               <div>
//                                 <h6 className="title_">Your Answer</h6>
//                               </div>
//                               {/*---------------------------| Ans |---------------------------*/}

//                               {subItem.questionTypeCode === "FILL" ? (
//                                 subItem?.fillInTheBlanks?.map(itm => {
//                                   const name = Object.keys(attendedCs).length > 0
//                                     && attendedCs[currentQuestionData.questionId]?.[subItem.questionId]?.some(itm1 => itm1.order === itm.order);

//                                   const answer = Object.keys(attendedCs).length > 0
//                                     && attendedCs[currentQuestionData.questionId]?.[subItem.questionId]?.find(itm1 => itm1.order === itm.order);
//                                   return (
//                                     <div
//                                       className="Option_and_Ans_cs"
//                                       key={itm.order}
//                                     >
//                                       <input
//                                         className="Ans1_FIB_cs"
//                                         placeholder="Answer"
//                                         type="text"
//                                         name={itm.order}
//                                         value={
//                                           Object.keys(attendedCs).length > 0
//                                             ? attendedCs[currentQuestionData.questionId]
//                                               ? attendedCs[currentQuestionData.questionId][subItem.questionId]
//                                                 ? name
//                                                   ? answer.answerKey
//                                                   : ""
//                                                 : ""
//                                               : ""
//                                             : ""
//                                         }
//                                         onChange={(e) =>
//                                           handleSavingAns(
//                                             e,
//                                             subItem.questionId,
//                                             "fill",
//                                             itm
//                                           )
//                                         }
//                                         // Disable copy paste
//                                         onCut={handleDisableCopyPaste}
//                                         onCopy={handleDisableCopyPaste}
//                                         onPaste={handleDisableCopyPaste}
//                                       />
//                                     </div>
//                                   )
//                                 })
//                               ) : subItem.questionTypeCode === "MCQ" ? (
//                                 subItem.multiChoiceOrSelect.map((itm, index) => {
//                                   const isCheckedMcq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId].findIndex(
//                                     (item) => item.option === itm.option
//                                   )
//                                     :
//                                     -1
//                                     :
//                                     -1
//                                     :
//                                     -1;
//                                   return (
//                                     <>
//                                       <div
//                                         className="Ans_MCQ_cs"
//                                         key={itm.option}
//                                       >
//                                         <CustomRadio
//                                           value={itm.answerKey}
//                                           sx={{
//                                             color: "#EEF0F9",
//                                           }}
//                                           id={`checkbox-${subIndex}-${index}`}
//                                           // checked={attendCs?.some(
//                                           //   (itm) =>
//                                           //     itm.answer[0]?.answerKey ===
//                                           //     value.answerKey
//                                           // )}
//                                           // checked={ans[0] ? ans[0].answer?.some(itm => itm.answerKey === value.answerKey) : false}
//                                           checked={isCheckedMcq !== -1}
//                                           onChange={(e) =>
//                                             handleSavingAns(
//                                               e,
//                                               subItem.questionId,
//                                               "mcq",
//                                               itm
//                                             )
//                                           }
//                                         />
//                                         {itm.htmlTag === null && itm.answerKey}
//                                         <p
//                                           dangerouslySetInnerHTML={{
//                                             __html: itm.htmlTag,
//                                           }}
//                                         ></p>
//                                       </div>
//                                     </>
//                                   )
//                                 })
//                               ) : (
//                                 subItem.multiChoiceOrSelect.map(itm => {
//                                   const isCheckedMsq = Object.keys(attendedCs).length > 0 ? attendedCs[currentQuestionData.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId] ? attendedCs[currentQuestionData.questionId][subItem.questionId].some(
//                                     (item) => item.option === itm.option
//                                   )
//                                     :
//                                     false
//                                     :
//                                     false
//                                     :
//                                     false;
//                                   return (
//                                     <>
//                                       <div
//                                         className="Ans1_MSQ_cs"
//                                         key={itm.option}
//                                       >
//                                         <Checkbox
//                                           value={itm.answerKey}
//                                           size="small"
//                                           checked={isCheckedMsq}
//                                           sx={{
//                                             color: "#001AF3",
//                                             "&.Mui-checked": {
//                                               color: "#001AF3",
//                                             },
//                                           }}
//                                           onChange={(e) =>
//                                             handleSavingAns(
//                                               e,
//                                               subItem.questionId,
//                                               "msq",
//                                               itm
//                                             )
//                                           }
//                                         />
//                                         {itm.htmlTag === null && itm.answerKey}
//                                         <p
//                                           dangerouslySetInnerHTML={{
//                                             __html: itm.htmlTag,
//                                           }}
//                                         ></p>
//                                       </div>
//                                     </>
//                                   )
//                                 })
//                               )
//                                 // })}
//                               }
//                             </div>
//                           </>
//                         );
//                       }
//                     )}
//                 </div>
//               }
//             </div>
//           </div>
//         </div>
//         {/*---------------------------| Quiz Case-Study Division Ends Here |---------------------------*/}
//       </div>
//       <Student_Image_Open_Modal
//         selectedImageUrl={selectedImageUrl}
//         modalOpen={modalOpen}
//         setModalOpen={setModalOpen}
//       />
//     </>
//   );
// }

// export default Student_Enrich_Quiz_CS;
