import React, { useEffect, useRef, useState } from "react";
// import dashboardContentStyles from "../../css/dashboardContent.module.css";
import dashboardContentStyles from "../../../../pages/css/dashboardContent.module.css";
// import dashHome from "../../../../src/img/dashHome.svg";
import dashHome from "../../../../img/dashHome.svg";
import GetLastModifiedAt from "../../../../pages/API/school-controller/GetLastModifiedAt";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { set_is_scrolling_to_top } from "../../../../actions/bluePrintActions";
import { Link, useNavigate } from "react-router-dom";

const DashboardBluePrintLayer = ({ children }) => {
  const role = localStorage.getItem("role");
  const containerRef = useRef(null)
  const targetRef = useRef(null)
  const userName = localStorage.getItem("userName");
  const [modifiedDate, setModifiedDate] = useState({});
  const { isScrollingToTop } = useSelector((state) => state.bluePrint);
  const dispatch = useDispatch()

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // GetLastModifiedAt(setModifiedDate);
    };

    fetchData();
  }, [userName, role]);


  const scrollUp = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  }

  useEffect(() => {
    if (isScrollingToTop) {
      scrollUp()
      setTimeout(() => {
        dispatch(set_is_scrolling_to_top(false))
      }, 300);
    }
  }, [isScrollingToTop])

  return (
    <div
      className="WholePagee"
      style={{
        background: "#eaeaf5",
        height: "120vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      ref={containerRef}
      onScroll={e => console.log(e, 'asdas')}
    >
      <article>
        <div
        style={{
          display: "flex",
          marginTop: "15px",
          marginLeft: "15px",
          position: "initial",
        }} 
        className={dashboardContentStyles.dashboard_link}>

          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template")
            }}

          >
            Home
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282",  marginTop: "5px", }} />
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282",  marginTop: "5px", }} />
          </span>
          <span onClick={() => {
              navigate("/dashboard/upload content")
              sessionStorage.setItem("sideNavMainMenuNames", "Upload Content");
              sessionStorage.removeItem("sideNavSubMenuNames");
            }}
            className={dashboardContentStyles.az_menu_text + ' ' + 'az_cursor_pointer'}
          >
           Content Admin
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282", marginTop: "5px", }} />
          </span>
          <span>
            <i className="fa-solid fa-angle-right"
              style={{ color: "#828282", marginTop: "5px", }} />
          </span>
          <span
            className={dashboardContentStyles.az_selected_menu_text}
          >
            Blue Print
          </span>
        </div>

        {/* <div
          className={dashboardContentStyles.dashboard_link}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginLeft: "15px",
            fontSize: "1vw",
            position: "initial",
          }}
          ref={targetRef}
        >
          <div>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            <span
              style={{ fontSize: "12px", marginLeft: "4px", color: "#7A7A7A" }}
            >
              Home ⨠
            </span>
            <span
              style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
            >
              Content Admin ⨠
            </span>
            <span
              style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
            >
              Blue Print
            </span>
          </div>
          <div
            className={dashboardContentStyles.dashboard_last_updated}
            style={{ position: "initial" }}
          >
            <p>Last Update: {modifiedDate?.data}</p>
          </div>
        </div> */}
      </article>
      {children}
      {/* <button onClick={()=>scrollUp()}>Click me</button> */}
    </div>
  );
};

export default DashboardBluePrintLayer;
