import React, { useState } from "react";
import { Box, Modal, TextField } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DarkYellowButton from "../../components/common/DarkYellowButton";
import OTPVerificationModal from "./OTPVerificationModal";
import Green100Button from "../../components/common/Green100Button";
import Yellow100Button from "../../components/common/Yellow100Button";
import SuccessModal from "./SuccessModal";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "7px",
  display: "flex",
  flexDirection: "column",
};
const GetOTPModal = ({ open, close, phoneNumber }) => {
  // const [mobileNumber, setMobileNumber] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [error, setError] = useState(false);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const token = sessionStorage.getItem("token");
  const sendSMSHandler = async (phoneNumber) => {
    const data = {
      message: "SMS send successfully",
      phoneNumber: phoneNumber,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/notification/sms`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("send SMS", res);
  };
  const submitHandler = async () => {
    if (phoneNumber.length < 10) {
      await setError(true);
    } else {
      await setError(false);
      await setOpenVerificationModal(true);
      await sendSMSHandler(phoneNumber);
      // setMobileNumber("");
    }
  };
  const closeHandler = () => {
    close();
    // setMobileNumber("");
    setError(false);
    setIsOTP(false);
  };
  // console.log("isOTP", isOTP);
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            background: "rgba(96, 91, 161, 0.81)",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
          }}
        >
          <div>Change Phone Number</div>
          <CloseOutlinedIcon
            onClick={closeHandler}
            style={{ cursor: "pointer" }}
          />
        </div>
        {isOTP ? (
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              rowGap: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              <div> Your New Number</div>
              <TextField
                type={"number"}
                placeholder={"Enter Your New Number"}
                value={newNumber}
                onChange={(e) => setNewNumber(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                columnGap: "1rem",
              }}
            >
              <Yellow100Button onClick={closeHandler}>Cancel</Yellow100Button>
              <Green100Button onClick={() => setIsSuccessModal(true)}>
                Update
              </Green100Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              rowGap: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              <div>Enter Your Number</div>
              <TextField
                type={"number"}
                value={phoneNumber}
                placeholder={"Enter Your New Number"}
                // onChange={(e) => setMobileNumber(e.target.value)}
              />
              {error && (
                <div style={{ color: "red" }}>Please Enter Valid Number</div>
              )}
            </div>
            <DarkYellowButton onClick={() => submitHandler()}>
              Get OTP
            </DarkYellowButton>
          </div>
        )}
        {openVerificationModal && (
          <OTPVerificationModal
            visible={openVerificationModal}
            hide={() => setOpenVerificationModal(false)}
            number={phoneNumber}
            setIsOTP={setIsOTP}
          />
        )}
        {isSuccessModal && (
          <SuccessModal
            open={isSuccessModal}
            close={() => setIsSuccessModal(false)}
            secondClose={close}
          />
        )}
      </Box>
    </Modal>
  );
};
export default GetOTPModal;
