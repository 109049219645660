//
// --- ❗❕❗ FIB-> FILL IN THE BLANKS ❗❕❗ --- //
//
import React, { useEffect, useState } from "react";

//Css Pages
import "../../css/Student/Student_Enrich_Quiz_FIB.css";
import Student_Image_Open_Modal from "./Student_Image_Open_Modal";
import { Preview } from "@mui/icons-material";
import { Box, Button, FormControl, TextField, FormControlLabel, FormLabel, Grid, LinearProgress, Modal, Paper, Radio, RadioGroup, Typography, } from "@mui/material";
// Images Used
import tickIcon from "../../../../src/img/tick-circle.svg";
import cancelIcon from "../../../../src/img/cancel.svg";
import { rightClickEnable } from "../../../../src/Serviceworker"


function Student_Enrich_Quiz_FIB({
  questions,
  currentQuestion,
  setAttendFib,
  attendFib,
  setAttendedFib,
  attendedFib,
  resumeBtnClicked,
  from,
  subItem,
  Vvalue,
  attendCs,
  setAttendCs,
  setCommonAttendedData,
  commonAttendedData,
  list,
  ispreview,
  Previewvalue,
  tempCaseStudy,
  settempCaseStudy,
  attendedCs,
  setAttendedCs,
  showExplanation
}) {
  const listData = (ispreview && resumeBtnClicked) ? list?.questionAnswerKeys[currentQuestion] : {};
  //console.log("FIBBBBBBBBBBBBB", listData, questions, currentQuestion, attendFib, attendedFib, "resumeBtnClicked", resumeBtnClicked, from, attendCs, "ispreview", ispreview)
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [ansByStudent, setAnsByStudent] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [isAuthenticatedShift, setIsAuthenticatedShift] = useState(true);

  const currentQuestionData = (!ispreview || !resumeBtnClicked) ? questions[currentQuestion] : list.questionAnswerKeys[currentQuestion];
  //console.log("currentQuestionData", currentQuestionData)
  // Disable copy paste
  const handleDisableCopyPaste = (e) => {
    e.preventDefault();
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };


  useEffect(() => {
    if (!ispreview) {
      //console.log("useeffectt valled.")
      if (rightClickEnable && isAuthenticatedShift) {

        const handleContextMenu = (e) => {
          // alert("context menu fib")
          // if (!authContext.isAuthenticated) { this is to restrict right click based on loggedin info

          e.preventDefault();
          //console.log("handleContextMenu called")
        };
        document.addEventListener('contextmenu', handleContextMenu);

        document.addEventListener('keydown', function (event) {
          //console.log(event);
          // alert(event.getModifierState)
          const isModifierKey = event.getModifierState('Alt') ||
            event.getModifierState('Tab') ||
            event.getModifierState('Shift');

          if (event.key === 'F12' || isModifierKey) {
            //console.log("Modifier key pressed");
            event.preventDefault();
          } else if (event.key === "Enter" || event.key === " ") {
            //console.log(`${event.key} key pressed`);

            // Check if no modifier keys are pressed
            const noModifiers = !event.getModifierState('Alt') &&
              !event.getModifierState('Control') &&
              !event.getModifierState('Meta') &&
              !event.getModifierState('Shift');

            if (noModifiers) {
              //console.log("No modifier keys pressed");
              // Prevent the default behavior only for <button> elements
              if (event.target.tagName === 'BUTTON') {
                event.preventDefault();
                //console.log("Key event prevented for <button>");
              }
            }
          }
        });

        const handleBeforeUnload = (event) => {
          // console.log("close tab", event.type); // For reload and close tab
          const isRefresh = !event.currentTarget.performance.navigation.type;
          //console.log("isRefresh", isRefresh);

          if (event.type === 'beforeunload') {
            // console.log("**************************************************************swe")
            // sessionStorage.removeItem("itemload");
            sessionStorage.removeItem("viewQuizData");
            // setIsModalOpen(false)
            //   //console.log("exit clicked1", previousPage);
            sessionStorage.removeItem("practiceQuizAnswer");
            sessionStorage.removeItem("drag_drop_array");
            sessionStorage.removeItem("drag_drop_array_final");
            sessionStorage.removeItem("shuffledQuiz");
            sessionStorage.removeItem("alltheQuizAnswer");
            sessionStorage.removeItem("exid");
            sessionStorage.removeItem("exIdTwo");
            sessionStorage.removeItem("drag_drop_array_final")
            sessionStorage.removeItem("case_help");
            const message = "Are you sure you want to leave? Your progress may be lost.";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
          } else {
            //console.log("else close tab");
            const message = "Are you sure you want to close the tab?";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
          }
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }
    }
  }, [])

  useEffect(() => {
    if (!ispreview && resumeBtnClicked) {
      // console.log("currentQuestionData", currentQuestionData, questions[currentQuestion], attendedFib)
      const transformedObject = {
        [currentQuestionData.questionId]: currentQuestionData?.ftbAnswerByStudent
      };
      // console.log("transformedObject", transformedObject)
      if (attendedFib.length == 0) {

        setAttendedFib(transformedObject);
        // console.log("transformedObject", transformedObject)
      } else {

        const keyToCheck = currentQuestionData?.questionId;
        var existingData = attendedFib;
        for (const key in transformedObject) {
          if (transformedObject.hasOwnProperty(key)) {
            if (existingData.hasOwnProperty(key)) {
              delete existingData[key];
            }
            existingData[key] = transformedObject[key];
          }
        }
        // console.log(existingData, "existingData");

        //   {
        //     "4028938b8c81d5de018cd8845eef0131": [
        //         {
        //             "order": 1,
        //             "answerKey": "feb"
        //         },
        //         {
        //             "order": 2,
        //             "answerKey": "jan"
        //         },
        //         {
        //             "order": 3,
        //             "answerKey": "mar"
        //         }
        //     ]
        // }
      }
      var existingData = attendedFib


    }
  }, [])


  const handleChange = (e, val, index) => {
    const value = (e.target.value);
    const obj = {
      order: val,
      answerKey: value,
    };
    const newArr = [...attendFib];
    const foundIndexFib = newArr.findIndex(itm => itm.order === val)

    if (foundIndexFib !== -1) {
      newArr[foundIndexFib] = obj;
    }
    else {
      newArr.push(obj);
    }
    newArr.map((itm, index) => {
      if (itm.answerKey === "") {
        newArr.splice(index, 1);
      }
    })

    newArr.sort((a, b) => a.order - b.order);
    setAttendFib(newArr);

    //console.log(newArr, "==============================================", from, currentQuestionData)
    if (from == "CASE") {
      var Data = JSON.parse(sessionStorage.getItem("casestudy")) || [];
      // console.log("Data1", Data)
      var v = { [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]: newArr, type: "FILL", currentQuestion };
      const filteredArray = Data.filter(item => {
        if (item[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] && item.type === "FILL") {
          const hasOrder1 = item[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].some(subItem => subItem.order === newArr[0].order);
          return !hasOrder1;
        }
        return true;
      });
      // console.log("filteredArray", filteredArray)
      filteredArray.push(v)
      Data = filteredArray
      // console.log("Data", Data)

      sessionStorage.setItem("casestudy", JSON.stringify(Data));
      commonAttendedData.push(v)
      setCommonAttendedData(commonAttendedData)

      var vtempCaseStudy = tempCaseStudy || [];
      const existingIndex = vtempCaseStudy.findIndex(item => item.order === val);
      // console.log("existingIndex", existingIndex)
      if (existingIndex !== -1) {
        vtempCaseStudy.splice(existingIndex, 1);
      }

      // vtempCaseStudy.push({
      //   [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]: [
      //     {
      //       "order": val,
      //       "answerKey": value,
      //     }
      //   ],
      //   "type": "FIB",
      //   "currentQuestion": currentQuestion
      // });
      // console.log("vtempCaseStudy", vtempCaseStudy)
      settempCaseStudy([...vtempCaseStudy])
    }

    setAttendedFib((prevAttendedQuestions) => {
      const updatedQuestions = { ...prevAttendedQuestions };
      if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
        updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
      }
      const foundIndex = updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].findIndex(
        (item) => item.order === val
      );
      if (foundIndex === -1) {
        updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].push({ "order": val, "answerKey": value })
      } else {
        updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id][foundIndex] = { "order": val, "answerKey": value }
      }
      // console.log("updatedQuestions", updatedQuestions)

      return updatedQuestions;
    });
  }

  useEffect(() => {
    if (!Preview) {
      if (attendedFib[currentQuestionData.id]) {
        attendedFib[currentQuestionData.id]?.forEach((ques) => {
          attendFib.push(ques);
          attendFib.sort((a, b) => a.order - b.order);
        })
      }
      if (resumeBtnClicked) {
        if (attendedFib[currentQuestionData.questionId]) {
          attendedFib[currentQuestionData.questionId]?.forEach((ques) => {
            attendFib.push(ques);
            attendFib.sort((a, b) => a.order - b.order);
          })
        }
        if (!attendedFib[currentQuestionData.questionId]) {
          currentQuestionData.ftbAnswerByStudent?.forEach((itm) => {
            attendFib.push(itm);
            setAttendedFib((prevAttendedQuestions) => {
              const updatedQuestions = { ...prevAttendedQuestions };
              if (!updatedQuestions[currentQuestionData.questionId]) {
                updatedQuestions[currentQuestionData.questionId] = [];
              }
              const foundIndex = updatedQuestions[currentQuestionData.questionId].findIndex(
                (item) => item.order === itm.order
              );
              if (foundIndex === -1) {
                updatedQuestions[currentQuestionData.questionId].push({ "order": itm.order, "answerKey": itm.answerKey });
              } else {
                updatedQuestions[currentQuestionData.questionId][foundIndex] = { "order": itm.order, "answerKey": itm.answerKey };
              }
              return updatedQuestions;
            });
          })
        }
      }
    } else {
      if (resumeBtnClicked && !ispreview) {
        if (attendedFib[currentQuestionData.questionId]) {
          attendedFib[currentQuestionData.questionId]?.forEach((ques) => {
            attendFib.push(ques);
            attendFib.sort((a, b) => a.order - b.order);
          })
        }
        if (!attendedFib[currentQuestionData.questionId]) {
          currentQuestionData.ftbAnswerByStudent?.forEach((itm) => {
            attendFib.push(itm);
            setAttendedFib((prevAttendedQuestions) => {
              const updatedQuestions = { ...prevAttendedQuestions };
              if (!updatedQuestions[currentQuestionData.questionId]) {
                updatedQuestions[currentQuestionData.questionId] = [];
              }
              const foundIndex = updatedQuestions[currentQuestionData.questionId].findIndex(
                (item) => item.order === itm.order
              );
              if (foundIndex === -1) {
                updatedQuestions[currentQuestionData.questionId].push({ "order": itm.order, "answerKey": itm.answerKey.replace(/\s/g, "") });
              } else {
                updatedQuestions[currentQuestionData.questionId][foundIndex] = { "order": itm.order, "answerKey": itm.answerKey.replace(/\s/g, "") };
              }
              return updatedQuestions;
            });
          })
        }
      }
    }
  }, [currentQuestion]);

  return (
    <>
      {
        ispreview ?
          !resumeBtnClicked ?
            <Grid
              item
              xs={12}
              mg={12}
              lg={12}
              style={{ display: "flex" }}
            >


              <div
                style={{
                  width: "100%",
                  height: "auto",
                  border: "1px solid #dbdee6",
                  fontSize: "13px",
                  color: "#1b1b1b",
                  opacity: "0.9",
                  marginTop: "10px",
                  lineHeight: "1",
                  padding: "10px",
                  borderRadius: "5px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "30px", // Adjust the spacing between checkbox and text
                    overflow: "hidden", // Hide overflow if the text is too long
                  }}
                >
                  {/* commented for teach not see */}
                  {/* {Previewvalue?.answerKey} */}
                </div>

              </div>
            </Grid>
            :
            <>
              {/*---------------------------| Quiz FIB Question & Answer Division Starts Here |---------------------------*/}
              <div className="cqk_Quiz_Main__div">
                {/*---------------------------| Quiz FIB Question Division |---------------------------*/}
                <div className="cqk_Qstn_and__Ans">
                  <div className="cqk_Qstn__div">
                    {/* <div>
                      <h6 className="cqk_Fib_Head">Fill in The Blank</h6>
                    </div> */}
                    <div className="cqk_Fib_Main_Qstn">
                      {listData?.question && <h3 id="az-quiz-fib-qstn" className="Paragraph_title">Question:-</h3>}
                      <p
                        className="Paragraph"
                        dangerouslySetInnerHTML={{
                          __html: listData?.question,
                        }}
                      ></p>

                      <div>
                        {/* <img className="cqk_Fib_Qstn_Img" src="" alt="Image" /> */}
                      </div>
                    </div>
                    {
                      //console.log("listData", listData)
                    }
                    {
                      showExplanation == true &&
                      <div>
                        {listData?.explanation && <h3 id="az-quiz-fib-expl" className="Paragraph_title" style={{marginTop:"10px"}}>Explanation:-</h3>}
                        <p
                          className="Paragraph "
                          dangerouslySetInnerHTML={{
                            __html: listData?.explanation,
                          }}
                        ></p>
                      </div>
                    }
                    {listData?.imageUrl && (
                      <div style={{ marginTop: '20px' }}>
                        <img
                          className="FIB_Qstn_Img"
                          src={listData?.imageUrl}
                          alt="Image"
                          onClick={() => {
                            setSelectedImageUrl(listData?.imageUrl);
                            setModalOpen(true);
                          }}
                        />
                      </div>
                    )
                    }

                  </div>

                  {/*---------------------------| Quiz FIB Answer Division |---------------------------*/}
                  <div className="cqk_Ans__div">
                    {/* <div>
                      <h6 className="cqk__Head">Answer</h6>
                    </div> */}
                    <div>
                      {/*---------------------------| Ans-1 |---------------------------*/}
                      {listData?.fillInTheBlanks.map((itm, index) => {
                       
                        const name = listData?.ftbAnswerByStudent
                          ? listData?.ftbAnswerByStudent.some(
                            (item) => String(item.order).toLowerCase() === String(itm.order).toLowerCase()
                          )
                          : false;

                        const ansCheck = listData?.ftbAnswerByStudent
                          ? listData?.ftbAnswerByStudent.some(
                            (item) => String(item.answerKey).toLowerCase() == String(itm.answerKey).toLowerCase()
                          )
                          : false;

                        const answerFib = listData?.ftbAnswerByStudent?.filter(abs => abs.order === itm.order)
                        const dynamicId = `az-answer-text-fib-${index}`;
                        var vv = name ? "Your Answer" : itm.correctAnswer === true ? "Correct Answer" : "";
                        return (
                          <>
                            <div className="cqk_Option_and_Ans">
                              <div className="cqk_Option">
                                {String.fromCharCode(64 + itm.order)}
                              </div>
                              {/*-------| Displaying the FIB Answer |-------*/}

                              <div
                                className="cqk_Ans_div"
                                id={dynamicId}
                                style={{
                                  background:
                                    ansCheck === true
                                      ? "#E7FFE8" //green
                                      : name === false
                                        ? "white"
                                        : "#FCD3D3", //red
                                }}
                              >
                                <div className="tick_And_Cancel_Icon">
                                  {ansCheck === true ? (
                                    <img
                                      src={tickIcon}
                                      width="22px"
                                      height="22px"
                                      className="tickIcon"
                                    />
                                  ) : name === false ? (
                                    ""
                                  ) : (
                                    <img
                                      src={cancelIcon}
                                      width="25px"
                                      height="25px"
                                      className="cancelIcon"
                                    />
                                  )}
                                </div>

                                <div>
                                  <p
                                  dangerouslySetInnerHTML={{
                                    __html: name === false
                                    ? itm.htmlTag[0]
                                    : answerFib[0]?.htmlTag
                                  }}>
                                    {/* {name === false
                              ? "Answer"
                              ? itm.answerKey[0]
                              : listData?.ftbAnswerByStudent[index]
                              ? listData?.ftbAnswerByStudent[index].answerKey
                              : itm.answerKey[0]} */}
                                    {/* {
                                      name === false
                                        ? itm.answerKey[0]
                                        : answerFib[0]?.answerKey
                                    } */}
                                  </p>
                                </div>

                              </div>
                            </div>
                            <div
                              className="CorrectAnsLabel"
                            >
                              {vv}
                            </div>

                            {ansCheck === false && name === true && (
                              <div className="CorrectAnsLabel"
                              dangerouslySetInnerHTML={{
                                __html: `Correct Answer: ${itm?.htmlTag}`,
                              }}
                              >
                                {/* Correct Answer: {itm?.answerKey} */}
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </> :
          <>
            {
              from === "CASE" ? (
                <> {/*---------------------------| Quiz FIB Question & Answer Division Starts Here |---------------------------*/}
                  {/* <div className="Quiz_Main__div"> */}
                  {/*---------------------------| Quiz FIB Question Division |---------------------------*/}


                  {/*---------------------------| Quiz FIB Answer Division |---------------------------*/}
                  <div className="Ans__div">

                    {/*---------------------------| Ans-1 |---------------------------*/}
                    {
                      //console.log(attendedFib, "attendedFib", subItem, Vvalue, resumeBtnClicked)
                    }
                    {!resumeBtnClicked ? subItem?.options[0]?.map((value, ind) => {
                      const name = Object.keys(attendedFib).length > 0
                        && attendedFib[currentQuestionData.id]?.some(itm => itm.order === value.order);

                      const answer = Object.keys(attendedFib).length > 0
                        && attendedFib[currentQuestionData.id]?.find(itm => itm.order === value.order);
                      //console.log(name, answer)
                      return (
                        <div className="Option_and_Ans" key={ind}>
                          {/* <div className="Option">
                     {String.fromCharCode(64 + value.order)}
                   </div> */}
                          {/* <input
                     className="Ans_inp"
                     placeholder="Answer"
                     color="orange"
                     type="text"
                     value={
                       Object.keys(attendedFib).length > 0
                         ? attendedFib[currentQuestionData.id]
                           ? name
                             ? answer.answerKey
                             : ""
                           : ""
                         : ""
                     }
                     onChange={(e) => handleChange(e, value.order, ind)}
                     onMouseEnter={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}
                     // Disable copy paste
                     onCut={handleDisableCopyPaste}
                     onCopy={handleDisableCopyPaste}
                     onPaste={handleDisableCopyPaste}
                     style={{
                       // backgroundColor: hovered ? '#ffcccb' : '#ffffff', 
                       border: hovered ? '5px solid orange' : '1px solid grey',
                       color: 'orange',
                     }}
                   />123 */}
                          <input
                            className="Ans_inp"
                            placeholder="Answer"
                            type="text"
                            value={
                              Object.keys(attendedFib).length > 0
                                ? attendedFib[currentQuestionData.id]
                                  ? name
                                    ? answer?.answerKey
                                    : ""
                                  : ""
                                : ""
                            }
                            onChange={(e) => handleChange(e, value.order, ind)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            // Disable copy paste
                            onCut={(e) => {
                              handleDisableCopyPaste(e);
                            }}
                            onCopy={(e) => {
                              handleDisableCopyPaste(e);
                            }}
                            onPaste={(e) => {
                              handleDisableCopyPaste(e);
                            }}
                            style={{
                              border: `1px solid ${hovered ? 'orange' : 'grey'}`,
                              color: 'orange',
                            }}
                          />
                        </div>
                      );
                    })
                      :
                      questions[currentQuestion]?.fillInTheBlanks?.map((value, ind) => {

                        const name = Object.keys(attendedFib).length > 0
                          && attendedFib[currentQuestionData.questionId]?.some(itm => itm.order === value.order);

                        const answer = Object.keys(attendedFib).length > 0
                          && attendedFib[currentQuestionData.questionId]?.find(itm => itm.order === value.order);

                        return (
                          <div className="Option_and_Ans" key={ind}>
                            <div className="Option">
                              {String.fromCharCode(64 + value.order)}
                            </div>
                            {/* <input
                       className="Ans_inp"
                       placeholder="Answer"
                       type="text"
                       value={
                         Object.keys(attendedFib).length > 0
                           ? attendedFib[currentQuestionData.questionId]
                             ? name
                               ? answer.answerKey
                               : ""
                             : ""
                           : ""
                       }
                       onChange={(e) => handleChange(e, value.order, ind)}
                       // Disable copy paste
                       onCut={handleDisableCopyPaste}
                       onCopy={handleDisableCopyPaste}
                       onPaste={handleDisableCopyPaste}
                     /> */}
                            <input
                              className="Ans_inp"
                              placeholder="Answer"
                              type="text"
                              value={
                                Object.keys(attendedFib).length > 0
                                  ? attendedFib[currentQuestionData.questionId]
                                    ? name
                                      ? answer?.answerKey
                                      : ""
                                    : ""
                                  : ""
                              }
                              onChange={(e) => handleChange(e, value.order, ind)}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              // Disable copy paste
                              onCut={(e) => {
                                handleDisableCopyPaste(e);
                              }}
                              onCopy={(e) => {
                                handleDisableCopyPaste(e);
                              }}
                              onPaste={(e) => {
                                handleDisableCopyPaste(e);
                              }}
                              style={{
                                border: `1px solid ${hovered ? 'orange' : 'grey'}`,
                                color: 'orange',
                              }}
                            />
                          </div>
                        );
                      })}

                  </div>

                  {/* </div> */}
                  {/*---------------------------| Quiz FIB Question & Answer Division Ends Here |---------------------------*/}
                  <Student_Image_Open_Modal
                    selectedImageUrl={selectedImageUrl}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  /></>
              ) : (
                <> {/*---------------------------| Quiz FIB Question & Answer Division Starts Here |---------------------------*/}
                  <div className="Quiz_Main__div">
                    {/*---------------------------| Quiz FIB Question Division |---------------------------*/}
                    <div className="Qstn_and__Ans">
                      <div className="Qstn__div">
                        {/* <div>
                          <h6 className="Fib_Head">Fill in The Blank</h6>
                        </div> */}
                        <div className="Fib_Main_Qstn">
                          {questions[currentQuestion]?.question && <h3 id="az-fib-qstn" className="Paragraph_title">Question:-</h3>}
                          <p
                            className="Paragraph"
                            // style={{ fontSize: "1.4vw" }}
                            dangerouslySetInnerHTML={{
                              __html: questions[currentQuestion]?.question,
                            }}
                          ></p>
                          {/* {questions[currentQuestion]?.explanation && <h3 id="az-fib-qstn" className="Paragraph_title">Explanation:-</h3>}
                          <p
                            className="Paragraph"
                            // style={{ fontSize: "1.4vw" }}
                            dangerouslySetInnerHTML={{
                              __html: questions[currentQuestion]?.explanation,
                            }}
                          ></p>
                          {questions && questions[currentQuestion]?.imageUrl && (
                            <div>
                              <img
                                className="Fib_Qstn_Img"
                                src={questions[currentQuestion]?.imageUrl}
                                alt="Image"
                                onClick={() => {
                                  setSelectedImageUrl(questions[currentQuestion]?.imageUrl);
                                  setModalOpen(true);
                                }}
                              />
                            </div>
                          )} */}
                        </div>
                      </div>

                      {/*---------------------------| Quiz FIB Answer Division |---------------------------*/}
                      <div className="Ans__div">
                        <div>
                          <h6 className="_Head">Answer</h6>
                        </div>
                        <div>
                          {/*---------------------------| Ans-1 |---------------------------*/}
                          {/* {
                            //console.log(attendedFib, attendFib, "attendedFib-------", questions[currentQuestion], resumeBtnClicked)
                          } */}
                          {!resumeBtnClicked ? questions[currentQuestion]?.options[0]?.map((value, ind) => {
                            const name = Object.keys(attendedFib).length > 0
                              && attendedFib[currentQuestionData.id]?.some(itm => itm.order === value.order);

                            const answer = Object.keys(attendedFib).length > 0
                              && attendedFib[currentQuestionData.id]?.find(itm => itm.order === value.order);
                            //console.log(name, answer)
                            const dynamicId = `az-answer-text-fib-${ind}`;
                            return (
                              <div className="Option_and_Ans" key={ind}>
                                <div className="Option">
                                  {String.fromCharCode(64 + value.order)}
                                </div>
                                <input
                                  className="Ans_inp"
                                  id={dynamicId}
                                  placeholder="Answer"
                                  type="text"
                                  value={
                                    // Object.keys(attendedFib).length > 0
                                    //   ? attendedFib[currentQuestionData.id]
                                    //     ? name
                                    //       ? answer?.answerKey
                                    //       : ""
                                    //     : ""
                                    //   : ""
                                    Object.keys(attendedFib).length > 0 &&
                                      attendedFib[currentQuestionData.id] &&
                                      name
                                      ? answer?.answerKey
                                      : ''
                                  }
                                  onChange={(e) => handleChange(e, value.order, ind)}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                  // Disable copy paste
                                  onCut={handleDisableCopyPaste}
                                  onCopy={handleDisableCopyPaste}
                                  onPaste={handleDisableCopyPaste}
                                  style={{
                                    border: `1px solid ${hovered ? 'orange' : 'grey'}`,
                                    color: 'orange',
                                  }}
                                />
                              </div>
                            );
                          })
                            :
                            questions[currentQuestion]?.fillInTheBlanks?.map((value, ind) => {

                              const name = Object.keys(attendedFib).length > 0
                                && attendedFib[currentQuestionData.questionId]?.some(itm => itm.order === value.order);

                              const answer = Object.keys(attendedFib).length > 0
                                && attendedFib[currentQuestionData.questionId]?.find(itm => itm.order === value.order);
                              const dynamicId = `az-answer-text-fib-${ind}`;
                              return (
                                <div className="Option_and_Ans" key={ind}>
                                  <div className="Option">
                                    {String.fromCharCode(64 + value.order)}
                                  </div>
                                  <input
                                    className="Ans_inp"
                                    id={dynamicId}
                                    placeholder="Answer"
                                    type="text"
                                    value={
                                      // Object.keys(attendedFib).length > 0
                                      //   ? attendedFib[currentQuestionData.questionId]
                                      //     ? name
                                      //       ? answer?.answerKey
                                      //       : ""
                                      //     : ""
                                      //   : ""
                                      Object.keys(attendedFib).length > 0 &&
                                        attendedFib[currentQuestionData.questionId] &&
                                        name
                                        ? answer?.answerKey
                                        : ''
                                    }
                                    onChange={(e) => handleChange(e, value.order, ind)}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    // Disable copy paste
                                    onCut={handleDisableCopyPaste}
                                    onCopy={handleDisableCopyPaste}
                                    onPaste={handleDisableCopyPaste}
                                    style={{
                                      border: `1px solid ${hovered ? 'orange' : 'grey'}`,
                                      color: 'orange',
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*---------------------------| Quiz FIB Question & Answer Division Ends Here |---------------------------*/}
                  <Student_Image_Open_Modal
                    selectedImageUrl={selectedImageUrl}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  /></>
              )
            }
          </>
      }
    </>
  );
}

export default Student_Enrich_Quiz_FIB;
