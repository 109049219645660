import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//API Used
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";

//Images Used
import dashHome from "../../../../src/img/dashHome.svg";
import searchIcn from "../../../../src/img/search.svg";
import altImg from "../../../../src/img/altImg.png";

//Css Pages
import "../../css/Student/Student_Study.css";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonSort from "../../js/CommonSort"

// Mui
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Student_Study_Files from "./Student_Study_Files";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: '160px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
}));

function Student_Study() {
  // Navigation
  const navigate = useNavigate();
  const [modifiedDate, setModifiedDate] = useState({});
  const token = sessionStorage.getItem("token");
  const [subjectList, setSubjectList] = useState([]);
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [prevState, setPreState] = useState();
  const [selectedsubTopic, setSelectedSubTopic] = useState("");
  const [subTopic, setSubTopic] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [query, setQuery] = useState("");
  const [branchId, setBranchId] = useState("");
  const [planId, setPlanId] = useState("");
  const [selectedChapterId, setSelectedChapterId] = useState("");
  const [showContentModal, setShowContentModal] = useState(false);
  const [revisionDetail, setRevisionDetail] = useState([]);
  const [studyDetail, setStudyDetail] = useState([]);
  const [secletedCardName, setSecletedCardName] = useState("");
  const [selectedSubName, setSelectedSubName] = useState();

  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [studentDetail, setStudentDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });

  const [schoolId, setSchoolId] = useState("");
  const [minDetails, setMinDetails] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showClear, setShowClear] = useState(false);
  const [rmLockUnlockDatas, setRmLockUnlockDatas] = useState([]);
  const classes = useStyles();

  const handleClear = () => {
    setQuery("");
    setShowClear(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setQuery(inputValue);
    setShowClear(inputValue !== "");
  };

  useEffect(async () => {
    await fetchStudentData(userName, role);
    const studentData = await fetchStudentData(userName, role);
    setStudentDetail(studentData);
    // GetLastModifiedAt(setModifiedDate);
  }, []);

  const fetchStudentData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBranchId(res.data.data.student.branchId);
    setSchoolId(res.data.data.student.schoolId);
    sessionStorage.setItem("studentId", res.data.data.student?.id);
    sessionStorage.setItem("gradeId", res.data.data.student.gradeId);
    sessionStorage.setItem("boardId", res.data.data.student.boardId);
    sessionStorage.setItem("sectionId", res.data.data.student.sectionId);
    sessionStorage.setItem("branchId", res.data.data.student.branchId);
    sessionStorage.setItem("schoolId", res.data.data.student.schoolId);
    sessionStorage.setItem("gradeName", res.data.data.student?.grade);
    sessionStorage.setItem("studentName", res.data.data.student?.firstName + res.data.data.student?.lastName);
    sessionStorage.setItem("sectionName", res.data.data.student?.section);
  };

  useEffect(async () => {
    if (branchId) {
      const planDetails = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      sessionStorage.setItem("planId", planDetails.data?.data?.plans[0]?.planId);
      setPlanId(planDetails.data?.data?.plans[0]?.planId);
      sessionStorage.setItem("planId", planDetails.data?.data?.plans[0]?.planId);
    }
  }, [branchId]);

  const fetchSubjectData = async () => {
    const gradeId = sessionStorage.getItem("gradeId");
    if (planId !== "" && planId !== null && gradeId !== "" && gradeId !== null) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan?planId=${planId}&gradeId=${gradeId}&menuName=STUDY`,
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${planId}&gradeId=${gradeId}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("response?.data.data", response?.data.data);
      sessionStorage.setItem("planSubjects", JSON.stringify(response?.data?.data) || [])
      return response?.data.data || [];
    } else {
      return []
    }
  };

  const fetchSubTopicData = async (subjectId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${subjectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data.data || [];
  };

  // console.log("selectedsubTopic", selectedsubTopic);
  useEffect(() => {
    // console.log('sdsdsdsdsd', selectedSubject, selectedSubName);
  }, [selectedSubject, selectedSubName])
  const fetchChapterData = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const branchId = sessionStorage.getItem("branchId");
    const schoolId = sessionStorage.getItem("schoolId");
    // console.log("fetchChapterData")
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters?subjectId=${selectedSubject}&gradeId=${gradeId}&boardId=${boardId}&studentLogin=true&schoolId=${schoolId}&branchId=${branchId}&sectionId=${sectionId}&subjectName=${selectedSubName}`;

    if (selectedsubTopic) {
      url += `&subTopicId=${selectedsubTopic}`;
    }

    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    res.data.data = await CommonSort(res.data.data || [], "chapter", false);
    sessionStorage.setItem("planSubtopics", JSON.stringify(res?.data?.data))
    return res.data.data || [];
  };

  //-------| Plan Template rm-lock/unlock Api |-------//
  const fetchRmlockUnlockData = async () => {
    const gradeId = sessionStorage.getItem("gradeId");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/subjects-by-template-feature?planId=${planId}&gradeId=${gradeId}&columnName=STUDENT_REVISION_MODULE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data.data || [];
    } catch (error) {
      // console.error("Error fetching RmLock/Unlock Data:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchRmData = async () => {
      const rmDataList = await fetchRmlockUnlockData();
      setRmLockUnlockDatas(rmDataList);
    };

    fetchRmData();
  }, [planId, selectedSubject,]);

  const subjectID = selectedSubject;
  const idArray = rmLockUnlockDatas.map((item) => item.subjectId)
  const activeStatusArray = rmLockUnlockDatas.map((item) => item.active);
  const foundIndex = idArray.indexOf(subjectID);

  let isActive = false;
  if (foundIndex !== -1) {
    isActive = activeStatusArray[foundIndex];
  }

  //--------| Assigned Contents fo the Chapter |--------//
  const fetchContentUsingChapterId = async (chapterId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/page?pageSize=1000&sortOrder=true&chapterId=${chapterId}&reviewStatus=APPROVED`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.data || [];
  };

  useEffect(async () => {
    if (selectedChapterId) {
      const menuData = await fetchContentUsingChapterId(selectedChapterId);
      if (menuData?.length !== 0) {
        setRevisionDetail(menuData[0].revisionDocMaterials);
        setStudyDetail(menuData[0].studyDocMaterials);
        setShowContentModal(true);
      } else {
        setRevisionDetail([]);
        setStudyDetail([]);
      }
    }
  }, [selectedChapterId]);

  /* Last Date and End Date & Listing the content according to the Start Date
(If there is startDate then the content will list).
Api Integration
 */
  const fetchContentUsingMinDetails = async (schoolId, branchId) => {
    const boardId = sessionStorage.getItem("boardId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");

    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/min/details?schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&chapterId=${selectedChapterId}&academicYearId=${academicYearList}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };

  // Academic year api

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("academicYear", res?.data.data[0].id)
    return res?.data.data[0].id || [];
  };

  useEffect(async () => {
    const fetchMindetails = async () => {
      try {
        const data = await fetchContentUsingMinDetails(schoolId, branchId);
        setMinDetails(data);
      } catch (error) {
        // console.error(error);
      }
    };

    const fetchAcademicYearList = async () => {
      try {
        const data = await fetchAcademicYearData();
        setAcademicYearList(data);
      } catch (error) {
        // console.error(error);
      }
    };
    fetchMindetails();
    fetchAcademicYearList();
  }, [
    schoolId,
    branchId,
    selectedSubject,
    selectedsubTopic,
    selectedChapterId,
  ]);

  const handleSubject = (event) => {
    // console.log("handleSubject", handleSubject)
    setSelectedSubject(event.target.value?.subjectId);
    setSelectedSubName(event.target.value.subject);
    // console.log(subjectList, event.target.value)
    const selectedSubject = subjectList.find(val => val.subjectId === event.target.value?.subjectId);
    // console.log('sssssss', event.target.value,)
    // console.log(selectedSubject?.hideSubtopics, selectedSubject.subjectId)
    // console.log(selectedsubTopic, "selectedsubTopic", showSubTopic)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      setSelectedSubTopic("")
      // console.log(selectedsubTopic, "selectedsubTopic11111", showSubTopic)
    } else {
      setShowSubTopic(true);
      // console.log(selectedsubTopic, "selectedsubTopic222222222", showSubTopic)
    }
  };

  const handleSubTopic = (event) => {
    // console.log("selected suvtopic", event.target.value)
    setSelectedSubTopic(event.target.value);
    sessionStorage.setItem("subTopicId", event.target.value)
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const chapList = await fetchChapterData();
      // console.log(chapList, chapterData);
      setChapterData(chapList);
      setLoading(false);
    };

    fetchData();
  }, [selectedSubject, selectedsubTopic, planId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var subList = await fetchSubjectData();
      // console.log("subList", subList)
      // console.log(subList, "27-6-24 required")
      // subList = subList?.filter(v => v.skilledSubject === true) || []
      setSubjectList(subList);
      setSelectedSubject(subList.length > 0 ? subList[0].subjectId : "");
      // console.log("subList", subList, subList[0]?.hideSubtopics ? false : true)
      setShowSubTopic(subList[0]?.hideSubtopics ? false : true);
      // console.log(subList[0]?.hideSubtopics ? false : true)
      // alert("SS")
      setLoading(false);
    };

    fetchData();
  }, [planId]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedSubject) {
        setLoading(true);
        const VselectedSubject = subjectList.find(val => val.subjectId === String(selectedSubject));
        if (VselectedSubject?.hideSubtopics) {
          setSelectedSubTopic(null)
        } else {
          const subTopicList = await fetchSubTopicData(selectedSubject);
          setSubTopic(subTopicList);
          if (subTopicList?.length > 0) {
            setSelectedSubTopic(subTopicList[0].id);
          } else {
            setSelectedSubTopic(null)
          }
        }

        setLoading(false);
      }
    };

    fetchData();
  }, [selectedSubject]);

  useEffect(() => {
    const fetchData = async () => {
      const datas = await fetchChapterData(selectedSubject);
      // setSubTopic(datas);
      setLoading(false);
    };

    fetchData();
  }, []);

  //-------| Truncate the Chapter Name |-------//
  const maxLength = 18;

  return (
    <>
      {!showContentModal ? (
        <div className="wholePage">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                width: "80vw",
              }}
            >
              {/* <CircularProgress /> loaderdisable */}
            </Box>
          ) : (
            <div>
              {/*-------| Common Items Across The Project Starts Here |-------*/}
              <article>
                {/* <div className={dashboardContentStyles.dashboard_link}>
                  <span className={dashboardContentStyles.link_icon}>
                    <img
                      src={dashHome}
                      alt="no img"
                      width="15px"
                      height="20px"
                      style={{ verticalAlign: "middle" }}
                    />
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      marginLeft: "4px",
                      color: "#7A7A7A",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/dashboard/template");
                    }}
                  >
                    Home ⨠
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#001AF3",
                      marginLeft: "4px",
                    }}
                  >
                    Subjects
                  </span>
                </div> */}

                <div style={{ position: 'relative', margin: '14px' }}>
                  <span className={dashboardContentStyles.link_icon}>
                    <img
                      src={dashHome}
                      alt="no img"
                      className={dashboardContentStyles.az_menu_icon}
                    />
                  </span>
                  <span
                    id={`az-breadscrum-home`}
                    className={dashboardContentStyles.az_menu_text}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                      sessionStorage.removeItem("sideNavSubMenuNames");
                      navigate("/dashboard/template");
                    }}
                  >
                    Home
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"
                      style={{ color: "#828282" }} />
                  </span>
                  <span>
                    <i className="fa-solid fa-angle-right"
                      style={{ color: "#828282" }} />
                  </span>
                  <span
                    className={dashboardContentStyles.az_selected_menu_text}
                  >
                    Subjects
                  </span>
                </div>

                {/* <div className={dashboardContentStyles.dashboard_last_updated}>
                  <p>Last Update: {modifiedDate.data}</p>
                </div> */}
              </article>
              {/*-------| Common Items Across The Project Ends Here |-------*/}

              <div>
                {/*-------| DropDown & Search Division Starts Here |-------*/}
                <div className="dropdown_And_search_div">
                  {/*-------| DropDown Division Starts Here |-------*/}
                  <div className="dropdown_div">
                    {/*-------| DD-1 |-------*/}
                    <div>
                      <Box sx={{ minWidth: 120 }}>
                        {/* <FormControl
                          fullWidth
                          size="small"
                          sx={{
                            "& .MuiInputLabel-root": {
                              color: "#FD8C00 !important",
                              fontSize: "14px !important",
                              opacity: 0.9,
                              lineHeight: 1.1,
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "#FFFFFF",
                              borderRadius: "50px",
                              width: "146px",
                              height: "32px",
                            },
                          }}
                        >
                          {!selectedSubject && (
                            <InputLabel
                              id="az-sudent-subject-select-label"
                              shrink={false}
                            >
                              Subjects
                            </InputLabel>
                          )}
                          <Select
                            labelId="az-sudent-subject-select-label"
                            id="az-sudent-subject-select"
                            value={selectedSubject}
                            label=""
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={handleSubject}
                            setSubjectList={selectedSubject}
                          >
                            {subjectList?.map((item) => {
                              return (
                                <MenuItem value={item.subjectId}
                                  id={`az-student-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: 'orange',
                                      color: "#FFFFFF"
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: '#fcedc2',
                                      color: "#000000"
                                    },
                                    "&:not(.Mui-selected):hover": {
                                      backgroundColor: '#fcedc2',
                                      color: '#000000',
                                    },
                                  }}>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        noWrap
                                        style={{
                                          fontSize: "12px",
                                          color:
                                            selectedSubject === item.subjectId
                                              ? "#FD8C00"
                                              : "black",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "100%",
                                        }}
                                      >
                                        {item.subject}
                                      </Typography>
                                    }
                                  />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl> */}

                        <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              fontSize: '13px',
                              color: '#e7ab3c',
                              top: '-10px',
                              '&.MuiInputLabel-shrink': {
                                top: '0',
                              },
                              '&.Mui-focused': {
                                color: '#e7ab3c'
                              }
                            }} className='dropDownLabel'
                            id="az-sudent-subject-select-label"
                          >
                            Subject
                          </InputLabel>
                          <Select
                            labelId="az-sudent-subject-select-label"
                            id="az-sudent-subject-select"
                            value={subjectList.find((e) => e.subjectId === selectedSubject)}
                            input={<OutlinedInput label='Subject' />}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  width: '150px',
                                  marginTop: '2px',
                                },
                              },
                              MenuListProps: {
                                sx: {
                                  padding: '0px'
                                },
                              },
                            }}
                            IconComponent={props => (
                              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                            )}
                            sx={{
                              width: '150px',
                              height: '30px',
                              padding: '10px',
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#000',
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#e7ab3c',
                              }
                            }}
                            className='dropdownSelect'
                            onChange={handleSubject}
                            setSubjectList={selectedSubject}
                          >
                            {subjectList?.map((item) => {
                              return (
                                <MenuItem value={item}
                                  id={`az-student-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                                  className={
                                    dashboardContentStyles.menu_item
                                  }
                                  sx={{
                                    fontSize: '10px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                      backgroundColor: '#f4d788',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#e7ab3c',
                                      '&:hover': {
                                        backgroundColor: '#f4d788',
                                      },
                                    },
                                  }}>
                                  {item.subject}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    {/*---------------------------| DD-2 |---------------------------*/}
                    {
                      showSubTopic &&
                      <div>
                        <Box sx={{ minWidth: 120 }}>
                          {/* <FormControl
                            fullWidth
                            size="small"
                            sx={{
                              "& .MuiInputLabel-root": {
                                color: "#FD8C00 !important",
                                fontSize: "14px !important",
                                opacity: 0.9,
                                lineHeight: 1.1,
                              },
                              "& .MuiInputBase-root": {
                                backgroundColor: "#FFFFFF",
                                borderRadius: "50px",
                                width: "148px",
                                height: "32px",
                              },
                            }}
                          >
                            {!selectedsubTopic && (
                              <InputLabel
                                id="az-sudent-subtopic-select-label"
                                shrink={false}
                              >
                                SubTopics
                              </InputLabel>
                            )}
                            <Select
                              labelId="az-sudent-subtopic-select-label"
                              id="az-sudent-subtopic-select"
                              value={selectedsubTopic}
                              label=""
                              IconComponent={KeyboardArrowDownIcon}
                              onChange={handleSubTopic}
                            >
                              {subTopic?.map((item) => {
                                return (
                                  <MenuItem value={item.id} key={item.id}
                                    id={`az-student-subtopic-dropdown-${item.subTopic.toLowerCase().replace(/ /g, "_")}`}
                                    sx={{
                                      "&.Mui-selected": {
                                        backgroundColor: 'orange',
                                        color: "#FFFFFF"
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor: '#fcedc2',
                                        color: "#000000"
                                      },
                                      "&:not(.Mui-selected):hover": {
                                        backgroundColor: '#fcedc2',
                                        color: '#000000',
                                      },
                                    }}>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          noWrap
                                          style={{
                                            fontSize: "12px",
                                            color:
                                              selectedsubTopic === item.id
                                                ? "#FD8C00"
                                                : "black",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100%",
                                          }}
                                        >
                                          {item.subTopic}
                                        </Typography>
                                      }
                                    />
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl> */}

                          <FormControl fullWidth>
                            <InputLabel sx={{
                              fontSize: '13px',
                              color: '#e7ab3c',
                              top: '-10px',
                              '&.MuiInputLabel-shrink': {
                                top: '0',
                              },
                              '&.Mui-focused': {
                                color: '#e7ab3c'
                              }
                            }} className='dropDownLabel'
                              id="az-sudent-subtopic-select-label">SubTopics</InputLabel>
                            <Select
                              labelId="az-sudent-subtopic-select-label"
                              id="az-sudent-subtopic-select"
                              value={selectedsubTopic}
                              input={<OutlinedInput label='SubTopics' />}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    width: '150px',
                                    marginTop: '2px',
                                  },
                                },
                                MenuListProps: {
                                  sx: {
                                    padding: '0px'
                                  },
                                },
                              }}
                              IconComponent={props => (
                                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                              )}
                              sx={{
                                width: '150px',
                                height: '30px',
                                padding: '10px',
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#000',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#000',
                                },
                                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#e7ab3c',
                                }
                              }}
                              className='dropdownSelect'
                              onChange={handleSubTopic}
                            >
                              {subTopic?.map((item) => {
                                return (
                                  <MenuItem value={item.id} key={item.id}
                                    id={`az-student-subtopic-dropdown-${item?.subTopic?.toLowerCase().replace(/ /g, "_")}`}
                                    className={
                                      dashboardContentStyles.menu_item
                                    }
                                    sx={{
                                      fontSize: '10px',
                                      backgroundColor: 'white',
                                      '&:hover': {
                                        backgroundColor: '#f4d788',
                                      },
                                      '&.Mui-selected': {
                                        backgroundColor: '#e7ab3c',
                                        '&:hover': {
                                          backgroundColor: '#f4d788',
                                        },
                                      },
                                    }}>
                                    {item.subTopic}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    }
                  </div>
                  {/*-------| DropDown Division Ends Here |-------*/}

                  {/*-------| Search Division Starts Here |-------*/}
                  <div className="search_div">
                    <TextField
                      id={`az-search-field`}
                      onChange={handleInputChange}
                      type={"text"}
                      value={query}
                      placeholder={"Search anything..."}
                      sx={{
                        "& .MuiInputBase-root": {
                          width: "200px !important",
                          height: "30px !important",
                          borderRadius: "50px !important",
                          fontFamily: "Poppins !important",
                          fontSize: "13px",
                          marginLeft: "50px !important",
                          background: "#FFFFFF",
                          border: "1px #FFFFFF",
                          opacity: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {showClear && (
                              <IconButton onClick={handleClear}>
                                <ClearIcon
                                  style={{
                                    color: "#F05262",
                                    fontSize: "1.2vw",
                                  }}
                                />
                              </IconButton>
                            )}
                            {!showClear && (
                              <IconButton>
                                <img
                                  src={searchIcn}
                                  style={{
                                    verticalAlign: "top",
                                    fontSize: "large",
                                  }}
                                  aria-hidden="true"
                                />
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />
                  </div>
                  {/*-------| Search Division Ends Here |-------*/}
                </div>
                {/*-------| DropDown & Search Division Ends Here |-------*/}

                <div className="u_line"></div>

                {/*-------| LMS Card Division Starts Here |-------*/}
                <div>
                  <div
                    id={`az-card-parent`}
                    className={dashboardContentStyles.cardContainer2}>
                    {chapterData.filter((itm) =>
                      itm.chapter.toLowerCase().includes(query)
                    ).length === 0 ? (
                      <div className="Nochapter">No Chapter</div>
                    ) : (
                      chapterData
                        .filter((itm) =>
                          itm.chapter.toLowerCase().includes(query)
                        )
                        .map((item) => {
                          return (
                            <div
                              id={`az-card-child-${item.chapter.toLowerCase().replace(/ /g, "_")}`}
                              className={dashboardContentStyles.card}
                              key={item.chapterId}
                              onClick={(e) => {
                                // console.log(selectedSubject,academicYearList,sessionStorage.getItem("selectedSub"),sessionStorage.getItem("academicYear"))
                                sessionStorage.setItem("selectedSub", selectedSubject)
                                setSecletedCardName(item?.chapter);
                                setSelectedChapterId(item?.id);
                                fetchRmlockUnlockData();
                              }}
                              style={{
                                width: '200px',
                                height: '300px',
                                borderRadius: '10px',
                                // margin: '10px',
                                // display: 'flex',
                                // flexDirection: 'column', // Stack items vertically
                              }}
                            >
                              <div className="cardContent">
                                {item?.thumbnailPath ? (
                                  <img
                                    src={item?.thumbnailPath}
                                    alt="Image"
                                    width={110}
                                    className={dashboardContentStyles.az_quiz_card_img}
                                  />
                                ) : (
                                  <img
                                    src={altImg}
                                    alt="Image"
                                    width={110}
                                    className={dashboardContentStyles.az_quiz_card_img}
                                  />
                                )}
                              </div>

                              {/*-------| Truncated Chapter Name |-------*/}
                              <span className={dashboardContentStyles.az_card_text}>{`${item?.chapter.substring(0, maxLength)}...`}</span>

                              {/* <p style={{ margin: '0', padding: '10px', textAlign: 'bottom' }}>
                                {item?.chapter.length > maxLength ? (
                                  <Tooltip arrow title={item?.chapter} classes={{ tooltip: classes.tooltip }}>
                                    <span>{`${item?.chapter.substring(0, maxLength)}...`}</span>
                                  </Tooltip>
                                ) : (
                                  item?.chapter
                                )}
                              </p> */}
                            </div>
                          );
                        })
                    )}
                  </div>
                </div>

                {/*-------| LMS Card Division Ends Here |-------*/}

                {/*-------| Message Icon Division Starts Here |-------*/}
                {/* <div>
          <div className="msg_Icon_style">
            <img src={msgIcon} alt="msgIcon" />
          </div>
        </div> */}
                {/*-------| Message Icon Division Ends Here |-------*/}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Student_Study_Files
          minDetails={minDetails}
          revisionData={revisionDetail}
          studyData={studyDetail}
          closeModal={() => {
            setShowContentModal(false);
            setSelectedChapterId("");
            setRevisionDetail([]);
            setStudyDetail([]);
          }}
          selectedChapterId={selectedChapterId}
          selectedCardName={secletedCardName}
          pageName={"Subjects"}
          isActive={isActive}
          selectedSubject={selectedSubject}
          selectedsubTopic={selectedsubTopic}
          subjectList={subjectList}
        />
      )}
    </>
  );
}

export default Student_Study;
