import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const QuestionTypeContext = createContext();

const QuestionTypeContextProvider = (props) => {
  const [allQuestionType, setAllQuestionType] = useState([]);

  const TOKEN = sessionStorage.getItem('token');
  // console.log(TOKEN);

  const BASE_URL = process.env.REACT_APP_SWAGGER_URL;

  useEffect(() => {
    if (TOKEN) {
      const fectchAllQuestionType = () => {
        axios
          .get(`${BASE_URL}v1/api/content/question-type/all`, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          })
          .then((response) => setAllQuestionType(response.data))
          .catch((error) => console.error(error));
      };
      fectchAllQuestionType();
    }
  }, [BASE_URL, TOKEN]);

  return (
    <QuestionTypeContext.Provider value={{ allQuestionType }}>
      {props.children}
    </QuestionTypeContext.Provider>
  );
};

export default QuestionTypeContextProvider;
