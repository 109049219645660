import {
  Box,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MenuProps } from '../../Data'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import dashboardContentStyles from '../../../css/dashboardContent.module.css'
import searchIcn from '../../../../img/search.svg'
import { isNOTNullOrUndefined } from '../../../../utils/helper'
import { Input, makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: '190px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
}));

function ChapterViewOptimised({
  selectedGrade,
  handleChangeGrade,
  gradeSection,
  selectedSection,
  handleChangeSection,
  selectedSubject,
  handleChangeSubject,
  subjectList,
  selectedSubTopic,
  selectedAcademicYear,
  setSelectedAcademicYear,
  academicYearList,
  isError,
  updatedData,
  onChapterClick,
  handleChangeSubtopic,
  sectionList,
  showSubTopic
}) {

  // console.log("updatedData", updatedData, selectedGrade, selectedSection, selectedSubject)
  const [tempUpdatedData, setTempUpdatedData] = useState(updatedData);


  sessionStorage.setItem("chapterList", JSON.stringify(tempUpdatedData))

  // console.log("showSubTopic", showSubTopic, selectedSubject, subjectList)
  if (selectedSubject?.hideSubtopics) {
    showSubTopic = false
  } else {
    showSubTopic = true
  }


  // useEffect(() => {
  //   console.log("ChapterViewOptimised.js", updatedData, JSON.parse(sessionStorage.getItem("tempUpdatedData")))
  // })


  // console.log("chapterviewoptimised.js -----------------", showSubTopic)
  const classes = useStyles();

  const ChapterItem = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }))

  const filterStyle = {
    '& .css-10hburv-MuiTypography-root': {
      fontSize: '12px !important',
      textOverflow: 'ellipsis'
    },
    '&.MuiOutlinedInput-root': {
      '& > fieldset': {
        border: '1px solid #D6D5DF !important'
      }
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #272727 !important'
      }
    },
    fontSize: '14px !important',
    font: 'Poppins !important'
  }
  const [query, setQuery] = useState('')

  //-------| Truncate the Chapter Name |-------//
  const maxLength = 20;

  return (
    <div
      style={{
        marginTop: '10px',
        paddingRight: '33px',
        position: 'absolute',
        right: '15px',
        left: '15px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '10px',
          alignItems: 'center',
          columnGap: '1rem'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '10px'
          }}
        >
          {/* <FormControl
            sx={{
              width: '113px',
              height: 45
            }}
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                marginBottom: 6,
                '&.Mui-focused': {
                  color: 'orange'
                }
              }}
              id='demo-simple-select-label'
            >
              Grades
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Grades' />}
              MenuProps={MenuProps}
              size={'small'}
              sx={filterStyle}
              style={{ height: '100%', background: 'white', borderRadius: 150 }}
              value={isNOTNullOrUndefined(selectedGrade) ? selectedGrade : ''}
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              onChange={handleChangeGrade}
            >
              {gradeSection?.map(item => {
                return (
                  <MenuItem
                    // value={item?.id}
                    // key={item.id}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.id}
                    key={item.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    {item.grade}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl> */}
          
          <FormControl fullWidth>
          <InputLabel
          sx={{
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
            }}  
          id="demo-simple-select-label" className='dropDownLabel'>Grade</InputLabel>
            <Select  labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Grade' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop:'2px', 
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}  
              value={isNOTNullOrUndefined(selectedGrade) ? selectedGrade : ''}
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              onChange={handleChangeGrade}
              sx={{
                width: '150px',    
                height: '30px',    
                padding: '10px',  
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c', 
                }
              }}
              className='dropdownSelect'
            >
              {gradeSection?.map(item => {
                return (
                  <MenuItem
                    // value={item?.id}
                    // key={item.id}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.id}
                    key={item.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}>
                    {item.grade}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth >
            <InputLabel 
            sx={{
                  top: '-10px',
                  '&.MuiInputLabel-shrink': {
                    top: '0',
                  },
                }}  
            id="demo-simple-select-label" className='dropDownLabel'>Section</InputLabel>
            <Select labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Section' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop:'2px', 
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}  
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              value={
                isNOTNullOrUndefined(selectedSection) ? selectedSection : ''
              }
              onChange={handleChangeSection}
              sx={{
                width: '150px',    
                height: '30px',    
                padding: '10px', 
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c', 
                }
              }}
              className='dropdownSelect'
            >
              {sectionList?.map(sec => {
                return (
                  <MenuItem
                    // value={sec.id}
                    // key={sec.id}
                    // className={dashboardContentStyles.menu_item}
                    value={sec?.id}
                    key={sec.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    {sec.section}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel
            sx={{
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
            }} 
           id="demo-simple-select-label" className='dropDownLabel'>Subject</InputLabel>
            <Select  labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Subject' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop:'2px', 
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}  
              value={
                isNOTNullOrUndefined(selectedSubject?.subjectId)
                  ? selectedSubject.subjectId
                  : ''
              }
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              onChange={handleChangeSubject}
              sx={{
                width: '150px',    
                height: '30px',    
                padding: '10px', 
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c', 
                }
              }}
              className='dropdownSelect'
            >
              {subjectList?.map(item => {
                return (
                  <MenuItem
                    // value={item.subjectId}
                    // key={item.subjectId}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.subjectId}
                    key={item.subjectId}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    {item.subject}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              minWidth: '7.8vw',
              background: 'white',
              borderRadius: 150
            }}
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                marginBottom: 6,
                '&.Mui-focused': {
                  color: 'orange'
                }
              }}
              id='demo-simple-select-label'
            >
              Section
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Section' />}
              MenuProps={MenuProps}
              size={'small'}
              sx={filterStyle}
              style={{ height: '100%', background: 'white', borderRadius: 150 }}
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              value={
                isNOTNullOrUndefined(selectedSection) ? selectedSection : ''
              }
              onChange={handleChangeSection}
            >
              {sectionList?.map(sec => {
                return (
                  <MenuItem
                    // value={sec.id}
                    // key={sec.id}
                    // className={dashboardContentStyles.menu_item}
                    value={sec?.id}
                    key={sec.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    {sec.section}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl> */}

          {/* <FormControl
            sx={{
              width: '113px',
              height: 45
            }}
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                marginBottom: 6,
                '&.Mui-focused': {
                  color: 'orange'
                }
              }}
              id='demo-simple-select-label'
            >
              Subjects
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Subjects' />}
              MenuProps={MenuProps}
              size={'small'}
              sx={filterStyle}
              style={{
                height: '100%',
                background: 'white',
                borderRadius: 150
              }}
              value={
                isNOTNullOrUndefined(selectedSubject?.subjectId)
                  ? selectedSubject.subjectId
                  : ''
              }
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              onChange={handleChangeSubject}
            >
              {subjectList?.map(item => {
                return (
                  <MenuItem
                    // value={item.subjectId}
                    // key={item.subjectId}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.subjectId}
                    key={item.subjectId}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    {item.subject}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl> */}

          {/* Assign Quiz menu ---------changes */}
          {/* {
            console.log(showSubTopic, "-=-=-===-=-=-==-=--=-===-=-=-=-=-=-=-=-=-=--=-===")
          } */}
          {
            showSubTopic &&

            <FormControl fullWidth>
            <InputLabel 
              sx={{
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
              }} 
            id="demo-simple-select-label" className='dropDownLabel'>SubTopic</InputLabel>
            <Select 
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                input={<OutlinedInput label='SubTopic' />}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: '150px',
                      marginTop:'2px', 
                    },
                  },
                  MenuListProps: {
                    sx: {
                      padding: '0px'
                    },
                  },
                }}  
                value={
                  isNOTNullOrUndefined(selectedSubTopic) ? selectedSubTopic : ''
                }
                IconComponent={props => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                )}
                onChange={handleChangeSubtopic}
                sx={{
                  width: '150px',    
                  height: '30px',    
                  padding: '10px', 
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e7ab3c', 
                  }
                }}
                className='dropdownSelect'
              >
                {selectedSubject?.subTopics?.map(item => (
                  <MenuItem
                    // value={item.id}
                    // key={item.id}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.id}
                    key={item.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    {item?.subTopic}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>

            // <FormControl
            //   sx={{
            //     width: '113px',
            //     height: 45
            //   }}
            // >
            //   <InputLabel
            //     sx={{
            //       fontSize: '14px',
            //       marginBottom: 6,
            //       '&.Mui-focused': {
            //         color: 'orange'
            //       }
            //     }}
            //     id='demo-simple-select-label'
            //   >
            //     SubTopic
            //   </InputLabel>
            //   <Select
            //     labelId='demo-simple-select-label'
            //     id='demo-simple-select'
            //     input={<OutlinedInput label='SubTopic' />}
            //     MenuProps={MenuProps}
            //     size={'small'}
            //     sx={filterStyle}
            //     style={{
            //       height: '100%',
            //       background: 'white',
            //       borderRadius: 150,
            //       width: '100%'
            //     }}
            //     value={
            //       isNOTNullOrUndefined(selectedSubTopic) ? selectedSubTopic : ''
            //     }
            //     IconComponent={props => (
            //       <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            //     )}
            //     onChange={handleChangeSubtopic}
            //   >
            //     {selectedSubject?.subTopics?.map(item => (
            //       <MenuItem
            //         // value={item.id}
            //         // key={item.id}
            //         // className={dashboardContentStyles.menu_item}
            //         value={item?.id}
            //         key={item.id}
            //         className={
            //           dashboardContentStyles.menu_item
            //         }
            //         sx={{
            //           "&.Mui-selected": {
            //             backgroundColor: 'orange',
            //             color: "#FFFFFF"
            //           },
            //           "&.Mui-selected:hover": {
            //             backgroundColor: '#fcedc2',
            //             color: "#000000"
            //           },
            //           "&:not(.Mui-selected):hover": {
            //             backgroundColor: '#fcedc2',
            //             color: '#000000',
            //           },
            //         }}
            //       >
            //         {item?.subTopic}
            //       </MenuItem>
            //     ))}
            //   </Select>
            // </FormControl>
          }

           <FormControl fullWidth 
            >
            <InputLabel 
             sx={{
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
            }} 
            id="demo-simple-select-label" className='dropDownLabel'>Academic Year</InputLabel>
            <Select 
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                input={<OutlinedInput label='Academic Year' />}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: '150px',
                      marginTop:'2px', 
                    },
                  },
                  MenuListProps: {
                    sx: {
                      padding: '0px'
                    },
                  },
                }}  
                value={selectedAcademicYear}
                IconComponent={props => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                )}
                onChange={e => setSelectedAcademicYear(e.target.value)}
                disabled
                sx={{
                  width: '150px',    
                  height: '30px',    
                  padding: '10px', 
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e7ab3c', 
                  },
                }}
                className='dropdownSelect'
              >
                 <MenuItem
                // value={academicYearList[0]?.id}
                // key={academicYearList[0]?.id}
                // className={dashboardContentStyles.menu_item}
                value={academicYearList[0]?.id}
                key={academicYearList[0]?.id}
                className={
                  dashboardContentStyles.menu_item
                }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                   {academicYearList[0]?.academicYear}
                </MenuItem>
              </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              width: '113px',
              height: 45
            }}
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                marginBottom: 6,
                '&.Mui-focused': {
                  color: 'orange'
                }
              }}
              id='demo-simple-select-label'
            >
              Academic Years
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Academic Years' />}
              MenuProps={MenuProps}
              value={selectedAcademicYear}
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              onChange={e => setSelectedAcademicYear(e.target.value)}
              disabled
            >
              <MenuItem
                // value={academicYearList[0]?.id}
                // key={academicYearList[0]?.id}
                // className={dashboardContentStyles.menu_item}
                value={academicYearList[0]?.id}
                key={academicYearList[0]?.id}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
              >
                {academicYearList[0]?.academicYear}
              </MenuItem>
            </Select>
          </FormControl> */}
        </div>
        <TextField
          type={'text'}
          placeholder={'Search anything....'}
          onChange={e => setQuery(e.target.value.toLowerCase())}
          InputProps={{
            endAdornment: (
              <IconButton>
                <img
                  src={searchIcn}
                  style={{
                    verticalAlign: 'top',
                    fontSize: 'large'
                  }}
                  aria-hidden='true'
                  alt=''
                />
              </IconButton>
            )
          }}
          sx={{
            background: !isError && '#fff',
            borderRadius: '150px',
            '& .MuiInputBase-root': {
              width: '200px !important',
              height: '30px !important',
              borderRadius: '50px !important',
              background: '#fff !important',
              lineHeight: '21px !important',
              font: 'normal normal normal 15px/15px Poppins !important'
            }
          }}
          error={isError}
          helperText={isError && 'Please Enter min 3 character.'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 700,
          width: '100%',
          padding: '25px 20px',
          overflow: 'auto',
          scrollbarWidth:"thin"
        }}
      >
        {selectedGrade && selectedSection && selectedSubject ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              
              {updatedData?.length > 0 ? (
                updatedData
                  ?.filter(itm =>
                    itm.chapter.toLowerCase().includes(query)
                  )
                  .map((item, i) => (
                    <div
                      key={item.chapterId}
                      onClick={() => onChapterClick(item)}
                      className={dashboardContentStyles.card}
                    >
                      <img
                        src={item?.thumbnailPath}
                        width={110}
                        alt={""}
                        // style={{ maxHeight: "138px" }}
                        className={dashboardContentStyles.az_quiz_card_img} />
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#423d5c",
                        }}
                      >
                        {item?.chapter}
                      </div>
                    </div>
                    // <ChapterCard
                    //   key={i}
                    //   item={item}
                    //   onChapterClick={() => {
                    //     setSelectedChapterId(item?.chapterId)
                    //     fetchEnableAssignBtn(selectedGrade)
                    //   }}
                    // />
                  ))


              ) : (
                <div>No data for the selected filters</div>
              )}
            </Grid>
          </Box>
        ) : (
          <div>Please select Grade , Section & Subject</div>
        )}
      </div>
    </div>
  )
}

export default ChapterViewOptimised
