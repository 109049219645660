import { Box, Modal, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import React, { useEffect, useState } from "react";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import YellowButton from "../../components/common/yellowButton";
import Switch from "../../components/common/Switch";
import axios from "axios";
import YellowBorderHover from "../../components/common/YellowBorderHover";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "477px",
  height:"352px",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};
const ViewSubtopicModal = ({ open, onClose, subtopicList, deleteHandler }) => {
  const subTopic = subtopicList?.subTopics;
  // console.log(subTopic);
  const [subTopicData, setSubTopicData] = useState(subTopic);
  const [idList, setIdList] = useState([]);
  const [id, setId] = useState();
  const token = sessionStorage.getItem("token");
  const [isDelete, setDelete] = useState(true);
  const [message, setMessage] = useState("");
  const [deletpopup, setdeletepopup] = useState(false);
  useEffect(() => {
    if (subTopic) {
      setSubTopicData(subTopic);
    }
  }, [subTopic]);
  const idHandler = async (id) => {
    setDelete(false);
    setdeletepopup(true);
    setId(id);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/confirmation-api?id=${id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setMessage(response?.data?.data?.message);
  };

  const no = () => {
    setDelete(true);
  };

  const deleteItem = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/bulk-delete?ids=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        const updateValue = subTopicData?.filter((item) => item.id !== id);
        setSubTopicData(updateValue);
        setIdList([...idList, id]);
        setDelete(true);
      })
      .catch(function (response) {});
  };
  const [toggleId, settoggleid] = useState({});
  const switchHandler = async (id, e) => {
    const value = e.target.checked;
    if (!value) {
      settoggleid({ id, value });
      setDelete(false);
      setdeletepopup(false);
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage(response.data.data.message);
    } else {
      const temp = [{ uniqueValue: id, value }];
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/bulk-toggle`,
          temp,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          const ele = subTopicData.filter((v) => v.id != id);
          const uptele = subTopicData.find((v) => v.id == id);
          uptele.active = value;
          setSubTopicData([...ele, uptele]);
          setDelete(true);
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };
  const updateToggle = () => {
    const { id, value } = toggleId;
    const temp = [{ uniqueValue: id, value }];
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/bulk-toggle`,
        temp,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: value,
        }
      )
      .then(function (response) {
        const ele = subTopicData.filter((v) => v.id != id);
        const uptele = subTopicData.find((v) => v.id == id);
        uptele.active = value;
        setSubTopicData([...ele, uptele]);
        setDelete(true);
      })
      .catch(function (response) {
        // console.log(response);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isDelete ? (
          <>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "16px",
                fontFamily:"Poppins",
                color:'#354052'
              }}
            >
              {subtopicList?.subject}
              <CloseOutlinedIcon
                onClick={() => {
                  onClose();
                  setSubTopicData(subTopic);
                }}
                style={{ cursor: "pointer", color: grey[500] }}
              />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#FD8C00",
                  fontWeight: 600,
                  fontFamily:"Poppins",
                  fontSize:"13px"
                }}
              >
                <div>Subtopics</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "130px",
                    color: "#FD8C00",
                    fontWeight: 600,
                    fontFamily:"Poppins",
                    fontSize:"13px"
                  }}
                >
                  <div>Action</div>
                  {/* <div>Delete</div> */}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: 0,
                  border: "1px solid ",
                  borderColor: grey[500],
                  marginTop: 6,
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {subTopicData?.map((item) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom:"12px"
                      }}
                      key={item.id}
                    >
                      <div style={{    
                          color: "#272727",
                          fontWeight: 500,
                          fontFamily:"Poppins",
                          fontSize:"14px"}}>{item?.subTopic}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          gap:4,
                          width: "130px",
                    paddingLeft:16
                        }}
                      >
                        <Switch
                          checked={item?.active}
                          onChange={(e) => switchHandler(item?.id, e)}
                        />
                        <DeleteIconButton onClick={() => idHandler(item?.id)}>
                          <DeleteOutlinedIcon style={{ fontSize: "25px" }} />
                        </DeleteIconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Typography>
          </>
        ) : (
          <div style={{ padding: "40px" }}>
            {deletpopup && (
              <>
                <p>{message}</p>
                {message ==
                "No Permission to delete or deactivate, as it has existing mapping in the workflow..!" ? (
                  <div>
                    {" "}
                    <YellowButton onClick={no}>Ok</YellowButton>{" "}
                  </div>
                ) : (
                  <div style={{ marginTop: "40px" }}>
                    <YellowBorderHover onClick={deleteItem}>
                      Yes
                    </YellowBorderHover>
                    <YellowButton onClick={no}>No</YellowButton>
                  </div>
                )}
              </>
            )}
            {!deletpopup && (
              <>
                <p>{message}</p>
                {message ==
                "No Permission to delete or deactivate, as it has existing mapping in the workflow..!" ? (
                  <div>
                    {" "}
                    <YellowButton onClick={no}>Ok</YellowButton>{" "}
                  </div>
                ) : (
                  <div style={{ marginTop: "40px" }}>
                    <YellowBorderHover onClick={updateToggle}>
                      Yes
                    </YellowBorderHover>
                    <YellowButton onClick={no}>No</YellowButton>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Box>
    </Modal>
  );
};
export default ViewSubtopicModal;
