import React, { useState, useEffect, useRef, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// Css Pages
import "../../css/Student/Student_Study_Files.css";

// Images Used
import rytArrows from "../../../../src/img/right-arrows.svg";
import SideMenu from "../../../../src/pages/js/Student/Student_SideMenu";

// Mui
import { Paper } from "@mui/material";
import { Tooltip } from "@material-ui/core";
import CustomVideoPlayer from "../CustomVideoPlayer/CustomVideoPlayer";
import HomeWorkModal from "../HomeWorkAssignment/HomeWorkModal";

// import PDFViewer from "pdf-viewer-reactjs";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import ContentViewer from '../../../components/common/ContentViewer';

function Student_Study_Files({
  revisionData,
  studyData,
  selectedCardName,
  selectedChapterId,
  closeModal,
  pageName,
  minDetails,
  isActive,
  selectedSubject,
  selectedsubTopic,
  subjectList
}) {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [component, setComponent] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [collapseId, setCollapseId] = useState("");
  const [isPlaying, setPlaying] = useState(false);
  const [progressObj, setProgressObj] = useState({});
  const [duration, setDuration] = useState(0);
  const [subDurationValue, setSubDurationValue] = useState(0);
  const [tabsValue, setTabsValue] = useState("1");
  const [tab, setTab] = useState("Practice Question");
  const [isExpanded, setExpendState] = useState(false);
  const videoRef = useRef(null);
  const [presentationPageNumber, setPresentationPageNumber] = useState(1);
  const [presentationNumPages, setPresentationNumPages] = useState(null);
  const [enterPageNo, setEnterPagNo] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState({});

  const [isHomeWorkModalOpen, setIsHomeWorkModalOpen] = useState(false);
  const [fileUrlValues, setFileUrlValues] = useState([]);
  const [fileUrlObjects, setfileUrlObjects] = useState([]);
  var vfileUrlObjects = [];


  //const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    Open: () => <></>,
    Print: () => <></>,
  });

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0], // Thumbnails tab      
    ],
    //setInitialTab: (doc) => Promise.resolve(0),
    renderToolbar,
  });


  const docs = [
    //  {
    //   uri : 'https://cors-anywhere.herokuapp.com/https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/Content-Service/Student/866d4cc5-b1cb-4201-b457-edf57c738a46_Video.mp4',
    //   fileType : 'mp4'
    // },
    {
      uri: "https://cors-anywhere.herokuapp.com/https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/Content-Service/Student/93f47876-1041-4712-b846-4388fb5b8ef7_Gr10-ENG-CH9-MADAM%20RIDES%20THE%20BUS-WB.pdf", // for pdf
      fileType: "pdf",
    },

  ];

  const boardId = sessionStorage.getItem("boardId");
  const schoolId = sessionStorage.getItem("schoolId");
  const branchId = sessionStorage.getItem("branchId");
  const gradeId = sessionStorage.getItem("gradeId");
  const sectionId = sessionStorage.getItem("sectionId");

  // console.log("MIN DETAILS FILES", minDetails.startDate);

  // console.log(component);
  // console.log("selectedUrl", selectedUrl);
  // console.log("startIndex", startIndex);
  // console.log("selectedMenu", selectedMenu);
  // console.log("collapseId", collapseId);
  // console.log("revisionData", revisionData);
  // console.log("studyData", studyData);

  const onError = (e) => {
    // console.log(e, "error in file-viewer");
  };



  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default right-click behavior
  };

  const viewerRef = useRef(null);



  useEffect(() => {
    if (revisionData?.length) {
      const data = revisionData[0]?.studentStudyContent;
      if (!data?.length) {
        setSelectedMenu(data?.documentType);
      } else {
        setSelectedItemDetails(data[0]);
        setSelectedMenu(data[0]?.contentWithIndux);
        setSelectedUrl(data[0]?.contentUrl);

      }
    }
  }, [revisionData]);



  useEffect(() => {
    if (studyData?.length) {
      const data = studyData[0]?.studentStudyContent;
      if (!data?.length) {
        setSelectedMenu(data?.documentType);
      } else {
        setSelectedItemDetails(data[0]);
        setSelectedMenu(data[0]?.contentWithIndux);
        setSelectedUrl(data[0]?.contentUrl);
      }
    }
  }, [studyData]);

  const handleClickRevision = (id, item, index) => {
    setCollapseId((pre) => (pre === id ? "" : id));
    if (!item?.studentRevisionContent?.length) {
      setSelectedMenu(item?.documentType);
    }
    setStartIndex(index);
  };
  const handleClickStudy = (id, item, index) => {
    setCollapseId((pre) => (pre === id ? "" : id));
    if (!item?.studentStudyContent?.length) {
      setSelectedMenu(item?.documentType);
    }
    setStartIndex(index);
  };

  const subMenuHandlerRevision = (item, url) => {
    if (item !== selectedMenu) {
      setPlaying(false);
      setProgressObj({});
      setDuration(0);
      setSubDurationValue(0);
    }
    setSelectedMenu(item);
    setSelectedUrl(url);

  };
  const subMenuHandlerStudy = (item, url) => {
    if (item !== selectedMenu) {
      setPlaying(false);
      setProgressObj({});
      setDuration(0);
      setSubDurationValue(0);
    }
    setSelectedMenu(item);
    setSelectedUrl(url);

  };

  const nameHandler = (name) => {
    const data = name.split("/")?.[name.split("/").length - 1]?.split(".");
    // return `${data[0]}.${data?.[data.length - 1]}`;
    // return `${data[0]}`;
    var str = data[0];
    return str.split(' ').map(word => word.split('-').map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)).join('-')).join(' ');
  };

  const checkLastIndexHandler = (menu, value) => {
    const array = menu?.split(".");
    const lastIndex = array?.[array.length - 1];
    //console.log('checkLastIndexHandler >> ', lastIndex?.includes(value), value, selectedUrl)
    if (lastIndex?.includes(value)) {
      vfileUrlObjects = [];
      vfileUrlObjects.push({
        "uri": "https://cors-anywhere.herokuapp.com/" + selectedUrl,
        //"uri" : "https://cors-anywhere.herokuapp.com/https://www.clickdimensions.com/links/TestPDFfile.pdf",
        "fileType": value
      })
      // setfileUrlObjects(vfileUrlObjects)
      // fileUrlObjects.push({
      //   "uri": "https://cors-anywhere.herokuapp.com/" + selectedUrl,
      //   //"uri" : "https://cors-anywhere.herokuapp.com/https://www.clickdimensions.com/links/TestPDFfile.pdf",
      //   "fileType": value
      // });
      // //  setFileUrlValues(fileUrlObjects);
    }
    // console.log(lastIndex?.includes(value))
    return lastIndex?.includes(value);
  };

  // To View The Image/Pdf Full-Sreen
  const handleFullscreen = () => {
    const element = document.querySelector("iframe");
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
  };



  const purchasedFeature = localStorage.getItem("purchasedFeatures");
  const purchasedFeatures = JSON.parse(purchasedFeature);

  const AssignHomeWork = () => {
    setIsHomeWorkModalOpen(true);
  };

  const moveToEnrich = () => {
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = selectedSubject;
    const subTopicId = (sessionStorage.getItem("subTopicId") === "null" || sessionStorage.getItem("subTopicId") === null) ? selectedsubTopic : sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const chapterId = selectedChapterId;
    // console.log("selectedCardName >>> ",
    //   sessionStorage.getItem("subTopicId"), sessionStorage.getItem("subTopicId") === null, typeof sessionStorage.getItem("subTopicId"), sessionStorage.getItem("subTopicId") === "null", selectedsubTopic, selectedsubTopic
    // );
    // const selectedCardName = selectedCardName;
    //  const showContentModal = false;

    var slctdsub = subjectList?.find((v) => v.subjectId === selectedSubject)
    var slctedtop = slctdsub?.subTopics?.find((v) => v.id === selectedsubTopic ? selectedsubTopic : subTopicId)
    const data = {
      bordId,
      gradeId,
      schoolId,
      branchId,
      subjectId,
      subTopicId: selectedsubTopic ? selectedsubTopic : subTopicId,
      sectionId,
      // chapterId,
      academicYearId,
      // selectedChapterId,
      selectedCardName,
      subtopicName: slctedtop?.subTopic,
      ref: selectedsubTopic
      //  showContentModal
    };
    const val = {
      chapId: selectedChapterId,
      // chapName: selectedCardName,
      subject: selectedSubject
    };

    // sessionStorage.setItem("academicYear", academicYearId)
    // sessionStorage.setItem("selectedSub", subjectId)
    //navigate(`/dashboard/enrich`);
    navigate("/dashboard/enrich", {
      state: { ...data, chapterId: selectedChapterId, from: "enrich" },
    });
    sessionStorage.setItem("sideNavMainMenuNames", "Enrich");
  }

  return (
    <>
      {/*----------------------------| Side-Menu Division Starts Here |-----------------------------*/}
      <div >
        <SideMenu
          revisionMenu={revisionData?.length !== 0 ? revisionData : []}
          // revisionMenu={[]}
          studyMenu={studyData?.length && studyData}
          menuRevisionClickHandler={handleClickRevision}
          menuStudyClickHandler={handleClickStudy}
          subMenuHandlerRev={subMenuHandlerRevision}
          subMenuHandlerStu={subMenuHandlerStudy}
          collapseId={collapseId}
          startIndex={startIndex}
          selectedUrl={selectedUrl}
        />
      </div>

      <div className="Main_Container"
        onContextMenu={handleContextMenu}
      >
        {/*---------------------------| Bread Crumbs Starts Here |---------------------------*/}
        <article>
          <div style={{ position: 'relative', margin: '14px' }}>
            <div className="Bread_Crumbs_itms">
              <p
                className="az_menu_text"
                style={{ cursor: 'pointer' }}
                id={`az-breadscrum-home`}
                onClick={() => {
                  sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                  sessionStorage.removeItem("sideNavSubMenuNames");
                  navigate("/dashboard/template");
                }}
              >
                Home
              </p>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <p
                className="az_menu_text"
                style={{ cursor: 'pointer' }}
                id={`az-breadscrum-back`}
                onClick={() => closeModal()}
              >
                {pageName}
              </p>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
              <p className="az_selected_menu_text">{selectedCardName}</p>
              {/* <img src={rytArrows} alt=">>" />
              <p style={{ marginLeft: "4px", color: "#001AF3" }}>
                Concept Name
              </p> */}
            </div>
          </div>
        </article>
        {/*---------------------------| Bread Crumbs Ends Here |---------------------------*/}

        {/*---------------------------| StartDate & Enrich Btn Division Starts Here |---------------------------*/}
        <div>
          <div className="SD_and_btn">
            <div className="StartAndEndDates">
              <tr>
                <td className="SD_heading1">Start Date: </td>
                <td
                  className="S-date"
                  style={{
                    color: minDetails.endDate ? "#0088FF" : "#7A7A7A",
                  }}
                >
                  {minDetails.startDate
                    ? minDetails.startDate.split("-").reverse().join(" -")
                    : "Not started yet"}
                </td>
              </tr>
              <tr>
                <td className="SD_heading2">End Date: </td>
                <td
                  className="E-date"
                  style={{
                    color: minDetails.endDate ? "#0088FF" : "#7A7A7A",
                  }}
                >
                  {minDetails.endDate
                    ? minDetails.endDate.split("-").reverse().join(" -")
                    : "Not ended yet"}
                </td>
              </tr>
            </div>

            <div>
              <button
                style={{
                  marginRight: "5px",
                  backgroundColor: "#1912BF",
                  color: "#ffff",
                  width: "120px",
                  height: "33px",
                  borderRadius: "5px",
                  outline: "none",
                  border: "none",
                }}
                onClick={() => AssignHomeWork()}
                id={`az-student-homework-btn`}
              >
                Homework
              </button>
              {isActive === true ? (
                <button
                  id={`az-student-enrich-btn`}
                  className="Enrich_btn"
                  onClick={moveToEnrich}
                  // onClick={() => {
                  //   const val = {
                  //     chapId: selectedChapterId,
                  //     chapName: selectedCardName,
                  //   };
                  //   navigate(`/dashboard/enrich/${JSON.stringify(val)}`);

                  //   // SS for Highlighthing the Sidenav Menu Name
                  //   sessionStorage.setItem("sideNavMainMenuNames", "Enrich");
                  // }}
                  disabled={!minDetails.endDate}
                  style={{
                    backgroundColor: minDetails.endDate ? "#fd8c00" : "#707070",
                    opacity: minDetails.endDate ? 1 : 0.7,
                  }}
                >
                  Enrich
                </button>
              ) : (
                // <Tooltip title="Not Purchased" arrow placement="top"></Tooltip>
                <button
                  className="Enrich_btn"
                  style={{ background: "#707070" }}
                >
                  Enrich
                </button>
              )}
            </div>
          </div>
        </div>
        {/*---------------------------| StartDate & Enrich Btn Division Ends Here |---------------------------*/}

        {/*Side-Menu Components*/}
        <div>
          <div>
            {checkLastIndexHandler(selectedMenu, "html") ||
              checkLastIndexHandler(selectedMenu, "jpg") ||
              checkLastIndexHandler(selectedMenu, "jpeg") ||
              checkLastIndexHandler(selectedMenu, "png") ||
              checkLastIndexHandler(selectedMenu, "svg") ||
              checkLastIndexHandler(selectedMenu, "gif") ||
              checkLastIndexHandler(selectedMenu, "cur") ?
              (
                <div
                  style={{
                    display: "flex",
                    background: "white",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "20px",
                  }}
                >
                  <div
                    id="student-handle-0"
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      fontSize: "0.84vw",
                    }}
                  >
                    {nameHandler(selectedMenu)}
                  </div>

                  <iframe
                    // style={{ pointerEvents: "none", }}
                    title={nameHandler(selectedUrl)}
                    src={selectedUrl + "#toolbar=0"}
                    className="image_iframe"
                  />

                  {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                    <div style={{ width: "100%", height: "100%" }}
                      onContextMenu={handleContextMenu}>
                      <Viewer
                        ref={viewerRef}
                        fileUrl={'https://cors-anywhere.herokuapp.com/' + selectedUrl}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  </Worker> */}


                  {/* <button
                  onClick={handleFullscreen}
                  className="FullScrnBtn hvr-bubble-top"
                >
                  Fullscreen
                </button> */}
                </div>
              ) : checkLastIndexHandler(selectedMenu, "pdf") ? (
                <div
                // style={{

                //   display: "flex",
                //   background: "white",
                //   flexDirection: "column",
                //   alignItems: "flex-start",
                //   padding: "20px",
                // }}
                >
                  <div
                    id="student-handle-1"
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      fontSize: "0.84vw",
                    }}
                  >
                    {nameHandler(selectedMenu)}
                  </div>
                  {/* inside pdf  */}


                  {/* {
                    //console.log(fileUrlObjects, "fileUrlObjects", "docs", docs)
                  }

                  {fileUrlObjects.map((doc, index) => {
                    //console.log('doc val >> ', doc);
                    return (
                      <DocumentViewer key={index} doc={doc} />
                    )
                  })} */}

                  {/* 
                  <iframe
                  // style={{ pointerEvents: "none" }}
                  title={nameHandler(selectedUrl)}
                  src={selectedUrl + "#toolbar=0"}
                  className="image_iframe"
                /> */}


                  {/* <div class="example-modal"
                      style={{
                        height: "750px",
                      }}
                      onContextMenu={handleContextMenu}>
                      <Viewer
                        fileUrl={selectedUrl}
                        plugins={[defaultLayoutPluginInstance]}
                        defaultScale={1}
                      />
                    </div> */}

                  <ContentViewer contentstyle={{
                    height: "750px",
                  }}
                    url={selectedUrl} />


                  {/* <button
                  onClick={handleFullscreen}
                  className="FullScrnBtn hvr-bubble-top"
                >
                  Fullscreen
                </button> */}
                </div>
              ) : selectedMenu === "Workbook" ? (
                ""
              ) : selectedMenu === "WB_Student" ? (
                ""
              ) : selectedMenu === "Video" ||
                checkLastIndexHandler(selectedMenu, "mp4") ||
                checkLastIndexHandler(selectedMenu, "webm") ||
                checkLastIndexHandler(selectedMenu, "ogv") ||
                checkLastIndexHandler(selectedMenu, "mov") ||
                checkLastIndexHandler(selectedMenu, "avi") ? (
                <Paper
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <div
                    id="student-handle-2"
                    style={{
                      fontWeight: 600,
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      fontSize: "13px",
                    }}
                  >
                    {nameHandler(selectedMenu)}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "20px", marginTop: "20px", textAlign: "center" }}>
                      <CustomVideoPlayer
                        videoRef={videoRef}
                        selectedUrl={selectedUrl}
                        studyData={studyData}
                        revisionData={revisionData}
                        revisionMenu={revisionData?.length && revisionData}
                        studyMenu={studyData?.length && studyData}
                      />
                    </div>
                  </div>
                </Paper>
              ) : selectedMenu === "Audio" ||
                checkLastIndexHandler(selectedMenu, "mp3") ||
                checkLastIndexHandler(selectedMenu, "wav") ||
                checkLastIndexHandler(selectedMenu, "wma") ||
                checkLastIndexHandler(selectedMenu, "aac") ||
                checkLastIndexHandler(selectedMenu, "ogg") ? (
                <Paper
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <div
                    id="student-handle-3"
                    style={{
                      fontWeight: 600,
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      fontSize: "13px",
                    }}
                  >
                    {nameHandler(selectedMenu)}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ReactAudioPlayer
                      src={selectedUrl}
                      autoPlay={false}
                      controls
                      controlsList="nodownload"
                    />
                  </div>
                </Paper>
              ) : selectedMenu === "Presentation" ||
                checkLastIndexHandler(selectedMenu, "pptx") ||
                checkLastIndexHandler(selectedMenu, "ppt") ||
                checkLastIndexHandler(selectedMenu, "doc") ||
                checkLastIndexHandler(selectedMenu, "docx") ? (
                <Paper
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <div
                    id="student-handle-4"
                    style={{
                      fontWeight: 600,
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      fontSize: "13px",
                    }}
                  >
                    {nameHandler(selectedMenu)}
                  </div>
                  <div
                    style={{
                      height: "520px",
                      width: "65vw",
                      padding: "10px",
                      position: "relative",
                    }}
                  >
                    {/* <div style={{
                    position: "absolute",
                    width: "3vw",
                    height: "6vh",
                    top: "3vh",
                    left: "61vw",
                    backgroundColor: "rgba(0,0,0,0.4)",
                    cursor: "no-drop"
                  }}></div> */}
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedUrl}`}
                      // src={`https://docs.google.com/viewer?url=${encodeURIComponent(selectedUrl)}?&embedded=true`}
                      style={{ width: "100%", height: "100%" }}
                    />

                  </div>
                </Paper>
              ) : selectedMenu === "Exel" ||
                checkLastIndexHandler(selectedMenu, "xls") ||
                checkLastIndexHandler(selectedMenu, "xlsx") ? (
                <Paper
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <div
                    id="student-handle-5"
                    style={{
                      fontWeight: 600,
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      fontSize: "13px",
                    }}
                  >
                    {nameHandler(selectedMenu)}
                  </div>
                  <div
                    style={{
                      height: "520px",
                      width: "100%",
                      overflow: "auto",
                      padding: "10px",
                    }}
                  >
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedUrl}`}
                      // src={`https://docs.google.com/viewer?url=${encodeURIComponent(selectedUrl)}&embedded=true`}
                      style={{ width: "100%", height: "100%" }}
                    />

                  </div>
                </Paper>
              ) : (
                ""
              )}
          </div>
        </div>
        {/*Side-Menu Components*/}
      </div>
      {isHomeWorkModalOpen && (
        <HomeWorkModal
          open={isHomeWorkModalOpen}
          close={() => setIsHomeWorkModalOpen(false)}
          role={"student"}
          boardId={boardId}
          gradeId={gradeId}
          schoolId={schoolId}
          branchId={branchId}
          subjectId={selectedSubject}
          sectionId={sectionId}
          // subTopicId={subTopicId}
          chapterId={selectedChapterId}
        // academicYearId={academicYearId}
        // teacherId={teacherId}
        />
      )}
    </>
  );
}

export default Student_Study_Files;
